import React, { Component } from "react";
import { connect } from "react-redux";
import { CardTitle, Card, CardFooter, CardImg, Button } from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { schema } from "../../helpers/photosXraysValidations";
import { PhotosXraysDataMobileDesktp } from "../../helpers/photos-xrays";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { uploadFile, keyToUrl } from "../../helpers/s3";
import Resizer from "react-image-file-resizer";
import { updatePhotosXraysAPI } from "../../api/api";
import { mapTeethToApi } from "../../services/mapTeethToApi";
import { errorToaster, infoToaster } from "../../services/toast";
import { updatePhotosXrays } from "../../redux/CaseSubmission/action";
import { mapAdvancedToApi } from "../../services/mapAdvancedToApi";
import CustomeHeader from "../../components/header/CustomeHeader";
import upload_not_active from "../../assets/images/icon/upload_not_active.svg";
import upload_active from "../../assets/images/icon/upload_active.svg";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updatePhotosXrays: (payload) => dispatch(updatePhotosXrays(payload)),
});
class PhotosXrays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_percentage: 0,
      intervalID: 0,
      showProgress: false,
      loading_percentage_front_smiling: 0,
      loading_percentage_front_pose: 0,
      loading_percentage_profile: 0,
      loading_percentage_panoramic_xray: 0,
      loading_percentage_upper_occlusal: 0,
      loading_percentage_frontal: 0,
      loading_percentage_lower_occlusal: 0,
      loading_percentage_cephalometric_xray: 0,
      loading_percentage_right_buccal: 0,
      loading_percentage_left_buccal: 0,

      hover_front_smiling: false,
      hover_front_pose: false,
      hover_profile: false,
      hover_panoramic_xray: false,
      hover_upper_occlusal: false,
      hover_frontal: false,
      hover_lower_occlusal: false,
      hover_cephalometric_xray: false,
      hover_right_buccal: false,
      hover_left_buccal: false,
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
    this.front_smiling_ref = React.createRef();
    this.front_pose_ref = React.createRef();
    this.profile_ref = React.createRef();

    this.panoramic_xray_ref = React.createRef();
    this.upper_occlusal_ref = React.createRef();
    this.frontal_ref = React.createRef();

    this.lower_occlusal_ref = React.createRef();
    this.cephalometric_xray_ref = React.createRef();

    this.right_buccal_ref = React.createRef();
    this.left_buccal_ref = React.createRef();
  }

  /*--------------------------------------------------------------------------------*/
  /* uploading file timer                                             */
  /*--------------------------------------------------------------------------------*/
  timer = (id) => {
    this.setState({
      [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
    });
  };

  checkIfChanged = (oldarray, newArray) => {
    for (var x = 0; x < oldarray.length; x++) {
      if (oldarray[x].url !== newArray[x].url) {
        return true;
      }
    }
  };
  checkIfChanged2 = (oldobj, newobj) => {
    if (
      oldobj.panoramic_url === newobj.panoramic_url &&
      oldobj.cephalometric_url === newobj.cephalometric_url
    ) {
      return false;
    } else {
      return true;
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Photos and xrays step submit  */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.props;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    const { content } = this.props.languageReducer;

    let images = [
      { name: "front_smiling", url: values.front_smiling },
      { name: "front_pose", url: values.front_pose },
      { name: "profile", url: values.profile },
      { name: "upper_occlusal", url: values.upper_occlusal },
      { name: "lower_occlusal", url: values.lower_occlusal },
      { name: "left_buccal", url: values.left_buccal },
      { name: "frontal", url: values.frontal },
      { name: "right_buccal", url: values.right_buccal },
    ];
    let oldimages = [
      {
        name: "front_smiling",
        url: this.props.caseSubmission.photos_xrays.front_smiling,
      },
      {
        name: "front_pose",
        url: this.props.caseSubmission.photos_xrays.front_pose,
      },
      { name: "profile", url: this.props.caseSubmission.photos_xrays.profile },
      {
        name: "upper_occlusal",
        url: this.props.caseSubmission.photos_xrays.upper_occlusal,
      },
      {
        name: "lower_occlusal",
        url: this.props.caseSubmission.photos_xrays.lower_occlusal,
      },
      {
        name: "left_buccal",
        url: this.props.caseSubmission.photos_xrays.left_buccal,
      },
      { name: "frontal", url: this.props.caseSubmission.photos_xrays.frontal },
      {
        name: "right_buccal",
        url: this.props.caseSubmission.photos_xrays.right_buccal,
      },
    ];
    let photos_xrays = {
      front_smiling: values.front_smiling || "",
      front_pose: values.front_pose || "",
      profile: values.profile || "",
      upper_occlusal: values.upper_occlusal || "",
      lower_occlusal: values.lower_occlusal || "",
      left_buccal: values.left_buccal || "",
      frontal: values.frontal || "",
      right_buccal: values.right_buccal || "",
      panoramic: values.panoramic_xray || "",
      cephalometric: values.cephalometric_xray || "",
    };
    let oldxrays = {
      panoramic_url: this.props.caseSubmission.photos_xrays.panoramic,
      cephalometric_url: this.props.caseSubmission.photos_xrays.cephalometric,
    };

    let xrays = {
      panoramic_url: values.panoramic_xray,
      cephalometric_url: values.cephalometric_xray,
    };
    let checkimage = this.checkIfChanged(oldimages, images);
    let checkxrays = this.checkIfChanged2(oldxrays, xrays);
    if (checkimage || checkxrays) {
      setSubmitting(true);
      let filtered_image = images.filter((key, index) => {
        return key.url.match(/http([^&]+)/);
      });

      let filtered_xrays = {};

      for (var key2 in xrays) {
        if (xrays[key2].match(/http([^&]+)/)) {
          filtered_xrays[key2] = xrays[key2];
        }
      }

      let photos_data = {
        aligner_case: {
          // step: "photos",
          photos: filtered_image,
          xrays: filtered_xrays,
          advanced_modules: {
            ...this.props.caseSubmission.advanced_modules,
            ipr: {
              ...this.props.caseSubmission.advanced_modules?.ipr,
              iprRestriction: mapTeethToApi(
                this.props.customeTeethReducer.Case_IPR
              ),
            },
            archExpansion: {
              ...this.props.caseSubmission.advanced_modules?.archExpansion,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Arch_Expansion
              ),
            },
            attachments: {
              ...this.props.caseSubmission.advanced_modules?.attachments,
              attachmentsRestriction: mapTeethToApi(
                this.props.customeTeethReducer.Case_Attachments
              ),
            },
            elastics: {
              ...this.props.caseSubmission.advanced_modules?.elastics,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Elastics
              ),
              buttons_teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Elastics_Buttons
              ),
              cuts_teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Elastics_Cuts
              ),
            },
            extraction: {
              ...this.props.caseSubmission.advanced_modules?.extraction,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Extraction
              ),
            },
            overCorrection: {
              ...this.props.caseSubmission.advanced_modules?.overCorrection,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Overcorrections
              ),
            },
            pontics: {
              ...this.props.caseSubmission.advanced_modules?.pontics,
              teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Pontics),
            },
            torqueEnhancers: {
              ...this.props.caseSubmission.advanced_modules?.torqueEnhancers,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Torque_Enhancers
              ),
            },
            malocclusion: {
              ...this.props.caseSubmission.advanced_modules?.malocclusion,
              crossbite: {
                ...this.props.caseSubmission.advanced_modules?.malocclusion
                  ?.crossbite,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_Crossbites
                ),
              },
              crowding: {
                ...this.props.caseSubmission.advanced_modules?.malocclusion
                  ?.crowding,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_Crowding
                ),
              },
              classII: {
                ...this.props.caseSubmission.advanced_modules?.malocclusion
                  ?.classII,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_ClassII
                ),
              },
              classIII: {
                ...this.props.caseSubmission.advanced_modules?.malocclusion
                  ?.classIII,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_ClassIII
                ),
              },
            },
            all_modules: mapAdvancedToApi(this.props.advancedModules.modules),
          },
        },
      };

      // let xrays_data = {
      //   aligner_case: {
      //     step: "xrays",
      //     xrays: filtered_xrays,
      //     advanced_modules: {
      //       ...this.props.caseSubmission.advanced_modules?,
      //       all_modules: this.props.advancedModules.modules,
      //     },
      //   },

      // };

      // this.props.NextStep();

      updatePhotosXraysAPI(doctorId, caseId, JSON.stringify(photos_data))
        .then((res) => {
          setSubmitting(false);
          this.props.updatePhotosXrays(photos_xrays);
          this.props.NextStep();
        })
        .catch((error) => {
          setSubmitting(false);
        });
    } else {
      infoToaster(content.nothing_is_changed, content.Attention_needed);
      this.props.NextStep();
    }

    // updateAlignerCase(doctorId, caseId, JSON.stringify(photos_data))
    //   .then((res) => { })
    //   .then(() => {
    //     updateAlignerCase(doctorId, caseId, JSON.stringify(xrays_data)).then(
    //       (res) => {
    //         setSubmitting(false);
    //         this.props.NextStep();
    //       }
    //     );
    //   })
    //   .catch((error) => {
    //     setSubmitting(false);
    //   });
  };

  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id, file) => {
    return uploadFile({
      name: `case-${
        this.state.caseId
      }/patient-photos/${id}/${new Date().valueOf()}.JPEG`,
      contentType: image.type,
      file: image,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* upload photos */
  /* --------------------------------------------------------------------------------*/

  UploadPhoto = async (e, id, setFieldValue) => {
    e.persist();
    if (e.target.files[0]) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) => {
        this.setState({
          id: evt.currentTarget.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          clearInterval(this.state.intervalID);
          this.setState({
            [id]: keyToUrl(key),
            [`loading_percentage_${id}`]: 100,
          });
          setFieldValue(id, keyToUrl(key));
          setTimeout(() => {
            this.setState({
              [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
              [`loading_percentage_${id}`]: 0,
            });
          }, 500);
        },
        "blob"
      );
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload photos if from drop zone */
  /* --------------------------------------------------------------------------------*/
  UploadPhotoDropZone = async (e, id, setFieldValue) => {
    if (e) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) => {
        this.setState({
          id: evt.currentTarget.result,
        });
      });
      reader.readAsDataURL(e);
      Resizer.imageFileResizer(
        e,
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e);
          clearInterval(this.state.intervalID);
          this.setState({
            [id]: keyToUrl(key),
            [`loading_percentage_${id}`]: 100,
          });
          setFieldValue(id, keyToUrl(key));
          setTimeout(() => {
            this.setState({
              [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
              [`loading_percentage_${id}`]: 0,
              [`hover_${id}`]: false,
            });
          }, 500);
        },
        "blob"
      );
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* preventDefault */
  /* --------------------------------------------------------------------------------*/
  preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Over */
  /* --------------------------------------------------------------------------------*/
  dragOver = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: true });
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Enter */
  /* --------------------------------------------------------------------------------*/
  dragEnter = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: true });
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Leave */
  /* --------------------------------------------------------------------------------*/
  dragLeave = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: false });
  };
  /* --------------------------------------------------------------------------------*/
  /* file Drop */
  /* --------------------------------------------------------------------------------*/
  fileDrop = (e, key, setFieldValue) => {
    this.preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length === 1) {
      this.handleFiles(files, key, setFieldValue);
    } else {
      this.setState({ [`hover_${key}`]: false });
      // infoToaster("please add one file ");
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* handle Files */
  /* --------------------------------------------------------------------------------*/
  handleFiles = (files, key, setFieldValue) => {
    const { content } = this.props.languageReducer;

    for (let i = 0; i < files.length; i++) {
      if (this.validateFile(files[i])) {
        this.UploadPhotoDropZone(files[i], key, setFieldValue);
      } else {
        errorToaster(content.file_not_supported, content.Warning);
        this.setState({ [`hover_${key}`]: false });
      }
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* validate File */
  /* --------------------------------------------------------------------------------*/
  validateFile = (file) => {
    let extention = file.name.split(".");
    const validTypes = ["png", "gif", "jpeg", "jpg"];
    let extention_lower = file.name
      .split(".")
      [extention.length - 1].toLowerCase();
    if (validTypes.indexOf(extention_lower.toLowerCase()) === -1) {
      return false;
    }

    return true;
  };
  /* --------------------------------------------------------------------------------*/
  /* file Input Clicked */
  /* --------------------------------------------------------------------------------*/
  fileInputClicked = (input) => {
    // this[`${input}_ref`].current.click();
  };
  render() {
    const { block_case_submission } = this.props.userReducer.currentUser;

    const { content } = this.props.languageReducer;
    const { photos_xrays } = this.props.caseSubmission;
    const { patient_info } = this.props.caseReducer.case;
    return (
      <div style={window.innerWidth < 839 ? { paddingBottom: "80px" } : {}}>
        {/* <div className="patient-info-header">
          <CustomeHeader title={content.photos_x_rays} />
          <span className="patient_info">
            <div>{this.props.patient?.full_name}</div>
            <div> {content.case} {this.state?.caseId}</div>
          </span>
          </div> */}

        {window.innerWidth > 839 ? (
          <div className="patient-info-header">
            <CustomeHeader title={content.photos_x_rays} />
            <span className="patient_info callout-text">
              <div>{patient_info?.full_name}</div>
              <div>
                {" "}
                {content.case} {this.state?.caseId}
              </div>
            </span>
          </div>
        ) : (
          <h1 className="head-mobile-caseSubmission title-3-text">
            {content.photos_x_rays}{" "}
          </h1>
        )}

        <Formik
          enableReinitialize
          initialValues={{
            front_smiling: photos_xrays.front_smiling,
            front_pose: photos_xrays.front_pose,
            profile: photos_xrays.profile,
            upper_occlusal: photos_xrays.upper_occlusal,
            lower_occlusal: photos_xrays.lower_occlusal,
            left_buccal: photos_xrays.left_buccal,
            frontal: photos_xrays.frontal,
            right_buccal: photos_xrays.right_buccal,
            panoramic_xray: photos_xrays.panoramic,
            cephalometric_xray: photos_xrays.cephalometric,
          }}
          validationSchema={
            this.props.userReducer.currentUser.gco_doctor ? schema : ""
          }
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form className="form-material " onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <div id="case-submission" className="patient-info-container">
                <div className="main-photos-grid ">
                  {PhotosXraysDataMobileDesktp.map((value, index) => (
                    <div key={index}>
                      {value.key && (
                        <>
                          <Card
                            onDragOver={(e) => this.dragOver(e, value.key)}
                            onDragEnter={(e) => this.dragEnter(e, value.key)}
                            onDragLeave={(e) => this.dragLeave(e, value.key)}
                            onDrop={(e) =>
                              this.fileDrop(e, value.key, setFieldValue)
                            }
                            onClick={() => this.fileInputClicked(value.key)}
                            className={`cp2  ${
                              this.state[`hover_${value.key}`]
                                ? "c-dragover"
                                : ""
                            } ${
                              values[value.key]
                                ? "c-card-with-img-active"
                                : "c-card-with-img"
                            }`}
                          >
                            <CardTitle className="cmb2">
                              <div className="card-title-grid body-text">
                                <div>
                                  {values[value.key] ? (
                                    value.title
                                  ) : this.state[
                                      `showProgress_${value.key}`
                                    ] ? (
                                    `Uploading ${value.title}`
                                  ) : (
                                    <span className="not-active-img">
                                      {value.title}{" "}
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <img
                                    src={
                                      values[value.key]
                                        ? upload_active
                                        : upload_not_active
                                    }
                                    alt={value.key}
                                  />
                                </div>
                              </div>
                              {(value.key === "cephalometric_xray" ||
                                value.key === "panoramic_xray" ||
                                !this.props.userReducer.currentUser
                                  .gco_doctor) && (
                                <div className="optional-input label-1-text">
                                  *{content.optional}
                                </div>
                              )}{" "}
                            </CardTitle>

                            <div className="image-overlay-container">
                              <div className="image-cassSubmission-mobile">
                                <label
                                  name={value.key}
                                  htmlFor={value.key}
                                  className="photos-xrays-img"
                                >
                                  <CardImg
                                    src={
                                      values[value.key]
                                        ? values[value.key]
                                        : value.img
                                    }
                                    className={`c-card-img cursor-pointer`}
                                  />
                                </label>
                              </div>
                              <input
                                ref={this[`${value.key}_ref`]}
                                type="file"
                                id={value.key}
                                onChange={(e) => {
                                  this.UploadPhoto(e, value.key, setFieldValue);
                                }}
                                multiple={false}
                                className="visually-hidden"
                                accept="image/x-png,image/gif,image/jpeg"
                              />
                              {this.state[`showProgress_${value.key}`] && (
                                <div className="overlay-loader">
                                  <CircularProgressbar
                                    className="circule-progress-bar"
                                    value={
                                      this.state[
                                        `loading_percentage_${value.key}`
                                      ]
                                    }
                                    text={`${
                                      this.state[
                                        `loading_percentage_${value.key}`
                                      ]
                                    }%`}
                                  />
                                </div>
                              )}
                            </div>
                          </Card>
                          <ErrorMessage
                            component="div"
                            name={value.key}
                            className="custom-invalid-feedback m-1 callout-text"
                          />
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {window.innerWidth <= 839 && !block_case_submission && (
                  <CardFooter className="p-3 container-footer-mobile">
                    <div className="order-save-button marginTop inmobile">
                      <Button
                        className={`btn light-green-btn  float-right  submission-btn   button-seme-bold-text marginTop`}
                        size="sm"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {content.next}{" "}
                        <i className="fas fa-angle-right pl-1"></i>
                      </Button>{" "}
                    </div>
                    <Button
                      className={`btn white-btn float-left submission-btn button-seme-bold-text `}
                      size="sm"
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => this.props.PrevStep()}
                    >
                      <i className="fas fa-angle-left pr-1"></i>{" "}
                      {content.previous}
                    </Button>{" "}
                    <div className="order-save-button inWebsit">
                      <Button
                        className={`btn light-green-btn float-right  submission-btn button-seme-bold-text `}
                        size="sm"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {content.next}{" "}
                        <i className="fas fa-angle-right pl-1"></i>
                      </Button>{" "}
                    </div>
                  </CardFooter>
                )}
              </div>
              {window.innerWidth > 839 && !block_case_submission && (
                <CardFooter className="p-3 container-footer-mobile">
                  <div className="order-save-button marginTop inmobile">
                    <Button
                      className={`btn light-green-btn  float-right  submission-btn  button-seme-bold-text  marginTop`}
                      size="sm"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {content.next} <i className="fas fa-angle-right pl-1"></i>
                    </Button>{" "}
                  </div>
                  <Button
                    className={`btn white-btn float-left submission-btn button-seme-bold-text `}
                    size="sm"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => this.props.PrevStep()}
                  >
                    <i className="fas fa-angle-left pr-1"></i>{" "}
                    {content.previous}
                  </Button>{" "}
                  <div className="order-save-button inWebsit">
                    <Button
                      className={`btn light-green-btn float-right  submission-btn button-seme-bold-text`}
                      size="sm"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {content.next} <i className="fas fa-angle-right pl-1"></i>
                    </Button>{" "}
                  </div>
                </CardFooter>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PhotosXrays);
