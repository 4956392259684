import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
function AppAccordion({
  eventKey,
  title,
  answer = "",
  className,
  children,
  onClick,
}) {
  return (
    <div className="app-accordion">
      <Accordion.Item
        onClick={onClick}
        eventKey={eventKey}
        className={className}
      >
        <Accordion.Header>
          {/* <p style={{ margin: 0 }}> */}
          {title}
          {/* </p> */}
        </Accordion.Header>
        {!!answer && <p className="selected-ans">{answer}</p>}

        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
    </div>
  );
}

export default AppAccordion;
