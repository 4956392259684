import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { GetCountryLabel } from "../../../helpers/countries";

const styles = StyleSheet.create({
  row: {
    paddingTop: 10,
    alignItems: "center",
    paddingBottom: 10,
  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    width: "100%",
  },
  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#E6E6FA",
    paddingBottom: 15,
    paddingTop: 5,
  },
  field: {
    flexDirection: "row",
    paddingTop: 10,
  },
  col: {
    flexDirection: "column",
    width: "33%",
  },
  title: {
    fontSize: 10,
    textAlign: "left",
    color: "#00c7b1",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
});

export default class ShppingAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      caseInfo: props.case,
    };
  }

  render() {
    const { caseInfo } = this.state;
    const { content } = this.props

    return (
      <Fragment>
        <View style={styles.row}>
          <Text style={styles.header}>{content.shipping_address}:</Text>
          <View style={styles.container}>
            <View style={styles.col}>
              <View style={styles.field}>
                <Text style={styles.title}>{content.country}: </Text>
                <Text style={styles.info}>
                  {GetCountryLabel(caseInfo.address.country)}
                </Text>
              </View>

              <View style={styles.field}>
                <Text style={styles.title}>{content.building}  : </Text>
                <Text style={styles.info}>{caseInfo.address.line2}</Text>
              </View>
            </View>
            <View style={styles.col}>
              <View style={styles.field}>
                <Text style={styles.title}>{content.city} : </Text>
                <Text style={styles.info}>{caseInfo.address.city}</Text>
              </View>
              <View style={styles.field}>
                <Text style={styles.title}>{content.street}: </Text>
                <Text style={styles.info}>{caseInfo.address.line1}</Text>
              </View>
            </View>
            <View style={styles.col}>
              <View style={styles.field}>
                <Text style={styles.title}>{content.state} : </Text>
                <Text style={styles.info}>{caseInfo.address.state}</Text>
              </View>

              <View style={styles.field}>
                <Text style={styles.title}>{content.phone} : </Text>
                <Text style={styles.info}>{caseInfo.address.phone}</Text>
              </View>
            </View>
          </View>
        </View>
      </Fragment>
    );
  }
}
