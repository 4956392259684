import { UPDATE_LANGUAGE, UPDATE_CONTENT } from "../constants/";

export const UpdateLanguage = (payload) => {
  return {
    type: UPDATE_LANGUAGE,
    payload,
  };
};

export const UpdateContent = (payload) => {
  return {
    type: UPDATE_CONTENT,
    payload,
  };
};
