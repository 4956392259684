import React, { useEffect } from "react";
import { getAccessToken, getDoctors } from "../../api/api";
const CallBack = () => {
  const code = (window.location.search.match(/code=([^&]+)/) || [])[1];

  useEffect(() => {
    /*--------------------------------------------------------------------------------*/
    /*if there is code in params get access token then get doctor data,
       before redirect to auth components   */
    /*--------------------------------------------------------------------------------*/
    const fn = async () => {
      if (!code) {
        //   await getAuthrizationCode();
      } else {
        await getAccessToken(code).then((res) => {
          localStorage.setItem("token", res.access_token);

          // let expire_time = new Date();
          // expire_time.setDate(expire_time.getDate() + 1);
          // localStorage.setItem("expire_time", expire_time);
          getDoctors().then((res) => {
            localStorage.setItem("currentUser", JSON.stringify(res[0]));
            window.location = "/home";
          });
        });
      }
    };

    fn();
  });

  return <div></div>;
};

export default CallBack;
