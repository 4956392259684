//1
import malocclusion from "../../assets/images/v4/Malocclusion.svg";
import malocclusion_active from "../../assets/images/v4/Malocclusion_active.svg";
//2
import extraction from "../../assets/images/v4/Extraction.svg";
import extraction_active from "../../assets/images/v4/Extraction_active.svg";
//3
import ipr from "../../assets/images/v4/IPR.svg";
import ipr_active from "../../assets/images/v4/IPR_active.svg";
//4
import attachments from "../../assets/images/v4/Attachement.svg";
import attachments_active from "../../assets/images/v4/Attachement_active.svg";
//5
import pontics from "../../assets/images/v4/Pontics_icon.svg";
import pontics_active from "../../assets/images/v4/Pontics_icon_active.svg";
//6
import overCorrection from "../../assets/images/v4/Overcorrection.svg";
import overCorrection_active from "../../assets/images/v4/Overcorrection_active.svg";
//7
import passiveAligners from "../../assets/images/v4/Passive Aligner.svg";
import passiveAligners_active from "../../assets/images/v4/Passive Aligner_active.svg";
//8
import toothSizeDiscrepancy from "../../assets/images/v4/Tooth_Descripancy.svg";
import toothSizeDiscrepancy_active from "../../assets/images/v4/Tooth_Descripancy_active.svg";
//9
import torqueEnhancers from "../../assets/images/v4/Torque_Enhancer.svg";
import torqueEnhancers_active from "../../assets/images/v4/Torque_Enhancer_active.svg";
//10
import biteRamps from "../../assets/images/v4/Bite_Ramp.svg";
import biteRamps_active from "../../assets/images/v4/Bite_Ramp_active.svg";
//11
import archExpansion from "../../assets/images/v4/Expansion.svg";
import archExpansion_active from "../../assets/images/v4/Expansion_active.svg";
//12
import elastics from "../../assets/images/v4/Elastics.svg";
import elastics_active from "../../assets/images/v4/Elastics_active.svg";
//13
import hybrid_treatment from "../../assets/images/v4/Hybrid_Treatment.svg";
import hybrid_treatment_active from "../../assets/images/v4/Hybrid_Treatment_active.svg";
//14
import teeth_movement from "../../assets/images/v4/Teeth_movement.svg";
import teeth_movement_active from "../../assets/images/v4/Teeth_movement_active.svg";



export const modules = [
  {
    id: 1,
    key: "malocclusion",
    title: "Malocclusion",
    name: "malocclusion",
    img: malocclusion,
    active_img: malocclusion_active,
    active: false,
  },
  {
    id: 2,
    key: "extraction",
    name: "extraction",
    title: "Extraction",
    img: extraction,
    active_img: extraction_active,
    active: false,
  },
  {
    id: 3,
    key: "ipr",
    name: "inter_proximal_reduction",
    title: "Interproximal Reduction",
    img: ipr,
    active_img: ipr_active,
    active: false,
  },

  {
    id: 4,
    key: "overCorrection",
    name: "overcorrection",
    title: "Overcorrection",
    img: overCorrection,
    active_img: overCorrection_active,
    active: false,
  },

  {
    id: 5,
    key: "passiveAligners",
    name: "passive_aligner",
    title: "Passive Aligner",
    img: passiveAligners,
    active_img: passiveAligners_active,
    active: false,
  },
   {
    id: 6,
    key: "biteRamps",
    name: "bite_ramps",
    title: "Bite Ramps",
    img: biteRamps,
    active_img: biteRamps_active,
    active: false,
  },
   
  {
    id: 7,
    key: "toothSizeDiscrepancy",
    name: "tooth_size_discrepancy",
    title: "Tooth size Discrepancy",
    img: toothSizeDiscrepancy,
    active_img: toothSizeDiscrepancy_active,
    active: false,
  },
  {
    id: 8,
    key: "elastics",
    name: "elastics",
    title: "Elastics",
    img: elastics,
    active_img: elastics_active,
    active: false,
  },
  {
    id: 9,
    key: "archExpansion",
    name: "archExpansion",
    title: "ArchExpansion",
    img: archExpansion,
    active_img: archExpansion_active,
    active: false,
  },
  {
    id: 10,
    key: "attachments",
    name: "attachments",
    title: "Attachments",
    img: attachments,
    active_img: attachments_active,
    active: false,
  },
  {
    id: 11,
    key: "pontics",
    name: "pontics",
    title: "Pontics",
    img: pontics,
    active_img: pontics_active,
    active: false,
  },
  {
    id: 12,
    key: "torqueEnhancers",
    name: "torque_enhancers",
    title: "Torque Enhancers",
    img: torqueEnhancers,
    active_img: torqueEnhancers_active,
    active: false,
  },
  {
    id: 13,
    key: "hybridTreatment",
    name: "hybrid_treatment",
    title: "Hybrid Treatment",
    img: hybrid_treatment,
    active_img: hybrid_treatment_active,
    active: false,
  },
  {
    id: 14,
    key: "teethMovement",
    name: "toothRateMovement",
    title: "Tooth rate Movement",
    img: teeth_movement,
    active_img: teeth_movement_active,
    active: false,
  },

];
