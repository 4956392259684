import { upper_teeth, lower_teeth } from "../redux/newCase/init_teeth";
import { checkMovementTooth } from "./checkChanges";
import { findNumber } from "./findNumbers";

export const mapRevisedData = (data) => {
  let current = mapTeeth(data, "current");
  let updated = mapTeeth(data, "updated");

  let mappedUpper = GetUpdatedData(current.upper_teeth, updated.upper_teeth);
  let mappedLower = GetUpdatedData(current.lower_teeth, updated.lower_teeth);
  return {
    saved: current,
    updated: {
      lower_teeth: mappedLower,
      upper_teeth: mappedUpper,
    },
  };
};

/* ----------------------------------------------------------------------------------*/
/* separate current from updated data and map ipr and movement data with needed keys */
/* ----------------------------------------------------------------------------------*/
const mapTeeth = (newData, newKey) => {
  let ipr = {
    upper_teeth: convertUpperIPRKey(newData.ipr.upper_teeth),
    lower_teeth: convertLowerIPRKey(newData.ipr.lower_teeth),
  };
  let RightTeethUpper = mapRightTeeth(
    newData,
    "upper_r_",
    upper_teeth,
    "upper_teeth",
    ipr.upper_teeth,
    newKey,
    false,
    18,
    "UR"
  );
  let RightTeethLower = mapRightTeeth(
    newData,
    "lower_r_",
    lower_teeth,
    "lower_teeth",
    ipr.lower_teeth,
    newKey,
    false,
    48,
    "LR"
  );

  let LeftTeethUpper = mapLeftTeeth(
    newData,
    "upper_l_",
    upper_teeth,
    "upper_teeth",
    ipr.upper_teeth,
    newKey,
    true,
    21,
    "UL"
  );
  let LeftTeethLower = mapLeftTeeth(
    newData,
    "lower_l_",
    lower_teeth,
    "lower_teeth",
    ipr.lower_teeth,
    newKey,
    true,
    31,
    "LL"
  );

  let teeth = {
    upper_teeth: { ...RightTeethUpper, ...LeftTeethUpper },
    lower_teeth: { ...RightTeethLower, ...LeftTeethLower },
  };
  return teeth;
};
/* ----------------------------------------------------------------------------------*/
/* map right teeth with needed keys */
/* ----------------------------------------------------------------------------------*/
const mapRightTeeth = (
  newData,
  key,
  teeth,
  position,
  ipr,
  newKey,
  increment,
  counter,
  position2
) => {
  let num = 8;
  let count = counter;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        ...teeth[`${key}${num}`],
        ...(ipr[`${key}${num}`]
          ? ipr[`${key}${num}`][newKey]
          : {
              Amount: "",
              Before_Step: "",
              IPR: false,
            }),
        image:
          ipr[`${key}${num}`] && ipr[`${key}${num}`][newKey]?.Restrict_Tooth
            ? teeth[`${key}${num}`].restrect
            : teeth[`${key}${num}`].not_active ||
              teeth[`${key}${num}`].not_active,

        movement_image: newData.teeth_movement[position][`${key}${num}`][newKey]
          .Restrict_Tooth
          ? teeth[`${key}${num}`].restrect
          : teeth[`${key}${num}`].not_active,
        updated: false,
        index: num,
        amount: ipr[`${key}${num}`] ? ipr[`${key}${num}`][newKey].Amount : "",
        before_step: ipr[`${key}${num}`]
          ? ipr[`${key}${num}`][newKey].Before_Step
          : "",
        divider: true,
        use_ipr: ipr[`${key}${num}`] ? ipr[`${key}${num}`][newKey].IPR : "",
        do_not_perform_ipr: false,
        tooth_key: `${position2}_${num} / ${position2}_${num - 1}`,
        fdi_key: increment ? count + 1 : count - 1,
        ipr_steps_amounts: ipr[`${key}${num}`]
          ? ipr[`${key}${num}`][newKey]?.IPR_Steps_Amounts || [
              { step: 0, amount: 0 },
            ]
          : [{ step: 0, amount: 0 }],
        restrict_ipr_tooth: ipr[`${key}${num}`]
          ? ipr[`${key}${num}`][newKey]?.Restrict_Tooth || false
          : false,
        tooth_key_sys: {
          palmer: `${findNumber("palmer", `${position2}${num}`)["palmer"]} / ${
            findNumber("palmer", `${position2}${num - 1}`)["palmer"]
          }`,
          fdi: `${findNumber("palmer", `${position2}${num}`)["fdi"]} / ${
            findNumber("palmer", `${position2}${num - 1}`)["fdi"]
          }`,
          universal: `${
            findNumber("palmer", `${position2}${num}`)["universal"]
          } / ${findNumber("palmer", `${position2}${num - 1}`)["universal"]}`,
        },
        ToothSystemName: findNumber("palmer", `${position2}${num}`),
        movement: mapMovement(
          { ...newData.teeth_movement[position][`${key}${num}`][newKey] },
          increment ? count + 1 : count - 1,
          `${key}${num}`
        ),
      },
    };
    num--;
  }
  return rightTeeth;
};
/* ----------------------------------------------------------------------------------*/
/* map left teeth with needed keys */
/* ----------------------------------------------------------------------------------*/
const mapLeftTeeth = (
  newData,
  key,
  teeth,
  position,
  ipr,
  newKey,
  increment,
  counter,
  position2
) => {
  let num = 1;
  let count = counter;

  let leftTeeth = {};
  for (var x = 1; x < 9; x++) {
    leftTeeth = {
      ...leftTeeth,

      [`${key}${num}`]: {
        ...teeth[`${key}${num}`],
        ...(ipr[`${key}${num}`]
          ? ipr[`${key}${num}`][newKey]
          : {
              Amount: "",
              Before_Step: "",
              IPR: false,
            }),
        image:
          ipr[`${key}${num}`] && ipr[`${key}${num}`][newKey]?.Restrict_Tooth
            ? teeth[`${key}${num}`].restrect
            : teeth[`${key}${num}`].not_active ||
              teeth[`${key}${num}`].not_active,

        movement_image: newData.teeth_movement[position][`${key}${num}`][newKey]
          .Restrict_Tooth
          ? teeth[`${key}${num}`].restrect
          : teeth[`${key}${num}`].not_active,
        updated: false,
        index: num,
        amount: ipr[`${key}${num}`] ? ipr[`${key}${num}`][newKey].Amount : "",
        before_step: ipr[`${key}${num}`]
          ? ipr[`${key}${num}`][newKey].Before_Step
          : "",
        divider: true,
        use_ipr: ipr[`${key}${num}`] ? ipr[`${key}${num}`][newKey].IPR : "",
        do_not_perform_ipr: false,
        tooth_key: `${position2}_${num} / ${position2}_${num + 1}`,
        fdi_key: increment ? count + 1 : count - 1,
        ipr_steps_amounts: ipr[`${key}${num}`]
          ? ipr[`${key}${num}`][newKey]?.IPR_Steps_Amounts || [
              { step: 0, amount: 0 },
            ]
          : [{ step: 0, amount: 0 }],
        restrict_ipr_tooth: ipr[`${key}${num}`]
          ? ipr[`${key}${num}`][newKey]?.Restrict_Tooth || false
          : false,
        tooth_key_sys: {
          palmer: `${findNumber("palmer", `${position2}${num}`)?.palmer} / ${
            findNumber("palmer", `${position2}${num + 1}`)?.palmer
          }`,
          fdi: `${findNumber("palmer", `${position2}${num}`).fdi} / ${
            findNumber("palmer", `${position2}${num + 1}`)?.fdi
          }`,
          universal: `${
            findNumber("palmer", `${position2}${num}`)?.universal
          } / ${findNumber("palmer", `${position2}${num + 1}`)?.universal}`,
        },
        ToothSystemName: findNumber("palmer", `${position2}${num}`),
        movement: mapMovement(
          { ...newData.teeth_movement[position][`${key}${num}`][newKey] },
          increment ? count + 1 : count - 1,
          `${key}${num}`
        ),
      },
    };
    num++;
  }
  return leftTeeth;
};
/* ----------------------------------------------------------------------------------*/
/* get lower ipr needed tooth key  */
/* ----------------------------------------------------------------------------------*/
const convertLowerIPRKey = (data) => {
  let teeth = {};
  for (var key in data) {
    let newKey = key.split("/")[0].replace("_", "");

    teeth[`lower_${newKey[1].toLowerCase()}_${newKey[2]}`] = data[key];
  }
  return teeth;
};
/* ----------------------------------------------------------------------------------*/
/* get upper ipr needed tooth key  */
/* ----------------------------------------------------------------------------------*/
const convertUpperIPRKey = (data) => {
  let teeth = {};
  for (var key in data) {
    let newKey = key.split("/")[0].replace("_", "");

    teeth[`upper_${newKey[1].toLowerCase()}_${newKey[2]}`] = data[key];
  }
  return teeth;
};

/* ----------------------------------------------------------------------------------*/
/* map teeth with needed movement keys  */
/* ----------------------------------------------------------------------------------*/
const mapMovement = (data, tooth, tooth_name) => {
  let newData = {
    ToothNumber: tooth,
    Angulation: data.Angulation || "0 deg",
    Circumference: "",
    ExtrusionIntrusion: data.ExtrusionIntrusion || "0 mm",
    ForwardBackward: data.ForwardBackward || "0 mm",
    Inclination: data.Inclination || "0 deg",
    IprDistal: "",
    IprMesial: "",
    LeftRight: data.LeftRight || "0 mm",
    LongAxis: "",
    Rotation: data.Rotation || "0 deg",
    use_extraction: data.Extraction || false,
    useAttachments: data.Attachments || false,
    attachments_step: data.Attachments_Step || "",
    attachment_type: data.Attachment_Type || "",
    usePontics: data.Pontics || false,
    useTorqueEnhancers: data.Torque_Enhancers || false,
    use_buttons: data.Elastic_Buttons || false,
    use_cuts: data.Elastic_Cuts || false,
    // //elastic
    useElastics: data.Elastic || false,

    ToothName: tooth_name,
    updated: false,
    note: data.Notes || "",
    restrict_tooth: data.Restrict_Tooth || false,
    attachments: data.Attachments_Steps_Types || [
      {
        step: 0,
        type: "",
      },
    ],
    elastics: data.Elastic_Stages || [{ from: 0, to: 0 }],
  };

  return newData;
};

/* ----------------------------------------------------------------------------------*/
/* check if ipr or movement data are updated  */
/* ----------------------------------------------------------------------------------*/
function GetUpdatedData(current, updated) {
  for (var key in updated) {
    updated[key] = {
      ...updated[key],
      updated: checkIPR(current[key], updated[key]),
      movement: {
        ...updated[key].movement,
        updated: checkMovementTooth(current[key].movement, updated[key].movement),
      },
      image: updated[key].restrict_ipr_tooth
        ? updated[key].restrect
        : updated[key].not_active,

      movement_image: updated[key].movement.restrict_tooth
        ? updated[key].restrect
        : checkMovementTooth(current[key].movement, updated[key].movement)
        ? updated[key].update
        : updated[key].not_active || updated[key].not_active,
    };
  }

  return updated;
}

/* ----------------------------------------------------------------------------------*/
/* check if movement data updated  */
/* ----------------------------------------------------------------------------------*/
const checkMovement = (current, updated) => {
  if (
    Number(current.LeftRight.split(" ")[0]) !==
      Number(updated.LeftRight.split(" ")[0]) ||
    Number(current.Rotation.split(" ")[0]) !==
      Number(updated.Rotation.split(" ")[0]) ||
    Number(current.Angulation.split(" ")[0]) !==
      Number(updated.Angulation.split(" ")[0]) ||
    Number(current.Inclination.split(" ")[0]) !==
      Number(updated.Inclination.split(" ")[0]) ||
    Number(current.ExtrusionIntrusion.split(" ")[0]) !==
      Number(updated.ExtrusionIntrusion.split(" ")[0]) ||
    Number(current.ForwardBackward.split(" ")[0]) !==
      Number(updated.ForwardBackward.split(" ")[0]) ||
    current.use_extraction !== updated.use_extraction ||
    current.useAttachments !== updated.useAttachments ||
    current.useElastics !== updated.useElastics ||
    Number(current.attachments_step) !== Number(updated.attachments_step) ||
    current.attachment_type !== updated.attachment_type ||
    current.usePontics !== updated.usePontics ||
    current.useTorqueEnhancers !== updated.useTorqueEnhancers ||
    current.use_buttons !== updated.use_buttons ||
    current.use_cuts !== updated.use_cuts ||
    current.note !== updated.note ||
    checkAttchments(current, updated) ||
    checkElastics(current, updated)
  ) {
    return true;
  } else {
    return false;
  }
};
/* ----------------------------------------------------------------------------------*/
/* check if there is any change in attachment array  */
/* ----------------------------------------------------------------------------------*/
const checkAttchments = (current, updated) => {
  if (updated.attachments.length && current.attachments.length) {
    if (updated.attachments.length !== current.attachments.length) {
      return true;
    } else {
      for (var x = 0; x < updated.attachments.length; x++) {
        if (
          updated.attachments[x].step !== current.attachments[x].step ||
          updated.attachments[x].type !== current.attachments[x].type
        ) {
          return true;
        }
      }
    }
  }

  return false;
};
/* ----------------------------------------------------------------------------------*/
/* check if there is any change in Elastics array  */
/* ----------------------------------------------------------------------------------*/
const checkElastics = (current, updated) => {
  if (updated.elastics.length && current.elastics.length) {
    if (updated.elastics.length !== current.elastics.length) {
      return true;
    } else {
      for (var x = 0; x < updated.elastics.length; x++) {
        if (
          updated.elastics[x].from !== current.elastics[x].from ||
          updated.elastics[x].to !== current.elastics[x].to
        ) {
          return true;
        }
      }
    }
  }

  return false;
};
/* ----------------------------------------------------------------------------------*/
/* check if ipr data updated  */
/* ----------------------------------------------------------------------------------*/
const checkIPR = (current, updated) => {
  if (current.use_ipr !== updated.use_ipr || checkSteps(current, updated)||
  current.restrict_ipr_tooth !== updated.restrict_ipr_tooth) {
    return true;
  } else {
    return false;
  }
};
/* ----------------------------------------------------------------------------------*/
/* check if there is any change in ipr ateps array  */
/* ----------------------------------------------------------------------------------*/

const checkSteps = (current, updated) => {
  let changed = false;

  if (current.ipr_steps_amounts.length && updated.ipr_steps_amounts.length) {
    if (current.ipr_steps_amounts.length !== updated.ipr_steps_amounts.length) {
      return true;
    } else {
      for (var x = 0; x < updated.ipr_steps_amounts.length; x++) {
        if (
          current.ipr_steps_amounts[x].step !==
            updated.ipr_steps_amounts[x].step ||
          current.ipr_steps_amounts[x].amount !==
            updated.ipr_steps_amounts[x].amount
        ) {
          return true;
        }
      }
    }
  }

  return false;
};
