import React, { Component } from "react";
import { connect } from "react-redux";

import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),

});
class Openbite extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.extrudeAnterior =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.openbite
        ?.extrudeAnterior || false;
    this.intrudePosterior =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.openbite
        ?.intrudePosterior || false;
    this.both =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.openbite
        ?.both || false;
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      extrudeAnterior: this.extrudeAnterior,
      intrudePosterior: this.intrudePosterior,
      both: this.both,
    };
    this.props.save("openbite", data);
  };
  render() {
    const { extrudeAnterior, intrudePosterior, both } = this;
    const { content } = this.props.languageReducer
    return (
      <div className="">
         <CustomeHeader title={content.openbite} optional={false} largeFont={false}/>
  
         <div className="buttom-paddingFORMalc">
          <div className="control-label title_active-gco title_tertment_option title-3-text">
          {content.treatment_options}{" "}
          </div>
          <CustomInput
            className={`pt-1 ${extrudeAnterior && "replacment-checkbox"} button-text-select`}
            type="checkbox"
            id="extrudeAnterior"
            label={content.extrude_anterior_teeth_only}
            checked={extrudeAnterior}
            disabled={both || intrudePosterior || this.props.summary}
            onChange={() => {
              this.extrudeAnterior = !this.extrudeAnterior;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${intrudePosterior && "replacment-checkbox"} button-text-select`}
            type="checkbox"
            id="intrudePosterior"
            label={content.intrude_posterior_teeth_only}
            checked={intrudePosterior}
            disabled={both || extrudeAnterior || this.props.summary}
            onChange={() => {
              this.intrudePosterior = !this.intrudePosterior;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${both && "replacment-checkbox"} button-text-select`}
            type="checkbox"
            id="both2"
            label={content.both}
            checked={both}
            disabled={intrudePosterior || extrudeAnterior || this.props.summary}
            onChange={() => {
              this.both = !this.both;
              this.UpdateCrossBiteReducer();
            }}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Openbite);
