import Amplify from "@aws-amplify/core";
import Storage from "@aws-amplify/storage";
import Auth from "@aws-amplify/auth";

const BASE_URL =
  "https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/";

Amplify.configure({
  Auth: {
    identityPoolId: "eu-central-1:cd8bae7f-e832-47e5-ace1-5091f4a93416",
    region: "eu-central-1",
  },
  Storage: {
    bucket: "eon-access-sandbox",
    region: "eu-central-1",
  },
});

function uploadFile({ name, file, contentType, callback = () => {} }) {
  return Storage.put(name, file, {
    contentType: contentType,
    ACL: "public-read",
    level: "public",
    progressCallback(progress) {
      callback(progress);
    },
  });
}

function keyToUrl(key) {
  return `${BASE_URL}${key}`;
}

export { uploadFile, keyToUrl };
