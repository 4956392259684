import React, { Component } from "react";
import { connect } from "react-redux";

import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { Button} from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import CustomToggle from "../../../../../components/toggle/CustomeToggle";
import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Extraction extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_extraction: this.props.caseSubmission.advanced_modules.extraction
        .use_extraction,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "extraction",
      data: {
        use_extraction: this.state.use_extraction,
        teeth: this.props.customeTeethReducer.Case_Extraction,
      },
    });
    this.props.save();
  };



  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_extraction } = this.state;
    const { content } = this.props.languageReducer

    return (
      <div>

    

        <div className="form-group content form-block-holder grid_container D-head-ts ">
        {/* <div class="D-total-cont"> */}
          <div className="customHeaderTS ">
            <div> <CustomeHeader title={content.extraction} className="cp3 pt-0" optional={false} note={false} note_text={content.extraction_note} /></div>
            <div className="reative-close-ts">  <span className={` pull-right ts-close-button-new`} onClick={() => { this.props.toggleModalsClose(this.props.active_module) }}>&times;</span></div>
          </div>
          {/* </div> */}


          <div className="admin-Grid-Advance">
            <div></div>
            {/* part 1 */}
            <div className=" admin-Grid-Advance-inner D-head-ts ">
              <div >
                {content.Used_Extraction_for_this_case}{" "}

              </div>
              {/* part 2 */}
              <div className="D-total-cont-toggle  ">
              <div className="pointer_disable">
                <CustomToggle id="use-attachments"
                  id="use-attachments"
                  checked={use_extraction}
                  onChange={() => {
                    this.setState({ use_extraction: !use_extraction });
                    if (use_extraction) {
                      this.props.Clear_Teeth_Data("Case_Extraction")
                    }
                  }}
                />
              </div>
              </div>
            </div>

            <div></div>
          </div>
          <div>

            <div className="control-label title_active-gcoTS-new text-center pb-2 mt-2  D-head-ts ">
              {content.extract}
            </div>

            {/* ///part 3 */}
<div className="teeth-selector-ts">
            <div className="teeth_selector  m-auto ">

              <CustomeTeeth action_key="Case_Extraction" disabled={!use_extraction || use_extraction === "eonToDecide"}  advanced_Ts="true" summary="true"  />
            </div>
            </div>
          </div>
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => {
              this.UpdateAdvancedModules();
            }}
          >
            {content.save}
          </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Extraction);
