import React, { Component } from "react";
import { connect } from "react-redux";
import {
    CardTitle,
    Card,
    CardFooter,
    CardImg,
    Button,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { PhotosDataMobileDesktp } from "../../../helpers/photos-xrays";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { uploadFile, keyToUrl } from "../../../helpers/s3";
import Resizer from "react-image-file-resizer";
import { errorToaster } from "../../../services/toast";
import upload_not_active from "../../../assets/images/icon/upload_not_active.svg";
import upload_active from "../../../assets/images/icon/upload_active.svg";
import { updatePhotosXraysBite } from "../../../redux/biteRecord/action";
import { PhotosSchema } from "../../../helpers/photosXraysValidations";

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({
    updatePhotosXraysBite: (payload) => dispatch(updatePhotosXraysBite(payload)),
});
class BitePhotosXrays extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_percentage: 0,
            intervalID: 0,
            showProgress: false,
            loading_percentage_front_smiling: 0,
            loading_percentage_front_pose: 0,
            loading_percentage_profile: 0,
            loading_percentage_panoramic_xray: 0,
            loading_percentage_upper_occlusal: 0,
            loading_percentage_frontal: 0,
            loading_percentage_lower_occlusal: 0,
            loading_percentage_cephalometric_xray: 0,
            loading_percentage_right_buccal: 0,
            loading_percentage_left_buccal: 0,

            hover_front_smiling: false,
            hover_front_pose: false,
            hover_profile: false,
            hover_panoramic_xray: false,
            hover_upper_occlusal: false,
            hover_frontal: false,
            hover_lower_occlusal: false,
            hover_cephalometric_xray: false,
            hover_right_buccal: false,
            hover_left_buccal: false,
            caseId: props.caseReducer.case.uid,
        };
        this.front_smiling_ref = React.createRef();
        this.front_pose_ref = React.createRef();
        this.profile_ref = React.createRef();

        this.panoramic_xray_ref = React.createRef();
        this.upper_occlusal_ref = React.createRef();
        this.frontal_ref = React.createRef();

        this.lower_occlusal_ref = React.createRef();
        this.cephalometric_xray_ref = React.createRef();

        this.right_buccal_ref = React.createRef();
        this.left_buccal_ref = React.createRef();

    }

    /*--------------------------------------------------------------------------------*/
    /* uploading file timer                                             */
    /*--------------------------------------------------------------------------------*/
    timer = (id) => {
        this.setState({
            [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
        });
    };


    /*--------------------------------------------------------------------------------*/
    /*Photos and xrays step submit  */
    /*--------------------------------------------------------------------------------*/
    handleSubmit = (values, { setSubmitting }) => {

        let photos = {
            front_smiling: values.front_smiling,
            front_pose: values.front_pose,
            profile: values.profile,
            upper_occlusal: values.upper_occlusal,
            lower_occlusal: values.lower_occlusal,
            left_buccal: values.left_buccal,
            frontal: values.frontal,
            right_buccal: values.right_buccal
        }

        let xrays = {
            panoramic_xray: values.panoramic_xray,
            cephalometric_xray: values.cephalometric_xray,
        };

        let data = {
            photos: photos,
            xrays: xrays,
        };
        this.props.updatePhotosXraysBite(data)
        this.props.NextStep()
    };

    /* --------------------------------------------------------------------------------*/
    /* Upload image to s3  */
    /* --------------------------------------------------------------------------------*/
    uploadImage = async (image, id, file) => {
        return uploadFile({
            name: `case-${this.state.caseId}/patient-photos/${id}/${new Date().valueOf()}.JPEG`,
            contentType: image.type,
            file: image,
        });
    };

    /* --------------------------------------------------------------------------------*/
    /* upload photos */
    /* --------------------------------------------------------------------------------*/

    UploadPhoto = async (e, id, setFieldValue) => {
        e.persist();
        if (e.target.files[0]) {
            const intervalID = setInterval(this.timer(id), 1000);
            this.setState({
                loading1: true,
                intervalID,
                [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
            });
            let reader = new FileReader();
            reader.addEventListener("load", (evt) => {
                this.setState({
                    id: evt.currentTarget.result,
                });
            });
            reader.readAsDataURL(e.target.files[0]);
            Resizer.imageFileResizer(
                e.target.files[0],
                720,
                1080,
                "JPEG",
                100,
                0,
                async (uri) => {
                    const { key } = await this.uploadImage(uri, id, e.target.files[0]);
                    clearInterval(this.state.intervalID);
                    this.setState({
                        [id]: keyToUrl(key),
                        [`loading_percentage_${id}`]: 100,
                    });
                    setFieldValue(id, keyToUrl(key));
                    setTimeout(() => {
                        this.setState({
                            [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
                            [`loading_percentage_${id}`]: 0,
                        });
                    }, 500);
                },
                "blob"
            );
        }
    };
    /* --------------------------------------------------------------------------------*/
    /* upload photos if from drop zone */
    /* --------------------------------------------------------------------------------*/
    UploadPhotoDropZone = async (e, id, setFieldValue) => {
        if (e) {
            const intervalID = setInterval(this.timer(id), 1000);
            this.setState({
                loading1: true,
                intervalID,
                [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
            });
            let reader = new FileReader();
            reader.addEventListener("load", (evt) => {
                this.setState({
                    id: evt.currentTarget.result,
                });
            });
            reader.readAsDataURL(e);
            Resizer.imageFileResizer(
                e,
                720,
                1080,
                "JPEG",
                100,
                0,
                async (uri) => {
                    const { key } = await this.uploadImage(uri, id, e);
                    clearInterval(this.state.intervalID);
                    this.setState({
                        [id]: keyToUrl(key),
                        [`loading_percentage_${id}`]: 100,
                    });
                    setFieldValue(id, keyToUrl(key));
                    setTimeout(() => {
                        this.setState({
                            [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
                            [`loading_percentage_${id}`]: 0,
                            [`hover_${id}`]: false
                        });
                    }, 500);
                },
                "blob"
            );
        }
    };
    /* --------------------------------------------------------------------------------*/
    /* preventDefault */
    /* --------------------------------------------------------------------------------*/
    preventDefault = (e) => {
        e.preventDefault();
        // e.stopPropagation();
    };
    /* --------------------------------------------------------------------------------*/
    /* drag Over */
    /* --------------------------------------------------------------------------------*/
    dragOver = (e, key) => {
        this.preventDefault(e);
        this.setState(({ [`hover_${key}`]: true }))

    };
    /* --------------------------------------------------------------------------------*/
    /* drag Enter */
    /* --------------------------------------------------------------------------------*/
    dragEnter = (e, key) => {
        this.preventDefault(e);
        this.setState(({ [`hover_${key}`]: true }))
    };
    /* --------------------------------------------------------------------------------*/
    /* drag Leave */
    /* --------------------------------------------------------------------------------*/
    dragLeave = (e, key) => {
        this.preventDefault(e);
        this.setState(({ [`hover_${key}`]: false }))

    };
    /* --------------------------------------------------------------------------------*/
    /* file Drop */
    /* --------------------------------------------------------------------------------*/
    fileDrop = (e, key, setFieldValue) => {
        this.preventDefault(e);
        const files = e.dataTransfer.files;
        if (files.length === 1) {
            this.handleFiles(files, key, setFieldValue);
        } else {
            this.setState(({ [`hover_${key}`]: false }))
            // infoToaster("please add one file ");
        }
    };
    /* --------------------------------------------------------------------------------*/
    /* handle Files */
    /* --------------------------------------------------------------------------------*/
    handleFiles = (files, key, setFieldValue) => {
        const { content } = this.props.languageReducer;

        for (let i = 0; i < files.length; i++) {
            if (this.validateFile(files[i])) {
                this.UploadPhotoDropZone(files[i], key, setFieldValue)
            } else {
                errorToaster(content.file_not_supported, content.Warning);
                this.setState(({ [`hover_${key}`]: false }))

            }

        }
    };
    /* --------------------------------------------------------------------------------*/
    /* validate File */
    /* --------------------------------------------------------------------------------*/
    validateFile = (file) => {
        let extention = file.name.split(".");
        const validTypes = ["png", "gif", "jpeg", "jpg"];
        let extention_lower = file.name.split(".")[extention.length - 1].toLowerCase();
        if (validTypes.indexOf(extention_lower.toLowerCase()) === -1) {
            return false;
        }

        return true;
    };
    /* --------------------------------------------------------------------------------*/
    /* file Input Clicked */
    /* --------------------------------------------------------------------------------*/
    fileInputClicked = (input) => {
        // this[`${input}_ref`].current.click();
    };
    render() {
        const { content } = this.props.languageReducer;
        const { photos, xrays } = this.props.biteReducer
        return (
            <div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        front_smiling: photos.front_smiling,
                        front_pose: photos.front_pose,
                        profile: photos.profile,
                        upper_occlusal: photos.upper_occlusal,
                        lower_occlusal: photos.lower_occlusal,
                        left_buccal: photos.left_buccal,
                        frontal: photos.frontal,
                        right_buccal: photos.right_buccal,

                    }}
                    validationSchema={this.props.userReducer.currentUser.gco_doctor ? PhotosSchema : ""}
                    onSubmit={this.handleSubmit}
                >


                    {({
                        touched,
                        errors,
                        isSubmitting,
                        values,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                    }) => (
                        <Form className="form-material " onSubmit={handleSubmit}>
                            {/* <div className="step-progress-new-case upload-container">
                                {isSubmitting && (
                                    <div className={`loading2`}>
                                        <div className="spinner_2"></div>
                                    </div>
                                )}
                            </div> */}
                            <div id="case-submission" className="bite_steps_container">
                                <div className="main-fix-photos-grid">
                                    {PhotosDataMobileDesktp.map((value, index) => (

                                        <div key={index}>
                                            {value.key && (
                                                <>
                                                    <Card

                                                        onDragOver={(e) => this.dragOver(e, value.key)}
                                                        onDragEnter={(e) => this.dragEnter(e, value.key)}
                                                        onDragLeave={(e) => this.dragLeave(e, value.key)}
                                                        onDrop={(e) => this.fileDrop(e, value.key, setFieldValue)}
                                                        onClick={() => this.fileInputClicked(value.key)}

                                                        className={`cp2  ${this.state[`hover_${value.key}`] ? "c-dragover" : ""} ${values[value.key] ? "c-card-with-img-active" : "c-card-with-img"}`}>
                                                        <CardTitle className="cmb2">
                                                            <div className="card-title-grid">
                                                                <div className="body-text">
                                                                    {values[value.key] ? value.title : this.state[`showProgress_${value.key}`] ? `Uploading ${value.title}` : <span className="not-active-img">{value.title} </span>}
                                                                </div>
                                                                <div>
                                                                    <img src={values[value.key] ? upload_active : upload_not_active} alt={value.key} />
                                                                </div>

                                                            </div>

                                                            {
                                                                value.key === "cephalometric_xray" || !this.props.userReducer.currentUser.gco_doctor && <div className="optional-input">*{content.optional}</div>
                                                            }  </CardTitle>

                                                        <div className="image-overlay-container">
                                                            <div className="image-cassSubmission-mobile">
                                                                <label name={value.key} htmlFor={value.key} className="photos-xrays-img">
                                                                    <CardImg
                                                                        src={
                                                                            values[value.key]
                                                                                ? values[value.key]
                                                                                : value.img
                                                                        }
                                                                        className={`c-card-img cursor-pointer`}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <input
                                                                ref={this[`${value.key}_ref`]}
                                                                type="file"
                                                                id={value.key}
                                                                onChange={(e) => {
                                                                    this.UploadPhoto(e, value.key, setFieldValue);
                                                                }}
                                                                multiple={false}
                                                                className="visually-hidden"
                                                                accept="image/x-png,image/gif,image/jpeg"
                                                            />
                                                            {this.state[`showProgress_${value.key}`] && (
                                                                <div className="overlay-loader">
                                                                    <CircularProgressbar
                                                                        className="circule-progress-bar"

                                                                        value={
                                                                            this.state[
                                                                            `loading_percentage_${value.key}`
                                                                            ]
                                                                        }
                                                                        text={`${this.state[
                                                                            `loading_percentage_${value.key}`
                                                                        ]
                                                                            }%`}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Card>
                                                    <ErrorMessage
                                                        component="div"
                                                        name={value.key}
                                                        className="custom-invalid-feedback m-1"
                                                    />
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <CardFooter className="p-3 container-footer-mobile">

                                <div className="order-save-button">
                                    <Button
                                        className={`btn light-green-btn float-right  submission-btn button-seme-bold-text `}
                                        size="sm"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {content.next} <i className="fas fa-angle-right pl-1"></i>
                                    </Button>{" "}
                                </div>
                            </CardFooter>{" "}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BitePhotosXrays);
