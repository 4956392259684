import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Link } from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  row: {
    position: "absolute",
    bottom: 50,
    left: 0,
    right: 0,
    paddingTop: 10,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flexWrap:"nowrap"

  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    width: "100%",
  },
  container: {
    flexDirection: "row",
    padding: 3,
  },
  field: {
    flexDirection: "row",
  },
  col: {
    flexDirection: "column",
    alignItems: "center",
    width: "32%",
  },
  col_1: {
    flexDirection: "column",
    alignItems: "center",
    width: "32%",
  },
  col_2: {
    flexDirection: "column",
    alignItems: "center",
    width: "36%",
  },
  col_3: {
    flexDirection: "column",
    alignItems: "center",
    width: "32%",
  },
  title: {
    fontSize: 8,
    textAlign: "left",
    color: "#00c7b1",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 50,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footer_text: {
    fontSize: 8,
    color: "gray",
    textDecoration: "none",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: -20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctor: props.doctorInfo,
    };
  }
  render() {
    const { doctor } = this.state;
    const { content } = this.props

    return (
      <Fragment>
        <View style={styles.row} fixed>
          <View style={styles.container}>
            <View style={styles.col_1}>
              <Link
                style={styles.footer_text}
                src="https://www.eonaligner.com/"
              >
                {content.www_eonaligner_com}
              </Link>
            </View>
            <View style={styles.col_2}>
              <View style={styles.field}>
                <Text style={styles.title}>{content.doctor_signature}: </Text>
                <Text style={styles.footer_text}>{doctor.full_name}</Text>
              </View>
            </View>
            <View style={styles.col_3}>
              <View style={styles.field}>
                <Text style={styles.title}>{content.date}: </Text>
                <Text style={styles.footer_text}>
                  {moment(new Date()).format("D MMM YYYY")}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.container}>
            <View style={styles.col}_1>
              <Text style={styles.footer_text}>{content.info_eonaligner_com}</Text>
            </View>
            <View style={styles.col_2}>
              <Text style={styles.footer_text}>
         {content.address_Belgium}
              </Text>
            </View>
            <View style={styles.col_3}>
              {/* <Text style={styles.footer_text}> Tel. +1 888 206 5955</Text> */}
            </View>
          </View>
          <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
        </View>
      </Fragment>
    );
  }
}
