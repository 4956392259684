// SETTINGS CONSTANTS
export const LOGO_BG = "LOGO_BG";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const THEME = "THEME";
export const DIRECTION = "DIRECTION";
export const SIDEBAR_POSITION = "SIDEBAR_POSITION";
export const HEADER_POSITION = "HEADER_POSITION";
export const LAYOUT = "LAYOUT";
export const SIDEBAR_TYPE = "SIDEBAR_TYPE";

// CHAT CONSTANTS
export const SELECTED_CHAT = "SELECTED_CHAT";
export const SEARCH_USER = "SEARCH_USER";
export const MSG_SUBMIT = "MSG_SUBMIT";

// CONTACT CONSTANTS
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CONTACT_DETAILS = "CONTACT_DETAILS";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const SET_VISIBILITY_FILTER = "SET_VISIBILITY_FILTER";
export const FILTER_CONTACT = "FILTER_CONTACT";
export const TOGGLE_STARRED_CONTACT = "TOGGLE_STARRED_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

// EMAIL CONSTANTS
export const STAR_EMAIL = "STAR_EMAIL";
export const IMPORTANT_EMAIL = "IMPORTANT_EMAIL";
export const OPEN_EMAIL = "OPEN_EMAIL";
export const TRASH_EMAIL = "TRASH_EMAIL";
export const ASSIGN_FOLDER = "ASSIGN_FOLDER";
export const ASSIGN_LABEL = "ASSIGN_LABEL";
export const SET_SELECTED_EMAIL = "SET_SELECTED_EMAIL";
export const FILTER_EMAIL = "FILTER_EMAIL";
export const SET_EMAIL_VISIBILITY_FILTER = "SET_EMAIL_VISIBILITY_FILTER";
export const UPDATE_CASE = "UPDATE_CASE";
export const USER_INFO = "USER_INFO";
export const NEW_CASE_USER_INFO = "NEW_CASE_USER_INFO";
export const TREATMENT_PLAN_INFO = "TREATMENT_PLAN_INFO";
export const IMPRESSIONS = "IMPRESSIONS";
export const PHOTOS = "PHOTOS";
export const XRAYS = "XRAYS";
export const SUBMISSION = "SUBMISSION";

//TEETH
export const TOOTH_MOVEMENT_RESTRICTIONS = "TOOTH_MOVEMENT_RESTRICTIONS";
export const DO_NOT_PLACE_ATTACHMENTS = "DO_NOT_PLACE_ATTACHMENTS";
export const EXTRACTION = "EXTRACTION";
export const CROSSBITES = "CROSSBITES";
export const LEAVESPACE = "LEAVESPACE";

export const UPDATE_ADJUSTMENT = "UPDATE_ADJUSTMENT";
export const UPDATE_INBOX = "UPDATE_INBOX";
export const PUSH_MSG = "PUSH_MSG";

export const UPDATE_ATTACHMENT = "UPDATE_ATTACHMENT";
export const CURRENT_CASE = "CURRENT_CASE";
export const RESET_ADJUSTMENT = "RESET_ADJUSTMENT";
export const NUMBERING = "NUMBERING";

//new case submission

export const CASE_TOOTH_MOVEMENT_RESTRICTIONS =
  "CASE_TOOTH_MOVEMENT_RESTRICTIONS";
export const CASE_EXTRACTION = "CASE_EXTRACTION";
export const CASE_CROSSBITES = "CASE_CROSSBITES";
export const CASE_CLASSII = "CASE_CLASSII";
export const CASE_CLASSIII = "CASE_CLASSIII";

export const CASE_ATTACHMENTS = "CASE_ATTACHMENTS";
export const CASE_IPR = "CASE_IPR";
export const CASE_PONTICS = "CASE_PONTICS";
export const CASE_OVERCORRECTION = "CASE_OVERCORRECTION";

export const CASE_PASSIVE_ALIGNERS = "CASE_PASSIVE_ALIGNERS";
export const CASE_TORQUE_ENHANCERS = "CASE_TORQUE_ENHANCERS";
export const CASE_ELASTICS = "CASE_ELASTICS";
export const CASE_ARCH_EXPANSION = "CASE_ARCH_EXPANSION";
//doctors prefrences
export const DOCTOR_PREFERENCES_IPR = "DOCTOR_PREFERENCES_IPR";
export const DOCTOR_PREFERENCES_ATTACHMENTS = "DOCTOR_PREFERENCES_ATTACHMENTS";

export const CASE_STEPS = "CASE_STEPS";
export const CURRENT_STEP = "CURRENT_STEP";
export const PREV_STEPS = "PREV_STEPS";

export const SHOW_ADVANCED = "SHOW_ADVANCED";
export const AVAILABLE_MODULES = "AVAILABLE_MODULES";
export const ACTIVE_MODULES = "ACTIVE_MODULES";
export const ALL_MODULES = "ALL_MODULES";

export const UPDATE_ADVANCED_MODULES = "UPDATE_ADVANCED_MODULES";
export const UPDATE_ALL_ADVANCED_MODULES = "UPDATE_ALL_ADVANCED_MODULES";
export const UPDATE_PHOTOS_XRAYS = "UPDATE_PHOTOS_XRAYS";
export const UPDATE_IMPRESSIONS = "UPDATE_IMPRESSIONS";
export const UPDATE_PRESCRIPTION = "UPDATE_PRESCRIPTION";
export const UPDATE_MALOCCLUSION = "UPDATE_MALOCCLUSION";
export const CLEAR_MALOCCLUSION_DATA = "CLEAR_MALOCCLUSION_DATA";
export const CLEAR_TEETH_DATA = "CLEAR_TEETH_DATA";
export const CLEAR_ADVANCED_MODULES_DATA = "CLEAR_ADVANCED_MODULES_DATA";

export const CLEAR_STEPS = "CLEAR_STEPS";
export const CASE_ELASTICS_BUTTONS = "CASE_ELASTICS_BUTTONS";
export const CASE_ELASTICS_CUTS = "CASE_ELASTICS_CUTS";
export const CASE_CROWDING = "CASE_CROWDING";
export const UPDATE_PATIENT_INFO = "UPDATE_PATIENT_INFO";

export const TEETH_IPR_DATA = "TEETH_IPR_DATA";
export const SAVED_DATA = "SAVED_DATA";
export const PROTOCOL_FEEDBACK = "PROTOCOL_FEEDBACK";
export const REJECTION_REASON = "REJECTION_REASON";
export const CHANGE_TRACKER = "CHANGE_TRACKER";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const UPDATE_CONTENT = "UPDATE_CONTENT";

export const CASE_TEETHMOVEMENT = "CASE_TEETHMOVEMENT";
export const SUBMISSION_SIDE_NAV = "SUBMISSION_SIDE_NAV";

export const PHOTO_POSITION = "PHOTO_POSITION";
export const UNSAVED_CHANGES = "UNSAVED_CHANGES";
export const UNSAVED_DATA = "UNSAVED_DATA";
export const UNSAVED_KEYS = "UNSAVED_KEYS";

export const CASE_RETAINER_STEPS = "CASE_RETAINER_STEPS";
export const PREV_RETAINER_STEPS = "PREV_RETAINER_STEPS";
// export const UNSAVED_CHANGES = "UNSAVED_CHANGES";
// export const UNSAVED_DATA = "UNSAVED_DATA";
// export const UNSAVED_KEYS = "UNSAVED_KEYS";
export const HYBRID_STATE = "HYBRID_STATE";
export const SHOW_TOOTH_MOVEMENT = "SHOW_TOOTH_MOVEMENT";
export const SHOW_IPR_TOOTH_MOVEMENT = "SHOW_IPR_TOOTH_MOVEMENT";

export const RESET_STEPS_DATA = "RESET_STEPS_DATA";
export const RESET_CHANGES = "RESET_CHANGES";
export const FILTER_DATA = "FILTER_DATA";

export const UPDATE_PHOTOS_XRAYS_BITE = "UPDATE_PHOTOS_XRAYS_BITE";
export const UPDATE_IMPRESSIONS_BITE = "UPDATE_IMPRESSIONS_BITE";
export const RESET_BITE = "RESET_BITE";

export const TEETH_IPR_DATA_BY_ID = "TEETH_IPR_DATA_BY_ID";
export const SAVED_DATA_BY_ID = "SAVED_DATA_BY_ID";
export const PROTOCOL_FEEDBACK_BY_ID = "PROTOCOL_FEEDBACK_BY_ID";
export const REJECTION_REASON_BY_ID = "REJECTION_REASON_BY_ID";
export const RESET_ALL_TS_DATA = "RESET_ALL_TS_DATA";
export const STEP_VISITED = "STEP_VISITED";
export const CASE_BY_ID = "CASE_BY_ID";
export const USED_IPR_BY_ID="USED_IPR_BY_ID";
export const TREATMENT_PLAN_ACTION="TREATMENT_PLAN_ACTION";
