import React, { Component } from "react";
import { connect } from "react-redux";

import StepZilla from "react-stepzilla";
// import { Card, CardBody } from "reactstrap";
import AdjustmentInfo from "./AdjustmentInfo";
import Impressions from "../../cases/newCase/Impressions";
import Photos from "../../cases/newCase/Photos";
import {
  updateAdjustment,
  resetAdjustment,
} from "../../../redux/adjutment/action";
import { newAdjustmetn } from "../../../services/newAdjustment";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";

import FixPhotos from "../../case-submission/FixStep/FixPhotos";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdjustment: (payload) => dispatch(updateAdjustment(payload)),
  resetAdjustment: (payload) => dispatch(resetAdjustment(payload)),
});
class Adjustment extends Component {
  /*--------------------------------------------------------------------------------*/
  /* constructor   */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state   */
    this.state = {
      width: window.innerWidth,
      step: 0,
      modal: false,
      rejectable_id: props.rejectable_id ? props.rejectable_id : null,
      rejectable_type: props.rejectable_type ? props.rejectable_type : null,
      sending: false,
    };
    this.adjustmentInfo = this.props.adjustment.adjustmentInfo;
    this.impressions = this.props.adjustment.impressions;
    this.photos = this.props.adjustment.photos;
  }

  /*--------------------------------------------------------------------------------*/
  /* update Dimensions after render all elements   */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
  }
  /*--------------------------------------------------------------------------------*/
  /* update Dimensions if screen size changed   */
  /*--------------------------------------------------------------------------------*/
  componentDidUpdate() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
  }
  /*--------------------------------------------------------------------------------*/
  /* update Dimensions  */
  /*--------------------------------------------------------------------------------*/
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* update Dimensions  */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  /*--------------------------------------------------------------------------------*/
  /* Adjustment Info  */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getStore = () => {
    return this.adjustmentInfo;
  };
  // Update Store
  updateAdjustmentStore = (update) => {
    this.adjustmentInfo = {
      ...this.adjustmentInfo,
      ...update,
    };
    this.props.updateAdjustment({
      ...this.props.adjustment,
      adjustmentInfo: this.adjustmentInfo,
    });
  };

  /*--------------------------------------------------------------------------------*/
  /* Impressions                                                                    */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getImpressionsStore = () => {
    return this.props.adjustment.impressions;
  };

  // Update Store
  updateImpressionsStore = (update) => {
    this.impressions = {
      ...this.impressions,
      ...update,
    };
    this.props.updateAdjustment({
      ...this.props.adjustment,
      impressions: this.impressions,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* Photos                                                                         */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getPhotosStore = () => {
    return this.props.adjustment.photos;
  };

  // Update Store
  updatePhotosStore(update) {
    this.photos = {
      ...this.photos,
      ...update,
    };
    this.props.updateAdjustment({
      ...this.props.adjustment,
      photos: this.photos,
    });
  }

  /*--------------------------------------------------------------------------------*/
  /* Add new Adjustment                                                             */
  /*--------------------------------------------------------------------------------*/
  addNewAdjustment = () => {
    this.setState({ sending: !this.state.sending });
    newAdjustmetn(this.props.DoctorId, this.props.UID, this.props.adjustment)
      .then((res) => {
        if (res === "ok") {
          this.setState({
            modal: !this.state.modal,
            sending: !this.state.sending,
          });
          this.props.SubmitNewAdjustment();
          this.props.resetAdjustment();
        }
      })
      .catch((res) => {
        this.setState({ sending: !this.state.sending });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* remove event listener whin leave component                                     */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    const {case_type}= this.props.caseReducer.case
    /*--------------------------------------------------------------------------------*/
    /* adjustement steps                                     */
    /*--------------------------------------------------------------------------------*/
    const steps = [
      {
        name: "Adjustment Info",
        component: (
          <AdjustmentInfo
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateAdjustmentStore(u);
            }}
            props={this.props}
            caseType={case_type}
          />
        ),
      },
      {
        name: "Impressions",
        component: (
          <Impressions
            getStore={() => this.getImpressionsStore()}
            updateStore={(u) => {
              this.updateImpressionsStore(u);
            }}
            content={this.props.languageReducer.content}
            component="adjustment"
            UID={this.props.UID}
            props={this.props}
            data={this.props}
            jumpToStep={this.jumpToStep}
          />
        ),
      },
      {
        name: "Optional Photos",
        component: (
          <div className="step step1 mt-5 mb-5">
            <FixPhotos
              adjustmentComponent={true}
              component="adjustment"
              updateStore={(u) => {
                this.updatePhotosStore(u);
              }}
            />
          </div>
        ),
      },
    ];
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/* confirmation add new adjustment modal  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          // className="modalx4"
        >
          <ModalHeader toggle={this.toggle}>Attention</ModalHeader>
          <ModalBody>
            If your adjustment request increases the total number of aligners
            over and above the current product package of the active case then a
            charge will be levied for the difference. By clicking accept you
            acknowledge that this adjustment might incur an additional charge.
          </ModalBody>
          <ModalFooter>
            <Row className="m-0 w-100 m-1 justify-content-end">
              <Col xs="4" md="4" lg="4" className="p-2 w-100">
                <Button
                  className=" btn white-btn pull-right cml1 button-seme-bold-text "
                  size="md"
                  color="danger"
                  disabled={this.state.sending}
                  onClick={() => this.setState({ modal: !this.state.modal })}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="8" md="4" lg="4" className="p-2 w-100">
                <Button
                  className="btn light-green-btn pull-right cml1  button-seme-bold-text"
                  size="md"
                  disabled={this.state.sending}
                  onClick={() => this.addNewAdjustment()}
                >
                  Save & Next
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end confirmation add new adjustment modal  */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* adjustement steps with  StepZilla */}
        {/*--------------------------------------------------------------------------------*/}
        <div className="">
          <div className="step-progress-adjustment">
            {steps && window.innerWidth < 1200 ? (
              <div className="case-container">
                {" "}
                <h4 className="control-label text-center title_active current-case">
                  {this.state.step === 3
                    ? steps[2]["name"]
                    : steps[this.state.step]["name"]}
                </h4>
              </div>
            ) : (
              ""
            )}
            <StepZilla
              steps={steps}
              backButtonText="Back"
              nextButtonText="Save & Next"
              onStepChange={(step) => {
                this.setState({ step: step });
              }}
            />
            {(this.state.step === 3 || this.state.step === 2) && (
              <button
                type="button"
                id="finish-button"
                className="btn btn-prev btn-primary btn-lg pull-right button-seme-bold-text"
                onClick={() => {
                  this.setState({ modal: !this.state.modal });
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
        {/*--------------------------------------------------------------------------------*/}
        {/* end adjustement steps with  StepZilla */}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Adjustment);
