import React, { Component } from "react";
import { connect } from "react-redux";


const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});

class Description extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {loyalty} =  this.props.userReducer.currentUser
    const { content } = this.props.languageReducer;

    return (
      <div className="Description-loyalty-container">
        <div className="Description-loyalty-steps body-italic">{content.discount_discount_on_the_price_of_all_aligner_treatments}</div>
        <div className="Description-loyalty-steps body-italic">{content.educational_events_online_and_in_person_events_for_learning_networking_and_collaboration}</div>
        <div className="Description-loyalty-steps body-italic">{content.digital_marketing_support_Take_advantage_of_digital_marketing_support_services_that_help_you_promote_and_grow_your_business_online}</div>
        <div className="Description-loyalty-steps body-italic">{content.expert_orthodontist_consultation_one_on_one_minute_sessions}</div>
        <div className="Description-loyalty-steps body-italic">{content.commercial_business_consultation_One_on_one_personalized}</div>
        <div className="Description-loyalty-steps body-italic">{content.grow_youraligner_business}</div>
        <div className="Description-loyalty-steps body-italic">{content.Dedicated_clinical_specialist_CAD_designer}</div>
        <div className="Description-loyalty-steps body-italic">{content.they_assess_case_history}</div>
        <div className="Description-loyalty-steps body-italic">{content.less_modifications_needed}</div>
        <div className="Description-loyalty-steps body-italic">{content.In_clinic_marketing_kit_A_complete_marketing_toolkit_to}</div>
        <div className="Description-loyalty-steps body-italic">{content.testimonials_and_ready_to_use}</div>
        <div className="Description-loyalty-steps body-italic">{content.Practice_plaque_plaque_awardedby_Eon_to_recognize_and_celebrate_the_top_tiers}</div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Description);
