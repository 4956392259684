import React, { Component } from "react";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import axios from "axios";
import { GetCountryLabel } from "../../helpers/countries";
const mapStateToProps = (state) => ({
  ...state,
});
class InfoBip extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      person_id: 0,
      auth_person: false,
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* componentDidMount */
  /* --------------------------------------------------------------------------------*/
  componentDidMount = async () => {
    await this.authChat();
    // await this.personProfile();
  };
  delay = (ms) => new Promise((res) => setTimeout(res, ms));
  personProfile = async () => {
    await this.delay(5000);
    console.log("Waited 5s");
    //await this.getPersonIdInInfoBip();

    await this.delay(5000);
    console.log("Waited an additional 5s");
    await this.updatePersonDataInInfoBip();
  };

  /* --------------------------------------------------------------------------------*/
  /* generate Random String with max length */
  /* --------------------------------------------------------------------------------*/
  generateRandomString = (maxLength) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    const length = Math.floor(Math.random() * maxLength) + 1; // Generates a random length between 1 and maxLength

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  };

  /* --------------------------------------------------------------------------------*/
  /* prep payload */
  /* --------------------------------------------------------------------------------*/
  prepPayload = () => {
    const { email } = this.props.userReducer.currentUser;

    //  securityKey
    const securityKey = JSON.parse(process.env.REACT_APP_INFOBIP_SECURITY_KEY);

    //  set props
    const d = Date.now() - 10 * 1000 * 60;
    let iat = Math.round(d / 1000);
    let exp = iat + 10 * 2000 * 60;
    var payload = {
      jti: this.generateRandomString(50),
      stp: "email",
      sub: email,
      iss: process.env.REACT_APP_INFOBIP_WIDGET_ID,
      iat: iat,
      exp: exp,
      ski: securityKey.id,
      sid: localStorage.getItem("token"),
    };
    return payload;
  };
  /* --------------------------------------------------------------------------------*/
  /* authChat */
  /* --------------------------------------------------------------------------------*/
  authChat = async () => {
    let that = this;
    //  securityKey
    const securityKey = JSON.parse(process.env.REACT_APP_INFOBIP_SECURITY_KEY);

    const payload = this.prepPayload();
    // get token
    var token = jwt.sign(payload, Buffer.from(securityKey.key, "base64"));
    // auth chat
    window.liveChat("auth", token, function (error, result) {
      if (error) {
        console.log(error.code, error.message);
        if (error.code !== 1121) {
          setTimeout(() => {
            //  window.liveChat("logout", () => {});
            that.authChat();
          }, 5000);
        }
      } else {
        console.log({ result });
        setTimeout(() => {
          that.updatePersonDataInInfoBip();
        }, 5000);
      }
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* get Person Id In InfoBip */
  /* --------------------------------------------------------------------------------*/
  getPersonIdInInfoBip = async () => {
    const { email } = this.props.userReducer.currentUser;

    const API_KEY = process.env.REACT_APP_INFOBIP_API_KEY;
    const Base_url = process.env.REACT_APP_INFOBIP_BASE_URL;

    const filter = `?email=${encodeURIComponent(email)}`;
    await axios
      .get(`${Base_url}${filter}`, {
        headers: {
          Authorization: `App ${API_KEY}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({ person_id: res.data.id });
        //  this.updatePersonDataInInfoBip();
      })
      .catch((error) => {
        console.error("Error:", error.response.data);
      });
  };
  /* --------------------------------------------------------------------------------*/
  /* update person data in InfoBip */
  /* --------------------------------------------------------------------------------*/
  updatePersonDataInInfoBip = async () => {
    // const { person_id } = this.state;
    const { first_name, last_name, email, country } =
      this.props.userReducer.currentUser;

    const partialUpdateData = {
      firstName: first_name,
      lastName: last_name,
      type: "CUSTOMER",
      country: GetCountryLabel(country),
      contactInformation: {
        email: [
          {
            address: email,
          },
        ],
      },
    };
    const API_KEY = process.env.REACT_APP_INFOBIP_API_KEY;
    const Base_url = process.env.REACT_APP_INFOBIP_BASE_URL;
    const filter = `?email=${encodeURIComponent(email)}`;

    await axios
      .patch(`${Base_url}${filter}`, partialUpdateData, {
        headers: {
          Authorization: `App ${API_KEY}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Partial Person Update Successful");
        this.setState({ auth_person: true });
      })
      .catch((error) => {
        console.error("Error:", error.response.data);
        this.setState({ auth_person: false });
      });
  };
  render() {
    return <></>;
  }
}

export default connect(mapStateToProps)(InfoBip);
