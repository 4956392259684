import {
  initialAdvancedModulesState,
  isEmptyObject,
} from "@eon-dental/advanced-modules/dist/rollup-export";
import { modules } from "../redux/advanced/modules";
import { upper_teeth, lower_teeth } from "../redux/newCase/init_teeth";
import { mapAdvancedToApi } from "./mapAdvancedToApi";
import { mapTeeth } from "./mapTeeth";
import { mapTeethToApi } from "./mapTeethToApi";

export function newMapDataWithReducer(
  dataFromApi,
  hybridKey,

  gco_doctor
) {
  let hybrid = hybridKey || false;
  let media = dataFromApi.media;
  let data = {
    id: dataFromApi.id,
    uid: dataFromApi.uid || "",
    step: dataFromApi.step,
    can_order_adjustment: dataFromApi.can_order_adjustment,
    submitted_at: dataFromApi.submitted_at,
    related_aligner_cases: dataFromApi?.related_aligner_cases || [],
    parent_aligner_case: dataFromApi?.parent_aligner_case || {},
    auto_expires_at: dataFromApi?.auto_expires_at || "",
    history: dataFromApi.history,
    stages: dataFromApi.stages,
    status: dataFromApi.display_status || dataFromApi.status || "",
    is_archived: dataFromApi.is_archived,
    case_type: dataFromApi.case_type,
    address: dataFromApi.address || {},
    pickup_address: dataFromApi.pickup_address || {},
    additional_stages: dataFromApi.additional_stages,
    rejections: dataFromApi.rejections,
    adjustments: dataFromApi.adjustments,
    adjustment_rejections: dataFromApi.adjustment_rejections,
    userInfo: userInfo_data(dataFromApi),
    upgrades: dataFromApi.upgrades,
    patient_info: patinet_info_data(dataFromApi, hybrid),
    treatmentPlan: {
      tab1: false,
      tab2: true,
      tab3: true,
      clinical_findings: clinical_findings_data(
        dataFromApi.clinical_findings,
        hybrid
      ),
      treatment_goals: treatment_goals_data(
        dataFromApi.treatment_goals,
        hybrid
      ),
      summary_and_special_instructions: summary_and_special_instructions_data(
        dataFromApi,
        hybrid
      ),
      express: express_data(dataFromApi, hybrid),
    },
    impressions: impressions_data(dataFromApi, media, hybrid),
    photos: photos_data(media, hybrid),
    xrays: xrays_data(media, hybrid),
    submission: {
      acceptTerms: false,
      savedToCloud: false,
      validateTerms: false,
    },
    photos_xrays: photos_xrays_data(media, hybrid),
    impressions_new: impressions_data_new(dataFromApi, media, hybrid),
    prescription: prescription_data(
      dataFromApi,
      hybrid,
      dataFromApi.selected_doctor_preferences
    ),
    advanced_modules:
      dataFromApi.selected_doctor_preferences.version == 2
        ? isEmptyObject(dataFromApi?.advanced_modules)
          ? initialAdvancedModulesState
          : dataFromApi?.advanced_modules
        : advanced_data(
            dataFromApi.advanced_modules,
            hybrid,
            dataFromApi.selected_doctor_preferences,
            gco_doctor
          ),
    isNewAdvancedModules:
      dataFromApi?.selected_doctor_preferences?.version == 2,
  };

  return data;
}
function mapModules(data) {
  for (let x = 0; x < modules.length; x++) {
    var module = data?.filter((value) => {
      return value.key === modules[x].key;
    });

    modules[x].active = module[0]?.active || false;
  }

  return modules;
}

function mapModulesWithDoctorPref(gco_doctor) {
  if (!gco_doctor) {
    for (let x = 0; x < modules.length; x++) {
      if (
        modules[x].key === "malocclusion" ||
        modules[x].key === "extraction" ||
        modules[x].key === "biteRamps" ||
        modules[x].key === "toothSizeDiscrepancy" ||
        modules[x].key === "elastics" ||
        modules[x].key === "archExpansion" ||
        modules[x].key === "torqueEnhancers" ||
        modules[x].key === "teethMovement"
      ) {
        modules[x].active = false;
      } else {
        modules[x].active = true;
      }
    }
  }
  if (gco_doctor) {
    for (let x = 0; x < modules.length; x++) {
      if (
        modules[x].key === "malocclusion" ||
        modules[x].key === "extraction" ||
        modules[x].key === "overCorrection" ||
        modules[x].key === "biteRamps" ||
        modules[x].key === "toothSizeDiscrepancy" ||
        modules[x].key === "elastics" ||
        modules[x].key === "archExpansion" ||
        modules[x].key === "pontics" ||
        modules[x].key === "torqueEnhancers" ||
        modules[x].key === "teethMovement"
      ) {
        modules[x].active = false;
      } else {
        modules[x].active = true;
      }
    }
  }
  return modules;
}

/* --------------------------------------------------------------------------------*/
/* map clinical findings data */
/* --------------------------------------------------------------------------------*/
function userInfo_data(data) {
  return {
    caseType: data.case_type,
    express_api_v2_case: data.express_api_v2_case,
  };
}
function patinet_info_data(data, hybrid) {
  return {
    date_of_birth: data.patient?.dob ? new Date(data.patient.dob) : "",
    first_name: data.patient?.first_name || "",
    gender: data.patient?.gender || "",
    last_name: data.patient?.last_name || "",
    email: data.patient?.email || "",
    case_type: data.case_type || "",
    address_id: data.address?.id || "",
    photo: data.patient?.photo,
    full_name: data.patient?.full_name || "",
  };
}

/* --------------------------------------------------------------------------------*/
/* map clinical findings data */
/* --------------------------------------------------------------------------------*/
function clinical_findings_data(data) {
  return {
    skeletal_1: data?.skeletal === "I",
    skeletal_2: data?.skeletal === "II",
    skeletal_3: data?.skeletal === "III",
    canine_r_1: data?.canine?.class1?.R === "true",
    canine_r_2: data?.canine?.class2?.R === "true",
    canine_r_3: data?.canine?.class3?.R === "true",
    canine_l_1: data?.canine?.class1?.L === "true",
    canine_l_2: data?.canine?.class2?.L === "true",
    canine_l_3: data?.canine?.class3?.L === "true",
    molar_r_1: data?.molar?.class1?.R === "true",
    molar_r_2: data?.molar?.class2?.R === "true",
    molar_r_3: data?.molar?.class3?.R === "true",
    molar_l_1: data?.molar?.class1?.L === "true",
    molar_l_2: data?.molar?.class2?.L === "true",
    molar_l_3: data?.molar?.class3?.L === "true",
    upper_midline_center: data?.upper_midline === "centered",
    upper_midline_shifted_left: data?.upper_midline === "shifted_left",
    upper_midline_shifted_right: data?.upper_midline === "shifted_right",
    lower_midline_center: data?.upper_midline === "centered",
    lower_midline_shifted_left: data?.upper_midline === "shifted_left",
    lower_midline_shifted_right: data?.upper_midline === "shifted_right",
    chief_complaint: data ? data?.chief_complaint : "",
    upper_midline_displacement: data ? data.upper_midline_displacement : 0,
    lower_midline_displacement: data ? data.lower_midline_displacement : 0,
    skeletal: data ? data.skeletal : "",
    upper_midline: data ? data.upper_midline : "",
    lower_midline: data ? data.lower_midline : "",
    Tooth_Movement_Restrictions: data
      ? mapTeeth(data.restriction)
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
    Do_not_place_attachments: data
      ? data.no_attachment
        ? mapTeeth(data.no_attachment)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
  };
}
/* --------------------------------------------------------------------------------*/
/* map treatment goals data */
/* --------------------------------------------------------------------------------*/
function treatment_goals_data(data) {
  return {
    treat_arches_upper: data?.treat_arches === "upper_only",

    treat_arches_lower: data?.treat_arches === "lower_only",

    treat_arches_both: data?.treat_arches === "both",

    midline_upper_maintain: data?.upper_midline === "maintain",

    midline_upper_improve: data?.upper_midline === "improve",

    midline_upper_center: data?.upper_midline === "center",

    midline_lower_maintain: data?.lower_midline === "maintain",

    midline_lower_improve: data?.lower_midline === "improve",

    midline_lower_center: data?.lower_midline === "center",
    overjet_maintain: data?.overjet === "maintain",
    overjet_improve: data?.overjet === "improve",
    overjet_Ideal: data?.overjet === "ideal",
    overbite_maintain: data?.overbite === "maintain",
    overbite_improve: data?.overbite === "improve",
    overbite_Ideal: data?.overbite === "ideal",
    arch_form_maintain: data?.arch_form === "maintain",
    arch_form_improve: data?.arch_form === "improve",
    arch_form_constrict: data?.arch_form === "constrict",
    procline_upper_primary: data?.resolve_crowding?.procline?.U === "true",
    procline_lower_primary: data?.resolve_crowding?.procline?.L === "true",
    procline_upper_if_needed: data?.resolve_crowding?.procline_in?.U === "true",
    procline_lower_if_needed: data?.resolve_crowding?.procline_in?.L === "true",
    expand_upper_primary: data?.resolve_crowding?.expand?.U === "true",
    expand_lower_primary: data?.resolve_crowding?.expand?.L === "true",
    expand_upper_if_needed: data?.resolve_crowding?.expand_in?.U === "true",
    expand_lower_if_needed: data?.resolve_crowding?.expand_in?.L === "true",
    ipr_upper_primary: data?.resolve_crowding?.ipr?.U === "true",
    ipr_lower_primary: data?.resolve_crowding?.ipr?.L === "true",
    ipr_upper_if_needed: data?.resolve_crowding?.ipr_in?.U === "true",
    ipr_lower_if_needed: data?.resolve_crowding?.ipr_in?.L === "true",
    canine_r_1: data?.canine?.class1?.R === "true",
    canine_r_2: data?.canine?.class2?.R === "true",
    canine_r_3: data?.canine?.class3?.R === "true",
    canine_l_1: data?.canine?.class1?.L === "true",
    canine_l_2: data?.canine?.class2?.L === "true",
    canine_l_3: data?.canine?.class3?.L === "true",
    molar_r_1: data?.molar?.class1?.R === "true",
    molar_r_2: data?.molar?.class2?.R === "true",
    molar_r_3: data?.molar?.class3?.R === "true",

    molar_l_1: data?.molar?.class1?.L === "true",
    molar_l_2: data?.molar?.class2?.L === "true",
    molar_l_3: data?.molar?.class3?.L === "true",
    goal_upper_ipr: data?.ap_goal?.ipr36?.U === "true",
    goal_lower_ipr: data?.ap_goal?.ipr36?.L === "true",
    goal_upper_distalization: data?.ap_goal?.distalization?.U === "true",
    goal_lower_distalization: data?.ap_goal?.distalization?.L === "true",
    goal_upper_mezialization: data?.ap_goal?.mezialization?.U === "true",
    goal_lower_mezialization: data?.ap_goal?.mezialization?.L === "true",
    treat_arches: data ? data.treat_arches : "",
    upper_midline: data ? data.upper_midline : "",
    lower_midline: data ? data.lower_midline : "",
    midline: {
      U: "",
      L: "",
    },
    overjet: data ? data.overjet : "",
    overbite: data ? data.overbite : "",
    arch_form: data ? data.arch_form : "",
    Extraction: data
      ? data.extraction
        ? mapTeeth(data.extraction)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
    Crossbites: data
      ? data.crossbites
        ? mapTeeth(data.crossbites)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
    LeaveSpace: data
      ? data.leave_space
        ? mapTeeth(data.leave_space)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
  };
}
/* --------------------------------------------------------------------------------*/
/* map summary and special instructions data */
/* --------------------------------------------------------------------------------*/
function summary_and_special_instructions_data(data) {
  return {
    treatment_summary: data.treatment_summary,
    special_instructions: data.special_instructions,
  };
}
/* --------------------------------------------------------------------------------*/
/* map express data  */
/* --------------------------------------------------------------------------------*/
function express_data(data) {
  return {
    chief_complaint: data.clinical_findings
      ? data.clinical_findings.chief_complaint
      : "",
    treatment_summary: data.treatment_summary,
  };
}
/* --------------------------------------------------------------------------------*/
/* map impressions data */
/* --------------------------------------------------------------------------------*/
function impressions_data(dataFromApi, media, fix) {
  return {
    Digital_Impression: dataFromApi.impressions_method === "upload",
    Digital_Means: dataFromApi.impressions_method === "third_party",
    Arrange_Pickup: dataFromApi.impressions_method === "courier",
    upperImageValue: fix
      ? ""
      : media.impressions.upper_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? media.impressions.upper_impression
      : "",
    lowerImageValue: fix
      ? ""
      : media.impressions.lower_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? media.impressions.lower_impression
      : "",
    additional_comments: dataFromApi.impressions_third_party_note || "",
    pickup_address: dataFromApi?.pickup_address || "",
    sets: dataFromApi.sets || 0,
    ArchImpressionUpper: dataFromApi.ArchImpressionUpper || false,
    ArchImpressionLower: dataFromApi.ArchImpressionLower || false,
    impressions_method_Arch: dataFromApi.impressions_method_Arch || "",
    Impression_Type: dataFromApi.impressions_method || "",
    upperImage: fix
      ? false
      : media.impressions.upper_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? true
      : false,
    lowerImage: fix
      ? false
      : media.impressions.lower_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? true
      : false,
  };
}

/* --------------------------------------------------------------------------------*/
/* map impressions data */
/* --------------------------------------------------------------------------------*/
function impressions_data_new(dataFromApi, media, hybrid) {
  return {
    impressions_method: hybrid ? "" : dataFromApi.impressions_method || "",
    impressions_third_party_note: hybrid
      ? ""
      : dataFromApi.impressions_third_party_note || "",
    pickup_address_id: dataFromApi?.pickup_address?.id || "",
    pickup_address: dataFromApi?.pickup_address || {},

    retainer_request_sets: hybrid ? "" : dataFromApi.retainer_request_sets || 0,
    arch_impression_upper: hybrid
      ? ""
      : dataFromApi.arch_impression_upper || false,
    arch_impression_lower: hybrid
      ? ""
      : dataFromApi.arch_impression_lower || false,
    impressions_method_arch: hybrid
      ? ""
      : dataFromApi.impressions_method_arch || "",
    upper_arch_url: hybrid
      ? ""
      : media.impressions.upper_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? media.impressions.upper_impression
      : "",
    lower_arch_url: hybrid
      ? ""
      : media.impressions.lower_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? media.impressions.lower_impression
      : "",
  };
}
/* --------------------------------------------------------------------------------*/
/* map photos data */
/* --------------------------------------------------------------------------------*/
function photos_data(media) {
  return {
    front_smiling_photo: media.patient_photos.front_smiling || "",
    front_smiling: media.patient_photos.front_smiling || "",
    front_smiling_image: media.patient_photos.front_smiling ? true : false,
    front_pose_photo: media.patient_photos.front_pose || "",
    front_pose: media.patient_photos.front_pose || "",
    front_pose_image: media.patient_photos.front_pose ? true : false,
    profile_photo: media.patient_photos.profile || "",
    profile: media.patient_photos.profile || "",
    profile_image: media.patient_photos.profile ? true : false,
    upper_occlusal_photo: media.patient_photos.upper_occlusal || "",
    upper_occlusal: media.patient_photos.upper_occlusal || "",
    upper_occlusal_image: media.patient_photos.upper_occlusal ? true : false,
    lower_occlusal_photo: media.patient_photos.lower_occlusal || "",
    lower_occlusal: media.patient_photos.lower_occlusal || "",
    lower_occlusal_image: media.patient_photos.lower_occlusal ? true : false,
    left_buccal_photo: media.patient_photos.left_buccal || "",
    left_buccal: media.patient_photos.left_buccal || "",
    left_buccal_image: media.patient_photos.left_buccal ? true : false,
    frontal_photo: media.patient_photos.frontal || "",
    frontal: media.patient_photos.frontal || "",
    frontal_image: media.patient_photos.frontal ? true : false,
    right_buccal_photo: media.patient_photos.right_buccal || "",
    right_buccal: media.patient_photos.right_buccal || "",
    right_buccal_image: media.patient_photos.right_buccal ? true : false,
  };
}
/* --------------------------------------------------------------------------------*/
/* map xrays data */
/* --------------------------------------------------------------------------------*/
function xrays_data(media) {
  return {
    panoramic_photo: media.xrays.panoramic || "",
    panoramic: media.xrays.panoramic || "",
    panoramic_image: media.xrays.panoramic ? true : false,
    cephalometric_photo: media.xrays.cephalometric || "",
    cephalometric: media.xrays.cephalometric || "",
    cephalometric_image: media.xrays.cephalometric ? true : false,
  };
}

function photos_xrays_data(media, hybrid) {
  return {
    front_smiling: hybrid ? "" : media.patient_photos.front_smiling || "",
    front_pose: hybrid ? "" : media.patient_photos.front_pose || "",
    profile: hybrid ? "" : media.patient_photos.profile || "",
    upper_occlusal: hybrid ? "" : media.patient_photos.upper_occlusal || "",
    lower_occlusal: hybrid ? "" : media.patient_photos.lower_occlusal || "",
    left_buccal: hybrid ? "" : media.patient_photos.left_buccal || "",
    frontal: hybrid ? "" : media.patient_photos.frontal || "",
    right_buccal: hybrid ? "" : media.patient_photos.right_buccal || "",
    panoramic: hybrid ? "" : media.xrays.panoramic || "",
    cephalometric: hybrid ? "" : media.xrays.cephalometric || "",
  };
}

function prescription_data(data, hybrid, newDoctorPref) {
  return {
    chief_complaint: hybrid
      ? ""
      : data.clinical_findings
      ? data.clinical_findings?.chief_complaint
      : "",
    summary_and_special_instructions: hybrid
      ? ""
      : data
      ? data.treatment_summary
      : "",
    treat_arches: hybrid
      ? ""
      : data
      ? data.treatment_goals
        ? data.treatment_goals.treat_arches
        : ""
      : "",
    // cuze wear_cycle it come with value from Api("regular") and we want to force it to empty for the first time to  take value from doctor preferences .
    wear_cycle: hybrid ? "" : data ? data.wear_cycle : newDoctorPref.wear_cycle,
    wear_cycle_saved: newDoctorPref.wear_cycle || "regular",

    case_type: hybrid ? "" : data ? data?.case_type : "",
    restriction: hybrid
      ? {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        }
      : data.clinical_findings
      ? data.clinical_findings?.restriction
        ? mapTeeth(data.clinical_findings?.restriction)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
  };
}

function advanced_data(newData, hybrid, newDoctorPref, gco_doctor) {
  let data = hybrid ? null : newData;

  return {
    all_modules: data
      ? data.all_modules
        ? mapModules(hybrid ? [] : data.all_modules)
        : mapModulesWithDoctorPref(gco_doctor)
      : mapModulesWithDoctorPref(gco_doctor),

    treatment_type: data?.treatment_type
      ? data.treatment_type
      : {
          type: "aligner",
          hybrid_treatment: {},
        },
    malocclusion: {
      crossbite_btn: data?.malocclusion?.crossbite_btn || false,
      spacing_btn: data?.malocclusion?.spacing_btn || false,
      classII_btn: data?.malocclusion?.classII_btn || false,
      classIII_btn: data?.malocclusion?.classIII_btn || false,
      openbite_btn: data?.malocclusion?.openbite_btn || false,
      deepbite_btn: data?.malocclusion?.deepbite_btn || false,
      midline_btn: data?.malocclusion?.midline_btn || false,
      crowding_btn: data?.malocclusion?.crowding_btn || false,
      crossbite: data?.malocclusion?.crossbite
        ? {
            useElasticWhenCorrecting:
              data.malocclusion.crossbite.useElasticWhenCorrecting,
            teeth: mapTeeth(data.malocclusion.crossbite.teeth),
            treatment_Options: {
              expansion:
                data.malocclusion.crossbite.treatment_Options.expansion,
              constriction:
                data.malocclusion.crossbite.treatment_Options.constriction,
              combination:
                data.malocclusion.crossbite.treatment_Options.combination,
              elastics: data.malocclusion.crossbite.treatment_Options.elastics,
            },
          }
        : {
            useElasticWhenCorrecting: "eonToDecide",
            teeth: mapTeeth({
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            }),
            treatment_Options: {
              expansion: false,
              constriction: false,
              combination: false,
              elastics: false,
            },
          },
      crowding: data?.malocclusion?.crowding
        ? {
            use_extraction: data.malocclusion.crowding.use_extraction,
            teeth: mapTeeth(data.malocclusion.crowding.teeth),
            treatment_Options: {
              proclination:
                data.malocclusion.crowding.treatment_Options.proclination,
              expansion: data.malocclusion.crowding.treatment_Options.expansion,
              ipr: data.malocclusion.crowding.treatment_Options.ipr,
              distalisation:
                data.malocclusion.crowding.treatment_Options.distalisation,
            },
          }
        : {
            use_extraction: "eonToDecide",
            teeth: mapTeeth({
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            }),
            treatment_Options: {
              proclination: false,
              expansion: false,
              ipr: false,
              distalisation: false,
            },
          },
      spacing: data?.malocclusion?.spacing
        ? {
            closeAllSpacesToggle:
              data.malocclusion.spacing.closeAllSpacesToggle,
            closeAllSpaces: {
              byRetractionOfAnteriors:
                data.malocclusion.spacing.closeAllSpaces
                  .byRetractionOfAnteriors,
              byMesialisation:
                data.malocclusion.spacing.closeAllSpaces.byMesialisation,
              both: data.malocclusion.spacing.closeAllSpaces.both,
            },
            partiallyClose: data.malocclusion.spacing.partiallyClose,
            IPRopposing: data.malocclusion.spacing.IPRopposing,
          }
        : {
            closeAllSpacesToggle: false,
            closeAllSpaces: {
              byRetractionOfAnteriors: false,
              byMesialisation: false,
              both: false,
            },
            partiallyClose: false,
            IPRopposing: false,
          },
      classII: data?.malocclusion?.classII
        ? {
            useElasticWhenCorrecting:
              data.malocclusion.classII?.useElasticWhenCorrecting ||
              "eonToDecide",
            teeth: mapTeeth(data.malocclusion.classII.teeth),
            extractionTwoUpperBicuspids:
              data.malocclusion.classII.extractionTwoUpperBicuspids,
            extractionUpperLowerBicuspids:
              data.malocclusion.classII.extractionUpperLowerBicuspids,
            upperArchDistalization:
              data.malocclusion.classII.upperArchDistalization,
            lowerArchMesialization:
              data.malocclusion.classII.lowerArchMesialization,
            leavingMolars: data.malocclusion.classII.leavingMolars,
            maintain: data.malocclusion.classII.maintain,
          }
        : {
            useElasticWhenCorrecting: "eonToDecide",
            teeth: mapTeeth({
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            }),
            extractionTwoUpperBicuspids: false,
            extractionUpperLowerBicuspids: false,
            upperArchDistalization: false,
            lowerArchMesialization: false,
            leavingMolars: false,
            maintain: false,
          },
      classIII: data?.malocclusion?.classIII
        ? {
            useElasticWhenCorrecting:
              data.malocclusion.classIII?.useElasticWhenCorrecting ||
              "eonToDecide",
            teeth: mapTeeth(data.malocclusion.classIII.teeth),
            advanceUppers: data.malocclusion.classIII.advanceUppers,
            retractLowersExtraction:
              data.malocclusion.classIII.retractLowersExtraction,
            retractLowersIPR: data.malocclusion.classIII.retractLowersIPR,
            estheticAlignmentOnly:
              data.malocclusion.classIII.estheticAlignmentOnly,
            preSurgicalAlignment:
              data.malocclusion.classIII.preSurgicalAlignment,
          }
        : {
            useElasticWhenCorrecting: "eonToDecide",
            teeth: mapTeeth({
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            }),
            advanceUppers: false,
            retractLowersExtraction: false,
            retractLowersIPR: false,
            estheticAlignmentOnly: false,
            preSurgicalAlignment: false,
          },
      openbite: data?.malocclusion?.openbite
        ? {
            extrudeAnterior: data.malocclusion.openbite.extrudeAnterior,
            intrudePosterior: data.malocclusion.openbite.intrudePosterior,
            both: data.malocclusion.openbite.both,
          }
        : {
            extrudeAnterior: false,
            intrudePosterior: false,
            both: false,
          },
      deepbite: data?.malocclusion?.deepbite
        ? {
            intrudeUpperAnteriors:
              data.malocclusion.deepbite.intrudeUpperAnteriors,
            intrudeLowerAnteriors:
              data.malocclusion.deepbite.intrudeLowerAnteriors,
            combination: data.malocclusion.deepbite.combination,
            extrudePremolars: data.malocclusion.deepbite.extrudePremolars,
          }
        : {
            intrudeUpperAnteriors: false,
            intrudeLowerAnteriors: false,
            combination: false,
            extrudePremolars: false,
          },
      midline: data?.malocclusion?.midline
        ? {
            upperMidline: {
              upperMidline: data.malocclusion.midline.upperMidline.upperMidline,
              upperMidline_displacement:
                data.malocclusion.midline.upperMidline
                  .upperMidline_displacement,
            },
            lowerMidline: {
              lowerMidline: data.malocclusion.midline.lowerMidline.lowerMidline,
              lowerMidline_displacement:
                data.malocclusion.midline.lowerMidline
                  .lowerMidline_displacement,
            },
            treatMidlineUpper: {
              resultMidlineAlignment:
                data.malocclusion?.midline?.treatMidlineUpper
                  ?.resultMidlineAlignment,
              maintain: data.malocclusion?.midline?.treatMidlineUpper?.maintain,
              improveIPR:
                data.malocclusion?.midline?.treatMidlineUpper?.improveIPR,
              improveExtraction:
                data.malocclusion?.midline?.treatMidlineUpper
                  ?.improveExtraction,
            },
            treatMidlineLower: {
              resultMidlineAlignment:
                data.malocclusion?.midline?.treatMidlineLower
                  ?.resultMidlineAlignment,
              maintain: data.malocclusion?.midline?.treatMidlineLower?.maintain,
              improveIPR:
                data.malocclusion?.midline?.treatMidlineLower?.improveIPR,
              improveExtraction:
                data.malocclusion?.midline?.treatMidlineLower
                  ?.improveExtraction,
            },
          }
        : {
            upperMidline: {
              upperMidline: "",
              upperMidline_displacement: "",
            },
            lowerMidline: {
              lowerMidline: "",
              lowerMidline_displacement: "",
            },
            treatMidlineUpper: {
              resultMidlineAlignment: false,
              maintain: false,
              improveIPR: false,
              improveExtraction: false,
            },
            treatMidlineLower: {
              resultMidlineAlignment: false,
              maintain: false,
              improveIPR: false,
              improveExtraction: false,
            },
          },
    },
    extraction: data?.extraction
      ? {
          use_extraction: data.extraction.use_extraction,
          teeth: mapTeeth(data.extraction.teeth),
        }
      : {
          use_extraction: "eonToDecide",
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        },

    ipr: data?.ipr
      ? {
          useIpr: data.ipr.useIpr,
          iprTiming: data.ipr.iprTiming,
          steps: data.ipr?.steps || [{ step: 0 }],
          iprRestriction: mapTeeth(data.ipr.iprRestriction),
        }
      : newDoctorPref.ipr
      ? {
          useIpr:
            newDoctorPref.ipr.use_ipr === true ||
            newDoctorPref.ipr.use_ipr === false
              ? newDoctorPref.ipr.use_ipr
              : "eonToDecide",

          iprTiming: newDoctorPref.ipr.iprTiming || "",
          steps: newDoctorPref.ipr.ipr_steps || [{ step: 0 }],
          iprRestriction: mapTeeth(newDoctorPref.ipr.iprRestriction),
        }
      : gco_doctor
      ? {
          useIpr: "eonToDecide",
          iprTiming: "",
          steps: [{ step: 0 }],
          iprRestriction: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        }
      : {
          useIpr: "eonToDecide",
          iprTiming: "",
          steps: [{ step: 0 }],
          iprRestriction: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        },

    attachments: data?.attachments
      ? {
          useAttachments: data.attachments.useAttachments,
          attachmentsTiming: data.attachments.attachmentsTiming,
          steps: data.attachments.steps || [{ step: 0 }],
          attachmentsRestriction: mapTeeth(
            data.attachments.attachmentsRestriction
          ),
        }
      : newDoctorPref.attachments
      ? {
          useAttachments:
            newDoctorPref.attachments.use_attachments === true ||
            newDoctorPref.attachments.use_attachments === false
              ? newDoctorPref.attachments.use_attachments
              : "eonToDecide",
          attachmentsTiming: newDoctorPref.attachments.attachmentsTiming || "",
          steps: newDoctorPref.attachments.attachments_steps || [{ step: 0 }],
          attachmentsRestriction: mapTeeth(
            newDoctorPref.attachments.attachmentsRestriction
          ),
        }
      : gco_doctor
      ? {
          useAttachments: "eonToDecide",
          attachmentsTiming: "",
          steps: [{ step: 0 }],
          attachmentsRestriction: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        }
      : {
          useAttachments: "eonToDecide",
          attachmentsTiming: "",
          steps: [{ step: 0 }],
          attachmentsRestriction: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        },

    pontics: data?.pontics
      ? {
          usePontics: data.pontics.usePontics,
          teeth: mapTeeth(data.pontics.teeth),
        }
      : newDoctorPref?.pontics !== undefined
      ? {
          usePontics:
            newDoctorPref.pontics === true || newDoctorPref.pontics === false
              ? newDoctorPref.pontics
              : "eonToDecide",
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        }
      : gco_doctor
      ? {
          usePontics: "eonToDecide",
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        }
      : {
          usePontics: "eonToDecide",
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        },
    overCorrection: data?.overCorrection
      ? {
          useOverCorrection: data.overCorrection.useOverCorrection,
          teeth: mapTeeth(data.overCorrection.teeth),
          details: data.overCorrection.details,
        }
      : newDoctorPref?.overcorrection !== undefined
      ? {
          useOverCorrection:
            newDoctorPref.overcorrection === true ||
            newDoctorPref.overcorrection === false
              ? newDoctorPref.overcorrection
              : "eonToDecide",
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        }
      : gco_doctor
      ? {
          useOverCorrection: "eonToDecide",
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
            details: "",
          }),
        }
      : {
          useOverCorrection: "eonToDecide",
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
            details: "",
          }),
        },

    passiveAligners: data?.passiveAligners
      ? {
          usePassiveAligners: data.passiveAligners.usePassiveAligners,
          add_passive_aligners_at_the_end:
            data.passiveAligners.add_passive_aligners_at_the_end,
          add_passive_aligners_at_the_beginning:
            data.passiveAligners.add_passive_aligners_at_the_beginning,
          details: data.passiveAligners.details,
        }
      : newDoctorPref.passive_aligners !== undefined
      ? {
          usePassiveAligners:
            newDoctorPref.passive_aligners === true ||
            newDoctorPref.passive_aligners === false
              ? newDoctorPref.passive_aligners
              : "eonToDecide",
        }
      : gco_doctor
      ? {
          usePassiveAligners: "eonToDecide",
          add_passive_aligners_at_the_end: false,
          add_passive_aligners_at_the_beginning: false,
          details: "",
        }
      : {
          usePassiveAligners: "eonToDecide",
        },
    toothSizeDiscrepancy: data?.toothSizeDiscrepancy
      ? {
          use_address_discrepancy:
            data.toothSizeDiscrepancy.use_address_discrepancy,
          leaveSpaceAroundLaterals:
            data.toothSizeDiscrepancy.leaveSpaceAroundLaterals,
          leaveSpaceDistalLaterals:
            data.toothSizeDiscrepancy.leaveSpaceDistalLaterals,
          iprOpposing: data.toothSizeDiscrepancy.iprOpposing,
          other: data.toothSizeDiscrepancy.other,
          other_details: data.toothSizeDiscrepancy.other_details,
        }
      : {
          use_address_discrepancy: "eonToDecide",
          leaveSpaceAroundLaterals: false,
          leaveSpaceDistalLaterals: false,
          iprOpposing: false,
          other: false,
          other_details: "",
        },
    torqueEnhancers: data?.torqueEnhancers
      ? {
          useTorqueEnhancers: data.torqueEnhancers.useTorqueEnhancers,
          teeth: mapTeeth(data.torqueEnhancers.teeth),
          details: data.torqueEnhancers.details,
        }
      : {
          useTorqueEnhancers: "eonToDecide",
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),

          details: "",
        },
    biteRamps: data?.biteRamps
      ? {
          useBiteRamps: data.biteRamps.useBiteRamps,
          incisors: data.biteRamps.incisors,
          incisors_and_canines: data.biteRamps.incisors_and_canines,
          details: data.biteRamps.details,
        }
      : {
          useBiteRamps: "eonToDecide",
          incisors: false,
          incisors_and_canines: false,
          details: "",
        },
    teethMovement: data?.teethMovement
      ? {
          use_teethMovement: data.teethMovement.use_teethMovement,
          Normal_Rate: data.teethMovement.Normal_Rate,
          Slow_Rate: data.teethMovement.Slow_Rate,
        }
      : {
          use_teethMovement: "eonToDecide",
          Normal_Rate: false,
          Slow_Rate: false,
        },

    hybridTreatment: data?.hybridTreatment
      ? {
          use_hybridTreatment: data.hybridTreatment.use_hybridTreatment,
          yes: data.hybridTreatment.yes,
          no: data.hybridTreatment.no,
        }
      : data?.hybirdTretment
      ? {
          use_hybridTreatment: data.hybirdTretment.use_hybirdTretment,
          yes: data.hybirdTretment.yes,
          no: data.hybirdTretment.no,
        }
      : newDoctorPref?.hybrid_treatment !== undefined
      ? {
          use_hybridTreatment:
            newDoctorPref.hybrid_treatment === true ||
            newDoctorPref.hybrid_treatment === false
              ? newDoctorPref.hybrid_treatment
              : "eonToDecide",
        }
      : gco_doctor
      ? {
          use_hybridTreatment: "eonToDecide",
          yes: false,
          no: false,
        }
      : {
          use_hybridTreatment: "eonToDecide",
          yes: false,
          no: false,
        },
    archExpansion: data?.archExpansion
      ? {
          use_arch_expansion: data.archExpansion.use_arch_expansion,
          increaseCaninesPremolarsMolars:
            data.archExpansion.increaseCaninesPremolarsMolars,
          increaseCaninesPremolars: data.archExpansion.increaseCaninesPremolars,
          increasePremolarsMolars: data.archExpansion.increasePremolarsMolars,
          teeth: mapTeeth(data.archExpansion.teeth),
        }
      : {
          use_arch_expansion: "eonToDecide",
          increaseCaninesPremolarsMolars: false,
          increaseCaninesPremolars: false,
          increasePremolarsMolars: false,
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        },

    teethMovement: data?.teethMovement
      ? {
          use_teethMovement: data.teethMovement.use_teethMovement,
          Normal_Rate: data.teethMovement.Normal_Rate,
          Slow_Rate: data.teethMovement.Slow_Rate,
        }
      : {
          useteethMovement: false,
          Normal_Rate: false,
          Slow_Rate: false,
        },

    elastics: data?.elastics
      ? {
          useElastics: data.elastics.useElastics,
          teeth: mapTeeth(data.elastics.teeth),
          buttons_teeth: mapTeeth(data.elastics.buttons_teeth),
          cuts_teeth: mapTeeth(data.elastics.cuts_teeth),
          details: data.elastics.details,
          type: data.elastics.type,
          use_buttons: data.elastics.use_buttons,
          use_cuts: data.elastics.use_cuts,
        }
      : {
          useElastics: "eonToDecide",
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
          buttons_teeth: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },
          cuts_teeth: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },
          use_buttons: false,
          use_cuts: false,
          details: "",
          type: "",
        },
  };
}
const checkIfItHasObject = (arr) => {
  if (arr?.filter((value) => typeof value == "object").length > 0) {
    return arr.map(({ step }) => Number(step));
  } else {
    return arr;
  }
};
export const doctor_preferences_mapper = (doctor_preferences) => ({
  use_ipr:
    doctor_preferences?.ipr?.use_ipr === "eonToDecide"
      ? "no_choice"
      : doctor_preferences?.ipr?.use_ipr || doctor_preferences?.use_ipr,
  ipr_steps:
    doctor_preferences?.ipr?.ipr_steps.map(({ step }) => Number(step)) ||
    checkIfItHasObject(doctor_preferences?.ipr_steps || []),
  use_attachments:
    doctor_preferences?.attachments?.use_attachments === "eonToDecide"
      ? "no_choice"
      : doctor_preferences?.attachments?.use_attachments ||
        doctor_preferences?.use_attachments,
  attachments_steps:
    doctor_preferences?.attachments?.attachments_steps.map(({ step }) =>
      Number(step)
    ) || checkIfItHasObject(doctor_preferences?.attachments_steps || []),
  passive_aligners:
    doctor_preferences?.passive_aligners === "eonToDecide"
      ? "no_choice"
      : doctor_preferences?.passive_aligners,
  pontics:
    doctor_preferences?.pontics === "eonToDecide"
      ? "no_choice"
      : doctor_preferences?.pontics,
  arch_leveling: doctor_preferences?.upper_arch_leveling?.level_gingival_margins
    ? "level_gingival_margins"
    : doctor_preferences?.upper_arch_leveling?.level_incisal_edge
    ? "level_incisal_edge"
    : doctor_preferences?.arch_leveling,
  overcorrection:
    doctor_preferences?.overcorrection === "eonToDecide"
      ? "no_choice"
      : doctor_preferences?.overcorrection,
  other_preferences: doctor_preferences?.other_preferences,
  version: doctor_preferences?.version,
});

export const oldAdvancedModulesMapper = (
  advancedModules,
  advancedModulesModules,
  customTeethReducer
) => ({
  ...advancedModules,
  ipr: {
    ...advancedModules.ipr,
    iprRestriction: mapTeethToApi(customTeethReducer.Case_IPR),
  },
  archExpansion: {
    ...advancedModules.archExpansion,
    teeth: mapTeethToApi(customTeethReducer.Case_Arch_Expansion),
  },
  attachments: {
    ...advancedModules.attachments,
    attachmentsRestriction: mapTeethToApi(customTeethReducer.Case_Attachments),
  },
  elastics: {
    ...advancedModules.elastics,
    teeth: mapTeethToApi(customTeethReducer.Case_Elastics),
    buttons_teeth: mapTeethToApi(customTeethReducer.Case_Elastics_Buttons),
    cuts_teeth: mapTeethToApi(customTeethReducer.Case_Elastics_Cuts),
  },
  extraction: {
    ...advancedModules.extraction,
    teeth: mapTeethToApi(customTeethReducer.Case_Extraction),
  },
  overCorrection: {
    ...advancedModules.overCorrection,
    teeth: mapTeethToApi(customTeethReducer.Case_Overcorrections),
  },
  pontics: {
    ...advancedModules.pontics,
    teeth: mapTeethToApi(customTeethReducer.Case_Pontics),
  },
  torqueEnhancers: {
    ...advancedModules.torqueEnhancers,
    teeth: mapTeethToApi(customTeethReducer.Case_Torque_Enhancers),
  },
  malocclusion: {
    ...advancedModules.malocclusion,
    crossbite: {
      ...advancedModules.malocclusion.crossbite,
      teeth: mapTeethToApi(customTeethReducer.Case_Crossbites),
    },
    crowding: {
      ...advancedModules.malocclusion.crowding,
      teeth: mapTeethToApi(customTeethReducer.Case_Crowding),
    },
    classII: {
      ...advancedModules.malocclusion.classII,
      teeth: mapTeethToApi(customTeethReducer.Case_ClassII),
    },
    classIII: {
      ...advancedModules.malocclusion.classIII,
      teeth: mapTeethToApi(customTeethReducer.Case_ClassIII),
    },
  },
  all_modules: mapAdvancedToApi(advancedModulesModules),
});
