import React, { Component } from "react";
import { connect } from "react-redux";

import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import { Button} from "reactstrap";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Extraction extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_extraction: this.props.caseSubmission.advanced_modules.extraction
        .use_extraction,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "extraction",
      data: {
        use_extraction: this.state.use_extraction,
        teeth: this.props.customeTeethReducer.Case_Extraction,
      },
    });
    this.props.save();
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { use_extraction } = this.state;
    const { content } = this.props.languageReducer

    return (
      <div>

        <div className="templete_module_grid">

          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.Use_Extraction_for_this_case}{"? "}
            </div>

            <TreatmentOptions content={content} value={use_extraction} name="use_extraction" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_extraction")
              if (value !== true) {
                this.props.Clear_Teeth_Data("Case_Extraction")
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />

          </div>
          <div className="divider_module-middle"></div>

          <div id="content_container_3"
            className={`${use_extraction !== true ? "c-content-container7" : "c-content-container "} tooth-extraction  cpb2 `}>
            <div className="control-label title_active-gco text-left pb-2 mt-2 light-green button-text">{content.select_the_teeth_that_needto_be_extracted}</div>
            <div className=" teeth_selector">
    {/* teeth_selector_extraction */}
              <CustomeTeeth action_key="Case_Extraction" disabled={!use_extraction || use_extraction === "eonToDecide"} summary={this.props.summary} />
            </div>
            <div className={`${(this.state.use_extraction === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_extraction === "eonToDecide") ? `Eon_to_recommend_eon  Eon_to_recommend_layout` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>
              <p className={`${(this.state.use_extraction === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}> {content.Extraction_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.use_extraction === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
            </div>
            <div className="margin-top2 displayNone_inmobile">

    
            {
            !this.props.summary && this.state.use_extraction ===true && <div className="btn-adv-mobile"><Button
              className=" mobile-but-adv light-green-btn float-right margin-top2   button-seme-bold-text "
              size="lg"
              type="button"
              onClick={() => {
                this.UpdateAdvancedModules();
              }}
            >
              {content.save}
            </Button></div>
          }
                  </div>

          </div>
        </div>
        <div className="container-button ">
          {
            !this.props.summary &&  !(this.state.use_extraction ===true && window.innerWidth <839  ) && <div className="btn-adv-mobile"><Button
              className=" mt-2 mobile-but-adv light-green-btn float-right   button-seme-bold-text"
              size="lg"
              type="button"
              onClick={() => {
                this.UpdateAdvancedModules();
              }}
            >
              {content.save}
            </Button></div>
          }
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Extraction);
