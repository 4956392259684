import React, { Component } from "react";
import { connect } from "react-redux";
import CustomIPRTeeth from "../../../Teeth/CustomIPRTeeth";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});

class IPRSummary extends Component {
  // constructor(props) {
  //     super(props);

  // }
  //Get the number from a string, return the value with it's per and with minuse and pluse wording
  GetNumber = (StringNum, per, moreThanText, lessThanText) => {
    let num = Number(StringNum.split(" ")[0]);
    return `${Math.abs(num)} ${per} ${num >= 0 ? moreThanText : lessThanText}`;
  };

  checkIPR = (newData, oldData) => {
    //let changed = false;
    if (newData.length && oldData.length) {
      if (newData.length !== oldData.length) {
        return true;
      } else {
        for (var x = 0; x < newData.length; x++) {
          if (
            newData[x].amount !== oldData[x].amount ||
            newData[x].step !== oldData[x].step
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  checkRestrectWithValue = (newData, oldData, restrect) => {
    if (this.checkIPR(newData, oldData)) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    const { content } = this.props.languageReducer;
    const { teeth_ipr_data, saved_data } = this.props.revised_data
      ? this.props.teethIPROptions[this.props.id]
      : this.props.teethIPROptions;
    const { dental_notation } = this.props.userReducer.currentUser;
    return (
      <div className="cmt2">
        <CustomIPRTeeth
          teeth={teeth_ipr_data}
          saved_teeth_data={saved_data}
          disableChanges={true}
          hideSide={true}
        />

        <div className="movement-summary-grid cmt2">
          {Object.keys(teeth_ipr_data.upper_teeth)
            .filter((value, index) => {
              return (
                teeth_ipr_data.upper_teeth[value].updated === true &&
                this.checkRestrectWithValue(
                  teeth_ipr_data.upper_teeth[value].ipr_steps_amounts,
                  saved_data.upper_teeth[value].ipr_steps_amounts,
                  teeth_ipr_data.upper_teeth[value].restrict_ipr_tooth
                )
              );
            })
            .map((value, index) => (
              <div key={index} className="summary-movement-details">
                <div className="summary-tooth-title">
                  {`${
                    content.ipr
                  } ${content.between.toLowerCase()} ${teeth_ipr_data.upper_teeth[
                    value
                  ].tooth_key_sys[dental_notation].replace(
                    "/",
                    ` ${content.and.toLowerCase()} `
                  )}`}
                </div>

                {/* Use IPR */}
                <div>
                  {" "}
                  {content.use_ipr}:{" "}
                  {teeth_ipr_data.upper_teeth[value].use_ipr
                    ? content.yes
                    : content.no}
                </div>
                {this.checkIPR(
                  teeth_ipr_data.upper_teeth[value].ipr_steps_amounts,
                  saved_data.upper_teeth[value].ipr_steps_amounts
                ) && (
                  <div className="summary-movement-details">
                    {teeth_ipr_data.upper_teeth[value].ipr_steps_amounts.map(
                      (value, index) => {
                        return (
                          <div key={index} className="summary-movement-details">
                            <div>
                              {content.ipr_amount} :
                              <span className="updated-value cpl4">
                                {value.amount}
                              </span>
                            </div>
                            <div>
                              {content.add_ipr_before_step} :
                              <span className="updated-value cpl4">
                                {value.step}
                              </span>{" "}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            ))}
          {Object.keys(teeth_ipr_data.lower_teeth)
            .filter((value, index) => {
              return (
                teeth_ipr_data.lower_teeth[value].updated === true &&
                this.checkRestrectWithValue(
                  teeth_ipr_data.lower_teeth[value].ipr_steps_amounts,
                  saved_data.lower_teeth[value].ipr_steps_amounts,
                  teeth_ipr_data.lower_teeth[value].restrict_ipr_tooth
                )
              );
            })
            .map((value, index) => (
              <div key={index} className="summary-movement-details">
                <div className="summary-tooth-title">
                  {`${
                    content.ipr
                  } ${content.between.toLowerCase()} ${teeth_ipr_data.lower_teeth[
                    value
                  ].tooth_key_sys[dental_notation].replace(
                    "/",
                    ` ${content.and.toLowerCase()} `
                  )}`}
                </div>

                {/* Use IPR */}
                <div>
                  {" "}
                  {content.use_ipr}:{" "}
                  {teeth_ipr_data.lower_teeth[value].use_ipr
                    ? content.yes
                    : content.no}
                </div>
                {this.checkIPR(
                  teeth_ipr_data.lower_teeth[value].ipr_steps_amounts,
                  saved_data.lower_teeth[value].ipr_steps_amounts
                ) && (
                  <div className="summary-movement-details">
                    {teeth_ipr_data.lower_teeth[value].ipr_steps_amounts.map(
                      (value, index) => {
                        return (
                          <div key={index} className="summary-movement-details">
                            <div>
                              {content.ipr_amount} :
                              <span className="updated-value cpl4">
                                {value.amount}
                              </span>
                            </div>
                            <div>
                              {content.add_ipr_before_step} :
                              <span className="updated-value cpl4">
                                {value.step}
                              </span>{" "}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IPRSummary);
