export const statusColors = {
  Active: {
    color: "#7AD93D",
    backgroundColor: "",
  },
  Complete: {
    color: "#59B120",
    backgroundColor: "",
  },
  "Pending Courier Notification": {
    color: "#FFCD5D",
    backgroundColor: "",
  },
  "Pending Record Approval": {
    color: "#FFCD5D",
    backgroundColor: "",
  },
  "Pending TS Approval": {
    color: "#FFCD5D",
    backgroundColor: "",
  },
  "Pending Accounting Clearance": {
    color: "#FFCD5D",
    backgroundColor: "",
  },
  "Records Rejected": {
    color: "#FC4A69",
    backgroundColor: "#f5c6cb",
  },
  "Rejected TS": {
    color: "#FC4A69",
    backgroundColor: "#f5c6cb",
  },
  "Incomplete Submission": {
    color: "#C8C8CA",
    backgroundColor: "",
  },
  Paused: {
    color: "#C8C8CA",
    backgroundColor: "",
  },
  "Generating TS": {
    color: "#75E6D9",
    backgroundColor: "",
  },
  "Generating Adjustment TS": {
    color: "#75E6D9",
    backgroundColor: "",
  },
  "Awaiting Completion": {
    color: "#00C7B1",
    backgroundColor: "",
  },
  "Awaiting Impressions": {
    color: "#FFCD5D",
    backgroundColor: "",
  },
  "Pending doctor approval": {
    color: "#FFCD5D",
    backgroundColor: "",
  },
  Inactive: {
    color: "#C8C8CA",
    backgroundColor: "",
  },
  Expired: {
    color: "#C8C8CA",
    backgroundColor: "",
  },
};

export const ts_status = {
  "Pending production file upload": {
    lable: "Active",
    color: "#7AD93D",
  },
  "Pending production file qc": {
    lable: "Active",
    color: "#7AD93D",
  },
  "Pending syncing": {
    lable: "Active",
    color: "#7AD93D",
  },
  Active: {
    lable: "Active",
    color: "#7AD93D",
  },
  Inactive: {
    lable: "Inactive",
    color: "#FC4A69",
  },
};
