import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Overcorrection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useOverCorrection: this.props.caseSubmission.advanced_modules
        .overCorrection.useOverCorrection,
      overCorrection_details: this.props.caseSubmission.advanced_modules
        .overCorrection.details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "overCorrection",
      data: {
        useOverCorrection: this.state.useOverCorrection,
        teeth:
          this.props.customeTeethReducer.Case_Overcorrections
        ,
        details: this.state.overCorrection_details,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/

  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { useOverCorrection, overCorrection_details } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>
        <div className="templete_module_grid">
          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.use_overcorrection_for_thiscase}{"? "}
            </div>

            <TreatmentOptions content={content} value={useOverCorrection} name="useOverCorrection" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "useOverCorrection")
              if (value !== true) {
                this.setState({
                  overCorrection_details: ""
                })
                this.props.Clear_Teeth_Data("Case_Overcorrections")
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>

          <div className="divider_module-middle"></div>

          <div id="content_container_3"
            className={`${useOverCorrection !== true ? "c-content-container7" : "c-content-container "} tooth-extraction  cpb2 `}>
            <div className="control-label title_active-gco text-left pb-2 mt-2 light-green button-text">
              {content.select_the_tooth_areas_requiring_overcorrection}
            </div>
            <div className="teeth_selector">
              <CustomeTeeth
                action_key="Case_Overcorrections"
                disabled={!useOverCorrection || useOverCorrection === "eonToDecide"}
                summary={this.props.summary}
              /></div>

            <div>
              {" "}
              <br />
              <br />

              <p className="control-label text-left  light-green button-text">{content.additional_comments}</p>
              <Input
                className="mt-2  border-radius backgrountTextarea  custome-input-text"
                name="overCorrection_details"
                id="overCorrection_details"
                type="textarea"
                placeholder={content.typeHere}
                rows={5}
                value={overCorrection_details}
                onChange={(e) => {
                  this.setState({ overCorrection_details: e.target.value });
                }}
                disabled={!useOverCorrection || useOverCorrection === "eonToDecide" || this.props.summary}
              />{" "}
            </div>
            <div className={`${(this.state.useOverCorrection === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.useOverCorrection === "eonToDecide") ? `Eon_to_recommend_eon Eon_to_recommend_layout` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>
              <p className={`${(this.state.useOverCorrection === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Overcorrection_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.useOverCorrection === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
            </div>
            {
          !this.props.summary && this.state.useOverCorrection ===true &&<div className="btn-adv-mobile displayNone_inmobile"><Button
            className=" mt-2 mobile-but-adv light-green-btn float-right   button-seme-bold-text "
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
          </div>
          
        </div>
        {
          !this.props.summary &&!(this.state.useOverCorrection ===true && window.innerWidth <839  )&& <div className="btn-adv-mobile"><Button
            className=" mt-2 mobile-but-adv light-green-btn float-right   button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Overcorrection);
