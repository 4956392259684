import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CustomeHeader from "../../../components/header/CustomeHeader";
import TermsContent from "./TermsContent";

const Terms = (props) => {
  const { CreateDoctorPreferences, UpdateUser, withSkip, showOnlyTerms } =
    props;
  const { content } = props.languageReducer;
  const { country } = props.userReducer.currentUser;

  const [activeTab, setActiveTab] = useState(1);
  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="terms_component">
      <ModalBody style={{ paddingTop: "24px" }}>
        <CustomeHeader
          title={
            withSkip
              ? content.terms_and_condition
              : content.weve_updated_our_terms_and_conditions
          }
          onboarding={true}
          withoutLine={true}
          largeFont={true}
        />
        {!withSkip && (
          <div className="normal_text cpb2">{content.accept_terms_note}</div>
        )}

        <div className="c-tabs">
          <Nav tabs>
            <NavItem className="w-auto button-italic">
              <NavLink
                className={`${activeTab === 1 ? "active" : ""} button-text`}
                onClick={() => {
                  toggleActiveTab(1);
                }}
              >
                {content.en}
              </NavLink>
            </NavItem>
            <NavItem className="w-auto button-italic">
              <NavLink
                className={`${activeTab === 2 ? "active" : ""} button-text`}
                onClick={() => {
                  toggleActiveTab(2);
                }}
              >
                {content.ar}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <div>
                <TermsContent language={"en"} country={country} />
              </div>
            </TabPane>
            <TabPane tabId={2}>
              <div>
                <TermsContent language={"ar"} country={country} />
              </div>
            </TabPane>
          </TabContent>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="onboarding-footer ">
          <Button
            className="onboarding-btn-save light-green-btn pull-right cml1 button-seme-bold-text"
            size="md"
            //  disabled={creatingNewPreferance && sendingData}
            onClick={async () => {
              if (withSkip) {
                await CreateDoctorPreferences();
                UpdateUser();
              } else {
                UpdateUser();
              }
            }}
          >
            {withSkip ? content.agree : content.approve}
          </Button>
        </div>
      </ModalFooter>
    </div>
  );
};

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, mapDispatchToProps)(Terms);
