import { combineReducers } from "redux";
import settings from "./settings/reducer";
import chatReducer from "./chat/reducer";
import contactReducer from "./contacts/";
import emailReducer from "./email/";
import userReducer from "./user/reducer";
import newCase from "./newCase/reducer";
import adjustment from "./adjutment/reducer";
import inbox from "./inbox/reducer";
import currentCase from "./caseDetails/reducer";
import customeTeethReducer from "./customeTeeth/reducer";
import caseSubmission from "./CaseSubmission/reducer";
import advancedModules from "./advanced/reducer";
import teethIPROptions from "./Tooth_IPR_Options/reducer"
import languageReducer from "./language/reducer";
import changesReducer from "./changes/reducer";
import hybridTreatment from "./hybridTreatment/reducer";
import biteReducer from "./biteRecord/reducer";
import caseReducer from "./caseById/reducer"
const reducers = combineReducers({
  settings,
  chatReducer,
  contactReducer,
  emailReducer,
  userReducer,
  newCase,
  adjustment,
  inbox,
  currentCase,
  customeTeethReducer,
  caseSubmission,
  advancedModules,
  teethIPROptions,
  languageReducer,
  changesReducer,
  hybridTreatment,
  biteReducer,
  caseReducer
});

export default reducers;
