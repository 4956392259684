import { modules } from "./modules";
import { AVAILABLE_MODULES, ACTIVE_MODULES, ALL_MODULES } from "../constants";
const INIT_STATE = {
  modules: modules,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AVAILABLE_MODULES:
      return {
        ...state,
        modules: state.modules.map((item, index) => {
          // Find the item with the matching key
          if (item.key === action.payload) {
            // Return a new object
            return {
              ...item, // copy the existing item
              active: false, // activate step
            };
          }
          // Leave every other item unchanged
          return item;
        }),
      };
    case ACTIVE_MODULES:
      return {
        ...state,
        modules: state.modules.map((item, index) => {
          // Find the item with the matching key
          if (item.key === action.payload) {
            // Return a new object
            return {
              ...item, // copy the existing item
              active: true, // activate step
            };
          }
          // Leave every other item unchanged
          return item;
        }),
      };
    case ALL_MODULES:
      return {
        ...state,
        modules: action.payload,
      };
    default:
      return state;
  }
};
