import React, { Component } from "react";
import { connect } from "react-redux";
import CustomeHeader from "../../../components/header/CustomeHeader";
import Back from "../../../components/back/Back";
import Header from "./header";
import Benefits from "./benefits";
import Description from "./description";
import FAQ from "./FAQs";
import LoyaltyTable from "./LoyaltyTable";
import { getDoctorInfo } from "../../../api/api";
import { setUserInfo } from "../../../redux/user/action";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setUserInfo:(payload) =>dispatch(setUserInfo(payload))
});

class LoyaltyDescription extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      currentStep: props.userReducer?.currentUser?.loyalty?.active_tier.id,
      id: props.userReducer.currentUser.id,
    };
  }

  GetDoctorInfo = () => {
    getDoctorInfo(this.state.id).then((res) => {

      const user = {
        ...res,
        language: res.preferred_language || "en",
        date_format: res.preferred_date_format || "DD/MM/YYYY",
        dental_notation: res.preferred_dental_notation || "fdi",
      }; 

      this.setState({
        doctorInfo: user,

      });
      this.props.setUserInfo(user);
    });
  };
  componentDidMount() {
    this.GetDoctorInfo();
  }


  /* --------------------------------------------------------------------------------*/
  /* color */
  /* --------------------------------------------------------------------------------*/
  color = (colorName) => {
    if (colorName === "Bronze") {
      return "#C68902";
    } else if (colorName === "Silver") {
      return "#C4C4C4";
    } else if (colorName === "Gold") {
      return "#FFB818";
    } else if (colorName === "Platinum") {
      return "#4F5665";
    } else if (colorName === "Diamond") {
      return "#74B1BE";
    } else if (colorName === "Elite") {
      return "#00C7B1";
    }
  };

  render() {
    let { Steps, LoyaltySteps } = this.props.caseSubmission;

    const {loyalty} =  this.props.userReducer?.currentUser

    let  currentStep  = loyalty?.active_tier?.id
    const { content } = this.props.languageReducer;
  

    const Step = (props) => (

     
      <div
        className={`Stepper__step  `}
  
      >
         {loyalty &&
        <div>
          <div
            className={
              props.updated === true
                ? "Stepper__edit  label-cont-stepper"
                : "Stepper__edit_empty label-cont-stepper"
            }
          >
            {" "}
          </div>

          <div className="Stepper__indicator">
            {currentStep === props.id ? (
              <span className="Stepper__info_loyalty">
              <span
                className={`${`Stepper__info_loyaltty`}`}
                style={{
                  borderColor: this.color(
                    loyalty.active_tier.name
                  ),
                  backgroundColor: this.color(
                    loyalty.active_tier.name
                  ),
                }}
              >
                <div className="step_indicator"></div>
              </span>
              </span>
            ) : (
              <span className="Stepper__info_loyalty_sec">
              <span className={`${`Stepper__info`}`}>
                <div className="step_indicator"></div>
              </span>
              </span>
            )}
          </div>
          {currentStep === props.id ? (
          <div className="caption-1-bold-text"  
          style={{
            color: this.color(
              loyalty.active_tier.name
            ),
          }} >{props.name}</div>)
          :(<div className="caption-1-text">{props.name}</div>)}
          <div className="seme-text">{props.cases}</div>

          <div className="Stepper__panel">{props.children}</div>
        </div> }
      </div>
       

    );

    // Overlay
    const Stepper = (props) => (
      <div
        className={`Stepper Stepper_loyalty  ${
          props.isVertical ? "Stepper--vertical" : ""
        } ${props.isInline ? "Stepper--inline" : ""}`}
      >
          {loyalty && props.children}
      </div>
    );

    return (
      <div className="container-height-loyalty-page">
        {loyalty &&
        <div>
          <Back />
          <CustomeHeader  title={content.loyalty_program}  bold={true}/>
          <div className="container_loyalty_program">
            <Stepper>
              {LoyaltySteps.map((value, index) => {
                return (
                  <Step
                    name={value.name}
                    active={value.active}
                    key={value.id}
                    color={value.color}
                    indicator={value.id}
                    ColorfulImage={value.icon}
                    DisableImage={value.disableImage}
                    updated=""
                    id={value.id}
                    cases={value.cases}
                  >
                    <div className="Content">
                      <button
                        onClick={() => {
                          this.props.prevStep(LoyaltySteps[value.id]);
                        }}
                      >
                        Prev
                      </button>
                      <button
                        onClick={() => {
                          this.props.changeCurrentStep(value.id + 1);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </Step>
                );
              })}
            </Stepper>

            <Header loyalty={loyalty} />
          </div>
          <CustomeHeader
            capitalize={false}
            bold={true}
            title={content.your_benefits}
            explore_the_full_loyalty_program
          />
          <Benefits loyalty={loyalty} />

          <CustomeHeader
            capitalize={false}
            bold={true}
            title={content.explore_the_full_loyalty_program}
          />
          <LoyaltyTable loyalty={loyalty} />
          <CustomeHeader
            capitalize={false}
            bold={true}
            title={content.description_of_benefits}
          />
          <Description loyalty={loyalty} />
          <CustomeHeader
            capitalize={false}
            bold={true}
            title={content.FAQs}
          />
          <FAQ loyalty={loyalty} />
        </div>
  }
      </div>
    );
    
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyDescription);
