import React from "react";
import clsx from "clsx";

export const RadioGroupTailwind = ({
  checked,
  onChange = () => {},
  disabled,
  options,
  name,
  withoutborder,
  className,
  classNameLabel,
}) => {
  return (
    <div className={className}>
      {options.map((value) => (
        <div
          className={clsx({
            "radio-tailwind active-border-new": checked === value.name,
            "radio-tailwind border-new": checked !== value.name,
          })}
          key={value.name}
          style={{ pointerEvents: disabled ? "none" : "" }}
        >
          <div>
            <input
              type="radio"
              className="tw-ea-hidden"
              id={name + value.name}
              name={name}
              value={value.name}
              checked={checked == value.name}
              // defaultChecked={!checked ? value.defaultChecked : undefined}
              onChange={(e) => onChange(e.target.value)}
              disabled={disabled}
              style={{ pointerEvents: disabled ? "none" : "cursor" }}
            />
            <label
              className={clsx(
                classNameLabel,
                "tw-ea-font-normal tw-ea-cursor-pointer tw-ea-inline tw-ea-leading-5",
                {
                  "tw-ea-p-[4px]": withoutborder,
                }
              )}
              htmlFor={name + value.name}
              dangerouslySetInnerHTML={{ __html: value.title }}
            ></label>
          </div>
        </div>
      ))}
    </div>
  );
};
