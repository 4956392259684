import { getPriceJOEN } from "./jo/en";
import { getPriceSAEN } from "./ksa/en";
import { getPriceKWEN } from "./kw/en";
import { getPriceOtherEN } from "./other/en";
import { getPriceQAEN } from "./qa/en";
import { getPriceUAEEN } from "./uae/en";


const getPrices = (country) => {
  switch (country) {
    case "AE":
      return getPriceUAEEN();
    case "JO":
      return getPriceJOEN();
    case "SA":
      return getPriceSAEN();
    case "KW":
      return getPriceKWEN();
    case "QA":
      return getPriceQAEN();
    default:
      return getPriceOtherEN();
  }
};
export const TermsEN = (country) => {

  return `
    <div class="bold_text cpy1"> EON ALIGNER TERMS & CONDITIONS </div>
    <div class="normal_text cpb1"> Last Update: 1 October 2022 </div>
    <div class="bold_text cpt2 cpb1"> 1. INTRODUCTION </div>

    <div class="normal_body_text"> 
    <span class="bold_text"> 1.1. </span>  These terms and conditions (“<span class="bold_text">Agreement </span>”)
     constitute the entire agreement and understanding and apply to all 
     aspects of the relationship between Eon Dental NV or, as applicable,
     one of its subsidiaries or associates ("<span class="bold_text">Eon Dental</span>"), and any customer
     ("<span class="bold_text">Customer</span>"), and no variation or amendment to the Agreement or any other
     terms or conditions, whether implied or otherwise, shall apply except where
     Eon Dental has expressly otherwise agreed in writing. </div>

     <div class="normal_body_text"> 
     <span class="bold_text"> 1.2. </span> Eon Dental is inter alia engaged in the business of designing, manufacturing,
     selling, distributing and servicing custom-made clear aligners for orthodontic
     treatment (“<span class="bold_text">Clear Aligners</span>”) and removable orthodontic retainers made entirely 
     of transparent plastic (“<span class="bold_text">Retainers</span>”) 
     (together “<span class="bold_text">Products</span>”) and the provision of
     the supporting and ancillary services (including renderings of a preview of how 
     the subject Products is expected to move teeth, 3D virtual models of each step of
     the treatment, and a 3D viewer (“<span class="bold_text">Treatment Setup</span>”))
    (“<span class="bold_text">Services</span>”). </div>

    <div class="normal_body_text"> 
    <span class="bold_text"> 1.3. </span>
    This Agreement governs the Customer's use of the Eon Dental website,
    its case management portal for Treatment Setup and Customer communication 
    (“<span class="bold_text">Eon Access</span>”) and the processes and requirements to submit an order for
    Products and Services (“<span class="bold_text">Case Order</span>”), and the Customer agrees to be bound
    by them from the time the Customer first accesses Eon Access in consideration
    for Eon Dental providing the Customer with the Products and Services and permission to use Eon Access.
    </div>

    <div class="bold_text cpt2 cpb1"> 2. CUSTOMERS </div>
    <div class="normal_body_text">
    <span class="bold_text"> 2.1. </span> Eon Dental’s Customer is the doctor whose email address 
    is used to log in to Eon Access (“<span class="bold_text">Eon Doctor</span>”).
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 2.2. </span> 
    If the Eon Doctor owns, works for, works with, or is employed by a 
    clinic or other legal person (“<span class="bold_text">Clinic</span>”), and such Clinic is named on or 
    as a matter of practice pays for the invoice(s) issued by Eon Dental,
    then such reference in or payment of the invoice(s) shall constitute 
    the Clinic’s authorization for the Eon Doctor to use Eon Access and make Case Orders,
    in which instance the Clinic is also an Eon Customer, and the Eon Doctor and the Clinic
    will be jointly and severally liable for obligations in this Agreement which are
    obligations of the Customer.
    </div>

    <div class="bold_text cpt2 cpb1"> 3. CASES AND ACCEPTANCE </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.1. </span> 
    Cases Orders are placed in Eon Access and shall take effect at
    the date and time a Customer approves the Treatment Setup associated
    with that Case Order (“<span class="bold_text">Approved Case</span>”)
    (“<span class="bold_text">Approval Date</span>”). 
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.2. </span> 
    All Approved Cases are subject to final acceptance by Eon Dental either in writing by 
    sending the Customer a confirmation email or implicitly with the elapse of time and shipment
    and Eon Dental has the right in its absolute and sole discretion, and without any liability,
    to refuse any Case Order or Approved Case. 
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.3. </span> It is the Customer’s responsibility to provide for each 
    Case Order’s named patient (“<span class="bold_text">Patient</span>”) all relevant records including 
    patient name and other personal data, the upper and lower arch physical or digital impressions of
    the teeth, x-rays, photos (including full face, smiling, upper and lower occlusal, left and right buccal) that
    accurately represent the current condition of a Patient’s teeth in addition to the prescription 
    (“<span class="bold_text">Patient Data</span>”). 
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.4. </span> The Customer shall only share Patient Data necessary for 
    Eon Dental to carry out its obligations under this Agreement. Customer shall ensure that each
    Patient signs the Patient Information and Consent Form (“<span class="bold_text">Consent Form</span>”) 
    available on Eon Access and retain a copy of such Consent Form for their own records.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.5. </span>  The Customer shall have the full and exclusive responsibility for
    (i) for the accuracy of the Patient Data, and (ii) to manage the Patient’s expectations on the timing of
    their treatment, particularly where delays are caused by the Customer or Patient’s own delays or failures to act.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.6. </span> Eon Dental shall produce a Treatment Setup within five (5)
    days of receiving the Patient Data in the form and quality as accepted by Eon Dental in its absolute and sole discretion. 
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.7. </span> A Case Order shall become under processing at the earlier of Eon Dental 
    either collecting, where applicable, the physical teeth impression or producing the Treatment Setup in 
    accordance with Article 3.6 (“<span class="bold_text">Case Under Processing</span>”).</div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.8. </span> Subject to the terms and conditions shown in Article 7, Products will only
    be manufactured and dispatched from the site of manufacture for Approved Cases. </div>

    <div class="bold_text cpt2 cpb1"> 4. PRODUCTS, PRICING AND PAYMENT TERMS  </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.1. </span> The Customer may elect one of the packages below 
    (each a “<span class="bold_text"> Treatment Package</span>”) when placing a Case Order:
    </div>

    ${getPrices(country)}
    <div class="normal_body_text">
    <span class="bold_text"> 4.2. </span> All invoices, including cancelation invoices, are payable by the 
    Customer in full within thirty (30) days of the invoice.</div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.3. </span> An Aligner Step is, in the case of Dual Arch Treatment Package,
    a pair of one (1) upper dental arch and one (1) lower dental arch or, in the case of a Single Arch 
    Treatment Package, either one (1) upper dental arch or one (1) lower dental arch. </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.4. </span> The Customer shall ensure that the number of steps
    clinically required to move the teeth from the initial teeth position to the final teeth 
    position in the initial Treatment Setup does not exceed the number of Aligner Steps in the 
    selected Treatment Package. Eon Dental, in its absolute and sole discretion, may propose 
    initial Treatment Setups that better correspond with the number of Aligner Steps clinically 
    required than those in the elected Treatment Package. </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.5. </span> Refinements are additional Aligner Steps based on 
    a new Treatment Setup that, in Eon Dental’s absolute and sole discretion, does not change 
    the initial treatment goal of final teeth position. The cumulative number of Aligner Steps
    in an Eon Basic or Eon Plus Product Refinement must not exceed the number of initial Aligner
    Steps in the initial Treatment Setup. 
    <div class="cpt1">In Eon Dental’s absolute and sole discretion, if a Refinement’s new Treatment
    Setup, changes the treatment goal of final teeth movement from that of the initial Treatment Setup,
    then Eon Dental reserves the right to automatically upgrade the initially approved Treatment Package.
    </div>
    </div>


    <div class="normal_body_text">
    <span class="bold_text"> 4.6. </span> A Retainer Set is, in the case of Dual Arch Treatment Package,
    a pair of one (1) upper dental arch retainers and one (1) lower dental arch retainer or, in the case
    of a Single Arch Treatment Package, either one (1) upper dental arch retainer or one (1)
    lower dental arch retainer. </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.7. </span> Eon Pro is subject to a ‘fair-use-policy’ and Eon Dental may
    apply fees for additional Aligner Steps and Treatment Setups that it may determine, in its absolute
    and sole discretion, are excessive with respect to the quantity of or time elapsed between such Aligner
    Steps and Treatment Setups. </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.8. </span> Expiration Date is the number of months from the first shipment date 
    of the Aligner Steps. </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.9. </span> For each Treatment Package, an Approved Case becomes complete 
    (a “<span class="bold_text"> Complete Case </span>”) at the earliest of (i) having been marked accordingly by the Eon Doctor, (ii) 
    shipment of all the Retainer Set(s), or (iii) the applicable Expiration Date. </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.10. </span> Additional Aligner Steps cannot be ordered individually for Complete Cases.
    If additional Aligner Steps are required for a Complete Case, then a Case Extension can be ordered as per one of 
    the Treatment Packages shown above. </div>



    <div class="bold_text cpt2 cpb1"> 5. EON OBLIGATIONS </div>

    <div class="normal_body_text">
    <span class="bold_text"> 5.1. </span> Eon Dental warrants that any and all Products are not damaged,
    bent or broken, are conforming with the Treatment Setup and are free from defects in material and workmanship.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 5.2. </span> The limited warranty in Article 5.1 expires ninety (90) days 
    after the Products have been delivered in accordance with Article 7. Any delay due to a Customer’s
    delivery to a Patient, or due to a Patient’s wear schedule after the Products have been delivered 
    to the Customer do not extend the limited warranty period.
    </div>


    <div class="normal_body_text">
    <span class="bold_text"> 5.3. </span> Eon Dental will not be liable for:
    </div>

    
    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.1. </span> The clinical outcome of any treatment using the Products or Services;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.2. </span> Failure to follow instructions, neglect, misuse, mistreatment or 
    improper handling of the Products and Services by the Customer, Patient or any third party;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.3. </span> Products or Services that have been altered 
    in any way by any party other than Eon Dental;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.4. </span> Products or Services that have been used in combination with any other 
    product or services provided by any other party other than Eon Dental;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.5. </span> Errors in submitted Patient Data.
    </div>


    <div class="normal_body_text ">
    <span class="bold_text"> 5.4. </span>Except for the sole and limited warranty shown in Article 5.1, 
    Eon Dental disclaims any and all other warranties of any kind, express or implied, including warranties
    of merchantability and fitness for a particular purpose. For the avoidance of doubt, this sole
    and limited warranty is only given to the Customer and not any other third party including any Patient.
    </div>

    
    <div class="normal_body_text ">
    <span class="bold_text"> 5.5. </span> If any Product or Service fails to conform to the sole and limited 
    warranty shown in 5.1, Eon Dental’s sole liability, would be to either, at its absolute and sole discretion 
    (i) replace the non-conforming Product or (ii) credit the Customer’s account for the cost of the Product or Service.
    </div>



    <div class="bold_text cpt2 cpb1"> 6. CUSTOMER OBLIGATIONS </div>

    <div class="normal_body_text ">
    <span class="bold_text"> 6.1. </span> Customer warrants and represents that: </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.1. </span> Customer is licensed to practice dentistry or orthodontics in the
    location to where the Product will be shipped;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.2. </span> Customer operates in accordance with applicable laws, regulations and 
    standard of care in the location to where the Product will be shipped;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.3. </span> Customer has the necessary training, expertise and experience 
    to treat Patients using the Products and Services;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.4. </span> Customer intends to only use the Products and Services in 
    accordance with the Clinician Guide (available on Eon Access);
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.5. </span> Customer is not purchasing the Products or Services with the intent for
    use by any party besides the Customer and the Customer’s Patient, or outside the country to where the Products will
    be shipped by Eon Dental.
    </div>


    <div class="normal_body_text ">
    <span class="bold_text"> 6.2. </span> Customer shall: </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.1. </span> Not breach applicable laws, regulations, professional rules, or
    otherwise lose its ability (including having its professional registration withdrawn or limited) to practice 
    dentistry or orthodontics, or where applicable, operate as a dental clinic;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.2. </span> Be solely and exclusively responsible for the treatment of
    Patients, exercising clinical judgment in the suitability of using the Products and Services 
    (including the Treatment Setup), the outcome of the Customer’s clinical decisions, and maintaining Patient Data;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.3. </span> Properly explain the Products and their clinical risks to
    the Patients before starting treatment; disclose to each Patient that Customer may share Patient
    Data with Eon Dental to facilitate their treatment with Customer’s discretion; and ensure that each
    Patient signs the Consent Form and retain a copy of such Consent Form for their own records;
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.4. </span> Not ship the Products out of the country to which they were shipped by Eon Dental;
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.5. </span> Not allow any other party to use the Products or Services and only use 
    them for the Patient for whom such Products or Services were shipped or provided; and.
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.6. </span> Pay the all sums that are owed to Eon Dental
     when they are due for payment in accordance with Article 10.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 6.3. </span> Customer acknowledges and understands that:
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.1. </span>Eon Dental is a medical device manufacturer, does not provide medical,
    dental or any health care services or advice and is not licensed to do so in any jurisdiction;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.2. </span>Eon Access is a case management portal that renders expected
    teeth movement and the actual results may vary. Some Products and Services incorporate default,
    preset or optional features and must only be considered as a recommendation with the ultimate decision
    to approve and apply a Treatment Setup, change them to specific conditions of each Patient, or reject them
    (subject to any cancelation fee) remaining solely and exclusively with the Customer;
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.3. </span> The Customer must read and understand the Clinician Guide and any 
    updates thereto including all risk factors stated therein;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.4. </span> The Eon Doctor details used to log in to Eon Access may only 
    be used by one single Eon Doctor for the treatment of Patients under the direct care and supervision of
    such Eon Doctor. The use of an Eon Doctor’s login details by any other party is strictly prohibited other
    than (i) by their support staff for the treatment of Patients under the direct care and supervision of
    such Eon Doctor; or (ii) with the express written permission of Eon Dental;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.5. </span> Any sharing of an Eon Doctor login details with any other party other 
    than as specified in Article 6.3.4 may violate Patient privacy rights. Separate login 
    details must be issued for doctors working in the same Clinic; and
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.6. </span> A Case Order constitutes an official purchase order, 
    instructing Eon Dental to produce a Treatment Setup and, where applicable, manufacture the Products, 
    after which time the terms and conditions with respect to cancelation in Article 8, if permitted, apply.
    </div>

    <div class="normal_body_text ">
    <span class="bold_text"> 6.4. </span> Without prejudice to any other remedies available to Eon Dental,
    if the provisions of Article 6.1 and 6.2 are not complied with in any respect throughout the 
    term of this Agreement, Eon Dental in its absolute and sole discretion may:
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.1. </span> Suspend performance of any of its obligations under this Agreement;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.2. </span> Restrict or terminate the Customer’s ability to login to Eon Access; 
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.3. </span>Halt the delivery of the Services or Products;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.4. </span> Revoke and withdraw the permission to use the Eon Marks pursuant to Article 9.4;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.5. </span> Terminate the Agreement in accordance with Article 14 and in which case the provisions of Article 15 shall apply.
    </div>



    <div class="bold_text cpt2 cpb1"> 7. SHIPMENT AND DELIVERY </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.1. </span> Eon Dental shall dispatch and ship the Products within fourteen (14) working days of the Approval
    Date and in accordance with the number of shipments for each Product shown in Article 4.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.2. </span> Any time periods indicated are not binding and are a mere indication
    of the ordering and delivery process. Eon Dental shall not be liable for any liability, losses or damages
    that the Customer may incur as a result of any delays in the shipment and delivery of the Products.
    </div>


    <div class="normal_body_text">
    <span class="bold_text"> 7.3. </span> If the Products are not delivered, Customer will inform Eon Dental
    who will determine the cause of the delay and, if in accordance with this Agreement Eon Dental is under
    an obligation to deliver, Eon Dental will manufacture and ship replacement Products and this will
    be the Customer's sole remedy in respect of an undelivered Product.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.4. </span> Any delay in the delivery of Products shall not relieve
    the Customer of its obligations to accept and pay for the Products.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.5. </span>If Customer fails to accept delivery of the Products within 
    four (4) days of a notice from Eon Dental or the party delivering the Products
    that the Products are ready for delivery, then delivery of the Products will
    be deemed to have been completed on the fifth day after such notice. Eon Dental shall charge Customer
    for redelivery and storage costs, including insurance, but shall have no obligation to redeliver the
    Products seven (7) days after the notice to Customer that the Products are ready for delivery.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.6. </span> The Products will be shipped CIF to the delivery 
    address as indicated in Eon Access, where title and risk of all Products transfer to 
    the Customer at the first party delivering the Products. For the avoidance of doubt,
    Eon Dental shall deliver the Products to the party delivering the Products and Eon Dental 
    shall contract and pay for all delivery and shipping costs to the Customer delivery address indicated in Eon Access.
    </div>



    <div class="bold_text cpt2 cpb1"> 8. CANCELATIONS AND TERMINATION   </div>

    <div class="normal_body_text">
    <span class="bold_text"> 8.1. </span> Eon Dental may cancel an Approved Case at
    any time in its absolute and sole discretion without any liability.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 8.2. </span> Eon Dental shall charge cancelation fees in accordance
    with Article 10 if a Customer (i) cancels a Case Under Processing or (ii) a Case Under 
    Processing does not become an Approved Case within one hundred eighty (180) days from the date
    of the Case Order whether due to missing Patient Data or for any other reason.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 8.3. </span> All sales are final. If a Customer cancels an Approved
    Case for any reason whatsoever, Eon Dental shall not credit or refund any portion 
    of the fees related to that Approved Case and any associated payments shall remain due and payable to Eon Dental.
    </div>

    <div class="bold_text cpt2 cpb1"> 9. INTELLECTUAL PROPERTY RIGHTS AND TRADEMARKS   </div>

    <div class="normal_body_text">
    <span class="bold_text"> 9.1. </span> The Customer agrees that Eon Dental retains, 
    and shall always retain, the sole and exclusive right, title, interest in and to, 
    and ownership of all Intellectual Property Rights related to Eon Dental, Eon Access,
    the Products and Services. 
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 9.2. </span> <span class="bold_text"> “Intellectual Property Rights” </span>
     mean all intellectual property, industrial property, and proprietary rights and assets, and all rights, interests and protections
    that are associated with, similar to, or required for the exercise of, any of the foregoing, however arising, 
    pursuant to the law of any jurisdiction throughout the world, whether registered or unregistered, 
    including any and all (a) patents, patent applications, patent disclosures, and inventions and all
    improvements thereto (whether or not patentable or reduced to practice), and all reissues, renewals,
    continuations, continuations-in-part, revisions, divisional, extensions, and reexaminations in connection 
    therewith, (b) trademarks, service marks, logos, trade dress, trade styles, corporate names, business names
    (including fictitious), trade names, and other indicia of source, and all registrations, applications, 
    recordings, and renewals in connection therewith (together with the goodwill associated therewith) (“<span class="bold_text"> Marks </span>”),
    (c) copyrights and all works of authorship (whether or not copyrightable), and all registrations, applications,
    extensions, restorations, and renewals in connection therewith, regardless of the medium of fixation or means
    of expression (“<span class="bold_text"> Copyrights </span>”), (d) software and firmware, including data files,
    source code, object code, application programming interfaces, architecture, files, records, schematics,
    computerized databases and other  related specifications and documentation (“<span class="bold_text"> Software & Databases </span>”), (e)
    internet domain names and URLs (“<span class="bold_text"> Domain Names </span>”),(f) any Trade Secrets, and (g) know-how, technologies, databases, processes, concepts, data, discoveries, models,
    research and development information, techniques, protocols, methods, formulae, algorithms, layouts, designs, specifications,
    and confidential information.
    </div>


    <div class="normal_body_text">
    <span class="bold_text"> 9.3. </span> No transfer of any Intellectual Property Rights or other 
    proprietary rights are intended by this Agreement. 
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 9.4. </span> Eon Dental agrees to allow the Customer to use the Eon Marks on a 
    royalty-free basis for the sole and limited purpose of promoting the Customer’s business and the use
    of Products within the Customer’s business (“<span class="bold_text"> Customer Promotion </span>”) only if the Customer:
    </div>

    
    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.1. </span> Continues to comply with the obligations set 
    out in Articles 6.1 and 6.2;</div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.2. </span> Uses the Eon Marks as provided by Eon Dental
    without altering the Eon Marks in any way without Eon Dental’s permission;</div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.3. </span>Does not use the Eon Marks for any purpose besides
    Customer Promotion and that such use shall be consistent with the highest professional
    standards and not for any other purpose or manner that Eon Dental may, 
    in its absolute and sole discretion, deem to be inappropriate;</div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.4. </span>Verifies that all Customer Promotion that uses Eon Marks
    complies with applicable laws, regulations and professional rules;</div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.5. </span>Will not permit the use of the Eon Marks by any third party without
    the prior written consent of Eon Dental, and that doing so will be deemed a 
    violation of this permission to use of Eon Marks ;</div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.6. </span>Agrees and acknowledges that
    Eon Dental shall have the right to revoke and terminate this permission 
    at any time upon five (5) days written notice, for any reason or no
    reason whatsoever, and Customer agrees that upon receiving such notice,
    Customer shall cease the distribution of and destroy any materials 
    containing the Eon Marks; and</div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.7. </span>Agrees and acknowledges that Customer 
    shall not be entitled to recover any costs, fees, damages, or other sums from Eon Dental 
    for any claim relating to or arising out of this permission.</div>


    <div class="bold_text cpt2 cpb1"> 10. PRICING AND PAYMENTS    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 10.1. </span>Prices for Products and Services shall be as per
    the price list published by Eon Dental or, as applicable, its authorized reseller 
    at the Approval Date. Eon Dental, or as applicable its authorized reseller,
    reserves the right to update such prices from time to time without prior notice.
    </div>

   <div class="normal_body_text">
   <span class="bold_text"> 10.2. </span>Eon Dental may offer certain Customers benefits 
   and discounts under its Eon Aligner Loyalty Program. The details of these benefits are
   available with the relevant Eon Dental employee or authorized reseller.
   </div>

  <div class="normal_body_text">
  <span class="bold_text"> 10.3. </span> Prices for Products and Services shall not include 
  applicable sales tax, value-add tax, consumption tax, withholding tax or any other 
  similar charges levied by any governmental authority and shall include shipping and insurance. 
  </div>


   <div class="normal_body_text">
   <span class="bold_text"> 10.4. </span> The Customer is responsible for payment of the invoices.
   The Eon Doctor and the Clinic, if named in the invoice or as a matter of practice pays for the 
   invoice(s) issued by Eon Dental, are jointly and severally liable for payment of invoices. Eon 
   Dental may therefore claim payment of any sums owed under any agreement against the Eon Doctor 
   or against the Clinic.
   </div>



  <div class="normal_body_text">
  <span class="bold_text"> 10.5. </span> Amounts due from Customer to Eon Dental under this Agreement
  must be paid in full without any amounts to be set off, deducted, withheld, or offset for any reason 
  whatsoever. If such deductions or withholding are required by law, then the Customer shall gross 
  up such amounts payable to ensure that Eon Dental receives the same amount that it would have received
  had no such amounts been deducted or withheld as a result of the payment. The Customer is responsible 
  for all fees charged by third parties (including outbound remittance or foreign exchange fees charged 
  by the Customer’s banks or financial institutions).
  </div>


  <div class="normal_body_text">
  <span class="bold_text"> 10.6. </span>Customer will pay all Eon Dental invoices as directed in the
  invoice in full and immediately available funds and within the period specified in Article 4, 
  or as otherwise agreed with Eon Dental under a separately negotiated payment plan.
  </div>


   <div class="normal_body_text">
   <span class="bold_text"> 10.7. </span> Eon Dental may in its absolute and sole discretion set a 
   credit limit of value of amounts due by a particular Customer to Eon Dental. In such instances, 
   if a Customer places a new Case Order, the Customer may be required to (i) settle part of the amounts 
   due to Eon Dental earlier than as provided in Article 10.6 of this Agreement, or (ii) make an 
   advanced payment for such new Case Orders.
   </div>


   <div class="normal_body_text">
   <span class="bold_text"> 10.8. </span>In case of delay past the due date of any properly invoiced
   amount to be made by the Customer to Eon Dental under this Agreement: (i) Eon Dental shall be allowed 
   to exercise its rights and remedies available pursuant to Article 6.4; and (ii) the Customer 
   may automatically and without any notice be charged interest at a per monthly rate of one percent (1%)
   in addition to all costs and fees (including reasonable attorney fees) incurred by Eon Dental in
   collecting such overdue amounts.
   </div>


   <div class="bold_text cpt2 cpb1"> 11.INDEMNIFICATION</div>

   <div class="normal_body_text">
   <span class="bold_text"> 11.1. </span> The Customer shall indemnify, defend and save harmless Eon Dental
   from and against any and all direct costs, losses, expenses, or liabilities (including attorney fees)
   incurred by Eon Dental in connection with or resulting from: (i) use of the Products or Services sold under 
   this Agreement, (ii) misrepresentation or breach of warranty by the Customer, or material breach or violation 
   by the Customer of any terms or conditions of this Agreement, (iii) material breach of any term or condition 
   of any agreement between the Customer and any Patient or any acts or failures in respect of a Patient, (iv) 
   Customer's provision of incorrect or incomplete information, including Patient Data to Eon Dental or any 
   failure to timely provide Eon Dental with any information it requests from Customer; and (v) any and all 
   dealings with national regulators, licensing or professional bodies in relation to Customer.
   </div>



   <div class="bold_text cpt2 cpb1"> 12.LIMITATION OF LIABILITY</div>

   <div class="normal_body_text">
   <span class="bold_text"> 12.1. </span> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO 
   CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR 
   OTHERWISE), SHALL ANY PARTY BE LIABLE TO THE OTHER PARTY FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, 
   PUNITIVE, RELIANCE, ENHANCED DAMAGES OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST REVENUE, 
   LOST PROFITS, LOSS OR INTERRUPTION OF BUSINESS OR LOSS OF PATIENT DATA OR OTHER DATA) INCURRED BY THE OTHER 
   PARTY HERETO RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF THE OTHER PARTY 
   HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 12.2. </span> UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN TORT 
   (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE), SHALL EON DENTAL BE LIABLE TO THE CUSTOMER 
   FOR ANY DIRECT DAMAGES IN AN AMOUNT THAT EXCEEDS THE TOTAL AMOUNT PAID TO EON DENTAL FOR THE PARTICULAR 
   PRODUCTS OR SERVICES SOLD FOR WHICH LOSSES OR DAMAGES ARE CLAIMED. </div>
   <div class="normal_body_text">


   <div class="bold_text cpt2 cpb1"> 13.DATA PRIVACY  </div>
   <div class="normal_body_text">
   <span class="bold_text"> 13.1. </span> Each party undertakes, represents, and warrants that it will comply with 
   applicable laws and regulations relating to the collection, use, storage, and protection of any personal data of 
   Customer and Patients (including Patient Data). Customer acknowledges to have read and accepted Eon Dental’s 
   privacy policy available on the Eon Dental website. Each Party will maintain any personal data confidential 
   in accordance with applicable laws, will use any such personal data solely to the extent permitted by this 
   Agreement and applicable laws, and will not use or disclose any such personal data in any manner that would 
   constitute a violation of any applicable laws. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.2. </span> For any and all personal data of Patients (including Patient Data), the 
   Customer is the data controller and Eon Dental is data processor under applicable laws and regulations. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.3. </span> Eon Dental undertakes, represents and warrants that it will use and 
   process any personal data of Customers and Patients (including Patient Data) only for the purpose of performing 
   its obligations under this Agreement. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.4. </span> The Customer agrees that Eon Dental may use Patient Data for promotional, 
   educational, or research and development purposes, publications in professional journals or use in professional 
   collateral materials, provided that such Patient Data has first been anonymized in a way that neither the Customer 
   nor any Patient is identifiable. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.5. </span> Eon Dental shall not, for any purposes other than the performance of this 
   Agreement, access, use, store or process personal data of Customers and Patients (including Patient Data) for any 
   purpose beyond the scope of this Agreement. Eon Dental agrees to protect the privacy and security of any personal 
   data of Customers and Patients (including Patient Data) and to comply with the relevant privacy laws and regulations. 
   </div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.6. </span> The Customer shall as data controller, upon receiving written notification 
   from any Patient or data subject to remove any of their personal data (including Patient Data) or data subjects, 
   notify Eon Dental of such request and Eon Dental shall remove such personal data unless otherwise required by 
   applicable laws and regulations. </div>


   <div class="bold_text cpt2 cpb1"> 14.TERMINATION  </div>

   <div class="normal_body_text">
   <span class="bold_text"> 14.1. </span> Without prejudice to its right to claim full compensation for damages 
   suffered in accordance with the provisions of this Agreement, Eon Dental may terminate this Agreement with 
   immediate effect as follows: </div>

   <div class="normal_body_text custom_padding_start">
   <span class="bold_text "> 14.1.1 </span> Customer breaches any of the terms and conditions
    of this Agreement including Articles 6.1 or 6.2; 
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text "> 14.1.2 </span> Customer becomes insolvent, is declared bankrupt,
    is subject to a liquidation, winding-up or receivership, suspends payment of its due debts or announces its 
    intention to do so, or is unable to meet its obligations under this or any other agreement;
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text "> 14.1.3 </span> Eon Dental considers that the Customer 
    is likely to adversely affect the ongoing, proper, and timely treatment of Patients using the Products 
    and Services, or otherwise not meet its obligations under this Agreement, or Eon Dental’s best interests.
    </div>

    <div class="normal_body_text">
    <span class="bold_text "> 14.2. </span> Eon Dental may terminate this Agreement, anytime, without cause,
    after delivering a 30 days prior written notice to Customer.
    </div>

    <div class="bold_text cpt2 cpb1"> 15.CONSEQUENCES OF TERMINATION  </div>

    <div class="normal_body_text">
    <span class="bold_text "> 15.1. </span> Upon expiration or termination of this Agreement 
    for any reason, Eon Dental shall immediately destroy or return to the Customer, at the Customer’s 
    discretion and expense, all Patient Data; however, Eon Dental shall not be required to return to 
    the Customer or destroy those copies of Patient Data that (A) reside on Eon Dental’s backup, disaster 
    recovery or business continuity systems, or (B) Eon Dental is obligated by applicable laws or regulations to retain. 
    </div>


    <div class="normal_body_text">
    <span class="bold_text "> 15.2. </span> Immediately upon the effective date of a termination of this
     Agreement for any reason, Customer shall:</div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 15.2.1 </span> Pay Eon Dental all outstanding and unpaid amounts due; and</div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 15.2.2 </span> Cease all use of the Eon Marks and any other Intellectual Property 
     Rights related to the Products, and the licenses to use the Eon Marks and the other Intellectual Property 
     Rights granted hereunder will immediately cease and be deemed canceled.</div>


     <div class="bold_text cpt2 cpb1"> 16.MISCELLANEOUS  </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.1. </span> Notices.</div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 16.1.1 </span> Any information under or in relation to this Agreement may 
     be exchanged by email or electronic data interchange.  Any formal notice or communication shall be 
     in writing and shall be deemed given in accordance with Article 16.1.2 if such notice is addressed, 
     for Eon Dental at <a href="mailto:info@eonaligner.com">info@eonaligner.com</a> (with a copy of legal 
     notices to <a href="mailto:legal@eonaligner.com"> legal@eonaligner.com </a>), and for the 
     Customer at either the email address or physical address provided by the Customer in Eon Access. </div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 16.1.2 </span> Any and all notices permitted or required to be given hereunder 
     will be deemed duly effectively given upon receipt when delivered personally; upon the earlier of forty-eight 
     (48) hours or receipt acknowledged by return email when delivered by email; or upon forty-eight (48) 
     hours after being deposited with an industry recognized and reputable overnight international courier 
     service (such as Federal Express or UPS), signature required. </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.2. </span> Relationship
     
     <div class="cpt1"> Nothing contained in this Agreement will be construed as to: (i) 
     create a labor relationship, (ii) constitute the parties as partners, joint venturers, 
     co-owners or otherwise participants in a joint undertaking, (iii) give a party the power 
     to direct and control the day-to-day activities of the other party or its Affiliates; (iv) 
     cause a party to assume any obligation or be held liable for the contractual obligations of 
     the another party, except that the Clinic may assume the obligations of or be held liable for 
     the Eon Doctor; or (v) allow a party to create any obligation on behalf of the other party for 
     any purpose whatsoever, except that the Eon Doctor may create an obligation for the Clinic.</div>
     </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.3. </span> Entire Agreement and Amendments.
     
     <div class="cpt1"> The Agreement including the preamble and recitals constitutes the entire agreement 
     and understanding between the parties with respect to the subject matter of this Agreement and supersedes 
     all previous letters, representations, warranties, or agreements, express or implied, between the Parties 
     relating to the subject matter of the Agreement.</div> 
     <div class="cpt1">
     Eon Dental shall have the right to modify or amend the Agreement from time to time. When Eon Dental makes 
     changes to the Agreement, your continued access to or use of Eon Access, Products and Services will constitute 
     an acceptance of any modification or amendment to the Agreement.
     </div>
     </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.4. </span> Assignment. 
     
     <div class="cpt1">This Agreement is personal to both Eon Dental and the Customer. Eon Dental may assign in whole 
     or in part any of its rights or obligations under this Agreement to any person, without the prior written consent 
     of the Customer. Customer may not assign in whole or in part any of its rights or obligations under this Agreement 
     to any person, without the prior written consent of the Eon Dental. </div>
     </div>


     <div class="normal_body_text">
     <span class="bold_text "> 16.5. </span> No Waiver.
     <div class="cpt1">Eon Dental shall not be considered to have waived any rights under this Agreement by not exercising 
     or delaying the exercise of any rights under Agreement. If Eon Dental waives any right under this Agreement, such waiver 
     will not constitute a waiver of any other or subsequent right, even if all rights under this Agreement are similar in character.
     </div>
     </div>


     <div class="normal_body_text">
     <span class="bold_text "> 16.6. </span> Severability.

     <div class="cpt1">If any provision or covenant in the Agreement is unenforceable, invalid, or contrary to mandatory law, 
     such provision or covenant will be ineffective to the extent of such unenforceability, invalidity, or illegality.  
     The remainder of such provision or covenant, and any other provision or covenant in the Agreement, will remain valid, 
     legal, and enforceable. 
     </div>
     </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.7. </span> Force Majeure.

     <div class="cpt1">This Agreement and all obligations hereunder of Eon Dental shall be suspended for any period 
     (“<span class="bold_text">Period of Suspension</span>”), and Eon Dental will not be liable in any way for failure or delay in performing 
     its obligations under this Agreement during the Period of Suspension due to acts of God, including war or warlike 
     conditions (e.g., terrorism, invasion, riot, or other civil unrest), blockades, international sanctions or embargoes, 
     serious accidents, fires, floods, strikes or lock-outs, lockdowns (including epidemic or pandemic lockdowns), 
     uncontrollable and unforeseen transport interruptions, non-performance by Eon Dental’s subcontractors, suppliers 
     or providers of their respective obligations or failure of software or hardware of said parties, government 
     intervention, civil insurrection, epidemics or pandemics in each case, or any other event beyond Eon Dental’s 
     reasonable control (“<span class="bold_text">Force Majeure</span>”); provided, however, that Eon Dental shall promptly notify the Customer of 
     the Force Majeure. For the avoidance of doubt, any and all obligations to pay money to Eon Dental shall remain in 
     place during any Period of Suspension despite any Force Majeure events.
     </div>
     </div>


     <div class="normal_body_text">
     <span class="bold_text "> 16.8. </span> Governing Law and Jurisdiction.</div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 16.8.1 </span> This Agreement and any non-contractual obligations arising out of or in connection with it shall 
     be governed by and construed in accordance with Belgian law.</div>


     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 16.8.2 </span> Eon Dental and the Customer agree that any dispute, controversy or claim whatsoever arising out of,
     in relation to or in connection with this Agreement that cannot be settled amicably shall be finally settled by the 
     courts of Antwerp, section Antwerp.</div>


     <div class="normal_body_text">
     <span class="bold_text "> 16.9. </span> Prevailing Language.
     
     <div class="cpt1">
     This Agreement has been drafted in English and then translated into Arabic. In case of any shortage, discrepancy, contradiction 
     or difference between the English and the Arabic languages of this Agreement, the English language shall prevail and shall 
     be considered the dominant and applicable language and the reference for interpreting the intention of the parties and the 
     terms of the Agreement.</div>
     </div>

    `;
};
