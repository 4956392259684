import React, { Component } from "react";
import { connect } from "react-redux";
import { successToaster } from "../../services/toast";
import { address_schema } from "../../helpers/addressValidations";
import {
  CardTitle,
  CardFooter,
  Button,
  Card,
  CardImg,
  Label,
  Input,
  FormGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import {
  countries,
  GetCountryLabel,
  mapCountry,
} from "../../helpers/countries";

import { Formik, Form, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import { schema, schema2, schema3 } from "../../helpers/ImpressionsValidations";
import upload_not_active from "../../assets/images/icon/upload_not_active.svg";
import upload_active from "../../assets/images/icon/upload_active.svg";
import {
  upload_impression_data_lower,
  upload_impression_data_upper,
  upload_impression_data,
} from "../../helpers/impressions";
import { CircularProgressbar } from "react-circular-progressbar";
import { uploadFile, keyToUrl } from "../../helpers/s3";
import {
  fixAdjustmentRejections,
  getDoctorAddresses,
  updateAlignerCase,
  createAddress,
} from "../../api/api";
import { mapTeethToApi } from "../../services/mapTeethToApi";
import { errorToaster, infoToaster } from "../../services/toast";
import { mapAdvancedToApi } from "../../services/mapAdvancedToApi";
import { updateImpressions } from "../../redux/CaseSubmission/action";
import CustomeHeader from "../../components/header/CustomeHeader";
import CustomRadioGroup from "../../components/radioGroup/CustomRadioGroup";
import { ReactComponent as Increment } from "../../assets/images/movement/increment.svg";
import { ReactComponent as Decrement } from "../../assets/images/movement/decrement.svg";

import check from "../../assets/images/v4/check_small.svg";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateImpressions: (payload) => dispatch(updateImpressions(payload)),
});
class Impressions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      line1: "",
      line2: "",
      zip: "",
      phone: "",
      state: "",
      country: this.props.userReducer.currentUser.country,
      clinic_name: "",
      city: "",
      id: "",
      createUpdateAddressModal: false,

      validation: false,
      doctorId: props.userReducer.currentUser.id,
      gco_doctor: props.userReducer.currentUser.gco_doctor,
      chose_one: "",
      impressions_method: "",
      impressions_method_arch:
        this.props.caseSubmission.impressions.impressions_method_arch || "",
      loading_percentage: 0,
      intervalID: 0,
      pickup_address_id:
        this.props.caseSubmission.impressions.pickup_address_id || "",
      showProgress: false,
      loading_percentage_lower_arch_url: 0,
      loading_percentage_upper_arch_url: 0,
      impressions_third_party_note: "",
      addresses: this.props.addresses,
      upper_arch_url: "",
      lower_arch_url: "",
      upload: false,
      courier: false,
      third_party: false,
      validation: false,
      sets: this.props.caseSubmission.impressions.sets || 1,

      arch_impression_upper:
        this.props.caseSubmission.impressions.arch_impression_upper || false,
      arch_impression_lower:
        this.props.caseSubmission.impressions.arch_impression_lower || false,
      hover_upper_arch: false,
      hover_lower_arch: false,
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
    this.lower_arch_ref = React.createRef();
    this.upper_arch_ref = React.createRef();
  }

  checkIfChanged = (oldobj, newobj) => {
    if (
      oldobj.impressions_method === "courier" &&
      newobj.impressions_method === "courier" &&
      this.props.rejectable_id
    ) {
      return true;
    } else {
      if (
        oldobj.impressions_method === newobj.impressions_method &&
        oldobj.impressions_third_party_note ===
          newobj.impressions_third_party_note &&
        oldobj.upper_arch_url === newobj.upper_arch_url &&
        oldobj.lower_arch_url === newobj.lower_arch_url &&
        oldobj.pickup_address_id === newobj.pickup_address_id
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.state;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    const { content } = this.props.languageReducer;

    let pickup_address_filtered = this.state.addresses?.filter(
      (v) => v?.id === values?.pickup_address_id
    );

    let impressions = {
      upper_arch_url: values.upper_arch_url,
      lower_arch_url: values.lower_arch_url,
    };
    let filtered_impressions = {};

    for (var key1 in impressions) {
      if (impressions[key1].match(/http([^&]+)/)) {
        filtered_impressions[key1] = impressions[key1];
      }
    }
    let newImpressions = {
      impressions_method: values.impressions_method,
      impressions_third_party_note: values.impressions_third_party_note,
      retainer_request_sets: values.sets,
      arch_impression_upper: values.arch_impression_upper,
      arch_impression_lower: values.arch_impression_lower,
      impressions_method_arch: values.impressions_method_arch,
      lower_arch_url: values.lower_arch_url,
      upper_arch_url: values.upper_arch_url,
      pickup_address_id: values.pickup_address_id,
    };
    let newImpressionsForReducer = {
      impressions_method: values.impressions_method,
      sets: values.sets,
      arch_impression_upper: values.arch_impression_upper,
      arch_impression_lower: values.arch_impression_lower,
      impressions_method_arch: values.impressions_method_arch,
      pickup_address_id: values.pickup_address_id,
      pickup_address: pickup_address_filtered[0],
      impressions_third_party_note:
        values.impressions_method !== "upload"
          ? values.impressions_third_party_note
          : "",
      lower_arch_url:
        values.impressions_method === "upload" ? values.lower_arch_url : "",
      upper_arch_url:
        values.impressions_method === "upload" ? values.upper_arch_url : "",
    };
    let checkimpressions = this.checkIfChanged(
      this.props.caseSubmission.impressions,
      newImpressions
    );
    if (checkimpressions) {
      setSubmitting(true);

      let key =
        this.props.rejectable_type === "Adjustment"
          ? "adjustment"
          : "aligner_case";
      let data = {
        [key]: {
          step: this.props.stepToFix === "summary" ? "summary" : "impressions",
          impressions_method: values.impressions_method,
          impressions_third_party_note: values.impressions_third_party_note,
          retainer_request_sets: values.sets,
          pickup_address_id: values.pickup_address_id,
          arch_impression_upper: values.arch_impression_upper,
          arch_impression_lower: values.arch_impression_lower,
          impressions_method_arch: values.impressions_method_arch,
          ...(values.impressions_method === "upload" && {
            impressions: filtered_impressions,
          }),
          ...(!this.props.rejectable_id ||
            (this.props.case.case_type === "Retainer" && {
              advanced_modules: {
                ...this.props.caseSubmission.advanced_modules,
                ipr: {
                  ...this.props.caseSubmission.advanced_modules.ipr,
                  iprRestriction: mapTeethToApi(
                    this.props.customeTeethReducer.Case_IPR
                  ),
                },
                archExpansion: {
                  ...this.props.caseSubmission.advanced_modules.archExpansion,
                  teeth: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Arch_Expansion
                  ),
                },
                attachments: {
                  ...this.props.caseSubmission.advanced_modules.attachments,
                  attachmentsRestriction: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Attachments
                  ),
                },
                elastics: {
                  ...this.props.caseSubmission.advanced_modules.elastics,
                  teeth: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Elastics
                  ),
                  buttons_teeth: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Elastics_Buttons
                  ),
                  cuts_teeth: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Elastics_Cuts
                  ),
                },
                extraction: {
                  ...this.props.caseSubmission.advanced_modules.extraction,
                  teeth: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Extraction
                  ),
                },
                overCorrection: {
                  ...this.props.caseSubmission.advanced_modules.overCorrection,
                  teeth: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Overcorrections
                  ),
                },
                pontics: {
                  ...this.props.caseSubmission.advanced_modules.pontics,
                  teeth: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Pontics
                  ),
                },
                torqueEnhancers: {
                  ...this.props.caseSubmission.advanced_modules.torqueEnhancers,
                  teeth: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Torque_Enhancers
                  ),
                },
                malocclusion: {
                  ...this.props.caseSubmission.advanced_modules.malocclusion,
                  crossbite: {
                    ...this.props.caseSubmission.advanced_modules.malocclusion
                      .crossbite,
                    teeth: mapTeethToApi(
                      this.props.customeTeethReducer.Case_Crossbites
                    ),
                  },
                },
                all_modules: mapAdvancedToApi(
                  this.props.advancedModules.modules
                ),
              },
            })),
        },
        ...(this.props.rejectable_id && {
          resolve_rejection_id: this.props.rejectable_id,
        }),
      };

      if (this.props.rejectable_type === "Adjustment") {
        fixAdjustmentRejections(
          doctorId,
          caseId,
          this.props.stepToFix_id,
          JSON.stringify(data)
        )
          .then((res) => {
            this.props.rejectable_id
              ? this.props.FixAlignerCase()
              : this.props.NextStep();
            this.props.updateImpressions(newImpressionsForReducer);
            setSubmitting(false);
          })
          .catch((error) => {
            setSubmitting(false);
          });
      } else {
        updateAlignerCase(doctorId, caseId, JSON.stringify(data))
          .then(async (res) => {
            this.props.rejectable_id
              ? this.props.FixAlignerCase()
              : this.props.NextStep();

            this.props.updateImpressions(newImpressionsForReducer);

            setSubmitting(false);
          })
          // .then(async (res1) => {
          //   await this.props.GetAlignerCaseById();
          // })
          .catch((error) => {
            setSubmitting(false);
          });
      }
    } else {
      infoToaster(content.nothing_is_changed, content.Attention_needed);
      this.props.rejectable_id
        ? this.props.FixAlignerCase()
        : this.props.NextStep();
    }
  };

  GetDoctorAddresses = () => {
    getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
      let newAddress = mapCountry(res);

      this.setState({
        addresses: res,
        newAddressArr: newAddress,
        loadData: true,
      });
    });
  };
  clearUrl = (setFieldValue, values) => {
    if (values.arch_impression_lower === false) {
      setFieldValue("lower_arch_url", "");

      this.setState({
        lower_arch_url: "",
      });
    }
    if (values.arch_impression_upper === false) {
      setFieldValue("upper_arch_url", "");

      this.setState({
        upper_arch_url: "",
      });
    }
    if (
      values.arch_impression_lower === false &&
      values.arch_impression_upper === false
    ) {
      setFieldValue("impressions_method", "");
      setFieldValue("upper_arch_url", "");
      setFieldValue("lower_arch_url", "");
      setFieldValue("pickup_address_id", "");
      setFieldValue("impressions_third_party_note", "");

      setFieldValue("impressions_method_arch", "");
      this.setState({
        upper_arch_url: "",
        lower_arch_url: "",
        impressions_third_party_note: "",
        impressions_method: "",
        impressions_method_arch: "",
        pickup_address_id: "",
      });
    }
  };

  /*--------------------------------------------------------------------------------*/
  /* uploading file timer                                             */
  /*--------------------------------------------------------------------------------*/
  timer = (id) => {
    this.setState({
      [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id) => {
    let extention = image.name.split(".");
    return uploadFile({
      name: `case-${
        this.state.caseId
      }/patient-impression/${id}/${new Date().valueOf()}.${
        image.name.split(".")[extention.length - 1]
      }`,
      contentType: image.type,
      file: image,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /*  customStyles for select */
  /* --------------------------------------------------------------------------------*/
  customStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: `8px`,
      padding: "10px",
    }),
    option: (base) => ({
      ...base,
      borderRadius: `8px`,
      height: "100%",
    }),
  };

  /* --------------------------------------------------------------------------------*/
  /* upload photos */
  /* --------------------------------------------------------------------------------*/

  UploadPhoto = async (e, id, setFieldValue) => {
    e.persist();

    if (e.target.files[0]) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      const file = e.target ? e.target.files[0] : e.files[0];
      const { key } = await this.uploadImage(file, id);
      clearInterval(this.state.intervalID);
      this.setState({
        [id]: keyToUrl(key),
        [`loading_percentage_${id}`]: 100,
      });

      setFieldValue(id, keyToUrl(key));
      setFieldValue("chose_one", true);
      setTimeout(() => {
        this.setState({
          [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
          [`loading_percentage_${id}`]: 0,
        });
      }, 500);
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload photos if from drop zone */
  /* --------------------------------------------------------------------------------*/
  UploadPhotoDropZone = async (e, id, setFieldValue) => {
    if (e) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();

      reader.readAsDataURL(e);
      const file = e;
      const { key } = await this.uploadImage(file, id);
      clearInterval(this.state.intervalID);
      this.setState({
        [id]: keyToUrl(key),
        [`loading_percentage_${id}`]: 100,
      });

      setFieldValue(id, keyToUrl(key));
      setFieldValue("chose_one", true);
      setTimeout(() => {
        this.setState({
          [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
          [`loading_percentage_${id}`]: 0,
          [`hover_${id}`]: false,
        });
      }, 500);
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* preventDefault */
  /* --------------------------------------------------------------------------------*/
  preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Over */
  /* --------------------------------------------------------------------------------*/
  dragOver = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: true });
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Enter */
  /* --------------------------------------------------------------------------------*/
  dragEnter = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: true });
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Leave */
  /* --------------------------------------------------------------------------------*/
  dragLeave = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: false });
  };
  /* --------------------------------------------------------------------------------*/
  /* file Drop */
  /* --------------------------------------------------------------------------------*/
  fileDrop = (e, key, setFieldValue) => {
    this.preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length === 1) {
      this.handleFiles(files, key, setFieldValue);
    } else {
      this.setState({ [`hover_${key}`]: false });
      // infoToaster("please add one file ");
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* handle Files */
  /* --------------------------------------------------------------------------------*/
  handleFiles = (files, key, setFieldValue) => {
    const { content } = this.props.languageReducer;

    for (let i = 0; i < files.length; i++) {
      if (this.validateFile(files[i])) {
        this.UploadPhotoDropZone(files[i], key, setFieldValue);
      } else {
        errorToaster(content.file_not_supported, content.Warning);
        this.setState({ [`hover_${key}`]: false });
      }
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* validate File */
  /* --------------------------------------------------------------------------------*/
  validateFile = (file) => {
    let extention = file.name.split(".");
    const validTypes = ["zip", "rar", "7zip", "ply", "stl"];
    if (validTypes.indexOf(file.name.split(".")[extention.length - 1]) === -1) {
      return false;
    }

    return true;
  };
  /* --------------------------------------------------------------------------------*/
  /* file Input Clicked */
  /* --------------------------------------------------------------------------------*/
  fileInputClicked = (input) => {
    // this[`${input}_ref`].current.click();
  };

  toggleCreateUpdateAddressModal = () => {
    this.setState({
      createUpdateAddressModal: !this.state.createUpdateAddressModal,
    });
  };

  handleSubmit2 = (values, { setSubmitting }) => {
    const { content } = this.props.languageReducer;

    setSubmitting(true);
    const data = {
      address: {
        country: this.props.userReducer.currentUser.country,
        city: values.city,
        state: values.state,
        line1: values.line1,
        line2: values.line2,
        zip: values.zip,
        clinic_name: values.clinic_name,
        phone: values.phone,
      },
    };
    // add new address
    createAddress(this.props.id, JSON.stringify(data)).then((res) => {
      this.setState({
        createUpdateAddressModal: !this.state.createUpdateAddressModal,
        country: this.props.userReducer.currentUser.country,
        city: "",
        state: "",
        line1: "",
        line2: "",
        zip: "",
        clinic_name: "",
        phone: "",
      });
      setSubmitting(false);
      this.GetDoctorAddresses();
      successToaster(content.created_new_address_successfully, content.Success);
    });
  };

  //options design in address drop down
  addressOptionLabel = (option) => {
    return (
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.5fr 5fr",
            columnGap: "0.5em",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            color: "#2E2E2F !important",
          }}
        >
          {this.state.shippingAddress === option.value ? (
            <img src={check} alt="" />
          ) : (
            <div></div>
          )}
          <span
            style={{
              color: "#2E2E2F",
            }}
          >
            {option.label}
          </span>
        </div>
      </div>
    );
  };

  componentDidMount() {
    this.GetDoctorAddresses();
  }

  render() {
    const { impressions, patient_info } = this.props.caseSubmission;
    const address = this.props.caseReducer.case?.address || {};
    const { content } = this.props.languageReducer;
    const pickup_address =
      this.props.caseSubmission.impressions.pickup_address || {};

    const {
      gco_doctor,
      sets,
      arch_impression_upper,
      arch_impression_lower,
      impressions_method,
      impressions_method_arch,
      validation,
    } = this.state;

    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];

    const impressions_methods = [
      { name: "upload", title: content.upload_3d_scans },
      { name: "third_party", title: content._3rd_party_integration },
      ...(!gco_doctor
        ? [{ name: "courier", title: content.impression_pickup }]
        : []),
    ];

    const impressions_methods_Arch = [
      { name: "Upload_new_impression", title: "Upload new impression" },
      // { name: "Use_last_step", title: "Use last step" },
    ];

    const ArchImpression = (
      window.location.pathname === "/case-submission" ||
      window.location.pathname === "/case-submission/" ||
      this.props.fixLabel
        ? upload_impression_data
        : window.location.pathname === "/retainer" &&
          arch_impression_lower === true &&
          arch_impression_upper === true
    )
      ? upload_impression_data
      : arch_impression_lower === true
      ? upload_impression_data_lower
      : arch_impression_upper === true
      ? upload_impression_data_upper
      : [];

    return (
      <div>
        <Modal
          isOpen={this.state.createUpdateAddressModal}
          toggle={this.toggleCreateUpdateAddressModal}
          className="c-border-light-address"
        >
          <ModalHeader
            toggle={this.toggleCreateUpdateAddressModal}
          ></ModalHeader>
          <ModalBody>
            <CustomeHeader title={content.new_address} largeFont={false} />

            <Formik
              initialValues={{
                id: "",
                country: GetCountryLabel(this.state.country),
                city: this.state.city,
                line1: this.state.line1,
                line2: this.state.line2,
                zip: this.state.zip,
                clinic_name: this.state.clinic_name,
                phone: this.state.phone,
                state: this.state.state,
              }}
              validationSchema={address_schema}
              onSubmit={this.handleSubmit2}
            >
              {({
                touched,
                errors,
                // isSubmitting,
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} className="cmt2 ">
                  <div className="add-address-height">
                    <Input type="hidden" name="id" id="id" />
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="country" className="address-title">
                            {content.country}{" "}
                          </Label>
                          <Field
                            type="text"
                            name="country"
                            id="country"
                            onBlur={handleBlur}
                            className={`custome-input-text form-control }`}
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="city" className="address-title">
                            {content.city}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            onBlur={handleBlur}
                            className={`custome-input-text form-control ${
                              touched.city && errors.city ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="city"
                            className="invalid-feedback callout-text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="line1" className="address-title">
                            {`${content.street}`}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="line1"
                            id="line1"
                            className={`custome-input-text form-control ${
                              touched.line1 && errors.line1 ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.line1}
                          />
                          <ErrorMessage
                            component="div"
                            name="line1"
                            className="invalid-feedback callout-text"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="line2"
                            className="address-title"
                          >{`${content.building}`}</Label>
                          <span style={{ color: "red" }}>*</span>{" "}
                          <Field
                            type="text"
                            name="line2"
                            id="line2"
                            className={`custome-input-text form-control ${
                              touched.line2 && errors.line2 ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.line2}
                          />
                          <ErrorMessage
                            component="div"
                            name="line2"
                            className="invalid-feedback callout-text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="state" className="address-title">
                            {content.state}
                          </Label>
                          <Field
                            type="text"
                            name="state"
                            id="state"
                            className={`custome-input-text form-control ${
                              touched.state && errors.state ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.state}
                          />
                          <ErrorMessage
                            component="div"
                            name="state"
                            className="invalid-feedback callout-text"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="zip" className="address-title">
                            {content.zip}
                          </Label>
                          <Field
                            type="text"
                            name="zip"
                            id="zip"
                            className={`custome-input-text form-control ${
                              touched.zip && errors.zip ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="zip"
                            className="invalid-feedback callout-text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="phone" className="address-title">
                            {content.phone}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="phone"
                            id="phone"
                            className={`custome-input-text form-control ${
                              touched.phone && errors.phone ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="phone"
                            className="invalid-feedback callout-text"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="clinic_name"
                            className="address-title callout-text"
                          >
                            {content.clinic_name}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            value={values.clinic_name}
                            name="clinic_name"
                            id="clinic_name"
                            className={`custome-input-text form-control ${
                              touched.clinic_name && errors.clinic_name
                                ? "is-invalid"
                                : ""
                            }`}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="clinic_name"
                            className="invalid-feedback callout-text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <div className="add-address-module-patiant">
                        <Button
                          className={`btn light-green-btn pull-right margin-in-address cpx3 button-seme-bold-text`}
                          size="md"
                          type="submit"
                          // type="button"
                          // onClick={() => handleSubmit()}
                        >
                          {`${content.add}`}
                        </Button>
                        <Button
                          className="btn white-btn pull-right margin-in-address cpx3 button-seme-bold-text"
                          size="md"
                          color="danger"
                          onClick={this.toggleCreateUpdateAddressModal}
                        >
                          {content.cancel}
                        </Button>{" "}
                      </div>
                    </FormGroup>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>

        {window.innerWidth > 839 && !this.props.rejectable_id ? (
          <div className="patient-info-header">
            <CustomeHeader title={content.impressions} />
            <span className="patient_info callout-text">
              <div>{patient_info?.full_name}</div>
              <div>
                {" "}
                {content.case} {this.state?.caseId}
              </div>
            </span>
          </div>
        ) : (
          <h1 className="head-mobile-caseSubmission title-3-text">
            {content.impressions}
          </h1>
        )}

        <Formik
          initialValues={{
            impressions_method: impressions.impressions_method,
            impressions_third_party_note:
              impressions.impressions_third_party_note,
            upper_arch_url: impressions.upper_arch_url,
            lower_arch_url: impressions.lower_arch_url,
            chose_one: this.state.chose_one,
            sets: impressions.sets || 1,
            impressions_method_arch: impressions.impressions_method_arch,
            arch_impression_upper: impressions.arch_impression_upper,
            arch_impression_lower: impressions.arch_impression_lower,
            pickup_address_id: impressions.pickup_address_id,
          }}
          // validationSchema={
          //   (this.props.case.case_type === "UnlimitedII" ||
          //     this.props.case.case_type === "LiteII") &&
          //   this.state.impressions_method === "upload"
          //     ? schema2
          //     : this.state.impressions_method === "upload"
          //     ? schema
          //     : schema3
          // }

          validationSchema={() =>
            yup.lazy((values) => {
              if (
                this.props.caseSubmission.patient_info.case_type ===
                  "Eon Pro" ||
                this.props.case.case_type === "UnlimitedII" ||
                (this.props.case.case_type === "LiteII" &&
                  !this.props.case.case_type === "Retainer")
              )
                return schema(this.props, content, values);
              if (this.props.case.case_type === "Retainer") {
                return schema3(this.props, content, values);
              } else return schema2(this.props, content, values);
            })
          }
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,

            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>

              <div className="patient-info-container">
                {window.location.pathname === "/retainer" && (
                  // false &&
                  <div className="Impression_num_set">
                    <div>
                      <p className="custom-title colorful">
                        {content.Number_of_sets}
                      </p>

                      <div className="Number_of_sets">
                        <div>
                          <Increment
                            className="increment-decrement-icon"
                            onClick={() => {
                              setFieldValue("sets", sets + 1);
                              this.setState({ sets: sets + 1 });
                            }}
                          />
                        </div>
                        <div>
                          <Decrement
                            className="increment-decrement-icon"
                            onClick={() => {
                              if (sets > 1) {
                                setFieldValue("sets", sets - 1);
                                this.setState({
                                  sets: this.state.sets - 1,
                                });
                              }
                            }}
                          />
                        </div>
                        <div>
                          {sets} {` `} {content.sets}
                        </div>
                      </div>
                      <div className="line-setNumber">
                        <div>
                          <hr
                            style={{
                              height: "1px",
                              borderWidth: "0",
                              color: "#C8C8CA",
                              backgroundColor: "#C8C8CA",
                            }}
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <div>
                      <p className="custom-title colorful">
                        {content.Arch_Required}
                      </p>

                      <div className="ArchCheckBoxImpression">
                        <div>
                          <input
                            id="checkboxArchImpressionUpper"
                            name="checkboxArchImpressionUpper"
                            className={
                              this.state.arch_impression_upper
                                ? "replacment-checkbox text_malocclusions"
                                : "text_malocclusions"
                            }
                            style={{ pointerEvents: "cursor" }}
                            value={arch_impression_upper}
                            type="checkbox"
                            checked={this.state.arch_impression_upper}
                            label="Upper"
                            onChange={() => {
                              setFieldValue("chose_one", true);
                              setFieldValue(
                                "arch_impression_upper",
                                !arch_impression_upper
                              );
                              this.setState({
                                arch_impression_upper: !arch_impression_upper,
                              });

                              this.clearUrl(setFieldValue, {
                                ...values,
                                arch_impression_upper: !arch_impression_upper,
                              });
                            }}
                          />
                          <label
                            htmlFor="checkboxArchImpressionUpper"
                            className="tw-ea-ml-2"
                          >
                            Upper
                          </label>
                        </div>

                        <div>
                          <input
                            id="checkboxArchImpressionLower"
                            name="checkboxArchImpressionLower"
                            className={
                              this.state.arch_impression_lower
                                ? "replacment-checkbox text_malocclusions"
                                : "text_malocclusions"
                            }
                            style={{ pointerEvents: "cursor" }}
                            type="checkbox"
                            checked={arch_impression_lower}
                            value={this.state.arch_impression_lower}
                            label="Lower"
                            onChange={() => {
                              setFieldValue("chose_one", true);
                              setFieldValue(
                                "arch_impression_lower",
                                !arch_impression_lower
                              );
                              this.setState({
                                arch_impression_lower: !arch_impression_lower,
                              });

                              this.clearUrl(setFieldValue, {
                                ...values,
                                arch_impression_lower: !arch_impression_lower,
                              });
                            }}
                          />
                          <label
                            htmlFor="checkboxArchImpressionLower"
                            className="tw-ea-ml-2"
                          >
                            Lower
                          </label>
                        </div>
                        <div></div>
                      </div>
                      {(values.arch_impression_upper === false ||
                        values.arch_impression_upper === undefined) &&
                      (values.arch_impression_lower === false ||
                        values.arch_impression_lower === undefined) ? (
                        <ErrorMessage
                          component="div"
                          name={"impressions_method"}
                          className="custom-invalid-feedback m-1 "
                        />
                      ) : (
                        ""
                      )}

                      <div className="line-setNumber">
                        <div>
                          <hr
                            style={{
                              height: "1px",
                              borderWidth: "0",
                              color: "#C8C8CA",
                              backgroundColor: "#C8C8CA",
                            }}
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>

                    <div>
                      <div>
                        <p className="custom-title colorful callout-text">
                          {content.impressions}
                        </p>
                        <div className="impression_CRG">
                          <CustomRadioGroup
                            name="impressions_method_arch"
                            checked={impressions_method_arch}
                            vertical="false"
                            value={impressions_method_arch}
                            onChange={(value) => {
                              setFieldValue("impressions_method_arch", value);
                              this.setState({ impressions_method_arch: value });
                            }}
                            options={impressions_methods_Arch}
                            id="impressions_method_arch"
                            retainer={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {impressions_method_arch === "Upload_new_impression" &&
                (arch_impression_lower === true ||
                  arch_impression_upper === true) ? (
                  <div
                    className={`${
                      window.location.pathname === "/case-submission" ||
                      window.location.pathname === "/case-submission/"
                        ? "divider_module-middle "
                        : "line-setNumber"
                    }`}
                  >
                    <div>
                      <hr
                        style={{
                          height: "1px",
                          borderWidth: "0",
                          color: "#C8C8CA",
                          backgroundColor: "#C8C8CA",
                        }}
                      />
                    </div>
                    <div></div>
                  </div>
                ) : (
                  ""
                )}

                {(impressions_method_arch === "Upload_new_impression" &&
                  (arch_impression_lower === true ||
                    arch_impression_upper === true)) ||
                // start new change for retainer //
                // window.location.pathname === "/retainer" ||
                //end new change for retainer //
                window.location.pathname === "/case-submission" ||
                window.location.pathname === "/case-submission/" ||
                this.props.fixLabel ? (
                  <div
                    className={`${
                      window.location.pathname === "/case-submission" ||
                      // start new change for retainer //
                      // window.location.pathname === "/retainer" ||
                      //end new change for retainer //
                      this.props.fixLabel
                        ? "c_impressions_grid "
                        : "c_impressions_grid-retainer"
                    }`}
                  >
                    <div>
                      <div className="custom-title colorful callout-text">
                        {
                          content.select_how_you_would_like_to_submit_your_impressions
                        }
                      </div>
                      {/* <CustomRadioGroup
                        name="impressions_method"
                        checked={values.impressions_method}
                        onChange={(value) => {
                          setFieldValue("impressions_method", value);
                          this.setState({ impressions_method: value });
                          setFieldValue("chose_one", "");
                        }}
                        options={impressions_methods}
                        id="impressions_method"
                      />
                     */}

                      <div className="title-impression title-3-text">
                        {content.digital}
                      </div>
                      <div
                        className="radio-item"
                        style={{
                          pointerEvents: this.props.summary ? "none " : "",
                        }}
                      >
                        <input
                          type="radio"
                          id="upload"
                          checked={values.impressions_method === "upload"}
                          name="impressions_method"
                          style={{
                            pointerEvents: this.props.summary ? "none" : "",
                          }}
                          disabled={this.props.summary}
                          value="upload"
                          onChange={(e) => {
                            setFieldValue("impressions_method", e.target.value);
                            this.setState({
                              impressions_method: e.target.value,
                            });
                            setFieldValue("chose_one", "");
                          }}
                        />
                        <label htmlFor="upload" className="body-text">
                          {content.upload_3d_scans}
                        </label>
                      </div>

                      {/* ///////////////////////////////////// */}
                      <br />
                      <div
                        className="radio-item"
                        style={{
                          pointerEvents: this.props.summary ? "none " : "",
                        }}
                      >
                        <input
                          type="radio"
                          id="third_party"
                          checked={values.impressions_method === "third_party"}
                          name="impressions_method"
                          style={{
                            pointerEvents: this.props.summary ? "none" : "",
                          }}
                          disabled={this.props.summary}
                          value="third_party"
                          onChange={(e) => {
                            setFieldValue("impressions_method", e.target.value);
                            this.setState({
                              impressions_method: e.target.value,
                            });
                            setFieldValue("chose_one", "");
                          }}
                        />
                        <label htmlFor="third_party" className="body-text">
                          {content._3rd_party_integration}
                        </label>
                      </div>
                      {/* ///////////////////////////////////// */}

                      {window.innerWidth > 839 && (
                        <div className="physical_padding_top">
                          <div className="title-impression title-3-text">
                            {content.physical}
                          </div>
                          <div
                            className="radio-item"
                            style={{
                              pointerEvents: this.props.summary ? "none " : "",
                            }}
                          >
                            <input
                              type="radio"
                              id="courier"
                              checked={values.impressions_method === "courier"}
                              name="impressions_method"
                              style={{
                                pointerEvents: this.props.summary ? "none" : "",
                              }}
                              disabled={this.props.summary}
                              value="courier"
                              onChange={(e) => {
                                setFieldValue(
                                  "impressions_method",
                                  e.target.value
                                );
                                this.setState({
                                  impressions_method: e.target.value,
                                });
                                setFieldValue("chose_one", "");
                              }}
                            />
                            <label htmlFor="courier" className="body-text">
                              {content.impression_pickup}
                            </label>
                          </div>
                        </div>
                      )}
                      {window.innerWidth > 839 && (
                        <ErrorMessage
                          component="div"
                          name={"impressions_method"}
                          className="custom-invalid-feedback m-1 text-center"
                        />
                      )}
                    </div>

                    <div
                      className={`${
                        window.location.pathname === "/case-submission" ||
                        window.location.pathname === "/case-submission/" ||
                        this.props.fixLabel
                          ? // start new change for retainer //
                            //||  window.location.pathname === "/retainer"
                            //end new change for retainer //
                            "c-divider "
                          : "none-c-divider"
                      }`}
                    ></div>

                    <div>
                      {values.impressions_method === "upload" && (
                        <div>
                          <div className="upload_impressions_grid">
                            {ArchImpression.map((value, index) => (
                              <div key={value.key}>
                                <Card
                                  onDragOver={(e) =>
                                    this.dragOver(e, value.key)
                                  }
                                  onDragEnter={(e) =>
                                    this.dragEnter(e, value.key)
                                  }
                                  onDragLeave={(e) =>
                                    this.dragLeave(e, value.key)
                                  }
                                  onDrop={(e) =>
                                    this.fileDrop(e, value.key, setFieldValue)
                                  }
                                  onClick={() =>
                                    this.fileInputClicked(value.key)
                                  }
                                  className={`cp2 cmb2 ${
                                    this.state[`hover_${value.key}`]
                                      ? "c-dragover"
                                      : ""
                                  } ${
                                    values[value.key]
                                      ? "c-card-with-img-active"
                                      : "c-card-with-img"
                                  }`}
                                >
                                  <CardTitle className="cmb2">
                                    <div className="card-title-grid">
                                      <div className="body-text">
                                        {values[value.key] ? (
                                          value.title
                                        ) : this.state[
                                            `showProgress_${value.key}`
                                          ] ? (
                                          `Uploading ${value.title}`
                                        ) : (
                                          <span className="not-active-img body-text">
                                            {value.title}{" "}
                                          </span>
                                        )}
                                      </div>
                                      <div>
                                        <img
                                          src={
                                            values[value.key]
                                              ? upload_active
                                              : upload_not_active
                                          }
                                          alt={value.key}
                                        />
                                      </div>
                                    </div>
                                  </CardTitle>

                                  <div className="image-overlay-container">
                                    <label
                                      ref={this[`${value.key}_ref`]}
                                      name={value.key}
                                      htmlFor={value.key}
                                      className="m-0 button-text"
                                    >
                                      <CardImg
                                        src={value.img}
                                        className="upload-impression-img"
                                      />
                                      {values[value.key] && (
                                        <div
                                          className="overlay-loader"
                                          htmlFor={value.key}
                                        >
                                          <div className="layer overlay-loader"></div>
                                        </div>
                                      )}

                                      {this.state[
                                        `showProgress_${value.key}`
                                      ] && (
                                        <div className="overlay-loader">
                                          <CircularProgressbar
                                            className="circule-progress-bar"
                                            value={
                                              this.state[
                                                `loading_percentage_${value.key}`
                                              ]
                                            }
                                            text={`${
                                              this.state[
                                                `loading_percentage_${value.key}`
                                              ]
                                            }%`}
                                          />
                                        </div>
                                      )}
                                    </label>
                                    <input
                                      type="file"
                                      id={value.key}
                                      onChange={(e) => {
                                        this.UploadPhoto(
                                          e,
                                          value.key,
                                          setFieldValue
                                        );
                                      }}
                                      multiple={false}
                                      className="visually-hidden"
                                      accept=".zip,.rar,.7zip,.ply,.stl"
                                    />
                                  </div>
                                </Card>
                                <ErrorMessage
                                  component="div"
                                  name={value.key}
                                  className="custom-invalid-feedback m-1"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/*--------------------------------------------------------------------------------*/}
                      {/* Digital Means Comments */}
                      {/*--------------------------------------------------------------------------------*/}
                      {values.impressions_method === "third_party" && (
                        <div className="form-group content form-block-holder">
                          <label className="custom-title colorful callout-text">
                            {content.provide_links_to_third_party}{" "}
                          </label>
                          <div>
                            <Input
                              autoComplete="off"
                              type="textarea"
                              rows={window.innerWidth < 839 ? "4" : "8"}
                              placeholder={`${content.paste_link} (${content.optional})`}
                              className="custome-textarea custome-input-text "
                              defaultValue={values.impressions_third_party_note}
                              onChange={(e) => {
                                this.setState({
                                  impressions_third_party_note: e.target.value,
                                });
                                setFieldValue(
                                  "impressions_third_party_note",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {values.impressions_method === "courier" &&
                        window.innerWidth > 839 && (
                          <>
                            <div className=" pickup-container">
                              <div className=" subhead-text">
                                {content.courier_note1}
                              </div>
                              <div className=" subhead-text spaces_pickup_inner">
                                {content.courier_note2}{" "}
                              </div>
                              <div className="subhead-bold-text spaces_pickup">
                                {content.courier_requirements}{" "}
                              </div>
                              <ul>
                                <li className=" subhead-text spaces_pickup_inner">
                                  {content.courior_list_1}
                                </li>
                                <li className=" subhead-text spaces_pickup_inner">
                                  {" "}
                                  {content.courior_list_2}{" "}
                                </li>
                                <li className=" subhead-text spaces_pickup_inner">
                                  {content.courior_list_3}
                                </li>
                              </ul>
                            </div>

                            <div className="pickup_address_grid">
                              <Label className="form-title w-100 colorful callout-text">
                                {" "}
                                {content.pickup_address}
                              </Label>
                              {!impressions.pickup_address_id && (
                                <span className=" pl-2">
                                  {`  `}
                                  <span
                                    className="title_active add-new-address"
                                    onClick={() =>
                                      this.toggleCreateUpdateAddressModal()
                                    }
                                  >
                                    {` + ${content.add_new_address}`}
                                  </span>
                                </span>
                              )}
                            </div>

                            <Select
                              className="custome-input-text address-cont select-address-impression-width"
                              styles={this.customStyles}
                              options={this.state.addresses}
                              id="pickup_address_id"
                              name="pickup_address_id"
                              onChange={(e) => {
                                setFieldValue("pickup_address_id", e.value);
                                this.setState({ pickup_address_id: e.value });
                              }}
                              value={
                                impressions?.pickup_address_id !== ""
                                  ? {
                                      value: "000",
                                      label: ` ${GetCountryLabel(
                                        pickup_address?.country
                                      )}, ${pickup_address?.city}, ${
                                        pickup_address?.state
                                      }, ${pickup_address?.line1}, ${
                                        pickup_address?.line2
                                      }, ${pickup_address?.zip}, ${
                                        pickup_address?.phone
                                      }`,
                                    }
                                  : this.state.addresses?.filter(
                                      (v) => v?.id === values?.pickup_address_id
                                    )
                              }
                              isDisabled={impressions?.pickup_address_id !== ""}
                              theme={(theme) => {
                                return {
                                  ...theme,
                                  margin: "8px",
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#00C7B110",
                                    primary: "#C8C8CA50",
                                    primary50: "#00C7B130",
                                  },
                                };
                              }}
                              getOptionLabel={this.addressOptionLabel}
                            />

                            <ErrorMessage
                              component="div"
                              name={"pickup_address_id"}
                              className="custom-invalid-feedback m-1"
                            />
                          </>
                        )}
                    </div>

                    {window.innerWidth < 839 && (
                      <div>
                        <div className="title-impression title-3-text">
                          {content.physical}
                        </div>
                        <div
                          className="radio-item"
                          style={{
                            pointerEvents: this.props.summary ? "none " : "",
                          }}
                        >
                          <input
                            type="radio"
                            id="courier"
                            checked={values.impressions_method === "courier"}
                            name="impressions_method"
                            style={{
                              pointerEvents: this.props.summary ? "none" : "",
                            }}
                            disabled={this.props.summary}
                            value="courier"
                            onChange={(e) => {
                              setFieldValue(
                                "impressions_method",
                                e.target.value
                              );
                              this.setState({
                                impressions_method: e.target.value,
                              });
                              setFieldValue("chose_one", "");
                            }}
                          />
                          <label htmlFor="courier" className="button-text">
                            {content.impression_pickup}
                          </label>
                        </div>
                        {
                          //  window.location.pathname === "/case-submission" &&
                          window.innerWidth < 839 && (
                            <ErrorMessage
                              component="div"
                              name={"impressions_method"}
                              className="custom-invalid-feedback m-1 text-center"
                            />
                          )
                        }
                        {values.impressions_method === "courier" && (
                          <div className=" pickup-container">
                            <div className=" subhead-text">
                              {content.courier_note1}
                            </div>
                            <div className=" subhead-text spaces_pickup_inner">
                              {content.courier_note2}{" "}
                            </div>
                            <div className="subhead-bold-text spaces_pickup">
                              {content.courier_requirements}{" "}
                            </div>
                            <ul>
                              <li className=" subhead-text">
                                {content.courior_list_1}
                              </li>
                              <li className=" subhead-text">
                                {" "}
                                {content.courior_list_2}{" "}
                              </li>
                              <li className=" subhead-text">
                                {content.courior_list_3}
                              </li>
                            </ul>

                            <div className="pickup_address_grid">
                              <Label className="form-title w-100 colorful callout-text">
                                {" "}
                                {content.pickup_address}
                              </Label>
                              {!impressions.pickup_address_id && (
                                <span className=" pl-2">
                                  {`  `}
                                  <span
                                    className="title_active add-new-address"
                                    onClick={() =>
                                      this.toggleCreateUpdateAddressModal()
                                    }
                                  >
                                    {` + ${content.add_new_address}`}
                                  </span>
                                </span>
                              )}
                            </div>

                            <Select
                              className="custome-input-text address-cont select-address-impression-width"
                              styles={this.customStyles}
                              options={this.state.addresses}
                              id="pickup_address_id"
                              name="pickup_address_id"
                              onChange={(e) => {
                                setFieldValue("pickup_address_id", e.value);
                                this.setState({ pickup_address_id: e.value });
                              }}
                              value={
                                impressions?.pickup_address_id !== ""
                                  ? {
                                      value: "000",
                                      label: ` ${GetCountryLabel(
                                        pickup_address?.country
                                      )}, ${pickup_address?.city}, ${
                                        pickup_address?.state
                                      }, ${pickup_address?.line1}, ${
                                        pickup_address?.line2
                                      }, ${pickup_address?.zip}, ${
                                        pickup_address?.phone
                                      }`,
                                    }
                                  : this.state.addresses?.filter(
                                      (v) => v?.id === values?.pickup_address_id
                                    )
                              }
                              isDisabled={impressions?.pickup_address_id !== ""}
                              theme={(theme) => {
                                return {
                                  ...theme,
                                  margin: "8px",
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#00C7B110",
                                    primary: "#C8C8CA50",
                                    primary50: "#00C7B130",
                                  },
                                };
                              }}
                              getOptionLabel={this.addressOptionLabel}
                            />

                            <ErrorMessage
                              component="div"
                              name={"pickup_address_id"}
                              className="custom-invalid-feedback m-1"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
                {window.innerWidth < 840 && (
                  <CardFooter className="container-footer-mobile-fix">
                    {this.props.rejectable_id ? (
                      <div>
                        <div></div>
                        <Button
                          className={`btn light-green-btn float-right  submission-btn button-bold-text`}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Fix
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </CardFooter>
                )}
              </div>

              {window.innerWidth > 839 && (
                <CardFooter className="p-3 container-footer-mobile">
                  {this.props.rejectable_id ? (
                    <div>
                      <div></div>
                      <Button
                        className={`btn light-green-btn float-right  submission-btn button-seme-bold-text`}
                        size="sm"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {content.fix}
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className="order-save-button inmobile">
                        <Button
                          className={`btn light-green-btn float-right m submission-btn  button-seme-bold-text  margin-top`}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {content.next}{" "}
                          <i className="fas fa-angle-right pl-1"></i>
                        </Button>
                      </div>{" "}
                      <Button
                        className={`btn white-btn float-left submission-btn button-seme-bold-text  `}
                        size="sm"
                        type="button"
                        disabled={isSubmitting}
                        onClick={() => this.props.PrevStep()}
                      >
                        <i className="fas fa-angle-left pr-1"></i>{" "}
                        {content.previous}
                      </Button>
                      <div className="order-save-button inWebsit">
                        <Button
                          className={`btn light-green-btn float-right  submission-btn button-seme-bold-text `}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {content.next}{" "}
                          <i className="fas fa-angle-right pl-1"></i>
                        </Button>
                      </div>{" "}
                    </>
                  )}
                </CardFooter>
              )}
              {window.innerWidth < 840 &&
                this.props.rejectable_id === undefined && (
                  <CardFooter className="p-3 container-footer-mobile">
                    <>
                      <div className="order-save-button inmobile">
                        <Button
                          className={`btn light-green-btn float-right m submission-btn  margin-top`}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {content.next}{" "}
                          <i className="fas fa-angle-right pl-1"></i>
                        </Button>
                      </div>{" "}
                      <Button
                        className={`btn white-btn float-left submission-btn button-bold-text custom-mb`}
                        size="sm"
                        type="button"
                        disabled={isSubmitting}
                        onClick={() => this.props.PrevStep()}
                      >
                        <i className="fas fa-angle-left pr-1"></i>{" "}
                        {content.previous}
                      </Button>
                      <div className="order-save-button inWebsit">
                        <Button
                          className={`btn light-green-btn float-right  submission-btn button-bold-text`}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {content.next}{" "}
                          <i className="fas fa-angle-right pl-1"></i>
                        </Button>
                      </div>{" "}
                    </>
                  </CardFooter>
                )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Impressions);
