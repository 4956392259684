import React, { Component, Fragment } from "react";

import { Text, StyleSheet, View, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    paddingTop: 5,
    paddingBottom: 5,
    // borderBottomWidth: 1,
    // borderColor: "#E6E6FA",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "20px"
  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"

  },
  container_num: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center"
  },
  container_top: {
    flexDirection: "row",
    alignItems: "flex-start"

  },
  container2: {
    flexDirection: "row",
  },
  field: {
    flexDirection: "row",
    paddingTop: 10,
  },
  col: {
    flexDirection: "column",
    width: "27px"
    // alignItems: "flex-end",
    // alignSelf: "flex-end"
  },
  col_4: {
    flexDirection: "column",
    width: "40%",
  },
  col_5: {
    flexDirection: "column",
    width: "50%",
  },
  col_1: {
    flexDirection: "column",
    width: "10%",
  },

  title: {
    fontSize: 10,
    textAlign: "left",
    color: "#00c7b1",
    padding: "10px 0 5px 0",
  },
  title2: {
    fontSize: 10,
    textAlign: "left",
    color: "#00c7b1",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
  image: {
    width: "15px",
    height: "auto",
    margin: 1,
    padding: 1,
    //alignSelf: "flex-end"
  },

  green_btn_not_active: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#d7f5f2",
    borderRadius: 8,
    fontSize: 8,
    padding: 2,
    margin: 3,
    flexDirection: "column",
    width: "30%",
  },
  col_title: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    fontSize: 8,
    padding: "0 5px 0 0",
    // margin: 1,
    flexDirection: "column",
    width: "10%",
  },
  green_btn_active: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00c7b1",
    borderRadius: 8,
    fontSize: 8,
    padding: 2,
    margin: 3,
    flexDirection: "column",
    width: "30%",
  },
  text_active: {
    fontSize: 10,
    color: "white",
    padding: 1,
  },
  text_not_active: {
    fontSize: 8,
    color: "#00c7b1",
    padding: 1,
  },
  emplty_col: {
    width: "30%",
  },
  text_area: {
    flexGrow: 1,
    borderColor: "#00c7b1",
    borderWidth: 1,
    borderRadius: 2,
    fontSize: 8,
    padding: 2,
    margin: 3,
    minHeight: "50px",
    flexDirection: "column",
    alignItems: "center"
  },
  value: {
    padding: "10px",
  },
  tooth_num: {
    fontSize: 10,
    width: "15px",
    margin: 1,
    marginRight: "2px",
    textAlign: "center",
    color: "black",
    alignItems: "center"
  },
  divider: {
    borderRightColor: "lightgray",
    borderRightWidth: 1,
    minHeight: "60px",
    // alignSelf: "flex-start",
    // padding: 1
  },
  horizantal_divider: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "gray",
    paddingVertical: 10,
    paddingBottom: 20

  },
  num_container: {
    flexDirection: "row",
    paddingVertical: 10,
    paddingTop: 20
  },
  name: {
    flexDirection: "row",
    width: "100%"
  },
  nameValue: {
    flexDirection: "column",
    //position: "absolute",
    fontSize: 9,
    // right: "200px",
    // top: "-15px",
    color: "gray",
    textAlign: "left",
    marginTop: 5,
    paddingLeft: "5px"

  },
  nameKey: {
    flexDirection: "column",

    //position: "absolute",
    fontSize: 10,
    // right: "200px",
    // top: "-15px",
    color: "#00c7b1",
    textAlign: "left",
    marginTop: 5
  },
  caseValue: {
    flexDirection: "column",
    fontSize: 9,
    color: "gray",
    textAlign: "left",
    marginBottom: 5,
    paddingLeft: "5px"
  },
  caseKey: {
    flexDirection: "column",
    fontSize: 10,
    color: "#00c7b1",
    textAlign: "left",
    marginBottom: 5

  },
  horizantal_line: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    width: "100%",
    marginVertical: 5
  },
  div_container: {
    position: "relative",
    //flexDirection:"column"
    alignItems: "center"
  },
  step: {
    position: "absolute",
    // flexDirection: "row",
    height: "15px",
    width: "15px",
    borderRadius: 50,
    backgroundColor: "#C8C8CA",
    color: "white",
    top: "-15px",
  },
  value: {
    fontSize: 10,
    color: "white",
    textAlign: "center",
    fontWeight: 800,
    paddingTop: "2px"
  },
  amount: {
    position: "absolute",
    // flexDirection: "row",
    height: "15px",
    width: "15px",
    borderRadius: 50,
    backgroundColor: "#898A8D",
    color: "white",
    top: "-33px"
  },
  lower_step: {
    position: "absolute",
    // flexDirection: "row",
    height: "15px",
    width: "15px",
    borderRadius: 50,
    backgroundColor: "#C8C8CA",
    color: "white",
    bottom: "-33px",
  },
  lower_amount: {
    position: "absolute",
    // flexDirection: "row",
    height: "15px",
    width: "15px",
    borderRadius: 50,
    backgroundColor: "#898A8D",
    color: "white",
    bottom: "-15px"
  },
  exeeding_lower: {
    position: "absolute",
    // flexDirection: "row",
    height: "15px",
    width: "15px",
    borderRadius: 50,
    backgroundColor: "#ff5c00",
    color: "white",
    bottom: "-15px"
  },
  exeeding_upper: {
    position: "absolute",
    // flexDirection: "row",
    height: "15px",
    width: "15px",
    borderRadius: 50,
    backgroundColor: "#ff5c00",
    color: "white",
    top: "-33px"
  },
  right: {
    position: "absolute",
    fontSize: 8,
    left: "-30px"
  },
  left: {
    position: "absolute",
    fontSize: 8,
    right: "-25px"
  }
});
export default class IPRTeethPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  render() {
    const { teeth } = this.props;
    const { content, patient, case_data } = this.props



    return (
      <Fragment>
        <View style={styles.row}>
          <View style={styles.container}>
            {Object.keys(teeth.upper_teeth)
              .map((prop, index) => {
                return (
                  <View style={styles.container} key={index}>
                    <Image
                      style={styles.image}
                      src={`${
                        teeth.upper_teeth[prop].restrict_ipr_tooth
                          ? teeth.upper_teeth[prop].restrect_pdf
                          : teeth.upper_teeth[prop].missing_ipr_tooth
                          ? teeth.upper_teeth[prop].missing_pdf
                          : teeth.upper_teeth[prop].image_pdf
                      }`}
                    />
                    {
                      teeth.upper_teeth[prop].ToothSystemName.fdi !== 28 &&
                      <View style={styles.div_container}>
                        {
                          teeth.upper_teeth[prop].ipr_steps_amounts[0].step !== 0 &&
                          <View style={styles.step}>
                            <Text style={styles.value}>{teeth.upper_teeth[prop].ipr_steps_amounts[0].step}</Text>
                          </View>
                        }
                        {
                          teeth.upper_teeth[prop].ipr_steps_amounts[0].amount !== 0 &&
                          <View style={teeth.upper_teeth[prop].ipr_steps_amounts[0].amount > 0.5 ? styles.exeeding_upper : styles.amount}>
                            <Text style={styles.value}>{teeth.upper_teeth[prop].ipr_steps_amounts[0].amount < 1 ? teeth.upper_teeth[prop].ipr_steps_amounts[0].amount.toString().replace(/^0+/, "") : teeth.upper_teeth[prop].ipr_steps_amounts[0].amount}</Text>
                          </View>
                        }
                        <View style={styles.divider}></View>
                      </View>
                    }
                  </View>);
              })}
          </View>
          <View style={styles.container_num}>
            <Text style={styles.tooth_num}>1</Text>
            <Text style={styles.tooth_num}>2</Text>
            <Text style={styles.tooth_num}>3</Text>
            <Text style={styles.tooth_num}>4</Text>
            <Text style={styles.tooth_num}>5</Text>
            <Text style={styles.tooth_num}>6</Text>
            <Text style={styles.tooth_num}>7</Text>
            <Text style={styles.tooth_num}>8</Text>
            <Text style={styles.tooth_num}>9</Text>
            <Text style={styles.tooth_num}>10</Text>
            <Text style={styles.tooth_num}>11</Text>
            <Text style={styles.tooth_num}>12</Text>
            <Text style={styles.tooth_num}>13</Text>
            <Text style={styles.tooth_num}>14</Text>
            <Text style={styles.tooth_num}>15</Text>
            <Text style={styles.tooth_num}>16</Text>
          </View>
          <Text style={styles.right}>RIGHT</Text>
          <Text style={styles.left}>LEFT</Text>

          <View style={styles.name}>
            <Text style={styles.nameKey}>Patient Name:</Text>
            <Text style={styles.nameValue}>{patient.first_name} {patient.last_name}</Text>
          </View>
          <View
            style={styles.horizantal_line}
          />
          <View style={styles.name}>
            <Text style={styles.caseKey}>Case Number:</Text>
            <Text style={styles.caseValue}>{case_data.uid}</Text>
          </View>
          <View style={styles.container_num}>
            <Text style={styles.tooth_num}>32</Text>
            <Text style={styles.tooth_num}>31</Text>
            <Text style={styles.tooth_num}>30</Text>
            <Text style={styles.tooth_num}>29</Text>
            <Text style={styles.tooth_num}>28</Text>
            <Text style={styles.tooth_num}>27</Text>
            <Text style={styles.tooth_num}>26</Text>
            <Text style={styles.tooth_num}>25</Text>
            <Text style={styles.tooth_num}>24</Text>
            <Text style={styles.tooth_num}>23</Text>
            <Text style={styles.tooth_num}>22</Text>
            <Text style={styles.tooth_num}>21</Text>
            <Text style={styles.tooth_num}>20</Text>
            <Text style={styles.tooth_num}>19</Text>
            <Text style={styles.tooth_num}>18</Text>
            <Text style={styles.tooth_num}>17</Text>
          </View>
          <View style={styles.container_top}>
            {Object.keys(teeth.lower_teeth)
              .map((prop, index) => {
                return (
                  <View style={styles.container_top} key={index}>
                    <Image
                      style={styles.image}
                      src={`${
                        teeth.lower_teeth[prop].restrict_ipr_tooth
                          ? teeth.lower_teeth[prop].restrect_pdf
                          : teeth.lower_teeth[prop].missing_ipr_tooth
                          ? teeth.lower_teeth[prop].missing_pdf
                          : teeth.lower_teeth[prop].image_pdf
                      }`}
                    />
                    {
                      teeth.lower_teeth[prop].ToothSystemName.fdi !== 38 &&
                      <View style={styles.div_container}>
                        {
                          teeth.lower_teeth[prop].ipr_steps_amounts[0].amount !== 0 &&
                          <View style={teeth.lower_teeth[prop].ipr_steps_amounts[0].amount > 0.5 ? styles.exeeding_lower : styles.lower_amount}>
                            <Text style={styles.value}>{teeth.lower_teeth[prop].ipr_steps_amounts[0].amount < 1 ? teeth.lower_teeth[prop].ipr_steps_amounts[0].amount.toString().replace(/^0+/, "") : teeth.lower_teeth[prop].ipr_steps_amounts[0].amount}</Text>
                          </View>
                        }
                        {
                          teeth.lower_teeth[prop].ipr_steps_amounts[0].step !== 0 &&
                          <View style={styles.lower_step}>
                            <Text style={styles.value}>{teeth.lower_teeth[prop].ipr_steps_amounts[0].step}</Text>
                          </View>
                        }
                        <View style={styles.divider}></View>
                      </View>
                    }
                  </View>);
              })}
          </View>
        </View>
      </Fragment>
    );
  }
}
