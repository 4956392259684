import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import Crossbite from "./Malocclusion/Crossbite";
import Spacing from "./Malocclusion/Spacing";
import ClassII from "./Malocclusion/ClassII";
import ClassIII from "./Malocclusion/ClassIII";
import Openbite from "./Malocclusion/Openbite";
import Deepbite from "./Malocclusion/Deepbite";
import Midline from "./Malocclusion/Midline";
import Crowding from "./Malocclusion/Crowding";


const styles = StyleSheet.create({
    row: {
        paddingTop: 10,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingBottom: 10,

    },

    header: {
        fontSize: 12,
        textAlign: "left",
        color: "#00a78c",
        width: "100%",
        paddingBottom: 10,
        borderBottomWidth: 1.2,
        borderColor: "#00a78c",
    },
    header2: {
        fontSize: 12,
        textAlign: "left",
        color: "#00a78c",
        width: "100%",
        paddingBottom: 10,
        paddingTop: 10,

    },
    container: {
        flexDirection: "row",
        justifyContent: "flex-start",
        borderBottomWidth: 1,
        borderColor: "#E6E6FA",
        paddingBottom: 15,
        paddingTop: 10,
    },
    container2: {
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 5,
    },
    text_area: {
        flexGrow: 1,
        borderColor: "#a0f0e7",
        borderWidth: 1,
        borderRadius: 2,
        fontSize: 8,
        padding: 2,
        margin: 3,
        minHeight: "70px",
        flexDirection: "column",
    },
    field: {
        flexDirection: "row",
        paddingTop: 10,
    },
    col: {
        flexDirection: "column",
        width: "50%",
    },
    col_4: {
        flexDirection: "column",
        width: "40%",
    },
    col_5: {
        flexDirection: "column",
        width: "50%",
    },
    col_55: {
        flexDirection: "column",
        width: "55%",
    },
    col_6: {
        flexDirection: "column",
        width: "60%",
    },
    col_1: {
        flexDirection: "column",
        width: "10%",
    },
    col_3: {
        flexDirection: "column",
        width: "30%",
    },
    col_7: {
        flexDirection: "column",
        width: "70%",
    },
    col_15: {
        flexDirection: "column",
        width: "15%",
    },
    col_25: {
        flexDirection: "column",
        width: "25%",
    },
    col_05: {
        flexDirection: "column",
        width: "5%",
    },
    title: {
        fontSize: 10,
        textAlign: "left",
        color: "#00a78c",
        padding: "10px 0 5px 0",
    },
    title2: {
        fontSize: 10,
        textAlign: "left",
        color: "#00a78c",
    },
    info: {
        fontSize: 10,
        textAlign: "left",
    },
    image: {
        // backgroundColor: "red",
        borderRadius: 4,
    },

    green_btn_not_active: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#d7f5f2",
        borderWidth: 1,
        backgroundColor: "white",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    hybrid_green_btn_not_active: {
        width: "auto",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#d7f5f2",
        borderWidth: 1,
        backgroundColor: "white",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    col_title: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        fontSize: 8,
        padding: "0 5px 0 0",
        // margin: 1,
        flexDirection: "column",
        width: "10%",
    },
    green_btn_active: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00a78c",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    hybrid_green_btn_active: {
        width: "auto",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00a78c",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    emplty_col: {
        width: "30%",
    },
    text_active: {
        fontSize: 8,
        color: "white",
        padding: 1,
    },
    text_not_active: {
        fontSize: 8,
        color: "#00a78c",
        padding: 1,
    },
    teeth_border: {
        borderWidth: 1,
        borderColor: "#00a78c",
    },
   
    photo_header: {
        flexDirection: "column",
        width: "100px",
        margin: 5,
        fontSize: 8,
        color: "#00a78c",
        textAlign: "center",
    },
    value: {
        padding: "10px",
    },
    photo: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
        zIndex: 99,
    },
    space: {
        flexDirection: "column",
        width: "10%",
    },
    active_card: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
    },
    card: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
        backgroundColor: "white",
    },
    hybrid: {
        flexDirection: "column",
        width: "90%",
        height: "auto",
        padding: "50px",
    },
    hybrid_header: {
        flexDirection: "column",
        width: "100%",
        margin: 5,
        fontSize: 8,
        color: "#00a78c",
        textAlign: "left",
    },
    buttons_container: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        paddingBottom: 5,
    },
    hybrid_subTitle: {
        fontSize: 8,
        color: "#00a78c",
        width: "30px",
        flexDirection: "column",
        margin: 5,
    },
    row_space: {
        flexDirection: "row",
        height: "10px",
    },
    row_space2: {
        flexDirection: "row",
        height: "10%",
    },
});

export default class AdvancedModules4 extends Component {
    constructor(props) {
        super(props);
        this.state = {

            filterd_malocclusions: []
        };
    }
    componentDidMount = () => {
        let malocclusions = [
            {
                key: "crossbite", active: this.props.mappedData.advanced_modules.malocclusion.crossbite_btn
            },
            {
                key: "spacing", active: this.props.mappedData.advanced_modules.malocclusion.spacing_btn
            },
            {
                key: "classII", active: this.props.mappedData.advanced_modules.malocclusion.classII_btn
            },
            {
                key: "classIII", active: this.props.mappedData.advanced_modules.malocclusion.classIII_btn
            },
            {
                key: "openbite", active: this.props.mappedData.advanced_modules.malocclusion.openbite_btn
            },
            {
                key: "deepbite", active: this.props.mappedData.advanced_modules.malocclusion.deepbite_btn
            },
            {
                key: "midline", active: this.props.mappedData.advanced_modules.malocclusion.midline_btn
            },
            {
                key: "crowding", active: this.props.mappedData.advanced_modules.malocclusion.crowding_btn || false
            }
        ]


        let filtered = malocclusions.filter((value, index) => {
            return value.active === true
        })
        this.setState({ filterd_malocclusions: filtered })

    }

    test = (malocclusions) => {
        let x
        if (malocclusions.length > 6) {
            x = this.splitToChunks(malocclusions, 3);
            return x
        }
        if (malocclusions.length > 3 && malocclusions.length <= 6) {
            x = this.splitToChunks(malocclusions, 2)
            return x

        } else {
            x = this.splitToChunks(malocclusions, 1)
            return x

        }
    }
    render() {
        let { filterd_malocclusions } = this.state
        const { content } = this.props

        return (
            <Fragment>
                <View style={styles.row}>
                    <Text style={styles.header2}>{`Malocclusion`}</Text>
                    {
                        filterd_malocclusions.map((value, index) => (
                            <View key={index}>
                                {
                                    value.key === "crossbite" && <Crossbite content={content} malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                }

                                {
                                    value.key === "spacing" && <Spacing content={content} malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                }
                                {
                                    value.key === "classII" && <ClassII content={content} malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                }

                                {
                                    value.key === "classIII" && <ClassIII content={content} malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                }

                                {
                                    value.key === "openbite" && <Openbite content={content} malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                }

                                {
                                    value.key === "deepbite" && <Deepbite content={content} malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                }
                                {
                                    value.key === "midline" && <Midline content={content} malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                }
                                {
                                    value.key === "crowding" && <Crowding content={content} malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                }

                                {
                                    index === 2 && <View style={styles.row_space2}></View>
                                }
                            </View>
                        ))
                    }
                </View>
            </Fragment>
        );
    }
}
