export const getPriceQAAR = () => {
  return `
    <div class="header_text_bold">إيون بيسك</div>
    <div class="bold_text cpy1"> حزمة العلاج والسعر</div>
  
    <div class="cpy1">
    <div class="normal_text">قوس فردي</div>
    <div class="normal_text">1,917 ريال قطري
    </div>
    </div>
  
    <div class="cpy1">
    <div class="normal_text">قوس مزدوج: </div>
    <div class="normal_text">2,738 ريال قطري
    </div>
    </div>
  
    <div class="bold_text cpy1">خطة العلاج </div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">ما يصل إلى أربعة (4) خطط علاج خاصة بكل من خطوات المصفف الأولية والتحسينات. </div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text">خطط علاج إضافية والتي سيتم فرض رسوم تبلغ 356 ريال قطري على كل منها.
    </div>
  
  
    <div class="bold_text cpy1">خطوات مصفف </div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">شحن ما يصل إلى عشر (10) من خطوات مصفف أولية في شحنة واحدة (1) واحدة</div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text"> خطوات مصفف بديلة والتي سيتم فرض رسوم عليها تبلغ 55 ريال قطري لكل قوس أسنان إلى جانب رسوم 182 ريال قطري الشحن.</div>
  
    <div class="bold_text cpy1">التحسينات</div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">توفير التحسينات في شحنة واحدة (1).</div>
  
    <div class="bold_text cpy1">مجموعات المثبتات</div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">شحن مجموعة مثبت واحدة (1) في شحنة واحدة (1). </div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text">مثبتات إضافية، حيث سيتم فرض رسوم عليها تبلغ 128 ريال قطري لكل قوس أسنان إلى جانب 182 ريال قطري رسوم الشحن</div>
  
    <div class="bold_text cpy1">تاريخ انتهاء الصلاحية</div>
    <div class="normal_text">تسعة (9) شهور</div>
  
  
  
  
    <div class="header_text_bold">إيون بلس </div>
    <div class="bold_text cpy1">حزمة العلاج والسعر   </div>
  
    <div class="cpy1">
    <div class="normal_text">قوس فردي:    </div>
    <div class="normal_text">3,705 ريال قطري
    </div>
    </div>
  
    <div class="cpy1">
    <div class="normal_text">قوس مزدوج:    </div>
    <div class="normal_text">5,293 ريال قطري
    </div>
    </div>
  
    <div class="bold_text cpy1">خطة العلاج    </div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">ما يصل إلى أربعة (4) خطط علاج خاصة بكل من خطوات المصفف الأولية والتحسينات.     </div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text">خطط علاج اضافية والتي سيتم فرض رسوم تبلغ 356 ريال قطري على كل منها.

    </div>
  
  
    <div class="bold_text cpy1">خطوات مصفف   </div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">شحن ما يصل إلى اربع وعشرين (24) من خطوات مصفف أولية في شحنة واحدة (1) واحدة    </div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text">خطوات مصفف بديلة والتي سيتم فرض رسوم عليها تبلغ 55 ريال قطري لكل قوس أسنان إلى جانب 182 ريال قطري رسوم الشحن.</div>
  
    <div class="bold_text cpy1">التحسينات    </div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">توفير التحسينات في ثلاث (3) شحنات منفصلة بحد أقصى    </div>
  
    <div class="bold_text cpy1"> مجموعات المثبتات    </div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">شحن مجموعة مثبت واحدة (1) في شحنة واحدة (1).  </div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text">مثبتات إضافية، حيث سيتم فرض رسوم عليها تبلغ 128 ريال قطري لكل قوس أسنان إلى جانب 182 ريال قطري رسوم الشحن.</div>
  
    <div class="bold_text cpy1">تاريخ انتهاء الصلاحية</div>
    <div class="normal_text">ثمانية عشر (18) شهرًا</div>

    
  
    <div class="header_text_bold">إيون برو    </div>
    <div class="bold_text cpy1">حزمة العلاج والسعر    </div>
  
    <div class="cpy1">
    <div class="normal_text">6,900 ريال قطري
    </div>
    </div>
   
    <div class="bold_text cpy1">خطة العلاج   </div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">عدد غير محدود من خطط العلاج لكل من خطوات مصففات أولية والتحسينات.    </div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text">خطط العلاج التي تتجاوز
   "سياسة الاستخدام العادل" والتي سيتم فرض رسوم تمديد الحالة عليها وفقًا لإحدى حزمة العلاج المناسبة. </div>
  
  
    <div class="bold_text cpy1">خطوات مصفف    </div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">شحن عدد غير محدود من خطوات مصفف أولية بمعدل لا يزيد عن أربع وعشرين (24) من خطوات مصفف للشحنة الواحدة.</div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text">خطوات مصفف التي تتجاوز "سياسة الاستخدام العادل" والتي سيتم فرض رسوم تمديد الحالة عليها وفقًا لإحدى حزمة العلاج المناسبة.

    <span class="cpt1">خطوات مصفف بديلة والتي سيتم فرض رسوم عليها تبلغ 55 ريال قطري لكل قوس أسنان إلى جانب 182 ريال قطري رسوم الشحن.</span>
    </div>
  
    <div class="bold_text cpy1">التحسينات</div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">توفير التحسينات في عدد غير محدود من الشحنات بمعدل لا يزيد عن أربع وعشرين (24) من خطوات مصفف للشحنة الواحدة.</div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text">التحسينات التي تتجاوز "سياسة الاستخدام العادل" والتي سيتم فرض رسوم تمديد الحالة عليها وفقًا لإحدى حزمة العلاج المناسبة</div>
  
    <div class="bold_text cpy1"> مجموعات المثبتات    </div>
    <div class="normal_text_underlined" >يشمل: </div>
    <div class="normal_text cpb1">شحن مجموعتي مثبت (2) معًا في شحنة واحدة . </div>
  
    <div class="normal_text_underlined" >لا يشمل: </div>
    <div class="normal_text">مثبتات إضافية، حيث سيتم فرض رسوم عليها تبلغ 128 ريال قطري لكل قوس أسنان إلى جانب 182 ريال قطري رسوم الشحن.</div>
  
    <div class="bold_text cpy1">تاريخ انتهاء الصلاحية    </div>
    <div class="normal_text">ستة وثلاثون (36) شهرًا    </div>
  
    <div class="header_text_bold">رسوم الإلغاء    </div>
    <div class="normal_text">356 ريال قطري
    </div>

    <div class="header_text_bold">رسوم تغيير الطبعات السِنيَّة
    </div>
    <div class="normal_text">سيتم فرض رسوم تبلغ 182 ريال قطري على الطبعات السِنيَّة الملموسة اللاحقة التي تحل محل تلك التي لا تتطابق مع الشكل والجودة المقبولة من قبل إيون دنتال.</div>
    `;
};
