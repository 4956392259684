import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import CustomToggle from "../../../../../components/toggle/CustomeToggle";
import CustomeHeader from "../../../../../components/header/CustomeHeader";
import CustomRadioGroup from "../../../../../components/radioGroup/CustomRadioGroup";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class ToothSizeDiscrepancy extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_address_discrepancy: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.use_address_discrepancy,
      leaveSpaceAroundLaterals: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.leaveSpaceAroundLaterals,
      leaveSpaceDistalLaterals: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.leaveSpaceDistalLaterals,
      iprOpposing: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.iprOpposing,
      other: this.props.caseSubmission.advanced_modules.toothSizeDiscrepancy
        .other,
      other_details: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.other_details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "toothSizeDiscrepancy",
      data: {
        use_address_discrepancy: this.state.use_address_discrepancy,
        leaveSpaceAroundLaterals: this.state.leaveSpaceAroundLaterals,
        leaveSpaceDistalLaterals: this.state.leaveSpaceDistalLaterals,
        iprOpposing: this.state.iprOpposing,
        other: this.state.other,
        other_details: this.state.other_details,
      },
    });
    this.props.save();
  };
  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const {
      use_address_discrepancy,
      leaveSpaceAroundLaterals,
      leaveSpaceDistalLaterals,
      iprOpposing,
      other,
      other_details,
    } = this.state;
    const { content } = this.props.languageReducer


    
    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">
       


          <div className="customHeaderTS">
            <div>  <CustomeHeader title={content.tooth_size_discrepancy} className="cp3 pt-0" optional={false} note={false} note_text={content.tooth_size_discrepancy_note} /></div>
            <div className="reative-close-ts">  <span className={` pull-right ts-close-button-new`} onClick={() => { this.props.toggleModalsClose(this.props.active_module) }}>&times;</span></div>
          </div>
          <div className="admin-Grid-Advance">
            <div></div>
            {/* part 1 */}
            <div className=" admin-Grid-Advance-inner">
              <div >

              {content.Used_Tooth_Size_Discrepancy_for_this_case}{" "}

              </div>
              {/* part 2 */}
              <div className="D-total-cont-toggle">
              <div className="pointer_disable">
                <CustomToggle

                  id="use_address_discrepancy"
               

                  checked={use_address_discrepancy}
                  onChange={() => {
                    this.setState({
                      use_address_discrepancy: !use_address_discrepancy,
                      ...use_address_discrepancy && {
                        leaveSpaceAroundLaterals: false,
                        leaveSpaceDistalLaterals: false,
                        iprOpposing: false,
                        other: false,
                        other_details: ""
                      }
                    });
                  }}
                />
              </div>
              </div>
            </div>

            <div></div>
          </div>


          <div className="address_discrepancy   D-total-cont-checkBox">
        

         





         



          <div className="radio-item  text_malocclusions" style={{ pointerEvents:  "none" }}>
              <input type="radio"
                id='leaveSpaceAroundLateralsTS'
          
                name='leave Space Around Laterals'
                style={{ pointerEvents: this.props.summary ? "none" : "none" }}
                checked={this.state.leaveSpaceAroundLaterals}
                value={leaveSpaceAroundLaterals}
                onClick={() => {
                  // this.setState({
                  //   synchronize: "end"
                  // });
                }}
              />
              <label htmlFor='leaveSpaceAroundLateralsTS' >   {content.leave_spaces_around_the_lateral}</label>
            </div>








          
            <div className="radio-item  text_malocclusions" style={{ pointerEvents:  "none" }}>
              <input type="radio"
                id='leaveSpaceDistalLateralsTS'
             
                name='leave Space Distal Laterals'
                style={{ pointerEvents: this.props.summary ? "none" : "none" }}
                checked={this.state.leaveSpaceDistalLaterals}
                value={leaveSpaceDistalLaterals}
                onClick={() => {
                  // this.setState({
                  //   synchronize: "end"
                  // });
                }}
              />
              <label htmlFor='leaveSpaceDistalLateralsTS' >   {content.leave_space_distal_to_lateral}</label>
            </div>


           


            <div className="radio-item  text_malocclusions" style={{ pointerEvents:  "none" }}>
              <input type="radio"
                id='iprOpposingTS'
                // className={`${synchronize === "start" && `}
                name='ipr Opposing'
                style={{ pointerEvents: this.props.summary ? "none" : "none" }}
                checked={this.state.iprOpposing}
                value={iprOpposing}
                onClick={() => {
                  // this.setState({
                  //   synchronize: "end"
                  // });
                }}
              />
              <label htmlFor='iprOpposingTS' >   {content.ipr_opposing}</label>
            </div>

           

            <div className="radio-item  text_malocclusions" style={{ pointerEvents:  "none" }}>
              <input type="radio"
                id='otherTS'
                // className={`${synchronize === "start" && `}
                name='other'
                style={{ pointerEvents: this.props.summary ? "none" : "none" }}
                checked={this.state.other}
                value={other}
                onClick={() => {
                  // this.setState({
                  //   synchronize: "end"
                  // });
                }}
              />
              <label htmlFor='otherTS' > {content.other}</label>
            </div>
          </div>

          {other && (
            <div className="">
              <p className="control-label text-left  light-green D-head-ts">{content.details}</p>
              <div className="disable-input">
              <Input
                className="mt-2  border-radius backgrountTextarea overFlow"
                name="other_details"
                id="other_details"
                type="textarea"
                placeholder="Please add details"
                rows={5}
                value={other_details}
                onChange={(e) => {
                  this.setState({
                    other_details: e.target.value,
                  });
                }}
                readOnly
              />
              </div>
            </div>
          )}
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
          </Button>
        }
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToothSizeDiscrepancy);
