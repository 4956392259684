import * as yup from "yup";

export const schemaRequest_final = (content, values) => {
  return yup.object({
    impressions_method:
      values.impressions_method_arch === "Use_last_step"
        ? yup.string()
        : yup
            .string()
            .required(content.Please_choose_one_of_Impression_Methodes),
    lower_arch_url:
      values.arch_impression_lower === true &&
      values.impressions_method === "upload"
        ? yup.string().required(content.lower_arch_required)
        : yup.string(),
    upper_arch_url:
      values.arch_impression_upper === true &&
      values.impressions_method === "upload"
        ? yup.string().required(content.upper_arch_required)
        : yup.string(),
    sets: yup.number(),

    impressions_method_arch: yup.string(),
    ArchImpressionUpper: yup.boolean(),
    ArchImpressionLower: yup.boolean(),
  });
};

export const schema = (props, content, values) => {
  return yup.object({
    impressions_method:
      values.impressions_method === "" ||
      values.impressions_method === undefined
        ? yup
            .string()
            .required(content.Please_choose_one_of_Impression_Methodes)
        : yup.string(),
    lower_arch_url:
      values.impressions_method === "upload"
        ? yup.string().required(content.lower_arch_required)
        : yup.string(),
    upper_arch_url:
      values.impressions_method === "upload"
        ? yup.string().required(content.upper_arch_required)
        : yup.string(),
    sets: yup.number(),
    impressions_method_Arch: yup.string(),
    arch_impression_upper: yup.boolean(),
    arch_impression_lower: yup.boolean(),
    pickup_address_id:
      values.impressions_method === "courier"
        ? yup.number().required("Pickup address is Required")
        : yup.number(),
  });
};

export const schema2 = (props, content, values) => {
  return yup.object({
    impressions_method:
      values.impressions_method === "" ||
      values.impressions_method === undefined
        ? yup
            .string()
            .required(
              values.arch_impression_upper === false &&
                values.arch_impression_upper === false &&
                props.case.case_type === "Retainer"
                ? content.please_choose_one_of_arch_impression_methodes
                : content.please_choose_one_method_new
            )
        : yup.string(),

    lower_arch_url:
      (values.upper_arch_url === "" || values.upper_arch_url === undefined) &&
      values.impressions_method === "upload"
        ? yup.string().required(content.lower_arch_required)
        : yup.string(),

    upper_arch_url:
      values.impressions_method === "upload" &&
      (values.lower_arch_url === "" || values.lower_arch_url === undefined)
        ? yup.string().required(content.upper_arch_required)
        : yup.string(),
    sets: yup.number(),
    impressions_method_Arch: yup.string(),
    arch_impression_upper: yup.boolean(),
    arch_impression_lower: yup.boolean(),

    pickup_address_id:
      values.impressions_method === "courier"
        ? yup.number().required("Pickup address is Required")
        : yup.number(),
  });
};

// retainer
export const schema3 = (props, content, values) => {
  return yup.object({
    impressions_method:
      values.impressions_method === "" ||
      values.impressions_method === undefined
        ? yup
            .string()
            .required(
              (values.arch_impression_upper === false ||
                values.arch_impression_upper === undefined) &&
                (values.arch_impression_lower === false ||
                  values.arch_impression_lower === undefined) &&
                props.case.case_type === "Retainer"
                ? content.please_choose_one_of_arch_impression_methodes
                : content.please_choose_one_method_new
            )
        : yup.string(),
    lower_arch_url:
      values.arch_impression_lower === true &&
      values.impressions_method === "upload"
        ? yup.string().required(content.lower_arch_required)
        : yup.string(),
    upper_arch_url:
      values.arch_impression_upper === true &&
      values.impressions_method === "upload"
        ? yup.string().required(content.upper_arch_required)
        : yup.string(),
    sets: yup.number(),

    impressions_method_arch: yup.string(),
    ArchImpressionUpper: yup.boolean(),
    ArchImpressionLower: yup.boolean(),
  });
};
