import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import {
  getDoctorInfo,
  getDoctorAddresses,
  UpdateDoctorInfo,
} from "../../api/api";
import { setUserInfo } from "../../redux/user/action";
import { successToaster } from "../../services/toast";
import { uploadFile, keyToUrl } from "../../helpers/s3";
import { GetCountryLabel, GetCountryValue } from "../../helpers/countries";
import Addresses from "./Addresses";
import Settings from "./Settings";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setUserInfo: (payload) => dispatch(setUserInfo(payload)),
});
class Profile extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      activeTab: this.props.history?.location?.state
        ? this.props.history.location?.state?.activeTab
        : "3",
      id: props.userReducer.currentUser.id,
      doctor: {},
      addresses: [],
      address: {},
      modal: false,
      modal2: false,
      currentUser: props.userReducer.currentUser,
      obj: {},
      subscribe: false,
      doctor_image_value: "",
      doctor_image: false,
      doctor_photo: "",
      country: "",
      city: "",
      sendingData: false,
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* get doctor info , get doctor address */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    this.GetDoctorInfo();
    this.getDoctorAddresses();
  }
  /* --------------------------------------------------------------------------------*/
  /* get doctor info  */
  /* --------------------------------------------------------------------------------*/
  GetDoctorInfo = () => {
    getDoctorInfo(this.props.userReducer.currentUser.id).then((res) => {
      this.setState({
        doctor: res,
        subscribe: res.subscribe,
        doctor_image_value: "",
        doctor_image: false,
        doctor_photo: "",
      });
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  get doctor address */
  /* --------------------------------------------------------------------------------*/
  getDoctorAddresses = () => {
    getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
      this.setState({ addresses: res, address: res[0] });
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  set active tab  */
  /* --------------------------------------------------------------------------------*/
  toggleTabs = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /*  upload Image  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id) => {
    let extention = image.name.split(".");

    return uploadFile({
      name: `doctor-photo/${id}/${new Date().valueOf()}.${
        image.name.split(".")[extention.length - 1]
      }`,
      contentType: image.type,
      file: image,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  update profile photo  */
  /* --------------------------------------------------------------------------------*/
  ProfilePhotoOnUpload = async (e, id) => {
    this.setState({ loading1: true });
    if (e.target.files[0]) {
      let reader = new FileReader();
      reader.addEventListener("load", (evt) => {
        this.setState({
          doctor_photo: evt.currentTarget.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);

      const file = e.target ? e.target.files[0] : e.files[0];
      const { key } = await this.uploadImage(file, id);

      this.setState({
        doctor_image_value: keyToUrl(key),
        doctor_image: false,
        loading1: false,
      });

      const data = {
        doctor: {
          first_name: this.state.doctor.first_name,
          middle_name: this.state.doctor.middle_name,
          last_name: this.state.doctor.last_name,
          phone: this.state.doctor.phone,
          subscribe: this.state.subscribe,
          avatar: keyToUrl(key),
        },
      };

      this.UpdateUser(data);
    }
  };
  /* --------------------------------------------------------------------------------*/
  /*  update doctor data  */
  /* --------------------------------------------------------------------------------*/
  UpdateUser = (data) => {
    const { content } = this.props.languageReducer;
    const currentUser = {
      ...this.props.userReducer.currentUser,
      avatar: data.doctor.avatar,
      email: this.state.currentUser.email,
      first_name: data.doctor.first_name,
      full_name: `${data.doctor.first_name} ${data.doctor.middle_name} ${data.doctor.last_name}`,
      id: this.state.currentUser.id,
      last_name: data.doctor.last_name,
    };
    this.setState({ sendingData: !this.state.sendingData });
    UpdateDoctorInfo(this.state.id, JSON.stringify(data))
      .then((res) => {
        this.GetDoctorInfo();
        this.props.setUserInfo(currentUser);
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
        successToaster("updated user info successfully" ,  content.Success);
        this.setState({ sendingData: !this.state.sendingData });
      })
      .catch((error) => {
        this.GetDoctorInfo();
        this.setState({ sendingData: !this.state.sendingData });
      });
  };

  /* --------------------------------------------------------------------------------*/
  /*  toggle Subscribe  */
  /* --------------------------------------------------------------------------------*/
  toggleSubscribe = () => {
    this.setState({
      subscribe: !this.state.subscribe,
    });
  };
  render() {
    return (
      <div>
        <Row>
          <Col xs="12" md="12" lg="4">
            <Card>
              <CardBody>
                <div className="text-center mt-4">
                  <div className="buttons-container2 ">
                    <input
                      type="file"
                      id="doctor_photo"
                      onChange={(e) =>
                        this.ProfilePhotoOnUpload(e, "doctor_photo")
                      }
                      multiple={false}
                      className="visually-hidden"
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                    {
                      <div className="upload-container m-auto">
                        {
                          <label htmlFor="doctor_photo" className="upload">
                            <img
                              className="rounded-circle"
                              width="150"
                              src={
                                this.state.doctor_photo
                                  ? this.state.doctor_photo
                                  : this.state.doctor.avatar
                                  ? this.state.doctor.avatar.match(
                                      /http([^&]+)/
                                    )
                                    ? this.state.doctor.avatar
                                    : "https://muratselek.com.tr/wp-content/uploads/2019/01/yorum-icon-avatar-men-768x918.png"
                                  : "https://muratselek.com.tr/wp-content/uploads/2019/01/yorum-icon-avatar-men-768x918.png"
                              }
                              alt={`${this.state.doctor.full_name}`}
                            />

                            {this.state.loading1 && (
                              <center>
                                <div className={` ${"loading"}`}></div>
                              </center>
                            )}
                          </label>
                        }
                      </div>
                    }
                  </div>

                  <CardTitle className="mt-2">
                    {this.state.doctor.full_name}
                  </CardTitle>
                </div>
              </CardBody>
              <CardBody className="border-top">
                <div>
                  <small className="text-muted">Email address </small>
                  <h6>{this.state.doctor.email}</h6>
                  <small className="text-muted pt-4 db">Phone</small>
                  <h6>{this.state.doctor.phone}</h6>
                  <small className="text-muted pt-4 db">Address</small>
                  <h6>{`  ${
                    this.state.address
                      ? GetCountryLabel(this.state.address.country)
                      : ""
                  } - ${
                    this.state.address ? this.state.address.city : ""
                  } `}</h6>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/*--------------------------------------------------------------------------------*/}
          {/* Doctor profile tabs */}
          {/*--------------------------------------------------------------------------------*/}
          <Col xs="12" md="12" lg="8">
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "3",
                    })}
                    onClick={() => {
                      this.toggleTabs("3");
                    }}
                  >
                    Setting
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "4",
                    })}
                    onClick={() => {
                      this.toggleTabs("4");
                    }}
                  >
                    Address
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                {/* settings tabs */}
                <TabPane tabId="3">
                  <Row className="m-2 mt-3 mb-3">
                    <Col sm="12">
                      <Settings
                        id={this.props.userReducer.currentUser.id}
                        UpdateUser={this.UpdateUser}
                        doctor={this.state.doctor}
                        sendingData={this.state.sendingData}
                        subscribe={this.state.subscribe}
                        toggleSubscribe={this.toggleSubscribe}
                      />
                    </Col>
                  </Row>
                </TabPane>
                {/* address tab */}
                <TabPane tabId="4">
                  <Row className="m-2 mt-3 mb-3">
                    <Col sm="12">
                      <Addresses
                        addresses={this.state.addresses}
                        id={this.props.userReducer.currentUser.id}
                        GetCountryLabel={GetCountryLabel}
                        GetCountryValue={GetCountryValue}
                        getDoctorAddresses={this.getDoctorAddresses}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
          {/*--------------------------------------------------------------------------------*/}
          {/* End Doctor profile tabs */}
          {/*--------------------------------------------------------------------------------*/}{" "}
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
