import React, { Component, Fragment } from "react";
import { Page, Document, StyleSheet } from "@react-pdf/renderer";
import PDFHeader from "./PDFComponents/PDFHeader";
import DoctorInfo from "./PDFComponents/DoctorInfo";
import PatientInfo from "./PDFComponents/PatientInfo";
import Footer from "./PDFComponents/Footer";
import ShppingAddress from "./PDFComponents/ShppingAddress";
import PhotosXrays from "./PDFComponents/PhotosXrays";
import TreatmentType from "./PDFComponents/TreatmentType";
import Prescription from "./PDFComponents/Prescription";

import AdvancedModules4 from "./PDFComponents/AdvancedModules4";

import AllModules from "./PDFComponents/AllModules";
import AllModules2 from "./PDFComponents/AllModules2";
import AllModules3 from "./PDFComponents/AllModules3";
import AllModules4 from "./PDFComponents/AllModules4";
import AllModules5 from "./PDFComponents/AllModules5";
import AllModules6 from "./PDFComponents/AllModules6";
import AllModules7 from "./PDFComponents/AllModules7";
import AllModules8 from "./PDFComponents/AllModules8";
import AllModules9 from "./PDFComponents/AllModules9";
import AllModules10 from "./PDFComponents/AllModules10";
import AllModules11 from "./PDFComponents/AllModules11";
import AllModules12 from "./PDFComponents/AllModules12";
import AllModules13 from "./PDFComponents/AllModules13";
// Create styles
const borderColor = "#90e5fc";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 80,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 20,
    marginTop: 5,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    marginBottom: 20,
    width: "10%",
    height: "10%",
    backgroundColor: "red",
    borderRadius: 50,
  },
  header: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    padding: 5,
  },
  value: {
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
    fontWeight: "normal",
    padding: 5,
  },

  box: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },

  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: "25%",
  },

  row1: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description1: {
    width: "25%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty1: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  rate1: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  amount1: {
    width: "25%",
    textAlign: "center",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
    fontSize: 12,
    textAlign: "center",
  },
  testRow1: {
    height: 100,
    backgroundColor: "red",
    position: "relative",
  },
  testRow2: {
    height: 100,
    backgroundColor: "balck",
    position: "relative",
  },
  viewConainer: {
    position: "absolute",
    bottom: 100,
  },
});

// Create Document Component
export default class MyDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseInfo: props.case,
      doctorInfo: JSON.parse(localStorage.getItem("currentUser")),
      mappedData: props.mappedData,
      filterd_advanced: [],
      filterd_advanced2: [],
    };
  }
  splitToChunks = (array, parts) => {
    let result = [];
    for (let i = parts; i > 0; i--) {
      result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
  };
  componentDidMount = () => {
    let advancedModules = [];

    if (this.props.mappedData.advanced_modules.all_modules) {
      advancedModules =
        this.props.mappedData.advanced_modules.all_modules.filter(
          (value, index) => {
            return value.active === true && value.key !== "malocclusion";
          }
        );
    }

    this.setState({
      filterd_advanced: this.test(advancedModules),
      filterd_advanced2: advancedModules,
    });
  };
  test = (advancedModules) => {
    let x;
    if (advancedModules.length > 8) {
      x = this.splitToChunks(advancedModules, 3);
      return x;
    }
    if (advancedModules.length > 4 && advancedModules.length <= 8) {
      x = this.splitToChunks(advancedModules, 2);
      return x;
    } else {
      x = this.splitToChunks(advancedModules, 1);
      return x;
    }
  };
  render() {
    let advancedModules = [];

    advancedModules = this.props.mappedData.advanced_modules.all_modules.filter(
      (value, index) => {
        return value.active === true;
      }
    );
    let advancedModules2 = [];
    advancedModules2 =
      this.props.mappedData.advanced_modules.all_modules.filter(
        (value, index) => {
          return value.active === true && value.key !== "malocclusion";
        }
      );

    const { caseInfo, doctorInfo, mappedData } = this.state;
    const { content } = this.props;
    return (
      <Document>
        <Page style={styles.body}>
          <PDFHeader content={content} />
          <DoctorInfo
            content={content}
            doctorInfo={doctorInfo}
            case={caseInfo}
          />
          <ShppingAddress content={content} case={caseInfo} />
          <PatientInfo content={content} case={caseInfo} />
          <PhotosXrays content={content} mappedData={mappedData} />
          <Footer content={content} doctorInfo={doctorInfo} />
        </Page>

        <Page style={styles.body}>
          <PDFHeader content={content} />
          <TreatmentType content={content} mappedData={mappedData} />
          <Prescription content={content} mappedData={mappedData} />
          <Footer content={content} doctorInfo={doctorInfo} />
        </Page>
        {advancedModules.length > 0 && advancedModules2.length === 13 && (
          <Fragment>
            <AllModules13
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 12 && (
          <Fragment>
            <AllModules12
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 11 && (
          <Fragment>
            <AllModules
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 10 && (
          <Fragment>
            <AllModules2
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 9 && (
          <Fragment>
            <AllModules3
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 8 && (
          <Fragment>
            <AllModules4
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 7 && (
          <Fragment>
            <AllModules5
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 6 && (
          <Fragment>
            <AllModules6
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 5 && (
          <Fragment>
            <AllModules7
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 4 && (
          <Fragment>
            <AllModules8
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 3 && (
          <Fragment>
            <AllModules9
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 2 && (
          <Fragment>
            <AllModules10
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 && advancedModules2.length === 1 && (
          <Fragment>
            <AllModules11
              content={content}
              doctorInfo={doctorInfo}
              mappedData={mappedData}
            />
          </Fragment>
        )}
        {advancedModules.length > 0 &&
          advancedModules2.length === 0 &&
          mappedData.advanced_modules.all_modules[0].active && (
            <Page style={styles.body}>
              {mappedData.advanced_modules.all_modules[0].active && (
                <AdvancedModules4 content={content} mappedData={mappedData} />
              )}
              <Footer content={content} doctorInfo={doctorInfo} />
            </Page>
          )}
        {/* {
          advancedModules.length > 0 && filterd_advanced.length === 1 &&
          <Fragment>
            <Page style={styles.body}>
              <AdvancedModulesSort filterd_advanced={filterd_advanced[0]} mappedData={mappedData} />
              <Footer doctorInfo={doctorInfo} />
            </Page>

            {advancedModules.length > 0 &&
              <Fragment>
                <Page style={styles.body} >
                  {mappedData.advanced_modules.all_modules[0].active && <AdvancedModules4 mappedData={mappedData} />}
                  <Footer doctorInfo={doctorInfo} />
                </Page>
              </Fragment>
            }
          </Fragment>

        }
        {
          advancedModules.length > 0 && filterd_advanced.length === 2 &&
          <Fragment>
            <Page style={styles.body}>
              <AdvancedModulesSort filterd_advanced={filterd_advanced[0]} mappedData={mappedData} />
              <Footer doctorInfo={doctorInfo} />
            </Page>
            <Page style={styles.body}>
              <AdvancedModulesSort filterd_advanced={filterd_advanced[1]} mappedData={mappedData} />
              <Footer doctorInfo={doctorInfo} />
            </Page>

            {advancedModules.length > 0 &&
              <Fragment>
                <Page style={styles.body} >
                  {mappedData.advanced_modules.all_modules[0].active && <AdvancedModules4 mappedData={mappedData} />}
                  <Footer doctorInfo={doctorInfo} />
                </Page>
              </Fragment>
            }
          </Fragment>
        }
        {
          advancedModules.length > 0 && filterd_advanced.length === 3 &&
          <Fragment>
            <Page style={styles.body} >
              <AdvancedModulesSort filterd_advanced={filterd_advanced} mappedData={mappedData} />
              <Footer doctorInfo={doctorInfo} />
            </Page>
            <Page style={styles.body}>
              <AdvancedModulesSort filterd_advanced={filterd_advanced[1]} mappedData={mappedData} />
              <Footer doctorInfo={doctorInfo} />
            </Page>
            <Page style={styles.body}>
              <AdvancedModulesSort filterd_advanced={filterd_advanced[2]} mappedData={mappedData} />
              <Footer doctorInfo={doctorInfo} />
            </Page>

            {advancedModules.length > 0 &&
              <Fragment>
                <Page style={styles.body} >
                  {mappedData.advanced_modules.all_modules[0].active && <AdvancedModules4 mappedData={mappedData} />}
                  <Footer doctorInfo={doctorInfo} />
                </Page>
              </Fragment>
            }
          </Fragment>
        } */}

        {/* <Page style={styles.body}>
          <AdvancedModules2 mappedData={mappedData} />
          <Footer doctorInfo={doctorInfo} />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>

        <Page style={styles.body}>
          <AdvancedModules3 mappedData={mappedData} />
          <Footer doctorInfo={doctorInfo} />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>

        <Page style={styles.body}>
          <AdvancedModules4 mappedData={mappedData} />
          <Footer doctorInfo={doctorInfo} />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>

        <Page style={styles.body}>
          <AdvancedModules5 mappedData={mappedData} />
          <Footer doctorInfo={doctorInfo} />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
       */}
      </Document>
    );
  }
}
