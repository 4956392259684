import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Spinner } from "reactstrap";
import StepZilla from "react-stepzilla";

import PatientInfo from "./PatientInfo";
import Impressions from "./Impressions";
import Photos from "./Photos";
import Xrays from "./Xrays";
import Submission from "./Submission";
import TreatmentPlan from "./TreatmentPlan";
import {
  updateCase,
  setNewCaseUserInfo,
  setTreatmentPlanInfo,
  setImpressionsInfo,
  setPhotosInfo,
  setXraysInfo,
  setSubmissionInfo,
} from "../../../redux/newCase/action";
import {
  getDoctorAddresses,
  updateAlignerCase,
  getAlignerCase,
  getDoctorInfo,
} from "../../../api/api";
import { mapDataWithReducer } from "../../../services/mapData";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateCase: (payload) => dispatch(updateCase(payload)),
  setNewCaseUserInfo: (payload) => dispatch(setNewCaseUserInfo(payload)),
  setTreatmentPlanInfo: (payload) => dispatch(setTreatmentPlanInfo(payload)),
  setImpressionsInfo: (payload) => dispatch(setImpressionsInfo(payload)),
  setPhotosInfo: (payload) => dispatch(setPhotosInfo(payload)),
  setXraysInfo: (payload) => dispatch(setXraysInfo(payload)),
  setSubmissionInfo: (payload) => dispatch(setSubmissionInfo(payload)),
});
class NewCase extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      addresses: [],
      step: 0,
      id: props.userReducer.currentUser.id,
      caseId:
        props.caseId || (window.location.search.match(/id=([^&]+)/) || [])[1],
      fix: props.fix ? props.fix.step : null,
      start: false,
      currentStep: "",
      case: {},
      rejectable_id: props.rejectable_id ? props.rejectable_id : null,
      sending: false,
      sending2: false,
      rejectable_type: props.rejectable_type ? props.rejectable_type : null,
      mappedData: {},
      doctorInfo: {},
    };
    this.sampleStore = this.props.newCase.userInfo;
    this.treatmentPlan = this.props.newCase.treatmentPlan;
    this.impressions = this.props.newCase.impressions;
    this.photos = this.props.newCase.photos;
    this.xrays = this.props.newCase.xrays;
    this.submission = this.props.newCase.submission;
  }
  /* --------------------------------------------------------------------------------*/
  /* get aligner case info , doctor info and doctor addresses  */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    if (this.state.caseId) {
      this.GetAlignerCaseById();
      getDoctorInfo(this.state.id).then((res) => {
        this.setState({
          doctorInfo: res,
        });
      });
    } else {
      getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
        this.setState({ addresses: res, start: true });
      });
    }
  }
  /* --------------------------------------------------------------------------------*/
  /* Sending Data state to show and hide spinner  */
  /* --------------------------------------------------------------------------------*/
  SendingData() {
    window.scrollTo(0, 0);
    this.setState({ sending: !this.state.sending });
  }
  /* --------------------------------------------------------------------------------*/
  /* Sending Data 2 state to show and hide spinner  */
  /* --------------------------------------------------------------------------------*/
  SendingData2() {
    window.scrollTo(0, 0);
    this.setState({ sending2: !this.state.sending2 });
  }
  // jumpToStep = (e) => {
  //   this.setState({ step: e });
  // };
  /*--------------------------------------------------------------------------------*/
  /* get aligner case by id                                                         */
  /*--------------------------------------------------------------------------------*/
  GetAlignerCaseById() {
    getAlignerCase(
      this.props.userReducer.currentUser.id,
      this.state.caseId
    ).then((res) => {
      this.mapData(res);
    });
  }
  /*--------------------------------------------------------------------------------*/
  /* map data from api with reducer                                                 */
  /*--------------------------------------------------------------------------------*/
  mapData(data) {
    let newData = mapDataWithReducer(data, this.state.fix);
    this.setState({ mappedData: newData });
    this.props.updateCase({ ...this.props.newCase, ...newData });
    if (data.case_type === "Retainer") {
      this.setState({
        case: data,
        step: this.renderActiveStep("summary"),
        currentStep: "summary",
        start: true,
      });
    } else {
      this.setState({
        case: data,
        step: this.renderActiveStep(
          this.state.fix ? this.state.fix : data.step
        ),
        currentStep: data.step,
        start: true,
      });
    }
  }
  /*--------------------------------------------------------------------------------*/
  /* render active step                                                             */
  /*--------------------------------------------------------------------------------*/
  renderActiveStep(step) {
    switch (step) {
      case "clinical_findings":
        return 1;
      case "treatment_goals":
        this.props.setTreatmentPlanInfo({
          ...this.props.newCase.treatmentPlan,
          tab2: false,
        });
        return 1;
      case "summary_and_special_instructions":
        this.props.setTreatmentPlanInfo({
          ...this.props.newCase.treatmentPlan,
          tab2: false,
          tab3: false,
        });
        return 1;
      case "impressions":
        return 2;
      case "photos":
        return 3;
      case "xrays":
        return 4;
      case "terms":
        return 5;
      case "summary":
        this.updateSubmissionStore({ savedToCloud: true });
        return 5;
      default:
        return 1;
    }
  }
  /*--------------------------------------------------------------------------------*/
  /* Patient Info                                                                   */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getStore() {
    return this.sampleStore;
  }
  // Update Store
  updateStore(update) {
    this.sampleStore = {
      ...this.sampleStore,
      ...update,
    };
    this.props.setNewCaseUserInfo(this.sampleStore);
  }
  /*--------------------------------------------------------------------------------*/
  /* Treatment Plan                                                                 */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getTreatmentStore() {
    return this.props.newCase.treatmentPlan;
  }
  // Update Store
  updateTreatmentPlanStore(update) {
    this.treatmentPlan = {
      ...this.treatmentPlan,
      ...update,
    };
    this.props.setTreatmentPlanInfo(this.treatmentPlan);
  }
  /*--------------------------------------------------------------------------------*/
  /* Impressions                                                                    */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getImpressionsStore() {
    return this.props.newCase.impressions;
  }
  // Update Store
  updateImpressionsStore(update) {
    this.impressions = {
      ...this.impressions,
      ...update,
    };
    this.props.setImpressionsInfo(this.impressions);
  }
  /*--------------------------------------------------------------------------------*/
  /* Photos                                                                         */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getPhotosStore() {
    return this.props.newCase.photos;
  }
  // Update Store
  updatePhotosStore(update) {
    this.photos = {
      ...this.photos,
      ...update,
    };
    this.props.setPhotosInfo(this.photos);
  }
  /*--------------------------------------------------------------------------------*/
  /* Xrays                                                                          */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getXraysStore() {
    return this.props.newCase.xrays;
  }
  // Update Store
  updateXraysStore(update) {
    this.xrays = {
      ...this.xrays,
      ...update,
    };
    this.props.setXraysInfo(this.xrays);
  }
  /*--------------------------------------------------------------------------------*/
  /* Submission                                                                     */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getSubmissionStore() {
    return this.props.newCase.submission;
  }
  // Update Store
  updateSubmissionStore(update) {
    this.submission = {
      ...this.submission,
      ...update,
    };
    this.props.setSubmissionInfo(this.submission);
  }
  /*--------------------------------------------------------------------------------*/
  /* Update Terms                                                                   */
  /*--------------------------------------------------------------------------------*/
  UpdateTerms() {
    let data = {
      aligner_case: {
        step: "terms",
        terms_and_agreements: "1",
      },
    };
    let caseId = this.props.caseId
      ? this.props.caseId
      : (window.location.search.match(/id=([^&]+)/) || [])[1];
    this.SendingData();
    updateAlignerCase(this.state.id, caseId, JSON.stringify(data))
      .then((res) => {
        this.completeCase();
        this.SendingData();
      })
      .catch((error) => {
        this.SendingData();
        this.updateSubmissionStore({
          ...this.submission,
          savedToCloud: false,
        });
      });
  }
  /*--------------------------------------------------------------------------------*/
  /* complete Case                                                                  */
  /*--------------------------------------------------------------------------------*/
  completeCase() {
    let data = {
      aligner_case: {
        step: "summary",
      },
      ...(this.state.rejectable_id && {
        resolve_rejection_id: this.state.rejectable_id,
      }),
    };
    let caseId = this.props.caseId
      ? this.props.caseId
      : (window.location.search.match(/id=([^&]+)/) || [])[1];

    this.SendingData();

    updateAlignerCase(this.state.id, caseId, JSON.stringify(data))
      .then((res) => {
        this.SendingData();
        if (this.state.fix) {
          this.props.FixAlignerCase();
        } else {
          this.props.history.push(`/case/${caseId}`);
        }
      })
      .catch((error) => {
        this.SendingData();
      });
  }
  /*--------------------------------------------------------------------------------*/
  /* hide prev btn in Treatment Plan step  or while fixing case info   
     and disable it while sending data  */
  /*--------------------------------------------------------------------------------*/
  getClassprev() {
    let classbtn =
      this.state.step === 1 ||
      this.state.case.case_type === "Retainer" ||
      this.state.fix
        ? "btn btn-next btn-primary btn-lg pull-left hidebtn"
        : `btn btn-next btn-primary btn-lg pull-left ${
            this.state.sending || this.state.sending2 ? "disable-btn" : ""
          }`;
    return classbtn;
  }
  /*--------------------------------------------------------------------------------*/
  /* hide next btn while fixing case info       
     and disable it while sending data   */
  /*--------------------------------------------------------------------------------*/
  getClassNext() {
    let classbtn = this.state.fix
      ? "btn btn-prev btn-primary btn-lg pull-right hidebtn"
      : `btn btn-prev btn-primary btn-lg pull-right ${
          this.state.sending || this.state.sending2 ? "disable-btn" : ""
        }`;
    return classbtn;
  }
  /*--------------------------------------------------------------------------------*/
  /* Steps for react  StepZilla */
  /*--------------------------------------------------------------------------------*/
  steps() {
    /* hide some steps if casetype is Retainer */
    let steps =
      this.sampleStore.caseType === "Retainer"
        ? [
            {
              name: "Patient Info",
              component: (
                <PatientInfo
                  getStore={() => this.getStore()}
                  updateStore={(u) => {
                    this.updateStore(u);
                  }}
                  sendingData={() => {
                    this.SendingData();
                  }}
                  addresses={this.state.addresses}
                  props={this.props}
                  caseId={this.state.caseId}
                  fix={this.state.fix}
                  rejectable_id={this.state.rejectable_id}
                  // jumpToStep={this.jumpToStep}
                />
              ),
            },
            {
              name: "Submission",
              component: (
                <Submission
                  savedToCloud={true}
                  getStore={() => this.getSubmissionStore()}
                  updateStore={(u) => {
                    this.updateSubmissionStore(u);
                  }}
                  sendingData={() => {
                    this.SendingData();
                  }}
                  case={this.state.case}
                  id={this.state.id}
                  caseId={this.state.caseId}
                  currentStep={this.state.currentStep}
                  fix={this.state.fix}
                  rejectable_id={this.state.rejectable_id}
                  caseType={this.props.newCase.userInfo.caseType}
                />
              ),
            },
          ]
        : [
            {
              name: "Patient Info",
              component: (
                <PatientInfo
                  getStore={() => this.getStore()}
                  updateStore={(u) => {
                    this.updateStore(u);
                  }}
                  sendingData={() => {
                    this.SendingData();
                  }}
                  addresses={this.state.addresses}
                  props={this.props}
                  caseId={this.state.caseId}
                  fix={this.state.fix}
                  rejectable_id={this.state.rejectable_id}
                  // jumpToStep={this.jumpToStep}
                />
              ),
            },
            {
              name: "Treatment Plan",
              component: (
                <TreatmentPlan
                  getStore={() => this.getTreatmentStore()}
                  updateStore={(u) => {
                    this.updateTreatmentPlanStore(u);
                  }}
                  sendingData={() => {
                    this.SendingData();
                  }}
                  sendingData2={() => {
                    this.SendingData2();
                  }}
                  currentStep={this.state.currentStep}
                  data={this.props}
                  caseId={this.state.caseId}
                  fix={this.state.fix}
                  rejectable_id={this.state.rejectable_id}
                />
              ),
            },
            {
              name: "Impressions",
              component: (
                <Impressions
                  getStore={() => this.getImpressionsStore()}
                  updateStore={(u) => {
                    this.updateImpressionsStore(u);
                  }}
                  sendingData={() => {
                    this.SendingData();
                  }}
                  component="newCase"
                  data={this.props}
                  caseId={this.state.caseId}
                  fix={this.state.fix}
                  rejectable_id={this.state.rejectable_id}
                  case={this.state.case}
                  jumpToStep={this.jumpToStep}
                />
              ),
            },
            {
              name: "Photos",
              component: (
                <Photos
                  getStore={() => this.getPhotosStore()}
                  updateStore={(u) => {
                    this.updatePhotosStore(u);
                  }}
                  sendingData={() => {
                    this.SendingData();
                  }}
                  component="newCase"
                  data={this.props}
                  caseId={this.state.caseId}
                  fix={this.state.fix}
                  rejectable_id={this.state.rejectable_id}
                />
              ),
            },
            {
              name: "Xrays",
              component: (
                <Xrays
                  getStore={() => this.getXraysStore()}
                  updateStore={(u) => {
                    this.updateXraysStore(u);
                  }}
                  sendingData={() => {
                    this.SendingData();
                  }}
                  data={this.props}
                  caseId={this.state.caseId}
                  fix={this.state.fix}
                  rejectable_id={this.state.rejectable_id}
                />
              ),
            },

            {
              name: "Submission",
              component: (
                <Submission
                  getStore={() => this.getSubmissionStore()}
                  updateStore={(u) => {
                    this.updateSubmissionStore(u);
                  }}
                  sendingData={() => {
                    this.SendingData();
                  }}
                  id={this.state.id}
                  case={this.state.case}
                  mappedData={this.state.mappedData}
                  doctorInfo={this.state.doctorInfo}
                  currentStep={this.state.currentStep}
                  caseId={this.state.caseId}
                  fix={this.state.fix}
                  rejectable_id={this.state.rejectable_id}
                  caseType={this.props.newCase.userInfo.caseType}
                />
              ),
            },
          ];

    return steps;
  }
  render() {
    const caseId = this.props.caseId
      ? this.props.caseId
      : (window.location.search.match(/id=([^&]+)/) || [])[1];
    return (
      <div>
        <Card>
          <CardBody
            className={
              !this.state.start
                ? "m-auto d-flex align-items-center exite-stepper"
                : "exite-stepper"
            }
          >
            {/*--------------------------------------------------------------------------------*/}
            {/* close icon redirect to cases list   */}
            {/*--------------------------------------------------------------------------------*/}
            {this.state.start && this.state.fix === null && (
              <div
                className="exite-stepper-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.props.history.push(`/cases`);
                }}
              >
                <i className="fas fa-times"></i>
              </div>
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* end close icon   */}
            {/*--------------------------------------------------------------------------------*/}
            <div className="example case-container">
              {this.state.start ? (
                <div className="step-progress-new-case upload-container">
                  {/*--------------------------------------------------------------------------------*/}
                  {/* spinner shows while sending data */}
                  {/*--------------------------------------------------------------------------------*/}
                  {(this.state.sending || this.state.sending2) && (
                    <div className={`loading2`}>
                      <div className="spinner_1"></div>
                    </div>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* end spinner */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* patient name if not fix or not not patient info step */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.state.step > 0 && this.state.fix === null && (
                    <div className="case-container">
                      <h4 className="control-label title_active patient-name">
                        {this.state.case.patient
                          ? this.state.case?.patient?.full_name
                          : `${this.props.newCase.userInfo.firstName}  ${this.props.newCase.userInfo.middleName} ${this.props.newCase.userInfo.lastName}`}{" "}
                        {}
                      </h4>
                    </div>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* end patient name  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* step name after specific screen width   */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.steps() &&
                  window.innerWidth < 1200 &&
                  this.state.fix === null ? (
                    <div className="case-container">
                      {" "}
                      <h4 className="control-label text-center title_active current-case">
                        {this.steps()[this.state.step]["name"]}
                      </h4>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* end step name  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* React StepZilla  */}
                  {/*--------------------------------------------------------------------------------*/}
                  <StepZilla
                    steps={this.steps()}
                    startAtStep={this.state.step}
                    backButtonText=""
                    nextButtonText=""
                    onStepChange={(step) => {
                      this.setState({ step: step });
                    }}
                    showSteps={this.state.fix ? false : true}
                    stepsNavigation={
                      this.state.case.case_type === "Retainer" || this.state.fix
                        ? false
                        : true
                    }
                    backButtonCls={this.getClassprev()}
                    nextButtonCls={this.getClassNext()}
                  />
                  {/* Update Terms Button   */}
                  {this.state.step === 5 &&
                    !this.submission.savedToCloud &&
                    !this.state.fix && (
                      <button
                        id="finish-button"
                        type="button"
                        className={`btn btn-prev btn-primary btn-lg pull-right ${
                          this.state.sending || this.state.sending2
                            ? "disable-btn"
                            : ""
                        }`}
                        onClick={() => {
                          this.submission.acceptTerms
                            ? this.UpdateTerms()
                            : this.updateSubmissionStore({
                                ...this.submission,
                                validateTerms: true,
                              });
                        }}
                      >
                        Finish
                      </button>
                    )}
                  {/* End Update Terms Button   */}

                  {/* Finish Retainer Case   */}
                  {this.props.newCase.userInfo.caseType === "Retainer" && (
                    <button
                      type="button"
                      id="finish-button"
                      className={`btn btn-prev btn-primary btn-lg pull-right ${
                        this.state.sending || this.state.sending2
                          ? "disable-btn"
                          : ""
                      }`}
                      onClick={() => {
                        this.props.history.push(`/case/${caseId}`);
                      }}
                    >
                      Finish
                    </button>
                  )}
                  {/* End Finish Retainer Case   */}

                  {/* Complete Case   */}
                  {this.props.newCase.userInfo.caseType !== "Retainer" &&
                    !this.state.fix &&
                    this.state.step === 5 &&
                    this.submission.savedToCloud && (
                      <button
                        type="button"
                        id="finish-button"
                        className={`btn btn-prev btn-primary btn-lg pull-right ${
                          this.state.sending || this.state.sending2
                            ? "disable-btn"
                            : ""
                        }`}
                        onClick={() => {
                          this.completeCase();
                        }}
                      >
                        Finish
                      </button>
                    )}
                  {/* End Complete Case   */}

                  {/* fix  Summery step    */}
                  {this.state.fix && this.state.step === 5 && (
                    <button
                      type="button"
                      id="finish-button"
                      className={`btn btn-prev btn-primary btn-lg pull-right ${
                        this.state.sending || this.state.sending2
                          ? "disable-btn"
                          : ""
                      }`}
                      onClick={() => {
                        this.completeCase();
                      }}
                    >
                      Finish
                    </button>
                  )}
                  {/* End fix  Summery step    */}
                </div>
              ) : (
                <Spinner className="green-spinner" type="grow" />
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/*End React StepZilla  */}
              {/*--------------------------------------------------------------------------------*/}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCase);
