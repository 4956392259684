import React, { Component, useState } from "react";
import {
  Collapse,
  Button,
  Row,
  TabContent,
  TabPane,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import TSViewer from "./TSViewer/TSViewer";
import {
  getTretmentSetupById,
  getAlignerCase,
  disApproveTreatmentSetup,
  approveTreatmentSetup,
  getViewerId,
  generateURL,
  generateURLBeta,
} from "../../api/api";
import Inbox from "../inbox/index";
import { successToaster, infoToaster } from "../../services/toast";

import { caseType } from "../../helpers/caseType";
import NewViewerDetails from "./TSViewer/NewViewerDetails";
import AppliedTreatment from "./TSViewer/AppliedTreatment";
import Notes from "./TSViewer/Notes";
import { connect } from "react-redux";
import {
  setTeethIPRData,
  setSavedData,
  setSavedDataById,
  setUsedIPRById,
  setTeethIPRDataById,
  setChangeTracker,
  setShowToothMovement,
  setShowIPRToothMovement,
  resetStepsData,
  setProtocolFeedback,
  setProtocolFeedbackById,
  setRejectionReson,
  setRejectionResonById,
  resetAllTsData,
  setVisitedStep,
  treatmentPlanAction,
} from "../../redux/Tooth_IPR_Options/action";
import { mapTeethFDI } from "../../services/mapTeethFDI";
import { mapModules } from "../../services/mapAdvancedModules";
import { updateAllAdvancedModules } from "../../redux/CaseSubmission/action";
import { mapIPRTeethFromApi } from "../../services/mapIPRTeethFromApi";
import { iprTeethTS } from "../../services/iprTeethTS";
import RevisedTable from "./RevisedTable";
import { newMapDataWithReducerTS } from "../../services/newDataMapperTS";
import share_link_mobile from "../../assets/images/caseActions/share_link_mobile.svg";
import Logo from "../../assets/images/Ts-navBar/Logo.svg";
import x from "../../assets/images/Ts-navBar/x.svg";
import Vector from "../../assets/images/Ts-navBar/Vector.svg";
import copy_link from "../../assets/images/caseActions/copy_link.svg";
import { statusColors, ts_status } from "../../helpers/caseStatus";
import classnames from "classnames";
import ReasonsRejection from "./TSViewer/ReasonsRejection/ReasonsRejection";
import Summary from "./TSViewer/Summary";
import { mapIPRTeethOptionsToApi } from "../../services/mapIPRTeethOptionsToApi";
import check from "../../assets/images/icon/check.svg";
import TreatmentProtocols from "./TSViewer/TreatmentProtocols/TreatmentProtocols";
import CustomeHeader from "../../components/header/CustomeHeader";
// import Stepper from 'react-stepper-component-with-svg';
import { setUnsavedChanges, resetChanges } from "../../redux/changes/action";
import HybridTreatment from "./HybridTreatment";
import { mapRevisedData } from "../../services/mapRevisedData";
import { SetHybridState } from "../../redux/hybridTreatment/action";
import {
  checkIPR,
  checkIPRTooth,
  checkMovement,
  checkMovementTooth,
} from "../../services/checkChanges";

import { getCaseById } from "../../redux/caseById/action";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import MyDocument from "../iprPDF/MyDocument";
import { uploadFile, keyToUrl } from "../../helpers/s3";
import TreatmentPlan from "./TreatmentPlan";
import DownloadIPRPdf from "../iprPDF/DownloadIPRPdf";
import { Async } from "react-select";
import { WrappedAdminAppliedTreatmentsPdf } from "@eon-dental/advanced-modules/dist/rollup-export";
import eonAccessLogo from "../../assets/images/eonAccessLogo.png";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setTeethIPRData: (payload) => dispatch(setTeethIPRData(payload)),
  setTeethIPRDataById: (payload) => dispatch(setTeethIPRDataById(payload)),
  setSavedData: (payload) => dispatch(setSavedData(payload)),
  setSavedDataById: (payload) => dispatch(setSavedDataById(payload)),
  setUsedIPRById: (payload) => dispatch(setUsedIPRById(payload)),
  treatmentPlanAction: (payload) => dispatch(treatmentPlanAction(payload)),
  setChangeTracker: (payload) => dispatch(setChangeTracker(payload)),
  updateAllAdvancedModules: (payload) =>
    dispatch(updateAllAdvancedModules(payload)),
  setUnsavedChanges: (payload) => dispatch(setUnsavedChanges(payload)),
  setShowToothMovement: (payload) => dispatch(setShowToothMovement(payload)),
  setShowIPRToothMovement: (payload) =>
    dispatch(setShowIPRToothMovement(payload)),
  resetStepsData: (payload) => dispatch(resetStepsData(payload)),
  resetChanges: (payload) => dispatch(resetChanges(payload)),
  setProtocolFeedback: (payload) => dispatch(setProtocolFeedback(payload)),
  setProtocolFeedbackById: (payload) =>
    dispatch(setProtocolFeedbackById(payload)),
  setRejectionReson: (payload) => dispatch(setRejectionReson(payload)),
  setRejectionResonById: (payload) => dispatch(setRejectionResonById(payload)),
  SetHybridState: (payload) => dispatch(SetHybridState(payload)),
  resetAllTsData: (payload) => dispatch(resetAllTsData(payload)),
  setVisitedStep: (payload) => dispatch(setVisitedStep(payload)),
  getCaseById: (payload) => dispatch(getCaseById(payload)),
});
class TreatmentSetup extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state */
    this.state = {
      activeTab: "1",
      collapse: false,
      collapseViewerDetails: false,
      viewer: false,
      setupDetails: props.value,
      case: props.case,
      doctorId: props.doctorId,
      details: {},
      width: window.innerWidth,
      Approve: false,
      DisApprove: false,
      RejectionReason: "",
      loading1: false,
      loading2: false,
      copyLink: false,
      data: props.data,
      viewerId: null,
      url: null,
      showBiteRecord: false,
      showToothIPROptions: false,
      mapped_advanced_modules: [],
      finish_loading_ts: false,
      showRevisedData: false,
      revised_data: {},
      showLinkModal: false,
      msg: this.props.inbox.messages,
      finished: false,
      showinbox: false,
      step: 1,
      toggleDisapprove: false,
      disapproving_data: false,
      disapproved_successfully: false,
      active_side_tab: "1",
      showUnsavedDataModal: false,
      showToothMovement: false,
      showIPRToothMovement: false,
      current_ipr_position: "",
      current_ipr_tooth: "",
      showIPRData: false,
      showConfirmationUnsavedDataModal: false,
      toggleReviseModalMobile: false,
      toggleReviseModalMobileSuccess: false,
      ipr_data: {},
      iprNotes: "",
      pdf_url: "",
      loadPdf: false,
      viewerHeight: window.innerHeight - 128,
      hideActions: false,
      backClicked: false,
      array: [],
    };
    this.uid = this.props.case.uid;
  }

  /* --------------------------------------------------------------------------------*/
  /* update Dimensions after get all data ,get treatment setup by id before render   */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    this.GetTretmentSetupById();
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
    this.setState({ finished: true });
  }
  componentDidUpdate() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
  }
  /* --------------------------------------------------------------------------------*/
  /* update Dimensions  */
  /* --------------------------------------------------------------------------------*/
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
    this.setViewerHeight();
  };
  /* --------------------------------------------------------------------------------*/
  /* toggle ts data   */
  /* --------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  /* --------------------------------------------------------------------------------*/
  /* toggle ts data   */
  /* --------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  /* --------------------------------------------------------------------------------*/
  /* open revise modal mobile   */
  /* --------------------------------------------------------------------------------*/
  openReviseModalMobile = () => {
    this.setState({
      toggleReviseModalMobile: !this.state.toggleReviseModalMobile,
    });
    if (this.state.toggleReviseModalMobileSuccess === true) {
      this.setState({ viewer: !this.state.viewer });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* exit TS mobile yes   */
  /* --------------------------------------------------------------------------------*/
  exitTSMobileYes = () => {
    this.setState({
      toggleReviseModalMobile: !this.state.toggleReviseModalMobile,
    });
    this.setState({
      showConfirmationUnsavedDataModal3:
        !this.state.showConfirmationUnsavedDataModal3,
    });
    this.resetShowActions();
    this.props.setProtocolFeedback("");
  };
  /* --------------------------------------------------------------------------------*/
  /* exit TS mobile no   */
  /* --------------------------------------------------------------------------------*/
  exitTSMobileNo = () => {
    this.setState({
      showConfirmationUnsavedDataModal3:
        !this.state.showConfirmationUnsavedDataModal3,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* cancel revise modal mobile   */
  /* --------------------------------------------------------------------------------*/
  cancelReviseModalMobile = () => {
    if (this.props.teethIPROptions.treatment_protocol_changed) {
      this.setState({
        showConfirmationUnsavedDataModal3:
          !this.state.showConfirmationUnsavedDataModal3,
      });
    } else {
      this.setState({
        toggleReviseModalMobile: !this.state.toggleReviseModalMobile,
      });
      this.resetShowActions();
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* show / hide viewer modal   */
  /* --------------------------------------------------------------------------------*/
  toggleViewerModal = () => {
    if (this.state.viewer === false) {
      this.props.resetChanges();
    }
    const {
      bite_check_changed,
      teeth_movement_changed,
      ipr_changed,
      treatment_protocol_changed,
    } = this.props.teethIPROptions;

    if (
      this.props.changesReducer.dataChanged ||
      bite_check_changed ||
      teeth_movement_changed ||
      ipr_changed ||
      treatment_protocol_changed
    ) {
      this.setState({
        showConfirmationUnsavedDataModal: true,
        savedState: "closeModal",
      });
    } else {
      this.setState({ viewer: !this.state.viewer, finish_loading_ts: false });
      this.clearStateToLeave();

      this.props.updateAllAdvancedModules({});
      this.props.SetHybridState(false);

      this.props.setChangeTracker(false);
      this.setState({
        collapseViewerDetails: !this.state.collapseViewerDetails,
        collapse4: !this.state.collapse4,
      });
    }
  };

  GetAllData = async () => {
    await this.props.GetTreatmentSetups();
    await this.GetTretmentSetupById();
    await this.GetAlignerCase();
  };
  /* --------------------------------------------------------------------------------*/
  /* show / hide copy link modal   */
  /* --------------------------------------------------------------------------------*/
  CopyLinkModal = () => {
    this.setState({ copyLink: !this.state.copyLink });
  };
  /* --------------------------------------------------------------------------------*/
  /* show / hide viewer details modal   */
  /* --------------------------------------------------------------------------------*/
  toggleViewerDetailsModal = () => {
    this.GetTretmentSetupById();
    this.GetAlignerCase();
    this.setState({
      collapseViewerDetails: !this.state.collapseViewerDetails,
      collapse4: !this.state.collapse4,
    });
  };
  UNSAFE_componentWillMount() {
    this.GetTretmentSetupById();
    this.setState({ finished: false });
  }
  /*--------------------------------------------------------------------------------*/
  /* Get Treatment Setup                                                    */
  /*--------------------------------------------------------------------------------*/
  GetTretmentSetupById = async () => {
    const { id } = this.props.caseReducer.case;
    const doctor_id = this.props.userReducer.currentUser.id;

    await getTretmentSetupById(doctor_id, id, this.state.setupDetails.id).then(
      (res) => {
        if (res.title === "Adjustment") {
          this.props.setIsAdjustmentStatus();
        }

        // this.setState({...array,
        //   array :res.published_at
        // })
        if (res.is_bite_rejected) {
          this.props.showBiteRecord();
        }

        if (res.converted_to_type === "braces_before") {
          this.props.SetContinueHybridSubmission();
        }
        let mapped_advanced_modules = mapModules(
          res.advanced_modules?.advanced_modules?.all_modules
        );
        let data = res;

        let revised_data = res.revised_data;
        //let mappedRevisedData=
        if (Object.keys(revised_data).length > 0 && revised_data) {
          let mapped_revised_data = mapRevisedData(res.revised_data);

          // this.props.setSavedData(mapped_revised_data.saved);
          // this.props.setTeethIPRData(mapped_revised_data.updated);
          // this.props.setProtocolFeedback(revised_data.Clinical_protocol.Clinical_protocol_feedback);
          // this.props.setRejectionReson(revised_data.Bite_Record.rejection_reason)

          this.props.setSavedDataById({
            id: res.id,
            data: mapped_revised_data.saved,
          });
          this.props.setTeethIPRDataById({
            id: res.id,
            data: mapped_revised_data.updated,
          });
          this.props.setProtocolFeedbackById({
            id: res.id,
            data: revised_data.Clinical_protocol.Clinical_protocol_feedback,
          });
          this.props.setRejectionResonById({
            id: res.id,
            data: revised_data.Bite_Record.rejection_reason,
          });
        }

        let newData = newMapDataWithReducerTS(res.advanced_modules);

        let ipr_data = mapTeethFDI(
          newData.advanced_modules.ipr.iprRestriction,
          res.teeth_movements_extracted || [],
          newData.advanced_modules
        );
        data["mapped_advanced_modules"] = mapped_advanced_modules;

        this.setState({
          details: data,
          mapped_advanced_modules: mapped_advanced_modules,
          revised_data: revised_data,
          ipr_data: ipr_data,
          iprNotes: newData.advanced_modules.ipr.iprNotes,
        });
        this.props.setUsedIPRById({
          id: res.id,
          data: newData.advanced_modules.ipr.useIpr,
        });

        this.props.treatmentPlanAction({
          id: res.id,
          data: res,
        });

        this.GetLink();
      }
    );
  };
  /*--------------------------------------------------------------------------------*/
  /* Get Aligner Case                                                     */
  /*--------------------------------------------------------------------------------*/
  GetAlignerCase = async () => {
    const doctor_id = this.props.userReducer.currentUser.id;
    const case_id = this.props.caseReducer.case.id;

    await this.props.getCaseById({ doctor_id: doctor_id, case_id: case_id });
  };
  /*--------------------------------------------------------------------------------*/
  /* find Reason in adjustemtn list to shoe it in ts by id                                          */
  /*--------------------------------------------------------------------------------*/
  findReason = (id) => {
    const { adjustments } = this.props.caseReducer.case;
    if (adjustments) {
      const found = adjustments.filter(
        (el) => el.treatment_setup_ids[0] === id
      );
      return found[0] ? found[0].reason : null;
    } else {
      return null;
    }
  };

  /*--------------------------------------------------------------------------------*/
  /* show / hide confirmation for approve treatment setup                                              */
  /*--------------------------------------------------------------------------------*/
  Approve = () => {
    this.setState({ Approve: !this.state.Approve });
  };
  /*--------------------------------------------------------------------------------*/
  /*hide show confirmantio for disapprove traetment setup                                                       */
  /*--------------------------------------------------------------------------------*/
  DisApprove = () => {
    this.setState({ DisApprove: !this.state.DisApprove });
  };
  /*--------------------------------------------------------------------------------*/
  /* Approve Treatment Setup                                                     */
  /*--------------------------------------------------------------------------------*/
  // ApproveTreatmentSetup = () => {
  //   const { content } = this.props.languageReducer;
  //   this.setState({ loading1: !this.state.loading1 });
  //   approveTreatmentSetup(
  //     this.props.doctorId,
  //     this.state.case.uid,
  //     this.state.details.id
  //   )
  //     .then((res) => {
  //       this.props.GetAlignerCase();
  //       this.GetTretmentSetupById();
  //       this.Approve();
  //       this.setState({ loading1: !this.state.loading1 });
  //       successToaster("Treatment Setup has been approved", content.Success);
  //     })
  //     .catch((error) => {
  //       this.setState({ loading1: !this.state.loading1 });
  //     });
  // };

  /*--------------------------------------------------------------------------------*/
  /* Approve Treatment Setup                                                     */
  /*--------------------------------------------------------------------------------*/
  ApproveTreatmentSetup = (link) => {
    const { content } = this.props.languageReducer;
    const { uid } = this.props.caseReducer.case;
    const doctor_id = this.props.userReducer.currentUser.id;
    if (link) {
    } else {
      this.setState({ loading1: !this.state.loading1 });
    }
    const data = {
      ipr_file_url: link || null,
    };
    approveTreatmentSetup(
      doctor_id,
      uid,
      this.state.details.id,
      JSON.stringify(data)
    )
      .then(async (res) => {
        await this.props.GetTreatmentSetups();
        await this.GetTretmentSetupById();
        await this.GetAlignerCase();
        await this.Approve();
        this.setState({ loading1: !this.state.loading1 });

        successToaster(
          content.treatment_Setup_has_been_approved,
          content.Success
        );

        this.props.updateAllAdvancedModules({});
        this.props.SetHybridState(false);
        this.props.setChangeTracker(false);
        this.clearStateToLeave();
        // await this.props.ToggleOptions();
        // this.setState({ showClinicProtocol: !this.state.showClinicProtocol });
        this.props.setChangeTracker(false);
      })
      .catch(async (error) => {
        await this.GetTretmentSetupById();
        await this.GetAlignerCase();
        await this.props.GetTreatmentSetups();

        this.setState({ loading1: !this.state.loading1 });
      });
  };
  mapDataToAPI = () => {
    let mapped_data = mapIPRTeethOptionsToApi(
      this.props.teethIPROptions.teeth_ipr_data,
      this.props.teethIPROptions.saved_data,
      this.props.teethIPROptions.clinical_protocol_feedback,
      this.props.teethIPROptions.rejection_reason
    );

    this.DisApproveTreatmentSetup(mapped_data);
  };
  /*--------------------------------------------------------------------------------*/
  /* DisApprove Treatment Setup                                                     */
  /*--------------------------------------------------------------------------------*/
  DisApproveTreatmentSetup = (mappeddata) => {
    const { content } = this.props.languageReducer;
    const { uid } = this.props.caseReducer.case;
    const doctor_id = this.props.userReducer.currentUser.id;

    this.setState({ disapproving_data: true });
    let data = {
      revised_data: mappeddata,
    };
    disApproveTreatmentSetup(
      doctor_id,
      uid,
      this.state.details.id,
      JSON.stringify(data),
      this.props.teethIPROptions.rejection_reason ? true : false
    )
      .then(async (res) => {
        this.setState({
          //  showBiteRecord: false,
          activeTab: "1",
          active_side_tab: "1",
          savedState: "",
          step: 1,
          showinbox: false,
          disapproving_data: false,
          disapproved_successfully: true,
        });
        this.props.resetStepsData();
        this.props.resetChanges();
        await this.props.GetTreatmentSetups();
        await this.GetTretmentSetupById();
        await this.GetAlignerCase();
        if (window.innerWidth > 839) {
          successToaster(
            content.treatment_Setup_has_been_disapproved,
            content.Success
          );
        }
        // this.confirmationDisapprove();
      })
      .catch(async (error) => {
        this.setState({
          disapproving_data: false,
          disapproved_successfully: false,
        });
      });
    this.setState({
      toggleReviseModalMobileSuccess:
        !this.state.toggleReviseModalMobileSuccess,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* Get url link from viewer details to copy   */
  /* --------------------------------------------------------------------------------*/
  GetLink = () => {
    const doctorIncluded = process.env.REACT_APP_BETA_DOCTORS.split(",");
    const { email } = this.props.userReducer.currentUser;
    const { new_converter, id } = this.state.details;

    getViewerId(
      this.uid,
      this.state.details.id,
      this.state.details.upper_steps,
      this.state.details.lower_steps
    ).then((res) => {
      const url = generateURL(res.viewer, new_converter, id);
      // doctorIncluded.includes(email)
      //   ? generateURLBeta(res.viewer)
      //   : generateURL(res.viewer);
      this.setState({ viewerId: res.viewer, url: url });
    });
  };

  SaveDataInReducer = () => {
    let ipr = {};

    if (
      this.state.details?.advanced_modules?.advanced_modules?.ipr
        ?.iprRestriction
    ) {
      if (
        this.state.details?.advanced_modules?.advanced_modules?.ipr?.iprRestriction.hasOwnProperty(
          "upper_teeth"
        )
      ) {
        ipr = mapIPRTeethFromApi(
          this.state.details?.advanced_modules?.advanced_modules?.ipr
            ?.iprRestriction
        );
      } else {
        ipr = iprTeethTS();
      }
    } else {
      ipr = iprTeethTS();
    }
    let newData = newMapDataWithReducerTS(this.state.details.advanced_modules);

    this.props.setSavedData(
      mapTeethFDI(
        ipr,
        this.state.details?.teeth_movements_extracted || [],
        newData.advanced_modules
      )
    );
    this.props.setTeethIPRData(
      mapTeethFDI(
        ipr,
        this.state.details?.teeth_movements_extracted || [],
        newData.advanced_modules
      )
    );
    this.props.updateAllAdvancedModules({});
  };

  ToggleBitsRecord = () => {
    this.setState({ showBiteRecord: !this.state.showBiteRecord });
    if (this.state.showinbox) {
      this.setState({ showinbox: false });
    }
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
    }
  };

  saveRejectionReason = (data) => {
    this.setState({
      showBiteRecord: !this.state.showBiteRecord,
      showToothIPROptions: true,
    });
  };
  ToggleOptionsFromBiteRecord = () => {
    this.setState({ showBiteRecord: false, showToothIPROptions: false });
  };
  ToggleOptions = () => {
    this.setState({ showToothIPROptions: !this.state.showToothIPROptions });
  };
  finishLoadingTs = () => {
    this.setState({ finish_loading_ts: true });
  };

  RevisedDataModal = () => {
    this.setState({ showRevisedData: !this.state.showRevisedData });
  };

  checkIfMatch = (link) => {
    if (link?.match(/http([^&]+)/)) {
      return false;
    } else {
      return true;
    }
  };
  toggleShowLinkModal = () => {
    this.setState({ showLinkModal: !this.state.showLinkModal });
  };
  inboxmsg = () => {
    const { step } = this.state;
    const { keys } = this.props.changesReducer.keys;
    if (this.props.changesReducer.dataChanged) {
      this.setState({ showUnsavedDataModal: true, savedState: "showInbox" });
    } else {
      if (keys?.current_ipr_position && keys?.current_ipr_tooth) {
        this.cancelChangesOnToothMovement();
        this.setState({ showinbox: true });
      } else {
        this.setState({ showinbox: true });
      }
    }
  };
  back = () => {
    this.setState({ showinbox: false });
    this.setState({ showinbox: false, savedState: "" });
  };
  logodirection = () => {
    window.location = "/home";
  };
  /*--------------------------------------------------------------------------------*/
  /*set active tab   */
  /*--------------------------------------------------------------------------------*/
  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*set active tab   */
  /*--------------------------------------------------------------------------------*/
  toggleSideTabs(tab) {
    if (this.state.active_side_tab !== tab) {
      this.setState({
        active_side_tab: tab,
      });
    }
  }

  /*--------------------------------------------------------------------------------*/
  /* hide prev btn in Treatment Plan step  or while fixing case info   
     and disable it while sending data  */
  /*--------------------------------------------------------------------------------*/
  getClassprev() {
    let classbtn = `btn btn-next btn-primary btn-lg pull-left `;
    return classbtn;
  }
  /*--------------------------------------------------------------------------------*/
  /* hide next btn while fixing case info       
     and disable it while sending data   */
  /*--------------------------------------------------------------------------------*/
  getClassNext() {
    let classbtn = `btn btn-prev btn-primary btn-lg pull-right `;
    return classbtn;
  }

  confirmationLeave = () => {
    this.setState({
      showConfirmationUnsavedDataModal:
        !this.state.showConfirmationUnsavedDataModal,
      savedState: "",
    });
  };
  confirmationLeave2 = () => {
    this.setState({
      showConfirmationUnsavedDataModal2:
        !this.state.showConfirmationUnsavedDataModal2,
      savedState: "",
    });
  };
  saveToothMovementData = () => {
    const { current_ipr_position, current_ipr_tooth } =
      this.props.changesReducer.keys;
    const { data } = this.props.changesReducer;

    if (this.state.savedState === "closeModal") {
      this.setState({ viewer: false, finish_loading_ts: false });
      this.props.updateAllAdvancedModules({});
      this.props.setChangeTracker(false);
      this.setState({
        collapseViewerDetails: false,
        collapse4: false,
        showBiteRecord: false,
      });
      this.setState({ showUnsavedDataModal: false, step: 1 });
      this.setState({
        showIPRData: false,
        current_ipr_position: "",
        current_ipr_tooth: "",
      });
      this.resetShowActions();
    } else {
      let new_data = {
        ...this.props.teethIPROptions.teeth_ipr_data,
        [current_ipr_position]: {
          ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position],
          [current_ipr_tooth]: {
            ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
              current_ipr_tooth
            ],
            value: false,
            movement_image: data.restrict_tooth
              ? this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
                  current_ipr_tooth
                ].restrect
              : checkMovementTooth(
                  data,
                  this.props.teethIPROptions.saved_data[current_ipr_position][
                    current_ipr_tooth
                  ].movement
                )
              ? this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
                  current_ipr_tooth
                ].update
              : this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
                  current_ipr_tooth
                ].not_active,
            movement: {
              ...this.props.teethIPROptions.teeth_ipr_data[
                current_ipr_position
              ][current_ipr_tooth].movement,
              ...data,
              updated: checkMovementTooth(
                {
                  ...this.props.teethIPROptions.teeth_ipr_data[
                    current_ipr_position
                  ][current_ipr_tooth].movement,
                  ...data,
                },
                this.props.teethIPROptions.saved_data[current_ipr_position][
                  current_ipr_tooth
                ].movement
              ),
            },
          },
        },
      };
      this.props.setTeethIPRData(new_data);
      this.props.setChangeTracker(true);
      this.props.setUnsavedChanges(false);

      if (
        this.state.savedState === "showMenu" ||
        this.state.savedState === "showInbox"
      ) {
        this.setState({
          showUnsavedDataModal: false,
          step: this.state.savedStep || this.state.step,
        });
        this.setState({
          showToothMovement: false,
          current_ipr_position: "",
          current_ipr_tooth: "",
          showBiteRecord: false,
        });
        if (this.state.savedState === "showMenu") {
          this.setState({ showMenu: true, backClicked: false, savedState: "" });
        }
        if (this.state.savedState === "showInbox") {
          this.setState({
            showinbox: true,
            backClicked: false,
            savedState: "",
          });
        }
      } else {
        this.setState({
          showUnsavedDataModal: false,
          step: this.state.step + (this.state.backClicked ? -1 : +1),
          backClicked: false,
        });
      }
    }
  };

  saveIPRData = () => {
    const { current_ipr_position, current_ipr_tooth } =
      this.props.changesReducer.keys;
    const { data } = this.props.changesReducer;

    if (this.state.savedState === "closeModal") {
      this.setState({ viewer: false, finish_loading_ts: false });
      this.props.updateAllAdvancedModules({});
      this.props.setChangeTracker(false);
      this.setState({
        collapseViewerDetails: false,
        collapse4: false,
        showBiteRecord: false,
        showIPRToothMovement: false,
      });
      this.setState({ showUnsavedDataModal: false, step: 1 });
      this.setState({
        showIPRData: false,
        current_ipr_position: "",
        current_ipr_tooth: "",
        backClicked: false,
      });
      this.resetShowActions();
    } else {
      let new_data = {
        ...this.props.teethIPROptions.teeth_ipr_data,
        [current_ipr_position]: {
          ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position],
          [current_ipr_tooth]: {
            ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
              current_ipr_tooth
            ],
            ...data,
            updated: checkIPRTooth(
              data,
              this.props.teethIPROptions.saved_data[current_ipr_position][
                current_ipr_tooth
              ]
            ),
          },
        },
      };

      this.props.setTeethIPRData(new_data);
      this.props.setChangeTracker(true);
      this.props.setUnsavedChanges(false);
      if (
        this.state.savedState === "showMenu" ||
        this.state.savedState === "showInbox"
      ) {
        this.setState({
          showUnsavedDataModal: false,
          step: this.state.savedStep || this.state.step,
        });
        this.setState({
          showToothMovement: false,
          current_ipr_position: "",
          current_ipr_tooth: "",
          showBiteRecord: false,
          backClicked: false,
        });
        if (this.state.savedState === "showMenu") {
          this.setState({ showMenu: true, backClicked: false, savedState: "" });
        }
        if (this.state.savedState === "showInbox") {
          this.setState({
            showinbox: true,
            backClicked: false,
            savedState: "",
          });
        }
      } else {
        this.setState({
          showUnsavedDataModal: false,
          step: this.state.step + (this.state.backClicked ? -1 : +1),
          backClicked: false,
        });
      }
    }
  };
  saveOtherData = () => {
    if (this.state.savedState === "closeModal") {
      this.setState({ viewer: false, finish_loading_ts: false });
      this.props.updateAllAdvancedModules({});
      this.props.setChangeTracker(false);
      this.setState({
        collapseViewerDetails: false,
        collapse4: false,
        showBiteRecord: false,
      });
      this.setState({ showUnsavedDataModal: false, step: 1 });
      this.setState({
        showIPRData: false,
        current_ipr_position: "",
        current_ipr_tooth: "",
      });
      this.resetShowActions();
    } else {
      this.props.setChangeTracker(true);
      this.props.setUnsavedChanges(false);
      this.setState({
        showUnsavedDataModal: false,
        step: this.state.savedStep || this.state.step,
      });
      this.setState({
        showToothMovement: false,
        current_ipr_position: "",
        current_ipr_tooth: "",
        hideActions: false,
        showBiteRecord: false,
      });

      if (this.state.savedState === "showMenu") {
        this.setState({ showMenu: true });
      }
      if (this.state.savedState === "showInbox") {
        this.setState({ showinbox: true });
      }
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* ResetTabs  */
  /* --------------------------------------------------------------------------------*/
  ResetTabs = () => {
    this.setState({ activeTab: "1", active_side_tab: "1" });
  };

  /* --------------------------------------------------------------------------------*/
  /* set Visited Step  */
  /* --------------------------------------------------------------------------------*/
  setVisitedStepFun = (step) => {
    if (step === 2) {
      this.props.setVisitedStep("bite_check_step_visited");
    }
    if (step === 3) {
      this.props.setVisitedStep("movement_step_visited");
    }
    if (step === 4) {
      this.props.setVisitedStep("ipr_step_visited");
    }

    if (step === 5) {
      this.props.setVisitedStep("submit_step_visited");
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* cancel Changes On Tooth Movement                                                    */
  /*--------------------------------------------------------------------------------*/
  cancelChangesOnToothMovement = () => {
    const { current_ipr_position, current_ipr_tooth } =
      this.props.changesReducer.keys;
    let new_data = {
      ...this.props.teethIPROptions.teeth_ipr_data,
      [current_ipr_position]: {
        ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position],
        [current_ipr_tooth]: {
          ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
            current_ipr_tooth
          ],
          value: false,
          movement_image:
            this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
              current_ipr_tooth
            ].movement.updated &&
            !this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
              current_ipr_tooth
            ].movement.restrict_tooth
              ? this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
                  current_ipr_tooth
                ].update
              : this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
                  current_ipr_tooth
                ].movement.restrict_tooth
              ? this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
                  current_ipr_tooth
                ].restrect
              : this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][
                  current_ipr_tooth
                ].not_active,
        },
      },
    };
    this.props.setTeethIPRData(new_data);
    this.props.setUnsavedChanges(false);
    this.setState({
      showToothMovement: false,
      savedState: "",
      active_side_tab: "1",
      activeTab: "1",
    });
    this.props.setShowToothMovement(false);
  };

  clearStateToLeave = () => {
    this.setState({
      showBiteRecord: false,
      activeTab: "1",
      active_side_tab: "1",
      step: 1,
      savedState: "",
      viewer: !this.state.viewer,
      finish_loading_ts: false,
      showinbox: false,
      showMenu: false,
      showConfirmationUnsavedDataModal: false,
    });
    this.props.resetStepsData();
    this.props.resetChanges();
    this.SaveDataInReducer();
    this.resetShowActions();
  };
  clearStateToLeave2 = () => {
    if (this.state.savedState === "showMenu") {
      this.setState({
        showMenu: true,
        backClicked: false,
        savedState: "",
        showBiteRecord: false,
        activeTab: "1",
        active_side_tab: "1",
        step: 1,
        showinbox: false,
        showConfirmationUnsavedDataModal2: false,
      });
    } else if (this.state.savedState === "showInbox") {
      this.setState({
        showinbox: true,
        backClicked: false,
        savedState: "",
        showBiteRecord: false,
        activeTab: "1",
        active_side_tab: "1",
        step: 1,
        showMenu: false,
        showConfirmationUnsavedDataModal2: false,
      });
    } else {
      this.setState({
        showBiteRecord: false,
        activeTab: "1",
        active_side_tab: "1",
        step: 1,
        showinbox: false,
        showMenu: false,
        showConfirmationUnsavedDataModal2: false,
        backClicked: false,
        savedState: "",
      });
    }

    this.props.resetStepsData();
    this.props.resetChanges();
    this.SaveDataInReducer();
    this.resetShowActions();
  };

  blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  };

  onHandlePDF = async (file) => {
    this.setState({ loadPdf: false });
    const { uid } = this.props.caseReducer.case;
    const { key } = await uploadFile({
      name: `case-${uid}/ts-ipr-file/${
        this.state.details.id
      }/${new Date().valueOf()}/${file.name.replace(/\s/g, "")}`,
      contentType: file.type,
      file: file,
    });
    const pdfLink = keyToUrl(key);

    this.ApproveTreatmentSetup(pdfLink);
  };
  LoadPdf = () => {
    this.setState({ loadPdf: true, loading1: !this.state.loading1 });
  };

  setViewerHeight = () => {
    if (window.innerWidth < 839) {
      if (
        this.state.details.can_approve_or_disapprove &&
        !this.props.caseReducer.case.is_archived
      ) {
        this.setState({ viewerHeight: window.innerHeight - 130 });
      } else this.setState({ viewerHeight: window.innerHeight - 75 });
    }
  };

  setShowUnsavedModal = (value) => {
    this.setState({
      showUnsavedDataModal: value,
    });
  };

  setDynamicState = (data) => {
    this.setState({ ...data });
  };
  showActions = () => {
    this.setState({ hideActions: !this.state.hideActions });
  };
  resetShowActions = () => {
    this.setState({ hideActions: false });
  };
  toggleMenu = () => {
    this.setState({ showinbox: false });

    const { keys } = this.props.changesReducer.keys;
    const {
      bite_check_changed,
      teeth_movement_changed,
      ipr_changed,
      treatment_protocol_changed,
    } = this.props.teethIPROptions;

    if (this.state.showinbox) {
      this.setDynamicState({ showBiteRecord: false });
      this.setState({ showMenu: !this.state.showMenu });

      this.resetShowActions();
    } else {
      if (
        this.props.changesReducer.dataChanged ||
        bite_check_changed ||
        teeth_movement_changed ||
        ipr_changed ||
        treatment_protocol_changed
      ) {
        this.confirmationLeave2();
        this.setDynamicState({ savedState: "showMenu" });
      } else {
        if (keys?.current_ipr_position && keys?.current_ipr_tooth) {
          this.cancelChangesOnToothMovement();
          this.setState({ showMenu: true });
        } else {
          this.setDynamicState({ showBiteRecord: false });
          this.setState({ showMenu: !this.state.showMenu });

          this.resetShowActions();
        }
      }
    }
  };
  toggleInbox = () => {
    const {
      bite_check_changed,
      teeth_movement_changed,
      ipr_changed,
      treatment_protocol_changed,
    } = this.props.teethIPROptions;
    const { convert_to_hybrid_treatment } = this.props.hybridTreatment;

    // setShowInbox(!showInbox);
    this.setState({ showMenu: false });
    if (convert_to_hybrid_treatment) {
      this.props.SetHybridState(false);
    }
    const { keys } = this.props.changesReducer.keys;

    if (this.state.showMenu) {
      this.setDynamicState({ showBiteRecord: false });
      this.setState({ showinbox: !this.state.showinbox });
      this.resetShowActions();
    } else {
      if (
        this.props.changesReducer.dataChanged ||
        bite_check_changed ||
        teeth_movement_changed ||
        ipr_changed ||
        treatment_protocol_changed
      ) {
        this.confirmationLeave2();
        this.setDynamicState({ savedState: "showInbox" });
      } else {
        if (keys?.current_ipr_position && keys?.current_ipr_tooth) {
          this.cancelChangesOnToothMovement();
          this.setState({ showinbox: true });
        } else {
          this.setDynamicState({ showBiteRecord: false });
          this.setState({ showinbox: !this.state.showinbox });
          this.resetShowActions();
        }
      }
    }
  };

  /* --------------------------------------------------------------------------------*/
  /* clear state if go out from component   */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount() {
    this.props.resetAllTsData();
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { revised_data, disapproving_data, ipr_data, iprNotes, loadPdf } =
      this.state;
    const { content } = this.props.languageReducer;
    const { activeTab, step, active_side_tab } = this.state;
    const { state, front_title, title, doctor, patient } = this.state.details;

    const {
      bite_check_changed,
      teeth_movement_changed,
      ipr_changed,
      treatment_protocol_changed,
      show_Tooth_Movement,
    } = this.props.teethIPROptions;
    const { used_ipr } =
      this.props.teethIPROptions[this.state.setupDetails.id] || {};
    const { patient_info, case_type, is_archived, uid, isNewAdvancedModules } =
      this.props.caseReducer.case;
    const { block_case_submission } = this.props.userReducer.currentUser;

    const Step = (props) => (
      <div
        className={`Stepper__step ${
          step > props.indicator && props.visited && props.pervVisited
            ? "done-stepper"
            : ""
        } `}
        onClick={() => {
          if (this.props.changesReducer.dataChanged) {
            this.setState({
              showUnsavedDataModal: true,
              savedStep: props.indicator,
            });
          } else {
            this.setState({ step: props.indicator });
            if (props.indicator > 1) {
              this.props.setVisitedStep(props.ReduxKey);
            }
          }
          if (show_Tooth_Movement && !this.props.changesReducer.dataChanged) {
            this.cancelChangesOnToothMovement();
          }
        }}
      >
        <div
          className={`${
            step === props.indicator ? "current-step_stepper" : ""
          } ${
            step > props.indicator && props.visited ? "done-step_stepper" : ""
          }`}
        >
          <div
            className={
              props.updated === true ? "Stepper__edit" : "Stepper__edit_empty"
            }
          >
            {props.updated ? "Edited" : "edited"}{" "}
          </div>
          <div className="Stepper__indicator">
            <span className="Stepper__info">
              <div className="step_indicator"></div>
            </span>
          </div>
          <div className="Stepper__label">{props.title}</div>
          <div className="Stepper__panel">{props.children}</div>
        </div>
      </div>
    );
    // Overlay
    const Stepper = (props) => (
      <div
        className={`Stepper ${props.isVertical ? "Stepper--vertical" : ""} ${
          props.isInline ? "Stepper--inline" : ""
        }`}
      >
        {props.children}
      </div>
    );

    return (
      <div className="ts-modal">
        {/*--------------------------------------------------------------------------------*/}
        {/* revise data Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.showRevisedData}
          toggle={this.RevisedDataModal}
          className="modal_revised_data"
        >
          <ModalHeader toggle={this.RevisedDataModal}></ModalHeader>
          <ModalBody className="">
            <Summary revised_data={true} id={this.state.details.id} />
            {/* <RevisedTable revised_data={revised_data} /> */}
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* revise data Modal Mobile */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.toggleReviseModalMobile && window.innerWidth < 839}
          className="confarim_preferences_modal"
        >
          <ModalHeader />
          <ModalBody className="">
            {this.state.toggleReviseModalMobileSuccess && (
              <div className="revise_mobile_grid">
                <img src={check} alt="check mark" />
                <div className="title-1-text">
                  {content.revision_submitted_successfully}
                </div>
              </div>
            )}

            {!this.state.toggleReviseModalMobileSuccess && (
              <TreatmentProtocols mobile={true} />
            )}
          </ModalBody>

          <ModalFooter>
            <div className="confarim_preferences-btns-grid">
              <div className="confarim_preferences_btn_width ">
                <Button
                  className={`btn button-seme-bold-text ${
                    this.state.toggleReviseModalMobileSuccess
                      ? "light-green-btn"
                      : "white-btn"
                  } pull-right cml1 button-seme-bold-text  full_width_in_button_inpopup`}
                  size="md"
                  color="danger"
                  onClick={() => {
                    this.state.toggleReviseModalMobileSuccess
                      ? this.openReviseModalMobile()
                      : this.cancelReviseModalMobile();
                  }}
                >
                  {this.state.toggleReviseModalMobileSuccess
                    ? content.exit
                    : content.cancel}
                </Button>
              </div>
              {!this.state.toggleReviseModalMobileSuccess && (
                <div className="confarim_preferences_btn_width confarim_preferences_btn_grid1 ">
                  {!bite_check_changed &&
                  !teeth_movement_changed &&
                  !ipr_changed &&
                  !treatment_protocol_changed ? (
                    <Button
                      disabled={disapproving_data}
                      className={`light-green-btn btn pull-right cml1 button-seme-bold-text full_width_in_button_inpopup button-seme-bold-text`}
                      onClick={() => {
                        this.Approve();
                      }}
                    >
                      {content.submit_without_modifications}{" "}
                      <i className="pl-1 fas fa-angle-right"></i>
                    </Button>
                  ) : (
                    <>
                      <Button
                        //disabled={disapproving_data}
                        className={`light-green-btn btn pull-right cml1 button-seme-bold-text full_width_in_button_inpopup button-seme-bold-text`}
                        onClick={async () => {
                          this.mapDataToAPI();
                          this.setVisitedStepFun(5);
                        }}
                      >
                        {content.submit}{" "}
                        <i className="pl-1 fas fa-angle-right"></i>
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
          </ModalFooter>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/*  Approve treatment setup Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        {loadPdf && used_ipr && (
          <BlobProvider
            document={
              <MyDocument
                content={content}
                data={ipr_data}
                doctor={doctor}
                patient={patient}
                case_data={{ uid: uid }}
                notes={iprNotes}
              />
            }
            fileName={`${content.ipr_indicator}_${uid}.pdf`}
          >
            {({ blob, url, loading, error }) => {
              if (blob) {
                const file = this.blobToFile(
                  blob,
                  `${content.ipr_indicator}_${uid}.pdf`
                );
                if (!loading) {
                  this.onHandlePDF(file);
                  this.setState({ loadPdf: false });
                }
              }

              return (
                <Button
                  className="btn green-green-btn button-bold-text"
                  size="sm"
                  disabled={loading}
                >
                  {content.ipr_file}
                </Button>
              );
            }}
          </BlobProvider>
        )}
        <Modal
          isOpen={this.state.Approve}
          toggle={this.Approve}
          className={
            window.innerWidth > 839
              ? "c-modal-approve-ts"
              : "confarim_preferences_modal"
          }
        >
          <ModalHeader toggle={this.Approve}></ModalHeader>
          <ModalBody>
            <CustomeHeader
              title={content.approve_treatment_setup}
              withoutLine={true}
            />
            <div className={"approve_ts_container"}>
              <span className="details_normal">{content.patient_name}</span>{" "}
              {` : `}
              <span className="details_bold">{patient_info.full_name}</span>
              <br></br>
              <span className="details_normal"> {content.case_type}</span>
              {` : `}
              <span className="details_bold">
                {content[caseType[case_type]] || case_type}
              </span>
              <br></br>
              <span className="details_normal">
                {content.invoice_based_on_case_type}
              </span>
              <br></br>
              <div className="cmt1">
                <span className="details_bold">
                  {`${this.state.details.upper_steps} `}
                </span>
                <span className="details_normal">{content.upper_aligners}</span>
                {` , `}
                <span className="details_bold">
                  {`${this.state.details.lower_steps} `}
                </span>
                <span className="details_normal">{content.lower_aligners}</span>
              </div>
              <hr></hr>
              <div
                dangerouslySetInnerHTML={{ __html: content.approve_message }}
              />
              <hr></hr>
              <div className="approve_tp_note">
                <div className="approve_tp_header">
                  {content.please_be_advised}
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: content.approve_ts_note }}
                />
              </div>
            </div>
            <div
              className={
                window.innerWidth > 839 ? "cmt3 " : "grid-approve-mobile cmy3 "
              }
            >
              <Button
                className="btn light-green-btn-ts pull-right addres-delete-margin "
                size="md"
                disabled={this.state.loading1}
                onClick={() => {
                  if (used_ipr) {
                    this.LoadPdf();
                  } else {
                    this.ApproveTreatmentSetup();
                  }
                }}
              >
                {content.approve}
              </Button>
              {/* <Button
                className=" btn white-btn pull-right addres-delete-margin cmb3 button-seme-bold-text"
                size="md"
                color="danger"
                disabled={this.state.loading1}
                onClick={() => this.Approve()}
              >
                {content.cancel}
              </Button> */}
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Approve treatment setup Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Leave Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.showConfirmationUnsavedDataModal}
          // toggle={false}
          className="c-modal c-border-light"
        >
          {/* <ModalHeader toggle={this.confirmationDisapprove}>
          </ModalHeader> */}
          <ModalBody className="cp2">
            <div>
              <div className="note-disapprove">{content.disapprove_note}</div>
              <div className="confirmation-disapprove">
                {content.confirmation_leave_page}
              </div>
            </div>
            <div className="cmt3 confarim_preferences-btns-grid">
              <Button
                className="btn light-green-btn pull-right cml1 button-seme-bold-text full_width_in_button_inpopup"
                size="md"
                // disabled={this.state.disapproving_data}
                onClick={() => this.clearStateToLeave()}
              >
                {content.yes}
              </Button>
              <Button
                className=" btn white-btn pull-right cml1 button-seme-bold-text full_width_in_button_inpopup"
                size="md"
                color="danger"
                // disabled={this.state.disapproving_data}
                onClick={() => this.confirmationLeave()}
              >
                {content.no}
              </Button>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Leave Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Leave Modal 2 */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.showConfirmationUnsavedDataModal2}
          // toggle={false}
          className="c-modal c-border-light"
        >
          {/* <ModalHeader toggle={this.confirmationDisapprove}>
          </ModalHeader> */}
          <ModalBody className="cp2">
            <div>
              <div className="note-disapprove">{content.disapprove_note}</div>
              <div className="confirmation-disapprove">
                {content.confirmation_leave_page}
              </div>
            </div>
            <div className="cmt3 confarim_preferences-btns-grid">
              <Button
                className="btn light-green-btn pull-right cml1 button-seme-bold-text full_width_in_button_inpopup"
                size="md"
                // disabled={this.state.disapproving_data}
                onClick={() => this.clearStateToLeave2()}
              >
                {content.yes}
              </Button>
              <Button
                className=" btn white-btn pull-right cml1 button-seme-bold-text full_width_in_button_inpopup"
                size="md"
                color="danger"
                // disabled={this.state.disapproving_data}
                onClick={() => this.confirmationLeave2()}
              >
                {content.no}
              </Button>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Leave Modal 2 */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Leave Modal 3 */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.showConfirmationUnsavedDataModal3}
          // toggle={false}
          className="c-modal c-border-light"
        >
          {/* <ModalHeader toggle={this.confirmationDisapprove}>
          </ModalHeader> */}
          <ModalBody className="cp2">
            <div>
              <div className="note-disapprove">{content.disapprove_note}</div>
              <div className="confirmation-disapprove">
                {content.confirmation_leave_page}
              </div>
            </div>
            <div className="cmt3 confarim_preferences-btns-grid">
              <Button
                className="btn light-green-btn pull-right cml1 full_width_in_button_inpopup"
                size="md"
                // disabled={this.state.disapproving_data}
                onClick={() => this.exitTSMobileYes()}
              >
                {content.yes}
              </Button>
              <Button
                className=" btn white-btn pull-right cml1 full_width_in_button_inpopup"
                size="md"
                color="danger"
                // disabled={this.state.disapproving_data}
                onClick={() => this.exitTSMobileNo()}
              >
                {content.no}
              </Button>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Leave Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.showUnsavedDataModal}
          //   toggle={false}
          className="c-modal c-border-light"
        >
          {/* <ModalHeader toggle={this.confirmationDisapprove}>
          </ModalHeader> */}
          <ModalBody className="cp2">
            <div>
              <div className="note-disapprove">
                {content.there_are_unsaved_changes}
              </div>
              <div className="confirmation-disapprove">
                {content.confirmation_leave_page}
              </div>
            </div>
            <div className="cmt3">
              <Button
                className="btn light-green-btn pull-right cml1 button-seme-bold-text"
                size="md"
                // disabled={this.state.disapproving_data}
                onClick={() => {
                  if (
                    this.props.changesReducer.keys.step === "tooth_movement"
                  ) {
                    this.saveToothMovementData();
                  } else if (this.props.changesReducer.keys.step === "ipr") {
                    this.saveIPRData();
                  } else {
                    this.saveOtherData();
                  }
                }}
              >
                {content.save}
              </Button>
              <Button
                className=" btn white-btn pull-right cml1 button-seme-bold-text"
                size="md"
                color="danger"
                //disabled={this.state.disapproving_data}
                onClick={() => {
                  this.setState({
                    showUnsavedDataModal: false,
                    savedState: "",
                  });
                }}
              >
                {content.no}
              </Button>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* Viewer Modal the previous one  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.viewer3}
          // toggle={this.toggleViewerModal}
          className="modalx"
        >
          {this.state.width < 840 && (
            <ModalHeader
              toggle={this.toggleViewerModal}
              className="w-100 align-items-center"
            >
              <Row className="w-100">
                <Col xs="10" md="10" lg="6">
                  {content.TS_Viewer}
                </Col>
              </Row>
            </ModalHeader>
          )}
          <ModalBody className="p-0">
            {this.state.width < 840 ? (
              <div className="row h-100">
                <div className="pr-0 col">
                  <TSViewer
                    viewerId={this.state.viewerId}
                    viewerHeight={this.state.viewerHeight}
                    details={this.state.details}
                    doctorId={this.props.doctorId}
                    caseId={this.props.case.uid}
                    collapse={this.state.collapse4}
                    case_data={this.state.case}
                    showBiteRecord={this.state.showBiteRecord}
                    saveRejectionReason={this.saveRejectionReason}
                    finishLoadingTs={this.finishLoadingTs}
                    viewer={this.state.viewer}
                  />
                </div>
              </div>
            ) : (
              <div className="row h-100">
                <div className="pr-0 col">
                  {this.state.showBiteRecord && (
                    <span
                      onClick={this.ToggleOptionsFromBiteRecord}
                      className="pull-right close-steps"
                    >
                      <i className="fa fa-times icon-md2" />
                    </span>
                  )}
                  <TSViewer
                    viewerId={this.state.viewerId}
                    viewerHeight={this.state.viewerHeight}
                    details={this.state.details}
                    doctorId={this.props.doctorId}
                    caseId={this.props.case.uid}
                    collapse={this.state.collapse4}
                    case_data={this.state.case}
                    showBiteRecord={this.state.showBiteRecord}
                    saveRejectionReason={this.saveRejectionReason}
                    finishLoadingTs={this.finishLoadingTs}
                    viewer={this.state.viewer}
                  />
                </div>
                {!this.state.showBiteRecord &&
                  !this.state.collapseViewerDetails &&
                  !this.state.showToothIPROptions &&
                  this.state.width > 839 && (
                    <div className="col-auto ">
                      <div>
                        <span
                          onClick={this.toggleViewerModal}
                          className="p-2 pull-right"
                        >
                          <i className="fa fa-times icon-md2" />
                        </span>
                        <span
                          onClick={this.toggleViewerDetailsModal}
                          className="pt-2 pull-left"
                        >
                          <i className="fa fa-outdent icon-md" />
                        </span>
                      </div>
                    </div>
                  )}
                {!this.state.showBiteRecord &&
                  this.state.collapseViewerDetails &&
                  this.state.width > 839 && (
                    <div className=" col-6">
                      {!this.state.showToothIPROptions && (
                        <div className="m-2 row">
                          <span
                            onClick={this.toggleViewerDetailsModal}
                            className="p-0 text-left col pull-left"
                          >
                            <i className="fa fa-indent icon-md" />
                          </span>
                          <span
                            onClick={this.toggleViewerModal}
                            className="p-0 text-right col pull-right"
                          >
                            <i className="fa fa-times icon-md2" />
                          </span>
                        </div>
                      )}
                      <div className="m-2 row">
                        <NewViewerDetails
                          doctorId={this.state.doctorId}
                          data={this.props.case}
                          details={this.state.details}
                          props={this.state.data}
                          GetAlignerCase={this.props.GetAlignerCase}
                          ToggleBitsRecord={this.ToggleBitsRecord}
                          mapped_advanced_modules={
                            this.state.mapped_advanced_modules
                          }
                          showToothIPROptions={this.state.showToothIPROptions}
                          ToggleOptions={this.ToggleOptions}
                          finish_loading_ts={this.state.finish_loading_ts}
                          GetAllTs={this.props.GetTreatmentSetups}
                          GetTretmentSetupById={this.GetTretmentSetupById}
                          showToothMovement={this.state.showToothMovement}
                          showIPRToothMovement={this.state.showIPRToothMovement}
                          current_ipr_position={this.state.current_ipr_position}
                          current_ipr_tooth={this.state.current_ipr_tooth}
                          showIPRData={this.state.showIPRData}
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/*End  Viewer Modal the previous one  */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Viewer Modal the new  one  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.viewer1}
          toggle={this.toggleViewerModal}
          className="modalx model_ts"
        >
          <div className="tsmodelTotalDiv">
            <div className="model_ts">
              <b className="logo-icon pointer">
                <img
                  onClick={this.logodirection}
                  src={Logo}
                  alt="homepage"
                  className="dark-logo pointer cpl3"
                  height="54"
                  width="181"
                />
              </b>
              {this.state.width < 840 && (
                <div className="model_ts_nav-close-parent pull-right">
                  <div className="model_ts_nav-close">
                    <div></div>
                    <div></div>
                    <div>
                      <span className="pull-right close_ts ">
                        <img
                          src={x}
                          alt="close"
                          className="fa fa-times icon-md2"
                          onClick={this.toggleViewerModal}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {this.state.width > 839 && (
                <div className="model_ts_navbar">
                  <b className="logo-icon pointer">
                    <img
                      onClick={this.logodirection}
                      src={Logo}
                      alt="homepage"
                      className="dark-logo pointer cpl3"
                      height="54"
                      width="181"
                    />
                  </b>
                  <div>
                    <p className="model_ts_nav-title">{content.patient_name}</p>
                    <p className="model_ts_nav_data">
                      {patient_info.full_name}
                    </p>
                  </div>
                  <div>
                    <p className="model_ts_nav-title">{content.case_type}</p>
                    <p className="model_ts_nav_data">
                      {" "}
                      {content[caseType[case_type]] || case_type}
                    </p>
                  </div>
                  <div>
                    <p className="model_ts_nav-title">
                      {content.Treatment_Setup}
                    </p>
                    <p className="model_ts_nav_data">
                      {" "}
                      {this.state.details.front_title}{" "}
                    </p>
                  </div>
                  <div>
                    <p className="model_ts_nav-title">{content.Case_ID}</p>
                    <p className="model_ts_nav_data">
                      {" "}
                      {this.state.setupDetails.id}
                    </p>
                  </div>

                  <div>
                    <p className="model_ts_nav-title">{content.status}</p>
                    <div className="model_ts_nav_data">
                      {" "}
                      <div className="case_status">
                        <div className="status_button ts-dot-grid">
                          <span
                            className="ts-dot"
                            style={{
                              backgroundColor:
                                ts_status[this.state.details?.state]?.color ||
                                "#898A8D",
                            }}
                          ></span>{" "}
                          <span>
                            {" "}
                            {ts_status[this.state.details?.state]?.lable ||
                              this.state.details.state}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="model_ts_nav-close-parent">
                    <div className="model_ts_nav-close">
                      <div></div>
                      <div className="inbox-container">
                        {" "}
                        <img
                          src={Vector}
                          alt="close"
                          className="inbox_ts"
                          onClick={this.inboxmsg}
                        />
                        {this.props.inbox.messages.length > 0 && (
                          <div className="tsInbox-num ">
                            {this.props.inbox.messages.length}
                          </div>
                        )}
                      </div>
                      <div>
                        <span className="pull-right close_ts ">
                          <img
                            src={x}
                            alt="close"
                            className="fa fa-times icon-md2"
                            onClick={this.toggleViewerModal}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <ModalBody className="p-0 ts-modal-body"> */}
            {/* ///new-height-change/// */}
            <ModalBody
              className="p-0"
              style={{
                height:
                  this.state.width < 839
                    ? this.state.details.can_approve_or_disapprove &&
                      !is_archived
                      ? window.innerHeight - 130
                      : window.innerHeight - 60
                    : window.innerHeight,
              }}
            >
              {/* ///new-height-change/// */}
              {this.state.width > 839 ? (
                <div className="ts-main-grid">
                  <div
                    className={
                      this.state.showBiteRecord ||
                      this.props.hybridTreatment.convert_to_hybrid_treatment
                        ? "tsViewer-tab"
                        : "tsViewer"
                    }
                  >
                    <div className="c-tabs spaceeInTS inboxParentlayput ">
                      {(this.state.showBiteRecord ||
                        this.props.hybridTreatment
                          .convert_to_hybrid_treatment) &&
                        this.state.width > 839 && (
                          <Nav tabs>
                            {/*--------------------------------------------------------------------------------*/}
                            {/* TS Viewer tab */}
                            {/*--------------------------------------------------------------------------------*/}
                            <NavItem className="ts-custom-tabs">
                              <NavLink
                                className={classnames({
                                  active: active_side_tab === "1",
                                })}
                                onClick={() => {
                                  this.toggleSideTabs("1");
                                }}
                              >
                                {content._3D_viewer}
                              </NavLink>
                            </NavItem>
                            {/*--------------------------------------------------------------------------------*/}
                            {/* Movement Table tab */}
                            {/*--------------------------------------------------------------------------------*/}
                            <NavItem className="ts-custom-tabs">
                              <NavLink
                                className={classnames({
                                  active: active_side_tab === "2",
                                })}
                                onClick={() => {
                                  this.toggleSideTabs("2");
                                }}
                              >
                                {content.Movement_Table}
                              </NavLink>
                            </NavItem>
                          </Nav>
                        )}
                      <TabContent
                        activeTab={active_side_tab}
                        className="case-info-container-ts Parentlayput cmt2"
                      >
                        {/*--------------------------------------------------------------------------------*/}
                        {/* TSViewer tab content  */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* //desctop viewer// */}
                        <TabPane tabId="1">
                          <TSViewer
                            viewerId={this.state.viewerId}
                            viewerHeight={this.state.viewerHeight}
                            details={this.state.details}
                            doctorId={this.props.doctorId}
                            caseId={this.props.case.uid}
                            collapse={this.state.collapse4}
                            case_data={this.state.case}
                            showBiteRecord={this.state.showBiteRecord}
                            saveRejectionReason={this.saveRejectionReason}
                            finishLoadingTs={this.finishLoadingTs}
                            viewer={this.state.viewer}
                          />
                        </TabPane>
                        {/*--------------------------------------------------------------------------------*/}
                        {/*Movement Table tab content  */}
                        {/*--------------------------------------------------------------------------------*/}
                        <TabPane tabId="2" className="tabs-content-height">
                          <NewViewerDetails
                            doctorId={this.state.doctorId}
                            data={this.props.case}
                            details={this.state.details}
                            props={this.state.data}
                            GetAlignerCase={this.props.GetAlignerCase}
                            ToggleBitsRecord={this.ToggleBitsRecord}
                            mapped_advanced_modules={
                              this.state.mapped_advanced_modules
                            }
                            showToothIPROptions={this.state.showToothIPROptions}
                            ToggleOptions={this.ToggleOptions}
                            finish_loading_ts={this.state.finish_loading_ts}
                            GetAllTs={this.props.GetTreatmentSetups}
                            GetTretmentSetupById={this.GetTretmentSetupById}
                            showToothMovement={this.state.showToothMovement}
                            showIPRToothMovement={
                              this.state.showIPRToothMovement
                            }
                            current_ipr_position={
                              this.state.current_ipr_position
                            }
                            current_ipr_tooth={this.state.current_ipr_tooth}
                            showIPRData={this.state.showIPRData}
                            showBiteRecord={this.state.showBiteRecord}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>

                  <div className="Parentlayout">
                    <div className="c-tabs spaceeInTS inboxParentlayput ">
                      {this.state.showinbox ? (
                        <div>
                          <div className="back-container">
                            <Inbox
                              className="inboxNewlayout"
                              caseId={uid}
                              formKey={"viewer"}
                            />
                          </div>
                          <hr className="line" />
                          <div className="back-button-container">
                            <button
                              className={`btn  white-btn-ts button-seme-bold-text`}
                              onClick={this.back}
                            >
                              {content.back}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {this.props.hybridTreatment
                            .convert_to_hybrid_treatment ? (
                            <HybridTreatment
                              toggleViewerModal={this.toggleViewerModal}
                              GetAllData={this.GetAllData}
                              details={this.state.details}
                              ResetTabs={this.ResetTabs}
                            />
                          ) : !this.state.showBiteRecord ? (
                            <div>
                              <div className="">
                                <Nav tabs>
                                  {/*--------------------------------------------------------------------------------*/}
                                  {/* Movement Table tab */}
                                  {/*--------------------------------------------------------------------------------*/}
                                  <NavItem className="ts-custom-tabs">
                                    <NavLink
                                      className={classnames({
                                        active: activeTab === "1",
                                      })}
                                      onClick={() => {
                                        this.toggleTabs("1");
                                      }}
                                    >
                                      {content.Movement_Table}
                                    </NavLink>
                                  </NavItem>
                                  {/*--------------------------------------------------------------------------------*/}
                                  {/* Applied Treatments tab */}
                                  {/*--------------------------------------------------------------------------------*/}
                                  {case_type !== "Retainer" && (
                                    <NavItem className="ts-custom-tabs">
                                      <NavLink
                                        className={classnames({
                                          active: activeTab === "2",
                                        })}
                                        onClick={() => {
                                          this.toggleTabs("2");
                                        }}
                                      >
                                        {content.Applied_Treatments}
                                      </NavLink>
                                    </NavItem>
                                  )}

                                  {/*--------------------------------------------------------------------------------*/}
                                  {/* Notes tab */}
                                  {/*--------------------------------------------------------------------------------*/}
                                  {case_type !== "Retainer" &&
                                    uid &&
                                    (this.state.details.technician_notes ||
                                      this.state.details
                                        .manufacturing_notes) && (
                                      <NavItem className="ts-custom-tabs">
                                        <NavLink
                                          className={classnames({
                                            active: activeTab === "3",
                                          })}
                                          onClick={() => {
                                            this.toggleTabs("3");
                                          }}
                                        >
                                          <div className="inbox-heartbit">
                                            {content.Notes}
                                            <div className="notify-in-ts-padding">
                                              <div className="notify">
                                                <span className="heartbit" />{" "}
                                                <span className="point" />
                                              </div>
                                            </div>
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                    )}
                                </Nav>

                                <TabContent
                                  activeTab={activeTab}
                                  className="case-info-container-ts Parentlayput"
                                >
                                  {/*--------------------------------------------------------------------------------*/}
                                  {/*Movement Table tab content  */}
                                  {/*--------------------------------------------------------------------------------*/}
                                  <TabPane tabId="1">
                                    <NewViewerDetails
                                      doctorId={this.state.doctorId}
                                      data={this.props.case}
                                      details={this.state.details}
                                      props={this.state.data}
                                      GetAlignerCase={this.props.GetAlignerCase}
                                      ToggleBitsRecord={this.ToggleBitsRecord}
                                      mapped_advanced_modules={
                                        this.state.mapped_advanced_modules
                                      }
                                      showToothIPROptions={
                                        this.state.showToothIPROptions
                                      }
                                      ToggleOptions={this.ToggleOptions}
                                      finish_loading_ts={
                                        this.state.finish_loading_ts
                                      }
                                      GetAllTs={this.props.GetTreatmentSetups}
                                      GetTretmentSetupById={
                                        this.GetTretmentSetupById
                                      }
                                      showToothMovement={
                                        this.state.showToothMovement
                                      }
                                      showIPRToothMovement={
                                        this.state.showIPRToothMovement
                                      }
                                      current_ipr_position={
                                        this.state.current_ipr_position
                                      }
                                      current_ipr_tooth={
                                        this.state.current_ipr_tooth
                                      }
                                      showIPRData={this.state.showIPRData}
                                      showBiteRecord={this.state.showBiteRecord}
                                    />
                                  </TabPane>

                                  {/*--------------------------------------------------------------------------------*/}
                                  {/*AppliedTreatment tab content  */}
                                  {/*--------------------------------------------------------------------------------*/}
                                  <TabPane tabId="2" className="c-tab-pane">
                                    <AppliedTreatment
                                      advanced_modules={
                                        this.state.details.advanced_modules
                                      }
                                      mapped_advanced_modules={
                                        this.state.mapped_advanced_modules
                                      }
                                    />
                                  </TabPane>
                                  {/*--------------------------------------------------------------------------------*/}
                                  {/*note tab content  */}
                                  {/*--------------------------------------------------------------------------------*/}
                                  <TabPane tabId="3" className="c-tab-pane">
                                    <Notes
                                      manufacturing_notes={
                                        this.state.details.manufacturing_notes
                                      }
                                      technician_notes={
                                        this.state.details.technician_notes
                                      }
                                    />
                                  </TabPane>
                                </TabContent>
                              </div>

                              <hr className="line" />
                              {this.state.details.can_approve_or_disapprove &&
                                !is_archived &&
                                !block_case_submission && (
                                  <div className="actions-grid2">
                                    <div></div>
                                    <div>
                                      <Button
                                        className={`btn white-btn-ts button-seme-bold-text`}
                                        size="sm"
                                        type="button"
                                        onClick={() => {
                                          this.ToggleBitsRecord();
                                        }}
                                      >
                                        {content.Revise_Treatment}{" "}
                                      </Button>
                                    </div>
                                    <div>
                                      <Button
                                        className={`btn green-green-btn button-seme-bold-text `}
                                        size="sm"
                                        type="button"
                                        onClick={() => {
                                          this.Approve();
                                        }}
                                      >
                                        {content.Approve_Treatment}
                                      </Button>
                                    </div>
                                  </div>
                                )}
                            </div>
                          ) : (
                            <div className="ts-stepper">
                              {!this.state.disapproved_successfully ? (
                                <div>
                                  <Stepper>
                                    <Step
                                      indicator={1}
                                      title={content.revision_notes}
                                      updated={treatment_protocol_changed}
                                      onClick={() => {
                                        this.setState({ step: 1 });
                                      }}
                                      pervVisited={
                                        this.props.teethIPROptions
                                          .bite_check_step_visited
                                      }
                                      ReduxKey={"protocol_step_visited"}
                                      // visited={
                                      //   this.props.teethIPROptions
                                      //     .protocol_step_visited
                                      // }
                                      visited={true}
                                    >
                                      <div className="Content">
                                        <button>Prev</button>
                                        <button>Next</button>
                                      </div>
                                    </Step>
                                    <Step
                                      indicator={2}
                                      title={content.bite_check}
                                      updated={bite_check_changed}
                                      onClick={() => {
                                        this.setState({ step: 2 });
                                      }}
                                      pervVisited={
                                        this.props.teethIPROptions
                                          .movement_step_visited
                                      }
                                      ReduxKey={"bite_check_step_visited"}
                                      visited={
                                        this.props.teethIPROptions
                                          .bite_check_step_visited
                                      }
                                    >
                                      <div className="Content">
                                        <button>Prev</button>
                                        <button>Next</button>
                                      </div>
                                    </Step>
                                    <Step
                                      indicator={3}
                                      title={content.teeth_movement}
                                      updated={checkMovement(
                                        this.props.teethIPROptions.saved_data,
                                        this.props.teethIPROptions
                                          .teeth_ipr_data
                                      )}
                                      onClick={() => {
                                        this.setState({ step: 3 });
                                      }}
                                      pervVisited={
                                        this.props.teethIPROptions
                                          .movement_step_visited &&
                                        this.props.teethIPROptions
                                          .ipr_step_visited
                                      }
                                      ReduxKey={"movement_step_visited"}
                                      visited={
                                        this.props.teethIPROptions
                                          .movement_step_visited
                                      }
                                    >
                                      <div className="Content">
                                        <button>Prev</button>
                                        <button>Next</button>
                                      </div>
                                    </Step>
                                    <Step
                                      indicator={4}
                                      title={content.ipr}
                                      updated={checkIPR(
                                        this.props.teethIPROptions.saved_data,
                                        this.props.teethIPROptions
                                          .teeth_ipr_data
                                      )}
                                      onClick={() => {
                                        this.setState({ step: 4 });
                                      }}
                                      pervVisited={
                                        this.props.teethIPROptions
                                          .ipr_step_visited &&
                                        this.props.teethIPROptions
                                          .submit_step_visited
                                      }
                                      ReduxKey={"ipr_step_visited"}
                                      visited={
                                        this.props.teethIPROptions
                                          .ipr_step_visited
                                      }
                                    >
                                      <div className="Content">
                                        <button>Prev</button>
                                        <button>Next</button>
                                      </div>
                                    </Step>

                                    <Step
                                      indicator={5}
                                      title={content.review_and_submit}
                                      updated={false}
                                      onClick={() => {
                                        this.setState({ step: 5 });
                                      }}
                                      pervVisited={
                                        this.props.teethIPROptions
                                          .ipr_step_visited &&
                                        this.props.teethIPROptions
                                          .submit_step_visited
                                      }
                                      ReduxKey={"submit_step_visited"}
                                      visited={
                                        this.props.teethIPROptions
                                          .submit_step_visited
                                      }
                                    >
                                      <div className="Content">
                                        <button>Prev</button>
                                        <button>Next</button>
                                      </div>
                                    </Step>
                                  </Stepper>
                                  <div className="ts-steps">
                                    {step === 1 && (
                                      <div>
                                        <TreatmentProtocols />
                                      </div>
                                    )}
                                    {step === 2 && (
                                      <div>
                                        <ReasonsRejection />
                                      </div>
                                    )}

                                    {step === 3 && (
                                      <div>
                                        <NewViewerDetails
                                          doctorId={this.state.doctorId}
                                          data={this.props.case}
                                          details={this.state.details}
                                          props={this.state.data}
                                          GetAlignerCase={
                                            this.props.GetAlignerCase
                                          }
                                          ToggleBitsRecord={
                                            this.ToggleBitsRecord
                                          }
                                          mapped_advanced_modules={
                                            this.state.mapped_advanced_modules
                                          }
                                          showToothIPROptions={true}
                                          ToggleOptions={this.ToggleOptions}
                                          finish_loading_ts={
                                            this.state.finish_loading_ts
                                          }
                                          GetAllTs={
                                            this.props.GetTreatmentSetups
                                          }
                                          GetTretmentSetupById={
                                            this.GetTretmentSetupById
                                          }
                                          showTeethMovement={true}
                                          showToothMovement={
                                            this.state.showToothMovement
                                          }
                                          showIPRToothMovement={
                                            this.state.showIPRToothMovement
                                          }
                                          current_ipr_position={
                                            this.state.current_ipr_position
                                          }
                                          current_ipr_tooth={
                                            this.state.current_ipr_tooth
                                          }
                                          showIPRData={this.state.showIPRData}
                                          showBiteRecord={
                                            this.state.showBiteRecord
                                          }
                                        />
                                      </div>
                                    )}

                                    {step === 4 && (
                                      <div>
                                        <NewViewerDetails
                                          doctorId={this.state.doctorId}
                                          data={this.props.case}
                                          details={this.state.details}
                                          props={this.state.data}
                                          GetAlignerCase={
                                            this.props.GetAlignerCase
                                          }
                                          ToggleBitsRecord={
                                            this.ToggleBitsRecord
                                          }
                                          mapped_advanced_modules={
                                            this.state.mapped_advanced_modules
                                          }
                                          showToothIPROptions={true}
                                          ToggleOptions={this.ToggleOptions}
                                          finish_loading_ts={
                                            this.state.finish_loading_ts
                                          }
                                          GetAllTs={
                                            this.props.GetTreatmentSetups
                                          }
                                          GetTretmentSetupById={
                                            this.GetTretmentSetupById
                                          }
                                          showIPRMovement={true}
                                          showToothMovement={
                                            this.state.showToothMovement
                                          }
                                          showIPRToothMovement={
                                            this.state.showIPRToothMovement
                                          }
                                          current_ipr_position={
                                            this.state.current_ipr_position
                                          }
                                          current_ipr_tooth={
                                            this.state.current_ipr_tooth
                                          }
                                          showIPRData={this.state.showIPRData}
                                          showBiteRecord={
                                            this.state.showBiteRecord
                                          }
                                        />
                                      </div>
                                    )}

                                    {step === 5 && <Summary />}
                                  </div>
                                  <hr className="line"></hr>
                                  <div className="ts-stepper-footer">
                                    <div>
                                      <Button
                                        disabled={disapproving_data}
                                        className={`btn  white-btn-ts button-seme-bold-text`}
                                        onClick={() => {
                                          if (
                                            this.props.changesReducer
                                              .dataChanged ||
                                            bite_check_changed ||
                                            teeth_movement_changed ||
                                            ipr_changed ||
                                            treatment_protocol_changed
                                          ) {
                                            this.setState({
                                              showConfirmationUnsavedDataModal2: true,
                                              savedState: "",
                                              active_side_tab: "1",
                                              activeTab: "1",
                                            });
                                          } else {
                                            this.clearStateToLeave2();
                                            this.setState({
                                              showBiteRecord:
                                                !this.state.showBiteRecord,
                                              active_side_tab: "1",
                                              activeTab: "1",
                                            });
                                          }
                                          if (
                                            show_Tooth_Movement &&
                                            !this.props.changesReducer
                                              .dataChanged
                                          ) {
                                            this.cancelChangesOnToothMovement();
                                          }
                                        }}
                                      >
                                        {content.cancel}
                                      </Button>
                                    </div>
                                    <div className="ts-stepper-actions">
                                      <div></div>
                                      <div>
                                        {this.state.step > 1 && (
                                          <Button
                                            disabled={disapproving_data}
                                            className={`btn  white-dark-green-btn float-right button-seme-bold-text`}
                                            onClick={() => {
                                              if (
                                                this.props.changesReducer
                                                  .dataChanged &&
                                                (step === 2 || step === 3)
                                              ) {
                                                this.setState({
                                                  showUnsavedDataModal: true,
                                                  savedStep:
                                                    this.state.step - 1,
                                                });
                                              } else {
                                                this.setState({
                                                  step: this.state.step - 1,
                                                });
                                              }
                                            }}
                                          >
                                            <i className="pr-1 fas fa-angle-left"></i>
                                            {content.back}
                                          </Button>
                                        )}
                                      </div>
                                      <div className="steps-color">
                                        {step} {content.of} {5}
                                      </div>
                                      <div>
                                        {" "}
                                        {this.state.step < 5 && (
                                          <Button
                                            disabled={disapproving_data}
                                            className={`btn  white-dark-green-btn float-left button-seme-bold-text`}
                                            onClick={() => {
                                              if (step === 5) {
                                                this.mapDataToAPI();
                                              } else {
                                                if (
                                                  this.props.changesReducer
                                                    .dataChanged &&
                                                  (step === 2 || step === 3)
                                                ) {
                                                  this.setState({
                                                    showUnsavedDataModal: true,
                                                    savedStep:
                                                      this.state.step + 1,
                                                  });
                                                } else {
                                                  this.setState({
                                                    step: this.state.step + 1,
                                                  });
                                                }
                                              }

                                              this.setVisitedStepFun(step + 1);
                                            }}
                                          >
                                            {content.next}{" "}
                                            <i className="pl-1 fas fa-angle-right"></i>
                                          </Button>
                                        )}
                                      </div>

                                      <div></div>
                                    </div>
                                    <div>
                                      {!bite_check_changed &&
                                      !teeth_movement_changed &&
                                      !ipr_changed &&
                                      !treatment_protocol_changed ? (
                                        <Button
                                          disabled={disapproving_data}
                                          className={`btn  green-green-btn max-btn-width button-seme-bold-text`}
                                          onClick={() => {
                                            this.Approve();
                                          }}
                                        >
                                          {content.submit_without_modifications}{" "}
                                          <i className="pl-1 fas fa-angle-right"></i>
                                        </Button>
                                      ) : (
                                        <>
                                          <Button
                                            disabled={disapproving_data}
                                            className={`btn  green-green-btn button-seme-bold-text`}
                                            onClick={async () => {
                                              if (step === 5) {
                                                this.mapDataToAPI();
                                              } else {
                                                if (
                                                  this.props.changesReducer
                                                    .dataChanged &&
                                                  (step === 3 || step === 4)
                                                ) {
                                                  this.setState({
                                                    showUnsavedDataModal: true,
                                                    savedStep: 5,
                                                  });
                                                } else {
                                                  this.setState({
                                                    step: 5,
                                                  });
                                                }
                                              }

                                              this.setVisitedStepFun(5);
                                            }}
                                          >
                                            {content.submit}{" "}
                                            <i className="pl-1 fas fa-angle-right"></i>
                                          </Button>
                                        </>
                                      )}
                                    </div>{" "}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="submitted_grid">
                                    <div className="case-submitted">
                                      <img src={check} alt="case-submitted" />{" "}
                                      <div>
                                        {content.revised_data_successfully}
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <hr className="line"></hr>
                                  <Button
                                    className={`btn  green-green-btn pull-right exit-button button-seme-bold-text `}
                                    onClick={() => {
                                      this.toggleViewerModal();
                                    }}
                                  >
                                    {content.exit}{" "}
                                  </Button>
                                </div>
                              )}

                              {/* <Stepper onClickCallback={(e) => { this.setState({ step: e + 1 }) }} stepperData={stepperData} /> */}

                              {/* <StepZilla
                              steps={this.steps()}
                              startAtStep={this.state.step}
                              backButtonText=""
                              nextButtonText=""
                              onStepChange={(step) => {
                                this.setState({ step: step });
                              }}
                              currentStep={this.state.step}
                              showSteps={true}

                            // backButtonCls={this.getClassprev()}
                            // nextButtonCls={this.getClassNext()}
                            /> */}

                              {/* <div className="ts-stepper">
                              <Button onClick={() => { this.setState({ step: 1 }) }}>Bite Check</Button>
                              <Button onClick={() => { this.setState({ step: 2 }) }}>Teeth Movement</Button>
                              <Button onClick={() => { this.setState({ step: 3 }) }}>IPR</Button>
                              <Button onClick={() => { this.setState({ step: 4 }) }}>  Treatment Protocols</Button>
                              <Button onClick={() => { this.setState({ step: 5 }) }}>{`Review & Submit`}</Button>
                            </div> */}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                // {/* //mobile viewer// */}
                <>
                  <TSViewer
                    viewerId={this.state.viewerId}
                    viewerHeight={this.state.viewerHeight}
                    details={this.state.details}
                    doctorId={this.props.doctorId}
                    caseId={this.props.case.uid}
                    collapse={this.state.collapse4}
                    case_data={this.state.case}
                    showBiteRecord={this.state.showBiteRecord}
                    saveRejectionReason={this.saveRejectionReason}
                    finishLoadingTs={this.finishLoadingTs}
                    viewer={this.state.viewer}
                  />
                  {this.state.details.can_approve_or_disapprove &&
                    !is_archived && (
                      <div className="approve_and_revise_grid ">
                        <div></div>
                        <Button
                          className={`btn  white-btn-ts  button-seme-bold-text`}
                          size="sm"
                          type="button"
                          onClick={() => {
                            this.openReviseModalMobile();
                          }}
                        >
                          {content.revise}
                        </Button>
                        <Button
                          className={`btn green-green-btn button-seme-bold-text `}
                          size="sm"
                          type="button"
                          onClick={() => {
                            this.Approve();
                          }}
                        >
                          {content.approve}
                        </Button>

                        <div></div>
                      </div>
                    )}
                </>
              )}
            </ModalBody>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.viewer2}
          toggle={this.toggleViewerModal}
          className="modalx model_ts"
        >
          <TreatmentPlan
            viewerId={this.state.viewerId}
            details={this.state.details}
            toggle={this.toggleViewerModal}
            showBiteRecord={this.state.showBiteRecord}
            url={this.state.url}
            mapped_advanced_modules={this.state.mapped_advanced_modules}
            toggleViewerModal={this.toggleViewerModal}
            GetAllData={this.GetAllData}
            ResetTabs={this.ResetTabs}
            toggleApprove={this.Approve}
            toggleRevise={this.ToggleBitsRecord}
            step={step}
            disapproving_data={disapproving_data}
            disapproved_successfully={this.state.disapproved_successfully}
            mapDataToAPI={this.mapDataToAPI}
            setShowUnsavedModal={this.setShowUnsavedModal}
            cancelChangesOnToothMovement={this.cancelChangesOnToothMovement}
            setDynamicState={this.setDynamicState}
            showIPRData={this.state.showIPRData}
            current_ipr_tooth={this.state.current_ipr_tooth}
            current_ipr_position={this.state.current_ipr_position}
            doctorId={this.state.doctorId}
            GetAlignerCase={this.GetAlignerCase}
            GetTreatmentSetups={this.props.GetTreatmentSetups}
            GetTretmentSetupById={this.GetTretmentSetupById}
            ToggleBitsRecord={this.ToggleBitsRecord}
            data={this.state.data}
            showToothMovement={this.state.showToothMovement}
            showIPRToothMovement={this.state.showIPRToothMovement}
            ToggleOptions={this.ToggleOptions}
            finish_loading_ts={this.state.finish_loading_ts}
            savedStep={this.state.savedStep}
            clearStateToLeave2={this.clearStateToLeave2}
            showActions={this.showActions}
            hideActions={this.state.hideActions}
            inboxmsg={this.inboxmsg}
            resetShowActions={this.resetShowActions}
            showInbox={this.state.showinbox}
            showMenu={this.state.showMenu}
            toggleInbox={this.toggleInbox}
            toggleMenu={this.toggleMenu}
            openReviseModalMobile={this.openReviseModalMobile}
          />
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Viewer Modal the new  one  */}
        {/*--------------------------------------------------------------------------------*/}
        {this.state.details && (
          <div className={` cmb2 ${this.state.collapse ? "ts-shadow" : ""}`}>
            <div
              className={`${
                this.state.collapse
                  ? "ts-collapse-open treatment_setup_grid"
                  : "treatment_setup_grid"
              }`}
            >
              {/* front title */}
              <div className="front_title">
                {front_title ? (
                  <span className="button-text">
                    {title ? `${front_title} (${title})` : `${front_title}`}{" "}
                  </span>
                ) : (
                  <div className="spinner_3"></div>
                )}
              </div>
              {/* end front title */}

              {/* state */}
              <div className="status_button">
                <span
                  className="status_button_dot button-text"
                  style={{
                    backgroundColor: ts_status[state]?.color || "#898A8D",
                  }}
                ></span>{" "}
                <span className="footnote-text">
                  {ts_status[state]?.lable || state}
                </span>
                <Button
                  // className={` btn  green-green-btn view-ts-btn ${

                  //   this.state.collapse
                  //     ? "green-green-btn view-ts-btn"
                  //     : state === "Active"
                  //     ? "white-gray-btn view-ts-btn"
                  //     : "dark-gray-btn view-ts-btn"
                  // }`}
                  className="btn-mobile-View-ts btn green-green-btn footnote-text button-seme-bold-text"
                  onClick={() => {
                    this.SaveDataInReducer();
                    this.setState({
                      viewer: true,
                    });
                  }}
                  size="sm"
                  type="button"
                >
                  {content.View_TS}
                  {/* {window.innerWidth > 839 &&
                  <i className="pl-1 fas fa-angle-right"></i>
  } */}
                </Button>
              </div>

              {/* end state */}

              {/* view treatment setup button */}
              <Button
                className="ts_btn btn green-green-btn button-seme-bold-text"
                onClick={() => {
                  this.SaveDataInReducer();
                  this.setState({
                    viewer: true,
                  });
                }}
                disabled={!front_title}
                size="sm"
                type="button"
              >
                {content.view_treatment_setup}{" "}
                {/* <i className="pl-1 fas fa-angle-right"></i>{" "} */}
              </Button>
              {/* end view treatment setup button */}

              <div className="share_link_toggle">
                {/* share link button */}
                <Button
                  className={`share_btn btn button-seme-bold-text  ${
                    this.state.collapse
                      ? "gray-green-btn"
                      : this.state.showLinkModal
                      ? " green-green-btn "
                      : state === "Active"
                      ? "gray-green-btn"
                      : "gray-green-btn"
                  } button-seme-bold-text`}
                  onClick={() => this.toggleShowLinkModal()}
                  size="sm"
                  type="button"
                >
                  {content.share} <i className="pl-1 fas fa-angle-right"></i>{" "}
                </Button>
                {/* end share link button */}

                {this.state.showLinkModal && this.state.url && (
                  <div className="share_link_content">
                    <img
                      src={share_link_mobile}
                      alt="share_link"
                      className="share_img_link"
                    />

                    <div className="share_img_content">
                      <div>
                        <div className="share_link_content_grid">
                          <div className="btn-group copy-link-group">
                            <Input
                              bsSize="large"
                              autoComplete="off"
                              type="text"
                              className="custome-input-text copy-link-text"
                              defaultValue={this.state.url}
                              disabled
                            />
                            <span id="copy_link">
                              <img src={copy_link} alt="copy-link-icon" />
                            </span>
                          </div>
                          <Button
                            className=" btn green-green-btn button-seme-bold-text"
                            onClick={() => {
                              navigator.clipboard.writeText(this.state.url);
                              this.toggleShowLinkModal();
                              infoToaster(
                                content.link_is_copied,
                                content.Attention_needed
                              );
                            }}
                          >
                            {content.copy_link}
                          </Button>
                        </div>

                        <div className="green-title cmt1">
                          {" "}
                          {content.link_valid}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          this.toggleShowLinkModal();
                        }}
                      >
                        {" "}
                        <i className="fas fa-times c-pointer "></i>{" "}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* collapse button */}
              <span
                className={`collapse_btn ${front_title ? "" : "disabled-btn"} `}
                onClick={this.toggle}
              >
                {this.state.collapse ? (
                  <i className="fas fa-angle-up icon-md" />
                ) : (
                  <i className="fas fa-angle-down icon-md" />
                )}
              </span>

              {/* end collapse button */}
            </div>
            <div className="treatment-setups">
              <Collapse isOpen={this.state.collapse}>
                <div className="ts_collapse_grid">
                  <div className="ts-view-share-grid">
                    {/* view treatment setup button */}
                    {/* <Button
                      className={`ts_btn btn ${
                        this.state.collapse
                          ? "green-green-btn view-ts-btn"
                          : state === "Active"
                          ? "white-gray-btn view-ts-btn"
                          : "dark-gray-btn view-ts-btn"
                      }`}
                      onClick={() => {
                        this.SaveDataInReducer();
                        this.setState({
                          viewer: true,
                        });
                      }}
                      size="sm"
                      type="button"
                    >
                      {content.View_TS}{" "}
                      <i className="pl-1 fas fa-angle-right"></i>{" "}
                    </Button> */}
                    <div className="case-info-details-grid">
                      <div className="case-info-details">
                        <div className="case-info-details-info button-text">
                          {content.title}
                          {` : `}
                          {this.state.details.front_title}
                        </div>

                        <div className="case-info-details-info button-text">
                          {content.start_together}
                          {` : `}
                          {this.state.details.start_together + ""}
                        </div>
                      </div>

                      {/* end view treatment setup button */}
                      <div className="share_link_toggle case-info-details-grid-button">
                        {/* share link button */}
                        <Button
                          className={`share_btn btn button-seme-bold-text fixed_width ${
                            // this.state.collapse
                            //   ? "gray-green-btn":

                            this.state.showLinkModal === true
                              ? " green-green-btn "
                              : state === "Active"
                              ? "white-green-btn"
                              : "white-green-btn"
                          } body-bold-text`}
                          onClick={() => this.toggleShowLinkModal()}
                          size="sm"
                          type="button"
                        >
                          {content.share}{" "}
                          <i className="pl-1 fas fa-angle-right"></i>{" "}
                        </Button>
                        {/* end share link button */}

                        {this.state.showLinkModal && this.state.url && (
                          <div className="share_link_content">
                            <img
                              src={share_link_mobile}
                              alt="share_link"
                              className="share_img_link"
                            />

                            <div className="share_img_content">
                              <div>
                                <div className="share_link_content_grid">
                                  <div className="btn-group copy-link-group">
                                    <Input
                                      bsSize="large"
                                      autoComplete="off"
                                      type="text"
                                      className="custome-input-text copy-link-text footnote-text-input"
                                      defaultValue={this.state.url}
                                      disabled
                                    />
                                    <span id="copy_link">
                                      <img
                                        src={copy_link}
                                        alt="copy-link-icon"
                                      />
                                    </span>
                                  </div>
                                  <Button
                                    className=" btn green-green-btn button-bold-text"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        this.state.url
                                      );
                                      this.toggleShowLinkModal();
                                      infoToaster(
                                        content.link_is_copied,
                                        content.Attention_needed
                                      );
                                    }}
                                  >
                                    {content.copy_link}
                                  </Button>
                                </div>

                                <div className="green-title cmt1 body-text">
                                  {" "}
                                  {content.link_valid}
                                </div>
                                <Button
                                  className=" btn green-green-btn button-seme-bold-text"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.url
                                    );
                                    this.toggleShowLinkModal();
                                    infoToaster(
                                      content.link_is_copied,
                                      content.Attention_needed
                                    );
                                  }}
                                >
                                  {content.copy_link}
                                </Button>
                              </div>
                              <div
                                onClick={() => {
                                  this.toggleShowLinkModal();
                                }}
                              >
                                {" "}
                                <i className="fas fa-times c-pointer "></i>{" "}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="case-info-details-desktop">
                      <div className="case-info-details">
                        <div className="green-title record-color body-text">
                          {content.title}
                        </div>
                        <div>{this.state.details.front_title}</div>
                      </div>
                      <div className="case-info-details">
                        <div className="green-title record-color body-text">
                          {content.start_together}
                        </div>
                        <div>{this.state.details.start_together + ""}</div>
                      </div>
                    </div>
                    {/* ipr & sps buttons  */}

                    <div className="btn_grid new-btn-grid cmt1">
                      {!this.checkIfMatch(this.state.details.ipr) ? (
                        <a
                          href={this.state.details.ipr}
                          target="blank"
                          download={`${content.ipr_indicator}_${uid}.pdf`}
                          disabled={this.checkIfMatch(
                            this.state?.details?.ipr || ""
                          )}
                        >
                          <Button
                            className="btn green-green-btn button-seme-bold-text"
                            size="sm"
                            disabled={this.checkIfMatch(
                              this.state?.details?.ipr || ""
                            )}
                          >
                            {content.ipr_file}
                          </Button>
                        </a>
                      ) : used_ipr ? (
                        <>
                          <DownloadIPRPdf
                            content={content}
                            data={ipr_data}
                            doctor={doctor}
                            patient={patient}
                            case_data={{ uid: uid }}
                            notes={iprNotes}
                            uid={uid}
                          />
                        </>
                      ) : (
                        // <PDFDownloadLink
                        //   document={
                        //     <MyDocument
                        //       content={content}
                        //       data={ipr_data}
                        //       doctor={doctor}
                        //       patient={patient}
                        //       case_data={{ uid: uid }}
                        //       notes={iprNotes}
                        //     />
                        //   }
                        //   fileName={`${content.ipr_indicator}_${uid}.pdf`}
                        // >
                        //   {({ blob, url, loading, error }) => {
                        //     return (
                        //       <Button
                        //         className="btn green-green-btn button-bold-text"
                        //         size="sm"
                        //         disabled={loading}
                        //       >
                        //         {content.ipr_file}
                        //       </Button>
                        //     );
                        //   }}
                        // </PDFDownloadLink>

                        <Button
                          className="btn green-green-btn button-bold-text"
                          size="sm"
                          disabled={true}
                        >
                          {content.ipr_file}
                        </Button>
                      )}
                      <a
                        href={this.state.details.sps}
                        target="blank"
                        download={`${uid} sps`}
                        disabled={this.checkIfMatch(
                          this.state?.details?.sps || ""
                        )}
                      >
                        <Button
                          className="btn green-green-btn button-seme-bold-text"
                          size="sm"
                          disabled={this.checkIfMatch(
                            this.state?.details?.sps || ""
                          )}
                        >
                          {content.sps_file}
                        </Button>
                      </a>
                    </div>
                    {/*end ipr & sps buttons  */}

                    {/* show Revised Data button  */}
                    {revised_data && Object.keys(revised_data).length !== 0 && (
                      <div className="cmt1 revised-btn">
                        <Button
                          className="btn green-green-btn cpx3 button-seme-bold-text"
                          size="sm"
                          onClick={() => {
                            this.setState({
                              showRevisedData: !this.state.showRevisedData,
                            });
                          }}
                        >
                          {content.show_revised_data}
                        </Button>
                      </div>
                    )}
                    {/* end show Revised Data button  */}
                  </div>
                  <div>
                    {this.state.details?.technician_notes && (
                      <div className="cmb2">
                        <div className="green-title record-color body-text">
                          {content.technician_notes}
                        </div>
                        <div className="technician_notes_padding ">
                          <Accordion
                            text={this.state.details?.technician_notes || ""}
                            limit={50}
                          />
                        </div>
                      </div>
                    )}
                    {this.state.details.ts_rejection_reasons && (
                      <div>
                        <div className="green-titl record-colore body-text">
                          {content.rejection_reasons}
                        </div>
                        <Accordion
                          text={this.state.details.ts_rejection_reasons}
                          limit={50}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    {/* ts upper steps */}
                    {this.state.details.upper_steps > 0 &&
                      this.state.details.upper_steps !== null && (
                        <div>
                          <div className="ts-info-details body-text">
                            <div>{content.upper_steps}</div>
                            <div className="green-title body-text center_num">
                              {this.state.details.upper_steps}
                            </div>
                          </div>
                        </div>
                      )}
                    {/* ts lower steps */}
                    {this.state.details.lower_steps > 0 &&
                      this.state.details.lower_steps !== null && (
                        <div>
                          <hr className="hr_remove_padding" />
                          <div className="ts-info-details body-text ">
                            <div>{content.lower_steps}</div>
                            <div className="green-title body-text center_num">
                              {this.state.details.lower_steps}
                            </div>
                          </div>
                        </div>
                      )}
                    {/* ts upper overcorrection steps */}
                    {this.state.details.upper_overcorrection_steps > 0 &&
                      this.state.details.upper_overcorrection_steps !==
                        null && (
                        <div>
                          <hr className="hr_remove_padding" />
                          <div className="ts-info-details body-text">
                            <div>{content.upper_over_correction_steps}</div>
                            <div className="green-title body-text center_num">
                              {this.state.details.upper_overcorrection_steps}
                            </div>
                          </div>
                        </div>
                      )}
                    {/* ts lower overcorrection steps */}
                    {this.state.details.lower_overcorrection_steps > 0 &&
                      this.state.details.lower_overcorrection_steps !==
                        null && (
                        <div>
                          <hr className="hr_remove_padding" />
                          <div className="ts-info-details body-text">
                            <div>{content.lower_over_correction_steps}</div>
                            <div className="green-title body-text center_num">
                              {this.state.details.lower_overcorrection_steps}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        )}{" "}
        {/* *************** */}
        {this.state.viewer && (
          <TreatmentPlan
            viewerId={this.state.viewerId}
            details={this.state.details}
            toggle={this.toggleViewerModal}
            showBiteRecord={this.state.showBiteRecord}
            url={this.state.url}
            mapped_advanced_modules={this.state.mapped_advanced_modules}
            toggleViewerModal={this.toggleViewerModal}
            GetAllData={this.GetAllData}
            ResetTabs={this.ResetTabs}
            toggleApprove={this.Approve}
            toggleRevise={this.ToggleBitsRecord}
            step={step}
            disapproving_data={disapproving_data}
            disapproved_successfully={this.state.disapproved_successfully}
            mapDataToAPI={this.mapDataToAPI}
            setShowUnsavedModal={this.setShowUnsavedModal}
            cancelChangesOnToothMovement={this.cancelChangesOnToothMovement}
            setDynamicState={this.setDynamicState}
            showIPRData={this.state.showIPRData}
            current_ipr_tooth={this.state.current_ipr_tooth}
            current_ipr_position={this.state.current_ipr_position}
            doctorId={this.state.doctorId}
            GetAlignerCase={this.GetAlignerCase}
            GetTreatmentSetups={this.props.GetTreatmentSetups}
            GetTretmentSetupById={this.GetTretmentSetupById}
            ToggleBitsRecord={this.ToggleBitsRecord}
            data={this.state.data}
            showToothMovement={this.state.showToothMovement}
            showIPRToothMovement={this.state.showIPRToothMovement}
            ToggleOptions={this.ToggleOptions}
            finish_loading_ts={this.state.finish_loading_ts}
            savedStep={this.state.savedStep}
            clearStateToLeave2={this.clearStateToLeave2}
            showActions={this.showActions}
            hideActions={this.state.hideActions}
            inboxmsg={this.inboxmsg}
            resetShowActions={this.resetShowActions}
            showInbox={this.state.showinbox}
            showMenu={this.state.showMenu}
            toggleInbox={this.toggleInbox}
            toggleMenu={this.toggleMenu}
            openReviseModalMobile={this.openReviseModalMobile}
          />
        )}
      </div>
    );
  }
}

export const Accordion = ({ text, limit = 25 }) => {
  const isExpandable = text.length > limit;
  const [viewText, setViewText] = useState(
    isExpandable ? text.slice(0, limit) : text
  );
  const isExpanded = text.length === viewText.length;
  return (
    <span>
      <span className="body-text">{`  ${viewText}`}</span>
      {isExpandable && (
        <span
          className="see_more_less body-text"
          onClick={() => setViewText(isExpanded ? text.slice(0, limit) : text)}
        >
          {isExpanded ? "See less" : ` ...read more`}
        </span>
      )}
    </span>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(TreatmentSetup);
