import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./layout-components/header/header";
import Sidebar from "./layout-components/sidebar/sidebar";
// import Footer from './layout-components/footer/footer';
// import Customizer from './layout-components/customizer/customizer';
import yallow_info_icon from "../assets/images/dashboard/yallow_info_icon.svg";
import ThemeRoutes from "../routes/router";
import { content } from "../redux/language/content";
import { setUserInfo } from "../redux/user/action";
import { UpdateContent, UpdateLanguage } from "../redux/language/action";
import { getDoctorInfo } from "../api/api";
import { Userpilot } from "userpilot";
import InfoBip from "../views/home/InfoBip";

const mapStateToProps = (state) => ({
  ...state,
});

class Fulllayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      width: window.innerWidth,
      finishLoading: false,
      recallInfoBip: false,
    };

    this.props.history.listen((location, action) => {
      if (
        window.innerWidth < 767 &&
        document
          .getElementById("main-wrapper")
          .className.indexOf("show-sidebar") !== -1
      ) {
        document
          .getElementById("main-wrapper")
          .classList.toggle("show-sidebar");
      }
    });
  }
  /* --------------------------------------------------------------------------------*/
  /*  update doctor data or initial data  */
  /* --------------------------------------------------------------------------------*/

  GetDoctorInfo = () => {
    const { id } = this.props.userReducer.currentUser;

    this.setState({ finishLoading: false });

    getDoctorInfo(id).then((res) => {
      const currentUser = {
        avatar: res.avatar,
        email: res.email,
        first_name: res.first_name,
        full_name: res.full_name,
        id: res.id,
        last_name: res.last_name,

        phone: res.phone,
        gco_doctor: res.gco_doctor,
        language: res.preferred_language,
        date_format: res.preferred_date_format,
        dental_notation: res.preferred_dental_notation,
      };

      const user = {
        ...res,
        language: res.preferred_language || "en",
        date_format: res.preferred_date_format || "DD/MM/YYYY",
        dental_notation: res.preferred_dental_notation || "fdi",
      };

      if (currentUser) {
        Userpilot.identify(id, {
          name: res.full_name,
          email: res.email,
          User: res.full_name,
          language: res.preferred_language,
        });
      }

      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      localStorage.setItem("language", res.preferred_language || "en");
      this.props.setUserInfo(user);

      this.props.UpdateLanguage(res.preferred_language || "en");
      this.props.UpdateContent(res.preferred_language || "en");
      this.setState({ finishLoading: true });
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook, Applies when loading or resizing App                           */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
    this.GetDoctorInfo();
  }
  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions = () => {
    let element = document.getElementById("main-wrapper");
    this.setState({
      width: window.innerWidth,
    });
    switch (this.props.settings.activeSidebarType) {
      case "full":
      case "iconbar":
        if (this.state.width < 1170) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
          element.classList.add("mini-sidebar");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
          element.classList.remove("mini-sidebar");
        }
        break;

      case "overlay":
        if (this.state.width < 767) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
        }
        break;

      default:
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook                                                                 */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Route has changed, show an alert or perform any desired action
      this.setState({ recallInfoBip: true });
    }
  }
  render() {
    const { block_case_submission } = this.props.userReducer.currentUser;
    const { finishLoading, recallInfoBip } = this.state;
    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    return (
      <div
        id="main-wrapper"
        dir={this.props.settings.activeDir}
        data-theme={this.props.settings.activeTheme}
        data-layout={this.props.settings.activeThemeLayout}
        data-sidebartype={this.props.settings.activeSidebarType}
        data-sidebar-position={this.props.settings.activeSidebarPos}
        data-header-position={this.props.settings.activeHeaderPos}
        data-boxed-layout={this.props.settings.activeLayout}
      >
        {(recallInfoBip || window.location.pathname === "/home") && <InfoBip />}

        {/*--------------------------------------------------------------------------------*/}
        {/* Header                                                                         */}
        {/*--------------------------------------------------------------------------------*/}
        <Header data={this.props} />
        {block_case_submission &&
          finishLoading &&
          window.location.pathname === "/home" && (
            <div className="displayNone_inmobile">
              <div className="grid-note-block-mobile">
                <div className="grid-note-block-mobile-inner">
                  <div>
                    {" "}
                    <img src={yallow_info_icon} alt="yallow_info_icon" />{" "}
                  </div>
                  <div className="bolcked-title-mobile">
                    {content.en.your_account_is_blocked_mobile}
                  </div>
                </div>
                <div className="bolcked-body-mobile">
                  {content.en.you_cant_submit_new_cases_please_contact}
                  <samp className="bolcked-body-mobile-email">
                    {" "}
                    {content.en.collections_eonaligner_com}{" "}
                  </samp>{" "}
                  {
                    content.en
                      .your_designated_relationship_manager_to_unblock_your_account
                  }
                </div>
              </div>
            </div>
          )}

        {/*--------------------------------------------------------------------------------*/}
        {/* Sidebar                                                                        */}
        {/*--------------------------------------------------------------------------------*/}
        <Sidebar {...this.props} routes={ThemeRoutes} />
        {/*--------------------------------------------------------------------------------*/}
        {/* Page Main-Content                                                              */}
        {/*--------------------------------------------------------------------------------*/}
        {finishLoading && (
          <div
            className={`${
              block_case_submission && window.location.pathname === "/home"
                ? "blocked-container"
                : ""
            }`}
          >
            <div
              className={
                window.location.pathname === "/case-submission" ||
                window.location.pathname === "/retainer" ||
                window.location.pathname === "/case-submission/"
                  ? "page-wrapper d-block case-submission-wrapper"
                  : "page-wrapper d-block"
              }
            >
              <div
                className={
                  window.location.pathname === "/case-submission" ||
                  window.location.pathname === "/retainer" ||
                  window.location.pathname === "/case-submission/"
                    ? "p-0 page-content container-fluid "
                    : "page-content container-fluid"
                }
              >
                <Switch>
                  {ThemeRoutes.map((prop, key) => {
                    if (prop.navlabel) {
                      return null;
                    } else if (prop.collapse) {
                      return prop.child.map((prop2, key2) => {
                        if (prop2.collapse) {
                          return prop2.subchild.map((prop3, key3) => {
                            return (
                              <Route
                                path={prop3.path}
                                component={prop3.component}
                                key={key3}
                              />
                            );
                          });
                        }
                        return (
                          <Route
                            path={prop2.path}
                            component={prop2.component}
                            key={key2}
                          />
                        );
                      });
                    } else if (prop.redirect) {
                      return (
                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                      );
                    } else {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                        />
                      );
                    }
                  })}
                </Switch>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
        )}
        {/*--------------------------------------------------------------------------------*/}
        {/* Customizer from which you can set all the Layout Settings                      */}
        {/*--------------------------------------------------------------------------------*/}
        {/* <Customizer

					boxedTheme={this.boxedTheme}
					rtl={this.rtl}
					headerPosition={this.headerPosition}
					sidebarPosition={this.sidebarPosition}

				/> */}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setUserInfo: (payload) => dispatch(setUserInfo(payload)),
  UpdateLanguage: (payload) => dispatch(UpdateLanguage(payload)),
  UpdateContent: (payload) => dispatch(UpdateContent(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Fulllayout);
