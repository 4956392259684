import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import { CustomInput } from 'reactstrap';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Elastics extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_elastics: this.props.caseSubmission.advanced_modules.elastics
        .useElastics,
      buttons:
        this.props.caseSubmission.advanced_modules.elastics.type === "buttons",
      cuts: this.props.caseSubmission.advanced_modules.elastics.type === "cuts",
      type: this.props.caseSubmission.advanced_modules.elastics.type,
      details: this.props.caseSubmission.advanced_modules.elastics.details,
      use_buttons: this.props.caseSubmission.advanced_modules.elastics.use_buttons,
      use_cuts: this.props.caseSubmission.advanced_modules.elastics.use_cuts,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "elastics",
      data: {
        useElastics: this.state.use_elastics,
        teeth: this.props.customeTeethReducer.Case_Elastics,
        buttons_teeth: this.props.customeTeethReducer.Case_Elastics_Buttons,
        cuts_teeth: this.props.customeTeethReducer.Case_Elastics_Cuts,
        details: this.state.details,
        type: this.state.type,
        use_buttons: this.state.use_buttons,
        use_cuts: this.state.use_cuts,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }


  render() {
    const { use_elastics, buttons, cuts, use_buttons, use_cuts , details } = this.state;
    const { content } = this.props.languageReducer

    return (
      <div>
        <div className="templete_module_grid">

          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.Use_elastics_for_this_case}{"? "}

            </div>

            <TreatmentOptions content={content} value={use_elastics} name="use_elastics" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_elastics")
              if (value !== true) {
                this.setState({
                  type: "",
                  details: "",
                  use_buttons: false,
                  use_cuts: false
                })
                this.props.Clear_Teeth_Data("Case_Elastics")
                this.props.Clear_Teeth_Data("Case_Elastics_Buttons")
                this.props.Clear_Teeth_Data("Case_Elastics_Cuts")
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>

          <div className="divider_module-middle"></div>
          <div id="content_container_3"
            className={`${use_elastics !== true ? "c-content-container7" : "c-content-container "} tooth-extraction  cpb2 `}>
            <div>
              <CustomInput
                id="checkboxButtons"
                className={(use_buttons) ? 'replacment-checkbox text_malocclusions button-text-select' : 'text_malocclusions button-text-select'}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}
                type="checkbox"
                checked={use_buttons}
                disabled={ this.props.summary}
                 label={content.buttons}
           
                onChange={() => {
                  this.setState({
                    buttons: !buttons,
                    type: "buttons",
                    use_buttons: !use_buttons
                  });

                  buttons &&
                    this.props.Clear_Teeth_Data("Case_Elastics_Buttons")
                }}
              />
              
              <div>
              
                <div className="teeth_selector ">
                  <CustomeTeeth action_key="Case_Elastics_Buttons" disabled={!use_elastics || use_elastics === "eonToDecide" || !use_buttons}
                    summary={this.props.summary} />
                </div>
              </div>
              <CustomInput
                id="checkboxCuts"
                className={(use_cuts) ? 'replacment-checkbox text_malocclusions cut-padding button-text-select' : 'text_malocclusions cut-padding button-text-select'}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}
                disabled={ this.props.summary}
                type="checkbox"
                checked={use_cuts}
            
                label={content.cuts}
                onChange={() => {
                  this.setState({
                    cuts: !cuts,
                    type: "cuts",
                    use_cuts: !use_cuts
                  });
                  cuts && this.props.Clear_Teeth_Data("Case_Elastics_Cuts")

                }}
              />
              <div>
                <div className="control-label title_active-gco text-left light-green pb-2 mt-2 button-text">
                  {content.select_the_teeth_requiring_precision_cuts_placement}
                </div><div className="teeth_selector">
                  <CustomeTeeth action_key="Case_Elastics_Cuts" disabled={!use_elastics || use_elastics === "eonToDecide" || !use_cuts}
                    cuts={true}
                    summary={this.props.summary} />
                </div>
              </div>
              <p className="control-label text-left  light-green ">{content.additional_comments}</p>
              <div className="backgrountTextareaContainer">
              <Input
                className=" border-radius backgrountTextarea  custome-input-text"
                name="elastics_details"
                id="elastics_details"
                type="textarea"
                placeholder={content.typeHere}
                rows={5}
                value={details}
                onChange={(e) => {
                  this.setState({ details: e.target.value });
                }}
                disabled={!use_elastics || use_elastics === "eonToDecide" || this.props.summary}
              />{" "}
              </div>
            </div>
            <div>
            </div>
            <div className={`${(this.state.use_elastics === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_elastics === "eonToDecide") ? `Eon_to_recommend_eon Eon_to_recommend_layout ` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>
              <p className={`${(this.state.use_elastics === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none  ` : ''} title-2-text`}>{content.Elastics_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.use_elastics === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none text_malocclusions` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
            </div>
            {
          !this.props.summary &&   this.state.use_elastics ===true && window.innerWidth < 839 &&  <div className="btn-adv-mobile margin-top3 "><Button
            className=" mt-2   mobile-but-adv light-green-btn float-right   button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
          </div>
        </div>

        {
          !this.props.summary && !(this.state.use_elastics ===true && window.innerWidth <839  ) 
           &&  <div className="btn-adv-mobile "><Button
            className=" mt-2   mobile-but-adv light-green-btn float-right   button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Elastics);
