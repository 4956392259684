export const TotalArray = {
    Rotation_fifty: [11, 12, 13, 14, 15, 21, 22, 23, 24, 25, 31, 32, 33, 34, 35, 41, 42, 43, 44, 45],
    Rotation_thirty: [16, 17, 18, 26, 27, 28, 36, 37, 38, 46, 47, 48],
    Torque_fifteen: [11, 12, 13, 21, 22, 23, 31, 32, 33, 41, 42, 43],
    Torque_ten: [14, 15, 16, 17, 24, 25, 26, 27, 34, 35, 36, 37, 44, 45, 46, 47],
    Extrusion_rand: [14, 15, 16, 17, 18, 24, 25, 26, 27, 28, 34, 35, 36, 37, 38, 44, 45, 46, 47, 48],
    Extrusion_tow: [11, 12, 13, 21, 22, 23, 31, 32, 33, 41, 42, 43],
}


export const TotalvalueToComp = {
    Rotation_fifty: [50, -50],
    Rotation_thirty: [30, -30],
    Angulation_twinty: [20, -20],
    Torque_fifteen: [15, -15],
    Torque_ten: [10, -10],
    Mesial_four: [4, -4],
    Extrusion_rand: [1, -0.5],
    Extrusion_tow: [2, -2],
    in_three: [3, -3],
}

//// Checked (over &under ) value  ////
export const Checked = (ToothNumber, array, Rotation, value) => {
    let newRotation = Number(Rotation.split(" ")[0]);
    for (var i = 0; i < array.length; i++) {
        if (Number(ToothNumber) === array[i]) {
            if (newRotation > value[0] || newRotation < value[1])
                return true;
        }
    }
    return false;
};
///// AllValueteeth	 (over &under ) all teeth  /////	
export const Allteeth = (ToothNumber, value, AllValueteeth) => {
    let newAllValueteeth = Number(AllValueteeth.split(" ")[0])
    if (newAllValueteeth > value[0] || newAllValueteeth < value[1]) {
        return true;
    }
    return false;
}