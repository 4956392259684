import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input } from "reactstrap";
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class ToothSizeDiscrepancy extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */

    this.state = {
      use_address_discrepancy: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.use_address_discrepancy,
      leaveSpaceAroundLaterals: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.leaveSpaceAroundLaterals,
      leaveSpaceDistalLaterals: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.leaveSpaceDistalLaterals,
      iprOpposing: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.iprOpposing,
      other: this.props.caseSubmission.advanced_modules.toothSizeDiscrepancy
        .other,
      other_details: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.other_details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "toothSizeDiscrepancy",
      data: {
        use_address_discrepancy: this.state.use_address_discrepancy,
        leaveSpaceAroundLaterals: this.state.leaveSpaceAroundLaterals,
        leaveSpaceDistalLaterals: this.state.leaveSpaceDistalLaterals,
        iprOpposing: this.state.iprOpposing,
        other: this.state.other,
        other_details: this.state.other_details,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/

  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  clearState = () => {
    this.setState({
      leaveSpaceAroundLaterals: false,
      leaveSpaceDistalLaterals: false,
      iprOpposing: false,
      other: false,
      other_details: ""
    })
  }
  render() {
    const {
      use_address_discrepancy,
      other,
      other_details,
    } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>
        <div className="templete_module_grid">
          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.address_discrepancy_for_this_case}{"? "}
            </div>
            <TreatmentOptions content={content} value={use_address_discrepancy} name="use_address_discrepancy" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_address_discrepancy")
              if (value !== true) {
                this.clearState()
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>

          <div className="divider_module-middle"></div>
          <div id="content_container_3"
            className={`${use_address_discrepancy !== true ? "c-content-container8" : "c-content-container4 "} tooth-extraction  cpb2 `}>
            <div className="address_discrepancy spaces_padding_buttom">
              <div className="spaces_pickup_inner">
              <div className="radio-item "
                     style={{ pointerEvents: this.props.summary ? "none" : "" }} >
                <input type="radio"
                  id='Leave_spaces_around_the_laterals'
                  name='ToothSizeDiscrepancy'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  disabled={ this.props.summary}
                  checked={this.state.leaveSpaceAroundLaterals}
                  value={"Leave_spaces_around_the_laterals"}
                  onChange={() => {
                    this.setState({
                      leaveSpaceAroundLaterals: true,
                      leaveSpaceDistalLaterals: false,
                      iprOpposing: false,
                      other: false,
                    });
                  }}
                />
                <label htmlFor='Leave_spaces_around_the_laterals' className="button-text">{content.leave_spaces_around_the_lateral}</label>
              </div>
              </div>
              <div className="spaces_pickup_inner">
              <div className="radio-item " 
                     style={{ pointerEvents: this.props.summary ? "none" : "" }}>
                <input type="radio"
                  id='Leave_space_distal_to_lateral'
                  name='ToothSizeDiscrepancy'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  disabled={ this.props.summary}
                  value={"Leave_space_distal_to_lateral"}
                  checked={this.state.leaveSpaceDistalLaterals}
                  onChange={() => {
                    this.setState({
                      leaveSpaceAroundLaterals: false,
                      leaveSpaceDistalLaterals: true,
                      iprOpposing: false,
                      other: false,
                    });
                  }}
                />
                <label htmlFor='Leave_space_distal_to_lateral' className="button-text">{content.leave_space_distal_to_lateral}</label>
              </div>
              </div>
              <div className="spaces_pickup_inner">
              <div className="radio-item "
                     style={{ pointerEvents: this.props.summary ? "none" : "" }} >
                <input type="radio"
                  id='IPR_opposing'
                  name='ToothSizeDiscrepancy'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  disabled={ this.props.summary}
                  value={"IPR_opposing"}
                  checked={this.state.iprOpposing}
                  onChange={() => {
                    this.setState({
                      leaveSpaceAroundLaterals: false,
                      leaveSpaceDistalLaterals: false,
                      iprOpposing: true,
                      other: false,
                    });
                  }}
                />
                <label htmlFor='IPR_opposing' className="button-text">{content.apply_ipr_in_the_opposing_arch}</label>
              </div>
              </div>
              <div className="spaces_pickup_inner ">
              <div className="radio-item " 
                     style={{ pointerEvents: this.props.summary ? "none" : "" }}>
                <input type="radio"
                  id='Other'
                  name='ToothSizeDiscrepancy'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  disabled={ this.props.summary}
                  checked={this.state.other}
                  value={"Other"}
                  onChange={() => {
                    this.setState({
                      leaveSpaceAroundLaterals: false,
                      leaveSpaceDistalLaterals: false,
                      iprOpposing: false,
                      other: true,
                    });
                  }}
                />
                <label htmlFor='Other' className="button-text">{content.other}</label>
              </div>
            </div>
            </div>

            {other && (
              <div>
                <br />
                <p className="control-label text-left light-green title-3-text ">{content.additional_comments}</p>
                <Input
                  className="teeth_selector border-radius text_malocclusions backgrountTextarea  custome-input-text"
                  name="other_details"
                  id="other_details"
                  type="textarea"
                  placeholder={content.typeHere}
                  rows={5}
                  value={other_details}
                  onChange={(e) => {
                    this.setState({
                      other_details: e.target.value,
                    });
                  }}
                  disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide" || this.props.summary}

                />
              </div>
            )}
            <div className={`${(this.state.use_address_discrepancy === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_address_discrepancy === "eonToDecide") ? `Eon_to_recommend_eon Eon_to_recommend_layout` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>
              <p className={`${(this.state.use_address_discrepancy === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Tooth_size_discrepancy_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.use_address_discrepancy === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
            </div>
            {
          !this.props.summary && this.state.use_address_discrepancy ===true  &&<div className="btn-adv-mobile margin-top2 displayNone_inmobile"><Button
            className=" mt-4 spaces_pickup_inner_duable  mobile-but-adv light-green-btn float-right   button-seme-bold-text" 
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
          </div>
        </div>
        {
          !this.props.summary  &&!(this.state.use_address_discrepancy ===true && window.innerWidth <839  ) && <div className="btn-adv-mobile"><Button
            className=" mt-2   mobile-but-adv light-green-btn float-right   button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToothSizeDiscrepancy);
