import { UPDATE_PHOTOS_XRAYS_BITE, UPDATE_IMPRESSIONS_BITE, RESET_BITE } from "../constants/";
const INIT_STATE = {
  xrays: {
    panoramic_xray: "",
    cephalometric_xray: "",
  },
  photos: {
    front_smiling: "",
    front_pose: "",
    profile: "",
    upper_occlusal: "",
    lower_occlusal: "",
    left_buccal: "",
    frontal: "",
    right_buccal: "",

  },
  impressions: {
    impressions_method: "",
    impressions_third_party_note: "",
    upper_arch_url: "",
    lower_arch_url: "",
  },
  update_photos_xrays: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PHOTOS_XRAYS_BITE:
      return {
        ...state,
        photos: action.payload.photos,
        xrays: action.payload.xrays,
        update_photos_xrays: true
      };
    case UPDATE_IMPRESSIONS_BITE:
      return {
        ...state,
        impressions: action.payload
      };
    case RESET_BITE:
      return INIT_STATE;
    default:
      return state;
  }
};
