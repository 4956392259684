export function mapIPRTeethOptionsToApi(new_data, old_data, clinical_protocol_feedback, rejection_reason) {
    let teeth_movement = mapTeethData(new_data, old_data);
    let ipr = mapIPRData(new_data, old_data);
    let Bite_Record = {
        "rejection_reason": rejection_reason || ""
    }
    let Clinical_protocol = {
        "Clinical_protocol_feedback": clinical_protocol_feedback || ""
    }
    return {
        teeth_movement: teeth_movement,
        ipr: ipr,
        Bite_Record: Bite_Record,
        Clinical_protocol: Clinical_protocol

    }
}

/* --------------------------------------------------------------------------------*/
/* map IPR Data data */
/* --------------------------------------------------------------------------------*/
function mapIPRData(new_data, old_data) {
  
    let teeth = {
        upper_teeth: mappUpperIPRTeeth(new_data, old_data),
        lower_teeth: mapLowerIPRTeeth(new_data, old_data)
    };
    return teeth;
}
/* --------------------------------------------------------------------------------*/
/* mapp Upper IPR Teeth */
/* --------------------------------------------------------------------------------*/
function mappUpperIPRTeeth(new_data, old_data) {
    let Teeth = {};

    for (var key in new_data.upper_teeth) {
        if (new_data.upper_teeth[key].tooth_key) {
            Teeth = {
                ...Teeth,
                [new_data.upper_teeth[key].tooth_key]: {
                    current: {
                        IPR: old_data.upper_teeth[key].use_ipr,
                        IPR_Steps_Amounts: old_data.upper_teeth[key].ipr_steps_amounts,
                        Restrict_Tooth: old_data.upper_teeth[key].restrict_ipr_tooth,
                        // Before_Step: old_data.upper_teeth[key].before_step,
                        // Amount: old_data.upper_teeth[key].amount,
                    },
                    updated: {
                        IPR: new_data.upper_teeth[key].use_ipr,
                        IPR_Steps_Amounts: new_data.upper_teeth[key].ipr_steps_amounts,
                        Restrict_Tooth: new_data.upper_teeth[key].restrict_ipr_tooth,

                        // Before_Step: new_data.upper_teeth[key].before_step,
                        // Amount: new_data.upper_teeth[key].amount,
                    }
                }
            }
        }

    }

    return Teeth;
}
/* --------------------------------------------------------------------------------*/
/* map Lower IPR Teeth */
/* --------------------------------------------------------------------------------*/
function mapLowerIPRTeeth(new_data, old_data) {
    let Teeth = {};

    for (var key in new_data.lower_teeth) {
        if (new_data.lower_teeth[key].tooth_key) {
            Teeth = {
                ...Teeth,
                [new_data.lower_teeth[key].tooth_key]: {
                    current: {
                        IPR: old_data.lower_teeth[key].use_ipr,
                        IPR_Steps_Amounts: old_data.lower_teeth[key].ipr_steps_amounts,
                        Restrict_Tooth: old_data.lower_teeth[key].restrict_ipr_tooth,
                        // Before_Step: old_data.lower_teeth[key].before_step,
                        // Amount: old_data.lower_teeth[key].amount,
                    },
                    updated: {
                        IPR: new_data.lower_teeth[key].use_ipr,
                        IPR_Steps_Amounts: new_data.lower_teeth[key].ipr_steps_amounts,
                        Restrict_Tooth: new_data.lower_teeth[key].restrict_ipr_tooth,
                        // Before_Step: new_data.lower_teeth[key].before_step,
                        // Amount: new_data.lower_teeth[key].amount,
                    }
                }
            }
        }

    }

    return Teeth;
}
/* --------------------------------------------------------------------------------*/
/* map Teeth movement Data */
/* --------------------------------------------------------------------------------*/
function mapTeethData(new_data, old_data) {

    let teeth = {
        upper_teeth: mapUpperTeeth(new_data, old_data),
        lower_teeth: mapLowerTeeth(new_data, old_data)
    };
    return teeth;
}
/* --------------------------------------------------------------------------------*/
/* map upper Teeth movement Data */
/* --------------------------------------------------------------------------------*/
function mapUpperTeeth(new_data, old_data) {
    let Teeth = {};

    for (var key in new_data.upper_teeth) {

        Teeth = {
            ...Teeth,
            [key]: {
                current: {
                    LeftRight: old_data.upper_teeth[key].movement.LeftRight,
                    Rotation: old_data.upper_teeth[key].movement.Rotation,
                    Angulation: old_data.upper_teeth[key].movement.Angulation,
                    Inclination: old_data.upper_teeth[key].movement.Inclination,
                    ExtrusionIntrusion: old_data.upper_teeth[key].movement.ExtrusionIntrusion,
                    ForwardBackward: old_data.upper_teeth[key].movement.ForwardBackward,
                    Sys_ToothNumber: old_data.upper_teeth[key].movement.ToothNumber,
                    Extraction: old_data.upper_teeth[key].movement.use_extraction,
                    Attachments: old_data.upper_teeth[key].movement.useAttachments,
                    Attachments_Steps_Types: old_data.upper_teeth[key].movement.attachments,
                    // Attachments_Step: old_data.upper_teeth[key].movement.attachments_step,
                    // Attachment_Type: old_data.upper_teeth[key].movement.attachment_type,
                    Attachment_Note:old_data.upper_teeth[key].movement.Attachment_Note,
                    Pontics: old_data.upper_teeth[key].movement.usePontics,
                    Torque_Enhancers: old_data.upper_teeth[key].movement.useTorqueEnhancers,
                    Elastic_Buttons: old_data.upper_teeth[key].movement.use_buttons,
                    Elastic_Cuts: old_data.upper_teeth[key].movement.use_cuts,
                    Elastic: old_data.upper_teeth[key].movement.useElastics,
                    Elastic_Stages: old_data.upper_teeth[key].movement.elastics,
                    Notes: old_data.upper_teeth[key].movement.note,
                    Restrict_Tooth: old_data.upper_teeth[key].movement.restrict_tooth,

                },
                updated: {
                    LeftRight: new_data.upper_teeth[key].movement.LeftRight,
                    Rotation: new_data.upper_teeth[key].movement.Rotation,
                    Angulation: new_data.upper_teeth[key].movement.Angulation,
                    Inclination: new_data.upper_teeth[key].movement.Inclination,
                    ExtrusionIntrusion: new_data.upper_teeth[key].movement.ExtrusionIntrusion,
                    ForwardBackward: new_data.upper_teeth[key].movement.ForwardBackward,
                    Sys_ToothNumber: new_data.upper_teeth[key].movement.ToothNumber,
                    Extraction: new_data.upper_teeth[key].movement.use_extraction,
                    Attachments: new_data.upper_teeth[key].movement.useAttachments,
                    Attachments_Steps_Types: new_data.upper_teeth[key].movement.attachments,
                    // Attachments_Step: new_data.upper_teeth[key].movement.attachments_step,
                    // Attachment_Type: new_data.upper_teeth[key].movement.attachment_type,
                    Attachment_Note :new_data.upper_teeth[key].movement.Attachment_Note,
                    Pontics: new_data.upper_teeth[key].movement.usePontics,
                    Torque_Enhancers: new_data.upper_teeth[key].movement.useTorqueEnhancers,
                    Elastic_Buttons: new_data.upper_teeth[key].movement.use_buttons,
                    Elastic_Cuts: new_data.upper_teeth[key].movement.use_cuts,
                    Elastic: new_data.upper_teeth[key].movement.useElastics,
                    Elastic_Stages: new_data.upper_teeth[key].movement.elastics,
                    Notes: new_data.upper_teeth[key].movement.note,
                    Restrict_Tooth: new_data.upper_teeth[key].movement.restrict_tooth,


                }
            }
        }


    }

    return Teeth;
}
/* --------------------------------------------------------------------------------*/
/* map lower Teeth movement Data */
/* --------------------------------------------------------------------------------*/
function mapLowerTeeth(new_data, old_data) {
    let Teeth = {};

    for (var key in new_data.lower_teeth) {

        Teeth = {
            ...Teeth,
            [key]: {
                current: {
                    LeftRight: old_data.lower_teeth[key].movement.LeftRight,
                    Rotation: old_data.lower_teeth[key].movement.Rotation,
                    Angulation: old_data.lower_teeth[key].movement.Angulation,
                    Inclination: old_data.lower_teeth[key].movement.Inclination,
                    ExtrusionIntrusion: old_data.lower_teeth[key].movement.ExtrusionIntrusion,
                    ForwardBackward: old_data.lower_teeth[key].movement.ForwardBackward,
                    Sys_ToothNumber: old_data.lower_teeth[key].movement.ToothNumber,
                    Extraction: old_data.lower_teeth[key].movement.use_extraction,
                    Attachments: old_data.lower_teeth[key].movement.useAttachments,
                    Attachments_Steps_Types: old_data.lower_teeth[key].movement.attachments,
                    // Attachments_Step: old_data.lower_teeth[key].movement.attachments_step,
                    // Attachment_Type: old_data.lower_teeth[key].movement.attachment_type,
                    Attachment_Note: old_data.lower_teeth[key].movement.Attachment_Note,
                    Pontics: old_data.lower_teeth[key].movement.usePontics,
                    Torque_Enhancers: old_data.lower_teeth[key].movement.useTorqueEnhancers,
                    Elastic_Buttons: old_data.lower_teeth[key].movement.use_buttons,
                    Elastic_Cuts: old_data.lower_teeth[key].movement.use_cuts,
                    Elastic: old_data.lower_teeth[key].movement.useElastics,
                    Elastic_Stages: old_data.lower_teeth[key].movement.elastics,
                    Notes: old_data.lower_teeth[key].movement.note,
                    Restrict_Tooth: old_data.lower_teeth[key].movement.restrict_tooth,


                },
                updated: {
                    LeftRight: new_data.lower_teeth[key].movement.LeftRight,
                    Rotation: new_data.lower_teeth[key].movement.Rotation,
                    Angulation: new_data.lower_teeth[key].movement.Angulation,
                    Inclination: new_data.lower_teeth[key].movement.Inclination,
                    ExtrusionIntrusion: new_data.lower_teeth[key].movement.ExtrusionIntrusion,
                    ForwardBackward: new_data.lower_teeth[key].movement.ForwardBackward,
                    Sys_ToothNumber: new_data.lower_teeth[key].movement.ToothNumber,
                    Extraction: new_data.lower_teeth[key].movement.use_extraction,
                    Attachments: new_data.lower_teeth[key].movement.useAttachments,
                    Attachments_Steps_Types: new_data.lower_teeth[key].movement.attachments,
                    // Attachments_Step: new_data.lower_teeth[key].movement.attachments_step,
                    // Attachment_Type: new_data.lower_teeth[key].movement.attachment_type,
                    Attachment_Note: new_data.lower_teeth[key].movement.Attachment_Note,
                    Pontics: new_data.lower_teeth[key].movement.usePontics,
                    Torque_Enhancers: new_data.lower_teeth[key].movement.useTorqueEnhancers,
                    Elastic_Buttons: new_data.lower_teeth[key].movement.use_buttons,
                    Elastic_Cuts: new_data.lower_teeth[key].movement.use_cuts,
                    Elastic: new_data.lower_teeth[key].movement.useElastics,
                    Elastic_Stages: new_data.lower_teeth[key].movement.elastics,
                    Notes: new_data.lower_teeth[key].movement.note,
                    Restrict_Tooth: new_data.lower_teeth[key].movement.restrict_tooth,


                }
            }
        }


    }

    return Teeth;
}
