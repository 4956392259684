import React, { Component, Fragment } from "react";

import { Text, StyleSheet, View, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    paddingTop: 10,
    paddingBottom: 10,
    // borderBottomWidth: 1,
    // borderColor: "#E6E6FA",
    alignItems: "center",
  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    width: "100%",
  },
  container: {
    flexDirection: "row",
  },
  container2: {
    flexDirection: "row",
  },
  field: {
    flexDirection: "row",
    paddingTop: 10,
  },
  col: {
    flexDirection: "column",
  },
  col_4: {
    flexDirection: "column",
    width: "40%",
  },
  col_5: {
    flexDirection: "column",
    width: "50%",
  },
  col_1: {
    flexDirection: "column",
    width: "10%",
  },

  title: {
    fontSize: 10,
    textAlign: "left",
    color: "#00c7b1",
    padding: "10px 0 5px 0",
  },
  title2: {
    fontSize: 10,
    textAlign: "left",
    color: "#00c7b1",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
  image: {
    width: "15px",
    height: "25px",
    margin: 1,
    padding: 1,
  },

  green_btn_not_active: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#d7f5f2",
    borderRadius: 8,
    fontSize: 8,
    padding: 2,
    margin: 3,
    flexDirection: "column",
    width: "30%",
  },
  col_title: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    fontSize: 8,
    padding: "0 5px 0 0",
    // margin: 1,
    flexDirection: "column",
    width: "10%",
  },
  green_btn_active: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00c7b1",
    borderRadius: 8,
    fontSize: 8,
    padding: 2,
    margin: 3,
    flexDirection: "column",
    width: "30%",
  },
  text_active: {
    fontSize: 10,
    color: "white",
    padding: 1,
  },
  text_not_active: {
    fontSize: 8,
    color: "#00c7b1",
    padding: 1,
  },
  emplty_col: {
    width: "30%",
  },
  text_area: {
    flexGrow: 1,
    borderColor: "#00c7b1",
    borderWidth: 1,
    borderRadius: 2,
    fontSize: 8,
    padding: 2,
    margin: 3,
    minHeight: "50px",
    flexDirection: "column",
  },
  value: {
    padding: "10px",
  },
  tooth_num: {
    fontSize: 8,
    width: "15px",
    margin: 1,
    padding: 1,
    textAlign: "center",
    color: "#00c7b1",
  },
});
export default class TeethPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teeth: props.teeth,
    };
  }
  render() {
    const { teeth } = this.state;
    const { content } = this.props

    return (
      <Fragment>
        <View style={styles.row}>
          <View style={styles.container}>
            {Object.keys(teeth.upper_teeth).map((prop, index) => {
              return (
                <View style={styles.col} key={index}>
                  <Image
                    style={styles.image}
                    src={`${teeth.upper_teeth[prop].image_pdf}`}
                  />
                </View>
              );
            })}
          </View>
          <View style={styles.container}>
            <Text style={styles.tooth_num}>R</Text>
            <Text style={styles.tooth_num}>8</Text>
            <Text style={styles.tooth_num}>7</Text>
            <Text style={styles.tooth_num}>6</Text>
            <Text style={styles.tooth_num}>5</Text>
            <Text style={styles.tooth_num}>4</Text>
            <Text style={styles.tooth_num}>3</Text>
            <Text style={styles.tooth_num}>2</Text>
            <Text style={styles.tooth_num}>1</Text>
            <Text style={styles.tooth_num}>1</Text>
            <Text style={styles.tooth_num}>2</Text>
            <Text style={styles.tooth_num}>3</Text>
            <Text style={styles.tooth_num}>4</Text>
            <Text style={styles.tooth_num}>5</Text>
            <Text style={styles.tooth_num}>6</Text>
            <Text style={styles.tooth_num}>7</Text>
            <Text style={styles.tooth_num}>8</Text>
            <Text style={styles.tooth_num}>L</Text>
          </View>
          <View style={styles.container}>
            {Object.keys(teeth.lower_teeth).map((prop, index) => {
              return (
                <View style={styles.col} key={index}>
                  <Image
                    style={styles.image}
                    src={`${teeth.lower_teeth[prop].image_pdf}`}
                  />
                </View>
              );
            })}
          </View>
        </View>
      </Fragment>
    );
  }
}
