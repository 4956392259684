import React from "react";


const CustomeHeader = ({ title, optional, note, note_text, capitalize , small, withoutLine, onboarding, largeFont ,bold }) => {
    return (
        <div className={`${note ? "header_grid_note" : "header_grid"}`}>
            <div className={`${small?"small-header":""} ${capitalize === false ? "not-capitalize" : ""} ${(title === "Malocclusion") ? `header_title2 Malocc-title-spaces title-2-text` : ``} 
            ${onboarding ? 'onbording_header_title': 'header_title'} ${largeFont ? 'title-1-text': ''} ${bold?"title-1-bold ":"title-3-text "} `}>{title}
                {optional && <span className="optional">(Optional)</span>}</div>
                {!withoutLine && <div className="c-line"></div> }
            {note &&
                <div style={{zIndex: 99}} className="note-tooltip">
                    <span

                        className="icon-container tooltip1 new-tooltip"
                        id="HybridTreatmentMoreInfo"
                    >
                        <i className="fas fa-question-circle "></i>
                        <div
                            className={`${window.innerWidth < 840
                                ? "tooltip-bottom"
                                : "tooltip1-right"
                                }  custom_shadow`}
                        >
                            <div className="tooltip-body">
                                {note_text}
                            </div>
                            <i></i>
                        </div>
                    </span>

                </div>
                }

        </div>
    );
};

export default CustomeHeader;
