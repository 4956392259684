import React from "react";
import trash from "../../assets/images/icon/trash.svg"
import { Input } from "reactstrap";

// this is the final calling in any component for (shered Step Controller) attachment and APR step
/* <Steps StepTitle="add Title Step here" hyberText="add from here " arrayOf_steps={(this.state.array=[])?this.state.array=[{ step: 0}]:this.state.array} onclick={(value) => {
    this.handelChanges(value)
}}/> */



const Steps = ({
    StepTitle,
    hyberText,
    onclick,
    summary,
    arrayOf_steps,
}) => {
    //adding Step//
    const addStep = () => {
        let newStep = arrayOf_steps.concat({
            step: 0
        });
        onclick(newStep);
    }

    //deleting Step//
    const removeStep = (index) => {
        let deleteStep = arrayOf_steps.filter((el, i) => i !== index)
        onclick(deleteStep);
    }

    //changing Step//
    const changetStep = (value, index) => {
        let steps_changing = arrayOf_steps.map(
            (item, j) => {
                if (j === index) {
                    return {
                        ...item,
                        step: value,
                    };
                } else {
                    return item;
                }
            }
        );
        onclick(steps_changing);
    }

    return (
        <div>
            <div className="cmt2">
                {arrayOf_steps.map((value, index) =>
                    <div className={index === 0 ? 'first-step-grid' : "step-grid"} key={index}>
                        <div className="remove-step"
                          disabled={summary}
                          style={{ pointerEvents: summary ? "none" : "" }}
                            onClick={() => removeStep(index)}
                        >{index !== 0 && <img src={trash} alt="trash" />}</div>
                        <div className="custom-dark-text button-text">{StepTitle}</div>
                        <div className="form-material button-text">
                            <Input
                                type="number"
                                placeholder=""
                                className="step-number"
                                onClick={e => {
                                    e.target.focus();
                                }}
                                onMouseUp={e => {
                                    e.target.blur();
                                }}
                                value={
                                    value.step
                                }
                                key={index}
                                min={0}
                                style={{ pointerEvents: summary ? "none" : "" }}
                                disabled={summary}
                                onChange={
                                    (e) => {
                                        if (e.target.value < 0) {
                                            changetStep(0, index)
                                        } else {
                                            changetStep(e.target.value.replace(/^0+/, ''), index)
                                        }
                                    }
                                }
                            />
                        </div>

                    </div>)}
            </div>
            <div className={`${summary ? "disabled-add-step" : "add-step"} body-text`}
                onClick={addStep}
                disabled={summary}
                style={{ pointerEvents: summary ? "none" : "" }}
            >{hyberText}
            </div>
        </div>
    );
};

export default Steps;