import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,

} from "reactstrap";
import { updateAlignerCase } from "../../../api/api";
import { availableModules, activateModules } from "../../../redux/advanced/action";
import { clearAdvancedModules } from "../../../redux/CaseSubmission/action"
import {
  Clear_Teeth_Data,
  Case_Extraction,
  Case_Attachments,
  Case_IPR,
  Case_Pontics,
  Case_Overcorrections,
  Case_Torque_Enhancers,
  Case_Arch_Expansion,
  Case_Elastics,
  Case_Elastics_Buttons,
  Case_Elastics_Cuts,
  Case_teethMovement,
} from "../../../redux/customeTeeth/action"
import ArchExpansion from "./ModulesComponents/ArchExpansion";
import Attachments from "./ModulesComponents/Attachments";
import BiteRamps from "./ModulesComponents/BiteRamps";
import Elastics from "./ModulesComponents/Elastics";
import ToothRateMovement from "./ModulesComponents/ToothRateMovement";
import Extraction from "./ModulesComponents/Extraction";
import IPR from "./ModulesComponents/IPR";
import Malocclusion from "./ModulesComponents/Malocclusion";
import OverCorrection from "./ModulesComponents/OverCorrection";
import PassiveAligner from "./ModulesComponents/PassiveAligner";
import Pontics from "./ModulesComponents/Pontics";
import ToothSizeDiscrepancy from "./ModulesComponents/ToothSizeDiscrepancy";
import HybirdTreatment from "./ModulesComponents/HybirdTreatment";
import TorqueEnhancers from "./ModulesComponents/TorqueEnhancers";
import { mapTeethToApi } from "../../../services/mapTeethToApi";
import { mapAdvancedToApi } from "../../../services/mapAdvancedToApi";
import CustomeHeader from "../../../components/header/CustomeHeader";
import { CustomInput } from "reactstrap";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  availableModules: (payload) => dispatch(availableModules(payload)),
  activateModules: (payload) => dispatch(activateModules(payload)),
  clearAdvancedModules: (payload) => dispatch(clearAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),
  Case_Extraction: (payload) => dispatch(Case_Extraction(payload)),
  Case_Attachments: (payload) => dispatch(Case_Attachments(payload)),
  Case_IPR: (payload) => dispatch(Case_IPR(payload)),
  Case_Pontics: (payload) => dispatch(Case_Pontics(payload)),
  Case_Overcorrections: (payload) => dispatch(Case_Overcorrections(payload)),
  Case_Torque_Enhancers: (payload) => dispatch(Case_Torque_Enhancers(payload)),
  Case_Arch_Expansion: (payload) => dispatch(Case_Arch_Expansion(payload)),
  Case_Elastics: (payload) => dispatch(Case_Elastics(payload)),
  Case_teethMovement: (payload) => dispatch(Case_teethMovement(payload)),
  Case_Elastics_Buttons: (payload) => dispatch(Case_Elastics_Buttons(payload)),
  Case_Elastics_Cuts: (payload) => dispatch(Case_Elastics_Cuts(payload)),
});
class AdvancedModules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      malocclusion_modal: false,
      extraction_modal: false,
      ipr_modal: false,
      attachments_modal: false,
      pontics_modal: false,

      overCorrection_modal: false,
      passiveAligners_modal: false,
      toothSizeDiscrepancy_modal: false,
      torqueEnhancers_modal: false,
      biteRamps_modal: false,

      elastics_modal: false,
      archExpansion_modal: false,
      teethMovement_modal: false,
      hybridTreatment_modal: false,
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*activateModules                                          */
  /*--------------------------------------------------------------------------------*/
  activateModules = (key) => {
    this.props.activateModules(key);
    this.toggleModals(key);
  };
  /*--------------------------------------------------------------------------------*/
  /*deActivate Modules   & clear data in modules                                       */
  /*--------------------------------------------------------------------------------*/
  deActivateModules = (key) => {

    this.props.availableModules(key);
    this.props.clearAdvancedModules(key);
    this.ClearTeethValues(key)
  };
  /*--------------------------------------------------------------------------------*/
  /*Clear Teeth Values  in modules                                        */
  /*--------------------------------------------------------------------------------*/
  ClearTeethValues = (key) => {

    if (key === "malocclusion") {
      this.props.Clear_Teeth_Data("Case_Crossbites")
      this.props.Clear_Teeth_Data("Case_ClassII")
      this.props.Clear_Teeth_Data("Case_ClassIII")
      this.props.Clear_Teeth_Data("Case_Crowding")
    }
    if (key === "ipr") {
      this.props.Clear_Teeth_Data("Case_IPR")
    }
    if (key === "extraction") {
      this.props.Clear_Teeth_Data("Case_Extraction")

    }
    if (key === "attachments") {
      this.props.Clear_Teeth_Data("Case_Attachments")

    }
    if (key === "pontics") {
      this.props.Clear_Teeth_Data("Case_Pontics")

    }
    if (key === "overCorrection") {
      this.props.Clear_Teeth_Data("Case_Overcorrections")

    }
    if (key === "torqueEnhancers") {
      this.props.Clear_Teeth_Data("Case_Torque_Enhancers")

    }
    if (key === "archExpansion") {
      this.props.Clear_Teeth_Data("Case_Arch_Expansion")

    }
    if (key === "elastics") {
      this.props.Clear_Teeth_Data("Case_Elastics")
      this.props.Clear_Teeth_Data("Case_Elastics_Buttons")
      this.props.Clear_Teeth_Data("Case_Elastics_Cuts")
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*fill teeth data if module is active                                       */
  /*--------------------------------------------------------------------------------*/
  FillTeethValues = (key) => {
    if (key === "ipr") {
      this.props.Case_IPR(this.props.mappedData.advanced_modules.ipr.iprRestriction);
    }
    if (key === "extraction") {
      this.props.Case_Extraction(this.props.mappedData.advanced_modules.extraction.teeth);
    }
    if (key === "attachments") {
      this.props.Case_Attachments(
        this.props.mappedData.advanced_modules.attachments.attachmentsRestriction
      );
    }
    if (key === "pontics") {
      this.props.Case_Pontics(this.props.mappedData.advanced_modules.pontics.teeth);
    }
    if (key === "overCorrection") {
      this.props.Case_Overcorrections(
        this.props.mappedData.advanced_modules.overCorrection.teeth
      );
    }
    if (key === "torqueEnhancers") {
      this.props.Case_Torque_Enhancers(
        this.props.mappedData.advanced_modules.torqueEnhancers.teeth
      );
    }
    if (key === "archExpansion") {
      this.props.Case_Arch_Expansion(
        this.props.mappedData.advanced_modules.archExpansion.teeth
      );
    }
    if (key === "elastics") {
      this.props.Case_Elastics(this.props.mappedData.advanced_modules.elastics.teeth);
      this.props.Case_Elastics_Buttons(this.props.mappedData.advanced_modules.elastics.buttons_teeth);
      this.props.Case_Elastics_Cuts(this.props.mappedData.advanced_modules.elastics.cuts_teeth);

    }
  }

  /*--------------------------------------------------------------------------------*/
  /*toggle modals                                          */
  /*--------------------------------------------------------------------------------*/
  toggleModals = (id) => {

    let modalData = this.props.advancedModules.modules
      .filter((value) => {
        return value.key === id;
      })
    if (this.state[`${id}_modal`] === false) {

      if (modalData[0].active === false) {
        this.ClearTeethValues(id)
      }
      else {
        // this.FillTeethValues(id)
      }
    }

    this.setState({ [`${id}_modal`]: !this.state[`${id}_modal`] });
  };

  justToggle = (key) => {
    this.toggleModals(key);
    this.deActivateModules(key)
  }
  /*--------------------------------------------------------------------------------*/
  /*handel Advanced Modules                                       */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.props;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    setSubmitting(true);
    let data = {
      aligner_case: {
        step: "advanced_modules",
        advanced_modules: {
          ...this.props.caseSubmission.advanced_modules,
          ipr: {
            ...this.props.caseSubmission.advanced_modules.ipr,
            iprRestriction: mapTeethToApi(this.props.customeTeethReducer.Case_IPR)
          },
          archExpansion: {
            ...this.props.caseSubmission.advanced_modules.archExpansion,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Arch_Expansion
            )
          },

          attachments: {
            ...this.props.caseSubmission.advanced_modules.attachments,
            attachmentsRestriction: mapTeethToApi(
              this.props.customeTeethReducer.Case_Attachments
            )
          },
          elastics: {
            ...this.props.caseSubmission.advanced_modules.elastics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics),
            buttons_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Buttons),
            cuts_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Cuts),

          },
          extraction: {
            ...this.props.caseSubmission.advanced_modules.extraction,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Extraction),
          },
          overCorrection: {
            ...this.props.caseSubmission.advanced_modules.overCorrection,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Overcorrections
            ),
          },
          pontics: {
            ...this.props.caseSubmission.advanced_modules.pontics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Pontics),
          },
          torqueEnhancers: {
            ...this.props.caseSubmission.advanced_modules.torqueEnhancers,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Torque_Enhancers
            ),
          },
          malocclusion: {
            ...this.props.caseSubmission.advanced_modules.malocclusion,
            crossbite: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crossbite,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Crossbites
              ),
            },
            crowding: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crowding,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Crowding
              ),
            },
            classII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classII,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_ClassII
              ),
            },
            classIII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classIII,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_ClassIII
              ),
            },
          },
          all_modules: mapAdvancedToApi(this.props.advancedModules.modules),
        },
      },
    };
    updateAlignerCase(doctorId, caseId, JSON.stringify(data))
      .then((res) => {
        this.props.GetAlignerCaseById()
        this.props.NextStep();
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };
  render() {
    const { content } = this.props.languageReducer
    return (
      <div>
        <div>
          <CustomeHeader title={content.advanced_treatment_options} optional={true} largeFont={false}/>
          <div className="advance_main_grid cmt5">
            <div>
              <div className="advance_main_grid_V4 mt-3 ">
                {this.props.advancedModules.modules
                  .map((values, index) => (
                    <div
                    key={index}
                    id="Treatment_Type"
                    className={` subhead-text   closebtn close-active-module ${(values.active) ? `relat` : `relat`}`} 
                    >

                    {values.active && <span className="close-modules" 
                        onClick={() => {
                          this.deActivateModules(values.key)
                        }}>&times;
                      </span>}
                      <div className={`${(values.active) ? `s_card_grid_active ` : `s_card_grid`}`}
                        onClick={() => {
                          this.toggleModals(values.key);
                        }}>
                        <div style={{ cursor: "pointer", width: "100%" }} >{
                          <img
                            src={`${(values.active) ? values.active_img : values.img}`}
                            className="modules-img"
                            alt="Aligner Treatment"
                          />}
                        </div>
                        <div className="text_malocclusions subhead-text"

                        >
                          {content[values.name].charAt(0).toUpperCase() + content[values.name].slice(1).toLowerCase()}
                        </div>

                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

        </div>

        {/*--------------------------------------------------------------------------------*/}
        {/* malocclusion Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.malocclusion_modal}
          toggle={() => this.toggleModals("malocclusion")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"malocclusion-modal"}`}
          // className="modalx"
        >
   
          <ModalHeader toggle={() => this.toggleModals("malocclusion")} className="Malocc-title-spaces c-modal">
            <CustomeHeader title={content.malocclusion} className="cp5 pt-2 Malocc-title" optional={false} largeFont={true} withoutLine={true} />


          </ModalHeader>

          <ModalBody className="">
            <Malocclusion save={() => this.activateModules("malocclusion")} justToggle={() => this.justToggle("malocclusion")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end malocclusion Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* extraction Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.extraction_modal}
          toggle={() => this.toggleModals("extraction")}
          // className="modalx6"
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("extraction")} className="c-modal">


          </ModalHeader>

          <ModalBody className="question-circle">
            <CustomeHeader title={content.extraction} className="cp3 pt-0" optional={false} note={true} note_text={content.extraction_note} largeFont={false}/>
            <Extraction save={() => this.activateModules("extraction")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end extraction Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* ipr Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.ipr_modal}
          toggle={() => this.toggleModals("ipr")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("ipr")}>

          </ModalHeader>


          <ModalBody className="question-circle">
            <CustomeHeader title={content.ipr} className="cp3 pt-0" optional={false} note={true} note_text={content.IPR_note} largeFont={false}/>
            <IPR save={() => this.activateModules("ipr")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end ipr Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* attachments Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.attachments_modal}
          toggle={() => this.toggleModals("attachments")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("attachments")} className="c-modal">


          </ModalHeader>

          <ModalBody className="question-circle">
            <CustomeHeader title={content.attachments} className="cp3 pt-0" optional={false} note={true} note_text={content.attachments_note} largeFont={false}/>
            <Attachments save={() => this.activateModules("attachments")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end attachments Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* pontics Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.pontics_modal}
          toggle={() => this.toggleModals("pontics")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("pontics")} className="c-modal">


          </ModalHeader>

          <ModalBody className="question-circle">
            <CustomeHeader title={content.pontics} className="cp3 pt-0" optional={false} note={true} note_text={content.pontics_note} largeFont={false}/>
            <Pontics save={() => this.activateModules("pontics")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end pontics Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* overCorrection Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.overCorrection_modal}
          toggle={() => this.toggleModals("overCorrection")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("overCorrection")} className="c-modal">


          </ModalHeader>

          <ModalBody className="question-circle">
            <CustomeHeader title={content.overcorrection} className="cp3 pt-0" optional={false} note={true} note_text={content.overCorrection_note} largeFont={false}/>
            <OverCorrection
              save={() => this.activateModules("overCorrection")}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end overCorrection Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* passiveAligners Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.passiveAligners_modal}
          toggle={() => this.toggleModals("passiveAligners")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("passiveAligners")} className="c-modal">

          </ModalHeader>

          <ModalBody className="question-circle">
            <CustomeHeader capitalize={false} title={content.passive_aligners} className="cp3 pt-0" optional={false} note={true} note_text={content.passive_aligner_note} largeFont={false}/>
            <PassiveAligner
              save={() => this.activateModules("passiveAligners")}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end passiveAligners Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* toothSizeDiscrepancy Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.toothSizeDiscrepancy_modal}
          toggle={() => this.toggleModals("toothSizeDiscrepancy")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("toothSizeDiscrepancy")} className="c-modal">
          </ModalHeader>

          <ModalBody className="question-circle">
            <CustomeHeader title={content.tooth_size_discrepancy} className="cp3 pt-0" optional={false} note={true} note_text={content.tooth_size_discrepancy_note} largeFont={false}/>
            <ToothSizeDiscrepancy
              save={() => this.activateModules("toothSizeDiscrepancy")}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end toothSizeDiscrepancy Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* torqueEnhancers Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.torqueEnhancers_modal}
          toggle={() => this.toggleModals("torqueEnhancers")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("torqueEnhancers")} className="c-modal">
          </ModalHeader>

          <ModalBody className="question-circle">
            <CustomeHeader title={content.torque_enhancers} className="cp3 pt-0" optional={false} note={true} note_text={content.torque_enhancers_note} largeFont={false}/>
            <TorqueEnhancers
              save={() => this.activateModules("torqueEnhancers")}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end torqueEnhancers Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* biteRamps Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.biteRamps_modal}
          toggle={() => this.toggleModals("biteRamps")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("biteRamps")} className="c-modal">



          </ModalHeader>

          <ModalBody className="question-circle">
            <CustomeHeader title={content.bite_ramps} className="cp3 pt-0" optional={false} note={true} note_text={content.BiteRamps_note} largeFont={false}/>
            <BiteRamps save={() => this.activateModules("biteRamps")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end biteRamps Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* archExpansion Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.archExpansion_modal}
          toggle={() => this.toggleModals("archExpansion")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
        >
          <ModalHeader toggle={() => this.toggleModals("archExpansion")} className="c-modal">

          </ModalHeader>

          <ModalBody className="question-circle">
            <CustomeHeader title={content.arch_expansion} className="cp3 pt-0" optional={false} note={true} note_text={content.Archexpansion_note} largeFont={false}/>
            <ArchExpansion save={() => this.activateModules("archExpansion")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end archExpansion Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* elastics Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.elastics_modal}
          toggle={() => this.toggleModals("elastics")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
          >
          <ModalHeader toggle={() => this.toggleModals("elastics")} className="c-modal">
          </ModalHeader>
          <ModalBody className="question-circle">
            <CustomeHeader title={content.elastics} className="cp3 pt-0" optional={false} note={true} note_text={content.Elastics_note} largeFont={false}/>
            <Elastics save={() => this.activateModules("elastics")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end elastics Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* toothRateMovement Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.teethMovement_modal}
          toggle={() => this.toggleModals("teethMovement")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
           >
          <ModalHeader toggle={() => this.toggleModals("teethMovement")} className="c-modal">
          </ModalHeader>
          <ModalBody className="question-circle">
            <CustomeHeader title={content.toothRateMovement} className="cp3 pt-0" optional={false} note={true} note_text={content.toothRateMovement_note} largeFont={false}/>
            <ToothRateMovement save={() => this.activateModules("teethMovement")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end toothRateMovement Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Hybrid Treatment Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.hybridTreatment_modal}
          toggle={() => this.toggleModals("hybridTreatment")}
          className={` ${(window.innerWidth < 839 )? "modalx" :"modalx6"}`}
           >
          <ModalHeader toggle={() => this.toggleModals("hybridTreatment")} className="c-modal">
          </ModalHeader>
          <ModalBody className="question-circle">
            <CustomeHeader title={content.hybridTreatment} className="cp3 pt-0" optional={false} note={true} note_text="" largeFont={false}/>
            <HybirdTreatment save={() => this.activateModules("hybridTreatment")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Hybrid Treatment Modal*/}
        {/*--------------------------------------------------------------------------------*/}

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedModules);
