import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { getComments } from "../../api/api";
import Patient from "./Patient";
import Gallery from "./Gallery";
import History from "./History";
import Comments from "./Comments";
import { connect } from "react-redux";
import { updateInbox } from "../../redux/inbox/action";
import { getCase } from "../../redux/caseDetails/action";
import Back from "../../components/back/Back";
import { withRouter } from 'react-router';
import { getCaseById } from "../../redux/caseById/action";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  getCaseById: (payload) => dispatch(getCaseById(payload)),
  updateInbox: (payload) => dispatch(updateInbox(payload)),
  getCase: (payload) => dispatch(getCase(payload)),
});

class CaseDetails extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);


    /*init state */
    this.state = {
      activeTab: "1",
      id: props.userReducer.currentUser.id,
      case: {},
      isLoading: true,
      commentsisLoading: false,
      comments: [],
      modal: false,
      gco_doctor: props.userReducer.currentUser.gco_doctor,
      mappedData: {},
      filters: this.props.location.state
        ? this.props.location.state.data
        : null,
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*get aligner case  */
  /*--------------------------------------------------------------------------------*/
  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const doctor_id = this.props.userReducer.currentUser.id
    const case_id = this.props.match.params.id.toString()
    // get case by id action 
    await this.props.getCaseById({ doctor_id: doctor_id, case_id: case_id })
    let mappedData = this.props.caseReducer.case;
    let data = this.props.currentCase.state
    this.setState({ case: data, isLoading: false, mappedData: mappedData , case_id: case_id});
    this.GetComments()
  }
  componentDidUpdate= async(prevProps, prevState)=> {
    if (this.state.case_id !== this.props.match.params.id.toString() && this.state.isLoading === false) {
      this.setState({ isLoading: true });
      const doctor_id = this.props.userReducer.currentUser.id
      const case_id = this.props.match.params.id.toString()
      // get case by id action 
      await this.props.getCaseById({ doctor_id: doctor_id, case_id: case_id })
      let mappedData = this.props.caseReducer.case;
      let data = this.props.currentCase.state
      this.setState({ case: data, isLoading: false, mappedData: mappedData , case_id: case_id});
      this.GetComments()    }
  }

  /*--------------------------------------------------------------------------------*/
  /*get Comments  */
  /*--------------------------------------------------------------------------------*/
  GetComments = async () => {
    let case_uid = this.props.caseReducer?.case?.uid
    if (case_uid) {
      await getComments(this.state.id, case_uid).then((res) => {
        this.setState({ comments: res, commentsisLoading: false });
        /* update data in inbox reducer   */
        this.props.updateInbox(res);
      });
    }

  }

  /*--------------------------------------------------------------------------------*/
  /*update data in reducer    */
  /*--------------------------------------------------------------------------------*/
  UpdateCaseReducer = (data) => {
    setTimeout(() => {
      this.setState({ isLoading: false });
      this.props.getCase(data);
    }, 1000);
  };
  /*--------------------------------------------------------------------------------*/
  /*set active tab   */
  /*--------------------------------------------------------------------------------*/
  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }


  /* --------------------------------------------------------------------------------*/
  /* clear state if go out from component   */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount = () => {
    if (this.props.history.action === "POP") {
      localStorage.setItem("data", JSON.stringify({
        back: true,
        state: { data: this.state.filters }
      }))
    }

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { content } = this.props.languageReducer;
    const { isLoading, activeTab, mappedData, id, comments } = this.state;
    const { uid, case_type } = this.props.caseReducer.case
    return (
      <div>
        <div >
          {isLoading ? (
            <div className="row spinner-container justify-content-center align-items-center">
              <Spinner className="green-spinner" type="grow" />
            </div>
          ) : (
            < div className="cpx2 p0_mobile">


              <Back custom={true} props={this.props} link={{
                pathname: `/cases`,
                state: { data: this.state.filters }
              }
              } />
              <div className="c-tabs case-details-container">
                <Nav tabs>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* back button */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.history.push({
                        pathname: `/cases`,
                        state: { data: this.state.filters },
                      });
                    }}
                  >
                    <i className="fas fa-arrow-left" aria-hidden="true"></i>
                  </NavLink>
                </NavItem> */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Details tab */}
                  {/*--------------------------------------------------------------------------------*/}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggleTabs("1");
                      }}
                    >
                      <div className="tabes-case-details2 button-text"> {content.details}</div>
                    </NavLink>
                  </NavItem>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Records tab */}
                  {/*--------------------------------------------------------------------------------*/}
                  {//this.props.currentCase.state.case_type !== "Retainer" && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTabs("2");
                        }}
                      >
                        <div className="tabes-case-details2 button-text"> {content.records}</div>

                      </NavLink>
                    </NavItem>
                    //)
                  }
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Inbox tab */}
                  {/*--------------------------------------------------------------------------------*/}
                  {//this.props.currentCase.state.case_type !== "Retainer" &&
                    uid && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggleTabs("3");
                          }}
                        >
                          <div className="inbox-heartbit tabes-case-details2 button-text">{content.inbox}
                            {comments.length > 0 && <div className="notify">
                              <span className="heartbit" /> <span className="point" />
                            </div>}

                          </div>
                        </NavLink>
                      </NavItem>
                    )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* History tab */}
                  {/*--------------------------------------------------------------------------------*/}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggleTabs("4");
                      }}
                    >
                      <div className="tabes-case-details2 button-text">  {content.history}</div>

                    </NavLink>
                  </NavItem>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* full patient name  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* <NavItem>
                  <NavLink>{this.state.case.patient.full_name}</NavLink>
                </NavItem> */}
                </Nav>
                <TabContent activeTab={activeTab} className="case-info-container">
                  {/*--------------------------------------------------------------------------------*/}
                  {/* case details tab content  */}
                  {/*--------------------------------------------------------------------------------*/}
                  <TabPane
                    tabId="1"
                    className={isLoading ? "text-center" : "cpx2 p8_mobile"}
                  >
                    {isLoading ? (
                      <Spinner className="green-spinner" type="grow" />
                    ) : (
                      <Patient
                        CaseDetails={this.props.caseReducer.case}
                        doctorId={id}
                        mappedData={mappedData}
                        UpdateCaseReducer={this.UpdateCaseReducer}
                        data={this.props}
                        gco_doctor={this.props.userReducer.currentUser.gco_doctor}
                      />
                    )}
                  </TabPane>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* end case details tab content  */}
                  {/*--------------------------------------------------------------------------------*/}

                  {/*--------------------------------------------------------------------------------*/}
                  {/*case gallery tab content  */}
                  {/*--------------------------------------------------------------------------------*/}
                  <TabPane tabId="2">
                    <div
                      className={
                        isLoading ? "text-center p-3" : "m-auto"
                      }
                    >
                      {isLoading ? (
                        <Spinner className="green-spinner" type="grow" />
                      ) : (
                        <Gallery
                          CaseType={case_type}
                        />
                      )}
                    </div>
                  </TabPane>
                  {/*--------------------------------------------------------------------------------*/}
                  {/*end case gallery tab content  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*case inbox tab content  */}
                  {/*--------------------------------------------------------------------------------*/}
                  <TabPane tabId="3">
                    <div

                      className={
                        this.state.commentsisLoading
                          ? "text-center p-3"
                          : "m-auto"
                      }
                    >
                      {this.state.activeTab === "3" && (
                        <Comments caseId={uid} />
                      )}
                    </div>
                  </TabPane>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* end case inbox tab content */}
                  {/*--------------------------------------------------------------------------------*/}

                  {/*--------------------------------------------------------------------------------*/}
                  {/* case history tab content */}
                  {/*--------------------------------------------------------------------------------*/}
                  <TabPane tabId="4">
                    <div

                      className={
                        isLoading
                          ? "text-center p-3"
                          : ""
                      }
                    >
                      {isLoading ? (
                        <Spinner className="green-spinner" type="grow" />
                      ) : (
                        <History />
                      )}
                    </div>
                  </TabPane>
                  {/*--------------------------------------------------------------------------------*/}
                  {/*end case history tab content */}
                  {/*--------------------------------------------------------------------------------*/}
                </TabContent>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CaseDetails));
