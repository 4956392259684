import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import CustomToggle from "../../../components/toggle/CustomeToggle";
import CustomeHeader from "../../../components/header/CustomeHeader";
import IPRStepAmount from "../../../components/IPR/IPRStepAmount";
import {
  setUnsavedChanges,
  setUnsavedData,
} from "../../../redux/changes/action";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setUnsavedChanges: (payload) => dispatch(setUnsavedChanges(payload)),
  setUnsavedData: (payload) => dispatch(setUnsavedData(payload)),
});
class IPRDetailes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      use_ipr: this.props.data.use_ipr,
      ipr_step: this.props.data.before_step,
      ipr_value: Number(this.props.data.amount),
      tooth_key: this.props.data.tooth_key,
      tooth_key_sys: this.props.data.tooth_key_sys,
      ipr_steps_amounts: this.props.data.ipr_steps_amounts,
    };
  }

  checkChanges = () => {
    if (
      this.props.data.use_ipr !== this.state.use_ipr ||
      this.props.data.before_step !== this.state.ipr_step ||
      Number(this.props.data.amount) !== this.state.ipr_value ||
      this.checkSteps()
    ) {
      return false;
    } else {
      return true;
    }
  };

  checkSteps = () => {
    let changed = false;
    if (this.props.data.ipr_steps_amounts !== this.state.ipr_steps_amounts) {
      changed = true;
    } else {
      if (
        this.state.ipr_steps_amounts.length &&
        this.props.data.ipr_steps_amounts.length
      ) {
        for (var x = 0; x < this.state.ipr_steps_amounts.length; x++) {
          if (
            this.state.ipr_steps_amounts[x].step !==
              this.props.data.ipr_steps_amounts[x].step ||
            this.state.ipr_steps_amounts[x].amount !==
              this.props.data.ipr_steps_amounts[x].amount
          ) {
            changed = true;
          }
        }
      }
    }
    return changed;
  };

  checkIPRData = () => {
    if (this.state.use_ipr) {
      for (var x = 0; x < this.state.ipr_steps_amounts.length; x++) {
        if (
          this.state.ipr_steps_amounts[x].step === 0 ||
          this.state.ipr_steps_amounts[x].amount === 0
        ) {
          return true;
        }
      }
    }

    return false;
  };
  componentDidUpdate = () => {
    if (!this.checkChanges() && !this.props.changesReducer.dataChanged) {
      let new_data = {
        ...this.props.data,
        use_ipr: this.state.use_ipr,
        before_step: this.state.ipr_step,
        amount: this.state.ipr_value,
        updated: true,
        ipr_steps_amounts: this.state.ipr_steps_amounts,
      };
      this.props.setUnsavedChanges(true);
      this.props.setUnsavedData(new_data);
    }
  };
  saveIPRData = () => {
    let new_data = {
      ...this.props.data,
      use_ipr: this.state.use_ipr,
      before_step: this.state.ipr_step,
      amount: this.state.ipr_value,
      updated: true,
      ipr_steps_amounts: this.state.ipr_steps_amounts,
    };
    this.props.saveIPRData(new_data);
  };
  render() {
    const { use_ipr, tooth_key_sys, ipr_steps_amounts } = this.state;
    const { content } = this.props.languageReducer;
    const { dental_notation } = this.props.userReducer.currentUser;

    return (
      <div className="tooth_movement_container">
        <CustomeHeader
          capitalize={false}
          title={
            tooth_key_sys
              ? `${
                  content.ipr
                } ${content.between.toLowerCase()} ${tooth_key_sys[
                  dental_notation
                ].replace("/", ` ${content.and.toLowerCase()} `)}`
              : ""
          }
        />
        <div className="mt-3 cml1">
          {/* use IPR toggle */}
          <CustomToggle
            id="movement_ipr"
            name="movement_ipr"
            checked={use_ipr ? true : false}
            onChange={() => {
              let use_ipr_value = use_ipr ? false : true;
              this.setState({
                use_ipr: use_ipr_value,
                ...(!use_ipr_value && {
                  ipr_steps_amounts: [{ step: 0, amount: 0 }],
                }),
              });
            }}
            title={content.use_ipr}
          />
          {/* end use IPR toggle */}
          <div>
            <IPRStepAmount
              onclick={(ipr_steps_amounts) => {
                this.setState({ ipr_steps_amounts: ipr_steps_amounts });
                let new_data = {
                  ...this.props.data,
                  use_ipr: this.state.use_ipr,
                  before_step: this.state.ipr_step,
                  amount: this.state.ipr_value,
                  updated: true,
                  ipr_steps_amounts: ipr_steps_amounts,
                };
                this.props.setUnsavedData(new_data);
              }}
              steps_amounts_array={ipr_steps_amounts}
              StepTitle={content.add_ipr_before_step}
              AmountTitle={content.ipr_amount}
              hyberText={content.add_ipr_step}
              content={content}
              useIPR={use_ipr}
            />
          </div>

          <div className="actions-grid">
            <div
              className={`${
                this.checkChanges() || this.checkIPRData()
                  ? "event-none save-movement"
                  : "save-movement-active"
              } h-100 float-right`}
              disabled={this.checkChanges() || this.checkIPRData()}
              onClick={() => {
                this.saveIPRData();
              }}
            >
              {content.save}
            </div>

            <div
              className={`save-movement h-100 float-right text-center`}
              onClick={() => {
                this.props.cancelChangesOnIPR();
              }}
            >
              {content.cancel}
            </div>
          </div>
        </div>{" "}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IPRDetailes);
