import React, { Component } from "react";
import { connect } from "react-redux";
import CustomeHeader from "../../../components/header/CustomeHeader";
import { SetHybridState } from "../../../redux/hybridTreatment/action"
import {
    Button,
    Input
} from "reactstrap";
import check from "../../../assets/images/icon/check.svg";

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({

    SetHybridState: (payload) => dispatch(SetHybridState(payload))
});
class Summary extends Component {
    /*--------------------------------------------------------------------------------*/
    /*constructor */
    /*--------------------------------------------------------------------------------*/
    constructor(props) {
        super(props);
        /*init state */
        this.state = {
            hybrid_treatment_type: this.props.data.hybrid_treatment_type,
            arch: this.props.data.arch,
            keep_protocols: this.props.data.keep_protocols,
            new_protocols: this.props.data.new_protocols,
            show_submitted_successfully: this.props.data.show_submitted_successfully

        };
    }

    SubmitRevision = () => {
        this.props.convert_to_hybrid()
    }
    render() {
        const { content } = this.props.languageReducer;
        const { hybrid_treatment_type, arch, keep_protocols, new_protocols, show_submitted_successfully } = this.state;


        return (
            <div className="">
                {/*--------------------------------------------------------------------------------*/}
                {/* Hybrid Treatment Summary */}
                {/*--------------------------------------------------------------------------------*/}
                {
                    !this.props.show_submitted_successfully &&
                    <div>

                        <div className="close-hybrid-treatment">
                            <CustomeHeader title={content.hybrid_treatment_Summary} small={true} />
                            <span className={` pull-right ts-close-button-new `} onClick={() => {
                                this.props.ResetTabs()
                                this.props.SetHybridState(false)
                            }}>&times;</span>
                        </div>
                        <div className="cmt4 hybrid-treatment-container_summary">

                            <div>
                                <span className="hybrid_both_types_title"> {content.hybrid_type} :</span>
                                <span className="cpl4">{hybrid_treatment_type}</span>

                            </div>
                            <div className="cmt2">
                                <span className="hybrid_both_types_title"> {content.arch} :</span>
                                <span className="cpl4">{arch}</span>

                            </div>
                            <div className="cmt2">
                                <span className="hybrid_both_types_title"> {content.keep_treatment_protocols}: </span>
                                <span className="cpl4">{keep_protocols}</span>

                            </div>

                            {
                                keep_protocols === "No" && <div className="cmt2">
                                    <div className="hybrid_both_types_title">{content.new_treatment_protocols}</div>
                                    <Input
                                        className="custome-textarea"
                                        name="new_protocols"
                                        id="new_protocols"
                                        type="textarea"
                                        placeholder={content.typeHere}
                                        rows={5}
                                        defaultValue={new_protocols}
                                        disabled
                                    />
                                </div>

                            }
                        </div>

                        <div className="">
                            <div className="both_type_note">{content.by_submitting_this_request}</div>
                            <div>
                                <hr></hr>
                                <Button
                                    className={`btn  white-btn-ts button-seme-bold-text`}
                                    size="sm"
                                    type="button"
                                    disabled={this.props.data.sending_data}
                                    onClick={() => {
                                        this.props.back()
                                    }}
                                ><i className="fas fa-angle-left  pr-1"></i>
                                    {content.back}</Button>
                                <Button
                                    className={`btn  green-green-btn pull-right button-seme-bold-text`}
                                    size="sm"
                                    type="button"
                                    disabled={this.props.data.sending_data}
                                    onClick={() => {
                                        this.SubmitRevision()
                                    }}
                                >
                                    {content.submit_request} <i className="fas fa-angle-right pl-1"></i>
                                </Button>
                            </div>

                        </div>


                    </div>
                }


                {this.props.show_submitted_successfully && <div className="">
                    <div className="close-hybrid-treatment">
                        <span className={` pull-right ts-close-button-new `} onClick={() => { this.props.SetHybridState(false) }}>&times;</span>
                    </div>
                    <div className="revision_submitted_successfully">
                        <div>
                            <img src={check} alt="case-submitted" />
                        </div>
                        <div className="hybrid_submitted_successfully">{content.revised_data_successfully}</div>
                    </div>
                    <div className="">

                        <hr></hr>

                        <Button
                            className={`btn  green-green-btn pull-right  exit-button button-seme-bold-text`}
                            size="sm"
                            type="button"
                            onClick={() => {
                                this.props.toggleViewerModal()
                                // this.props.SetHybridState(false)
                            }}
                        >
                            {content.exit}
                        </Button>


                    </div>




                </div>}



                {/*--------------------------------------------------------------------------------*/}
                {/* End Hybrid Treatment Summary*/}
                {/*--------------------------------------------------------------------------------*/}
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Summary);
