import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input } from "reactstrap";
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class PassiveAligner extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_passive_aligners: this.props.caseSubmission.advanced_modules
        .passiveAligners.usePassiveAligners,
      passive_aligners_details: this.props.caseSubmission.advanced_modules
        .passiveAligners.details,
      add_passive_aligners_at_the_end: this.props.caseSubmission.advanced_modules
        .passiveAligners.add_passive_aligners_at_the_end,
      add_passive_aligners_at_the_beginning: this.props.caseSubmission.advanced_modules
        .passiveAligners.add_passive_aligners_at_the_beginning,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "passiveAligners",
      data: {
        usePassiveAligners: this.state.use_passive_aligners,
        add_passive_aligners_at_the_end: this.state.add_passive_aligners_at_the_end,
        add_passive_aligners_at_the_beginning: this.state.add_passive_aligners_at_the_beginning,
        details: this.state.passive_aligners_details,
      },
    });
    this.props.save();
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { use_passive_aligners,
      passive_aligners_details } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>
        {/* <div className="form-group content form-block-holder grid_container "> */}
        <div className="templete_module_grid">
          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.use_passive_aligners_for_this_case}{"? "}
            </div>

            <TreatmentOptions content={content} value={use_passive_aligners} name="use_passive_aligners" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_passive_aligners")
              if (value !== true) {
                this.setState({
                  passive_aligners_details: "",
                  add_passive_aligners_at_the_end: false,
                  add_passive_aligners_at_the_beginning: false,
                })
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>
          <div className="divider_module-middle">          </div>
          <div id="content_container_3"
            className={`${use_passive_aligners !== true ? "c-content-container8" : "c-content-container "} tooth-extraction  cpb2 `}>
            <div className="passive_aligners_grid">
              <div className="radio-item"
         style={{ pointerEvents: this.props.summary ? "none" : "" }}>
                <input type="radio"
                  id='Add_passive_aligners_at_the_beginning'
                  name='add_passive_aligners'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  disabled={ this.props.summary}
                  checked={this.state.add_passive_aligners_at_the_beginning}
                  value={"Add_passive_aligners_at_the_beginning"}
              
                  onChange={() => {
                    this.setState({
                      add_passive_aligners_at_the_end: false,
                      add_passive_aligners_at_the_beginning: true,
                    });
                  }} />

                <label htmlFor='Add_passive_aligners_at_the_beginning' className="button-text">{content.add_passive_aligners_at_the_beginning}</label>
              </div>
              <div className="radio-item" 
               style={{ pointerEvents: this.props.summary ? "none ": "" }}>
                <input type="radio"
                  id='Add_passive_aligners_at_the_end'
                  checked={this.state.add_passive_aligners_at_the_end}
                  name='add_passive_aligners'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  disabled={ this.props.summary}
          
                  value={"Add_passive_aligners_at_the_end"}
                  onChange={() => {
                    this.setState({
                      add_passive_aligners_at_the_end: true,
                      add_passive_aligners_at_the_beginning: false,
                    });
                  }}
                />
                <label htmlFor='Add_passive_aligners_at_the_end' className="button-text">{content.add_passive_aligners_at_the_end}</label>
              </div>
              <div></div>
            </div>
            <br />
            <div>
              {" "}
              <p className="control-label text-left  light-green button-text title-3-text">{content.additional_comments}</p>
              <Input
                className=" border-radius backgrountTextarea  custome-input-text"
                name="passive_aligners_details"
                id="passive_aligners_details"
                type="textarea"
                placeholder={content.typeHere}
                rows={5}
                value={passive_aligners_details}
                onChange={(e) => {
                  this.setState({ passive_aligners_details: e.target.value });
                }}
                disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide" || this.props.summary}
              />{" "}
            </div>
            <div className={`${(this.state.use_passive_aligners === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_passive_aligners === "eonToDecide") ? `Eon_to_recommend_eon Eon_to_recommend_layout` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>
              <p className={`${(this.state.use_passive_aligners === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}> {content.Passive_aligner_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.use_passive_aligners === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
            </div>
            {!this.props.summary&& this.state.use_passive_aligners ===true && <div className="btn-adv-mobile margin-top2 displayNone_inmobile"><Button
          className=" mt-2   mobile-but-adv light-green-btn float-right   button-seme-bold-text"
          size="lg"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          {content.save}
        </Button></div>}

          </div>
        </div>
        {!this.props.summary  &&  !(this.state.use_passive_aligners ===true && window.innerWidth <839  ) && <div className="btn-adv-mobile"><Button
          className=" mt-2   mobile-but-adv light-green-btn float-right   button-seme-bold-text "
          size="lg"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          {content.save}
        </Button></div>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PassiveAligner);
