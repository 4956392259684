import React, { Component } from "react";
import { connect } from "react-redux";
import { getDoctorInfo, UpdateDoctorInfo } from "../../api/api";
import { uploadFile, keyToUrl } from "../../helpers/s3";
import { successToaster } from "../../services/toast";
import { Button } from "reactstrap";
import CustomRadioGroup from "../../components/radioGroup/CustomRadioGroup";
import { setUserInfo } from "../../redux/user/action";
import { UpdateLanguage, UpdateContent } from "../../redux/language/action";
import male from "../../assets/images/icon/avatar-male.svg";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setUserInfo: (payload) => dispatch(setUserInfo(payload)),
  UpdateLanguage: (payload) => dispatch(UpdateLanguage(payload)),
  UpdateContent: (payload) => dispatch(UpdateContent(payload)),
});
class DoctorSettings extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      id: props.userReducer.currentUser.id,
      doctor: {},
      addresses: [],
      address: {},
      modal: false,
      modal2: false,
      currentUser: props.userReducer.currentUser,
      obj: {},
      subscribe: false,
      doctor_image_value: "",
      doctor_image: false,
      doctor_photo: "",
      country: "",
      city: "",
      sendingData: false,
      edited: false,
      edit_name: false,
      edit_phone: false,
      edit_language: false,
      edit_date_format: false,
      edit_dental_notation: false,
      edit_email_notifications: false,

      doctor_data: {
        first_name: "",
        last_name: "",
        phone: "",
        language: "",
        date_format: "",
        dental_notation: "",
        email_notifications: "",
      },
    };
  }

  /* --------------------------------------------------------------------------------*/
  /* get doctor info , get doctor address */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    this.GetDoctorInfo();
  }
  checkIfMatch = (link) => {
    if (link?.match(/http([^&]+)/)) {
      return false;
    } else {
      return true;
    }
  };

  /* --------------------------------------------------------------------------------*/
  /* get doctor info  */
  /* --------------------------------------------------------------------------------*/
  GetDoctorInfo = () => {
    getDoctorInfo(this.props.userReducer.currentUser.id).then((res) => {
      let all_doctor_data = res;
 
      all_doctor_data["language"] = res.preferred_language || "en";
      all_doctor_data["date_format"] =
        res.preferred_date_format || "DD/MM/YYYY";
      all_doctor_data["dental_notation"] =
        res.preferred_dental_notation || "fdi";
      all_doctor_data["email_notifications"] = res.subscribe
        ? "subscribe"
        : "unsubscribe";

      this.setState({
        doctor: all_doctor_data,
        subscribe: res.subscribe,
        doctor_image_value: !this.checkIfMatch( res.avatar)? res.avatar : male,
        doctor_image: false,
        doctor_photo: !this.checkIfMatch( res.avatar) ? res.avatar : male,

        doctor_data: {
          first_name: res.first_name,
          last_name: res.last_name,
          phone: res.phone,
          language: res.preferred_language || "en",
          date_format: res.preferred_date_format || "DD/MM/YYYY",
          dental_notation: res.preferred_dental_notation || "fdi",
          email_notifications: res.subscribe ? "subscribe" : "unsubscribe",
        },
        edited: false,
      });

      localStorage.setItem("language", res.preferred_language || "en");
      this.props.UpdateLanguage(res.preferred_language || "en");
      this.props.UpdateContent(res.preferred_language || "en");
    });
  };

  /* --------------------------------------------------------------------------------*/
  /*  upload Image  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id) => {
    let extention = image.name.split(".");

    return uploadFile({
      name: `doctor-photo/${id}/${new Date().valueOf()}.${
        image.name.split(".")[extention.length - 1]
      }`,
      contentType: image.type,
      file: image,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  update profile photo  */
  /* --------------------------------------------------------------------------------*/
  ProfilePhotoOnUpload = async (e, id) => {
    e.persist();

    this.setState({ loading1: true });
    if (e.target.files[0]) {
      let reader = new FileReader();
      reader.addEventListener("load", (evt) => {
        this.setState({
          doctor_photo: evt.currentTarget.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);

      const file = e.target ? e.target.files[0] : e.files[0];
      const { key } = await this.uploadImage(file, id);

      this.setState({
        doctor_image_value: keyToUrl(key),
        doctor_image: false,
        loading1: false,
        edited: true,
      });

      // const data = {
      //     doctor: {
      //         first_name: this.state.doctor.first_name,
      //         middle_name: this.state.doctor.middle_name,
      //         last_name: this.state.doctor.last_name,
      //         phone: this.state.doctor.phone,
      //         subscribe: this.state.subscribe,
      //         avatar: keyToUrl(key),
      //     },
      // };

      // this.UpdateUser();
    }
  };
  /* --------------------------------------------------------------------------------*/
  /*  update doctor data  */
  /* --------------------------------------------------------------------------------*/
  UpdateUser = () => {
    const { content } = this.props.languageReducer;
    const { doctor_data } = this.state;
    this.setState({ edited: false });
    const data = {
      doctor: {
        first_name: doctor_data.first_name,
        last_name: doctor_data.last_name,
        phone: doctor_data.phone,
        subscribe:
          doctor_data.email_notifications === "subscribe" ? true : false,
        ...(this.state.doctor_image_value.match(/http([^&]+)/) && {
          avatar: this.state.doctor_image_value,
        }),
        preferred_language: doctor_data.language,
        preferred_date_format: doctor_data.date_format,
        preferred_dental_notation: doctor_data.dental_notation,
        finished_onboarding: this.state.doctor.finished_onboarding,
      },
    };
    const currentUser = {
      ...this.props.userReducer.currentUser,
      ...(this.state.doctor_image_value.match(/http([^&]+)/) && {
        avatar: this.state.doctor_image_value,
      }),
      email: this.props.userReducer.currentUser.email,
      first_name: doctor_data.first_name,
      full_name: `${doctor_data.first_name} ${doctor_data.last_name}`,
      id: this.props.userReducer.currentUser.id,
      last_name: doctor_data.last_name,
      language: doctor_data.language,
      date_format: doctor_data.date_format,
      dental_notation: doctor_data.dental_notation,
      email_notifications: doctor_data.email_notifications,
    };

    UpdateDoctorInfo(this.state.id, JSON.stringify(data))
      .then((res) => {
        this.GetDoctorInfo();
        this.props.setUserInfo(currentUser);
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
        successToaster(content.updated_user_info_successfully, content.Success);
        /****************change lang ****************/
        localStorage.setItem("language", doctor_data.language);
        this.props.UpdateLanguage(doctor_data.language);
        this.props.UpdateContent(doctor_data.language);
        /********************************************/
        //this.reset_data()
      })
      .catch((error) => {
        this.GetDoctorInfo();
      });
  };

  /* --------------------------------------------------------------------------------*/
  /*  toggle Subscribe  */
  /* --------------------------------------------------------------------------------*/
  toggleSubscribe = () => {
    this.setState({
      subscribe: !this.state.subscribe,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  change data value  */
  /* --------------------------------------------------------------------------------*/
  change_data_value = (key, value) => {
    const { doctor_data } = this.state;
    this.setState({
      doctor_data: {
        ...doctor_data,
        [key]: value,
      },
      edited: true,
    });
  };

  reset_data = () => {
    const { doctor } = this.state;
    this.setState({
      doctor_data: {
        first_name: doctor.first_name,
        last_name: doctor.last_name,
        phone: doctor.phone,
        language: doctor.language || "en",
        date_format: doctor.date_format || "DD/MM/YYYY",
        dental_notation: doctor.dental_notation || "fdi",
        email_notifications: doctor.email_notifications || "subscribe",
      },
      doctor: {
        ...this.state.doctor,
        avatar: doctor.avatar,
      },
      doctor_photo: "",
      edited: false,
      edit_name: false,
      edit_phone: false,
      edit_language: false,
      edit_date_format: false,
      edit_dental_notation: false,
      edit_email_notifications: false,
    });
  };

  render() {
    const { content } = this.props.languageReducer;
    const { doctor } = this.state;
    const {
      edited,
      edit_name,
      edit_phone,
      edit_language,
      edit_date_format,
      edit_dental_notation,
      edit_email_notifications,
      doctor_data,
    } = this.state;
    const languages = [
      { name: "en", title: content.en },
      { name: "fr", title: content.fr },
    ];
    const date_format = [
      { name: "MM/DD/YYYY", title: "MM/DD/YYYY" },
      { name: "DD/MM/YYYY", title: "DD/MM/YYYY" },
    ];

    const dental_notation = [
      { name: "universal", title: content.universal },
      { name: "palmer", title: content.palmer },
      { name: "fdi", title: content.fdi_new },
    ];

    const email_notifications = [
      { name: "subscribe", title: content.subscribe },
      { name: "unsubscribe", title: content.unsubscribe },
    ];

    return (
      <div>
        <div className="general-settings-grid new-general-settings-grid">
          <div className="cmb5">
            <div className="profile-img-grid cmt3">
              <input
                type="file"
                id="doctor_photo"
                onChange={(e) => this.ProfilePhotoOnUpload(e, "doctor_photo")}
                multiple={false}
                className="visually-hidden"
                accept="image/x-png,image/gif,image/jpeg"
              />

              <label htmlFor="doctor_photo" className="c-pointer">
                <img
                  className="avatar"
                  src={
                    this.state.doctor_photo
                      ? this.state.doctor_photo
                      : this.state.doctor.avatar
                      ? this.state.doctor.avatar.match(/http([^&]+)/)
                        ? this.state.doctor.avatar
                        : male
                      : male
                  }
                
                    
                
                  alt={`${this.state.doctor.full_name}`}
                />
              </label>

              {/* <img src={doctor.avatar} className="avatar" /> */}
              <div></div>
              <label
                htmlFor="doctor_photo"
                className="custom-title c-pointer text-center cmt2 m-auto body-text"
              >
                {content.change_image}
              </label>
            </div>

            <hr className="new-hr"></hr>
            <div className="setting-data-grid">
              <div className="setting-text body-text">{content.name}</div>
              <div className="body-text">{doctor.full_name}</div> 
              {/* {!edit_name && (
                <div
                  className="text-center setting-text c-pointer m-auto body-text"
                  onClick={() => {first_name
                    this.setState({
                      edit_name: true,
                    });
                  }}
                >
                  {content.edit}
                </div>
              )} */}
            </div>
            {edit_name && (
              <div className="setting-values-grid cmt2">
                <div>
                  <div className="setting-text cmb1 body-text">{content.first_name}</div>
                  <input
                    type="text"
                    className="custome-input-text form-control"
                    value={doctor_data.first_name}
                    onChange={(e) => {
                      this.change_data_value("first_name", e.target.value);
                    }}
                  />
                </div>
                <div>
                  <div className="setting-text cmb1 body-text">{content.last_name}</div>
                  <input
                    type="text"
                    className="custome-input-text form-control"
                    value={doctor_data.last_name}
                    onChange={(e) => {
                      this.change_data_value("last_name", e.target.value);
                    }}
                  />
                </div>
                <div></div>
              </div>
            )}
            <hr className="new-hr"></hr>
            <div className="setting-data-grid">
              <div className="setting-text body-text">{content.email}</div>
              <div className="body-text">{doctor.email}</div>
              <div></div>
            </div>
            <hr className="new-hr"></hr>
            <div className="setting-data-grid">
              <div className="setting-text body-text">{content.phone_number}</div>
              <div className="body-text">{doctor.phone}</div>
              {!edit_phone && (
                <div
                  className="text-center setting-text c-pointer m-auto body-text"
                  onClick={() => {
                    this.setState({
                      edit_phone: true,
                    });
                  }}
                >
                  {content.edit}
                </div>
              )}
            </div>
            {edit_phone && (
              <div className="setting-values-grid cmt1">
                <div>
                  <input
                    type="text"
                    className="custome-input-text form-control custome-input-text"
                    value={doctor_data.phone}
                    onChange={(e) => {
                      this.change_data_value("phone", e.target.value);
                    }}
                  />
                </div>
                <div></div>
                <div></div>
              </div>
            )}
            <hr className="new-hr"></hr>
            <div className="setting-data-grid">
              <div className="setting-text body-text">{content.language}</div>
              <div className="body-text">{content[doctor.language]}</div>
              {!edit_language && (    
                <div
                  className="text-center setting-text c-pointer m-auto body-text"
                  onClick={() => {
                    this.setState({
                      edit_language: true,
                    });
                  }}
                >
                  {content.edit}
                </div>
              )}
            </div>
            {edit_language && (
              <div className="setting-values-grid cmt1">
                <div>
                  <CustomRadioGroup
                    name="language"
                    checked={doctor_data.language}
                    onChange={(data) => {
                      this.change_data_value("language", data);
                    }}
                    options={languages}
                  />
                </div>
                <div></div>
                <div></div>
              </div>
            )}
            <hr className="new-hr"></hr>
            <div className="setting-data-grid">
              <div className="setting-text body-text">{content.date_format}</div>
              <div className="body-text">{doctor.date_format}</div>
              {!edit_date_format && (
                <div
                  className="text-center setting-text c-pointer m-auto body-text"
                  onClick={() => {
                    this.setState({
                      edit_date_format: true,
                    });
                  }}
                >
                  {content.edit}
                </div>
              )}
            </div>
            {edit_date_format && (
              <div className="setting-values-grid cmt1">
                <div>
                  <CustomRadioGroup
                    name="date_format"
                    checked={doctor_data.date_format}
                    onChange={(data) => {
                      this.change_data_value("date_format", data);
                    }}
                    options={date_format}
                  />
                </div>
                <div></div>
                <div></div>
              </div>
            )}
            <hr className="new-hr"></hr>
            <div className="setting-data-grid">
              <div className="setting-text body-text">{content.dental_notation}</div>
              <div className="body-text">{content[doctor.dental_notation]}</div>
              {!edit_dental_notation && (
                <div
                  className="text-center setting-text c-pointer m-auto body-text"
                  onClick={() => {
                    this.setState({
                      edit_dental_notation: true,
                    });
                  }}
                >
                  {content.edit}
                </div>
              )}
            </div>
            {edit_dental_notation && (
              <div className="setting-values-grid cmt1">
                <div>
                  <CustomRadioGroup
                    name="dental_notation"
                    checked={doctor_data.dental_notation}
                    onChange={(data) => {
                      this.change_data_value("dental_notation", data);
                    }}
                    options={dental_notation}
                  />
                </div>
                <div></div>
                <div></div>
              </div>
            )}
            <hr className="new-hr"></hr>
            <div className="setting-data-grid">
              <div className="setting-text body-text">{content.email_notifications}</div>
              <div className="body-text">{content[doctor.email_notifications]}</div>
              {!edit_email_notifications && (
                <div
                  className="text-center setting-text c-pointer m-auto body-text"
                  onClick={() => {
                    this.setState({
                      edit_email_notifications: true,
                    });
                  }}
                >
                  {content.edit}
                </div>
              )}
            </div>
            {edit_email_notifications && (
              <div className="setting-values-grid cmt1">
                <div>
                  <CustomRadioGroup
                    name="email_notifications"
                    checked={doctor_data.email_notifications}
                    onChange={(data) => {
                      this.change_data_value("email_notifications", data);
                    }}
                    options={email_notifications}
                    vertical={true}
                  />
                </div>
                <div></div>
                <div></div>
              </div>
            )}
            {
               window.innerWidth > 839 &&
               <hr className="new-hr"></hr>
            }
           
          </div>
          <div className="settings-buttons-grid new-settings-buttons-grid">
          <Button
              className="btn light-green-btn w-100 new-btn-height advanceModuleInmobile button-seme-bold-text"
              size="sm"
              disabled={!edited}
              onClick={() => this.UpdateUser()}
            >
              {content.save}
            </Button> 

            <Button
              className="btn white-btn  w-100 new-btn-height button-seme-bold-text"
              size="sm"
              disabled={!edited}
              onClick={() => {
                this.reset_data();
              }}
            >
              {" "}
              {content.cancel}
            </Button>
            <Button
              className="btn light-green-btn w-100 new-btn-height button-seme-bold-text advanceModuleInWebsite"
              size="sm"
              disabled={!edited}
              onClick={() => this.UpdateUser()}
            >
              {content.save}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DoctorSettings);
