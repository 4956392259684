import { UNSAVED_CHANGES, UNSAVED_DATA, UNSAVED_KEYS, RESET_CHANGES, FILTER_DATA } from "../constants/";

const INIT_STATE = {
  dataChanged: false,
  data: {},
  keys: {},
  filters: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UNSAVED_CHANGES:
      return {
        ...state,
        dataChanged: action.payload,
      };
    case UNSAVED_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case UNSAVED_KEYS:
      return {
        ...state,
        keys: action.payload,
      };

    case RESET_CHANGES:
      return {
        ...state,
        dataChanged: false,
        data: {},
        keys: {},
      };

    case FILTER_DATA:
      return {
        ...state,
        filters: action.payload
      };
    default:
      return state;
  }
};
