import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectContent } from "../../redux/language/reducer";
import { selectDoctorId } from "../../redux/user/reducer";
import { createDoctorPreferences, getDoctorPreferences } from "../../api/api";
import { preferenceSchema } from "../../helpers/preferenceValidation";

import { Form, Formik } from "formik";
import { Button, Input, Spinner } from "reactstrap";
import { NumbersChipsCombobox } from "../../components/combobox/NumbersChipsCombobox";
import { RadioGroupTailwind } from "../../components/radioGroup/RadioGroupTailwind";
import { ConfirmPopup } from "./ConfirmPopup";
import { errorToaster, successToaster } from "../../services/toast";
import { doctor_preferences_mapper } from "../../services/newDataMapper";

export const DoctorPreferences = ({
  handleFormChange = () => {},
  modalStyling,
  ...props
}) => {
  const [pop_up, setPopUp] = useState(false);
  const [preferences, setPreferences] = useState({});
  const [creatingNewPreferences, setCreatingNewPreferences] = useState(false);
  const [loading, setLoading] = useState(true);

  const content = useSelector(selectContent);
  const doctorId = useSelector(selectDoctorId);

  const initialFormikValues = {
    use_ipr: preferences?.use_ipr || "",
    ipr_steps: preferences?.ipr_steps || [],
    use_attachments: preferences?.use_attachments || "",
    attachments_steps: preferences?.attachments_steps || [],
    passive_aligners: preferences?.passive_aligners || "",
    pontics: preferences?.pontics || "",
    overcorrection: preferences?.overcorrection || "",
    other_preferences: preferences?.other_preferences || "",
    version: 2,
  };

  const handlePreferenceSubmit = (model, errors) => {
    if (Object.keys(errors).length > 0) {
      return errorToaster(content.please_check_missing_fields, "Warning");
    }
    setCreatingNewPreferences(true);
    handlePopUpModal();
    createDoctorPreferences(doctorId, model)
      .then(() => {
        setCreatingNewPreferences(false);
        successToaster(
          content.update_doctor_preferences_successfully,
          content.Success
        );
      })
      .catch((e) => {
        setCreatingNewPreferences(false);
        errorToaster(content.update_error, "Warning");
      });
  };

  const fetchDoctorPreferences = () => {
    setLoading(true);
    getDoctorPreferences(doctorId)
      .then((res) => {
        if (res.length) {
          let filtered_doctor_preferences = res.filter((value, index) => {
            return value.current === true;
          })?.[0]?.preferences;

          // handles old preferences and converts them to using the new one.
          if (filtered_doctor_preferences) {
            filtered_doctor_preferences = {
              ...doctor_preferences_mapper(filtered_doctor_preferences),
            };

            filtered_doctor_preferences &&
              filtered_doctor_preferences.version === 2 &&
              setPreferences(filtered_doctor_preferences);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const handlePopUpModal = () => {
    setPopUp(!pop_up);
  };

  useEffect(() => {
    fetchDoctorPreferences();
  }, []);

  if (loading)
    return (
      <div className="tw-ea-items-center tw-ea-m-auto tw-ea-flex tw-ea-p-3">
        <Spinner className="green-spinner" type="grow" />
      </div>
    );

  if (modalStyling)
    return (
      <div className="tw-ea-mt-5 tw-ea-text-[#2E2E2F]">
        <Formik
          initialValues={initialFormikValues}
          onSubmit={handlePreferenceSubmit}
          validationSchema={preferenceSchema(content)}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form
              onSubmitCapture={(e) => e.preventDefault()}
              onSubmit={(e) => e.preventDefault()}
              className="tw-ea-flex tw-ea-flex-col tw-ea-gap-10"
            >
              <div className="tw-ea-pb-6 tw-ea-border-b-[1px] tw-ea-border-solid tw-ea-border-b-[#C8C8CA] tw-ea-border-0 tw-ea-gap-3 lg:tw-ea-gap-[92px]">
                <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row tw-ea-px-1 tw-ea-gap-4">
                  <div className="tw-ea-flex-1 tw-ea-font-semibold tw-ea-text-sm tw-ea-min-w-[231px] tw-ea-text-[12px]">
                    <span className="tw-ea-text-base">
                      {content.do_you_want_to_use_IPR}
                    </span>
                    <RadioGroupTailwind
                      name="use_ipr"
                      id="use_ipr"
                      onChange={(valueArr) => {
                        setFieldValue("use_ipr", valueArr);
                        handleFormChange("use_ipr", valueArr);
                      }}
                      options={yes_no_options(content)}
                      className={
                        "tw-ea-whitespace-nowrap tw-ea-flex tw-ea-flex-col tw-ea-gap-4 tw-ea-mt-4"
                      }
                      checked={values.use_ipr || preferences.use_ipr}
                    />
                    {errors.use_ipr && touched.use_ipr && (
                      <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                        {errors.use_ipr}
                      </div>
                    )}
                  </div>
                  <div className="tw-ea-flex-[2] tw-ea-font-semibold tw-ea-text-xs tw-ea-min-w-[231px]">
                    {content.ipr_preferred_steps}
                    <NumbersChipsCombobox
                      onChange={(valueArr) => {
                        setFieldValue("ipr_steps", valueArr);
                        handleFormChange("ipr_steps", valueArr);
                      }}
                      className="tw-ea-max-w-xs tw-ea-mt-2"
                      value={values.ipr_steps || preferences.ipr_steps}
                      disabled={values.use_ipr === "no"}
                    />
                    {errors.ipr_steps && touched.ipr_steps && (
                      <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                        {errors.ipr_steps}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="tw-ea-pb-6 tw-ea-border-b-[1px] tw-ea-border-solid tw-ea-border-b-[#C8C8CA] tw-ea-border-0 tw-ea-gap-3 lg:tw-ea-gap-[92px]">
                <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row tw-ea-px-1 tw-ea-gap-4">
                  <div className="tw-ea-flex-1 tw-ea-font-semibold tw-ea-text-sm tw-ea-min-w-[231px] tw-ea-text-[12px] ">
                    <span className="tw-ea-text-base">
                      {content.do_you_want_to_use_attachments}
                    </span>
                    <RadioGroupTailwind
                      name="use_attachments"
                      id="use_attachments"
                      onChange={(valueArr) => {
                        setFieldValue("use_attachments", valueArr);
                        handleFormChange("use_attachments", valueArr);
                      }}
                      options={yes_no_options(content)}
                      className={
                        "tw-ea-text-[12px] tw-ea-whitespace-nowrap tw-ea-flex tw-ea-flex-row md:tw-ea-flex-col tw-ea-gap-4 tw-ea-mt-4"
                      }
                      checked={
                        values.use_attachments || preferences.use_attachments
                      }
                    />
                    {errors.use_attachments && touched.use_attachments && (
                      <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                        {errors.use_attachments}
                      </div>
                    )}
                  </div>
                  <div className="tw-ea-flex-[2] tw-ea-font-semibold tw-ea-text-xs tw-ea-min-w-[231px]">
                    {content.attachments_preferred_steps}:
                    <NumbersChipsCombobox
                      onChange={(valueArr) => {
                        setFieldValue("attachments_steps", valueArr);
                        handleFormChange("attachments_steps", valueArr);
                      }}
                      className="tw-ea-max-w-xs tw-ea-mt-2"
                      value={
                        values.attachments_steps ||
                        preferences.attachments_steps
                      }
                      disabled={values.use_attachments === "no"}
                    />
                    {errors.attachments_steps && touched.attachments_steps && (
                      <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                        {errors.attachments_steps}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="tw-ea-pb-6 tw-ea-flex tw-ea-flex-col">
                <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row tw-ea-px-1 tw-ea-gap-4 tw-ea-justify-between">
                  <div className="tw-ea-text-[16px] tw-ea-flex-1 tw-ea-font-semibold">
                    {content.use_passive_aligners}:
                    <RadioGroupTailwind
                      name="passive_aligners"
                      id="passive_aligners"
                      onChange={(valueArr) => {
                        setFieldValue("passive_aligners", valueArr);
                        handleFormChange("passive_aligners", valueArr);
                      }}
                      options={yes_no_options(content)}
                      className={
                        "tw-ea-text-[12px] tw-ea-whitespace-nowrap tw-ea-mt-4 tw-ea-flex tw-ea-flex-row md:tw-ea-flex-col tw-ea-gap-4"
                      }
                      checked={
                        values.passive_aligners || preferences.passive_aligners
                      }
                    />
                    {errors.passive_aligners && touched.passive_aligners && (
                      <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                        {errors.passive_aligners}
                      </div>
                    )}
                  </div>
                  <div className="tw-ea-text-[16px] tw-ea-flex-1 tw-ea-font-semibold">
                    {content.use_pontics}:
                    <RadioGroupTailwind
                      name="pontics"
                      id="pontics"
                      onChange={(valueArr) => {
                        setFieldValue("pontics", valueArr);
                        handleFormChange("pontics", valueArr);
                      }}
                      options={yes_no_options(content)}
                      className={
                        "tw-ea-text-[12px] tw-ea-whitespace-nowrap tw-ea-mt-4 tw-ea-flex tw-ea-flex-row md:tw-ea-flex-col tw-ea-gap-4"
                      }
                      checked={values.pontics || preferences.pontics}
                    />
                    {errors.pontics && touched.pontics && (
                      <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                        {errors.pontics}
                      </div>
                    )}
                  </div>
                  <div className="tw-ea-text-[16px] tw-ea-flex-1 tw-ea-font-semibold">
                    {content.use_overcorrection}:
                    <RadioGroupTailwind
                      name="overcorrection"
                      id="overcorrection"
                      onChange={(valueArr) => {
                        setFieldValue("overcorrection", valueArr);
                        handleFormChange("overcorrection", valueArr);
                      }}
                      options={yes_no_options(content)}
                      className={
                        "tw-ea-text-[12px] tw-ea-whitespace-nowrap tw-ea-mt-4 tw-ea-flex tw-ea-flex-row md:tw-ea-flex-col tw-ea-gap-4"
                      }
                      checked={
                        values.overcorrection || preferences.overcorrection
                      }
                    />
                    {errors.overcorrection && touched.overcorrection && (
                      <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                        {errors.overcorrection}
                      </div>
                    )}
                  </div>
                </div>
                <div className="tw-ea-font-semibold tw-ea-text-sm tw-ea-mt-6">
                  {content.other_preferences}
                  <Input
                    className="custome-textarea  custome-input-text !tw-ea-max-w-none tw-ea-mt-3"
                    name="other_preferences"
                    id="other_preferences"
                    type="textarea"
                    rows={5}
                    onChange={(e) => {
                      setFieldValue("other_preferences", e.target.value);
                      handleFormChange("other_preferences", e.target.value);
                    }}
                    defaultValue={
                      values.other_preferences ||
                      preferences.other_preferences ||
                      ""
                    }
                  />
                  {errors.other_preferences && touched.other_preferences && (
                    <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                      {errors.other_preferences}
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );

  return (
    <div className="tw-ea-mt-10">
      <div className="tw-ea-text-[#2E2E2F] tw-ea-grid tw-ea-grid-cols-[1fr_0fr] md:tw-ea-grid-cols-[2fr_1fr] tw-ea-max-h-[calc(100vh-250px)] tw-ea-overflow-auto">
        <Formik
          initialValues={initialFormikValues}
          onSubmit={handlePreferenceSubmit}
          validationSchema={preferenceSchema(content)}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form
              onSubmitCapture={(e) => e.preventDefault()}
              onSubmit={(e) => e.preventDefault()}
              className="tw-ea-flex tw-ea-flex-col tw-ea-gap-10"
            >
              <div className="tw-ea-pb-6 tw-ea-border-b-[1px] tw-ea-border-solid tw-ea-border-b-[#C8C8CA] tw-ea-border-0 tw-ea-flex tw-ea-flex-col lg:tw-ea-flex-row tw-ea-gap-3 lg:tw-ea-gap-[92px]">
                <div className="tw-ea-font-semibold tw-ea-text-lg tw-ea-min-w-[170px]">
                  {content.ipr}
                </div>
                <div className="tw-ea-flex-1">
                  <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row md:tw-ea-gap-20 tw-ea-pb-5 tw-ea-gap-5 tw-ea-border-b-[1px] tw-ea-border-solid tw-ea-border-b-[#C8C8CA] tw-ea-border-0">
                    <div className="tw-ea-font-semibold tw-ea-text-sm tw-ea-w-[170px]">
                      {content.do_you_want_to_use_IPR}
                    </div>
                    <div>
                      <div className="tw-ea-text-[10px] tw-ea-text-[#898A8D] tw-ea-font-bold tw-ea-mb-2">
                        {content.choose}:
                      </div>
                      <RadioGroupTailwind
                        name="use_ipr"
                        id="use_ipr"
                        onChange={(valueArr) =>
                          setFieldValue("use_ipr", valueArr)
                        }
                        options={yes_no_options(content)}
                        className={
                          "tw-ea-text-[12px] tw-ea-whitespace-nowrap tw-ea-flex tw-ea-flex-row md:tw-ea-flex-col tw-ea-gap-4"
                        }
                        checked={values.use_ipr || preferences.use_ipr}
                      />
                      {errors.use_ipr && touched.use_ipr && (
                        <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                          {errors.use_ipr}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row tw-ea-py-5 tw-ea-px-1 tw-ea-gap-5 md:tw-ea-gap-20">
                    <div className="tw-ea-font-semibold tw-ea-text-sm tw-ea-w-[170px]">
                      {content.ipr_preferred_steps}
                    </div>
                    <div>
                      <div className="tw-ea-text-[10px] tw-ea-text-[#898A8D] tw-ea-font-bold tw-ea-mb-2">
                        {content.ipr_preferred_steps}:
                      </div>
                      <NumbersChipsCombobox
                        onChange={(valueArr) =>
                          setFieldValue("ipr_steps", valueArr)
                        }
                        className="tw-ea-max-w-xs"
                        value={values.ipr_steps || preferences.ipr_steps}
                        disabled={values.use_ipr === "no"}
                      />
                      {errors.ipr_steps && touched.ipr_steps && (
                        <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                          {errors.ipr_steps}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="tw-ea-pb-6 tw-ea-border-b-[1px] tw-ea-border-solid tw-ea-border-b-[#C8C8CA] tw-ea-border-0 tw-ea-flex tw-ea-flex-col lg:tw-ea-flex-row tw-ea-gap-3 lg:tw-ea-gap-[92px]">
                <div className="tw-ea-font-semibold tw-ea-text-lg tw-ea-min-w-[170px]">
                  {content.attachments}
                </div>
                <div className="tw-ea-flex-1">
                  <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row md:tw-ea-gap-20 tw-ea-pb-5 tw-ea-gap-5 tw-ea-border-b-[1px] tw-ea-border-solid tw-ea-border-b-[#C8C8CA] tw-ea-border-0">
                    <div className="tw-ea-font-semibold tw-ea-text-sm tw-ea-w-[170px]">
                      {content.do_you_want_to_use_attachments}
                    </div>
                    <div>
                      <div className="tw-ea-text-[10px] tw-ea-text-[#898A8D] tw-ea-font-bold tw-ea-mb-2">
                        {content.choose}:
                      </div>
                      <RadioGroupTailwind
                        name="use_attachments"
                        id="use_attachments"
                        onChange={(valueArr) =>
                          setFieldValue("use_attachments", valueArr)
                        }
                        options={yes_no_options(content)}
                        className={
                          "tw-ea-text-[12px] tw-ea-whitespace-nowrap tw-ea-flex tw-ea-flex-row md:tw-ea-flex-col tw-ea-gap-4"
                        }
                        checked={
                          values.use_attachments || preferences.use_attachments
                        }
                      />
                      {errors.use_attachments && touched.use_attachments && (
                        <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                          {errors.use_attachments}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row tw-ea-py-5 tw-ea-px-1 tw-ea-gap-5 md:tw-ea-gap-20">
                    <div className="tw-ea-font-semibold tw-ea-text-sm tw-ea-w-[170px]">
                      {content.attachments_preferred_steps}
                    </div>
                    <div>
                      <div className="tw-ea-text-[10px] tw-ea-text-[#898A8D] tw-ea-font-bold tw-ea-mb-2">
                        {content.attachments_preferred_steps}:
                      </div>
                      <NumbersChipsCombobox
                        onChange={(valueArr) =>
                          setFieldValue("attachments_steps", valueArr)
                        }
                        className="tw-ea-max-w-xs"
                        value={
                          values.attachments_steps ||
                          preferences.attachments_steps
                        }
                        disabled={values.use_attachments === "no"}
                      />
                      {errors.attachments_steps &&
                        touched.attachments_steps && (
                          <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                            {errors.attachments_steps}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-ea-pb-6 tw-ea-flex tw-ea-flex-col lg:tw-ea-flex-row tw-ea-gap-3 lg:tw-ea-gap-[92px]">
                <div className="tw-ea-font-semibold tw-ea-text-lg tw-ea-min-w-[170px]">
                  {content.other_preferences}
                </div>
                <div className="tw-ea-flex-1">
                  <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row md:tw-ea-gap-20 tw-ea-pb-5 tw-ea-gap-5 tw-ea-border-b-[1px] tw-ea-border-solid tw-ea-border-b-[#C8C8CA] tw-ea-border-0">
                    <div className="tw-ea-font-semibold tw-ea-text-sm tw-ea-w-[170px]">
                      {content.passive_aligners}
                    </div>
                    <div>
                      <div className="tw-ea-text-[10px] tw-ea-text-[#898A8D] tw-ea-font-bold tw-ea-mb-2">
                        {content.use_passive_aligners}:
                      </div>
                      <RadioGroupTailwind
                        name="passive_aligners"
                        id="passive_aligners"
                        onChange={(valueArr) =>
                          setFieldValue("passive_aligners", valueArr)
                        }
                        options={yes_no_options(content)}
                        className={
                          "tw-ea-text-[12px] tw-ea-whitespace-nowrap tw-ea-flex tw-ea-flex-row md:tw-ea-flex-col tw-ea-gap-4"
                        }
                        checked={
                          values.passive_aligners ||
                          preferences.passive_aligners
                        }
                      />
                      {errors.passive_aligners && touched.passive_aligners && (
                        <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                          {errors.passive_aligners}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row tw-ea-py-5 tw-ea-px-1 tw-ea-gap-5 md:tw-ea-gap-20 tw-ea-border-b-[1px] tw-ea-border-solid tw-ea-border-b-[#C8C8CA] tw-ea-border-0">
                    <div className="tw-ea-font-semibold tw-ea-text-sm tw-ea-w-[170px]">
                      {content.pontics}
                    </div>
                    <div>
                      <div className="tw-ea-text-[10px] tw-ea-text-[#898A8D] tw-ea-font-bold tw-ea-mb-2">
                        {content.use_pontics}:
                      </div>
                      <RadioGroupTailwind
                        name="pontics"
                        id="pontics"
                        onChange={(valueArr) =>
                          setFieldValue("pontics", valueArr)
                        }
                        options={yes_no_options(content)}
                        className={
                          "tw-ea-text-[12px] tw-ea-whitespace-nowrap tw-ea-flex tw-ea-flex-row md:tw-ea-flex-col tw-ea-gap-4"
                        }
                        checked={values.pontics || preferences.pontics}
                      />
                      {errors.pontics && touched.pontics && (
                        <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                          {errors.pontics}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tw-ea-flex tw-ea-flex-col md:tw-ea-flex-row tw-ea-py-5 tw-ea-px-1 tw-ea-gap-5 md:tw-ea-gap-20 tw-ea-border-b-[1px] tw-ea-border-solid tw-ea-border-b-[#C8C8CA] tw-ea-border-0">
                    <div className="tw-ea-font-semibold tw-ea-text-sm tw-ea-w-[170px]">
                      {content.overcorrection}
                    </div>
                    <div>
                      <div className="tw-ea-text-[10px] tw-ea-text-[#898A8D] tw-ea-font-bold tw-ea-mb-2">
                        {content.use_overcorrection}:
                      </div>
                      <RadioGroupTailwind
                        name="overcorrection"
                        id="overcorrection"
                        onChange={(valueArr) =>
                          setFieldValue("overcorrection", valueArr)
                        }
                        options={yes_no_options(content)}
                        className={
                          "tw-ea-text-[12px] tw-ea-whitespace-nowrap tw-ea-flex tw-ea-flex-row md:tw-ea-flex-col tw-ea-gap-4"
                        }
                        checked={
                          values.overcorrection || preferences.overcorrection
                        }
                      />
                      {errors.overcorrection && touched.overcorrection && (
                        <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                          {errors.overcorrection}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tw-ea-flex tw-ea-flex-col tw-ea-py-5 tw-ea-gap-2">
                    <div className="tw-ea-font-semibold tw-ea-text-sm tw-ea-w-[170px]">
                      {content.other_preferences}
                    </div>
                    <Input
                      className="custome-textarea  custome-input-text !tw-ea-max-w-none"
                      name="other_preferences"
                      id="other_preferences"
                      type="textarea"
                      rows={5}
                      onChange={(e) => {
                        setFieldValue("other_preferences", e.target.value);
                      }}
                      defaultValue={
                        values.other_preferences ||
                        preferences.other_preferences ||
                        ""
                      }
                    />
                    {errors.other_preferences && touched.other_preferences && (
                      <div className="tw-ea-mt-2 tw-ea-w-full tw-ea-text-[#FC4A69]">
                        {errors.other_preferences}
                      </div>
                    )}
                  </div>
                  <div className="new-addres-buttons-grid-onPreferences">
                    <Button
                      className="btn light-green-btn float-right preferance-btn-height button-seme-bold-text"
                      size="sm"
                      onClick={handlePopUpModal}
                      disabled={creatingNewPreferences}
                    >
                      {content.save}
                    </Button>
                  </div>
                </div>
              </div>

              <ConfirmPopup
                pop_up={pop_up}
                content={content}
                creatingNewPreferences={creatingNewPreferences}
                CreateDoctorPreferences={() =>
                  handlePreferenceSubmit(values, errors)
                }
                handlePopUpModal={handlePopUpModal}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const yes_no_options = (content) => [
  {
    name: "yes",
    title: content.yes_as_needed,
  },
  {
    name: "no",
    title: content.no,
  },
];
