
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class HybirdTreatment extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */

    this.state = {

      use_hybridTreatment: this.props.caseSubmission.advanced_modules.hybridTreatment.use_hybridTreatment,


    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "hybridTreatment",
      data: {
        use_hybridTreatment: this.state.use_hybridTreatment,

      },
    });

    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  };

  clearState = () => {
    this.setState({
    
    })
  };

  render() {

    const {
      use_hybridTreatment,

    } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>
        <div className="templete_module_grid">
          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.Use_Hybrid_treatment_for_this_case}{"? "}
            </div>
            <TreatmentOptions content={content} value={use_hybridTreatment} name="use_hybridTreatment" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_hybridTreatment")
              if (value !== true) {
                this.clearState()
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>

          <div className="divider_module-middle"></div>
          <div id="content_container_3"
            className={`c-content-container6 tooth-extraction  cpb2 `}>

            <div className="Eon_to_recommend_no">
              <p className={`${(this.state.use_hybridTreatment === "eonToDecide") ? 'Eon_to_recommend_no Eon_to_recommend_layout' : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
              <p className={`${(this.state.use_hybridTreatment === true) ? 'Eon_to_recommend_no Eon_to_recommend_layout' : ''} title-2-text`}>{content.Hybrid_treatment_will_b_considered_for_difficult_movements_in_this_case}</p>
              <p className={`${(this.state.use_hybridTreatment === false) ? 'Eon_to_recommend_no Eon_to_recommend_layout' : ''} title-2-text`}>{content.Hybrid_treatment_will_not_be_applied_this_case}</p>
            </div>
      
          </div>
          {
          !this.props.summary  &&  (this.state.use_hybridTreatment ===true && window.innerWidth <839  )  && <div className="btn-adv-mobile"><Button
            className="  mobile-but-adv light-green-btn float-right margin-button   button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
        </div>
        {
          !this.props.summary  &&  !(this.state.use_hybridTreatment ===true && window.innerWidth <839  )  && <div className="btn-adv-mobile"><Button
            className={`mobile-but-adv light-green-btn float-right   button-seme-bold-text  ${(window.innerWidth >839 )?"mt-2":""}`}  
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps,
  mapDispatchToProps)(HybirdTreatment);