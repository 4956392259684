import { compact } from "lodash";
import React from "react";
import {
  ToolTipEonBasic,
  ToolTipEonPlus,
  ToolTipEonPro,
} from "../../views/case-submission/tooltip";
/*
Custom Radio Group Component
Note: id, checked ,title and onChange are required for CustomRadioGroup component to function. The props name and disabled
 are optional.
Usage: <CustomRadioGroup id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const CustomRadioGroup = ({
  name,
  checked = "",
  onChange = () => {},
  disabled = false,
  options,
  vertical,
  retainer,
  smallFont,
  isToolTip,

  medFont,
}) => {
  return (
    <div
      className={
        vertical ? "custom-radio-group-grid-v" : "custom-radio-group-grid"
      }
    >
      {options.map((value) => (
        <div
          className={`radio-item  `}
          key={value.name}
          style={{ pointerEvents: disabled ? "none" : "cursor" }}
        >
          <input
            type="radio"
            id={value.name}
            name={name}
            value={value.name}
            checked={checked === value.name}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
          />
          <label
            className={`custom-options-title ${
              smallFont
                ? "label-1-text"
                : medFont
                ? "medFont"
                : retainer
                ? "body-text"
                : "button-text"
            }`}
            htmlFor={value.name}
          >
            {value.title}
          </label>
          {isToolTip ? (
            <span className="tooltips-container">{value.com}</span>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default CustomRadioGroup;
