import { UPDATE_PHOTOS_XRAYS_BITE, UPDATE_IMPRESSIONS_BITE,RESET_BITE } from "../constants";

export const updatePhotosXraysBite = (payload) => {
  return {
    type: UPDATE_PHOTOS_XRAYS_BITE,
    payload,
  };
};

export const updateImpressionsBite = (payload) => {
  return {
    type: UPDATE_IMPRESSIONS_BITE,
    payload,
  };
};

export const resetBite = (payload) => {
  return {
    type: RESET_BITE,
    payload,
  };
};
