import React, { Component } from "react";
import Terms from "./Submission/Terms";
//import Summary from "./Submission/Summary";
import { getAlignerCase, getDoctorInfo } from "../../../api/api";
import { mapDataWithReducer } from "../../../services/mapData";
export default class Submission extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      activeTab: "1",
      doctorInfo: {},
      id: props.id,
      case: {},
      mappedData: {},
      caseId:
        props.caseId || (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
  }

  /* --------------------------------------------------------------------------------*/
  /* scroll to top after render,get aligner case info , get doctor info */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    if (this.props.caseType !== "Retainer") {
      this.GetAlignerCaseById();
      this.GetDoctorInfo();
    }
    window.scrollTo(0, 0);
  }
  /*--------------------------------------------------------------------------------*/
  /* get doctor info                                                       */
  /*--------------------------------------------------------------------------------*/
  GetDoctorInfo = () => {
    getDoctorInfo(this.state.id).then((res) => {
      this.setState({
        doctorInfo: res,
      });
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* get aligner case by id                                                         */
  /*--------------------------------------------------------------------------------*/
  GetAlignerCaseById = () => {
    getAlignerCase(this.state.id, this.state.caseId).then((res) => {
      this.mapData(res);
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* map data from api with reducer                                                 */
  /*--------------------------------------------------------------------------------*/
  mapData = (data) => {
    let newData = mapDataWithReducer(data);
    this.setState({ mappedData: newData, case: data });
  };
  /*--------------------------------------------------------------------------------*/
  /* toggle Active Tab                                              */
  /*--------------------------------------------------------------------------------*/
  toggleActiveTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    return (
      <div className="mt-5 mb-2">
        <div className="row justify-content-md-center">
          <div className="col">
            <div className="">
              {/*--------------------------------------------------------------------------------*/}
              {/* Terms component */}
              {/*--------------------------------------------------------------------------------*/}
              <Terms data={this.props} />
              {/*--------------------------------------------------------------------------------*/}
              {/* End Terms component */}
              {/*--------------------------------------------------------------------------------*/}
              {/* {this.props.getStore().savedToCloud || this.props.savedToCloud ? (
                <Summary
                  caseType={this.props.caseType}
                  data={this.props}
                  id={this.state.id}
                  case={this.state.case}
                  mappedData={this.state.mappedData}
                  doctorInfo={this.state.doctorInfo}
                  caseId={this.props.caseId}
                />
              ) : (
                <Terms data={this.props} />
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
