import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import {
  Tooth_Movement_Restrictions,
  Do_not_place_attachments,
  Extraction,
  Crossbites,
  LeaveSpace,
} from "../../../../redux/newCase/action";
import { mapTeeth2 } from "../../../../services/mapTeeth2";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  Tooth_Movement_Restrictions: (payload) =>
    dispatch(Tooth_Movement_Restrictions(payload)),

  Do_not_place_attachments: (payload) =>
    dispatch(Do_not_place_attachments(payload)),

  Extraction: (payload) => dispatch(Extraction(payload)),

  Crossbites: (payload) => dispatch(Crossbites(payload)),

  LeaveSpace: (payload) => dispatch(LeaveSpace(payload)),
});
class Teeth2 extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      upper_teeth: this.props.newCase.treatmentPlan[this.props.tab_name][
        this.props.action_key
      ]["upper_teeth"],
      lower_teeth: this.props.newCase.treatmentPlan[this.props.tab_name][
        this.props.action_key
      ]["lower_teeth"],
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* change upper teeth on mouse hover or mouse leave ( not working now) */
  /* --------------------------------------------------------------------------------*/
  toggle_upper = (state, photo, value) => {
    const { upper_teeth } = this.state;
    if (state === "hover" && value) {
      this.setState((prevState) => ({
        upper_teeth: {
          ...prevState.upper_teeth,
          [photo]: {
            ...prevState.upper_teeth[photo],
            image: upper_teeth[photo].active,
          },
        },
      }));
    } else if (state === "hover" && !value) {
      this.setState((prevState) => ({
        upper_teeth: {
          ...prevState.upper_teeth,
          [photo]: {
            ...prevState.upper_teeth[photo],
            image: upper_teeth[photo].hover,
          },
        },
      }));
    } else if (state === "leave" && value) {
      this.setState((prevState) => ({
        upper_teeth: {
          ...prevState.upper_teeth,
          [photo]: {
            ...prevState.upper_teeth[photo],
            image: upper_teeth[photo].active,
          },
        },
      }));
    } else if (state === "leave" && !value) {
      this.setState((prevState) => ({
        upper_teeth: {
          ...prevState.upper_teeth,
          [photo]: {
            ...prevState.upper_teeth[photo],
            image: upper_teeth[photo].not_active,
          },
        },
      }));
    } else if (state === "click") {
      const newState = !value;
      this.setState((prevState) => ({
        upper_teeth: {
          ...prevState.upper_teeth,
          [photo]: {
            ...prevState.upper_teeth[photo],
            image: newState
              ? upper_teeth[photo].active
              : upper_teeth[photo].not_active,
            value: !value,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        upper_teeth: {
          ...prevState.upper_teeth,
          [photo]: {
            ...prevState.upper_teeth[photo],
            image: upper_teeth[photo].not_active,
            value: false,
          },
        },
      }));
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* change upper teeth on click "active or not" (not working now ) */
  /* --------------------------------------------------------------------------------*/
  change_upper_value = (state, photo, value) => {
    const { upper_teeth, lower_teeth } = this.state;
    if (state === "click") {
      const newState = !value;
      this.setState({
        upper_teeth: {
          ...upper_teeth,
          [photo]: {
            ...upper_teeth[photo],
            image: newState
              ? upper_teeth[photo].active
              : upper_teeth[photo].not_active,
            value: !value,
          },
        },
      });

      this.props[this.props.action_key]({
        upper_teeth: {
          ...upper_teeth,
          [photo]: {
            ...upper_teeth[photo],
            image: newState
              ? upper_teeth[photo].active
              : upper_teeth[photo].not_active,
            value: !value,
          },
        },
        lower_teeth: lower_teeth,
      });
    } else {
      this.setState((prevState) => ({
        upper_teeth: {
          ...prevState.upper_teeth,
          [photo]: {
            ...prevState.upper_teeth[photo],
            image: upper_teeth[photo].not_active,
            value: false,
          },
        },
      }));

      this.props[this.props.action_key]({
        upper_teeth: {
          ...upper_teeth,
          [photo]: {
            ...upper_teeth[photo],
            image: upper_teeth[photo].not_active,
            value: false,
          },
        },
        lower_teeth: lower_teeth,
      });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* change lower teeth on mouse hover or mouse leave ( not working now) */
  /* --------------------------------------------------------------------------------*/
  toggle_lower = (state, photo, value) => {
    const { lower_teeth } = this.state;
    if (state === "hover" && value) {
      this.setState((prevState) => ({
        lower_teeth: {
          ...prevState.lower_teeth,
          [photo]: {
            ...prevState.lower_teeth[photo],
            image: lower_teeth[photo].active,
          },
        },
      }));
    } else if (state === "hover" && !value) {
      this.setState((prevState) => ({
        lower_teeth: {
          ...prevState.lower_teeth,
          [photo]: {
            ...prevState.lower_teeth[photo],
            image: lower_teeth[photo].hover,
          },
        },
      }));
    } else if (state === "leave" && value) {
      this.setState((prevState) => ({
        lower_teeth: {
          ...prevState.lower_teeth,
          [photo]: {
            ...prevState.lower_teeth[photo],
            image: lower_teeth[photo].active,
          },
        },
      }));
    } else if (state === "leave" && !value) {
      this.setState((prevState) => ({
        lower_teeth: {
          ...prevState.lower_teeth,
          [photo]: {
            ...prevState.lower_teeth[photo],
            image: lower_teeth[photo].not_active,
          },
        },
      }));
    } else if (state === "click") {
      const newState = !value;
      this.setState((prevState) => ({
        lower_teeth: {
          ...prevState.lower_teeth,
          [photo]: {
            ...prevState.lower_teeth[photo],
            image: newState
              ? lower_teeth[photo].active
              : lower_teeth[photo].not_active,
            value: !value,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        lower_teeth: {
          ...prevState.lower_teeth,
          [photo]: {
            ...prevState.lower_teeth[photo],
            image: lower_teeth[photo].not_active,
            value: false,
          },
        },
      }));
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* change lower teeth on click "active or not" (not working now ) */
  /* --------------------------------------------------------------------------------*/
  change_lower_value = (state, photo, value) => {
    const { lower_teeth, upper_teeth } = this.state;
    if (state === "click") {
      const newState = !value;
      this.setState((prevState) => ({
        lower_teeth: {
          ...prevState.lower_teeth,
          [photo]: {
            ...prevState.lower_teeth[photo],
            image: newState
              ? lower_teeth[photo].active
              : lower_teeth[photo].not_active,
            value: !value,
          },
        },
      }));

      this.props[this.props.action_key]({
        upper_teeth: upper_teeth,
        lower_teeth: {
          ...lower_teeth,
          [photo]: {
            ...lower_teeth[photo],
            image: newState
              ? lower_teeth[photo].active
              : lower_teeth[photo].not_active,
            value: !value,
          },
        },
      });
    } else {
      this.setState((prevState) => ({
        lower_teeth: {
          ...prevState.lower_teeth,
          [photo]: {
            ...prevState.lower_teeth[photo],
            image: lower_teeth[photo].not_active,
            value: false,
          },
        },
      }));
      this.props[this.props.action_key]({
        upper_teeth: upper_teeth,
        lower_teeth: {
          ...lower_teeth,
          [photo]: {
            ...lower_teeth[photo],
            image: lower_teeth[photo].not_active,
            value: false,
          },
        },
      });
    }
  };

  render() {
    let upper_teeth = this.props.newCase.treatmentPlan[this.props.tab_name][
      this.props.action_key
    ]["upper_teeth"];
    let lower_teeth = this.props.newCase.treatmentPlan[this.props.tab_name][
      this.props.action_key
    ]["lower_teeth"];
    return (
      <div>
        <Row className="align-items-center justify-content-center pt-3 pb-3 border2">
          {this.props.newCase.numbering === "palmer" && (
            <Col xs="1" md="1" lg="1" className="text-center title_active side">
              R
            </Col>
          )}
          <Col
            xs={`${this.props.newCase.numbering === "palmer" ? "10" : "11"}`}
            md={`${this.props.newCase.numbering === "palmer" ? "10" : "11"}`}
            lg={`${this.props.newCase.numbering === "palmer" ? "10" : "11"}`}
            className="pr-0  pl-0 text-center"
          >
            {/*--------------------------------------------------------------------------------*/}
            {/* Upper Teeth */}
            {/*--------------------------------------------------------------------------------*/}
            <div className="grid">
              {Object.keys(upper_teeth).map((prop, key) => {
                return (
                  <div key={key}>
                    <img
                      // className="teeth"
                      alt={prop}
                      src={upper_teeth[prop].image}
                      //onMouseOver
                      onMouseOver={(e) => {
                        // if tooth is active show active tooth on Mouse Over
                        // else show hovered tooth
                        e.currentTarget.src = upper_teeth[prop].value
                          ? upper_teeth[prop].active
                          : upper_teeth[prop].hover;
                      }}
                      //onMouseOut
                      onMouseOut={(e) => {
                        // if tooth is active show active tooth on Mouse Out
                        // else show not active tooth
                        e.currentTarget.src = upper_teeth[prop].value
                          ? upper_teeth[prop].active
                          : upper_teeth[prop].not_active;
                      }}
                      //onclick
                      onClick={(e) => {
                        // if tooth is active change value to not active and show not active tooth
                        // else change value to active and show active tooth
                        let newdata = !upper_teeth[prop].value;
                        let mapped_data = mapTeeth2({
                          upper_teeth: {
                            ...upper_teeth,
                            [prop]: {
                              ...upper_teeth[prop],
                              image: newdata
                                ? upper_teeth[prop].active
                                : upper_teeth[prop].not_active,
                              value: !upper_teeth[prop].value,
                            },
                          },
                          lower_teeth: lower_teeth,
                        });
                        // change values in reducer
                        this.props[this.props.action_key](mapped_data);
                      }}
                    ></img>
                  </div>
                );
              })}
            </div>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Upper Teeth */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Plamer tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {this.props.newCase.numbering === "palmer" && (
              <div className="grid text-center title_active">
                <div className="text-center">8</div>
                <div className="text-center">7</div>
                <div className="text-center">6</div>
                <div className="text-center">5</div>
                <div className="text-center">4</div>
                <div className="text-center">3</div>
                <div className="text-center">2</div>
                <div className="text-center">1</div>
                <div className="text-center">1</div>
                <div className="text-center">2</div>
                <div className="text-center">3</div>
                <div className="text-center">4</div>
                <div className="text-center">5</div>
                <div className="text-center">6</div>
                <div className="text-center">7</div>
                <div className="text-center">8</div>
              </div>
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Plamer tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* FDI tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {this.props.newCase.numbering === "fdi" && (
              <>
                {" "}
                <div className="grid text-center title_active">
                  <div className="text-center">18</div>
                  <div className="text-center">17</div>
                  <div className="text-center">16</div>
                  <div className="text-center">15</div>
                  <div className="text-center">14</div>
                  <div className="text-center">13</div>
                  <div className="text-center">12</div>
                  <div className="text-center">11</div>
                  <div className="text-center">21</div>
                  <div className="text-center">22</div>
                  <div className="text-center">23</div>
                  <div className="text-center">24</div>
                  <div className="text-center">25</div>
                  <div className="text-center">26</div>
                  <div className="text-center">27</div>
                  <div className="text-center">28</div>
                </div>
                <hr className="divider"></hr>
                <div className="grid text-center title_active">
                  <div className="text-center">48</div>
                  <div className="text-center">47</div>
                  <div className="text-center">46</div>
                  <div className="text-center">45</div>
                  <div className="text-center">44</div>
                  <div className="text-center">43</div>
                  <div className="text-center">42</div>
                  <div className="text-center">41</div>
                  <div className="text-center">31</div>
                  <div className="text-center">32</div>
                  <div className="text-center">33</div>
                  <div className="text-center">34</div>
                  <div className="text-center">35</div>
                  <div className="text-center">36</div>
                  <div className="text-center">37</div>
                  <div className="text-center">38</div>
                </div>
              </>
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* End FDI tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Universal tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {this.props.newCase.numbering === "universal" && (
              <>
                {" "}
                <div className="grid text-center title_active">
                  <div className="text-center">1</div>
                  <div className="text-center">2</div>
                  <div className="text-center">3</div>
                  <div className="text-center">4</div>
                  <div className="text-center">5</div>
                  <div className="text-center">6</div>
                  <div className="text-center">7</div>
                  <div className="text-center">8</div>
                  <div className="text-center">9</div>
                  <div className="text-center">10</div>
                  <div className="text-center">11</div>
                  <div className="text-center">12</div>
                  <div className="text-center">13</div>
                  <div className="text-center">14</div>
                  <div className="text-center">15</div>
                  <div className="text-center">16</div>
                </div>
                <hr className="divider"></hr>
                <div className="grid text-center title_active">
                  <div className="text-center">32</div>
                  <div className="text-center">31</div>
                  <div className="text-center">30</div>
                  <div className="text-center">29</div>
                  <div className="text-center">28</div>
                  <div className="text-center">27</div>
                  <div className="text-center">26</div>
                  <div className="text-center">25</div>
                  <div className="text-center">24</div>
                  <div className="text-center">23</div>
                  <div className="text-center">22</div>
                  <div className="text-center">21</div>
                  <div className="text-center">20</div>
                  <div className="text-center">19</div>
                  <div className="text-center">18</div>
                  <div className="text-center">17</div>
                </div>
              </>
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Universal tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Lower Teeth */}
            {/*--------------------------------------------------------------------------------*/}
            <div className="grid">
              {Object.keys(lower_teeth).map((prop, key) => {
                return (
                  <div key={key}>
                    <img
                      // className="teeth"
                      alt={prop}
                      src={lower_teeth[prop].image}
                      //onMouseOver
                      onMouseOver={(e) => {
                        e.currentTarget.src = lower_teeth[prop].value
                          ? lower_teeth[prop].active
                          : lower_teeth[prop].hover;
                      }}
                      //onMouseOut
                      onMouseOut={(e) => {
                        e.currentTarget.src = lower_teeth[prop].value
                          ? lower_teeth[prop].active
                          : lower_teeth[prop].not_active;
                      }}
                      //onClick
                      onClick={(e) => {
                        let newdata = !lower_teeth[prop].value;
                        let mapped_data = mapTeeth2({
                          upper_teeth: upper_teeth,
                          lower_teeth: {
                            ...lower_teeth,
                            [prop]: {
                              ...lower_teeth[prop],
                              image: newdata
                                ? lower_teeth[prop].active
                                : lower_teeth[prop].not_active,
                              value: !lower_teeth[prop].value,
                            },
                          },
                        });

                        this.props[this.props.action_key](mapped_data);
                      }}
                    ></img>
                  </div>
                );
              })}
            </div>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Lower Teeth */}
            {/*--------------------------------------------------------------------------------*/}
          </Col>
          {this.props.newCase.numbering === "palmer" && (
            <Col
              xs="1"
              md="1"
              lg="1"
              className=" text-center title_active side"
            >
              L
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Teeth2);
