import { upper_teeth, lower_teeth } from "../redux/customeTeeth/init_teeth";
import { findNumber } from "./findNumbers";

export function mapTeethElastics(newData) {
  let RightTeethUpper = mapUpperRightTeeth(newData, "upper_r_", upper_teeth, "R", "U", "upper_teeth");
  let RightTeethLower = mapLowerRightTeeth(newData, "lower_r_", lower_teeth, "R", "L", "lower_teeth");

  let LeftTeethUpper = mapUpperLeftTeeth(newData, "upper_l_", upper_teeth, "L", "U", "upper_teeth");
  let LeftTeethLower = mapLowerLeftTeeth(newData, "lower_l_", lower_teeth, "L", "L", "lower_teeth");

  let teeth = {
    upper_teeth: { ...RightTeethUpper, ...LeftTeethUpper },
    lower_teeth: { ...RightTeethLower, ...LeftTeethLower },
  };
  return teeth;
}

function mapUpperRightTeeth(newData, key, teeth, side, position, mainPosition) {
  let num = 8;
  let rightTeeth = {};
  let tooth_key = 1;
  for (var x = 1; x < 9; x++) {
    var value = newData[mainPosition][`${key}${num}`]?.value ? newData[mainPosition][`${key}${num}`]?.value : newData[mainPosition][`${key}${num}`].use_elastic || false;

    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]:
        newData[mainPosition] !== {} && newData[mainPosition] !== undefined
          ? {
            ...teeth[`${key}${num}`],
            image:
              value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
            value: value,
            updated: false,
            use_elastic: value,
            elastic_stages: newData[mainPosition][`${key}${num}`].elastic_stages || [{ from: 0, to: 0 }],
            key: tooth_key,
            tooth_key_sys:
            {
              palmer: `${findNumber("palmer", `${position}${side}${num}`)?.palmer} `,
              fdi: `${findNumber("palmer", `${position}${side}${num}`)?.fdi} `,
              universal: `${findNumber("palmer", `${position}${side}${num}`)?.universal}`
            },

          }
          : {
            ...teeth[`${key}${num}`],
            updated: false,
            key: tooth_key,
            use_elastic: false,
            elastic_stages: [{ from: 0, to: 0 }],

            tooth_key_sys:
            {
              palmer: `${findNumber("palmer", `${position}${side}${num}`)?.palmer} `,
              fdi: `${findNumber("palmer", `${position}${side}${num}`)?.fdi}`,
              universal: `${findNumber("palmer", `${position}${side}${num}`)?.universal} `
            },
          },
    };
    num--;
    tooth_key++;

  }
  return rightTeeth;
}

function mapUpperLeftTeeth(newData, key, teeth, side, position, mainPosition) {
  let num = 1;
  let tooth_key = 9
  let leftTeeth = {};
  for (var x = 1; x < 9; x++) {
    var value = newData[mainPosition][`${key}${num}`]?.value ? newData[mainPosition][`${key}${num}`]?.value : newData[mainPosition][`${key}${num}`].use_elastic || false;

    leftTeeth = {
      ...leftTeeth,

      [`${key}${num}`]:
        newData[mainPosition] !== {} && newData[mainPosition] !== undefined
          ? {
            ...teeth[`${key}${num}`],
            image:
              value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
            value: value,
            updated: false,
            use_elastic: value,
            elastic_stages: newData[mainPosition][`${key}${num}`].elastic_stages || [{ from: 0, to: 0 }],
            key: tooth_key,
            tooth_key_sys:
            {
              palmer: `${findNumber("palmer", `${position}${side}${num}`)?.palmer} `,
              fdi: `${findNumber("palmer", `${position}${side}${num}`)?.fdi} `,
              universal: `${findNumber("palmer", `${position}${side}${num}`)?.universal} `
            },

          }
          : {
            ...teeth[`${key}${num}`],
            updated: false,
            key: tooth_key,
            use_elastic: false,
            elastic_stages: [{ from: 0, to: 0 }],

            tooth_key_sys:
            {
              palmer: `${findNumber("palmer", `${position}${side}${num}`)?.palmer} `,
              fdi: `${findNumber("palmer", `${position}${side}${num}`)?.fdi} `,
              universal: `${findNumber("palmer", `${position}${side}${num}`)?.universal} `
            },
          },
    };
    num++;
    tooth_key++;

  }
  return leftTeeth;
}
function mapLowerRightTeeth(newData, key, teeth, side, position, mainPosition) {
  let num = 8;
  let tooth_key = 1;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    var value = newData[mainPosition][`${key}${num}`]?.value ? newData[mainPosition][`${key}${num}`]?.value : newData[mainPosition][`${key}${num}`].use_elastic || false;

    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]:
        newData[mainPosition] !== {} && newData[mainPosition] !== undefined
          ? {
            ...teeth[`${key}${num}`],
            image:
              value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
            value: value,
            updated: false,
            use_elastic: value,
            elastic_stages: newData[mainPosition][`${key}${num}`].elastic_stages || [{ from: 0, to: 0 }],
            key: tooth_key,
            tooth_key_sys:
            {
              palmer: `${findNumber("palmer", `${position}${side}${num}`)?.palmer} `,
              fdi: `${findNumber("palmer", `${position}${side}${num}`)?.fdi} `,
              universal: `${findNumber("palmer", `${position}${side}${num}`)?.universal} `
            },

          }
          : {
            ...teeth[`${key}${num}`],
            updated: false,
            key: tooth_key,
            use_elastic: false,
            elastic_stages: [{ from: 0, to: 0 }],

            tooth_key_sys:
            {
              palmer: `${findNumber("palmer", `${position}${side}${num}`)?.palmer} `,
              fdi: `${findNumber("palmer", `${position}${side}${num}`)?.fdi} `,
              universal: `${findNumber("palmer", `${position}${side}${num}`)?.universal} `
            },
          },
    };
    num--;
    tooth_key++;

  }
  return rightTeeth;
}

function mapLowerLeftTeeth(newData, key, teeth, side, position, mainPosition) {
  let num = 1;
  let tooth_key = 9;
  let leftTeeth = {};
  for (var x = 1; x < 9; x++) {
    var value = newData[mainPosition][`${key}${num}`]?.value ? newData[mainPosition][`${key}${num}`]?.value : newData[mainPosition][`${key}${num}`].use_elastic || false;

    leftTeeth = {
      ...leftTeeth,

      [`${key}${num}`]:
        newData[mainPosition] !== {} && newData[mainPosition] !== undefined
          ? {
            ...teeth[`${key}${num}`],
            image:
              value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
            value: value,
            updated: false,
            use_elastic: value,
            elastic_stages: newData[mainPosition][`${key}${num}`].elastic_stages || [{ from: 0, to: 0 }],
            key: tooth_key,
            tooth_key_sys:
            {
              palmer: `${findNumber("palmer", `${position}${side}${num}`)?.palmer} `,
              fdi: `${findNumber("palmer", `${position}${side}${num}`)?.fdi}`,
              universal: `${findNumber("palmer", `${position}${side}${num}`)?.universal} `
            },

          }
          : {
            ...teeth[`${key}${num}`],
            updated: false,
            key: tooth_key,
            use_elastic: false,
            elastic_stages: [{ from: 0, to: 0 }],

            tooth_key_sys:
            {
              palmer: `${findNumber("palmer", `${position}${side}${num}`)?.palmer} `,
              fdi: `${findNumber("palmer", `${position}${side}${num}`)?.fdi} `,
              universal: `${findNumber("palmer", `${position}${side}${num}`)?.universal} `
            },
          },
    };
    num++;
    tooth_key++;

  }
  return leftTeeth;
}


