export function findClosestVal(arr, tar) {
    let current_ipr_position;
    // check with universal value

    if (tar<=16) {
      current_ipr_position = "upper_teeth";
    } else {
      current_ipr_position = "lower_teeth";
    }
  
    var closest = arr?.reduce(function (prev, curr) {
      return Math.abs(curr - tar) < Math.abs(prev - tar) ? curr : prev;
    });
  
    var index;
    if (current_ipr_position === "lower_teeth") {
      index = arr.indexOf(closest) - 1;
    } else if (current_ipr_position === "upper_teeth") {
      index = arr.indexOf(closest) + 1;
    }
  
    return arr[index];
  }

  export function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }