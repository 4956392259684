import React from "react";
import { Button } from "reactstrap";
import { PopupContainer } from "./PopupContainer.js";
import { changePackageApi } from "../../api/api";
import clsx from "clsx";
import CustomRadioVertivalGroup from "../../components/radioGroup/CustomRadioVertivalGroup.js";

import { infoToaster, successToaster } from "../../services/toast";

class CaseTypePopupModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caseType: this.props.prescription.case_type,
    };
  }

  checkIfChange=() =>{
    if( this.state.caseType === this.props.prescription.case_type){
     return false
    }
    return true
  }

  handleSubmit = () => {
    let data = {
      aligner_case: {
        case_type: this.state.caseType,
       
      },
    };

    if (this.checkIfChange()) {
       changePackageApi(
      this.props.doctor_Id,
      this.props.case_Id,
      JSON.stringify(data)
    )
      .then((res) => {
        this.props.HandleCaseTypePopup();
        this.props.GetAlignerCase();
        successToaster(
          this.props.content.case_type_successfully_updated,
          this.props.content.Success
        );
      })
      .catch((error) => {
        console.log(error);
      });
    }else{
      this.props.HandleCaseTypePopup();
      infoToaster(this.props.content.nothing_is_changed, this.props.content.Attention_needed);
    }
   
  };

  handleCancel = () => {
    this.setState({
      caseType: this.props.prescription.case_type,
    });

    this.props.HandleCaseTypePopup();
  };

  render() {
    const { caseType } = this.state;
    const { CaseTypePopup , prescription } = this.props;
    let content = this.props.content;
    const case_Type_Option = [
      { name: "Eon Basic", title: content.eon_basic },
      { name: "Eon Plus", title: content.eon_plus },
      { name: "Eon Pro", title: content.eon_pro },
    ];
    return (
      <>
        <span className="relative">
          <span
            className="  CaseType_change_button"
            onClick={this.props.HandleCaseTypePopup}
          >
            {content.change}
          </span>
      
          {CaseTypePopup && (
            <PopupContainer className={clsx("absolute", { CaseTypePopup })}>
              <>
                <div className="CaseType-popup-title ">
                  {content.change_case_type}{" "}
                </div>
                <CustomRadioVertivalGroup
                  id="caseType"
                  medFont={true}
                  name="caseType"
                  withBorder={true}
                  checked={caseType}
                  value={caseType}
              
                  onChange={(value) =>
                    this.setState({
                      caseType: value,
                    })
                  }
                  options={case_Type_Option}
                />
                <div className="caseType-popup-button">
                  <div></div>
                  <div
                    onClick={this.handleCancel}
                    className="caseType-popup-button-close"
                  >
                    {content.cancel}
                  </div>
                  <div
                    className="caseType-popup-button-add"
                    onClick={this.handleSubmit}
                  >
                    {content.save}
                  </div>
                </div>
              </>
            </PopupContainer>
          )}
        </span>
      </>
    );
  }
}

export default CaseTypePopupModule;
