import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input} from "reactstrap";


import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import CustomToggle from "../../../../../components/toggle/CustomeToggle";
import CustomeHeader from "../../../../../components/header/CustomeHeader";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class TorqueEnhancers extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_torque_enhancers: this.props.caseSubmission.advanced_modules
        .torqueEnhancers.useTorqueEnhancers,
      torque_enhancers_details: this.props.caseSubmission.advanced_modules
        .torqueEnhancers.details,
    };
  }

  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  handelChanges = (value) => {
    this.setState({
      attachmentsTiming: value
    })
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "torqueEnhancers",
      data: {
        useTorqueEnhancers: this.state.use_torque_enhancers,
        teeth:
          this.props.customeTeethReducer.Case_Torque_Enhancers
        ,
        details: this.state.torque_enhancers_details,
      },
    });
    this.props.save();
  };
  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_torque_enhancers, torque_enhancers_details } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div className="grid_container-total">
        <div className="form-group content form-block-holder grid_container ">

        <div className="customHeaderTS">
            <div> <CustomeHeader title={content.torque_enhancers} className="cp3 pt-0" optional={false} note={false} note_text={content.torque_enhancers_note} /></div>
            <div className="reative-close-ts">  <span className={` pull-right ts-close-button-new`} onClick={() => { this.props.toggleModalsClose(this.props.active_module) }}>&times;</span></div>
          </div>

        

<div className="torque-header">
          <div className="admin-Grid-Advance">
              <div></div>
              {/* part 1 */}
              <div className=" admin-Grid-Advance-inner D-head-ts">
                <div >
                {content.Used_torque_enhancers_for_this_case}{" "}
                </div>
                {/* part 2 */}
                <div className="D-total-cont-toggle">
                <div className="pointer_disable">
                  <CustomToggle  id="use_torque_enhancers"
                // disabled={this.props.summary}
                checked={use_torque_enhancers}
                onChange={() => {
                  this.setState({
                    use_torque_enhancers: !use_torque_enhancers,
                    ...use_torque_enhancers && {
                      torque_enhancers_details: ""
                    }
                  });

                  if (use_torque_enhancers) {
                    this.props.Clear_Teeth_Data("Case_Torque_Enhancers")
                  }
                }} />
                </div>
                </div>
              </div>
              <div></div>
            </div>
            </div>
    

  {/* ///part 3 */}
  <div className="grid_container D-head-ts">
        
          <div className="teeth_selector m-auto">
            <div className="control-label title_active-gcoTS-new  D-head-ts text-center pb-2 mt-2 ">
              {content.placement}
              </div> <CustomeTeeth
              advanced_Ts="true"
              action_key="Case_Torque_Enhancers"
              disabled={!use_torque_enhancers || use_torque_enhancers === "eonToDecide"}
              summary={this.props.summary}
            /></div>



<p className="control-label text-left  light-green   D-head-ts ">{content.details}</p>
<div className="disable-input">
            <Input
              className="mt-2  border-radius backgrountTextarea"
              name="torque_enhancers_details"
              id="torque_enhancers_details"
              type="textarea"
              placeholder="from step #"
              rows={5}
              value={torque_enhancers_details}
              onChange={(e) => {
                this.setState({ torque_enhancers_details: e.target.value });
              }}
              readOnly
            />
          </div>

        </div>
        </div>
    
    
        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TorqueEnhancers);
