import { checkIPR, checkMovement } from "../../services/checkChanges";
import {
  TEETH_IPR_DATA,
  SAVED_DATA,
  REJECTION_REASON,
  PROTOCOL_FEEDBACK,
  CHANGE_TRACKER,
  SHOW_TOOTH_MOVEMENT,
  RESET_STEPS_DATA,
  SHOW_IPR_TOOTH_MOVEMENT,
  TEETH_IPR_DATA_BY_ID,
  SAVED_DATA_BY_ID,
  REJECTION_REASON_BY_ID,
  PROTOCOL_FEEDBACK_BY_ID,
  RESET_ALL_TS_DATA,
  STEP_VISITED,
  USED_IPR_BY_ID,
  TREATMENT_PLAN_ACTION
} from "../constants/";

const INIT_STATE = {
  teeth_ipr_data: {},
  saved_data: {},
  clinical_protocol_feedback: "",
  rejection_reason: "",
  change_tracker: false,
  bite_check_changed: false,
  teeth_movement_changed: false,
  ipr_changed: false,
  treatment_protocol_changed: false,
  show_Tooth_Movement: false,
  show_IPR_tooth_Movement: false,
  movement_step_visited: false,
  ipr_step_visited: false,
  protocol_step_visited: true,
  submit_step_visited: false,
  bite_check_step_visited: false,
  used_ipr: false,
  treatment_plans: {},
};

const checkTeethMovement = (teeth) => {
  let value = false;
  let lower_teeth = teeth.lower_teeth;
  let upper_teeth = teeth.upper_teeth;
  for (var key in lower_teeth) {
    if (lower_teeth[key].movement.updated === true) {
      value = true;
    }
  }
  for (var key in upper_teeth) {
    if (upper_teeth[key].movement.updated === true) {
      value = true;
    }
  }

  return value;
};

const checkIPRData = (teeth) => {
  let value = false;
  let lower_teeth = teeth.lower_teeth;
  let upper_teeth = teeth.upper_teeth;
  for (var key in lower_teeth) {
    if (lower_teeth[key].updated === true) {
      value = true;
    }
  }
  for (var key in upper_teeth) {
    if (upper_teeth[key].updated === true) {
      value = true;
    }
  }

  return value;
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEETH_IPR_DATA:
      return {
        ...state,
        teeth_ipr_data: action.payload,
        ipr_changed: checkIPR(state.saved_data, action.payload),
        teeth_movement_changed: checkMovement(state.saved_data, action.payload),
      };
    case TEETH_IPR_DATA_BY_ID:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          teeth_ipr_data: action.payload.data,
          ipr_changed: checkIPRData(action.payload.data),
          teeth_movement_changed: checkTeethMovement(action.payload.data),
        },
      };
    case RESET_STEPS_DATA:
      return {
        ...state,
        teeth_ipr_data: {},
        saved_data: {},
        clinical_protocol_feedback: "",
        rejection_reason: "",
        change_tracker: false,
        bite_check_changed: false,
        teeth_movement_changed: false,
        ipr_changed: false,
        treatment_protocol_changed: false,
        show_Tooth_Movement: false,
        show_IPR_tooth_Movement: false,
        movement_step_visited: false,
        ipr_step_visited: false,
        protocol_step_visited: false,
        submit_step_visited: false,
      };

    case SAVED_DATA:
      return {
        ...state,
        saved_data: action.payload,
      };
    case SAVED_DATA_BY_ID:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          saved_data: action.payload.data,
        },
      };
    case USED_IPR_BY_ID:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          used_ipr: action.payload.data,
        },
      };
    case REJECTION_REASON:
      return {
        ...state,
        rejection_reason: action.payload,
        bite_check_changed: action.payload ? true : false,
      };
    case REJECTION_REASON_BY_ID:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          rejection_reason: action.payload.data,
          bite_check_changed: action.payload.data ? true : false,
        },
      };
    case PROTOCOL_FEEDBACK:
      return {
        ...state,
        clinical_protocol_feedback: action.payload,
        treatment_protocol_changed: action.payload ? true : false,
      };
    case PROTOCOL_FEEDBACK_BY_ID:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          clinical_protocol_feedback: action.payload.data,
          treatment_protocol_changed: action.payload.data ? true : false,
        },
      };
    case CHANGE_TRACKER:
      return {
        ...state,
        change_tracker: action.payload,
      };
    case SHOW_TOOTH_MOVEMENT:
      return {
        ...state,
        show_Tooth_Movement: action.payload,
      };
    case SHOW_IPR_TOOTH_MOVEMENT:
      return {
        ...state,
        show_IPR_tooth_Movement: action.payload,
      };
    case STEP_VISITED:
      return {
        ...state,
        [action.payload]: true,
      };

    case TREATMENT_PLAN_ACTION:
      return {
        ...state,
        treatment_plans: {
          ...state.treatment_plans,
          [action.payload.id]: action.payload.data,
        },
      };

    case RESET_ALL_TS_DATA:
      return INIT_STATE;
    default:
      return state;
  }
};
