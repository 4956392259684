import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  getDoctorAddresses,
  updateAddress,
  createAddress,
  deleteAddress,
} from "../../api/api";
import { countries } from "../../helpers/countries";
import Select from "react-select";
import { successToaster } from "../../services/toast";
import { Formik, Field, ErrorMessage } from "formik";
import { address_schema } from "../../helpers/addressValidations";
import address_icon from "../../assets/images/icon/address.svg";
import CustomeHeader from "../../components/header/CustomeHeader";
import { GetCountryLabel, GetCountryValue } from "../../helpers/countries";

const mapStateToProps = (state) => ({
  ...state,
});
class Address extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      doctorId: this.props.userReducer.currentUser.id,
      createUpdateAddressModal: false,
      deleteModal: false,
      obj: {},
      line1: "",
      line2: "",
      zip: "",
      phone: "",
      state: "",
      country: this.props.userReducer.currentUser.country,
      city: "",
      id: "",
      clinic_name:"" , 
      addresses: [],
      address: {},
    };
  }
  /* --------------------------------------------------------------------------------*/
  /*  get doctor address */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    this.getDoctorAddresses();
  }
  /* --------------------------------------------------------------------------------*/
  /*  get doctor address */
  /* --------------------------------------------------------------------------------*/
  getDoctorAddresses = () => {
    getDoctorAddresses(this.state.doctorId).then((res) => {
      this.setState({ addresses: res, address: res[0] });
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  toggle create and update address modal */
  /* --------------------------------------------------------------------------------*/
  toggleCreateUpdateAddressModal = () => {
    this.setState({
      createUpdateAddressModal: !this.state.createUpdateAddressModal,
      id: "",
      line1: "",
      line2: "",
      zip: "",
      phone: "",
      clinic_name:"" , 
      state: "",
      country: this.props.userReducer.currentUser.country,
      city: "",
   
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  toggle delete modal */
  /* --------------------------------------------------------------------------------*/
  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  Create and  Update Address */
  /* --------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { content } = this.props.languageReducer;

    setSubmitting(true);
    const data = {
      address: {
        country: this.props.userReducer.currentUser.country,
        city: values.city,
        state: values.state,
        line1: values.line1,
        line2: values.line2,
        zip: values.zip,
        phone: values.phone,
        clinic_name: values.clinic_name
      },
    };
    if (this.state.id) {
      //update address
      updateAddress(
        this.state.doctorId,
        this.state.id,
        JSON.stringify(data)
      ).then((res) => {
        this.getDoctorAddresses();
        this.setState({
          createUpdateAddressModal: !this.state.createUpdateAddressModal,
          country: this.props.userReducer.currentUser.country,
          city: "",
          state: "",
          line1: "",
          clinic_name:"" , 
          line2: "",
          zip: "",
          phone: "",
        });
        setSubmitting(false);

        successToaster(content.updated_address_successfully, content.Success);
      });
    } else {
      // add new address
      createAddress(this.props.id, JSON.stringify(data)).then((res) => {

  
        this.getDoctorAddresses();
        this.setState({
          createUpdateAddressModal: !this.state.createUpdateAddressModal,
          country: this.props.userReducer.currentUser.country,
          city: "",
          state: "",
          line1: "",
          line2: "",
          clinic_name:"" , 
          zip: "",
          phone: "",
        });
        setSubmitting(false);
        successToaster(
          content.created_new_address_successfully,
          content.Success
        );
      });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /*  Delete Address */
  /* --------------------------------------------------------------------------------*/
  DeleteAddress = () => {
    const { content } = this.props.languageReducer;

    deleteAddress(this.state.doctorId, this.state.obj.id).then((res) => {
      this.getDoctorAddresses();
      this.setState({
        deleteModal: !this.state.deleteModal,
        createUpdateAddressModal: !this.state.createUpdateAddressModal,
        country: this.props.userReducer.currentUser.country,
        city: "",
        state: "",
        clinic_name:"" , 
        line1: "",
        line2: "",
        zip: "",
        phone: "",
        id: "",
      });
      successToaster(content.deleted_address_successfully, content.Success);
    });
  };

  /* --------------------------------------------------------------------------------*/

  render() {
    const { content } = this.props.languageReducer;
    const { addresses } = this.state;
    return (
      <div className="new-general-settings-grid">
        <div className="tw-ea-mt-9">
        <div className="new-addres-buttons-grid advanceModuleInWebsite button-seme-bold-text">
          <Button
            onClick={() => {
              this.setState({
                createUpdateAddressModal: !this.state.createUpdateAddressModal,
                obj: {},
              });
            }}
            className="btn light-green-btn float-right cpy1 cpx2 new-btn-height button-seme-bold-text"
            size="sm"
          >
            {content.add_new_address}{" "}
            <i className="fas fa-angle-right icon-button "></i>
          </Button>
        </div>
          <div className="address-grid cml1">
            {addresses.map((address, index) => (
        
              <div key={index} className="d-grid">
              
                <div className="address-title-grid colorful">
                  {" "}
                  <img src={address_icon} />
                  <div className="colorful button-text">{`${content.address} ${
                    index + 1
                  }`}</div>{" "}
                </div>

                <div className="address-data-grid">
                  <div className="body-text">{address.city}</div>
                  <div className="body-text">
                    {GetCountryLabel(address.country)}
                  </div>
                  <div className="body-text">{address.state}</div>
                  <div className="body-text">{address.line1}</div>
                  <div className="body-text">{address.phone}</div>
                </div>
                <div
                  className="edit-address colorful body-text"
                  onClick={() => {
                    // let obj = data.find((o) => o.id === prop.id);
                    this.setState({
                      createUpdateAddressModal:
                        !this.state.createUpdateAddressModal,
                      obj: address,
                      id: address.id,
                      line1: address.line1,
                      line2: address.line2,
                      zip: address.zip,
                      phone: address.phone,
                      state: address.state,
                      country: GetCountryValue(address.country),
                      city: address.city,
                      clinic_name: address.clinic_name
                    });
                  }}
                >
                  {content.edit}
                </div>
              </div>
            ))}
          </div>
          <div className="advanceModuleInmobile ">
            <Button
              onClick={() => {
                this.setState({
                  createUpdateAddressModal:
                    !this.state.createUpdateAddressModal,
                  obj: {},
                });
              }}
              className="btn light-green-btn float-right cpy1 cpx2 address-botton-mobile button-seme-bold-text "
              size="sm"
            >
              {content.add_new_address}{" "}
              <i className="fas fa-angle-right icon-button "></i>
            </Button>
          </div>
        </div>

        {/*--------------------------------------------------------------------------------*/}
        {/* Create and Update Adress modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.createUpdateAddressModal}
          toggle={this.toggleCreateUpdateAddressModal}
          className="onboarding-modal confarim-modal"
        >
          {/* <ModalHeader toggle={this.toggleCreateUpdateAddressModal}>
                    </ModalHeader> */}
          <ModalBody>
            <CustomeHeader
              title={
                this.state.obj.country
                  ? content.update_address
                  : content.new_address
              }
              withoutLine={true}
              largeFont={true}
            />

            <Formik
              initialValues={{
                id: "",
                country: GetCountryLabel(this.state.country),
                city: this.state.city,
                line1: this.state.line1,
                line2: this.state.line2,
                zip: this.state.zip,
                phone: this.state.phone,
                state: this.state.state,
                clinic_name: this.state.clinic_name  , 
              }}
              validationSchema={address_schema}
              onSubmit={this.handleSubmit}
            >
              {({
                touched,
                errors,
                isSubmitting,
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} className="cmt2 mb136">
                  <div className="new-modal-content">
                    <Input type="hidden" name="id" id="id" />
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="country"
                            className="address-title callout-text"
                          >
                            {content.country}{" "}
                          </Label>
                          <Field
                            type="text"
                            name="country"
                            id="country"
                            onBlur={handleBlur}
                            className={`custome-input-text form-control }`}
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="city"
                            className="address-title callout-text"
                          >
                            {content.city}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            onBlur={handleBlur}
                            className={`custome-input-text form-control ${
                              touched.city && errors.city ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="city"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="line1"
                            className="address-title callout-text"
                          >
                            {content.street}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="line1"
                            id="line1"
                            className={`custome-input-text form-control ${
                              touched.line1 && errors.line1 ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.line1}
                          
                          />
                          <ErrorMessage
                            component="div"
                            name="line1"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="line2"
                            className="address-title callout-text"
                          >
                            {content.building}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="line2"
                            id="line2"
                            className={`custome-input-text form-control ${
                              touched.line2 && errors.line2 ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.line2}
                          />
                          <ErrorMessage
                            component="div"
                            name="line2"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="state"
                            className="address-title callout-text"
                          >
                            {content.state}
                          </Label>
                          <Field
                            type="text"
                            name="state"
                            id="state"
                            className={`custome-input-text form-control ${
                              touched.state && errors.state ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.state}
                          />
                          <ErrorMessage
                            component="div"
                            name="state"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="zip"
                            className="address-title callout-text"
                          >
                            {content.zip}
                          </Label>
                          <Field
                            type="text"
                            name="zip"
                            id="zip"
                            className={`custome-input-text form-control ${
                              touched.zip && errors.zip ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="zip"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="phone"
                            className="address-title callout-text"
                          >
                            {content.phone}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="phone"
                            id="phone"
                            className={`custome-input-text form-control ${
                              touched.phone && errors.phone ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="phone"
                            className="invalid-feedback"
                          />
                  
                        </FormGroup>
                        </Col>
                        <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="clinic_name"
                            className="address-title callout-text"
                          >
                            {content.clinic_name}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="clinic_name"
                            value={values.clinic_name}
                       
                            id="clinic_name"
                            className={`custome-input-text form-control ${
                              touched.clinic_name && errors.clinic_name ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="clinic_name"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                      {this.state.obj.id && (
                            <div
                              className="delete-address subhead-text"
                              onClick={() => {
                                // let obj = data.find((o) => o.id === prop.id);
                                this.setState({
                                  deleteModal: !this.state.deleteModal,
                                });
                              }}
                            >
                              {" "}
                              {content.delete_address}
                            </div>
                          )}

                      
                        <div className="advanceModuleInmobile">
                          {/* <FormGroup className={this.state.obj.id ? "footer-address-grid" : ""}> */}

                          <div className="">
                            <Button
                              className={`btn light-green-btn pull-right cml1 cpx3 new-btn-height button-seme-bold-text ${
                                this.state.obj.id ? "update-button-address" : ""
                              }`}
                              size="md"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {this.state.obj.country
                                ? `${content.update}`
                                : `${content.add}`}
                            </Button>
                            <Button
                              className="btn white-btn pull-right cml1 cpx3 new-btn-height button-seme-bold-text"
                              size="md"
                              color="danger"
                              onClick={this.toggleCreateUpdateAddressModal}
                            >
                              {content.cancel}
                            </Button>{" "}
                          </div>

                          {/* </FormGroup> */}
                        </div>
                     
                    </Row>
                  </div>
                  <div className="advanceModuleInWebsite">
                    <FormGroup
                      className={this.state.obj.id ? "footer-address-grid" : ""}
                    >
                      <div className="new-edit-addres-buttons-grid">
                        <Button
                          className={`btn light-green-btn pull-right cml1 cpx3 new-btn-height button-seme-bold-text ${
                            this.state.obj.id ? "update-button-address" : ""
                          }`}
                          size="md"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {this.state.obj.country
                            ? `${content.update}`
                            : `${content.add}`}
                        </Button>
                        <Button
                          className="btn white-btn pull-right cml1 cpx3 new-btn-heightbutton-seme-bold-text"
                          size="md"
                          color="danger"
                          onClick={this.toggleCreateUpdateAddressModal}
                        >
                          {content.cancel}
                        </Button>{" "}
                      </div>
                    </FormGroup>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Create and Update Adress modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Delete Address Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeleteModal}
          className="c-modal c-border-light confarim-modal"
        >
          {/* <ModalHeader toggle={this.toggleDeleteModal}>
                    </ModalHeader> */}
          <ModalBody>
            <CustomeHeader
              title={content.delete_address}
              largeFont={true}
              withoutLine={true}
            />

            <FormGroup className="cmt2">
              <h5 className="headline-text">{content.confirmation_delete}</h5>
            </FormGroup>
            <FormGroup>
              <div className="new-addres-delete-buttons">
                <Button
                  className="btn light-green-btn pull-right addres-delete-margin cpx3 button-seme-bold-text"
                  size="md"
                  type="submit"
                  onClick={this.DeleteAddress}
                >
                  {content.delete}
                </Button>
                <Button
                  className="btn white-btn pull-right addres-delete-margin cpx3 button-seme-bold-text"
                  size="md"
                  color="danger"
                  onClick={this.toggleDeleteModal}
                >
                  {content.cancel}
                </Button>
              </div>
            </FormGroup>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Delete Address Modal */}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
export default connect(mapStateToProps)(Address);
