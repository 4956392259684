import React from "react";
import trash from "../../assets/images/icon/trash.svg";
import { Input } from "reactstrap";

// this is shared component for add attchements on steps for each tooth in revesion flow
/* <IPRStepAmount
     StepTitle="Add attachments on step" 
     hyberText="Add another attachment" 
     steps_amounts_array={attachments_array}
     onclick={(value) => {
     this.handelChanges(value)
     }}
     content={content}
     useIPR={useIPR}
/> */

const IPRStepAmount = ({
  StepTitle,
  hyberText,
  AmountTitle,
  onclick,
  steps_amounts_array,
  content,
  useIPR,
}) => {
  //adding new ipr step and amount //
  const addStep = () => {
    let newStep = steps_amounts_array.concat({
      step: 0,
      amount: 0,
    });
    onclick(newStep);
  };

  //delete ipr Step  and amount //
  const removeStep = (index) => {
    let deleteStep = steps_amounts_array.filter((el, i) => i !== index);
    onclick(deleteStep);
  };

  //change ipr Step Value//
  const changeStepValue = (value, index) => {
    let steps_changing = steps_amounts_array.map((item, j) => {
      if (j === index) {
        return {
          ...item,
          step: value,
        };
      } else {
        return item;
      }
    });
    onclick(steps_changing);
  };
  //change ipr amount Value//

  const changeStepAmount = (value, index) => {
    let steps_changing = steps_amounts_array.map((item, j) => {
      if (j === index) {
        return {
          ...item,
          amount: value,
        };
      } else {
        return item;
      }
    });
    onclick(steps_changing);
  };

  return (
    <div>
      <div className=" cmt2 ipr_steps_grid">
        {steps_amounts_array.map((value, index) => (
          <div key={index}>
            <div className="attachment-movement-grid cmb1">
              <div>
                {index > 0 && (
                  <div
                    className={`${!useIPR ? "event-none" : ""} remove-step`}
                    onClick={() => removeStep(index)}
                  >
                    <img src={trash} alt="trash" />
                  </div>
                )}
              </div>
              <div>
                <div>
                  <span className="custom-dark-text">{AmountTitle}</span>
                  <span className="form-material">
                    <Input
                      type="number"
                      placeholder=""
                      className="step-number"
                      onClick={(e) => {
                        e.target.focus();
                      }}
                      onMouseUp={(e) => {
                        e.target.blur();
                      }}
                      value={value.amount}
                      key={index}
                      min={0}
                      step="0.1"
                      disabled={!useIPR}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          changeStepAmount(0, index);
                        } else {
                          changeStepAmount(
                            e.target.value.replace(/^0+/, ""),
                            index
                          );
                        }
                      }}
                    />
                  </span>
                </div>

                <div>
                  <span className="custom-dark-text">{StepTitle}</span>
                  <span className="form-material">
                    <Input
                      type="number"
                      placeholder=""
                      className="step-number"
                      onClick={(e) => {
                        e.target.focus();
                      }}
                      onMouseUp={(e) => {
                        e.target.blur();
                      }}
                      value={value.step}
                      key={index}
                      min={1}
                      step="1"
                      disabled={!useIPR}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          changeStepValue(0, index);
                        } else {
                          changeStepValue(
                            e.target.value.replace(/^0+/, ""),
                            index
                          );
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            {steps_amounts_array.length - 1 === index && (
              <div className="add-attachment-movement-grid">
                <div></div>
                <div
                  className={`${!useIPR ? "event-none" : "green_text"} add-step`}
                  onClick={addStep}
                >
                  {hyberText}{" "}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IPRStepAmount;
