import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import CustomToggle from "../../../../../components/toggle/CustomeToggle";
import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class BiteRamps extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_bite_ramps: this.props.caseSubmission.advanced_modules.biteRamps
        .useBiteRamps,
      incisors: this.props.caseSubmission.advanced_modules.biteRamps.incisors,
      incisors_and_canines: this.props.caseSubmission.advanced_modules.biteRamps
        .incisors_and_canines,
      bite_ramps_details: this.props.caseSubmission.advanced_modules.biteRamps
        .details,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "biteRamps",
      data: {
        useBiteRamps: this.state.use_bite_ramps,
        incisors: this.state.incisors,
        incisors_and_canines: this.state.incisors_and_canines,
        details: this.state.bite_ramps_details,
      },
    });
    this.props.save();
  };
  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const {
      use_bite_ramps,
      bite_ramps_details,
    } = this.state;
    const { content } = this.props.languageReducer

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

         
        {/* <div class="D-total-cont"> */}
<div className="customHeaderTS">
            <div> <CustomeHeader title={content.bite_ramps} className="cp3 pt-0" optional={false} note={false} note_text={content.BiteRamps_note} /></div>
            <div className="reative-close-ts">  <span className={` pull-right ts-close-button-new`} onClick={() => { this.props.toggleModalsClose(this.props.active_module) }}>&times;</span></div>
          </div>
          {/* </div> */}

          <div className="admin-Grid-Advance">
            <div></div>
            {/* part 1 */}
            <div className=" admin-Grid-Advance-inner D-head-ts">
              <div >

            {content.Used_bite_ramps_for_this_case}{" "}

              </div>
              {/* part 2 */}
              <div className="D-total-cont-toggle">
              <div className="pointer_disable">
                <CustomToggle

                  id="use_bite_ramps"
                  // disabled={this.props.summary}

                  checked={use_bite_ramps}
                  onChange={() => {
                    this.setState({
                      use_bite_ramps: !use_bite_ramps,

                      ...use_bite_ramps && {
                        incisors: false,
                        incisors_and_canines: false,
                        bite_ramps_details: ""
                      }
                    });
                  }}
                />
              </div>
              </div>
            </div>

            <div></div>
          </div>



          <div className=" D-total-cont-checkBox">
            <div className="address_discrepancy">
            <div className="radio-item  text_malocclusions" style={{ pointerEvents:  "none" }}>
                <input type="radio"
                  id='IncisorsTS'
                  name='BiteRamps'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  checked={this.state.incisors}
                  value={"Incisors"}
                  onClick={() => {
                    this.setState({
                      incisors: true,
                      incisors_and_canines: false,
                    });
                  }}
                />
                <label htmlFor='Incisors' >{content.incisors}</label>
              </div>




              <div className="radio-item  text_malocclusions" style={{ pointerEvents:  "none" }}>
                <input type="radio"
                  id='Incisors_and_caninesTs'
                  name='BiteRamps'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  value={"Incisors_and_canines"}
                  checked={this.state.incisors_and_canines}
                  onClick={() => {
                    this.setState({
                      incisors: false,
                      incisors_and_canines: true,
                    });
                  }}
                />
                <label htmlFor='Incisors_and_canines D-head-ts' >{content.incisors_and_canines}</label>
              </div>
              <br />

            </div>
</div>


            <p className="control-label text-left  light-green  D-head-ts ">{content.details}</p>

            <div className="">
            <div className="disable-input">
              <Input
               className="mt-2  border-radius backgrountTextarea "
                name="bite_ramps_details"
                id="bite_ramps_details"
                type="textarea"
                placeholder="Please add details"
                value={bite_ramps_details}
                rows={5}
                onChange={(e) => {
                  this.setState({ bite_ramps_details: e.target.value });
                }}
                readOnly />{" "}
                </div>
            </div>
          </div>


        {!this.props.summary && <Button
          className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BiteRamps);
