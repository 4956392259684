import React, { Component } from "react";
import { connect } from "react-redux";
import { getRetainers } from "../../../api/api";
import moment from "moment";
import {
    Spinner,
  } from "reactstrap";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
class RequestedRetainers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      case: this.props.case,
      doctorId: this.props.doctorId,
   
      loading: true,
      loading_percentage: 0,
      showProgress: false,
      loading_percentage_lower_arch_url: 0,
      loading_percentage_upper_arch_url: 0,
      retainers_List: [],
    };
    this.loader = null;
  }

  componentDidMount() {
    this.GetRetainers();
  }

  /*--------------------------------------------------------------------------------*/
  /* Reques Retainer                                                       */
  /*--------------------------------------------------------------------------------*/
  GetRetainers = () => {
    getRetainers(this.state.doctorId, this.state.case.uid).then((res) => {
      this.setState({
        retainers_List: res,

        loading: false,
      });
    });
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    clearTimeout(this.loader);
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { content } = this.props.languageReducer
    return (
      <div className="">
        {this.state.loading ? (
       <div className="row spinner-container justify-content-center align-items-center">
       <Spinner className="green-spinner" type="grow" />
     </div>
        ) : (
          <div>
            {this.state.retainers_List.length !== 0 && (
              <div className="tabel-request-retqiner-container-total">
                <div></div>
                <div className="tabel-request-retqiner-container">
                  <div className="Impression_hieght_retainer_request_tabel">
                    <div className="retainer-request-table ciles ">
                      <div className="control-label title_active ciles-text2 font_request ciles-text-first first-cile body-text">
                      {content.Date_of_request}
                      </div>
                      <div className="control-label title_active ciles-text2  font_request ciles-text-first  last-cile body-text">
                   {content.Number_of_sets}
                      </div>
                      <div className="control-label title_active ciles-text2  font_request ciles-text-first  last-cile body-text">
                   {content.status}
                      </div>
                    </div>
                    <div className="tabel-body-total">
                      {this.state.retainers_List.map((value ,index) => {
                        return (
                          <div className="retainer-request-table-body"   key={index}>
                            <div className="new-table-info2 new-table-info-first font_request_body  ciles-row body-text">
                              {`${moment(new Date(value.created_at)).format(
                                "DD/ MM /YYYY"
                              )}`}{" "}
                            </div>
                            <div className="new-table-info2 new-table-info-first font_request_body  ciles-row body-text">
                              {value.sets === 1
                                ? `${value.sets} set`
                                : `${value.sets} sets`}{" "}
                            </div>
                            <div className="new-table-info2 new-table-info-first font_request_body  ciles-row body-text">
                              {value.status.replace(/_/g, " ")}

                          
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestedRetainers);
