import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Case_Crossbites,
  Case_Attachments,
  Case_Elastics,
  Case_Extraction,
  Case_IPR,
  Case_Overcorrections,
  Case_Passive_Aligners,
  Case_Pontics,
  Case_Tooth_Movement_Restrictions,
  Case_Torque_Enhancers,
  Case_Arch_Expansion,
  Doctor_Preferences_Attachments,
  Doctor_Preferences_IPR,
  Case_Elastics_Buttons,
  Case_Elastics_Cuts,
} from "../../redux/customeTeeth/action";
import { setUnsavedKeys } from "../../redux/changes/action";
import { setTeethIPRData } from "../../redux/Tooth_IPR_Options/action";
import restrict from "../../assets/images/icon/restrect.svg";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  Case_Tooth_Movement_Restrictions: (payload) =>
    dispatch(Case_Tooth_Movement_Restrictions(payload)),

  Case_Extraction: (payload) => dispatch(Case_Extraction(payload)),

  Case_Crossbites: (payload) => dispatch(Case_Crossbites(payload)),

  Case_Attachments: (payload) => dispatch(Case_Attachments(payload)),
  Case_Arch_Expansion: (payload) => dispatch(Case_Arch_Expansion(payload)),
  Case_IPR: (payload) => dispatch(Case_IPR(payload)),
  Case_Pontics: (payload) => dispatch(Case_Pontics(payload)),
  Case_Overcorrections: (payload) => dispatch(Case_Overcorrections(payload)),
  Case_Passive_Aligners: (payload) => dispatch(Case_Passive_Aligners(payload)),
  Case_Torque_Enhancers: (payload) => dispatch(Case_Torque_Enhancers(payload)),
  Case_Elastics: (payload) => dispatch(Case_Elastics(payload)),
  Case_Elastics_Buttons: (payload) => dispatch(Case_Elastics_Buttons(payload)),
  Case_Elastics_Cuts: (payload) => dispatch(Case_Elastics_Cuts(payload)),

  Doctor_Preferences_Attachments: (payload) =>
    dispatch(Doctor_Preferences_Attachments(payload)),
  Doctor_Preferences_IPR: (payload) =>
    dispatch(Doctor_Preferences_IPR(payload)),
  setTeethIPRData: (payload) => dispatch(setTeethIPRData(payload)),
  setUnsavedKeys: (payload) => dispatch(setUnsavedKeys(payload)),
});
class CustomeMovementTeeth extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      upper_teeth: props.teeth["upper_teeth"],
      lower_teeth: props.teeth["lower_teeth"],
      numbering: this.props.userReducer.currentUser.dental_notation,
    };
  }

  render() {
    let upper_teeth = this.props.teeth["upper_teeth"];
    let lower_teeth = this.props.teeth["lower_teeth"];
    let saved_upper_teeth = this.props.saved_teeth_data["upper_teeth"];
    let saved_lower_teeth = this.props.saved_teeth_data["lower_teeth"];
    const { content } = this.props.languageReducer;

    return (
      <div
        className={`${
          this.props.hideSide
            ? "custom-teeth-selector-container-summary"
            : "custom-teeth-selector-container"
        }`}
      >
        <div className="teeth_container_grid">
          {this.props.userReducer.currentUser.dental_notation === "palmer" ? (
            <div className={`text-center dir `}>
              <div>UR</div>
              <div>LR</div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="side-teeth-grid">
            <div className="teeth-grid align-items-end">
              {Object.keys(upper_teeth)
                .filter((value, index) => upper_teeth[value].key < 9)
                .map((prop, key) => {
                  return (
                    <div key={key}>
                      <img
                        className={this.props.disabled ? "disabled-teeth" : ""}
                        style={{
                          pointerEvents: this.props.disableChanges
                            ? "none"
                            : "",
                          cursor: "pointer",
                        }}
                        // className="teeth"
                        alt={prop}
                        src={upper_teeth[prop].movement_image}
                        //onMouseOver
                        onMouseOver={(e) => {
                          // if tooth is active show active tooth on Mouse Over
                          // else show hovered tooth
                          e.currentTarget.src =
                            upper_teeth[prop].movement.updated &&
                            !upper_teeth[prop].movement.restrict_tooth
                              ? upper_teeth[prop].update
                              : upper_teeth[prop].movement.restrict_tooth
                              ? upper_teeth[prop].restrect
                              : upper_teeth[prop].value
                              ? upper_teeth[prop].active_movement
                              : upper_teeth[prop].hover;
                        }}
                        //onMouseOut
                        onMouseOut={(e) => {
                          // if tooth is active show active tooth on Mouse Out
                          // else show not active tooth
                          e.currentTarget.src =
                            upper_teeth[prop].movement.updated &&
                            !upper_teeth[prop].movement.restrict_tooth
                              ? upper_teeth[prop].update
                              : upper_teeth[prop].movement.restrict_tooth
                              ? upper_teeth[prop].restrect
                              : upper_teeth[prop].value
                              ? upper_teeth[prop].active_movement
                              : upper_teeth[prop].not_active;
                        }}
                        //onclick
                        onClick={(e) => {
                          // if tooth is active change value to not active and show not active tooth
                          // else change value to active and show active tooth
                          let mapped_data = {
                            upper_teeth: {
                              ...upper_teeth,
                              [prop]: {
                                ...upper_teeth[prop],
                                movement_image: !upper_teeth[prop].value
                                  ? upper_teeth[prop].active_movement
                                  : upper_teeth[prop].not_active,
                                value: !upper_teeth[prop].value,
                              },
                            },
                            lower_teeth: lower_teeth,
                          };
                          this.props.setTeethIPRData(mapped_data);
                          this.props.changeToothData(
                            upper_teeth[prop],
                            "upper_teeth",
                            prop,
                            saved_upper_teeth[prop].movement
                          );
                          this.props.setUnsavedKeys({
                            current_ipr_position: "upper_teeth",
                            current_ipr_tooth: prop,
                            step: "tooth_movement",
                          });
                          // change values in reducer
                          // this.props.teeth(mapped_data);
                          // if (this.props?.key === "malocclusion") {
                          //   this.props.UpdateCrossBiteReducer();
                          // }
                        }}
                      ></img>
                    </div>
                  );
                })}
            </div>
            <div>
              {/*--------------------------------------------------------------------------------*/}
              {/* Plamer tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation ===
                "palmer" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">8</div>
                    <div className="text-center">7</div>
                    <div className="text-center">6</div>
                    <div className="text-center">5</div>
                    <div className="text-center">4</div>
                    <div className="text-center">3</div>
                    <div className="text-center">2</div>
                    <div className="text-center">1</div>
                  </div>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">8</div>
                    <div className="text-center">7</div>
                    <div className="text-center">6</div>
                    <div className="text-center">5</div>
                    <div className="text-center">4</div>
                    <div className="text-center">3</div>
                    <div className="text-center">2</div>
                    <div className="text-center">1</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Plamer tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* FDI tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation === "fdi" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">18</div>
                    <div className="text-center">17</div>
                    <div className="text-center">16</div>
                    <div className="text-center">15</div>
                    <div className="text-center">14</div>
                    <div className="text-center">13</div>
                    <div className="text-center">12</div>
                    <div className="text-center">11</div>
                  </div>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">48</div>
                    <div className="text-center">47</div>
                    <div className="text-center">46</div>
                    <div className="text-center">45</div>
                    <div className="text-center">44</div>
                    <div className="text-center">43</div>
                    <div className="text-center">42</div>
                    <div className="text-center">41</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End FDI tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* Universal tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation ===
                "universal" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">1</div>
                    <div className="text-center">2</div>
                    <div className="text-center">3</div>
                    <div className="text-center">4</div>
                    <div className="text-center">5</div>
                    <div className="text-center">6</div>
                    <div className="text-center">7</div>
                    <div className="text-center">8</div>
                  </div>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">32</div>
                    <div className="text-center">31</div>
                    <div className="text-center">30</div>
                    <div className="text-center">29</div>
                    <div className="text-center">28</div>
                    <div className="text-center">27</div>
                    <div className="text-center">26</div>
                    <div className="text-center">25</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Universal tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
            </div>
            <div className="teeth-grid align-items-start">
              {Object.keys(lower_teeth)
                .filter((value, index) => lower_teeth[value].key < 9)
                .map((prop, key) => {
                  return (
                    <div key={key}>
                      <img
                        className={this.props.disabled ? "disabled-teeth" : ""}
                        style={{
                          pointerEvents: this.props.disableChanges
                            ? "none"
                            : "",
                          cursor: "pointer",
                        }}
                        // className="teeth"
                        alt={prop}
                        src={lower_teeth[prop].movement_image}
                        //onMouseOver
                        onMouseOver={(e) => {
                          e.currentTarget.src =
                            lower_teeth[prop].movement.updated &&
                            !lower_teeth[prop].movement.restrict_tooth
                              ? lower_teeth[prop].update
                              : lower_teeth[prop].movement.restrict_tooth
                              ? lower_teeth[prop].restrect
                              : lower_teeth[prop].value
                              ? lower_teeth[prop].active_movement
                              : lower_teeth[prop].hover;
                        }}
                        //onMouseOut
                        onMouseOut={(e) => {
                          e.currentTarget.src =
                            lower_teeth[prop].movement.updated &&
                            !lower_teeth[prop].movement.restrict_tooth
                              ? lower_teeth[prop].update
                              : lower_teeth[prop].movement.restrict_tooth
                              ? lower_teeth[prop].restrect
                              : lower_teeth[prop].value
                              ? lower_teeth[prop].active_movement
                              : lower_teeth[prop].not_active;
                        }}
                        //onClick
                        onClick={(e) => {
                          let mapped_data = {
                            lower_teeth: {
                              ...lower_teeth,
                              [prop]: {
                                ...lower_teeth[prop],
                                movement_image: !lower_teeth[prop].value
                                  ? lower_teeth[prop].active_movement
                                  : lower_teeth[prop].not_active,
                                value: !lower_teeth[prop].value,
                              },
                            },
                            upper_teeth: upper_teeth,
                          };
                          this.props.setTeethIPRData(mapped_data);
                          this.props.changeToothData(
                            lower_teeth[prop],
                            "lower_teeth",
                            prop,
                            saved_lower_teeth[prop].movement
                          );
                          this.props.setUnsavedKeys({
                            current_ipr_position: "lower_teeth",
                            current_ipr_tooth: prop,
                            step: "tooth_movement",
                          });
                          // this.props.teeth(mapped_data);
                          // if (this.props?.key === "malocclusion") {
                          //   this.props.UpdateCrossBiteReducer();
                          // }
                        }}
                      ></img>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="teeth_divider"></div>
          <div className="side-teeth-grid">
            <div className="teeth-grid align-items-end">
              {Object.keys(upper_teeth)
                .filter((value) => upper_teeth[value].key >= 9)
                .map((prop, key) => {
                  return (
                    <div key={key}>
                      <img
                        className={this.props.disabled ? "disabled-teeth" : ""}
                        style={{
                          pointerEvents: this.props.disableChanges
                            ? "none"
                            : "",
                          cursor: "pointer",
                        }}
                        // className="teeth"
                        alt={prop}
                        src={upper_teeth[prop].movement_image}
                        //onMouseOver
                        onMouseOver={(e) => {
                          // if tooth is active show active tooth on Mouse Over
                          // else show hovered tooth

                          e.currentTarget.src =
                            upper_teeth[prop].movement.updated &&
                            !upper_teeth[prop].movement.restrict_tooth
                              ? upper_teeth[prop].update
                              : upper_teeth[prop].movement.restrict_tooth
                              ? upper_teeth[prop].restrect
                              : upper_teeth[prop].value
                              ? upper_teeth[prop].active_movement
                              : upper_teeth[prop].hover;
                        }}
                        //onMouseOut
                        onMouseOut={(e) => {
                          // if tooth is active show active tooth on Mouse Out
                          // else show not active tooth
                          e.currentTarget.src =
                            upper_teeth[prop].movement.updated &&
                            !upper_teeth[prop].movement.restrict_tooth
                              ? upper_teeth[prop].update
                              : upper_teeth[prop].movement.restrict_tooth
                              ? upper_teeth[prop].restrect
                              : upper_teeth[prop].value
                              ? upper_teeth[prop].active_movement
                              : upper_teeth[prop].not_active;
                        }}
                        //onclick
                        onClick={(e) => {
                          // if tooth is active change value to not active and show not active tooth
                          // else change value to active and show active tooth
                          let mapped_data = {
                            upper_teeth: {
                              ...upper_teeth,
                              [prop]: {
                                ...upper_teeth[prop],
                                movement_image: !upper_teeth[prop].value
                                  ? upper_teeth[prop].active_movement
                                  : upper_teeth[prop].not_active,
                                value: !upper_teeth[prop].value,
                              },
                            },
                            lower_teeth: lower_teeth,
                          };
                          this.props.setTeethIPRData(mapped_data);
                          this.props.changeToothData(
                            upper_teeth[prop],
                            "upper_teeth",
                            prop,
                            saved_upper_teeth[prop].movement
                          );
                          this.props.setUnsavedKeys({
                            current_ipr_position: "upper_teeth",
                            current_ipr_tooth: prop,
                            step: "tooth_movement",
                          });
                          // change values in reducer
                          // this.props.teeth(mapped_data);
                          // if (this.props?.key === "malocclusion") {
                          //   this.props.UpdateCrossBiteReducer();
                          // }
                        }}
                      ></img>
                    </div>
                  );
                })}
            </div>
            <div>
              {/*--------------------------------------------------------------------------------*/}
              {/* Plamer tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation ===
                "palmer" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">1</div>
                    <div className="text-center">2</div>
                    <div className="text-center">3</div>
                    <div className="text-center">4</div>
                    <div className="text-center">5</div>
                    <div className="text-center">6</div>
                    <div className="text-center">7</div>
                    <div className="text-center">8</div>
                  </div>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">1</div>
                    <div className="text-center">2</div>
                    <div className="text-center">3</div>
                    <div className="text-center">4</div>
                    <div className="text-center">5</div>
                    <div className="text-center">6</div>
                    <div className="text-center">7</div>
                    <div className="text-center">8</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Plamer tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* FDI tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation === "fdi" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">21</div>
                    <div className="text-center">22</div>
                    <div className="text-center">23</div>
                    <div className="text-center">24</div>
                    <div className="text-center">25</div>
                    <div className="text-center">26</div>
                    <div className="text-center">27</div>
                    <div className="text-center">28</div>
                  </div>

                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">31</div>
                    <div className="text-center">32</div>
                    <div className="text-center">33</div>
                    <div className="text-center">34</div>
                    <div className="text-center">35</div>
                    <div className="text-center">36</div>
                    <div className="text-center">37</div>
                    <div className="text-center">38</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End FDI tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* Universal tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation ===
                "universal" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">9</div>
                    <div className="text-center">10</div>
                    <div className="text-center">11</div>
                    <div className="text-center">12</div>
                    <div className="text-center">13</div>
                    <div className="text-center">14</div>
                    <div className="text-center">15</div>
                    <div className="text-center">16</div>
                  </div>

                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">24</div>
                    <div className="text-center">23</div>
                    <div className="text-center">22</div>
                    <div className="text-center">21</div>
                    <div className="text-center">20</div>
                    <div className="text-center">19</div>
                    <div className="text-center">18</div>
                    <div className="text-center">17</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Universal tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
            </div>
            <div className="teeth-grid align-items-start">
              {Object.keys(lower_teeth)
                .filter((value, index) => lower_teeth[value].key >= 9)
                .map((prop, key) => {
                  return (
                    <div key={key}>
                      <img
                        className={this.props.disabled ? "disabled-teeth" : ""}
                        style={{
                          pointerEvents: this.props.disableChanges
                            ? "none"
                            : "",
                          cursor: "pointer",
                        }}
                        // className="teeth"
                        alt={prop}
                        src={lower_teeth[prop].movement_image}
                        //onMouseOver
                        onMouseOver={(e) => {
                          e.currentTarget.src =
                            lower_teeth[prop].movement.updated &&
                            !lower_teeth[prop].movement.restrict_tooth
                              ? lower_teeth[prop].update
                              : lower_teeth[prop].movement.restrict_tooth
                              ? lower_teeth[prop].restrect
                              : lower_teeth[prop].value
                              ? lower_teeth[prop].active_movement
                              : lower_teeth[prop].hover;
                        }}
                        //onMouseOut
                        onMouseOut={(e) => {
                          e.currentTarget.src =
                            lower_teeth[prop].movement.updated &&
                            !lower_teeth[prop].movement.restrict_tooth
                              ? lower_teeth[prop].update
                              : lower_teeth[prop].movement.restrict_tooth
                              ? lower_teeth[prop].restrect
                              : lower_teeth[prop].value
                              ? lower_teeth[prop].active_movement
                              : lower_teeth[prop].not_active;
                        }}
                        //onClick
                        onClick={(e) => {
                          let mapped_data = {
                            lower_teeth: {
                              ...lower_teeth,
                              [prop]: {
                                ...lower_teeth[prop],
                                movement_image: !lower_teeth[prop].value
                                  ? lower_teeth[prop].active_movement
                                  : lower_teeth[prop].not_active,
                                value: !lower_teeth[prop].value,
                              },
                            },
                            upper_teeth: upper_teeth,
                          };
                          this.props.setTeethIPRData(mapped_data);
                          this.props.changeToothData(
                            lower_teeth[prop],
                            "lower_teeth",
                            prop,
                            saved_lower_teeth[prop].movement
                          );
                          this.props.setUnsavedKeys({
                            current_ipr_position: "lower_teeth",
                            current_ipr_tooth: prop,
                            step: "tooth_movement",
                          });
                          // this.props.teeth(mapped_data);
                          // if (this.props?.key === "malocclusion") {
                          //   this.props.UpdateCrossBiteReducer();
                          // }
                        }}
                      ></img>
                    </div>
                  );
                })}
            </div>
          </div>
          {this.props.userReducer.currentUser.dental_notation === "palmer" ? (
            <div className={`text-center dir `}>
              <div>UL</div>
              <div>LL</div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {!this.props.hideSide && (
          <div className="custom-teeth-selector-codes">
            <div className="color-code-grid">
              <div className="color not_edited"></div>
              <div className="title">{content.un_edited_tooth}</div>
            </div>

            <div className="color-code-grid">
              <div className="color edited"></div>
              <div className="title">{content.edited_tooth}</div>
            </div>

            <div className="color-code-grid">
              <div className="color active"></div>
              <div className="title">{content.active_tooth}</div>
            </div>

            <div className="color-code-grid restrict-grid">
              <img src={restrict} alt="restrict"></img>
              <div className="title">{content.restricted_tooth}</div>
              <div className="note-tooltip">
                <span
                  className="icon-container tooltip1 new-tooltip"
                  id="HybridTreatmentMoreInfo"
                >
                  <i className="fas fa-question-circle "></i>
                  <div
                    className={`${
                      window.innerWidth < 840
                        ? "tooltip-bottom"
                        : "tooltip-bottom"
                    }  custom_shadow`}
                  >
                    <div className="tooltip-body">{"note_text"}</div>
                    <i></i>
                  </div>
                </span>
              </div>
            </div>
          </div>
        )}{" "}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomeMovementTeeth);
