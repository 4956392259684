import { upper_teeth, lower_teeth } from "../redux/newCase/init_teeth";
import { findNumber } from "./findNumbers";

export function mapTeethFDI(newData, teethMovement, advanced_modules) {

 
  let RightTeethUpper = mapRightTeeth(
    newData,
    "upper_r_",
    upper_teeth,
    "R",
    "upper_teeth",
    18,
    false,
    "UR",
    teethMovement,
    advanced_modules
  );
  let RightTeethLower = mapRightTeeth(
    newData,
    "lower_r_",
    lower_teeth,
    "R",
    "lower_teeth",
    48,
    false,
    "LR",
    teethMovement,
    advanced_modules
  );

  let LeftTeethUpper = mapLeftTeeth(
    newData,
    "upper_l_",
    upper_teeth,
    "L",
    "upper_teeth",
    21,
    true,
    "UL",
    teethMovement,
    advanced_modules
  );
  let LeftTeethLower = mapLeftTeeth(
    newData,
    "lower_l_",
    lower_teeth,
    "L",
    "lower_teeth",
    31,
    true,
    "LL",
    teethMovement,
    advanced_modules
  );

  let teeth = {
    upper_teeth: { ...RightTeethUpper, ...LeftTeethUpper },
    lower_teeth: { ...RightTeethLower, ...LeftTeethLower },
    all : newData?.all
  };
  return teeth;
}

function mapRightTeeth(
  newData,
  key,
  teeth,
  side,
  position,
  counter,
  increment,
  position2,
  teethMovement,
  advanced_modules
) {
  let num = 8;
  let count = counter;
  let rightTeeth = {};

 
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]:
        newData !== undefined &&
        newData[position] !== {} &&
        newData[position] !== undefined
          ? {
              ...teeth[`${key}${num}`],
              ...newData[position][`${key}${num}`],
              image: newData[position][`${key}${num}`].restrict_ipr_tooth
                ? teeth[`${key}${num}`].restrect
                : newData[position][`${key}${num}`].value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
              movement_image: newData[position][`${key}${num}`].value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
              value: newData[position][`${key}${num}`].value,
              updated: newData[position][`${key}${num}`].updated || false,
              ToothSystemName: findNumber("palmer", `${position2}${num}`),
              ipr_steps_amounts: newData[position][`${key}${num}`]
                .ipr_steps_amounts || [{ step: 0, amount: 0 }],
              restrict_ipr_tooth:
                newData[position][`${key}${num}`].restrict_ipr_tooth || false,
              tooth_key_sys: {
                palmer: `${
                  findNumber("palmer", `${position2}${num}`)?.palmer
                } / ${findNumber("palmer", `${position2}${num - 1}`)?.palmer}`,
                fdi: `${findNumber("palmer", `${position2}${num}`)?.fdi} / ${
                  findNumber("palmer", `${position2}${num - 1}`)?.fdi
                }`,
                universal: `${
                  findNumber("palmer", `${position2}${num}`)?.universal
                } / ${
                  findNumber("palmer", `${position2}${num - 1}`)?.universal
                }`,
              },
              movement: findMovement(
                newData[position][`${key}${num}`].fdi_key,
                teethMovement,
                position,
                `${key}${num}`,
                advanced_modules
              ),
            }
          : {
              ...teeth[`${key}${num}`],
              updated: false,
              index: num,
              amount: "",
              before_step: "",
              divider: true,
              use_ipr: false,
              do_not_perform_ipr: false,
              fdi_key: increment ? count + 1 : count - 1,
              tooth_key: `${position2}_${num} / ${position2}_${num - 1}`,
              ipr_steps_amounts: [{ step: 0, amount: 0 }],
              restrict_ipr_tooth: false,
              tooth_key_sys: {
                palmer: `${
                  findNumber("palmer", `${position2}${num}`)["palmer"]
                } / ${
                  findNumber("palmer", `${position2}${num - 1}`)["palmer"]
                }`,
                fdi: `${findNumber("palmer", `${position2}${num}`)["fdi"]} / ${
                  findNumber("palmer", `${position2}${num - 1}`)["fdi"]
                }`,
                universal: `${
                  findNumber("palmer", `${position2}${num}`)["universal"]
                } / ${
                  findNumber("palmer", `${position2}${num - 1}`)["universal"]
                }`,
              },
              ToothSystemName: findNumber("palmer", `${position2}${num}`),

              movement: findMovement(
                increment ? count + 1 : count - 1,
                teethMovement,
                position,
                `${key}${num}`,
                advanced_modules
              ),
            },
    };
    num--;
  }
  return rightTeeth;
}

function mapLeftTeeth(
  newData,
  key,
  teeth,
  side,
  position,
  counter,
  increment,
  position2,
  teethMovement,
  advanced_modules
) {
  let num = 1;
  let count = counter;

  let leftTeeth = {};
  for (var x = 1; x < 9; x++) {
    leftTeeth = {
      ...leftTeeth,

      [`${key}${num}`]:
        newData !== undefined &&
        newData[position] !== {} &&
        newData[position] !== undefined
          ? {
              ...teeth[`${key}${num}`],
              ...newData[position][`${key}${num}`],
              image: newData[position][`${key}${num}`].restrict_ipr_tooth
                ? teeth[`${key}${num}`].restrect
                : newData[position][`${key}${num}`].value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
              movement_image: newData[position][`${key}${num}`].value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
              value: newData[position][`${key}${num}`].value,
              updated: newData[position][`${key}${num}`].updated || false,
              ToothSystemName: findNumber("palmer", `${position2}${num}`),
              ipr_steps_amounts: newData[position][`${key}${num}`]
                .ipr_steps_amounts || [{ step: 0, amount: 0 }],
              restrict_ipr_tooth:
                newData[position][`${key}${num}`].restrict_ipr_tooth || false,
              tooth_key_sys: {
                palmer: `${
                  findNumber("palmer", `${position2}${num}`)?.palmer
                } / ${findNumber("palmer", `${position2}${num + 1}`)?.palmer}`,
                fdi: `${findNumber("palmer", `${position2}${num}`).fdi} / ${
                  findNumber("palmer", `${position2}${num + 1}`)?.fdi
                }`,
                universal: `${
                  findNumber("palmer", `${position2}${num}`)?.universal
                } / ${
                  findNumber("palmer", `${position2}${num + 1}`)?.universal
                }`,
              },
              movement: findMovement(
                newData[position][`${key}${num}`].fdi_key,
                teethMovement,
                position,
                `${key}${num}`,
                advanced_modules
              ),
            }
          : {
              ...teeth[`${key}${num}`],
              updated: false,
              ...(x < 8 && {
                amount: "",
                before_step: "",
                divider: true,
                use_ipr: false,
                do_not_perform_ipr: false,
                restrict_ipr_tooth: false,
                tooth_key_sys: {
                  palmer: `${
                    findNumber("palmer", `${position2}${num}`)?.palmer
                  } / ${
                    findNumber("palmer", `${position2}${num + 1}`)?.palmer
                  }`,
                  fdi: `${findNumber("palmer", `${position2}${num}`).fdi} / ${
                    findNumber("palmer", `${position2}${num + 1}`)?.fdi
                  }`,
                  universal: `${
                    findNumber("palmer", `${position2}${num}`)?.universal
                  } / ${
                    findNumber("palmer", `${position2}${num + 1}`)?.universal
                  }`,
                },
                tooth_key: `${position2}_${num} / ${position2}_${num + 1}`,
                ipr_steps_amounts: [{ step: 0, amount: 0 }],
              }),
              fdi_key: increment ? count + 1 : count - 1,
              ToothSystemName: findNumber("palmer", `${position2}${num}`),
              movement: findMovement(
                increment ? count + 1 : count - 1,
                teethMovement,
                position,
                `${key}${num}`,
                advanced_modules
              ),
            },
    };
    num++;
  }
  return leftTeeth;
}

function findMovement(
  tooth,
  teethMovement,
  position,
  tooth_name,
  advanced_modules
) {
  let filtered_teeth =
    teethMovement?.filter((value) => {
      return Number(value.ToothNumber) === tooth;
    }) || [];
  if (filtered_teeth.length > 0) {
    filtered_teeth[0]["Angulation"] =
      filtered_teeth[0]["Angulation"] || "0 deg";
    filtered_teeth[0]["Circumference"] =
      filtered_teeth[0]["Circumference"] || "";
    filtered_teeth[0]["ExtrusionIntrusion"] =
      filtered_teeth[0]["ExtrusionIntrusion"] || "0 mm";
    filtered_teeth[0]["ForwardBackward"] =
      filtered_teeth[0]["ForwardBackward"] || "0 mm";
    filtered_teeth[0]["Inclination"] =
      filtered_teeth[0]["Inclination"] || "0 deg";
    filtered_teeth[0]["IprDistal"] = filtered_teeth[0]["IprDistal"] || "";
    filtered_teeth[0]["IprMesial"] = filtered_teeth[0]["IprMesial"] || "";
    filtered_teeth[0]["LeftRight"] = filtered_teeth[0]["LeftRight"] || "0 mm";
    filtered_teeth[0]["LongAxis"] = filtered_teeth[0]["LongAxis"] || "";
    filtered_teeth[0]["Rotation"] = filtered_teeth[0]["Rotation"] || "0 deg";
    filtered_teeth[0]["updated"] = false;
    filtered_teeth[0]["ToothName"] = tooth_name;
    filtered_teeth[0]["use_extraction"] =
      advanced_modules?.extraction?.teeth[position][tooth_name].value || false;
    filtered_teeth[0]["useAttachments"] =
      advanced_modules?.attachments?.attachmentsRestriction[position][
        tooth_name
      ].value || false;
    filtered_teeth[0]["attachments_step"] =
      advanced_modules?.attachments?.attachmentsTiming || "";
    filtered_teeth[0]["attachment_type"] =
      advanced_modules?.attachments?.attachment_type || "";
    
    filtered_teeth[0]["usePontics"] =
      advanced_modules?.pontics?.teeth[position][tooth_name].value || false;
    filtered_teeth[0]["useTorqueEnhancers"] =
      advanced_modules?.torqueEnhancers?.teeth[position][tooth_name].value ||
      false;
    filtered_teeth[0]["useElastics"] =
      advanced_modules?.elastics?.buttons_teeth[position][tooth_name].value ||
      advanced_modules?.elastics?.cuts_teeth[position][tooth_name].value ||
      false;
    filtered_teeth[0]["use_buttons"] =
      advanced_modules?.elastics?.buttons_teeth[position][tooth_name].value ||
      false;
    filtered_teeth[0]["use_cuts"] =
      advanced_modules?.elastics?.cuts_teeth[position][tooth_name].value ||
      false;
    filtered_teeth[0]["note"] = "";
    filtered_teeth[0]["attachments"] = advanced_modules?.attachments
      ?.attachmentsRestriction?.[position][tooth_name]
      .attachment_steps_typs || [{ step: 0, type: "" }];
      filtered_teeth[0]["Attachment_Note"] ="";
      // advanced_modules?.attachments?.Attachment_Note || "";
    filtered_teeth[0]["elastics"] = GetElasticsArray(
      advanced_modules?.elastics?.buttons_teeth[position][tooth_name]
        ?.elastic_stages,
      advanced_modules?.elastics?.cuts_teeth[position][tooth_name]
        ?.elastic_stages
    );

    return filtered_teeth[0];
  } else {
    return {
      Angulation: "0 deg",
      Circumference: "",
      ExtrusionIntrusion: "0 mm",
      ForwardBackward: "0 mm",
      Inclination: "0 deg",
      IprDistal: "",
      IprMesial: "",
      LeftRight: "0 mm",
      LongAxis: "",
      Rotation: "0 deg",
      use_extraction:
        advanced_modules?.extraction?.teeth[position][tooth_name].value ||
        false,
      useAttachments:
        advanced_modules?.attachments?.attachmentsRestriction[position][
          tooth_name
        ].value || false,
      attachments_step: advanced_modules?.attachments?.attachmentsTiming || "",
      attachment_type: advanced_modules?.attachments?.attachment_type || "",
      usePontics:
        advanced_modules?.pontics?.teeth[position][tooth_name].value || false,
      useTorqueEnhancers:
        advanced_modules?.torqueEnhancers?.teeth[position][tooth_name].value ||
        false,
      use_buttons:
        advanced_modules?.elastics?.buttons_teeth[position][tooth_name].value ||
        false,
      use_cuts:
        advanced_modules?.elastics?.cuts_teeth[position][tooth_name].value ||
        false,
      //elastic
      useElastics:
        advanced_modules?.elastics?.cuts_teeth[position][tooth_name].value ||
        advanced_modules?.elastics?.buttons_teeth[position][tooth_name].value ||
        false,

      ToothNumber: tooth,
      ToothName: tooth_name,
      updated: false,
      note: "",
      restrict_tooth: false,
      attachments: [
        {
          step: 0,
          type: "",
        },
      ],
      Attachment_Note:"",
      elastics: [{ from: 0, to: 0 }],
    };
  }
}
const GetElasticsArray = (arr1, arr2) => {
  let newArr = arr1.concat(arr2);
  let filtered = newArr.filter((value) => {
    return value.from !== 0 && value.to !== 0;
  });
  if (filtered.length > 0) {
    return filtered;
  } else {
    return [{ from: 0, to: 0 }];
  }
};
