import React, { Component } from "react";
import { connect } from "react-redux";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({

});
class Loyalty extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {

    super(props);
    /*inint state*/
    this.state = {}
  }
    render() {
            const {  onChange } = this.props
            const {loyalty} =  this.props.userReducer.currentUser
            const { content } = this.props.languageReducer;
        
    return (
    <div className='loyalty-container'>
        <div className='loyalty-header'>{content.your_loyalty_status} <span 
          
         onClick={() => {
            onChange()
        }}
        className='benefits-note subhead-text'>{content.What_are_my_benefits}</span></div>
        <div className='loyalty-grid'>
            <a className={`loyalty-start ${loyalty.active_tier?.name === "Bronze" ? "loyalty-bronze-active" : 'loyalty-bronze'}`} href='/loyalty' >
                {content.bronze}
            </a>
            <a className={`loyalty-mid ${loyalty.active_tier?.name === "Silver" ? "loyalty-silver-active" : 'loyalty-silver'}`} href='/loyalty' >
                {content.silver}
            </a>
            <a className={`loyalty-mid ${loyalty.active_tier?.name === "Gold" ? "loyalty-gold-active" : 'loyalty-gold'}`} href='/loyalty' >
                {content.gold}
            </a>
            <a className={`loyalty-mid ${loyalty.active_tier?.name === "Platinum" ? "loyalty-platinum-active" : 'loyalty-platinum '}`} href='/loyalty' >
                {content.platinum}
            </a>
            <a className={`loyalty-mid ${loyalty.active_tier?.name === "Diamond" ? "loyalty-diamond-active" : 'loyalty-diamond'}`} href='/loyalty' >
                {content.diamond}
            </a>
            <a className={`loyalty-end ${loyalty.active_tier?.name === "Elite" ? "loyalty-elite-active" : 'loyalty-elite'}`} href='/loyalty' >
                {content.elite}
            </a>
        </div>
    </div>
  )
}}
export default connect(mapStateToProps, mapDispatchToProps)(Loyalty);