import React, { Component, useState } from "react";
import { connect } from "react-redux";
import CustomeHeader from "../../../components/header/CustomeHeader";


const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({

});

class Notes extends Component {
  constructor(props) {
    super(props);


  }
  render() {

    const { content } = this.props.languageReducer;
    return (


      <div className="parentNote">
        <div className="notesGrid ">
          {this.props.technician_notes && (
            <div className="ManufacturingNotes">
              <CustomeHeader title={content.Technician_notes} />
              <Accordion
                className="TechnicianNotes-text"
                text={this.props.technician_notes}
                limit={50} />
            </div>
          )}



          {this.props.manufacturing_notes && (
            <div className="ManufacturingNotes">
              <CustomeHeader title={content.Manufacturing_notes} />
              <Accordion className="ManufacturingNotes-text"
                text={this.props.manufacturing_notes}
                limit={50}
              />
            </div>
          )}


        </div>
      </div>
    );
  }
}
export const Accordion = ({ text, limit = 25 }) => {
  const isExpandable = text.length > limit;
  const [viewText, setViewText] = useState(text);
  const isExpanded = text.length === viewText.length;
  return (
    <span>
      <span >{`  ${viewText}`}</span>
      {isExpandable && (
        <span
          className="see_more_less"
          onClick={() => setViewText(isExpanded ? text.slice(0, limit) : text)}
        >
          {isExpanded ? "See less" : ` ...read more`}
        </span>
      )}
    </span>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Notes);