import { update } from "lodash";

export const checkMovement = (old_data, new_data) => {
  let concat_old = { ...old_data.lower_teeth, ...old_data.upper_teeth };
  let concat_new = { ...new_data.lower_teeth, ...new_data.upper_teeth };

  for (var key in concat_new) {
    if (
      checkMovementTooth(concat_old[key].movement, concat_new[key].movement)
    ) {
      return true;
    }
  }
  return false;
};

export const checkIPR = (old_data, new_data) => {
  let concat_old = { ...old_data.lower_teeth, ...old_data.upper_teeth };
  let concat_new = { ...new_data.lower_teeth, ...new_data.upper_teeth };

  for (var key in concat_new) {
    if (checkIPRTooth(concat_old[key], concat_new[key])) {
      return true;
    }
  }
  return false;
};

/* ----------------------------------------------------------------------------------*/
/* check if movement data updated  */
/* ----------------------------------------------------------------------------------*/
export const checkMovementTooth = (current, updated) => {
  if (
    Number(current.LeftRight.split(" ")[0]) !==
      Number(updated.LeftRight.split(" ")[0]) ||
    Number(current.Rotation.split(" ")[0]) !==
      Number(updated.Rotation.split(" ")[0]) ||
    Number(current.Angulation.split(" ")[0]) !==
      Number(updated.Angulation.split(" ")[0]) ||
    Number(current.Inclination.split(" ")[0]) !==
      Number(updated.Inclination.split(" ")[0]) ||
    Number(current.ExtrusionIntrusion.split(" ")[0]) !==
      Number(updated.ExtrusionIntrusion.split(" ")[0]) ||
    Number(current.ForwardBackward.split(" ")[0]) !==
      Number(updated.ForwardBackward.split(" ")[0]) ||
    current.use_extraction !== updated.use_extraction ||
    current.useAttachments !== updated.useAttachments ||
    current.useElastics !== updated.useElastics ||
    current.usePontics !== updated.usePontics ||
    current.useTorqueEnhancers !== updated.useTorqueEnhancers ||
    current.restrict_tooth !== updated.restrict_tooth ||
    current.Attachment_Note !== updated.Attachment_Note ||
    checkAttchments(current, updated) ||
    checkElastics(current, updated)
  ) {
    return true;
  } else {
    return false;
  }
};

/* ----------------------------------------------------------------------------------*/
/* check if there is any change in attachment array  */
/* ----------------------------------------------------------------------------------*/
const checkAttchments = (current, updated) => {
  if (updated.attachments.length && current.attachments.length) {
    if (updated.attachments.length !== current.attachments.length) {
      return true;
    } else {
      for (var x = 0; x < updated.attachments.length; x++) {
        if (
          updated.attachments[x].step !== current.attachments[x].step ||
          updated.attachments[x].type !== current.attachments[x].type
        ) {
          return true;
        }
      }
    }
  }

  return false;
};
/* ----------------------------------------------------------------------------------*/
/* check if there is any change in Elastics array  */
/* ----------------------------------------------------------------------------------*/
const checkElastics = (current, updated) => {
  if (updated.elastics.length && current.elastics.length) {
    if (updated.elastics.length !== current.elastics.length) {
      return true;
    } else {
      for (var x = 0; x < updated.elastics.length; x++) {
        if (
          updated.elastics[x].from !== current.elastics[x].from ||
          updated.elastics[x].to !== current.elastics[x].to
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

/* ----------------------------------------------------------------------------------*/
/* check if ipr data updated  */
/* ----------------------------------------------------------------------------------*/
export const checkIPRTooth = (current, updated) => {
  if (
    current.use_ipr !== updated.use_ipr ||
    checkSteps(current, updated) ||
    current.restrict_ipr_tooth !== updated.restrict_ipr_tooth
  ) {
    return true;
  } else {
    return false;
  }
};
/* ----------------------------------------------------------------------------------*/
/* check if there is any change in ipr ateps array  */
/* ----------------------------------------------------------------------------------*/

const checkSteps = (current, updated) => {
  if (current.ipr_steps_amounts.length && updated.ipr_steps_amounts.length) {
    if (current.ipr_steps_amounts.length !== updated.ipr_steps_amounts.length) {
      return true;
    } else {
      for (var x = 0; x < updated.ipr_steps_amounts.length; x++) {
        if (
          current.ipr_steps_amounts[x].step !==
            updated.ipr_steps_amounts[x].step ||
          current.ipr_steps_amounts[x].amount !==
            updated.ipr_steps_amounts[x].amount
        ) {
          return true;
        }
      }
    }
  }

  return false;
};
