import { UPDATE_INBOX, PUSH_MSG, UPDATE_ATTACHMENT } from "../constants";

export const updateInbox = (payload) => {
  return {
    type: UPDATE_INBOX,
    payload,
  };
};
export const pushMsg = (payload) => {
  return {
    type: PUSH_MSG,
    payload,
  };
};

export const updateAttachment = (payload) => {
  return {
    type: UPDATE_ATTACHMENT,
    payload,
  };
};
