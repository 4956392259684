import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import Steps from "../../../../components/sheredStepController/sheredStepController"

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class IPR extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_ipr: this.props.caseSubmission.advanced_modules.ipr.useIpr,
      ipr_timing_1: false,
      ipr_timing_3: false,
      iprTiming: this.props.caseSubmission.advanced_modules.ipr.iprTiming,
      steps: this.props.caseSubmission.advanced_modules.ipr.steps,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "ipr",
      data: {
        useIpr: this.state.use_ipr,
        iprTiming: this.state.iprTiming,
        iprRestriction: this.props.customeTeethReducer.Case_IPR,
        steps: this.state.steps,
      },

    });

    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  handelChanges = (value) => {
    this.setState({
      steps: value
    })
  }
  render() {
    const { use_ipr } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>
        <div className="templete_module_grid">
          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.use_ipr_for_this_case}{"? "}
            </div>

            <TreatmentOptions content={content} value={use_ipr} name="use_ipr" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_ipr")
              if (value !== true) {
                this.setState({
                  iprTiming: "" , 
                  steps: [{ step: 0 }] 
                })
                this.props.Clear_Teeth_Data("Case_IPR")
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>
          <div className="divider_module-middle"></div>

          <div id="content_container_3"
            className={`${use_ipr !== true ? "c-content-container7" : "c-content-container "} tooth-extraction  cpb2 `}>
            <div className="grid_container">
              {" "}
              <div className="pb-2">
                <Steps StepTitle={content.perform_ipr_before_step} summary={this.props.summary} hyberText={content.add_ipr_step} arrayOf_steps={this.state.steps || [{step: 0}]} onclick={(value) => {
                  this.handelChanges(value)
                }}
                />
              </div>
              <div className="">
                <div className="control-label title_active-gco title_tertment_option text-left pb-2 mt-2  ">
                  {content.ipr_restrictions}
                </div>
                <div className="ipr-teeth-selector-label">
                  {content.select_the_teeth_you_dont_want_ipr_on}
                </div>
                <div className="teeth_selector">
                  <CustomeTeeth action_key="Case_IPR" disabled={!use_ipr || use_ipr === "eonToDecide"}
                    summary={this.props.summary}
                  />
                </div>
              </div>
            </div>
            <div className={`${(this.state.use_ipr === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_ipr === "eonToDecide") ? `Eon_to_recommend_eon  Eon_to_recommend_layout` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>
              <p className={`${(this.state.use_ipr === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.IPR_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.use_ipr === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
            </div>
            {!this.props.summary && this.state.use_ipr ===true && window.innerWidth < 839 &&
            <div className="btn-adv-mobile"> <Button
          className=" mt-2 mobile-but-adv light-green-btn float-right  button-seme-bold-text"
          size="lg"
          type="button"
          onClick={() => {
            this.UpdateAdvancedModules();
          }}
        >
          {content.save}
        </Button></div>}

          </div>
        </div>

        {!this.props.summary && !(this.state.use_ipr ===true && window.innerWidth <839  ) 
        &&<div className="btn-adv-mobile"> <Button
          className=" mt-2 mobile-but-adv light-green-btn float-right   button-seme-bold-text "
          size="lg"
          type="button"
          onClick={() => {
            this.UpdateAdvancedModules();
          }}
        >
          {content.save}
        </Button></div>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IPR);
