import { toast } from "react-toastify";
import React from "react";
import Vector_false from "../assets/scss/all/icons/toast_icon/Vector_false.svg";
import Vector_true from "../assets/scss/all/icons/toast_icon/Vector_true.svg";
import Vector_mark from "../assets/scss/all/icons/toast_icon/Vector_mark.svg";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/*--------------------------------------------------------------------------------*/
/* error Toaster                                                     */
/*--------------------------------------------------------------------------------*/
export function errorToaster(msg, mainMsg) {
  toast.error(
    <div className="TosterCard">
      <div className="TosterCard-image"><img src={Vector_false} alt="icone" width="30" height="30" /></div>
      <div className="TosterCard-title"><p className="Toster-title">   {capitalizeFirstLetter(mainMsg)}</p>
        <p className="Toster-desc">{msg}</p></div>
    </div>
    , {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

}
/*--------------------------------------------------------------------------------*/
/* success Toaster                                                     */
/*--------------------------------------------------------------------------------*/
export function successToaster(msg, mainMsg) {

  toast.success(<div className="TosterCard">
    <div className="TosterCard-image"><img src={Vector_true} alt="icone" width="30" height="30" /></div>
    <div className="TosterCard-title"><p className="Toster-title">{capitalizeFirstLetter(mainMsg)}</p>
      <p className="Toster-desc">{msg}</p></div>
  </div>
    , {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
}

/*--------------------------------------------------------------------------------*/
/* info Toaster                                                     */
/*--------------------------------------------------------------------------------*/
export function infoToaster(msg, mainMsg) {
  toast.info(<div className="TosterCard">
    <div className="TosterCard-image"><img src={Vector_mark} alt="icone" width="30" height="30" /></div>
    <div className="TosterCard-title"><p className="Toster-title">{capitalizeFirstLetter(mainMsg)}</p>
      <p className="Toster-desc">{msg}</p></div>
  </div>, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
