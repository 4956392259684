import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { findNumber } from "../../../services/findNumbers";

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({

});

const TotalArray = {
    Rotation_fifty: [11, 12, 13, 14, 15, 21, 22, 23, 24, 25, 31, 32, 33, 34, 35, 41, 42, 43, 44, 45],
    Rotation_thirty: [16, 17, 18, 26, 27, 28, 36, 37, 38, 46, 47, 48],
    Torque_fifteen: [11, 12, 13, 21, 22, 23, 31, 32, 33, 41, 42, 43],
    Torque_ten: [14, 15, 16, 17, 24, 25, 26, 27, 34, 35, 36, 37,38,  44, 45, 46, 47],
    Extrusion_rand: [14, 15, 16, 17, 18, 24, 25, 26, 27, 28, 34, 35, 36, 37, 38, 44, 45, 46, 47, 48],
    Extrusion_tow: [11, 12, 13, 21, 22, 23, 31, 32, 33, 41, 42, 43],
}


const TotalvalueToComp = {
    Rotation_fifty: [50, -50],
    Rotation_thirty: [30, -30],
    Angulation_twinty: [20, -20],
    Torque_fifteen: [15, -15],
    Torque_ten: [10, -10],
    Mesial_four: [4, -4],
    Extrusion_rand: [1, -0.5],
    Extrusion_tow: [2, -2],
    in_three: [3, -3],
}


class Movement extends Component {
    // constructor(props) {
    //     super(props);
    // }

    //// Checked (over &under ) value  ////
    Checked = (ToothNumber, array, Rotation, value) => {
        let newRotation = Number(Rotation.split(" ")[0]);
        for (var i = 0; i < array.length; i++) {
            if (Number(ToothNumber) === array[i]) {
                if (newRotation > value[0] || newRotation < value[1])
                    return true;
            }
        }
        return false;
    };
    ///// AllValueteeth	 (over &under ) all teeth  /////	
    Allteeth = (ToothNumber, value, AllValueteeth) => {
        let newAllValueteeth = Number(AllValueteeth.split(" ")[0])
        if (newAllValueteeth > value[0] || newAllValueteeth < value[1]) {
            return true;
        }
        return false;
    }


    render() {
        const { content } = this.props.languageReducer;
        const { dental_notation } = this.props.userReducer.currentUser

        return (
            <div className="movement-table">
                {this.props.movement.length > 0 &&
                    <Table className="p-0" responsive height="100" striped size="sm">
                        <thead>
                            <tr>
                                <th className={`table-prop `}>{content.Tooth}<br></br>{content.Number}</th>
                                <th className="table-prop">{content.Rotation}</th>
                                <th className="table-prop">{content.Angulation}</th>
                                <th className="table-prop">{content.Torque}</th>
                                <th className="table-prop">{content.Mesial}<br></br>{content.Distal}</th>
                                <th className="table-prop">{content.Extrusion}<br></br>{content.Intrusion}</th>
                                <th className="table-prop">{content.In_Out}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.movement.map((value, index) => (
                                <tr key={index}>
                                    <td className="table-value-num"><div className="ToothNumber-Grid">
                                        <div></div>
                                        <div className={`${(this.Checked(value.ToothNumber, TotalArray.Rotation_fifty, value.Rotation, TotalvalueToComp.Rotation_fifty)||  this.Checked(value.ToothNumber, TotalArray.Rotation_thirty, value.Rotation, TotalvalueToComp.Rotation_thirty) ||  
                                        this.Allteeth(value.ToothNumber, TotalvalueToComp.Angulation_twinty, value.Angulation) ||  this.Checked(value.ToothNumber, TotalArray.Torque_fifteen, value.Inclination, TotalvalueToComp.Torque_fifteen) || 
                                        this.Checked(value.ToothNumber, TotalArray.Torque_ten, value.Inclination, TotalvalueToComp.Torque_ten) || 
                                        this.Allteeth(value.ToothNumber, TotalvalueToComp.Mesial_four, value.LeftRight) ||
                                        this.Checked(value.ToothNumber, TotalArray.Extrusion_rand, value.ExtrusionIntrusion, TotalvalueToComp.Extrusion_rand) ||
                                        this.Checked(value.ToothNumber, TotalArray.Extrusion_tow, value.ExtrusionIntrusion, TotalvalueToComp.Extrusion_tow)||
                                        this.Allteeth(value.ToothNumber, TotalvalueToComp.in_three, value.ForwardBackward)  ? "circle-orange" :""
                                        )}`}></div>
                                        <div >{findNumber("fdi", value.ToothNumber)[dental_notation]}</div>
                                        <div></div>
                                    </div>
                                    </td>
                                    <td className={`table-value ${this.Checked(value.ToothNumber, TotalArray.Rotation_fifty, value.Rotation, TotalvalueToComp.Rotation_fifty) ||
                                        this.Checked(value.ToothNumber, TotalArray.Rotation_thirty, value.Rotation, TotalvalueToComp.Rotation_thirty) ? "test-roles" : ""}`}>{value.Rotation}</td>
                                    <td className={`table-value ${this.Allteeth(value.ToothNumber, TotalvalueToComp.Angulation_twinty, value.Angulation) ? "test-roles" : ""}`} >{value.Angulation}</td>
                                    <td className={`table-value ${this.Checked(value.ToothNumber, TotalArray.Torque_fifteen, value.Inclination, TotalvalueToComp.Torque_fifteen) ||
                                        this.Checked(value.ToothNumber, TotalArray.Torque_ten, value.Inclination, TotalvalueToComp.Torque_ten) ? "test-roles" : ""}`}>{value.Inclination}</td>
                                    <td className={`table-value ${this.Allteeth(value.ToothNumber, TotalvalueToComp.Mesial_four, value.LeftRight) ? "test-roles" : ""}`} >{value.LeftRight}</td>
                                    <td className={`table-value ${this.Checked(value.ToothNumber, TotalArray.Extrusion_rand, value.ExtrusionIntrusion, TotalvalueToComp.Extrusion_rand) ||
                                        this.Checked(value.ToothNumber, TotalArray.Extrusion_tow, value.ExtrusionIntrusion, TotalvalueToComp.Extrusion_tow) ? "test-roles" : ""}`}>{value.ExtrusionIntrusion}</td>
                                    <td className={`table-value ${this.Allteeth(value.ToothNumber, TotalvalueToComp.in_three, value.ForwardBackward) ? "test-roles" : ""}`}>{value.ForwardBackward}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table >
                }
            </div>


        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Movement);

