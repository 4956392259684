import React, { Component } from "react";
import { connect } from "react-redux";

import {
  CardFooter,
  Button,
  Label,
  Input,
  FormGroup,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";

import CustomeTeeth from "../Teeth/CustomeTeeth";
import { caseType } from "../../helpers/caseType";
import { SummaryStep, updateAlignerCase } from "../../api/api";
import { Formik, Form } from "formik";
import { countries } from "../../helpers/countries";
import Malocclusion from "./AdvancedModulesCopy/ModulesComponents/Malocclusion";
import Extraction from "./AdvancedModulesCopy/ModulesComponents/Extraction";
import IPR from "./AdvancedModulesCopy/ModulesComponents/IPR";
import Attachments from "./AdvancedModulesCopy/ModulesComponents/Attachments";
import Pontics from "./AdvancedModulesCopy/ModulesComponents/Pontics";
import Overcorrection from "./AdvancedModulesCopy/ModulesComponents/OverCorrection";
import PassiveAligner from "./AdvancedModulesCopy/ModulesComponents/PassiveAligner";
import TorqueEnhancers from "./AdvancedModulesCopy/ModulesComponents/TorqueEnhancers";
import ToothSizeDiscrepancy from "./AdvancedModulesCopy/ModulesComponents/ToothSizeDiscrepancy";
import BiteRamps from "./AdvancedModulesCopy/ModulesComponents/BiteRamps";
import ArchExpansion from "./AdvancedModulesCopy/ModulesComponents/ArchExpansion";
import Elastics from "./AdvancedModulesCopy/ModulesComponents/Elastics";
import ToothRateMovement from "./AdvancedModulesCopy/ModulesComponents/ToothRateMovement";
import HybirdTreatment from "./AdvancedModulesCopy/ModulesComponents/HybirdTreatment";

import CustomeHeader from "../../components/header/CustomeHeader";
import { WrappedSummaryModule } from "@eon-dental/advanced-modules/dist/rollup-export";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
      country: {},
      detailedCaseSubmission: false,
      hybrid: this.props.hybrid,
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*Summery step submit  */
  /*--------------------------------------------------------------------------------*/

  handleSubmit = async (values, { setSubmitting }) => {
    const { doctorId } = this.props;
    const { hybrid } = this.state;
    let data = {
      aligner_case: {
        step: "summary",
      },
    };
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    setSubmitting(true);
    if (values.case_type === "Retainer") {
      this.props.NextStep();
    } else {
      // updateAlignerCase(doctorId, caseId,JSON.stringify(data))
      //   .then((res) => {
      //     this.props.NextStep();
      //     setSubmitting(false);
      //     data = {
      //       "aligner_case": {
      //         "step": "summary"
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     setSubmitting(false);
      //   });
      if (hybrid) {
        let data2 = {
          aligner_case: {
            step: "terms",
            terms_and_agreements: "1",
          },
        };
        await updateAlignerCase(doctorId, caseId, JSON.stringify(data2))
          .then((res) => {
            //  this.props.NextStep();
            setSubmitting(false);
            // data = {
            //   "aligner_case": {
            //     "step": "summary"
            //   }
            // }
          })
          .catch((error) => {
            setSubmitting(false);
          });
        await SummaryStep(doctorId, caseId, data)
          .then((res) => {
            this.props.NextStep();
            // setSubmitting(false);
          })
          .catch((error) => {
            setSubmitting(false);
          });
      } else {
        SummaryStep(doctorId, caseId, data)
          .then((res) => {
            this.props.NextStep();
            setSubmitting(false);
          })
          .catch((error) => {
            setSubmitting(false);
          });
      }
    }
  };
  componentDidMount = () => {
    let countryData = countries.filter(
      (country) => country.value === this.props.caseData.address.country
    );
    this.setState({
      country: countryData[0],
    });
  };
  DetailedCaseSubmission = () => {
    this.setState({
      detailedCaseSubmission: !this.state.detailedCaseSubmission,
    });
  };
  render() {
    const { block_case_submission } = this.props.userReducer.currentUser;
    const { dental_notation } = this.props.userReducer.currentUser;

    const { prescription, patient_info, advanced_modules } =
      this.props.caseSubmission;

    const { address, isNewAdvancedModules } = this.props.caseReducer.case;
    const { country } = this.state;
    const { content } = this.props.languageReducer;

    const wear_cycle = {
      regular: "two_week",
      express: "one_week",
    };
    const treat_arches = {
      upper_only: "upper",
      lower_only: "lower",
      both: "upper_and_lower",
    };
    let advancedModules = [];

    var correctDate = patient_info?.date_of_birth?.toString();
    var correctDate2 = correctDate?.slice(4, 15) || "";

    advancedModules = this.props.advancedModules.modules.filter(
      (value, index) => {
        return value.active === true;
      }
    );
    return (
      <div className="summery">
        {window.innerWidth > 839 ? (
          <div className="patient-info-header">
            <CustomeHeader title={content.summary} />

            <span className="patient_info">
              <div className="callout-text">{patient_info.full_name}</div>
              <div className="callout-text">
                {" "}
                {content.case} {this.state?.caseId}
              </div>
            </span>
          </div>
        ) : (
          <div></div>
        )}
        {window.innerWidth < 839 && (
          <span className="naming-in-summary ">
            <div>{patient_info.full_name}</div>
            <div>
              {" "}
              {content.case} {this.state?.caseId}
            </div>
          </span>
        )}

        <Formik
          enableReinitialize
          initialValues={{
            chief_complaint: prescription.chief_complaint,
            summary_and_special_instructions:
              prescription.summary_and_special_instructions,
            treat_arches: prescription.treat_arches,
            wear_cycle: prescription.wear_cycle,
            case_type: patient_info.case_type,
          }}
          onSubmit={this.handleSubmit}
        >
          {({ isSubmitting, values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>

              <div className="patient-info-container ">
                <div className="patient-info-summary button-text">
                  <div>{`${content.gender} : ${
                    content[patient_info.gender.toLowerCase()]
                  }`}</div>
                  <div>{`${content.date_of_birth} : ${correctDate2}`}</div>
                  <div>{`${content.patient_email} : ${patient_info.email}`}</div>
                  <div>
                    {" "}
                    {`${content.shipping_address} : ${address.line1}, ${address.zip}, ${address.city}, ${country.label}`}
                  </div>
                </div>
                {window.location.pathname === "/retainer" ? (
                  <div></div>
                ) : (
                  <div className="prescription_summery_grid cmy5 ">
                    <div>
                      <FormGroup>
                        <Label className="custom-title colorful title-3-text">
                          {content.chief_complaint}
                        </Label>
                        <Input
                          name="chief_complaint"
                          id="chief_complaint"
                          type="textarea"
                          rows={3}
                          className="custome-textarea2"
                          readOnly
                          defaultValue={values.chief_complaint}
                        />{" "}
                      </FormGroup>

                      <div className="cmt4">
                        <div className="case-info-summary">
                          <span className="green-title colorful button-text">
                            {content.arch} :{" "}
                          </span>
                          <span className="button-text">
                            {content[treat_arches[values.treat_arches]]}
                          </span>{" "}
                        </div>
                        <div className="case-info-summary">
                          <span className="green-title colorful button-text">
                            {content.case_type} :{" "}
                          </span>
                          <span className="button-text">
                            {content[caseType[values.case_type]] ||
                              caseType[values.case_type]}
                          </span>
                        </div>
                        <div className="case-info-summary">
                          <span className="green-title colorful button-text">
                            {content.wear_cycle} :{" "}
                          </span>
                          <span className="button-text">
                            {content[wear_cycle[values.wear_cycle]]}
                          </span>
                        </div>
                        <div className="case-info-summary">
                          <span className="green-title colorful button-text">
                            {content.treatment_type} :{" "}
                          </span>
                          <span className="button-text">
                            {advanced_modules?.treatment_type?.type ||
                            isNewAdvancedModules
                              ? "aligner"
                              : ""}
                          </span>
                        </div>
                        {isNewAdvancedModules ? (
                          <WrappedSummaryModule
                            advancedModules={advanced_modules}
                            numberingSystem={dental_notation}
                          />
                        ) : (
                          advancedModules.length > 0 && (
                            <div
                              className="detailed_case_submission subhead"
                              onClick={this.DetailedCaseSubmission}
                            >
                              {content.detailed_case_submission}{" "}
                              <i className="fas fa-angle-right pl-1"></i>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div>
                      <FormGroup>
                        <Label className="custom-title colorful title-3-text">
                          {content.treatment_summary}
                        </Label>
                        <Input
                          name="summary_and_special_instructions"
                          id="summary_and_special_instructions"
                          type="textarea"
                          className="custome-textarea2"
                          rows={3}
                          readOnly
                          defaultValue={values.summary_and_special_instructions}
                        />{" "}
                      </FormGroup>
                      {!isNewAdvancedModules && (
                        <FormGroup>
                          <Label className="custom-title colorful cmt2 title-3-text">
                            {content.teeth_movement_restrictions}
                          </Label>
                          <div className="teeth_selector">
                            {" "}
                            <CustomeTeeth
                              action_key="Case_Tooth_Movement_Restrictions"
                              summary={true}
                            />
                          </div>
                        </FormGroup>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="prescription_summery_grid cmy5 "> */}
              {/* <div className="prescription_grid">
<div>
                    <FormGroup>
                      <Label className="custom-title colorful">{content.chief_complaint}</Label>
                      <Input
                        name="chief_complaint"
                        id="chief_complaint"
                        type="textarea"
                        rows={3}
                        className="custome-textarea2"
                        readOnly
                        defaultValue={values.chief_complaint}
                      />{" "}
                    </FormGroup>
                    </div>
                   
                
                  <div>
                    <FormGroup>
                      <Label className="custom-title colorful">
                        {content.treatment_summary}
                      </Label>
                      <Input
                        name="summary_and_special_instructions"
                        id="summary_and_special_instructions"
                        type="textarea"
                        className="custome-textarea2"
                        rows={3}
                        readOnly
                        defaultValue={values.summary_and_special_instructions}
                      />{" "}
                    </FormGroup>
                    </div>
                    <div className="cmt4">
                      <div className="case-info-summary"><span className="green-title colorful">{content.treat_arch} : </span><span>{content[treat_arches[values.treat_arches]]}</span>  </div>
                      <div className="case-info-summary"><span className="green-title colorful">{content.case_type}</span><span>{content[caseType[values.case_type]]}</span></div>
                      <div className="case-info-summary"><span className="green-title colorful">{content.wear_cycle}</span><span>{content[wear_cycle[values.wear_cycle]]}</span></div>
                      <div className="case-info-summary"><span className="green-title colorful">{content.treatment_type}</span><span>{advanced_modules?.treatment_type?.type || ""}</span></div>
                      {
                        advancedModules.length > 0 &&
                        <div className="detailed_case_submission" onClick={this.DetailedCaseSubmission}>{content.detailed_case_submission} <i className="fas fa-angle-right pl-1"></i></div>
                      }
                    </div>
                    <FormGroup>
                      <Label className="custom-title colorful cmt2">
                        {content.teeth_movement_restrictions}
                      </Label>
                      <div className="teeth_selector">   <CustomeTeeth
                        action_key="Case_Tooth_Movement_Restrictions"
                        summary={true}
                      /></div>

                    </FormGroup>

                 
                </div>

 */}

              {/* </div> */}
              {window.innerWidth > 839 && !block_case_submission && (
                <CardFooter className="p-3 container-footer-mobile">
                  <Button
                    className={`btn white-btn float-left submission-btn  button-seme-bold-text`}
                    size="sm"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => this.props.PrevStep()}
                  >
                    <i className="fas fa-angle-left pr-1"></i>{" "}
                    {content.previous}
                  </Button>
                  <div className="order-save-button">
                    <Button
                      className={`btn light-green-btn float-right  submission-btn  button-seme-bold-text`}
                      size="sm"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {content.submit}{" "}
                      <i className="fas fa-angle-right pl-1"></i>
                    </Button>
                  </div>
                </CardFooter>
              )}

              {window.innerWidth <= 839 && !block_case_submission && (
                <CardFooter className="p-3 .mobile-but-adv ">
                  <Button
                    className={`btn light-green-btn float-right  submission-btn  button-seme-bold-text`}
                    size="sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {content.submit} <i className="fas fa-angle-right pl-1"></i>
                  </Button>
                </CardFooter>
              )}
            </Form>
          )}
        </Formik>

        {/*--------------------------------------------------------------------------------*/}
        {/* Detailed Case Submission Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.detailedCaseSubmission}
          toggle={this.DetailedCaseSubmission}
          className="malocclusion-modal fix-modal2"
        >
          <ModalHeader toggle={this.DetailedCaseSubmission}></ModalHeader>
          <ModalBody>
            {window.innerWidth < 839 && (
              <h1 className="header_title3">
                {" "}
                {content.detailed_case_submission}
              </h1>
            )}
            {window.innerWidth > 839 && (
              <CustomeHeader title={content.detailed_case_submission} />
            )}

            <div className="modules-modal-summary">
              {this.props.advancedModules?.modules &&
                this.props.advancedModules.modules.map((values, index) => (
                  <div key={index}>
                    {values.active && values.key === "malocclusion" && (
                      <>
                        <CustomeHeader title={content.malocclusion} />
                        <Malocclusion summary={true} />
                      </>
                    )}

                    {values.active && values.key === "extraction" && (
                      <>
                        <CustomeHeader title={content.extraction} />
                        <Extraction summary={true} />
                      </>
                    )}

                    {values.active && values.key === "ipr" && (
                      <>
                        <CustomeHeader title={content.ipr} />
                        <IPR summary={true} />
                      </>
                    )}

                    {values.active && values.key === "attachments" && (
                      <>
                        <CustomeHeader title={content.attachments} />
                        <Attachments summary={true} />
                      </>
                    )}

                    {values.active && values.key === "pontics" && (
                      <>
                        <CustomeHeader title={content.pontics} />
                        <Pontics summary={true} />
                      </>
                    )}

                    {values.active && values.key === "overCorrection" && (
                      <>
                        <CustomeHeader title={content.overcorrection} />
                        <Overcorrection summary={true} />
                      </>
                    )}

                    {values.active && values.key === "passiveAligners" && (
                      <>
                        <CustomeHeader title={content.passive_aligners} />
                        <PassiveAligner summary={true} />
                      </>
                    )}

                    {values.active && values.key === "toothSizeDiscrepancy" && (
                      <>
                        <CustomeHeader title={content.tooth_size_discrepancy} />
                        <ToothSizeDiscrepancy summary={true} />
                      </>
                    )}

                    {values.active && values.key === "torqueEnhancers" && (
                      <>
                        <CustomeHeader title={content.torque_enhancers} />
                        <TorqueEnhancers summary={true} />
                      </>
                    )}

                    {values.active && values.key === "biteRamps" && (
                      <>
                        <CustomeHeader title={content.bite_ramps} />
                        <BiteRamps summary={true} />
                      </>
                    )}

                    {values.active && values.key === "archExpansion" && (
                      <>
                        <CustomeHeader title={content.arch_expansion} />
                        <ArchExpansion summary={true} />
                      </>
                    )}
                    {values.active && values.key === "elastics" && (
                      <>
                        <CustomeHeader title={content.elastics} />
                        <Elastics summary={true} />
                      </>
                    )}

                    {values.active && values.key === "hybridTreatment" && (
                      <>
                        <CustomeHeader title={content.hybridTreatment} />
                        <HybirdTreatment summary={true} />
                      </>
                    )}

                    {values.active && values.key === "teethMovement" && (
                      <>
                        <CustomeHeader title={content.toothRateMovement} />
                        <ToothRateMovement summary={true} />
                      </>
                    )}
                  </div>
                ))}
              <CardFooter className="p-3 container-footer-mobile inmobile2">
                <Button
                  className={`btn light-green-btn float-right  submission-btn  button-seme-bold-text `}
                  size="md"
                  color="danger"
                  onClick={this.DetailedCaseSubmission}
                >
                  {content.exit}
                </Button>
              </CardFooter>
            </div>

            <CardFooter className="p-3 container-footer-mobile inWebsit">
              <Button
                className={`btn light-green-btn float-right  submission-btn  button-seme-bold-text exit_summary_button_position`}
                size="md"
                color="danger"
                onClick={this.DetailedCaseSubmission}
              >
                {content.exit}
              </Button>
            </CardFooter>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Detailed Case Submission Modal /}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Summary);
