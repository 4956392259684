import React, { Component } from "react";
import { connect } from "react-redux";
import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
import {
  Clear_Teeth_Data
} from "../../../../../redux/customeTeeth/action"
import CustomeHeader from "../../../../../components/header/CustomeHeader";


const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload))

});
class ClassIII extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.use_elastics =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.useElasticWhenCorrecting || false;

    this.advanceUppers =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.advanceUppers || false;

    this.retractLowersExtraction =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.retractLowersExtraction || false;

    this.retractLowersIPR =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.retractLowersIPR || false;

    this.estheticAlignmentOnly =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.estheticAlignmentOnly || false;

    this.preSurgicalAlignment =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.preSurgicalAlignment || false;
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      useElasticWhenCorrecting: this.use_elastics,
      teeth: this.props.customeTeethReducer.Case_ClassII,
      advanceUppers: this.advanceUppers,
      retractLowersExtraction: this.retractLowersExtraction,
      retractLowersIPR: this.retractLowersIPR,
      estheticAlignmentOnly: this.estheticAlignmentOnly,
      preSurgicalAlignment: this.preSurgicalAlignment,
    };

    this.props.save("classIII", data);
  };
    /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.use_elastics = value
  }
  render() {
    const { 
      advanceUppers,
      retractLowersExtraction,
      retractLowersIPR,
      estheticAlignmentOnly,
      preSurgicalAlignment,
    } = this;
    const { content } = this.props.languageReducer
    return (
      <div className="">
         <CustomeHeader title={content.classIlI} optional={false} largeFont={false}/>

        <div className="buttom-paddingFORMalc">
          <div className="control-label title_active-gco title_tertment_option title-3-text">
          {content.treatment_options}{" "}
          </div>
         
          {/* 1 */}
          <CustomInput

            className={`pt-1 ${advanceUppers && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="advanceUppers"
            label={content.advance_upper_sby_aligners_and_classIII_elastics}
            checked={advanceUppers}
            disabled={preSurgicalAlignment || estheticAlignmentOnly || this.props.summary}
            onChange={() => {
              this.advanceUppers = !this.advanceUppers;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 2 */}

          <CustomInput

            className={`pt-1 ${retractLowersExtraction && "replacment-checkbox "
              } button-text-select`}
            type="checkbox"
            id="retractLowersExtraction"
            label={content.retract_lowers_by_extraction_and_classIII_elastics}
            checked={retractLowersExtraction}
            disabled={
              retractLowersIPR || preSurgicalAlignment || estheticAlignmentOnly || this.props.summary
            }
            onChange={() => {
              this.retractLowersExtraction = !this.retractLowersExtraction;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 3 */}

          <CustomInput

            className={`pt-1 ${retractLowersIPR && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="retractLowersIPR"
            label={content.retract_lowers_after_ipr}
            checked={retractLowersIPR}
            disabled={
              retractLowersExtraction ||
              preSurgicalAlignment ||
              estheticAlignmentOnly || this.props.summary
            }
            onChange={() => {
              this.retractLowersIPR = !this.retractLowersIPR;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 4 */}

          <CustomInput
            className={`pt-1 ${estheticAlignmentOnly && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="estheticAlignmentOnly"
            label={content.esthetic_alignment_only}
            checked={estheticAlignmentOnly}
            disabled={
              advanceUppers ||
              preSurgicalAlignment ||
              retractLowersExtraction ||
              retractLowersIPR || this.props.summary
            }
            onChange={() => {
              this.estheticAlignmentOnly = !this.estheticAlignmentOnly;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 5 */}
          <CustomInput
            className={`pt-1 ${preSurgicalAlignment && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="preSurgicalAlignment"
            label={content.pre_surgical_alignment_followed_by_surgery}
            checked={preSurgicalAlignment}
            disabled={
              advanceUppers ||
              estheticAlignmentOnly ||
              retractLowersExtraction ||
              retractLowersIPR || this.props.summary
            }
            onChange={() => {
              this.preSurgicalAlignment = !this.preSurgicalAlignment;
              this.UpdateCrossBiteReducer();
            }}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassIII);
