import front_smiling from "../assets/images/photos/front_smiling.svg";
import front_pose from "../assets/images/photos/front_pose.svg";
import profile from "../assets/images/photos/side.svg";

import upper_occlusal from "../assets/images/photos/upper_occlusal.svg";
import lower_occlusal from "../assets/images/photos/lower_occlusal.svg";
import frontal from "../assets/images/photos/frontal.svg";

import right_buccal from "../assets/images/photos/right_buccal.svg";
import left_buccal from "../assets/images/photos/left_buccal.svg";
import panoramic_xray from "../assets/images/photos/panoramic_xray.svg";
import cephalometric_xray from "../assets/images/photos/cephalometric_xray.svg"

export const Photos = {
  front_smiling: front_smiling,
  front_pose: front_pose,
  profile: profile,
  panoramic_xray: panoramic_xray,
  upper_occlusal: upper_occlusal,
  lower_occlusal: lower_occlusal,
  left_buccal: left_buccal,
  cephalometric_xray: cephalometric_xray,
  frontal: frontal,
  space: "",
  right_buccal: right_buccal,
};

export const Xrays = {
  panoramic_xray: panoramic_xray,

  cephalometric_xray: cephalometric_xray
};


export const MissingPhotosXraysData = {
    front_smiling: front_smiling,
    front_pose: front_pose,
    profile: profile,
    panoramic_xray: panoramic_xray,
    upper_occlusal: upper_occlusal,
    lower_occlusal: lower_occlusal,
    left_buccal: left_buccal,
    cephalometric_xray: cephalometric_xray,
    frontal: frontal,
    space: "",
    right_buccal: right_buccal,
    panoramic_xray: panoramic_xray,
    cephalometric_xray: cephalometric_xray
};
export const PhotosXraysData = [
  {
    img: front_smiling,
    title: "Front (smiling)",
    key: "front_smiling",
    uploadKey: "UploadFrontSmiligPhoto",
  },

  {
    img: front_pose,
    title: "Front (non-smiling)",
    key: "front_pose",
    uploadKey: "UploadFrontPosePhoto",
  },
  {
    img: profile,
    title: "Side profile",
    key: "profile",
    uploadKey: "UploadProfilePhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: panoramic_xray,
    title: "Panoramic x-ray",
    key: "panoramic_xray",
    uploadKey: "UploadPanoramicPhoto",
  },
  {
    img: upper_occlusal,
    title: "Upper occlusal",
    key: "upper_occlusal",
    uploadKey: "UploadUpperOcclusalPhoto",
  },
  {
    img: frontal,
    title: "Frontal",
    key: "frontal",
    uploadKey: "UploadFrontalPhoto",
  },
  {
    img: lower_occlusal,
    title: "Lower occlusal",
    key: "lower_occlusal",
    uploadKey: "UploadLowerOcclusalPhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: cephalometric_xray,
    title: "Cephalometric x-ray",
    key: "cephalometric_xray",
    uploadKey: "UploadCephalometricPhoto",
  },
  {
    img: right_buccal,
    title: "Right buccal",
    key: "right_buccal",
    uploadKey: "UploadRightBuccalPhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: left_buccal,
    title: "Left buccal",
    key: "left_buccal",
    uploadKey: "UploadLeftBuccalPhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: "",
    title: "",
    key: "",
  },
];

export const PhotosXraysDataMobile = [
  {
    img: front_smiling,
    title: "Front (smiling)",
    key: "front_smiling",
    uploadKey: "UploadFrontSmiligPhoto",
  },

  {
    img: front_pose,
    title: "Front (non-smiling)",
    key: "front_pose",
    uploadKey: "UploadFrontPosePhoto",
  },
  {
    img: profile,
    title: "Side profile",
    key: "profile",
    uploadKey: "UploadProfilePhoto",
  },
  {
    img: frontal,
    title: "Frontal",
    key: "frontal",
    uploadKey: "UploadFrontalPhoto",
  },
 
  {
    img: upper_occlusal,
    title: "Upper occlusal",
    key: "upper_occlusal",
    uploadKey: "UploadUpperOcclusalPhoto",
  },
 
  {
    img: lower_occlusal,
    title: "Lower occlusal",
    key: "lower_occlusal",
    uploadKey: "UploadLowerOcclusalPhoto",
  },

  {
    img: right_buccal,
    title: "Right buccal",
    key: "right_buccal",
    uploadKey: "UploadRightBuccalPhoto",
  },
  {
    img: left_buccal,
    title: "Left buccal",
    key: "left_buccal",
    uploadKey: "UploadLeftBuccalPhoto",
  },
  {
    img: panoramic_xray,
    title: "Panoramic x-ray",
    key: "panoramic_xray",
    uploadKey: "UploadPanoramicPhoto",
  },
  {
    img: cephalometric_xray,
    title: "Cephalometric x-ray",
    key: "cephalometric_xray",
    uploadKey: "UploadCephalometricPhoto",
  },
];
export const PhotosXraysDataMobileDesktp = window.innerWidth > 839 ? PhotosXraysData : PhotosXraysDataMobile

export const PhotosData = [
  {
    img: front_smiling,
    title: "Front Smiling",
    key: "front_smiling",
    uploadKey: "UploadFrontSmiligPhoto",
  },

  {
    img: front_pose,
    title: "Front Pose",
    key: "front_pose",
    uploadKey: "UploadFrontPosePhoto",
  },
  {
    img: profile,
    title: "Profile",
    key: "profile",
    uploadKey: "UploadProfilePhoto",
  }
  ,

  {
    img: upper_occlusal,
    title: "Upper Occlusal",
    key: "upper_occlusal",
    uploadKey: "UploadUpperOcclusalPhoto",
  },
  {
    img: frontal,
    title: "Frontal",
    key: "frontal",
    uploadKey: "UploadFrontalPhoto",
  },
  {
    img: lower_occlusal,
    title: " Lower Occlusal",
    key: "lower_occlusal",
    uploadKey: "UploadLowerOcclusalPhoto",
  },


  {
    img: right_buccal,
    title: "Right Buccal",
    key: "right_buccal",
    uploadKey: "UploadRightBuccalPhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: left_buccal,
    title: "Left Buccal",
    key: "left_buccal",
    uploadKey: "UploadLeftBuccalPhoto",
  },

];
export const PhotosDataMobile = [
  {
    img: front_smiling,
    title: "Front Smiling",
    key: "front_smiling",
    uploadKey: "UploadFrontSmiligPhoto",
  },
  {
    img: front_pose,
    title: "Front Pose",
    key: "front_pose",
    uploadKey: "UploadFrontPosePhoto",
  }, 
  {
    img: profile,
    title: "Profile",
    key: "profile",
    uploadKey: "UploadProfilePhoto",
  },
  {
    img: frontal,
    title: "Frontal",
    key: "frontal",
    uploadKey: "UploadFrontalPhoto",
  },
  {
    img: upper_occlusal,
    title: "Upper Occlusal",
    key: "upper_occlusal",
    uploadKey: "UploadUpperOcclusalPhoto",
  },

  {
    img: lower_occlusal,
    title: " Lower Occlusal",
    key: "lower_occlusal",
    uploadKey: "UploadLowerOcclusalPhoto",
  },


  {
    img: right_buccal,
    title: "Right Buccal",
    key: "right_buccal",
    uploadKey: "UploadRightBuccalPhoto",
  },
  {
    img: left_buccal,
    title: "Left Buccal",
    key: "left_buccal",
    uploadKey: "UploadLeftBuccalPhoto",
  },

];
export const PhotosDataMobileDesktp = window.innerWidth > 839 ? PhotosData : PhotosDataMobile;

export const XraysData = [

  {
    img: panoramic_xray,
    title: "Panoramic Xray",
    key: "panoramic_xray",
    uploadKey: "UploadPanoramicPhoto",
  },


  {
    img: cephalometric_xray,
    title: "Cephalometric Xray",
    key: "cephalometric_xray",
    uploadKey: "UploadCephalometricPhoto",
  },

];