import React from "react";
import trash from "../../assets/images/icon/trash.svg";
import { Input } from "reactstrap";

// this is the final calling in any component for (shered Step Controller) attachment and APR step
/* <Steps StepTitle="add Title Step here" hyberText="add from here " arrayOf_steps={(this.state.array=[])?this.state.array=[{ step: 0}]:this.state.array} onclick={(value) => {
    this.handelChanges(value)
}}/> */

const StepsElastic = ({
  StepsElasticTitle,
  hyberText,
  onclick,
  to,
  useElastics,
  arrayOf_steps,
}) => {
  //adding Step//
  const addStep = () => {
    let newStep = arrayOf_steps.concat({
      from: 0,
      to: 0,
    });
    onclick(newStep);
  };
  //deleting Step//
  const removeStep = (index) => {
    let deleteStep = arrayOf_steps.filter((el, i) => i !== index);
    onclick(deleteStep);
  };

  //changing Step//
  const changetFrom = (value, index) => {
    let steps_changing_From = arrayOf_steps.map((item, j) => {
      if (j === index) {
        return {
          ...item,
          from: value,
        };
      } else {
        return item;
      }
    });
    onclick(steps_changing_From);
  };

  //changing Step//
  const changetTo = (value, index) => {
    let steps_changing_To = arrayOf_steps.map((item, j) => {
      if (j === index) {
        return {
          ...item,
          to: value,
        };
      } else {
        return item;
      }
    });

    onclick(steps_changing_To);
  };

  return (
    <div>
      {" "}
      <div className="cmt2">
        {arrayOf_steps.map((value, index) => (
          <div className="stepElastic-grid" key={index}>
            <div>
              {index > 0 && (
                <div
                  className={`${!useElastics ? "event-none" : ""} remove-step`}
                  onClick={() => removeStep(index)}
                >
                  <img src={trash} alt="trash" />
                </div>
              )}
            </div>

            <div className="custom-dark-text">{StepsElasticTitle}</div>
            <div className="form-material">
              <Input
                type="number"
                placeholder=""
                className="step-number"
                onClick={(e) => {
                  e.target.focus();
                }}
                onMouseUp={(e) => {
                  e.target.blur();
                }}
                value={value.from}
                key={index.value}
                min={0}
                disabled={!useElastics}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    changetFrom(0, index);
                  } else {
                    changetFrom(e.target.value.replace(/^0+/, ""), index);
                  }
                }}
              />
            </div>
            <div>{to}</div>
            <div className="form-material">
              {" "}
              <Input
                type="number"
                placeholder=""
                className="step-number"
                onClick={(e) => {
                  e.target.focus();
                }}
                onMouseUp={(e) => {
                  e.target.blur();
                }}
                value={value.to}
                key={index.value}
                min={0}
                disabled={!useElastics}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    changetTo(0, index);
                  } else {
                    changetTo(e.target.value.replace(/^0+/, ""), index);
                  }
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="add-attachment-movement-grid">
        <div></div>
        <div
          className={`${!useElastics ? "event-none" : "green_text"} add-step`}
          onClick={addStep}
        >
          {hyberText}
        </div>
      </div>
    </div>
  );
};

export default StepsElastic;
