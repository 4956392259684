import React, { Component } from "react";
import Banners from "./Banners";

import { connect } from "react-redux";
import { getDoctorInfo, getCasesNumbersByStatus } from "../../api/api";
import { setUserInfo } from "../../redux/user/action";
import submit_aligner from "../../assets/images/dashboard/submit_aligner.svg";
import submit_retainer from "../../assets/images/dashboard/submit_retainer.svg";

import submit_aligner_not_active from "../../assets/images/dashboard/submit_aligner_not_active.svg";
import submit_retainer_not_active from "../../assets/images/dashboard/submit_retainer_not_active.svg";
import review_cases from "../../assets/images/dashboard/review_case.svg";
import academy_not_active from "../../assets/images/dashboard/academy_not_active.svg";
import academy_new from "../../assets/images/dashboard/academy_new.svg";
import { statusColors } from "../../helpers/caseStatus";
import { UpdateLanguage, UpdateContent } from "../../redux/language/action";

import OnBoarding from "./onBoarding/OnBoarding";
import Loyalty from "./Loyalty";
import AssessmentTool from "../assessment-tool/App";
// import InfoBip from "./InfoBip";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setUserInfo: (payload) => dispatch(setUserInfo(payload)),
  UpdateLanguage: (payload) => dispatch(UpdateLanguage(payload)),
  UpdateContent: (payload) => dispatch(UpdateContent(payload)),
});
class HomePage extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      id: props.userReducer.currentUser.id,
      banners: [],
      start: false,
      loyalty: null,
      incomplete: "",
      rejected_records: "",
      pending_ts_approval: "",
      awaiting_completion: "",
      gco_doctor: false,
      sendingData: false,
      finished_onboarding: false,
      language: "",
      date_format: "",
      dental_notation: "",
      terms_approval: false,
      finish_loading: false,
      block_case_submission:
        props.userReducer.currentUser.block_case_submission,
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* get doctor info , save info in local storage */
  /* --------------------------------------------------------------------------------*/
  componentDidMount = () => {
    this.GetDoctorInfo();
    let status = [
      "incomplete",
      "rejected_records",
      "pending_ts_approval",
      "awaiting_completion",
    ];

    status.map((value, index) => {
      return getCasesNumbersByStatus(this.state.id, value).then((res) => {
        this.setState({ [value]: res.count });
      });
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  update doctor data or initial data  */
  /* --------------------------------------------------------------------------------*/

  GetDoctorInfo = () => {
    getDoctorInfo(this.state.id).then((res) => {
      const currentUser = {
        avatar: res.avatar,
        email: res.email,
        first_name: res.first_name,
        full_name: res.full_name,
        id: res.id,
        last_name: res.last_name,

        phone: res.phone,
        gco_doctor: res.gco_doctor,
        language: res.preferred_language,
        date_format: res.preferred_date_format,
        dental_notation: res.preferred_dental_notation,
        terms_approval: res.terms_approval,
      };

      const user = {
        ...res,
        language: res.preferred_language || "en",
        date_format: res.preferred_date_format || "DD/MM/YYYY",
        dental_notation: res.preferred_dental_notation || "fdi",
      };
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      localStorage.setItem("language", res.preferred_language || "en");
      this.props.setUserInfo(user);

      this.props.UpdateLanguage(res.preferred_language || "en");
      this.props.UpdateContent(res.preferred_language || "en");
      this.setState({
        finish_loading: true,
        banners: res.banners,
        start: true,
        loyalty: res.loyalty ? res.loyalty : null,
        gco_doctor: res.gco_doctor,
        finished_onboarding: res.finished_onboarding,
        terms_approval: res.terms_approval,
        language: res.preferred_language || "en",
        date_format: res.preferred_date_format || "DD/MM/YYYY",
        dental_notation: res.preferred_dental_notation || "fdi",
      });
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* component Will Unmount */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  RedirectToCaseWithFilter = (filter) => {
    localStorage.removeItem("data");
    this.props.history.push({
      pathname: `/cases`,
      state: {
        data: {
          filters: {
            uid_or_patient_full_name_cont: "",
            aligner_case_transition_to_state_in: [filter],
            display_status_eq: filter,
            s: `created_at desc`,
            archived_case_eq: false,
          },

          page: 1,
          per_page: 20,
        },
      },
    });
  };

  onChange = () => {
    const { loyalty } = this.props.userReducer.currentUser;
    this.props.history.push({
      pathname: "/loyalty",
      state: { loyalty: loyalty },
    });
  };
  render() {
    const {
      incomplete,
      rejected_records,
      pending_ts_approval,
      awaiting_completion,
      finished_onboarding,
      language,
      dental_notation,
      date_format,
      sendingData,
      terms_approval,
      finish_loading,
    } = this.state;

    const { block_case_submission } = this.props.userReducer.currentUser;
    const { content } = this.props.languageReducer;

    return (
      <div>
        {/* <InfoBip /> */}
        {finish_loading && (
          <OnBoarding
            content={content}
            language={language}
            finished_onboarding={finished_onboarding}
            terms_approval={terms_approval}
            GetDoctorInfo={this.GetDoctorInfo}
            dental_notation={dental_notation}
            date_format={date_format}
            sendingData={sendingData}
            setUserInfo={this.props.setUserInfo}
          />
        )}

        {this.state.start ? (
          <div>
            <div className="dashboard-container">
              <div className="dashboard-actions-grid ">
                {/* ///////AssessmentTool////// */}
                <AssessmentTool
                  inHome={true}
                  content={this.props.languageReducer.content}
                />

                {/* ///////submit aligner case////// */}
                <div
                  className={`callout-text  ${
                    block_case_submission
                      ? "s_card_grid_disabled cmb1"
                      : "s_card_grid cmb1"
                  }`}
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/case-submission",
                      state: { id: this.state.id },
                    });
                  }}
                >
                  <div>
                    <img
                      src={
                        block_case_submission
                          ? submit_aligner_not_active
                          : submit_aligner
                      }
                      alt={content.submit_aligner_case}
                    />
                  </div>
                  <div className="displayNone_inmobile">
                    {content.submit_aligner_case}
                  </div>
                  <div className="displayNone">
                    {content.submit}
                    <br></br> {content.aligner_case}
                  </div>
                </div>
                {/* //////submit retainer////// */}
                <div
                  className={`callout-text  ${
                    block_case_submission
                      ? "s_card_grid_disabled cmb1"
                      : "s_card_grid cmb1"
                  }`}
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/retainer",
                      state: { id: this.state.id },
                    });
                  }}
                >
                  <div>
                    <img
                      src={
                        block_case_submission
                          ? submit_retainer_not_active
                          : submit_retainer
                      }
                      alt={content.submit_retainer_case}
                    />
                  </div>
                  <div className="displayNone_inmobile">
                    {content.submit_retainer_case}
                  </div>
                  <div className="displayNone">
                    {" "}
                    {content.submit}
                    <br></br> {content.retainer_case}
                  </div>
                </div>

                {/* ////////review_cases////////// */}
                <div
                  className="s_card_grid cmb1 callout-text"
                  onClick={() => {
                    localStorage.removeItem("data");
                    this.props.history.push({
                      pathname: "/cases",
                      state: { id: this.state.id },
                    });
                  }}
                >
                  <div>
                    <img src={review_cases} alt={content.review_cases} />
                  </div>
                  <div>{content.review_cases}</div>
                </div>

                <div
                  className="s_card_grid cmb1 callout-text"
                  onClick={() => {
                    window.open("https://academy.eonaligner.com/", "_blank");
                  }}
                >
                  <div>
                    <img src={academy_new} alt={content.eon_academy} />
                  </div>
                  <div>{content.eon_academy}</div>
                </div>
                {/* <div className="text-center caption-1-text">{content.coming_soon}.</div> */}
              </div>

              <div className="border-right"></div>
              <div className="displayNone">
                <div className="dashboard-filters ">
                  <div className="header_title title-1-text">
                    {content.pending_action}
                  </div>
                  <div
                    className="filters-grid"
                    onClick={() => {
                      this.RedirectToCaseWithFilter("incomplete");
                    }}
                  >
                    <div
                      className="filter-circle"
                      style={{
                        background: statusColors["Incomplete Submission"].color,
                      }}
                    >
                      {incomplete}
                    </div>
                    <div className="callout-text">
                      {content.continue_case_submission}
                    </div>
                  </div>
                  <div
                    className="filters-grid"
                    onClick={() => {
                      this.RedirectToCaseWithFilter("rejected_records");
                    }}
                  >
                    <div
                      className="filter-circle"
                      style={{
                        background: statusColors["Records Rejected"].color,
                      }}
                    >
                      {rejected_records}
                    </div>
                    <div className="callout-text">
                      {content.review_rejected_records}
                    </div>
                  </div>
                  <div
                    className="filters-grid"
                    onClick={() => {
                      this.RedirectToCaseWithFilter("pending_ts_approval");
                    }}
                  >
                    <div
                      className="filter-circle"
                      style={{
                        background: statusColors["Pending TS Approval"].color,
                      }}
                    >
                      {pending_ts_approval}
                    </div>
                    <div className="callout-text">
                      {content.approve_treatment_setup}
                    </div>
                  </div>
                  <div
                    className="filters-grid"
                    onClick={() => {
                      this.RedirectToCaseWithFilter("awaiting_completion");
                    }}
                  >
                    <div
                      className="filter-circle"
                      style={{
                        background: statusColors["Awaiting Completion"].color,
                      }}
                    >
                      {awaiting_completion}
                    </div>
                    <div className="callout-text">{content.mark_complete}</div>
                  </div>
                </div>
              </div>

              <div className="border-right"></div>
              <div className="displayNone">
                <div className="banner">
                  <Banners banners={this.state.banners} />
                </div>
              </div>
            </div>

            <div className="bttom-border" />
            <div>
              <Loyalty
                loyalty={this.state.loyalty}
                content={content}
                language={language}
                onChange={this.onChange}
              />
            </div>
            <div></div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
