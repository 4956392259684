import { UPDATE_LANGUAGE, UPDATE_CONTENT } from "../constants/";
import { content } from "./content";

const lang = localStorage.getItem("language") || "en";
const INIT_STATE = {
  language: lang,
  content: content[lang],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case UPDATE_CONTENT:
      return {
        ...state,
        content: content[action.payload],
      };
    default:
      return state;
  }
};

export const selectContent = ({ languageReducer }) => languageReducer.content;
