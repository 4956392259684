import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import CustomToggle from "../../../../../components/toggle/CustomeToggle";

import CustomeHeader from "../../../../../components/header/CustomeHeader";
import CustomRadioGroup from "../../../../../components/radioGroup/CustomRadioGroup";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class PassiveAligner extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_passive_aligners: this.props.caseSubmission.advanced_modules
        .passiveAligners.usePassiveAligners,
      passive_aligners_details: this.props.caseSubmission.advanced_modules
        .passiveAligners.details,
      use_arch_upper: this.props.caseSubmission.advanced_modules
        .passiveAligners.use_arch_upper,
      use_arch_lower: this.props.caseSubmission.advanced_modules
        .passiveAligners.use_arch_lower,
      num_upper_passive_aligner: this.props.caseSubmission.advanced_modules
        .passiveAligners.num_upper_passive_aligner,
      num_lower_passive_aligner: this.props.caseSubmission.advanced_modules
        .passiveAligners.num_lower_passive_aligner,
      synchronize: this.props.caseSubmission.advanced_modules
        .passiveAligners.synchronize
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "passiveAligners",
      data: {
        usePassiveAligners: this.state.use_passive_aligners,
        use_arch_upper: this.state.use_arch_upper,
        use_arch_lower: this.state.use_arch_lower,
        num_upper_passive_aligner: this.state.num_upper_passive_aligner,
        num_lower_passive_aligner: this.state.num_lower_passive_aligner,
        synchronize: this.state.synchronize,
        details: this.state.passive_aligners_details,
      },
    });
    this.props.save();
  };

  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  handelChanges = (value) => {
    this.setState({
      attachmentsTiming: value
    })
  }
  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_passive_aligners,
      use_arch_upper,
      use_arch_lower,
      num_upper_passive_aligner,
      num_lower_passive_aligner,
      synchronize } = this.state;
    const { content } = this.props.languageReducer;


    const synchronize_options =
    [{ name: "start", title: content.start_together },
    { name: "end", title: content.end_Together}];

    return (
      <div>
        <div className="form-group content form-block-holder grid_container  restrict_tooth ">


        {/* <div class="D-total-cont"> */}
          <div className="customHeaderTS">
            <div> <CustomeHeader title={content.passive_aligner} className="cp3 pt-0" optional={false} note={false} note_text={content.passive_aligner_note} /></div>
            <div className="reative-close-ts">  <span className={` pull-right ts-close-button-new`} onClick={() => { this.props.toggleModalsClose(this.props.active_module) }}>&times;</span></div>
          </div>
{/* 
          </div> */}


          <div className="admin-Grid-Advance">
            <div></div>
            {/* part 1 */}
            <div className=" admin-Grid-Advance-inner">
              <div >
                {content.Used_Passive_aligners_for_this_case}{" "}
              </div>
              {/* part 2 */}
              <div className="D-total-cont-toggle">
              <div className="pointer_disable">
                <CustomToggle
                  id="use-pontics"
                  //  disabled={this.props.summary}
                  className="D-toggle-ts"
                  checked={use_passive_aligners}
                  onChange={() => {
                    this.setState({
                      use_passive_aligners: !use_passive_aligners,
                      ...use_passive_aligners && {
                        passive_aligners_details: "",
                        use_arch_upper: false,
                        use_arch_lower: false,
                        num_upper_passive_aligner: "",
                        num_lower_passive_aligner: "",
                        synchronize: ""
                      }
                    });
                  }} />
              </div>
              </div>
            </div>
            <div></div>
          </div>

          {/* ///part 2*/}
          <div className="passive-aligner-grid">
            <div>
              <div className="control-label title_active-gcoTS-new mb-2  D-head-ts">
                {content.arch}{" "}
              </div>
              <div className="arch_passive_num mb-2 D-head-ts">
               



              <div className="D-total-cont-checkBox">
                <Input

                  className={`text-center  ${use_arch_upper && "replacment-checkbox "
                    }`}
                  type="checkbox"
                  id="UpperTS"
                  label='Upper'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  checked={use_arch_upper}
                  disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide"}
                  onChange={() => {
                    this.setState({
                      // use_arch_upper: !use_arch_upper,
                      // ...use_arch_upper && {
                      //   num_upper_passive_aligner: "",
                      // }
                    });
                  }}
                />




</div>



                <div class="label-container">
                  <div className="control-label title_active-gcoTS-new custom-label D-head-ts">
                    {content.number_of_passive_aligners}
                  </div>
                </div>
                <div className="passive-aliginer-form-material">
                <div className="form-material">
                  <Input
                    type="number"
                    placeholder=""
                    className="custom-number"
                    value={num_upper_passive_aligner
                    }
                    onClick={e => {
                      e.target.focus();
                    }}
                    onMouseUp={e => {
                      e.target.blur();
                    }}

                    min="1"
                    onChange={(e) => {
                      this.setState({
                        num_upper_passive_aligner: e.target.value,
                      });
                    }}
                    disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide" || this.props.summary || !use_arch_upper}

                  />
                </div>
                </div>
              </div>

              <div className="arch_passive_num   pointerUnset D-head-ts">
            
<div className="D-total-cont-checkBox pointerUnset">

                <Input
                disabled

className={`text-center D-head-ts  pointerUnset  ${use_arch_upper && "replacment-checkbox   pointerUnset D-head-ts"
                    }`}
                  type="checkbox"
                  id="LowerTS"
                  label='Lower'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  checked={use_arch_lower}
                  // disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide"}
                  onChange={() => {
                    this.setState({
                      
                    });
                  }}
                />
                </div>
                <div class="label-container">
                <div className="control-label title_active-gcoTS-new  D-head-ts  custom-label">
                    {content.number_of_passive_aligners}
                  </div>
                </div>
                <div className="passive-aliginer-form-material">
                <div className="form-material">
                  <Input
                    type="number"
                    placeholder=""
                    className="custom-number"
                    value={num_lower_passive_aligner
                    }
                    onClick={e => {
                      e.target.focus();
                    }}
                    onMouseUp={e => {
                      e.target.blur();
                    }}
                    onChange={(e) => {
                      this.setState({
                        num_lower_passive_aligner: e.target.value,
                      });
                    }}
                    disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide" || this.props.summary || use_arch_lower}

                  />
                </div>
                </div>
              </div>

            </div>


            <div>
              <div className="control-label title_active-gcoTS-new  D-head-ts mb-2">
                {content.synchronize}
              </div>


              <div className="D-total-cont-checkBox pointerUnset">
              <div className="Dsables-text pointerUnset">
              <CustomRadioGroup name="synchronize" checked={synchronize}
                onChange={(value) => {
                  // this.setState({ synchronize: value })
                }}
                disabled={true}
                className="pointerUnset"
                style={{ pointerEvents: "unset" }}
                options={synchronize_options}
              />
              </div></div>





            </div>
          </div>
        </div>

        {!this.props.summary && <Button
          className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          {content.save}
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PassiveAligner);
