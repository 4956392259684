import React, { Component } from "react";

import MsgForm from "./MsgForm";
import MsgContent from "./MsgContent";
class Inbox extends Component {
  /*--------------------------------------------------------------------------------*/
  /* remove event listener whin leave component                                     */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*  after get all messages scroll to bottom                                       */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    this.updateScrollbar();
  }
  /*--------------------------------------------------------------------------------*/
  /*  scroll to bottom of messages                                                  */
  /*--------------------------------------------------------------------------------*/
  updateScrollbar = () => {
    const ctn = document.getElementById(`inbox`);
    setTimeout(() => {
      if (ctn) {
        ctn.scrollTo(0, ctn.scrollHeight);
      }
    }, 300);
  };
  render() {
    return (
      <div
        id={`inbox`}
        className="main-inbox-grid"
      >
        <div></div>
        <div>
          <MsgContent formKey={this.props.formKey} />
          <MsgForm caseId={this.props.caseId} formKey={this.props.formKey} />
        </div>
        <div></div>
      </div>


    );
  }
}

export default Inbox;
