import {
  TEETH_IPR_DATA,
  SAVED_DATA,
  REJECTION_REASON,
  PROTOCOL_FEEDBACK,
  CHANGE_TRACKER,
  SHOW_TOOTH_MOVEMENT,
  RESET_STEPS_DATA,
  RESET_ALL_TS_DATA,
  SHOW_IPR_TOOTH_MOVEMENT,
  TEETH_IPR_DATA_BY_ID,
  SAVED_DATA_BY_ID,
  REJECTION_REASON_BY_ID,
  PROTOCOL_FEEDBACK_BY_ID,
  STEP_VISITED,
  USED_IPR_BY_ID,
  TREATMENT_PLAN_ACTION
} from "../constants/";

export const setVisitedStep = (payload) => {
  return {
    type: STEP_VISITED,
    payload,
  };
};
export const resetAllTsData = (payload) => {
  return {
    type: RESET_ALL_TS_DATA,
    payload,
  };
};
export const setTeethIPRData = (payload) => {
  return {
    type: TEETH_IPR_DATA,
    payload,
  };
};

export const setSavedData = (payload) => {
  return {
    type: SAVED_DATA,
    payload,
  };
};

export const setProtocolFeedback = (payload) => {
  return {
    type: PROTOCOL_FEEDBACK,
    payload,
  };
};

export const setRejectionReson = (payload) => {
  return {
    type: REJECTION_REASON,
    payload,
  };
};

export const setTeethIPRDataById = (payload) => {
  return {
    type: TEETH_IPR_DATA_BY_ID,
    payload,
  };
};

export const setSavedDataById = (payload) => {
  return {
    type: SAVED_DATA_BY_ID,
    payload,
  };
};
export const setUsedIPRById=(payload)=>{
  return{
    type: USED_IPR_BY_ID,
    payload,
  }
}

export const treatmentPlanAction=(payload)=>{
  return{
    type: TREATMENT_PLAN_ACTION,
    payload,
  }
}
export const setProtocolFeedbackById = (payload) => {
  return {
    type: PROTOCOL_FEEDBACK_BY_ID,
    payload,
  };
};

export const setRejectionResonById = (payload) => {
  return {
    type: REJECTION_REASON_BY_ID,
    payload,
  };
};

export const setChangeTracker = (payload) => {
  return {
    type: CHANGE_TRACKER,
    payload,
  };
};

export const setShowToothMovement = (payload) => {
  return {
    type: SHOW_TOOTH_MOVEMENT,
    payload,
  };
};

export const setShowIPRToothMovement = (payload) => {
  return {
    type: SHOW_IPR_TOOTH_MOVEMENT,
    payload,
  };
};

export const resetStepsData = (payload) => {
  return {
    type: RESET_STEPS_DATA,
    payload,
  };
};
