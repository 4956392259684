export const data = [
  {
    key: "crossbite_btn",
    title: "Crossbite",
  },
  {
    key: "spacing_btn",
    title: "Spacing",
  },
  {
    key: "classII_btn",
    title: "Class II",
  },
  {
    key: "classIII_btn",
    title: "Class III",
  },
  {
    key: "openbite_btn",
    title: "Openbite",
  },
  {
    key: "deepbite_btn",
    title: "Deepbite",
  },
  {
    key: "midline_btn",
    title: "Midline",
  },
  {
    key: "crowding_btn",
    title: "Crowding",
  },
];
