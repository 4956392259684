import * as yup from "yup";


export const address_schema = (values) => {


  return yup.object({
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    line1: yup.string().required("Street is required"),
    line2: yup.string().required("Building is required"),
    state: yup.string(),
    zip: yup.string(),
    clinic_name :yup.string().nullable().required("Clinic Name is required"),
    phone: yup.string().required("Phone number is required"),
  });
};
