import React, { Component } from "react";
import { connect } from "react-redux";
import CustomeHeader from "../../../components/header/CustomeHeader";
import { Input } from "reactstrap";
import { ReactComponent as Increment } from "../../../assets/images/movement/increment.svg";
import { ReactComponent as Decrement } from "../../../assets/images/movement/decrement.svg";
import CustomToggle from "../../../components/toggle/CustomeToggle";
import ToothAttachments from "../../../components/Attachments/ToothAttachments";
import StepsElastic from "../../../components/StepsElastic/StepsElastic";
import {
  setUnsavedChanges,
  setUnsavedData,
} from "../../../redux/changes/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setUnsavedChanges: (payload) => dispatch(setUnsavedChanges(payload)),
  setUnsavedData: (payload) => dispatch(setUnsavedData(payload)),
});
class ToothMovement extends Component {


  constructor(props) {
    super(props);
    this.state = {
      LeftRight: Number(this.props.data.LeftRight.split(" ")[0]),
      Rotation: Number(this.props.data.Rotation.split(" ")[0]),
      Angulation: Number(this.props.data.Angulation.split(" ")[0]),
      Inclination: Number(this.props.data.Inclination.split(" ")[0]),
      ExtrusionIntrusion: Number(
        this.props.data.ExtrusionIntrusion.split(" ")[0]
      ),
      ForwardBackward: Number(this.props.data.ForwardBackward.split(" ")[0]),
      ToothName: this.props.data.ToothName,
      use_extraction: this.props.data.use_extraction,
      useAttachments: this.props.data.useAttachments,

      Attachment_Note: this.props.data.Attachment_Note,
      attachments_step: Number(this.props.data.attachments_step),
      attachment_type: this.props.data.attachment_type,
      usePontics: this.props.data.usePontics,
      useTorqueEnhancers: this.props.data.useTorqueEnhancers,
      use_buttons: this.props.data.use_buttons,
      use_cuts: this.props.data.use_cuts,
      note: this.props.data.note,
      ToothNumber: this.props.data.ToothNumber,
      old_tooth_data: this.props.old_tooth_data,
      OldLeftRight:
        Number(this.props.old_tooth_data?.LeftRight.split(" ")[0]) || 0,
      OldRotation:
        Number(this.props.old_tooth_data?.Rotation.split(" ")[0]) || 0,
      OldAngulation:
        Number(this.props.old_tooth_data?.Angulation.split(" ")[0]) || 0,
      OldInclination:
        Number(this.props.old_tooth_data?.Inclination.split(" ")[0]) || 0,
      OldExtrusionIntrusion:
        Number(this.props.old_tooth_data?.ExtrusionIntrusion.split(" ")[0]) ||
        0,
      OldForwardBackward:
        Number(this.props.old_tooth_data?.ForwardBackward.split(" ")[0]) || 0,
      restrict_tooth: this.props.data.restrict_tooth,
      attachments: this.props.data.attachments,
      old_attachment: this.props.data.attachments,
      useElastics: this.props.data.useElastics,
      elastics: this.props.data.elastics,
    };
  }


  checkChanges = () => {
    if (
      Number(this.props.data.LeftRight.split(" ")[0]) !==
      this.state.LeftRight ||
      Number(this.props.data.Rotation.split(" ")[0]) !== this.state.Rotation ||
      Number(this.props.data.Angulation.split(" ")[0]) !==
      this.state.Angulation ||
      Number(this.props.data.Inclination.split(" ")[0]) !==
      this.state.Inclination ||
      Number(this.props.data.ExtrusionIntrusion.split(" ")[0]) !==
      this.state.ExtrusionIntrusion ||
      Number(this.props.data.ForwardBackward.split(" ")[0]) !==
      this.state.ForwardBackward ||
      this.props.data.use_extraction !== this.state.use_extraction ||
      this.props.data.useAttachments !== this.state.useAttachments ||
      this.props.data.Attachment_Note !== this.state.Attachment_Note ||
      this.props.data.useElastics !== this.state.useElastics ||
      this.props.data.elastics !== this.state.elastics ||
      Number(this.props.data.attachments_step) !==
      this.state.attachments_step ||
      this.props.data.attachment_type !== this.state.attachment_type ||
      this.props.data.usePontics !== this.state.usePontics ||
      this.props.data.useTorqueEnhancers !== this.state.useTorqueEnhancers ||
      this.props.data.use_buttons !== this.state.use_buttons ||
      this.props.data.use_cuts !== this.state.use_cuts ||
      this.props.data.note !== this.state.note ||
      this.props.data.restrict_tooth !== this.state.restrict_tooth ||
      this.checkAttchments()
    ) {
      return false;
    } else {
      return true;
    }
  };

  componentDidUpdate = () => {
    if (!this.checkChanges() && !this.props.changesReducer.dataChanged) {
      let new_data = {
        ...this.props.data,
        LeftRight: this.state.LeftRight + " mm",
        Rotation: this.state.Rotation + " deg",
        Angulation: this.state.Angulation + " deg",
        Inclination: this.state.Inclination + " deg",
        ExtrusionIntrusion: this.state.ExtrusionIntrusion + " mm",
        ForwardBackward: this.state.ForwardBackward + " mm",
        ToothName: this.state.ToothName,
        use_extraction: this.state.use_extraction,
        useAttachments: this.state.useAttachments,

        Attachment_Note: this.state.Attachment_Note,
        useElastics: this.state.useElastics,
        attachments_step: this.state.attachments_step.toString(),
        attachment_type: this.state.attachment_type,
        note: this.state.note,
        usePontics: this.state.usePontics,
        useTorqueEnhancers: this.state.useTorqueEnhancers,
        use_buttons: this.state.use_buttons,
        use_cuts: this.state.use_cuts,
        updated: true,
        restrict_tooth: this.state.restrict_tooth,
        attachments: this.state.attachments,
        elastics: this.state.elastics,
      };
      this.props.setUnsavedChanges(true);
      this.props.setUnsavedData(new_data);
    }
  };

  checkAttchments = () => {
    let changed = false;
    if (this.props.data.attachments !== this.state.attachments) {
      changed = true;
    } else {
      if (this.state.attachments.length && this.props.data.attachments.length) {
        for (var x = 0; x < this.state.attachments.length; x++) {
          if (
            this.state.attachments[x].step !==
            this.props.data.attachments[x].step ||
            this.state.attachments[x].type !==
            this.props.data.attachments[x].type
          ) {
            changed = true;
          }
        }
      }
    }
    return changed;
  };
  saveToothMovementData = () => {
    let new_data = {
      ...this.props.data,
      LeftRight: this.state.LeftRight + " mm",
      Rotation: this.state.Rotation + " deg",
      Angulation: this.state.Angulation + " deg",
      Inclination: this.state.Inclination + " deg",
      ExtrusionIntrusion: this.state.ExtrusionIntrusion + " mm",
      ForwardBackward: this.state.ForwardBackward + " mm",
      ToothName: this.state.ToothName,
      use_extraction: this.state.use_extraction,
      useAttachments: this.state.useAttachments,
      Attachment_Note: this.state.Attachment_Note,
      useElastics: this.state.useElastics,
      attachments_step: this.state.attachments_step.toString(),
      attachment_type: this.state.attachment_type,
      note: this.state.note,
      usePontics: this.state.usePontics,
      useTorqueEnhancers: this.state.useTorqueEnhancers,
      use_buttons: this.state.use_buttons,
      use_cuts: this.state.use_cuts,
      updated: true,
      restrict_tooth: this.state.restrict_tooth,
      attachments: this.state.attachments,
      elastics: this.state.elastics,
    };
    this.props.saveToothMovementData(new_data);
  };
  handelChanges = (value) => {
    this.setState({
      elastics: value,
    });
  };
  handelChangesAttachment_Note = (e) => {
    this.setState({
      Attachment_Note: e.target.value,
    });
  };


  AddRestrictedTooth = () => {
    const { restrict_tooth } = this.state;
    if (!restrict_tooth) {

      this.setState({
        LeftRight: 0,
        Rotation: 0,
        Angulation: 0,
        Inclination: 0,
        ExtrusionIntrusion: 0,
        ForwardBackward: 0,
        use_extraction: false,
        useAttachments: false,
        Attachment_Note: "",
        useElastics: false,
        attachments_step: 0,
        attachment_type: "",
        usePontics: false,
        useTorqueEnhancers: false,
        use_buttons: false,
        use_cuts: false,
        attachments: [
          {
            step: 0,
            type: "",
          },
        ],
        elastics: [
          {
            from: 0,
            to: 0,
          },
        ],
      });
    } else {
      this.setState({
        LeftRight: Number(this.props.data.LeftRight.split(" ")[0]),
        Rotation: Number(this.props.data.Rotation.split(" ")[0]),
        Angulation: Number(this.props.data.Angulation.split(" ")[0]),
        Inclination: Number(this.props.data.Inclination.split(" ")[0]),
        ExtrusionIntrusion: Number(
          this.props.data.ExtrusionIntrusion.split(" ")[0]
        ),
        ForwardBackward: Number(this.props.data.ForwardBackward.split(" ")[0]),
        use_extraction: this.props.data.use_extraction,
        useAttachments: this.props.data.useAttachments,
        Attachment_Note: this.props.data.Attachment_Note,
        useElastics: this.props.data.useElastics,
        attachments_step: Number(this.props.data.attachments_step),
        attachment_type: this.props.data.attachment_type,
        usePontics: this.props.data.usePontics,
        useTorqueEnhancers: this.props.data.useTorqueEnhancers,
        use_buttons: this.props.data.use_buttons,
        use_cuts: this.props.data.use_cuts,
        attachments: this.props.data.attachments,
        elastics: this.props.data.elastics,
      });
    }
  };

  render() {

    const {
      LeftRight,
      Rotation,
      Angulation,
      Inclination,
      ExtrusionIntrusion,
      ForwardBackward,
      use_extraction,
      useAttachments,
      Attachment_Note,
      useElastics,
      usePontics,
      useTorqueEnhancers,
      OldLeftRight,
      OldRotation,
      OldAngulation,
      OldInclination,
      OldExtrusionIntrusion,
      OldForwardBackward,
      restrict_tooth,
      attachments,
    } = this.state;

    const useAttachmentAdmain = this.props.old_tooth_data.useAttachments
    const { ToothSystemName } = this.props;
    const { dental_notation } = this.props.userReducer.currentUser;
    const { content } = this.props.languageReducer;

    return (
      <div className="tooth_movement_container">
        <CustomeHeader
          title={ToothSystemName ? ToothSystemName[dental_notation] : ""}

        />

        <div
          className={`${restrict_tooth ? "restrict_tooth" : ""
            } tooth_movement_grid cmt2`}
        >
          <div>
            <div className="movement_value_grid cmb2">
              <div className="header">{content.movement}</div>
              <div className="header">{content.value}</div>
            </div>
            {/* rotation */}
            <div className="movement_value_grid cmb1">
              <div>{content.rotation}</div>
              <div>
                <div className="value_grid">
                  <div>
                    <Increment
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = Rotation + 0.1;
                        this.setState({ Rotation: Number(value.toFixed(1)) });
                      }}
                    />
                  </div>
                  <div>
                    <Decrement
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = Rotation - 0.1;
                        this.setState({ Rotation: Number(value.toFixed(1)) });
                      }}
                    />
                  </div>
                  <div
                    className={`${Rotation !== OldRotation ? "updated-value" : ""
                      }`}
                  >
                    {`${Math.abs(Rotation)} ${content.deg} ${Rotation >= 0
                      ? content.distal.toLowerCase()
                      : content.mesial.toLowerCase()}`}

                  </div>
                </div>
              </div>
            </div>
            {/* end  rotation */}

            {/* angulation */}
            <div className="movement_value_grid cmb1">
              <div>{content.angulation}</div>
              <div>
                <div className="value_grid">
                  <div>
                    <Increment
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = Angulation + 0.1;
                        this.setState({ Angulation: Number(value.toFixed(1)) });
                      }}
                    />
                  </div>
                  <div>
                    <Decrement
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = Angulation - 0.1;
                        this.setState({ Angulation: Number(value.toFixed(1)) });
                      }}
                    />
                  </div>
                  <div
                    className={`${Angulation !== OldAngulation ? "updated-value" : ""
                      }`}
                  >
                    {`${Math.abs(Angulation)}  ${content.deg} ${Angulation >= 0
                      ? content.toward.toLowerCase()
                      : content.away.toLowerCase()}`}
                    
                  </div>
                </div>
              </div>
            </div>
            {/* end angulation */}

            {/* torque */}
            <div className="movement_value_grid cmb1">
              <div>{content.torque}</div>
              <div>
                <div className="value_grid">
                  <div>
                    <Increment
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = Inclination + 0.1;
                        this.setState({
                          Inclination: Number(value.toFixed(1)),
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Decrement
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = Inclination - 0.1;
                        this.setState({
                          Inclination: Number(value.toFixed(1)),
                        });
                      }}
                    />
                  </div>
                  <div
                    className={`${Inclination !== OldInclination ? "updated-value" : ""
                      }`}
                  >
                    {`${Math.abs(Inclination)}  ${content.deg} ${Inclination >= 0
                      ? content.labial.toLowerCase()
                      : content.lingual.toLowerCase()}`}
                    
                  </div>
                </div>
              </div>
            </div>
            {/* end torque */}

            {/* Left Right */}
            <div className="movement_value_grid cmb1">
              <div>{`${content.mesial}(+) / ${content.distal}(-)`}</div>
              <div>
                <div className="value_grid">
                  <div>
                    <Increment
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = LeftRight + 0.1;
                        this.setState({ LeftRight: Number(value.toFixed(1)) });
                      }}
                    />
                  </div>
                  <div>
                    <Decrement
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = LeftRight - 0.1;
                        this.setState({ LeftRight: Number(value.toFixed(1)) });
                      }}
                    />
                  </div>
                  <div
                    className={`${LeftRight !== OldLeftRight ? "updated-value" : ""
                      }`}
                  >
                    {LeftRight} {`mm`}
                  </div>
                </div>
              </div>
            </div>
            {/* end  Left Right */}

            {/* Extrusion Intrusion */}
            <div className="movement_value_grid cmb1">
              <div>{`${content.intrusion}(+) / ${content.extrusion}(-)`}</div>
              <div>
                <div className="value_grid">
                  <div>
                    <Increment
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = ExtrusionIntrusion + 0.1;
                        this.setState({
                          ExtrusionIntrusion: Number(value.toFixed(1)),
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Decrement
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = ExtrusionIntrusion - 0.1;
                        this.setState({
                          ExtrusionIntrusion: Number(value.toFixed(1)),
                        });
                      }}
                    />
                  </div>
                  <div
                    className={`${ExtrusionIntrusion !== OldExtrusionIntrusion
                      ? "updated-value"
                      : ""
                      }`}
                  >
                    {ExtrusionIntrusion} {`mm`}
                  </div>
                </div>
              </div>
            </div>
            {/* end Extrusion Intrusion */}

            {/* Forward Backward */}
            <div className="movement_value_grid cmb1">
              <div>{`${content.out}(+) / ${content.in}(-)`}</div>
              <div>
                <div className="value_grid">
                  <div>
                    <Increment
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = ForwardBackward + 0.1;
                        this.setState({
                          ForwardBackward: Number(value.toFixed(1)),
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Decrement
                      className="increment-decrement-icon"
                      onClick={() => {
                        var value = ForwardBackward - 0.1;
                        this.setState({
                          ForwardBackward: Number(value.toFixed(1)),
                        });
                      }}
                    />
                  </div>
                  <div
                    className={`${ForwardBackward !== OldForwardBackward
                      ? "updated-value"
                      : ""
                      }`}
                  >
                    {ForwardBackward} {`mm`}
                  </div>
                </div>
              </div>
            </div>
            {/* end Forward Backward */}
          </div>
          <div>
            {/* use Attachments toggle */}
            <CustomToggle
              id="movement_attachments"
              name="movement_attachments"
              checked={useAttachments ? true : false}
              onChange={() => {
                let use_attachments_value = useAttachments ? false : true;
                this.setState({
                  Attachment_Note: "",
                  useAttachments: use_attachments_value,
                  ...(!use_attachments_value && {
                    attachments: [{ step: 0, type: "" }],
                    Attachment_Note: "",
                  }),

                });
              }}
              disabled={restrict_tooth}
              title={content.use_attachments}
            />
            {/* end use Attachments toggle */}
            <div className="ToothAttachments">
              <ToothAttachments
                onclick={(attachments) => {
                  this.setState({ attachments: attachments });
                }}
                attachments_array={attachments}
                StepTitle={content.add_attachments_on_step}
                hyberText={content.add_another_attachment}
                content={content}
                useAttachments={useAttachments}
                useAttachmentAdmain={useAttachmentAdmain}
              />
            </div>

            {/* {useAttachmentAdmain && <div className="textarea-attachment-container"> <Input
                                        className="custome-textarea-attachment"
                                        name="Attachment_Note"
                                        id="Attachment_Note"
                                        type="textarea"
                                        placeholder={content.AttachmentTypeNOTE}
                                        rows={2}
                                        onClick={(e) => {
                                            e.target.focus();
                                          }}
                                          onMouseUp={(e) => {
                                            e.target.blur();
                                          }}
                                          onChange={(value) => {
                                            this.handelChangesAttachment_Note(value);
                                          }}
                                          value={Attachment_Note}
                                    
                                        disabled={!useAttachments || restrict_tooth}
                                    /> </div>} */}

            <br />
            {/* use elastic toggle */}
            <CustomToggle
              id="movement_elastic"
              name="movement_elastic"
              checked={useElastics ? true : false}
              onChange={() => {
                let use_Elastics_value = useElastics ? false : true;
                this.setState({
                  useElastics: use_Elastics_value,
                  ...(!use_Elastics_value && {
                    elastics: [{ from: 0, to: 0 }],
                  }),
                });
              }}
              disabled={restrict_tooth}
              title={content.use_elastics}
            />
            {/* end elastic toggle */}

            <StepsElastic
              StepsElasticTitle={content.use_from_stage}
              to={content.to}
              hyberText={content.add_another_elastics}
              content={content}
              arrayOf_steps={this.state.elastics}
              onclick={(value) => {
                this.handelChanges(value);
              }}
              useElastics={useElastics}
            />
          </div>

          <div>
            {/* use Pontics toggle */}

            <CustomToggle
              id="movement_pontics"
              name="movement_pontics"
              checked={usePontics ? true : false}
              onChange={() => {
                let use_pontics_value = usePontics ? false : true;
                this.setState({ usePontics: use_pontics_value });
              }}
              disabled={restrict_tooth}
              title={content.pontics}
            />
            {/* end use Pontics toggle */}

            {/* use extraction toggle */}
            <CustomToggle
              id="movement_extraction"
              name="movement_extraction"
              checked={use_extraction ? true : false}
              onChange={() => {
                let use_extraction_value = use_extraction ? false : true;
                this.setState({ use_extraction: use_extraction_value });
              }}
              disabled={restrict_tooth}
              title={content.extraction}
            />
            {/* end use extraction toggle */}

            {/* use Torque Enhancers toggle */}

            <CustomToggle
              id="movement_torque_enhancers"
              name="movement_torque_enhancers"
              checked={useTorqueEnhancers ? true : false}
              onChange={() => {
                let use_torque_enhancers_value = useTorqueEnhancers
                  ? false
                  : true;
                this.setState({
                  useTorqueEnhancers: use_torque_enhancers_value,
                });
              }}
              disabled={restrict_tooth}
              title={content.torque_enhancers}
            />
            {/* end restrict_tooth toggle */}

            <CustomToggle
              id="movement_restrict_tooth"
              name="movement_restrict_tooth"
              checked={restrict_tooth ? true : false}
              onChange={() => {
                let use_restrict_tooth_value = restrict_tooth ? false : true;
                this.setState({ restrict_tooth: use_restrict_tooth_value });

                this.AddRestrictedTooth();
              }}
              title={content.restrict_tooth}
            />
            {/* end restrict_tooth toggle */}

            <div className="actions-grid align-self-end">
              <div
                className={`${this.checkChanges()
                  ? "event-none save-movement"
                  : "save-movement-active"
                  } h-100 float-right`}
                disabled={this.checkChanges()}
                onClick={() => {
                  this.saveToothMovementData();
                }}
              >
                {content.save}
              </div>

              <div
                className={`save-movement h-100 float-right text-center`}
                onClick={() => {
                  this.props.cancelChangesOnToothMovement();
                }}
              >
                {content.cancel}
              </div>
            </div>
          </div>
        </div>
        {/* <Card className="mt-3">
                    <CardTitle className="border-bottom p-2 mb-0 detailes_title case-title">
                        Tooth Name   {ToothSystemName ? ToothSystemName[dental_notation] : ""}
                    </CardTitle>
                    <CardBody className="p-0" >
                        <div className="movement-modules-grid">
                            <div>
                                <div className="auto_grid5 pb-2">
                                    <div className="control-label title_active-gco">
                                        Attachments
                                </div>
                                    <div>
                                        <ToggleSwitch
                                            id="useAttachments"
                                            checked={useAttachments}
                                            onChange={() => {
                                                this.setState({ useAttachments: !useAttachments })
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="auto_grid5 pb-2">
                                    <div className="control-label title_active-gco">
                                        Attachments Step
                                </div>
                                    <div className="number-ipr-detailes form-material">
                                        <Input type="number" value={attachments_step} className="hide-arrows" min="0"
                                            step="1" onChange={(e) => {

                                                if (e.target.value < 0) {
                                                    this.setState({ attachments_step: 0 })
                                                } else {
                                                    this.setState({ attachments_step: Number(e.target.value) })
                                                }

                                            }} />
                                        <div className="auto_grid6">


                                            <i onClick={() => {
                                                this.setState({ attachments_step: Number(attachments_step) + 1 })
                                            }} className="fas fa-angle-up num-angle"></i>
                                            <i onClick={() => {
                                                if (attachments_step > 0) {
                                                    this.setState({ attachments_step: Number(attachments_step) - 1 })
                                                }

                                            }} className={`fas fa-angle-down ${attachments_step > 0 ? "num-angle" : "disable-num-angle"}  `}></i>

                                        </div>
                                    </div>
                                </div>
                                <div className=" pb-2">
                                    <div className="control-label title_active-gco pb-2">
                                        Attachments Type
                                </div>

                                    <div className="">
                                        <Input type="textarea" rows={2} value={attachment_type} onChange={(e) => {
                                            this.setState({ attachment_type: e.target.value })
                                        }} />
                                    </div>

                                </div>

                            </div>

                            <div>
                                <div className="auto_grid5 pb-2">
                                    <div className="control-label title_active-gco">
                                        Extraction
                                </div>
                                    <div>
                                        <ToggleSwitch
                                            id="use_extraction"
                                            checked={use_extraction}
                                            onChange={() => {
                                                this.setState({ use_extraction: !use_extraction })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="auto_grid5 pb-2">
                                    <div className="control-label title_active-gco">
                                        Pontics
                                </div>
                                    <div>
                                        <ToggleSwitch
                                            id="usePontics"
                                            checked={usePontics}
                                            onChange={() => {
                                                this.setState({ usePontics: !usePontics })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="auto_grid5 pb-2">
                                    <div className="control-label title_active-gco">
                                        Torque Enhancers
                                </div>
                                    <div>
                                        <ToggleSwitch
                                            id="useTorqueEnhancers"
                                            checked={useTorqueEnhancers}
                                            onChange={() => {
                                                this.setState({ useTorqueEnhancers: !useTorqueEnhancers })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="auto_grid5 pb-2">
                                    <div className="control-label title_active-gco">
                                        Elastics Cuts
                                </div>
                                    <div>
                                        <ToggleSwitch
                                            id="use_cuts"
                                            checked={use_cuts}
                                            onChange={() => {
                                                this.setState({ use_cuts: !use_cuts })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="auto_grid5 pb-2">
                                    <div className="control-label title_active-gco">
                                        Elastics Buttons
                                </div>
                                    <div>
                                        <ToggleSwitch
                                            id="use_buttons"
                                            checked={use_buttons}
                                            onChange={() => {
                                                this.setState({ use_buttons: !use_buttons })
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="actions-grid">
                                <div><Button
                                    className={`btn h-100  actions-btn ${"preference-btn"}  float-right text-center  btn-radius`}
                                    size="sm"
                                    type="button"

                                    disabled={this.checkChanges()}
                                    onClick={() => { this.saveToothMovementData() }}  >
                                    Save Changes  </Button></div>

                                <div><Button
                                    className={`btn h-100 actions-btn  ${"preference-btn"}  float-right text-center  btn-radius`}
                                    size="sm"
                                    type="button"
                                    onClick={() => { this.props.cancelChangesOnToothMovement() }}  >
                                    Cancel  </Button>

                                </div>
                            </div>
                        </div>
                        <div className="movement-note-modules-grid">
                            <div className=" pb-2">
                                <div className="control-label title_active-gco pb-2">
                                    Notes
                                </div>

                                <div className="">
                                    <Input type="textarea" rows={4} value={note} onChange={(e) => {
                                        this.setState({ note: e.target.value })
                                    }} />
                                </div>

                            </div>
                            <div></div>
                        </div>

                    </CardBody>
                </Card> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToothMovement);
