export function mapTeethToApi(Data) {
  let RightTeethUpper = mapTeeth(Data, "upper_teeth", "upper_r_", "U");
  let RightTeethLower = mapTeeth(Data, "lower_teeth", "lower_r_", "L");

  let LeftTeethUpper = mapTeeth(Data, "upper_teeth", "upper_l_", "U");
  let LeftTeethLower = mapTeeth(Data, "lower_teeth", "lower_l_", "L");
  let teeth = {
    R: { ...RightTeethUpper, ...RightTeethLower },
    L: { ...LeftTeethUpper, ...LeftTeethLower },
  };
  return teeth;
}

function mapTeeth(Data, teeth, key, position) {
  let Teeth = {};
  for (var x = 1; x < 9; x++) {
    Teeth = {
      ...Teeth,
      [`${position}${x}`]: `${Data[`${teeth}`][`${key}${x}`].value.toString()}`,
    };
  }
  return Teeth;
}
