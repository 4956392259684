import React, { Component } from "react";
import { connect } from "react-redux";
import { CustomInput} from "reactstrap";
import {
    clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
import {
    Clear_Teeth_Data
} from "../../../../../redux/customeTeeth/action"
import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({
    clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),
    Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload))
});
class Crowding extends Component {
    /* --------------------------------------------------------------------------------*/
    /* constructor */
    /* --------------------------------------------------------------------------------*/
    constructor(props) {
        super(props);
        this.state = {
            use_extraction: ""
        }

        /* init state */
        //use_extraction
        this.use_extraction =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.use_extraction || false;
        //Proclination treatment Options
        this.proclination =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.treatment_Options?.proclination || false;
        //expansion treatment Options
        this.expansion =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.treatment_Options?.expansion || false;
        //ipr treatment Options
        this.ipr =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.treatment_Options?.ipr || false;
        //Distalisation treatment Options
        this.distalisation =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.treatment_Options?.distalisation || false;


    }

    UpdateCrossBiteReducer = () => {
        let data = {
            use_extraction: this.use_extraction,
            teeth: this.props.customeTeethReducer.Case_Crowding,
            treatment_Options: {
                proclination: this.proclination,
                expansion: this.expansion,
                ipr: this.ipr,
                distalisation: this.distalisation,
            },
        };

        this.props.save("crowding", data);
    };
    /* --------------------------------------------------------------------------------*/
    /* handel Treatment Options Changes */
    /* --------------------------------------------------------------------------------*/
    handelTreatmentOptionsChanges = (value, key) => {
        this.use_extraction = value
    }
    render() {
        const {
            proclination,
            expansion,
            ipr,
            distalisation,
        } = this;
        const { content } = this.props.languageReducer
        return (
            <div className="">
                 <CustomeHeader title={content.crowding} optional={false} largeFont={false}/>
                <div className="buttom-paddingFORMalc">
                    <div className="control-label title_active-gco title_tertment_option title-3-text">
                    {content.treatment_options}{" "}
                    </div>
                    <CustomInput
                        className={`pt-1 ${proclination && "replacment-checkbox"} button-text-select`}
                        type="checkbox"
                        id="proclination"
                        checked={proclination}
                        disabled={this.props.summary}
                        onChange={() => {
                            this.proclination = !this.proclination;
                            this.UpdateCrossBiteReducer();
                        }}
                        label={content.resolve_with_proclination}
                    />
                    <CustomInput
                        className={`pt-1 ${expansion && "replacment-checkbox"} button-text-select`}
                        type="checkbox"
                        id="expansion-crowding"
                        checked={expansion}
                        disabled={this.props.summary}
                        onChange={() => {
                            this.expansion = !this.expansion;
                            this.UpdateCrossBiteReducer();
                        }}
                        label={content.resolve_with_expansion}
                    />
                    <CustomInput
                        className={`pt-1 ${ipr && "replacment-checkbox"} button-text-select`}
                        type="checkbox"
                        id="ipr"
                        checked={ipr}
                        disabled={this.props.summary}
                        onChange={() => {
                            this.ipr = !this.ipr;
                            this.UpdateCrossBiteReducer();
                        }}
                        label={content.resolve_with_ipr}
                    />
                    <CustomInput
                        className={`pt-1 ${distalisation && "replacment-checkbox"} button-text-select`}
                        type="checkbox"
                        id="distalisation"
                        checked={distalisation}
                        disabled={this.props.summary}
                        onChange={() => {
                            this.distalisation = !this.distalisation;
                            this.UpdateCrossBiteReducer();
                        }}
                        label={content.resolve_with_distalisation}
                    />
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Crowding);
