import {
  CASE_CROWDING,
  CASE_TOOTH_MOVEMENT_RESTRICTIONS,
  CASE_EXTRACTION,
  CASE_CROSSBITES,
  CASE_CLASSII,
  CASE_CLASSIII,
  CASE_ATTACHMENTS,
  CASE_IPR,
  CASE_PONTICS,
  CASE_OVERCORRECTION,
  CASE_PASSIVE_ALIGNERS,
  CASE_TORQUE_ENHANCERS,
  CASE_ELASTICS,
  CASE_ELASTICS_BUTTONS,
  CASE_ELASTICS_CUTS,
  CASE_ARCH_EXPANSION,
  DOCTOR_PREFERENCES_IPR,
  DOCTOR_PREFERENCES_ATTACHMENTS,
  CASE_TEETHMOVEMENT,
  CLEAR_TEETH_DATA
} from "../constants";

export const Case_teethMovement = (payload) => {
  return {
    type: CASE_TEETHMOVEMENT,
    payload,
  };
};

export const Case_Crowding = (payload) => {
  return {
    type: CASE_CROWDING,
    payload,
  };
};
export const Case_Tooth_Movement_Restrictions = (payload) => {
  return {
    type: CASE_TOOTH_MOVEMENT_RESTRICTIONS,
    payload,
  };
};

export const Case_Extraction = (payload) => {
  return {
    type: CASE_EXTRACTION,
    payload,
  };
};

export const Case_Crossbites = (payload) => {
  return {
    type: CASE_CROSSBITES,
    payload,
  };
};

export const Case_ClassII = (payload) => {
  return {
    type: CASE_CLASSII,
    payload,
  };
};
export const Case_ClassIII = (payload) => {
  return {
    type: CASE_CLASSIII,
    payload,
  };
};
export const Case_Attachments = (payload) => {
  return {
    type: CASE_ATTACHMENTS,
    payload,
  };
};

export const Case_IPR = (payload) => {
  return {
    type: CASE_IPR,
    payload,
  };
};

export const Case_Pontics = (payload) => {
  return {
    type: CASE_PONTICS,
    payload,
  };
};

export const Case_Overcorrections = (payload) => {
  return {
    type: CASE_OVERCORRECTION,
    payload,
  };
};

export const Case_Passive_Aligners = (payload) => {
  return {
    type: CASE_PASSIVE_ALIGNERS,
    payload,
  };
};

export const Case_Torque_Enhancers = (payload) => {
  return {
    type: CASE_TORQUE_ENHANCERS,
    payload,
  };
};

export const Case_Elastics = (payload) => {
  return {
    type: CASE_ELASTICS,
    payload,
  };
};
export const Case_Elastics_Buttons = (payload) => {
  return {
    type: CASE_ELASTICS_BUTTONS,
    payload,
  };
}; export const Case_Elastics_Cuts = (payload) => {
  return {
    type: CASE_ELASTICS_CUTS,
    payload,
  };
};
export const Case_Arch_Expansion = (payload) => {
  return {
    type: CASE_ARCH_EXPANSION,
    payload,
  };
};
export const Doctor_Preferences_Attachments = (payload) => {
  return {
    type: DOCTOR_PREFERENCES_ATTACHMENTS,
    payload,
  };
};

export const Doctor_Preferences_IPR = (payload) => {
  return {
    type: DOCTOR_PREFERENCES_IPR,
    payload,
  };
};


export const Clear_Teeth_Data = (payload) => {
  return {
    type: CLEAR_TEETH_DATA,
    payload,

  }
}