import React, { Component } from "react";
import { connect } from "react-redux";
import CustomeHeader from "../../../../components/header/CustomeHeader"
import { setProtocolFeedback } from "../../../../redux/Tooth_IPR_Options/action";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setProtocolFeedback: (payload) => dispatch(setProtocolFeedback(payload)),

});

class TreatmentProtocols extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: this.props.teethIPROptions.clinical_protocol_feedback
    };
  }

  handelDetails = (e) => {
    this.setState({ details: e.target.value });
    this.props.setProtocolFeedback(e.target.value);
  }
  saveTretmentProtocols = () => {
    this.props.setProtocolFeedback(this.state.details);
  }


  render() {
    const { content } = this.props.languageReducer;
    const { mobile } = this.props;
    return (
      <div>
       {mobile&& <CustomeHeader title={mobile ? content.revision_notes  : content.provide_treatment_revision_notes} largeFont={mobile ? true : false} 
        withoutLine={window.innerWidth > 839 ? false : true}/> }
          {mobile && <div className="callout-text subhead-container">{content.provide_treatment_revision_notes}</div>}
        <textarea className="TreatmentProtocols-textarea"
          name="details"
          id="details"
          type="textarea"
          placeholder={content.typeHere}
          rows={5}
          value={this.state.details}
          onChange={this.handelDetails}

        />
        {/* <div className="ReasonsRejection-container-button"><Button className="ReasonsRejection-button light-green-btn btn" onClick={this.saveTretmentProtocols}>{content.next}</Button></div>  */}
      </div>
    )
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(TreatmentProtocols);

