import React, { Component } from "react";
import { connect } from "react-redux";

import { Button } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import CustomeHeader from "../../../../../components/header/CustomeHeader";
import CustomIPRTeeth from "../../../../Teeth/CustomIPRTeeth";
import CustomToggle from "../../../../../components/toggle/CustomeToggle";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../../../../iprPDF/MyDocument";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class IPR extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_ipr: this.props.caseSubmission.advanced_modules.ipr.useIpr,
      ipr_timing_1: false,
      ipr_timing_3: false,
      iprTiming: this.props.caseSubmission.advanced_modules.ipr.iprTiming,
    };
  }



  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "ipr",
      data: {
        useIpr: this.state.use_ipr,
        iprTiming: this.state.iprTiming,
        iprRestriction: this.props.customeTeethReducer.Case_IPR,
      },
    });
    this.props.save();
  };

  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
    /* --------------------------------------------------------------------------------*/
  /* clear state if go out from component   */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {


    const { use_ipr } = this.state;
    const { content } = this.props.languageReducer
     const {  ipr } = this.props.caseSubmission.advanced_modules;
    const { uid, patient_info } = this.props.caseReducer.case;
    const { currentUser } = this.props.userReducer;
    const {Case_IPR}= this.props.customeTeethReducer



    return (
      <div>
        <div className="form-group content form-block-holder grid_container mb-6 ">


          {/* <div class="D-total-cont"> */}
          <div className="customHeaderTS ">
            <div> <CustomeHeader title={content.ipr} className="cp3 pt-0 D-" optional={false} note={false} note_text={content.IPR_note} /></div>
            <div className="reative-close-ts">  <span className={` pull-right ts-close-button-new`} onClick={() => { this.props.toggleModalsClose(this.props.active_module) }}>&times;</span></div>
          </div>
          {/* </div> */}

          <div className="admin-Grid-Advance">
            <div></div>
            {/* part 1 */}
            <div className=" admin-Grid-Advance-inner D-head-ts">
              <div >

                {content.Use_IPR_for_this_case}{" "}

              </div>
              {/* part 2 */}
              <div className="D-total-cont-toggle">
                <div className="pointer_disable">
                  <CustomToggle
                    id="use-ipr"
                    //  disabled={this.props.summary}

                    checked={use_ipr}
                    onChange={() => {
                      this.setState({
                        use_ipr: !use_ipr,
                        ...use_ipr && {
                          iprTiming: ""
                        }

                      });
                      if (use_ipr) {
                        this.props.Clear_Teeth_Data("Case_IPR")
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div></div>
          </div>


          <div className="control-label title_active-gco D-head-ts text-center mb-6 ">
            {content.placement}
          </div>





          <div className="grid_container">
            {" "}
            <div className="">

              <div>
                {/* <CustomIPRTeeth /> */}
                {/* <CustomIPRTeeth action_key="Case_IPR" disabled={!use_ipr || use_ipr === "eonToDecide" || this.props.summary}
                  summary={this.props.summary}
                /> */}


                <CustomIPRTeeth
                  teeth={this.props.teethIPROptions.teeth_ipr_data}
                  saved_teeth_data={this.props.teethIPROptions.saved_data}
                  changeToothData={this.changeToothData}
                  changeIPRData={this.changeIPRData}
                  disableChanges={true}
                  // current_ipr_tooth={this.current_ipr_tooth}
                  advanced_modulesTs={true}
                  hideSide={false}
                  restrectIPRTooth={this.restrectIPRTooth}
                />
              </div>
            </div>
          </div>
        </div>
        <PDFDownloadLink
          document={
            <MyDocument
              content={content}
              data={Case_IPR}
              doctor={currentUser}
              patient={patient_info}
              case_data={{ uid: uid }}
              notes={ipr?.iprNotes || ""}
            />
          }
          fileName={`treatment form_${uid}.pdf`}
        >
          {({ blob, url, loading, error }) => {
            return (
              <Button
                className="btn green-green-btn button-bold-text"
                size="sm"
                disabled={loading}
              >
                {content.ipr_file}
              </Button>
            )
          }}
        </PDFDownloadLink>

        {!this.props.summary && <Button
          className={`btn mt-6 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => {
            this.UpdateAdvancedModules();
          }}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IPR);
