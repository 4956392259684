import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ClinicalFindings from "./Treatmentplan/ClinicalFindings";
import TreatmentSummary from "./Treatmentplan/TreatmentSummary";
import TreatmentGoals from "./Treatmentplan/TreatmentGoals";
import {
  updateClinicalFindings,
  validateClinicalFinding,
} from "../../../services/clinicalFindings";
import {
  updateTreatmentGoals,
  validateTreatmentGoals,
} from "../../../services/treatmentGoals";
import { updateTreatmentSummary } from "../../../services/treatmentSummary";
import ExpressTreatmentPlan from "./Treatmentplan/ExpressTreatmentPlan";
import { expressStep } from "../../../services/expressStep";
export default class TreatmentPlan extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      activeTab: 1,
      tab1: this.props.data.newCase.treatmentPlan.tab1,
      tab2: this.props.data.newCase.treatmentPlan.tab2,
      tab3: this.props.data.newCase.treatmentPlan.tab3,
      validateTab1: false,
      validateTab2: false,
      validateTab3: false,
      currentStep: props.fix ? props.fix : props.currentStep,
    };

    this.clinical_findings_store = this.props.data.newCase.treatmentPlan.clinical_findings;
    this.treatment_goals_store = this.props.data.newCase.treatmentPlan.treatment_goals;
    this.summary_and_special_instructions_store = this.props.data.newCase.treatmentPlan.summary_and_special_instructions;
    this.Express = this.props.data.newCase.treatmentPlan.express;

    this.caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    this.fix = this.props.fix;
    this.rejectable_id = this.props.rejectable_id;
  }
  /* --------------------------------------------------------------------------------*/
  /* scroll to top after render , set active tab */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      activeTab: this.renderSwitch(this.state.currentStep),
    });
  }
  /* --------------------------------------------------------------------------------*/
  /* render active tab  */
  /* --------------------------------------------------------------------------------*/
  renderSwitch = (step) => {
    switch (step) {
      case "clinical_findings":
        return 1;
      case "treatment_goals":
        return 2;
      case "summary_and_special_instructions":
        return 3;
      default:
        return 1;
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*  validate Tabs                                                                 */
  /*--------------------------------------------------------------------------------*/
  isValidated = () => {
    let isDataValid = false;

    // if full validation passes then save to store and pass as valid
    if (!this.state.tab3 && this.validateTab3) {
      isDataValid = true;
    } else {
      // if anything fails then update the UI validation state but NOT the UI Data State
      if (this.state.activeTab === 1) {
        if (this.validationCheckTab1()) {
          this.setState({
            activeTab: 2,
            tab2: false,
          });

          window.scrollTo(0, 0);
        } else {
          this.setState({
            validateTab1: true,
          });
        }
      }
      if (this.state.activeTab === 2) {
        if (this.validationCheckTab2()) {
          this.setState({
            activeTab: 3,
            tab3: false,
          });
          window.scrollTo(0, 0);
        } else {
          this.setState({
            validateTab2: true,
          });
        }
      }
      if (this.state.activeTab === 3) {
        this.validationCheckTab3()
          ? (isDataValid = true)
          : (isDataValid = false);
      }
      if (this.props.data.newCase.userInfo.express_api_v2_case) {
        this.validationCheckTab4()
          ? (isDataValid = true)
          : (isDataValid = false);
      }
    }

    return isDataValid;
  };
  /*--------------------------------------------------------------------------------*/
  /*  validate Clinical Findings tab                                                */
  /*--------------------------------------------------------------------------------*/
  validationCheckTab1 = () => {
    if (validateClinicalFinding(this.clinical_findings_store)) {
      this.setState({
        validateTab1: true,
      });
      return false;
    } else {
      this.updateClinicalFindingsStor({
        Tooth_Movement_Restrictions: this.props.data.newCase.treatmentPlan
          .clinical_findings.Tooth_Movement_Restrictions,
        Do_not_place_attachments: this.props.data.newCase.treatmentPlan
          .clinical_findings.Do_not_place_attachments,
      });
      this.props.sendingData();

      return updateClinicalFindings(
        this.props.data.userReducer.currentUser.id,
        this.clinical_findings_store,
        this.caseId,
        this.rejectable_id
      )
        .then((res) => {
          if (res === "ok") {
            this.props.sendingData();
            this.props.data.setTreatmentPlanInfo({
              ...this.props.data.newCase.treatmentPlan,
              tab2: false,
              clinical_findings: this.clinical_findings_store,
            });

            return !this.treatment_goals_store.tab2 ? true : false;
          }
        })
        .catch((error) => {
          this.props.sendingData();
          return false;
        });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* validate Treatment Goals Tab                                                   */
  /*--------------------------------------------------------------------------------*/
  validationCheckTab2 = () => {
    if (validateTreatmentGoals(this.treatment_goals_store)) {
      this.setState({
        validateTab2: true,
      });
      return false;
    } else {
      this.updateTreatmentGoalsStor({
        Extraction: this.props.data.newCase.treatmentPlan.treatment_goals
          .Extraction,
        Crossbites: this.props.data.newCase.treatmentPlan.treatment_goals
          .Crossbites,
        LeaveSpace: this.props.data.newCase.treatmentPlan.treatment_goals
          .LeaveSpace,
      });
      this.props.sendingData();

      return updateTreatmentGoals(
        this.props.data.userReducer.currentUser.id,
        this.treatment_goals_store,
        this.caseId
      )
        .then((res) => {
          if (res === "ok") {
            this.props.sendingData();
            this.props.data.setTreatmentPlanInfo({
              ...this.props.data.newCase.treatmentPlan,
              tab3: false,
              treatment_goals: this.treatment_goals_store,
            });

            return !this.treatment_goals_store.tab3 ? true : false;
          }
        })
        .catch((error) => {
          this.props.sendingData();
          return false;
        });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* validate Treatment Summary Tab                                                 */
  /*--------------------------------------------------------------------------------*/
  validationCheckTab3 = () => {
    this.props.sendingData();

    return updateTreatmentSummary(
      this.props.data.userReducer.currentUser.id,
      this.summary_and_special_instructions_store,
      this.caseId
    )
      .then((res) => {
        this.props.sendingData();
        this.props.data.setTreatmentPlanInfo({
          ...this.props.data.newCase.treatmentPlan,
          summary_and_special_instructions: this
            .summary_and_special_instructions_store,
        });
        if (this.fix) {
          this.props.data.FixAlignerCase();
        }
      })
      .catch((error) => {
        this.props.sendingData();
        return false;
      });
  };

  /*--------------------------------------------------------------------------------*/
  /* validate express Tab                                                 */
  /*--------------------------------------------------------------------------------*/
  validationCheckTab4 = () => {
    this.props.sendingData2();
    return expressStep(
      this.props.data.userReducer.currentUser.id,
      this.clinical_findings_store,
      this.summary_and_special_instructions_store,
      this.caseId
    )
      .then((res) => {
        this.props.sendingData2();
        this.props.data.setTreatmentPlanInfo({
          ...this.props.data.newCase.treatmentPlan,
          express: this.Express,
        });
        if (this.fix) {
          this.props.data.FixAlignerCase();
        }
      })
      .catch((error) => {
        this.props.sendingData();
        return false;
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* Clinical Findings                                                              */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getClinicalFindingsStore = () => {
    return (this.clinical_findings_store = {
      ...this.clinical_findings_store,
      validateTab1: this.state.validateTab1,
    });
  };
  // Update Store
  updateClinicalFindingsStor = (update) => {
    this.clinical_findings_store = {
      ...this.clinical_findings_store,
      ...update,
    };
  };
  /*--------------------------------------------------------------------------------*/
  /* Treatment Goals                                                                */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getTreatmentGoalsStore = () => {
    return (this.treatment_goals_store = {
      ...this.treatment_goals_store,
      validateTab2: this.state.validateTab2,
    });
  };
  // Update Store
  updateTreatmentGoalsStor = (update) => {
    this.treatment_goals_store = {
      ...this.treatment_goals_store,
      ...update,
    };
  };
  /*--------------------------------------------------------------------------------*/
  /* Treatment Summary & Special Instruction                                        */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getSummaryAndSpecialInstructionsStore = () => {
    return this.summary_and_special_instructions_store;
  };
  // Update Store
  updateSummaryAndSpecialInstructionsStor = (update) => {
    this.summary_and_special_instructions_store = {
      ...this.summary_and_special_instructions_store,
      ...update,
    };
  };

  /*--------------------------------------------------------------------------------*/
  /* Express                                       */
  /*--------------------------------------------------------------------------------*/
  // Get Store
  getExpressStore = () => {
    return this.Express;
  };
  // Update Store
  updateExpressStor = (update) => {
    this.Express = {
      ...this.Express,
      ...update,
    };
  };
  /*--------------------------------------------------------------------------------*/
  /* Toggle Active tabs                                                             */
  /*--------------------------------------------------------------------------------*/
  toggleActiveTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  render() {
    return (
      <div className="step step2 mt-5 mb-2">
        {/* <div className="main-tree ">
          <div className="tree-content">
            {" "}
            <div className="tree2">
              <div className="tree2-link"></div>
              <div className="tree2-link2"></div>
              <div className="tree2-link3"></div>
              <div className="tree2-link4"></div>
              <div className="tree2-link5"></div>
              <div
                className={`${
                  this.state.activeTab === 1
                    ? "tree2-link6-doing"
                    : "tree2-link6"
                }`}
              ></div>
              <div
                className={`${
                  this.state.activeTab === 1
                    ? "tree2-link7-todo"
                    : this.state.activeTab === 2
                    ? "tree2-link7-doing"
                    : "tree2-link7"
                }`}
              ></div>
              <div
                className={`${
                  this.state.activeTab === 3
                    ? "tree2-link8-doing"
                    : "tree2-link8"
                }`}
              ></div>
              <div
                className={` tree2-link9 ${classnames({
                  active: this.state.activeTab === 1,
                })}`}
                onClick={() => {
                  this.toggle(1);
                }}
              >
                1.Clinical Findings
              </div>
              <div
                className={`tree2-link10 ${classnames({
                  active: this.state.activeTab === 2,
                })} ${this.state.tab2 ? "tree2-link10-disabled" : ""}`}
                onClick={() => {
                  this.toggle(2);
                }}
              >
                2. Treatment Goals
              </div>
              <div
                className={`tree2-link11 ${classnames({
                  active: this.state.activeTab === 3,
                })} ${this.state.tab3 ? "tree2-link11-disabled" : ""}`}
                onClick={() => {
                  this.toggle(3);
                }}
              >
                3. Treatment Summary & Special Instruction
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br> */}
        {/*--------------------------------------------------------------------------------*/}
        {/* Treatment plan for express case  */}
        {/*--------------------------------------------------------------------------------*/}
        {this.props.data.newCase.userInfo.express_api_v2_case ? (
          <ExpressTreatmentPlan
            getSummeryStore={() => this.getSummaryAndSpecialInstructionsStore()}
            updateSummeryStore={(u) => {
              this.updateSummaryAndSpecialInstructionsStor(u);
            }}
            getClinicalFindingsStore={() => this.getClinicalFindingsStore()}
            updateClinicalFindingsStore={(u) => {
              this.updateClinicalFindingsStor(u);
            }}
            data={this.props}
            fix={this.fix}
          />
        ) : (
          <div className="row justify-content-md-center mt-3">
            <div className="col">
              <div className="">
                <Nav tabs className="justify-content-center">
                  {/* Clinical Findings Tab */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 1,
                      })}
                      onClick={() => {
                        this.toggleActiveTab(1);
                      }}
                    >
                      {`1. Clinical Findings`}
                    </NavLink>
                  </NavItem>
                  {/* Treatment Goals Tab */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 2,
                      })}
                      onClick={() => {
                        this.toggleActiveTab(2);
                      }}
                      disabled={this.state.tab2}
                    >
                      {`2. Treatment Goals`}
                    </NavLink>
                  </NavItem>
                  {/* Treatment Summary & Special Instruction Tab */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 3,
                      })}
                      onClick={() => {
                        this.toggleActiveTab(3);
                      }}
                      disabled={this.state.tab3}
                    >
                      {`3. Treatment Summary & Special Instruction`}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  {/* Clinical Findings Tab */}
                  <TabPane tabId={1}>
                    <ClinicalFindings
                      getStore={() => this.getClinicalFindingsStore()}
                      updateStore={(u) => {
                        this.updateClinicalFindingsStor(u);
                      }}
                      fix={this.fix}
                    />
                  </TabPane>
                  {/* Treatment Goals Tab */}
                  <TabPane tabId={2}>
                    <TreatmentGoals
                      getStore={() => this.getTreatmentGoalsStore()}
                      updateStore={(u) => {
                        this.updateTreatmentGoalsStor(u);
                      }}
                      fix={this.fix}
                      numbering={this.props.data.newCase.numbering}
                    />
                  </TabPane>
                  {/* Treatment Summary & Special Instruction Tab */}
                  <TabPane tabId={3}>
                    <TreatmentSummary
                      getStore={() =>
                        this.getSummaryAndSpecialInstructionsStore()
                      }
                      updateStore={(u) => {
                        this.updateSummaryAndSpecialInstructionsStor(u);
                      }}
                      fix={this.fix}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        )}
        {/*--------------------------------------------------------------------------------*/}
        {/* Fix Treatment plan step  */}
        {/*--------------------------------------------------------------------------------*/}
        {this.fix && (
          <button
            type="button"
            id="finish-button"
            className="btn btn-prev btn-primary btn-lg pull-right"
            onClick={() => {
              this.isValidated();
            }}
          >
            Finish
          </button>
        )}
      </div>
    );
  }
}
