import React, { Component } from "react";
import { connect } from "react-redux";
import Back from "../../components/back/Back";
import CustomeHeader from "../../components/header/CustomeHeader";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import DoctorSettings from "./DoctorSettings";
import Address from "./Address";
import { DoctorPreferences } from "./DoctorPreferences";
import Resources from "./Resources";

const mapStateToProps = (state) => ({
  ...state,
});
class Settings extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      activeTab: 1,
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* toggle tabs */
  /* --------------------------------------------------------------------------------*/
  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    const { activeTab } = this.state;
    const { content } = this.props.languageReducer;

    return (
      <div className="cpx5">
        {/* Custom back component */}
        <Back />
        {/* end Custom back component */}

        {/* Custom Header */}
        <CustomeHeader title="Settings" />
        {/* end Custom Header */}

        <div className="c-tabs">
          {/* Tabs */}
          <Nav tabs className="new-nav">
            {/* Doctor Settings tab */}
            <NavItem>
              <NavLink
                className={`${classnames({
                  active: activeTab === 1,
                })} button-text`}
                onClick={() => {
                  this.toggle(1);
                }}
              >
                {content.general}
              </NavLink>
            </NavItem>
            {/* end Doctor Settings tab */}

            {/* Address tab */}
            <NavItem>
              <NavLink
                className={`${classnames({
                  active: activeTab === 2,
                })} button-text`}
                onClick={() => {
                  this.toggle(2);
                }}
              >
                {content.address}
              </NavLink>
            </NavItem>
            {/*end Address tab */}

            {/* Preferences tab */}
            <NavItem>
              <NavLink
                className={`${classnames({
                  active: activeTab === 3,
                })} button-text`}
                onClick={() => {
                  this.toggle(3);
                }}
              >
                {content.preferences}
              </NavLink>
            </NavItem>
            {/* end Preferences tab */}

            {/* Resources tab */}
            <NavItem>
              <NavLink
                className={`${classnames({
                  active: activeTab === 4,
                })} button-text`}
                onClick={() => {
                  this.toggle(4);
                }}
              >
                {content.resources}
              </NavLink>
            </NavItem>
            {/* end Resources tab */}
          </Nav>
          {/* end Tabs */}

          {/* Tabs Content */}
          <TabContent activeTab={activeTab}>
            {/* Doctor Settings tab content*/}
            <TabPane tabId={1}>
              <DoctorSettings />
            </TabPane>
            {/* end Doctor Settings tab content*/}

            {/* Address tab content*/}
            <TabPane tabId={2}>
              <Address />
            </TabPane>
            {/* end Address tab content*/}

            {/* Preferences tab */}
            <TabPane tabId={3}>
              <DoctorPreferences />
            </TabPane>
            {/* end Preferences tab */}

            {/* Resources tab */}
            <TabPane tabId={4}>
              <Resources />
            </TabPane>
            {/* end Resources tab */}
          </TabContent>
          {/* end Tabs Content */}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(Settings);
