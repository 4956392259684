export const universal = {
    // upper right side 
    1: {
        palmer: "UR8",
        fdi: 18,
        universal: 1
    },

    2: {
        palmer: "UR7",
        fdi: 17,
        universal: 2
    },

    3: {
        palmer: "UR6",
        fdi: 16,
        universal: 3
    },

    4: {
        palmer: "UR5",
        fdi: 15,
        universal: 4
    },

    5: {
        palmer: "UR4",
        fdi: 14,
        universal: 5
    },

    6: {
        palmer: "UR3",
        fdi: 13,
        universal: 6
    },

    7: {
        palmer: "UR2",
        fdi: 12,
        universal: 7
    },

    8: {
        palmer: "UR1",
        fdi: 11,
        universal: 8
    },
    0: {
        palmer: "UL1",
        fdi: 21,
        universal: 9
    },

    // upper left side 

    9: {
        palmer: "UL1",
        fdi: 21,
        universal: 9
    },

    10: {
        palmer: "UL2",
        fdi: 22,
        universal: 10
    },

    11: {
        palmer: "UL3",
        fdi: 23,
        universal: 11
    },

    12: {
        palmer: "UL4",
        fdi: 24,
        universal: 12
    },

    13: {
        palmer: "UL5",
        fdi: 25,
        universal: 13
    },

    14: {
        palmer: "UL6",
        fdi: 26,
        universal: 14
    },

    15: {
        palmer: "UL7",
        fdi: 27,
        universal: 15
    },

    16: {
        palmer: "UL8",
        fdi: 28,
        universal: 16
    },


    // lower left side 

    17: {
        palmer: "LL8",
        fdi: 38,
        universal: 17
    },

    18: {
        palmer: "LL7",
        fdi: 37,
        universal: 18
    },

    19: {
        palmer: "LL6",
        fdi: 36,
        universal: 19
    },

    20: {
        palmer: "LL5",
        fdi: 35,
        universal: 20
    },

    21: {
        palmer: "LL4",
        fdi: 34,
        universal: 21
    },

    22: {
        palmer: "LL3",
        fdi: 33,
        universal: 22
    },

    23: {
        palmer: "LL2",
        fdi: 32,
        universal: 23
    },

    24: {
        palmer: "LL1",
        fdi: 31,
        universal: 24
    },



    // lower right side 

    25: {
        palmer: "LR1",
        fdi: 41,
        universal: 25
    },

    26: {
        palmer: "LR2",
        fdi: 42,
        universal: 26
    },

    27: {
        palmer: "LR3",
        fdi: 43,
        universal: 27
    },

    28: {
        palmer: "LR4",
        fdi: 44,
        universal: 28
    },

    29: {
        palmer: "LR5",
        fdi: 45,
        universal: 29
    },

    30: {
        palmer: "LR6",
        fdi: 46,
        universal: 30
    },

    31: {
        palmer: "LR7",
        fdi: 47,
        universal: 31
    },

    32: {
        palmer: "LR8",
        fdi: 48,
        universal: 32
    },


}