import { teeth } from "./teeth_v4";
import { teeth_pdf } from "./teeth";

export const upper_teeth = {
  ...initRightTeeth("upper_r_"),
  ...initLeftTeeth("upper_l_"),
};

export const lower_teeth = {
  ...initRightTeeth("lower_r_"),
  ...initLeftTeeth("lower_l_"),
};

function initRightTeeth(key) {
  
  let num = 8;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        image_pdf: teeth_pdf[`${key}${num}`],
        active_pdf: teeth_pdf[`${key}${num}_active`],
        not_active_pdf: teeth_pdf[`${key}${num}`],
        update: teeth[`${key}${num}_updated`],
        restrect: teeth[`${key}${num}_restrect`],
        restrect_pdf: teeth_pdf[`${key}${num}_restrect`],
        missing_pdf: teeth_pdf[`${key}${num}_missing`],
        active_movement: teeth[`${key}${num}_active_movement`],
        missing: teeth[`${key}${num}_missing`],



        value: false
      },
    };
    num--;
  }
  return rightTeeth;
}

function initLeftTeeth(key) {
  let num = 1;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        image_pdf: teeth_pdf[`${key}${num}`],
        active_pdf: teeth_pdf[`${key}${num}_active`],
        not_active_pdf: teeth_pdf[`${key}${num}`],
        update: teeth[`${key}${num}_updated`],
        restrect: teeth[`${key}${num}_restrect`],
        restrect_pdf: teeth_pdf[`${key}${num}_restrect`],
        missing_pdf: teeth_pdf[`${key}${num}_missing`],
        active_movement: teeth[`${key}${num}_active_movement`],
        missing: teeth[`${key}${num}_missing`],
       
        value: false
      },
    };
    num++;
  }
  return rightTeeth;
}
