import clsx from "clsx";
import React from "react";

export const PopupContainer = ({
  className,
  children,
  arch,

  ...props
}) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  if (!children) return <></>;

  return (
    <>
      <div
        className={ clsx("case_type_popup", arch?"popup-arch " :"caseType_and-arch-popup" , "left")}
        {...props}
        onClick={stopPropagation}
      >
        {children}
      </div>
    </>
  );
};
