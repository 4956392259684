import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { localeData } from "moment";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});

class Header extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
   const {loyalty} =  this.props.userReducer.currentUser

    const { content } = this.props.languageReducer;

    return (
      <div className="loyalty-program-container">
        <div className="loyalty-program-block">
          <div className="loyalty-program-block-title subhead-text  centerHorizontal">
            {content.your_tier}
          </div>
          <div className="loyalty-program-block-data subhead-text  centerHorizontal">
            {loyalty?.active_tier.name}
          </div>
        </div>
        <div className="loyalty-program-block">
          <div className="loyalty-program-block-title subhead-text centerHorizontal">
            {content.tier_period}
          </div>
          <div className="loyalty-program-block-data subhead-text centerHorizontal">
            {new Date(loyalty?.loyalty_period_start_date).toDateString().slice(4)} - {" "}
            {new Date(loyalty?.loyalty_period_end_date).toDateString().slice(4)}
          </div>
        </div>
        <div className="loyalty-program-block">
          <div className="loyalty-program-block-title subhead-text centerHorizontal">
            {content.cases_required_to_upgrade}
          </div>
          <div className="loyalty-program-block-data subhead-text centerHorizontal">
            {loyalty?.remaining_cases_for_next_tier}
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
