import React, { Component } from "react";
import { connect } from "react-redux";

import {
  CardBody,
  CardTitle,
  Card,
  CardFooter,
  CardImg,
  Button,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { PhotosSchema } from "../../../helpers/photosXraysValidations";
import { PhotosDataMobileDesktp } from "../../../helpers/photos-xrays";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { uploadFile, keyToUrl } from "../../../helpers/s3";
import Resizer from "react-image-file-resizer";
import { updateAlignerCase } from "../../../api/api";
import { infoToaster, errorToaster } from "../../../services/toast";
import upload_not_active from "../../../assets/images/icon/upload_not_active.svg";
import upload_active from "../../../assets/images/icon/upload_active.svg";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
class FixPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorId: props.userReducer.currentUser.id,

      loading_percentage: 0,
      intervalID: 0,
      showProgress: false,
      loading_percentage_front_smiling: 0,
      loading_percentage_front_pose: 0,
      loading_percentage_profile: 0,
      loading_percentage_panoramic_xray: 0,
      loading_percentage_upper_occlusal: 0,
      loading_percentage_frontal: 0,
      loading_percentage_lower_occlusal: 0,
      loading_percentage_cephalometric_xray: 0,
      loading_percentage_right_buccal: 0,
      loading_percentage_left_buccal: 0,
      hover_front_smiling: false,
      hover_front_pose: false,
      hover_profile: false,
      hover_upper_occlusal: false,
      hover_frontal: false,
      hover_lower_occlusal: false,
      hover_right_buccal: false,
      hover_left_buccal: false,
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };

    this.front_smiling_ref = React.createRef();
    this.front_pose_ref = React.createRef();
    this.profile_ref = React.createRef();

    this.upper_occlusal_ref = React.createRef();
    this.frontal_ref = React.createRef();

    this.lower_occlusal_ref = React.createRef();

    this.right_buccal_ref = React.createRef();
    this.left_buccal_ref = React.createRef();
  }

  /*--------------------------------------------------------------------------------*/
  /* uploading file timer                                             */
  /*--------------------------------------------------------------------------------*/
  timer = (id) => {
    this.setState({
      [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
    });
  };

  checkIfChanged = (oldarray, newArray) => {
    for (var x = 0; x < oldarray.length; x++) {
      if (oldarray[x].url !== newArray[x].url) {
        return true;
      }
    }
  };


    /* --------------------------------------------------------------------------------*/
  /* grab Photo Input data on submit  */
  /* --------------------------------------------------------------------------------*/
  _grabPhotosInput = () => {
    return {
      front_smiling: this.state.front_smiling,
      front_smiling_image: this.state.front_smiling_image,
      front_smiling_photo: this.state.front_smiling_photo,

      front_pose: this.state.front_pose,
      front_pose_image: this.state.front_pose_image,
      front_pose_photo: this.state.front_pose_photo,

      profile: this.state.profile,
      profile_image: this.state.profile_image,
      profile_photo: this.state.profile_photo,

      upper_occlusal: this.state.upper_occlusal,
      upper_occlusal_image: this.state.upper_occlusal_image,
      upper_occlusal_photo: this.state.upper_occlusal_photo,

      lower_occlusal: this.state.lower_occlusal,
      lower_occlusal_image: this.state.lower_occlusal_image,
      lower_occlusal_photo: this.state.lower_occlusal_photo,

      frontal: this.state.frontal,
      frontal_image: this.state.frontal_image,
      frontal_photo: this.state.frontal_photo,

      right_buccal: this.state.right_buccal,
      right_buccal_image: this.state.right_buccal_image,
      right_buccal_photo: this.state.right_buccal_photo,

      left_buccal: this.state.left_buccal,
      left_buccal_image: this.state.left_buccal_image,
      left_buccal_photo: this.state.left_buccal_photo,
    };
  }; 
    /* --------------------------------------------------------------------------------*/
  /* update photo for adjustment */
  /* --------------------------------------------------------------------------------*/
  UpdatePhotosForAdjustment = () => {

 
    const PhotosInput = this._grabPhotosInput(); // grab user entered vals
  
    if (this.props.component === "adjustment") {
      // only update store of something changed
      this.props.updateStore({
        ...PhotosInput,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* Fix Photos step   */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.state;
    const { content } = this.props.languageReducer;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];

    let images = [
      { name: "front_smiling", url: values.front_smiling },
      { name: "front_pose", url: values.front_pose },
      { name: "profile", url: values.profile },
      { name: "upper_occlusal", url: values.upper_occlusal },
      { name: "lower_occlusal", url: values.lower_occlusal },
      { name: "left_buccal", url: values.left_buccal },
      { name: "frontal", url: values.frontal },
      { name: "right_buccal", url: values.right_buccal },
    ];
    let oldimages = [
      {
        name: "front_smiling",
        url: this.props.caseSubmission.photos_xrays.front_smiling,
      },
      {
        name: "front_pose",
        url: this.props.caseSubmission.photos_xrays.front_pose,
      },
      { name: "profile", url: this.props.caseSubmission.photos_xrays.profile },
      {
        name: "upper_occlusal",
        url: this.props.caseSubmission.photos_xrays.upper_occlusal,
      },
      {
        name: "lower_occlusal",
        url: this.props.caseSubmission.photos_xrays.lower_occlusal,
      },
      {
        name: "left_buccal",
        url: this.props.caseSubmission.photos_xrays.left_buccal,
      },
      { name: "frontal", url: this.props.caseSubmission.photos_xrays.frontal },
      {
        name: "right_buccal",
        url: this.props.caseSubmission.photos_xrays.right_buccal,
      },
    ];

    let checkimage = this.checkIfChanged(oldimages, images);
    if (checkimage) {
      setSubmitting(true);
      let filtered_image = images.filter((key, index) => {
        return key.url.match(/http([^&]+)/);
      });

      let photos_data = {
        aligner_case: {
          step: "photos",
          photos: filtered_image,
        },
        ...(this.props.rejectable_id && {
          resolve_rejection_id: this.props.rejectable_id,
        }),
      };


      updateAlignerCase(doctorId, caseId, JSON.stringify(photos_data))
        .then((res) => {
          setSubmitting(false);
          this.props.FixAlignerCase();
        })
        .catch((error) => {
          setSubmitting(false);
        });
    } else {
      infoToaster(content.nothing_is_changed, content.Attention_needed);
      this.props.FixAlignerCase();
    }
    // updateAlignerCase(doctorId, caseId, JSON.stringify(photos_data))
        //   .then((res) => { })
        //   .then(() => {
        //     updateAlignerCase(doctorId, caseId, JSON.stringify(xrays_data)).then(
        //       (res) => {
        //         setSubmitting(false);
        //         this.props.NextStep();
        //       }
        //     );
        //   })
        //   .catch((error) => {
        //     setSubmitting(false);
        //   });
  };

  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id, file) => {
    return uploadFile({
      name: `case-${
        this.state.caseId
      }/patient-photos/${id}/${new Date().valueOf()}.JPEG`,
      contentType: image.type,
      file: image,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* upload photos */
  /* --------------------------------------------------------------------------------*/

  UploadPhoto = async (e, id, setFieldValue) => {

  
    e.persist();
    if (e.target.files[0]) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) => {
        this.setState({
          id: evt.currentTarget.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          clearInterval(this.state.intervalID);
          this.setState({
            [id]: keyToUrl(key),
            [`loading_percentage_${id}`]: 100,
          });
          setFieldValue(id, keyToUrl(key));
          setTimeout(() => {
            this.setState({
              [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
              [`loading_percentage_${id}`]: 0,
              [`hover_${id}`]: false,
            });
          }, 500);
          if( this.props.component==="adjustment"){
            this.UpdatePhotosForAdjustment()
        }
        },
        "blob"
      );
    }
   
       
   


    
  };

  /* --------------------------------------------------------------------------------*/
  /* upload photos if from drop zone */
  /* --------------------------------------------------------------------------------*/
  UploadPhotoDropZone = async (e, id, setFieldValue) => {
    if (e) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) => {
        this.setState({
          id: evt.currentTarget.result,
        });
      });
      reader.readAsDataURL(e);
      Resizer.imageFileResizer(
        e,
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e);
          clearInterval(this.state.intervalID);
          this.setState({
            [id]: keyToUrl(key),
            [`loading_percentage_${id}`]: 100,
          });
          setFieldValue(id, keyToUrl(key));
          setTimeout(() => {
            this.setState({
              [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
              [`loading_percentage_${id}`]: 0,
              [`hover_${id}`]: false,
            });
          }, 500);

          if( this.props.component==="adjustment"){
            this.UpdatePhotosForAdjustment()
        }
        },
        "blob"
      );
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* preventDefault */
  /* --------------------------------------------------------------------------------*/
  preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Over */
  /* --------------------------------------------------------------------------------*/
  dragOver = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: true });
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Enter */
  /* --------------------------------------------------------------------------------*/
  dragEnter = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: true });
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Leave */
  /* --------------------------------------------------------------------------------*/
  dragLeave = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: false });
  };
  /* --------------------------------------------------------------------------------*/
  /* file Drop */
  /* --------------------------------------------------------------------------------*/
  fileDrop = (e, key, setFieldValue) => {
    this.preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length === 1) {
      this.handleFiles(files, key, setFieldValue);
    } else {
      this.setState({ [`hover_${key}`]: false });
      // infoToaster("please add one file ");
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* handle Files */
  /* --------------------------------------------------------------------------------*/
  handleFiles = (files, key, setFieldValue) => {
    const { content } = this.props.languageReducer;

    for (let i = 0; i < files.length; i++) {
      if (this.validateFile(files[i])) {
        this.UploadPhotoDropZone(files[i], key, setFieldValue);
      } else {
        errorToaster(content.file_not_supported, content.Warning);
        this.setState({ [`hover_${key}`]: false });
      }
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* validate File */
  /* --------------------------------------------------------------------------------*/
  validateFile = (file) => {
    let extention = file.name.split(".");
    const validTypes = ["png", "gif", "jpeg", "jpg"];
    if (validTypes.indexOf(file.name.split(".")[extention.length - 1]) === -1) {
      return false;
    }

    return true;
  };
  /* --------------------------------------------------------------------------------*/
  /* file Input Clicked */
  /* --------------------------------------------------------------------------------*/
  fileInputClicked = (input) => {
    // this[`${input}_ref`].current.click();
  };
  render() {
    const { content } = this.props.languageReducer;



    let photos_xrays = {};
    if (this.props.adjustmentComponent) {
      photos_xrays = this.props.adjustment.photos;
    } else {
      photos_xrays = this.props.caseSubmission.photos_xrays;
    }

    return (
      <div>
        {/* <CardTitle className="border-bottom pb-3 title_active-gco case-title">
                    <div className="step-header-title">
                        <span>{`Photos & Xrays`}</span>
                        <span className="patient_info">
                            <div> {this.props.patient.full_name}
                            </div>
                            <div> Case {this.state.caseId}
                            </div>
                        </span>
                    </div>
                </CardTitle> */}

        <Formik
          enableReinitialize
          initialValues={{
            front_smiling: photos_xrays.front_smiling,
            front_pose: photos_xrays.front_pose,
            profile: photos_xrays.profile,
            upper_occlusal: photos_xrays.upper_occlusal,
            lower_occlusal: photos_xrays.lower_occlusal,
            left_buccal: photos_xrays.left_buccal,
            frontal: photos_xrays.frontal,
            right_buccal: photos_xrays.right_buccal,
          }}
          validationSchema={PhotosSchema}
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form className="form-material" onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
         <div className={`${(this.props.adjustmentComponent)?"fix-photo-bite-record-container":""}`}>
              <CardBody id="case-submission" className="fix-photo-bite-record">
                <div className="main-fix-photos-grid ">
                  {PhotosDataMobileDesktp.map((value, index) => (
                    <div key={index}>
                      {value.key && (
                        <>
                          <Card
                            onDragOver={(e) => this.dragOver(e, value.key)}
                            onDragEnter={(e) => this.dragEnter(e, value.key)}
                            onDragLeave={(e) => this.dragLeave(e, value.key)}
                            onDrop={(e) =>
                              this.fileDrop(e, value.key, setFieldValue)
                            }
                            onClick={() => this.fileInputClicked(value.key)}
                            className={`cp2  ${
                              this.state[`hover_${value.key}`]
                                ? "c-dragover"
                                : ""
                            } ${
                              values[value.key]
                                ? "c-card-with-img-active"
                                : "c-card-with-img"
                            }`}
                          >
                            <CardTitle className="cmb2">
                              <div className="card-title-grid">
                                <div>
                                  {values[value.key] ? (
                                    value.title
                                  ) : this.state[
                                      `showProgress_${value.key}`
                                    ] ? (
                                    `Uploading ${value.title}`
                                  ) : (
                                    <span className="not-active-img">
                                      {value.title}{" "}
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <img
                                    src={
                                      values[value.key]
                                        ? upload_active
                                        : upload_not_active
                                    }
                                    alt={value.key}
                                  />
                                </div>
                              </div>
                            </CardTitle>
                            <div className="image-overlay-container">
                              <label
                                name={value.key}
                                htmlFor={value.key}
                                className=" photos-xrays-img"
                              >
                                <CardImg
                                  src={
                                    values[value.key]
                                      ? values[value.key]
                                      : value.img
                                  }
                                  className={`c-card-img cursor-pointer`}
                                />
                              </label>
                              <input
                                ref={this[`${value.key}_ref`]}
                                type="file"
                                id={value.key}
                                onChange={(e) => {
                                  this.UploadPhoto(e, value.key, setFieldValue);
                            
                                }}
                                multiple={false}
                                className="visually-hidden"
                                accept="image/x-png,image/gif,image/jpeg"
                              />
                              {this.state[`showProgress_${value.key}`] && (
                                <div className="overlay-loader">
                                  <CircularProgressbar
                                    className="circule-progress-bar"
                                    value={
                                      this.state[
                                        `loading_percentage_${value.key}`
                                      ]
                                    }
                                    text={`${
                                      this.state[
                                        `loading_percentage_${value.key}`
                                      ]
                                    }%`}
                                  />
                                </div>
                              )}
                            </div>
                          </Card>
                          <ErrorMessage
                            component="div"
                            name={value.key}
                            className="custom-invalid-feedback m-1"
                          />
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {window.innerWidth < 840 && <div className={`${(this.props.adjustmentComponent)?"footerAdjusment":""} pt-4 pb-4`}>
              <CardFooter>
                {!this.props.adjustmentComponent && (
                  <Button
                    className={`btn light-green-btn float-right submission-btn confarim_preferences_btn_width`}
                    size="sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {content.fix}
                    {/* <i className="fas fa-angle-right arrows-icon pl-1"></i> */}
                  </Button>
                )}
              </CardFooter>{" "}
              </div>}
              </CardBody>
              </div>

              {window.innerWidth > 840 &&  <div className={`${(this.props.adjustmentComponent)?"footerAdjusment":""}`}>
              <CardFooter className="p-3">
                {!this.props.adjustmentComponent && (
                  <Button
                    className={`btn light-green-btn float-right  submission-btn `}
                    size="sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {content.fix}
                    {/* <i className="fas fa-angle-right arrows-icon pl-1"></i> */}
                  </Button>
                )}
              </CardFooter>{" "}
              </div>}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FixPhotos);