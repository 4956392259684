import React, { Component } from "react";
import { connect } from "react-redux";
import BitePhotosXrays from "./BitePhotosXrays"
import BiteImpressions from "./BiteImpressions";
import { resetBite } from "../../../redux/biteRecord/action"
import { FixBiteRecords } from "../../../api/api";
import { successToaster } from "../../../services/toast";

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({
    resetBite: (payload) => dispatch(resetBite(payload)),

});
class BiteRecord extends Component {
    /*--------------------------------------------------------------------------------*/
    /*constructor */
    /*--------------------------------------------------------------------------------*/
    constructor(props) {
        super(props);
        /*init state */
        this.state = {
            step: 1,
            isSubmitting: false,
            case_id: props.caseReducer.case.id,
            doctor_id: this.props.userReducer.currentUser.id
        };
    }
    NextStep = () => {
        this.setState({ step: this.state.step + 1 })
    }
    PrevStep = () => {
        this.setState({ step: this.state.step - 1 })
    }
    SubmitBite = () => {
        this.setState({ isSubmitting: true });
        const { doctor_id, case_id } = this.state;
        const { content } = this.props.languageReducer;

        // set and filter impressions data 
        let impressions = {
            upper_arch_url: this.props.biteReducer.impressions.upper_arch_url,
            lower_arch_url: this.props.biteReducer.impressions.lower_arch_url,
        };
        let filtered_impressions = {};
        for (var key1 in impressions) {
            if (impressions[key1].match(/http([^&]+)/)) {
                filtered_impressions[key1] = impressions[key1];
            }
        }

        // set and filter photos data 
        let images = [
            { name: "front_smiling", url: this.props.biteReducer.photos.front_smiling },
            { name: "front_pose", url: this.props.biteReducer.photos.front_pose },
            { name: "profile", url: this.props.biteReducer.photos.profile },
            { name: "upper_occlusal", url: this.props.biteReducer.photos.upper_occlusal },
            { name: "lower_occlusal", url: this.props.biteReducer.photos.lower_occlusal },
            { name: "left_buccal", url: this.props.biteReducer.photos.left_buccal },
            { name: "frontal", url: this.props.biteReducer.photos.frontal },
            { name: "right_buccal", url: this.props.biteReducer.photos.right_buccal },
        ];

        let filtered_image = images.filter((key, index) => {
            return key.url.match(/http([^&]+)/);
        });
        // set and filter xrays data 
        let xrays = {
            panoramic_url: this.props.biteReducer.xrays.panoramic_xray,
            cephalometric_url: this.props.biteReducer.xrays.cephalometric_xray,
        };

       // let filtered_xrays = {};

        // for (var key2 in xrays) {
        //     if (xrays[key2].match(/http([^&]+)/)) {
        //         filtered_xrays[key2] = xrays[key2];
        //     }
        // }
        let data = {
            aligner_case: {

                bite_impressions: {
                    impressions_method: this.props.biteReducer.impressions.impressions_method,
                    impressions_third_party_note: this.props.biteReducer.impressions.impressions_third_party_note,
                    ...(this.props.biteReducer.impressions.impressions_method === "upload" && {
                        impressions: filtered_impressions,
                    }),
                },
                bite_photos: filtered_image,
                //xrays: filtered_xrays,
            }

        };
        //call Fix Bite Records api
        FixBiteRecords(doctor_id, case_id, JSON.stringify(data))
            .then((res) => {
                this.setState({ isSubmitting: false })
                this.props.finishSubmitBiteRecord();
                successToaster(content.fix_bite_records_successfully, content.Success);
            }).catch((err) => {
                console.log(err, "err");
                this.setState({ isSubmitting: false })
            });

    }
    render() {
        const { content } = this.props.languageReducer;
        const { step, isSubmitting } = this.state;
        const Step = (props) =>

            <div
                style={{ pointerEvents: props.indicator === 2 && !this.props.biteReducer.update_photos_xrays ? "none" : "" }}
                className={`bite_stepper Stepper__step ${step > props.indicator ? "done-stepper" : ""} `} onClick={() => {
                    this.setState({ step: props.indicator })
                }}>
                <div className={`${step === props.indicator ? "current-step_stepper" : ""} ${step > props.indicator ? "done-step_stepper" : ""}`}>
                    <div className={
                        props.updated === true ? "Stepper__edit" : "Stepper__edit_empty"}>{props.updated ? "Edited" : "edited"} </div>
                    <div className="Stepper__indicator">
                        <span className="Stepper__info">

                            <div className="step_indicator"></div>
                        </span>
                    </div>
                    <div className="Stepper__label">{props.title}</div>
                    <div className="Stepper__panel">
                        {props.children}
                    </div></div>

            </div>
            ;

        // Overlay
        const Stepper = (props) =>
            <div className={`Stepper ${props.isVertical ? 'Stepper--vertical' : ''} ${props.isInline ? 'Stepper--inline' : ''}`}>
                {props.children}
            </div>;



        return (
            <div className="stepper-Bite-record-photos-and-xray">
  
                <Stepper>
                    <Step indicator={1} title={content.photos_x_rays} updated={false} onClick={() => { this.setState({ step: 1 }) }} >
                        <div className="Content">
                            <button>Prev</button>
                            <button>Next</button>
                        </div>
                    </Step>
                    <Step indicator={2} title={content.impressions} updated={false} onClick={() => { this.setState({ step: 2 }) }}>
                        <div className="Content">
                            <button>Prev</button>
                            <button>Next</button>
                        </div>
                    </Step>


                </Stepper>
                <div className="ts-steps">
                    {step === 1 && <div>
                        <BitePhotosXrays NextStep={this.NextStep} />
                    </div>
                    }
                    {step === 2 && <div>
                        <BiteImpressions PrevStep={this.PrevStep} SubmitBite={this.SubmitBite} isSubmitting={isSubmitting} />
                    </div>
                    }
                </div>

            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BiteRecord);
