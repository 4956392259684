
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class ToothRateMovement extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {

      use_teethMovement: this.props.caseSubmission.advanced_modules
        .teethMovement.use_teethMovement,
      Normal_Rate: this.props.caseSubmission.advanced_modules
        .teethMovement.Normal_Rate,
      Slow_Rate: this.props.caseSubmission.advanced_modules
        .teethMovement.Slow_Rate,



    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "teethMovement",
      data: {
        use_teethMovement: this.state.use_teethMovement,
        Normal_Rate: this.state.Normal_Rate,
        Slow_Rate: this.state.Slow_Rate,
      },
    });

    this.props.save();


  };
  clearState = () => {
    this.setState({

      Normal_Rate: false,
      Slow_Rate: false,
    })
  }

  render() {
    const { use_teethMovement } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>
        <div className="ToothRateMovement-container">
        <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
           {content.Set_tooth_rate_movement_for_this_case}{"? "}
          </div>
          <div className="ToothRateMovement-grid">
            <div className="radio-item" 
                            style={{ pointerEvents: this.props.summary ? "none" : "" }}>
              <input type="radio"
                id='Normal_Rate'
                name='ToothRateMovement'
                style={{ pointerEvents: this.props.summary ? "none" : "" }}
                disabled={ this.props.summary}
                checked={this.state.Normal_Rate}
                value={"Normal_Rate"}
                onChange={() => {
                  this.setState({
                    Normal_Rate: true,
                    Slow_Rate: false,
                  });
                }}
              />
              <label htmlFor='Normal_Rate' className="button-text">{content.Normal_Rate}</label>
            </div>


            <div className="radio-item"
                            style={{ pointerEvents: this.props.summary ? "none" : "" }} >
              <input type="radio"
                id='Slow_Rate'
                name='ToothRateMovement'
                checked={this.state.Slow_Rate}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}
                disabled={ this.props.summary}
                value={"Slow_Rate"}
                onChange={() => {
                  this.setState({
                    Normal_Rate: false,
                    Slow_Rate: true,
                  });
                }}
              />
              <label htmlFor='Slow_Rate' className="button-text">{content.Slow_Rate}</label>
            </div>
    
            {window.innerWidth < 839 &&
            <div id="content_container_3"
            className={`${use_teethMovement !== true ? "c-content-container7" : "c-content-container "} tooth-extraction  cpb2 `}>
              </div>
  }
          </div>

          {
            !this.props.summary && <div className="btn-adv-mobile"><Button
              className=" mt-2   mobile-but-adv light-green-btn float-right  button-seme-bold-text"
              size="lg"
              type="button"
              onClick={() => this.UpdateAdvancedModules()
                // ()=>this.clearState()
              }
            >
              {content.save}
            </Button></div>
          }
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps,
  mapDispatchToProps)(ToothRateMovement);