import React, { Component } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import "react-table/react-table.css";
import {
  archiveCase,
  getFilteredAlignerCases,
  unArchiveCase,
} from "../../api/api";
import { connect } from "react-redux";
import { caseType } from "../../helpers/caseType";
import { statusColors } from "../../helpers/caseStatus";
import { successToaster } from "../../services/toast";
import { clearSteps } from "../../redux/CaseSubmission/action";
import CustomeHeader from "../../components/header/CustomeHeader";
import Back from "../../components/back/Back";
import CustomRadioGroup from "../../components/radioGroup/CustomRadioGroup";
import restore from "../../assets/images/icon/restore.svg";
import archive from "../../assets/images/icon/archive.svg";
import moment from "moment";

import Pagination from "./Pagination";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearSteps: (payload) => dispatch(clearSteps(payload)),
});
class Cases extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*inint state*/
    this.state = {
      new_cases_Date: "2021-01-25",
      id: props.userReducer.currentUser.id,
      cases: [],
      modal: false,
      obj: {},
      data: [],
      filteredData: [],
      searchInput: this.props.location.state?.data
        ? this.props.location.state?.data.filters.uid_or_patient_full_name_cont
        : "",
      filter: this.props.location.state?.data
        ? this.props.location.state?.data.filter
        : "All",
      filteredData2: [],
      Loading: false,
      filters: this.props.location.state?.data
        ? this.props.location.state?.data.filters
            .aligner_case_transition_to_state_in
        : null,
      display_status_eq: this.props.location.state?.data
        ? this.props.location.state?.data.filters.display_status_eq
        : null,
      pageSize: this.props.location.state?.data
        ? this.props.location.state?.data.per_page
        : 20,
      page: this.props.location.state?.data
        ? this.props.location.state?.data.page
        : 1,
      pages: 1,
      sort: {
        id: "created_at",
        desc: true,
      },
      oldFilters: this.props.location.state?.data,
      loadingData: false,
      archiveModal: false,
      unArchiveModal: false,
      inputChanged: false,
      archived: false,
      display_status_eq: this.props.location.state?.data
        ? this.props.location.state?.data.filters.display_status_eq
        : null,
    };
    this.reactTable = React.createRef();
  }
  /*--------------------------------------------------------------------------------*/
  /*get aligner cases with init filters                                             */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    this.GetAllCases();
  }

  GetAllCases = () => {
    this.setState({ Loading: true });
    //const { filters } = this.props.changesReducer;
    let filters = JSON.parse(localStorage.getItem("data"));
    let data = {};
    if (filters?.back) {
      data = {
        filters: {
          uid_or_patient_full_name_cont:
            filters.state.data.filters.uid_or_patient_full_name_cont,
          aligner_case_transition_to_state_in:
            filters.state.data.filters.aligner_case_transition_to_state_in,
          display_status_eq: filters.state.data.filters.display_status_eq,
          s: filters.state.data.filters.s,
          archived_case_eq: filters.state.data.filters.archived_case_eq,
        },
        page: filters.state.data.page,
        per_page: filters.state.data.per_page,
      };

      let sort = filters.state.data.filters.s.split(" ");
      this.setState({
        page: filters.state.data.page,
        pageSize: filters.state.data.per_page,
        searchInput: filters.state.data.filters.uid_or_patient_full_name_cont,
        archived: filters.state.data.filters.archived_case_eq,
        filter: filters.state.data.filter,
        sort: {
          id: sort[0],
          desc: sort[1],
        },
        filters: filters.state.data.filters.aligner_case_transition_to_state_in,
        display_status_eq: filters.state.data.filters.display_status_eq,
      });
    } else {
      data = {
        ...(this.props.location.state?.data
          ? this.props.location.state?.data
          : {
              filters: {
                uid_or_patient_full_name_cont: this.state.searchInput,
                aligner_case_transition_to_state_in: this.state.filters,
                display_status_eq: this.state.filters
                  ? this.state.filters[0]
                  : "",
                s: "created_at desc",
                archived_case_eq: this.state.archived,
              },
              page: this.state.page,
              per_page: this.state.pageSize,
            }),
      };
    }

    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*get aligner cases                                                               */
  /*--------------------------------------------------------------------------------*/
  GetFilteredAlignerCase(data) {
    window.scrollTo(0, 0);
    this.setState({ loadingData: !this.setState.loadingData });
    getFilteredAlignerCases(this.state.id, JSON.stringify(data))
      .then((res) => {
        this.setState({
          cases: res.aligner_cases,
          Loading: false,
          pages: res.total_pages,
          loadingData: false,
          inputChanged: false,
        });
      })
      .catch((error) => {
        this.setState({ loadingData: !this.setState.loadingData });
      });
  }
  /*--------------------------------------------------------------------------------*/
  /*search                                                                          */
  /*--------------------------------------------------------------------------------*/
  handleChange = () => {
    let data = {
      filters: {
        uid_or_patient_full_name_cont: this.state.searchInput,
        aligner_case_transition_to_state_in: this.state.filters,
        display_status_eq: this.state.filters ? this.state.filters[0] : "",
        s: "created_at desc",
        archived_case_eq: this.state.archived,
      },
      page: 1,
      per_page: this.state.pageSize,
    };
    this.setState({
      page: 1,
      display_status_eq: this.state.filters ? this.state.filters[0] : "",
    });
    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*clear Data                                                                      */
  /*--------------------------------------------------------------------------------*/
  clearData = () => {
    this.setState({
      searchInput: "",
      display_status_eq: this.state.filters ? this.state.filters[0] : "",
    });

    let data = {
      filters: {
        uid_or_patient_full_name_cont: "",
        aligner_case_transition_to_state_in: this.state.filters,
        display_status_eq: this.state.filters ? this.state.filters[0] : "",
        s: "created_at desc",
        archived_case_eq: this.state.archived,
      },
      page: this.state.page,
      per_page: this.state.pageSize,
    };
    if (!this.state.inputChanged) {
      this.GetFilteredAlignerCase(data);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Filter                                                                          */
  /*--------------------------------------------------------------------------------*/
  Filter = (status, status2) => {
    // this.reactTable.current.state.page = 0;
    this.setState({
      filter: status,
      filters: status2,
      page: 1,
      searchInput: "",
      display_status_eq: "",
      archived: false,
    });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: "",
        aligner_case_transition_to_state_in: status2,
        display_status_eq: "",
        s: "created_at desc",
        archived_case_eq: false,
      },
      page: 1,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*Filter archived cases                                                                       */
  /*--------------------------------------------------------------------------------*/
  Archived = (status, status2) => {
    this.setState({
      filter: status,
      filters: [],
      page: 1,
      searchInput: "",
      display_status_eq: "",
    });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: "",
        aligner_case_transition_to_state_in: [],
        display_status_eq: "",
        s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
        archived_case_eq: true,
      },
      page: 1,
      per_page: this.state.pageSize,
    };
    this.setState({ archived: true });
    this.GetFilteredAlignerCase(data);
  };

  /*--------------------------------------------------------------------------------*/
  /*  on Page Num Change                                                             */
  /*--------------------------------------------------------------------------------*/
  onPageNumChange(page) {
    this.setState({ page });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: this.state.searchInput,
        aligner_case_transition_to_state_in: this.state.filters,
        display_status_eq:
          this.state.filters && this.state.display_status_eq
            ? this.state.filters[0]
            : "",
        s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
        archived_case_eq: this.state.archived,
      },
      page: page,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  }
  /*--------------------------------------------------------------------------------*/
  /*  on Sorted Change                                                              */
  /*--------------------------------------------------------------------------------*/
  onSortedChange(sort) {
    this.setState({
      sort,
      display_status_eq: this.state.filters ? this.state.filters[0] : "",
    });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: this.state.searchInput,
        aligner_case_transition_to_state_in: this.state.filters,
        display_status_eq: this.state.filters ? this.state.filters[0] : "",
        s: `${sort.id} ${sort.desc ? "desc" : "asc"}`,
        archived_case_eq: this.state.archived,
      },
      page: this.state.page,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  }
  /*--------------------------------------------------------------------------------*/
  /*  archive modal                                                           */
  /*--------------------------------------------------------------------------------*/
  toggleArchiveModal = () => {
    this.setState({ archiveModal: !this.state.archiveModal });
  };
  ArchiveCase = () => {
    const { content } = this.props.languageReducer;
    archiveCase(this.state.id, this.state.obj.id).then((res) => {
      successToaster(content.archived_case_successfully, content.Success);
      this.toggleArchiveModal();

      this.GetAllCases();
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*  archive modal                                                           */
  /*--------------------------------------------------------------------------------*/
  toggleUnArchiveModal = () => {
    this.setState({ unArchiveModal: !this.state.unArchiveModal });
  };

  unArchiveCase = () => {
    const { content } = this.props.languageReducer;
    unArchiveCase(this.state.id, this.state.obj.id).then((res) => {
      successToaster(content.restored_case_successfully, content.Success);
      this.toggleUnArchiveModal();
      // this.GetAllCases();
      this.setState({
        Loading: true,
        display_status_eq: this.state.filters ? this.state.filters[0] : "",
      });
      let data = {
        ...(this.props.location.state?.data
          ? this.props.location.state?.data
          : {
              filters: {
                uid_or_patient_full_name_cont: this.state.searchInput,
                aligner_case_transition_to_state_in: this.state.filters,
                display_status_eq: this.state.filters
                  ? this.state.filters[0]
                  : "",
                s: "created_at desc",
                archived_case_eq: true,
              },
              page: this.state.page,
              per_page: this.state.pageSize,
            }),
      };
      this.GetFilteredAlignerCase(data);
    });
  };
  archivedStatus = (status) => {
    let statuses = [
      // "Incomplete Submission",
      "Pending Courier Notification",
      "Pending Record Approval",
      "Pending TS Approval",
      "Generating TS",
      "Rejected TS",
      "Records Rejected",
      "Complete",
      "Awaiting Impressions",
      "Generating Adjustment TS",
      "Awaiting completion",
      "Expired"
    ];
    return statuses.includes(status);
  };
  /* --------------------------------------------------------------------------------*/
  /* component Will Unmount */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  /*--------------------------------------------------------------------------------*/
  render() {
    const { filter, searchInput, sort } = this.state;
    const { content } = this.props.languageReducer;
    const { date_format } = this.props.userReducer.currentUser;
    const FiltersOptions = [
      { name: "All", title: content.all },
      { name: "Active", title: content.active },
      { name: "Complete", title: content.complete },
      { name: "Pending", title: content.pending },
      { name: "Rejected", title: content.rejected },
      { name: "Archived", title: content.archived },
    ];

    const FilterOptionsValus = {
      All: "",
      Active: "active",
      Complete: "complete",
      Pending: [
        "pending_ts_approval",
        "pending_courier_notification",
        "pending_records_approval",
        "pending_accounting_clearance",
      ],
      Rejected: ["rejected_records", "doctor_rejected_ts"],
      Archived: true,
    };
    const { block_case_submission } = this.props.userReducer.currentUser;

    return (
      <div className="cases_review_page">
        <style>
          {`.ReactTable .rt-thead{
              color:#00C7B1
          }`}
        </style>
        {/*--------------------------------------------------------------------------------*/}
        {/* Archive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.archiveModal}
          toggle={this.toggleArchiveModal}
          className="c-modal c-border-light confarim-modal"
        >
          {/* <ModalHeader toggle={this.toggleArchiveModal}></ModalHeader> */}
          <ModalBody>
            <CustomeHeader
              title={content.archive_a_case}
              largeFont={true}
              withoutLine={true}
            />

            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 mb-1 headline-text">
                {content.confirmation_archive}
              </label>
              <div className="cmt3 order-button-mobile">
                <Button
                  className="btn light-green-btn pull-right cml1 button-seme-bold-text"
                  size="md"
                  onClick={this.ArchiveCase}
                >
                  {content.archive}
                </Button>
                <Button
                  className=" btn white-btn pull-right cml1 button-seme-bold-text"
                  size="md"
                  color="danger"
                  onClick={this.toggleArchiveModal}
                >
                  {content.cancel}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Archive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* unArchive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.unArchiveModal}
          toggle={this.toggleUnArchiveModal}
          className="c-modal c-border-light confarim-modal"
        >
          {/* <ModalHeader toggle={this.toggleUnArchiveModal}></ModalHeader> */}
          <ModalBody>
            <CustomeHeader
              title={content.restore_a_case}
              largeFont={true}
              withoutLine={true}
            />

            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 mb-1 headlin-text">
                {content.confirmation_restore}
              </label>
              <div className="cmt3 order-button-mobile">
                <Button
                  className="btn light-green-btn pull-right custom-margin-mobile button-seme-bold-text"
                  size="md"
                  onClick={this.unArchiveCase}
                >
                  {content.restore}
                </Button>
                <Button
                  className=" btn white-btn pull-right custom-margin-mobile button-seme-bold-text"
                  size="md"
                  color="danger"
                  onClick={this.toggleUnArchiveModal}
                >
                  {content.cancel}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End unArchive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Fixed header-footer table*/}
        {/*--------------------------------------------------------------------------------*/}
        <div>
          <div className="submit_case_btn_container">
            <Back />
            {window.innerWidth > 839 && (
              <span className="submit_case_btn">
                {!block_case_submission && (
                  <Button
                    className="btn light-green-btn pull-right cml1 cpx3 button-seme-bold-text"
                    size="md"
                    onClick={() => {
                      this.props.clearSteps();
                      window.location.href = `/case-submission`;

                      //this.props.history.push(`/case/new`);
                    }}
                  >
                    {content.submit_case}{" "}
                    <i className="fas fa-angle-right pl-1"></i>
                  </Button>
                )}
              </span>
            )}
          </div>
          <CustomeHeader title={content.cases_review} />
          <div className="cmt3">
            {/*--------------------------------------------------------------------------------*/}
            {/* Filters*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="cases_filter_search_grid ">
              {!this.state.Loading && (
                <>
                  {" "}
                  <div className="cases_filter_search_grid_inner_website">
                    <CustomRadioGroup
                      //  smallFont={true}
                      name={filter}
                      checked={filter}
                      onChange={(value) => {
                        if (value === "Archived") {
                          this.Archived("Archived", true);
                        } else {
                          this.Filter(value, FilterOptionsValus[value]);
                        }
                      }}
                      options={FiltersOptions}
                    />
                  </div>
                  <div className="cases_filter_search_grid_inner_mobile">
                    <CustomRadioGroup
                      smallFont={true}
                      name={`${filter}_mobile`}
                      checked={filter}
                      onChange={(value) => {
                        if (value === "Archived") {
                          this.Archived("Archived", true);
                        } else {
                          this.Filter(value, FilterOptionsValus[value]);
                        }
                      }}
                      options={FiltersOptions}
                    />

                    {/* {/* <span className="submit_case_btn"> */}
                    {!block_case_submission && (
                      <Button
                        className="btn light-green-btn light-green-btn-mobile-table pull-right cml1 label-1 button-small-bold-text"
                        size="md"
                        onClick={() => {
                          this.props.clearSteps();
                          window.location.href = `/case-submission`;

                          //this.props.history.push(`/case/new`);
                        }}
                      >
                        {content.submit_case}{" "}
                        {/* <i className="fas fa-angle-right pl-1"></i> */}
                      </Button>
                    )}
                    {/* }
              </span> */}
                  </div>
                  <Row className="align-items-center">
                    <Col>
                      {" "}
                      <div className=" question-circle btn-group w-100">
                        <Input
                          bsSize="large"
                          name="searchInput"
                          placeholder=" Search "
                          value={this.state.searchInput || ""}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              this.clearData();
                            } else {
                              this.setState({
                                searchInput: e.target.value,
                                inputChanged: true,
                              });
                            }
                          }}
                          label="Search"
                          autoComplete="off"
                          className="custome-input-text"
                          onKeyDown={(e) => {
                            if (e.target.value !== "" && e.key === "Enter") {
                              this.setState({ searchInput: e.target.value });
                              this.handleChange();
                            }
                          }}
                        />
                        {searchInput && (
                          <span
                            id="searchclear2"
                            className={`${
                              this.state.searchInput === ""
                                ? "disabled_search"
                                : "active-clear"
                            }`}
                            onClick={() => {
                              this.clearData();
                            }}
                            title="clear data"
                          >
                            <i className="fas fa-times"></i>{" "}
                          </span>
                        )}
                        <span
                          id="searchclear"
                          className={`${
                            this.state.searchInput === ""
                              ? "disabled_search"
                              : "active-clear"
                          }`}
                          onClick={() => {
                            this.handleChange();
                          }}
                          title="search"
                        >
                          <i className="fas fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Filters*/}
            {/*--------------------------------------------------------------------------------*/}
          </div>

          <div
            className={
              !this.state.Loading
                ? "cmt5"
                : "m-auto d-flex align-items-center cmt5"
            }
            id="cases_table"
          >
            {" "}
            {!this.state.Loading ? (
              <>
                {" "}
                <div className="step-progress-new-case upload-container">
                  {this.state.loadingData && (
                    <div className={`loading2`}>
                      <div className="spinner_2"></div>
                    </div>
                  )}
                </div>
                {window.innerWidth > 839 && (
                  <div className="c-table cmt2">
                    <div className="c-table-header-grid">
                      <div className="full_name">
                        {content.name}
                        {sort.id === "patient_full_name" &&
                        sort.desc === true ? (
                          <i
                            onClick={() => {
                              this.onSortedChange({
                                id: "patient_full_name",
                                desc: false,
                              });
                            }}
                            className={`${
                              sort.id === "patient_full_name" ? "" : ""
                            } fas fa-angle-up cpl1 v_align_middle c-pointer`}
                          ></i>
                        ) : (
                          <i
                            onClick={() => {
                              this.onSortedChange({
                                id: "patient_full_name",
                                desc: true,
                              });
                            }}
                            className={`${
                              sort.id === "patient_full_name" ? "" : ""
                            } fas fa-angle-down cpl1 v_align_middle c-pointer`}
                          ></i>
                        )}
                      </div>
                      <div className="case_id">
                        {content.case_no}
                        {sort.id === "uid" && sort.desc === true ? (
                          <i
                            onClick={() => {
                              this.onSortedChange({ id: "uid", desc: false });
                            }}
                            className={`${
                              sort.id === "uid" ? "" : ""
                            } fas fa-angle-up cpl1 v_align_middle c-pointer`}
                          ></i>
                        ) : (
                          <i
                            onClick={() => {
                              this.onSortedChange({ id: "uid", desc: true });
                            }}
                            className={`${
                              sort.id === "uid" ? "" : ""
                            } fas fa-angle-down cpl1 v_align_middle c-pointer`}
                          ></i>
                        )}
                      </div>
                      <div className="case_type">
                        {content.type}
                        {sort.id === "case_type" && sort.desc === true ? (
                          <i
                            onClick={() => {
                              this.onSortedChange({
                                id: "case_type",
                                desc: false,
                              });
                            }}
                            className={`${
                              sort.id === "case_type" ? "" : ""
                            } fas fa-angle-up cpl1 v_align_middle c-pointer`}
                          ></i>
                        ) : (
                          <i
                            onClick={() => {
                              this.onSortedChange({
                                id: "case_type",
                                desc: true,
                              });
                            }}
                            className={`${
                              sort.id === "case_type" ? "" : ""
                            } fas fa-angle-down cpl1 v_align_middle c-pointer`}
                          ></i>
                        )}
                      </div>
                      <div className="case_date">
                        {content.date}
                        {sort.id === "created_at" && sort.desc === true ? (
                          <i
                            onClick={() => {
                              this.onSortedChange({
                                id: "created_at",
                                desc: false,
                              });
                            }}
                            className={`${
                              sort.id === "created_at" ? "" : ""
                            } fas fa-angle-up cpl1 v_align_middle c-pointer`}
                          ></i>
                        ) : (
                          <i
                            onClick={() => {
                              this.onSortedChange({
                                id: "created_at",
                                desc: true,
                              });
                            }}
                            className={`${
                              sort.id === "created_at" ? "" : ""
                            } fas fa-angle-down cpl1 v_align_middle c-pointer`}
                          ></i>
                        )}
                      </div>
                      <div className="case_status">{content.status} </div>
                      <div className="case_actions">{content.actions} </div>
                      <div className="case_archive">{content.archive}</div>
                    </div>
                    <div className="max-body-h">
                      {this.state.cases.map((prop, key) => (
                        <div className="c-table-body-grid" key={key}>
                          <div className="full_name body-text">
                            {prop.patient.full_name}
                          </div>
                          <div className="case_id body-text">
                            <div
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={() => {
                                localStorage.removeItem("data");
                                this.props.history.push({
                                  pathname: `/case/${prop.id}`,
                                  state: {
                                    data: {
                                      filters: {
                                        uid_or_patient_full_name_cont:
                                          this.state.searchInput,
                                        aligner_case_transition_to_state_in:
                                          this.state.filters,
                                        display_status_eq: this.state
                                          .display_status_eq
                                          ? this.display_status_eq
                                          : "",
                                        s: `${this.state.sort.id} ${
                                          this.state.sort.desc ? "desc" : "asc"
                                        }`,
                                        archived_case_eq: this.state.archived,
                                      },
                                      page: this.state.page,
                                      per_page: this.state.pageSize,
                                      filter: this.state.filter,
                                    },
                                  },
                                });
                              }}
                            >
                              {" "}
                              {prop.uid
                                ? prop.display_status !==
                                  "Incomplete Submission"
                                  ? prop.uid
                                  : ""
                                : ""}
                            </div>
                          </div>
                          <div className="case_type body-text">
                            {content[caseType[prop.case_type]] ||
                              prop.case_type}
                          </div>
                          <div className="case_date body-text">
                            {moment(new Date(prop.submitted_at)).format(
                              date_format
                            )}
                          </div>
                          <div className="case_status body-text">
                            <div className="status_button">
                              <span
                                className="status_button_dot "
                                style={{
                                  backgroundColor: statusColors[
                                    prop.display_status
                                  ]
                                    ? `${
                                        statusColors[prop.display_status].color
                                      }`
                                    : "#67757c",
                                }}
                              ></span>{" "}
                              <span>{prop.display_status}</span>
                            </div>
                          </div>
                          <div className="case_actions caption-2-text">
                            <div className="align-items-center body-text">
                              {prop.display_status ===
                                "Incomplete Submission" &&
                                !prop.is_archived && !block_case_submission &&(
                                  <div>
                                    {" "}
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          modal: !this.state.modal,
                                          obj: prop,
                                        });
                                        this.props.clearSteps();

                                        if (prop.case_type === "Retainer") {
                                          this.props.history.push(
                                            `/retainer?id=${prop.id}`
                                          );
                                        } else {
                                          this.props.history.push(
                                            `/case-submission?id=${prop.id}`
                                          );
                                        }
                                      }}
                                      size="sm"
                                      className="btn dark-gray-btn2 custom-case-review-btn button-small-bold-text"
                                    >
                                      {content.submit_case}

                                      <i className="fas fa-angle-right pl-2"></i>
                                    </Button>
                                  </div>
                                )}

                              {prop.display_status === "Records Rejected" &&
                                !prop.is_archived && (
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        modal: !this.state.modal,
                                        obj: prop,
                                      });
                                      localStorage.removeItem("data");
                                      this.props.history.push({
                                        pathname: `/case/${prop.id}`,
                                        state: {
                                          data: {
                                            filters: {
                                              uid_or_patient_full_name_cont:
                                                this.state.searchInput,
                                              aligner_case_transition_to_state_in:
                                                this.state.filters,
                                              display_status_eq: this.state
                                                .display_status_eq
                                                ? this.display_status_eq
                                                : "",
                                              s: `${this.state.sort.id} ${
                                                this.state.sort.desc
                                                  ? "desc"
                                                  : "asc"
                                              }`,
                                              archived_case_eq:
                                                this.state.archived,
                                            },
                                            page: this.state.page,
                                            per_page: this.state.pageSize,
                                            filter: this.state.filter,
                                          },
                                        },
                                      });
                                    }}
                                    color="info"
                                    size="sm"
                                    className="btn dark-gray-btn2 custom-case-review-btn button-small-bold-text"
                                  >
                                    {content.update_records}
                                    <i className="fas fa-angle-right pl-2"></i>
                                  </Button>
                                )}
                              {prop.display_status === "Pending TS Approval" &&
                                !prop.is_archived && (
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        modal: !this.state.modal,
                                        obj: prop,
                                      });
                                      localStorage.removeItem("data");
                                      this.props.history.push({
                                        pathname: `/case/${prop.id}`,
                                        state: {
                                          data: {
                                            filters: {
                                              uid_or_patient_full_name_cont:
                                                this.state.searchInput,
                                              aligner_case_transition_to_state_in:
                                                this.state.filters,
                                              display_status_eq: this.state
                                                .display_status_eq
                                                ? this.display_status_eq
                                                : "",
                                              s: `${this.state.sort.id} ${
                                                this.state.sort.desc
                                                  ? "desc"
                                                  : "asc"
                                              }`,
                                              archived_case_eq:
                                                this.state.archived,
                                            },
                                            page: this.state.page,
                                            per_page: this.state.pageSize,
                                            filter: this.state.filter,
                                          },
                                        },
                                      });
                                    }}
                                    color="info"
                                    size="sm"
                                    className="btn dark-gray-btn2 custom-case-review-btn button-small-bold-text"
                                  >
                                    {content.View_TS}

                                    <i className="fas fa-angle-right pl-2"></i>
                                  </Button>
                                )}
                            </div>
                          </div>
                          <div className="case_archive">
                            <div className="text-center align-items-center justify-content-center ">
                              {this.archivedStatus(prop.display_status) &&
                                !prop.is_archived && (
                                  <div>
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          archiveModal:
                                            !this.state.archiveModal,
                                          obj: prop,
                                        });
                                      }}
                                      className="ml-1"
                                      color="white"
                                      size="md"
                                      round="true"
                                      icon="true"
                                      title={content.archive_case}
                                    >
                                      <img
                                        src={archive}
                                        alt={content.archive_case}
                                      />
                                    </Button>
                                  </div>
                                )}
                              {prop.is_archived && (
                                <div>
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        unArchiveModal:
                                          !this.state.unArchiveModal,
                                        obj: prop,
                                      });
                                    }}
                                    className="ml-1 button-seme-bold-text"
                                    color="white"
                                    size="md"
                                    round="true"
                                    icon="true"
                                    title={content.restore_case}
                                  >
                                    <img
                                      src={restore}
                                      alt={content.restore_case}
                                    />
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="c-table-footer">
                      {this.state.pages >= 2 && (
                        <div className="Pagination-Container">
                          <Pagination
                            cases={this.state.cases}
                            items={this.state.pages}
                            perPage={this.state.pageSize}
                            initialPage={this.state.page}
                            onChangePage={(e) => {
                              this.onPageNumChange(e.currentPage);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {window.innerWidth < 840 && (
                  <>
                    <Table className="w-100 p-0" bordered size="sm">
                      <thead>
                        <tr>
                          <th className="body-text">
                            {content.name}
                            {sort.id === "patient_full_name" &&
                            sort.desc === true ? (
                              <i
                                onClick={() => {
                                  this.onSortedChange({
                                    id: "patient_full_name",
                                    desc: false,
                                  });
                                }}
                                className={`${
                                  sort.id === "patient_full_name"
                                    ? "light_green_icon"
                                    : ""
                                } fas fa-angle-up cpl1 v_align_middle c-pointer`}
                              ></i>
                            ) : (
                              <i
                                onClick={() => {
                                  this.onSortedChange({
                                    id: "patient_full_name",
                                    desc: true,
                                  });
                                }}
                                className={`${
                                  sort.id === "patient_full_name"
                                    ? "light_green_icon"
                                    : ""
                                } fas fa-angle-down cpl1 v_align_middle c-pointer`}
                              ></i>
                            )}
                          </th>
                          <th className="body-text">{content.status}</th>
                          <th className="body-text">{content.actions}</th>
                        </tr>
                      </thead>
                      <tbody className="max-body-h">
                        {this.state.cases.map((value, index) => (
                          <tr key={index}>
                            <td className="body-text">
                              {value.patient.full_name}
                            </td>
                            <td className="body-text">
                              {value.uid &&
                              value.display_status !==
                                "Incomplete Submission" ? (
                                <div
                                  style={{ color: "blue" }}
                                  onClick={() => {
                                    localStorage.removeItem("data");
                                    this.props.history.push({
                                      pathname: `/case/${value.id}`,
                                      state: {
                                        data: {
                                          filters: {
                                            uid_or_patient_full_name_cont:
                                              this.state.searchInput,
                                            aligner_case_transition_to_state_in:
                                              this.state.filters,
                                            display_status_eq: this.state
                                              .display_status_eq
                                              ? this.display_status_eq
                                              : "",
                                            s: `${this.state.sort.id} ${
                                              this.state.sort.desc
                                                ? "desc"
                                                : "asc"
                                            }`,
                                            archived_case_eq: false,
                                          },
                                          page: this.state.page,
                                          per_page: this.state.pageSize,
                                          filter: this.state.filter,
                                        },
                                      },
                                    });
                                  }}
                                >
                                  <div className="status_button">
                                    <span
                                      className="status_button_dot"
                                      style={{
                                        backgroundColor: statusColors[
                                          value.display_status
                                        ]
                                          ? `${
                                              statusColors[value.display_status]
                                                .color
                                            }`
                                          : "#67757c",
                                      }}
                                    ></span>{" "}
                                    <span className="subhead-text">
                                      {value.display_status}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="status_button">
                                  <span
                                    className="status_button_dot"
                                    style={{
                                      backgroundColor: statusColors[
                                        value.display_status
                                      ]
                                        ? `${
                                            statusColors[value.display_status]
                                              .color
                                          }`
                                        : "#67757c",
                                    }}
                                  ></span>{" "}
                                  <span>{value.display_status}</span>
                                </div>
                              )}
                            </td>
                            <td className="caption-2-text">
                              <div className="align-items-center">
                                {value.display_status ===
                                  "Incomplete Submission" &&
                                  !value.is_archived &&!block_case_submission &&(
                                    <div>
                                      {" "}
                                      <Button
                                        onClick={() => {
                                          this.setState({
                                            modal: !this.state.modal,
                                            obj: value,
                                          });
                                          this.props.clearSteps();

                                          if (value.case_type === "Retainer") {
                                            this.props.history.push(
                                              `/retainer?id=${value.id}`
                                            );
                                          } else {
                                            this.props.history.push(
                                              `/case-submission?id=${value.id}`
                                            );
                                          }
                                        }}
                                        size="sm"
                                        className="btn dark-gray-btn2 button-small-bold-text"
                                      >
                                        {content.submit_case}
                                        <i className="fas fa-angle-right pl-2"></i>
                                      </Button>
                                    </div>
                                  )}

                                {value.display_status === "Records Rejected" &&
                                  !value.is_archived && (
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          modal: !this.state.modal,
                                          obj: value,
                                        });
                                        localStorage.removeItem("data");
                                        this.props.history.push({
                                          pathname: `/case/${value.id}`,
                                          state: {
                                            data: {
                                              filters: {
                                                uid_or_patient_full_name_cont:
                                                  this.state.searchInput,
                                                aligner_case_transition_to_state_in:
                                                  this.state.filters,
                                                display_status_eq: this.state
                                                  .display_status_eq
                                                  ? this.display_status_eq
                                                  : "",
                                                s: `${this.state.sort.id} ${
                                                  this.state.sort.desc
                                                    ? "desc"
                                                    : "asc"
                                                }`,
                                                archived_case_eq:
                                                  this.state.archived,
                                              },
                                              page: this.state.page,
                                              per_page: this.state.pageSize,
                                              filter: this.state.filter,
                                            },
                                          },
                                        });
                                      }}
                                      color="info"
                                      size="sm"
                                      className="btn dark-gray-btn2 button-small-bold-text"
                                    >
                                      {content.update_records}
                                      <i className="fas fa-angle-right pl-2"></i>
                                    </Button>
                                  )}
                                {value.display_status ===
                                  "Pending TS Approval" &&
                                  !value.is_archived && (
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          modal: !this.state.modal,
                                          obj: value,
                                        });
                                        localStorage.removeItem("data");
                                        this.props.history.push({
                                          pathname: `/case/${value.id}`,
                                          state: {
                                            data: {
                                              filters: {
                                                uid_or_patient_full_name_cont:
                                                  this.state.searchInput,
                                                aligner_case_transition_to_state_in:
                                                  this.state.filters,
                                                display_status_eq: this.state
                                                  .display_status_eq
                                                  ? this.display_status_eq
                                                  : "",
                                                s: `${this.state.sort.id} ${
                                                  this.state.sort.desc
                                                    ? "desc"
                                                    : "asc"
                                                }`,
                                                archived_case_eq:
                                                  this.state.archived,
                                              },
                                              page: this.state.page,
                                              per_page: this.state.pageSize,
                                              filter: this.state.filter,
                                            },
                                          },
                                        });
                                      }}
                                      color="info"
                                      size="sm"
                                      className="btn dark-gray-btn2 button-small-bold-text"
                                    >
                                      {content.View_TS}

                                      <i className="fas fa-angle-right pl-2"></i>
                                    </Button>
                                  )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {this.state.pages >= 2 && (
                      <div className="Pagination-Container">
                        <Pagination
                          cases={this.state.cases}
                          items={this.state.pages}
                          perPage={this.state.pageSize}
                          initialPage={this.state.page}
                          onChangePage={(e) => {
                            this.onPageNumChange(e.currentPage);
                          }}
                        />
                      </div>
                    )}

                    {!block_case_submission && (
                      <div className="new_case2">
                        {/* <div id="container_btn">
                          <div id="content">
                            <Button
                              className="service-panel-toggle text-white add-case btn light-green-btn button-bold-text"
                              size="md"
                              title="Submit a case"
                              onClick={() => {
                                this.props.clearSteps();

                                window.location.href = `/case-submission`;
                                // this.props.history.push(`/case/new`);
                              }}
                            >
                              {content.submit_case}{" "}
                              <i className="fas fa-angle-right pl-1"></i>
                            </Button>{" "}
                          </div>
                        </div> */}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <Spinner className="green-spinner" type="grow" />
            )}
          </div>
        </div>
        {/*--------------------------------------------------------------------------------*/}
        {/* End fixed header-footer table*/}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Cases);
