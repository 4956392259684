import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Image, Link } from "@react-pdf/renderer";
const styles = StyleSheet.create({
    row: {
        //  flexDirection: "row",
        paddingBottom: "5px",
    },
    image: {
        width: "15%",
    },
    text: {
        display: "flex",
        flexDirection: "row",
        fontSize: 14,
        textAlign: "left",
        color: "#00c7b1",
        paddingBottom: 5,
    },

    text_area: {
        display: "flex",
        flexDirection: "row",
        borderColor: "#a0f0e7",
        borderWidth: 1,
        borderRadius: 2,
        fontSize: 10,
        padding: 2,
        minHeight: "170px",
        textAlign: 'justify'
    },
    value: {
        padding: "10px",
        fontSize: 10
    },
    disclaimer: {
        // position: "absolute",
        // bottom: "0",
        // display: "flex",
        // flexDirection: "row",
    },
    important: {
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        textAlign: "left",
        color: "black",
        paddingBottom: 5,
        paddingTop: 10,
        fontWeight: "bold"
    },
    important_notes: {
        fontSize: 8,
    }
});

export default class Notes extends Component {
    render() {
        const { content, notes } = this.props
        return (
            <Fragment>
            <View style={styles.row}>
                <Text style={styles.text}>Notes :</Text>
                <View style={styles.text_area}>
                    <Text style={styles.value}>
                        {notes}
                    </Text>
                </View>
                <View style={styles.disclaimer}>
                    <Text style={styles.important}>Important:</Text>
                    <Text style={styles.important_notes}>The IPR amount indicated is an estimate and it is meant as a guideline. As the treating doctor, you must
                        determine the final amount of IPR performed depending on the case progress.
                    </Text>
                    <Text style={styles.important_notes}>
                        For example: in the case of tight contacts, you can perform unscheduled IPR to free those contacts. On the other hand
                        , if you find a contact with more interproximal space then anticipated, then you can defer or cancel the scheduled IPR for that contact.
                    </Text>
                </View>
            </View>
        </Fragment>
   
        );
    }
}
