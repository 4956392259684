import {
  CASE_STEPS,
  CURRENT_STEP,
  PREV_STEPS,
  SHOW_ADVANCED,
  UPDATE_ADVANCED_MODULES,
  UPDATE_ALL_ADVANCED_MODULES,
  UPDATE_PHOTOS_XRAYS,
  UPDATE_IMPRESSIONS,
  UPDATE_PRESCRIPTION,
  UPDATE_MALOCCLUSION,
  CLEAR_MALOCCLUSION_DATA,
  CLEAR_ADVANCED_MODULES_DATA,
  CLEAR_STEPS,
  UPDATE_PATIENT_INFO,
  // Case_teethMovement,
  SUBMISSION_SIDE_NAV, 
  CASE_RETAINER_STEPS, 
  PREV_RETAINER_STEPS
} from "../constants/";
import patient_info_icon from "../../assets/images/v4/patient_info_icon.svg";
import photos_icon from "../../assets/images/v4/photos_icon.svg";
import impressions_icon from "../../assets/images/v4/impressions_icon.svg";
import prescription_icon from "../../assets/images/v4/prescription_icon.svg";
import summary_icon from "../../assets/images/v4/summary_icon.svg"
import complete from "../../assets/images/v4/check.svg"


import cameraDisable from "../../assets/images/StepperMobile/cameraDisable.svg"
import FrameDisable from "../../assets/images/StepperMobile/FrameDisable.svg"
import InformationDisable from "../../assets/images/StepperMobile/InformationDisable.svg"
import PrescriptionImageDisable from "../../assets/images/StepperMobile/PrescriptionImageDisable.svg"
import ImpressionsImageDisable from "../../assets/images/StepperMobile/ImpressionsImageDisable.svg"
import checkDisable from "../../assets/images/StepperMobile/checkDisable.svg"
















import { advanced_modules_data } from "./advanced_modules_data";
const INIT_STATE = {
  currentStep: 1,
  showAdvancedModule: true,
  hideSubmissionSideNav: window.innerWidth < 840 ? true : false,
  Steps: [
    {
      id: 1,
      name: "Patient Information",
      key: "patient_Information",
      active: true,
      subTitle: false,
      icon: patient_info_icon,
      disableImage:InformationDisable
    },
    // {
    //   id: 2,
    //   name: "Treatment Type",
    //   active: false,
    //   subTitle: false,
    // },
    {
      id: 2,
      name: "Photos + X-rays",
      key: "photos_x_rays",
      active: false,
      subTitle: false,
      icon: photos_icon,
      disableImage:cameraDisable
    },
    {
      id: 3,
      name: "Impressions",
      key: "impressions",
      active: false,
      subTitle: false,
      icon: impressions_icon,
      disableImage:ImpressionsImageDisable
    },

    {
      id: 4,
      name: "Prescription",
      key: "prescription",
      active: false,
      subTitle: false,
      icon: prescription_icon,
      disableImage:PrescriptionImageDisable,
    },
    // {
    //   id: 5,
    //   name: "Advanced Treatment Options",
    //   active: false,
    //   subTitle: true,
    // },
    {
      id: 6,
      name: "Summary",
      key: "summary",
      active: false,
      subTitle: false,
      icon: summary_icon,
      disableImage: FrameDisable,
    },

    {
      id: 7,
      name: "Submission Complete",
      key: "submission_complete",
      active: false,
      subTitle: false,
      icon: complete,
      disableImage:checkDisable
    }
  ],
  LoyaltySteps : [
    {
      id: 1,
      name: "Bronze",
      key: "Bronze",
      active: true,
      subTitle: false,
      icon: patient_info_icon,
      disableImage:InformationDisable,
      cases: "1-8 Cases"

    },

    {
      id: 2,
      name: "Silver",
      key: "Silver",
      active: false,
      subTitle: false,
      icon: photos_icon,
      disableImage:cameraDisable ,
      cases: "9-16 Cases"
    },
    {
      id: 3,
      name: "Gold",
      key: "Gold",
      active: false,
      subTitle: false,
      icon: impressions_icon,
      disableImage:ImpressionsImageDisable,
      cases: "17-24 Cases"
    },

    {
      id: 4,
      name: "Platinum",
      key: "Platinum",
      active: false,
      subTitle: false,
      icon: prescription_icon,
      disableImage:PrescriptionImageDisable,
      color:"#4F5665",
      cases: "25-40 Cases"
    },

    {
      id: 5,
      name: "Diamond",
      key: "Diamond",
      active: false,
      subTitle: false,
      icon: summary_icon,
      disableImage: FrameDisable,
      color:"#74B1BE",
      cases: "41-56 Cases"
    },
    {
      id: 6,
      name: "Elite",
      key: "Elite",
      active: false,
      subTitle: false,
      icon: summary_icon,
      disableImage: FrameDisable,
      color:"#00C7B1",
      cases: "57+ Cases"
    },


  ],

  RetainerSteps: [
    {
      id: 1,
      name: "Patient Information",
      key: "patient_Information",
      active: true,
      subTitle: false,
      icon: patient_info_icon,
      disableImage:InformationDisable
    },
    {
      id: 2,
      name: "Impressions",
      key: "impressions",
      active: false,
      subTitle: false,
      icon: impressions_icon,
      disableImage:ImpressionsImageDisable,
    },

   
    {
      id: 3,
      name: "Summary",
      key: "summary",
      active: false,
      subTitle: false,
      icon: summary_icon,
      disableImage: FrameDisable,
    },

    {
      id: 4,
      name: "Submission Complete",
      key: "submission_complete",
      active: false,
      subTitle: false,
      icon: complete,
      disableImage:checkDisable
    }
  ],
  
  advanced_modules: advanced_modules_data,
  patient_info: {
    date_of_birth: "",
    first_name: "",
    gender: "",
    last_name: "",
    email: "",
    case_type: "",
    address_id: ""
  },
  photos_xrays: {
    front_smiling: "",
    front_pose: "",
    profile: "",
    upper_occlusal: "",
    lower_occlusal: "",
    left_buccal: "",
    frontal: "",
    right_buccal: "",
    panoramic: "",
    cephalometric: "",
  },
  impressions: {
    impressions_method: "",
    impressions_third_party_note: "",
    upper_arch_url: "",
    lower_arch_url: "",
    pickup_address_id:"", 
    pickup_address :""
  },

  prescription: {
    chief_complaint: "",
    summary_and_special_instructions: "",
    treat_arches: "",
    wear_cycle: "",
  },
};





export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBMISSION_SIDE_NAV:
      if (action.payload === true) {
        if (document.getElementById("mySidenav")) {
          document.getElementById("mySidenav").style.width = "0";

          if (window.innerWidth > 840) {
            document.getElementById("main-gco").style.marginLeft = "0";
          }
        }
      } else {
        if (window.innerWidth> 840) {
          if (document.getElementById("mySidenav") && document.getElementById("main-gco")) {
            document.getElementById("mySidenav").style.width = "250px";
            document.getElementById("main-gco").style.marginLeft = "250px";
          }

        } else {
          if (document.getElementById("main-gco")) {
            document.getElementById("mySidenav").style.width = "205px";
          }

        }

      }
      return {
        ...state,
        hideSubmissionSideNav: action.payload
      };
    case CASE_STEPS:
      return {
        ...state,
        Steps: state.Steps.map((item, index) => {
          // Find the item with the matching id
          if (item.id <= action.payload.id) {
            // Return a new object
            return {
              ...item, // copy the existing item
              active: true, // activate step
            };
          }

          // Leave every other item unchanged
          return item;
        }),
      };
      case CASE_RETAINER_STEPS:
        return {
          ...state,
          RetainerSteps: state.RetainerSteps.map((item, index) => {
            // Find the item with the matching id
            if (item.id <= action.payload.id) {
              // Return a new object
              return {
                ...item, // copy the existing item
                active: true, // activate step
              };
            }
  
            // Leave every other item unchanged
            return item;
          }),
        };


      
    case PREV_STEPS:
      return {
        ...state,
        Steps: state.Steps.map((item, index) => {
          // Find the item with the matching id
          if (item.id <= action.payload.id) {
            // Return a new object
            return {
              ...item, // copy the existing item
              active: true, // activate step
            };
          } else {
            return {
              ...item, // copy the existing item
              active: false, // activate step
            };
          }
        }),
      };
      case PREV_RETAINER_STEPS:
        return {
          ...state,
          RetainerSteps: state.RetainerSteps.map((item, index) => {
            // Find the item with the matching id
            if (item.id <= action.payload.id) {
              // Return a new object
              return {
                ...item, // copy the existing item
                active: true, // activate step
              };
            } else {
              return {
                ...item, // copy the existing item
                active: false, // activate step
              };
            }
          }),
        };
    case CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case SHOW_ADVANCED:
      return {
        ...state,
        showAdvancedModule: action.payload,
      };
    case UPDATE_PATIENT_INFO:
      return {
        ...state,
        patient_info: action.payload
      }
    case UPDATE_ADVANCED_MODULES:
      return {
        ...state,
        advanced_modules: {
          ...state.advanced_modules,
          [action.payload.key]: action.payload.data,
        },
      };
    case UPDATE_ALL_ADVANCED_MODULES:
      return {
        ...state,
        advanced_modules: action.payload,
      };
    case UPDATE_PHOTOS_XRAYS:
      return {
        ...state,
        photos_xrays: action.payload,
      };
    case UPDATE_IMPRESSIONS:
      return {
        ...state,
        impressions: action.payload,
      };
    case UPDATE_PRESCRIPTION:
      return {
        ...state,
        prescription: action.payload,
      };
    case UPDATE_MALOCCLUSION:
      return {
        ...state,
        advanced_modules: {
          ...state.advanced_modules,
          malocclusion: {
            ...state.advanced_modules.malocclusion,
            [action.payload.key]: action.payload.data,
          },
        },
      };
    case CLEAR_MALOCCLUSION_DATA:
      return {
        ...state,
        advanced_modules: {
          ...state.advanced_modules,
          malocclusion: {
            ...state.advanced_modules.malocclusion,
            [action.payload]: INIT_STATE.advanced_modules.malocclusion[action.payload],
          },
        },
      };
    case CLEAR_ADVANCED_MODULES_DATA:
      return {
        ...state,
        advanced_modules: {
          ...state.advanced_modules,
          [action.payload]: INIT_STATE.advanced_modules[action.payload],

        },
      };
    case CLEAR_STEPS:
      return {
        ...state,
        Steps: INIT_STATE.Steps
      }
    default:
      return state;
  }
};

export const selectAdvancedModules = ({ caseSubmission }) =>
  caseSubmission.advanced_modules;

  export const selectEnableAdvancedModules = ({ caseSubmission }) =>
  caseSubmission.showAdvancedModule;
