import React, { Component } from "react";
import { connect } from "react-redux";

import { Button } from "reactstrap";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action";
import CustomToggle from "../../../../../components/toggle/CustomeToggle";
import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),
});
class Attachments extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_attachments:
        this.props.caseSubmission.advanced_modules.attachments.useAttachments,
      attachments_timing_3: false,
      attachments_timing_7: false,
      attachmentsTiming:
        this.props.caseSubmission.advanced_modules.attachments
          .attachmentsTiming,
      attachments: [
        {
          step: 0,
          type: "",
        },
      ],
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "attachments",
      data: {
        useAttachments: this.state.use_attachments,
        attachmentsTiming: this.state.attachmentsTiming,
        attachmentsRestriction: this.props.customeTeethReducer.Case_Attachments,
      },
    });
    this.props.save();
  };

  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value,
    });
  };
  handelChanges = (value) => {
    this.setState({
      attachmentsTiming: value,
    });
  };

  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false,
        });
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide",
        });
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true,
        });
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_attachments } = this.state;
    const { content } = this.props.languageReducer;

    {
      /* <span className={`${this.state.showCard ? "pull-right ts-close-button" : "ts-close-button-none"}`} onClick={() => { this.toggleModalsClose(active_module) }}>&times;</span>
          <br /> */
    }
    // <CustomeHeader title={content.attachments} className="cp3 pt-0" optional={false} note={true} note_text={content.attachments_note} />

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">
          {/* <div class="D-total-cont"> */}
          <div className="customHeaderTS ">
            <div>
              {" "}
              <CustomeHeader
                title={content.attachments}
                className=""
                optional={false}
                note={false}
                note_text={content.attachments_note}
              />
            </div>
            <div className="reative-close-ts">
              {" "}
              <span
                className={` pull-right ts-close-button-new`}
                onClick={() => {
                  this.props.toggleModalsClose(this.props.active_module);
                }}
              >
                &times;
              </span>
            </div>
          </div>
          {/* </div> */}
          <div>
            <div className="admin-Grid-Advance">
              <div></div>
              {/* part 1 */}
              <div className=" admin-Grid-Advance-inner D-head-ts">
                <div>{content.Used_attachments_for_this_case} </div>
                {/* part 2 */}
                <div className="D-total-cont-toggle">
                  <div className="pointer_disable">
                    <CustomToggle
                      id="use-attachments"
                      checked={use_attachments}
                      className="pointer_disable"
                      // disabled={this.props.summary}
                      onChange={() => {
                        this.setState({
                          use_attachments: !use_attachments,

                          ...(use_attachments && {
                            attachmentsTiming: "",
                          }),
                        });

                        if (use_attachments) {
                          this.props.Clear_Teeth_Data("Case_Attachments");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div></div>
            </div>

            <div>
              <div className="control-label title_active-gcoTS-new  pb-2  pt-3 text-center D-head-ts">
                {content.placement}
              </div>
              <div className="teeth-selector-ts">
                <div className="teeth_selector m-auto">
                  <CustomeTeeth
                    action_key="Case_Attachments"
                    disabled={
                      !use_attachments || use_attachments === "eonToDecide"
                    }
                    // summary={this.props.summary}
                    advanced_Ts="true"
                    summary="true"
                  />
                </div>
              </div>
            </div>

            {/* ////////// new part ///////// */}

            {/* <div className="admin-Grid-Advance">

              <div></div>
              <div>
                <ToothAttachments
                  onclick={(attachments) => {
                    this.setState({ attachments: attachments })
                  }}
                  attachments_array={this.state.attachments}
                  StepTitle={content.add_attachments_on_step}
                  hyberText={content.add_another_attachment}
                  content={content}
                  useAttachments={use_attachments}
                />
              </div>

              <div></div>
            </div> */}
            {/* <div className="auto_grid_timing pb-2">
              <div className="control-label title_active-gco align-self-end">
                Attachments timing{" "}
              </div>
              <div className="auto_grid2">


                <div className="form-material">
                  <Input
                    type="number"
                    placeholder=""
                    className="custom-number"
                   
                    onClick={e => {
                      e.target.focus();
                    }}
                    onMouseUp={e => {
                      e.target.blur();
                    }}
                    value={
                      attachmentsTiming
                    }
                    min="1"
                    onChange={(e) => {
                      this.setState({
                        attachmentsTiming: e.target.value,
                      });
                    }}

                    disabled={!use_attachments || use_attachments === "eonToDecide" || this.props.summary}

                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {!this.props.summary && (
          <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
