import { getPriceJOAR } from "./jo/ar";
import { getPriceSAAR } from "./ksa/ar";
import { getPriceKWAR } from "./kw/ar";
import { getPriceOtherAR } from "./other/ar";
import { getPriceQAAR } from "./qa/ar";
import { getPriceUAEAR } from "./uae/ar";

const getPrices = (country) => {
  switch (country) {
    case "AE":
      return getPriceUAEAR();
    case "JO":
      return getPriceJOAR();
    case "SA":
      return getPriceSAAR();
    case "KW":
      return getPriceKWAR();
    case "QA":
      return getPriceQAAR();
    default:
      return getPriceOtherAR();
  }
};
export const TermsAR = (country) => {
  return `
    <div class="bold_text cpy1"> شروط وأحكام إيون  ألاينر </div>
    <div class="normal_text cpb1"> تاريخ آخر تحديث: 1 اكتوبر 2022</div>
    <div class="bold_text cpt2 cpb1"> 1. مقدمة  </div>

    <div class="normal_body_text"> 
    <span class="bold_text"> 1.1. </span> 
     تشكل هذه الشروط والأحكام ("<span class="bold_text">الاتفاقية</span>") الاتفاق والتفاهم الكامل
     وتطبق على كافة جوانب العلاقة  بين إيون دنتال إن في، أو حسب الحالة
     إحدى شركاتها الفرعية أو الزميلة ("<span class="bold_text">إيون دنتال</span>") وأي عميل
     ("<span class="bold_text">العميل</span>"). لن يسري أي تغيير أو تعديل على هذه الاتفاقية
    أو أي شروط أو أحكام أخرى، سواء كانت ضمنية أو غير ذلك،إلا إذا وافقت إيون دنتال صراحة على ذلك خطيا.
     </div>

     <div class="normal_body_text"> 
     <span class="bold_text"> 1.2. </span>تعمل إيون دنتال، وذلك من ضمن أمور أخرى، في مجال تصميم وتصنيع وبيع وتوزيع وتقديم خدمات مصففات الأسنان الشفافة 
     المُصممة خصيصًا لتقويم الأسنان ("<span class="bold_text">المصففات الشفافة</span>") وكذلك مثبتات تقويم 
     الأسنان المتحركة المصنوعة بالكامل من البلاستيك الشفاف ("<span class="bold_text">المثبتات</span>") 
     (يُشار إليهم معًا بـ "<span class="bold_text">المنتجات</span>") وذلك إلى جانب تقديم الخدمات المساندة
      و الإضافية (بما في ذلك عروض المعاينة حول كيفية توقع قيام 
      المنتجات بتحريك الأسنان، والنماذج الافتراضية ثلاثية الأبعاد لكل خطوة
       من خطوات العلاج، وعارض حاسوب ثلاثي الأبعاد ("<span class="bold_text">خطة العلاج</span>")
        ("<span class="bold_text">الخدمات</span>").
       </div>

    <div class="normal_body_text"> 
    <span class="bold_text"> 1.3. </span>تحكم هذه الاتفاقية استخدام العميل لموقع إيون دنتال الإلكتروني،
     والمنصة الالكترونية لإدارة الحالة من أجل إعداد خطة العلاج
     والتواصل مع العميل ("<span class="bold_text">إيون أكسيس</span>") والخطوات والمتطلبات لتقديم
     طلب للحصول على المنتجات والخدمات ("<span class="bold_text">طلب الحالة</span>")، والتي يوافق العميل على
     الالتزام بها من وقت دخول العميل لأول مرة إلى إيون أكسيس مقابل تزويد
     إيون دنتال للعميل بالمنتجات والخدمات والإذن باستخدام إيون أكسيس. 
    </div>

    <div class="bold_text cpt2 cpb1"> 2. العملاء </div>
    <div class="normal_body_text">
    <span class="bold_text"> 2.1. </span> عميل إيون دنتال هو الطبيب الذي يستخدم عنوان بريده
     الإلكتروني لتسجيل الدخول إلى إيون أكسيس ("<span class="bold_text">طبيب إيون</span>").
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 2.2. </span> إذا كان طبيب إيون يمتلك أو يعمل مع
     أو لدى عيادة أو شخص اعتباري آخر ("العيادة")، وقد تم تسمية هذه العيادة في الفاتورة (الفواتير) الصادرة
      عن إيون دنتال أو تقوم بدفعها  على سبيل الممارسة العملية، فإن الإشارة الى الفاتورة
      (الفواتير) أو عملية دفعها ستكون بمثابة تفويض من العيادة لطبيب إيون لاستخدام
      إيون أكسيس وإصدار طلبات الحالة، وفي هذه الحالة تكون العيادة أيضًا أحد عملاء إيون،
      وسيكون طبيب إيون والعيادة مسؤولين بالتضامن والتكافل عن الالتزامات الواردة
      في هذه الاتفاقية والتي تمثل التزامات العميل. 
    </div>

    <div class="bold_text cpt2 cpb1"> 3. الحالات والقبول </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.1. </span> 
    يجب إدراج طلبات الحالات في إيون أكسيس وتصبح سارية المفعول في التاريخ
     والوقت الذي يوافق فيه العميل على خطة العلاج الخاصة بطلب الحالة
      ("<span class="bold_text">الحالة المعتمدة</span>") ("<span class="bold_text">تاريخ الاعتماد</span>").
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.2. </span>تخضع جميع الحالات المعتمدة للقبول النهائي من قبل إيون دنتال
     إما خطيا عن طريق إرسال بريد إلكتروني بالتأكيد إلى العميل أو ضمنيًا مع مرور
     الوقت وشحن الحالة، ويحق  لإيون دنتال وفقًا لتقديرها الوحيد والمطلق،
     ودون أي مسؤولية، رفض أي طلب حالة أو حالة مُوافق عليها.  
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.3. </span> تقع على عاتق العميل مسؤولية توفير جميع السجلات ذات الصلة لطلب
    الحالة لكل مريض ("<span class="bold_text">المريض</span>") بما في ذلك اسم المريض والبيانات الشخصية
    الأخرى، والطبعات الملموسو أو الرقمية للقوسين العلوي والسفلي للأسنان،
    والأشعة السينية، والصور (بما في ذلك صور الوجه الكامل والابتسامة
    والإطباق العلوي والسفلي والشدق الأيمن والأيسر)
    التي تمثل بدقة الحالة الحالية لأسنان المريض بالإضافة إلى الوصفة الطبية ("<span class="bold_text">بيانات المريض</span>"). 
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.4. </span> يجب على العميل إرسال بيانات المريض الضرورية فقط لإيون دنتال
     من أجل تنفيذ التزاماتها بموجب هذه الاتفاقية، ويجب على العميل
      التأكد من قيام كل مريض بالتوقيع على استمارة معلومات وموافقة المريض 
      ("<span class="bold_text">استمارة الموافقة</span>") المتاحة على إيون أكسيس والاحتفاظ بنسخة من استمارة الموافقة لسجلاته الخاصة.</div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.5. </span>يتحمل العميل المسؤولية الكاملة والحصرية فيما يتعلق بما يلي (1)
     دقة بيانات المريض ، (2) إدارة توقعات المريض بشأن توقيت العلاج،
     لا سيما عندما يكون سبب التأخير هو العميل أو المريض نفسه او عدم قيامه بدوره.</div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.6. </span> ستقوم إيون دنتال بتقديم خطة علاج المريض في غضون خمسة (5)
     أيام من استلام بيانات المريض بالشكل والجودة اللذين تقبلهما إيون دنتال وفقًا لتقديرها المطلق والوحيد. </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.7. </span> سيصبح طلب الحالة قيد المعالجة إما عند قيام إيون دنتال بجمع
     (حيثما ينطبق ذلك) طبعة الأسنان الملموسة أو اصدار خطة العلاج وفقًا للمادة 3-6 أيهما أسبق 
     ("<span class="bold_text">حالة قيد المعالجة</span>"). </div>

    <div class="normal_body_text">
    <span class="bold_text"> 3.8. </span> مع مراعاة الشروط والأحكام الموضحة في المادة 7، سيتم تصنيع
     المنتجات وإرسالها من موقع التصنيع فقط للحالات المعتمدة. </div>

    <div class="bold_text cpt2 cpb1"> 4. المنتجات والأسعار وفترة الدفع  </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.1. </span> يجوز للعميل اختيار إحدى الحزم أدناه 
    (يُشار إلى كل منها باسم "<span class="bold_text">حزمة العلاج</span>")
     عند تقديم طلب الحالة:</div>
    ${getPrices(country)}

      <div class="normal_body_text">
    <span class="bold_text"> 4.2. </span>يجب أن يسدد العميل جميع الفواتير بالكامل، بما في ذلك فواتير الإلغاء،في غضون ثلاثين (30) يومًا من تاريخ الفاتورة.</div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.3. </span> خطوة المصفف هي، في حالة حزمة علاج القوس المزدوج، زوج من قوس أسنان علوي واحد
     (1) وقوس أسنان سفلي واحد (1) أو، في حالة حزمة علاج القوس الفردي، إما قوس أسنان علوي واحد (1) أو قوس أسنان سفلي واحد (1).  </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.4. </span> يجب على العميل التأكد من أن عدد الخطوات المطلوبة طبيًا لتحريك الأسنان
     من وضعها الأولي إلى وضعها النهائي في خطة العلاج الأولية لا تتجاوز عدد خطوات المصفف في حزمة العلاج المختارة.
      قد تقترح إيون دنتال، وفقًا لتقديرها المطلق والوحيد، خطة علاج أولية تتوافق بشكل
      أفضل مع عدد خطوات المصفف المطلوبة طبيًا عن تلك الموجودة في حزمة العلاج المختارة. </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.5. </span> التحسينات هي خطوات مصفف إضافية تستند إلى خطة علاج جديدة والتي وفقًا لتقدير
     إيون دنتال المطلق والوحيد لا تغير هدف العلاج الأولي لوضع الأسنان النهائي. يجب ألا يتجاوز العدد
      التراكمي لخطوات المصفف في أي تحسين تلك الموضوعة في منتج إيون بيسك أو إيون بلس عند خطة العلاج الأولية.  

    <div class="cpt1">وفقًا لتقدير إيون دنتال المطلق والوحيد، إذا غيّرت خطة العلاج الجديدة
     الخاصة بالتحسين هدف العلاج لحركة الأسنان النهائية عن الهدف الموضوع في خطة العلاج الأولية،
      فإن إيون دنتال تحتفظ بالحق في ترقية حزمة العلاج المعتمدة في البداية بشكل تلقائي. 
    </div>
    </div>


    <div class="normal_body_text">
    <span class="bold_text"> 4.6. </span> مجموعة مثبتات الأسنان هي، في حالة حزمة علاج القوس المزدوج، زوجًا واحدًا (1)
     من مثبتات قوس الأسنان العلوية وزوجًا واحدًا (1) من مثبتات قوس الأسنان السفلية، أو،
      في حالة حزمة علاج القوس الفردي، إما مثبت قوس أسنان علوي واحد (1) أو مثبت قوس أسنان سفلي واحد (1). </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.7. </span> يخضع إيون برو لـ "سياسة الاستخدام العادل" ويجوز لإيون دنتال اضافة رسوم
     على خطوات المصفف الإضافية وخطط العلاج التي ترى (وفقًا لتقديرها المطلق والوحيد)
      أنها مفرطة فيما يتعلق بالكمية أو الوقت المستغرق بين خطوات المصفف تلك وخطط العلاج.  </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.8. </span> تاريخ انتهاء الصلاحية هو عدد الأشهر منذ تاريخ شحن أول خطوات مصفف.</div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.9. </span> بالنسبة لكل حزمة علاج، تصبح الحالة المعتمدة مكتملة 
    ("<span class="bold_text">الحالة المكتملة</span>")
    عند أي من الحالات التالية أيهما أسبق (1) تحديد طبيب إيون بأنها مكتملة، 
    أو (2) شحن كافة مجموعة (مجموعات) مثبتات الأسنان، أو (3) تاريخ انتهاء الصلاحية المُطبق. </div>

    <div class="normal_body_text">
    <span class="bold_text"> 4.10. </span> لا يمكن طلب خطوات مصفف إضافية بشكل فردي للحالات المكتملة.
    وإذا كانت خطوات المصفف الإضافية مطلوبة لحالة مكتملة،
    فيمكن طلب تمديد الحالة وفقًا لإحدى حزم العلاج الموضحة أعلاه.
    </div>



    <div class="bold_text cpt2 cpb1"> 5. التزامات إيون دنتال </div>

    <div class="normal_body_text">
    <span class="bold_text"> 5.1. </span> تضمن إيون دنتال أن أي من وجميع المنتجات ليست تالفة أو مثنية أو مكسورة،
     وأنها متوافقة مع خطة العلاج وخالية من عيوب المواد والتصنيع.</div>

    <div class="normal_body_text">
    <span class="bold_text"> 5.2. </span> ينتهي الضمان المحدود المذكور في المادة 5-1 بعد تسعين (90) يومًا من تسليم المنتجات وفقًا للمادة
     7، مع العلم أن أي تأخير بسبب تسليم العميل المنتجات إلى المريض أو بسبب الجدول
     الزمني لاستخدام المريض للمصففات بعد تسليم المنتجات إلى العميل لن تؤدي إلى تمديد فترة الضمان المحدود.
    </div>


    <div class="normal_body_text">
    <span class="bold_text"> 5.3. </span> لن تكون إيون دنتال مسؤولة عن:
    </div>

    
    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.1. </span> النتائج الطبية لأي علاج يستخدم المنتجات أو الخدمات؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.2. </span> عدم اتباع التعليمات أو الإهمال أو سوء الاستخدام أو سوء التعامل
     أو التعامل غير السليم مع المنتجات والخدمات من قبل العميل أو المريض أو أي طرف ثالث؛ 
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.3. </span> المنتجات أو الخدمات التي تم تغييرها بأي شكل من
     الأشكال من قبل أي طرف آخر غير إيون دنتال؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.4. </span> المنتجات أو الخدمات التي تم استخدامها
     مع أي منتج أو خدمات أخرى مُقدمة من أي طرف آخر غير إيون دنتال؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 5.3.5. </span> الأخطاء في بيانات المريض المقدمة.
    </div>


    <div class="normal_body_text ">
    <span class="bold_text"> 5.4. </span>باستثناء الضمان الوحيد والمحدود الموضح في المادة 5-1، 
    فإن إيون دنتال تخلي مسؤوليتها عن أي وجميع الضمانات الأخرى من أي نوع،
     سواء صريحة أو ضمنية، بما في ذلك ضمانات قابلية الاستخدام السوقي والملاءمة لغرض معين. لتجنب الشك،
      لا يُمنح هذا الضمان الوحيد والمحدود إلا للعميل فقط وليس لأي طرف ثالث بما في ذلك أي مريض.
    </div>

    
    <div class="normal_body_text ">
    <span class="bold_text"> 5.5. </span> إذا فشل أي منتج أو خدمة في أن يتوافق مع الضمان الوحيد والمحدود الموضح 
    في 5-1، فإن المسؤولية الوحيدة لإيون دنتال ستكون إما، وفقًا لتقديرها الوحيد والمطلق (1)
     استبدال المنتج غير المطابق أو (2) إعادة قيمة تكلفة المنتج أو الخدمة إلى حساب العميل. </div>



    <div class="bold_text cpt2 cpb1"> 6. التزامات العميل  </div>

    <div class="normal_body_text ">
    <span class="bold_text"> 6.1. </span> يضمن العميل ويقر أنه: </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.1. </span> مُرخص له لممارسة طب الأسنان أو تقويم الأسنان في الموقع
     الذي سيتم شحن المنتجات إليه؛ 
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.2. </span> يمتثل للقوانين والأنظمة ومعايير الرعاية المُطبقة في الموقع
     الذي سيتم شحن المنتجات إليه؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.3. </span> لديه ما يلزم من تدريب ومهارة وخبرة لعلاج المرضى
     الذين يستخدمون المنتجات والخدمات؛ 
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.4. </span> ينوي استخدام المنتجات والخدمات فقط وفقًا لدليل الأطباء (المتوفر على إيون أكسيس)؛ 
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.1.5. </span> لا يشتري المنتجات أو الخدمات بقصد استخدامها من قبل أي طرف آخر غير 
    العميل ومرضى العميل، أو خارج البلد الذي سيتم شحن المنتجات إليه بواسطة إيون دنتال.
    </div>


    <div class="normal_body_text ">
    <span class="bold_text"> 6.2. </span>  يجب على العميل:  </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.1. </span> عدم خرق القوانين أو الأنظمة أو القواعد المهنية المُطبقة، أو فقدان اعتماده
     (بما في ذلك سحب تسجيله المهني أو تقييده) لممارسة طب الأسنان أو تقويم الأسنان، أو (حيثما ينطبق ذلك) العمل كعيادة أسنان؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.2. </span> أن يكون مسؤولاً بمفرده وبشكل حصري عن علاج المرضى،
     وممارسة الاجتهاد الطبي في مدى ملاءمة استخدام المنتجات والخدمات
     (بما في ذلك خطة العلاج)، ونتائج القرارات الطبية للعميل، والحفاظ على بيانات المرضى؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.3. </span> أن يشرح بشكل صحيح عن المنتجات ومخاطرها الطبية للمرضى قبل بدء العلاج،
     والإفصاح لكل مريض أنه يمكن للعميل إرسال بيانات المريض 
    إلى إيون دنتال لتسهيل علاجهم وفقًا لتقدير العميل، وضمان توقيع كل مريض على استمارة الموافقة والاحتفاظ بنسخة منها لسجلاته الخاصة؛
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.4. </span> عدم شحن المنتجات خارج الدولة التي تم شحن المنتجات إليها بواسطة إيون دنتال؛
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.5. </span> عدم السماح لأي طرف آخر باستخدام المنتجات أو الخدمات،
     واستخدامها فقط للمريض الذي تم شحن أو توفير هذه المنتجات أو الخدمات له؛ و 
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.2.6. </span> دفع جميع المبالغ المستحقة لإيون دنتال عند استحقاقها وفقًا للمادة 10.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 6.3. </span> يقر العميل ويدرك: 
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.1. </span>إيون دنتال هي مصنعة للمستلزمات الطبية، ولا تقدم خدمات أو مشورة طبية
     أو طب أسنان أو أي رعاية صحية وليست مرخصة للقيام بذلك في أي  ولاية قضائية؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.2. </span>إيون أكسيس عبارة عن منصة الكترونية لإدارة الحالات تعرض التحركات المتوقعة للأسنان،مع إمكانية اختلاف النتائج الفعلية. تتضمن بعض المنتجات والخدمات ميزات مفترضة أو مُحددة مسبقًا
     أو اختيارية ويجب فقط اعتبارها كتوصية مع بقاء القرار النهائي بشكل منفرد وحصري للموافقة على خطة العلاج وتطبيقه،
     أو تغييرها إلى أوضاع محددة لكل مريض، أو رفضها (تخضع لرسوم إلغاء) مع العميل؛ 
    </div>


    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.3. </span> يتعين على العميل قراءة وفهم دليل الأطباء
     وأي تحديثات تطرأ عليه بما في ذلك جميع عوامل الخطر المذكورة فيه؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.4. </span> لا يجوز استخدام تفاصيل طبيب إيون المُستخدمة لتسجيل الدخول
     إلى إيون أكسيس إلا من قبل طبيب إيون واحد فقط من
     أجل علاج المرضى تحت رعايته وإشرافه مباشرة. يُحظر تمامًا استخدام تفاصيل تسجيل الدخول الخاصة بطبيب
     إيون من قبل أي طرف آخر إلا (1) من قبل موظفي الدعم التابعين له من أجل علاج
      المرضى تحت رعايته وإشرافه مباشرة؛ أو (2) بإذن كتابي صريح من إيون دنتال؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.5. </span> أن أي مشاركة لتفاصيل تسجيل دخول طبيب إيون مع
     أي طرف آخر بخلاف ما هو مُحدد في المادة 6-3-4 
    قد ينتهك حقوق خصوصية المريض. يجب إصدار تفاصيل تسجيل دخول منفصلة للأطباء العاملين في نفس العيادة؛
     و
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.3.6. </span> طلب الحالة يُعتبر بمثابة طلب شراء رسمي يطلب فيه
     من إيون دنتال اصدار خطة العلاج وتصنيع المنتجات
     (حيثما ينطبق ذلك)، وتصبح بعدها، 
     الشروط والأحكام المتعلقة بالإلغاء المنصوص عليها في المادة 8 (إذا كان ذلك مسموحًا) مطبقة.
    </div>

    <div class="normal_body_text ">
    <span class="bold_text"> 6.4. </span> دون الإخلال بأي  تعويضات قانونية
     أخرى متاحة لإيون دنتال، إذا لم يتم الامتثال لأحكام البندين 6-1  
     و 6-2 بأي شكل من الأشكال طوال مدة هذه الاتفاقية، يجوز لإيون دنتال وفقًا لتقديرها الوحيد
     والمطلق:
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.1. </span> تعليق أداء أي من التزاماتها بموجب هذه الاتفاقية؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.2. </span> تقييد أو إنهاء امكانية تسجيل الدخول إلى إيون أكسيس من قبل العميل؛  
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.3. </span>التوقف عن تقديم الخدمات أو المنتجات؛ 
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.4. </span> إلغاء وسحب الإذن باستخدام علامات إيون التجارية وفقًا للمادة 9-4؛
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 6.4.5. </span> إنهاء هذه الاتفاقية وفقًا للمادة 14 وفي هذه الحالة ستُطبق أحكام المادة 15.
    </div>



    <div class="bold_text cpt2 cpb1"> 7. الشحن والتسليم </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.1. </span> سترسل إيون دنتال المنتجات وتشحنها في غضون أربعة عشر (14)
     يوم عمل من تاريخ الاعتماد ووفقًا لعدد الشحنات الخاصة بكل منتج كما هو موضح في المادة 4. 
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.2. </span> أية فترات زمنية مُشار إليها تعتبر غير مُلزمة وهي مجرد إشارة إلى عملية
     الطلب والتسليم. لن تكون إيون دنتال مسؤولة عن أي التزام
      أو خسائر أو أضرار قد يتكبدها العميل نتيجة لأي تأخير في شحن المنتجات وتسليمها.
    </div>


    <div class="normal_body_text">
    <span class="bold_text"> 7.3. </span>إذا لم يتم تسليم المنتجات، فسيقوم العميل بإبلاغ
     إيون دنتال والتي ستحدد بدورها سبب التأخير، وإذا كانت إيون دنتال ملتزمة بالتسليم وفقًا لهذه الاتفاقية، فإن إيون دنتال ستقوم بتصنيع
      وشحن منتجات بديلة، وسيكون هذا هو التعويض الوحيد للعميل فيما يتعلق بالمنتجات غير المُسلمة.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.4. </span> لا يجوز لأي تأخير في تسليم المنتجات أن يعفي العميل
     من التزاماته المتعلقة بقبول ودفع سعر المنتجات. 
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.5. </span>إذا لم يقبل العميل تسليم المنتجات في غضون أربعة (4) أيام من إرسال إشعار يفيد
     بأن المنتجات جاهزة للتسليم من إيون دنتال أو الطرف الذي يوصّل المنتجات، فإن تسليم المنتجات يُعتبر قد تم في
     اليوم الخامس بعد هذا الإشعار. ستفرض إيون دنتال رسوم على تكاليف إعادة التسليم والتخزين،
      بما في ذلك التأمين، ولكن لن يكون لديها أي التزام بإعادة تسليم المنتجات بعد سبعة
     (7) أيام من إرسال إشعار إلى العميل بأن المنتجات جاهزة للتسليم.  
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 7.6. </span> سيتم شحن 
    المنتجات شاملة التكاليف والتأمين و الشحن (CIF) إلى عنوان التسليم الموضح في إيون أكسيس، حيث تنتقل ملكية ومخاطر
     جميع المنتجات
    إلى العميل عند تسليم ايون دنتال الى أول طرف ينقل المنتجات. لتجنب الشك، ستقوم إيون دنتال بتسليم
    المنتجات إلى الطرف الذي سيوصل المنتجات وستسدد
    إيون دنتال كافة تكاليف التوصيل والشحن إلى عنوان تسليم العميل المذكور في إيون أكسيس.
    </div>



    <div class="bold_text cpt2 cpb1"> 8. الإلغاء والإنهاء   </div>

    <div class="normal_body_text">
    <span class="bold_text"> 8.1. </span> يجوز لإيون دنتال إلغاء أية حالة معتمدة في أي وقت وفقًا لتقديرها 
    المطلق والوحيد ودون تحملها أي مسؤولية.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 8.2. </span> ستفرض إيون دنتال رسوم إلغاء وفقًا للمادة 
    10 بحال (1) ألغى العميل حالة قيد المعالجة أو (2) لم تصبح حالة قيد
     المعالجة حالة معتمدة في غضون مائة وثمانون (180)
      يومًا من تاريخ طلب الحالة سواء بسبب نقصان في بيانات المريض أو لأي سبب آخر.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 8.3. </span>  تُعد جميع المبيعات نهائية، ولذلك إذا ألغى العميل حالة معتمدة لأي سبب من
     الأسباب، فلن تدفع أو تعيد إيون دنتال أي جزء من الرسوم المتعلقة بتلك الحالة المعتمدة،
      وأي مدفوعات ذات صلة يجب أن تبقى مستحقة وواجبة الأداء لإيون دنتال. 
    </div>

    <div class="bold_text cpt2 cpb1"> 9. حقوق الملكية الفكرية والعلامات التجارية   </div>

    <div class="normal_body_text">
    <span class="bold_text"> 9.1. </span> يوافق العميل على أن تحتفظ إيون دنتال وستظل دائمًا محتفظة
     وحدها وحصريا بالحق والملكية والمنفعة في ملكية جميع حقوق 
     الملكية الفكرية المتعلقة  بإيون دنتال وإيون أكسيس والمنتجات والخدمات.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 9.2. </span> <span class="bold_text">  "حقوق الملكية الفكرية":  </span>
    تعني جميع حقوق الملكية الفكرية والملكية الصناعية وحقوق الملكية والأصول،
     وجميع الحقوق والمنافع والحمايات المرتبطة أو المشابهة أو المطلوبة لممارسة أي مما سبق ذكره، كيفما تنشأ 
     وفقًا لقانون أي ولاية قضائية في جميع أنحاء العالم، سواء كانت مسجلة أو غير مسجلة، بما في ذلك أي 
     وجميع (أ) براءات الاختراع وطلبات براءات الاختراع والإفصاحات عن براءات الاختراع والاختراعات وجميع
      التحسينات عليها (سواء أكانت قابلة للحماية بموجب براءة اختراع أو تم تقليصها إلى الممارسة)، 
     وجميع عمليات إعادة الإصدار والتجديدات والاستمرارية والاستمرارية الجزئية والمراجعات والتشعب 
     والتمديدات وإعادة الفحص فيما يتعلق بها، (ب) العلامات التجارية وعلامات الخدمة والشعارات
      والمظهر التجاري والأنماط التجارية وأسماء الشركات وأسماء الأعمال (بما في ذلك الوهمية)
      والأسماء التجارية وغيرها من علامات المصدر وجميع التسجيلات والطلبات والتجديدات المتعلقة بها 
     (جنبًا إلى جنب مع الشهرة المرتبطة بها) ("<span class="bold_text">العلامات التجارية</span>")، (ج) حقوق النشر وجميع أعمال التأليف 
     (سواء كانت محمية بحقوق الطبع والنشر أم لا)، وجميع التسجيلات والطلبات والإضافات والتعديلات والتجديدات المتعلقة بها، بغض النظر عن
      وسائل التعديل أو التعبير ("<span class="bold_text"> حقوق النشر</span>")، (د) البرمجيات والبرمجيات الثابتة، بما في ذلك ملفات البيانات وشفرة المصدر  
     وشفرة الوسيط وواجهات برمجة التطبيقات والهيكل والملفات والسجلات والمخططات وقواعد البيانات المحوسبة وغيرها من
      المواصفات والوثائق الأخرى ذات الصلة ("<span class="bold_text"> البرمجيات وقواعد البيانات</span>")، (هـ) أسماء نطاقات الإنترنت ومحددات مواقع
      المعلومات ("<span class="bold_text"> أسماء النطاقات</span>")، (و) الأسرار التجارية، (ز) المعرفة الفنية والتقنيات وقواعد
      البيانات والعمليات والمفاهيم والبيانات والاكتشافات والنماذج ومعلومات البحث والتطوير والتقنيات والبروتوكولات 
     والأساليب والصيغ والخوارزميات والمخططات  والتصاميم والمواصفات والمعلومات السرية.
    </div>


    <div class="normal_body_text">
    <span class="bold_text"> 9.3. </span>  لا يُقصد من هذه الاتفاقية نقل أي من حقوق الملكية الفكرية أو حقوق الملكية الأخرى.
    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 9.4. </span> توافق إيون دنتال على السماح للعميل باستخدام علامات إيون التجارية
     مجانًا لغرض وحيد ومحدود يتمثل في 
     الترويج لأعمال العميل واستخدام المنتجات في أعمال العميل ("<span class="bold_text">ترويج العميل</span>") فقط إذا كان العميل:</div>

    
    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.1. </span> مستمر في الامتثال للالتزامات المنصوص عليها في البندين 6-1 و 6-2؛ </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.2. </span>  يستخدم علامات إيون التجارية على النحو المنصوص عليه من قبل إيون دنتال،
     مع عدم إجراء أي تغيير على علامات إيون التجارية بأي شكل من الأشكال دون الحصول على إذن من إيون دنتال؛ </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.3. </span>لا يستخدم علامات إيون التجارية لأي غرض بخلاف ترويج العميل وأن هذا 
    الاستخدام متوافق مع أعلى المعايير المهنية 
    وليس لأي غرض أو طريقة أخرى قد تعتبرها  إيون دنتال غير لائقة أو مناسبة، وفقًا لتقديرها المطلق والوحيد؛ </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.4. </span>يضمن أن الترويج للعميل باستخدام علامات 
    إيون التجارية يتوافق مع القوانين والأنظمة والقواعد المهنية المُطبقة؛</div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.5. </span>لا يسمح باستخدام علامات إيون التجارية من قبل أي طرف ثالث دون
     الحصول على موافقة كتابية مسبقة من  إيون دنتال، 
     وان استخدامها سيُعتبر بمثابة انتهاك لموافقة استخدام علامات إيون التجارية؛ </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.6. </span>يوافق ويقر بأن  إيون دنتال لها الحق في إلغاء وإنهاء
     هذه الموافقة في أي وقت لأي سبب أو بدون سبب على الإطلاق بموجب إشعار كتابي مدته خمسة (5) أيام،
      ويوافق العميل على أنه عند تلقي هذا الإشعار، يجب أن يتوقف
       عن توزيع وأن يتلف أي مواد تحتوي على علامات إيون التجارية؛ و</div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text"> 9.4.7. </span>يوافق ويقر بأنه لا يحق للعميل استرداد أية تكاليف أو رسوم 
    أو تعويضات أو مبالغ أخرى من  إيون دنتال لأي مطالبة تتعلق بهذه الموافقة أو ناشئة عنها.</div>


    <div class="bold_text cpt2 cpb1"> 10. الأسعار والمدفوعات    </div>

    <div class="normal_body_text">
    <span class="bold_text"> 10.1. </span>يجب أن تكون أسعار المنتجات والخدمات وفقًا لقائمة الأسعار المنشورة بواسطة  
    إيون دنتال أو (حسب الحالة) موزعها المعتمد في تاريخ الاعتماد، وتحتفظ  إيون دنتال،
     أو بائعها المعتمد (حسب الحالة) بالحق في تحديث هذه الأسعار من وقت لآخر دون إشعار مسبق.
    </div>

   <div class="normal_body_text">
   <span class="bold_text"> 10.2. </span>قد تقدم إيون دنتال بعض المزايا والخصومات للعملاء
    بموجب خطة ولاء إيون الاينر. تتوفر تفاصيل هذه المزايا لدى موظف إيون دنتال المعني أو الموزع المعتمد. 
   </div>

  <div class="normal_body_text">
  <span class="bold_text"> 10.3. </span> لا تشمل أسعار المنتجات والخدمات ضريبة المبيعات أو ضريبة القيمة المضافة
   أو ضريبة الاستهلاك أو الضريبة المقتطعة أو أي رسوم أخرى مماثلة تفرضها أي سلطة حكومية، ولكنها تشمل الشحن والتأمين. 
  </div>


   <div class="normal_body_text">
   <span class="bold_text"> 10.4. </span> يتحمل العميل مسؤولية سداد الفواتير، وسيكون طبيب إيون والعيادة 
   إذا تم ذكرها في الفاتورة (الفواتير) الصادرة عن إيون دنتال أو تقوم بدفعها على سبيل الممارسة
    العملية مسؤولان بالتضامن والتكافل عن سداد هذه الفواتير. ولذلك يجوز لإيون دنتال
    المطالبة بدفع أي مبالغ مستحقة على طبيب إيون أو العيادة بموجب أي اتفاقية.
   </div>



  <div class="normal_body_text">
  <span class="bold_text"> 10.5. </span> يجب دفع المبالغ المستحقة من العميل إلى  إيون دنتال بموجب هذه الاتفاقية
   بالكامل دون مقاصة أو خصم أو اقتطاع أية مبالغ لأي سبب من الأسباب. إذا كان القانون
    يقتضي إجراء مثل هذه الخصومات أو الاقتطاعات، فيجب على العميل زيادة هذه المبالغ المستحقة الدفع لضمان حصول  
   إيون دنتال على نفس المبلغ الذي كانت ستحصل عليه لو لم يتم خصم أو اقتطاع هذه المبالغ كنتيجة للدفع. يتحمل 
   العميل مسؤولية دفع جميع الرسوم التي تفرضها جهات أخرى (بما في ذلك رسوم الحوالات المُرسلة 
    أو رسوم الصرف الأجنبي التي تفرضها البنوك أو المؤسسات المالية المستخدمة من قبل العميل).
  </div>


  <div class="normal_body_text">
  <span class="bold_text"> 10.6. </span> سيدفع العميل جميع مبالغ فواتير  إيون دنتال بالكامل كما هو محدد في الفاتورة
   وفي غضون الفترة المحددة في المادة 4،
   أو على النحو المتفق عليه مع  إيون دنتال بموجب خطة دفع يتم التفاوض عليها بشكل منفصل. 
  </div>


   <div class="normal_body_text">
   <span class="bold_text"> 10.7. </span> يجوز لإيون دنتال وفقًا لتقديرها الوحيد والمطلق تحديد سقف ائتماني للمبالغ المستحقة على 
   عميل معين لإيون دنتال. في مثل هذه الحالات، إذا قدم العميل طلب حالة جديد، فقد يُطلب من العميل
    (1) تسوية جزء من المبالغ المستحقة لإيون دنتال في وقت أبكر مما هو منصوص عليه في البند 10-6 
   من هذه الاتفاقية، أو (2) إجراء دفعة مسبقة مقابل طلبات الحالة الجديدة هذه.
   </div>


   <div class="normal_body_text">
   <span class="bold_text"> 10.8. </span>في حالة التأخير في سداد أي مبلغ مستحق تم إرسال فاتورة
    بشأنه بشكل صحيح من قبل العميل إلى  إيون دنتال بموجب هذه الاتفاقية: (1) يُسمح لإيون دنتال بممارسة
    حقوقها وسبل الانتصاف المتاحة بموجب البند 6-4؛ و (2) يمكن فرض فائدة على العميل تلقائيًا
    وبدون أي إشعار بمعدل شهري قدره واحد بالمائة (1٪) بالإضافة إلى جميع
   التكاليف والرسوم (بما في ذلك أتعاب المحاماة المعقولة) التي تكبدتهاإيون دنتال من أجل تحصيل هذه المبالغ المتأخرة. 
   </div>


   <div class="bold_text cpt2 cpb1"> 11.التعويض </div>

   <div class="normal_body_text">
   <span class="bold_text"> 11.1. </span> يجب على العميل تعويض والدفاع ودرء الضرر عن  إيون دنتال من أي وجميع التكاليف 
   أو الخسائر أو النفقات أو الالتزامات المباشرة (بما في ذلك أتعاب المحاماة) التي تكبدتها 
    إيون دنتال فيما يتعلق أو
     الناتجة عن: (1) استخدام المنتجات أو الخدمات المباعة بموجب هذه الاتفاقية، (2) تزوير العميل أو خرقه للضمان، أو أي خرق 
    أو انتهاك جوهري من قبل العميل لأي من بنود أو شروط هذه الاتفاقية، (3) خرق جوهري لأي من بنود أو شروط أي اتفاقية بين
    العميل وأي مريض أو أي أفعال أو إخفاقات فيما يتعلق بالمريض، (4) تقديم العميل لمعلومات غير صحيحة أو غير كاملة إلى  
   إيون دنتال، بما في ذلك بيانات المريض أو عدم تزويد  إيون دنتال في الوقت المناسب بأي معلومات تطلبها من العميل؛ 
   و (5) أي وجميع التعاملات مع الجهات التنظيمية الوطنية أو هيئات الترخيص أو الهيئات المهنية فيما يتعلق بالعميل. 
   </div>



   <div class="bold_text cpt2 cpb1"> 12.حدود المسؤولية</div>

   <div class="normal_body_text">
   <span class="bold_text"> 12.1. </span> إلى أقصى حد يسمح به القانون المُطبق، لن يكون تحت أي ظرف أو نظرية قانونية 
   (سواء في المسؤولية 
    التقصيرية (بما في ذلك الإهمال) أو مخالفة العقد أو غير ذلك)، أي طرف مسؤولاً تجاه الطرف الآخر عن أي أضرار غير مباشرة أو عرضية أو تأديبية أو خاصة أو عقابية
    أو مبنية على وعود أو معززة أو تبعية (بما في ذلك على سبيل المثال لا الحصر، خسارة
       الإيرادات أو خسارة الأرباح أو خسارة أو توقف العمل أو فقدان بيانات المريض أو غيرها من البيانات)
        يتكبدها الطرف الآخر بموجب أو نتيجة أو فيما يتعلق بهذه الاتفاقية، حتى لو تم إبلاغ الطرف الآخر بإمكانية
    حدوث مثل هذه الأضرار. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 12.2. </span> لن تكون تحت أي ظرف أو نظرية قانونية (سواء في المسؤولية التقصيرية (بما في ذلك الإهمال) 
   أو مخالفة العقد أو غير ذلك)، إيون دنتال مسؤولة أمام العميل عن أي أضرار مباشرة بمبلغ يتجاوز المبلغ الإجمالي المدفوع لإيون دنتال
    مقابل نفس المنتجات أو الخدمات المباعة والتي يتم المطالبة بخسائر أو أضرار بسببها. </div>
   
    <div class="normal_body_text">

   <div class="bold_text cpt2 cpb1"> 13.خصوصية البيانات </div>
   <div class="normal_body_text">
   <span class="bold_text"> 13.1. </span> يتعهد كل طرف ويقر ويضمن أنه سيمتثل للقوانين والأنظمة المُطبقة فيما يتعلق بجمع
    واستخدام وتخزين وحماية أي بيانات شخصية للعميل والمرضى
     (بما في ذلك بيانات المرضى)، ويقر العميل بقراءة وقبول سياسة خصوصية إيون دينتال المتاحة على الموقع 
    الإلكتروني لإيون دنتال. سيحافظ كل طرف على سرية أي بيانات شخصية وفقًا للقوانين المُطبقة،
     وسيستخدم هذه البيانات الشخصية فقط إلى الحد الذي تسمح به هذه الاتفاقية والقوانين المُطبقة،
     ولن يستخدم أو يفصح عن أي من هذه البيانات الشخصية بأي طريقة قد تشكل انتهاكًا لأي من القوانين المُطبقة. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.2. </span> فيما يتعلق بأي وجميع البيانات الشخصية للمرضى (بما في ذلك بيانات المرضى)،
    فإن العميل هو المتحكم في البيانات وإيون دنتال هي معالج البيانات بموجب القوانين والأنظمة المُطبقة. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.3. </span> تتعهد  إيون دنتال وتقر وتضمن أنها ستستخدم وتعالج أي بيانات شخصية للعملاء
    والمرضى (بما في ذلك بيانات المرضى) فقط لغرض أداء التزاماتها بموجب هذه الاتفاقية. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.4. </span> يوافق العميل على أنه يجوز لإيون دنتال استخدام بيانات المريض لأغراض ترويجية أو تعليمية 
   أو بحثية أو تطويرية أو كمنشورات في المجلات المهنية أو استخدامها في وسائط مهنية، 
   شريطة أن تكون بيانات المريض قد تم إخفاء هويتها أولاً بطريقة لا تسمح بتحديد هوية العميل أو أي مريض.</div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.5. </span> لا يجوز لإيون دنتال، لأي أغراض بخلاف تنفيذ هذه الاتفاقية، الوصول إلى أو استخدام أو تخزين
    او معالجة البيانات الشخصية للعملاء
     والمرضى (بما في ذلك بيانات المرضى) لأي غرض خارج نطاق هذه الاتفاقية. توافق  إيون دنتال على حماية أمان وخصوصية
     أي بيانات شخصية للعملاء والمرضى (بما في ذلك بيانات المرضى) والامتثال لقوانين وأنظمة الخصوصية ذات الصلة. </div>

   <div class="normal_body_text">
   <span class="bold_text"> 13.6. </span> يجب على العميل بصفته المتحكم في البيانات، عند تلقي إشعار أو طلب كتابي من أي مريض 
   أو شخص مُعَرّف بإزالة أي من بياناتهم الشخصية (بما في ذلك بيانات المريض)، إخطار  إيون دنتال بهذا الطلب، ويجب على 
    إيون دنتال إزالة هذه البيانات الشخصية، ما لم تتطلب القوانين والأنظمة المُطبقة خلاف ذلك. </div>


   <div class="bold_text cpt2 cpb1"> 14.الإنهاء   </div>

   <div class="normal_body_text">
   <span class="bold_text"> 14.1. </span> مع عدم الإخلال بحقها في المطالبة بالتعويض الكامل عن الأضرار التي تكبدتها
    وفقًا لأحكام هذه الاتفاقية، يجوز لإيون دنتال إنهاء هذه الاتفاقية بأثر فوري في الحالات التالية: </div>

   <div class="normal_body_text custom_padding_start">
   <span class="bold_text "> 14.1.1 </span> إذا انتهك العميل أيًا من بنود وشروط هذه الاتفاقية
    بما في ذلك البندين 6-1 أو 6-2؛  
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text "> 14.1.2 </span> إذا أصبح العميل معسراً أو أُعلن إفلاسه أو خاضعا للتصفية أو لإنهاء الاعمال 
    أو الحراسة القضائية، أو توقف عن سداد ديونه المستحقة أو أعلن عن نيته في القيام بذلك،
     أو كان غير قادر على الوفاء بالتزاماته بموجب هذه الاتفاقية أو أي اتفاقية أخرى؛ 
    </div>

    <div class="normal_body_text custom_padding_start">
    <span class="bold_text "> 14.1.3 </span> إذا رأت إيون دنتال أنه من المحتمل أن يؤثر العميل تأثيرا سلبيا 
    على العلاج المستمر والسليم وبوقته الملائم للمرضى الذين يستخدمون المنتجات والخدمات،
     أو عدم الوفاء بالتزاماته بموجب هذه الاتفاقية أو بمصالح  إيون دنتال على النحو الأمثل.
    </div>

    <div class="normal_body_text">
    <span class="bold_text "> 14.2. </span>  يجوز لإيون دنتال إنهاء هذه الاتفاقية، في أي وقت وبدون سبب،
     بعد تقديم إشعار كتابي مسبق إلى العميل مدته 30 يومًا. 
    </div>

    <div class="bold_text cpt2 cpb1"> 15.النتائج المترتبة على الإنهاء  </div>

    <div class="normal_body_text">
    <span class="bold_text "> 15.1. </span> عند إنهاء أو انتهاء صلاحية هذه الاتفاقية لأي سبب من الأسباب، يجب على  إيون دنتال وفقًا لتقدير
     العميل وعلى نفقته إتلاف جميع بيانات المريض أو إعادتها إلى العميل على الفور 
     ،ومع ذلك لا يُطلب من  إيون دنتال إتلاف نسخ بيانات المريض أو إعادتها إلى العميل، في الحالات التالية
      (أ) عندما تكون موجودة على أنظمة  إيون دنتال الاحتياطية أو أنظمة التعافي من الكوارث أو أنظمة استمرارية الأعمال، 
     (ب) عندما تكون  إيون دنتال ملزمة بالاحتفاظ بها بموجب القوانين أو الأنظمة المُطبقة.
    </div>


    <div class="normal_body_text">
    <span class="bold_text "> 15.2. </span> يتعين على العميل القيام بما يلي فورًا عند إنهاء هذه الاتفاقية لأي سبب من الأسباب:</div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 15.2.1 </span> دفع جميع المبالغ المستحقة وغير المُسددة لإيون دنتال؛ </div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 15.2.2 </span> التوقف عن استخدام علامات إيون التجارية وأي حقوق ملكية فكرية 
     أخرى تتعلق بالمنتجات، وستُعتبر تراخيص استخدام علامات إيون التجارية
      وحقوق الملكية الفكرية الأخرى الممنوحة بموجب هذه الاتفاقية ملغاة على الفور. </div>


     <div class="bold_text cpt2 cpb1"> 16.بنود متنوعة   </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.1. </span> الإشعارات.</div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 16.1.1 </span> يجوز
      تبادل أي معلومات بموجب هذه الاتفاقية أو فيما يتعلق بها عن طريق البريد الإلكتروني أو أي من أنظمة تبادل البيانات إلكترونيًا.
       يجب أن يكون أي إشعار رسمي أو اتصال مكتوبًا ويُعتبر مقدمًا
        وفقًا للبند 16-1-2 إذا تم توجيه هذا الإشعار، إلى  إيون دنتال على <a href="mailto:info@eonaligner.com">info@eonaligner.com</a>
         (مع نسخة من الإشعارات القانونية إلى <a href="mailto:legal@eonaligner.com"> legal@eonaligner.com </a>)،
        وإلى العميل إما على عنوان البريد الإلكتروني أو العنوان
      الفعلي المُقدم من العميل في إيون أكسيس. </div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 16.1.2 </span> سيتم اعتبار أي إشعار مسموح به أو مطلوب تقديمه بموجب هذه الاتفاقية قد تم تقديمه بالفعل
      حسب الأصول عند استلامه إذا تم تقديمه شخصيًا؛ أو عند مرور ثماني وأربعين (48) ساعة أو استلام الإقرار بالاستلام عن طريق البريد الإلكتروني 
      إذا تم إرساله عن طريق البريد الإلكتروني أيهما أسبق؛ أو بعد ثماني وأربعين (48) ساعة من إيداعه في خدمة
       بريد سريع دولية معترف بها وحسنة السمعة (مثل فيديرال إكسبريس أو يو بي إس)، مع ضرورة التوقيع عليه.  </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.2. </span> العلاقة بين الطرفين.
     
     <div class="cpt1"> لا يجوز تفسير أي شيء وارد في هذه الاتفاقية على أنه: (1) ينشئ علاقة عمل، (2) ينشئ علاقة بين الأطراف
      كشركاء أو أصحاب مشاريع مشتركة أو مالكين مشاركين أو غير ذلك من المشاركين في مشروع مشترك، 
      (3) يمنح أحد الطرفين سلطة التوجيه والتحكم في الأنشطة اليومية للطرف الآخر أو الشركات التابعة له؛
       (4) يتسبب في قيام أحد الطرفين بتحمل أي التزام أو مسؤولية فيما يتعلق بالالتزامات التعاقدية للطرف 
      الآخر، باستثناء أن العيادة قد تتحمل التزامات أو مسؤوليات طبيب إيون؛ أو (5) يسمح لأي طرف بإنشاء 
      أي التزام نيابة عن الطرف الآخر لأي غرض كان، باستثناء أنه يجوز لطبيب إيون إنشاء التزام للعيادة.</div>
     </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.3. </span> الاتفاق الكامل والتعديلات.
     
     <div class="cpt1"> تشكل هذه الاتفاقية بما في ذلك المقدمة والتمهيد الاتفاق والتفاهم الكامل بين
      الطرفين فيما يتعلق بموضوع هذه الاتفاقية وتحل محل جميع الخطابات أو الإقرارات أو الضمانات أو الاتفاقيات السابقة، 
      الصريحة أو الضمنية، بين الطرفين فيما يتعلق بموضوع هذه الاتفاقية.</div> 

     <div class="cpt1">يحق لإيون دنتال تغيير أو تعديل هذه الاتفاقية من وقت لآخر، وعندما 
     تُجري إيون دنتال تغييرات أو تعديلات على هذه الاتفاقية، فإن استمرارية دخول العميل إلى واستخدام إيون
      أكسيس والمنتجات والخدمات  سيشكل قبولًا لأي تغيير أو تعديل على الاتفاقية. 
     </div>
     </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.4. </span> الإحالة. 
     
     <div class="cpt1">هذه الاتفاقية شخصية لكل من إيون دنتال والعميل. يجوز لإيون دنتال التنازل كليًا
      أو جزئيًا عن أي من حقوقها أو التزاماتها بموجب هذه الاتفاقية لأي شخص دون الحصول على موافقة كتابية مسبقة من العميل، 
      ولكن لا يجوز للعميل التنازل كليًا أو جزئيًا عن أي من حقوقه أو التزاماته بموجب هذه
       الاتفاقية لأي شخص دون الحصول على موافقة كتابية مسبقة من  إيون دنتال. </div>
     </div>


     <div class="normal_body_text">
     <span class="bold_text "> 16.5. </span> عدم التنازل.

     <div class="cpt1">لا تعتبر إيون دنتال قد تنازلت عن أي حقوق بموجب هذه الاتفاقية
      من خلال عدم ممارسة أو التأخير في ممارسة أي حقوق بموجب الاتفاقية. إذا تنازلت إيون دنتال عن
       أي حق بموجب هذه الاتفاقية، فلن يشكل هذا التنازل تنازلاً عن أي حق آخر أو حق لاحق،
       حتى لو كانت جميع الحقوق المنصوص عليها في هذه الاتفاقية متشابهة في طبيعتها. </div>
     </div>


     <div class="normal_body_text">
     <span class="bold_text "> 16.6. </span> الاستقلالية/ القابلية للفصل.

     <div class="cpt1">إذا كان أي حكم او ميثاق في الاتفاقية غير قابل للنفاذ أو باطل
      أو مخالف لقانون ملزم، فسيكون هذا الحكم أو الميثاق غير 
      فعال فقط في حدود عدم قابليته للنفاذ أو بطلانه أو عدم قانونيته، وسيظل باقي الحكم
       أو الميثاق وأي حكم أو ميثاق اخر في الاتفاقية ساري المفعول وقانوني وقابل للنفاذ. 
     </div>
     </div>

     <div class="normal_body_text">
     <span class="bold_text "> 16.7. </span> القوة القاهرة.

     <div class="cpt1">يجب تعليق هذه الاتفاقية وجميع التزامات إيون دنتال بموجب هذه الاتفاقية ولأي فترة
      ("<span class="bold_text ">فترة التعليق</span>")، ولن تكون إيون دنتال مسؤولة بأي شكل من الأشكال عن الإخفاق أو
       التأخير في تنفيذ التزاماتها بموجب هذه الاتفاقية خلال فترة التعليق، نتيجة 
      أي من أفعال القضاء والقدر والحرب أو الظروف الشبيهة بالحرب (على سبيل المثال،
         الإرهاب أو الغزو أو الشغب أو الاضطرابات المدنية الأخرى) أو الحصار أو
       العقوبات أو عمليات الحظر الدولية أو الحوادث الخطيرة أو الحرائق أو الفيضانات 
      أو الإضرابات أو الإقفال أو الإغلاق (بما في ذلك عمليات الإغلاق بسبب الاوبئة والجوائح)، 
      وانقطاعات النقل التي لا يمكن السيطرة عليها وغير المتوقعة، أو عدم التنفيذ من قبل مقاولي 
       إيون دنتال الفرعيين أو الموردين أو مقدمي الخدمات لالتزاماتهم أو فشل البرمجيات أو الأجهزة 
      الخاصة بتلك الأطراف أو التدخل الحكومي أو التمرد المدني أو الأوبئة أو الجوائح، أو 
      أي أحداث أخرى خارج نطاق السيطرة المعقولة  لإيون دنتال ("<span class="bold_text ">القوة القاهرة</span>")؛ 
      شريطة أن تقوم  إيون دنتال بإشعار العميل على الفور بحدوث القوة القاهرة.
       لتجنب الشك، تظل أي وجميع الالتزامات بدفع الأموال إلى  إيون دنتال سارية خلال
       أي فترة تعليق على الرغم من أي أحداث قوة قاهرة.
     </div>
     </div>


     <div class="normal_body_text">
     <span class="bold_text "> 16.8. </span> القانون الحاكم والاختصاص القضائي.</div>

     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 16.8.1 </span> تخضع وتُفسر هذه الاتفاقية وأي التزامات غير تعاقدية تنشأ عنها أو تتعلق بها وفقًا للقانون البلجيكي. </div>


     <div class="normal_body_text custom_padding_start">
     <span class="bold_text "> 16.8.2 </span> توافق إيون دنتال والعميل على أن أي نزاع أو خلاف أو مطالبة من أي نوع تنشأ عن
      أو متعلقة أو متصلة بهذه الاتفاقية و لا يمكن تسويتها وديًا يجب الفصل فيها بشكل نهائي من قبل محاكم أنتويرب، دائرة أنتويرب.</div>


     <div class="normal_body_text">
     <span class="bold_text "> 16.9. </span>اللغة السائدة.

     
     <div class="cpt1">تمت صياغة هذه الاتفاقية باللغة الانجليزية ومن ثم ترجمت الى اللغة العربية. في حال
      وجود نقص أو تعارض أو تناقض او اختلاف بين اللغتين الانجليزية والعربية في هذه الاتفاقية، فان اللغة الانجليزية
       تسود وتعتبر هي اللغة السائدة والواجبة التطبيق والمرجع في تفسير نية الأطراف وشروط الاتفاقية.</div>
     </div>

    `;
};
