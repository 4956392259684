import CallBack from "../views/authentication/CallBack";

var authRoutes = [
  {
    path: "/call-back",
    name: "Login",
    icon: "mdi mdi-account-key",
    component: CallBack,
  },
  {
    path: "/authentication/login",
    pathTo: "/",
    name: "Login",
    redirect: true,
  },
];
export default authRoutes;
