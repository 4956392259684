import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import Teeth2 from "../Teeth/Teeth2";
import { FillTreatmentGoal } from "../../../../services/fillForm";
import { connect } from "react-redux";
import { setNumberingSystem } from "../../../../redux/newCase/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setNumberingSystem: (payload) => dispatch(setNumberingSystem(payload)),
});
class TreatmentGoals extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = props.getStore();
    this.fix = this.props.fix;
  }
  /* --------------------------------------------------------------------------------*/
  /* fill form with init value  */
  /* --------------------------------------------------------------------------------*/
  FillForm() {
    this.setState(FillTreatmentGoal());

    this.props.updateStore({
      ...this.props.getStore(),
      treat_arches_upper: true,
      treat_arches_lower: false,
      treat_arches_both: false,
      treat_arches: "upper_only",
      midline_upper_maintain: true,
      midline_upper_improve: false,
      midline_upper_center: false,
      upper_midline: "maintain",
      midline_lower_maintain: true,
      midline_lower_improve: false,
      midline_lower_center: false,
      lower_midline: "maintain",
      overjet_maintain: true,
      overjet_improve: false,
      overjet_Ideal: false,
      overjet: "maintain",
      overbite_maintain: true,
      overbite_improve: false,
      overbite_Ideal: false,
      overbite: "maintain",
      arch_form_maintain: true,
      arch_form_improve: false,
      arch_form_constrict: false,
      arch_form: "maintain",
    });
  }
  /* --------------------------------------------------------------------------------*/
  /* tooth numbering system slider  */
  /* --------------------------------------------------------------------------------*/
  slider = (e) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.props.setNumberingSystem("universal");
      } else if (e.target.value === "50") {
        this.props.setNumberingSystem("palmer");
      } else if (e.target.value === "100") {
        this.props.setNumberingSystem("fdi");
      }
    }
  };
  sliderValue = () => {
    if (this.props.newCase.numbering === "universal") {
      return 0;
    } else if (this.props.newCase.numbering === "palmer") {
      return 50;
    } else {
      return 100;
    }
  };

  render() {
    // explicit class assigning based on validation
    let notValidClasses = {};
    // treat arches validation
    if (
      typeof this.state.treat_arches === "undefined" ||
      this.state.treat_arches
    ) {
      notValidClasses.treatArchesCls = "form-control";
    } else {
      notValidClasses.treatArchesCls = "is-invalid form-control";
      notValidClasses.treatArchesValGrpCls = "text-danger";
    }
    // upper midline validation
    if (
      typeof this.state.upper_midline === "undefined" ||
      this.state.upper_midline
    ) {
      notValidClasses.midlineUpperCls = "form-control";
    } else {
      notValidClasses.midlineUpperCls = "is-invalid form-control";
      notValidClasses.midlineUpperValGrpCls = "text-danger";
    }
    // lower midline validation
    if (
      typeof this.state.lower_midline === "undefined" ||
      this.state.lower_midline
    ) {
      notValidClasses.midlineLowerCls = "form-control";
    } else {
      notValidClasses.midlineLowerCls = "is-invalid form-control";
      notValidClasses.midlineLowerValGrpCls = "text-danger";
    }
    // overjet validation
    if (typeof this.state.overjet === "undefined" || this.state.overjet) {
      notValidClasses.overjetCls = "form-control";
    } else {
      notValidClasses.overjetCls = "is-invalid form-control";
      notValidClasses.overjetValGrpCls = "text-danger";
    }
    // overbite validation
    if (typeof this.state.overbite === "undefined" || this.state.overbite) {
      notValidClasses.overbiteCls = "form-control";
    } else {
      notValidClasses.overbiteCls = "is-invalid form-control";
      notValidClasses.overbiteValGrpCls = "text-danger";
    }
    // arch form validation
    if (typeof this.state.arch_form === "undefined" || this.state.arch_form) {
      notValidClasses.archFormCls = "form-control";
    } else {
      notValidClasses.archFormCls = "is-invalid form-control";
      notValidClasses.archFormValGrpCls = "text-danger";
    }
    return (
      <div className="step step2 mt-5 mb-2">
        {/*--------------------------------------------------------------------------------*/}
        {/* Fill Form   */}
        {/*--------------------------------------------------------------------------------*/}
        {/* {!this.fix && (
          <div className="row p-3">
            <button
              type="button"
              id="finish-button"
              className="btn btn-next btn-primary btn-lg pull-left"
              onClick={() => {
                this.FillForm();
              }}
            >
              Fill Form
            </button>
          </div>
        )} */}
        {/*--------------------------------------------------------------------------------*/}
        {/* End Fill Form   */}
        {/*--------------------------------------------------------------------------------*/}
        <div className="row justify-content-md-center">
          <div className="col">
            <div className="">
              <form id="Form2" className="form-horizontal mt-2 mb-2">
                <Row>
                  <Col xs="12" md="4" lg="4">
                    {/* Treat Arches   */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Treat Arches
                      </label>
                      <Row className="">
                        <Col xs="12" md="12" lg="1" className="p-0"></Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.treat_arches_upper
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                treat_arches_upper: true,
                                treat_arches_lower: false,
                                treat_arches_both: false,
                                treat_arches: "upper_only",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                treat_arches_upper: true,
                                treat_arches_lower: false,
                                treat_arches_both: false,
                                treat_arches: "upper_only",
                              });
                            }}
                          >
                            Upper Only
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.treat_arches_lower
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                treat_arches_upper: false,
                                treat_arches_lower: true,
                                treat_arches_both: false,
                                treat_arches: "lower_only",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                treat_arches_upper: false,
                                treat_arches_lower: true,
                                treat_arches_both: false,
                                treat_arches: "lower_only",
                              });
                            }}
                          >
                            Lower Only
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.treat_arches_both
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                treat_arches_upper: false,
                                treat_arches_lower: false,
                                treat_arches_both: true,
                                treat_arches: "both",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                treat_arches_upper: false,
                                treat_arches_lower: false,
                                treat_arches_both: true,
                                treat_arches: "both",
                              });
                            }}
                          >
                            Both
                          </Button>
                        </Col>
                      </Row>
                      {this.props.getStore().treat_arches === "" &&
                        this.props.getStore().validateTab2 && (
                          <div className={notValidClasses.treatArchesValGrpCls}>
                            {"Treat Arches is required"}
                          </div>
                        )}
                    </div>
                    {/* Midline   */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active w-100">
                        <span className="pull-left">Midline</span>
                        <span className="pl-2" style={{ color: "red" }}>
                          *May require IPR
                        </span>{" "}
                      </label>
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          U
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.midline_upper_maintain
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                midline_upper_maintain: true,
                                midline_upper_improve: false,
                                midline_upper_center: false,
                                upper_midline: "maintain",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                midline_upper_maintain: true,
                                midline_upper_improve: false,
                                midline_upper_center: false,
                                upper_midline: "maintain",
                              });
                            }}
                          >
                            Maintain
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.midline_upper_improve
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                midline_upper_maintain: false,
                                midline_upper_improve: true,
                                midline_upper_center: false,
                                upper_midline: "improve",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                midline_upper_maintain: false,
                                midline_upper_improve: true,
                                midline_upper_center: false,
                                upper_midline: "improve",
                              });
                            }}
                          >
                            Improve
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.midline_upper_center
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                midline_upper_maintain: false,
                                midline_upper_improve: false,
                                midline_upper_center: true,
                                upper_midline: "center",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                midline_upper_maintain: false,
                                midline_upper_improve: false,
                                midline_upper_center: true,
                                upper_midline: "center",
                              });
                            }}
                          >
                            Center
                          </Button>
                        </Col>
                      </Row>
                      {this.props.getStore().upper_midline === "" &&
                        this.props.getStore().validateTab2 && (
                          <div
                            className={notValidClasses.midlineUpperValGrpCls}
                          >
                            {"Upper Midline is required"}
                          </div>
                        )}
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          L
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.midline_lower_maintain
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                midline_lower_maintain: true,
                                midline_lower_improve: false,
                                midline_lower_center: false,
                                lower_midline: "maintain",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                midline_lower_maintain: true,
                                midline_lower_improve: false,
                                midline_lower_center: false,
                                lower_midline: "maintain",
                              });
                            }}
                          >
                            Maintain
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.midline_lower_improve
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                midline_lower_maintain: false,
                                midline_lower_improve: true,
                                midline_lower_center: false,
                                lower_midline: "improve",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                midline_lower_maintain: false,
                                midline_lower_improve: true,
                                midline_lower_center: false,
                                lower_midline: "improve",
                              });
                            }}
                          >
                            Improve
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.midline_lower_center
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                midline_lower_maintain: false,
                                midline_lower_improve: false,
                                midline_lower_center: true,
                                lower_midline: "center",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                midline_lower_maintain: false,
                                midline_lower_improve: false,
                                midline_lower_center: true,
                                lower_midline: "center",
                              });
                            }}
                          >
                            Center
                          </Button>
                        </Col>
                      </Row>

                      {this.props.getStore().lower_midline === "" &&
                        this.props.getStore().validateTab2 && (
                          <div
                            className={notValidClasses.midlineLowerValGrpCls}
                          >
                            {"Lower Midline is required"}
                          </div>
                        )}
                    </div>
                    {/* Overjet   */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active w-100">
                        <span className="pull-left">Overjet</span>
                        <span className="pl-2" style={{ color: "red" }}>
                          *May require IPR
                        </span>
                      </label>
                      <Row className="">
                        <Col xs="12" md="12" lg="1" className="p-0"></Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.overjet_maintain
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                overjet_maintain: true,
                                overjet_improve: false,
                                overjet_Ideal: false,
                                overjet: "maintain",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                overjet_maintain: true,
                                overjet_improve: false,
                                overjet_Ideal: false,
                                overjet: "maintain",
                              });
                            }}
                          >
                            Maintain
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.overjet_improve
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                overjet_maintain: false,
                                overjet_improve: true,
                                overjet_Ideal: false,
                                overjet: "improve",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                overjet_maintain: false,
                                overjet_improve: true,
                                overjet_Ideal: false,
                                overjet: "improve",
                              });
                            }}
                          >
                            Improve
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.overjet_Ideal
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                overjet_maintain: false,
                                overjet_improve: false,
                                overjet_Ideal: true,
                                overjet: "ideal",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                overjet_maintain: false,
                                overjet_improve: false,
                                overjet_Ideal: true,
                                overjet: "ideal",
                              });
                            }}
                          >
                            Ideal
                          </Button>
                        </Col>
                      </Row>
                      {this.props.getStore().overjet === "" &&
                        this.props.getStore().validateTab2 && (
                          <div className={notValidClasses.overbiteValGrpCls}>
                            {"Overjet is required"}
                          </div>
                        )}
                    </div>
                    {/* Overbite   */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Overbite
                      </label>
                      <Row className="">
                        <Col xs="12" md="12" lg="1" className="p-0"></Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.overbite_maintain
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                overbite_maintain: true,
                                overbite_improve: false,
                                overbite_Ideal: false,
                                overbite: "maintain",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                overbite_maintain: true,
                                overbite_improve: false,
                                overbite_Ideal: false,
                                overbite: "maintain",
                              });
                            }}
                          >
                            Maintain
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.overbite_improve
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                overbite_maintain: false,
                                overbite_improve: true,
                                overbite_Ideal: false,
                                overbite: "improve",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                overbite_maintain: false,
                                overbite_improve: true,
                                overbite_Ideal: false,
                                overbite: "improve",
                              });
                            }}
                          >
                            Improve
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.overbite_Ideal
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                overbite_maintain: false,
                                overbite_improve: false,
                                overbite_Ideal: true,
                                overbite: "ideal",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                overbite_maintain: false,
                                overbite_improve: false,
                                overbite_Ideal: true,
                                overbite: "ideal",
                              });
                            }}
                          >
                            Ideal
                          </Button>
                        </Col>
                      </Row>
                      {this.props.getStore().overbite === "" &&
                        this.props.getStore().validateTab2 && (
                          <div className={notValidClasses.overbiteValGrpCls}>
                            {"Overbite is required"}
                          </div>
                        )}
                    </div>
                    {/* Arch Form  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Arch Form
                      </label>
                      <Row className="">
                        <Col xs="12" md="12" lg="1" className="p-0"></Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.arch_form_maintain
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                arch_form_maintain: true,
                                arch_form_improve: false,
                                arch_form_constrict: false,
                                arch_form: "maintain",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                arch_form_maintain: true,
                                arch_form_improve: false,
                                arch_form_constrict: false,
                                arch_form: "maintain",
                              });
                            }}
                          >
                            Maintain
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.arch_form_improve
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                arch_form_maintain: false,
                                arch_form_improve: true,
                                arch_form_constrict: false,
                                arch_form: "improve",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                arch_form_maintain: false,
                                arch_form_improve: true,
                                arch_form_constrict: false,
                                arch_form: "improve",
                              });
                            }}
                          >
                            Improve
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.arch_form_constrict
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                arch_form_maintain: false,
                                arch_form_improve: false,
                                arch_form_constrict: true,
                                arch_form: "constrict",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                arch_form_maintain: false,
                                arch_form_improve: false,
                                arch_form_constrict: true,
                                arch_form: "constrict",
                              });
                            }}
                          >
                            Constrict
                          </Button>
                        </Col>
                      </Row>
                      {this.props.getStore().arch_form === "" &&
                        this.props.getStore().validateTab2 && (
                          <div className={notValidClasses.archFormValGrpCls}>
                            {"Arch Form is required"}
                          </div>
                        )}
                    </div>
                    {/* Procline  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Procline
                      </label>
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active "
                        >
                          U
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.procline_upper_primary
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                procline_upper_primary: true,
                                procline_upper_if_needed: false,
                                procline_upper: "primary",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                procline_upper_primary: true,
                                procline_upper_if_needed: false,
                                procline_upper: "primary",
                              });
                            }}
                          >
                            Primary
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.procline_upper_if_needed
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                procline_upper_primary: false,
                                procline_upper_if_needed: true,
                                procline_upper: "if_needed",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                procline_upper_primary: false,
                                procline_upper_if_needed: true,
                                procline_upper: "if_needed",
                              });
                            }}
                          >
                            If needed
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1"></Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          L
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.procline_lower_primary
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                procline_lower_primary: true,
                                procline_lower_if_needed: false,
                                procline_lower: "primary",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                procline_lower_primary: true,
                                procline_lower_if_needed: false,
                                procline_lower: "primary",
                              });
                            }}
                          >
                            Primary
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.procline_lower_if_needed
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                procline_lower_primary: false,
                                procline_lower_if_needed: true,
                                procline_lower: "if_needed",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                procline_lower_primary: false,
                                procline_lower_if_needed: true,
                                procline_lower: "if_needed",
                              });
                            }}
                          >
                            If needed
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1"></Col>
                      </Row>
                    </div>
                    {/* Expand  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Expand
                      </label>
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          U
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.expand_upper_primary
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                expand_upper_primary: true,
                                expand_upper_if_needed: false,
                                expand_upper: "primary",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                expand_upper_primary: true,
                                expand_upper_if_needed: false,
                                expand_upper: "primary",
                              });
                            }}
                          >
                            Primary
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.expand_upper_if_needed
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                expand_upper_primary: false,
                                expand_upper_if_needed: true,
                                expand_upper: "if_needed",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                expand_upper_primary: false,
                                expand_upper_if_needed: true,
                                expand_upper: "if_needed",
                              });
                            }}
                          >
                            If needed
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1"></Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          L
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.expand_lower_primary
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                expand_lower_primary: true,
                                expand_lower_if_needed: false,
                                expand_lower: "primary",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                expand_lower_primary: true,
                                expand_lower_if_needed: false,
                                expand_lower: "primary",
                              });
                            }}
                          >
                            Primary
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.expand_lower_if_needed
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                expand_lower_primary: false,
                                expand_lower_if_needed: true,
                                expand_lower: "if_needed",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                expand_lower_primary: false,
                                expand_lower_if_needed: true,
                                expand_lower: "if_needed",
                              });
                            }}
                          >
                            If needed
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1"></Col>
                      </Row>
                    </div>
                    {/* IPR  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">IPR</label>
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          U
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.ipr_upper_primary
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                ipr_upper_primary: true,
                                ipr_upper_if_needed: false,
                                ipr_upper: "primary",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                ipr_upper_primary: true,
                                ipr_upper_if_needed: false,
                                ipr_upper: "primary",
                              });
                            }}
                          >
                            Primary
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.ipr_upper_if_needed
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                ipr_upper_primary: false,
                                ipr_upper_if_needed: true,
                                ipr_upper: "if_needed",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                ipr_upper_primary: false,
                                ipr_upper_if_needed: true,
                                ipr_upper: "if_needed",
                              });
                            }}
                          >
                            If needed
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1"></Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          L
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.ipr_lower_primary
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                ipr_lower_primary: true,
                                ipr_lower_if_needed: false,
                                ipr_lower: "primary",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                ipr_lower_primary: true,
                                ipr_lower_if_needed: false,
                                ipr_lower: "primary",
                              });
                            }}
                          >
                            Primary
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.ipr_lower_if_needed
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                ipr_lower_primary: false,
                                ipr_lower_if_needed: true,
                                ipr_lower: "if_needed",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                ipr_lower_primary: false,
                                ipr_lower_if_needed: true,
                                ipr_lower: "if_needed",
                              });
                            }}
                          >
                            If needed
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1"></Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs="12" md="1" lg="1"></Col>

                  <Col xs="12" md="7" lg="7">
                    <Row className="justify-content-end">
                      <Col xs="12" md="12" lg="7">
                        {/* tooth numbering system slider */}
                        <div className="tooth-slider form-group content form-block-holder">
                          <input
                            type="range"
                            step="50"
                            onChange={this.slider}
                            value={this.sliderValue()}
                          />
                          <Row className="align-items-center ">
                            <Col xs="4" md="4" lg="4" className="text-left">
                              <label className="title_active">Universal</label>
                            </Col>
                            <Col xs="4" md="4" lg="4" className="text-center">
                              <label className="title_active">Palmer</label>
                            </Col>
                            <Col xs="4" md="4" lg="4" className="text-right">
                              <label className="title_active">FDI</label>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    {/* Extraction */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active ">
                        Extraction
                      </label>
                      <Teeth2
                        action_key="Extraction"
                        tab_name="treatment_goals"
                      />
                    </div>
                    {/* Crossbites */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Crossbites
                      </label>
                      <Teeth2
                        action_key="Crossbites"
                        tab_name="treatment_goals"
                      />
                    </div>
                    {/* Leave space */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Leave space
                      </label>
                      <Teeth2
                        action_key="LeaveSpace"
                        tab_name="treatment_goals"
                      />
                    </div>
                    {/* Canine Relationship */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Canine Relationship
                      </label>
                      <Row>
                        <Col xs="12" md="12" lg="10">
                          {" "}
                          <Row className="align-items-center">
                            <Col
                              xs="12"
                              md="12"
                              lg="1"
                              className="pr-0 text-center title_active"
                            >
                              R
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.canine_r_1
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    canine_r_1: true,
                                    canine_r_2: false,
                                    canine_r_3: false,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    canine_r_1: true,
                                    canine_r_2: false,
                                    canine_r_3: false,
                                  });
                                }}
                              >
                                Class I
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.canine_r_2
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    canine_r_1: false,
                                    canine_r_2: true,
                                    canine_r_3: false,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    canine_r_1: false,
                                    canine_r_2: true,
                                    canine_r_3: false,
                                  });
                                }}
                              >
                                Class II
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.canine_r_3
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    canine_r_1: false,
                                    canine_r_2: false,
                                    canine_r_3: true,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    canine_r_1: false,
                                    canine_r_2: false,
                                    canine_r_3: true,
                                  });
                                }}
                              >
                                Class III
                              </Button>
                            </Col>
                          </Row>
                          <Row className="align-items-center">
                            <Col
                              xs="12"
                              md="12"
                              lg="1"
                              className="pr-0 text-center title_active"
                            >
                              L
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.canine_l_1
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    canine_l_1: true,
                                    canine_l_2: false,
                                    canine_l_3: false,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    canine_l_1: true,
                                    canine_l_2: false,
                                    canine_l_3: false,
                                  });
                                }}
                              >
                                Class I
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.canine_l_2
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    canine_l_1: false,
                                    canine_l_2: true,
                                    canine_l_3: false,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    canine_l_1: false,
                                    canine_l_2: true,
                                    canine_l_3: false,
                                  });
                                }}
                              >
                                Class II
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.canine_l_3
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    canine_l_1: false,
                                    canine_l_2: false,
                                    canine_l_3: true,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    canine_l_1: false,
                                    canine_l_2: false,
                                    canine_l_3: true,
                                  });
                                }}
                              >
                                Class III
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    {/*  Molar Relationship */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Molar Relationship
                      </label>
                      <Row>
                        <Col xs="12" md="12" lg="10">
                          {" "}
                          <Row className="align-items-center">
                            <Col
                              xs="12"
                              md="12"
                              lg="1"
                              className="pr-0 text-center title_active"
                            >
                              R
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.molar_r_1
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    molar_r_1: true,
                                    molar_r_2: false,
                                    molar_r_3: false,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    molar_r_1: true,
                                    molar_r_2: false,
                                    molar_r_3: false,
                                  });
                                }}
                              >
                                Class I
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.molar_r_2
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    molar_r_1: false,
                                    molar_r_2: true,
                                    molar_r_3: false,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    molar_r_1: false,
                                    molar_r_2: true,
                                    molar_r_3: false,
                                  });
                                }}
                              >
                                Class II
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.molar_r_3
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    molar_r_1: false,
                                    molar_r_2: false,
                                    molar_r_3: true,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    molar_r_1: false,
                                    molar_r_2: false,
                                    molar_r_3: true,
                                  });
                                }}
                              >
                                Class III
                              </Button>
                            </Col>
                          </Row>
                          <Row className="align-items-center">
                            <Col
                              xs="12"
                              md="12"
                              lg="1"
                              className="pr-0 text-center title_active"
                            >
                              L
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.molar_l_1
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    molar_l_1: true,
                                    molar_l_2: false,
                                    molar_l_3: false,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    molar_l_1: true,
                                    molar_l_2: false,
                                    molar_l_3: false,
                                  });
                                }}
                              >
                                Class I
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.molar_l_2
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    molar_l_1: false,
                                    molar_l_2: true,
                                    molar_l_3: false,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    molar_l_1: false,
                                    molar_l_2: true,
                                    molar_l_3: false,
                                  });
                                }}
                              >
                                Class II
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.molar_l_3
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    molar_l_1: false,
                                    molar_l_2: false,
                                    molar_l_3: true,
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    molar_l_1: false,
                                    molar_l_2: false,
                                    molar_l_3: true,
                                  });
                                }}
                              >
                                Class III
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    {/*  How to Achieve A-P Goal */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        How to Achieve A-P Goal
                      </label>
                      <Row>
                        <Col xs="12" md="12" lg="10">
                          {" "}
                          <Row className="align-items-center">
                            <Col
                              xs="12"
                              md="12"
                              lg="1"
                              className="pr-0 text-center title_active"
                            >
                              U
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.goal_upper_ipr
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    goal_upper_ipr: !this.state.goal_upper_ipr,
                                    // goal_upper_distalization: false,
                                    // goal_upper_mezialization: false,
                                    how_to_achieve_a_p_goal_upper: "ipr",
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    goal_upper_ipr: !this.state.goal_upper_ipr,
                                    // goal_upper_distalization: false,
                                    // goal_upper_mezialization: false,
                                    how_to_achieve_a_p_goal_upper: "ipr",
                                  });
                                }}
                              >
                                IPR 3-6
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.goal_upper_distalization
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    // goal_upper_ipr: false,
                                    goal_upper_distalization: !this.state
                                      .goal_upper_distalization,
                                    // goal_upper_mezialization: false,
                                    how_to_achieve_a_p_goal_upper:
                                      "distalization",
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    // goal_upper_ipr: false,
                                    goal_upper_distalization: !this.state
                                      .goal_upper_distalization,
                                    // goal_upper_mezialization: false,
                                    how_to_achieve_a_p_goal_upper:
                                      "distalization",
                                  });
                                }}
                              >
                                Distalization
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.goal_upper_mezialization
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    // goal_upper_ipr: false,
                                    // goal_upper_distalization: false,
                                    goal_upper_mezialization: !this.state
                                      .goal_upper_mezialization,
                                    how_to_achieve_a_p_goal_upper:
                                      "mezialization",
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    // goal_upper_ipr: false,
                                    // goal_upper_distalization: false,
                                    goal_upper_mezialization: !this.state
                                      .goal_upper_mezialization,
                                    how_to_achieve_a_p_goal_upper:
                                      "mezialization",
                                  });
                                }}
                              >
                                Mezialization
                              </Button>
                            </Col>
                          </Row>
                          <Row className="align-items-center">
                            <Col
                              xs="12"
                              md="12"
                              lg="1"
                              className="pr-0 text-center title_active"
                            >
                              L
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.goal_lower_ipr
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    goal_lower_ipr: !this.state.goal_lower_ipr,
                                    // goal_lower_distalization: false,
                                    // goal_lower_mezialization: false,
                                    how_to_achieve_a_p_goal_lower: "ipr",
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    goal_lower_ipr: !this.state.goal_lower_ipr,
                                    // goal_lower_distalization: false,
                                    // goal_lower_mezialization: false,
                                    how_to_achieve_a_p_goal_lower: "ipr",
                                  });
                                }}
                              >
                                IPR 3-6
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.goal_lower_distalization
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    // goal_lower_ipr: false,
                                    goal_lower_distalization: !this.state
                                      .goal_lower_distalization,
                                    // goal_lower_mezialization: false,
                                    how_to_achieve_a_p_goal_lower:
                                      "distalization",
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    // goal_lower_ipr: false,
                                    goal_lower_distalization: !this.state
                                      .goal_lower_distalization,
                                    // goal_lower_mezialization: false,
                                    how_to_achieve_a_p_goal_lower:
                                      "distalization",
                                  });
                                }}
                              >
                                Distalization
                              </Button>
                            </Col>
                            <Col xs="12" md="12" lg="3" className="p-1">
                              <Button
                                className={`btn ${
                                  this.state.goal_lower_mezialization
                                    ? `green-btn-active`
                                    : `green-btn-not-active`
                                } text-center w-100 h-100`}
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    // goal_lower_ipr: false,
                                    // goal_lower_distalization: false,
                                    goal_lower_mezialization: !this.state
                                      .goal_lower_mezialization,
                                    how_to_achieve_a_p_goal_lower:
                                      "mezialization",
                                  });
                                  this.props.updateStore({
                                    ...this.props.getStore(),
                                    // goal_lower_ipr: false,
                                    // goal_lower_distalization: false,
                                    goal_lower_mezialization: !this.state
                                      .goal_lower_mezialization,
                                    how_to_achieve_a_p_goal_lower:
                                      "mezialization",
                                  });
                                }}
                              >
                                Mezialization
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentGoals);
