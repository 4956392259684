import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
  Input,
} from "reactstrap";

import RetainerModal from "./retainerModel/index";
import {
  upload_impression_data_lower,
  upload_impression_data_upper,
  upload_impression_data,
} from "../../helpers/impressions";
import male from "../../assets/images/icon/avatar-male.svg";
import classnames from "classnames";
import {
  requestRetainer,
  downloadTreatmentPDF,
  pauseAlignerCase,
  resumeAlignerCase,
  submitReplacement,
  getReplacements,
  getTretmentSetup,
  markCaseAsComplete,
  archiveCase,
  unArchiveCase,
} from "../../api/api";
import CaseProgress from "./CaseProgress";
import Adjustment from "./Adjustment/Index";
import { connect } from "react-redux";
import moment from "moment";
import TreatmentSetup from "./TreatmentSetup";
import { successToaster, errorToaster } from "../../services/toast";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { caseType } from "../../helpers/caseType";
import { resetAdjustment } from "../../redux/adjutment/action";
import FixPhotos from "../case-submission/FixStep/FixPhotos";
import FixXrays from "../case-submission/FixStep/FixXrays";
import Impressions from "../case-submission/Impressions";
import Prescription from "../case-submission/Prescription";

import BiteRecord from "./BiteRecord";

import TSApprovalPopup from "./TSApprovalPopup";
import ShipmentPopup from "./ShipmentPopup";

import eonAccessLogo from "../../assets/images/eonAccessLogo.png";
import retainer_icon from "../../assets/images/caseActions/retainer.svg";
import adjustment_icon from "../../assets/images/caseActions/adjustment.svg";
import replacement_icon from "../../assets/images/caseActions/replacement.svg";
import file_icon from "../../assets/images/caseActions/file.svg";

import hybrid_icon from "../../assets/images/caseActions/hybrid.svg";

import pause_icon from "../../assets/images/caseActions/pause.svg";
import play_icon from "../../assets/images/caseActions/play.svg";

import archive_icon from "../../assets/images/caseActions/archive.svg";
import restore_icon from "../../assets/images/caseActions/restore.svg";
import check_icon from "../../assets/images/caseActions/check.svg";
import fix_icon from "../../assets/images/caseActions/fix.svg";
import submit_aligner from "../../assets/images/dashboard/submit_aligner.svg";

import CustomeHeader from "../../components/header/CustomeHeader";
import { resetBite } from "../../redux/biteRecord/action";

import { uploadFile, keyToUrl } from "../../helpers/s3";
import { getCaseById } from "../../redux/caseById/action";
import RelatedCases from "./RelatedCases";
import DownloadPdf from "../newPDF/DownloadPdf";
import CaseTypePopupModule from "./CaseTypePopup";
import { Type } from "../case-submission/tooltip";
import ArchSelectionPopupModule from "./ArchSelectionPopup";

import { includes } from "lodash";
import { WrappedDoctorCasePdf } from "@eon-dental/advanced-modules/dist/rollup-export";
import clsx from "clsx";
import { GetCountryLabel } from "../../helpers/countries";
// import Test from "./Test2";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  resetAdjustment: (payload) => dispatch(resetAdjustment(payload)),
  resetBite: (payload) => dispatch(resetBite(payload)),
  getCaseById: (payload) => dispatch(getCaseById(payload)),
});
class Patient extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    /*init state */
    this.state = {
      case: props.caseReducer.case,
      doctorId: props.doctorId,
      retainer: false,
      retainer_new: false,
      sets: 1,
      pause: props.caseReducer.case.status === "Paused" ? true : false,
      gco_doctor: this.props.userReducer.currentUser.gco_doctor,
      arch_impression_upper: false,
      arch_impression_lower: false,
      confirmPause: false,
      confirmResume: false,
      addReplacments: false,
      addReplacment: false,
      adjustment: false,
      Replacements: [],
      Replacement: {
        step_number: 1,
        upper: true,
        lower: true,
      },
      chose_one: "",
      impressions_method: "",
      retainers_List: [],
      replacements_List: [],
      setups: [],
      fix: false,
      activeRetainerTab: 1,
      activeReplacementTab: 1,
      stepToFix: "",
      stepToFixName: "",
      loading: true,
      doctorInfo: {},
      mappedData: props.mappedData,
      sendingData: false,
      sendingRetainer: false,
      pusingCase: false,
      mark: false,
      completingCase: false,
      archiveModal: false,
      unArchiveModal: false,
      show_upload_new_bite_record: false,
      show_bite_record_modal: false,
      hybrid_type_braces_before: false,
      impressions_method_arch: "",
      arch_impression_upper: false,
      arch_impression_lower: false,
      loading_percentage: 0,
      intervalID: 0,
      upper_arch_url: "",
      lower_arch_url: "",
      impressions_third_party_note: "",
      showProgress: false,
      loading_percentage_lower_arch_url: 0,
      loading_percentage_upper_arch_url: 0,
      hover_upper_arch: false,
      hover_lower_arch: false,
      start_treatment: false,
      CaseTypePopup: false,
      ArchSelectionPopup: false,
      isAdjustmentStatus: false,
    };
    this.loaderpdf = null;
  }

  /*--------------------------------------------------------------------------------*/
  /*get treatment setups & loading pdf  */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    this.loaderpdf = setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
    this.GetTreatmentSetups();
  }

  GetTreatmentSetups = async () => {
    await getTretmentSetup(this.state.doctorId, this.state.case.id).then(
      (res) => {
        // sort treatment setups by id desc
        let sortedSetups = []
          .concat(res)
          .sort((a, b) => (a.id < b.id ? 1 : -1));
        this.setState({ setups: sortedSetups });
      }
    );
  };
  showBiteRecord = () => {
    this.setState({ show_upload_new_bite_record: true });
  };
  SetContinueHybridSubmission = () => {
    this.setState({ hybrid_type_braces_before: true });
  };
  setIsAdjustmentStatus = () => {
    this.setState({ isAdjustmentStatus: true });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide retainer modal   */
  /*--------------------------------------------------------------------------------*/
  toggleRetainerModal = () => {
    this.setState({
      retainer_new: !this.state.retainer_new,
      activeRetainerTab: 1,
      impressions_method: "",
      upper_arch_url: "",
      lower_arch_url: "",
      chose_one: this.state.chose_one,
      sets: 1,
      impressions_third_party_note: "",
      impressions_method_arch: "",
      arch_impression_upper: false,
      arch_impression_lower: false,
    });
  };
  toggleRetainerModalClose = () => {
    this.setState({
      retainer_new: !this.state.retainer_new,
      impressions_method: "",
      upper_arch_url: "",
      lower_arch_url: "",
      chose_one: this.state.chose_one,
      sets: 1,
      impressions_third_party_note: "",
      impressions_method_arch: "",
      arch_impression_upper: false,
      arch_impression_lower: false,
    });
  };

  /*--------------------------------------------------------------------------------*/
  /*show / hide confirmation Pause aligner case modal  */
  /*--------------------------------------------------------------------------------*/
  toggleConfirmationPauseModal = () => {
    this.setState({ confirmPause: !this.state.confirmPause });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide confirmation Resume aligner case modal   */
  /*--------------------------------------------------------------------------------*/
  toggleConfirmationResumeModal = () => {
    this.setState({ confirmResume: !this.state.confirmResume });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide add or review replacments in case modal   */
  /*--------------------------------------------------------------------------------*/
  toggleReplacementsModal = () => {
    this.setState({
      addReplacments: !this.state.addReplacments,
      activeReplacementTab: 1,
      Replacements: [],
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide  add new replacment modal   */
  /*--------------------------------------------------------------------------------*/
  toggleReplacementModal = () => {
    this.setState({
      addReplacment: !this.state.addReplacment,
      Replacement: {
        step_number: 1,
        upper: true,
        lower: true,
      },
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide  add new Adjustment modal */
  /*--------------------------------------------------------------------------------*/
  toggleAdjustmentModal = () => {
    this.props.resetAdjustment();
    this.setState({ adjustment: !this.state.adjustment });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide  fix aligner case modal */
  /*--------------------------------------------------------------------------------*/
  toggleFixCaseModal = () => {
    this.setState({ fix: !this.state.fix });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide  fix aligner case modal */
  /*--------------------------------------------------------------------------------*/
  toggleSubmitCaseModal = () => {
    this.setState({ start_treatment: !this.state.start_treatment });
  };
  /*--------------------------------------------------------------------------------*/
  /*set Active Retainer Tab */
  /*--------------------------------------------------------------------------------*/
  setActiveRetainerTab = (tab) => {
    if (this.state.activeRetainerTab !== tab) {
      this.setState({
        activeRetainerTab: tab,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*mark */
  /*--------------------------------------------------------------------------------*/
  toggleMarkCaseModal = () => {
    this.setState({ mark: !this.state.mark });
  };
  /*--------------------------------------------------------------------------------*/
  /*set Active Replacement Tab  */
  /*--------------------------------------------------------------------------------*/
  setActiveReplacementTab = (tab) => {
    if (this.state.activeReplacementTab !== tab) {
      this.setState({
        activeReplacementTab: tab,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* Get Aligner Case                                                     */
  /*--------------------------------------------------------------------------------*/
  GetAlignerCase = async () => {
    const doctor_id = this.props.userReducer.currentUser.id;
    const case_id = this.props.caseReducer.case.id;
    // get case by id action
    await this.props.getCaseById({ doctor_id: doctor_id, case_id: case_id });
  };

  /*--------------------------------------------------------------------------------*/
  /* Reques Retainer                                                       */
  /*--------------------------------------------------------------------------------*/
  RequestRetainer = ({ setSubmitting }) => {
    // setSubmitting(true);
    const { content } = this.props.languageReducer;
    this.setState({ sendingRetainer: !this.state.sendingRetainer });

    let impressions = {
      upper_arch_url: this.state.upper_arch_url,
      lower_arch_url: this.state.lower_arch_url,
    };
    let filtered_impressions = {};

    for (var key2 in impressions) {
      if (impressions[key2].match(/http([^&]+)/)) {
        filtered_impressions[key2] = impressions[key2];
      }
    }

    let data = {
      retainer: {
        sets: this.state.sets.toString(),
        arch_impression_upper: this.state.arch_impression_upper,
        arch_impression_lower: this.state.arch_impression_lower,
        impressions_method_arch: this.state.impressions_method_arch,
        impressions_method: this.state.impressions_method,
        impressions_third_party_note: this.state.impressions_third_party_note,
        impressions: filtered_impressions,
      },
    };

    requestRetainer(
      this.state.doctorId,
      this.state.case.uid,
      JSON.stringify(data)
    )
      .then((res) => {
        this.setState({
          retainer_new: !this.state.retainer_new,
          sets: 1,
          arch_impression_upper: false,
          arch_impression_lower: false,
          impressions_method_arch: "",
          impressions_method: "",
          impressions_third_party_note: "",
          impressions: {},
          sendingRetainer: !this.state.sendingRetainer,
        });

        successToaster(
          content.request_new_retainer_has_been_submitted,
          content.Success
        );
        setSubmitting(false);
      })
      .catch((err) => {
        this.setState({ sendingRetainer: !this.state.sendingRetainer });
        setSubmitting(false);
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* Reques Retainer                                                       */
  /*--------------------------------------------------------------------------------*/
  GetRetainers = () => {
    // getRetainers(this.state.doctorId, this.state.case.uid).then((res) => {
    this.setState({
      // retainers_List: res,
      // retainer: !this.state.retainer
      retainer_new: true,
    });
    // });
  };
  /*--------------------------------------------------------------------------------*/
  /* Download Treatment PDF                                                       */
  /*--------------------------------------------------------------------------------*/
  downloadTreatment = () => {
    downloadTreatmentPDF(this.state.doctorId, this.state.case.uid).then(
      (response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Treatment.json";
          a.click();
        });
      }
    );
  };
  /*--------------------------------------------------------------------------------*/
  /* Pause Aligner Case                                                     */
  /*--------------------------------------------------------------------------------*/
  PauseAlignerCase = () => {
    const { content } = this.props.languageReducer;

    this.setState({ pusingCase: !this.state.pusingCase });
    pauseAlignerCase(this.state.doctorId, this.state.case.uid)
      .then((res) => {
        this.setState({
          pause: !this.state.pause,
          confirmPause: !this.state.confirmPause,
          pusingCase: !this.state.pusingCase,
        });
        this.GetAlignerCase();
        successToaster(content.aligner_case_has_been_paused, content.Success);
      })
      .catch((err) => {
        this.setState({ pusingCase: !this.state.pusingCase });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* Resume Aligner Case                                                       */
  /*--------------------------------------------------------------------------------*/
  ResumeAlignerCase = () => {
    const { content } = this.props.languageReducer;

    this.setState({ resumingCase: !this.state.resumingCase });

    resumeAlignerCase(this.state.doctorId, this.state.case.uid)
      .then((res) => {
        this.setState({
          pause: !this.state.pause,
          confirmResume: !this.state.confirmResume,
          resumingCase: !this.state.resumingCase,
        });
        this.GetAlignerCase();
        successToaster(content.aligner_case_has_been_resumed, content.Success);
      })
      .then((err) => {
        this.setState({ resumingCase: !this.state.resumingCase });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* Replacment validation each replacment step number > 0 & upper or lower = true  */
  /*--------------------------------------------------------------------------------*/
  checkReplacment = () => {
    let found = false;
    for (var x = 0; x < this.state.Replacements.length; x++) {
      if (
        (!this.state.Replacements[x]["upper"] &&
          !this.state.Replacements[x]["lower"]) ||
        this.state.Replacements[x]["step_number"] === "0" ||
        this.state.Replacements[x]["step_number"] === ""
      ) {
        found = true;
        break;
      }
    }
    return found;
  };
  /*--------------------------------------------------------------------------------*/
  /* Submit Replacement                                                     */
  /*--------------------------------------------------------------------------------*/
  SubmitReplacement = () => {
    const { content } = this.props.languageReducer;
    if (this.checkReplacment()) {
      errorToaster(content.please_check_values, content.Warning);
    } else {
      this.setState({ sendingData: !this.state.sendingData });
      let data = {
        replacement: {
          replacement_steps_attributes: this.state.Replacements,
        },
      };
      submitReplacement(
        this.state.doctorId,
        this.state.case.uid,
        JSON.stringify(data)
      )
        .then((res) => {
          this.setState({
            addReplacments: !this.state.addReplacments,
            Replacements: [],
            sendingData: !this.state.sendingData,
          });
          successToaster(
            content.request_new_replacment_has_been_submitted,
            content.Success
          );
        })
        .catch((err) => {
          this.setState({ sendingData: !this.state.sendingData });
        });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* Get Replacements                                                   */
  /*--------------------------------------------------------------------------------*/
  GetReplacements = () => {
    getReplacements(this.state.doctorId, this.state.case.uid).then((res) => {
      this.setState({
        replacements_List: res,
        addReplacments: !this.state.addReplacments,
      });
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*Fix Aligner Case                                                    */
  /*--------------------------------------------------------------------------------*/
  FixAlignerCase = (data) => {
    this.setState({ fix: !this.state.fix });
    this.GetAlignerCase();
  };
  /*--------------------------------------------------------------------------------*/
  /*Submit New Adjustment                                                   */
  /*--------------------------------------------------------------------------------*/
  SubmitNewAdjustment = () => {
    const { content } = this.props.languageReducer;
    this.setState({
      adjustment: !this.state.adjustment,
    });
    this.GetAlignerCase();
    successToaster(content.new_adjustment_has_been_submitted, content.Success);
  };

  /*--------------------------------------------------------------------------------*/
  /*Mark Case As Complete                                                 */
  /*--------------------------------------------------------------------------------*/
  MarkCaseAsComplete = () => {
    const { content } = this.props.languageReducer;
    this.setState({ completingCase: !this.state.completingCase });
    markCaseAsComplete(this.state.doctorId, this.state.case.uid)
      .then((res) => {
        this.setState({
          mark: !this.state.mark,
          completingCase: !this.state.completingCase,
        });
        this.GetAlignerCase();
        successToaster(
          content.aligner_case_has_been_marked_as_completed,
          content.Success
        );
      })
      .catch((err) => {
        this.setState({ completingCase: !this.state.completingCase });
      });
  };

  /* --------------------------------------------------------------------------------*/
  /* status where doctor can archive a case  */
  /* --------------------------------------------------------------------------------*/
  archivedStatus = (status) => {
    let statuses = [
      // "Incomplete Submission",
      "Pending Courier Notification",
      "Pending Record Approval",
      "Pending TS Approval",
      "Generating TS",
      "Rejected TS",
      "Records Rejected",
      "Complete",
      "Awaiting Impressions",
      "Generating Adjustment TS",
      "Awaiting completion",
      "Expired",
    ];
    return statuses.includes(status);
  };
  /* --------------------------------------------------------------------------------*/
  /* toggle Archive Modal */
  /* --------------------------------------------------------------------------------*/
  toggleArchiveModal = () => {
    this.setState({ archiveModal: !this.state.archiveModal });
  };
  /* --------------------------------------------------------------------------------*/
  /* Archive a Case */
  /* --------------------------------------------------------------------------------*/
  ArchiveCase = () => {
    const { content } = this.props.languageReducer;
    archiveCase(this.state.doctorId, this.state.case.uid).then((res) => {
      successToaster(content.archived_case_successfully, content.Success);
      this.toggleArchiveModal();
      this.GetAlignerCase();
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* toggle UnArchive Modal */
  /* --------------------------------------------------------------------------------*/
  toggleUnArchiveModal = () => {
    this.setState({ unArchiveModal: !this.state.unArchiveModal });
  };
  /* --------------------------------------------------------------------------------*/
  /* UnArchive a Case */
  /* --------------------------------------------------------------------------------*/
  UnArchiveCase = () => {
    const { content } = this.props.languageReducer;
    unArchiveCase(this.state.doctorId, this.state.case.uid).then((res) => {
      successToaster(content.restored_case_successfully, content.Success);
      this.toggleUnArchiveModal();
      this.GetAlignerCase();
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* toggle Bite Record Modal  */
  /* --------------------------------------------------------------------------------*/
  toggleBiteRecordModal = () => {
    this.props.resetBite();
    this.setState({
      show_bite_record_modal: !this.state.show_bite_record_modal,
    });
  };

  finishSubmitBiteRecord = async () => {
    await this.GetAlignerCase();
    this.setState({ show_upload_new_bite_record: false });
    this.toggleBiteRecordModal();
  };

  checkReasons = () => {
    const { rejections } = this.props.caseReducer.case;

    for (var x = 0; x < rejections.length; x++) {
      if (rejections[x].reason.step === "bite") {
        return false;
      }
    }
    return true;
  };

  /*--------------------------------------------------------------------------------*/
  /* uploading file timer                                             */
  /*--------------------------------------------------------------------------------*/
  timer = (id) => {
    this.setState({
      [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id) => {
    let extention = image.name.split(".");
    return uploadFile({
      name: `case-${
        this.state.caseId
      }/patient-impression/${id}/${new Date().valueOf()}.${
        image.name.split(".")[extention.length - 1]
      }`,
      contentType: image.type,
      file: image,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* upload photos */
  /* --------------------------------------------------------------------------------*/

  UploadPhoto = async (e, id, setFieldValue) => {
    e.persist();

    if (e.target.files[0]) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      const file = e.target ? e.target.files[0] : e.files[0];
      const { key } = await this.uploadImage(file, id);
      clearInterval(this.state.intervalID);
      this.setState({
        [id]: keyToUrl(key),
        [`loading_percentage_${id}`]: 100,
      });

      setFieldValue(id, keyToUrl(key));
      setFieldValue("chose_one", true);
      setTimeout(() => {
        this.setState({
          [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
          [`loading_percentage_${id}`]: 0,
        });
      }, 500);
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload photos if from drop zone */
  /* --------------------------------------------------------------------------------*/
  UploadPhotoDropZone = async (e, id, setFieldValue) => {
    if (e) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();

      reader.readAsDataURL(e);
      const file = e;
      const { key } = await this.uploadImage(file, id);
      clearInterval(this.state.intervalID);
      this.setState({
        [id]: keyToUrl(key),
        [`loading_percentage_${id}`]: 100,
      });

      setFieldValue(id, keyToUrl(key));
      setFieldValue("chose_one", true);
      setTimeout(() => {
        this.setState({
          [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
          [`loading_percentage_${id}`]: 0,
          [`hover_${id}`]: false,
        });
      }, 500);
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* preventDefault */
  /* --------------------------------------------------------------------------------*/
  preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };
  StartNewTreatment = () => {
    const { patient_info, id } = this.props.caseReducer.case;
    this.props.data.history.push({
      pathname: `/case-submission`,
      state: { patient_info: patient_info, parent_aligner_case_id: id },
    });
  };

  openRelatedCase = (id, status) => {
    if (status === "Incomplete Submission") {
      this.props.data.history.push(`/case-submission?id=${id}`);
    } else {
      this.props.data.history.push({
        pathname: `/case/${id}`,
        replace: true,
      });
    }
  };

  handleOpening = (v) => {};

  HandleCaseTypePopup = (v) => {
    this.setState({
      CaseTypePopup: !this.state.CaseTypePopup,
      ArchSelectionPopup: false,
    });
  };
  HandleArchSelectionPopup = (v) => {
    this.setState({
      ArchSelectionPopup: !this.state.ArchSelectionPopup,
      CaseTypePopup: false,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* clear state if go out from component   */
  /* --------------------------------------------------------------------------------*/

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    clearTimeout(this.loaderpdf);
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const {
      show_upload_new_bite_record,
      sets,

      CaseTypePopup,
      ArchSelectionPopup,

      impressions_method_arch,
      arch_impression_upper,
      arch_impression_lower,
      impressions_method,
      gco_doctor,
      start_treatment,
    } = this.state;

    const wearCycle = {
      express: "1 Week",
      regular: "2 Weeks",
    };
    const { content } = this.props.languageReducer;

    const {
      patient_info,
      status,
      case_type,
      uid,
      id,
      prescription,
      is_archived,
      rejections,
      adjustment_rejections,
      stages,
      additional_stages,
      can_order_adjustment,
      related_aligner_cases,
      parent_aligner_case,
      submitted_at,
      adjustments,

      auto_expires_at,
      isNewAdvancedModules,
    } = this.props.caseReducer.case;

    const { date_format } = this.props.userReducer.currentUser;

    var auto_expires_at_adding_one = new Date(auto_expires_at);
    auto_expires_at_adding_one.setDate(
      auto_expires_at_adding_one.getDate() + 1
    );

    const auto_expires_at_value = moment(new Date(auto_expires_at)).format(
      date_format
    );

    const parentCase =
      Object.keys(parent_aligner_case).length > 0 ? [parent_aligner_case] : [];
    const allRelatedCases = related_aligner_cases.concat(parentCase);
    const patientImage =
      patient_info?.photo === null ? male : patient_info?.photo;
    const impressions_methods_Arch = [
      { name: "Upload_new_impression", title: "Upload new impression" },
      // { name: "Use_last_step", title: "Use last step" },
    ];

    const impressions_methods = [
      { name: "upload", title: content.upload_3d_scans },
      { name: "third_party", title: content._3rd_party_integration },

      ...(!gco_doctor
        ? [{ name: "courier", title: content.impression_pickup }]
        : []),
    ];

    const ArchImpression =
      arch_impression_lower === true && arch_impression_upper === true
        ? upload_impression_data
        : arch_impression_lower === true
        ? upload_impression_data_lower
        : arch_impression_upper === true
        ? upload_impression_data_upper
        : [];

    const upgrade_And_Downgrade_Status = [
      "Pending Courier Notification",
      "Awaiting Impressions",

      "Records Rejected",
      "Pending Record Approval",
    ];

    const case_type_option = ["Eon Basic", "Eon Plus", "Eon Pro"];
    const ArchMapper = {
      upper_only: "Upper",
      lower_only: "Lower",
      both: "Both",
    };
    const stagesCounter =
      stages && stages?.filter((v) => v.shipped_at !== null);

    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/* start new treatment */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.start_treatment}
          toggle={this.toggleSubmitCaseModal}
          className="c-modal c-border-light confarim-modal-with-header "
        >
          <ModalHeader toggle={this.toggleSubmitCaseModal}>
            <CustomeHeader
              title={content.additional_aligners}
              withoutLine={true}
            />
          </ModalHeader>
          <ModalBody>
            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 mb-1">
                {content.confirmation_start_new_treatment}
              </label>
              <div className="cmt3 ">
                <Button
                  className="btn light-green-btn pull-right cml1 full_width_in_button button-seme-bold-text "
                  size="md"
                  onClick={this.StartNewTreatment}
                >
                  {content.start_new_treatment}
                </Button>
                {/* <Button
                  className=" btn white-btn pull-right cml1 button-seme-bold-text"
                  size="md"
                  color="danger"
                  onClick={() => this.setState({ start_treatment: !this.state.start_treatment })}
                >
                  {content.cancel}
                </Button> */}
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Mark case as completed Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Mark case as completed Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.mark}
          toggle={this.toggleMarkCaseModal}
          className="c-modal c-border-light confarim-modal"
        >
          {/* <ModalHeader toggle={this.toggleMarkCaseModal}></ModalHeader> */}
          <ModalBody>
            <CustomeHeader
              title={content.mark_aligner_case_as_completed}
              withoutLine={true}
            />

            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 mb-1">
                {content.confirmation_mark_case_as_completed}
              </label>
              <div className="cmt3 ">
                <Button
                  className="btn light-green-btn pull-right cml1 full_width_in_button button-seme-bold-text "
                  size="md"
                  disabled={this.state.completingCase}
                  onClick={() => this.MarkCaseAsComplete()}
                >
                  {content.mark}
                </Button>
                <Button
                  className=" btn white-btn pull-right cml1 button-seme-bold-text"
                  size="md"
                  color="danger"
                  disabled={this.state.completingCase}
                  onClick={() => this.setState({ mark: !this.state.mark })}
                >
                  {content.cancel}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Mark case as completed Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Confirmation Pause Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.confirmPause}
          toggle={this.toggleConfirmationPauseModal}
          className="c-modal c-border-light confarim-modal"
        >
          <ModalBody>
            <CustomeHeader
              title={content.pause_aligner_case}
              largeFont={true}
              withoutLine={true}
            />

            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 mb-1 headlone-text">
                {content.confirmation_pause}
              </label>

              <div className="cmt3 order-button-mobile">
                <Button
                  className="btn light-green-btn pull-right custom-margin-mobile button-seme-bold-text "
                  size="md"
                  disabled={this.state.pusingCase}
                  onClick={() => this.PauseAlignerCase()}
                >
                  {content.pause}
                </Button>
                <Button
                  className=" btn white-btn pull-right custom-margin-mobile button-seme-bold-text"
                  size="md"
                  color="danger"
                  disabled={this.state.pusingCase}
                  onClick={this.toggleConfirmationPauseModal}
                >
                  {content.cancel}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/*end Confirmation Pause Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Confirmation Resume Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.confirmResume}
          toggle={this.toggleConfirmationResumeModal}
          className="c-modal c-border-light confarim-modal"
        >
          {/* <ModalHeader
            toggle={this.toggleConfirmationResumeModal}
          ></ModalHeader> */}
          <ModalBody>
            <CustomeHeader
              title={content.resume_aligner_case}
              largeFont={true}
              withoutLine={true}
            />

            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 mb-1 headline-text">
                {content.confirmation_resume}
              </label>

              <div className=" confirmResume_grid">
                <Button
                  className="btn light-green-btn pull-right cml1 full-width advanceModuleInmobile "
                  size="md"
                  disabled={this.state.resumingCase}
                  onClick={() => this.ResumeAlignerCase()}
                >
                  {content.resume}
                </Button>
                <Button
                  className=" btn white-btn pull-right cml1 button-seme-bold-text"
                  size="md"
                  color="danger"
                  disabled={this.state.resumingCase}
                  onClick={() =>
                    this.setState({
                      confirmResume: !this.state.confirmResume,
                    })
                  }
                >
                  {content.cancel}
                </Button>
                <Button
                  className="btn light-green-btn pull-right cml1 full-width advanceModuleInWebsite"
                  size="md"
                  disabled={this.state.resumingCase}
                  onClick={() => this.ResumeAlignerCase()}
                >
                  {content.resume}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Confirmation Resume Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Archive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.archiveModal}
          toggle={this.toggleArchiveModal}
          className="c-modal confarim-modal"
        >
          {/* <ModalHeader toggle={this.toggleArchiveModal}></ModalHeader> */}
          <ModalBody>
            <CustomeHeader
              title={content.archive_a_case}
              withoutLine={true}
              largeFont={true}
            />

            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 mb-1 headline-text">
                {content.confirmation_archive}
              </label>

              <div className="cmt3 order-button-mobile">
                <Button
                  className="btn light-green-btn pull-right cml1 button-seme-bold-text"
                  size="md"
                  onClick={this.ArchiveCase}
                >
                  {content.archive}
                </Button>
                <Button
                  className=" btn white-btn pull-right cml1 button-seme-bold-text"
                  size="md"
                  color="danger"
                  onClick={this.toggleArchiveModal}
                >
                  {content.cancel}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Archive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* unArchive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.unArchiveModal}
          toggle={this.toggleUnArchiveModal}
          className="c-modal confarim-modal"
        >
          {/* <ModalHeader toggle={this.toggleUnArchiveModal}></ModalHeader> */}
          <ModalBody>
            <CustomeHeader
              title={content.restore_a_case}
              withoutLine={true}
              largeFont={true}
            />

            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 mb-1 headline-text">
                {content.confirmation_restore}
              </label>

              <div className="cmt3 order-button-mobile">
                <Button
                  className="btn light-green-btn pull-right addres-delete-margin button-seme-bold-text"
                  size="md"
                  onClick={this.UnArchiveCase}
                >
                  {content.restore}
                </Button>
                <Button
                  className=" btn white-btn pull-right addres-delete-margin button-seme-bold-text"
                  size="md"
                  color="danger"
                  onClick={this.toggleUnArchiveModal}
                >
                  {content.cancel}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End unArchive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        {/* /////////////////////////////sara///////////////////////////////////// */}
        {/*--------------------------------------------------------------------------------*/}
        {/* Retainer Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {this.state.retainer_new ? (
          <Modal
            id="Retainer-Modal"
            isOpen={this.state.retainer_new}
            toggle={this.toggleRetainerModal}
            className="modal-rqtainer-request c-border-light confarim-modal"
          >
            {/* <div className="ModalHeaderClosing">
              <ModalHeader
                className="ModalHeaderClosing"
                toggle={this.toggleRetainerModal}
              ></ModalHeader>
            </div> */}

            <ModalBody>
              <RetainerModal
                toggleRetainerModal={this.toggleRetainerModal}
                data={this.state}
              />
            </ModalBody>
          </Modal>
        ) : null}
        {/*--------------------------------------------------------------------------------*/}
        {/* end Retainer Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Replacements Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.addReplacments}
          toggle={this.toggleReplacementsModal}
          className="c-border-light"
        >
          <ModalHeader toggle={this.toggleReplacementsModal}>
            <span className="title_active">
              {content.replacement_aligners_request}
            </span>
          </ModalHeader>

          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeReplacementTab === 1,
                  })}
                  onClick={() => {
                    this.setActiveReplacementTab(1);
                  }}
                >
                  New Replacements
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeReplacementTab === 2,
                  })}
                  onClick={() => {
                    this.setActiveReplacementTab(2);
                  }}
                >
                  Requested Replacement
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={this.state.activeReplacementTab}
              className="replacment-tabs"
            >
              <TabPane tabId={1}>
                <div className="form-group content form-block-holder h-100">
                  {/* <Row className="m-1 mt-3 justify-content-end ">
                    <Col xs="6" md="3" lg="3">
                      <Button
                        className="btn green-btn w-100"
                        onClick={() => {
                          this.setState({
                            addReplacment: !this.state.addReplacment,
                          });
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row> */}

                  <Row className="mt-3 mb-3 ml-1 mr-1 ">
                    <Col
                      xs="1"
                      md="1"
                      lg="1"
                      className="d-flex align-items-end add-container"
                    >
                      <i
                        className="fas fa-plus-circle add-replacment"
                        onClick={() => {
                          let newRep = this.state.Replacements.concat({
                            step_number: 1,
                            upper: true,
                            lower: true,
                          });
                          this.setState({
                            Replacements: newRep,
                          });

                          // this.setState({
                          //   addReplacment: !this.state.addReplacment,
                          // });
                        }}
                      />{" "}
                    </Col>
                    <Col xs="10" md="8" lg="8" className="replacements">
                      {this.state.Replacements.length > 0 && (
                        <div id="replacment-table">
                          <Table className="w-100 p-0" size="sm">
                            <thead>
                              <tr>
                                <th>Step Number</th>
                                <th className="text-center">Upper</th>
                                <th className="text-center">Lower</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.Replacements.map((value, index) => (
                                <tr
                                  key={index}
                                  className="align-items-center justify-content-center"
                                >
                                  <td>
                                    {" "}
                                    <div>
                                      <input
                                        autoComplete="off"
                                        type="number"
                                        className={`steps_num`}
                                        defaultValue={value.step_number}
                                        onChange={(e) => {
                                          e.persist();
                                          this.setState((state) => {
                                            const Replacements =
                                              state.Replacements.map(
                                                (item, j) => {
                                                  if (j === index) {
                                                    return {
                                                      ...item,
                                                      step_number:
                                                        e.target.value,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              );

                                            return {
                                              Replacements,
                                            };
                                          });
                                        }}
                                        min={1}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="content form-block-holder ">
                                      <div
                                        className={`custom-control custom-checkbox ${
                                          value.upper
                                            ? "replacment-checkbox"
                                            : ""
                                        }`}
                                      >
                                        <Input
                                          id={`upper${index}`}
                                          name={`upper${index}`}
                                          className={`custom-control-input `}
                                          type="checkbox"
                                          data-toggle="checkbox"
                                          checked={value.upper}
                                          onChange={(e) => {
                                            e.persist();
                                            this.setState((state) => {
                                              const Replacements =
                                                state.Replacements.map(
                                                  (item, j) => {
                                                    if (j === index) {
                                                      return {
                                                        ...item,
                                                        upper: !value.upper,
                                                      };
                                                    } else {
                                                      return item;
                                                    }
                                                  }
                                                );

                                              return {
                                                Replacements,
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          className="custom-control-label title_active"
                                          htmlFor={`upper${index}`}
                                        ></label>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {" "}
                                    <div className="content form-block-holder ">
                                      <div
                                        className={`custom-control custom-checkbox ${
                                          value.lower
                                            ? "replacment-checkbox"
                                            : ""
                                        }`}
                                      >
                                        <input
                                          id={`lower${index}`}
                                          name={`lower${index}`}
                                          type="checkbox"
                                          className={`custom-control-input `}
                                          data-toggle="checkbox"
                                          checked={value.lower}
                                          onChange={(e) => {
                                            e.persist();
                                            this.setState((state) => {
                                              const Replacements =
                                                state.Replacements.map(
                                                  (item, j) => {
                                                    if (j === index) {
                                                      return {
                                                        ...item,
                                                        lower: !value.lower,
                                                      };
                                                    } else {
                                                      return item;
                                                    }
                                                  }
                                                );

                                              return {
                                                Replacements,
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          className="custom-control-label title_active"
                                          htmlFor={`lower${index}`}
                                        ></label>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-times "
                                      onClick={() => {
                                        this.setState((state) => {
                                          const Replacements =
                                            state.Replacements.filter(
                                              (item, j) => index !== j
                                            );
                                          return {
                                            Replacements,
                                          };
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      )}

                      {/* {this.state.Replacements.map((value, index) => (
                        <>
                          <Card key={index}>
                            <CardTitle>
                              <div className=" text-right p-0 pr-3 pt-3">
                                <i
                                  className="fa fa-times delete"
                                  onClick={() => {
                                    this.setState((state) => {
                                      const Replacements = state.Replacements.filter(
                                        (item, j) => index !== j
                                      );
                                      return {
                                        Replacements,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </CardTitle>
                            <CardBody className="pt-0">
                              <Row>
                                <Col xs="12" md="4" lg="4">
                                  <div className="form-group content form-block-holder ">
                                    <label className="control-label title_active">
                                      Step number
                                    </label>
                                    <div>
                                      <input
                                        autoComplete="off"
                                        type="number"
                                        className={`steps_num`}
                                        defaultValue={value.step_number}
                                        onChange={(e) => {
                                          e.persist();
                                          this.setState((state) => {
                                            const Replacements = state.Replacements.map(
                                              (item, j) => {
                                                if (j === index) {
                                                  return {
                                                    ...item,
                                                    step_number: e.target.value,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );

                                            return {
                                              Replacements,
                                            };
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col xs="12" md="4" lg="4">
                                  {" "}
                                  <div className="form-group content form-block-holder ">
                                    <label className="control-label title_active">
                                      {""}
                                    </label>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        id={`upper${index}`}
                                        name={`upper${index}`}
                                        className={`custom-control-input `}
                                        type="checkbox"
                                        data-toggle="checkbox"
                                        checked={value.upper}
                                        onChange={(e) => {
                                          e.persist();
                                          this.setState((state) => {
                                            const Replacements = state.Replacements.map(
                                              (item, j) => {
                                                if (j === index) {
                                                  return {
                                                    ...item,
                                                    upper: !value.upper,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );

                                            return {
                                              Replacements,
                                            };
                                          });
                                        }}
                                      />
                                      <label
                                        className="custom-control-label title_active"
                                        htmlFor={`upper${index}`}
                                      >
                                        Upper
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs="12" md="4" lg="4">
                                  <div className="form-group content form-block-holder ">
                                    <label className="control-label title_active">
                                      {""}
                                    </label>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        id={`lower${index}`}
                                        name={`lower${index}`}
                                        type="checkbox"
                                        className={`custom-control-input `}
                                        data-toggle="checkbox"
                                        checked={value.lower}
                                        onChange={(e) => {
                                          e.persist();
                                          this.setState((state) => {
                                            const Replacements = state.Replacements.map(
                                              (item, j) => {
                                                if (j === index) {
                                                  return {
                                                    ...item,
                                                    lower: !value.lower,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );

                                            return {
                                              Replacements,
                                            };
                                          });
                                        }}
                                      />
                                      <label
                                        className="custom-control-label title_active"
                                        htmlFor={`lower${index}`}
                                      >
                                        Lower
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </>
                      ))} */}
                    </Col>
                  </Row>
                  <Row className="m-1 mb-4 p-0">
                    <Col xs="12" md="6" lg="6">
                      <Button
                        disabled={
                          this.state.Replacements.length === 0 ||
                          this.state.sendingData ||
                          this.checkReplacment()
                        }
                        className="btn green-btn pull-right w-100 button-seme-bold-text"
                        onClick={() => this.SubmitReplacement()}
                      >
                        Submit Request
                      </Button>
                    </Col>
                  </Row>
                </div>
              </TabPane>
              <TabPane tabId={2}>
                {this.state.replacements_List.length !== 0 && (
                  <div>
                    <label className="control-label title_active mt-3 ml-3 mb-1">
                      The following replacement aligners have been requested
                    </label>
                    <ul>
                      {this.state.replacements_List.map((value, index) => {
                        return this.state.replacements_List[
                          index
                        ].replacement_steps.map((value, index) => {
                          return (
                            <li key={index}>
                              {` Step ${value.step_number}  (${
                                value.upper ? `Upper` : ``
                              }${value.lower && value.upper ? `,` : ``}${
                                value.lower ? ` Lower` : ``
                              } ) on ${moment(
                                new Date(value.created_at)
                              ).format("DD MMMM YYYY")}`}
                            </li>
                          );
                        });
                      })}
                    </ul>
                  </div>
                )}
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Replacements Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* add Replacement Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.addReplacment}
          toggle={this.toggleReplacementModal}
          className="modalx3"
        >
          <ModalHeader toggle={this.toggleReplacementModal}>
            <span className="title_active">
              Add Replacement Aligners Request
            </span>
          </ModalHeader>

          <ModalBody className="p-0">
            <div className="form-group content align-items-center form-block-holder h-100">
              <Row className="m-3 mt-4 ">
                <Col xs="12" md="4" lg="4">
                  <div className="form-group content form-block-holder ">
                    <label className="control-label title_active">
                      Step number
                    </label>
                    <div>
                      <input
                        autoComplete="off"
                        type="number"
                        className={`steps_num`}
                        // className={`m-3 mt-1 `}
                        defaultValue={this.state.Replacement.step_number}
                        onChange={(e) => {
                          this.setState({
                            Replacement: {
                              ...this.state.Replacement,
                              step_number: e.target.value,
                            },
                          });
                        }}
                        min={1}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="4" lg="4">
                  <div className="form-group content form-block-holder">
                    <label className="control-label title_active">{""}</label>
                    <div
                      className={`custom-control custom-checkbox ${
                        this.state.Replacement.upper
                          ? "replacment-checkbox"
                          : ""
                      }`}
                    >
                      <input
                        id="add-upper"
                        name="add-upper"
                        type="checkbox"
                        className={`custom-control-input `}
                        data-toggle="checkbox"
                        defaultValue={this.state.Replacement.upper}
                        onChange={(e) => {
                          this.setState({
                            Replacement: {
                              ...this.state.Replacement,
                              upper: !this.state.upper,
                            },
                          });
                        }}
                      />
                      <label
                        className="custom-control-label title_active"
                        htmlFor="add-upper"
                      >
                        Upper
                      </label>
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="4" lg="4">
                  <div className="form-group content form-block-holder">
                    <label className="control-label title_active">{""}</label>
                    <div
                      className={`custom-control custom-checkbox ${
                        this.state.Replacement.lower
                          ? "replacment-checkbox"
                          : ""
                      }`}
                    >
                      <input
                        id="add-lower"
                        name="add-lower"
                        type="checkbox"
                        className={`custom-control-input `}
                        data-toggle="checkbox"
                        defaultValue={this.state.Replacement.lower}
                        onChange={(e) => {
                          this.setState({
                            Replacement: {
                              ...this.state.Replacement,
                              lower: !this.state.lower,
                            },
                          });
                        }}
                      />
                      <label
                        className="custom-control-label title_active"
                        htmlFor="add-lower"
                      >
                        Lower
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="m-1 justify-content-end">
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100 button-seme-bold-text"
                    size="md"
                    disabled={
                      !this.state.Replacement.upper &&
                      !this.state.Replacement.lower
                    }
                    onClick={() => {
                      let newRep = this.state.Replacements.concat(
                        this.state.Replacement
                      );
                      this.setState({
                        Replacements: newRep,
                        Replacement: {
                          step_number: 1,
                          upper: true,
                          lower: true,
                        },
                        addReplacment: !this.state.addReplacment,
                      });
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end add Replacement Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Adjustment Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.adjustment}
          toggle={this.toggleAdjustmentModal}
          className="modalx4 fix-modal3"
        >
          <ModalHeader toggle={this.toggleAdjustmentModal}>
            {" "}
            New Adjustment{" "}
          </ModalHeader>
          <ModalBody>
            <Adjustment
              DoctorId={this.state.doctorId}
              UID={uid}
              SubmitNewAdjustment={this.SubmitNewAdjustment}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Adjustment Modal */}
        {/*--------------------------------------------------------------------------------*/}

        <Modal
          isOpen={this.state.show_bite_record_modal}
          toggle={this.toggleBiteRecordModal}
          className={"FixStepModalBite"}
        >
          <ModalHeader toggle={this.toggleBiteRecordModal}></ModalHeader>
          <ModalBody>
            <CustomeHeader
              title={content.upload_new_bite_records}
              small={true}
            />
            <BiteRecord finishSubmitBiteRecord={this.finishSubmitBiteRecord} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* Fix Aligner Case Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.fix}
          toggle={this.toggleFixCaseModal}
          className={`
          fix-modal
            ${
              this.state.stepToFix?.reason?.step === "xrays"
                ? "FixXrayStepModal"
                : this.state.stepToFix?.reason?.step === "clinical_findings"
                ? "fix-preferances-modal"
                : "FixStepModal"
            }`}
        >
          <ModalHeader toggle={this.toggleFixCaseModal}></ModalHeader>
          <ModalBody>
            <CustomeHeader
              title={`${content.fix} ${this.state.stepToFixName}`}
              withoutLine={true}
            />

            {
              this.state.stepToFix?.reason?.step === "photos" ? (
                <FixPhotos
                  FixAlignerCase={this.FixAlignerCase}
                  fix={this.state.stepToFix.reason}
                  caseId={id}
                  rejectable_id={this.state.stepToFix.id}
                  rejectable_type={this.state.stepToFix.rejectable_type}
                  stepToFix_id={this.state.stepToFix.rejectable_id}
                />
              ) : this.state.stepToFix?.reason?.step === "xrays" ? (
                <FixXrays
                  FixAlignerCase={this.FixAlignerCase}
                  fix={this.state.stepToFix.reason}
                  caseId={id}
                  rejectable_id={this.state.stepToFix.id}
                  rejectable_type={this.state.stepToFix.rejectable_type}
                  stepToFix_id={this.state.stepToFix.rejectable_id}
                />
              ) : this.state.stepToFix?.reason?.step === "impressions" ? (
                <div className="Impressions-bite-record">
                  <Impressions
                    case={this.state.case}
                    FixAlignerCase={this.FixAlignerCase}
                    fix={this.state.stepToFix.reason}
                    caseId={uid}
                    rejectable_id={this.state.stepToFix.id}
                    rejectable_type={this.state.stepToFix.rejectable_type}
                    stepToFix_id={this.state.stepToFix.rejectable_id}
                    fixLabel={true}
                  />
                </div>
              ) : this.state.stepToFix?.reason?.step === "clinical_findings" ? (
                <div className="Prescription-bite-record">
                  <Prescription
                    FixAlignerCase={this.FixAlignerCase}
                    fix={this.state.stepToFix.reason}
                    caseId={id}
                    rejectable_id={this.state.stepToFix.id}
                    rejectable_type={this.state.stepToFix.rejectable_type}
                    stepToFix_id={this.state.stepToFix.rejectable_id}
                  />
                </div>
              ) : (
                // card EAC-200  is it was (Missing Bite Register(step === summary)) insted of terms and agreement show impressions model
                <div className="Impressions-bite-record">
                  <Impressions
                    case={this.state.case}
                    FixAlignerCase={this.FixAlignerCase}
                    fix={this.state.stepToFix.reason}
                    caseId={uid}
                    rejectable_id={this.state.stepToFix.id}
                    rejectable_type={this.state.stepToFix.rejectable_type}
                    stepToFix_id={this.state.stepToFix.rejectable_id}
                    stepToFix={"summary"}
                    fixLabel={true}
                  />
                </div>
                // <div className="Terms-bite-record">
                //   <Terms
                //     FixAlignerCase={this.FixAlignerCase}
                //     fix={this.state.stepToFix.reason}
                //     caseId={this.props.CaseDetails.id}
                //     rejectable_id={this.state.stepToFix.id}
                //     rejectable_type={this.state.stepToFix.rejectable_type}
                //     stepToFix_id={this.state.stepToFix.rejectable_id}
                //   />
                // </div>
              )
              // <NewCase
              //   FixAlignerCase={this.FixAlignerCase}
              //   fix={this.state.stepToFix.reason}
              //   caseId={this.props.CaseDetails.id}
              //   rejectable_id={this.state.stepToFix.id}
              //   rejectable_type={this.state.stepToFix.rejectable_type}
              //   stepToFix_id={this.state.stepToFix.rejectable_id}
              // />
            }
          </ModalBody>
        </Modal>
        {/*-----------------------------------------------------------------------------<<---*/}
        {/* end Fix Aligner Case Modal */}
        {/*--------------------------------------------------------------------------------*/}

        <div>
          <div xs="11" md="11" lg="11" className="m-auto pl-0 pr-0">
            {case_type === "Retainer" ? (
              <Row className="text-center new-table">
                <Col xs="12" md="3" lg="3" className="p0">
                  <div className="ciles first-cile">
                    <label className="control-label title_active ciles-text ciles-text-first body-text">
                      Case No.
                    </label>
                  </div>

                  <div>
                    <div className="new-table-info new-table-info-first body-text">
                      {uid}
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="3" lg="3" className="p0">
                  <div className="ciles">
                    <label className="control-label ciles-text body-text">
                      Case Type
                    </label>
                  </div>
                  {/* <hr></hr> */}
                  <div>
                    <div className="new-table-info body-text">{case_type}</div>
                  </div>
                </Col>
                <Col xs="12" md="3" lg="3" className="p0">
                  <div className="ciles">
                    <label className="control-label title_active ciles-text body-text">
                      Patient Name
                    </label>
                  </div>
                  {/* <hr></hr> */}
                  <div>
                    <div className="new-table-info body-text">
                      {patient_info.full_name}
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="3" lg="3" className="p0">
                  <div className="ciles last-cile">
                    <label className="control-label title_active ciles-text body-text">
                      Case Status
                    </label>
                  </div>
                  {/* <hr></hr> */}
                  <div>
                    <div className="new-table-info body-text">{status}</div>
                  </div>
                </Col>
              </Row>
            ) : (
              <div>
                {status === "Pending TS Approval" &&
                  this.props.teethIPROptions?.treatment_plans[
                    this.state.setups[0]?.id
                  ]?.published_at && (
                    <TSApprovalPopup
                      content={content}
                      date_format={date_format}
                      setupDetailsId={
                        this.props.teethIPROptions?.treatment_plans[
                          this.state.setups[0]?.id
                        ]?.published_at
                      }
                      days={181}
                    />
                  )}

                {/* // here Heads up Shipment popup  */}
                {stagesCounter?.length > 0 && status !== "Complete" && (
                  <ShipmentPopup
                    content={content}
                    auto_expires_at_value={auto_expires_at_value}
                    expiresAt={auto_expires_at_adding_one}
                    date_format={date_format}
                  />
                )}

                {/* // here Heads up Shipment popup  */}

                <div className="patient_info_actions_grid">
                  {/* patient and case info */}
                  <div className="patient_case_grid">
                    <div className="rounded-img text-center">
                      <img
                        src={patientImage}
                        width="86"
                        height="86"
                        alt={patient_info.full_name}
                      />
                    </div>
                    <div>
                      <div className="case-info-details">
                        <span className=" black-title fs12m caption-1-bold-text">
                          {content.patient}{" "}
                        </span>
                        <span className="fs12m caption-1-text">
                          {" "}
                          {patient_info.full_name}{" "}
                        </span>
                      </div>
                      <div className="case-info-details">
                        <span className=" black-title fs12m caption-1-bold-text">
                          {content.uuid}{" "}
                        </span>
                        <span className="fs12m caption-1-text"> {uid} </span>
                      </div>
                      <div className="case-info-details">
                        <span className=" black-title fs12m caption-1-bold-text">
                          {content.case_type_with_capital_t}
                        </span>
                        <span className="fs12m caption-1-text relative">
                          {" "}
                          {content[caseType[case_type]] || case_type}{" "}
                          {adjustments.length <= 0 &&
                            upgrade_And_Downgrade_Status.includes(status) &&
                            case_type_option.includes(case_type) && (
                              <span>
                                <CaseTypePopupModule
                                  prescription={prescription}
                                  GetAlignerCase={this.GetAlignerCase}
                                  doctor_Id={
                                    this.props.userReducer.currentUser.id
                                  }
                                  case_Id={this.props.caseReducer.case.id}
                                  content={content}
                                  HandleCaseTypePopup={this.HandleCaseTypePopup}
                                  CaseTypePopup={this.state.CaseTypePopup}
                                />{" "}
                              </span>
                            )}
                        </span>
                      </div>

                      <div className="case-info-details">
                        <span className="black-title fs12m caption-1-bold-text">
                          {content.arch}
                        </span>
                        <span className="fs12m caption-1-text">
                          {" "}
                          {ArchMapper[prescription?.treat_arches] || "--"}
                          {adjustments.length <= 0 &&
                            upgrade_And_Downgrade_Status.includes(status) &&
                            case_type_option.includes(case_type) && (
                              <span>
                                <ArchSelectionPopupModule
                                  prescription={prescription}
                                  GetAlignerCase={this.GetAlignerCase}
                                  treat_arches={prescription.treat_arches}
                                  doctor_Id={
                                    this.props.userReducer.currentUser.id
                                  }
                                  case_Id={this.props.caseReducer.case.id}
                                  onChange={this.handleOpening}
                                  ArchSelectionPopup={ArchSelectionPopup}
                                  HandleArchSelectionPopup={
                                    this.HandleArchSelectionPopup
                                  }
                                  content={content}
                                />{" "}
                              </span>
                            )}
                        </span>
                      </div>

                      <div className="case-info-details">
                        <span className="black-title fs12m caption-1-bold-text">
                          {content.wear_cycle}{" "}
                        </span>
                        <span className="fs12m caption-1-text">
                          {" "}
                          {wearCycle[prescription.wear_cycle] ||
                            prescription.wear_cycle}{" "}
                        </span>
                      </div>
                      <div className="case-info-details">
                        <span className="black-title fs12m caption-1-bold-text">
                          {content.case_status}{" "}
                        </span>
                        <span className="fs12m caption-1-text"> {status} </span>
                      </div>
                    </div>
                  </div>
                  {/* end patient and case info */}

                  {/* case actions */}
                  <div className="case_actions_grid">
                    {/* request retainer card*/}
                    {(status === "Active" ||
                      status === "Awaiting completion" ||
                      status === "Complete") &&
                      !is_archived && (
                        <div
                          className="s_card_grid actions-card"
                          onClick={() => {
                            this.GetRetainers();
                          }}
                        >
                          <div className="text-center">
                            <img
                              src={retainer_icon}
                              alt={content.request_retainer}
                            />
                          </div>
                          <div className="subhead-text">
                            {content.request_retainer}
                          </div>
                        </div>
                      )}
                    {/* end request retainer card*/}

                    {/* download pdf card*/}
                    {isNewAdvancedModules ? (
                      <WrappedDoctorCasePdf
                        documentTitle={content.treatment_prescription_form}
                        caseId={this.state.case.id}
                        documentLogoUrl={eonAccessLogo}
                        doctorName={
                          this.props.userReducer.currentUser.full_name
                        }
                        patientName={patient_info.full_name}
                        advancedModules={this.state.mappedData.advanced_modules}
                        // Case PDF Section
                        address={{
                          ...this.props.caseReducer.case.address,
                          country: {
                            name: GetCountryLabel(
                              this.props.caseReducer.case.address.country
                            ),
                          },
                        }}
                        photos_xrays={this.props.mappedData.photos_xrays}
                        prescription={{
                          ...prescription,
                          wear_cycle: wearCycle[prescription.wear_cycle],
                          treat_arches:
                            ArchMapper[prescription.treat_arches] === "Both"
                              ? "Upper & Lower"
                              : ArchMapper[prescription.treat_arches],
                        }}
                        doctorInfo={{
                          user_data: this.props.userReducer.currentUser,
                        }}
                        patient_info={patient_info}
                        enableAdvancedModules={true}
                        dentalNotation={
                          this.props.userReducer.currentUser.dental_notation
                        }
                        Button={({ onClick }) => (
                          <div
                            className={clsx("s_card_grid actions-card")}
                            onClick={onClick}
                          >
                            {this.state.loading ? (
                              <div className="submit-loader">
                                <div className="loader"></div>
                              </div>
                            ) : (
                              <img src={file_icon} alt={content.download_pdf} />
                            )}
                            <div className={"summary-casedetails subhead-text"}>
                              {content.download_pdf}
                            </div>
                          </div>
                        )}
                      />
                    ) : (
                      <DownloadPdf
                        content={content}
                        case={this.props.caseReducer.case}
                        mappedData={this.state.mappedData}
                        doctorInfo={this.state.doctorInfo}
                        uid={uid}
                      />
                    )}
                    {/*end download pdf card*/}

                    {/*add new adjustment card*/}
                    {(status === "Active" ||
                      status === "Awaiting completion" ||
                      status === "Complete") &&
                      !is_archived && (
                        <div
                          className="s_card_grid actions-card"
                          onClick={() => {
                            if (can_order_adjustment) {
                              this.setState({
                                adjustment: !this.state.adjustment,
                              });
                            } else {
                              this.setState({
                                start_treatment: !this.state.start_treatment,
                              });
                            }
                          }}
                        >
                          <div className="text-center">
                            <img src={adjustment_icon} alt={content.adjust} />
                          </div>
                          <div className="subhead-text">{content.adjust}</div>
                        </div>
                      )}
                    {/*end add new adjustment card*/}

                    {/*continue hybrid submissiont card*/}
                    {this.state.hybrid_type_braces_before &&
                      this.state.case.status ===
                        "Converted awaiting new impressions" && (
                        <div
                          className="s_card_grid actions-card"
                          onClick={() => {
                            this.props.data.history.push({
                              pathname: `/case-submission/?id=${this.state.case.id}`,
                              state: { hybrid: true },
                            });
                          }}
                        >
                          <div className="text-center">
                            <img
                              src={hybrid_icon}
                              alt={content.continue_hybrid_submission}
                            />
                          </div>
                          <div className="footnote-text">
                            {content.continue_hybrid_submission}
                          </div>
                        </div>
                      )}
                    {/*end continue hybrid submissiont card*/}

                    {/*request replacement card*/}
                    {(status === "Active" ||
                      status === "Awaiting completion" ||
                      status === "Paused") &&
                      !is_archived && (
                        <div
                          className="s_card_grid actions-card"
                          onClick={() => {
                            this.GetReplacements();
                          }}
                        >
                          <div className="text-center">
                            <img
                              src={replacement_icon}
                              alt={content.request_replacement}
                            />
                          </div>
                          <div className="subhead-text">
                            {content.request_replacement}
                          </div>
                        </div>
                      )}
                    {/*end request replacement card*/}

                    {/*archive case card*/}
                    {this.archivedStatus(status) && !is_archived && (
                      <div
                        className="s_card_grid actions-card"
                        onClick={() => {
                          this.setState({
                            archiveModal: !this.state.archiveModal,
                          });
                        }}
                      >
                        <div className="text-center">
                          <img src={archive_icon} alt={content.archive_case} />
                        </div>
                        <div className="subhead-text">
                          {content.archive_case}
                        </div>
                      </div>
                    )}
                    {/*end archive case card*/}

                    {/*restore case card*/}
                    {is_archived && (
                      <div
                        className="s_card_grid actions-card"
                        onClick={() => {
                          this.setState({
                            unArchiveModal: !this.state.unArchiveModal,
                          });
                        }}
                      >
                        <div className="text-center">
                          <img src={restore_icon} alt={content.restore_case} />
                        </div>
                        <div className="subhead-text">
                          {content.restore_case}
                        </div>
                      </div>
                    )}
                    {/*end restore case card*/}

                    {/*pause case card*/}
                    {/* {(status === "Active" ||
                      status === "Awaiting completion") &&
                      !is_archived && (
                        <div
                          className="s_card_grid actions-card"
                          onClick={() =>
                            this.setState({
                              confirmPause: !this.state.confirmPause,
                            })
                          }
                        >
                          <div className="text-center">
                            <img src={pause_icon} alt={content.pause} />
                          </div>
                          <div className="subhead-text">{content.pause}</div>
                        </div>
                      )} */}
                    {/* end pause case card*/}

                    {/*Resume case card*/}
                    {status === "Paused" && !is_archived && (
                      <div
                        className="s_card_grid actions-card"
                        onClick={() =>
                          this.setState({
                            confirmResume: !this.state.confirmResume,
                          })
                        }
                      >
                        <div className="text-center">
                          <img src={play_icon} alt={content.resume} />
                        </div>
                        <div className="subhead-text">{content.resume}</div>
                      </div>
                    )}
                    {/*end resume case card*/}

                    {/*upload new bite record card*/}
                    {/* {<div className="s_card_grid actions-card">
                      <div className="text-center">
                        <img src={new_bite_icon} alt={content.upload_new_bite_record} />
                      </div>
                      <div>{content.upload_new_bite_record}</div>
                    </div>} */}
                    {/*end upload new bite record card*/}

                    {/*mark case as completed card*/}
                    {status === "Awaiting completion" && !is_archived && (
                      <div
                        className="s_card_grid actions-card"
                        onClick={() =>
                          this.setState({ mark: !this.state.mark })
                        }
                      >
                        <div className="text-center">
                          <img src={check_icon} alt={content.mark_complete} />
                        </div>
                        <div className="subhead-text">
                          {content.mark_complete}
                        </div>
                      </div>
                    )}
                    {/*mark case as completed card*/}

                    {/*submit new case */}
                    {!is_archived && (
                      <div
                        className="s_card_grid actions-card"
                        onClick={this.StartNewTreatment}
                      >
                        <div className="text-center">
                          <img
                            src={submit_aligner}
                            alt={content.submit_aligner_case}
                          />
                        </div>
                        <div className="subhead-text">
                          {content.start_new_treatment}
                        </div>
                      </div>
                    )}
                    {/*submit new case */}
                  </div>
                  {/* end case actions */}
                </div>
                {allRelatedCases?.length > 0 && (
                  <RelatedCases
                    related_aligner_cases={allRelatedCases}
                    openRelatedCase={this.openRelatedCase}
                  />
                )}
                {/*--------------------------------------------------------------------------------*/}
                {/* Rejection reasons(if the case is archived you can see the data without fix btn)*/}
                {/*--------------------------------------------------------------------------------*/}

                {/* {status === "Records Rejected" && show_upload_new_bite_record && (
                  <div className="rejection_list_grid cmt3">
                    <label className="custom-titlecase-rejected">
                      {content.case_rejected_for_the_following_reasons}
                    </label>
                    <div className="rejection_card_grid cmb2">
                      <div className="inner_rejection_grid ">
                        <div>
                          <img src={fix_icon} alt="fix icon" />
                        </div>

                        <div className="fs10m">
                          <b> {content.bite_record}</b>
                          <div className="cpt1">
                            {content.bite_record_rejected}
                          </div>
                        </div>
                      </div>
                      <div>
                        {!is_archived && (
                          <Button
                            className="btn red-btn new-red-btn"
                            size="md"
                            color="danger"
                            onClick={() => {
                              this.toggleBiteRecordModal();
                            }}
                          >
                            {content.fix}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )} */}

                <div className="rejection_list_grid cmt3">
                  {status === "Records Rejected" && (
                    <div>
                      <label className="custom-titlecase-rejected title-3-text">
                        {content.case_rejected_for_the_following_reasons}
                      </label>

                      {rejections
                        .filter((value) => {
                          return !value.fixed;
                        })
                        .map((value, index) => {
                          return (
                            <div
                              key={index}
                              className="rejection_card_grid cmb2"
                            >
                              <div className="inner_rejection_grid ">
                                <div>
                                  <img src={fix_icon} alt="fix icon" />
                                </div>

                                <div className="fs10m">
                                  <b className="body-bold-text">
                                    {" "}
                                    {value.reason.step === "bite"
                                      ? content.bite_record
                                      : value.reason.name}
                                  </b>
                                  <div className="cpt1 footnote-text">
                                    {value.reason.step === "bite"
                                      ? content.bite_record_rejected
                                      : value.reason.description}
                                  </div>
                                </div>
                              </div>
                              <div>
                                {!is_archived && (
                                  <Button
                                    className="btn red-btn new-red-btn footnote-text button-seme-bold-text"
                                    size="md"
                                    color="danger"
                                    onClick={() => {
                                      if (value.reason.step === "bite") {
                                        this.toggleBiteRecordModal();
                                      } else {
                                        this.setState({
                                          fix: !this.state.fix,
                                          stepToFix: value,
                                          stepToFixName: value.reason.name,
                                        });
                                      }
                                    }}
                                  >
                                    {content.fix}
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}

                      {adjustment_rejections &&
                        adjustment_rejections
                          .filter((value) => {
                            return !value.fixed;
                            //  && value.reason.step !== "bite"
                          })
                          .map((value, index) => {
                            return (
                              <div
                                key={index}
                                className="rejection_card_grid cmb2"
                              >
                                <div className="inner_rejection_grid ">
                                  <div>
                                    <img src={fix_icon} alt="fix icon" />
                                  </div>

                                  <div className="fs10m">
                                    <b>
                                      {" "}
                                      {value.reason.step === "bite"
                                        ? content.bite_record
                                        : value.reason.name}
                                    </b>
                                    <div className="cpt1">
                                      {value.reason.step === "bite"
                                        ? content.bite_record_rejected
                                        : value.reason.description}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  {!is_archived && (
                                    <Button
                                      className="btn red-btn footnote-text button-seme-bold-text"
                                      size="md"
                                      color="danger"
                                      onClick={() => {
                                        if (value.reason.step === "bite") {
                                          this.toggleBiteRecordModal();
                                        } else {
                                          this.setState({
                                            fix: !this.state.fix,
                                            stepToFix: value,
                                            stepToFixName: value.reason.name,
                                          });
                                        }
                                      }}
                                    >
                                      {content.fix}
                                    </Button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  )}
                </div>

                {/*--------------------------------------------------------------------------------*/}
                {/* end Rejection reasons*/}
                {/*--------------------------------------------------------------------------------*/}
              </div>
            )}

            {/*--------------------------------------------------------------------------------*/}
            {/* Treatment Setups */}
            {/*--------------------------------------------------------------------------------*/}
            {this.state.setups.length !== 0 &&
              this.state.setups.map((value, index) => (
                <div key={index}>
                  <TreatmentSetup
                    value={value}
                    case={this.props.caseReducer.case}
                    doctorId={this.state.doctorId}
                    data={this.props.data}
                    GetAlignerCase={this.GetAlignerCase}
                    GetTreatmentSetups={this.GetTreatmentSetups}
                    setIsAdjustmentStatus={this.setIsAdjustmentStatus}
                    showBiteRecord={this.showBiteRecord}
                    SetContinueHybridSubmission={
                      this.SetContinueHybridSubmission
                    }
                  />
                </div>
              ))}
            {/*--------------------------------------------------------------------------------*/}
            {/* end Treatment Setups */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/* Stages */}
            {/*--------------------------------------------------------------------------------*/}
            {stages && (
              <Row className="align-content-center m-auto">
                <Col className="progress-table p-0">
                  <CaseProgress
                    progress={stages}
                    additional={additional_stages ? additional_stages : []}
                    stagesLength={
                      additional_stages
                        ? [additional_stages].length + stages.length
                        : stages.length
                    }
                  />
                </Col>
              </Row>
            )}

            {/*--------------------------------------------------------------------------------*/}
            {/* end Stages */}
            {/*--------------------------------------------------------------------------------*/}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
