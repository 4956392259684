import * as yup from "yup";
export const preferenceSchema = (content) =>
  yup.object({
    use_ipr: yup.string().required(content.field_is_required),
    ipr_steps: yup.array(),
    use_attachments: yup.string().required(content.field_is_required),
    attachments_steps: yup.array(),
    passive_aligners: yup.string().required(content.field_is_required),
    pontics: yup.string().required(content.field_is_required),
    overcorrection: yup.string().required(content.field_is_required),
    other_preferences: yup.string()
  });
