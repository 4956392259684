// Category Images
import Class2 from "../assets/images/category/Class2.png";
import Class3 from "../assets/images/category/Class3.png";
import Crowdin from "../assets/images/category/Crowding.png";
import DeepBite from "../assets/images/category/DeepBite.png";
import Midline from "../assets/images/category/Midline.png";
import OpenBite from "../assets/images/category/OpenBite.png";
import PosteriorCrossbite from "../assets/images/category/PosteriorCrossbite.png";
import Spacing from "../assets/images/category/Spacing.png";
import AnterioCrossbite from "../assets/images/category/AnterioCrossbite.png";

// Crowding
import CACL from "../assets/images/questions/Crowding/Q1/AnteriorCrowdingOnly.png";
import CAPC from "../assets/images/questions/Crowding/Q1/AnteriorPosteriorCrowding.png";

import CMM14 from "../assets/images/questions/Crowding/Q2/1-4-mm.png";
import CMM46 from "../assets/images/questions/Crowding/Q2/4-6-mm.png";
import CMT6 from "../assets/images/questions/Crowding/Q2/MoreThan-6-mm.png";

import CD2040 from "../assets/images/questions/Crowding/Q3/20-40-degrees.png";
import CD20 from "../assets/images/questions/Crowding/Q3/LessThen-20-degrees.png";
import CD40 from "../assets/images/questions/Crowding/Q3/MoreThan-40-degrees.png";

import CNO from "../assets/images/questions/Crowding/Q4/No.png";
import CYES from "../assets/images/questions/Crowding/Q4/Yes.png";

// Spacing
import SMM14 from "../assets/images/questions/Spacing/Q1/1-4.png";
import SMM46 from "../assets/images/questions/Spacing/Q1/4-6.png";
import SMT6 from "../assets/images/questions/Spacing/Q1/MoreThan-6.png";

import SNO from "../assets/images/questions/Spacing/Q2/No.png";
import SYES from "../assets/images/questions/Spacing/Q2/Yes.png";

// Openbite
// import ONOQ1 from "no_image";
// import ONOQ1 from "../assets/images/questions/OpenBite/Q1/slash.png";
import OB24 from "../assets/images/questions/OpenBite/Q1/Between-2-4-mm.png";
import OLT2 from "../assets/images/questions/OpenBite/Q1/LessThan-2-mm.png";
import OMT4 from "../assets/images/questions/OpenBite/Q1/MoreThan-4mm.png";

import ONOQ2 from "../assets/images/questions/OpenBite/Q2/slash.png";
import OMM5 from "../assets/images/questions/OpenBite/Q2/05-mm.png";
import OMM51 from "../assets/images/questions/OpenBite/Q2/Between-05-1.png";
import OMT1 from "../assets/images/questions/OpenBite/Q2/MoreThan-1mm.png";

import ONO from "../assets/images/questions/OpenBite/Q3/No.png";
import OYES from "../assets/images/questions/OpenBite/Q3/Yes.png";

// Posteriorcrossbite
import P1T from "../assets/images/questions/PosteriorCrossbite/Q1/1-Tooth.png";
import PT23 from "../assets/images/questions/PosteriorCrossbite/Q1/2-3-Teeth.png";
import PMT4 from "../assets/images/questions/PosteriorCrossbite/Q1/MoreThan-3-teeth.png";
import PNO from "../assets/images/questions/PosteriorCrossbite/Q2/No.png";
import PYES from "../assets/images/questions/PosteriorCrossbite/Q2/YES.png";

// Class2
import C2LT4 from "../assets/images/questions/Class2/Q1/Less-4-mm.png";
import C2MT4 from "../assets/images/questions/Class2/Q1/MoreThan-4-mm.png";

import C2NO from "../assets/images/questions/Class2/Q2/No.png";
import C2YES from "../assets/images/questions/Class2/Q2/Yes.png";

// DeepBite
import DNOQ1 from "../assets/images/questions/DeepBite/Q1/slash.png";
import DMT25 from "../assets/images/questions/DeepBite/Q1/MoreThan-25mm.png";
import DUT25 from "../assets/images/questions/DeepBite/Q1/UpTo-25mm.png";

import DNOQ2 from "../assets/images/questions/DeepBite/Q2/slash.png";
import DMT5 from "../assets/images/questions/DeepBite/Q2/MoreThan-05mm.png";
import DUT5 from "../assets/images/questions/DeepBite/Q2/UpTo-05mm.png";

import DNO from "../assets/images/questions/DeepBite/Q3/No.png";
import DYES from "../assets/images/questions/DeepBite/Q3/Yes.png";

// Midline
import M01 from "../assets/images/questions/Midline/Q1/0-1.png";
import M12 from "../assets/images/questions/Midline/Q1/1-2.png";
import MMT2 from "../assets/images/questions/Midline/Q1/MoreThan-2.png";

// Class3
import C3LT4 from "../assets/images/questions/Class3/Q2/LessThan-4mm.png";
import C3MT4 from "../assets/images/questions/Class3/Q2/MoreThan-4mm.png"
import C3NO from "../assets/images/questions/Class3/Q1/No.png";
import C3YES from "../assets/images/questions/Class3/Q1/Yes.png";

//Anterior Crossbite
import AC1T from "../assets/images/questions/AnteriorCrossbite/Q1/1tooth.jpg";
import AC23T from "../assets/images/questions/AnteriorCrossbite/Q1/2-3teeth.jpg";
import ACMT3T from "../assets/images/questions/AnteriorCrossbite/Q1/morethen3teeth.jpg";



export const categories = [
  {
    key: "crowding",
    title: "Crowding",
    img: Crowdin,
  },
  {
    key: "spacing",
    title: "Spacing",
    img: Spacing,
  },
  {
    key: "openbite",
    title: "Open Bite",
    img: OpenBite,
  },
  {
    key: "posteriorcrossbite",
    title: "Posterior crossbite",
    img: PosteriorCrossbite,
  },
  {
    key: "class2",
    title: "Class || malocclusion",
    img: Class2,
  },
  {
    key: "deepbite",
    title: "Deep Bite",
    img: DeepBite,
  },
  {
    key: "midline",
    title: "Midline",
    img: Midline,
  },
  {
    key: "class3",
    title: "Class ||| malocclusion",
    img: Class3,
  },
  {
    key: "AnterioCrossbite",
    title: "Anterior Crossbite",
    img: AnterioCrossbite,
  },
];

export let questionsAnswers = {
  crowding: [
    {
      question: "Are you addressing anterior or posterior crowding",
      answers: [
        {
          type: "basic",
          ans: "Anterior Crowding only",
          img: CACL,
          key: "CACL",
        },
        {
          type: "none",
          ans: "Anterior and posterior crowding",
          img: CAPC,
          key: "CAPC",
        },
      ],
    },
    {
      question: "What is the amount of crowding",
      answers: [
        {
          type: "basic",
          ans: "1-4 mm",
          img: CMM14,
          key: "CMM14",
        },
        {
          type: "plus",
          ans: "4-6 mm",
          img: CMM46,
          key: "CMM46",
        },
        {
          type: "pro",
          ans: "More than 6 mm",
          img: CMT6,
          key: "CMT6",
        },
      ],
    },
    {
      question: "Does the crowding include rotations ",
      answers: [
        {
          type: "basic",
          ans: "Less than 20 degrees",
          img: CD20,
          key: "CD20",
        },
        {
          type: "plus",
          ans: "20-40 degrees",
          img: CD2040,
          key: "CD2040",
        },
        {
          type: "pro",
          ans: "More than 40 degrees",
          img: CD40,
          key: "CD40",
        },
      ],
    },
    {
      question: "Does the case require extraction?",
      answers: [
        {
          type: "none",
          ans: "No",
          img: CNO,
          key: "CNO",
        },
        {
          type: "pro",
          ans: "Yes",
          img: CYES,
          key: "CYES",
        },
      ],
    },
  ],
  spacing: [
    {
      question: "What is the amount of spacing",
      answers: [
        {
          type: "basic",
          ans: "1-4",
          img: SMM14,
          key: "SMM14",
        },
        {
          type: "plus",
          ans: "4-6",
          img: SMM46,
          key: "SMM46",
        },
        {
          type: "pro",
          ans: "More than 6",
          img: SMT6,
          key: "SMT6",
        },
      ],
    },
    {
      question: "Will space closure require posterior segments mesialization",
      answers: [
        {
          type: "none",
          ans: "No",
          img: SNO,
          key: "SNO",
        },
        {
          type: "pro",
          ans: "Yes",
          img: SYES,
          key: "SYES",
        },
      ],
    },
  ],
  openbite: [
    {
      question: "Do you plan on doing anterior extrusion",
      answers: [
        {
          type: "basic",
          ans: "No",
          img: 'no_image',
          desc: 'I do not plan on doing anterior extrusion',
          key: "ONO",
        },
        {
          type: "basic",
          ans: "Yes, less than 2 mm",
          img: OLT2,
          key: "OLT2",
        },
        {
          type: "plus",
          ans: "Yes, between 2-4 mm",
          img: OB24,
          key: "OB24",
        },
        {
          type: "pro",
          ans: "More than 4 mm",
          img: OMT4,
          key: "OMT4",
        },
      ],
    },
    {
      question: "Do you plan on doing posterior intrusion",
      answers: [
        {
          type: "basic",
          ans: "No",
          img: 'no_image',
          desc: 'I do not plan on doing posterior intrusion',
          key: "ONOQ2",
        },
        {
          type: "plus",
          ans: "Yes, 0.5 mm",
          img: OMM5,
          key: "OMM5",
        },
        {
          type: "plus",
          ans: "Yes, between 0.5 and 1 mm",
          img: OMM51,
          key: "OMM51",
        },
        {
          type: "pro",
          ans: "Yes, more than 1 mm",
          img: OMT1,
          key: "OMT1",
        },
      ],
    },
    {
      question: "Does the case require extraction?",
      answers: [
        {
          type: "none",
          ans: "No",
          img: ONO,
          key: "ONO",
        },
        {
          type: "pro",
          ans: "Yes",
          img: OYES,
          key: "OYES",
        },
      ],
    },
  ],
  posteriorcrossbite: [
    {
      question: "How many teeth are involved",
      answers: [
        {
          type: "basic",
          ans: "1 tooth",
          img: P1T,
          key: "P1T",
        },
        {
          type: "plus",
          ans: "2-3 teeth",
          img: PT23,
          key: "PT23",
        },
        {
          type: "pro",
          ans: "More than 3 teeth",
          img: PMT4,
          key: "PMT4",
        },
      ],
    },
    {
      question: "Does the case require extraction?",
      answers: [
        {
          type: "none",
          ans: "No",
          img: PNO,
          key: "PNO",
        },
        {
          type: "pro",
          ans: "Yes",
          img: PYES,
          key: "PYES",
        },
      ],
    },
  ],
  class2: [
    {
      question: "Severity of the class II discrepancy",
      answers: [
        {
          type: "plus",
          ans: "Less than 4mm",
          img: C2LT4,
          key: "C2LT4",
        },
        {
          type: "pro",
          ans: "more than 4 mm",
          img: C2MT4,
          key: "C2MT4",
        },
      ],
    },
    {
      question: "Is it likely that the case needs extraction",
      answers: [
        {
          type: "plus",
          ans: "No",
          img: C2NO,
          key: "C2NO",
        },
        {
          type: "pro",
          ans: "Yes",
          img: C2YES,
          key: "C2YES",
        },
      ],
    },
  ],
  deepbite: [
    {
      question: "Do you plan on doing anterior intrusion",
      answers: [
        {
          type: "basic",
          ans: "No",
          img: 'no_image',
          desc: 'I do not plan on doing anterior intrusion',
          key: "DNOQ1",
        },
        {
          type: "plus",
          ans: "Up to 2.5 mm",
          img: DUT25,
          key: "DUT25",
        },
        {
          type: "pro",
          ans: "More than 2.5 mm",
          img: DMT25,
          key: "DMT25",
        },
      ],
    },
    {
      question: "Do you plan on doing posterior extrusion",
      answers: [
        {
          type: "basic",
          ans: "No",
          img: 'no_image',
          desc: 'I do not plan on doing posterior extrusion',
          key: "DNOQ2",
        },
        {
          type: "plus",
          ans: "Up to 0.5 mm",
          img: DUT5,
          key: "DUT5",
        },
        {
          type: "pro",
          ans: "More than 0.5 mm",
          img: DMT5,
          key: "DMT5",
        },
      ],
    },
    {
      question: "Does the case require extraction?",
      answers: [
        {
          type: "none",
          ans: "No",
          img: DNO,
          key: "DNO",
        },
        {
          type: "pro",
          ans: "Yes",
          img: DYES,
          key: "DYES",
        },
      ],
    },
  ],
  midline: [
    {
      question: "Amount of deviation",
      answers: [
        {
          type: "basic",
          ans: "0-1",
          img: M01,
          key: "M01",
        },
        {
          type: "plus",
          ans: "1-2",
          img: M12,
          key: "M12",
        },
        {
          type: "pro",
          ans: ">2",
          img: MMT2,
          key: "MMT2",
        },
      ],
    },
  ],
  class3: [
    {
      question: "Severity of the class III discrepancy",
      answers: [
        {
          type: "plus",
          ans: "Less than 4 mm",
          img: C3LT4,
          key: "C3LT4",
        },
        {
          type: "pro",
          ans: "More than 4 mm",
          img: C3MT4,
          key: "C3MT4",
        },
      ],
    },
    {
      question: "Is it likely that the case will need extraction?",
      answers: [
        {
          type: "plus",
          ans: "No",
          img: C3NO,
          key: "C3NO",
        },
        {
          type: "pro",
          ans: "Yes",
          img: C3YES,
          key: "C3YES",
        },
      ],
    },
  ],
  AnterioCrossbite: [
    {
      question: "How many teeth are involved",
      answers: [
        {
          type: "basic",
          ans: "1 tooth",
          img: AC1T,
          key: "AC1T",
        },
        {
          type: "plus",
          ans: "2-3 teeth",
          img: AC23T,
          key: "AC23T",
        },
        {
          type: "pro",
          ans: "More than 3 teeth",
          img: ACMT3T,
          key: "ACMT3T",
        },
      
      ],
    },
  ]
};
