import React, { Component } from "react";
import { Collapse } from "reactstrap";
import CustomeHeader from "../../../components/header/CustomeHeader";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import {
  Doctor_Preferences_IPR,
  Doctor_Preferences_Attachments,
  Clear_Teeth_Data,
} from "../../../redux/customeTeeth/action";

import {
  createDoctorPreferences,
  getDoctorPreferences,
  UpdateDoctorInfo,
} from "../../../api/api";
import { mapTeeth } from "../../../services/mapTeeth";
import { mapTeethToApi } from "../../../services/mapTeethToApi";
import { successToaster } from "../../../services/toast";
import { DoctorPreferences } from "../../Settings/DoctorPreferences";
import OnBoardingSettings from "./OnBoardingSettings";
import Terms from "./Terms";
import { doctor_preferences_mapper } from "../../../services/newDataMapper";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  Doctor_Preferences_IPR: (payload) =>
    dispatch(Doctor_Preferences_IPR(payload)),
  Doctor_Preferences_Attachments: (payload) =>
    dispatch(Doctor_Preferences_Attachments(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),
});

class OnBoarding extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      doctorId: this.props.userReducer.currentUser.id,
      use_ipr: true,
      ipr_timing_1: false,
      ipr_timing_3: false,
      use_attachments: true,
      attachments_timing_3: false,
      attachments_timing_7: false,
      passive_aligners: false,
      wear_cycle: "",
      pontics: false,
      overcorrection: false,
      level_incisal_edge: false,
      level_gingival_margins: false,
      doctor_preferences: {},
      ipr_timing: "",
      attachments_timing: "",
      loadingData: true,
      other_preferences: "",
      upper_arch_leveling: "",
      hybrid_treatment: "",
      ipr_steps: [],
      onboard_skip: false,
      language: this.props.language || "en",
      dental_notation: this.props.dental_notation || "fdi",
      date_format: this.props.date_format || "DD/MM/YYYY",
      attachments_steps: [],
      creatingNewPreferance: false,
      isV2: true,
      account_preferences_isOpen: true,
      clinical_preferences_isOpen: false,
      gco_doctor: this.props.userReducer.currentUser.gco_doctor,
      saveData: false,
    };
  }

  componentDidMount() {
    this.GetDoctorPreferences();
  }
  /* --------------------------------------------------------------------------------*/
  /* Get Doctor Preferences */
  /* --------------------------------------------------------------------------------*/
  GetDoctorPreferences = () => {
    getDoctorPreferences(this.state.doctorId).then((res) => {
      if (res.length) {
        let filtered_doctor_preferences = res.filter((value, index) => {
          return value.current === true;
        });

        this.setState({
          isV2: filtered_doctor_preferences?.[0]?.preferences?.version == 2,
        });
        if (filtered_doctor_preferences?.length > 0) {
          this.mapDoctorPreferences(filtered_doctor_preferences);
        } else {
          // if  filtered doctor pref. length = 0
          this.mapDoctorEmptyPreferences();
          this.setState({ isV2: res?.[0]?.preferences?.version == 2 });
        }
      } else {
        // if doctor pref. length = 0 || null
        this.mapDoctorEmptyPreferences();
        this.setState({ isV2: res?.[0]?.preferences?.version == 2 });
      }
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* map Doctor Preferences */
  /* --------------------------------------------------------------------------------*/
  mapDoctorPreferences = (data) => {
    let doctor_preferences = data[0]?.preferences;
    this.setState({
      loadingData: false,
      creatingNewPreferance: false,
      ...doctor_preferences_mapper(doctor_preferences),
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* map Doctor empty  Preferences */
  /* --------------------------------------------------------------------------------*/
  mapDoctorEmptyPreferences = () => {
    const { gco_doctor } = this.props.userReducer.currentUser;
    this.setState({
      loadingData: false,
      creatingNewPreferance: false,
      use_ipr: "eonToDecide",
      use_attachments: "eonToDecide",
      passive_aligners: "eonToDecide",
      wear_cycle: "regular",
      pontics: !gco_doctor ? "eonToDecide" : "",
      overcorrection: !gco_doctor ? "eonToDecide" : "",
      upper_arch_leveling: "level_incisal_edge",
      hybrid_treatment: "eonToDecide",
      ipr_steps: [{ step: 0 }],
      attachments_steps: [{ step: 0 }],
    });
  };

  CreateDoctorPreferences = (skip) => {
    const { content } = this.props;
    const { gco_doctor, onboard_skip } = this.state;
    this.setState({ creatingNewPreferance: true });
    let data = {};
    if (onboard_skip || skip) {
      this.props.Clear_Teeth_Data("Doctor_Preferences_IPR");
      this.props.Clear_Teeth_Data("Doctor_Preferences_Attachments");
      data = {
        passive_aligners: "yes",
        ...(!gco_doctor && {
          pontics: "yes",
          overcorrection: "yes",
        }),
        use_ipr: "yes",
        ipr_steps: [],
        use_attachments: "yes",
        attachments_steps: [],
        other_preferences: "",
        version: 2,
      };
    } else {
      data = {
        passive_aligners: this.state.passive_aligners,
        ...(!gco_doctor && {
          pontics: this.state.pontics,
          overcorrection: this.state.overcorrection,
        }),
        use_ipr: this.state.use_ipr,
        ipr_steps: this.state.ipr_steps,
        use_attachments: this.state.use_attachments,
        attachments_steps: this.state.attachments_steps,
        other_preferences: this.state.other_preferences,
        version: 2,
      };
    }

    createDoctorPreferences(this.state.doctorId, data).then((res) => {
      successToaster(
        content.update_doctor_preferences_successfully,
        content.Success
      );
      this.GetDoctorPreferences();
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* toogle account prefernces modal */
  /* --------------------------------------------------------------------------------*/
  toogleAccountPrefernces() {
    this.setState({
      account_preferences_isOpen: !this.state.account_preferences_isOpen,
    });
  }
  /* --------------------------------------------------------------------------------*/
  /* toogle clinical prefernces modal */
  /* --------------------------------------------------------------------------------*/
  toogleClinicalPrefernces() {
    this.setState({
      clinical_preferences_isOpen: !this.state.clinical_preferences_isOpen,
    });
  }
  OnChange = (key, value) => {
    this.setState({ [key]: value });
  };
  OnClickSkip = () => {
    this.setState({ onboard_skip: true });
  };
  OnClickSave = () => {
    this.setState({ saveData: true });
  };
  /* --------------------------------------------------------------------------------*/
  /*  update doctor data  */
  /* --------------------------------------------------------------------------------*/
  UpdateUser = () => {
    const { content } = this.props.languageReducer;
    const {
      language,
      dental_notation,
      date_format,
      sendingData,
      onboard_skip,
    } = this.state;
    const {
      first_name,
      last_name,
      phone,
      email_notifications,
      avatar,
      email,
      id,
    } = this.props.userReducer.currentUser;

    let data = {};

    if (onboard_skip) {
      data = {
        doctor: {
          first_name: first_name,
          last_name: last_name,
          phone: phone,
          subscribe: true, //email_notifications === "subscribe" ? true : false,
          preferred_language: "en",
          preferred_date_format: "DD/MM/YYYY",
          preferred_dental_notation: "fdi",
          finished_onboarding: true,
          terms_approval: true,
        },
      };
    } else {
      data = {
        doctor: {
          first_name: first_name,
          last_name: last_name,
          phone: phone,
          subscribe: true, //email_notifications === "subscribe" ? true : false,
          preferred_language: language,
          preferred_date_format: date_format,
          preferred_dental_notation: dental_notation,
          finished_onboarding: true,
          terms_approval: true,
        },
      };
    }

    const currentUser = {
      ...this.props.userReducer.currentUser,
      avatar: avatar,
      email: email,
      first_name: first_name,
      full_name: `${first_name} ${last_name}`,
      id: id,
      last_name: last_name,
      language: language,
      date_format: date_format,
      dental_notation: dental_notation,
      email_notifications: email_notifications,
    };
    UpdateDoctorInfo(id, JSON.stringify(data))
      .then((res) => {
        this.props.GetDoctorInfo();
        this.props.setUserInfo(currentUser);
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
        successToaster(content.updated_user_info_successfully, content.Success);

        /****************change lang ****************/
        localStorage.setItem("language", language);
        this.props.UpdateLanguage(language);
        this.props.UpdateContent(language);
        /********************************************/
        this.setState({ sendingData: !sendingData });
      })
      .catch((error) => {
        this.props.GetDoctorInfo();
        this.setState({ sendingData: !sendingData });
      });
  };

  render() {
    const { finished_onboarding, sendingData, content, terms_approval } =
      this.props;

    const {
      creatingNewPreferance,
      date_format,
      dental_notation,
      language,
      onboard_skip,
      saveData,
      isV2,
    } = this.state;

    const showOnBoardingModal =
      !terms_approval || !finished_onboarding || !isV2;

    const showOnlyTerms = !terms_approval && finished_onboarding && isV2;

    const showOnlyOnboarding =
      terms_approval && (!finished_onboarding || !isV2);

    const showBoth = !showOnlyTerms && !showOnlyOnboarding;

    const isNewTerms = !terms_approval && finished_onboarding;

    const { country } = this.props.userReducer.currentUser;
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/* on boarding Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal isOpen={showOnBoardingModal} className="onboarding-modal">
          {/* <ModalHeader></ModalHeader> */}

          {(showBoth || showOnlyOnboarding) && !onboard_skip && !saveData && (
            <>
              <ModalBody style={{ paddingTop: "24px", paddingBottom: "0" }}>
                <CustomeHeader
                  title={content.get_started}
                  onboarding={true}
                  withoutLine={true}
                  largeFont={true}
                />
                <div
                  className="onboarding-container"
                  style={{ overflowY: "auto", position: "relative" }}
                >
                  <div className="c-line-bold"></div>
                  <div className="onboarding-container">
                    <div
                      style={{ position: "relative" }}
                      className={`arrow-grid ${
                        this.state.account_preferences_isOpen ? "open" : ""
                      }`}
                    >
                      <label className="cmt2 account_preferences_title callout-text">
                        {content.select_your_default_account_preferences}
                      </label>
                      <div
                        className={`arrow`}
                        onClick={() => this.toogleAccountPrefernces()}
                      />
                    </div>
                    <Collapse isOpen={this.state.account_preferences_isOpen}>
                      <OnBoardingSettings
                        content={content}
                        language={language}
                        OnChange={this.OnChange}
                        dental_notation={dental_notation}
                        date_format={date_format}
                      />
                    </Collapse>
                    <div className="c-line-bold"></div>
                    <div
                      style={{ position: "relative" }}
                      className={`arrow-grid ${
                        this.state.clinical_preferences_isOpen ? "open" : ""
                      }`}
                    >
                      <label className="cmt2 clinical_preferences_title callout-text">
                        <span className="tw-ea-font-semibold">
                          {content.select_your_default_clinical_preferences}
                        </span>
                        <div className="clinical_preferences_sub_title footnote-text">
                          {
                            content.you_can_override_these_preferences_on_a_case_by_case_basis_during_submission
                          }
                        </div>
                      </label>
                      <div
                        className={`arrow`}
                        onClick={() => this.toogleClinicalPrefernces()}
                      />
                    </div>
                    <Collapse isOpen={this.state.clinical_preferences_isOpen}>
                      <DoctorPreferences
                        modalStyling
                        handleFormChange={this.OnChange}
                      />
                    </Collapse>

                    <div className="displayNone_inmobile">
                      <ModalFooter>
                        <div className="onboarding-footer">
                          <Button
                            className="onboarding-btn-save light-green-btn btn pull-right cml1 cmt2 full-width button-seme-bold-text"
                            size="md"
                            disabled={creatingNewPreferance && sendingData}
                            onClick={
                              !showBoth
                                ? async () => {
                                    this.CreateDoctorPreferences();
                                    this.UpdateUser();
                                  }
                                : this.OnClickSave
                            }
                          >
                            {content.save}
                          </Button>
                          <br />
                          <Button
                            className="onboarding-btn-skip btn pull-right cml1 cmt1 full-width button-seme-bold-text"
                            size="md"
                            disabled={creatingNewPreferance && sendingData}
                            onClick={
                              !showBoth
                                ? async () => {
                                    this.CreateDoctorPreferences(true);
                                    this.UpdateUser();
                                  }
                                : this.OnClickSkip
                            }
                          >
                            {content.skip}
                          </Button>
                          <div className="onboarding-skip-text tw-ea-whitespace-nowrap tw-ea-text-black">
                            {
                              content.you_can_change_these_preferences_in_settings
                            }
                          </div>
                        </div>
                      </ModalFooter>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <div className="displayNone_website">
                <ModalFooter>
                  <div className="onboarding-footer">
                    <div className="tw-ea-flex tw-ea-flex-row-reverse tw-ea-gap-2">
                      <Button
                        className="onboarding-btn-save light-green-btn btn pull-right cml1 button-seme-bold-text"
                        size="md"
                        disabled={creatingNewPreferance && sendingData}
                        onClick={
                          !showBoth
                            ? async () => {
                                this.CreateDoctorPreferences();
                                this.UpdateUser();
                              }
                            : this.OnClickSave
                        }
                      >
                        {content.save}
                      </Button>
                      <Button
                        className="tw-ea-text-[#898A8D] tw-ea-font-medium tw-ea-text-base tw-ea-border-none hover:tw-ea-border-none tw-ea-shadow-none tw-ea-bg-transparent"
                        size="md"
                        disabled={creatingNewPreferance && sendingData}
                        onClick={
                          !showBoth
                            ? async () => {
                                this.CreateDoctorPreferences(true);
                                this.UpdateUser();
                              }
                            : this.OnClickSkip
                        }
                      >
                        {content.skip}
                      </Button>
                    </div>
                    <div className="onboarding-skip-text tw-ea-whitespace-nowrap body-text">
                      {content.you_can_change_these_preferences_in_settings}
                    </div>
                  </div>
                </ModalFooter>
              </div>
            </>
          )}
          {((showBoth && (onboard_skip || saveData)) || showOnlyTerms) && (
            <Terms
              CreateDoctorPreferences={this.CreateDoctorPreferences}
              UpdateUser={this.UpdateUser}
              country={country}
              withSkip={isNewTerms ? false : true}
              showOnlyTerms={showOnlyTerms}
            />
          )}
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end on boarding Modal */}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);
