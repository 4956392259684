import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Row, Col } from "reactstrap";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class ArchExpansion extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      increaseCaninesPremolarsMolars: this.props.caseSubmission.advanced_modules
        .archExpansion.increaseCaninesPremolarsMolars,
      increaseCaninesPremolars: this.props.caseSubmission.advanced_modules
        .archExpansion.increaseCaninesPremolars,
      increasePremolarsMolars: this.props.caseSubmission.advanced_modules
        .archExpansion.increasePremolarsMolars,
      use_arch_expansion: this.props.caseSubmission.advanced_modules
        .archExpansion.use_arch_expansion,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "archExpansion",
      data: {
        use_arch_expansion: this.state.use_arch_expansion,
        increaseCaninesPremolarsMolars: this.state
          .increaseCaninesPremolarsMolars,
        increaseCaninesPremolars: this.state.increaseCaninesPremolars,
        increasePremolarsMolars: this.state.increasePremolarsMolars,
        teeth:
          this.props.customeTeethReducer.Case_Arch_Expansion
        ,
      },
    });
    this.props.save();
  };

  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const {
      increaseCaninesPremolarsMolars,
      increaseCaninesPremolars,
      increasePremolarsMolars,
      use_arch_expansion,
    } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container m-2">

          <div>
            <div className="control-label title_active-gco text-center mb-2">
              Used Arch Expansion for this case{" "}
            </div>
            <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
              <input
                type="range"
                step="50"
                onChange={(e) => {
                  this.slider(e, "use_arch_expansion")

                  if (e?.target?.value === "0" || e?.target?.value === "50") {
                    this.setState({

                      increaseCaninesPremolarsMolars: false,
                      increaseCaninesPremolars: false,
                      increasePremolarsMolars: false,
                    })
                    this.props.Clear_Teeth_Data("Case_Arch_Expansion")

                  }
                }}
                value={this.sliderValue("use_arch_expansion")}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}

              />
              <Row className="align-items-center ">
                <Col xs="4" md="4" lg="4" className="text-left">
                  <label className="title_active">No</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-center">
                  <label className="title_active">EON to Decide</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-right">
                  <label className="title_active">Yes</label>
                </Col>
              </Row>
            </div>

          </div>


        <div className="auto_grid4 pb-2">
            <div className="control-label title_active-gco">
              Used Arch Expansion for this case{" "}
            </div>
            <div>
              <ToggleSwitch
                id="use-attachments"
                checked={use_arch_expansion}
                disabled={this.props.summary}

                onChange={() => {
                  this.setState({
                    use_arch_expansion: !use_arch_expansion,
                    ...use_arch_expansion && {
                      increaseCaninesPremolarsMolars: false,
                      increaseCaninesPremolars: false,
                      increasePremolarsMolars: false,
                    }
                  });
                  if (use_arch_expansion) {
                    this.props.Clear_Teeth_Data("Case_Arch_Expansion")
                  }
                }}
              />
            </div>
          </div>
      
          <div className="arch_expansion_grid pb-2">
            <Button
              onClick={() => {
                this.setState({
                  increaseCaninesPremolarsMolars: true,
                  increaseCaninesPremolars: false,
                  increasePremolarsMolars: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${increaseCaninesPremolarsMolars
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_arch_expansion || use_arch_expansion === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Increasing the arch width between canines, premolars and molars
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  increaseCaninesPremolarsMolars: false,
                  increaseCaninesPremolars: true,
                  increasePremolarsMolars: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${increaseCaninesPremolars
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_arch_expansion || use_arch_expansion === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              increasing the arch width between canines, premolars only{" "}
            </Button>{" "}
            <Button
              onClick={() => {
                this.setState({
                  increaseCaninesPremolarsMolars: false,
                  increaseCaninesPremolars: false,
                  increasePremolarsMolars: true,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${increasePremolarsMolars
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_arch_expansion || use_arch_expansion === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Increasing the arch width between premolars and molars only{" "}
            </Button>{" "}
          </div>

          <div className="teeth_selector">
            <CustomeTeeth
              action_key="Case_Arch_Expansion"
              disabled={!use_arch_expansion || use_arch_expansion === "eonToDecide"}
              summary={this.props.summary}
              advanced_Ts="true"
            />
          </div>
        </div>

        { !this.props.summary && <Button
          className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArchExpansion);
