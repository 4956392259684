import { en } from "./en";
import { fr } from "./fr";

function compare(base, compared, deepSearch) {
  var missing = [];

  var compareProp = function (baseValue, comparedValue, path, deepSearch) {
    //console.log('comparing', path.join('.'));

    if (comparedValue === undefined) {
      console.log("missing key", path.join("."));

      if (!deepSearch) {
        return;
      }
    }

    if (typeof baseValue === "object") {
      Object.keys(baseValue).forEach(function (key) {
        compareProp(
          baseValue[key],
          comparedValue && comparedValue[key],
          path.concat(key),
          deepSearch
        );
      });
    }
  };

  Object.keys(base).forEach(function (key) {
    compareProp(base[key], compared[key], [key], deepSearch);
  });
}

//compare (fr,en, true);

function getKey(fr) {
  let newObj = {}
  const arr = [],
    obj = Object.keys(fr);
  for (var x in obj) {
    if (fr[obj[x]].includes("_fr")) {
      arr.push(obj[x]);
      newObj = { ...newObj, [obj[x]]: fr[obj[x]] }
    }
  }
  return  newObj;
}

//console.log(getKey(fr))
export const content = {
  en: en,
  fr: fr,
};
