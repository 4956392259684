import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import Steps from "../../../../components/sheredStepController/sheredStepController"

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Attachments extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_attachments: this.props.caseSubmission.advanced_modules.attachments
        .useAttachments,
      attachments_timing_3: false,
      attachments_timing_7: false,
      attachmentsTiming: this.props.caseSubmission.advanced_modules.attachments
        .attachmentsTiming,
      steps: this.props.caseSubmission.advanced_modules.attachments.steps,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "attachments",
      data: {
        useAttachments: this.state.use_attachments,
        attachmentsTiming: this.state.attachmentsTiming,
        attachmentsRestriction:
          this.props.customeTeethReducer.Case_Attachments,
        steps: this.state.steps,

      },
    });
    this.props.save();
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  handelChanges = (value) => {
    this.setState({
      steps: value
    })
  }
  render() {
    const {
      use_attachments
    } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>
        <div className="templete_module_grid">

          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.use_attachments_for_this_case}{"? "}
            </div>
            <TreatmentOptions content={content} value={use_attachments} name="use_attachments" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_attachments")
              if (value !== true) {
                this.props.Clear_Teeth_Data("Case_Attachments")
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
                this.setState({ attachmentsTiming: "", 
                steps: [{ step: 0 }]  })
              }
            }} />
          </div>
          <div className="divider_module-middle"></div>

          <div id="content_container_3"
            className={`${use_attachments !== true ? "c-content-container7" : "c-content-container "} tooth-extraction  cpb2 `}>

            <div className="grid_container">
              {" "}
              <div className=" pb-2">
                {/* <div className="control-label title_active-gco align-self-end">
                {content.attachments_timing}{" "}
              </div> */}
                <Steps StepTitle={content.perform_attachments_before_step} summary={this.props.summary} 
                  hyberText={content.add_attachments_step} arrayOf_steps={this.state.steps || [{step: 0}]} onclick={(value) => {
                    this.handelChanges(value)
                  }}
                />
                <div className="">

                 
                </div>
              </div>
              <div className="">
                <div className="control-label title_active-gco title_tertment_option pb-2 text-left mt-2 callout-text">
                  {content.select_the_teeth_where_you_dont_want_to_place_attachments}
                </div>
                <div className="teeth_selector">
                  <CustomeTeeth
                    action_key="Case_Attachments"
                    disabled={!use_attachments || use_attachments === "eonToDecide"}
                    summary={this.props.summary}
                  />
                </div>
              </div>
            </div>
            <div className={`${(this.state.use_attachments === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_attachments === "eonToDecide") ? `Eon_to_recommend_eon Eon_to_recommend_layout ` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>
              <p className={`${(this.state.use_attachments === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Attachments_will_not_be_applied_to_his_case}</p>
              <p className={`${(this.state.use_attachments === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>

            </div>
            {
          !this.props.summary && this.state.use_attachments ===true && window.innerWidth < 839 &&   <div className="btn-adv-mobile"><Button
            className="mt-2  mobile-but-adv light-green-btn float-right  button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
          </div>
        </div>

        {
          !this.props.summary && !(this.state.use_attachments ===true && window.innerWidth <839  )  && <div className="btn-adv-mobile"><Button
            className="mt-2  mobile-but-adv light-green-btn float-right  button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
