import React, { Component } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import Teeth2 from "../Teeth/Teeth2";
import { FillClinicalFindings } from "../../../../services/fillForm";
import { connect } from "react-redux";
import { setNumberingSystem } from "../../../../redux/newCase/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setNumberingSystem: (payload) => dispatch(setNumberingSystem(payload)),
});
class ClinicalFindings extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = props.getStore();
    this.fix = this.props.fix;
  }
  /* --------------------------------------------------------------------------------*/
  /* fill form with init value  */
  /* --------------------------------------------------------------------------------*/
  FillForm() {
    this.setState(FillClinicalFindings());

    this.props.updateStore({
      ...this.props.getStore(),
      chief_complaint: "test",
      skeletal_1: true,
      skeletal_2: false,
      skeletal_3: false,
      skeletal: "I",
      canine_r_1: true,
      canine_r_2: false,
      canine_r_3: false,
      canine_l_1: true,
      canine_l_2: false,
      canine_l_3: false,
      molar_r_1: true,
      molar_r_2: false,
      molar_r_3: false,
      molar_l_1: true,
      molar_l_2: false,
      molar_l_3: false,
      lower_midline_center: true,
      lower_midline_shifted_left: false,
      lower_midline_shifted_right: false,
      lower_midline: "centered",
      upper_midline_center: true,
      upper_midline_shifted_left: false,
      upper_midline_shifted_right: false,
      upper_midline: "centered",
    });
  }
  /* --------------------------------------------------------------------------------*/
  /* tooth numbering system slider  */
  /* --------------------------------------------------------------------------------*/
  slider = (e) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.props.setNumberingSystem("universal");
      } else if (e.target.value === "50") {
        this.props.setNumberingSystem("palmer");
      } else if (e.target.value === "100") {
        this.props.setNumberingSystem("fdi");
      }
    }
  };

  sliderValue = () => {
    if (this.props.newCase.numbering === "universal") {
      return 0;
    } else if (this.props.newCase.numbering === "palmer") {
      return 50;
    } else {
      return 100;
    }
  };

  render() {
    // explicit class assigning based on validation
    let notValidClasses = {};
    // chief complaint validation
    if (
      typeof this.state.chief_complaint === "undefined" ||
      this.state.chief_complaint ||
      !this.props.getStore().validateTab1
    ) {
      notValidClasses.chiefComplaintCls = "form-control";
    } else {
      notValidClasses.chiefComplaintCls = "is-invalid form-control";
      notValidClasses.chiefComplaintValGrpCls = "text-danger";
    }
    // upper midline displacement validation
    if (
      typeof this.state.upper_midline_displacement === "undefined" ||
      this.state.upper_midline_displacement
    ) {
      notValidClasses.upperMidlineDisplacementCls = "form-control";
    } else {
      notValidClasses.upperMidlineDisplacementCls = "is-invalid form-control";
      notValidClasses.upperMidlineDisplacementValGrpCls = "text-danger";
    }
    // lower midline displacement validation
    if (
      typeof this.state.lower_midline_displacement === "undefined" ||
      this.state.lower_midline_displacement
    ) {
      notValidClasses.lowerMidlineDisplacementCls = "form-control";
    } else {
      notValidClasses.lowerMidlineDisplacementCls = "is-invalid form-control";
      notValidClasses.lowerMidlineDisplacementValGrpCls = "text-danger";
    }
    // skeletal validation
    if (typeof this.state.skeletal === "undefined" || this.state.skeletal) {
      notValidClasses.skeletalCls = "form-control ";
    } else {
      notValidClasses.skeletalCls = "is-invalid form-control";
      notValidClasses.skeletalValGrpCls = "text-danger";
    }
    // canine right validation
    if (
      this.state.canine_r_1 ||
      this.state.canine_r_2 ||
      this.state.canine_r_3
    ) {
      notValidClasses.canineRCls = "form-control";
    } else {
      notValidClasses.canineRCls = "is-invalid form-control";
      notValidClasses.canineRValGrpCls = "text-danger";
    }
    // canine left validation
    if (
      this.state.canine_l_1 ||
      this.state.canine_l_2 ||
      this.state.canine_l_3
    ) {
      notValidClasses.canineLCls = "form-control";
    } else {
      notValidClasses.canineLCls = "is-invalid form-control";
      notValidClasses.canineLValGrpCls = "text-danger";
    }
    // molar right validation
    if (this.state.molar_r_1 || this.state.molar_r_2 || this.state.molar_r_3) {
      notValidClasses.molarRCls = "form-control";
    } else {
      notValidClasses.molarRCls = "is-invalid form-control";
      notValidClasses.molarRValGrpCls = "text-danger";
    }
    // molar left validation
    if (this.state.molar_l_1 || this.state.molar_l_2 || this.state.molar_l_3) {
      notValidClasses.molarLCls = "form-control";
    } else {
      notValidClasses.molarLCls = "is-invalid form-control";
      notValidClasses.molarLValGrpCls = "text-danger";
    }
    // upper midline validation
    if (
      typeof this.state.upper_midline === "undefined" ||
      this.state.upper_midline
    ) {
      notValidClasses.upperMidlineCls = "form-control";
    } else {
      notValidClasses.upperMidlineCls = "is-invalid form-control";
      notValidClasses.upperMidlineValGrpCls = "text-danger";
    }
    // lower midline validation
    if (
      typeof this.state.lower_midline === "undefined" ||
      this.state.lower_midline
    ) {
      notValidClasses.lowerMidlineCls = "form-control";
    } else {
      notValidClasses.lowerMidlineCls = "is-invalid form-control";
      notValidClasses.lowerMidlineValGrpCls = "text-danger";
    }

    return (
      <div className="step step2 mt-5 mb-2">
        {/*--------------------------------------------------------------------------------*/}
        {/* Fill Form   */}
        {/*--------------------------------------------------------------------------------*/}
        {/* {!this.fix && (
          <div className="row p-3">
            <button
              type="button"
              id="finish-button"
              className="btn btn-next btn-primary btn-lg pull-left"
              onClick={() => {
                this.FillForm();
              }}
            >
              Fill Form
            </button>
          </div>
        )} */}
        {/*--------------------------------------------------------------------------------*/}
        {/*End  Fill Form   */}
        {/*--------------------------------------------------------------------------------*/}
        <div className="row justify-content-md-center">
          <div className="col">
            <div className="">
              <form id="Form" className="form-horizontal mt-2 mb-2">
                <Row>
                  <Col xs="12" md="4" lg="4">
                    {/* Chief Complaint  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Chief Complaint
                      </label>
                      <div>
                        <Input
                          autoComplete="off"
                          type="textarea"
                          rows={8}
                          placeholder="Type something…"
                          className={`${notValidClasses.chiefComplaintCls} green_input`}
                          defaultValue={this.state.chief_complaint}
                          onChange={(e) => {
                            this.setState({
                              chief_complaint: e.target.value,
                            });
                            this.props.updateStore({
                              ...this.props.getStore(),
                              chief_complaint: e.target.value,
                            });
                          }}
                        />
                      </div>

                      {this.props.getStore().chief_complaint === "" &&
                        this.props.getStore().validateTab1 && (
                          <div
                            className={notValidClasses.chiefComplaintValGrpCls}
                          >
                            {"Chief Complaint is required"}
                          </div>
                        )}
                    </div>
                    {/* Skeletal Relationship  */}

                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active ">
                        Skeletal Relationship
                      </label>
                      <Row className="">
                        <Col xs="12" md="12" lg="1" className="p-0"></Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.skeletal_1
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 `}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                skeletal_1: true,
                                skeletal_2: false,
                                skeletal_3: false,
                                skeletal: "I",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                skeletal_1: true,
                                skeletal_2: false,
                                skeletal_3: false,
                                skeletal: "I",
                              });
                            }}
                          >
                            Class I
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.skeletal_2
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 `}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                skeletal_1: false,
                                skeletal_2: true,
                                skeletal_3: false,
                                skeletal: "II",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                skeletal_1: false,
                                skeletal_2: true,
                                skeletal_3: false,
                                skeletal: "II",
                              });
                            }}
                          >
                            Class II
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.skeletal_3
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                skeletal_1: false,
                                skeletal_2: false,
                                skeletal_3: true,
                                skeletal: "III",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                skeletal_1: false,
                                skeletal_2: false,
                                skeletal_3: true,
                                skeletal: "III",
                              });
                            }}
                          >
                            Class III
                          </Button>
                        </Col>
                      </Row>

                      {this.props.getStore().skeletal === "" &&
                        this.props.getStore().validateTab1 && (
                          <div className={notValidClasses.skeletalValGrpCls}>
                            {"Skeletal Relationship is required"}
                          </div>
                        )}
                    </div>
                    {/* Canine Relationship  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Canine Relationship
                      </label>
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          R
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.canine_r_1
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                canine_r_1: true,
                                canine_r_2: false,
                                canine_r_3: false,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                canine_r_1: true,
                                canine_r_2: false,
                                canine_r_3: false,
                              });
                            }}
                          >
                            Class I
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.canine_r_2
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                canine_r_1: false,
                                canine_r_2: true,
                                canine_r_3: false,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                canine_r_1: false,
                                canine_r_2: true,
                                canine_r_3: false,
                              });
                            }}
                          >
                            Class II
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.canine_r_3
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                canine_r_1: false,
                                canine_r_2: false,
                                canine_r_3: true,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                canine_r_1: false,
                                canine_r_2: false,
                                canine_r_3: true,
                              });
                            }}
                          >
                            Class III
                          </Button>
                        </Col>
                      </Row>
                      {this.props.getStore().canine_r_1 === false &&
                        this.props.getStore().canine_r_2 === false &&
                        this.props.getStore().canine_r_3 === false &&
                        this.props.getStore().validateTab1 && (
                          <div className={notValidClasses.canineRValGrpCls}>
                            {"Right Canine Relationship is required"}
                          </div>
                        )}
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          L
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.canine_l_1
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                canine_l_1: true,
                                canine_l_2: false,
                                canine_l_3: false,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                canine_l_1: true,
                                canine_l_2: false,
                                canine_l_3: false,
                              });
                            }}
                          >
                            Class I
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.canine_l_2
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                canine_l_1: false,
                                canine_l_2: true,
                                canine_l_3: false,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                canine_l_1: false,
                                canine_l_2: true,
                                canine_l_3: false,
                              });
                            }}
                          >
                            Class II
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.canine_l_3
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                canine_l_1: false,
                                canine_l_2: false,
                                canine_l_3: true,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                canine_l_1: false,
                                canine_l_2: false,
                                canine_l_3: true,
                              });
                            }}
                          >
                            Class III
                          </Button>
                        </Col>
                      </Row>
                      {this.props.getStore().canine_l_1 === false &&
                        this.props.getStore().canine_l_2 === false &&
                        this.props.getStore().canine_l_3 === false &&
                        this.props.getStore().validateTab1 && (
                          <div className={notValidClasses.canineLValGrpCls}>
                            {"Left Canine Relationship is required"}
                          </div>
                        )}
                    </div>
                    {/* Molar Relationship  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Molar Relationship
                      </label>

                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          R
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.molar_r_1
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                molar_r_1: true,
                                molar_r_2: false,
                                molar_r_3: false,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                molar_r_1: true,
                                molar_r_2: false,
                                molar_r_3: false,
                              });
                            }}
                          >
                            Class I
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.molar_r_2
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                molar_r_1: false,
                                molar_r_2: true,
                                molar_r_3: false,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                molar_r_1: false,
                                molar_r_2: true,
                                molar_r_3: false,
                              });
                            }}
                          >
                            Class II
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.molar_r_3
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                molar_r_1: false,
                                molar_r_2: false,
                                molar_r_3: true,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                molar_r_1: false,
                                molar_r_2: false,
                                molar_r_3: true,
                              });
                            }}
                          >
                            Class III
                          </Button>
                        </Col>
                      </Row>
                      {this.props.getStore().molar_r_1 === false &&
                        this.props.getStore().molar_r_2 === false &&
                        this.props.getStore().molar_r_3 === false &&
                        this.props.getStore().validateTab1 && (
                          <div className={notValidClasses.molarRValGrpCls}>
                            {"Right Molar Relationship is required"}
                          </div>
                        )}
                      <Row className="align-items-center">
                        <Col
                          xs="12"
                          md="12"
                          lg="1"
                          className="pr-0 text-center title_active"
                        >
                          L
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.molar_l_1
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                molar_l_1: true,
                                molar_l_2: false,
                                molar_l_3: false,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                molar_l_1: true,
                                molar_l_2: false,
                                molar_l_3: false,
                              });
                            }}
                          >
                            Class I
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.molar_l_2
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                molar_l_1: false,
                                molar_l_2: true,
                                molar_l_3: false,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                molar_l_1: false,
                                molar_l_2: true,
                                molar_l_3: false,
                              });
                            }}
                          >
                            Class II
                          </Button>
                        </Col>
                        <Col xs="12" md="12" lg="3" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.molar_l_3
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                molar_l_1: false,
                                molar_l_2: false,
                                molar_l_3: true,
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                molar_l_1: false,
                                molar_l_2: false,
                                molar_l_3: true,
                              });
                            }}
                          >
                            Class III
                          </Button>
                        </Col>
                      </Row>
                      {this.props.getStore().molar_l_1 === false &&
                        this.props.getStore().molar_l_2 === false &&
                        this.props.getStore().molar_l_3 === false &&
                        this.props.getStore().validateTab1 && (
                          <div className={notValidClasses.molarLValGrpCls}>
                            {"Left Molar Relationship is required"}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col xs="12" md="1" lg="1"></Col>
                  <Col xs="12" md="7" lg="7">
                    <Row className="justify-content-end">
                      <Col xs="12" md="12" lg="7">
                        {/* tooth numbering system slider */}
                        <div className="tooth-slider form-group content form-block-holder">
                          <input
                            type="range"
                            step="50"
                            onChange={this.slider}
                            value={this.sliderValue()}
                          />
                          <Row className="align-items-center ">
                            <Col xs="4" md="4" lg="4" className="text-left">
                              <label className="title_active">Universal</label>
                            </Col>
                            <Col xs="4" md="4" lg="4" className="text-center">
                              <label className="title_active">Palmer</label>
                            </Col>
                            <Col xs="4" md="4" lg="4" className="text-right">
                              <label className="title_active">FDI</label>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    {/* Tooth Movement Restrictions  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Tooth Movement Restrictions
                      </label>
                      <Teeth2
                        action_key="Tooth_Movement_Restrictions"
                        tab_name="clinical_findings"
                      />
                    </div>
                    {/* Do not place attachments  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active ">
                        Do not place attachments
                      </label>
                      <Teeth2
                        action_key="Do_not_place_attachments"
                        tab_name="clinical_findings"
                      />
                    </div>
                    {/*  Upper Midline  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Upper Midline
                      </label>
                      <Row className="">
                        <Col xs="12" md="4" lg="2" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.upper_midline_center
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                upper_midline_center: true,
                                upper_midline_shifted_left: false,
                                upper_midline_shifted_right: false,
                                upper_midline: "centered",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                upper_midline_center: true,
                                upper_midline_shifted_left: false,
                                upper_midline_shifted_right: false,
                                upper_midline: "centered",
                              });
                            }}
                          >
                            Centered
                          </Button>
                        </Col>
                        <Col xs="12" md="4" lg="2" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.upper_midline_shifted_left
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                upper_midline_center: false,
                                upper_midline_shifted_left: true,
                                upper_midline_shifted_right: false,
                                upper_midline: "shifted_left",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                upper_midline_center: false,
                                upper_midline_shifted_left: true,
                                upper_midline_shifted_right: false,
                                upper_midline: "shifted_left",
                              });
                            }}
                          >
                            Shifted Left
                          </Button>
                        </Col>
                        <Col xs="12" md="4" lg="2" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.upper_midline_shifted_right
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                upper_midline_center: false,
                                upper_midline_shifted_left: false,
                                upper_midline_shifted_right: true,
                                upper_midline: "shifted_right",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                upper_midline_center: false,
                                upper_midline_shifted_left: false,
                                upper_midline_shifted_right: true,
                                upper_midline: "shifted_right",
                              });
                            }}
                          >
                            Shifted Right
                          </Button>
                        </Col>
                      </Row>

                      {(this.state.upper_midline_shifted_left ||
                        this.state.upper_midline_shifted_right) && (
                        <Row className="p-2 mt-2 align-items-center">
                          <Col xs="12" md="8" lg="4">
                            <label className="control-label title_active">
                              If shifted, displacement in mm
                            </label>
                          </Col>
                          <Col xs="12" md="4" lg="2">
                            <Input
                              autoComplete="off"
                              type="number"
                              className={`${notValidClasses.upperMidlineDisplacemenCls} green_input`}
                              defaultValue={
                                this.state.upper_midline_displacement
                              }
                              onChange={(e) => {
                                this.setState({
                                  upper_midline_displacement: e.target.value,
                                });
                                this.props.updateStore({
                                  ...this.props.getStore(),
                                  upper_midline_displacement: e.target.value,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      )}

                      {this.props.getStore().upper_midline === "" &&
                        this.props.getStore().validateTab1 && (
                          <div
                            className={notValidClasses.upperMidlineValGrpCls}
                          >
                            {"Upper Midline is required"}
                          </div>
                        )}
                    </div>
                    {/*  Lower Midlinee  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Lower Midline
                      </label>
                      <Row className="">
                        <Col xs="12" md="4" lg="2" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.lower_midline_center
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                lower_midline_center: true,
                                lower_midline_shifted_left: false,
                                lower_midline_shifted_right: false,
                                lower_midline: "centered",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                lower_midline_center: true,
                                lower_midline_shifted_left: false,
                                lower_midline_shifted_right: false,
                                lower_midline: "centered",
                              });
                            }}
                          >
                            Centered
                          </Button>
                        </Col>
                        <Col xs="12" md="4" lg="2" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.lower_midline_shifted_left
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                lower_midline_center: false,
                                lower_midline_shifted_left: true,
                                lower_midline_shifted_right: false,
                                lower_midline: "shifted_left",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                lower_midline_center: false,
                                lower_midline_shifted_left: true,
                                lower_midline_shifted_right: false,
                                lower_midline: "shifted_left",
                              });
                            }}
                          >
                            Shifted Left
                          </Button>
                        </Col>
                        <Col xs="12" md="4" lg="2" className="p-1">
                          <Button
                            className={`btn ${
                              this.state.lower_midline_shifted_right
                                ? `green-btn-active`
                                : `green-btn-not-active`
                            } text-center w-100 h-100`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                lower_midline_center: false,
                                lower_midline_shifted_left: false,
                                lower_midline_shifted_right: true,
                                lower_midline: "shifted_right",
                              });
                              this.props.updateStore({
                                ...this.props.getStore(),
                                lower_midline_center: false,
                                lower_midline_shifted_left: false,
                                lower_midline_shifted_right: true,
                                lower_midline: "shifted_right",
                              });
                            }}
                          >
                            Shifted Right
                          </Button>
                        </Col>
                      </Row>
                      {(this.state.lower_midline_shifted_left ||
                        this.state.lower_midline_shifted_right) && (
                        <Row className="p-2 mt-2 align-items-center">
                          <Col xs="12" md="8" lg="4">
                            <label className="control-label title_active">
                              If shifted, displacement in mm
                            </label>
                          </Col>
                          <Col xs="12" md="4" lg="2">
                            <Input
                              autoComplete="off"
                              type="number"
                              className={`${notValidClasses.lowerMidlineDisplacemenCls} green_input`}
                              defaultValue={
                                this.state.lower_midline_displacement
                              }
                              onChange={(e) => {
                                this.setState({
                                  lower_midline_displacement: e.target.value,
                                });
                                this.props.updateStore({
                                  ...this.props.getStore(),
                                  lower_midline_displacement: e.target.value,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      {this.props.getStore().lower_midline === "" &&
                        this.props.getStore().validateTab1 && (
                          <div
                            className={notValidClasses.lowerMidlineValGrpCls}
                          >
                            {"Lower Midline is required"}
                          </div>
                        )}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalFindings);
