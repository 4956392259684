import React, { useState } from "react";
import SideCanvas from "./components/sideCanvas";
import Information_icon from "../../assets/images/dashboard/Information_icon.svg";

function AssessmentTool({  inHome, content }) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {inHome ? (
        <>
          <div
            className={`callout-text  s_card_grid cmb1`}
            onClick={handleShow}
          >
            <div>
              <img
                src={
                  Information_icon
                }
                alt={content.Information_icon}
              />
            </div>
            <div className="displayNone_inmobile">{content.evaluate_your_case}</div>
            <div className="displayNone">
             {content.evaluate}
              <br></br>{content.your_case}
            </div>
          </div>
        </>
      ) : (
        <div className="need-help-button" onClick={handleShow}>
          {content.need_help_choosing_your_package_type}
        </div>
      )}

      <SideCanvas show={show} onHide={handleClose} />
    </div>
  );
}

export default AssessmentTool;
