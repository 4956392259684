export const data = (content, teir) => {
  let teirs = {
    Bronze: {
      discount: "0%",
      discount_description: content.automatically_applied,
      educational_events: "image",
      educational_events_description: content.invites_will_be_sent_by_email,
      digita_marketing_consultation: "image",
      digita_marketing_consultation_description: content.book_your_session_now,
      advanced_digital_marketing_consultation: false,
      advanced_digital_marketing_consultation_description: false,
      expert_orthodontist_consultation: false,
      expert_orthodontist_consultation_description:
        content.book_your_session_now,
      commercial_business_consultation: content.free_1_session_month,
      commercial_business_consultation_description:
        content.book_your_session_now,
      dedicated_clinical_specialist_CAD_designer: false,
      dedicated_clinical_specialist_CAD_designer_description: false,
      inclinic_marketing_kit: "image",
      inclinic_marketing_kit_description:
        content.Automatically_sent_if_not_received,
      click_here: content.click_here,
      practicep_laque: false,
      practicep_laque_description: false,
      // link
      digita_marketing_link:
      "https://docs.google.com/forms/d/e/1FAIpQLSdZC5dOAd29qcYe4JoemoRKaLwaNSNOYpSTzM6cCahVNfEYgQ/viewform",
       
      commercial_business_consultation_link:
        "https://forms.gle/VtHDHSS9h9LjMSAz9",
      expert_orthodontist_consultation_link:
        "https://calendly.com/eon-support/expert-orthodontic-consultation",
      inclinic_marketing_kit_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
    },

    Silver: {
      discount: "10.0%",
      discount_description: content.automatically_applied,
      educational_events: "image",
      educational_events_description: content.invites_will_be_sent_by_email,
      digita_marketing_consultation: "image",
      digita_marketing_consultation_description: content.book_your_session_now,
      advanced_digital_marketing_consultation: false,
      advanced_digital_marketing_consultation_description: false,
      expert_orthodontist_consultation: false,
      expert_orthodontist_consultation_description:
        content.book_your_session_now,
      commercial_business_consultation: content.free_2_session_month,
      commercial_business_consultation_description:
        content.book_your_session_now,
      dedicated_clinical_specialist_CAD_designer: false,
      dedicated_clinical_specialist_CAD_designer_description: false,
      inclinic_marketing_kit: "image",
      inclinic_marketing_kit_description:
        content.Automatically_sent_if_not_received,
      click_here: content.click_here,
      practicep_laque: false,
      practicep_laque_description: false,
      // link
      digita_marketing_link:
        "https://docs.google.com/forms/d/e/1FAIpQLSdZC5dOAd29qcYe4JoemoRKaLwaNSNOYpSTzM6cCahVNfEYgQ/viewform",
      commercial_business_consultation_link:
        "https://forms.gle/VtHDHSS9h9LjMSAz9",
      expert_orthodontist_consultation_link:
        "https://calendly.com/eon-support/expert-orthodontic-consultation",
      inclinic_marketing_kit_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
    },

    Gold: {
      discount: "17.5%",
      discount_description: content.automatically_applied,
      educational_events: "image",
      educational_events_description: content.invites_will_be_sent_by_email,
      digita_marketing_consultation: "image",
      digita_marketing_consultation_description: content.book_your_session_now,
      advanced_digital_marketing_consultation: false,
      advanced_digital_marketing_consultation_description: false,
      expert_orthodontist_consultation: content.free_1_session_month,
      expert_orthodontist_consultation_description:
        content.book_your_session_now,
      commercial_business_consultation: content.free_3_session_month,
      commercial_business_consultation_description:
        content.book_your_session_now,
      dedicated_clinical_specialist_CAD_designer: false,
      dedicated_clinical_specialist_CAD_designer_description: false,
      inclinic_marketing_kit: "image",
      inclinic_marketing_kit_description:
        content.Automatically_sent_if_not_received,
      click_here: content.click_here,
      practicep_laque: "image",
      practicep_laque_description: content.sent_directly_to_you_if_not_received,
      // link

      digita_marketing_link:
        "https://docs.google.com/forms/d/e/1FAIpQLSdZC5dOAd29qcYe4JoemoRKaLwaNSNOYpSTzM6cCahVNfEYgQ/viewform",
      expert_orthodontist_consultation_link:
        "https://calendly.com/expert_consultations/expert-orthodontic-consultation?month=2023-08",
      commercial_business_consultation_link:
        "https://forms.gle/VtHDHSS9h9LjMSAz9",
      inclinic_marketing_kit_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
      practicep_laque_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
    },
    Platinum: {
      discount: "22.5%",
      discount_description: content.automatically_applied,
      educational_events: "image",
      educational_events_description: content.invites_will_be_sent_by_email,
      digita_marketing_consultation: false,
      digita_marketing_consultation_description: false,
      advanced_digital_marketing_consultation: "image",
      advanced_digital_marketing_consultation_description:
        content.book_your_session_now,
      expert_orthodontist_consultation: content.free_2_session_month,
      expert_orthodontist_consultation_description:
        content.book_your_session_now,
      commercial_business_consultation: content.free_4_session_month,
      commercial_business_consultation_description:
        content.book_your_session_now,
      dedicated_clinical_specialist_CAD_designer: false,
      dedicated_clinical_specialist_CAD_designer_description: false,
      inclinic_marketing_kit: "image",
      inclinic_marketing_kit_description:
        content.Automatically_sent_if_not_received,
      click_here: content.click_here,
      practicep_laque: "image",
      practicep_laque_description: content.sent_directly_to_you_if_not_received,
      // link
      advanced_digital_marketing_link: "https://forms.gle/mWDJdyGYfKnPCzXRA",
      expert_orthodontist_consultation_link:
        "https://calendly.com/expert_consultations/expert-orthodontic-consultation?month=2023-08",
      commercial_business_consultation_link:
        "https://forms.gle/VtHDHSS9h9LjMSAz9",
      inclinic_marketing_kit_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
      practicep_laque_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
    },
    Diamond: {
      discount: "27.5%",
      discount_description: content.automatically_applied,
      educational_events: "image",
      educational_events_description: content.invites_will_be_sent_by_email,
      digita_marketing_consultation: false,
      digita_marketing_consultation_description: false,
      advanced_digital_marketing_consultation: "image",
      advanced_digital_marketing_consultation_description:
        content.book_your_session_now,
      expert_orthodontist_consultation: content.free_3_session_month,
      expert_orthodontist_consultation_description:
        content.book_your_session_now,
      commercial_business_consultation: content.custom,
      commercial_business_consultation_description:
        content.book_your_session_now,
      dedicated_clinical_specialist_CAD_designer: "image",
      dedicated_clinical_specialist_CAD_designer_description:
        content.book_time_with_your_dedicated_specialist_via_email,
      inclinic_marketing_kit: "image",
      inclinic_marketing_kit_description:
        content.Automatically_sent_if_not_received,
      click_here: content.click_here,
      practicep_laque: "image",
      practicep_laque_description: content.sent_directly_to_you_if_not_received,

      // link
      advanced_digital_marketing_link: "https://forms.gle/mWDJdyGYfKnPCzXRA",
      expert_orthodontist_consultation_link:
        "https://calendly.com/expert_consultations/expert-orthodontic-consultation?month=2023-08",
      commercial_business_consultation_link:
        "https://forms.gle/VtHDHSS9h9LjMSAz9",
      inclinic_marketing_kit_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
      practicep_laque_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
    },
    Elite: {
      discount: "30%",
      discount_description: content.automatically_applied,
      educational_events: "image",
      educational_events_description: content.invites_will_be_sent_by_email,
      digita_marketing_consultation: false,
      digita_marketing_consultation_description: false,
      advanced_digital_marketing_consultation: "image",
      advanced_digital_marketing_consultation_description:
        content.book_your_session_now,
      expert_orthodontist_consultation: content.custom,
      expert_orthodontist_consultation_description:
        content.book_your_session_now,
      commercial_business_consultation: content.custom,
      commercial_business_consultation_description:
        content.book_your_session_now,
      dedicated_clinical_specialist_CAD_designer: "image",
      dedicated_clinical_specialist_CAD_designer_description:
        content.book_time_with_your_dedicated_specialist_via_email,
      inclinic_marketing_kit: "image",
      inclinic_marketing_kit_description:
        content.Automatically_sent_if_not_received,
      click_here: content.click_here,
      practicep_laque: "image",
      practicep_laque_description: content.sent_directly_to_you_if_not_received,
      // link
      advanced_digital_marketing_link: "https://forms.gle/mWDJdyGYfKnPCzXRA",
      expert_orthodontist_consultation_link:
        "https://calendly.com/expert_consultations/expert-orthodontic-consultation?month=2023-08",
      commercial_business_consultation_link:
        "https://forms.gle/VtHDHSS9h9LjMSAz9",
      inclinic_marketing_kit_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
      practicep_laque_link:
        "https://calendly.com/eon-support/commercial-support?primary_color=00c4b3&month=2022-06&back=1",
    },
  };

  return teirs[teir];
};
