import React, { useState } from "react";
import { Accordion, Col, Row, Tooltip } from "react-bootstrap";
import { Button } from "reactstrap";
import Image from "react-bootstrap/Image";
import Offcanvas from "react-bootstrap/Offcanvas";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import infoDark from "../../assets/icons/infoDark.png";
import infoLight from "../../assets/icons/infoLight.png";

import { Category, CatgType, Question } from "../Cards";
import RecomendtaionBox from "../recomendation";
import { categories, questionsAnswers } from "../../utils";
import AppAccordion from "../DropDown";
import styles from "./style.module.css";

const initialCount = {
  none: 0,
  basic: 0,
  plus: 0,
  pro: 0,
};

function SideCanvas({ show, onHide }) {
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [packageTypeCount, setPackageTypeCount] = useState({ ...initialCount });
  const [requiredQuestions, setRequiredQuestions] = useState(0);
  const [araiExpended, setAraiExpended] = useState("");

  const handlePackageType = (type, add = true) => {
    setPackageTypeCount((prev) => ({
      ...prev,
      [type]: add ? prev[type] + 1 : prev[type] - 1,
    }));
  };

  const handleNext = () => {
    selectedCategories.length > 0
      ? setPageIndex((prev) => ++prev)
      : alert("Please Select Category");
  };

  const handleSubmit = () => {
    Object.keys(selectedAnswers).length > 0
      ? setPageIndex((prev) => ++prev)
      : alert("Please Select Answers");
  };

  let totalAnsweredQuestions = 0;
  for (const item in selectedAnswers) {
    totalAnsweredQuestions += Object.keys(selectedAnswers[item]).length;
  }

  let isCatSelected = selectedCategories.length > 0 ? false : true;
  let isAnswersSelected = !(totalAnsweredQuestions === requiredQuestions);

  // console.log("Start");
  // console.log(selectedCategories);
  // console.log(selectedAnswers);
  // console.log(packageTypeCount);
  // console.log(requiredQuestions);
  // console.log(araiExpended);
  // console.log("End");

  const handleReset = () => {
    setTimeout(() => {
      setPageIndex(0);
      setRequiredQuestions(0);
      setPackageTypeCount(initialCount);
      setSelectedCategories([]);
      setSelectedAnswers({});
    }, 1000);
  };

  return (
    <div className={styles.sideCanvas}>
      <Offcanvas
        show={show}
        onHide={() => {
          onHide();
          handleReset();
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Clinical assessment tool</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {pageIndex === 0 ? (
            <>
              <div className={styles.labelSec}>
                <p className={styles.canvasLabel}>
                  Please select the treatment needs:
                </p>
                <OverlayTrigger
                  placement="bottom-end"
                  // show={true}
                  overlay={
                    <Tooltip
                      id="button-tooltip-2 "
                      className="side-canvas-tooltip"
                    >
                      <Image
                        src={infoLight}
                        height="20px"
                        width="20px"
                        style={{ marginRight: 10 }}
                      />
                      <div>
                        <p>
                          Please choose the main malocclusion that you wish to
                          address in your clear aligner therapy.
                        </p>
                        <p>
                          Ex. If you have a case with Class II and Crowding and you wish to address Crowding, please pick Crowding only.
                        </p>
                        <p>You can select as many options as you'd like.</p>
                      </div>
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Image
                      {...triggerHandler}
                      className={styles.info}
                      ref={ref}
                      roundedCircle
                      src={infoDark}
                    />
                  )}
                </OverlayTrigger>
              </div>
              <Row className="container-assessment-tool">
                {categories.map((category) => {
                  const index = selectedCategories.findIndex(
                    (selectedCat) => selectedCat.key === category.key
                  );

                  const isActive = index < 0 ? false : true;

                  return (
                    <Col xs={6} key={category.key}>
                      <Category
                        {...category}
                        active={isActive}
                        onClick={() => {
                          let packageTypeC = { ...packageTypeCount };
                          let selectedAns = { ...selectedAnswers };
                          let categoryWiseQuestions =
                            questionsAnswers[category.key];

                          if (isActive) {
                            let data = selectedAns?.[category.key] || {};

                            for (const item in data) {
                              packageTypeC[data[item].type]--;
                            }

                            delete selectedAns?.[category.key];

                            setSelectedAnswers(selectedAns);
                            setPackageTypeCount(packageTypeC);
                          }

                          setRequiredQuestions((prev) => {
                            if (isActive) {
                              return prev - categoryWiseQuestions.length || 0;
                            }
                            return prev + categoryWiseQuestions.length;
                          });

                          setSelectedCategories((prevCat) => {
                            if (isActive) {
                              let prevU = [...prevCat];
                              prevU.splice(index, 1);
                              return [...prevU];
                            }
                            return [
                              ...prevCat,
                              {
                                ...category,
                                active: true,
                              },
                            ];
                          });
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : pageIndex === 1 ? (
            <>
              {selectedCategories.map((selectedCat) => (
                <CatgType
                  key={selectedCat.key}
                  img={selectedCat.img}
                  title={selectedCat.title}
                  onClick={() => setPageIndex(0)}
                />
              ))}
              <Accordion activeKey={araiExpended}>
                {selectedCategories.map((selectedCat) => {
                  let CatQueAns = questionsAnswers[selectedCat.key] || [];

                  return CatQueAns.map((selectedCatQA, qInd) => {
                    let parentKey = `${selectedCat.key}_${qInd}`;
                    let keyObj =
                      selectedAnswers?.[selectedCat.key]?.[parentKey];

                    return (
                      <div key={parentKey}>
                        {qInd === 0 && (
                          <p
                            style={{
                              margin: `24px 0 0 0`,
                              fontWeight: 600,
                            }}
                          >
                            {selectedCat.title}
                          </p>
                        )}
                        <AppAccordion
                          eventKey={parentKey}
                          className={`mt-2 ${keyObj?.type ? "checked" : ""}`}
                          title={selectedCatQA.question}
                          answer={
                            araiExpended === parentKey
                              ? ""
                              : selectedAnswers[selectedCat.key]?.[parentKey]
                                  ?.answer
                          }
                          onClick={(el) => {
                            if (!!!el.target.ariaExpanded) {
                              return false;
                            }

                            if (el.target.ariaExpanded === "true") {
                              setAraiExpended("");
                            } else {
                              setAraiExpended(parentKey);
                            }
                          }}
                        >
                          <Row>
                            {selectedCatQA.answers.map((answer, aInd) => {
                              let key = [`${selectedCat.key}_${qInd}_${aInd}`];

                              let isKeyFound =
                                selectedAnswers?.[selectedCat.key]?.[
                                  parentKey
                                ]?.[key];

                              return (
                                <Col xs={6} className="mb-3" key={key}>
                                  <Question
                                    {...answer}
                                    active={
                                      !!selectedAnswers?.[selectedCat.key]?.[
                                        parentKey
                                      ]?.[key]
                                    }
                                    onClick={() => {
                                      handlePackageType(
                                        answer.type,
                                        !isKeyFound
                                      );

                                      if (
                                        !!keyObj &&
                                        keyObj.type !== answer.type
                                      ) {
                                        handlePackageType(keyObj.type, false);
                                      }

                                      if (!isKeyFound) {
                                        setAraiExpended((prev) => {
                                          let [key, num] = prev.split("_");
                                          return `${key}_${++num}`;
                                        });
                                      }

                                      setSelectedAnswers((prev) => {
                                        if (
                                          typeof keyObj === "object" &&
                                          !Array.isArray(keyObj) &&
                                          keyObj !== null
                                        ) {
                                          let prevU = { ...prev };
                                          delete prevU?.[selectedCat.key]?.[
                                            parentKey
                                          ];

                                          if (!isKeyFound) {
                                            prevU = {
                                              ...prevU,
                                              [selectedCat.key]: {
                                                ...prevU[selectedCat.key],
                                                [parentKey]: {
                                                  type: answer.type,
                                                  answer: answer.ans,
                                                  [key]: true,
                                                },
                                              },
                                            };
                                          }
                                          return prevU;
                                        }

                                        return {
                                          ...prev,
                                          [selectedCat.key]: {
                                            ...prev[selectedCat.key],
                                            [parentKey]: {
                                              type: answer.type,
                                              answer: answer.ans,
                                              [key]: true,
                                            },
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                        </AppAccordion>
                      </div>
                    );
                  });
                })}
              </Accordion>
            </>
          ) : (
            <RecomendtaionBox packagesCount={packageTypeCount} />
          )}
        </Offcanvas.Body>
        <div className={styles.canvasFooter}>
          {pageIndex === 0 ? (
            <Button
              onClick={handleNext}
              className={styles.canvasBtn}
              disabled={isCatSelected}
            >
              Next
            </Button>
          ) : pageIndex === 1 ? (
            <Button
              onClick={handleSubmit}
              className={styles.canvasBtn}
              disabled={isAnswersSelected}
            >
              Submit
            </Button>
          ) : null}
        </div>
      </Offcanvas>
    </div>
  );
}

export default SideCanvas;
