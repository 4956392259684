import React from "react";
import { Button, CustomInput } from "reactstrap";
import { PopupContainer } from "./PopupContainer.js";
import clsx from "clsx";
import CustomRadioGroup from "../../components/radioGroup/CustomRadioGroup.js";
import { successToaster } from "../../services/toast.js";
import { changePackageApi } from "../../api/api.js";


class ArchSelectionPopupModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      archUpper: ["upper_only", "both"].includes(
        this.props.prescription.treat_arches
      ),

      archLower: ["lower_only", "both"].includes(
        this.props.prescription.treat_arches
      ),
    };
  }

  handleSubmit = () => {
    let data = {
      aligner_case: {
       
        treat_arches:
          this.state.archUpper && this.state.archLower
            ? "both"
            : this.state.archUpper && !this.state.archLower
            ? "upper_only"
            : !this.state.archUpper && this.state.archLower
            ? "lower_only"
            : "",
      },
    };

    changePackageApi(
      this.props.doctor_Id,
      this.props.case_Id,
      JSON.stringify(data)
    )
      .then((res) => {
        this.props.HandleArchSelectionPopup();
        this.props.GetAlignerCase();
        successToaster(
          this.props.content.arch_successfully_updated,
          this.props.content.Success
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleCancel = () => {
    this.setState({
      archUpper: ["upper_only", "both"].includes(
        this.props.prescription.treat_arches
      ),
      archLower: ["lower_only", "both"].includes(
        this.props.prescription.treat_arches
      ),
    });

    this.props.HandleArchSelectionPopup();
  };
  disabled = () => {
    if (this.state.archUpper === false && this.state.archLower === false)
      return true;
    return false;
  };
  render() {
    const { archUpper, archLower } = this.state;
    const { ArchSelectionPopup } = this.props;

    let content = this.props.content;

    return (
      <>
            <span className="dtw-relative">
          <span
            className="CaseType_change_button"
            onClick={this.props.HandleArchSelectionPopup}
          >
            {content.change}
          </span>
          {ArchSelectionPopup && (
            <PopupContainer
              arch={true}
              className={clsx("absolute", { ArchSelectionPopup })}
            >
              <>
                <div className="CaseType-popup-title ">
                  {content.change_arch_selection}{" "}
                </div>

                <div className="ArchSelectionCheckBox">
                  <div
                    className={`checkBox-arch-container ${
                      this.state.archUpper
                        ? "checkBox-arch-container-active "
                        : ""
                    }  `}
                  >
                    <CustomInput
                      id="checkboxArchImpressionUpper"
                      className={
                        this.state.archUpper
                          ? "replacment-checkbox text_malocclusions body-text"
                          : "text_malocclusions body-text"
                      }
                      style={{ pointerEvents: "cursor" }}
                      value={archUpper}
                      type="checkbox"
                      checked={archUpper}
                      label="Upper"
                      onChange={() => {
                        this.setState({
                          archUpper: !archUpper,
                        });
                      }}
                    />
                  </div>
                  <div
                    className={`checkBox-arch-container ${
                      this.state.archLower
                        ? "checkBox-arch-container-active "
                        : ""
                    }  `}
                  >
                    <CustomInput
                      id="checkboxArchImpressionLower"
                      className={
                        archLower
                          ? "replacment-checkbox text_malocclusions body-text"
                          : "text_malocclusions body-text"
                      }
                      style={{ pointerEvents: "cursor" }}
                      type="checkbox"
                      checked={archLower}
                      value={archLower}
                      label="Lower"
                      onChange={() => {
                        this.setState({
                          archLower: !archLower,
                        });
                      }}
                    />
                  </div>

                  <div></div>
                </div>

                <div className="Arch-popup-button">
                  <div></div>
                  <div
                    onClick={this.handleCancel}
                    className="caseType-popup-button-close"
                  >
                    {content.cancel}
                  </div>
                  <div
                    className={clsx("caseType-popup-button-add", {
                      disabled: this.disabled(),
                    })}
                    onClick={this.handleSubmit}
                  >
                    {" "}
                    {content.save}
                  </div>
                </div>
              </>
            </PopupContainer>
          )}
        </span>
      </>
    );
  }
}

export default ArchSelectionPopupModule;