import { teeth } from "./teeth_v4";
import { teeth_pdf } from "./teeth";
export const upper_teeth = {
  ...initRightTeethUpper("upper_r_"),
  ...initLeftTeethUpper("upper_l_"),
};

export const lower_teeth = {
  ...initRightTeethLower("lower_r_"),
  ...initLeftTeethLower("lower_l_"),
};

function initRightTeethUpper(key) {
  let num = 8;
  let tooth_key = 1;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        image_pdf: teeth_pdf[`${key}${num}`],
        active_pdf: teeth_pdf[`${key}${num}_active`],
        not_active_pdf: teeth_pdf[`${key}${num}`],
        update: teeth[`${key}${num}_updated`],
        restrect: teeth[`${key}${num}_restrect`],
        restrect_pdf: teeth_pdf[`${key}${num}_restrect`],
        missing_pdf: teeth_pdf[`${key}${num}_missing`],
        missing: teeth[`${key}${num}_missing`],
        active_movement: teeth[`${key}${num}_active_movement`],
        value: false,
        key: tooth_key
      },
    };
    num--;
    tooth_key++;
  }
  return rightTeeth;
}

function initLeftTeethUpper(key) {
  let num = 1;
  let tooth_key = 9
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        image_pdf: teeth_pdf[`${key}${num}`],
        active_pdf: teeth_pdf[`${key}${num}_active`],
        not_active_pdf: teeth_pdf[`${key}${num}`],
        update: teeth[`${key}${num}_updated`],
        restrect: teeth[`${key}${num}_restrect`],
        restrect_pdf: teeth_pdf[`${key}${num}_restrect`],
        missing_pdf: teeth_pdf[`${key}${num}_missing`],
        active_movement: teeth[`${key}${num}_active_movement`],
        missing: teeth[`${key}${num}_missing`],
        value: false,
        key: tooth_key
      },
    };
    num++;
    tooth_key++;
  }
  return rightTeeth;
}

function initRightTeethLower(key) {
  let num = 8;
  let tooth_key = 1;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        image_pdf: teeth_pdf[`${key}${num}`],
        active_pdf: teeth_pdf[`${key}${num}_active`],
        not_active_pdf: teeth_pdf[`${key}${num}`],
        update: teeth[`${key}${num}_updated`],
        restrect: teeth[`${key}${num}_restrect`],
        restrect_pdf: teeth_pdf[`${key}${num}_restrect`],
        missing_pdf: teeth_pdf[`${key}${num}_missing`],
        active_movement: teeth[`${key}${num}_active_movement`],
        missing: teeth[`${key}${num}_missing`],
        value: false,
        key: tooth_key
      },
    };
    num--;
    tooth_key++;
  }
  return rightTeeth;
}

function initLeftTeethLower(key) {
  let num = 1;
  let tooth_key = 9;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        image_pdf: teeth_pdf[`${key}${num}`],
        active_pdf: teeth_pdf[`${key}${num}_active`],
        not_active_pdf: teeth_pdf[`${key}${num}`],
        update: teeth[`${key}${num}_updated`],
        restrect: teeth[`${key}${num}_restrect`],
        restrect_pdf: teeth_pdf[`${key}${num}_restrect`],
        missing_pdf: teeth_pdf[`${key}${num}_missing`],
        active_movement: teeth[`${key}${num}_active_movement`],
        missing: teeth[`${key}${num}_missing`],
        value: false,
        key: tooth_key
      },
    };
    num++;
    tooth_key++;
  }
  return rightTeeth;
}