import { teeth_arr } from "../redux/newCase/teethArr";
import { upper_teeth, lower_teeth } from "../redux/customeTeeth/init_teeth";

export function mapIPRTeethFromApi(data) {
  let new_lower_teeth = data.lower_teeth;
  let new_upper_teeth = data.upper_teeth;

  let all_teeth = teeth_arr;

  //add images to object
  for (var key in new_lower_teeth) {
    new_lower_teeth[key]["active"] = lower_teeth[key].active;
    new_lower_teeth[key]["not_active"] = lower_teeth[key].not_active;
    new_lower_teeth[key]["hover"] = lower_teeth[key].hover;
    new_lower_teeth[key]["image"] = lower_teeth[key].image;
    new_lower_teeth[key]["restrect"] = lower_teeth[key].restrect;
    new_lower_teeth[key]["active_pdf"] = lower_teeth[key].active_pdf;
    new_lower_teeth[key]["not_active_pdf"] = lower_teeth[key].not_active_pdf;
    new_lower_teeth[key]["image_pdf"] = lower_teeth[key].image_pdf;
    new_lower_teeth[key]["restrect_pdf"] = lower_teeth[key].restrect_pdf;
    new_lower_teeth[key]["missing_pdf"] = lower_teeth[key].missing_pdf;

    if (new_lower_teeth[key].missing_ipr_tooth === true) {
      var index = all_teeth.indexOf(
        new_lower_teeth[key]?.ToothSystemName?.universal
      );
      if (index > -1) {
        all_teeth.splice(index, 1);
      }
    }
  }
  //add images to object
  for (var key2 in new_upper_teeth) {
    new_upper_teeth[key2]["active"] = upper_teeth[key2].active;
    new_upper_teeth[key2]["not_active"] = upper_teeth[key2].not_active;
    new_upper_teeth[key2]["hover"] = upper_teeth[key2].hover;
    new_upper_teeth[key2]["image"] = upper_teeth[key2].image;
    new_upper_teeth[key2]["restrect"] = upper_teeth[key2].restrect;
    new_upper_teeth[key2]["active_pdf"] = upper_teeth[key2].active_pdf;
    new_upper_teeth[key2]["not_active_pdf"] = upper_teeth[key2].not_active_pdf;
    new_upper_teeth[key2]["image_pdf"] = upper_teeth[key2].image_pdf;
    new_upper_teeth[key2]["restrect_pdf"] = upper_teeth[key2].restrect_pdf;
    new_upper_teeth[key2]["missing_pdf"] = upper_teeth[key2].missing_pdf;

    if (new_upper_teeth[key2].missing_ipr_tooth === true) {
      var index = all_teeth.indexOf(
        new_upper_teeth[key2]?.ToothSystemName?.universal
      );
      if (index > -1) {
        all_teeth.splice(index, 1);
      }
    }
  }



  return {
    lower_teeth: new_lower_teeth,
    upper_teeth: new_upper_teeth,
    all: all_teeth,
  };
}
