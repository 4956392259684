import React, { Component } from "react";
import moment from "moment";
// import { ExportCSV } from "./ExportCSV";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  ...state,
});
class History extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state */
    this.state = {};
  }
  render() {
    const { content } = this.props.languageReducer;
    const { date_format } = this.props.userReducer.currentUser;
    const { history } = this.props.caseReducer.case;
    // let data = history.map((prop, key) => {
    //   return {
    //     "Created At": moment(new Date(prop.created_at)).format(date_format),
    //     Comment: `${history[prop.key]} by ${prop.done_by ? prop.done_by : "Doctor "
    //       } `,
    //   };
    // });
    return (
      <ul className="case-history new-history">
        {/*--------------------------------------------------------------------------------*/}
        {/* History Table*/}
        {/*--------------------------------------------------------------------------------*/}
        {history
          .filter((elem) => elem.key !== "aligner_case.support")
          .map((prop, key) => {
            return (
              <li key={key}>
                <div key={key} className="history-card">
                  <div className="text-center body-text">
                    {moment(new Date(prop.created_at)).format(date_format)}
                  </div>
                  <div className="body-text">
                    {`${content[prop.key]}  ${
                      prop.done_by
                        ? ""
                        : prop.key === "aligner_case.expired_and_dormant"
                        ? ""
                        : "by Doctor "
                    } `}
                  </div>
                </div>
              </li>
            );
          })}

        {/*--------------------------------------------------------------------------------*/}
        {/* End [History Table*/}
        {/*--------------------------------------------------------------------------------*/}
      </ul>
    );
  }
}
export default connect(mapStateToProps)(History);
