import React from "react";
import back from "../../assets/images/icon/back.svg"

const Back = ({ custom, link, props }) => {

    return (
        <div className="back" >
            <img src={back} alt="back" onClick={() => { custom ? props.history.push(link) : window.history.back() }} />
        </div>
    );
};

export default Back;
