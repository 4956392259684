import React, { Component } from "react";


import { connect } from "react-redux";
import { mapIPRTeethOptionsToApi } from "../../../services/mapIPRTeethOptionsToApi";
import { newMapDataWithReducerTS } from "../../../services/newDataMapperTS";
import { clearAdvancedModules, updateAllAdvancedModules } from "../../../redux/CaseSubmission/action"
import { allModules } from "../../../redux/advanced/action";
import {
  Clear_Teeth_Data,
  Case_Extraction,
  Case_Attachments,
  Case_IPR,
  Case_Pontics,
  Case_Overcorrections,
  Case_Torque_Enhancers,
  Case_Arch_Expansion,
  Case_Elastics,
  Case_Elastics_Buttons,
  Case_Elastics_Cuts
} from "../../../redux/customeTeeth/action";
import {
  availableModules,
  activateModules,
} from "../../../redux/advanced/action";
import ToothRateMovement from "./TSModules/ModulesComponents/ToothRateMovement"
import ArchExpansion from "./TSModules/ModulesComponents/ArchExpansion";
import Attachments from "./TSModules/ModulesComponents/Attachments";
import BiteRamps from "./TSModules/ModulesComponents/BiteRamps";
import Elastics from "./TSModules/ModulesComponents/Elastics";
import Extraction from "./TSModules/ModulesComponents/Extraction";
import IPR from "./TSModules/ModulesComponents/IPR";
import OverCorrection from "./TSModules/ModulesComponents/OverCorrection";
import PassiveAligner from "./TSModules/ModulesComponents/PassiveAligner";
import Pontics from "./TSModules/ModulesComponents/Pontics";
import ToothSizeDiscrepancy from "./TSModules/ModulesComponents/ToothSizeDiscrepancy";
import TorqueEnhancers from "./TSModules/ModulesComponents/TorqueEnhancers";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  availableModules: (payload) => dispatch(availableModules(payload)),
  activateModules: (payload) => dispatch(activateModules(payload)),
  clearAdvancedModules: (payload) => dispatch(clearAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),
  Case_Extraction: (payload) => dispatch(Case_Extraction(payload)),
  Case_Attachments: (payload) => dispatch(Case_Attachments(payload)),
  Case_IPR: (payload) => dispatch(Case_IPR(payload)),
  Case_Pontics: (payload) => dispatch(Case_Pontics(payload)),
  Case_Overcorrections: (payload) => dispatch(Case_Overcorrections(payload)),
  Case_Torque_Enhancers: (payload) => dispatch(Case_Torque_Enhancers(payload)),
  Case_Arch_Expansion: (payload) => dispatch(Case_Arch_Expansion(payload)),
  Case_Elastics: (payload) => dispatch(Case_Elastics(payload)),
  Case_Elastics_Buttons: (payload) => dispatch(Case_Elastics_Buttons(payload)),
  Case_Elastics_Cuts: (payload) => dispatch(Case_Elastics_Cuts(payload)),
  updateAllAdvancedModules: (payload) =>
    dispatch(updateAllAdvancedModules(payload)),
  allModules: (payload) => dispatch(allModules(payload)),

});

class AppliedTreatment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finished: false,
      showCard: false,
      showModules: true,

    }
  }

  componentDidMount() {
    let newData = newMapDataWithReducerTS(this.props.advanced_modules);
    this.props.updateAllAdvancedModules(newData.advanced_modules);
    this.props.allModules(this.props.mapped_advanced_modules);
    this.props.Case_Extraction(newData.advanced_modules.extraction.teeth);
    this.props.Case_Attachments(
      newData.advanced_modules.attachments.attachmentsRestriction
    );
    this.props.Case_IPR(newData.advanced_modules.ipr.iprRestriction);
    this.props.Case_Pontics(newData.advanced_modules.pontics.teeth);
    this.props.Case_Torque_Enhancers(
      newData.advanced_modules.torqueEnhancers.teeth
    );
    this.props.Case_Elastics(newData.advanced_modules.elastics.teeth);
    this.props.Case_Elastics_Buttons(newData.advanced_modules.elastics.buttons_teeth);
    this.props.Case_Elastics_Cuts(newData.advanced_modules.elastics.cuts_teeth);

    this.setState({ finished: true })

  }
  /*--------------------------------------------------------------------------------*/
  /*toggle modals                                          */
  /*--------------------------------------------------------------------------------*/
  toggleModals = (id) => {
    this.setState({ showCard: true });
    this.setState({ [`${id}_modal`]: !this.state[`${id}_modal`], active_module: id, showModules: false });
  };
  toggleModalsClose = (id) => {
    this.setState({ showCard: false });
    this.setState({ [`${id}_modal`]: !this.state[`${id}_modal`], active_module: "", showModules: true });
  };

  toggleClinicProtocol = () => {
    this.setState({ showClinicProtocol: !this.state.showClinicProtocol })
  }

  ToggleOptions = () => {

    this.props.setProtocolFeedback(this.state.protocol_feedback);
    let mapped_data =
      mapIPRTeethOptionsToApi(this.props.teethIPROptions.teeth_ipr_data, this.props.teethIPROptions.saved_data, this.state.protocol_feedback, this.props.teethIPROptions.rejection_reason)


    this.DisApproveTreatmentSetup(mapped_data)


  }

  ExiteOptions = () => {
    this.props.ToggleOptions();
    this.setState({ showClinicProtocol: false })
  }

  getallts = () => {
    this.props.GetAllTs()
  }

  render() {
    const { showModules, active_module } = this.state;
    return (

      <div className="total-height">
        <div className="active-advanced-module-ts ">
          {showModules && this.state.finished && this.props.advanced_modules // 👈 null and undefined check
            && Object.keys(this.props.advanced_modules).length !== 0 && this.props?.mapped_advanced_modules
              .filter((value, index) => {
                return value.active === true && (value.key !== "archExpansion" && value.key !== "overCorrection" && value.key !== "malocclusion");
              })
              .map((values, index) => (

                <div
                  style={{ cursor: "pointer", width: "100%" }}


                  className={`${(this.state.showCard) ? `s_card_grid_activeTS ss ` : `s_card_gridTS`}`}
                  onClick={() => {
                    this.toggleModals(values.key);

                  }}
                  key={index}
                >

                  <div
                    style={{ cursor: "pointer", width: "100%" }}
                  >

                    {
                      <img
                        src={this.state.active_module ? values.active_img : values.img}
                        className="modules-img-ts"
                        alt="Aligner Treatment"
                      />}


                  </div>
                  <div className="m-0">
                    {values.title}
                  </div>
                </div>
              ))}

        </div>




        <div className="container-disable-layout">

          <br />
          <div className="">
            {this.state.ipr_modal && <IPR save={() => this.activateModules("ipr")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.extraction_modal && <Extraction save={() => this.activateModules("extraction")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.attachments_modal && <Attachments save={() => this.activateModules("attachments")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.pontics_modal && <Pontics save={() => this.activateModules("pontics")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.overCorrection_modal && <OverCorrection save={() => this.activateModules("overCorrection")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.passiveAligners_modal && <PassiveAligner save={() => this.activateModules("passiveAligners")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.toothSizeDiscrepancy_modal && <ToothSizeDiscrepancy save={() => this.activateModules("toothSizeDiscrepancy")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.torqueEnhancers_modal && <TorqueEnhancers save={() => this.activateModules("torqueEnhancers")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.biteRamps_modal && <BiteRamps save={() => this.activateModules("biteRamps")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.archExpansion_modal && <ArchExpansion save={() => this.activateModules("archExpansion")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.elastics_modal && <Elastics save={() => this.activateModules("elastics")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}
            {this.state.teethMovement_modal && <ToothRateMovement save={() => this.activateModules("teethMovement")} handleSubmit={this.handleSubmit} summary={true} toggleModalsClose={this.toggleModalsClose} active_module={active_module} />}

          </div>
        </div>
      </div>


    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppliedTreatment);