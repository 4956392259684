import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input } from "reactstrap";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Overcorrection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useOverCorrection: this.props.caseSubmission.advanced_modules
        .overCorrection.useOverCorrection,
      overCorrection_details: this.props.caseSubmission.advanced_modules
        .overCorrection.details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "overCorrection",
      data: {
        useOverCorrection: this.state.useOverCorrection,
        teeth:
          this.props.customeTeethReducer.Case_Overcorrections
        ,
        details: this.state.overCorrection_details,
      },
    });
    this.props.save();
  };
  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { useOverCorrection, overCorrection_details } = this.state;
    return (
      <div>

        {/* <div>
          <div className="control-label title_active-gco text-center mb-2">
            Used Overcorrection for this case
            </div>
          <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
            <input
              type="range"
              step="50"
              onChange={(e) => {
                this.slider(e, "useOverCorrection")

                if (e?.target?.value === "0" || e?.target?.value === "50") {
                  this.setState({
                    overCorrection_details: ""
                  })
                  this.props.Clear_Teeth_Data("Case_Overcorrections")

                }
              }}
              value={this.sliderValue("useOverCorrection")}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}
            />
            <Row className="align-items-center ">
              <Col xs="4" md="4" lg="4" className="text-left">
                <label className="title_active">No</label>
              </Col>
              <Col xs="4" md="4" lg="4" className="text-center">
                <label className="title_active">EON to Decide</label>
              </Col>
              <Col xs="4" md="4" lg="4" className="text-right">
                <label className="title_active">Yes</label>
              </Col>
            </Row>
          </div>

        </div>
 */}

        <div className="auto_grid4 pb-3">
          <div className="control-label title_active-gco ">
            Use Overcorrection for this case
          </div>
          <div>
            <ToggleSwitch
              id="useOverCorrection"
              disabled={this.props.summary}

              checked={useOverCorrection}
              onChange={() => {
                this.setState({
                  useOverCorrection: !useOverCorrection,
                  ...useOverCorrection && {
                    overCorrection_details: ""
                  }
                });
                if (useOverCorrection) {
                  this.props.Clear_Teeth_Data("Case_Overcorrections")
                }
              }}
            />
          </div>
        </div>



        <div className="teeth_selector"> <CustomeTeeth
        advanced_Ts="true"
          action_key="Case_Overcorrections"
          disabled={!useOverCorrection || useOverCorrection === "eonToDecide"}
          summary={this.props.summary}
        /></div>

        <div>
          {" "}
          <Input
            className="mt-2"
            name="overCorrection_details"
            id="overCorrection_details"
            type="textarea"
            placeholder="Please add details"
            rows={5}
            value={overCorrection_details}
            onChange={(e) => {
              this.setState({ overCorrection_details: e.target.value });
            }}
            disabled={!useOverCorrection || useOverCorrection === "eonToDecide" || this.props.summary}
          />{" "}
        </div>
        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Overcorrection);
