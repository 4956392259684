export const en = {
  logout: "Logout",
  notification: "Notification",
  mark_notification_as_read: "Mark All Notifications As Read",
  confirm_logout: "confirm Logout",
  confirmation_logout: "Are You Sure you want to Logout ?",
  yes: "Yes",
  yes_as_needed: "Yes (As needed)",
  email_address: "Email address",
  setting: "Setting",
  middle_name: "Middle Name",
  email: "Email ",
  phone_no: "Phone No",
  subscribe: "Subscribe",
  address: "Address",
  zip: "Zip",
  phone: "Phone",
  delete_address: "Delete Address",
  confirmation_delete: "Are you sure you want to delete this address?",
  delete: "Delete",
  update_address: "Update Address",
  country: "Country",
  city: "City",
  line: "Line",
  state: "State",
  update: "Update",
  confirmation_cancel: "Cancel",
  new_address: "New Address",
  add: "Add",
  country_required: "country is required",
  city_required: "city is required",
  field_is_required: "field is required",
  create_new_address: "Create New address successfully",
  previous: "Previous",
  page: "Page",
  of: "of",
  rows: "rows",
  next: "Next",
  review_cases: "Review Cases",
  submit_case: "Submit case",

  doctor_preferences: "Doctor Preferences",
  preferences: "Preferences",
  eon_academy: "Eon academy",
  cases_requiring_your_attention: "Cases requiring your attention",
  continue_case_submission: "Continue Case Submission",
  review_rejected_records: "Review Rejected Records",
  approve_treatment_plan: "approve Treatment plan",
  mark_complete: "Mark Case as Complete",
  coming_soon: "Coming soon",
  aligner_cases: "Aligner Cases",
  all: "All",
  active: "Active",
  pending: "Pending",
  rejected: "Rejected",
  complete: "Complete",
  archived: "Archived",
  search: "Search",
  patient_name: "Patient Name",
  case_num: "Case#",
  data_submitted: "Data Submitted",
  status: "Status",
  action: "Action",
  archive: "Archive",
  archive_case: "Archive case",
  unlimited: "Unlimited",
  lite: "Lite",
  retainer: "Retainer",
  eon_basic: "Eon Basic",
  eon_plus: "Eon Plus",
  eon_pro: "Eon Pro",
  lite_single_arch: "Lite Single Arch",
  continue_submission: "Continue submission",
  update_records: "Update records",
  view_treatment_plan: "View Treatment plan",
  restore_case: "Restore case",
  generating_ts: "Generating TS",
  incomplete_submission: "Incomplete Submission",
  pending_ts_approval: "Pending Ts Approval",
  Pending_record_approval: "Pending Record Approval",
  pending_courier_notification: "Pending Courier Notification",
  records_rejected: "Records Rejected",
  rejected_ts: "Rejected TS",
  doctor_perfences: "Doctor Perfernces",
  doctor_perfences_without_advanced_options:
    "Doctor Perfernces are only applied to cases submitted without advanced options.",
  passive_aligners: "Passive aligners",
  eon_to_recommend: "Eon to recommend",
  no: "No",
  no_choice: "As Needed",
  upper_arch_leveling: "Upper arch leveling",
  arch_leveling: "Arch leveling",
  arch_leveling_options: "Arch Leveling options",
  level_incisal_edge: "Level incisal edge",
  level_gingival_margins: "level gingival margins",
  other_preferences: "Other preferences",
  ipr_timing: "IPR Timing",
  restrictions: "Restrictions",
  attachments: "Attachments",
  save_preferences: "Save Preferences",
  update_doctor_preferences: "update Doctor Preferences Succesfully",
  patient_Information: "Patient Information",
  first_name: "First Name",
  last_name: "Last Name",
  patient_email_address: "Patient Email Address",
  gender: "Gender",
  select: "Select",
  male: "Male",
  female: "Female",
  date_of_birth: "Date of Birth",
  dd_MM_yy: "dd/MM/yy",
  shipping_address: "Shipping address",
  add_new_address: "Add new Address",
  please_add_your_shipping_address: "Please add your Shipping   ",
  unliminted: "Unliminted",
  cancel_submission: "Cancel Submission",
  confirmation_cancel_submission:
    "Are you sure you would like to cancel this submission?",
  photos_x_rays_plus: "Photos + X-rays",
  first_name_required: "First Name is Required",
  last_name_required: "Last Name is Required",
  gender_required: "Gender is Required",
  shipping_address_required: "Shipping Address is Required",
  case_type_required: "Package type is required",
  photos_x_rays: "Photos & X-rays",
  front_smiling: "Front (smiling)",
  front_pose: "Front (non-smiling)",
  side_profile: "Profile",
  panoramic_xray: "panoramic x-ray",
  panoramic: "Panoramic",
  upper_occlusal: "Upper occlusal",
  frontal: "Frontal",
  lower_occlusal: "Lower occlusal",
  cephalometric_xray: "Cephalometric x-ray",
  cephalometric: "Cephalometric",
  optional: "Optional",
  optional2: " (Optional)",
  right_buccal: "Right buccal",
  left_buccal: "Left buccal",
  uploading: "Uploading",
  image_required: "image is Required",
  chief_complaint: "Chief complaint",
  treat_arch: "Treat Arch",
  upper: "Upper",
  lower: "Lower",
  upper_and_lower: "Upper & Lower",
  treatment_summay_and_instruction:
    "Treatment Summary and Specific Instructions",
  treatment_summay: "Treatment summary ",
  teeth_movement_restrictions: "Teeth movement restrictions",
  go_to_case_summary: "Go to case summary",
  or: "or",
  impression: "impression",
  select_advanced_options: "select advanced options",
  chief_complaint_required: "Chief Complaint is Required",
  treat_arches_required: "Treat Arches is Required",
  summary_and_special_instructions_required:
    "summary and special instructions is Required",
  summary_treatment_required: "Treatment Summary is Required",
  upload_3d_scans: "Upload 3D Scans",
  _3rd_party_integration: "3rd Party Integration",
  impression_pickup: "Impression Pickup",
  upload_impressions: "Upload Impressions",
  upload_upper_arch: "Upload Upper Arch",
  upload_lower_arch: "Upload Lower Arch",
  upper_arch_required: "Upper Arch is Required",
  lower_arch_required: "Lower Arch is Required",
  additional_comments: "Additional Comments",
  use_this_option_for_third_party_integration_systems_only:
    "Use this option for third party integration systems only",
  provide_links_to_3rd_party_integration_systems:
    "Provide links to 3rd party integration systems",
  _3shape_communicate_and_other_services:
    "(Ex.3shape communicate and other services)",
  please_choose_one_of_impression_methodes:
    "Please choose one of Impression Methodes",
  please_choose_one_to_upload: "Please choose one to upload",
  choose: "Choose",
  extraction: "Extraction",
  ipr: "IPR",
  ipr_preferred_steps: "IPR Preferred Steps",
  inter_proximal_reduction: "Interproximal reduction",
  pontics: "Pontics",
  overcorrection: "Overcorrection",
  passive_aligner: "Passive Aligner",
  tooth_size_discrepancy: "Tooth size discrepancy",
  torque_enhancers: "Torque enhancers",
  bite_ramps: "Bite ramps",
  arch_expansion: "Arch expansion",
  elastics: "Elastics",
  malocclusion: "Malocclusion",
  crossbite: "Crossbite",
  spacing: "Spacing",
  classII: "Class II",
  classIlI: "Class III",
  openbite: "Openbite",
  deepbite: "Deepbite",
  midline: "Midline",
  crowding: "Crowding",
  use_elastics_when_correcting: "Use elastics when correcting",
  placement: "Placement",
  treatment_options: "Treatment options",
  expansion_of_the_upper_arch: "Expansion of the upper arch",
  constriction_of_the_lower_arch:
    "Constriction of the lower arch (may require IPR)",
  combination_of_upper_expansion_and_lower_constriction:
    "Combination of upper expansion and lower constriction (may require IPR)",
  close_all_spaces: "Close all spaces",
  by_retraction_of_anteriors: "With retraction of anteriors",
  by_mesialisation: "With mesialisation",
  combination_of_both: "Combination of both",
  partially_close_and_follow_with_restorative_or_prosthodon_tics:
    "Partially close and follow with restorative or prosthodontic treatment",
  ipr_opposing: "IPR in opposing arch",
  extraction_of_two_upper_bicuspids: "Extraction of two upper bicuspids",
  extraction_of_upper_and_lower_bicuspids:
    "Extraction of upper and lower bicuspids",
  upper_arch_distalization: "Upper arch distalization",
  sequential_distalization_may_increase_number_of_steps_elastics_using_May_necessitate_extraction_of_wisdoms_if_present:
    "Sequential distalization- number of steps may increase- may necessitate use of elastics- may necessitate extraction of third molars if present",
  lower_arch_mesialization: "Lower arch mesialization",
  sequential_mesialization_may_increase_number_of_steps_elastics_using:
    "Sequential mesialization - may increase number of steps - elastics using",
  leaving_the_molars_in_classII_and_doing_posterior_reproximation:
    "Leaving the molars in Class II and Performing posterior reproximation (IPR distal of canine to molar) as needed to improve the canine relationship.",
  maintain_classII_with_esthetic_anterior_alignment_only:
    "Maintain Class II with esthetic anterior alignment only",
  advance_upper_sby_aligners_and_classIII_elastics:
    "Advance uppers with aligners",
  retract_lowers_by_extraction_and_classIII_elastics:
    "Retract lowers with extraction",
  retract_lowers_after_ipr: "Retract lowers after IPR",
  esthetic_alignment_only: "Esthetic alignment only",
  pre_surgical_alignment_followed_by_surgery:
    "Pre-surgical alignment followed by surgery",
  extrude_anterior_teeth_only: "Extrude anterior teeth only",
  intrude_posterior_teeth_only: "Intrude posterior teeth only",
  both: "Both",
  intrude_upper_anteriors: "Intrude upper anteriors (may require IPR)",
  intrude_lower_anteriors: "Intrude lower anteriors (may require IPR)",
  extrude_posteriors: "Extrude posteriors",
  upper_midline: "Upper Midline",
  lower_midline: "Lower Midline",
  centered: "Centered",
  shifted_left: "Shifted Left",
  shifted_right: "Shifted Right",
  if_shifted_displacement_in_mm: "If shifted, displacement in mm",
  treat_midline_by: "Treat midline by",
  accept_resulting_midline: "Accept resulting midline",
  maintain_intial_midline: "Maintain intial midline",
  improve_with_ipr: "Improve with IPR",
  improve_with_extraction: "Improve with extraction",
  use_extraction_when_correcting: "Use extraction when correcting",
  resolve_with_proclination: "Resolve with Proclination",
  resolve_with_expansion: "Resolve with Expansion",
  resolve_with_ipr: "Resolve with IPR",
  resolve_with_distalisation: "Resolve with Distalisation",
  save: "Save",
  extract: "Extract",
  use_ipr_for_this_case: "Use IPR for this case   ",
  use_attachments_for_this_case: "Use attachments for this case   ",
  attachments_timing: "Attachments timing",
  use_pontics_for_this_case: "Use pontics for this case  ",
  use_overcorrection_for_thiscase: "Use Overcorrection for this case  ",
  add_details: "add details",
  address_discrepancy_for_this_case: "Address discrepancy for this case  ",
  leave_spaces_around_the_lateral: "Leave space around the laterals",
  leave_space_distal_to_lateral: "Leave space distal to the laterals",
  other: "Other",
  use_passive_aligners_for_this_case: "Use Passive aligners for this case ",
  add_passive_aligners_at_the_beginning:
    "Add passive aligners at the beginning of the treatment",
  add_passive_aligners_at_the_end:
    "Add passive aligners at the end of the treatment",
  use_torque_enhancers_for_this_case: "Use torque enhancers for this case ",
  use_bite_ramps_for_this_case: "Use bite ramps for this case ",
  incisors: "Incisors",
  incisors_and_canines: "Incisors and canines",
  use_arch_expansion_for_this_case: "Use Arch Expansion for this case  ",
  increasing_the_arch_width_between_canines_remolars_and_molars:
    "Increasing the arch width between canines, premolars and molars",
  increasing_the_arch_width_between_canines_premolars_only:
    "Increasing the arch width between canines, premolars only .",
  increasing_the_arch_width_between_premolars_and_molars_only:
    "Increasing the arch width between premolars and molars only",
  buttons: "Buttons",
  cuts: "Precision cuts",
  case_submission: " Case Submission ",
  patient_information: "Patient Information",
  impressions: "Impressions",
  prescription: "Prescription",
  advanced_treatment_options: "Advanced Treatment Options",
  summary: "Summary",
  details: "Details",
  records: "Records",
  inbox: "Inbox",
  history: "History",
  full_name: "Full Name",
  uuid: "UUID",
  case_type: "Case type",
  case_status: "Case Status",
  loading_document: "Loading document",
  download_treatment_pdf: "Download Treatment PDF",
  archive_a_case: "Archive a Case",
  adjustment: "Adjustment",
  request_replacement_aligners: "Request Replacement Aligners",
  request_retainer: "Request Retainer",
  pause: "Pause",
  resume: "Resume",
  mark_as_completed: "Mark as completed",
  restore_a_case: "Restore a Case",
  confirmation_archive: "Are you sure you want to archive this case?",
  cancel: "Cancel",
  new_adjustment: "New Adjustment",
  adjusment_info: "Adjustment Info",
  current_step_numbers: "Current Step Numbers",
  last_step_that_was_tracking: "Last Step That Was Tracking",
  upper_step: "Upper step #",
  lower_step: "Lower step #",
  reason_for_submission: "Reason for Submission",
  additional_instructions: "Additional Instructions",
  save_next: "Save & Next",
  upload_digital_impressions: "Upload Digital Impressions",
  upload_upper_and_lower_3d_models_by_clicking_the_images_on_the_right:
    "Upload upper and lower 3D models by clicking the images on the right",
  other_digital_means: "Other Digital Means",
  use_this_option_for_third_party_integration_systemsonly:
    "Use this option for third party integration systems only (for example: 3shape communicate)",
  arrange_pickup: "Arrange Pickup",
  use_this_option_to_send_traditional_silicon_impressions_to_eon_via_courier:
    "Use this option to send traditional silicon impressions to eon via courier",
  back: "Back",
  optional_photos: "Optional Photos",
  click_on_an_image_to_upload: "Click on an image to upload",
  submit: "Submit",
  replacement_aligners_request: "Replacement Aligners Request",
  new_replacements: "New Replacements",
  requested_replacement: "Requested Replacement",
  step_number: "Step Number",

  submit_request: "Submit Request",
  the_following_replacement_aligners_have_been_requested:
    "The following replacement aligners have been requested",
  new_retainers: "New Retainers",
  requested_retainers: "Requested Retainers",
  sets_number: "Sets Number",
  the_following_retainers_have_been_requested:
    "The following retainers have been requested",
  pause_aligner_case: "Pause Aligner Case",
  confirmation_pause: "Are You Sure you want to Pause this Aligner Case ?",
  resume_aligner_case: "Resume Aligner Case",
  confirmation_resume: "Are You Sure you want to Resume this Aligner Case ?",
  case_rejected_for_the_following_reasons:
    "Case is rejected for the following reasons :",
  fix: "Fix",
  _3D_viewer: "3D Viewer",
  copy_link_to_share: "Copy Link to Share",
  copy: "Copy",
  inactive: "inactive",
  title: "Title",
  start_together: "Start Together",
  technician_notes: "Technician’s Notes ",
  adjustment_reason: "Adjustment Reason",
  ipr_file: "IPR File",
  sps_file: "SPS File",
  upper_steps: "Upper Steps",
  lower_steps: "Lower Steps",
  upper_over_correction_steps: "Upper Over Correction Steps",
  lower_over_correction_steps: "Lower Over Correction Steps",
  case_progress: "Case Progress",
  stage: "Stage",
  expected_delivery_date: "Expected Delivery Date",
  airway_bill: "Airway Bill #",
  main_impressions_method_selected: "Main impressions method selected",
  no_impressions_were_uploaded_to_access:
    "No Impressions were uploaded to Access.",
  impressions_note_sent_by_dr: "Impressions Note sent by Dr: ",
  type_Your_message: "Type Your message",
  send: "Send",
  allowed_file_types_are_word_documents_images_and_PDFs:
    "*Allowed file types are word documents, images, and PDFs.",
  terms_and_agreements: "Terms and Agreements",
  updated_may_10_th_2018: "Updated May 8th, 2018",
  agree: "Agree",
  your_case_has_been_submitted: "Your case has been submitted",
  submission_PDF: "Submission PDF",
  submit_another_case: "Submit Another Case",
  request_new_retainer_has_been_submitted:
    "Request new retainer has been submitted",
  aligner_case_has_been_paused: "Aligner case has been paused",
  aligner_case_has_been_resumed: "Aligner case has been resumed",
  request_new_replacment_has_been_submitted:
    "Request new replacment has been submitted",
  new_adjustment_has_been_submitted: "new Adjustment has been submitted",
  aligner_case_has_been_marked_as_completed:
    "Aligner case has been marked as completed",
  archived_case_successfully: "archived case successfully",
  restored_case_successfully: "Restored case successfully",
  treatment_Setup_has_been_approved: "Treatment Setup has been approved",
  treatment_Setup_has_been_disapproved: "Treatment Setup has been disapproved",
  updated_address_successfully: "Updated address successfully",
  created_new_address_successfully: "Created new address successfully",
  update_doctor_preferences_successfully:
    "Updated Doctor Preferences successfully",
  deleted_address_successfully: "deleted address successfully",
  updated_user_info_successfully: "updated user info successfully",
  link_is_copied: "Link is copied",
  nothing_is_changed: "nothing is changed",
  please_check_values: "please check values",
  "aligner_case.created": "Case has been created",
  "aligner_case.changed_package": "Arch has been changed",
  "aligner_case.case_type_changed": "Case type has been changed",
  "aligner_case.submit": "Case has been submitted",
  "aligner_case.courier_notified": "Pickup has been arranged",
  "aligner_case.rejected": "Records have been rejected",
  "aligner_case.updated": "Case has been updated",
  "aligner_case.approved": "Records have been approved",
  "aligner_case.comment_posted": "Comment has been posted",
  "aligner_case.doctor_notified_of_ts": "Notified of treatment setup",
  "aligner_case.rejected_ts": "Treatment Setup has been rejected",
  "aligner_case.approved_ts": "Treatment Setup has been approved",
  "aligner_case.cleared_by_accounting": "Case has been cleared by accounting",
  "aligner_case.paused": "Case has been paused",
  "aligner_case.resumed": "Case has been resumed",
  "aligner_case.shipped": "Aligners has been marked as shipped",
  "aligner_case.retainer_requested": "Retainer requested",
  "aligner_case.replacement_aligners_requested":
    "Replacement aligners have been requested",
  "aligner_case.replacement_aligners_shipped":
    "Replacement aligners have been shipped",
  "aligner_case.adjustment_requested": "Adjustment has been requested",
  "aligner_case.retainer_shipped": "Retainer shipped",
  "aligner_case.case_completed": "Case Completed",
  "aligner_case.expired_and_dormant": "System Marked Case Expired",
  "aligner_case.retainer_manufactured": "Retainer Manufactured",
  "aligner_case.overriden_by_admin": "Records Overridden by Admin  ",
  "aligner_case.manufactured": "Case Manufactured ",
  "aligner_case.support":"Support",
  "aligner_case.expired":"Case has been expired",
  "aligner_case.received_impressions":"Received Impressions",
  "aligner_case.pending_pre_production_process":"Pending Pre Production Process",
  "aligner_case.replacement_aligners_manufactured":"Replacement Aligners Manufactured",
  "aligner_case.converted_to_hybrid":"Case has been converted to hybrid",
  "aligner_case.cleared_by_manager":"Case Cleared by Manager",
  "aligner_case.transferred_case":"Transferred Case",
  "aligner_case.treatment_setup_generated":"Treatment Setup Generated",
  "aligner_case.exceeded_month_cap":"Case Exceeded month cap",
  "aligner_case.midcourse_adjustment_requested":"Midcourse Adjustment Requested",
  "aligner_case.refinement_requested":"Refinement Requested",
  "aligner_case.cancelled":"Case Cancelled",
  notifications: "Notifications",
  case: "Case",
  photos: "Photos",
  x_rays: "X-Rays",
  rejection_reasons: "Rejection Reasons",
  typeHere: "Type here ...",
  Use_Extraction_for_this_case: "Use Extraction for this case ",
  Extraction_will_not_be_applied_to_this_case:
    "Extraction will not be applied to this case.",
  Eon_to_recommend_best_settings_for_this_treatment:
    "Eon to recommend best settings for this treatment.",
  IPR_will_not_be_applied_to_this_case: "IPR will not be applied to this case.",
  Overcorrection_will_not_be_applied_to_this_case:
    "Overcorrection will not be applied to this case .",
  Passive_aligner_will_not_be_applied_to_this_case:
    "Passive aligners will not be applied to this case.",
  Bite_ramps_will_not_be_applied_to_this_case:
    "Bite ramps will not be applied to this case.",
  Tooth_size_discrepancy_will_not_be_applied_to_this_case:
    "Tooth size discrepancy correction will not be applied in this case.",
  Use_elastics_for_this_case: "Use elastics for this case  ",
  Elastics_will_not_be_applied_to_this_case:
    "Elastics will not be applied to this case.",
  Arch_expansion_will_not_be_applied_to_this_case:
    "Arch expansion will not be applied to this case.",
  Attachments_will_not_be_applied_to_his_case:
    "Attachments will not be applied to this case.",
  Pontics_will_not_be_applied_to_this_case:
    "Pontics will not be applied to this case.",
  Torque_enhancers_will_not_be_applied_to_this_case:
    "Torque enhancers will not be applied to this case.",
  Combination_of_upper_and_lower_anteriors_intrusion:
    "Combination of upper and lower anteriors intrusion",
  extraction_note: "Use extraction to select the teeth to be extracted.",
  IPR_note:
    "Use IPR to create space for teeth alignment or reshape the contact area between neighboring teeth.",
  attachments_note:
    "Use attachments to decide the aligner steps that require attachments as well as the teeth that don’t require attachments",
  pontics_note:
    "Use pontics to pinpoint the areas you want an artificial tooth (pontic) placed.",
  overCorrection_note:
    "Use overcorrection to plan movements beyond the ideal teeth positions to compensate for a lag in tracking.",
  passive_aligner_note:
    "Use passive aligners to maintain teeth position in one arch while the teeth in the opposing arch are still under treatment.",
  tooth_size_discrepancy_note:
    "Use tooth size discrepancy when there is lack of harmony between the mesiodistal teeth widths, compared to their functional counterparts in the opposing arch.",
  torque_enhancers_note:
    "Use torque enhancers to correct the inclination of the front teeth by delivering force at a specific angle.",
  BiteRamps_note:
    "Use bite ramps to intrude anterior teeth, dis-occlude posterior teeth and apply intrusive forces on anterior teeth.",
  Archexpansion_note:
    "Use arch expansion to expand narrow arches and make space for the required teeth movements.",
  Elastics_note:
    "Use elastics to provide additional force on the teeth during bite alignment, using either buttons or precision cuts.",
  hybrid_treatment: "Hybrid Treatment",
  archExpansion: "Arch Expansion",
  case_details: "Case Details",
  patient_management_portal: "Patient Management Portal",
  see_all_notifications: "See all notifications",
  submit_aligner_case: "Submit aligner case",
  submit_retainer_case: "Submit retainer Case",
  case_dashboard: "Case Dashboard",
  approve_treatment_setup: "Approve Treatment Setup",
  general: "General",
  use_passive_aligners: "Use passive aligners",
  wear_cycle: "Wear cycle",
  one_week: "1 Week",
  two_week: "2 Weeks",
  use_pontics: "Use pontics",
  use_overcorrection: "Use overcorrection",
  beta: "Beta",
  consider_hybrid_treatment_for_difficult_movements:
    "Consider Hybrid treatment for difficult movements ",
  ipr_restrictions: "IPR restrictions",
  use_ipr: "Use IPR",
  use_attachments: "Use attachments",
  attachment_restrictions: "Attachment restrictions",
  add_attachments_on_step: "Add attachments on step",
  add_ipr_on_step: "Add IPR on step",
  add_ipr_step: "Add IPR Step",
  add_attachment_step: "Add Attachment Step",
  edit: "Edit",
  Set_tooth_rate_movement_for_this_case:
    "Set tooth rate movement for this case ",
  Normal_Rate: "Normal Rate",
  Slow_Rate: "Slow Rate",
  toothRateMovement: "Tooth movement rate",
  toothRateMovement_note:
    "Use tooth movement rate to prolong the treatment time - recommended in patients with compromised periodontal situation or in patients with altered bone metabolism.",
  hybridTreatment: "Hybrid treatment",
  change_image: "Change image",
  name: "Name",
  email_notifications: "Email Notifications",
  dental_notation: "Dental Notation",
  date_format: "Date Format",
  phone_number: "Phone Number",
  language: "Language",
  universal: "Universal Notation System",
  palmer: "Palmer Notation System",
  fdi: "FDI notation system",
  unsubscribe: "Unsubscribe",
  en: "English",
  fr: "French",
  package_type: "Package Type",
  Hybrid_treatment_will_b_considered_for_difficult_movements_in_this_case:
    "Hybrid treatment will be considered for difficult movements in this case.",
  Hybrid_treatment_will_not_be_applied_this_case:
    "Hybrid treatment will not be applied to this case.",
  Use_Hybrid_treatment_for_this_case: "Use Hybrid treatment for this case ",
  package_type_lite_note:
    "A total of 14 steps (14 upper and 14 lower aligners) | Includes a retainer and 6 refinement steps.",
  package_type_lite_single_arch_note:
    " A total of 14 steps (14 upper OR 14 lower aligners single arch) | Includes a retainer and 6 refinement steps. ",
  package_type_unlimited_note:
    "Unlimited number of steps. Includes unlimited number of stages, unlimited adjustments between stages and retainer. ",
  file_not_supported: "sorry this file type is not supported",
  send_teeth_impressions: "Send teeth impressions",
  provide_links_to_third_party:
    "Provide links to third party integration systems (Ex. 3Shape Communicate)",
  provide_links: "Provide links to third party integration systems",
  provide_links_commit: "(Ex. 3Shape Communicate)",
  paste_link: "Paste link",
  patient_email: "Patient email",
  treatment_summary: "Treatment summary",
  treatment_type: "Treatment type",
  detailed_case: "Detailed case",
  submission: "submission",
  exit: "Exit",
  case_submitted_successfully: "Case submitted successfully",
  download_case_details: "Download case details",
  homepage: "Homepage",
  aligner_case_submission: "Aligner Case Submission",
  submission_complete: "Submission Complete",
  confirmation_restore: "Are you sure you want to restore this case?",
  restore: "Restore",
  cases_review: "Cases review",
  Warning: "Warning",
  Warning_message_here: "Warning message here!",
  Success: "Success",
  Archived_case_successfuly: "Archived case successfuly",
  Attention_needed: "Attention needed",
  Message_goes_here: "Message goes here!",
  patient: "Patient",
  download_pdf: "Download PDF",
  adjust: "Adjust",
  continue_hybrid_submission: "Continue hybrid submission",
  request_replacement: "Request replacement",
  upload_new_bite_record: "Upload new bite record",
  confirmation_mark_case_as_completed:
    "Are You Sure you want to Mark this Aligner Case as Completed ?",
  mark_aligner_case_as_completed: "Mark Aligner Case as Completed",
  mark: "Mark",
  choose_your_device: "choose your device",
  Perform_IPR_on_step: "Perform IPR on step",
  Perform_attachments_on_step: "Perform Attachments on step",
  add_attachments_step: "Add Attachments Step",
  write_something_here: "Write something here",
  lower_impression: "Lower Impression",
  upper_impression: "Upper Impression",
  view_treatment_setup: "View Treatment Setup",
  share: "Share",
  show_revised_data: "Show revised data",
  copy_link: "Copy Link",
  link_valid: "*This link is valid for 30 days only! ",
  case_no: "Case No.",
  type: "Type",
  date: "Date",
  actions: "Actions",
  terms_title_1: "eon Dental (Pricing Terms)",
  terms_title_2: "eon Dental Pricing Terms and Conditions (“Terms”)",
  terms_introduction: "INTRODUCTION :",
  terms_introduction_content:
    "These Terms are the only terms or conditions which apply to contracts between eon Dental or one of our subsidiaries, as applicable (“we”/ “us”/ “eon”), and any Customer('our Customer' / 'Customer') for purchases of products('Products', or an individual'Product') and/ or related services (“Services”),except where we have expressly otherwise agreed in writing.These Terms apply to all aspects of the relationship between eon and Customer from the time that Customer first accesses our systems and software, whether to place an order for Products or Services('Customer Orders'), or to provide patient information for the purpose of placing such orders, or to obtain access to eon branding or marketing materials.No other terms shall apply to the relationship between eon and our Customer for the purchase of Products or Services, whether implied or otherwise, except terms expressly agreed to in writing by eon.",
  terms_title_3: "CUSTOMER ORDERS AND CONTRACTS:",
  terms_orders_contract:
    " If Customer accesses eon’s IT systems or uses eon software, including any eon website, eon’s Store or Webstore and/ or the eon Doctor Access and Treatment setup viewer software (together “eon Systems”), Customer does so with the permission of eon, for the purpose of a good faith relationship with eon, for ordering Products or Services offered by eon from time to time. Use of Access software is subject to the terms of the eon software license. These Terms govern Customer's use of eon Systems and the processes and requirements for ordering Products and Services and Customer agrees to be bound by them in consideration for eon providing Customer with access to the eon Systems",
  terms_orders_contract2:
    "Customer Orders are placed or submitted using eon Access and are considered to be placed or submitted (pending acceptance by eon): if the Records Approval (‘Records Approval’) is required. The price applicable to a Customer Order will be the price current at the time the Customer Order is placed. Customer Orders are accepted by sending the Customer a confirmation email or as indicated by eon Access.",
  terms_orders_contract3:
    "Order for Products that are aligners or retainers must be for either delivery to the country in which the named patient (‘Patient”) for those aligners is receiving treatment, or for delivery to the doctor shipping address as indicated in the Doctors Access Profile.",
  terms_who_is_our_customer: "WHO IS OUR CUSTOMER?",
  terms_who_is_our_customer_content:
    "eon Dental customers are the Doctors whose email address is used to access the protected eon System (‘eon Doctor’). If a practice, partnership, corporate body or other legal entity for or with which the eon Doctor works is named on the eon invoice(s) and/or that practice pays eon’s invoices, then eon is entitled to assume that it is the eon Doctor who uses eon Access and places Customer Orders with the authorization of, for and on behalf of that entity (the ‘practice’). Under these circumstances, the Practice will also be an eon Customer and the eon Doctor and the Practice will be jointly and severally liable for obligations in these Terms which are obligations of the ‘Customer’",
  terms_who_is_our_customer_content2:
    "The eon Doctor and the Practice must read and understand these terms before downloading any materials or software, uploading Patient information or placed any Customer Order, because in each case, a contract will be formed and Customer will be bound by these Terms which will govern that contract.",
  terms_who_is_our_customer_content3:
    "The Align contract also has ‘Limitation of Liability’, ‘Invoice payment date’ and ‘Art and advertising standards’, ‘law and jurisdiction applicable to these terms’ clauses here.",
  terms_products: "PRODUCTS:",
  terms_product_eon_unlimited: "Product : Eon Unlimited",
  terms_product_eon_unlimited_content:
    "Number of aligners: Unlimited aligners to be used within 5 years based on a patient’s 2 week wear cycle.",
  terms_product_eon_unlimited_expiry: "Expiry Date : 60 months",
  terms_product_eon_lite: "Product : Eon Lite",
  terms_product_eon_lite_content:
    "Number of aligners: 14 upper and 14 lower aligners & a Free refinement of an additional 6 Upper & 6 lower aligners to reach the same initial treatment goal that’s approved by eons team.",
  terms_product_eon_lite_expiry: "Expiry Date : 18 Months",
  terms_lite_note:
    "Notes : We provide an additional 6 individual steps (1 upper and lower aligner) free as refinement aligners to reach the treatment goal provided the treatment goal has not changed from the initial treatment goal. An additional 6 steps can be provided for USD $300 to reach the treatment goal if it has not been achieved after the initial 6 refinement aligners, provided the initial treatment goal has not changed. All aligners will be shipped in one shipment",
  terms_product_eon_lite_single_arch: "Product : Eon Lite Single Arch",
  terms_product_eon_lite_single_arch_content:
    "Number of aligners : 14 Upper OR 14 Lower aligners",
  terms_product_eon_lite_single_arch_expiry: "Expiry Date : 18 Months",
  terms_product_eon_lite_single_arch_price:
    "Prices are subject to change with a 45 days prior notice.",
  terms_product_eon_lite_single_arch_expiry2:
    "Expiration dates: Unlimited cases expire 5 years from the date of shipment for the initial set of aligners for the eon Aligner ® Unlimited product category, based on a wear cycle of 2 weeks.",
  terms_product_eon_lite_single_arch_expiry3:
    "Lite cases expire 18 months from the date of shipment of the initial set of aligner for the eon Aligner ® Lite and eon Aligner ® Lite Single Arch product categories based on a wear cycle of 2 weeks.",
  terms_product_eon_lite_single_arch_expiry4:
    "Once a treatment expiration date has passed, any additional order for the same patient will be considered a new treatment.",
  terms_refinement:
    "Refinement is an option to order extra aligners for eon Aligner Lite and Lite Single Arch product categories that meet the clinical criteria for treatment completion within 14 steps of treatment, if further tooth movement is required in order to meet the original treatment goals. For the eon Aligner ® Lite product category, one refinement stage (six upper and lower aligners) is included in the price if the treatment objectives have not changed. The order must be received minimum 6 months prior to the Treatment Expirate Date. Only one refinement may be ordered per treatment.",
  terms_product_description: "PRODUCT DESCRIPTIONS",
  terms_eon_unlimited: "eon Unlimited",
  terms_eon_unlimited_content:
    "Unlimited aligners to be used within 5 years based on a patients 2 week wear cycle .",
  terms_eon_lite: "eon Lite",
  terms_eon_lite_content:
    " An Aligner treatment up to 14 upper and 14 lower aligners",
  terms_product_note_1:
    "We provide an additional 6 individual steps (1 upper and lower aligner) free as refinement aligners to reach the treatment goal provided the treatment goal has not changed from the initial treatment goal.",
  terms_product_note_2:
    "An additional 6 steps can be purchased by the customer to reach the treatment goal if it has not been achieved after the initial 6 refinement aligners, provided the initial treatment goal has not changed",
  terms_product_shipment: "All aligners will be shipped in one shipment",
  terms_lite_single_arch: "eon Lite Single Arch",
  terms_lite_single_arch_content:
    "A Single arch treatment up to 14 aligners , then 6 free aligners are included in the package as a refinement to reach the same initial treatment objective .",
  terms_discount: "DISCOUNT SCHEME AND ADVANTAGE PROGRAM",
  terms_loyalty_program: "The Current Loyalty Program",
  terms_others: "OTHER TERMS AND CONDITIONS",
  terms_shipment_risk: "Shipment, Risk, Ownership, Cancellations and Returns",
  terms_shipment_risk_content_1:
    "It is Customer's responsibility to provide for each Patient all material records ('All Materials Required') and to accept a treatment plan. Eon will only manufacture the Products once it has received the necessary All Materials Required and Customer's treatment setup acceptance. Customer must manage Customer's Patient's expectations on the timing of their I treatment, particularly where delays are caused by Customer's own delays or failures to act. All Materials Required will not be returned to Customer.",
  terms_shipment_risk_content_2: `Products are manufactured and dispatched from the site of manufacture within approximately 14 days of approval of the treatment plan or, for replacement aligners, within seven (7) days of placement of the Customer Order. All dates are approximate only and the time of dispatch is not of the essence.`,
  terms_shipment_risk_content_3:
    "eon is not liable for any liability arising as a result of a delay in delivery of Products .",
  terms_shipment_risk_content_4:
    "If the Products are not delivered, Customer will inform eon who will determine the cause of the delay and, if in accordance with these Terms eon is under an obligation to deliver, eon will manufacture and ship replacement Products and this will be Customer's sole remedy in respect of a failed delivery.",
  terms_shipment_risk_content_5:
    "If Customer fails to accept delivery of the Products within five (5) days of a notice from the delivery company that the Products are ready for delivery, then delivery of the Products will be deemed to have been completed at 11am on the fifth day after such notice. eon shall charge Customer for redelivery and storage costs, including insurance, but shall have no obligation to redeliver the Products seven (7) days after the notice to Customer of the Products' readiness for delivery.",
  terms_shipment_risk_content_6:
    "The Products will be shipped to the delivery address selected by the Customer at the time the Customer Order is placed from the list of Customer addresses recorded in the eon Access Systems.",
  terms_shipment_risk_content_7:
    "Intellectual Property Rights, Confidentiality and Trade Marks",
  terms_shipment_risk_content_8:
    "All rights in intellectual property (including all patents, trademarks, service marks, registered designs, utility models, design right, database rights, copyright (including copyright in software and computer algorithms), trade secrets and other confidential information, know-how, and all other intellectual and industrial property and rights of a similar or corresponding nature in any part of the world) in or relating to the Products, the Services, any materials, information, access software, eon Systems, documents or items that eon prepares or produces for Customer or makes available to Customer will belong to eon absolutely.",
  terms_termination: "Termination",
  terms_termination_content_1:
    "Once Customer has placed a Customer Order that is accepted by eon, Customer cannot cancel or terminate the Customer Order unless eon fails to provide the Products or Services to Customer within a reasonable time.",
  terms_termination_content_2:
    "eon has the right to give Customer written notice terminating any or all contracts(s) with immediate effect (without need for a court order) if any of the following occur, or eon considers in its sole discretion that they are likely to occur (and which might refer to either the Customer or the Practice, or both):",
  terms_termination_content_3:
    "Customer fails to pay the sums that are owed to eon under any agreement(s) when they are due for payment, and/ or are persistently late in paying sums due to eon under any agreement(s); or",
  terms_termination_content_4:
    "eon considers that Customer's financial position is such that Customer will not be able to pay sums due to eon; or",
  terms_termination_content_5:
    "the Practice ceases to operate as a dental practice and/ or Customer, the Doctor, ceases to practice dentistry (whether permanently or temporarily so that eon at its sole discretion considers that Customer is not able to treat Customer's Patients in a timely manner);",

  terms_and_conditions: "TERMS AND CONDITIONS",
  terms_and_conditions_content_1:
    "These terms and conditions constitute a legally binding agreement between “Eon Dental, BVBAa company incorporated, organized and existing under the laws of Belgium, with registered seat at Korenlei 22, 9000 Ghent (Belgium), (hereinafter referred to as the“ Eon ”); ; and you(hereinafter referred to as the “ Doctor ”) , and are referred to herein as either 'Terms and Conditions' or this 'Agreement'.",
  terms_and_conditions_content_2:
    "[The parties hereto shall be singly referred to as “ Party” and collectively as the “Parties”]",
  terms_and_conditions_content_3:
    "These Terms and Conditions are subject to change at any time and for any reason. Eon reserves the right to update these terms and conditions at any time without prior notice.",
  terms_case_acceptance: "Case Acceptance :",
  terms_case_acceptance_content_1:
    "All Cases are subject to acceptance by Eon in writing ,by submitting an “Eon Case Submission Form”, or in a manner and format prescribed by Eon from time to time,",
  terms_case_acceptance_content_2: "Cases are accepted upon receipt if:",
  terms_case_acceptance_content_3:
    "The Case Submission Form is accurate and complete;",
  terms_case_acceptance_content_4:
    "The accompanying records of the patient are accurate and complete; and",
  terms_case_acceptance_content_5:
    "There are no special instructions that would require Doctor’s clarification or decision",
  terms_case_acceptance_content_6:
    "Payment is due immediately when case is accepted by Eon.",
  terms_case_acceptance_content_7:
    "If there is a credit card on file, it will be charged in amount of the current price for the Treatment Setup at that time.",
  terms_case_acceptance_content_8:
    "Cases that are not automatically accepted are not charged. Should this occur the Doctor will be contacted for consultation.",
  terms_price_and_payment: "Price and Payment :",
  terms_price_and_payment_content_1:
    "Prices shall be as specified by Eon based on a published price list by Eon or its resellers, or on the date of Treatment Setup acceptance of the case by the Doctor. Eon reserves the right to update the price list from time to time without prior notice. All prices shall not include customs duties, sales tax, or any other charges levied by any governmental authority.",
  terms_price_and_payment_content_2:
    "Unless otherwise agreed to in writing by both parties, payment of the Case ordered by the Doctor shall be made upon the Case acceptance by Eon.",
  terms_cancellation_and_refunds: "Cancellation and Refunds",
  terms_cancellation_and_refunds_content_1:
    "If Doctor cancels a case before delivery of the Treatment Setup, Doctor will not incur any cancellation fee.",
  terms_cancellation_and_refunds_content_2:
    "For cases cancelled after delivery of the Treatment Setup, but prior to the Doctor’s approval of the Treatment Setup a cancellation fee of USD $ 195 will be incurred by Doctor.",
  terms_cancellation_and_refunds_content_3:
    "Cases that are cancelled after the delivery of the Treatment Setup and after the Doctor’s approval will be charged the full fee.",
  terms_cancellation_and_refunds_content_4:
    "Lab fees for cases cancelled before delivery of the Treatment Setup will be refunded in full.",
  terms_cancellation_and_refunds_content_5:
    "Lab fees for cases cancelled after delivery of the Treatment Setup will be credited to Doctor’s account minus any applicable cancellation fees.",
  terms_shipment_and_delivery: "Shipment and Delivery:",
  terms_shipment_and_delivery_content_1:
    "The Aligners will not be fabricated until Doctor approves the Treatment Setup. Doctor should receive the Treatment Setup for a case within 2 to 3 weeks of case submission and acceptance by Eon. The first stage of aligners will be fabricated and shipped within 2 to 3 weeks of Doctor’s approval of the Treatment Setup. Subsequent stages will be shipped automatically. Their delivery will be timed to meet the wear cycle of 2 weeks/14 days per aligner. Stages can be shipped as early as 4 weeks before their wear date to allow Doctor time to verify receipt of shipment, review upcoming stages and schedule the patient.",
  terms_shipment_and_delivery_content_2:
    "Delivery dates are estimates. Eon shall not be liable for any damages, losses or expenses incurred by Doctor if Eon fails to meet the estimated delivery dates. The Title to products shipped under this Agreement and risk of loss or damage during shipment pass from Eon to Doctor upon delivery to the address specified by Doctor. Shipping of aligners and the records required for original case submissions is included at no additional cost in the lab fee. Delay in the delivery of products shall not relieve the Doctor of its obligations to accept and pay for products.",
  terms_shipment_and_delivery_content_3:
    "If the Doctor causes or requests a delay in shipment without any intervening or superseding fault or negligence of Eon, risk of loss shall be deemed to have passed as of the date Eon notifies the Doctor of its readiness for shipment. If Eon incurs costs and expenses because of a delay in shipment as described in this subparagraph, such costs, shall be paid by the Doctor.",
  terms_records: "Records:",
  terms_records_content:
    "Doctor shall not submit original records to Eon. Records submitted to Eon become the property of Eon and will not be returned to Doctor. Doctor shall obtain patient’s informed consent for Doctor to provide such patient’s medical records to Eon as necessary for Doctor’s treatment of the patient.",
  terms_doctor_representations: "Doctor Representations:",
  terms_doctor_representations_content:
    "Doctor represents and warrants to Eon that: (i) Doctor is licensed to practice dentistry and/or orthodontics in the location where, and at all times during which, treatment is being provided (ii) Doctor has the proper training, expertise and/or experience to perform procedures associated with and/or using Eon clear aligner products; (iii) Doctor’s use of Eon products will be in accordance with all applicable medical and dental standards and used in compliance with Eon’s product specifications; and (iv) Doctor has obtained an executed informed consent form, from each patient Doctor treats using Eon clear aligner products. Such form will be furnished by the Doctor and must comply with the local laws governing the Doctor.",
  terms_warranties_and_disclaimer: "Warranties and Disclaimer",
  terms_warranties_and_disclaimer_content_1:
    "Eon warrants that its Treatment Setup: (i) shall conform to the specifications provided by Doctor in the Case Submission Form and as contained in the Treatment Setup approved by the Doctor; and (ii) are free from defects in material and workmanship.",
  terms_warranties_and_disclaimer_content_2:
    "Eon shall not be liable for (i) any defects that are caused by neglect, misuse, or mistreatment of its products by any third party, (ii) for any products that have been altered or modified in any way by an individual or entity other than Eon, or (iii) for any products being used in combination with other third party products. Moreover, Eon shall not be liable for any defects that result from Doctor’s specifications or instructions for such products. This limited warranty expires three (3) months after shipment of the product. If any Eon product fails to conform to the warranty set forth above, Eon’s sole liability, at its option, shall be to: (i) repair or replace such product; or (ii) credit Doctor’s account for such product. If Eon elects to repair or replace such product, it shall have a reasonable time to repair such products or provide replacements. Repaired products shall be warranted for the remainder of the original warranty period. Replaced products shall be warranted for a three (3) month full warranty period.",
  terms_warranties_and_disclaimer_content_3:
    "To the extent allowed by local law, except for the obligations set forth in the warranty statement above, in no event shall EON be held liable for any other warranty or condition of any kind whether express or implied warranties or conditions of merchantability, satisfactory quality, and fitness for any specific purpose.",
  terms_limitations_of_liability: "Limitations of Liability :",
  terms_limitations_of_liability_content_1:
    "To the extent allowed by local law, except for the obligations specifically set forth in this warranty statement, in no event shall EON be liable for direct, indirect, special, incidental or consequential damages in connection with or arising out of this Agreement or the use of the Treatment Setup provided hereunder, however caused, and under any theory of liability whether in negligence, breach of warranty, strict liability, contract, tort, indemnity or any other cause or theory whatsoever .",
  terms_limitations_of_liability_content_2:
    "In no event shall Eons aggregate monetary liability for damages of any kind arising out of or in connection with this agreement, or any use of any product provided hereunder, exceed the total amount paid to eon by doctor for the particular products sold under the agreement for which losses or damages are claimed. the existence of more than one claim against the particular products sold to doctor under this agreement, or the existence of more than one agreement with the doctor or the sales of additional products to the doctor shall not enlarge or extend this limit.",
  terms_limitations_of_liability_content_3:
    "The Doctor shall be liable to compensate Eon for any expenses incurred by Eon as a result of the Doctor’s failure to comply with this Agreement.",
  terms_indemnification: "Indemnification :",
  terms_indemnification_content:
    "DOCTOR AGREES TO INDEMNIFY, DEFEND AND HOLD HARMLESS EON AND/OR ITS OFFICERS, AGENTS, EMPLOYEES, CONTRACTORS, SUCCESSORS AND ASSIGNS FROM AND AGAINST ANY AND ALL LIABILITY, OBLIGATIONS, LOSSES, CLAIMS, ACTIONS, DAMAGES, PENALTIES, FINES, DEMANDS OR SUITS AND ALL RELATED COSTS, ATTORNEY’S FEES AND EXPENSES OF ANY KIND AND NATURE WHATSOEVER ARISING UNDER ANY THEORY OF LEGAL LIABILITY (A henceforth referred to as the ”CLAIM”) THAT MAY BE ASSERTED AGAINST EON ARISING OUT OF, OR RESULTING FROM, OR RELATING TO: (I) THIS AGREEMENT OR USE OF PRODUCTS SOLD UNDER THIS AGREEMENT; (II) ANY BREACH OF OR FAILURE OF DOCTOR TO ABIDE BY ANY TERM OF THIS AGREEMENT; (III) ANY BREACH OR ALLEGED BREACH OF ANY REPRESENTATIONS OR WARRANTIES MADE BY DOCTOR IN THIS AGREEMENT OR ANY INCORRECT INFORMATION PROVIDED BY DOCTOR OR DOCTOR’S PATIENT TO EON; OR (IV) EON’S PROVIDING OF OR FAILURE TO PROVIDE PRODUCTS TO DOCTOR, UNLESS THE PROVIDING OF OR FAILURE TO PROVIDE SUCH PRODUCTS WAS DUE TO EON’S WILLFUL MISCONDUCT OR GROSS NEGLIGENCE.",
  terms_relationship_of_parties: "Relationship of Parties :",
  terms_relationship_of_parties_content:
    "The Parties intend by this Agreement that Doctor is and at all times shall be an independent contractor and not the agent or employee of Eon. Neither this Agreement nor any contract with Eon nor any course of dealing or practice shall be interpreted as creating, or shall be deemed to create, any employer-employee, principal-agent, partnership, joint venture or other relationship between Doctor and Eon.",
  terms_advertising_and_marketing: "Advertising and Marketing :",
  terms_advertising_and_marketing_content:
    "Eon reserves the right to use Doctor’s name, address, telephone number, and descriptions of care and specialty services in any advertising, promotional and marketing materials, provided that Doctor has the right to review such marketing materials before final publication or distribution.",
  terms_assignment: "Assignment :",
  terms_assignment_content:
    "This Agreement shall not be assigned by either Party hereto without the prior written consent of the other Party.",
  terms_successor_and_assigns: "Successor and Assigns :",
  terms_successor_and_assigns_content:
    "This Agreement shall be binding upon and shall inure solely to the benefit of the Parties hereto and their respective successors and shall not be for the benefit of any other person, persons, or legal entities.",
  terms_entire_agreement_and_amendment: "Entire Agreement and Amendment :",
  terms_entire_agreement_and_amendment_content:
    "This Agreement, the Eon Case Submission Form, which provided by the Doctor and approved by Eon, shall constitute and contain the entire agreement of the Parties and supersede any and all prior negotiations, correspondence, understandings and agreements between the Parties respecting the subject matter hereof. This Agreement may be modified only by an agreement in writing duly executed by the Parties hereto.",
  terms_severability: "Severability :",
  terms_severability_content:
    "If any provision of this Agreement is or shall be deemed a violation of any applicable law, rule or regulation, such legal invalidity shall not void this Agreement or affect the remaining terms and provision of this Agreement and this Agreement shall be construed and interpreted to comply with all laws, rules or regulations.",
  terms_force_majeure: "Force Majeure :",
  terms_force_majeure_content:
    "Eon cannot be in default or breach by reason of any failure of its performance under this Agreement if such failure results, whether directly or indirectly, from fire, explosion, strike, freight embargo, act of God, or of war, civil disturbance, act of any government, de jure or de facto, or any agency or official thereof, labor shortage, transportation contingencies, severe weather, default of manufacturer or supplier, quarantine or restriction, epidemic or catastrophe, lack of timely instructions or essential information from Doctor or any other third party, or other conditions beyond the control of Eon.",
  terms_notices: "Notices :",
  terms_notices_content:
    "All notices, demands, requests, approvals and other communications given or made pursuant hereto shall be in writing and shall be deemed to have been given or made as of the date delivered or mailed if delivered personally or mailed by certified mail (postage prepaid, return receipt requested), or on the date transmitted if transmitted by facsimile or electronic mail, to Doctor at the address provided by Doctor.",
  terms_waiver: "Waiver :",
  terms_waiver_content:
    " The failure of either Party at any time or times to require performance of any provision hereof shall in no manner affect the right to enforce the same. No waiver by either Party of any condition, or of the breach of any term, provision, covenant or warranty contained in this Agreement, shall be deemed to be or construed as a further or continuing waiver of any such condition or breach or a waiver of any other condition or of the breach of any other term, provision, covenant or warranty.",
  terms_governing_law: "Governing Law  :",
  terms_governing_law_content:
    "This Agreement shall be governed by, and construed in accordance with, Belgian law.",
  TS_Viewer: "TS Viewer",
  Treatment_Setup: "Treatment Setup",
  Case_ID: "Case ID",
  Movement_Table: "Movement Table",
  Applied_Treatments: "Applied Treatments",
  Notes: "Notes",
  Hybrid_treatment_is_recommended_due_to_difficult_movements:
    "Hybrid treatment is recommended due to difficult movements",
  Convert_to_Hybrid_Treatment: "Convert to Hybrid Treatment",
  Tooth: "Tooth",
  Number: "Number",
  Rotation: "Rotation",
  Angulation: "Angulation",
  Torque: "Torque",
  Mesial: "Mesial/",
  Distal: "Distal",
  Extrusion: "Extrusion/",
  Intrusion: "Intrusion",
  In_Out: "In/Out",
  Approve_Treatment: "Approve treatment",
  Revise_Treatment: "Revise Treatment",
  onboarding: "Onboarding",
  select_your_default_options: "Select your default options.",
  select_your_default_account_preferences:
    "Select your default account preferences.",
  movement: "Movement",
  value: "Value",
  rotation: "Rotation",
  angulation: "Angulation",
  torque: "Torque",
  mesial: "Mesial",
  distal: "Distal",
  intrusion: "Intrusion",
  extrusion: "Extrusion",
  in: "In",
  out: "Out",
  deg: "deg",
  toward: "toward",
  away: "Away",
  labial: "Labial",
  lingual: "Lingual",
  restrict_tooth: "Restrict tooth",
  add_another_attachment: "Add another attachment",
  rotational: "Rotational",
  hbra: "HBRA",
  vbra: "VBRA",
  biplane: "Biplane",
  root_control: "Root control",
  use_elastics: "Use Elastics",
  un_edited_tooth: "Un-edited tooth",
  edited_tooth: "Edited tooth",
  active_tooth: "Active tooth",
  restricted_tooth: "Restricted tooth",
  treatment_protocols: "Treatment Protocols",
  Reject_Bite_Records: "Reject Bite Records",
  ipr_amount: "IPR amount",
  between: "between",
  ipr_before_this_step: "IPR before this step",
  edited: "Edited",
  IPR_amount_exceeding_recommended_limit:
    "IPR amount exceeding recommended limit",
  restrict_ipr: "Restrict IPR",
  front: "Front",
  right: "Right",
  left: "Left",
  Used_torque_enhancers_for_this_case: "Used torque enhancers for this case",
  Used_attachments_for_this_case: "Used attachments for this case",
  Used_Extraction_for_this_case: "Used Extraction for this case",
  Used_Passive_aligners_for_this_case: " Used Passive aligners for this case",
  arch: "Arch",
  number_of_passive_aligners: "Number of passive aligners",
  synchronize: "Synchronize",
  end_Together: "End Together",
  get_started: "Get Started",
  select_your_default_clinical_preferences:
    "Select your default clinical preferences.",
  you_can_override_these_preferences_on_a_case_by_case_basis_during_submission:
    "You can override these preferences on a case-by-case basis during submission.",
  you_can_change_these_preferences_in_settings:
    "You can change these preferences in settings.",
  skip: "Skip",
  Used_bite_ramps_for_this_case: "Used bite ramps for this case",
  Used_pontics_for_this_case: "Used pontics for this case",
  Used_Elastics_for_this_case: "Used Elastics for this case",
  High_Rate: "High Rate",
  Use_IPR_for_this_case: " Use IPR for this case",
  bite_check: "Bite Check",
  teeth_movement: "Teeth Movement",
  review_and_submit: "Review & Submit",
  to: "to",
  attachments_type: "Attachments Type",
  use_from_stage: "Use from stage",
  add_another_elastics: "Add another elastics",
  case_summary: "Case Summary",
  approved: "Approved",
  confirmation_leave_page: "Are you sure you want to leave the page?",
  disapprove_note: "Your changes have not been submitted.",
  revised_data_successfully: "Revision submitted successfully ",
  no_revesion_made: "No revisions made",
  submit_without_modifications: "Submit without modifications",
  invoice_based_on_case_type: "Invoice will be based on case type",
  approve_message: `<div class="tp_notes">By approving this Treatment Setup, you hereby authorize Eon Aligner
         to fabricate the aligners as per the specifications provided by you
         in the Treatment Form and as contained in this Treatment Setup.</div>
         <div class="tp_notes cmt2"> You will be invoiced according to the approved case type unless the
         required treatment plan exceeds it.</div>`,
  approve: "Approve",
  Technician_notes: "Technician Notes",
  Manufacturing_notes: "Manufacturing Notes",
  add_ipr_before_step: "Add IPR before step",
  and: "and",
  Retainer_Case_Submission: "Retainer Case Submission",
  Retainer_Case_Submission1: "Retainer ",
  Retainer_Case_Submission2: "Case Submission",
  detailed_case_submission: "Detailed case submission",
  braces_before_aligners: "Braces before aligners",
  braces_and_aligners_together: "Braces and aligners together",
  exit_review: "Exit Review",
  braces_before_aligners_note:
    " Begin bracket treatment and submit new impression after bracket treatment is complete",
  which_arch_do_you_want_to_treat_with_aligners:
    "Which arch do you want to treat with aligners?",
  do_you_want_to_use_IPR: "Do you want to use IPR ?",
  do_you_want_to_use_attachments: "Do you want to use Attachments ?",
  attachments_preferred_steps: "Attachments Preferred Steps",
  valid_steps_error: "Make sure to enter a valid unique number.",
  number_error: "Please enter a number.",
  upper_arch: "Upper Arch",
  lower_arch: "Lower Arch",
  do_you_want_to_keep_the_submitted_treatment_protocols:
    "Do you want to keep the submitted treatment protocols? ",
  please_choose_one: "Please choose one",
  provide_new_treatment_protocols: "Provide new treatment protocols",
  hybrid_treatment_Summary: "Hybrid Treatment Summary",
  keep_treatment_protocols: "Keep treatment protocols",
  hybrid_type: "Hybrid Type",
  new_treatment_protocols: "New treatment protocols",
  by_submitting_this_request:
    "By submitting this request, we will create a new treatment setup for this case.",
  revised_data: "Revised Data",
  aligner: "Aligner",
  bite_record: "Bite Record",
  bite_record_rejected: "Bite record rejected",
  upload_new_bite_records: "Upload new bite records",
  converted_to_hybrid_successfully:
    "converted to hybrid treatment successfully",
  fix_bite_records_successfully: "Fixed bite records successfully ",
  select_tooth_to_edit: "Select tooth to edit",
  there_are_unsaved_changes: "There are unsaved changes.",
  Used_Tooth_Size_Discrepancy_for_this_case:
    "Used Tooth Size Discrepancy for this case",
  please_add_details_in_elastic: "please add details in elastic",
  to_edit_ipr: "to edit IPR",
  your_account_is_blocked: "Your account is blocked from submitting new cases.",
  please_contact: "Please contact",
  contact_email: "collections@eonaligner.com",
  blocked_note_part2:
    "or your designated relationship manager to unblock your account.",
  AttachmentTypeNOTE: "Attachment Type...",
  Attachment_Note: "Attachment Type",
  these_new_preferences_will_only_be_applied_to_new_case_submissions:
    "These new preferences will only be applied to new case submissions.",
  retainer_request: "Retainer Request",
  Number_of_sets: "Number of sets",
  sets: "sets",
  Arch_Required: "Arch Required",
  impressions: "Impressions",
  Submit_request: "Submit request",
  Date_of_request: "Date of request",
  View_TS: "View TS",
  retainer_case: "retainer case",
  aligner_case: "aligner case",
  fdi_new: "FDI notation system",
  select_how_you_would_like_to_submit_your_impressions:
    "Select how you would like to submit your impressions:",
  street: "Street",
  building: "Building",
  perform_ipr_before_step: "Perform IPR before step",
  add_attachments_before_step: "Add attachments before step",
  arch: "Arch",
  download_case_pdf: "Download case PDF",
  digital_impressions: "Digital impressions",
  digital: "Digital",
  physical_impressions: "Physical impressions",
  physical: "Physical",
  all_options_might_require_the_use_of_elastics:
    "All options might require the use of elastics",
  select_the_teeth_you_dont_want_ipr_on:
    "Select the teeth you don't want IPR on:",
  select_the_teeth_that_needto_be_extracted:
    "Select the teeth that need to be extracted:",
  perform_attachments_before_step: "Perform attachments before step",
  select_the_teeth_where_you_dont_want_to_place_attachments:
    "Select the teeth where you don't want to place attachments:",
  select_the_teeth_requiring_pontic_placement:
    "Select the teeth requiring pontic placement:",
  additional_comments: "Additional comments:",
  select_the_tooth_areas_requiring_overcorrection:
    "Select the tooth areas requiring overcorrection:",
  apply_ipr_in_the_opposing_arch: "Apply IPR in the opposing arch",
  select_the_teeth_requiring_torque_enhancers:
    "Select the teeth requiring torque enhancers:",
  select_the_teeth_on_which_you_want_to_place_bite_ramps:
    "Select the teeth on which you want to place bite ramps:",
  select_the_area_where_you_want_arch_expansion:
    "Select the area where you want arch expansion:",
  set_slow_tooth_movement_rate_for_this_case:
    "Set slow tooth movement rate for this case?",
  select_the_teeth_requiring_buttons_placement:
    "Select the teeth requiring buttons placement (cutouts):",
  select_the_teeth_requiring_precision_cuts_placement:
    "Select the teeth requiring precision cuts placement:",
  your_account_is_blocked_mobile: "Your account is blocked!",
  you_cant_submit_new_cases_please_contact:
    "You can’t submit new cases. Please contact",
  collections_eonaligner_com: "collections@eonaligner.com",
  your_designated_relationship_manager_to_unblock_your_account:
    "or your designated relationship manager to unblock your account.",
  revision_notes: "Revision notes",
  provide_treatment_revision_notes: "Provide treatment revision notes",
  courier_note1:
    "A courier service will contact you to arrange for the date and time of the impression pick-up.",
  courier_note2: "Please keep in mind that impression trays won't be returned.",
  courier_requirements: "Requirements:",
  courior_list_1: "Clean, clear and sterilized impressions",
  courior_list_2:
    " Silicone impressions (Alginate impressions are not accepted)",
  courior_list_3:
    "Case ID and doctor name to be printed on the impression box . ",
  revision_submitted_successfully: "Revision submitted successfully",
  revise: "Revise",
  please_choose_one_of_arch_impression_methodes: "Please choose one of arches",
  please_choose_one_method_new: "Please choose one of Impression methodes",
  info_eonaligner_com: "info@eonaligner.com",
  address_Belgium: " Mechelsesteenweg 271 Bus 1.1 2018 Antwerpen, Belgium",
  www_eonaligner_com: "www.eonaligner.com",
  doctor_signature: "Doctor Signature:",
  doctor_information: "Doctor Information",
  treatment_submission_form: "Treatment Submission Form",
  aligner_treatment: "Aligner Treatment",
  Braces_aligners_together: "Braces + aligners together",
  aligners: "Aligners",
  braces: "Braces",
  special_instructions: "Special Instructions",
  eonToDecide: "EON to Decide",
  case_type_with_capital_t: "Case Type",
  ipr_indicator: "IPR indicator",
  pending_action: "Pending Action",
  eon_basic_hedaer: "Best for mild cases like crowding, spacing, ",
  eon_basic_hedaer_2: " or relapse.",
  eon_basic_text_1: "10 aligner steps or less",
  eon_basic_text_2: "9 months",
  eon_basic_text_3: "Includes: 3 TS revisions + 1 refinement + 1 retainer",
  eon_basic_footer:
    "Free Refinements are limited to the number of steps in the initial TS",
  eon_plus_hedaer: "Covers a wide range of mild to moderate. ",
  eon_plus_text_1: "11-24 aligner steps or less",
  eon_plus_text_2: "18 months",
  eon_plus_text_3: "Includes: 3 TS revisions + 3 refinements + 1 retainer",
  eon_pro_hedaer: "Ideal for advanced cases requiring extraction ",
  eon_pro_hedaer_2: " or A-P correction.",
  eon_pro_text_1: "Unlimited aligner steps",
  eon_pro_text_2: "36 months",
  eon_pro_text_3:
    "Includes required number of TS revisions + refinements needed to achieve the desired patient outcomes + 2 retainers.",
  your_loyalty_status: "Your Loyalty Status",
  bronze: "Bronze",
  silver: "Silver",
  gold: "Gold",
  platinum: "Platinum",
  diamond: "Diamond",
  elite: "Elite",
  updated_april_1_st_2022: "Updated April 1st, 2022",
  t_and_c: `
  <br> </br>
  <b>1. INTRODUCTION</b>
  <br></br>
  1.1. These terms and conditions ("<b>Agreement</b>") constitute the entire agreement and understanding and apply to all aspects of the relationship between Eon Dental NV or, as applicable, one of its subsidiaries or associates ("<b>Eon Dental</b>"), 
  and any customer ("<b>Customer</b>"), and no variation or amendment to the Agreement or any other terms or conditions, whether implied or otherwise, shall apply except where Eon Dental has expressly otherwise agreed in writing.
  <br></br>
  1.2. Eon Dental is inter alia engaged in the business of designing, manufacturing, selling, distributing and servicing custom-made clear aligners for orthodontic treatment ("<b>Clear Aligners</b>) and removable orthodontic 
  retainers made entirely of transparent plastic ("<b>Retainers</b>") (together "<b>Products</b>) and the provision of the supporting and ancillary services (including renderings of a preview of how the subject Products is expected to impact teeth, 
  including scans of the teeth, 3D virtual models of each step of the treatment, and a 3D viewer ("<b>Treatment Setup</b>")) ("<b>Services</b>").
  <br></br>
  1.3. This Agreement governs the Customer's use of the Eon Dental website, its case management portal for Treatment Setup and Customer communication ("<b>Eon Access</b>") and the processes and requirements to submit an order for Products and Services
  ("<b>Case Order</b>"), and the Customer agrees to be bound by them from the time the Customer first accesses Eon Access in consideration for Eon Dental providing the Customer with the Products and Services and permission to use Eon Access.
  <br></br>
  <b>2. CUSTOMERS</b>
  <br></br>
  2.1. Eon Dental's Customer is the doctor whose email address is used to log in to Eon Access ("<b>Eon Doctor</b>).
  <br></br>
  2.2.If the Eon Doctor owns, works for, works with, or is employed by a clinic or other legal entity ("<b>Clinic</b>"), and such Clinic is named on or as a matter of practice pays for the invoice(s) issued by Eon Dental, 
  then such reference in or payment of the invoice(s) shall constitute the Clinic's authorization for the Eon Doctor to use Eon Access and make Case Orders, in which instance the Clinic is also an Eon Customer, and the Eon Doctor and the Clinic will be jointly and severally liable for obligations in this Agreement which are obligations of the Customer.
  <br></br>
  <b>3. CASES AND ACCEPTANCE</b>
  <br></br>
  3.1. Cases Orders are placed in Eon Access and shall take effect at the date and time a Customer approves the Treatment Setup associated with that Case Order ("<b>Approved Case</b>") ("<b>Approval Date</b>"). 
  <br></br>
  3.2. All Approved Cases are subject to final acceptance by Eon Dental either in writing by sending the Customer a confirmation email or implicitly with the elapse of time and shipment and Eon Dental has the right in its absolute and sole discretion, and without any liability, to refuse any Case Order or Approved Case. 
  <br></br>
  3.3. It is the Customer's responsibility to provide for each Case Order's named patient ("<b>Patient</b>") all relevant records including patient name and other personal data, the upper and lower arch scans of the teeth (the "<b>Scans</b>"),
  x-rays, photos (including full face, smiling, upper and lower occlusal, left and right buccal) that accurately represent the current condition of a Patient's teeth in addition to the prescription ("<b>Patient Data</b>"). 
  <br></br>  
  3.4. The Customer shall only share Patient Data necessary for Eon Dental to carry out its obligations under this Agreement. Customer shall ensure that each Patient signs the Patient Information and Consent Form ("<b>Consent Form</b>") 
  available on Eon Access and retain a copy of such Consent Form for their own records.
  <br></br>
  3.5. The Customer shall have the full and exclusive responsibility for (i) for the accuracy of the Scans and other Patient Data, and (ii) to manage the Patient's expectations on the timing of their treatment,
  particularly where delays are caused by the Customer or Patient's own delays or failures to act.
  <br></br>
  3.6. Eon Dental shall produce a Treatment Plan within five (5) days of receiving the Patient Data in the form and quality as accepted by Eon Dental in its absolute and sole discretion. 
  <br></br>
  3.7. Subject to the terms and conditions shown in Article 7, Products will only be manufactured and dispatched from the site of manufacture for Approved Cases. 
  <br></br>
  <b>4. PRODUCTS, PRICING AND PAYMENT TERMS</b>
  <br></br>
  4.1. The Customer may elect one of the Product packages below when placing a Case Order:
  <br></br>
  <li class="t_and_c">4.1.1. &ensp;Eon Pro: includes an unlimited number of initial Treatment Setups and unlimited number of initial Aligner Steps to be shipped to the
  Customer at a rate of no more than twenty four (24) Aligner Steps per single shipment, 
  in addition to an unlimited number of Refinements which must be shipped to the Customer at a rate of no more than twenty four (24) Aligner Steps per single shipment, and two (2) Retainer Sets that must be shipped in one single shipment, before an Expiration Date of thirty six (36) months.</li> 
  <br>
  <li class="t_and_c">4.1.2. &ensp;Eon Plus: includes no more than four (4) initial Treatment Setups and no more than twenty-four (24) initial Aligner Steps to be shipped to the Customer in one single shipment, 
  in addition to three (3) Refinements (each, subject to no more than four (4) Treatment Setups) and one (1) Retainer Set, each of which must be shipped to the Customer in a single shipment before an Expiration Date of eighteen (18) months.</li> 
  <br>
  <li class="t_and_c">4.1.3. &ensp;Eon Basic: includes no more than four (4) initial Treatment Setups and no more than ten (10) initial Aligner Steps to be shipped to the Customer in one single shipment, 
  in addition to one (1) Refinement (subject to no more than four (4) Treatment Setups) and one (1) Retainer Set, each of which must be shipped to the Customer in a single shipment before an Expiration Date of nine (9) months.</li> 
  <br>
  4.2. All invoices, including cancelation invoices, are payable by the Customer in full within thirty (30) days of the invoice.
  <br></br>
  4.3. An Aligner Step is, in the case of Dual Arch package, a pair of one (1) upper dental arch and one (1) lower dental arch or, in the case of a Single Arch package, either one (1) upper dental arch or one (1) lower dental arch. 
  <br></br>
  4.4. The Customer shall ensure that the number of steps clinically required to move the teeth from the initial teeth position to the final teeth position in the initial Treatment Setup does not exceed the number of Aligner Steps in the elected Product package. Eon Dental, in its absolute and sole discretion, may propose initial Treatment Setups that better correspond with the number of Aligner Steps clinically required than those in the elected Product package.
  <br></br>
  4.5. Refinements are additional Aligner Steps based on a new Treatment Setup that, in Eon Dental's absolute and sole discretion, does not change the initial treatment goal of final teeth position. The cumulative number of Aligner Steps in a Product package's Refinement must not exceed the number of initial Aligner Steps from the initial Treatment Setup. Standard fees shall apply for additional Aligner Steps that exceed the number of Aligner Steps from the initial Treatment Setup.
  <br></br>
  In Eon Dental's absolute and sole discretion, if a Refinement's new Treatment Setup, changes the treatment goal of final teeth movement from that of the initial Treatment Setup, then Eon Dental reserves the right to automatically upgrade the initially approved Product package.
  <br></br>
  4.6. A Retainer Set is, in the case of Dual Arch package, a pair of one (1) upper dental arch and one (1) lower dental arch or, in the case of a Single Arch package, either one (1) upper dental arch or one (1) lower dental arch.
  <br></br>
  4.7. Eon Pro is subject to a 'fair-use-policy' and Eon Dental may apply standard fees for additional Aligner Steps and Treatment Setups that it may determine, in its absolute and sole discretion, are excessive with respect to the quantity of or time elapsed between such Aligner Steps and Treatment Setups.
  <br></br>
  4.8. Expiration Date is the number of months from the Approval Date.
  <br></br>
  4.9. For each package, a Case Order becomes closed (a "Closed Order") at the earliest of the (i) shipment of the applicable total number of Refinements included, (ii) shipment of the Retainer Set(s), or (iii) the applicable Expiration Date.
  <br></br>
  4.10. Additional Aligner Steps cannot be ordered for Closed Orders.
  <br></br>
  <b>5. EON OBLIGATIONS</b>
  <br></br>
  5.1. Eon Dental warrants that any and all Products are not damaged, bent or broken, are conforming with the Treatment Setup and are free from defects in material and workmanship.
  <br></br>
  5.2. The limited warranty in Article 5.1 expires ninety (90) days after the Products have been delivered in accordance with Article 7. Delays due to a Customer's delivery to a Patient, or due to a Patient's wear schedule after the Products have been delivered to the Customer do not extend the limited warranty period.
  <br></br>
  5.3. Eon Dental will not be liable for:
  <br></br>
  <li class="t_and_c">5.3.1. &ensp;The clinical outcome of any treatment using the Products or Services;</li>
  <br>
  <li class="t_and_c">5.3.2. &ensp;Failure to follow instructions, neglect, misuse, mistreatment or improper handling of the Products and Services by the Customer, Patient or any third party;</li>
  <br>
  <li class="t_and_c">5.3.3. &ensp;Products or Services that have been altered in any way by any party other than Eon Dental;</li>
  <br>
  <li class="t_and_c">5.3.4. &ensp;Products or Services that have been used in combination with any other product or services provided by any other party other than Eon Dental;</li>
  <br>
  </li>5.3.5. Errors in submitted Scans or other Patient Data.</li>
  <br></br>
  5.4. Except for the sole and limited warranty shown in Article 5.1, Eon Dental disclaims any and all other warranties of any kind, express or implied, including warranties of merchantability and fitness for a particular purpose. For the avoidance of doubt, this sole and limited warranty is only given to the Customer and not any other third party including any Patient.
  <br></br>
  5.5. If any Product or Service fails to conform to the sole and limited warranty shown in 5.1, Eon Dental's sole liability, would be to either, at its absolute and sole discretion (i) replace the non-conforming Product or (ii) credit the Customer's account for the cost of the Product or Service.
  <br></br>
  <b>6. CUSTOMER OBLIGATIONS</b>
  <br></br>
  6.1. Customer warrants and represents that:
  <br></br>
  <li class="t_and_c">6.1.1. &ensp;Customer is licensed to practice dentistry or orthodontics in the location to where the Product will be shipped;</li>
  <br>
  <li class="t_and_c">6.1.2. &ensp;Customer operates in accordance with applicable laws, regulations and standard of care in the location to where the Product will be shipped;</li>
  <br>
  <li class="t_and_c">6.1.3. &ensp;Customer has the necessary training, expertise and experience to treat Patients using the Products and Services;</li>
  <br>
  <li class="t_and_c">6.1.4. &ensp;Customer intends to only use the Products and Services in accordance with the Clinician Guide (available on Eon Access);</li>
  <br>
  <li class="t_and_c">6.1.5. &ensp;Customer is not purchasing the Products or Services with the intent for use by any party besides the Customer and the Customer's Patient, or outside the country to where the Products will be shipped by Eon Dental.</li>
  <br>
  6.2. Customer shall:
  <br></br>
  <li class="t_and_c">6.2.1. &ensp;Not breach applicable laws, regulations, professional rules, or otherwise lose its ability (including having its professional registration withdrawn or limited) to practice dentistry or orthodontics, or where applicable, operate as a dental clinic;</li>
  <br>
  <li class="t_and_c">6.2.2. &ensp;Be solely and exclusively responsible for the treatment of Patients, exercising clinical judgment in the suitability of using the Products and Services (including the Treatment Setup), the outcome of the Customer's clinical decisions, and maintaining Patient Data;</li>
  <br>
  <li class="t_and_c">6.2.3. &ensp;Properly explain the Products and their clinical risks to the Patients before starting treatment; disclose to each Patient that Customer may share Patient Data with Eon Dental to facilitate their treatment with Customer's discretion; and ensure that each Patient signs the Consent Form and retain a copy of such Consent Form for their own records;</li>
  <br>
  <li class="t_and_c">6.2.4. &ensp;Not ship the Products out of the country to which they were shipped by Eon Dental;</li>
  <br>
  <li class="t_and_c">6.2.5. &ensp;Not allow any other party to use the Products or Services and only use them for the Patient for whom such Products or Services were shipped or provided; and</li>
  <br>
  <li class="t_and_c">6.2.6. &ensp;Pay the sums that are owed to Eon Dental when they are due for payment in accordance with Article 10.</li>
  <br>
  6.3. Customer acknowledges and understands that:
  <br></br>
  <li class="t_and_c">6.3.1. &ensp;Eon Dental is a medical device manufacturer, does not provide medical, dental or any health care services or advice and is not licensed to do so in any jurisdiction;</li>
  <br>
  <li class="t_and_c">6.3.2. &ensp;Eon Access is a case management portal that renders expected teeth movement and the actual results may vary. Some Products and Services incorporate default, preset or optional features and must only be considered as a recommendation with the ultimate decision to approve and apply a Treatment Setup, change them to specific conditions of each Patient, or reject them (subject to any cancelation fee) remaining solely and exclusively with the Customer;</li>
  <br>
  <li class="t_and_c">6.3.3. &ensp;The Customer must read and understand the Clinician Guide and any updates thereto including all risk factors stated therein;</li>
  <br>
  <li class="t_and_c">6.3.4. &ensp;The Eon Doctor details used to log in to Eon Access may only be used by one single Eon Doctor for the treatment of Patients under the direct care and supervision of such Eon Doctor. The use of an Eon Doctor's login details by any other party is strictly prohibited other than (i) by their support staff for the treatment of Patients under the direct care and supervision of such Eon Doctor; or (ii) with the express written permission of Eon Dental;</li>
  <br>
  <li class="t_and_c">6.3.5. &ensp;Any sharing of an Eon Doctor login details with any other party other than as specified in Article 6.3.4 may violate Patient privacy rights. Separate login details must be issued for doctors working in the same Clinic; and</li>
  <br>
  <li class="t_and_c">6.3.6. &ensp;A Case Order constitutes an official purchase order, instructing Eon Dental to produce a Treatment Setup and, where applicable, manufacture the Products, after which time the terms and conditions with respect to cancelation in Article 8, if permitted, apply.</li>
  <br>
  6.4. Without prejudice to any other remedies available to Eon Dental, if the provisions of Article 6.1 and 6.2 are not complied with in any respect throughout the term of this Agreement, Eon Dental in its absolute and sole discretion may:
  <br></br>
  <li class="t_and_c">6.4.1. &ensp;Suspend performance of any of its obligations under this Agreement;</li>
  <br>
  <li class="t_and_c">6.4.2. &ensp;Restrict or terminate the Customer's ability to login to Eon Access;</li> 
  <br>
  <li class="t_and_c">6.4.3. &ensp;Halt the delivery of the Services or Products;</li>
  <br>
  <li class="t_and_c">6.4.4. &ensp;Revoke and withdraw the permission to use the Eon Marks pursuant to Article 9.4;</li>
  <br>
  <li class="t_and_c">6.4.5. &ensp;Terminate the Agreement in accordance with Article 14 and in which case the provisions of Article 15 shall apply.</li>
  <br>
  <b>7. SHIPMENT AND DELIVERY</b>
  <br></br>
  7.1. Eon Dental shall dispatch and ship the Products within fourteen (14) working days of the Approval Date and in accordance with the number of shipments for each Product shown in Article 4. 
  <br></br>
  7.2. Any time periods indicated are not binding and are a mere indication of the ordering and delivery process. Eon Dental shall not be liable for any liability, losses or damages that the Customer may incur as a result of any delays in the shipment and delivery of the Products.
  <br></br>
  7.3. If the Products are not delivered, Customer will inform Eon Dental who will determine the cause of the delay and, if in accordance with this Agreement Eon Dental is under an obligation to deliver, Eon Dental will manufacture and ship replacement Products and this will be the Customer's sole remedy in respect of an undelivered Product. 
  <br></br>
  7.4. Any delay in the delivery of Products shall not relieve the Customer of its obligations to accept and pay for the Products.
  <br></br>
  7.5. If Customer fails to accept delivery of the Products within four (4) days of a notice from Eon Dental or the party delivering the Products that the Products are ready for delivery, then delivery of the Products will be deemed to have been completed on the fifth day after such notice. Eon Dental shall charge Customer for redelivery and storage costs, including insurance, but shall have no obligation to redeliver the Products seven (7) days after the notice to Customer that the Products are ready for delivery.
  <br></br>
  7.6. The Products will be shipped CIF to the delivery address as indicated in Eon Access, where title and risk of all Products transfer to the Customer at the first party delivering the Products. For the avoidance of doubt, Eon Dental shall deliver the Products to the party delivering the Products and Eon Dental shall contract and pay for all delivery and shipping costs to the Customer delivery address indicated in Eon Access.
  <br></br>
  <b>8. CANCELATIONS AND TERMINATION</b>
  <br></br>
  8.1. Eon Dental may cancel an Approved Case at any time in its absolute and sole discretion without any liability.
  <br></br>
  8.2. Eon Dental shall charge cancelation fees in accordance with Article 10 if a Customer (i) cancels a Case Order prior to such Case Order becoming an Approved Case or (ii) a Case Order does not become an Approved Case within one hundred eighty (180) days from the date of the Case Order whether due to missing Patient Data or for any other reason.
  <br></br>
  8.3. All sales are final. If a Customer cancels an Approved Case for any reason whatsoever, Eon Dental shall not credit or refund any portion of the fees related to that Approved Case and any associated payments shall remain due and payable to Eon Dental.
  <br></br>
  <b>9. INTELLECTUAL PROPERTY RIGHTS AND TRADEMARKS</b>
  <br></br>
  9.1. The Customer agrees that Eon Dental retains, and shall always retain, the sole and exclusive right, title, interest in and to, and ownership of all Intellectual Property Rights related to Eon Dental, Eon Access, the Products and Services. 
  <br></br>
  9.2. "<b>Intellectual Property Rights</b>" mean all intellectual property, industrial property, and proprietary rights and assets, and all rights, interests and protections that are associated with, similar to, or required for the exercise of, any of the foregoing, however arising, pursuant to the law of any jurisdiction throughout the world, whether registered or unregistered, 
  including any and all (a) patents, patent applications, patent disclosures, and inventions and all improvements thereto (whether or not patentable or reduced to practice), and all reissues, renewals, continuations, continuations-in-part, revisions, divisional, extensions, and reexaminations in connection therewith, (b) trademarks, service marks, logos, trade dress, trade styles,
  corporate names, business names (including fictitious), trade names, and other indicia of source, and all registrations, applications, recordings, and renewals in connection therewith (together with the goodwill associated therewith) ("<b>Marks</b>"), (c) copyrights and all works of authorship (whether or not copyrightable), and all registrations, applications, extensions, restorations,
  and renewals in connection therewith, regardless of the medium of fixation or means of expression ("<b>Copyrights</b>"), (d) software and firmware, including data files, source code, object code, application programming interfaces, architecture, files, records, schematics, computerized databases and other related specifications and documentation ("<b>Software & Databases</b>"),
  (e) internet domain names and URLs ("<b>Domain Names</b>"), (f) any Trade Secrets, and (g) know-how, technologies, databases, processes, concepts, data, discoveries, models, research and development information, techniques, protocols, methods, formulae, algorithms, layouts, designs, specifications, and confidential information.
  <br></br>
  9.3. No transfer of any Intellectual Property Rights or other proprietary rights are intended by this Agreement.
  <br></br>
  9.4. Eon Dental agrees to allow the Customer to use the Eon Marks on a royalty-free basis for the sole and limited purpose of promoting the Customer's business and the use of Products within the Customer's business ("<b>Customer Promotion</b>") only if the Customer:
  <br></br>
  <li class="t_and_c">9.4.1. &ensp;Continues to comply with the obligations set out in Articles 6.1 and 6.2;</li>
  <br>
  <li class="t_and_c">9.4.2. &ensp;Uses the Eon Marks as provided by Eon Dental without altering the Eon Marks in any way without Eon Dental's permission;</li>
  <br>
  <li class="t_and_c">9.4.3. &ensp;Does not use the Eon Marks for any purpose besides Customer Promotion and that such use shall be consistent with the highest professional standards and not for any other purpose or manner that Eon Dental may, in its absolute and sole discretion, deem to be inappropriate;</li>
  <br>
  <li class="t_and_c">9.4.4. &ensp;Verifies that all Customer Promotion that uses Eon Marks complies with applicable laws, regulations and professional rules;</li>
  <br>
  <li class="t_and_c">9.4.5. &ensp;Will not permit the use of the Eon Marks by any third party without the prior written consent of Eon Dental, and that doing so will be deemed a violation of this permission;</li>
  <br>
  <li class="t_and_c">9.4.6. &ensp;Agrees and acknowledges that Eon Dental shall have the right to revoke and terminate this permission at any time upon five (5) days written notice, for any reason or no reason whatsoever, and Customer agrees that upon receiving such notice, Customer shall cease the distribution of and destroy any materials containing the Eon Marks; and</li>
  <br>
  <li class="t_and_c">9.4.7. &ensp;Agrees and acknowledges that Customer shall not be entitled to recover any costs, fees, damages, or other sums from Eon Dental for any claim relating to or arising out of this permission.</li>
  <br></br>
  <b>10. PRICING AND PAYMENTS</b>
  <br></br>
  10.1. Prices for Products and Services shall be as per the price list published by Eon Dental or, as applicable, its authorized reseller at the Approval Date. Eon Dental, or as applicable its authorized reseller, reserves the right to update such prices from time to time without prior notice. 
  <br></br>
  10.2. Eon Dental may offer certain Customers benefits and discounts under its Eon Aligner Loyalty Program. The details of these benefits are available with the relevant Eon Dental employee or authorized reseller.
  <br></br>
  10.3. Prices for Products and Services shall not include applicable sales tax, value-add tax, consumption tax, withholding tax or any other similar charges levied by any governmental authority and shall include shipping and insurance. 
  <br></br>
  10.4. The Customer is responsible for payment of the invoices. The Eon Doctor and the Clinic, if named in the invoice or as a matter of practice pays for the invoice(s) issued by Eon Dental, are jointly and severally liable for payment of invoices. Eon Dental may therefore claim payment of any sums owed under any agreement against the Eon Doctor or against the Clinic.
  <br></br>
  10.5. Amounts due from Customer to Eon Dental under this Agreement must be paid in full without any amounts to be set off, deducted, withheld, or offset for any reason whatsoever. If such deductions or withholding are required by law, then the Customer shall gross up such amounts payable to ensure that Eon Dental receives the same amount that it would have received had no such amounts been deducted or withheld as a result of the payment. The Customer is responsible for all fees charged by third parties (including outbound remittance or foreign exchange fees charged by the Customer’s banks or financial institutions).
  <br></br>
  10.6. Customer will pay all Eon Dental invoices as directed in the invoice in full and immediately available funds and within the period specified in Article 4, or as otherwise agreed with Eon Dental under a separately negotiated payment plan.
  <br></br>
  10.7. Eon Dental may in its absolute and sole discretion set a maximum value of amounts due by a particular Customer to Eon Dental. In such instances, if a Customer places a new Case Order, the Customer may be required to (i) settle part of the amounts due to Eon Dental earlier than as provided in Article 10.6 of this Agreement, or (ii) make an advanced payment for such new Case Orders.
  <br></br>
  10.8. In case of delay past the due date of any properly invoiced amount to be made by the Customer to Eon Dental under this Agreement: (i) Eon Dental shall be allowed to exercise its rights and remedies available pursuant to Article 6.4; and (ii) the Customer may automatically and without any notice be charged interest at a per monthly rate of one percent (1%) in addition to all costs and fees (including reasonable attorney fees) incurred by Eon Dental in collecting such overdue amounts.
  <br></br>
  <b>11. INDEMNIFICATION</b>
  <br></br>
  11.1. The Customer shall indemnify, defend and save harmless Eon Dental from and against any and all direct costs, losses, expenses, or liabilities (including attorney fees) incurred by Eon Dental in connection with or resulting from: (i) use of the Products or Services sold under this Agreement, (ii) misrepresentation or breach of warranty by the Customer, or material breach or violation by the Customer of any terms or conditions of this Agreement, (iii) material breach of any term or condition of any agreement between the Customer and any Patient or any acts or failures in respect of a Patient, (iv) Customer's provision of incorrect or incomplete information, including Scans or other Patient Data to Eon Dental or any failure to timely provide Eon Dental with any information it requests from Customer; and (v) any and all dealings with national regulators, licensing or professional bodies in relation to Customer.
  <br></br>
  <b>12. LIMITATION OF LIABILITY</b>
  <br></br>
  12.1. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, SHALL ANY PARTY BE LIABLE TO THE OTHER PARTY FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, PUNITIVE, RELIANCE, ENHANCED DAMAGES OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST REVENUE, LOST PROFITS, LOSS OR INTERRUPTION OF BUSINESS OR LOSS OF PATIENT DATA OR OTHER DATA) INCURRED BY THE OTHER PARTY HERETO RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF THE OTHER PARTY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES. 
  <br></br>
  12.2. UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE), SHALL EON DENTAL BE LIABLE TO THE CUSTOMER FOR ANY DIRECT DAMAGES IN AN AMOUNT THAT EXCEEDS THE TOTAL AMOUNT PAID TO EON DENTAL FOR THE PARTICULAR PRODUCTS OR SERVICES SOLD FOR WHICH LOSSES OR DAMAGES ARE CLAIMED.
  <br></br>
  <b>13. DATA PRIVACY</b>
  <br></br>
  13.1 Each party undertakes, represents, and warrants that it will comply with applicable laws and regulations relating to the collection, use, storage, and protection of any personal data of Customer and Patients (including Patient Data). Customer acknowledges to have read and accepted Eon Dental’s privacy policy available on the Eon Dental website. Each Party will maintain any personal data confidential in accordance with applicable laws, will use any such personal data solely to the extent permitted by this Agreement and applicable laws, and will not use or disclose any such personal data in any manner that would constitute a violation of any applicable laws.
  <br></br>
  13.2. For any and all personal data of Patients (including Patient Data), the Customer is the data controller and Eon Dental is data processor under applicable laws and regulations.
  <br></br>
  13.3. Eon Dental undertakes, represents and warrants that it will use and process any personal data of Customers and Patients (including Patient Data) only for the purpose of performing its obligations under this Agreement.
  <br></br>
  13.4. The Customer agrees that Eon Dental may use Patient Data for promotional, educational, or research and development purposes, publications in professional journals or use in professional collateral materials, provided that such Patient Data has first been anonymized in a way that neither the Customer nor any Patient is identifiable.
  <br></br>
  13.5. Eon Dental shall not, for any purposes other than the performance of this Agreement, access, use, store or process personal data of Customers and Patients (including Patient Data) for any purpose beyond the scope of this Agreement. Eon Dental agrees to protect the privacy and security of any personal data of Customers and Patients (including Patient Data) and to comply with the relevant privacy laws and regulations
  <br></br>
  <b>14. TERMINATION</b>
  <br></br>
  14.1. Without prejudice to its right to claim full compensation for damages suffered in accordance with the provisions of this Agreement, Eon Dental may terminate this Agreement with immediate effect as follows:
  <br></br>
  </li>14.1.1. Customer breaches any of the terms and conditions of this Agreement including Articles 6.1 or 6.2;</li>
  <br></br>
  <li class="t_and_c">14.1.2. &ensp;Customer becomes insolvent, is declared bankrupt, is subject to a liquidation, winding-up or receivership, suspends payment of its due debts or announces its intention to do so, or is unable to meet its obligations under this or any other agreement;</li>
  <br>
  <li class="t_and_c">14.1.3. &ensp;Eon Dental considers that the Customer is likely to adversely affect the ongoing, proper, and timely treatment of Patients using the Products and Services, or otherwise meet its obligations under this Agreement, or Eon Dental's best interests.</li>
  <br>
  14.2. Eon Dental may terminate this Agreement, anytime, without cause, after delivering a 30 days prior written notice to Customer.
  <br></br>
  <b>15. CONSEQUENCES OF TERMINATION</b>
  <br></br>
  15.1. Upon expiration or termination of this Agreement for any reason, Eon Dental shall immediately destroy or return to the Customer, at the Customer's discretion and expense, all Patient Data; however, Eon Dental shall not be required to return to the Customer or destroy those copies of Patient Data that (A) reside on Eon Dental’s backup, disaster recovery or business continuity systems, or (B) Eon Dental is obligated by applicable laws or regulations to retain. 
  <br></br>
  15.2. Immediately upon the effective date of a termination of this Agreement for any reason, Customer shall:
  <br></br>
  <li class="t_and_c">15.2.1. &ensp;Pay Eon Dental all outstanding and unpaid amounts due; and</li>
  <br>
  <li class="t_and_c">15.2.2. &ensp;Cease all use of the Eon Marks and any other Intellectual Property Rights related to the Products, and the licenses to use the Eon Marks and the other Intellectual Property Rights granted hereunder will immediately cease and be deemed canceled.</li>
  <br>
  <b>16. MISCELLANEOUS</b>
  <br></br>
  <b>16.1. Notices</b>
  <br></br>
  <li class="t_and_c">16.1.1. &ensp;Any information under or in relation to this Agreement may be exchanged by email or electronic data interchange.  Any formal notice or communication shall be in writing and shall be deemed given in accordance with Article 16.1.2 if such notice is addressed, for Eon Dental at <a href="info@eonaligner.com">info@eonaligner.com</a> (with a copy of legal notices to <a href="legal@eonaligner.com">legal@eonaligner.com</a>), and for the Customer at either the email address or physical address provided by the Customer in Eon Access.</li> 
  <br>
  <li class="t_and_c">16.1.2. &ensp;Any and all notices permitted or required to be given hereunder will be deemed duly effectively given upon receipt when delivered personally; upon the earlier of forty-eight (48) hours or receipt acknowledged by return email when delivered by email; or upon forty-eight (48) hours after being deposited with an industry recognized and reputable overnight international courier service (such as Federal Express or UPS), signature required.</li>  
  <br>
  <b>16.2 Relationship</b>
  <br></br>
  Nothing contained in this Agreement will be construed as to: (i) create a labor relationship, (ii) constitute the parties as partners, joint venturers, co-owners or otherwise participants in a joint undertaking, (iii) give a party the power to direct and control the day-to-day activities of the other party or its Affiliates; (iv) cause a party to assume any obligation or be held liable for the contractual obligations of the another party, except that the Clinic may assume the obligations of or be held liable for the Eon Doctor; or (v) allow a party to create any obligation on behalf of the other party for any purpose whatsoever, except that the Eon Doctor may create an obligation for the Clinic.
  <br></br>
  <b>16.3. Entire Agreement and Amendments.</b>
  <br></br>
  The Agreement including the preamble and recitals constitutes the entire agreement and understanding between the parties with respect to the subject matter of this Agreement and supersedes all previous letters, representations, warranties, or agreements, express or implied, between the Parties relating to the subject matter of the Agreement. 
  <br></br>
  Eon Dental shall have the right to modify or amend the Agreement from time to time. When Eon Dental makes changes to the Agreement, your continued access to or use of Eon Access, Products and Services will constitute an acceptance of any modification or amendment to the Agreement.
  <br></br>
  <b>16.4. Assignment</b>. 
  <br></br>
  This Agreement is personal to both Eon Dental and the Customer. Eon Dental may assign in whole or in part any of its rights or obligations under this Agreement to any person, without the prior written consent of the Customer. Customer may not assign in whole or in part any of its rights or obligations under this Agreement to any person, without the prior written consent of the Eon Dental. 
  <br></br>
  <b>16.5. No Waiver</b>. 
  <br></br>
  Eon Dental shall not be considered to have waived any rights under this Agreement by not exercising or delaying the exercise of any rights under Agreement. If Eon Dental waives any right under this Agreement, such waiver will not constitute a waiver of any other or subsequent right, even if all rights under this Agreement are similar in character.
  <br></br>
  <b>16.6. Severability.</b>
  <br></br>
  If any provision or covenant in the Agreement is unenforceable, invalid, or contrary to mandatory law, such provision or covenant will be ineffective to the extent of such unenforceability, invalidity, or illegality.  The remainder of such provision or covenant, and any other provision or covenant in the Agreement, will remain valid, legal, and enforceable. 
  <br></br>
  <b>16.7. Force Majeure</b>
  <br></br>
  This Agreement and all obligations hereunder of Eon Dental shall be suspended for any period ("<b>Period of Suspension</b>"), and Eon Dental will not be liable in any way for failure or delay in performing its obligations under this Agreement during the Period of Suspension due to acts of God, including war or warlike conditions (e.g., terrorism, invasion, riot, or other civil unrest), blockades, international sanctions or embargoes, serious accidents, fires, floods, strikes or lock-outs, lockdowns (including epidemic or pandemic lockdowns), uncontrollable and unforeseen transport interruptions, non-performance by Eon Dental's subcontractors, suppliers or providers of their respective obligations or failure of software or hardware of said parties, government intervention, civil insurrection, epidemics or pandemics in each case, or any other even beyond Eon Dental's reasonable control (“Force Majeure”); provided, however, that Eon Dental shall promptly notify the Customer of the Force Majeure. For the avoidance of doubt, any and all obligations to pay money to Eon Dental shall remain in place during any Period of Suspension despite any Force Majeure events.
  <br></br>
  <b>16.8. Governing Law and Jurisdiction</b>
  <br></br>
  <li class="t_and_c">16.8.1. &ensp;This Agreement and any non-contractual obligations arising out of or in connection with it shall be governed by and construed in accordance with Belgian law.</li>
  <br>
  <li class="t_and_c">16.8.2. &ensp;Eon Dental and the Customer agree that any dispute, controversy or claim whatsoever arising out of, in relation to or in connection with this Agreement that cannot be settled amicably shall be finally settled by the courts of Antwerp, section Antwerp.</li>
  <br></br>
  `,
  you_cant_select_pontics_on_this_tooth:
    "You can’t select pontics on this tooth",
  loyalty_program: "Loyalty program",
  your_benefits: "Your benefits",
  discount: "Discount",
  educational_events: "Educational events",
  digita_marketing_consultation: "Digital marketing consultation",
  advanced_digital_marketing_consultation:
    "Advanced digital marketing consultation",
  expert_orthodontist_consultation: "Expert orthodontist consultation",
  commercial_business_consultation: "Commercial & business consultation",
  dedicated_clinical_specialist_CAD_designer:
    "Dedicated clinical specialist & CAD designer",
  inclinic_marketing_kit: "In-clinic marketing kit",
  practicep_laque: "Practice plaque",
  automatically_applied: "Automatically applied",
  invites_will_be_sent_by_email: "Invites will be sent by email",
  book_your_session_now: "Book your session now",
  free_1_session_month: "1 free session/month",
  free_2_session_month: "2 free sessions/month",
  free_3_session_month: "3 free sessions/month",
  free_4_session_month: "4 free sessions/month",
  free_5_session_month: "5 free sessions/month",
  free_3_sessions: "3 free sessions",
  sent_directly_to_you_if_not_received: "Sent directly to you, if not received",
  custom: "Custom",
  book_time_with_your_dedicated_specialist_via_email:
    "Book time with your dedicated specialist via email",
  Automatically_sent_if_not_received: "Automatically sent, if not received",
  click_here: "click here",
  explore_the_full_loyalty_program: "Explore the full loyalty program",
  description_of_benefits: "Description of benefits",
  discount_discount_on_the_price_of_all_aligner_treatments:
    "1. Discount: discount on the price of all aligner treatments.",
  educational_events_online_and_in_person_events_for_learning_networking_and_collaboration:
    "2. Educational events: online and in-person events for learning, networking and collaboration.",
  digital_marketing_support_Take_advantage_of_digital_marketing_support_services_that_help_you_promote_and_grow_your_business_online:
    "3. Digital marketing support: Take advantage of digital marketing support services that help you promote and grow your business online .",
  expert_orthodontist_consultation_one_on_one_minute_sessions:
    "4. Expert orthodontist consultation: one-on-one 30 minute sessions with an orthodontist that has extensive aligner experience and can advise doctors on specific cases.",
  commercial_business_consultation_One_on_one_personalized:
    "5. Commercial & business consultation: One-on-one personalized assessment sessions from our success team who are armed with data and are here to help you ",
  grow_youraligner_business:
    "grow youraligner business, by understanding your goals and targets to assist you with patient pricing and more.",
  Dedicated_clinical_specialist_CAD_designer:
    "6. Dedicated clinical specialist & CAD designer: a team is assigned to doctors of certain tiers to make all communication around cases easier and more efficient. ",
  they_assess_case_history:
    "They assess case history, preferences and understand treatment protocols for all future cases. This decreases turnaround time, gets cases approved faster with",
  less_modifications_needed:
    " less modifications needed (up to 2 calls per case).",
  In_clinic_marketing_kit_A_complete_marketing_toolkit_to:
    "7. In-clinic marketing kit: A complete marketing toolkit to promote clear aligners in your clinic, including; brochures, marketing videos, case studies, patient",
  testimonials_and_ready_to_use:
    "testimonials and ready-to-use social media templates.",
  Practice_plaque_plaque_awardedby_Eon_to_recognize_and_celebrate_the_top_tiers:
    "8. Practice plaque: A plaque awarded by Eon to recognize and celebrate the top tiers. Hang it up in your clinic, or show it off in your office!",
  FAQs: "FAQs",
  what_is_the_duration_for_this_loyalty_program:
    "  What is the duration for this loyalty program?",
  the_duration_is_12_months_meaning_the_case_counter_resets_every_year_to_zero:
    "The duration is 12 months, meaning the case counter resets every year to zero. That being said, for the next 12 months you still get to enjoy your benefits, just make sure",
  you_achieve_at_least_the_minimum_number_of_cases_to_maintain_your_tier:
    "you achieve at least the minimum number of cases to maintain your tier.",
  how_do_I_upgrade_to_higher_tiers: "How do I upgrade to higher tiers? ",
  our_system_counts_the_number_of_approved_cases_youve_achieved:
    "Our system counts the number of approved cases you’ve achieved, once you hit a certain number that corresponds to the minimum required for the next tier,",
  you_will_be_automatically_upgraded: "you will be automatically upgraded.",
  Is_it_possible_to_downgrade_to_lower_tiers:
    "Is it possible to downgrade to lower tiers?",
  Yes_it_is_downgrade_check_happens_after_year_2_of_tier_attainment:
    "Yes it is. A downgrade check happens after year 2 of tier attainment, meaning you can enjoy the benefits of a tier until a check is done in year 2. If the number",
  of_cases_is_notin_the_range_of_current_tier_then_downgrade_occurs_that_matches_your_performance:
    "of cases is not in the range of current tier then a downgrade occurs that matches your performance.",
  are_all_cases_counted_the_same: "Are all cases counted the same?",
  No_Eon_Basic_is_counted_as_half_a_case_and_Eon_Pro_and_Eon_Plus_are_counted_as_one_case:
    "No, Eon Basic is counted as half a case and Eon Pro and Eon Plus are counted as one case.",
  How_do_I_access_my_benefits: "How do I access my benefits?",
  the_consultation_related_benefits_are_bookable_through_the_links:
    "The consultation related benefits are bookable through the links found above (in the section titled Your Benefits). Some benefits are automatically applied",
  like_discounts_inclinic_marketing_kits_are_sent_automatically:
    "like discounts, in-clinic marketing kits are sent automatically, practice plaques are sent yearly and as for educational events you will receive invites by email.",
  cases_for_each_tier_refer_to_the_number_of_approved_cases_per_year:
    "Cases for each tier refer to the number of approved cases per year. Until October 1st 2022, Eon Basic, Plus and Pro case types will all be counted as 1 case, after ",
  which_Eon_Basic_will_be_counted_as_half_a_case:
    "which Eon Basic will be counted as half a case.",
  cases_required_to_upgrade: "Cases required to maintain the current tier next year",
  tier_period: "Tier period",
  your_tier: "Your tier",
  What_are_my_benefits: "What are my benefits?",
  menu: "Menu",
  upper_aligners: "Upper Aligners",
  lower_aligners: "Lower Aligners",
  please_be_advised: "Please be advised",
  approve_ts_note: `<div class="details_normal" >Once you click <span class="details_bold"> approve </span>, aligner manufacturing will start immediately, and this case is <span class="details_bold"> non-refundable </span>. </div>`,
  submit_revision: "Submit Revision",
  terms_and_condition: "Terms and Conditions",
  weve_updated_our_terms_and_conditions:
    "We’ve Updated our Terms and Conditions",
  accept_terms_note:
    "By accepting you agree to our updated terms and conditions.",
  ar: "عربي",
  start_new_treatment: "Start New Treatment",
  addtional_aligners: "Addtional Aligners",
  confirmation_start_new_treatment:
    "You’ve used up all of the additional aligners included in your case. Place an order for a new treatment.",
  related_cases: "Related Cases",
  resources: "Resources",
  resource_1_title: "patient information and consent form",
  terms_and_condition2: "Terms & Conditions",
  patient_consent_form: "Patient Consent Form",
  media_consent_form:"Media Consent Form",
  attention_Pending_T_S_approval:"Attention! Pending T.S approval",
  Dear_valued_doctor_please_be_aware_that_if_you_dont_approve_the_case_in :" Dear valued doctor, please note that  if you don’t approve this case in" , 
  it_will_automatically_expire_cancellation_charges_apply_for_expired_cases_submitted_after_October:"it will automatically expire. Cancellation charges apply for expired cases submitted after October 1st." , 
  pickup_address:"Pickup address", 
  Heads_up_treatment_duration_expiring_in:"Heads up! Treatment duration expiring in", 
  days:"days" , 
  this_patients_treatment_duration_expires_soon_and_the_case_will_automatically:"This patient's treatment duration expires soon, and the case will automatically be marked as complete" ,
  on :"on" ,
  you_wont_be_able_to_request_refinements_andor_receive_your_free_retainer :" You won't be able to request refinements and/or receive your free retainer.", 
  change :"Change", 
  change_arch_selection:"Change Arch Selection" ,
  cancel:"Cancel" ,
  change_case_type:"Change Case Type", 
  arch :"Arch" ,
  case_package_has_been_changed:"Case package has been changed:",
  do_you_agree_with_this_change:"Do you agree with this change?" ,
  please_note_that_your_package_selection_has_been_changed_to:" Please note that your package selection has been changed to support you in achieving your desired treatment outcome." ,
  decline:"Decline",
  case_type_successfully_updated:"Case Type Updated Successfully",
  arch_successfully_updated:"Arch Updated Successfully"
  ,missingIPR :"missingIPR" , 
  update_error: "Update Unsuccessful",
  need_help_choosing_your_package_type : "Need help choosing your package type?" , 
  evaluate_your_case:"Evaluate Your Case" , 
  evaluate :" Evaluate" , 
  your_case:"Your Case" , 
  clinic_name:"Clinic Name",
  please_check_missing_fields: "Please check missing/invalid fields",
  write_step_no: "Write Step No.",
  treatment_prescription_form: "Treatment Prescription Form",
  download_prescription_form: "Download Prescription Form",
  ipr_generation: "IPR file is being generated.",
};
