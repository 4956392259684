import React from "react";
import { connect } from "react-redux";
import TSViewer from "../TSViewer/TSViewer";

export const Viewer = (props) => {
  const { id } = props?.userReducer?.currentUser;

  const { details, showBiteRecord ,height, divided, viewerId} = props;
  return (
    <div className="tp_viewer">
      <TSViewer
        viewerId={viewerId}
        viewerHeight={height}
        details={details}
        doctorId={id}
        caseId={details.aligner_case_uid}
        showBiteRecord={showBiteRecord}
        divided={divided}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);
