import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function ShipmentPopup({
  expiresAt, // end date
  auto_expires_at_value,
  content,
  date_format,
}) {
  const intervalRef = useRef();
  const [expiryTime, setExpiryTime] = useState(new Date(expiresAt));
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownlMinutes: "",
    countdownSeconds: "",
  });

  const countdownTimer = () => {
    intervalRef.current = setInterval(() => {
      const countdownDateTime = new Date(expiryTime).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime < 0) {
        clearInterval(intervalRef.current);
        setExpiryTime(false);
      }
    }, 1000);
  };

  useEffect(() => {
    countdownTimer();

    return () => clearInterval(intervalRef.current);
  });

  if (countdownTime.countdownDays > 30 && countdownTime.countdownDays < 0) {
    return <></>;
  }

  return (
    <>
      {countdownTime.countdownDays ? (
        <span>
          {countdownTime.countdownDays <= 30 &&
            countdownTime.countdownDays > 0 && (
              <div className="Heads-up-pop-up">
                <div className="Heads-up-title">
                  {content.Heads_up_treatment_duration_expiring_in}(
                  {countdownTime.countdownDays}) {content.days}
                </div>
                <div className="attention-text">
                  {
                    content.this_patients_treatment_duration_expires_soon_and_the_case_will_automatically
                  }
                  ({content.on} {auto_expires_at_value}).
                  {
                    content.you_wont_be_able_to_request_refinements_andor_receive_your_free_retainer
                  }
                </div>
              </div>
            )}
        </span>
      ) : (
        ""
      )}
    </>
  );
}
