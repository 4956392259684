import { HYBRID_STATE } from "../constants/";

const INIT_STATE = {
  convert_to_hybrid_treatment: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case HYBRID_STATE:
      return {
        ...state,
        convert_to_hybrid_treatment: action.payload
      }

    default:
      return state;
  }
};
