import { UPDATE_ADJUSTMENT, RESET_ADJUSTMENT } from "../constants/";
const INIT_STATE = {
  saveNewAdjustment: false,
  adjustmentInfo: {
    current_upper_step: "",
    current_lower_step: "",
    tracking_upper_step: "",
    tracking_lower_step: "",
    additional_instructions: "",
    reason: "",
  },
  photos: {
    front_smiling_photo: "",
    front_smiling: "",
    front_smiling_image: false,
    front_pose_photo: "",
    front_pose: "",
    front_pose_image: false,
    profile_photo: "",
    profile: "",
    profile_image: false,
    upper_occlusal_photo: "",
    upper_occlusal: "",
    upper_occlusal_image: false,
    lower_occlusal_photo: "",
    lower_occlusal: "",
    lower_occlusal_image: false,
    left_buccal_photo: "",
    left_buccal: "",
    left_buccal_image: false,
    frontal_photo: "",
    frontal: "",
    frontal_image: false,
    right_buccal_photo: "",
    right_buccal: "",
    right_buccal_image: false,
  },
  impressions: {
    Digital_Impression: false,
    Digital_Means: false,
    Arrange_Pickup: false,
    upperImageValue: "",
    lowerImageValue: "",
    additional_comments: "",
    Impression_Type: "",
    upperImage: false,
    lowerImage: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_ADJUSTMENT:
      return action.payload;
    case RESET_ADJUSTMENT:
      return INIT_STATE;
    default:
      return state;
  }
};
