import React, { Component } from "react";
import { Input } from "reactstrap";

export default class RevisedTable extends Component {
    /*--------------------------------------------------------------------------------*/
    /*constructor */
    /*--------------------------------------------------------------------------------*/
    render() {
        const { revised_data } = this.props
        return (
            <div >
                <div className="border-bottom p-2 h-m-3 mb-2 detailes_title case-title movemevt-header"> Teeth Movement</div>
                <div className="tableFixHead">
                    {
                        revised_data && Object.keys(revised_data).length !== 0 &&
                        <table className="p-0 table table-bordered" height="100" size="sm">
                            <thead>
                                <tr>
                                    <th>Tooth #</th>
                                    <th></th>
                                    <th>Mesial/Distal</th>
                                    <th>Rotation</th>
                                    <th>Angulation</th>
                                    <th>Torque</th>
                                    <th>Extrusion/Intrusion</th>
                                    <th>Out/In</th>
                                    <th>Extraction</th>
                                    <th>Attachments</th>
                                    <th>Attachments Step</th>
                                    <th>Attachment Type</th>
                                    <th>Pontics</th>
                                    <th>Torque Enhancers</th>
                                    <th>Elastic Buttons</th>
                                    <th>Elastic Cuts</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(revised_data.teeth_movement.upper_teeth).map((prop, key) => (
                                        <tr key={key}>
                                            <td>{revised_data.teeth_movement.upper_teeth[prop].current.Sys_ToothNumber}</td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">current</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className="p-2 h-m-3">updated</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.LeftRight || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.LeftRight
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.LeftRight
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.LeftRight || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Rotation || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Rotation
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Rotation
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Rotation || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Angulation || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Angulation
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Angulation
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Angulation || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Inclination || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Inclination
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Inclination
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Inclination || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.ExtrusionIntrusion || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.ExtrusionIntrusion
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.ExtrusionIntrusion
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.ExtrusionIntrusion || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.ForwardBackward || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.ForwardBackward
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.ForwardBackward
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.ForwardBackward || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">

                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Extraction.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Extraction.toString()
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Extraction.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Extraction.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Attachments.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Attachments.toString()
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Attachments.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Attachments.toString() || String.fromCharCode(8212)}</div>
                                            </td>

                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Attachments_Step || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Attachments_Step.toString()
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Attachments_Step.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Attachments_Step || String.fromCharCode(8212)}</div>
                                            </td>

                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Attachment_Type || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Attachment_Type
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Attachment_Type
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Attachment_Type || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Pontics.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Pontics.toString()
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Pontics.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Pontics.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Torque_Enhancers.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Torque_Enhancers.toString()
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Torque_Enhancers.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Torque_Enhancers.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Elastic_Buttons.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Elastic_Buttons.toString()
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Elastic_Buttons.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Elastic_Buttons.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Elastic_Cuts.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.teeth_movement.upper_teeth[prop].current.Elastic_Cuts.toString()
                                                        !== revised_data.teeth_movement.upper_teeth[prop].updated.Elastic_Cuts.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Elastic_Cuts.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.upper_teeth[prop].current.Notes || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${(revised_data.teeth_movement.upper_teeth[prop].current.Notes || "")
                                                        !== (revised_data.teeth_movement.upper_teeth[prop].updated.Notes || "")
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.upper_teeth[prop].updated.Notes || String.fromCharCode(8212)}</div>
                                            </td>
                                        </tr>
                                    ))}


                                {
                                    Object.keys(revised_data.teeth_movement.lower_teeth).map((prop, key) => (
                                        <tr key={key}>
                                            <td>{revised_data.teeth_movement.lower_teeth[prop].current.Sys_ToothNumber}</td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">current</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className="p-2 h-m-3">updated</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.LeftRight || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.LeftRight
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.LeftRight
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.LeftRight || String.fromCharCode(8212)}</div>
                                            </td>

                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Rotation || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Rotation
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Rotation
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Rotation || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Angulation || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Angulation
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Angulation
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Angulation || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Inclination || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Inclination
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Inclination
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Inclination || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.ExtrusionIntrusion || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.ExtrusionIntrusion
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.ExtrusionIntrusion
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.ExtrusionIntrusion || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.ForwardBackward || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.ForwardBackward
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.ForwardBackward
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.ForwardBackward || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">

                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Extraction.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Extraction.toString()
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Extraction.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Extraction.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Attachments.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Attachments.toString()
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Attachments.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Attachments.toString() || String.fromCharCode(8212)}</div>
                                            </td>

                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Attachments_Step || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Attachments_Step.toString()
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Attachments_Step.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Attachments_Step || String.fromCharCode(8212)}</div>
                                            </td>

                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Attachment_Type || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Attachment_Type
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Attachment_Type
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Attachment_Type || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Pontics.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Pontics.toString()
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Pontics.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Pontics.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Torque_Enhancers.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Torque_Enhancers.toString()
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Torque_Enhancers.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Torque_Enhancers.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Elastic_Buttons.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Elastic_Buttons.toString()
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Elastic_Buttons.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Elastic_Buttons.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Elastic_Cuts.toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${revised_data.teeth_movement.lower_teeth[prop].current.Elastic_Cuts.toString()
                                                        !== revised_data.teeth_movement.lower_teeth[prop].updated.Elastic_Cuts.toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Elastic_Cuts.toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.teeth_movement.lower_teeth[prop].current.Notes || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                             ${(revised_data.teeth_movement.lower_teeth[prop].current.Notes || "")
                                                        !== (revised_data.teeth_movement.lower_teeth[prop].updated.Notes || "")
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.teeth_movement.lower_teeth[prop].updated.Notes || String.fromCharCode(8212)}</div>
                                            </td>
                                        </tr>


                                    ))}
                            </tbody>
                        </table>

                    }


                </div>
                <div className="border-bottom  mt-3 p-2 h-m-3 mb-2 detailes_title case-title movemevt-header"> IPR </div>
                <div className="tableFixHead">
                    {
                        revised_data && Object.keys(revised_data).length !== 0 &&
                        <table className="p-0 table table-bordered" height="100" size="sm">
                            <thead>
                                <tr>
                                    <th>Tooth #</th>
                                    <th></th>
                                    <th>IPR</th>
                                    <th>Before Step</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(revised_data.ipr.upper_teeth).map((prop, key) => (
                                        <tr key={key}>
                                            <td>{prop}</td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">current</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className="p-2 h-m-3">updated</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.ipr.upper_teeth[prop].current["IPR"].toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.ipr.upper_teeth[prop].current["IPR"].toString()
                                                        !== revised_data.ipr.upper_teeth[prop].updated["IPR"].toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.ipr.upper_teeth[prop].updated["IPR"].toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.ipr.upper_teeth[prop].current.Before_Step || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.ipr.upper_teeth[prop].current.Before_Step
                                                        !== revised_data.ipr.upper_teeth[prop].updated.Before_Step
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.ipr.upper_teeth[prop].updated.Before_Step || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.ipr.upper_teeth[prop].current.Amount || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.ipr.upper_teeth[prop].current.Amount
                                                        !== revised_data.ipr.upper_teeth[prop].updated.Amount
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.ipr.upper_teeth[prop].updated.Amount || String.fromCharCode(8212)}</div>
                                            </td>
                                        </tr>
                                    ))}


                                {
                                    Object.keys(revised_data.ipr.lower_teeth).map((prop, key) => (
                                        <tr key={key}>
                                            <td>{prop}</td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">current</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className="p-2 h-m-3">updated</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.ipr.lower_teeth[prop].current["IPR"].toString() || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.ipr.lower_teeth[prop].current["IPR"].toString()
                                                        !== revised_data.ipr.lower_teeth[prop].updated["IPR"].toString()
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.ipr.lower_teeth[prop].updated["IPR"].toString() || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.ipr.lower_teeth[prop].current.Before_Step || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.ipr.lower_teeth[prop].current.Before_Step
                                                        !== revised_data.ipr.lower_teeth[prop].updated.Before_Step
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.ipr.lower_teeth[prop].updated.Before_Step || String.fromCharCode(8212)}</div>
                                            </td>
                                            <td className="p-0">
                                                <div className="p-2 h-m-3">{revised_data.ipr.lower_teeth[prop].current.Amount || String.fromCharCode(8212)}</div>
                                                <hr className="mt-1 mb-1"></hr>
                                                <div className={`p-2 h-m-3
                                                 ${revised_data.ipr.lower_teeth[prop].current.Amount
                                                        !== revised_data.ipr.lower_teeth[prop].updated.Amount
                                                        ? "changed-value" : ""} }`}>
                                                    {revised_data.ipr.lower_teeth[prop].updated.Amount || String.fromCharCode(8212)}</div>
                                            </td>

                                        </tr>
                                    ))}
                            </tbody>
                        </table>

                    }

                </div>


                <div className="border-bottom  mt-3 p-2 h-m-3 mb-2 detailes_title case-title movemevt-header"> Bite Record </div>
                <div className="readOnly-text">
                    <Input
                        className="teeth_selector"
                        name="Bite_Record"
                        id="Bite_Record"
                        type="textarea"
                        placeholder="Bite_Record"
                        rows={5}
                        value={revised_data.Bite_Record.rejection_reason}
                        readOnly
                    />
                </div>
                <div className="border-bottom  mt-3 p-2 h-m-3 mb-2 detailes_title case-title movemevt-header"> Clinical Protocol </div>
                <div className="readOnly-text">
                    <Input
                        className="teeth_selector"
                        name="Clinical_Protocol"
                        id="Clinical_Protocol"
                        type="textarea"
                        placeholder="Clinical_Protocol"
                        rows={5}
                        value={revised_data.Clinical_protocol.Clinical_protocol_feedback}
                        readOnly
                    />
                </div>
            </div>
        );
    }
}
