import React, { Component } from "react";
import { Row, Col, Input } from "reactstrap";

export default class ExpressTreatmentPlan extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      chief_complaint: props.getClinicalFindingsStore().chief_complaint,
      treatment_summary: props.getSummeryStore().treatment_summary,
    };
  }
  render() {
    return (
      <div className="step step2 mt-5 mb-2">
        <div className="row justify-content-md-center">
          <div className="col">
            <div className="">
              <form id="Form3" className="form-horizontal mt-2 mb-2">
                <Row>
                  <Col xs="12" md="12" lg="6">
                    {/* Chief Complaint  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Chief Complaint
                      </label>
                      <div>
                        <Input
                          autoComplete="off"
                          type="textarea"
                          rows={8}
                          className="green_input"
                          defaultValue={this.state.chief_complaint}
                          onChange={(e) => {
                            this.setState({
                              chief_complaint: e.target.value,
                            });
                            this.props.updateClinicalFindingsStore({
                              ...this.props.getClinicalFindingsStore(),
                              chief_complaint: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xs="12" md="12" lg="6">
                    {/* Treatment Summary  */}
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active ">
                        Treatment Summary
                      </label>
                      <div>
                        <Input
                          autoComplete="off"
                          type="textarea"
                          rows={8}
                          className="green_input"
                          defaultValue={this.state.treatment_summary}
                          onChange={(e) => {
                            this.setState({
                              treatment_summary: e.target.value,
                            });
                            this.props.updateSummeryStore({
                              ...this.props.getSummeryStore(),
                              treatment_summary: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
