import React, { Component } from "react";
import { connect } from "react-redux";

import { CustomInput , Input  } from "reactstrap";

import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),

});
class Midline extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */

    this.upperMidline =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.upperMidline?.upperMidline || "";
    this.upperMidline_displacement =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.upperMidline?.upperMidline_displacement || "";
    this.lowerMidline =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.lowerMidline?.lowerMidline || "";
    this.lowerMidline_displacement =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.lowerMidline?.lowerMidline_displacement || "";

    this.upper_resultMidlineAlignment =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineUpper?.resultMidlineAlignment || "";
    this.upper_maintain =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineUpper?.maintain || "";
    this.upper_improveIPR =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineUpper?.improveIPR || "";
    this.upper_improveExtraction =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineUpper?.improveExtraction || "";

    this.lower_resultMidlineAlignment =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineLower?.resultMidlineAlignment || "";
    this.lower_maintain =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineLower?.maintain || "";
    this.lower_improveIPR =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineLower?.improveIPR || "";
    this.lower_improveExtraction =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineLower?.improveExtraction || "";
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      upperMidline: {
        upperMidline: this.upperMidline,
        upperMidline_displacement: this.upperMidline_displacement,
      },
      lowerMidline: {
        lowerMidline: this.lowerMidline,
        lowerMidline_displacement: this.lowerMidline_displacement,
      },
      treatMidlineUpper: {
        resultMidlineAlignment: this.upper_resultMidlineAlignment,
        maintain: this.upper_maintain,
        improveIPR: this.upper_improveIPR,
        improveExtraction: this.upper_improveExtraction,
      },
      treatMidlineLower: {
        resultMidlineAlignment: this.lower_resultMidlineAlignment,
        maintain: this.lower_maintain,
        improveIPR: this.lower_improveIPR,
        improveExtraction: this.lower_improveExtraction,
      },
    };

    this.props.save("midline", data);
  };
  render() {
    const {
      upperMidline,
      upperMidline_displacement,
      lowerMidline,
      lowerMidline_displacement,

      upper_resultMidlineAlignment,
      upper_maintain,
      upper_improveIPR,
      upper_improveExtraction,

      lower_resultMidlineAlignment,
      lower_maintain,
      lower_improveIPR,
      lower_improveExtraction,
    } = this;
    const { content } = this.props.languageReducer
    return (
      <div className="">
        <CustomeHeader title={content.midline} optional={false} largeFont={false}/>

        <div className="buttom-paddingFORMalc">
          <div className="form-group content form-block-holder ">
            <div className="midline-section-grid">
              <div>
                {" "}
                <label className="control-label  padTOP">
                  <div className="  title_active-gco title_tertment_option title-3-text">
                    {content.upper_midline}
                  </div>{" "}
                </label>
                <div className="midline_grid ">
                  <div className="">

                    <div className="radio-item" 
                     style={{ pointerEvents: this.props.summary ? "none" : "" }}>
                      <input type="radio" name="UpperMidline"
                       
                        disabled={this.props.summary}
                        style={{ pointerEvents: this.props.summary ? "none" : "" }}
                        checked={this.upperMidline === "centered"}
                        id='centered-upper'
                        value={"centered"} onChange={() => {
                          this.upperMidline = "centered";
                          this.UpdateCrossBiteReducer();
                        }} />
                      <label htmlFor='centered-upper' className="button-text"> {content.centered} </label>
                    </div>

                  </div>
                  <div className="">
                    <div className="radio-item"
                      style={{ pointerEvents: this.props.summary ? "none" : "" }}>
                      <input type="radio"
                        style={{ pointerEvents: this.props.summary ? "none" : "" }}
                        disabled={this.props.summary}
                        name="UpperMidline"
                        id='shifted_left-upper'
                        checked={this.upperMidline === "shifted_left"}
                        value={"shifted_left"} onChange={() => {
                          this.upperMidline = "shifted_left";
                          this.UpdateCrossBiteReducer();
                        }} />
                      <label htmlFor='shifted_left-upper' className="button-text">{content.shifted_left}</label>
                    </div>
                  </div>
                  <div className="">
                    <div className="radio-item"
                      style={{ pointerEvents: this.props.summary ? "none" : "" }} >
                      <input type="radio"
                        name="UpperMidline"
                        checked={this.upperMidline === "shifted_right"}
                        id='shifted_right-upper'
                        style={{ pointerEvents: this.props.summary ? "none" : "" }}
                        disabled={this.props.summary}
                        value={"shifted_right"} onChange={() => {
                          this.upperMidline = "shifted_right";
                          this.UpdateCrossBiteReducer();
                        }} />
                      <label htmlFor='shifted_right-upper' className="button-text">{content.shifted_right}</label>
                    </div>
                  </div>
                </div>
                {(upperMidline === "shifted_right" ||
                  upperMidline === "shifted_left") && (
                    <div className="p-2 mt-2 align-items-center displacement-Shifted">
                      <div>
                        <label className="control-label title_tertment_option button-text">
                          {content.if_shifted_displacement_in_mm}
                        </label>
                      </div>
                      <div>
                        <Input
                          disabled={this.props.summary}
                          autoComplete="off"
                          type="number"
                          className={`green_input button-text-select`}
                          defaultValue={upperMidline_displacement}
                          onChange={(e) => {
                            this.upperMidline_displacement = e.target.value;
                            this.UpdateCrossBiteReducer();
                          }}
                        />
                      </div>
                    </div>
                  )}
              </div>

              <div className="p-2">
                <div className="control-label title_laowerAnd_upper2 title-3-text">
                  {content.treat_midline_by}{" "}
                </div>
                <CustomInput 
                  className={`pt-1 ${upper_resultMidlineAlignment && "replacment-checkbox"
                    } button-text-select`}
                  type="checkbox"
                  id="resultMidlineAlignment-upper"
                  label={content.accept_resulting_midline}
                  checked={upper_resultMidlineAlignment}
                  disabled={this.props.summary}

                  onChange={() => {
                    this.upper_resultMidlineAlignment = !this
                      .upper_resultMidlineAlignment;
                    this.UpdateCrossBiteReducer();
                  }}
                />
                <CustomInput 
                  className={`pt-1 ${upper_maintain && "replacment-checkbox"
                    } button-text-select`}
                  type="checkbox"
                  id="upper_maintainr"
                  label={content.maintain_intial_midline}
                  checked={upper_maintain} disabled={this.props.summary}

                  onChange={() => {
                    this.upper_maintain = !this.upper_maintain;
                    this.UpdateCrossBiteReducer();
                  }}
                />
                <CustomInput 
                  className={`pt-1 ${upper_improveIPR && "replacment-checkbox"
                    } button-text-select`}
                  type="checkbox"
                  id="improveIPR-upper"
                  label={content.improve_with_ipr}
                  checked={upper_improveIPR} disabled={this.props.summary}

                  onChange={() => {
                    this.upper_improveIPR = !this.upper_improveIPR;
                    this.UpdateCrossBiteReducer();
                  }}
                />
                <CustomInput 
                  className={`pt-1 ${upper_improveExtraction && "replacment-checkbox"
                    } button-text-select`}
                  type="checkbox"
                  id="improveExtraction-upper"
                  label={content.improve_with_extraction}
                  checked={upper_improveExtraction} disabled={this.props.summary}

                  onChange={() => {
                    this.upper_improveExtraction = !this
                      .upper_improveExtraction;
                    this.UpdateCrossBiteReducer();
                  }}
                />
              </div>

            </div>
            <div className="midline-section-grid">
              <div>
                {" "}
                <label className="control-label padTOP">
                  <div className=" text-center title_tertment_option title-3-text">
                    {content.lower_midline}
                  </div>{" "}
                </label>
                <div className="midline_grid ">
                  <div className="">
                    <div className="radio-item"
                      style={{ pointerEvents: this.props.summary ? "none" : "" }}>
                      <input type="radio" name="lowerMidline"
                        style={{ pointerEvents: this.props.summary ? "none" : "" }}
                        disabled={this.props.summary}
                        // checked={this.lowerMidline==="centered"}
                        id='centered-lower'
                        value={"centered"} onChange={() => {
                          this.lowerMidline = "centered";
                          this.UpdateCrossBiteReducer();
                        }} />
                      <label htmlFor='centered-lower' className="button-text"> {content.centered}  </label>
                    </div>
                  </div>
                  <div className="">
                    <div className="radio-item"
                      style={{ pointerEvents: this.props.summary ? "none" : "" }} >
                      <input
                        style={{ pointerEvents: this.props.summary ? "none" : "" }}
                        disabled={this.props.summary}
                        type="radio" name="lowerMidline"

                        id='shifted_left-lower'
                        checked={this.lowerMidline === "shifted_left"}
                        value={"shifted_left"} onChange={() => {
                          this.lowerMidline = "shifted_left";
                          this.UpdateCrossBiteReducer();
                        }} />
                      <label htmlFor='shifted_left-lower' className="button-text">{content.shifted_left} </label>
                    </div>
                  </div>


                  <div className="">
                    <div className="radio-item"
                      style={{ pointerEvents: this.props.summary ? "none" : "" }}>
                      <input
                        type="radio" name="lowerMidline"
                        style={{ pointerEvents: this.props.summary ? "none" : "" }}
                        disabled={this.props.summary}
                        checked={this.lowerMidline === "shifted_right"}
                        id='shifted_right-lower'
                        value={"shifted_right"}
                        onChange={() => {
                          this.lowerMidline = "shifted_right";
                          this.UpdateCrossBiteReducer();
                        }} />
                      <label htmlFor='shifted_right-lower' className="button-text">{content.shifted_right} </label>
                    </div>

                  </div>
                </div>
                {(lowerMidline === "shifted_left" ||
                  lowerMidline === "shifted_right") && (
                    <div className="p-2 mt-2 align-items-center displacement-Shifted">
                      <div>
                        <label className="control-label title_active-gco title_tertment_option button-text">
                          {content.if_shifted_displacement_in_mm}
                        </label>
                      </div>
                      <div>
                        <Input disabled={this.props.summary}

                          autoComplete="off"
                          type="number"
                          className={`green_input`}
                          defaultValue={lowerMidline_displacement}
                          onChange={(e) => {
                            this.lowerMidline_displacement = e.target.value;
                            this.UpdateCrossBiteReducer();
                          }}
                        />
                      </div>
                    </div>
                  )}
              </div>
              <div>
                <div className="p-2">
                  <div className="control-label  title_laowerAnd_upper2 title-3-text">
                    {content.treat_midline_by}{" "}
                  </div>
                  <CustomInput 
                    className={`pt-1 ${lower_resultMidlineAlignment && "replacment-checkbox "
                      } button-text-select`}
                    type="checkbox"
                    id="resultMidlineAlignment-lower"
                    label={content.accept_resulting_midline}
                    checked={lower_resultMidlineAlignment} disabled={this.props.summary}

                    onChange={() => {
                      this.lower_resultMidlineAlignment = !this
                        .lower_resultMidlineAlignment;
                      this.UpdateCrossBiteReducer();
                    }}
                  />
                  <CustomInput 
                    className={`pt-1 ${lower_maintain && "replacment-checkbox "
                      } button-text-select`}
                    type="checkbox"
                    id="lower_maintainr"
                    label={content.maintain_intial_midline}
                    checked={lower_maintain} disabled={this.props.summary}

                    onChange={() => {
                      this.lower_maintain = !this.lower_maintain;
                      this.UpdateCrossBiteReducer();
                    }}
                  />
                  <CustomInput 
                    className={`pt-1 ${lower_improveIPR && "replacment-checkbox "
                      } button-text-select`}
                    type="checkbox"
                    id="improveIPR-lower"
                    label={content.improve_with_ipr}
                    checked={lower_improveIPR} disabled={this.props.summary}

                    onChange={() => {
                      this.lower_improveIPR = !this.lower_improveIPR;
                      this.UpdateCrossBiteReducer();
                    }}
                  />

                  <CustomInput 
                    className={`pt-1 ${lower_improveExtraction && "replacment-checkbox "
                      } button-text-select`}
                    type="checkbox"
                    id="improveExtraction-lower"
                    label={content.improve_with_extraction}
                    checked={lower_improveExtraction} disabled={this.props.summary}

                    onChange={() => {
                      this.lower_improveExtraction = !this
                        .lower_improveExtraction;
                      this.UpdateCrossBiteReducer();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Midline);
