import { CURRENT_CASE } from "../constants/";
const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CURRENT_CASE:
      return {
        state: action.payload,
      };

    default:
      return state;
  }
};

export const selectCaseDetails = ({ caseReducer }) => caseReducer.case;

export const selectIprDetails = ({ caseReducer, userReducer }) => ({
  uuid: caseReducer.case.uid,
  patientName:
    caseReducer.case.patient_info.first_name +
    " " +
    caseReducer.case.patient_info.last_name,
  doctorName: userReducer.currentUser.full_name,
  dentalNotation: userReducer.currentUser.dental_notation,
});
