import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});

class ToothRateMovement extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {


      Normal_Rate: this.props.caseSubmission.advanced_modules
      .teethMovement.Normal_Rate, 
      Slow_Rate:this.props.caseSubmission.advanced_modules
      .teethMovement.Slow_Rate,
      High_Rate : this.props.caseSubmission.advanced_modules
      .teethMovement.High_Rate,

      use_teethMovement: this.props.caseSubmission.advanced_modules
      .teethMovement.use_teethMovement,
     

    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "teethMovement",
      data: {
     use_teethMovement: this.state.use_teethMovement,
     Normal_Rate : this.state.Normal_Rate ,
     Slow_Rate : this.state.Slow_Rate ,
     High_Rate :this.state.High_Rate


      },
    });
   
    this.props.save();
    

  };
  clearState = () => {
    this.setState({
     
         Normal_Rate: false,
         Slow_Rate: false,
         High_Rate: false,
    })
  } 

  render() {
    const {Normal_Rate , Slow_Rate , High_Rate} = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>   
   <div className="ToothRateMovement-container">



   <div className="customHeaderTS">
            <div>   <CustomeHeader title={content.toothRateMovement} className="cp3 pt-0" optional={false} note={false} note_text={content.toothRateMovement_note} /></div>
            <div className="reative-close-ts">  <span className={` pull-right ts-close-button-new`} onClick={() => { this.props.toggleModalsClose(this.props.active_module) }}>&times;</span></div>
          </div>
   <div className="control-label title_active-gco  text-left mb-2">
     <p className="title_tertment_option D-head-ts">{content.Set_tooth_rate_movement_for_this_case}</p>
     </div> 
     <div className="Dsables-text D-total-cont-checkBox">
     <div className="ToothRateMovement-grid-ts">
     <div className="ToothRateMovement-grid">
     <div className="radio-item  text_malocclusions" style={{ pointerEvents:  "none" }}>
     <input type="radio"
                  id='Normal_RateTs'
                  name='ToothRateMovement'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  checked={this.state.Normal_Rate}
                  value={Normal_Rate}
                  onChange={() => {
                 
                  }}
                />
                <label htmlFor='Normal_RateTs' >{content.Normal_Rate}</label>
              </div>


              <div className="radio-item  text_malocclusions" style={{ pointerEvents:  "none" }}>
                <input type="radio"
                  id='Slow_RateTs'
                  name='ToothRateMovement'
                  checked={this.state.Slow_Rate}
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  value={Slow_Rate}
                  onChange={() => {
                    this.setState({
                   
                    });
                  }}
                />
                <label htmlFor='Slow_RateTs' >{content.Slow_Rate}</label>
              </div>







              <div className="radio-item  text_malocclusions" style={{ pointerEvents:  "none" }}>
     <input type="radio"
                  id='High_RateTs'
                  name='ToothRateMovement'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  checked={this.state.High_Rate}
                  value={High_Rate}
                  onChange={() => {
                    this.setState({
                    
                    });
                  }}
                />
                <label htmlFor='High_RateTs' >{content.High_Rate}</label>
              </div>
              </div>
              </div>
              </div>
    
        {
          !this.props.summary && <Button
          className=" mt-2  light-green-btn float-right"
          size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()
              // ()=>this.clearState()
            }
          >
 {content.save}
        </Button>
        }
      </div>
      </div>
    );

  }
}
export default connect( mapStateToProps,
  mapDispatchToProps)(ToothRateMovement);