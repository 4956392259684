import React, { Component } from "react";

import { connect } from "react-redux";

import LoyaltyDescription from "./LoyaltyDescription/index"

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({
});
class LoyaltyMainPage extends Component {
    constructor(props) {
        super(props);
        /* init state */
        this.state = {
       
          
        }
    }


    render() {
        const loyalty  = this.props.location.state
 
        return (
            <div>
                <LoyaltyDescription loyalty={loyalty}/>


               
            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyMainPage);
