import React, { Component } from "react";
// import { useState } from 'react';
// import { toast } from "react-toastify";
import { connect } from "react-redux";

// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import StepsElastic from "../../components/StepsElastic/StepsElastic";


// import Inbox from "../inbox/index"
// import MsgContent from "../inbox/MsgContent"
// import MsgForm from "../inbox/MsgForm"

// import errorToaster from "../../services/toast"

//import TreatmentProtocols from "../../components/TreatmentProtocols/TreatmentProtocols"

// import AdvancedModules from "../case-submission/AdvancedModulesCopy/index";
// import SVG from "../../components/svg/SVG";
// import lower_l_1_not_active from "../../assets/images/teeth_v4/not_active/lower_l_1_not_active.svg";
// import lower_l_2_not_active from "../../assets/images/teeth_v4/not_active/upper_l_2_not_active.svg"
// import { ReactComponent as Logo } from '../../assets/images/teeth_v4/not_active/lower_l_2_not_active.svg';
// import { ReactComponent as Attachment } from "../../assets/images/tsImages/attachment.svg";
// import { ReactComponent as Superimpose } from "../../assets/images/tsImages/superimpose.svg";

// import { ReactComponent as Front } from "../../assets/images/tsImages/front.svg";
// import { ReactComponent as Top } from "../../assets/images/tsImages/top.svg";

// import { ReactComponent as Bottom } from "../../assets/images/tsImages/bottom.svg";
// import { ReactComponent as Lower } from "../../assets/images/tsImages/lower.svg";
// import { ReactComponent as Upper } from "../../assets/images/tsImages/upper.svg";
// import { ReactComponent as Right } from "../../assets/images/tsImages/right.svg";
// import { ReactComponent as Left } from "../../assets/images/tsImages/left.svg";

// import { ReactComponent as ZoomIn } from "../../assets/images/tsImages/zoom_in.svg";
// import { ReactComponent as ZoomOut } from "../../assets/images/tsImages/zoom_out.svg";
import LoyaltyDescription from "./LoyaltyDescription/index"

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({
});
class Test extends Component {
    constructor(props) {
        super(props);
        /* init state */
        this.state = {
            array: [{ from: 0, to: 0 }],
            // array:[],
        }
    }

    //     componentDidMount() {
    //         this.errorToaster("msg"); // msg
    //       this.successToaster("msg")
    //       this.infoToaster("msg");
    //     }
    // errorToaster(msg) {
    //         toast.error(
    //             <div className="TosterCard">
    //             <div className="TosterCard-image"><img src={Vector} alt="icone" width="30" height="30"/></div>
    //             <div className="TosterCard-title"><p className="Toster-title">Warning</p>
    //             <p className="Toster-desc">Warning message here!</p></div>
    //             </div>
    //             , {
    //           position: "bottom-left",
    //           autoClose: false,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         });

    //       }


    //        successToaster(msg) {
    //         toast.success(   <div className="TosterCard">
    //         <div className="TosterCard-image"><img src={Vector_true} alt="icone" width="30" height="30"/></div>
    //         <div className="TosterCard-title"><p className="Toster-title">Success</p>
    //         <p className="Toster-desc">Archived case successfuly</p></div>
    //         </div>
    //             , {
    //           position: "bottom-left",
    //           autoClose: false,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         });
    //       }




    //     infoToaster(msg) {
    //         toast.info(<div className="TosterCard">
    //         <div className="TosterCard-image"><img src={Vector_mark} alt="icone" width="30" height="30"/></div>
    //         <div className="TosterCard-title"><p className="Toster-title">Attention needed</p>
    //         <p className="Toster-desc">Message goes here!</p></div>
    //         </div>, {
    //           position: "bottom-left",
    //           autoClose: false,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         });
    //       }

    handelChanges = (value) => {
        this.setState({
            array: value,
        })

    }
    render() {
        const loyalty  = this.props.location.state
 
        return (
            <div>
       

               


                {/* <Logo className="testLogo" stroke="green" /> */}

                {/* <Steps StepTitle="add Title Step here" hyberText="add from here " arrayOf_steps={(this.state.array === []) ? this.state.array = [{ step: 0 }] : this.state.array} onclick={(value) => {
                    this.handelChanges(value)
                }}
                /> */}


                {/* <TreatmentProtocols/> */}
                {/* <StepsElastic   StepsElasticTitle="Use from stage" to="to" hyberText="Add another elastics"
   arrayOf_steps={this.state.array}  onclick={(value) => {this.handelChanges(value)}}/> */}


                {/* 
<Steps StepTitle="add Title Step here" hyberText="add from here " arrayOf_steps={(this.state.array === []) ? this.state.array = [{ step: 0 }] : this.state.array} onclick={(value) => {
                    this.handelChanges(value)
                }}
                /> */}
                {/* <Inbox/> */}
                {/* <Inbox caseId={this.state.case.uid} formKey={"viewer"} /> */}
                {/* <MsgForm/> */}

                {/* <AdvancedModules/> */}
                {/* <Steps  StepTitle="add AttachmentStep"  hyberText="add from here "value={this.state.array} onClick={(value) => {
                <SVG name="ll1" color="blue" attr="fill" img={lower_l_1_not_active} />
                <SVG name="ll2" color="green" attr="fill" img={lower_l_2_not_active} />
                <div className="ts_nav_grid">
                    <div></div>
                    <div className="position_grid">
                        <div className="zoom_in_out_grid">
                            <div>
                                <ZoomOut />
                            </div>
                            <div>100%</div>
                            <div>
                                <ZoomIn />
                            </div>
                        </div>
                        <div>Zoom</div>
                    </div>

                    <div className="position_grid">
                        <Front />
                        <div>Front</div>
                    </div>
                    <div className="position_grid">
                        <Top />
                        <div>Top</div>
                    </div>
                    <div className="position_grid">
                        <Bottom />
                        <div>Bottom</div>
                    </div>
                    <div className="position_grid">
                        <Lower />
                        <div>Lower</div>
                    </div>
                    <div className="position_grid">
                        <Upper />
                        <div>Upper</div>
                    </div>
                    <div className="position_grid">
                        <Right />
                        <div>Right</div>
                    </div>
                    <div className="position_grid">
                        <Left />
                        <div>Left</div>
                    </div>
                    <div className="position_grid">
                        <Attachment />
                        <div>Attachment</div>
                    </div>
                    <div className="position_grid">
                        <Superimpose />
                        <div>Superimpose</div>
                    </div>
                    <div></div>
                </div>




                {/* <AdvancedModules/> */}
                {/* <Steps  StepTitle="add AttachmentStep"  hyberText="add from here "value={this.state.array} onClick={(value) => {
              this.handelChanges(value)}}
                                           
                                             /> */}
                {/* <div className="main-page-con">


                <SVG name="ll1" color="blue" attr="fill" img={lower_l_1_not_active}/>


                {/* <AdvancedModules/> */}
                {/* <div className="main-page-con">
                    <Button className="btn white-btn"> save</Button>
                    <Button className="btn light-green-btn"> save</Button>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    <div className="s_card_grid_active" onClick={() => {

                    }}>
                        <div >
                            <object className="svgClass" type="image/svg+xml" data={malcclusion_active}></object>
                        </div>
                        <div>Malucclusion</div>
                    </div>
                    <br></br>
                    <div className="s_card_grid">
                        <div >
                            <object className="svgClass" type="image/svg+xml" data={malcclusion}></object>
                        </div>
                        <div>Malucclusion</div>
                    </div>
                    <br></br>
                    <div className="s_card_grid_clicked">
                        <div >
                            <object className="svgClass" type="image/svg+xml" data={malcclusion}></object>
                        </div>
                        <div>Malucclusion</div>
                    </div>
                    <br></br>
                    <CustomeHeader title="Terms & Agreemants" note={true} note_text="This feature is used to create space for tooth alignment or reshape the contact area between neighboring teeth." />

                    <br></br>
                    <CustomeHeader title="Advanced Treatment" optional={true} />
                    <br></br>

                    <div style={{ height: "250px", background: "#f6f9fc", padding: "50px", overflow: "auto" }} className="c-scroll" >

                        Lorem ipsum dolor, sit amet
                        consectetur adipisicing elit.
                        Aut optio dicta, excepturi eum non reprehenderit
                        libero possimus ex molestias ipsa qui officia repellendus vel
                        quasi magni ratione laborum provident maiores vitae animi eveniet quae dolorum.
                        Libero quae nisi sequi, assumenda qui cumque atque, obcaecati tempore nihil repudiandae quia.
                        Cum nisi, libero tenetur quae obcaecati molestias et veniam inventore vitae adipisci deleniti
                        maxime consectetur enim pariatur consequuntur dolores quaerat. Ex animi at reiciendis fugiat
                        mollitia nobis ad veniam reprehenderit, quae incidunt, earum quod quisquam voluptatem, dolores est.
                        Dolor repellat voluptate neque reiciendis, dolorem consequatur fuga laboriosam sit eos et quidem magnam
                        non doloremque magni accusamus sequi dolorum doloribus! Quibusdam ipsum impedit velit numquam temporibus
                        voluptate odio doloribus. Rem illum nisi minus sit amet exercitationem laboriosam explicabo sequi,
                        eum libero assumenda id. Vitae sapiente dolorum, aut laborum, saepe vel quae harum architecto iste
                        porro commodi eaque error praesentium! Pariatur, fuga id quasi laudantium est suscipit soluta iure
                        debitis ratione, hic magnam quam provident molestias cum incidunt cupiditate similique ea, laboriosam
                        ut aliquid minus dolores earum quisquam? Laudantium nemo explicabo magnam maxime suscipit
                        in at a totam eligendi animi libero amet quam quod fuga, numquam iure corporis, distinctio,
                        autem quidem officiis veniam exercitationem.


                        Aut optio dicta, excepturi eum non reprehenderit
                        libero possimus ex molestias ipsa qui officia repellendus vel
                        quasi magni ratione laborum provident maiores vitae animi eveniet quae dolorum.
                        Libero quae nisi sequi, assumenda qui cumque atque, obcaecati tempore nihil repudiandae quia.
                        Cum nisi, libero tenetur quae obcaecati molestias et veniam inventore vitae adipisci deleniti
                        maxime consectetur enim pariatur consequuntur dolores quaerat. Ex animi at reiciendis fugiat
                        mollitia nobis ad veniam reprehenderit, quae incidunt, earum quod quisquam voluptatem, dolores est.
                        Dolor repellat voluptate neque reiciendis, dolorem consequatur fuga laboriosam sit eos et quidem magnam
                        non doloremque magni accusamus sequi dolorum doloribus! Quibusdam ipsum impedit velit numquam temporibus
                        voluptate odio doloribus. Rem illum nisi minus sit amet exercitationem laboriosam explicabo sequi,
                        eum libero assumenda id. Vitae sapiente dolorum, aut laborum, saepe vel quae harum architecto iste
                        porro commodi eaque error praesentium! Pariatur, fuga id quasi laudantium est suscipit soluta iure
                        debitis ratione, hic magnam quam provident molestias cum incidunt cupiditate similique ea, laboriosam
                        ut aliquid minus dolores earum quisquam? Laudantium nemo explicabo magnam maxime suscipit
                        in at a totam eligendi animi libero amet quam quod fuga, numquam iure corporis, distinctio,
                        autem quidem officiis veniam exercitationem.
</div>

                </div> 
                </div> */}
            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Test);
