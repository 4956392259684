import { modules } from "../redux/advanced/modules";
import { upper_teeth, lower_teeth } from "../redux/customeTeeth/init_teeth";
import {
  upper_ipr_teeth,
  lower_ipr_teeth,
} from "../redux/customeTeeth/init_ipr_teeth";

import { mapIPRTeethFromApi } from "./mapIPRTeethFromApi";
import { mapTeeth } from "./mapTeeth";
import { iprTeethTS } from "./iprTeethTS";
import { mapTeethAttachments } from "./mapTeethAttachments";
import { mapTeethElastics } from "./mapTeethElastics";
export function newMapDataWithReducerTS(dataFromApi) {
  let data = {
    advanced_modules: advanced_data(dataFromApi.advanced_modules),
  };


  return data;
}

function mapModules(data) {
  for (let x = 0; x < modules.length; x++) {
    var module =
      data?.filter((value) => {
        return value.key === modules[x].key;
      }) || [];
    modules[x].active = module[0]?.active || false;
  }

  return modules;
}

function advanced_data(data) {

  
  return {
    all_modules: data
      ? data.all_modules
        ? mapModules(data.all_modules)
        : modules
      : modules,
    // treatment_type: data?.treatment_type
    //   ? data.treatment_type
    //   : {
    //     type: "",
    //     hybrid_treatment: {},
    //   },
    // malocclusion: {
    //   crossbite_btn: data?.malocclusion?.crossbite_btn || false,
    //   spacing_btn: data?.malocclusion?.spacing_btn || false,
    //   classII_btn: data?.malocclusion?.classII_btn || false,
    //   classIII_btn: data?.malocclusion?.classIII_btn || false,
    //   openbite_btn: data?.malocclusion?.openbite_btn || false,
    //   deepbite_btn: data?.malocclusion?.deepbite_btn || false,
    //   midline_btn: data?.malocclusion?.midline_btn || false,
    //   crossbite: data?.malocclusion?.crossbite
    //     ? {
    //       useElasticWhenCorrecting:
    //         data.malocclusion.crossbite.useElasticWhenCorrecting,
    //       teeth: mapTeeth(data.malocclusion.crossbite.teeth),
    //       treatment_Options: {
    //         expansion:
    //           data.malocclusion.crossbite.treatment_Options.expansion,
    //         constriction:
    //           data.malocclusion.crossbite.treatment_Options.constriction,
    //         combination:
    //           data.malocclusion.crossbite.treatment_Options.combination,
    //         elastics: data.malocclusion.crossbite.treatment_Options.elastics,
    //       },
    //     }
    //     : {
    //       useElasticWhenCorrecting: false,
    //       teeth: mapTeeth({
    //         upper_teeth: Object.assign({}, upper_teeth),
    //         lower_teeth: Object.assign({}, lower_teeth),
    //       }),
    //       treatment_Options: {
    //         expansion: false,
    //         constriction: false,
    //         combination: false,
    //         elastics: false,
    //       },
    //     },
    //   spacing: data?.malocclusion?.spacing
    //     ? {
    //       closeAllSpacesToggle:
    //         data.malocclusion.spacing.closeAllSpacesToggle,
    //       closeAllSpaces: {
    //         byRetractionOfAnteriors:
    //           data.malocclusion.spacing.closeAllSpaces
    //             .byRetractionOfAnteriors,
    //         byMesialisation:
    //           data.malocclusion.spacing.closeAllSpaces.byMesialisation,
    //         both: data.malocclusion.spacing.closeAllSpaces.both,
    //       },
    //       partiallyClose: data.malocclusion.spacing.partiallyClose,
    //       IPRopposing: data.malocclusion.spacing.IPRopposing,
    //     }
    //     : {
    //       closeAllSpacesToggle: false,
    //       closeAllSpaces: {
    //         byRetractionOfAnteriors: false,
    //         byMesialisation: false,
    //         both: false,
    //       },
    //       partiallyClose: false,
    //       IPRopposing: false,
    //     },
    //   classII: data?.malocclusion?.classII
    //     ? {
    //       extractionTwoUpperBicuspids:
    //         data.malocclusion.classII.extractionTwoUpperBicuspids,
    //       extractionUpperLowerBicuspids:
    //         data.malocclusion.classII.extractionUpperLowerBicuspids,
    //       upperArchDistalization:
    //         data.malocclusion.classII.upperArchDistalization,
    //       lowerArchMesialization:
    //         data.malocclusion.classII.lowerArchMesialization,
    //       leavingMolars: data.malocclusion.classII.leavingMolars,
    //       maintain: data.malocclusion.classII.maintain,
    //     }
    //     : {
    //       extractionTwoUpperBicuspids: false,
    //       extractionUpperLowerBicuspids: false,
    //       upperArchDistalization: false,
    //       lowerArchMesialization: false,
    //       leavingMolars: false,
    //       maintain: false,
    //     },
    //   classIII: data?.malocclusion?.classIII
    //     ? {
    //       advanceUppers: data.malocclusion.classIII.advanceUppers,
    //       retractLowersExtraction:
    //         data.malocclusion.classIII.retractLowersExtraction,
    //       retractLowersIPR: data.malocclusion.classIII.retractLowersIPR,
    //       estheticAlignmentOnly:
    //         data.malocclusion.classIII.estheticAlignmentOnly,
    //       preSurgicalAlignment:
    //         data.malocclusion.classIII.preSurgicalAlignment,
    //     }
    //     : {
    //       advanceUppers: false,
    //       retractLowersExtraction: false,
    //       retractLowersIPR: false,
    //       estheticAlignmentOnly: false,
    //       preSurgicalAlignment: false,
    //     },
    //   openbite: data?.malocclusion?.openbite
    //     ? {
    //       extrudeAnterior: data.malocclusion.openbite.extrudeAnterior,
    //       intrudePosterior: data.malocclusion.openbite.intrudePosterior,
    //       both: data.malocclusion.openbite.both,
    //     }
    //     : {
    //       extrudeAnterior: false,
    //       intrudePosterior: false,
    //       both: false,
    //     },
    //   deepbite: data?.malocclusion?.deepbite
    //     ? {
    //       intrudeUpperAnteriors:
    //         data.malocclusion.deepbite.intrudeUpperAnteriors,
    //       intrudeLowerAnteriors:
    //         data.malocclusion.deepbite.intrudeLowerAnteriors,
    //       combination: data.malocclusion.deepbite.combination,
    //       extrudePremolars: data.malocclusion.deepbite.extrudePremolars,
    //     }
    //     : {
    //       intrudeUpperAnteriors: false,
    //       intrudeLowerAnteriors: false,
    //       combination: false,
    //       extrudePremolars: false,
    //     },
    //   midline: data?.malocclusion?.midline
    //     ? {
    //       upperMidline: {
    //         upperMidline: data.malocclusion.midline.upperMidline.upperMidline,
    //         upperMidline_displacement:
    //           data.malocclusion.midline.upperMidline
    //             .upperMidline_displacement,
    //       },
    //       lowerMidline: {
    //         lowerMidline: data.malocclusion.midline.lowerMidline.lowerMidline,
    //         lowerMidline_displacement:
    //           data.malocclusion.midline.lowerMidline
    //             .lowerMidline_displacement,
    //       },
    //       treatMidlineUpper: {
    //         resultMidlineAlignment:
    //           data.malocclusion?.midline?.treatMidlineUpper
    //             ?.resultMidlineAlignment,
    //         maintain: data.malocclusion?.midline?.treatMidlineUpper?.maintain,
    //         improveIPR:
    //           data.malocclusion?.midline?.treatMidlineUpper?.improveIPR,
    //         improveExtraction:
    //           data.malocclusion?.midline?.treatMidlineUpper?.improveExtraction,
    //       },
    //       treatMidlineLower: {
    //         resultMidlineAlignment:
    //           data.malocclusion?.midline?.treatMidlineLower
    //             ?.resultMidlineAlignment,
    //         maintain: data.malocclusion?.midline?.treatMidlineLower?.maintain,
    //         improveIPR:
    //           data.malocclusion?.midline?.treatMidlineLower?.improveIPR,
    //         improveExtraction:
    //           data.malocclusion?.midline?.treatMidlineLower?.improveExtraction,
    //       },
    //     }
    //     : {
    //       upperMidline: {
    //         upperMidline: "",
    //         upperMidline_displacement: "",
    //       },
    //       lowerMidline: {
    //         lowerMidline: "",
    //         lowerMidline_displacement: "",
    //       },
    //       treatMidlineUpper: {
    //         resultMidlineAlignment: false,
    //         maintain: false,
    //         improveIPR: false,
    //         improveExtraction: false,
    //       },
    //       treatMidlineLower: {
    //         resultMidlineAlignment: false,
    //         maintain: false,
    //         improveIPR: false,
    //         improveExtraction: false,
    //       },
    //     },
    // },
    extraction: data?.extraction
      ? {
          use_extraction: data.extraction.use_extraction,
          teeth: mapTeeth(data.extraction.teeth),
        }
      : {
          use_extraction: false,
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        },
    ipr: data?.ipr
      ? {
          useIpr: data.ipr.useIpr,
          iprTiming: data.ipr.iprTiming,
          iprRestriction: data.ipr.iprRestriction.hasOwnProperty("upper_teeth")
            ? mapIPRTeethFromApi(data.ipr.iprRestriction)
            : iprTeethTS(),
          iprNotes: data.ipr.iprNotes || "",
          
          
        }
      : {
          useIpr: false,
          iprTiming: "",
          iprRestriction: mapTeeth({
            upper_teeth: Object.assign({}, upper_ipr_teeth),
            lower_teeth: Object.assign({}, lower_ipr_teeth),
          }),
          iprNotes: "",
        },
    attachments: data?.attachments
      ? {
          useAttachments: data.attachments.useAttachments,
          attachmentsTiming: data.attachments.attachmentsTiming,
          attachmentsRestriction: mapTeethAttachments(
            data.attachments.attachmentsRestriction
          ),
        }
      : {
          useAttachments: false,
          attachmentsTiming: "",
          attachmentsRestriction: mapTeethAttachments({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        },
    pontics: data?.pontics
      ? {
          usePontics: data.pontics.usePontics,
          teeth: mapTeeth(data.pontics.teeth),
        }
      : {
          usePontics: false,
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
        },
    // overCorrection: data?.overCorrection
    //   ? {
    //     useOverCorrection: data.overCorrection.useOverCorrection,
    //     teeth: mapTeeth(data.overCorrection.teeth),
    //     details: data.overCorrection.details,
    //   }
    //   : {
    //     useOverCorrection: false,
    //     teeth: mapTeeth({
    //       upper_teeth: Object.assign({}, upper_teeth),
    //       lower_teeth: Object.assign({}, lower_teeth),
    //     }),

    //     details: "",
    //   },
    passiveAligners: data?.passiveAligners
      ? {
          usePassiveAligners: data.passiveAligners.usePassiveAligners,
          details: data.passiveAligners.details,
          use_arch_upper: data.passiveAligners.use_arch_upper,
          use_arch_lower: data.passiveAligners.use_arch_lower,
          num_upper_passive_aligner:
            data.passiveAligners.num_upper_passive_aligner,
          num_lower_passive_aligner:
            data.passiveAligners.num_lower_passive_aligner,
          synchronize: data.passiveAligners.synchronize,
        }
      : {
          usePassiveAligners: false,
          details: "",
          use_arch_upper: false,
          use_arch_lower: false,
          num_upper_passive_aligner: "",
          num_lower_passive_aligner: "",
          synchronize: "",
        },
    toothSizeDiscrepancy: data?.toothSizeDiscrepancy
      ? {
          use_address_discrepancy:
            data.toothSizeDiscrepancy.use_address_discrepancy,
          leaveSpaceAroundLaterals:
            data.toothSizeDiscrepancy.leaveSpaceAroundLaterals,
          leaveSpaceDistalLaterals:
            data.toothSizeDiscrepancy.leaveSpaceDistalLaterals,
          iprOpposing: data.toothSizeDiscrepancy.iprOpposing,
          other: data.toothSizeDiscrepancy.other,
          other_details: data.toothSizeDiscrepancy.other_details,
        }
      : {
          use_address_discrepancy: false,
          leaveSpaceAroundLaterals: false,
          leaveSpaceDistalLaterals: false,
          iprOpposing: false,
          other: false,
          other_details: "",
        },
    torqueEnhancers: data?.torqueEnhancers
      ? {
          useTorqueEnhancers: data.torqueEnhancers.useTorqueEnhancers,
          teeth: mapTeeth(data.torqueEnhancers.teeth),
          details: data.torqueEnhancers.details,
        }
      : {
          useTorqueEnhancers: false,
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),

          details: "",
        },
    biteRamps: data?.biteRamps
      ? {
          useBiteRamps: data.biteRamps.useBiteRamps,
          incisors: data.biteRamps.incisors,
          incisors_and_canines: data.biteRamps.incisors_and_canines,
          details: data.biteRamps.details,
        }
      : {
          useBiteRamps: false,
          incisors: false,
          incisors_and_canines: false,
          details: "",
        },

    teethMovement: data?.teethMovement
      ? {
          use_teethMovement: data.teethMovement.use_teethMovement,
          Normal_Rate: data.teethMovement.Normal_Rate,
          Slow_Rate: data.teethMovement.Slow_Rate,
          High_Rate: data.teethMovement.High_Rate,
        }
      : {
          useteethMovement: false,
          Normal_Rate: false,
          Slow_Rate: false,
          High_Rate: false,
        },

    // hybridTreatment: data?.hybridTreatment
    // ? {
    //   use_hybridTreatment: data.hybridTreatment.use_hybridTreatment,
    //   yes: data.hybridTreatment.yes,
    //   no: data.hybridTreatment.no,

    // }
    // : {
    //   usehybridTreatment: false,
    //   yes: false,
    //   no: false,

    // },

    // archExpansion: data?.archExpansion
    //   ? {
    //     use_arch_expansion: data.archExpansion.use_arch_expansion,
    //     increaseCaninesPremolarsMolars:
    //       data.archExpansion.increaseCaninesPremolarsMolars,
    //     increaseCaninesPremolars: data.archExpansion.increaseCaninesPremolars,
    //     increasePremolarsMolars: data.archExpansion.increasePremolarsMolars,
    //     teeth: mapTeeth(data.archExpansion.teeth),
    //   }
    //   : {
    //     use_arch_expansion: false,
    //     increaseCaninesPremolarsMolars: false,
    //     increaseCaninesPremolars: false,
    //     increasePremolarsMolars: false,
    //     teeth: mapTeeth({
    //       upper_teeth: Object.assign({}, upper_teeth),
    //       lower_teeth: Object.assign({}, lower_teeth),
    //     }),
    //   },
    elastics: data?.elastics
      ? {
          useElastics: data.elastics.useElastics,

          teeth: mapTeeth(data.elastics.teeth),
          buttons_teeth: mapTeethElastics(data.elastics.buttons_teeth),
          cuts_teeth: mapTeethElastics(data.elastics.cuts_teeth),
          details: data.elastics.details,
          type: data.elastics.type,
          use_buttons: data.elastics.use_buttons,
          use_cuts: data.elastics.use_cuts,
        }
      : {
          useElastics: false,
          teeth: mapTeeth({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
          buttons_teeth: mapTeethElastics({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
          cuts_teeth: mapTeethElastics({
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }),
          use_buttons: false,
          use_cuts: false,
          details: "",
          type: "",
        },
  };
}
