import React, { Component } from "react";
import { connect } from "react-redux";
import CustomeHeader from "../../../components/header/CustomeHeader";
import { SetHybridState } from "../../../redux/hybridTreatment/action"
import * as yup from "yup";
import { Formik, Form } from "formik";
import {
    Button,
    Input
} from "reactstrap";
import CustomRadioGroup from "../../../components/radioGroup/CustomRadioGroup";
import check from "../../../assets/images/icon/check.svg";
import braces_before_aligners_img from "../../../assets/images/hybrid/braces_before_aligner.svg";
import braces_before_aligners_img_2 from "../../../assets/images/hybrid/braces_before_aligner_2.svg"
import Summary from "./Summary";
import { ConvertToHybrid } from "../../../api/api";
import { successToaster } from "../../../services/toast";

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({

    SetHybridState: (payload) => dispatch(SetHybridState(payload))
});
class HybridTreatment extends Component {
    /*--------------------------------------------------------------------------------*/
    /*constructor */
    /*--------------------------------------------------------------------------------*/
    constructor(props) {
        super(props);
        /*init state */
        this.state = {
            hybrid_treatment_type: "",
            show_type_values: false,
            arch: "",
            keep_protocols: "",
            new_protocols: "",
            show_summary: false,
            sending_data: false,
            show_submitted_successfully: false

        };
    }
    /*--------------------------------------------------------------------------------*/
    /*choose Hybrid Treatment Type */
    /*--------------------------------------------------------------------------------*/
    chooseHybridTreatmentType = (values, { setSubmitting }) => {
        this.setState({ hybrid_treatment_type: values.hybrid_treatment_type, show_type_values: true })
    }
    /*--------------------------------------------------------------------------------*/
    /*on Submit Both Types */
    /*--------------------------------------------------------------------------------*/
    onSubmitBothTypes = (values, { setSubmitting }) => {
        this.setState({
            arch: values.arch,
            keep_protocols: values.keep_protocols,
            new_protocols: values.new_protocols,
            show_summary: true
        })
    }
    /*--------------------------------------------------------------------------------*/
    /*back To Options */
    /*--------------------------------------------------------------------------------*/
    backToOptions = () => {
        this.setState({
            show_summary: false
        })
    }

    ResetTabs = () => {
        this.props.ResetTabs()
    }


    HandleConvertToHybridTreatment = () => {
        this.setState({ sending_data: true });
        const { content } = this.props.languageReducer;

        const { id } = this.props.userReducer.currentUser
        const { uid } = this.props.caseReducer.case;
        const ts_id = this.props.details.id;

        const { hybrid_treatment_type, new_protocols, arch } = this.state
        let data = {
            "convert_type": hybrid_treatment_type === "Braces before aligners" ? "braces_before" : "same_time",
            "protocol": hybrid_treatment_type === "Braces before aligners" ? "current" : new_protocols ? "new" : "current",
            "convert_extra_data": {
                ...(hybrid_treatment_type === "Braces and aligners together" && {
                    arch: arch
                }),
                ...(new_protocols && {
                    convert_protocol: new_protocols ? new_protocols : ""
                })
            }
        }

        ConvertToHybrid(id, uid, ts_id, JSON.stringify(data))
            .then((res) => {
                this.props.GetAllData()
                successToaster(content.converted_to_hybrid_successfully, content.Success);
                this.setState({ sending_data: false });
                if (hybrid_treatment_type === "Braces and aligners together") {
                    this.show_submitted_successfully = true
                } else {
                    this.props.toggleViewerModal()
                }
            })
            .catch((error) => {
                this.setState({ sending_data: false })

            });
    }
    render() {
        const { content } = this.props.languageReducer;
        const { show_type_values, hybrid_treatment_type, show_summary, sending_data, show_submitted_successfully } = this.state;

        // hybrid treatment types array for radio buttons 
        const hybrid_treatment_types =
            [{ name: "Braces before aligners", title: content.braces_before_aligners },
            { name: "Braces and aligners together", title: content.braces_and_aligners_together }];

        // archs array for radio buttons 
        const archs =
            [{ name: "Upper Arch", title: content.upper_arch },
            { name: "Lower Arch", title: content.lower_arch }];

        // keep protocols array for radio buttons 
        const keep_protocols_array =
            [{ name: "Yes", title: content.yes },
            { name: "No", title: content.no }];

        // hybrid treatment type schema for validation  
        const schema = yup.object({
            hybrid_treatment_type: yup
                .string()
                .required(content.please_choose_one),
        })

        // both hybrid treatment type schema for validation  
        const schema_both = yup.object({
            arch: yup
                .string()
                .required(content.please_choose_one),
            keep_protocols: yup
                .string()
                .required(content.please_choose_one),
            new_protocols: yup
                .string(),
        })



        return (
            <div className="cpt4 tp_details">
                {/*--------------------------------------------------------------------------------*/}
                {/* Hybrid Treatment */}
                {/*--------------------------------------------------------------------------------*/}

                {!show_summary && <div>
                    <div className="close-hybrid-treatment">
                        <CustomeHeader title={content.Convert_to_Hybrid_Treatment} small={true} />
                        <span className={` pull-right ts-close-button-new `} onClick={() => {
                            this.ResetTabs()
                            this.props.SetHybridState(false)
                        }}>&times;</span>
                    </div>

                    {/*--------------------------------------------------------------------------------*/}
                    {/*Choose Hybrid Treatment Type */}
                    {/*--------------------------------------------------------------------------------*/}
                    {
                        !show_type_values &&
                        <Formik
                            initialValues={{
                                hybrid_treatment_type: this.state.hybrid_treatment_type
                            }}
                            validationSchema={schema}
                            onSubmit={this.chooseHybridTreatmentType}
                        >
                            {({
                                touched,
                                errors,
                                isSubmitting,
                                isValid,
                                values,
                                handleSubmit,
                                handleBlur,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit} className="">


                                    <div className="cmt4 hybrid-treatment-container">
                                        <CustomRadioGroup
                                            id="hybrid_treatment_type"
                                            name="hybrid_treatment_type"
                                            checked={values.hybrid_treatment_type}
                                            onChange={(data) => {
                                                setFieldValue("hybrid_treatment_type", data)
                                            }}
                                            options={hybrid_treatment_types}
                                        />

                                        {errors.hybrid_treatment_type && touched.hybrid_treatment_type ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    color: "#FC4A69",
                                                }}
                                                className="cml3"
                                            >
                                                {errors.hybrid_treatment_type}
                                            </div>
                                        ) : null}

                                    </div>
                                    <div className="">
                                        <hr></hr>
                                        <Button
                                            className={`btn  green-green-btn pull-right  button-seme-bold-text`}
                                            size="sm"
                                            type="submit"
                                            disabled={!values.hybrid_treatment_type}
                                        >
                                            {content.next} <i className="fas fa-angle-right pl-1"></i>
                                        </Button>{" "}
                                    </div>{" "}

                                </Form>
                            )}</Formik>

                    }
                    {/*--------------------------------------------------------------------------------*/}
                    {/* end Choose Hybrid Treatment Type */}
                    {/*--------------------------------------------------------------------------------*/}


                    {/*--------------------------------------------------------------------------------*/}
                    {/*Braces before Aligners */}
                    {/*--------------------------------------------------------------------------------*/}
                    {show_type_values && hybrid_treatment_type === "Braces before aligners" &&
                        <div className="cmt4 ">
                            <div className="hybrid-treatment-container">
                                <div className="braces_before_aligner_note">
                                    <img src={check} alt="case-submitted" />
                                    <div className="hyber_text_note">
                                        {content.braces_before_aligners_note}
                                    </div>
                                    <div></div>
                                </div>

                                <div className="cmt4">

                                    <img className="braces_before_aligner_img" src={braces_before_aligners_img} alt="braces before aligners" />
                                    <img className="braces_before_aligner_img2" src={braces_before_aligners_img_2} alt="braces before aligners_2" />

                                </div></div>

                            <div className="">
                                <hr></hr>
                                <Button
                                    className={`btn  white-btn-ts button-seme-bold-text`}
                                    size="sm"
                                    type="button"
                                    disabled={sending_data}
                                    onClick={() => {
                                        this.setState({ show_type_values: false })
                                    }}
                                ><i className="fas fa-angle-left  pr-1"></i>
                                    {content.back}</Button>
                                <Button
                                    className={`btn  green-green-btn pull-right button-seme-bold-text`}
                                    size="sm"
                                    type="button"
                                    disabled={sending_data}
                                    onClick={() => {
                                        this.HandleConvertToHybridTreatment()
                                    }}
                                >
                                    {content.exit_review} <i className="fas fa-angle-right pl-1"></i>
                                </Button>{" "}
                            </div>{" "}
                        </div>
                    }

                    {/*--------------------------------------------------------------------------------*/}
                    {/*end Braces before Aligners */}
                    {/*--------------------------------------------------------------------------------*/}


                    {/*--------------------------------------------------------------------------------*/}
                    {/*Braces and Aligners together */}
                    {/*--------------------------------------------------------------------------------*/}
                    {
                        show_type_values && hybrid_treatment_type === "Braces and aligners together" &&
                        <Formik
                            initialValues={{
                                arch: this.state.arch,
                                keep_protocols: this.state.keep_protocols,
                                new_protocols: this.state.new_protocols
                            }}
                            validationSchema={schema_both}
                            onSubmit={this.onSubmitBothTypes}
                        >
                            {({
                                touched,
                                errors,
                                isSubmitting,
                                isValid,
                                values,
                                handleSubmit,
                                handleBlur,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit} className="">
                                    <div className="cmt4 hybrid-treatment-container">

                                        <div >
                                            <div className="hybrid_both_types_title"> {content.which_arch_do_you_want_to_treat_with_aligners}</div>
                                            <CustomRadioGroup
                                                id="arch"
                                                name="arch"
                                                checked={values.arch}
                                                onChange={(data) => {
                                                    setFieldValue("arch", data)
                                                }}
                                                options={archs}
                                            />

                                            {errors.arch && touched.arch ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        color: "#FC4A69",
                                                    }}
                                                    className="cml3"
                                                >
                                                    {errors.arch}
                                                </div>
                                            ) : null}

                                        </div>

                                        {
                                            values.arch && <div className="cmt2">
                                                <div className="hybrid_both_types_title"> {content.do_you_want_to_keep_the_submitted_treatment_protocols}</div>

                                                <CustomRadioGroup
                                                    id="keep_protocols"
                                                    name="keep_protocols"
                                                    checked={values.keep_protocols}
                                                    onChange={(data) => {
                                                        setFieldValue("keep_protocols", data)
                                                        if (data === "Yes") {
                                                            setFieldValue("new_protocols", "")
                                                        }
                                                    }}
                                                    options={keep_protocols_array}
                                                />

                                                {errors.keep_protocols && touched.keep_protocols ? (
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            color: "#FC4A69",
                                                        }}
                                                        className="cml3"
                                                    >
                                                        {errors.keep_protocols}
                                                    </div>
                                                ) : null}

                                            </div>
                                        }

                                        {
                                            values.keep_protocols === "No" && <div className="cmt2">
                                                <div className="hybrid_both_types_title">{content.provide_new_treatment_protocols}</div>
                                                <Input
                                                    className="custome-textarea"
                                                    name="new_protocols"
                                                    id="new_protocols"
                                                    type="textarea"
                                                    placeholder={content.typeHere}
                                                    rows={5}
                                                    defaultValue={values.new_protocols}
                                                    onChange={(e) => {
                                                        setFieldValue("new_protocols", e.target.value)
                                                    }}
                                                />
                                            </div>

                                        }
                                    </div>

                                    <div className="">

                                        <hr></hr>
                                        <Button
                                            className={`btn  white-btn-ts button-seme-bold-text`}
                                            size="sm"
                                            type="button"

                                            onClick={() => {
                                                this.setState({ show_type_values: false })
                                            }}
                                        ><i className="fas fa-angle-left  pr-1"></i>
                                            {content.back}</Button>
                                        <Button
                                            className={`btn  green-green-btn pull-right button-seme-bold-text `}
                                            size="sm"
                                            type="submit"
                                            disabled={!values.keep_protocols ||
                                                !values.arch}
                                        >
                                            {content.next} <i className="fas fa-angle-right pl-1"></i>
                                        </Button>{" "}
                                    </div>{" "}

                                </Form>
                            )}</Formik>

                    }
                    {/*--------------------------------------------------------------------------------*/}
                    {/*end Braces and Aligners together */}
                    {/*--------------------------------------------------------------------------------*/}


                </div>

                }

                {show_summary && <Summary data={this.state} back={this.backToOptions} ResetTabs={this.ResetTabs} convert_to_hybrid={this.HandleConvertToHybridTreatment} toggleViewerModal={this.props.toggleViewerModal} show_submitted_successfully={this.show_submitted_successfully} />}
                {/*--------------------------------------------------------------------------------*/}
                {/* End Hybrid Treatment*/}
                {/*--------------------------------------------------------------------------------*/}
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HybridTreatment);
