import React, { Component } from "react";
import {
  setRejectionReson,
  setChangeTracker,
} from "../../../redux/Tooth_IPR_Options/action";
import { connect } from "react-redux";
//import NewTS from "./NewTS";
import OldTS from "./OldTS";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setRejectionReson: (payload) => dispatch(setRejectionReson(payload)),
  setChangeTracker: (payload) => dispatch(setChangeTracker(payload)),
});

class TSViewer extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      collapse: false,
      details: props.details,
      width: window.innerWidth,
    };
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  // UNSAFE_componentWillMount() {
  //   window.dispatchEvent(new Event("print_report"));
  //   window.addEventListener("print_report", () => {
  //     return {
  //       name: "test",
  //     };
  //   });

  //   // Create the event.
  //   // const event = document.createEvent("Event");
  //   // // Define that the event name is 'build'.
  //   // event.initEvent("build", true, true);
  //   // window.dispatchEvent(event);
  // }

  updateSize = () => {
    let vh = window.innerHeight - 60;
    let vh2 = window.innerHeight - 140;
    const elements = document.getElementsByClassName("ib-button-messaging");
    if (window.innerWidth < 840) {
      for (const element of elements) {
        element.style.setProperty("z-index", 99);
      }
    }

    // else {
    //   document
    //     .getElementById("container")
    //     .style.setProperty("height", `${vh2}px`);
    // }
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateSize());
    // let iframe = document.getElementById("test");
    // window.onmessage = (e) => {
    //   if (e.data) {
    //     iframe.contentWindow.postMessage(this.props.details, "*");
    // iframe.contentWindow.postMessage("eon", "*");
    // }
    // };
    // setTimeout(() => {
    //   iframe.contentWindow.postMessage("iframe", "*");

    // }, 3000);
    // window.top.postMessage("I am Iframe", "*");
    // window.onmessage = (event) => {
    //   if (event.data === "GOT_YOU_IFRAME") {
    //     console.log("Parent received successfully.");
    //   }
    // };
  };
  componentDidUpdate = () => {
    window.addEventListener("resize", this.updateSize());
    // console.log(
    //   document.getElementById("container").clientHeight - 60,
    //   "countainer.....222",
    //   window.innerHeight - 60
    // );
  };
  saveRejectionReason = (data) => {
    this.props.setRejectionReson(data);
    if (data) {
      this.props.setChangeTracker(true);
    }
    this.props.saveRejectionReason(data);
  };
  finished = (data) => {
    if (data) {
      this.props.finishLoadingTs();
    }
  };

  render() {
    const {
      details,
      doctorId,
      caseId,
      case_data,
      showBiteRecord,
      viewer,
      divided,
      viewerHeight,
      viewerId,
    } = this.props;
    const { position } = this.props.newCase;
    const { photos } = this.props.caseReducer.case;
    const { dental_notation, email } = this.props.userReducer.currentUser;
    const { new_converter, id } = this.props.details;
    //const doctorIncluded = process.env.REACT_APP_BETA_DOCTORS.split(",");
    const data = {
      isM: false,
      isAdmin: false,
      caseId: caseId,
      tsId: details.id,
      doctorId: doctorId,
      showBiteRecord: showBiteRecord,
      position: position,
      tsHeight: viewerHeight,
      dental_notation: dental_notation,
      divided: divided,
      isDoctor: true,
      viewerId: viewerId,
      new_converter: new_converter,
      id: id,
    };
    return (
      <div id="container" >
        <OldTS data={data} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TSViewer);
