import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Image, Link } from "@react-pdf/renderer";
import restrect_pdf from "../../assets/images/icon/restrect_pdf.png";
import missing_pdf from "../../assets/images/icon/missing_pdf.png";

const styles = StyleSheet.create({
    row: {
        paddingTop: "5px",
        // flexDirection: "row",
        //  alignItems:"center", 
        // width:"100%"
        marginLeft:"50px"
    },
    inner_row: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: "5px"
    },
    orangeCircle: {
        flexDirection: "column",
        height: "15px",
        width: "15px",
        borderRadius: 50,
        backgroundColor: "#ff5c00",
        flexWrap: "wrap"
    },
    darkgrayCircle: {
        flexDirection: "column",
        height: "15px",
        width: "15px",
        borderRadius: 50,
        backgroundColor: "#898A8D",

    },
    grayCircle: {
        flexDirection: "column",
        height: "15px",
        width: "15px",
        borderRadius: 50,
        backgroundColor: "#C8C8CA",

    },
    restrectCircle: {
        flexDirection: "column",

    },
    title: {
        display:"flex",
        flexDirection: "column",
        fontSize: 9,
        paddingLeft: "5px",
        flexWrap:"wrap"
    },
    image: {
        height: "15px",
        width: "15px"
    },
    border: {
        // borderWidth: 1,
        // borderColor: "gray",
        paddingVertical: "8px",
    }
});

export default class Legends extends Component {
    render() {
        const { content } = this.props
        return (
            <Fragment>
                <View style={styles.row}>
                    <View style={styles.border}>
                        <View style={styles.inner_row}>
                            <View style={styles.darkgrayCircle}></View>
                            <Text style={styles.title}>IPR amount</Text>
                        </View>
                        <View style={styles.inner_row}>
                            <View style={styles.grayCircle}></View>
                            <Text style={styles.title}>IPR before this step</Text>
                        </View>
                        <View style={styles.inner_row}>
                            <View style={styles.orangeCircle}></View>
                            <Text style={styles.title}>IPR amount exceeding recommended limit</Text>
                        </View>
                        <View style={styles.inner_row}>
                            <View style={styles.restrectCircle}>
                                <Image
                                    style={styles.image}
                                    src={restrect_pdf}
                                />
                            </View>
                            <Text style={styles.title}>Restrict IPR</Text>
                        </View>
                        <View style={styles.inner_row}>
                            <View >
                                <Image
                                    style={styles.image}
                                    src={missing_pdf}
                                />
                            </View>
                            <Text style={styles.title}>Missing teeth</Text>
                        </View>
                    </View>
                </View>
            </Fragment>
        );
    }
}
