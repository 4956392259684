import React, { useState } from "react";

import upper_lower_light from "../../assets/images/v4/upper_lower_light.svg";
import upper_new from "../../assets/images/v4/upper_new.svg";
import lower_light from "../../assets/images/v4/lower_light.svg";
import upper_light from "../../assets/images/v4/upper_light.svg";
import lower_dark from "../../assets/images/v4/lower_dark.svg";
import upper_dark from "../../assets/images/v4/upper_dark.svg";
import eon_basic from "../../assets/images/v4/eon_basic.svg";
import eon_plus from "../../assets/images/v4/eon_plus.svg";
import eon_pro from "../../assets/images/v4/eon_pro.svg";
import clock from "../../assets/images/v4/clock.svg";
import close from "../../assets/images/v4/close.svg";

export const ToolTipEonBasic = (props) => {
  const [toggleToolTip, setToggleToolTip] = useState(false);

  const { content, handleResetDatePicker } = props;
  return (
    <div
      className={`note-tooltip-gender ${
        toggleToolTip ? "toggle-tool-tip" : ""
      }`}
    >
      <div
        className="note-tooltip"
        onMouseOver={handleResetDatePicker}
        onClick={
          window.innerWidth < 840 && toggleToolTip
            ? () => setToggleToolTip(!toggleToolTip)
            : null
        }
      >
        <span
          className={`icon-container tooltip1 tooltip-basic package-type-tooltip ${
            !toggleToolTip ? "toggle-question-circle" : ""
          }`}
          id="HybridTreatmentMoreInfo"
        >
          {}
          <i className={`fas fa-question-circle `}></i>
          <div
            className={`c-tooltip-body ${
              window.innerWidth < 840
                ? "custom-tooltip-bottom custom-tooltip-basic-bottom"
                : "tooltip-basic-right tooltip1-right"
            }  custom_shadow `}
          >
            <i></i>
            {window.innerWidth < 840 ? (
              <img
                onClick={() => setToggleToolTip(!toggleToolTip)}
                className="tooltip-close-mobile"
                src={close}
                alt="close"
              />
            ) : null}
            <div className="eon-basic-tooltip">
              <img className="eon-basic-img" src={eon_basic} alt="eon Basic" />
              <div className="eon_pro_hedaer body-bold-text">
                {content.eon_basic_hedaer}
                <span className="eon_header_text_2">
                  {content.eon_basic_hedaer_2}
                </span>
              </div>
              <div className="eon_basic_text_1 caption-1-text ">
                <img src={upper_new} alt="upper light" />{" "}
                {content.eon_basic_text_1}{" "}
                <span className="eon_basic_text_2">
                  {" "}
                  <img src={clock} alt="clock" /> {content.eon_basic_text_2}
                </span>
              </div>
              <div className="eon_basic_text_3 caption-1-text ">
                {content.eon_basic_text_3}
              </div>
              <div className="eon_basic_footer caption-2-text">
                {content.eon_basic_footer}
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};
export const ToolTipEonPlus = (props) => {
  const [toggleToolTip, setToggleToolTip] = useState(false);
  const { content, handleResetDatePicker } = props;
  return (
    <div
      className={`note-tooltip-gender ${
        toggleToolTip ? "toggle-tool-tip" : ""
      }`}
    >
      <div
        className="note-tooltip"
        onMouseOver={handleResetDatePicker}
        onClick={
          window.innerWidth < 840 && toggleToolTip
            ? () => setToggleToolTip(!toggleToolTip)
            : null
        }
      >
        <span
          className="icon-container tooltip1 tooltip-plus package-type-tooltip"
          id="HybridTreatmentMoreInfo"
        >
          {}
          <i
            className={`fas fa-question-circle ${
              toggleToolTip ? "toggle-question-circle" : ""
            }`}
          ></i>

          <div
            className={`c-tooltip-body ${
              window.innerWidth < 840
                ? "custom-tooltip-bottom custom-tooltip-plus-bottom"
                : "tooltip-basic-right tooltip-plus-right tooltip1-right"
            }  custom_shadow ${toggleToolTip ? "toggle-tool-tip" : ""}`}
          >
            <i></i>
            {window.innerWidth < 840 ? (
              <img
                onClick={() => setToggleToolTip(!toggleToolTip)}
                className="tooltip-close-mobile"
                src={close}
                alt="close"
              />
            ) : null}
            <div className="eon-basic-tooltip">
              <img className="eon-basic-img" src={eon_plus} alt="eon Plus" />
              <div className="eon_plus_hedaer body-bold-text">
                {content.eon_plus_hedaer}
              </div>
              <div className="eon_basic_text_1 caption-1-text ">
                <img src={upper_new} alt="upper light" />{" "}
                {content.eon_plus_text_1}{" "}
                <span className="eon_basic_text_2">
                  <img src={clock} alt="clock" /> {content.eon_plus_text_2}
                </span>
              </div>
              <div className="eon_basic_text_3 caption-1-text ">
                {content.eon_plus_text_3}
              </div>
              <div className="eon_basic_footer caption-2-text">
                {content.eon_basic_footer}
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};
export const ToolTipEonPro = (props) => {
  const [toggleToolTip, setToggleToolTip] = useState(false);
  const { content, handleResetDatePicker } = props;
  return (
    <div
      className={`note-tooltip-gender ${
        toggleToolTip ? "toggle-tool-tip" : ""
      }`}
    >
      <div
        className="note-tooltip"
        onMouseOver={handleResetDatePicker}
        onClick={
          window.innerWidth < 840 && toggleToolTip
            ? () => setToggleToolTip(!toggleToolTip)
            : null
        }
      >
        <span
          className="icon-container tooltip1 tooltip-pro package-type-tooltip"
          id="HybridTreatmentMoreInfo"
        >
          {}
          <i
            className={`fas fa-question-circle ${
              toggleToolTip ? "toggle-question-circle" : ""
            }`}
          ></i>

          <div
            className={`c-tooltip-body ${
              window.innerWidth < 840
                ? "custom-tooltip-bottom custom-tooltip-pro-bottom"
                : "tooltip-pro-right tooltip1-right"
            }  custom_shadow ${toggleToolTip ? "toggle-tool-tip" : ""}`}
          >
            <i></i>
            {window.innerWidth < 840 ? (
              <img
                onClick={() => setToggleToolTip(!toggleToolTip)}
                className="tooltip-close-mobile"
                src={close}
                alt="close"
              />
            ) : null}
            <div className="eon-basic-tooltip">
              <img className="eon-basic-img" src={eon_pro} alt="eon Pro" />
              <div className="eon_pro_hedaer body-bold-text">
                {content.eon_pro_hedaer}
                <span className="eon_header_text_2">
                  {content.eon_pro_hedaer_2}
                </span>
              </div>
              <div className="eon_basic_text_1 caption-1-text ">
                <img src={upper_new} alt="upper light" />{" "}
                {content.eon_pro_text_1}{" "}
                <span className="eon_basic_text_2">
                  <img src={clock} alt="clock" /> {content.eon_pro_text_2}
                </span>
              </div>
              <div className="eon_pro_text_3 caption-1-text ">
                {content.eon_pro_text_3}
              </div>
              <div className="eon_basic_footer caption-2-text">
                {content.eon_basic_footer}
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export const Type = (props) => {
  const [toggleToolTip, setToggleToolTip] = useState(true);

  const { content } = props;
  return (
    <div>
      <div
        className="note-tooltip"
        // onMouseOver={handleResetDatePicker}
        onClick={
          window.innerWidth < 840 && toggleToolTip
            ? () => setToggleToolTip(!toggleToolTip)
            : null
        }
      >
        <span className={` tooltip1   `}>
          {}
          <>change</>
          <div
            className={`
                        
   
                                 tooltip1-right
                       
                            
                            
                            custom_shadow `}
          >
            <i></i>
            {window.innerWidth < 840 ? (
              <img
                onClick={() => setToggleToolTip(!toggleToolTip)}
                className="tooltip-close-mobile"
                src={close}
                alt="close"
              />
            ) : null}
            <div className="">hiiii</div>
          </div>
        </span>
      </div>
    </div>
  );
};
