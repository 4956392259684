import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Link } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    position: "absolute",
    bottom: 40,
    left: 0,
    right: 0,
    paddingTop: 10,
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    // borderTopWidth: 1,
    // borderColor: "#00c7b1",
  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    width: "100%",
  },
  container: {
    flexDirection: "row",
    padding: "10px",
    paddingBottom:"0px"
  },
  field: {
    flexDirection: "row",
  },
  col: {
    flexDirection: "column",
    alignItems: "center",
    // width: "32%",
  },
  col_1: {
    flexDirection: "column",
    alignItems: "center",
    //  width: "15%",
  },
  col_2: {
    flexDirection: "column",
    alignItems: "center",
    // width: "40%",
  },
  col_3: {
    flexDirection: "column",
    alignItems: "center",
    // width: "25%",
  },
  col_4: {
    flexDirection: "column",
    alignItems: "center",
    //  width: "15%",
  },

  title: {
    fontSize: 8,
    textAlign: "left",
    color: "#00c7b1",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 50,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footer_text: {
    fontSize: 9,
    color: "#00c7b1",
    textDecoration: "none",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: -30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  dot: {
    flexDirection: "column",
    width:"5px",
    height:"5px",
    borderRadius:50,
    backgroundColor:"#00c7b1",
    marginTop:"2px",
    marginHorizontal:"10px"
  },
  dot_value: {
    fontSize: 15
  },
  horizantal_line:{
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    width:"90%"
  }
});
export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  render() {
    const { content } = this.props

    return (
      <Fragment>
        <View style={styles.row} fixed>
        <View
            style={styles.horizantal_line}
          />
          <View style={styles.container}>
            <View style={styles.col_1}>
              <Link
                style={styles.footer_text}
                src="https://www.eonaligner.com/"
              >
                {content.www_eonaligner_com}
              </Link>
            </View>
            <View style={styles.dot}></View>
            <View style={styles.col_2}>
              <View style={styles.field}>
                <Text style={styles.footer_text}>Mechelsesteenweg 271 Bus 1.1 2018 Antwerpen, Belgium</Text>
              </View>
            </View>
            <View style={styles.dot}></View>
            <View style={styles.col_3}>
              <View style={styles.field}>
                <Text style={styles.footer_text}>
                  Tel. +1 888 206 5955
                </Text>
              </View>
            </View>
            <View style={styles.dot}></View>
            <View style={styles.col_4}>
              <View style={styles.field}>
                <Text style={styles.footer_text}>info@eonaligner.com </Text>
              </View>
            </View>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Fragment>
    );
  }
}
