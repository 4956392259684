import {
  UPDATE_CASE,
  NEW_CASE_USER_INFO,
  TREATMENT_PLAN_INFO,
  IMPRESSIONS,
  PHOTOS,
  XRAYS,
  TOOTH_MOVEMENT_RESTRICTIONS,
  DO_NOT_PLACE_ATTACHMENTS,
  EXTRACTION,
  CROSSBITES,
  LEAVESPACE,
  SUBMISSION,
  NUMBERING,
  PHOTO_POSITION
} from "../constants/";
import { upper_teeth, lower_teeth } from "./init_teeth";
const INIT_STATE = {
  userInfo: {
    email: "",
    gender: "",
    firstName: "",
    middleName: "",
    lastName: "",

    day: "",
    month: "",
    year: "",

    caseType: "",
    wearCycle: "1 Week",
    shippingAddress: "",
    acceptTerms: false,
    savedToCloud: false,
    express_api_v2_case: false,
  },
  treatmentPlan: {
    tab1: false,
    tab2: true,
    tab3: true,
    clinical_findings: {
      skeletal_1: false,
      skeletal_2: false,
      skeletal_3: false,
      canine_r_1: false,
      canine_r_2: false,
      canine_r_3: false,
      canine_l_1: false,
      canine_l_2: false,
      canine_l_3: false,
      molar_r_1: false,
      molar_r_2: false,
      molar_r_3: false,
      molar_l_1: false,
      molar_l_2: false,
      molar_l_3: false,
      upper_midline_center: false,
      upper_midline_shifted_left: false,
      upper_midline_shifted_right: false,
      lower_midline_center: false,
      lower_midline_shifted_left: false,
      lower_midline_shifted_right: false,
      chief_complaint: "",
      skeletal: "",
      canine: {
        class1: {
          R: false,
          L: false,
        },
        class2: {
          R: false,
          L: false,
        },
        class3: {
          R: false,
          L: false,
        },
      },
      molar: {
        class1: {
          R: false,
          L: false,
        },
        class2: {
          R: false,
          L: false,
        },
        class3: {
          R: false,
          L: false,
        },
      },
      upper_midline: "",
      lower_midline: "",
      upper_midline_displacement: 0,
      lower_midline_displacement: 0,
      Tooth_Movement_Restrictions: {
        upper_teeth: Object.assign({}, upper_teeth),
        lower_teeth: Object.assign({}, lower_teeth),
      },
      Do_not_place_attachments: {
        upper_teeth: Object.assign({}, upper_teeth),
        lower_teeth: Object.assign({}, lower_teeth),
      },
    },
    treatment_goals: {
      treat_arches_upper: false,
      treat_arches_lower: false,
      treat_arches_both: false,
      midline_upper_maintain: false,
      midline_upper_improve: false,
      midline_upper_center: false,
      midline_lower_maintain: false,
      midline_lower_improve: false,
      midline_lower_center: false,
      overjet_maintain: false,
      overjet_improve: false,
      overjet_Ideal: false,
      overbite_maintain: false,
      overbite_improve: false,
      overbite_Ideal: false,
      arch_form_maintain: false,
      arch_form_improve: false,
      arch_form_constrict: false,
      procline_upper_primary: false,
      procline_lower_primary: false,
      procline_upper_if_needed: false,
      procline_lower_if_needed: false,

      expand_upper_primary: false,
      expand_lower_primary: false,
      expand_upper_if_needed: false,
      expand_lower_if_needed: false,

      ipr_upper_primary: false,
      ipr_lower_primary: false,
      ipr_upper_if_needed: false,
      ipr_lower_if_needed: false,

      canine_r_1: false,
      canine_r_2: false,
      canine_r_3: false,
      canine_l_1: false,
      canine_l_2: false,
      canine_l_3: false,
      molar_r_1: false,
      molar_r_2: false,
      molar_r_3: false,
      molar_l_1: false,
      molar_l_2: false,
      molar_l_3: false,
      goal_upper_ipr: false,
      goal_lower_ipr: false,
      goal_upper_distalization: false,
      goal_lower_distalization: false,
      goal_upper_mezialization: false,
      goal_lower_mezialization: false,
      treat_arches: "",
      upper_midline: "",
      lower_midline: "",
      midline: {
        U: "",
        L: "",
      },
      overjet: "",
      overbite: "",
      arch_form: "",
      procline: {
        U: "",
        L: "",
      },
      expand: {
        U: "",
        L: "",
      },
      ipr: {
        U: "",
        L: "",
      },
      canine: {
        class1: {
          R: false,
          L: false,
        },
        class2: {
          R: false,
          L: false,
        },
        class3: {
          R: false,
          L: false,
        },
      },
      molar: {
        class1: {
          R: false,
          L: false,
        },
        class2: {
          R: false,
          L: false,
        },
        class3: {
          R: false,
          L: false,
        },
      },
      how_to_achieve_a_p_goal: {
        U: "",
        L: "",
      },
      Extraction: {
        upper_teeth: Object.assign({}, upper_teeth),
        lower_teeth: Object.assign({}, lower_teeth),
      },
      Crossbites: {
        upper_teeth: Object.assign({}, upper_teeth),
        lower_teeth: Object.assign({}, lower_teeth),
      },
      LeaveSpace: {
        upper_teeth: Object.assign({}, upper_teeth),
        lower_teeth: Object.assign({}, lower_teeth),
      },
    },
    summary_and_special_instructions: {
      treatment_summary: "",
      special_instructions: "",
    },
    express: {
      chief_complaint: "",
      treatment_summary: "",
    },
  },
  impressions: {
    Digital_Impression: false,
    Digital_Means: false,
    Arrange_Pickup: false,
    upperImageValue: "",
    lowerImageValue: "",
    additional_comments: "",
    Impression_Type: "",
    upperImage: false,
    lowerImage: false,
  },
  photos: {
    front_smiling_photo: "",
    front_smiling: "",
    front_smiling_image: false,
    front_pose_photo: "",
    front_pose: "",
    front_pose_image: false,
    profile_photo: "",
    profile: "",
    profile_image: false,
    upper_occlusal_photo: "",
    upper_occlusal: "",
    upper_occlusal_image: false,
    lower_occlusal_photo: "",
    lower_occlusal: "",
    lower_occlusal_image: false,
    left_buccal_photo: "",
    left_buccal: "",
    left_buccal_image: false,
    frontal_photo: "",
    frontal: "",
    frontal_image: false,
    right_buccal_photo: "",
    right_buccal: "",
    right_buccal_image: false,
  },
  xrays: {
    panoramic_photo: "",
    panoramic: "",
    panoramic_image: false,
    cephalometric_photo: "",
    cephalometric: "",
    cephalometric_image: false,
  },
  submission: {
    acceptTerms: false,
    savedToCloud: false,
    validateTerms: false,
  },
  numbering: "palmer",
  position: "front"
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CASE:
      return action.payload;

    case PHOTO_POSITION:
      return {
        ...state,
        position: action.payload
      };
    case NEW_CASE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case NUMBERING:
      return {
        ...state,
        numbering: action.payload,
      };
    case TREATMENT_PLAN_INFO:
      return {
        ...state,
        treatmentPlan: action.payload,
      };
    case IMPRESSIONS:
      return {
        ...state,
        impressions: action.payload,
      };
    case PHOTOS:
      return {
        ...state,
        photos: action.payload,
      };
    case XRAYS:
      return {
        ...state,
        xrays: action.payload,
      };
    case SUBMISSION:
      return {
        ...state,
        submission: action.payload,
      };
    case TOOTH_MOVEMENT_RESTRICTIONS:
      return {
        ...state,
        treatmentPlan: {
          ...state.treatmentPlan,
          clinical_findings: {
            ...state.treatmentPlan.clinical_findings,
            Tooth_Movement_Restrictions: action.payload,
          },
        },
      };
    case DO_NOT_PLACE_ATTACHMENTS:
      return {
        ...state,
        treatmentPlan: {
          ...state.treatmentPlan,
          clinical_findings: {
            ...state.treatmentPlan.clinical_findings,
            Do_not_place_attachments: action.payload,
          },
        },
      };
    case EXTRACTION:
      return {
        ...state,
        treatmentPlan: {
          ...state.treatmentPlan,
          treatment_goals: {
            ...state.treatmentPlan.treatment_goals,
            Extraction: action.payload,
          },
        },
      };
    case CROSSBITES:
      return {
        ...state,
        treatmentPlan: {
          ...state.treatmentPlan,
          treatment_goals: {
            ...state.treatmentPlan.treatment_goals,
            Crossbites: action.payload,
          },
        },
      };
    case LEAVESPACE:
      return {
        ...state,
        treatmentPlan: {
          ...state.treatmentPlan,
          treatment_goals: {
            ...state.treatmentPlan.treatment_goals,
            LeaveSpace: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
