import { CASE_BY_ID } from "../constants/";

const DATA = {
  case: {},
  error: null,
};

export default (state = DATA, action) => {
  switch (action.type) {
    case CASE_BY_ID:
      return {
        ...state,
        case: action.payload || state.case,
        error: action.error,
      };
    default:
      return state;
  }
};

