import React, { Component } from "react";
import { connect } from "react-redux";
import edit_active from "../../assets/images/icon/edit_active.svg";
import edit_not_active from "../../assets/images/icon/edit_not_active.svg";
import restrict from "../../assets/images/icon/restrect.svg";
import missing from "../../assets/images/icon/missing.svg";
import { setUnsavedKeys } from "../../redux/changes/action";
import {
  Case_Arch_Expansion,
  Case_Attachments,
  Case_Crossbites,
  Case_Elastics,
  Case_Elastics_Buttons,
  Case_Elastics_Cuts,
  Case_Extraction,
  Case_IPR,
  Case_Overcorrections,
  Case_Passive_Aligners,
  Case_Pontics,
  Case_Tooth_Movement_Restrictions,
  Case_Torque_Enhancers,
  Doctor_Preferences_Attachments,
  Doctor_Preferences_IPR,
} from "../../redux/customeTeeth/action";
import { setTeethIPRData } from "../../redux/Tooth_IPR_Options/action";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  Case_Tooth_Movement_Restrictions: (payload) =>
    dispatch(Case_Tooth_Movement_Restrictions(payload)),

  Case_Extraction: (payload) => dispatch(Case_Extraction(payload)),

  Case_Crossbites: (payload) => dispatch(Case_Crossbites(payload)),

  Case_Attachments: (payload) => dispatch(Case_Attachments(payload)),
  Case_Arch_Expansion: (payload) => dispatch(Case_Arch_Expansion(payload)),
  Case_IPR: (payload) => dispatch(Case_IPR(payload)),
  Case_Pontics: (payload) => dispatch(Case_Pontics(payload)),
  Case_Overcorrections: (payload) => dispatch(Case_Overcorrections(payload)),
  Case_Passive_Aligners: (payload) => dispatch(Case_Passive_Aligners(payload)),
  Case_Torque_Enhancers: (payload) => dispatch(Case_Torque_Enhancers(payload)),
  Case_Elastics: (payload) => dispatch(Case_Elastics(payload)),
  Case_Elastics_Buttons: (payload) => dispatch(Case_Elastics_Buttons(payload)),
  Case_Elastics_Cuts: (payload) => dispatch(Case_Elastics_Cuts(payload)),

  Doctor_Preferences_Attachments: (payload) =>
    dispatch(Doctor_Preferences_Attachments(payload)),
  Doctor_Preferences_IPR: (payload) =>
    dispatch(Doctor_Preferences_IPR(payload)),
  setTeethIPRData: (payload) => dispatch(setTeethIPRData(payload)),
  setUnsavedKeys: (payload) => dispatch(setUnsavedKeys(payload)),
});
class CustomIPRTeeth extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      upper_teeth: props.teeth["upper_teeth"],
      lower_teeth: props.teeth["lower_teeth"],
      numbering: this.props.userReducer.currentUser.dental_notation,
    };
  }
  checkIPR = (newData, oldData) => {
    //let changed = false;
    if (newData.length && oldData.length) {
      if (newData.length !== oldData.length) {
        return true;
      } else {
        for (var x = 0; x < newData.length; x++) {
          if (
            newData[x].amount !== oldData[x].amount ||
            newData[x].step !== oldData[x].step
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  render() {
    let upper_teeth = this.props.teeth["upper_teeth"];
    let lower_teeth = this.props.teeth["lower_teeth"];
    // let saved_upper_teeth = this.props.saved_teeth_data["upper_teeth"];
    // let saved_lower_teeth = this.props.saved_teeth_data["lower_teeth"]
    const { content } = this.props.languageReducer;
    const advanced_modulesTs = this.props.advanced_modulesTs || false;
    return (
      <div className="ipr_teeth_selector">
        {upper_teeth && lower_teeth && (
          <div
            className={`${
              this.props.hideSide || advanced_modulesTs
                ? "custom-teeth-selector-container-summary"
                : "custom-teeth-selector-container"
            }`}
          >
            <div className="teeth_container_grid cmt3">
              {this.props.userReducer.currentUser.dental_notation ===
              "palmer" ? (
                <div className={`text-center dir`}>
                  <div>UR</div>
                  <div>LR</div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="side-teeth-grid">
                <div className="teeth-grid align-items-end">
                  {Object.keys(upper_teeth)
                    .filter((value, index) => upper_teeth[value].key < 9)
                    .map((prop, key) => {
                      return (
                        <div key={key} className="ipr_teeth_upper">
                          <img
                            className={
                              this.props.disabled ? "disabled-teeth" : ""
                            }
                            style={{
                              pointerEvents:
                                this.props.summary || this.props.disableChanges
                                  ? "none"
                                  : "",
                              filter:
                                this.props.cuts && upper_teeth[prop].value
                                  ? "invert(65%)"
                                  : "",
                            }}
                            alt={prop}
                            // src={
                            //   this.props.disableChanges &&
                            //     upper_teeth[prop].restrict_ipr_tooth
                            //     ? upper_teeth[prop].restrect
                            //     : upper_teeth[prop].image
                            // }

                            src={
                              this.props.disableChanges &&
                              upper_teeth[prop].restrict_ipr_tooth
                                ? upper_teeth[prop].restrect
                                : upper_teeth[prop].missing_ipr_tooth
                                ? upper_teeth[prop].missing
                                : upper_teeth[prop].image
                            }
                            //onMouseOver
                            onMouseOver={(e) => {
                              // if tooth is active show active tooth on Mouse Over
                              // else show hovered tooth
                              e.currentTarget.src = upper_teeth[prop]
                                .restrict_ipr_tooth
                                ? upper_teeth[prop].restrect
                                : upper_teeth[prop].missing_ipr_tooth
                                ? upper_teeth[prop].missing
                                : upper_teeth[prop].hover;
                            }}
                            //onMouseOut
                            onMouseOut={(e) => {
                              // if tooth is active show active tooth on Mouse Out
                              // else show not active tooth
                              e.currentTarget.src = upper_teeth[prop]
                                .restrict_ipr_tooth
                                ? upper_teeth[prop].restrect
                                : upper_teeth[prop].missing_ipr_tooth
                                ? upper_teeth[prop].missing
                                : upper_teeth[prop].not_active;
                            }}
                            //onclick
                            onClick={(e) => {
                              // if tooth is active change value to not active and show not active tooth
                              // else change value to active and show active tooth

                              // upper_teeth[prop].missing_ipr_tooth

                              if (upper_teeth[prop].missing_ipr_tooth) {
                                e.currentTarget.src = upper_teeth[prop].missing;
                              } else {
                                e.currentTarget.src = !upper_teeth[prop]
                                  .restrict_ipr_tooth
                                  ? upper_teeth[prop].restrect
                                  : upper_teeth[prop].not_active;
                                this.props.restrectIPRTooth(
                                  "upper_teeth",
                                  prop
                                );
                              }
                            }}
                          ></img>

                          {upper_teeth[prop].divider && (
                            <div
                              className={`${
                                this.checkIPR(
                                  upper_teeth[prop].ipr_steps_amounts,
                                  this.props.saved_teeth_data.upper_teeth[prop]
                                    .ipr_steps_amounts
                                )
                                  ? "updated_ipr"
                                  : upper_teeth[prop].use_ipr
                                  ? "use_ipr"
                                  : ""
                              } num-container`}
                            >
                              {upper_teeth[prop].key !== 8 && (
                                <div className="v-line-upper3"></div>
                              )}
                              <div
                                className={`form-material text-center  ${
                                  upper_teeth[prop].key !== 8
                                    ? upper_teeth[prop].ipr_steps_amounts[0]
                                        .step !== 0 ||
                                      upper_teeth[prop].ipr_steps_amounts[0]
                                        .amount !== 0
                                      ? "ipr-amount"
                                      : "ipr-amount-empty"
                                    : upper_teeth[prop].ipr_steps_amounts[0]
                                        .step !== 0 ||
                                      upper_teeth[prop].ipr_steps_amounts[0]
                                        .amount !== 0
                                    ? "ipr-amount-center"
                                    : "ipr-amount-center-empty"
                                }`}
                              >
                                {upper_teeth[prop].ipr_steps_amounts.length >
                                  0 &&
                                (upper_teeth[prop].ipr_steps_amounts[0].step !==
                                  0 ||
                                  upper_teeth[prop].ipr_steps_amounts[0]
                                    .amount !== 0) ? (
                                  <div className="ipr_cir_val">
                                    {this.props.current_ipr_tooth === prop &&
                                    !this.props.hideSide ? (
                                      <img
                                        style={{
                                          pointerEvents: this.props
                                            .disableChanges
                                            ? "none"
                                            : "",
                                          cursor: "pointer",
                                          width: "14px",
                                          height: "14px",
                                        }}
                                        src={edit_active}
                                        alt="edit"
                                        onClick={() => {
                                          this.props.changeIPRData(
                                            upper_teeth[prop],
                                            "upper_teeth",
                                            prop
                                          );
                                          this.props.setUnsavedKeys({
                                            current_ipr_position: "upper_teeth",
                                            current_ipr_tooth: prop,
                                            step: "ipr",
                                          });
                                        }}
                                      ></img>
                                    ) : (
                                      !this.props.hideSide && (
                                        <img
                                          style={{
                                            display: this.props
                                              .advanced_modulesTs
                                              ? "none"
                                              : "",
                                            pointerEvents: this.props
                                              .disableChanges
                                              ? "none"
                                              : "",
                                            cursor: "pointer",
                                            width: "14px",
                                            height: "14px",
                                          }}
                                          src={edit_not_active}
                                          alt="edit"
                                          onClick={() => {
                                            this.props.changeIPRData(
                                              upper_teeth[prop],
                                              "upper_teeth",
                                              prop
                                            );
                                            this.props.setUnsavedKeys({
                                              current_ipr_position:
                                                "upper_teeth",
                                              current_ipr_tooth: prop,
                                              step: "ipr",
                                            });
                                          }}
                                        ></img>
                                      )
                                    )}
                                    <div
                                      className={`ipr_circle ${
                                        upper_teeth[prop].ipr_steps_amounts[0]
                                          .amount > 0.5
                                          ? "exeeding_recommended"
                                          : this.checkIPR(
                                              upper_teeth[prop]
                                                .ipr_steps_amounts,
                                              this.props.saved_teeth_data
                                                .upper_teeth[prop]
                                                .ipr_steps_amounts
                                            )
                                          ? "edited_ipr"
                                          : "ipr_amount"
                                      }`}
                                    >
                                      {upper_teeth[
                                        prop
                                      ].ipr_steps_amounts[0].amount.replace(
                                        /^0+/,
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className={`ipr_circle ${
                                        this.checkIPR(
                                          upper_teeth[prop].ipr_steps_amounts,
                                          this.props.saved_teeth_data
                                            .upper_teeth[prop].ipr_steps_amounts
                                        )
                                          ? "edited_ipr"
                                          : "ipr_before_step"
                                      }`}
                                    >
                                      {
                                        upper_teeth[prop].ipr_steps_amounts[0]
                                          .step
                                      }
                                    </div>
                                  </div>
                                ) : (
                                  !this.props.hideSide && (
                                    <i
                                      className={`fas fa-plus-circle ${
                                        this.props.current_ipr_tooth === prop
                                          ? "circle-active"
                                          : ""
                                      }`}
                                      style={{
                                        pointerEvents: this.props.disableChanges
                                          ? "none"
                                          : "",
                                        display: this.props.advanced_modulesTs
                                          ? "none"
                                          : "",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          active_tooth: upper_teeth[prop].key,
                                        });

                                        this.props.changeIPRData(
                                          upper_teeth[prop],
                                          "upper_teeth",
                                          prop
                                        );
                                        this.props.setUnsavedKeys({
                                          current_ipr_position: "upper_teeth",
                                          current_ipr_tooth: prop,
                                          step: "ipr",
                                        });
                                      }}
                                    ></i>
                                  )
                                )}

                                {/* <div style={{ pointerEvents: this.props.disableChanges ? "none" : "" }} className="triangle" onClick={() => this.props.changeIPRData(upper_teeth[prop], "upper_teeth", prop)}>
                            <div
                              className={`${upper_teeth[prop].before_step.toString().length > 1 ? "tri-num_big" : "tri-num"}`} >{upper_teeth[prop].before_step}</div>
                          </div> */}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Plamer tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.props.userReducer.currentUser.dental_notation ===
                    "palmer" && (
                    <>
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">8</div>
                        <div className="text-center">7</div>
                        <div className="text-center">6</div>
                        <div className="text-center">5</div>
                        <div className="text-center">4</div>
                        <div className="text-center">3</div>
                        <div className="text-center">2</div>
                        <div className="text-center">1</div>
                      </div>
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">8</div>
                        <div className="text-center">7</div>
                        <div className="text-center">6</div>
                        <div className="text-center">5</div>
                        <div className="text-center">4</div>
                        <div className="text-center">3</div>
                        <div className="text-center">2</div>
                        <div className="text-center">1</div>
                      </div>
                    </>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* End Plamer tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* FDI tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.props.userReducer.currentUser.dental_notation ===
                    "fdi" && (
                    <>
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">18</div>
                        <div className="text-center">17</div>
                        <div className="text-center">16</div>
                        <div className="text-center">15</div>
                        <div className="text-center">14</div>
                        <div className="text-center">13</div>
                        <div className="text-center">12</div>
                        <div className="text-center">11</div>
                      </div>
                      {/* <hr className="divider"></hr> */}
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">48</div>
                        <div className="text-center">47</div>
                        <div className="text-center">46</div>
                        <div className="text-center">45</div>
                        <div className="text-center">44</div>
                        <div className="text-center">43</div>
                        <div className="text-center">42</div>
                        <div className="text-center">41</div>
                      </div>
                    </>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* End FDI tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Universal tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.props.userReducer.currentUser.dental_notation ===
                    "universal" && (
                    <>
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">1</div>
                        <div className="text-center">2</div>
                        <div className="text-center">3</div>
                        <div className="text-center">4</div>
                        <div className="text-center">5</div>
                        <div className="text-center">6</div>
                        <div className="text-center">7</div>
                        <div className="text-center">8</div>
                      </div>
                      {/* <hr className="divider"></hr> */}
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">32</div>
                        <div className="text-center">31</div>
                        <div className="text-center">30</div>
                        <div className="text-center">29</div>
                        <div className="text-center">28</div>
                        <div className="text-center">27</div>
                        <div className="text-center">26</div>
                        <div className="text-center">25</div>
                      </div>
                    </>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* End Universal tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                </div>
                <div className="teeth-grid align-items-start">
                  {Object.keys(lower_teeth)
                    .filter((value, index) => lower_teeth[value].key < 9)
                    .map((prop, key) => {
                      return (
                        <div key={key} className="ipr_teeth_lower">
                          <img
                            className={
                              this.props.disabled ? "disabled-teeth" : ""
                            }
                            style={{
                              pointerEvents:
                                this.props.summary || this.props.disableChanges
                                  ? "none"
                                  : "",
                              filter:
                                this.props.cuts && lower_teeth[prop].value
                                  ? "invert(65%)"
                                  : "",
                            }}
                            // className="teeth"
                            alt={prop}
                            // src={
                            //   this.props.disableChanges &&
                            //     lower_teeth[prop].restrict_ipr_tooth
                            //     ? lower_teeth[prop].restrect
                            //     : lower_teeth[prop].image
                            // }

                            src={
                              this.props.disableChanges &&
                              lower_teeth[prop].restrict_ipr_tooth
                                ? lower_teeth[prop].restrect
                                : lower_teeth[prop].missing_ipr_tooth
                                ? lower_teeth[prop].missing
                                : lower_teeth[prop].image
                            }
                            //onMouseOver
                            onMouseOver={(e) => {
                              e.currentTarget.src = lower_teeth[prop]
                                .restrict_ipr_tooth
                                ? lower_teeth[prop].restrect
                                : lower_teeth[prop].missing_ipr_tooth
                                ? lower_teeth[prop].missing
                                : lower_teeth[prop].hover;
                            }}
                            //onMouseOut
                            onMouseOut={(e) => {
                              e.currentTarget.src = lower_teeth[prop]
                                .restrict_ipr_tooth
                                ? lower_teeth[prop].restrect
                                : lower_teeth[prop].missing_ipr_tooth
                                ? lower_teeth[prop].missing
                                : lower_teeth[prop].not_active;
                            }}
                            //onClick
                            onClick={(e) => {
                              if (lower_teeth[prop].missing_ipr_tooth) {
                                e.currentTarget.src = lower_teeth[prop].missing;
                              } else {
                                
                   
                             
                                 (e.currentTarget.src = !lower_teeth[prop]
                                    .restrict_ipr_tooth
                                    ? lower_teeth[prop].restrect
                                    : lower_teeth[prop].not_active);
                              this.props.restrectIPRTooth("lower_teeth", prop);
                            }}}
                          ></img>

                          {lower_teeth[prop].divider && (
                            <div
                              className={`${
                                this.checkIPR(
                                  lower_teeth[prop].ipr_steps_amounts,
                                  this.props.saved_teeth_data.lower_teeth[prop]
                                    .ipr_steps_amounts
                                )
                                  ? "updated_ipr"
                                  : lower_teeth[prop].use_ipr
                                  ? "use_ipr"
                                  : ""
                              } num-container`}
                            >
                              {lower_teeth[prop].key !== 8 && (
                                <div className="v-line-lower3"></div>
                              )}

                              <div
                                className={`form-material text-center ${
                                  lower_teeth[prop].key !== 8
                                    ? lower_teeth[prop].ipr_steps_amounts[0]
                                        .step !== 0 ||
                                      lower_teeth[prop].ipr_steps_amounts[0]
                                        .amount !== 0
                                      ? "ipr-amount-lower"
                                      : "ipr-amount-lower-empty"
                                    : lower_teeth[prop].ipr_steps_amounts[0]
                                        .step !== 0 ||
                                      lower_teeth[prop].ipr_steps_amounts[0]
                                        .amount !== 0
                                    ? "ipr-amount-lower-center"
                                    : "ipr-amount-lower-center-empty"
                                }`}
                              >
                                {lower_teeth[prop].ipr_steps_amounts.length >
                                  0 &&
                                (lower_teeth[prop].ipr_steps_amounts[0].step !==
                                  0 ||
                                  lower_teeth[prop].ipr_steps_amounts[0]
                                    .amount !== 0) ? (
                                  <div className="ipr_cir_val">
                                    <div
                                      className={`ipr_circle ${
                                        lower_teeth[prop].ipr_steps_amounts[0]
                                          .amount > 0.5
                                          ? "exeeding_recommended"
                                          : this.checkIPR(
                                              lower_teeth[prop]
                                                .ipr_steps_amounts,
                                              this.props.saved_teeth_data
                                                .lower_teeth[prop]
                                                .ipr_steps_amounts
                                            )
                                          ? "edited_ipr"
                                          : "ipr_amount"
                                      }`}
                                    >
                                      {lower_teeth[
                                        prop
                                      ].ipr_steps_amounts[0].amount.replace(
                                        /^0+/,
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className={`ipr_circle ${
                                        this.checkIPR(
                                          lower_teeth[prop].ipr_steps_amounts,
                                          this.props.saved_teeth_data
                                            .lower_teeth[prop].ipr_steps_amounts
                                        )
                                          ? "edited_ipr"
                                          : "ipr_before_step"
                                      }`}
                                    >
                                      {
                                        lower_teeth[prop].ipr_steps_amounts[0]
                                          .step
                                      }
                                    </div>

                                    {this.props.current_ipr_tooth === prop &&
                                    !this.props.hideSide ? (
                                      <img
                                        style={{
                                          display: this.props.advanced_modulesTs
                                            ? "none"
                                            : "",
                                          pointerEvents: this.props
                                            .disableChanges
                                            ? "none"
                                            : "",
                                          cursor: "pointer",
                                          width: "14px",
                                          height: "14px",
                                        }}
                                        src={edit_active}
                                        alt="edit"
                                        onClick={() => {
                                          this.props.changeIPRData(
                                            lower_teeth[prop],
                                            "lower_teeth",
                                            prop
                                          );
                                          this.props.setUnsavedKeys({
                                            current_ipr_position: "lower_teeth",
                                            current_ipr_tooth: prop,
                                            step: "ipr",
                                          });
                                        }}
                                      ></img>
                                    ) : (
                                      !this.props.hideSide && (
                                        <img
                                          style={{
                                            display: this.props
                                              .advanced_modulesTs
                                              ? "none"
                                              : "",
                                            pointerEvents: this.props
                                              .disableChanges
                                              ? "none"
                                              : "",
                                            cursor: "pointer",
                                            width: "14px",
                                            height: "14px",
                                          }}
                                          src={edit_not_active}
                                          alt="edit"
                                          onClick={() => {
                                            this.props.changeIPRData(
                                              lower_teeth[prop],
                                              "lower_teeth",
                                              prop
                                            );
                                            this.props.setUnsavedKeys({
                                              current_ipr_position:
                                                "lower_teeth",
                                              current_ipr_tooth: prop,
                                              step: "ipr",
                                            });
                                          }}
                                        ></img>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  !this.props.hideSide && (
                                    <i
                                      className={`fas fa-plus-circle ${
                                        this.props.current_ipr_tooth === prop
                                          ? "circle-active"
                                          : ""
                                      }`}
                                      style={{
                                        pointerEvents: this.props.disableChanges
                                          ? "none"
                                          : "",
                                        display: this.props.advanced_modulesTs
                                          ? "none"
                                          : "",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          active_tooth: lower_teeth[prop].key,
                                        });

                                        this.props.changeIPRData(
                                          lower_teeth[prop],
                                          "lower_teeth",
                                          prop
                                        );
                                        this.props.setUnsavedKeys({
                                          current_ipr_position: "lower_teeth",
                                          current_ipr_tooth: prop,
                                          step: "ipr",
                                        });
                                      }}
                                    ></i>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="teeth_divider-ipr"></div>
              <div className="side-teeth-grid">
                <div className="teeth-grid align-items-end">
                  {Object.keys(upper_teeth)
                    .filter((value) => upper_teeth[value].key >= 9)
                    .map((prop, key) => {
                      return (
                        <div key={key} className="ipr_teeth_upper">
                          <img
                            className={
                              this.props.disabled ? "disabled-teeth" : ""
                            }
                            style={{
                              pointerEvents:
                                this.props.summary || this.props.disableChanges
                                  ? "none"
                                  : "",
                              filter:
                                this.props.cuts && upper_teeth[prop].value
                                  ? "invert(65%)"
                                  : "",
                            }}
                            // className="teeth"
                            alt={prop}
                            // src={
                            //   this.props.disableChanges &&
                            //     upper_teeth[prop].restrict_ipr_tooth
                            //     ? upper_teeth[prop].restrect
                            //     : upper_teeth[prop].image
                            // }

                            src={
                              this.props.disableChanges &&
                              upper_teeth[prop].restrict_ipr_tooth
                                ? upper_teeth[prop].restrect
                                : upper_teeth[prop].missing_ipr_tooth
                                ? upper_teeth[prop].missing
                                : upper_teeth[prop].image
                            }
                            //onMouseOver
                            onMouseOver={(e) => {
                              // if tooth is active show active tooth on Mouse Over
                              // else show hovered tooth
                              e.currentTarget.src = upper_teeth[prop]
                                .restrict_ipr_tooth
                                ? upper_teeth[prop].restrect
                                : upper_teeth[prop].missing_ipr_tooth
                                ? upper_teeth[prop].missing
                                : upper_teeth[prop].hover;
                            }}
                            //onMouseOut
                            onMouseOut={(e) => {
                              // if tooth is active show active tooth on Mouse Out
                              // else show not active tooth
                              e.currentTarget.src = upper_teeth[prop]
                                .restrict_ipr_tooth
                                ? upper_teeth[prop].restrect
                                : upper_teeth[prop].missing_ipr_tooth
                                ? upper_teeth[prop].missing
                                : upper_teeth[prop].not_active;
                            }}
                            //onclick
                            onClick={(e) => {
                              // if tooth is active change value to not active and show not active tooth
                              // else change value to active and show active tooth

                    
                                if (upper_teeth[prop].missing_ipr_tooth) {
                                  e.currentTarget.src = upper_teeth[prop].missing;
                                } else { 
                                (e.currentTarget.src = !upper_teeth[prop]
                                    .restrict_ipr_tooth
                                    ? upper_teeth[prop].restrect
                                    : upper_teeth[prop].not_active);
                              this.props.restrectIPRTooth("upper_teeth", prop);
                            }}
                          }
                            
                          ></img>
                          {upper_teeth[prop].divider && (
                            <div
                              className={`${
                                this.checkIPR(
                                  upper_teeth[prop].ipr_steps_amounts,
                                  this.props.saved_teeth_data.upper_teeth[prop]
                                    .ipr_steps_amounts
                                )
                                  ? "updated_ipr"
                                  : upper_teeth[prop].use_ipr
                                  ? "use_ipr"
                                  : ""
                              } num-container`}
                            >
                              <div className="v-line-upper3"></div>

                              <div
                                className={`form-material text-center  ${
                                  upper_teeth[prop].ipr_steps_amounts[0]
                                    .step !== 0 ||
                                  upper_teeth[prop].ipr_steps_amounts[0]
                                    .amount !== 0
                                    ? "ipr-amount"
                                    : "ipr-amount-empty"
                                }`}
                              >
                                {upper_teeth[prop].ipr_steps_amounts.length >
                                  0 &&
                                (upper_teeth[prop].ipr_steps_amounts[0].step !==
                                  0 ||
                                  upper_teeth[prop].ipr_steps_amounts[0]
                                    .amount !== 0) ? (
                                  <div className="ipr_cir_val">
                                    {this.props.current_ipr_tooth === prop &&
                                    !this.props.hideSide ? (
                                      <img
                                        style={{
                                          pointerEvents: this.props
                                            .disableChanges
                                            ? "none"
                                            : "",
                                          cursor: "pointer",
                                          width: "14px",
                                          height: "14px",
                                        }}
                                        src={edit_active}
                                        alt="edit"
                                        onClick={() => {
                                          this.props.changeIPRData(
                                            upper_teeth[prop],
                                            "upper_teeth",
                                            prop
                                          );
                                          this.props.setUnsavedKeys({
                                            current_ipr_position: "upper_teeth",
                                            current_ipr_tooth: prop,
                                            step: "ipr",
                                          });
                                        }}
                                      ></img>
                                    ) : (
                                      !this.props.hideSide && (
                                        <img
                                          style={{
                                            display: this.props
                                              .advanced_modulesTs
                                              ? "none"
                                              : "",
                                            pointerEvents: this.props
                                              .disableChanges
                                              ? "none"
                                              : "",
                                            cursor: "pointer",
                                            width: "14px",
                                            height: "14px",
                                          }}
                                          src={edit_not_active}
                                          alt="edit"
                                          onClick={() => {
                                            this.props.changeIPRData(
                                              upper_teeth[prop],
                                              "upper_teeth",
                                              prop
                                            );
                                            this.props.setUnsavedKeys({
                                              current_ipr_position:
                                                "upper_teeth",
                                              current_ipr_tooth: prop,
                                              step: "ipr",
                                            });
                                          }}
                                        ></img>
                                      )
                                    )}
                                    <div
                                      className={`ipr_circle ${
                                        upper_teeth[prop].ipr_steps_amounts[0]
                                          .amount > 0.5
                                          ? "exeeding_recommended"
                                          : this.checkIPR(
                                              upper_teeth[prop]
                                                .ipr_steps_amounts,
                                              this.props.saved_teeth_data
                                                .upper_teeth[prop]
                                                .ipr_steps_amounts
                                            )
                                          ? "edited_ipr"
                                          : "ipr_amount"
                                      }`}
                                    >
                                      {upper_teeth[
                                        prop
                                      ].ipr_steps_amounts[0].amount.replace(
                                        /^0+/,
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className={`ipr_circle ${
                                        this.checkIPR(
                                          upper_teeth[prop].ipr_steps_amounts,
                                          this.props.saved_teeth_data
                                            .upper_teeth[prop].ipr_steps_amounts
                                        )
                                          ? "edited_ipr"
                                          : "ipr_before_step"
                                      }`}
                                    >
                                      {
                                        upper_teeth[prop].ipr_steps_amounts[0]
                                          .step
                                      }
                                    </div>
                                  </div>
                                ) : (
                                  !this.props.hideSide && (
                                    <i
                                      className={`fas fa-plus-circle ${
                                        this.props.current_ipr_tooth === prop
                                          ? "circle-active"
                                          : ""
                                      }`}
                                      style={{
                                        pointerEvents: this.props.disableChanges
                                          ? "none"
                                          : "",
                                        display: this.props.advanced_modulesTs
                                          ? "none"
                                          : "",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          active_tooth: upper_teeth[prop].key,
                                        });

                                        this.props.changeIPRData(
                                          upper_teeth[prop],
                                          "upper_teeth",
                                          prop
                                        );
                                        this.props.setUnsavedKeys({
                                          current_ipr_position: "upper_teeth",
                                          current_ipr_tooth: prop,
                                          step: "ipr",
                                        });
                                      }}
                                    ></i>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Plamer tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.props.userReducer.currentUser.dental_notation ===
                    "palmer" && (
                    <>
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">1</div>
                        <div className="text-center">2</div>
                        <div className="text-center">3</div>
                        <div className="text-center">4</div>
                        <div className="text-center">5</div>
                        <div className="text-center">6</div>
                        <div className="text-center">7</div>
                        <div className="text-center">8</div>
                      </div>

                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">1</div>
                        <div className="text-center">2</div>
                        <div className="text-center">3</div>
                        <div className="text-center">4</div>
                        <div className="text-center">5</div>
                        <div className="text-center">6</div>
                        <div className="text-center">7</div>
                        <div className="text-center">8</div>
                      </div>
                    </>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* End Plamer tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* FDI tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.props.userReducer.currentUser.dental_notation ===
                    "fdi" && (
                    <>
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">21</div>
                        <div className="text-center">22</div>
                        <div className="text-center">23</div>
                        <div className="text-center">24</div>
                        <div className="text-center">25</div>
                        <div className="text-center">26</div>
                        <div className="text-center">27</div>
                        <div className="text-center">28</div>
                      </div>
                      {/* <hr className="divider"></hr> */}
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">31</div>
                        <div className="text-center">32</div>
                        <div className="text-center">33</div>
                        <div className="text-center">34</div>
                        <div className="text-center">35</div>
                        <div className="text-center">36</div>
                        <div className="text-center">37</div>
                        <div className="text-center">38</div>
                      </div>
                    </>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* End FDI tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Universal tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.props.userReducer.currentUser.dental_notation ===
                    "universal" && (
                    <>
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">9</div>
                        <div className="text-center">10</div>
                        <div className="text-center">11</div>
                        <div className="text-center">12</div>
                        <div className="text-center">13</div>
                        <div className="text-center">14</div>
                        <div className="text-center">15</div>
                        <div className="text-center">16</div>
                      </div>
                      {/* <hr className="divider"></hr> */}
                      <div className={`teeth-grid text-center `}>
                        <div className="text-center">24</div>
                        <div className="text-center">23</div>
                        <div className="text-center">22</div>
                        <div className="text-center">21</div>
                        <div className="text-center">20</div>
                        <div className="text-center">19</div>
                        <div className="text-center">18</div>
                        <div className="text-center">17</div>
                      </div>
                    </>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* End Universal tooth numbering system  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                </div>
                <div className="teeth-grid align-items-start">
                  {Object.keys(lower_teeth)
                    .filter((value, index) => lower_teeth[value].key >= 9)
                    .map((prop, key) => {
                      return (
                        <div key={key} className="ipr_teeth_lower">
                          <img
                            className={
                              this.props.disabled ? "disabled-teeth" : ""
                            }
                            style={{
                              pointerEvents:
                                this.props.summary || this.props.disableChanges
                                  ? "none"
                                  : "",
                              filter:
                                this.props.cuts && lower_teeth[prop].value
                                  ? "invert(65%)"
                                  : "",
                            }}
                            // className="teeth"
                            alt={prop}
                            // src={
                            //   this.props.disableChanges &&
                            //     lower_teeth[prop].restrict_ipr_tooth
                            //     ? lower_teeth[prop].restrect
                            //     : lower_teeth[prop].image
                            // }

                            src={
                              this.props.disableChanges &&
                              lower_teeth[prop].restrict_ipr_tooth
                                ? lower_teeth[prop].restrect
                                : lower_teeth[prop].missing_ipr_tooth
                                ? lower_teeth[prop].missing
                                : lower_teeth[prop].image
                            }
                            // iprReviseMissing
                            //onMouseOver
                            onMouseOver={(e) => {
                              e.currentTarget.src = lower_teeth[prop]
                                .restrict_ipr_tooth
                                ? lower_teeth[prop].restrect
                                : lower_teeth[prop].missing_ipr_tooth
                                ? lower_teeth[prop].missing
                                : lower_teeth[prop].hover;
                            }}
                            //onMouseOut
                            onMouseOut={(e) => {
                              e.currentTarget.src = lower_teeth[prop]
                                .restrict_ipr_tooth
                                ? lower_teeth[prop].restrect
                                : lower_teeth[prop].missing_ipr_tooth
                                ? lower_teeth[prop].missing
                                : lower_teeth[prop].not_active;
                            }}
                            //onClick
         

                            onClick={(e) => {
                              if (lower_teeth[prop].missing_ipr_tooth) {
                                e.currentTarget.src = lower_teeth[prop].missing;
                              } else {
                                
                   
                             
                                 (e.currentTarget.src = !lower_teeth[prop]
                                    .restrict_ipr_tooth
                                    ? lower_teeth[prop].restrect
                                    : lower_teeth[prop].not_active);
                              this.props.restrectIPRTooth("lower_teeth", prop);
                            }}}





                          ></img>

                          {lower_teeth[prop].divider && (
                            <div
                              className={`${
                                this.checkIPR(
                                  lower_teeth[prop].ipr_steps_amounts,
                                  this.props.saved_teeth_data.lower_teeth[prop]
                                    .ipr_steps_amounts
                                )
                                  ? "updated_ipr"
                                  : lower_teeth[prop].use_ipr
                                  ? "use_ipr"
                                  : ""
                              } num-container`}
                            >
                              <div className="v-line-lower3"></div>
                              <div
                                className={`form-material text-center ${
                                  lower_teeth[prop].ipr_steps_amounts[0]
                                    .step !== 0 ||
                                  lower_teeth[prop].ipr_steps_amounts[0]
                                    .amount !== 0
                                    ? "ipr-amount-lower"
                                    : "ipr-amount-lower-empty"
                                }`}
                              >
                                {lower_teeth[prop].ipr_steps_amounts.length >
                                  0 &&
                                (lower_teeth[prop].ipr_steps_amounts[0].step !==
                                  0 ||
                                  lower_teeth[prop].ipr_steps_amounts[0]
                                    .amount !== 0) ? (
                                  <div className="ipr_cir_val">
                                    <div
                                      className={`ipr_circle ${
                                        lower_teeth[prop].ipr_steps_amounts[0]
                                          .amount > 0.5
                                          ? "exeeding_recommended"
                                          : this.checkIPR(
                                              lower_teeth[prop]
                                                .ipr_steps_amounts,
                                              this.props.saved_teeth_data
                                                .lower_teeth[prop]
                                                .ipr_steps_amounts
                                            )
                                          ? "edited_ipr"
                                          : "ipr_amount"
                                      }`}
                                    >
                                      {lower_teeth[
                                        prop
                                      ].ipr_steps_amounts[0].amount.replace(
                                        /^0+/,
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className={`ipr_circle ${
                                        this.checkIPR(
                                          lower_teeth[prop].ipr_steps_amounts,
                                          this.props.saved_teeth_data
                                            .lower_teeth[prop].ipr_steps_amounts
                                        )
                                          ? "edited_ipr"
                                          : "ipr_before_step"
                                      }`}
                                    >
                                      {
                                        lower_teeth[prop].ipr_steps_amounts[0]
                                          .step
                                      }
                                    </div>
                                    {this.props.current_ipr_tooth === prop &&
                                    !this.props.hideSide ? (
                                      <img
                                        style={{
                                          pointerEvents: this.props
                                            .disableChanges
                                            ? "none"
                                            : "",
                                          cursor: "pointer",
                                          width: "14px",
                                          height: "14px",
                                        }}
                                        src={edit_active}
                                        alt="edit"
                                        onClick={() => {
                                          this.props.changeIPRData(
                                            lower_teeth[prop],
                                            "lower_teeth",
                                            prop
                                          );
                                          this.props.setUnsavedKeys({
                                            current_ipr_position: "lower_teeth",
                                            current_ipr_tooth: prop,
                                            step: "ipr",
                                          });
                                        }}
                                      ></img>
                                    ) : (
                                      !this.props.hideSide && (
                                        <img
                                          style={{
                                            display: this.props
                                              .advanced_modulesTs
                                              ? "none"
                                              : "",
                                            pointerEvents: this.props
                                              .disableChanges
                                              ? "none"
                                              : "",
                                            cursor: "pointer",
                                            width: "14px",
                                            height: "14px",
                                          }}
                                          src={edit_not_active}
                                          alt="edit"
                                          onClick={() => {
                                            this.props.changeIPRData(
                                              lower_teeth[prop],
                                              "lower_teeth",
                                              prop
                                            );
                                            this.props.setUnsavedKeys({
                                              current_ipr_position:
                                                "lower_teeth",
                                              current_ipr_tooth: prop,
                                              step: "ipr",
                                            });
                                          }}
                                        ></img>
                                      )
                                    )}{" "}
                                  </div>
                                ) : (
                                  !this.props.hideSide && (
                                    <i
                                      className={`fas fa-plus-circle ${
                                        this.props.current_ipr_tooth === prop
                                          ? "circle-active"
                                          : ""
                                      }`}
                                      style={{
                                        pointerEvents: this.props.disableChanges
                                          ? "none"
                                          : "",
                                        display: this.props.advanced_modulesTs
                                          ? "none"
                                          : "",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          active_tooth: lower_teeth[prop].key,
                                        });

                                        this.props.changeIPRData(
                                          lower_teeth[prop],
                                          "lower_teeth",
                                          prop
                                        );
                                        this.props.setUnsavedKeys({
                                          current_ipr_position: "lower_teeth",
                                          current_ipr_tooth: prop,
                                          step: "ipr",
                                        });
                                      }}
                                    ></i>
                                  )
                                )}
                                {/* <i className={`fas fa-plus-circle ${this.props.current_ipr_tooth === prop ? "circle-active" : ""}`} style={{ pointerEvents: this.props.disableChanges ? "none" : "" }} onClick={() => this.props.changeIPRData(lower_teeth[prop], "lower_teeth", prop)}></i> */}
                                {/* <div style={{ pointerEvents: this.props.disableChanges ? "none" : "" }} className="triangle_lower" onClick={() => this.props.changeIPRData(lower_teeth[prop], "lower_teeth", prop)}>
                            <div
                              className={`${lower_teeth[prop].before_step.toString().length > 1 ? "tri-num_lower_big" : "tri-num_lower"}`} >{lower_teeth[prop].before_step}</div>
                          </div> */}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              {this.props.userReducer.currentUser.dental_notation ===
              "palmer" ? (
                <div className={`text-center dir `}>
                  <div>UL</div>
                  <div>LL</div>
                </div>
              ) : (
                <div></div>
              )}
            </div>

            {!this.props.hideSide && !this.props.advanced_modulesTs && (
              <div className="custom-teeth-selector-codes">
                <div className="color-code-grid">
                  <div className="color ipr_amount"></div>
                  <div className="title">{content.ipr_amount}</div>
                </div>

                <div className="color-code-grid">
                  <div className="color ipr_before_step"></div>
                  <div className="title">{content.ipr_before_this_step}</div>
                </div>
                <div className="color-code-grid">
                  <div className="color edited_ipr"></div>
                  <div className="title">{content.edited}</div>
                </div>
                <div className="color-code-grid  ">
                  <div className="color exeeding_recommended "></div>
                  <div className="title ">
                    {content.IPR_amount_exceeding_recommended_limit}
                  </div>
                </div>

                <div className="color-code-grid restrict-grid">
                  <img src={restrict} alt="restrict"></img>
                  <div className="title">{content.restrict_ipr}</div>
                  <div className="note-tooltip">
                    <span
                      className="icon-container tooltip1 new-tooltip"
                      id="HybridTreatmentMoreInfo"
                    >
                      <i className="fas fa-question-circle "></i>
                      <div
                        className={`${
                          window.innerWidth < 840
                            ? "tooltip-bottom"
                            : "tooltip-bottom"
                        }  custom_shadow`}
                      >
                        <div className="tooltip-body">{"note_text"}</div>
                        <i></i>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="color-code-grid  ">
             
                 <img src={missing} alt="restrict"></img>
                  <div className="title ">
                    {content.missingIPR}
                  </div>
                </div>




              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomIPRTeeth);
