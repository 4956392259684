import React from "react";

import exclamationMark from "../../assets/images/icon/exclamation_mark.svg";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export const ConfirmPopup = ({
  pop_up,
  content,
  CreateDoctorPreferences,
  handlePopUpModal,
  creatingNewPreferences,
}) => {
  return (
    <div>
      <Modal isOpen={pop_up} className="confarim_preferences_modal">
        <ModalHeader />
        <ModalBody>
          <div className="confarim_preferences_grid">
            <div>
              <img src={exclamationMark} alt="exclamation_mark" />
            </div>
            <div className="headline-text">
              {
                content.these_new_preferences_will_only_be_applied_to_new_case_submissions
              }
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="confarim_preferences-btns-grid">
            <div className="confarim_preferences_btn_width ">
              <Button
                className=" btn white-btn pull-right cml1 button-seme-bold-text"
                size="md"
                color="danger"
                disabled={creatingNewPreferences}
                onClick={() => handlePopUpModal()}
              >
                {content.cancel}
              </Button>
            </div>
            <div className="confarim_preferences_btn_width  confarim_preferences_btn_grid1  ">
              <Button
                className="light-green-btn btn pull-right cml1  full_width_in_button_inpopup button-seme-bold-text"
                size="md"
                disabled={creatingNewPreferences}
                onClick={() => CreateDoctorPreferences()}
              >
                {content.save}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
