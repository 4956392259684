import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, } from "reactstrap";
import ClassII from "./MalocclusionComponents/ClassII";
import ClassIII from "./MalocclusionComponents/ClassIII";
import Crossbite from "./MalocclusionComponents/Crossbite";
import Deepbite from "./MalocclusionComponents/Deepbite";
import { data } from "./MalocclusionComponents/malocclusion_data";
import Midline from "./MalocclusionComponents/Midline";
import Openbite from "./MalocclusionComponents/Openbite";
import Spacing from "./MalocclusionComponents/Spacing";
import {
  updateAdvancedModules,
  updateMalocclusion,
} from "../../../../redux/CaseSubmission/action";
import Crowding from "./MalocclusionComponents/Crowding";
import { CustomInput } from "reactstrap";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  updateMalocclusion: (payload) => dispatch(updateMalocclusion(payload)),
});
class Malocclusion extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      crossbite_btn:
        this.props.caseSubmission?.advanced_modules?.malocclusion
          ?.crossbite_btn || false,
      spacing_btn:
        this.props.caseSubmission?.advanced_modules?.malocclusion
          ?.spacing_btn || false,
      classII_btn:
        this.props.caseSubmission?.advanced_modules?.malocclusion
          ?.classII_btn || false,
      classIII_btn:
        this.props.caseSubmission?.advanced_modules?.malocclusion
          ?.classIII_btn || false,
      openbite_btn:
        this.props.caseSubmission?.advanced_modules?.malocclusion
          ?.openbite_btn || false,
      deepbite_btn:
        this.props.caseSubmission?.advanced_modules?.malocclusion
          ?.deepbite_btn || false,
      midline_btn:
        this.props.caseSubmission?.advanced_modules?.malocclusion
          ?.midline_btn || false,
      crowding_btn: this.props.caseSubmission?.advanced_modules?.malocclusion
        ?.crowding_btn || false,
    };
  }

  toggleComponents = (key) => {
    this.setState({ [key]: !this.state[key] });

    this.props.updateMalocclusion({
      key: key,
      data: !this.state[key],
    });
  };

  UpdateMalocclusion = (key, data) => {
    this.props.updateMalocclusion({
      key: key,
      data: data,
    });
  };

  UpdateAdvancedModules = () => {
    const { crossbite_btn, spacing_btn, classII_btn, classIII_btn, openbite_btn, deepbite_btn, midline_btn, crowding_btn }
      = this.state
    if (crossbite_btn || spacing_btn || classII_btn || classIII_btn || openbite_btn || deepbite_btn || midline_btn || crowding_btn) {
      this.props.updateAdvancedModules({
        key: "malocclusion",
        data: this.props.caseSubmission?.advanced_modules.malocclusion,
      });
      this.props.save();
    } else {
      this.props.justToggle()
    }

  };

  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: true
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: false
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 0;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 100;
    }
  };
  render() {
    const {
      crossbite_btn,
      spacing_btn,
      classII_btn,
      classIII_btn,
      openbite_btn,
      deepbite_btn,
      midline_btn,
      crowding_btn
    } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div >
       
          <div className="templete_module_grid_maloccluison">
            <div></div>
            <div>
            {/*--------------------------------------------------------------------------------*/}
            {/*  malocclusion  buttons*/}
            {/*--------------------------------------------------------------------------------*/}
            {!this.props.summary && <div className="malocclusion-btn cmt3">
              {data.map((value, index) => (
                
                <div key={index}>
                <CustomInput 
                id={value.key}
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  key={index}
                  type="checkbox" 
                  checked={this.state[value.key]}
                    
             
                  onChange={() => {
                    this.toggleComponents(value.key);
                  
                  }}
                  className={`${this.state[value.key]
                    ? " replacment-checkbox_green"
                    : "discrepancy-btn2"
                    } footnote-text-select`}
                    // className={`${this.state[value.key]===true?"replacment-checkbox_green  text_malocclusions":""}`}
                    label={value.title}
             
                    
                />
              </div>))}
            </div>}
            {/*--------------------------------------------------------------------------------*/}
            {/* end malocclusion  buttons*/}
            {/*--------------------------------------------------------------------------------*/}
            </div>
            <div className="divider_module-middle"></div>
            <div className="Malocclusion-content c-content-container cpb2">
            {/*--------------------------------------------------------------------------------*/}
            {/* Crossbite Components*/}
            {/*--------------------------------------------------------------------------------*/}
            {crossbite_btn && (
              <Crossbite
                toggleComponents={() => this.toggleComponents("crossbite_btn")}
                save={this.UpdateMalocclusion}
                summary={this.props.summary || false}
              />
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* Spacing Components*/}
            {/*--------------------------------------------------------------------------------*/}
            {spacing_btn && (
              <Spacing
                toggleComponents={() => this.toggleComponents("spacing_btn")}
                save={this.UpdateMalocclusion}
                summary={this.props.summary || false}

              />
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* ClassII Components*/}
            {/*--------------------------------------------------------------------------------*/}
            {classII_btn && (
              <ClassII
                toggleComponents={() => this.toggleComponents("classII_btn")}
                save={this.UpdateMalocclusion}
                summary={this.props.summary || false}

              />
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* ClassIII Components*/}
            {/*--------------------------------------------------------------------------------*/}
            {classIII_btn && (
              <ClassIII
                toggleComponents={() => this.toggleComponents("classIII_btn")}
                save={this.UpdateMalocclusion}
                summary={this.props.summary || false}

              />
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* Openbite Components*/}
            {/*--------------------------------------------------------------------------------*/}
            {openbite_btn && (
              <Openbite
                toggleComponents={() => this.toggleComponents("openbite_btn")}
                save={this.UpdateMalocclusion}
                summary={this.props.summary || false}

              />
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* Deepbite Components*/}
            {/*--------------------------------------------------------------------------------*/}
            {deepbite_btn && (
              <Deepbite
                toggleComponents={() => this.toggleComponents("deepbite_btn")}
                save={this.UpdateMalocclusion}
                summary={this.props.summary || false}

              />
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* Midline Components*/}
            {/*--------------------------------------------------------------------------------*/}
            {midline_btn && (
              <Midline
                toggleComponents={() => this.toggleComponents("midline_btn")}
                save={this.UpdateMalocclusion}
                summary={this.props.summary || false}

              />
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* Crowding Components*/}
            {/*--------------------------------------------------------------------------------*/}
            {crowding_btn && (
              <Crowding
                toggleComponents={() => this.toggleComponents("crowding_btn")}
                save={this.UpdateMalocclusion}
                summary={this.props.summary || false}

              />
            )}
                {
          !this.props.summary &&  <div className="btn-adv-mobile"><Button
          className=" mt-2 mobile-but-adv  light-green-btn float-right  advanceModuleInmobile  button-seme-bold-text"
          size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
        </Button></div>
        }

          </div>
      
          <div></div>
          </div>
         
        {
          !this.props.summary &&  <div className="btn-adv-mobile"><Button
          className=" mt-2 mobile-but-adv  light-green-btn float-right  advanceModuleInWebsite button-seme-bold-text "
          size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
        </Button></div>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Malocclusion);
