import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import {
  createAlignerCase,
  createAddress,
  getDoctorAddresses,
} from "../../../api/api";
import { FillPatientInfo } from "../../../services/fillForm";
import { countries, mapCountry } from "../../../helpers/countries";
import { successToaster } from "../../../services/toast";
import Select from "react-select";
import { wearCycle } from "../../../helpers/wearCycle";
import { gendar } from "../../../helpers/gendar";
export default class PatientInfo extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      email: props.getStore().email,
      gender: props.getStore().gender,
      firstName: props.getStore().firstName,
      middleName: props.getStore().middleName,
      lastName: props.getStore().lastName,

      day: props.getStore().day,
      month: props.getStore().month,
      year: props.getStore().year,

      caseType: props.getStore().caseType,
      wearCycle: props.getStore().wearCycle,
      shippingAddress: props.getStore().shippingAddress,
      express_api_v2_case: props.getStore().express_api_v2_case,
      obj: {},
      subscribe: false,
      doctor_image_value: "",
      doctor_image: false,
      doctor_photo: "",
      country: "",
      city: "",
      addresses: this.props.addresses,
      newAddress: [],
      validateAddress: true,
      validateWearCycle: true,
      validateGender: true,
      express_modal: false,
      address_modal: false,
      wear_cycle_value: null,
    };

    this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
    this.express_api_v2_case = false;
    this.full_data = false;
    this.shippingAddress = "";
    this.gender = "";
  }
  /* --------------------------------------------------------------------------------*/
  /* create new array of addresses with value and lable for react select  */
  /* --------------------------------------------------------------------------------*/
  componentDidMount = () => {
    let newAddressArray = mapCountry(this.props.addresses);
    this.setState({ newAddress: newAddressArray });
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation on submit   */
  /* --------------------------------------------------------------------------------*/
  isValidated = async () => {
    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator
    let isDataValid = false;
    // if full validation passes then save to store and pass as valid
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === true;
      }) ||
      this.full_data
    ) {
      if (
        this.props.getStore().email !== userInput.email ||
        this.props.getStore().gender !== userInput.gender.value ||
        this.props.getStore().firstName !== userInput.firstName ||
        this.props.getStore().middleName !== userInput.middleName ||
        this.props.getStore().lastName !== userInput.lastName ||
        this.props.getStore().day !== userInput.day ||
        this.props.getStore().month !== userInput.month ||
        this.props.getStore().year !== userInput.year ||
        this.props.getStore().caseType !== userInput.caseType ||
        this.props.getStore().wearCycle !== userInput.wearCycle.value ||
        this.props.getStore().shippingAddress !==
          userInput.shippingAddress.value
      ) {
        // only update store of something changed
        let userInfo = {
          aligner_case: {
            patient_attributes: {
              date_of_birth: `${userInput.year}-${userInput.month}-${userInput.day}`,
              first_name: userInput.firstName,
              gender: userInput.gender.value,
              middle_name: userInput.middleName,
              last_name: userInput.lastName,
              email: userInput.email,
            },
            case_type: userInput.caseType,
            wear_cycle: this.state.wear_cycle_value
              ? this.state.wear_cycle_value
              : "regular",
            express_api_v2_case: this.express_api_v2_case,
          },
          address_id: userInput.shippingAddress.value,
        };

        if (caseId) {
          return (isDataValid = true);
        } else {
          if (this.state.caseType === "Retainer") {
            this.props.sendingData();
            return createAlignerCase(
              this.props.props.userReducer.currentUser.id,
              JSON.stringify(userInfo)
            )
              .then((res) => {
                this.props.sendingData();
                this.props.props.history.push(
                  `${window.location.pathname}?id=${res.id}`
                );
                this.props.updateStore({
                  ...userInput,
                  express_api_v2_case: this.express_api_v2_case,
                  savedToCloud: false,
                });
                isDataValid = true;
              })
              .catch((error) => {
                this.props.sendingData();
                return (isDataValid = false);
              });
          } else {
            if (this.full_data) {
              this.props.sendingData();
              return await createAlignerCase(
                this.props.props.userReducer.currentUser.id,
                JSON.stringify(userInfo)
              )
                .then((res) => {
                  this.props.sendingData();
                  this.props.updateStore({
                    ...userInput,
                    express_api_v2_case: this.express_api_v2_case,
                    savedToCloud: false,
                  });
                  // this.props.props.history.push(
                  //   `${window.location.pathname}?id=${res.id}`
                  // );

                  window.location.href = `${window.location.pathname}?id=${res.id}`;
                  return (isDataValid = true);
                })
                .catch((error) => {
                  this.props.sendingData();
                  return (isDataValid = false);
                });
              //this.props.jumpToStep(1);
            } else {
              this.setState({ express_modal: !this.state.express_modal });
            }
          }
        }
      }
    } else {
      if (
        !this.full_data &&
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === true;
        })
      ) {
        this.setState({ express_modal: !this.state.express_modal });
      }

      // if anything fails then update the UI validation state but NOT the UI Data State
      this.setState(
        Object.assign(
          userInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    }
    return isDataValid;
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Check on submit  */
  /* --------------------------------------------------------------------------------*/
  validationCheck = () => {
    if (!this._validateOnDemand) return;

    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator

    this.setState(
      Object.assign(
        userInput,
        validateNewInput,
        this._validationErrors(validateNewInput)
      )
    );
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation on blure  */
  /* --------------------------------------------------------------------------------*/
  validationCheck2 = (key) => {
    if (!this._validateOnDemand) return;

    const userInput = this._grabUserInput2(key); // grab user entered vals
    const validateNewInput = this._validateData2(userInput, key); // run the new input against the validator

    this.setState(
      Object.assign(
        userInput,
        validateNewInput,
        this._validationErrors2(validateNewInput, key)
      )
    );
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation for each element on submit   */
  /* --------------------------------------------------------------------------------*/
  _validateData = (data) => {
    this.setState({
      validateAddress: data.shippingAddress !== "",
      validateGender: data.gender !== "",
      validateWearCycle: data.wearCycle !== "",
    });

    return {
      firstNameVal: data.firstName !== "", // required: anything besides N/A
      // middleNameVal: data.middleName !== "", // required: anything besides N/A
      lastNameVal: data.lastName !== "", // required: anything besides N/A
      genderVal: data.gender !== "", // required: anything besides N/A

      dayVal: data.day !== "" && data.day > 0 && data.day < 32,

      monthVal: data.month !== "" && data.month > 0 && data.month < 13,
      yearVal: data.year !== "" && data.year.length === 4,

      caseTypeVal: data.caseType !== "",
      wearCycleVal: data.wearCycle !== "",
      shippingAddressVal: data.shippingAddress !== "",
      emailVal: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        data.email
      ), // required: regex w3c uses in html5
      //  express: !checkdata,
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation for each element on blur   */
  /* --------------------------------------------------------------------------------*/
  _validateData2 = (data, key) => {
    let dataVal = {
      firstNameVal: data.firstName !== "", // required: anything besides N/A
      // middleNameVal: data.middleName !== "", // required: anything besides N/A
      lastNameVal: data.lastName !== "", // required: anything besides N/A
      genderVal: data.gender !== "", // required: anything besides N/A

      dayVal: data.day !== "" && data.day > 0 && data.day < 32,

      monthVal: data.month !== "" && data.month > 0 && data.month < 13,
      yearVal: data.year ? data.year !== "" && data.year.length === 4 : false,

      caseTypeVal: data.caseType !== "",
      wearCycleVal: data.wearCycle !== "",
      shippingAddressVal: data.shippingAddress !== "",
      emailVal: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        data.email
      ), // required: regex w3c uses in html5
    };
    let KeyVal = key + "Val";
    this.setState({
      validateAddress: data.shippingAddress !== "",
      validateGender: data.gender !== "",
      validateWearCycle: data.wearCycle !== "",
    });
    return { [KeyVal]: dataVal[KeyVal] };
  };
  /* --------------------------------------------------------------------------------*/
  /* radio Button Handler  */
  /* --------------------------------------------------------------------------------*/
  radioButtonHandler = (e) => {
    this.setState({
      caseType: e.target.value,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Errors on submit   */
  /* --------------------------------------------------------------------------------*/
  _validationErrors = (val) => {
    const errMsgs = {
      firstNameValMsg: val.firstNameVal ? "" : "First Name is  required",
      // middleNameValMsg: val.middleNameVal ? "" : "Middle Name is required",
      lastNameValMsg: val.lastNameVal ? "" : "Last Name is required",

      dayValMsg: val.dayVal ? "" : "A valid day is required",
      monthValMsg: val.monthVal ? "" : "A valid month is required",
      yearValMsg: val.yearVal ? "" : "A valid year is required",

      caseTypeValMsg: val.caseTypeVal ? "" : "Case Type is required",
      wearCycleValMsg: val.wearCycleVal ? "" : "Wear Cycle  is required",
      shippingAddressValMsg: val.shippingAddressVal
        ? ""
        : "Shipping Address is required",
      genderValMsg: val.genderVal ? "" : "A gender selection is required",
      emailValMsg: val.emailVal ? "" : "A valid email is required",
    };
    return errMsgs;
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Errors on blur   */
  /* --------------------------------------------------------------------------------*/
  _validationErrors2 = (val, key) => {
    const errMsgs = {
      firstNameValMsg: val.firstNameVal ? "" : "First Name is  required",
      // middleNameValMsg: val.middleNameVal ? "" : "Middle Name is required",
      lastNameValMsg: val.lastNameVal ? "" : "Last Name is required",

      dayValMsg: val.dayVal ? "" : "A valid day is required",
      monthValMsg: val.monthVal ? "" : "A valid month is required",
      yearValMsg: val.yearVal ? "" : "A valid year is required",

      caseTypeValMsg: val.caseTypeVal ? "" : "Case Type is required",
      wearCycleValMsg: val.wearCycleVal ? "" : "Wear Cycle  is required",
      shippingAddressValMsg: val.shippingAddressVal
        ? ""
        : "Shipping Address is required",
      genderValMsg: val.genderVal ? "" : "A gender selection is required",
      emailValMsg: val.emailVal ? "" : "A valid email is required",
    };
    let errMsgKey = key + "ValMsg";
    return { [errMsgKey]: errMsgs[errMsgKey] };
  };
  /* --------------------------------------------------------------------------------*/
  /* grab User Input data on submit  */
  /* --------------------------------------------------------------------------------*/
  _grabUserInput = () => {
    return {
      firstName: this.firstName.value,
      middleName: this.middleName.value,
      lastName: this.lastName.value,

      day: this.day.value,
      month: this.month.value,
      year: this.year.value,

      gender: this.gender,
      email: this.email.value,
      caseType: this.state.caseType,
      wearCycle: this.state.wearCycle,
      shippingAddress: this.shippingAddress,
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* grab User Input data on blure   */
  /* --------------------------------------------------------------------------------*/
  _grabUserInput2 = (key) => {
    let data = {
      firstName: this.firstName.value,
      middleName: this.middleName.value,
      lastName: this.lastName.value,

      day: this.day.value,
      month: this.month.value,
      year: this.year.value,

      gender: this.gender,
      email: this.email.value,
      caseType: this.state.caseType,
      wearCycle: this.state.wearCycle,
      shippingAddress: this.shippingAddress,
    };

    return { [key]: data[key] };
  };
  /* --------------------------------------------------------------------------------*/
  /* Fill Form with init data   */
  /* --------------------------------------------------------------------------------*/
  FillForm = () => {
    this.setState(FillPatientInfo());
  };
  /* --------------------------------------------------------------------------------*/
  /* Get Doctor Addresses   */
  /* --------------------------------------------------------------------------------*/
  GetDoctorAddresses = () => {
    getDoctorAddresses(this.props.props.userReducer.currentUser.id).then(
      (res) => {
        let newAddressArray = mapCountry(res);
        this.setState({ addresses: res, newAddress: newAddressArray });
      }
    );
  };
  /* --------------------------------------------------------------------------------*/
  /* show address modal    */
  /* --------------------------------------------------------------------------------*/
  toggleAddressModal = () => {
    this.setState({
      address_modal: !this.state.address_modal,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* add new address   */
  /* --------------------------------------------------------------------------------*/
  AddNewAddress = (event) => {
    const { content } = this.props.languageReducer;
    event.preventDefault();
    const data = {
      address: {
        country: this.state.country,
        city: event.target.city.value,
        state: event.target.state.value,
        line1: event.target.line1.value,
        line2: event.target.line2.value,
        zip: event.target.zip.value,
        phone: event.target.phone.value,
      },
    };
    this.setState({ city: event.target.city.value });

    // add new address
    createAddress(
      this.props.props.userReducer.currentUser.id,
      JSON.stringify(data)
    ).then((res) => {
      this.GetDoctorAddresses();
      this.setState({
        address_modal: !this.state.address_modal,
        country: "",
      });
      successToaster("Created new address successfully" ,  content.Success);
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* toggle Modal Express   */
  /* --------------------------------------------------------------------------------*/
  toggleModalExpress = () => {
    this.setState({ express_modal: !this.state.express_modal });
  };
  /* --------------------------------------------------------------------------------*/
  /* clear state if go out from component   */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    //const addresses = this.props.addresses;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];

    // explicit class assigning based on validation
    let notValidClasses = {};
    // gender validation
    if (typeof this.state.genderVal === "undefined" || this.state.genderVal) {
      notValidClasses.genderCls = "form-control";
    } else {
      notValidClasses.genderCls = "is-invalid form-control";
      notValidClasses.genderValGrpCls = "text-danger";
    }

    // first name validation

    if (
      typeof this.state.firstNameVal === "undefined" ||
      this.state.firstNameVal
    ) {
      notValidClasses.firstNameCls = "form-control";
    } else {
      notValidClasses.firstNameCls = "is-invalid form-control";
      notValidClasses.firstNameValGrpCls = "text-danger";
    }
    // middle name validation

    if (
      typeof this.state.middleNameVal === "undefined" ||
      this.state.middleNameVal
    ) {
      notValidClasses.middleNameCls = "form-control";
    } else {
      notValidClasses.middleNameCls = "is-invalid form-control";
      notValidClasses.middleNameValGrpCls = "text-danger";
    }
    // last name validation

    if (
      typeof this.state.lastNameVal === "undefined" ||
      this.state.lastNameVal
    ) {
      notValidClasses.lastNameCls = "form-control";
    } else {
      notValidClasses.lastNameCls = "is-invalid form-control";
      notValidClasses.lastNameValGrpCls = "text-danger";
    }
    // date of birth validation

    if (typeof this.state.dayVal === "undefined" || this.state.dayVal) {
      notValidClasses.dayCls = "form-control";
    } else {
      notValidClasses.dayCls = "is-invalid form-control";
      notValidClasses.dayValGrpCls = "text-danger";
    }

    if (typeof this.state.monthVal === "undefined" || this.state.monthVal) {
      notValidClasses.monthCls = "form-control";
    } else {
      notValidClasses.monthCls = "is-invalid form-control";
      notValidClasses.monthValGrpCls = "text-danger";
    }

    if (typeof this.state.yearVal === "undefined" || this.state.yearVal) {
      notValidClasses.yearCls = "form-control";
    } else {
      notValidClasses.yearCls = "is-invalid form-control";
      notValidClasses.yearValGrpCls = "text-danger";
    }
    // email of birth validation

    if (typeof this.state.emailVal === "undefined" || this.state.emailVal) {
      notValidClasses.emailCls = "form-control";
    } else {
      notValidClasses.emailCls = "is-invalid form-control";
      notValidClasses.emailValGrpCls = "text-danger";
    }

    // case type validation

    if (
      typeof this.state.caseTypeVal === "undefined" ||
      this.state.caseTypeVal
    ) {
      notValidClasses.caseTypeCls = "form-control";
    } else {
      notValidClasses.caseTypeCls = "is-invalid form-control";
      notValidClasses.caseTypeValGrpCls = "text-danger";
    }
    //wear Cycle validation
    if (
      typeof this.state.wearCycleVal === "undefined" ||
      this.state.wearCycleVal
    ) {
      notValidClasses.wearCycleCls = "form-control";
    } else {
      notValidClasses.wearCycleCls = "is-invalid form-control";
      notValidClasses.wearCycleValGrpCls = "text-danger";
    }

    //shipping address validation
    if (
      typeof this.state.shippingAddressVal === "undefined" ||
      this.state.shippingAddressVal
    ) {
      notValidClasses.shippingAddressCls = "form-control";
    } else {
      notValidClasses.shippingAddressCls = "is-invalid form-control";
      notValidClasses.shippingAddressValGrpCls = "text-danger";
    }
    return (
      <div className="step step1 mt-5 mb-5 ">
        {/*--------------------------------------------------------------------------------*/}
        {/* Fill Form With init values button   */}
        {/*--------------------------------------------------------------------------------*/}
        {/* <div className="row p-3">
          <button
            type="button"
            id="finish-button"
            className="btn btn-next btn-primary btn-lg pull-left"
            onClick={() => {
              this.FillForm();
            }}
          >
            Fill Form
          </button>
        </div> */}
        {/*--------------------------------------------------------------------------------*/}
        {/* End Fill Form With init values button   */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Address modal  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.address_modal}
          toggle={this.toggleAddressModal}
        >
          <ModalHeader
            toggle={this.toggleAddressModal}
          >{`New Address`}</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.AddNewAddress}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="country">
                      Country <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Select
                      // value={this.state.country}
                      onChange={(country) => {
                        this.setState({
                          country: country.value,
                        });
                      }}
                      options={countries}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="city">
                      City
                      <span style={{ color: "red" }}>*</span>{" "}
                    </Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      onChange={(e) => {
                        this.setState({
                          city: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="line1">Line 1</Label>
                    <Input type="text" name="line1" id="line1" />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="line2">Line 2</Label>
                    <Input type="text" name="line2" id="line2" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="state">State</Label>
                    <Input type="text" name="state" id="state" />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="zip">Zip</Label>
                    <Input type="text" name="zip" id="zip" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="phone">Phone</Label>
                    <Input type="text" name="phone" id="phone" />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Button
                  className="btn red-btn pull-right m-1 "
                  size="md"
                  color="danger"
                  onClick={this.toggleAddressModal}
                >
                  Cancel
                </Button>{" "}
                <Button
                  className="btn green-btn pull-right m-1"
                  size="md"
                  type="submit"
                  disabled={this.state.country === "" || this.state.city === ""}
                >
                  {`Add`}
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/*End  Address modal  */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Submission Types modal  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.express_modal}
          toggle={this.toggleModalExpress}
          className="modal-express"
        >
          <ModalHeader toggle={this.toggleModalExpress}>
            Submission Types
          </ModalHeader>

          <ModalBody className="p-3">
            <div className="content form-block-holder h-100">
              <Row className="m-1">
                <Col xs="12" md="6" lg="6" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100 h-100"
                    size="md"
                    onClick={() => {
                      this.setState({
                        express_modal: !this.state.express_modal,
                        express_api_v2_case: false,
                      });
                      this.full_data = true;
                      this.express_api_v2_case = false;
                      this.isValidated();
                    }}
                  >
                    Detailed Case Submission
                  </Button>
                </Col>
                <Col xs="12" md="6" lg="6" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100 h-100"
                    size="md"
                    onClick={() => {
                      this.setState({
                        express_modal: !this.state.express_modal,
                        express_api_v2_case: true,
                      });
                      this.full_data = true;
                      this.express_api_v2_case = true;
                      this.isValidated();
                    }}
                  >
                    Summarized Case Submission
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Submission Types modal  */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Patient Info Form   */}
        {/*--------------------------------------------------------------------------------*/}
        <div className="row justify-content-md-center">
          <div className="col">
            <div className="">
              <form id="Form" className="form-horizontal mt-2 mb-2">
                <Row>
                  {/* Case Types  */}
                  <Col xs="12" md="4" lg="4">
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Case Type
                      </label>
                      <Row className="">
                        <Col xs="12" md="12" lg="12">
                          <div className="grid3">
                            <div className="custom-control custom-radio ">
                              <input
                                type="radio"
                                id="customRadioInline1"
                                name="case-type"
                                ref={(f) => {
                                  this.caseType = f;
                                }}
                                className={`${notValidClasses.caseTypeCls} custom-control-input `}
                                required
                                onBlur={() => this.validationCheck2("caseType")}
                                onChange={(e) => this.radioButtonHandler(e)}
                                value="LiteII"
                                defaultChecked={
                                  this.state.caseType === "LiteII"
                                }
                                disabled={caseId ? true : false}
                              />
                              <label
                                className="custom-control-label title_active"
                                htmlFor="customRadioInline1"
                              >
                                Lite
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="customRadioInline2"
                                name="case-type"
                                ref={(f) => {
                                  this.caseType = f;
                                }}
                                className={`${notValidClasses.caseTypeCls} custom-control-input `}
                                required
                                onBlur={() => this.validationCheck2("caseType")}
                                onChange={(e) => this.radioButtonHandler(e)}
                                value="UnlimitedII"
                                defaultChecked={
                                  this.state.caseType === "UnlimitedII"
                                }
                                disabled={caseId ? true : false}
                              />
                              <label
                                className="custom-control-label title_active"
                                htmlFor="customRadioInline2"
                              >
                                Unlimited
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="customRadioInline3"
                                name="case-type"
                                ref={(f) => {
                                  this.caseType = f;
                                }}
                                className={`${notValidClasses.caseTypeCls} custom-control-input `}
                                required
                                onBlur={() => this.validationCheck2("caseType")}
                                onChange={(e) => this.radioButtonHandler(e)}
                                value="Lite Single Arch"
                                defaultChecked={
                                  this.state.caseType === "Lite Single Arch"
                                }
                                disabled={caseId ? true : false}
                              />
                              <label
                                className="custom-control-label title_active"
                                htmlFor="customRadioInline3"
                              >
                                Lite Single Arch
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="customRadioInline4"
                                name="case-type"
                                ref={(f) => {
                                  this.caseType = f;
                                }}
                                className={`${notValidClasses.caseTypeCls} custom-control-input `}
                                required
                                onBlur={() => this.validationCheck2("caseType")}
                                onChange={(e) => this.radioButtonHandler(e)}
                                value="Retainer"
                                defaultChecked={
                                  this.state.caseType === "Retainer"
                                }
                                disabled={caseId ? true : false}
                              />
                              <label
                                className="custom-control-label title_active"
                                htmlFor="customRadioInline4"
                              >
                                Retainer
                              </label>
                            </div>
                          </div>
                          <div className={notValidClasses.caseTypeValGrpCls}>
                            {this.state.caseTypeValMsg}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {/*End Case Types  */}
                  <Col xs="12" md="8" lg="8">
                    <Row>
                      {/* Wear Cycle  */}
                      <Col xs="12" md="12" lg="4">
                        <div className="form-group content form-block-holder">
                          <label className="control-label title_active">
                            Wear Cycle
                          </label>
                          <div>
                            <div
                              className={`patient-form ${
                                !this.state.validateWearCycle
                                  ? "not-valid-select"
                                  : ""
                              }`}
                            >
                              <Select
                                ref={(e) => {
                                  this.wearCycle = e;
                                }}
                                autoComplete="off"
                                required
                                onBlur={() =>
                                  this.validationCheck2("wearCycle")
                                }
                                defaultValue={{
                                  label: wearCycle[1].label,
                                  value: wearCycle[1].value,
                                }}
                                //value={this.state.wearCycle}
                                onChange={(e) => {
                                  this.setState({
                                    wearCycle: e,
                                    wear_cycle_value: e.value,
                                  });
                                }}
                                disabled={caseId ? true : false}
                                options={wearCycle}
                              ></Select>
                            </div>
                            <div className={notValidClasses.wearCycleValGrpCls}>
                              {this.state.wearCycleValMsg}
                            </div>
                          </div>
                        </div>
                      </Col>
                      {/*End Wear Cycle  */}
                      {/* Shipping Address  */}
                      <Col xs="12" md="12" lg="8">
                        {" "}
                        <div className="form-group content form-block-holder">
                          <label className="control-label title_active">
                            Shipping Address{" "}
                            
                            {this.state.addresses.length === 0 && (
                              <span
                                className=" pl-2"
                                style={{ fontSize: "12px", color: "red" }}
                              >
                                {` * Please add your Shipping `}
                                <span
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  className="title_active"
                                  onClick={() =>
                                    // this.props.props.history.push({
                                    //   pathname: "/profile",
                                    //   state: {
                                    //     activeTab: "4",
                                    //     id: this.props.props.userReducer
                                    //       .currentUser.id,
                                    //   },
                                    // })
                                    this.toggleAddressModal()
                                  }
                                >
                            {`Address`} 
                                </span>
                              </span>
                            )}
                          </label>
                          <div>
                            <div
                              className={`patient-form ${
                                !this.state.validateAddress
                                  ? "not-valid-select"
                                  : ""
                              }`}
                            >
                              <Select
                                // ref={(e) => {
                                //   this.shippingAddress = e;
                                // }}
                                value={this.state.shippingAddress}
                                autoComplete="off"
                                // className={`${notValidClasses.shippingAddressCls} `}
                                required
                                defaultValue={this.state.shippingAddress}
                                onChange={(e) => {
                                  this.shippingAddress = e;
                                  this.setState({ shippingAddress: e });
                                }}
                                onBlur={() =>
                                  this.validationCheck2("shippingAddress")
                                }
                                disabled={caseId ? true : false}
                                options={this.state.addresses}
                              ></Select>
                            </div>

                            <div
                              className={
                                notValidClasses.shippingAddressValGrpCls
                              }
                            >
                              {this.state.shippingAddressValMsg}
                            </div>
                          </div>
                        </div>
                      </Col>
                      {/*End Shipping Address  */}
                    </Row>
                  </Col>
                </Row>
                {/* Patient Name   */}
                <Row>
                  <Col xs="12" md="4" lg="4">
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        First Name
                      </label>
                      <div>
                        <input
                          // ref="first name"
                          ref={(f) => {
                            this.firstName = f;
                          }}
                          autoComplete="off"
                          type="text"
                          placeholder="Required Field"
                          className={`${notValidClasses.firstNameCls} green_input`}
                          required
                          defaultValue={this.state.firstName}
                          onBlur={() => this.validationCheck2("firstName")}
                          disabled={caseId ? true : false}
                        />
                        <div className={notValidClasses.firstNameValGrpCls}>
                          {this.state.firstNameValMsg}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" md="4" lg="4">
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Middle Name
                      </label>
                      <div>
                        <input
                          // ref="middle name"
                          ref={(f) => {
                            this.middleName = f;
                          }}
                          autoComplete="off"
                          type="text"
                          // placeholder="Required Field"
                          className={`${notValidClasses.middleNameCls} green_input`}
                          defaultValue={this.state.middleName}
                          onBlur={() => this.validationCheck2("middleName")}
                          disabled={caseId ? true : false}
                        />
                        <div className={notValidClasses.middleNameValGrpCls}>
                          {this.state.middleNameValMsg}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" md="4" lg="4">
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Last Name
                      </label>
                      <div>
                        <input
                          // ref="last name"
                          ref={(f) => {
                            this.lastName = f;
                          }}
                          autoComplete="off"
                          type="text"
                          placeholder="Required Field"
                          className={`${notValidClasses.lastNameCls} green_input`}
                          required
                          defaultValue={this.state.lastName}
                          onBlur={() => this.validationCheck2("lastName")}
                          disabled={caseId ? true : false}
                        />
                        <div className={notValidClasses.lastNameValGrpCls}>
                          {this.state.lastNameValMsg}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* End Patient Name   */}

                <Row>
                  {/* Gender  */}
                  <Col xs="12" md="4" lg="4">
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Gender
                      </label>
                      <div>
                        <div
                          className={`patient-form ${
                            !this.state.validateGender ? "not-valid-select" : ""
                          }`}
                        >
                          <Select
                            // ref={(e) => {
                            //   this.gender = e;
                            // }}
                            autoComplete="off"
                            required
                            onBlur={() => this.validationCheck2("gender")}
                            value={this.state.gender}
                            onChange={(e) => {
                              this.gender = e;
                              this.setState({ gender: e });
                            }}
                            disabled={caseId ? true : false}
                            options={gendar}
                          ></Select>
                        </div>

                        <div className={notValidClasses.genderValGrpCls}>
                          {this.state.genderValMsg}
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* End Gender  */}

                  {/* DOB  */}
                  <Col xs="12" md="4" lg="4">
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Date of Birth
                      </label>
                      <Row>
                        <Col xs="4" md="4" lg="4" className="pr-0">
                          <div>
                            <input
                              // ref="day"
                              ref={(f) => {
                                this.day = f;
                              }}
                              autoComplete="off"
                              type="number"
                              placeholder="Day"
                              className={`${notValidClasses.dayCls} green_input `}
                              required
                              defaultValue={this.state.day}
                              onBlur={() => this.validationCheck2("day")}
                              onKeyUp={() => {
                                if (
                                  this.day.value.length === 2 &&
                                  this.day.value > 0 &&
                                  this.day.value < 32
                                ) {
                                  this.month.focus();
                                } else {
                                  this.validationCheck2("day");
                                }
                              }}
                              disabled={caseId ? true : false}
                            />
                            <div className={notValidClasses.dayValGrpCls}>
                              {this.state.dayValMsg}
                            </div>
                          </div>
                        </Col>
                        <Col xs="4" md="4" lg="4" className="p-0">
                          <div>
                            <input
                              // ref="month"
                              ref={(f) => {
                                this.month = f;
                              }}
                              autoComplete="off"
                              type="number"
                              placeholder="Month"
                              className={`${notValidClasses.monthCls} border-l-r-none green_input`}
                              required
                              defaultValue={this.state.month}
                              onBlur={() => this.validationCheck2("month")}
                              onKeyUp={() => {
                                if (
                                  this.month.value.length === 2 &&
                                  this.month.value > 0 &&
                                  this.month.value < 13
                                ) {
                                  this.year.focus();
                                } else {
                                  this.validationCheck2("month");
                                }
                              }}
                              disabled={caseId ? true : false}
                            />
                            <div className={notValidClasses.monthValGrpCls}>
                              {this.state.monthValMsg}
                            </div>
                          </div>
                        </Col>
                        <Col xs="4" md="4" lg="4" className="pl-0">
                          <div>
                            <input
                              // ref="year"
                              ref={(f) => {
                                this.year = f;
                              }}
                              autoComplete="off"
                              type="number"
                              placeholder="Year"
                              className={`${notValidClasses.yearCls} green_input`}
                              required
                              defaultValue={this.state.year}
                              onBlur={() => this.validationCheck2("year")}
                              disabled={caseId ? true : false}
                            />
                            <div className={notValidClasses.yearValGrpCls}>
                              {this.state.yearValMsg}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {/*End DOB  */}

                  {/* Email  */}
                  <Col xs="12" md="4" lg="4">
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Email
                      </label>
                      <div>
                        <input
                          // ref="email"
                          ref={(f) => {
                            this.email = f;
                          }}
                          autoComplete="off"
                          type="email"
                          placeholder="Required Field"
                          className={`${notValidClasses.emailCls} green_input`}
                          required
                          defaultValue={this.state.email}
                          onBlur={() => this.validationCheck2("email")}
                          disabled={caseId ? true : false}
                        />
                        <div className={notValidClasses.emailValGrpCls}>
                          {this.state.emailValMsg}
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* End Email  */}
                </Row>
              </form>
            </div>
          </div>
        </div>
        {/*--------------------------------------------------------------------------------*/}
        {/*End Patient Info Form   */}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
