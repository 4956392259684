import { updateAlignerCase } from "../api/api";

export function updateTreatmentSummary(
  doctorId,
  summary_and_special_instructions_store,
  caseId,
  rejectable_id
) {
  let data = {
    aligner_case: {
      step: "summary_and_special_instructions",
      treatment_summary:
        summary_and_special_instructions_store.treatment_summary,
      special_instructions:
        summary_and_special_instructions_store.special_instructions,
    },
    ...(rejectable_id && { resolve_rejection_id: rejectable_id }),
  };

  return updateAlignerCase(doctorId, caseId, JSON.stringify(data)).then(
    (res) => {
      return true;
    }
  );
}
