import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { data } from "./data";
import Ellipse from "../../../assets/images/loyalty/Ellipse.svg";
import { ReactComponent as Group } from "../../../assets/images/loyalty/Group.svg";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});

class Benefits extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { content } = this.props.languageReducer;
    const { loyalty } = this.props.userReducer.currentUser;
    const loyaltyName = loyalty?.active_tier.name;
    

    let loyaltyData = data(content, loyaltyName);
  

    return (
      <div className="tableContaianer">
        <Table>
          <tbody>
            <tr className="borderBottom">
              <td>{content.discount}</td>
              <th className="centerization">{loyaltyData?.discount}</th>
              <td className="centerization">
                {loyaltyData?.discount_description}
              </td>
            </tr>

            <tr className="borderBottom">
              <td>{content.educational_events}</td>
              <td className="centerization">
                {loyaltyData?.educational_events === "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.educational_events === false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div></div>
                )}
              </td>
              <td className="centerization">
                {loyaltyData?.educational_events_description}
              </td>
            </tr>
            <tr className="borderBottom">
              <td>{content.digita_marketing_consultation}</td>
              <td className="centerization">
                {loyaltyData?.digita_marketing_consultation === "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.digita_marketing_consultation === false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div></div>
                )}
              </td>
              <td className="centerization">
                {loyaltyData?.digita_marketing_consultation_description ===
                false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <a
                    href={loyaltyData?.digita_marketing_link}
                    target="_blank"
                    className="booking"
                  >
                    {loyaltyData?.digita_marketing_consultation_description}
                  </a>
                )}
              </td>
            </tr>
            <tr className="borderBottom">
              <td>{content.advanced_digital_marketing_consultation}</td>
              <td className="centerization">
                {loyaltyData?.advanced_digital_marketing_consultation ===
                "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.advanced_digital_marketing_consultation_description ===
                  false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div></div>
                )}
              </td>
              <td className="centerization">
                {loyaltyData?.advanced_digital_marketing_consultation_description ===
                "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.advanced_digital_marketing_consultation_description ===
                  false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div>
                    <a
                      href={loyaltyData?.advanced_digital_marketing_link}
                      target="_blank"
                      className="booking"
                    >
                      {
                        loyaltyData?.advanced_digital_marketing_consultation_description
                      }
                    </a>
                  </div>
                )}
              </td>
            </tr>
            <tr className="borderBottom">
              <td>{content.expert_orthodontist_consultation}</td>
              <td className="centerization">
                {loyaltyData?.expert_orthodontist_consultation === "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.expert_orthodontist_consultation === false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div>{loyaltyData?.expert_orthodontist_consultation}</div>
                )}
              </td>
              <td className="centerization">
                {loyaltyData?.expert_orthodontist_consultation === "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.expert_orthodontist_consultation === false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <a
                    href={loyaltyData?.expert_orthodontist_consultation_link}
                    target="_blank"
                    className="booking"
                  >
                    {loyaltyData?.expert_orthodontist_consultation_description}
                  </a>
                )}
              </td>
            </tr>

            <tr className="borderBottom">
              <td>{content.commercial_business_consultation}</td>
              <td className="centerization">
                {loyaltyData?.commercial_business_consultation}
              </td>
              <td className="centerization">
                {" "}
                <a
                  href={loyaltyData?.commercial_business_consultation_link}
                  target="_blank"
                  className="booking"
                >
                  {loyaltyData?.commercial_business_consultation_description}
                </a>
              </td>
            </tr>
            <tr className="borderBottom">
              <td>{content.dedicated_clinical_specialist_CAD_designer}</td>
              <td className="centerization">
                {loyaltyData?.dedicated_clinical_specialist_CAD_designer ===
                "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.dedicated_clinical_specialist_CAD_designer ===
                  false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div></div>
                )}
              </td>
              <td className="centerization">
                {loyaltyData?.dedicated_clinical_specialist_CAD_designer_description ===
                "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.dedicated_clinical_specialist_CAD_designer_description ===
                  false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div>
                    {
                      loyaltyData?.dedicated_clinical_specialist_CAD_designer_description
                    }
                  </div>
                )}
              </td>
            </tr>
            <tr className="borderBottom">
              <td>{content.inclinic_marketing_kit}</td>
              <td className="centerization">
                {loyaltyData?.inclinic_marketing_kit === "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.inclinic_marketing_kit === false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div></div>
                )}
              </td>
              <td className="centerization">
                {loyaltyData?.inclinic_marketing_kit_description}{" "}
                <a
                  href={loyaltyData?.inclinic_marketing_kit_link}
                  target="_blank"
                  className="booking"
                >
                  {loyaltyData?.click_here}
                </a>
              </td>
            </tr>
            <tr>
              <td>{content.practicep_laque}</td>
              <td className="centerization">
                {loyaltyData?.practicep_laque === "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.practicep_laque === false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div></div>
                )}
              </td>
              <td className="centerization">
                {loyaltyData?.practicep_laque_description === "image" ? (
                  <Group className={loyaltyName} />
                ) : loyaltyData?.practicep_laque_description === false ? (
                  <img src={Ellipse} className="Gray Ellipse " alt="" />
                ) : (
                  <div>
                    {loyaltyData?.practicep_laque_description}{" "}
                    <a
                      href={loyaltyData?.practicep_laque_link}
                      target="_blank"
                      className="booking"
                    >
                      {loyaltyData?.click_here}
                    </a>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
