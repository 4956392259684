import React from "react";
import { Button, Image, Row } from "react-bootstrap";
import styles from "./style.module.css";

export const Category = ({ img, title, active, onClick }) => {
  return (
    <div className={styles.category} onClick={onClick}>
      <div
        className={[styles.imgGrid, active ? styles.selected : ""].join(" ")}
      >
        <Image src={img} alt="" loading="lazy" />
      </div>
      <p className={styles.title}>{title}</p>
    </div>
  );
};

export const CatgType = ({ img, title, onClick }) => {
  return (
    <div className={styles.catgType}>
      <Row className={styles.row}>
        <div className={styles.content}>
          <Image src={img} alt=""  className={styles.img} loading="lazy" />
          <p className={styles.title}>{title}</p>
        </div>
        <Button
          size="sm"
          variant="link"
          className={styles.btn}
          onClick={onClick}
        >
          Change
        </Button>
      </Row>
    </div>
  );
};

export const Question = ({ img, ans, desc, margin, active, onClick }) => {
  return (
    <div className={styles.question} style={{ margin }} onClick={onClick}>
      <div className={[styles.qsImg, active ? styles.selected : ""].join(" ")}>
      {
        img !== 'no_image' ? 
        <Image src={img} alt="" height="100%" width="100%" />
        :
        <div className={styles.descBox}>
          {desc}
        </div>

      }
      
      </div>
      <p className={styles.title}>{ans}</p>
    </div>
  );
};
