import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Case_Crowding,
  Case_ClassII,
  Case_ClassIII,
  Case_Crossbites,
  Case_Attachments,
  Case_Elastics,
  Case_Elastics_Buttons,
  Case_Elastics_Cuts,
  Case_Extraction,
  Case_IPR,
  Case_Overcorrections,
  Case_Passive_Aligners,
  Case_Pontics,
  Case_Tooth_Movement_Restrictions,
  Case_Torque_Enhancers,
  Case_Arch_Expansion,
  Doctor_Preferences_Attachments,
  Doctor_Preferences_IPR,
} from "../../redux/customeTeeth/action";
import { mapTeeth2 } from "../../services/mapTeeth2";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  Case_Tooth_Movement_Restrictions: (payload) =>
    dispatch(Case_Tooth_Movement_Restrictions(payload)),

  Case_Extraction: (payload) => dispatch(Case_Extraction(payload)),
  Case_Crowding: (payload) => dispatch(Case_Crowding(payload)),
  Case_Crossbites: (payload) => dispatch(Case_Crossbites(payload)),
  Case_ClassII: (payload) => dispatch(Case_ClassII(payload)),
  Case_ClassIII: (payload) => dispatch(Case_ClassIII(payload)),

  Case_Attachments: (payload) => dispatch(Case_Attachments(payload)),
  Case_Arch_Expansion: (payload) => dispatch(Case_Arch_Expansion(payload)),
  Case_IPR: (payload) => dispatch(Case_IPR(payload)),
  Case_Pontics: (payload) => dispatch(Case_Pontics(payload)),
  Case_Overcorrections: (payload) => dispatch(Case_Overcorrections(payload)),
  Case_Passive_Aligners: (payload) => dispatch(Case_Passive_Aligners(payload)),
  Case_Torque_Enhancers: (payload) => dispatch(Case_Torque_Enhancers(payload)),
  Case_Elastics: (payload) => dispatch(Case_Elastics(payload)),
  Case_Elastics_Buttons: (payload) => dispatch(Case_Elastics_Buttons(payload)),
  Case_Elastics_Cuts: (payload) => dispatch(Case_Elastics_Cuts(payload)),
  Doctor_Preferences_Attachments: (payload) =>
    dispatch(Doctor_Preferences_Attachments(payload)),
  Doctor_Preferences_IPR: (payload) =>
    dispatch(Doctor_Preferences_IPR(payload)),
});
class CustomeTeeth extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      upper_teeth:
        this.props.customeTeethReducer[this.props.action_key]["upper_teeth"],
      lower_teeth:
        this.props.customeTeethReducer[this.props.action_key]["lower_teeth"],
      show: false,
      showUpperRight :false,
      showLowerRight:false,

      showUpperLeft :false, 
      showLowerLeft:false,
    };
  }

  render() {
    const test = {
      lower_l_8: true,
      lower_l_7: true,
      lower_l_6: true,
      lower_l_5: true,
      lower_l_4: false,
      lower_l_3: false,
      lower_l_2: false,
      lower_l_1: false,

      lower_r_8: true,
      lower_r_7: true,
      lower_r_6: true,
      lower_r_5: true,
      lower_r_4: false,
      lower_r_3: false,
      lower_r_2: false,
      lower_r_1: false,

      upper_l_8: true,
      upper_l_7: true,
      upper_l_6: true,
      upper_l_5: true,
      upper_1_4: false,
      upper_1_3: false,
      upper_1_2: false,
      upper_1_1: false,

      upper_r_8: true,
      upper_r_7: true,
      upper_r_6: true,
      upper_r_5: true,
      upper_r_4: false,
      upper_r_3: false,
      upper_r_2: false,
      upper_r_1: false,
    };
    let upper_teeth =
      this.props.customeTeethReducer[this.props.action_key]["upper_teeth"];
    let lower_teeth =
      this.props.customeTeethReducer[this.props.action_key]["lower_teeth"];
 
    // const advanced_Ts = this.props.advanced_Ts || false
    return (
    
        <div className="teeth_container_grid">
          <div className={`text-center dir`}> R</div>
          <div className="side-teeth-grid">

            <div className="teeth-grid align-items-end ">
              {Object.keys(upper_teeth)
                .filter((value, index) => upper_teeth[value].key < 9)
                .map((prop, key1) => {
                  return (
                    <div className="teeth-selector-container-new">
                    <div
                      key={key1}
                      className={this.props.pontics && test[prop] && !this.props.summary  ? "ctooltipTop" : ""}
          
                    >
                      <img
                        className={`${
                          this.props.disabled ? "disabled-teeth" : ""
                        }`}
                        style={{
                          pointerEvents:
                            this.props.summary ||
                            (this.props.pontics && test[prop])
                              ? "none"
                              : "",
                          filter:
                            this.props.advanced_Ts ||
                            (this.props.pontics && test[prop])
                              ? upper_teeth[prop].value
                                ? "grayscale(100%)"
                                : "opacity(30%)"
                              : this.props.cuts && upper_teeth[prop].value
                              ? "invert(65%)"
                              : "",
                        }}
                        // className="teeth"
                        alt={prop}
                        src={upper_teeth[prop].image}
                        //onMouseOver
                        onMouseOver={(e) => {
                          // if tooth is active show active tooth on Mouse Over
                          // else show hovered tooth
                          e.currentTarget.src = upper_teeth[prop].value
                            ? upper_teeth[prop].active
                            : upper_teeth[prop].hover;
                        }}
                        //onMouseOut
                        onMouseOut={(e) => {
                          // if tooth is active show active tooth on Mouse Out
                          // else show not active tooth
                          e.currentTarget.src = upper_teeth[prop].value
                            ? upper_teeth[prop].active
                            : upper_teeth[prop].not_active;
                        }}
                        //onclick
                        onClick={(e) => {
                          // if tooth is active change value to not active and show not active tooth
                          // else change value to active and show active tooth
                          let newdata = !upper_teeth[prop].value;
                          let mapped_data = mapTeeth2({
                            upper_teeth: {
                              ...upper_teeth,
                              [prop]: {
                                ...upper_teeth[prop],
                                image: newdata
                                  ? upper_teeth[prop].active
                                  : upper_teeth[prop].not_active,
                                value: !upper_teeth[prop].value,
                              },
                            },
                            lower_teeth: lower_teeth,
                          });
                          // change values in reducer
                          this.props[this.props.action_key](mapped_data);

                          if (this.props?.action_key === "Case_Crossbites") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_ClassII") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_ClassIII") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_Crowding") {
                            this.props.UpdateCrossBiteReducer();
                          }
                        }}
                      ></img>
                              {this.props.pontics && test[prop] && !this.props.summary  && (
                      <span
                        class="tooltiptext top-right"
                      >
                      {this.props?.content?.you_cant_select_pontics_on_this_tooth}
                      </span>
                    )}
                    </div>
                    </div>
                  );
                })}
            </div>
            <div>
              {/*--------------------------------------------------------------------------------*/}
              {/* Plamer tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation ===
                "palmer" && (
                <div className={`teeth-grid text-center `}>
                  <div className="text-center">8</div>
                  <div className="text-center">7</div>
                  <div className="text-center">6</div>
                  <div className="text-center">5</div>
                  <div className="text-center">4</div>
                  <div className="text-center">3</div>
                  <div className="text-center">2</div>
                  <div className="text-center">1</div>
                </div>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Plamer tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* FDI tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation === "fdi" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">18</div>
                    <div className="text-center">17</div>
                    <div className="text-center">16</div>
                    <div className="text-center">15</div>
                    <div className="text-center">14</div>
                    <div className="text-center">13</div>
                    <div className="text-center">12</div>
                    <div className="text-center">11</div>
                  </div>
                  <hr className="divider"></hr>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">48</div>
                    <div className="text-center">47</div>
                    <div className="text-center">46</div>
                    <div className="text-center">45</div>
                    <div className="text-center">44</div>
                    <div className="text-center">43</div>
                    <div className="text-center">42</div>
                    <div className="text-center">41</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End FDI tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* Universal tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation ===
                "universal" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">1</div>
                    <div className="text-center">2</div>
                    <div className="text-center">3</div>
                    <div className="text-center">4</div>
                    <div className="text-center">5</div>
                    <div className="text-center">6</div>
                    <div className="text-center">7</div>
                    <div className="text-center">8</div>
                  </div>
                  <hr className="divider"></hr>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">32</div>
                    <div className="text-center">31</div>
                    <div className="text-center">30</div>
                    <div className="text-center">29</div>
                    <div className="text-center">28</div>
                    <div className="text-center">27</div>
                    <div className="text-center">26</div>
                    <div className="text-center">25</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Universal tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
            </div>



            <div className="teeth-grid align-items-start">
              {Object.keys(lower_teeth)
                .filter((value, index) => lower_teeth[value].key < 9)
                .map((prop, key2) => {
                  return (
                    <div        
                       key={key2}
                       className={this.props.pontics && test[prop] && !this.props.summary  ? "ctooltip" : ""}
                    
                    >
                      <img
                        className={this.props.disabled ? "disabled-teeth" : ""}
                        style={{
                          pointerEvents:
                            this.props.summary ||
                            (this.props.pontics && test[prop])
                              ? "none"
                              : "",

                          filter:
                            this.props.advanced_Ts ||
                            (this.props.pontics && test[prop])
                              ? lower_teeth[prop].value
                                ? "grayscale(100%)"
                                : "opacity(30%)"
                              : this.props.cuts && lower_teeth[prop].value
                              ? "invert(65%)"
                              : "",
                        }}
                        // className="teeth"
                        alt={prop}
                        src={lower_teeth[prop].image}
                        //onMouseOver
                        onMouseOver={(e) => {
                          e.currentTarget.src = lower_teeth[prop].value
                            ? lower_teeth[prop].active
                            : lower_teeth[prop].hover;
                        }}
                        //onMouseOut
                        onMouseOut={(e) => {
                          e.currentTarget.src = lower_teeth[prop].value
                            ? lower_teeth[prop].active
                            : lower_teeth[prop].not_active;
                        }}
                        //onClick
                        onClick={(e) => {
                          let newdata = !lower_teeth[prop].value;
                          let mapped_data = mapTeeth2({
                            upper_teeth: upper_teeth,
                            lower_teeth: {
                              ...lower_teeth,
                              [prop]: {
                                ...lower_teeth[prop],
                                image: newdata
                                  ? lower_teeth[prop].active
                                  : lower_teeth[prop].not_active,
                                value: !lower_teeth[prop].value,
                              },
                            },
                          });

                          this.props[this.props.action_key](mapped_data);
                          if (this.props?.action_key === "Case_Crossbites") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_ClassII") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_ClassIII") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_Crowding") {
                            this.props.UpdateCrossBiteReducer();
                          }
                        }}
                      ></img>
                            {this.props.pontics && test[prop] && !this.props.summary  &&  (
                      <span
                        class="tooltiptext lower-right"
                      >
                            {this.props?.content?.you_cant_select_pontics_on_this_tooth}
                      </span>
                    )}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="teeth_divider"></div>
          <div className="side-teeth-grid">
            <div className="teeth-grid align-items-end">
         
              {Object.keys(upper_teeth)
                .filter((value) => upper_teeth[value].key >= 9)
                .map((prop, key3) => {
                  return (
                    <div 
                    key={key3}
                    className={this.props.pontics && test[prop] && !this.props.summary  ? "ctooltipTop" : ""}>
                      <img
                        className={this.props.disabled ? "disabled-teeth" : ""}
                        style={{
                          pointerEvents:
                            this.props.summary ||
                            (this.props.pontics && test[prop])
                              ? "none"
                              : "",
                          // filter: this.props.cuts && upper_teeth[prop].value ? "invert(65%)" : ""
                          // filter: upper_teeth[prop].value ? "grayscale(100%)" : "opacity(30%)"
                          filter:
                            this.props.advanced_Ts ||
                            (this.props.pontics && test[prop])
                              ? upper_teeth[prop].value
                                ? "grayscale(100%)"
                                : "opacity(30%)"
                              : this.props.cuts && upper_teeth[prop].value
                              ? "invert(65%)"
                              : "",
                        }}
                        // className="teeth"
                        alt={prop}
                        src={upper_teeth[prop].image}
                        //onMouseOver
                        onMouseOver={(e) => {
                          // if tooth is active show active tooth on Mouse Over
                          // else show hovered tooth
                          e.currentTarget.src = upper_teeth[prop].value
                            ? upper_teeth[prop].active
                            : upper_teeth[prop].hover;
                        }}
                        //onMouseOut
                        onMouseOut={(e) => {
                          // if tooth is active show active tooth on Mouse Out
                          // else show not active tooth
                          e.currentTarget.src = upper_teeth[prop].value
                            ? upper_teeth[prop].active
                            : upper_teeth[prop].not_active;
                        }}
                        //onclick
                        onClick={(e) => {
                          // if tooth is active change value to not active and show not active tooth
                          // else change value to active and show active tooth
                          let newdata = !upper_teeth[prop].value;
                          let mapped_data = mapTeeth2({
                            upper_teeth: {
                              ...upper_teeth,
                              [prop]: {
                                ...upper_teeth[prop],
                                image: newdata
                                  ? upper_teeth[prop].active
                                  : upper_teeth[prop].not_active,
                                value: !upper_teeth[prop].value,
                              },
                            },
                            lower_teeth: lower_teeth,
                          });
                          // change values in reducer
                          this.props[this.props.action_key](mapped_data);

                          if (this.props?.action_key === "Case_Crossbites") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_ClassII") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_ClassIII") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_Crowding") {
                            this.props.UpdateCrossBiteReducer();
                          }
                        }}
                      ></img>
                      {this.props.pontics && test[prop]  && !this.props.summary  && (
                      <span
                        class="tooltiptext top-left"
                        
                        
                      >
                           {this.props?.content?.you_cant_select_pontics_on_this_tooth}
                      </span>
                    )}
                      
                    </div>
                  );
                })}
            </div>
            <div>
              {/*--------------------------------------------------------------------------------*/}
              {/* Plamer tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation ===
                "palmer" && (
                <div className={`teeth-grid text-center `}>
                  <div className="text-center">1</div>
                  <div className="text-center">2</div>
                  <div className="text-center">3</div>
                  <div className="text-center">4</div>
                  <div className="text-center">5</div>
                  <div className="text-center">6</div>
                  <div className="text-center">7</div>
                  <div className="text-center">8</div>
                </div>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Plamer tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* FDI tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation === "fdi" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">21</div>
                    <div className="text-center">22</div>
                    <div className="text-center">23</div>
                    <div className="text-center">24</div>
                    <div className="text-center">25</div>
                    <div className="text-center">26</div>
                    <div className="text-center">27</div>
                    <div className="text-center">28</div>
                  </div>
                  <hr className="divider"></hr>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">31</div>
                    <div className="text-center">32</div>
                    <div className="text-center">33</div>
                    <div className="text-center">34</div>
                    <div className="text-center">35</div>
                    <div className="text-center">36</div>
                    <div className="text-center">37</div>
                    <div className="text-center">38</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End FDI tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* Universal tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.userReducer.currentUser.dental_notation ===
                "universal" && (
                <>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">9</div>
                    <div className="text-center">10</div>
                    <div className="text-center">11</div>
                    <div className="text-center">12</div>
                    <div className="text-center">13</div>
                    <div className="text-center">14</div>
                    <div className="text-center">15</div>
                    <div className="text-center">16</div>
                  </div>
                  <hr className="divider"></hr>
                  <div className={`teeth-grid text-center `}>
                    <div className="text-center">24</div>
                    <div className="text-center">23</div>
                    <div className="text-center">22</div>
                    <div className="text-center">21</div>
                    <div className="text-center">20</div>
                    <div className="text-center">19</div>
                    <div className="text-center">18</div>
                    <div className="text-center">17</div>
                  </div>
                </>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Universal tooth numbering system  */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
            </div>
    


            <div className="teeth-grid align-items-start">
              {Object.keys(lower_teeth)
                .filter((value, index) => lower_teeth[value].key >= 9)
                .map((prop, key4) => {
                  return (
                    <div
                    key={key4}

                 
                    className={this.props.pontics && test[prop] && !this.props.summary  ? "ctooltip" : ""}
                  >
                      <img
                        className={this.props.disabled ? "disabled-teeth" : ""}
                        style={{
                          pointerEvents:
                            this.props.summary ||
                            (this.props.pontics && test[prop])
                              ? "none"
                              : "",
                          // filter: this.props.cuts && lower_teeth[prop].value ? "invert(65%)" : ""
                          // filter: lower_teeth[prop].value? "grayscale(100%)" : "opacity(30%)"

                          filter:
                            this.props.advanced_Ts ||
                            (this.props.pontics && test[prop])
                              ? lower_teeth[prop].value
                                ? "grayscale(100%)"
                                : "opacity(30%)"
                              : this.props.cuts && lower_teeth[prop].value
                              ? "invert(65%)"
                              : "",
                        }}
                        // className="teeth"
                        alt={prop}
                        src={lower_teeth[prop].image}
                        //onMouseOver
                        onMouseOver={(e) => {
                 
                          e.currentTarget.src = lower_teeth[prop].value
                            ? lower_teeth[prop].active
                            : lower_teeth[prop].hover;
                        }}
                        //onMouseOut
                        onMouseOut={(e) => {
;
                          e.currentTarget.src = lower_teeth[prop].value
                            ? lower_teeth[prop].active
                            : lower_teeth[prop].not_active;
                      
                        }}
                        //onClick
                        onClick={(e) => {
                          let newdata = !lower_teeth[prop].value;
                          let mapped_data = mapTeeth2({
                            upper_teeth: upper_teeth,
                            lower_teeth: {
                              ...lower_teeth,
                              [prop]: {
                                ...lower_teeth[prop],
                                image: newdata
                                  ? lower_teeth[prop].active
                                  : lower_teeth[prop].not_active,
                                value: !lower_teeth[prop].value,
                              },
                            },
                          });

                          this.props[this.props.action_key](mapped_data);
                          if (this.props?.action_key === "Case_Crossbites") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_ClassII") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_ClassIII") {
                            this.props.UpdateCrossBiteReducer();
                          }
                          if (this.props?.action_key === "Case_Crowding") {
                            this.props.UpdateCrossBiteReducer();
                          }
                        }}
                      ></img>
                      
                    { this.props.pontics && test[prop]  && !this.props.summary  && (
                      <span
                        class="tooltiptext lower-left"
                       
                      >
                         {this.props?.content?.you_cant_select_pontics_on_this_tooth}
                      </span>
                    )}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={`text-center dir `}>L</div>
        </div>
  
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomeTeeth);
