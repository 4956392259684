import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  row: {
    paddingTop: 10,
    alignItems: "center",
    paddingBottom:10,

  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    width: "100%",
  },
  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#E6E6FA",
    paddingBottom: 15,
    paddingTop: 5,
  },
  field: {
    flexDirection: "row",
    paddingTop: 10,
  },
  col: {
    flexDirection: "column",
    width: "50%",
  },
  title: {
    fontSize: 10,
    textAlign: "left",
    color: "#00c7b1",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
});
export default class DoctorInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctor: props.doctorInfo,
      caseInfo: props.case,
    };
  }
  render() {
    const { doctor, caseInfo } = this.state;
    const { content } = this.props

    return (
      <Fragment>
        <View style={styles.row}>
          <Text style={styles.header}> {content.doctor_information}</Text>
          <View style={styles.container}>
            <View style={styles.col}>
              <View style={styles.field}>
                <Text style={styles.title}>{content.name}: </Text>
                <Text style={styles.info}>{doctor.full_name}</Text>
              </View>
              <View style={styles.field}>
                <Text style={styles.title}>{content.phone} : </Text>
                <Text style={styles.info}>{doctor.phone}</Text>
              </View>
            </View>
            <View style={styles.col}>
              <View style={styles.field}>
                <Text style={styles.title}>{content.email} : </Text>
                <Text style={styles.info}>{doctor.email}</Text>
              </View>
              <View style={styles.field}>
                <Text style={styles.title}>{content.case_num} : </Text>
                <Text style={styles.info}>{caseInfo.uid}</Text>
              </View>
            </View>
          </View>
        </View>
      </Fragment>
    );
  }
}
