import React, { Component } from "react";
import { connect } from "react-redux";

import { Button } from "reactstrap";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class ArchExpansion extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      increaseCaninesPremolarsMolars: this.props.caseSubmission.advanced_modules
        .archExpansion.increaseCaninesPremolarsMolars,
      increaseCaninesPremolars: this.props.caseSubmission.advanced_modules
        .archExpansion.increaseCaninesPremolars,
      increasePremolarsMolars: this.props.caseSubmission.advanced_modules
        .archExpansion.increasePremolarsMolars,
      use_arch_expansion: this.props.caseSubmission.advanced_modules
        .archExpansion.use_arch_expansion,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "archExpansion",
      data: {
        use_arch_expansion: this.state.use_arch_expansion,
        increaseCaninesPremolarsMolars: this.state
          .increaseCaninesPremolarsMolars,
        increaseCaninesPremolars: this.state.increaseCaninesPremolars,
        increasePremolarsMolars: this.state.increasePremolarsMolars,
        // teeth:
        //   this.props.customeTeethReducer.Case_Arch_Expansion
        // ,
      },
    });
    this.props.save();
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  clearState = () => {
    this.setState({
      increaseCaninesPremolarsMolars: false,
      increaseCaninesPremolars: false,
      increasePremolarsMolars: false,
    })
  }
  render() {
    const {
      use_arch_expansion,
    } = this.state;
    const { content } = this.props.languageReducer

    return (
      <div>
        <div className="templete_module_grid">

          <div className="text-left">
            <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.use_arch_expansion_for_this_case}{"? "}
            </div>

            <TreatmentOptions content={content} value={use_arch_expansion} name="use_arch_expansion" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_arch_expansion")
              if (value !== true) {
                //this.props.Clear_Teeth_Data("Case_Arch_Expansion")
                this.clearState()
                window.scrollTo(0, 0)

                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>
          <div className="divider_module-middle">
          </div>
          <div id="content_container_3"
            className={`${use_arch_expansion !== true ? "c-content-container8" : "c-content-container "} tooth-extraction  cpb2 `}>
            <div className="control-label text-left  light-green title-3-text">
              {content.select_the_area_where_you_want_arch_expansion}
            </div>
            <div className="arch_expansion_grid pb-2">
              <div className="spaces_pickup_inner ">
                <div className="radio-item text_malocclusions "
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}>
                  <input type="radio"
                    id='Increasing_the_arch_width_between_canines_premolars_and_molars'
                    className="button-text"
                    name='Increasing_the_arch_width'
                    style={{ pointerEvents: this.props.summary ? "none" : "" }}
                    disabled={this.props.summary}
                    value={"Increasing_the_arch_width_between_canines_premolars_and_molars"}
                    checked={this.state.increaseCaninesPremolarsMolars}
                    onChange={() => {
                      this.setState({
                        increaseCaninesPremolarsMolars: true,
                        increaseCaninesPremolars: false,
                        increasePremolarsMolars: false,
                      });
                    }}
                  />
                  <label htmlFor='Increasing_the_arch_width_between_canines_premolars_and_molars' className="button-text">{content.increasing_the_arch_width_between_canines_remolars_and_molars}</label>
                </div>
              </div>
              <div className="spaces_pickup_inner ">
                <div className="radio-item text_malocclusions "
                  style={{ pointerEvents: this.props.summary ? "none" : "" }} >
                  <input type="radio"
                    className="button-text"
                    id='increasing_the_arch_width_between_canines_premolars_only'
                    name='Increasing_the_arch_width'
                    style={{ pointerEvents: this.props.summary ? "none" : "" }}
                    disabled={this.props.summary}
                    checked={this.state.increaseCaninesPremolars}
                    value={"increasing_the_arch_width_between_canines_premolars_only"}
                    onChange={() => {
                      this.setState({
                        increaseCaninesPremolarsMolars: false,
                        increaseCaninesPremolars: true,
                        increasePremolarsMolars: false,
                      });
                    }}
                  />
                  <label htmlFor='increasing_the_arch_width_between_canines_premolars_only' className="button-text">{content.increasing_the_arch_width_between_canines_premolars_only}</label>
                </div>
              </div>
              <div className="spaces_pickup_inner ">
                <div className="radio-item text_malocclusions"
                  style={{ pointerEvents: this.props.summary ? "none" : "" }} >
                  <input type="radio"
                    id='Increasing_the_arch_width_between_premolars_and_molars_only'
                    name='Increasing_the_arch_width'
                    style={{ pointerEvents: this.props.summary ? "none" : "" }}
                    disabled={this.props.summary}
                    value={"Increasing_the_arch_width_between_premolars_and_molars_only"}
                    checked={this.state.increasePremolarsMolars}
                    onChange={() => {
                      this.setState({
                        increaseCaninesPremolarsMolars: false,
                        increaseCaninesPremolars: false,
                        increasePremolarsMolars: true,
                      });
                    }}
                  />
                  <label htmlFor='Increasing_the_arch_width_between_premolars_and_molars_only' className="button-text">{content.increasing_the_arch_width_between_premolars_and_molars_only}</label>
                </div>
              </div>
            </div>
            <div className={`${(this.state.use_arch_expansion === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_arch_expansion === "eonToDecide") ? `Eon_to_recommend_eon Eon_to_recommend_layout` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>
              <p className={`${(this.state.use_arch_expansion === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Arch_expansion_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.use_arch_expansion === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
            </div>
            {!this.props.summary && this.state.use_arch_expansion === true && <div className="btn-adv-mobile displayNone_inmobile margin-top2"><Button
              className="mt-2  mobile-but-adv light-green-btn float-right  button-seme-bold-text"
              size="lg"
              type="button"
              onClick={() => this.UpdateAdvancedModules()}
            >
              {content.save}
            </Button>
            </div>}
          </div>
        </div>
        {!this.props.summary && !(this.state.use_arch_expansion === true && window.innerWidth < 839) && <div className="btn-adv-mobile"><Button
          className="mt-2  mobile-but-adv light-green-btn float-right  button-seme-bold-text"
          size="lg"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          {content.save}
        </Button>
        </div>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArchExpansion);
