import React from "react";
import { connect } from "react-redux";
import {
  Nav,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Navbar,
  NavbarBrand,
  Collapse,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import male from "../../../assets/images/icon/avatar-male.svg";
import eonAccessLogo from "../../../assets/images/eon-access-logo.svg";
import eonAccessLogoLight from "../../../assets/images/eon-access-logo-light.svg";
import hamburger from "../../../assets/images/icon/Hamburger.svg";
import notifications_icon from "../../../assets/images/icon/notification.svg";
import settings_icon from "../../../assets/images/icon/settings.svg";
import logout_icon from "../../../assets/images/icon/logout.svg";
import { authenticationService } from "../../../jwt/_services";
import { Redirect } from "react-router-dom";
import {
  getNotifications,
  markNotificationsAsRead,
  markOneActionItemsAsRead,
} from "../../../api/api.js";
import moment from "moment";
import CustomeHeader from "../../../components/header/CustomeHeader";
import { Set_Submission_Side_Nav } from "../../../redux/CaseSubmission/action";
import white_info_icon from "../../../assets/images/dashboard/white_info_icon.svg";



const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  Set_Submission_Side_Nav: (payload) =>
    dispatch(Set_Submission_Side_Nav(payload)),
});
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      doctor: {},
      doctorInfo: {},
      Notifications: [],
      Notifications_id: 0,
      toggleNotification: false,
      logout: false,
      logingout: false,
      cancelSubmission: false,
      width: window.innerWidth,
    };
    
  }

  /*--------------------------------------------------------------------------------*/
  /* resize screen after render all element   */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
    const user_data = this.props.userReducer.currentUser
    const data = {
      //Email: user_data.email,
      //Name: user_data.full_name,
      // "Preferred Language": user_data.preferred_language,
      // "Preferred Dental Notation": res.dental_notation,
      // "Active/ Current Loyalty Tier": `${
      //   res?.loyalty?.active_tier?.name || ""
      // } / ${res?.loyalty?.current_tier?.name || ""}`,
      Country: user_data.country,
      // "Block Case submission": res?.block_case_submission + "",
      // "Terms Approval": res?.terms_approval + "",
    };
    window.userGuiding.identify(user_data.id, {
      ...data,
    });
  }
  UNSAFE_componentWillMount() {
    getNotifications(this.props.userReducer.currentUser.id).then((res) => {
      let FilteredNotification = res.action_items.filter((key, index) => {
        return key.actionable_type !== "RestorativeCase";
      });
      FilteredNotification.sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1));
      this.setState({
        Notifications: FilteredNotification,
      });
    });
  }

  MarkNotificationsAsRead = () => {
    markNotificationsAsRead(this.props.userReducer.currentUser.id).then(
      (res) => {
        this.setState({
          Notifications: [],
        });
      }
    );
  };
  /*--------------------------------------------------------------------------------*/
  /*To open Search Bar                                                              */
  /*--------------------------------------------------------------------------------*/
  toggleMenu = () => {
    document.getElementById("search").classList.toggle("show-search");
  };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  showMobilemenu = () => {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  };
  sidebarHandler = () => {
    let element = document.getElementById("main-wrapper");
    switch (this.props.settings.activeSidebarType) {
      case "full":
      case "iconbar":
        element.classList.toggle("mini-sidebar");
        if (element.classList.contains("mini-sidebar")) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
        }
        break;

      case "overlay":
      case "mini-sidebar":
        element.classList.toggle("full");
        if (element.classList.contains("full")) {
          element.setAttribute("data-sidebartype", "full");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
        }
        break;

      default:
    }
  };

  KeyDictionary(key) {
    return key.split(".")[1].split("_").join(" ");
  }
  ToggleNotifaction = () => {
    this.setState({ toggleNotification: !this.state.toggleNotification });
  };
  toggleLogoutModal = () => {
    this.setState({ logout: !this.state.logout });
  };
  toggleCancelConfirmation = () => {
    this.setState({ cancelSubmission: !this.state.cancelSubmission });
  };

  /*--------------------------------------------------------------------------------*/
  /* update Dimensions    */
  /*--------------------------------------------------------------------------------*/
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  /*--------------------------------------------------------------------------------*/
  /*Mark  One Action Items As Read */
  /*--------------------------------------------------------------------------------*/
  MarkOneActionItemsAsRead = async (doctor_ID, notificationId, notificationCase) => {
   await  markOneActionItemsAsRead(doctor_ID, notificationId).then( () => {
      getNotifications(this.props.userReducer.currentUser.id).then((res) => {
        let FilteredNotification = res.action_items.filter((key, index) => {
          return key.actionable_type !== "RestorativeCase";
        });
        FilteredNotification.sort((a, b) =>
          new Date(a) < new Date(b) ? 1 : -1
        );
        this.setState({
          Notifications: FilteredNotification,
        });
      });
     
    })
    .catch( (error) => {
      console.log(error,"error")
    })

    window.location.href = `/case/${notificationCase}`;

  };

  /*--------------------------------------------------------------------------------*/
  /* remove event listener whin leave component                                     */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { content } = this.props.languageReducer;
    const { block_case_submission } = this.props.userReducer.currentUser;
    const { width, Notifications } = this.state;
    const doctor_ID = this.props.userReducer.currentUser.id;
    let counter_Notifications = Notifications.filter((value, index) => {
      return value.read === false;
    });


   

    if (this.state.setting) {
      return (
        <Redirect
          to={{
            pathname: "/settings",
            state: {
              activeTab: "3",
              id: this.props.userReducer.currentUser.id,
            },
          }}
        />
      );
    } else if (this.state.profile) {
      return (
        <Redirect
          to={{
            pathname: "/settings",
            state: {
              activeTab: "1",
              id: this.props.userReducer.currentUser.id,
            },
          }}
        />
      );
    }
    return (
      <header
        className="topbar navbarbg"
        data-navbarbg={this.props.settings.activeNavbarBg}
      >
        {/*--------------------------------------------------------------------------------*/}
        {/* Cancel Submission modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.cancelSubmission}
          toggle={this.toggleCancelConfirmation}
          className="c-modal cancel-mobile confarim-modal"
        >
          {/* <ModalHeader toggle={this.toggleCancelConfirmation}></ModalHeader> */}
          <ModalBody>
            <CustomeHeader title={content.cancel_submission} largeFont={true} withoutLine={true}/>

            <div className="form-group content form-block-holder h-100">
              <label className="mt-3 mb-1 control-label headline-text">
                {content.confirmation_cancel_submission}
              </label>

              <div className=" confirmation-model-grid cmt2">
              <div className="displayNone_inmobile">
              <Button
                  className="btn light-green-btn pull-right cml1 button-seme-bold-text "
                  size="md"
                  disabled={this.state.completingCase}
                  onClick={() => (window.location.href = "/home")}
                >
                  {content.yes}
                </Button>
                </div>
                <Button
                  className=" btn white-btn pull-right addres-delete-margin button-seme-bold-text"
                  size="md"
                  color="danger"
                  disabled={this.state.completingCase}
                  onClick={() => this.toggleCancelConfirmation()}
                >
                  {content.no}
                </Button>
                <div className="displayNone_website">
                <Button
                  className="btn light-green-btn pull-right addres-delete-margin button-seme-bold-text"
                  size="md"
                  disabled={this.state.completingCase}
                  onClick={() => (window.location.href = "/home")}
                >
                  {content.yes}
                </Button>
                </div>
                {/* <Button
                  className=" btn white-btn pull-right cml1"
                  size="md"
                  color="danger"
                  disabled={this.state.completingCase}
                  onClick={() => this.toggleCancelConfirmation()}
                >
                  {content.no}
                </Button> */}
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Cancel Submission modal */}
        {/*--------------------------------------------------------------------------------*/}

        <Navbar
          className={
            "top-navbar " +
            (this.props.settings.activeNavbarBg === "skin6"
              ? "navbar-light"
              : "navbar-dark")
          }
          expand="md"
        >
          <div
            className="navbar-header"
            id="logobg"
            data-logobg={this.props.settings.activeLogoBg}
          >
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}
            {/* <span
              className="text-white nav-toggler d-block d-md-none"
              onClick={this.showMobilemenu}
            >
              <i className="ti-menu ti-close" />
            </span> */}
            {/*--------------------------------------------------------------------------------*/}
            {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
            {/*--------------------------------------------------------------------------------*/}
            <NavbarBrand
              className={`${
                (window.location.pathname === "/case-submission" ||
                  window.location.pathname === "/retainer" ||
                  window.location.pathname === "/case-submission/") &&
                !this.props.caseSubmission.hideSubmissionSideNav
                  ? "navbar-header-light"
                  : ""
              } `}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (
                  (window.location.pathname === "/case-submission" ||
                    window.location.pathname === "/retainer" ||
                    window.location.pathname === "/case-submission/") &&
                  !window.location.search
                ) {
                  this.toggleCancelConfirmation();
                } else {
                  window.location.href = "/home";
                }
              }}
            >
              <b className="logo-icon">
                <img
                  src={
                    (window.location.pathname === "/case-submission" ||
                      window.location.pathname === "/retainer" ||
                      window.location.pathname === "/case-submission/") &&
                    !this.props.caseSubmission.hideSubmissionSideNav
                      ? eonAccessLogoLight
                      : eonAccessLogo
                  }
                  alt="homepage"
                  className="dark-logo"
                  height="54"
                  width="181"
                />
              </b>

              {/* <span className="logo-text">
                <img src={logodarktext} alt="homepage" className="dark-logo" />
                <img
                  src={logolighttext}
                  className="light-logo"
                  alt="homepage"
                />
              </span> */}
            </NavbarBrand>
            <Nav
              className={`${
                width < 768
                  ? "ml-auto  align-items-center"
                  : "ml-auto float-right  align-items-center"
              } d-md-none`}
              navbar
            >
              {width < 768 && (
                <div className="notifacation-container-inMobile">
                  <UncontrolledDropdown
                    isOpen={this.state.toggleNotification}
                    toggle={this.ToggleNotifaction}
                  >
                    <DropdownToggle nav caret>
                      <div className="rounded-circle">
                        <img
                          src={notifications_icon}
                          className=""
                          alt="notifications_icon"
                        />
                      
                      
                        {counter_Notifications.length > 0  ? (
                            <div className="notification-num ">
                              {counter_Notifications.length}
                            </div>
                          ):<div></div>}
                      </div>
                    </DropdownToggle>
                    <DropdownMenu right className="mailbox">
                      <div className="notification-triangle"></div>
                      <div className="cmb2">
                        <h6
                          className="mb-0 font-medium white-text"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            //window.location.href = "/notifications";
                            this.props.data.history.push({
                              pathname: "/notifications",
                              state: {
                                data: this.state.Notifications,
                                id: this.props.userReducer.currentUser.id,
                              },
                            });
                            this.ToggleNotifaction();
                          }}
                        >
                          {content.notifications}{" "}
                          {this.state.Notifications.length} 
                        </h6>
                      </div>
                      <div className="message-center notifications cpr1">
                        {/*<!-- Message -->*/}

                        {this.state.Notifications.map((notification, index) => {
                          return (
                            <div
                              className="notification-card"
                              key={index}
                              onClick={() => {
                                // this.props.data.history.push(
                                //   `/case/${notification.case}`
                                // );
                                this.MarkOneActionItemsAsRead(
                                  doctor_ID,
                                  notification.id,
                                  notification.case
                                );
                          
                              }}
                            >
                              <div className="notification-card-title">
                                <div className={`${
                                        notification.read === true
                                          ? "circle-gray"
                                          : "circle"
                                      }`}>
                                  <div className="inner-circle"></div>
                                </div>
                                <div className={`${
                                        notification.read === true
                                          ? "notification-status-gray"
                                          : "notification-status"
                                      }`}>
                                  {this.KeyDictionary(notification.key)}
                                </div>
                                <div className="dot">.</div>
                                <div className="notification-date">
                                  {`${moment(notification.created_at).format(
                                    "DD MMM YYYY"
                                  )}`}
                                </div>
                              </div>

                              <div>
                                {content.case} {notification.case_uid}
                              </div>
                              <div>
                                {` ${
                                  notification.patient &&
                                  ` ${notification.patient.first_name}  ${notification.patient.last_name}`
                                }  `}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className={
                          // this.state.Notifications.length !== 0
                          // ?
                          `see-all cmt2 white-text`
                          // : `see-all cmt2 white-text disable_notifications`
                        }
                        onClick={() => {
                          this.props.data.history.push({
                            pathname: "/notifications",
                            state: {
                              data: this.state.Notifications,
                              id: this.props.userReducer.currentUser.id,
                            },
                          });
                          this.ToggleNotifaction();
                        }}
                      >
                        {content.see_all_notifications}
                        <i className="cpl1 fa fa-angle-right" />
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </Nav>
            {(window.location.pathname === "/case-submission" ||
              window.location.pathname === "/retainer" ||
              window.location.pathname === "/case-submission/") && (
              <img
                src={hamburger}
                alt="hamburger"
                className="hamburger-mobile"
                onClick={() => {
                  this.props.Set_Submission_Side_Nav(
                    !this.props.caseSubmission.hideSubmissionSideNav
                  );
                }}
              />
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}

            <span
              className="text-white topbartoggler d-block d-md-none"
              onClick={this.toggle}
            >
              <i className="ti-more" />
            </span>
          </div>
          <Collapse
            className="navbarbg"
            isOpen={this.state.isOpen}
            navbar
            data-navbarbg={this.props.settings.activeNavbarBg}
          >
            {(window.location.pathname === "/case-submission" ||
              window.location.pathname === "/retainer" ||
              window.location.pathname === "/case-submission/") && (
              <Nav className="float-left cml3" navbar>
                <NavItem>
                  <NavLink
                    href="#"
                    className="d-none d-md-block"
                    onClick={() => {
                      this.props.Set_Submission_Side_Nav(
                        !this.props.caseSubmission.hideSubmissionSideNav
                      );
                    }}
                  >
                    <img src={hamburger} alt="hamburger" />
                  </NavLink>
                </NavItem>
              </Nav>
            )}
            <span className="navbar-title">
              {content.patient_management_portal}
            </span>

            <div className="nav-grid">
              <div>
                <Nav
                  className={`${
                    width < 768
                      ? "ml-auto  align-items-center"
                      : "ml-auto float-right  align-items-center"
                  }`}
                  navbar
                >
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Start Notifications Dropdown                                                   */}
                  {/*--------------------------------------------------------------------------------*/}

                  {width > 767 && (
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      isOpen={this.state.toggleNotification}
                      toggle={this.ToggleNotifaction}
                      className="notifacation-container-desktop"
                    >
                      <DropdownToggle nav caret>
                        <div className="rounded-circle">
                          <img
                            src={notifications_icon}
                            className=""
                            alt="notifications_icon"
                          />
                      
                          { counter_Notifications.length > 0  ? (
                            <div className="notification-num ">
                              {counter_Notifications.length}
                            </div>
                          ):<div></div>}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu right className="mailbox">
                        <div className="notification-triangle"></div>
                        <div className="cmb2">
                          <h6
                            className="mb-0 font-medium white-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              //window.location.href = "/notifications";
                              this.props.data.history.push({
                                pathname: "/notifications",
                                state: {
                                  data: this.state.Notifications,
                                  id: this.props.userReducer.currentUser.id,
                                },
                              });
                              this.ToggleNotifaction();
                            }}
                          >
                            {content.notifications}{" "}
                            {this.state.Notifications.length}  
                          </h6>
                        </div>
                        <div className="message-center notifications cpr1">
                          {/*<!-- Message -->*/}
                          {this.state.Notifications.map(
                            (notification, index) => {
                              return (
                                <div
                                  className="notification-card"
                                  key={index}
                                  onClick={() => {
                                    // this.props.data.history.push(
                                    //   `/case/${notification.case}`
                                    // );

                                    this.MarkOneActionItemsAsRead(
                                      doctor_ID,
                                      notification.id,
                                      notification.case
                                    );


                                  }}
                                >
                                  <div className="notification-card-title">
                                    <div
                                      className={`${
                                        notification.read === true
                                          ? "circle-gray"
                                          : "circle"
                                      }`}
                                    >
                                      <div className="inner-circle"></div>
                                    </div>

                                    <div
                                      className={`${
                                        notification.read === true
                                          ? "notification-status-gray"
                                          : "notification-status"
                                      }`}
                                    >
                                      {this.KeyDictionary(notification.key)}
                                    </div>
                                    <div className="dot">.</div>
                                    <div className="notification-date">
                                      {`${moment(
                                        notification.created_at
                                      ).format("DD MMM YYYY")}`}
                                    </div>
                                  </div>

                                  <div>
                                    {content.case} {notification.case_uid}
                                  </div>
                                  <div>
                                    {` ${
                                      notification.patient &&
                                      ` ${notification.patient.first_name}  ${notification.patient.last_name}`
                                    }  `}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div
                          className={
                            // this.state.Notifications.length !== 0
                            // ?
                            `see-all cmt2 white-text`
                            // : `see-all cmt2 white-text disable_notifications`
                          }
                          onClick={() => {
                            this.props.data.history.push({
                              pathname: "/notifications",
                              state: {
                                data: this.state.Notifications,
                                id: this.props.userReducer.currentUser.id,
                                content : this.props.languageReducer
                              },
                            });

                            this.ToggleNotifaction();
                          }}
                        >
                          {content.see_all_notifications}
                          <i className="cpl1 fa fa-angle-right" />
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}

                  {/*--------------------------------------------------------------------------------*/}
                  {/* End Notifications Dropdown                                                     */}
                  {/*--------------------------------------------------------------------------------*/}
                  <UncontrolledDropdown
                    nav
                    inNavbar
                    onClick={() => {
                      window.location.href = "/settings";
                      // this.setState({
                      //   setting: true,
                      // });
                    }}
                    title={content.setting}
                  >
                    <DropdownToggle nav caret>
                      <div className="rounded-circle">
                        <img
                          src={settings_icon}
                          className=""
                          alt="settings_icon"
                        />
                      </div>
                    </DropdownToggle>
                    {/* <i className="pl-1 pr-1 ti-settings setting-icon headers-icons" title="setting" /> */}
                  </UncontrolledDropdown>
                  <UncontrolledDropdown
                    nav
                    inNavbar
                    onClick={() => {
                      this.toggleLogoutModal();
                      // authenticationService.logout();
                    }}
                    className=""
                    title={content.logout}
                  >
                    <DropdownToggle nav caret>
                      <div className="h-icons">
                        {" "}
                        <img className="" src={logout_icon} alt="logout_icon" />
                      </div>
                    </DropdownToggle>
                    {/* <object className="headers-icons" type="image/svg+xml" data={logout_icon}></object> */}

                    {/* <i className="pl-1 pr-1 fas fa-sign-out-alt setting-icon" title="Logout"></i> */}
                  </UncontrolledDropdown>

                  {/*--------------------------------------------------------------------------------*/}
                  {/* Start Profile Dropdown                                                         */}
                  {/*--------------------------------------------------------------------------------*/}

                  <UncontrolledDropdown inNavbar nav>
                    <DropdownToggle
                      nav
                      caret
                      className="rounded-circle"
                      style={{ cursor: "default" }}
                    >
                      <div>
                        <img
                          src={
                            this.props.userReducer.currentUser.avatar.match(
                              /http([^&]+)/
                            )
                              ? this.props.userReducer.currentUser.avatar
                              : male
                          }
                          // alt={this.props.userReducer.currentUser.full_name}
                          className="pl-1 pr-1 rounded-circle headers-icons "
                          width="40"
                          style={{ cursor: "default" }}
                          alt="avatar"
                        />
                        <span className="doctor-name">
                          {this.props.userReducer.currentUser.full_name}
                        </span>
                      </div>
                    </DropdownToggle>

                    {/* <DropdownMenu right className="user-dd">
                  <div className="p-3 mb-2 d-flex no-block align-items-center border-bottom">
                    <div className="">
                      <img
                        src={
                          this.props.userReducer.currentUser.avatar.match(
                            /http([^&]+)/
                          )
                            ? this.props.userReducer.currentUser.avatar
                            : "https://muratselek.com.tr/wp-content/uploads/2019/01/yorum-icon-avatar-men-768x918.png"
                        }
                        alt={this.props.userReducer.currentUser.first_name}
                        className="rounded"
                        width="80"
                      />
                    </div>
                    <div className="ml-3">
                      <h4 className="mb-0">
                        {this.props.userReducer.currentUser.full_name}
                      </h4>
                      <p className="mb-0 text-muted">
                        {this.props.userReducer.currentUser.email}
                      </p>
                      <Button
                        color="danger"
                        className="mt-2 btn-rounded"
                        onClick={() => {
                          window.location.href = "/sample-pages/profile";
                          this.setState({
                            profile: true,
                          });
                        }}
                      >
                        View Profile
                      </Button>
                    </div>
                  </div>
                  <DropdownItem>
                    <i className="ml-1 mr-1 ti-user" /> My Account
                  </DropdownItem>
                  <DropdownItem>
                    <i className="ml-1 mr-1 ti-wallet" /> My Balance
                  </DropdownItem>
                  <DropdownItem className="border-bottom">
                    <i className="ml-1 mr-1 ti-email" /> Inbox
                  </DropdownItem>
                  <DropdownItem
                    className="border-bottom"
                    onClick={() => {
                      window.location.href = "/profile";
                      this.setState({
                        setting: true,
                      });
                    }}
                  >
                    <i className="ml-1 mr-1 ti-settings" /> Account Settings
                  </DropdownItem>
                  <DropdownItem
                     href="https://sandbox.access.eonaligner.com/doctors/sign_out"
                    onClick={() => {
                      authenticationService.logout();
                    }}
                  >
                    <i className="ml-1 mr-1 fa fa-power-off" /> Logout
                  </DropdownItem>
                </DropdownMenu>
             */}
                  </UncontrolledDropdown>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* End Profile Dropdown                                                           */}
                  {/*--------------------------------------------------------------------------------*/}
                </Nav>
              </div>
            </div>
            {/*--------------------------------------------------------------------------------*/}
            {/* logout Modal */}
            {/*--------------------------------------------------------------------------------*/}
            <Modal
              isOpen={this.state.logout}
              toggle={this.toggleLogoutModal}
              className="c-modal confarim-modal"
            >
              {/* <ModalHeader toggle={this.toggleLogoutModal}></ModalHeader> */}

              <ModalBody className="pt-0 cp3 ">
                <CustomeHeader title="Confirm Logout" withoutLine={true} largeFont={true}/>
                <div className="form-block-holder h-100">
                  <label className="cmt3 cmb3 headline-text">
                    {content.confirmation_logout}
                  </label>

                  <div className="confirmation-buttons-grid">
                    <Button
                      className="btn white-btn button-seme-bold-text"
                      disabled={this.state.logingout}
                      onClick={() =>
                        this.setState({ logout: !this.state.logout })
                      }
                    >
                      {content.cancel}
                    </Button>
                    <Button
                      className="btn light-green-btn button-seme-bold-text mobile-order"
                      disabled={this.state.logingout}
                      onClick={() => {
                        this.setState({ logingout: true });
                        authenticationService.logout();
                      }}
                    >
                      {content.yes}
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            {/*--------------------------------------------------------------------------------*/}
            {/* end logout Modal */}
            {/*--------------------------------------------------------------------------------*/}
          </Collapse>
        </Navbar>
        {block_case_submission && window.location.pathname === "/home" && (
          <div>



          <div className="blocked_doc_note">
            <div className="blocked-doc-note-grid">
              <div className="info_note">
                <img src={white_info_icon} alt="white_info_icon" />
              </div>
              <div>
                <span className="bold_l_note headline-bold-text">
                  {content.your_account_is_blocked}
                </span>
                <span className="normal_s_note footnote-text">{content.please_contact}</span>
                <span className="bold_s_note footnote-bold-text">
                  <a href="mailto: collections@eonaligner">
                    {content.contact_email}
                  </a>
                </span>
                <span className="normal_s_note footnote-text">
                  {content.blocked_note_part2}
                </span>
              </div>
            </div>
          </div>
          </div>
        )}
      </header>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
