import React, { useState } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Notes from "../TSViewer/Notes";
import AppliedTreatment from "../TSViewer/AppliedTreatment";
import Movement from "../TSViewer/Movement";
import {
  Allteeth,
  Checked,
  TotalArray,
  TotalvalueToComp,
} from "../../../services/checkMovement";
import { SetHybridState } from "../../../redux/hybridTreatment/action";
import { ReactComponent as Close } from "../../../assets/images/Ts-navBar/close.svg";
import {
  hasAdvancedModules,
  WrappedAppliedTreatments,
} from "@eon-dental/advanced-modules/dist/rollup-export";

export const Details = (props) => {
  const {
    case_type,
    uid,
    is_archived,
    advanced_modules,
    isNewAdvancedModules,
  } = props.caseReducer.case;
  const { convert_to_hybrid_treatment } = props.hybridTreatment;
  const { content } = props.languageReducer;
  const [activeTab, setActiveTab] = useState(1);
  const { details, mapped_advanced_modules, showBiteRecord, closeMenu } = props;
  const { block_case_submission, dental_notation } =
    props.userReducer.currentUser;
  /*--------------------------------------------------------------------------------*/
  /*set active tab   */
  /*--------------------------------------------------------------------------------*/
  const toggleTabs = (tab) => {
    setActiveTab(tab);
  };
  const convertToHybridTreatment = () => {
    props.SetHybridState(true);
  };
  /* --------------------------------------------------------------------------------*/
  /* check Extracted Data  if there is upnormal value*/
  /* --------------------------------------------------------------------------------*/
  const checkExtractedData = () => {
    let data = details?.teeth_movements_extracted || [];
    for (var x = 0; x < data.length; x++) {
      if (
        Checked(
          data[x].ToothNumber,
          TotalArray.Rotation_fifty,
          data[x].Rotation,
          TotalvalueToComp.Rotation_fifty
        ) ||
        Checked(
          data[x].ToothNumber,
          TotalArray.Rotation_thirty,
          data[x].Rotation,
          TotalvalueToComp.Rotation_thirty
        ) ||
        Allteeth(
          data[x].ToothNumber,
          TotalvalueToComp.Angulation_twinty,
          data[x].Angulation
        ) ||
        Checked(
          data[x].ToothNumber,
          TotalArray.Torque_fifteen,
          data[x].Inclination,
          TotalvalueToComp.Torque_fifteen
        ) ||
        Checked(
          data[x].ToothNumber,
          TotalArray.Torque_ten,
          data[x].Inclination,
          TotalvalueToComp.Torque_ten
        ) ||
        Allteeth(
          data[x].ToothNumber,
          TotalvalueToComp.Mesial_four,
          data[x].LeftRight
        ) ||
        Checked(
          data[x].ToothNumber,
          TotalArray.Extrusion_rand,
          data[x].ExtrusionIntrusion,
          TotalvalueToComp.Extrusion_rand
        ) ||
        Checked(
          data[x].ToothNumber,
          TotalArray.Extrusion_rand,
          data[x].ExtrusionIntrusion,
          TotalvalueToComp.Extrusion_rand
        ) ||
        Allteeth(
          data[x].ToothNumber,
          TotalvalueToComp.in_three,
          data[x].ForwardBackward
        )
      ) {
        return true;
      }
    }
    return false;
  };
  /* --------------------------------------------------------------------------------*/
  /* Check Hybrid Treatment Rules*/
  /* --------------------------------------------------------------------------------*/
  const CheckHybridTreatmentRules = () => {
    if (
      details.can_approve_or_disapprove &&
      !is_archived &&
      ((advanced_modules.hybridTreatment?.use_hybridTreatment ===
        "eonToDecide" &&
        checkExtractedData()) ||
        (advanced_modules.hybridTreatment?.use_hybridTreatment === true &&
          checkExtractedData()) ||
        advanced_modules.hybridTreatment?.use_hybridTreatment === true) &&
      !convert_to_hybrid_treatment &&
      !showBiteRecord &&
      !block_case_submission
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="tp_details c-tabs ">
      <div className="menu-title">
        <div>{content.menu}</div>
        <Close className="cursor-pointer" alt="back" onClick={closeMenu} />
      </div>
      <Nav tabs>
        {/*--------------------------------------------------------------------------------*/}
        {/* Movement Table tab */}
        {/*--------------------------------------------------------------------------------*/}
        <NavItem className="ts-custom-tabs">
          <NavLink
            className={classnames({
              active: activeTab === 1,
            })}
            onClick={() => {
              toggleTabs(1);
            }}
          >
            {content.Movement_Table}
          </NavLink>
        </NavItem>
        {/*--------------------------------------------------------------------------------*/}
        {/* Applied Treatments tab */}
        {/*--------------------------------------------------------------------------------*/}
        {case_type !== "Retainer" && (
          <NavItem className="ts-custom-tabs">
            <NavLink
              className={classnames({
                active: activeTab === 2,
              })}
              onClick={() => {
                toggleTabs(2);
              }}
            >
              {content.Applied_Treatments}
            </NavLink>
          </NavItem>
        )}

        {/*--------------------------------------------------------------------------------*/}
        {/* Notes tab */}
        {/*--------------------------------------------------------------------------------*/}
        {case_type !== "Retainer" &&
          uid &&
          (details?.technician_notes || details?.manufacturing_notes) && (
            <NavItem className="ts-custom-tabs">
              <NavLink
                className={classnames({
                  active: activeTab === 3,
                })}
                onClick={() => {
                  toggleTabs(3);
                }}
              >
                <div className="inbox-heartbit">
                  {content.Notes}
                  <div className="notify-in-ts-padding">
                    <div className="notify">
                      <span className="heartbit" /> <span className="point" />
                    </div>
                  </div>
                </div>
              </NavLink>
            </NavItem>
          )}
      </Nav>
      <TabContent
        activeTab={activeTab}
        className="case-info-container-ts Parentlayput"
      >
        {/*--------------------------------------------------------------------------------*/}
        {/*Movement Table tab content  */}
        {/*--------------------------------------------------------------------------------*/}
        <TabPane tabId={1}>
          {CheckHybridTreatmentRules() && (
            <div className="HT-ts">
              <div className="HT-ts-title">
                {
                  content.Hybrid_treatment_is_recommended_due_to_difficult_movements
                }
              </div>
              <div className="HT-ts-link" onClick={convertToHybridTreatment}>
                {content.Convert_to_Hybrid_Treatment}
              </div>
            </div>
          )}
          <br></br>
          <Movement movement={details?.teeth_movements_extracted || []} />
        </TabPane>

        {/*--------------------------------------------------------------------------------*/}
        {/*AppliedTreatment tab content  */}
        {/*--------------------------------------------------------------------------------*/}
        <TabPane tabId={2} className="c-tab-pane">
          {isNewAdvancedModules ? (
            hasAdvancedModules(details.advanced_modules) && (
              <WrappedAppliedTreatments
                numberingSystem={dental_notation}
                appliedTreatments={details.advanced_modules}
              />
            )
          ) : (
            <AppliedTreatment
              advanced_modules={details.advanced_modules}
              mapped_advanced_modules={mapped_advanced_modules}
            />
          )}
        </TabPane>
        {/*--------------------------------------------------------------------------------*/}
        {/*note tab content  */}
        {/*--------------------------------------------------------------------------------*/}
        <TabPane tabId={3} className="c-tab-pane">
          <Notes
            manufacturing_notes={details.manufacturing_notes}
            technician_notes={details.technician_notes}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  SetHybridState: (payload) => dispatch(SetHybridState(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
