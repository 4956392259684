import React, { Component } from "react";
import {
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
} from "reactstrap";
import { successToaster, errorToaster } from "../../../services/toast";
import { Formik, Form, ErrorMessage } from "formik";
import { schemaRequest_final } from "../../../helpers/ImpressionsValidations";
import * as yup from "yup";

import {
  upload_impression_data_lower,
  upload_impression_data_upper,
  upload_impression_data,
} from "../../../helpers/impressions";
import { CardTitle, CardFooter, Card, CardImg } from "reactstrap";
import classnames from "classnames";
import { requestRetainer, getRetainers } from "../../../api/api";

import RequestedRetainers from "./requestedRetainers";

import { connect } from "react-redux";
import moment from "moment";

import CustomRadioGroup from "../../../components/radioGroup/CustomRadioGroup";
import { ReactComponent as Increment } from "../../../assets/images/movement/increment.svg";
import { ReactComponent as Decrement } from "../../../assets/images/movement/decrement.svg";
import { CustomInput } from "reactstrap";
import { uploadFile, keyToUrl } from "../../../helpers/s3";

import { CircularProgressbar } from "react-circular-progressbar";
import upload_not_active from "../../../assets/images/icon/upload_not_active.svg";
import upload_active from "../../../assets/images/icon/upload_active.svg";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
class RetainerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      case: this.props.data.case,
      doctorId: this.props.data.doctorId,
      retainer: false,
      retainer_new: false,
      sets: 1,
      // pause: props.CaseDetails.status === "Paused" ? true : false,
      gco_doctor: this.props.userReducer.currentUser.gco_doctor,

      arch_impression_upper: false,
      arch_impression_lower: false,
      confirmPause: false,
      confirmResume: false,

      chose_one: "",
      impressions_method: "",
      retainers_List: [],
      replacements_List: [],
      setups: [],
      fix: false,
      activeRetainerTab: 1,
      activeReplacementTab: 1,
      stepToFix: "",
      stepToFixName: "",
      loading: true,
      doctorInfo: {},
      mappedData: props.mappedData,
      sendingData: false,
      sendingRetainer: false,
      pusingCase: false,
      mark: false,
      completingCase: false,
      archiveModal: false,
      unArchiveModal: false,
      show_upload_new_bite_record: false,
      show_bite_record_modal: false,
      hybrid_type_braces_before: false,
      impressions_method_arch: "",

      loading_percentage: 0,
      intervalID: 0,
      upper_arch_url: "",
      lower_arch_url: "",
      impressions_third_party_note: "",
      showProgress: false,
      loading_percentage_lower_arch_url: 0,
      loading_percentage_upper_arch_url: 0,
      hover_upper_arch: false,
      hover_lower_arch: false,
    };
  }

  /*--------------------------------------------------------------------------------*/
  /*set Active Retainer Tab */
  /*--------------------------------------------------------------------------------*/
  setActiveRetainerTab = (tab) => {
    if (this.state.activeRetainerTab !== tab) {
      this.setState({
        activeRetainerTab: tab,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* Reques Retainer                                                       */
  /*--------------------------------------------------------------------------------*/
  RequestRetainer = (values, { setSubmitting }) => {
    const { content } = this.props.languageReducer;
    this.setState({ sendingRetainer: !this.state.sendingRetainer });

    let impressions = {
      upper_arch_url: this.state.upper_arch_url,
      lower_arch_url: this.state.lower_arch_url,
    };
    let filtered_impressions = {};

    for (var key2 in impressions) {
      if (impressions[key2].match(/http([^&]+)/)) {
        filtered_impressions[key2] = impressions[key2];
      }
    }

    let data = {
      retainer: {
        sets: this.state.sets.toString(),
        arch_impression_upper: this.state.arch_impression_upper,
        arch_impression_lower: this.state.arch_impression_lower,
        impressions_method_arch: this.state.impressions_method_arch,
        impressions_method: this.state.impressions_method,
        impressions_third_party_note: this.state.impressions_third_party_note,
        impressions: filtered_impressions,
      },
    };

    requestRetainer(
      this.state.doctorId,
      this.state.case.uid,
      JSON.stringify(data)
    )
      .then((res) => {
        this.setState({
          sets: 1,
          arch_impression_upper: false,
          arch_impression_lower: false,
          impressions_method_arch: "",
          impressions_method: "",
          impressions_third_party_note: "",
          impressions: {},
          sendingRetainer: !this.state.sendingRetainer,
        });
        this.props.toggleRetainerModal();
        successToaster(
          content.request_new_retainer_has_been_submitted,
          content.Success
        );
        setSubmitting(false);
      })
      .catch((err) => {
        this.setState({ sendingRetainer: !this.state.sendingRetainer });
        // setSubmitting(false);
      });
  };

  GetRetainers = () => {
    getRetainers(this.state.doctorId, this.state.case.uid).then((res) => {
      this.setState({
        retainers_List: res,

        loading: false,
      });
    });
  };
  // validation functions
  ModalClear = (setFieldValue) => {
    this.setState({
      impressions_method: "",
      upper_arch_url: "",
      lower_arch_url: "",
      impressions_third_party_note: "",
    });
    setFieldValue("impressions_method", "");
    setFieldValue("upper_arch_url", "");
    setFieldValue("lower_arch_url", "");
    setFieldValue("impressions_third_party_note", "");
  };
  // validation functions
  clearUrl = (setFieldValue, values) => {
    if (values.arch_impression_lower === false) {
      setFieldValue("lower_arch_url", "");

      this.setState({
        lower_arch_url: "",
      });
    }
    if (values.arch_impression_upper === false) {
      setFieldValue("upper_arch_url", "");

      this.setState({
        upper_arch_url: "",
      });
    }
    if (
      values.arch_impression_lower === false &&
      values.arch_impression_upper === false &&
      values.impressions_method_arch !== "Use_last_step"
    ) {
      setFieldValue("impressions_method", "");
      setFieldValue("upper_arch_url", "");
      setFieldValue("lower_arch_url", "");
      setFieldValue("impressions_third_party_note", "");
      setFieldValue("impressions_method_arch", "");
      this.setState({
        upper_arch_url: "",
        lower_arch_url: "",
        impressions_third_party_note: "",
        impressions_method: "",
        impressions_method_arch: "",
      });
    }
  };

  /*--------------------------------------------------------------------------------*/
  /* uploading file timer                                             */
  /*--------------------------------------------------------------------------------*/
  timer = (id) => {
    this.setState({
      [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id) => {
    let extention = image.name.split(".");
    return uploadFile({
      name: `case-${
        this.state.caseId
      }/patient-impression/${id}/${new Date().valueOf()}.${
        image.name.split(".")[extention.length - 1]
      }`,
      contentType: image.type,
      file: image,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* upload photos */
  /* --------------------------------------------------------------------------------*/

  UploadPhoto = async (e, id, setFieldValue) => {
    e.persist();

    if (e.target.files[0]) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      const file = e.target ? e.target.files[0] : e.files[0];
      const { key } = await this.uploadImage(file, id);
      clearInterval(this.state.intervalID);
      this.setState({
        [id]: keyToUrl(key),
        [`loading_percentage_${id}`]: 100,
      });

      setFieldValue(id, keyToUrl(key));
      setFieldValue("chose_one", true);
      setTimeout(() => {
        this.setState({
          [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
          [`loading_percentage_${id}`]: 0,
        });
      }, 500);
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload photos if from drop zone */
  /* --------------------------------------------------------------------------------*/
  UploadPhotoDropZone = async (e, id, setFieldValue) => {
    if (e) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();

      reader.readAsDataURL(e);
      const file = e;
      const { key } = await this.uploadImage(file, id);
      clearInterval(this.state.intervalID);
      this.setState({
        [id]: keyToUrl(key),
        [`loading_percentage_${id}`]: 100,
      });

      setFieldValue(id, keyToUrl(key));
      setFieldValue("chose_one", true);
      setTimeout(() => {
        this.setState({
          [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
          [`loading_percentage_${id}`]: 0,
          [`hover_${id}`]: false,
        });
      }, 500);
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* preventDefault */
  /* --------------------------------------------------------------------------------*/
  preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Over */
  /* --------------------------------------------------------------------------------*/
  dragOver = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: true });
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Enter */
  /* --------------------------------------------------------------------------------*/
  dragEnter = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: true });
  };
  /* --------------------------------------------------------------------------------*/
  /* drag Leave */
  /* --------------------------------------------------------------------------------*/
  dragLeave = (e, key) => {
    this.preventDefault(e);
    this.setState({ [`hover_${key}`]: false });
  };
  /* --------------------------------------------------------------------------------*/
  /* file Drop */
  /* --------------------------------------------------------------------------------*/
  fileDrop = (e, key, setFieldValue) => {
    this.preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length === 1) {
      this.handleFiles(files, key, setFieldValue);
    } else {
      this.setState({ [`hover_${key}`]: false });
      // infoToaster("please add one file ");
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* handle Files */
  /* --------------------------------------------------------------------------------*/
  handleFiles = (files, key, setFieldValue) => {
    const { content } = this.props.languageReducer;

    for (let i = 0; i < files.length; i++) {
      if (this.validateFile(files[i])) {
        this.UploadPhotoDropZone(files[i], key, setFieldValue);
      } else {
        errorToaster(content.file_not_supported, content.Warning);
        this.setState({ [`hover_${key}`]: false });
      }
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* validate File */
  /* --------------------------------------------------------------------------------*/
  validateFile = (file) => {
    let extention = file.name.split(".");
    const validTypes = ["zip", "rar", "7zip", "ply", "stl"];
    if (validTypes.indexOf(file.name.split(".")[extention.length - 1]) === -1) {
      return false;
    }

    return true;
  };
  /* --------------------------------------------------------------------------------*/
  /* file Input Clicked */
  /* --------------------------------------------------------------------------------*/
  fileInputClicked = (input) => {
    // this[`${input}_ref`].current.click();
  };

  render() {
    const {
      sets,
      retainers_List,
      impressions_method_arch,
      arch_impression_upper,
      arch_impression_lower,
      impressions_method,
      gco_doctor,
    } = this.state;

    const { content } = this.props.languageReducer;

    const impressions_methods_Arch = [
      { name: "Upload_new_impression", title: "Upload new impression" },
      { name: "Use_last_step", title: "Use last step" },
    ];

    const impressions_methods = [
      { name: "upload", title: content.upload_3d_scans },
      { name: "third_party", title: content._3rd_party_integration },
      ...(!gco_doctor
        ? [{ name: "courier", title: content.impression_pickup }]
        : []),
    ];

    const ArchImpression =
      arch_impression_lower === true && arch_impression_upper === true
        ? upload_impression_data
        : arch_impression_lower === true
        ? upload_impression_data_lower
        : arch_impression_upper === true
        ? upload_impression_data_upper
        : [];

    return (
      <div className="retainer-model-ts">
        <div className="c-tabs case-details-container">
          <Nav tabs>
            <NavItem className="ts-custom-tabs-retainer">
              <NavLink
                className={
                  (classnames({
                    active: this.state.activeRetainerTab === 1,
                  }),
                  "button-text")
                }
                onClick={() => {
                  this.setActiveRetainerTab(1);
                }}
              >
                {content.retainer_request}
              </NavLink>
            </NavItem>

            <NavItem className="ts-custom-tabs-retainer">
              <NavLink
                className={
                  (classnames({
                    active: this.state.activeRetainerTab === 2,
                  }),
                  "button-text")
                }
                onClick={() => {
                  this.GetRetainers();
                  this.setActiveRetainerTab(2);
                }}
              >
                {content.requested_retainers}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            activeTab={this.state.activeRetainerTab}
            className="case-info-container-ts Parentlayput cmt2"
          >
            <TabPane tabId={1}>
              <Formik
                initialValues={{
                  impressions_method: "",
                  upper_arch_url: "",
                  lower_arch_url: "",
                  chose_one: this.state.chose_one,
                  sets: 1,
                  impressions_third_party_note: "",
                  impressions_method_arch: "",
                  arch_impression_upper: false,
                  arch_impression_lower: false,
                }}
                validationSchema={() =>
                  yup.lazy((values) => {
                    return schemaRequest_final(content, values);
                  })
                }
                onSubmit={this.RequestRetainer}
              >
                {({
                  touched,
                  errors,
                  isSubmitting,
                  values,
                  isValid,
                  handleSubmit,
                  handleBlur,

                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form-group content form-block-holder h-100">
                      <div className="Impression_hieght_retainer_request">
                        <div className="Impression_num_setImpression_num_set-container">
                          <div className="Impression_num_setImpression_num_set">
                            <div>
                              <p className="custom-title colorful">
                                {content.Number_of_sets}
                              </p>

                              <div className="Number_of_sets">
                                <div>
                                  <Increment
                                    className="increment-decrement-icon"
                                    onClick={() => {
                                      this.setState({ sets: sets + 1 });
                                    }}
                                  />
                                </div>
                                <div>
                                  <Decrement
                                    className="increment-decrement-icon"
                                    onClick={() => {
                                      if (sets > 1) {
                                        this.setState({
                                          sets: this.state.sets - 1,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="button-text">
                                  {sets} {` `} {content.sets}
                                </div>
                              </div>
                              <ErrorMessage
                                component="div"
                                name={"sets"}
                                className="custom-invalid-feedback m-1 text-center callout-text"
                              />

                              <div className="line-setNumber-request">
                                <div>
                                  <hr
                                    style={{
                                      height: "1px",
                                      borderWidth: "0",
                                      color: "#C8C8CA",
                                      backgroundColor: "#C8C8CA",
                                    }}
                                  />
                                </div>
                                <div></div>
                              </div>
                            </div>
                            <div>
                              <p className="custom-title colorful title-3-text">
                                {content.Arch_Required}
                              </p>
                              <div className="ArchCheckBoxImpression">
                                <CustomInput
                                  id="checkboxArchImpressionUpper"
                                  className={
                                    this.state.arch_impression_upper
                                      ? "replacment-checkbox text_malocclusions body-text"
                                      : "text_malocclusions body-text"
                                  }
                                  style={{ pointerEvents: "cursor" }}
                                  value={arch_impression_upper}
                                  type="checkbox"
                                  checked={arch_impression_upper}
                                  label="Upper"
                                  onChange={() => {
                                    setFieldValue("chose_one", true);
                                    setFieldValue(
                                      "arch_impression_upper",
                                      !arch_impression_upper
                                    );
                                    this.setState({
                                      arch_impression_upper:
                                        !arch_impression_upper,
                                    });

                                    this.clearUrl(setFieldValue, {
                                      ...values,
                                      arch_impression_upper:
                                        !arch_impression_upper,
                                    });
                                  }}
                                />

                                <CustomInput
                                  id="checkboxArchImpressionLower"
                                  className={
                                    this.state.arch_impression_lower
                                      ? "replacment-checkbox text_malocclusions body-text"
                                      : "text_malocclusions body-text"
                                  }
                                  style={{ pointerEvents: "cursor" }}
                                  type="checkbox"
                                  checked={arch_impression_lower}
                                  value={arch_impression_lower}
                                  label="Lower"
                                  onChange={() => {
                                    setFieldValue("chose_one", true);
                                    setFieldValue(
                                      "arch_impression_lower",
                                      !arch_impression_lower
                                    );

                                    this.setState({
                                      arch_impression_lower:
                                        !arch_impression_lower,
                                    });

                                    this.clearUrl(setFieldValue, {
                                      ...values,
                                      arch_impression_lower:
                                        !arch_impression_lower,
                                    });
                                  }}
                                />

                                <div></div>
                              </div>

                              <ErrorMessage
                                component="div"
                                name={"chose_one"}
                                className="custom-invalid-feedback m-1 text-center callout-text"
                              />

                              <div className="line-setNumber-request">
                                <div>
                                  <hr
                                    style={{
                                      height: "1px",
                                      borderWidth: "0",
                                      color: "#C8C8CA",
                                      backgroundColor: "#C8C8CA",
                                    }}
                                  />
                                </div>
                                <div></div>
                              </div>
                            </div>

                            <div>
                              <div>
                                <p className="custom-title colorful title-3-text">
                                  {content.impressions}
                                </p>
                                <div className="impression_CRG">
                                  <CustomRadioGroup
                                    name="impressions_method_arch"
                                    checked={impressions_method_arch}
                                    vertical="false"
                                    value={impressions_method_arch}
                                    onChange={(value) => {
                                      setFieldValue(
                                        "impressions_method_arch",
                                        value
                                      );

                                      this.setState({
                                        impressions_method_arch: value,
                                      });
                                      this.ModalClear(setFieldValue);
                                    }}
                                    options={impressions_methods_Arch}
                                    id="impressions_method_arch"
                                    retainer={true}
                                  />
                                </div>
                                <ErrorMessage
                                  component="div"
                                  name={"impressions_method_arch"}
                                  className="custom-invalid-feedback m-1 text-center callout-text"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {impressions_method_arch === "Upload_new_impression" &&
                        (arch_impression_upper === true ||
                          arch_impression_lower === true) ? (
                          <div>
                            <div>
                              <hr
                                style={{
                                  height: "1px",
                                  borderWidth: "0",
                                  color: "#C8C8CA",
                                  backgroundColor: "#C8C8CA",
                                }}
                              />
                            </div>
                            <div
                              className={`${"c_impressions_grid_retainer_request2 "}`}
                            >
                              <div>
                                <div>
                                  <CustomRadioGroup
                                    name="impressions_method"
                                    checked={values.impressions_method}
                                    onChange={(value) => {
                                      setFieldValue(
                                        "impressions_method",
                                        value
                                      );
                                      this.setState({
                                        impressions_method: value,
                                      });

                                      setFieldValue("chose_one", "");
                                    }}
                                    options={impressions_methods}
                                    id="impressions_method"
                                    retainer={true}
                                  />

                                  <ErrorMessage
                                    component="div"
                                    name={"impressions_method"}
                                    className="custom-invalid-feedback m-1 text-center callout-text"
                                  />
                                </div>
                              </div>
                              <div>
                                {values.impressions_method === "upload" && (
                                  <div>
                                    <div className="upload_impressions_grid">
                                      {ArchImpression.map((value, index) => (
                                        <div key={index}>
                                          <Card
                                            onDragOver={(e) =>
                                              this.dragOver(e, value.key)
                                            }
                                            onDragEnter={(e) =>
                                              this.dragEnter(e, value.key)
                                            }
                                            onDragLeave={(e) =>
                                              this.dragLeave(e, value.key)
                                            }
                                            onDrop={(e) =>
                                              this.fileDrop(
                                                e,
                                                value.key,
                                                setFieldValue
                                              )
                                            }
                                            onClick={() =>
                                              this.fileInputClicked(value.key)
                                            }
                                            className={`cp2 cmb2 ${
                                              this.state[`hover_${value.key}`]
                                                ? "c-dragover"
                                                : ""
                                            } ${
                                              values[value.key]
                                                ? "c-card-with-img-active"
                                                : "c-card-with-img"
                                            }`}
                                          >
                                            <CardTitle className="cmb2">
                                              <div className="card-title-grid">
                                                <div className="body-text">
                                                  {values[value.key] ? (
                                                    value.title
                                                  ) : this.state[
                                                      `showProgress_${value.key}`
                                                    ] ? (
                                                    `Uploading ${value.title}`
                                                  ) : (
                                                    <span className="not-active-img body-text">
                                                      {value.title}{" "}
                                                    </span>
                                                  )}
                                                </div>
                                                <div>
                                                  <img
                                                    src={
                                                      values[value.key]
                                                        ? upload_active
                                                        : upload_not_active
                                                    }
                                                    alt={value.key}
                                                  />
                                                </div>
                                              </div>
                                            </CardTitle>

                                            <div className="image-overlay-container">
                                              <label
                                                ref={this[`${value.key}_ref`]}
                                                name={value.key}
                                                htmlFor={value.key}
                                                className="m-0"
                                              >
                                                <CardImg
                                                  src={value.img}
                                                  className="upload-impression-img"
                                                />
                                                {values[value.key] && (
                                                  <div
                                                    className="overlay-loader"
                                                    htmlFor={value.key}
                                                  >
                                                    <div className="layer overlay-loader"></div>
                                                  </div>
                                                )}

                                                {this.state[
                                                  `showProgress_${value.key}`
                                                ] && (
                                                  <div className="overlay-loader">
                                                    <CircularProgressbar
                                                      className="circule-progress-bar"
                                                      value={
                                                        this.state[
                                                          `loading_percentage_${value.key}`
                                                        ]
                                                      }
                                                      text={`${
                                                        this.state[
                                                          `loading_percentage_${value.key}`
                                                        ]
                                                      }%`}
                                                    />
                                                  </div>
                                                )}
                                              </label>
                                              <input
                                                type="file"
                                                id={value.key}
                                                onChange={(e) => {
                                                  this.UploadPhoto(
                                                    e,
                                                    value.key,
                                                    setFieldValue
                                                  );
                                                }}
                                                multiple={false}
                                                className="visually-hidden"
                                                accept=".zip,.rar,.7zip,.ply,.stl"
                                              />
                                            </div>
                                          </Card>
                                          <ErrorMessage
                                            component="div"
                                            // name={value.key}
                                            name={"upper_arch_url"}
                                            className="custom-invalid-feedback m-1 callout-text"
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}

                                {values.impressions_method ===
                                  "third_party" && (
                                  <div className="form-group content form-block-holder">
                                    <label className="custom-title colorful">
                                      {content.provide_links}
                                      <br></br>
                                      {content.provide_links_commit}
                                    </label>
                                    <div>
                                      <Input
                                        autoComplete="off"
                                        type="textarea"
                                        rows={
                                          window.innerWidth < 839 ? "4" : "8"
                                        }
                                        placeholder={`${content.paste_link} (${content.optional})`}
                                        className="custome-textarea custome-input-text"
                                        defaultValue={
                                          values.impressions_third_party_note
                                        }
                                        onChange={(e) => {
                                          this.setState({
                                            impressions_third_party_note:
                                              e.target.value,
                                          });
                                          setFieldValue(
                                            "impressions_third_party_note",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div className="advanceModuleInmobile">
                          <div className="retainer-mobile-total-grid">
                            <div className="green-btn-ts-container-in-grid">
                              <Button
                                className={`btn green-green-btn button-seme-bold-text`}
                                size="md"
                                type="submit"
                                disabled={isSubmitting || !isValid}
                              >
                                {content.Submit_request}
                              </Button>
                            </div>
                            <div className="white-btn-ts-container-in-grid">
                              <Button
                                className={`btn  white-btn-ts button-seme-bold-text`}
                                size="md"
                                onClick={() => this.props.toggleRetainerModal()}
                                disabled={isSubmitting}
                              >
                                {content.cancel}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="advanceModuleInWebsite">
                        <div className="actions-grid3 pt-3 ">
                          <div></div>
                          <div className="white-btn-ts-container-in-grid">
                            <Button
                              className={`btn  white-btn-ts button-seme-bold-text`}
                              size="md"
                              onClick={() => this.props.toggleRetainerModal()}
                              disabled={isSubmitting}
                            >
                              {content.cancel}
                            </Button>
                          </div>

                          <Button
                            className={`btn green-green-btn button-seme-bold-text`}
                            size="md"
                            type="submit"
                            disabled={isSubmitting || !isValid}
                          >
                            {content.Submit_request}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </TabPane>

            <TabPane tabId={2}>
              <RequestedRetainers
                doctorId={this.state.doctorId}
                case={this.state.case}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RetainerModal);
