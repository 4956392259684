import React, { Component } from "react";
import { connect } from "react-redux";

import { Button } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Pontics extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_pontics: this.props.caseSubmission.advanced_modules.pontics
        .usePontics,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "pontics",
      data: {
        usePontics: this.state.use_pontics,
        teeth: this.props.customeTeethReducer.Case_Pontics,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { use_pontics } = this.state;
    const { content } = this.props.languageReducer
    const test = [
      "lower_l_8",
      "lower_l_7",
      "lower_l_6",
      "lower_l_5",
      "lower_r_8",
      "lower_r_7",
      "lower_r_6",
      "lower_r_5",
      "upper_l_8",
      "upper_l_7",
      "upper_l_6",
      "upper_l_5",
      "upper_r_8",
      "upper_r_7",
      "upper_r_6",
      "upper_r_5",
    ];
    return (
      <div>
        <div className="templete_module_grid">

          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.use_pontics_for_this_case}{"? "}
            </div>

            <TreatmentOptions content={content} value={use_pontics} name="use_pontics" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_pontics")
              if (value !== true) {
                this.props.Clear_Teeth_Data("Case_Pontics")
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>
          <div className="divider_module-middle"></div>

          <div id="content_container_3"
            className={`${use_pontics !== true ? "c-content-container7" : "c-content-container-pontics "} tooth-extraction  cpb2 `}>
            <div className="grid_container">
              {" "}
              <div className="">
                <div className="control-label title_active-gco text-left pb-2 mt-2 light-green callout-text">
                  {content.select_the_teeth_requiring_pontic_placement}
                </div>
                <div className="teeth_selector">
                  <CustomeTeeth
                    action_key="Case_Pontics"
                    disabled={!use_pontics || use_pontics === "eonToDecide"}
                    summary={this.props.summary}
                    pontics={true}
                    content={content}
                  />
                </div>
              </div>
            </div>
            <div className={`${(this.state.use_pontics === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_pontics === "eonToDecide") ? `Eon_to_recommend_eon  Eon_to_recommend_layout` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>
              <p className={`${(this.state.use_pontics === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Pontics_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.use_pontics === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>

            </div>
            {
          !this.props.summary  &&this.state.use_pontics ===true && <div className="btn-adv-mobile margin-top2 displayNone_inmobile"><Button
            className=" mt-2 mobile-but-adv light-green-btn float-right   button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
          </div>
        </div>

        {
          !this.props.summary &&  !(this.state.use_pontics ===true && window.innerWidth <839  ) &&  <div className="btn-adv-mobile"><Button
            className=" mt-2 mobile-but-adv light-green-btn float-right   button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pontics);
