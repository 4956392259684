import { UNSAVED_CHANGES, UNSAVED_DATA,UNSAVED_KEYS,RESET_CHANGES,FILTER_DATA } from "../constants/";

export const setUnsavedChanges = (payload) => {
  return {
    type: UNSAVED_CHANGES,
    payload,
  };
};
export const setUnsavedData = (payload) => {
  return {
    type: UNSAVED_DATA,
    payload,
  };
};
export const setUnsavedKeys = (payload) => {
  return {
    type: UNSAVED_KEYS,
    payload,
  };
};

export const resetChanges = (payload) => {
  return {
    type: RESET_CHANGES,
    payload,
  };
};

export const setFilters = (payload) => {
  return {
    type: FILTER_DATA,
    payload,
  };
};
