import React from "react";
import { getTerms } from "../../../redux/language/terms/terms";

function TermsContent(props) {
  const { country, language } = props;
  return (
    <div className="terms_inner_container">
      <div
        className="terms_container"
        dir={language === "ar" ? "rtl" : "ltr"}
        dangerouslySetInnerHTML={{ __html: getTerms(language, country) }}
      />
    </div>
  );
}

export default TermsContent;
