import {
  NEW_CASE_USER_INFO,
  TREATMENT_PLAN_INFO,
  IMPRESSIONS,
  TOOTH_MOVEMENT_RESTRICTIONS,
  DO_NOT_PLACE_ATTACHMENTS,
  EXTRACTION,
  CROSSBITES,
  LEAVESPACE,
  PHOTOS,
  XRAYS,
  SUBMISSION,
  UPDATE_CASE,
  NUMBERING,
  PHOTO_POSITION
} from "../constants";

export const updateCase = (payload) => {
  return {
    type: UPDATE_CASE,
    payload,
  };
};

export const changePhotoPosition = (payload) => {
  return {
    type: PHOTO_POSITION,
    payload
  }
}
export const setNewCaseUserInfo = (payload) => {
  return {
    type: NEW_CASE_USER_INFO,
    payload,
  };
};

export const setNumberingSystem = (payload) => {
  return {
    type: NUMBERING,
    payload,
  };
};

export const setTreatmentPlanInfo = (payload) => {
  return {
    type: TREATMENT_PLAN_INFO,
    payload,
  };
};
export const setImpressionsInfo = (payload) => {
  return {
    type: IMPRESSIONS,
    payload,
  };
};
export const setPhotosInfo = (payload) => {
  return {
    type: PHOTOS,
    payload,
  };
};
export const setXraysInfo = (payload) => {
  return {
    type: XRAYS,
    payload,
  };
};
export const setSubmissionInfo = (payload) => {
  return {
    type: SUBMISSION,
    payload,
  };
};
export const Tooth_Movement_Restrictions = (payload) => {
  return {
    type: TOOTH_MOVEMENT_RESTRICTIONS,
    payload,
  };
};
export const Do_not_place_attachments = (payload) => {
  return {
    type: DO_NOT_PLACE_ATTACHMENTS,
    payload,
  };
};

export const Extraction = (payload) => {
  return {
    type: EXTRACTION,
    payload,
  };
};

export const Crossbites = (payload) => {
  return {
    type: CROSSBITES,
    payload,
  };
};
export const LeaveSpace = (payload) => {
  return {
    type: LEAVESPACE,
    payload,
  };
};
