import React, { Component } from "react";
import { connect } from "react-redux";


const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});

class FAQ extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {loyalty} =  this.props.userReducer.currentUser
    const { content } = this.props.languageReducer;

    return (
      <div className="Description-loyalty-container">
        <div className="Description-loyalty-steps subhead-bold-text">{content.what_is_the_duration_for_this_loyalty_program}</div>
        <div className="Description-loyalty-steps body-italic">{content.the_duration_is_12_months_meaning_the_case_counter_resets_every_year_to_zero}</div>
        <div className="Description-loyalty-steps body-italic">{content.you_achieve_at_least_the_minimum_number_of_cases_to_maintain_your_tier}</div>
        <div className="Description-loyalty-steps subhead-bold-text FAQ-step">{content.how_do_I_upgrade_to_higher_tiers}</div>
        <div className="Description-loyalty-steps body-italic">{content.our_system_counts_the_number_of_approved_cases_youve_achieved}</div>
        <div className="Description-loyalty-steps body-italic">{content.you_will_be_automatically_upgraded}</div>
        <div className="Description-loyalty-steps subhead-bold-text FAQ-step">{content.Is_it_possible_to_downgrade_to_lower_tiers}</div>
        <div className="Description-loyalty-steps body-italic">{content.Yes_it_is_downgrade_check_happens_after_year_2_of_tier_attainment}</div>
        <div className="Description-loyalty-steps body-italic">{content.of_cases_is_notin_the_range_of_current_tier_then_downgrade_occurs_that_matches_your_performance}</div>
        <div className="Description-loyalty-steps subhead-bold-text FAQ-step">{content.are_all_cases_counted_the_same}</div>
        <div className="Description-loyalty-steps body-italic">{content.No_Eon_Basic_is_counted_as_half_a_case_and_Eon_Pro_and_Eon_Plus_are_counted_as_one_case}</div>
        <div className="Description-loyalty-steps subhead-bold-text FAQ-step">{content.How_do_I_access_my_benefits}</div>
        <div className="Description-loyalty-steps body-italic">{content.the_consultation_related_benefits_are_bookable_through_the_links}</div>
        <div className="Description-loyalty-steps body-italic">{content.like_discounts_inclinic_marketing_kits_are_sent_automatically}</div>

      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
