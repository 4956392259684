import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class TorqueEnhancers extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_torque_enhancers: this.props.caseSubmission.advanced_modules
        .torqueEnhancers.useTorqueEnhancers,
      torque_enhancers_details: this.props.caseSubmission.advanced_modules
        .torqueEnhancers.details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "torqueEnhancers",
      data: {
        useTorqueEnhancers: this.state.use_torque_enhancers,
        teeth:
          this.props.customeTeethReducer.Case_Torque_Enhancers
        ,
        details: this.state.torque_enhancers_details,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { use_torque_enhancers, torque_enhancers_details } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>
        <div className="templete_module_grid">

          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.use_torque_enhancers_for_this_case}{"? "}
            </div>
            <TreatmentOptions content={content} value={use_torque_enhancers} name="use_torque_enhancers" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_torque_enhancers")
              if (value !== true) {
                this.setState({
                  torque_enhancers_details: ""
                })
                this.props.Clear_Teeth_Data("Case_Torque_Enhancers")
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>
          <div className="divider_module-middle">          </div>
          <div id="content_container_3"
            className={`${use_torque_enhancers !== true ? "c-content-container8" : "c-content-container "} tooth-extraction  cpb2 `}>
            <div>
              <div className="control-label title_active-gco text-left light-green pb-2 mt-2 callout-text">
                {content.select_the_teeth_requiring_torque_enhancers}
              </div>

              <div className="teeth_selector">  <CustomeTeeth
                action_key="Case_Torque_Enhancers"
                disabled={!use_torque_enhancers || use_torque_enhancers === "eonToDecide"}
                summary={this.props.summary}
              /></div>
            </div>
            <br />
            <br />
            <div>
              <p className="control-label text-left  light-green button-text">{content.additional_comments}</p>
              <Input
                className=" border-radius backgrountTextarea  custome-input-text "
                name="torque_enhancers_details"
                id="torque_enhancers_details"
                type="textarea"
                placeholder={content.typeHere}
                rows={5}
                value={torque_enhancers_details}
                onChange={(e) => {
                  this.setState({ torque_enhancers_details: e.target.value });
                }}
                disabled={!use_torque_enhancers || use_torque_enhancers === "eonToDecide" || this.props.summary}
              />
            </div>
            <div className={`${(this.state.use_torque_enhancers === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_torque_enhancers === "eonToDecide") ? `Eon_to_recommend_eon Eon_to_recommend_layout` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`}`}>

              <p className={`${(this.state.use_torque_enhancers === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Torque_enhancers_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.use_torque_enhancers === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
            </div>
            {
          !this.props.summary && this.state.use_torque_enhancers ===true && <div className="btn-adv-mobile margin-top2 displayNone_inmobile"><Button
            className=" mt-2 mobile-but-adv light-green-btn float-right   button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
          </div>
        </div>
        {
          !this.props.summary &&  !(this.state.use_torque_enhancers ===true && window.innerWidth <839  ) &&<div className="btn-adv-mobile"><Button
            className=" mt-2 mobile-but-adv light-green-btn float-right   button-seme-bold-text"
            size="lg"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            {content.save}
          </Button></div>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TorqueEnhancers);
