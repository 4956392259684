import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input } from "reactstrap";
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class BiteRamps extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_bite_ramps: this.props.caseSubmission.advanced_modules.biteRamps
        .useBiteRamps,
      incisors: this.props.caseSubmission.advanced_modules.biteRamps.incisors,
      incisors_and_canines: this.props.caseSubmission.advanced_modules.biteRamps
        .incisors_and_canines,
      bite_ramps_details: this.props.caseSubmission.advanced_modules.biteRamps
        .details,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "biteRamps",
      data: {
        useBiteRamps: this.state.use_bite_ramps,
        incisors: this.state.incisors,
        incisors_and_canines: this.state.incisors_and_canines,
        details: this.state.bite_ramps_details,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }

  clearState = () => {
    this.setState({
      incisors: false,
      incisors_and_canines: false,
      bite_ramps_details: ""
    })
  }
  render() {
    const {
      use_bite_ramps,
      bite_ramps_details,
    } = this.state;
    const { content } = this.props.languageReducer
    return (
      <div>
        <div className="templete_module_grid">

          <div className="text-left">
          <div className="control-label title_active-gco title_tertment_option text-left mb-2 title-3-text">
              {content.use_bite_ramps_for_this_case}{"? "}
            </div>
            <TreatmentOptions content={content} value={use_bite_ramps} name="use_bite_ramps" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_bite_ramps")
              if (value !== true) {
                this.clearState()
                window.scrollTo(0, 0)
                var content_container_3 = document.getElementById('content_container_3');
                content_container_3.scrollTop = 0;
              }
            }} />
          </div>

          <div className="divider_module-middle"></div>
          <div id="content_container_3"
            className={`${use_bite_ramps !== true ? "c-content-container8" : "c-content-container "} tooth-extraction  cpb2 `}>
              <div className="control-label text-left  light-green button-text">
                {content.select_the_teeth_on_which_you_want_to_place_bite_ramps}
              </div>
            <div className="address_discrepancy">
              <div className="spaces_pickup_inner">
              <div className="radio-item text_malocclusions" 
                style={{ pointerEvents: this.props.summary ? "none  ": "" }} >
                <input type="radio"
                  id='Incisors'
                  name='BiteRamps'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  checked={this.state.incisors}
                  value={"Incisors"}
                  disabled={ this.props.summary}
                  onClick={() => {
                    this.setState({
                      incisors: true,
                      incisors_and_canines: false,
                    });
                  }}
                />
                <label htmlFor='Incisors' className="title-3-text">{content.incisors}</label>
              </div>
              </div>
              <div className="radio-item text_malocclusions"
                style={{ pointerEvents: this.props.summary ? "none   ": "" }}  >
                <input type="radio"
                  id='Incisors_and_canines'
                  name='BiteRamps'
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  value={"Incisors_and_canines"}
                  disabled={ this.props.summary}
                  checked={this.state.incisors_and_canines}
                  onClick={() => {
                    this.setState({
                      incisors: false,
                      incisors_and_canines: true,
                    });
                  }}
                />
                <label htmlFor='Incisors_and_canines' className="title-3-text">{content.incisors_and_canines}</label>
              </div>
              <br />

            </div>
            <p className="control-label text-left  light-green button-text">{content.additional_comments}</p>
            <div>
              <Input
                className=" border-radius text_malocclusions backgrountTextarea  custome-input-text" 
                name="bite_ramps_details"
                id="bite_ramps_details"
                type="textarea"
                placeholder={content.typeHere}
                value={bite_ramps_details}
                rows={5}
                onChange={(e) => {
                  this.setState({ bite_ramps_details: e.target.value });
                }}
                disabled={!use_bite_ramps || use_bite_ramps === "eonToDecide" || this.props.summary}
              />{" "}
            </div>
            <div className={`${(this.state.use_bite_ramps === false) ? `Eon_to_recommend_no Eon_to_recommend_layout` : (this.state.use_bite_ramps === "eonToDecide") ? `Eon_to_recommend_eon Eon_to_recommend_layout ` : `Eon_to_recommend_eon_yes Eon_to_recommend_layout`} title-2-text`}>
              <p className={`${(this.state.use_bite_ramps === "eonToDecide") ? `Eon_to_recommend_text_eon Eon_to_recommend_text_no_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Bite_ramps_will_not_be_applied_to_this_case}</p>
              <p className={`${(this.state.use_bite_ramps === false) ? `Eon_to_recommend_text_no Eon_to_recommend_text_eon_none Eon_to_recommend_layout` : ''} title-2-text`}>{content.Eon_to_recommend_best_settings_for_this_treatment}</p>
            </div>

            { !this.props.summary && this.state.use_bite_ramps ===true&&   <div className="btn-adv-mobile margin-top3 displayNone_inmobile"><Button
          className="mt-2  light-green-btn  mobile-but-adv float-right  button-seme-bold-text "
          size="lg"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          {content.save}
        </Button></div>}
          </div>

        </div>
        { !this.props.summary &&  !(this.state.use_bite_ramps ===true && window.innerWidth <839  )  &&   <div className="btn-adv-mobile"><Button
          className="mt-2  light-green-btn  mobile-but-adv float-right  button-seme-bold-text "
          size="lg"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          {content.save}
        </Button></div>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BiteRamps);
