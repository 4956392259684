import React, { Component } from 'react'
import CustomRadioGroup from "../../../components/radioGroup/CustomRadioGroup";

export default class OnBoardingSettings extends Component {
    render() {
    const { content, language, date_format, dental_notation, OnChange}  = this.props
    const languages =
        [{ name: "en", title: content.en },
        { name: "fr", title: content.fr }];
    const date_formats =
        [{ name: "MM/DD/YYYY", title: "MM/DD/YYYY" },
        { name: "DD/MM/YYYY", title: "DD/MM/YYYY" }];
    const dental_notations =
        [{ name: "universal", title: content.universal },
        { name: "palmer", title: content.palmer },
        { name: "fdi", title: content.fdi_new }];
    
        return (
            <div className="form-group content form-block-holder h-100">
            <div className="onboarding_grid">
              <div>
                <div className="custom-title cmb1 cmt2 title-3-text"> {content.dental_notation}
                </div>
                <CustomRadioGroup
                  name="dental_notation" checked={dental_notation}
                  onChange={(data) => {
                      OnChange('dental_notation', data)
                  }}
                  options={dental_notations} />
              </div>
              <div>
                <div className="custom-title cmb1 cmt2 title-3-text"> {content.language}</div>
                <CustomRadioGroup
                  name="language" checked={language}
                  onChange={(data) => {
                    OnChange('language', data)
                  }}
                  options={languages} />
              </div>
              <div>
                <div className="custom-title cmb1 cmt2 title-3-text"> {content.date_format}</div>
                <CustomRadioGroup
                  name="date_format" checked={date_format}
                  onChange={(data) => {
                    OnChange('date_format', data)
                  }}
                  options={date_formats} />
              </div>
            </div>
          </div>
        )
    }
}
