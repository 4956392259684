import React, { Component } from "react";
import { connect } from "react-redux";

import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),

});
class Deepbite extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */

    this.intrudeUpperAnteriors =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.deepbite
        ?.intrudeUpperAnteriors || false;

    this.intrudeLowerAnteriors =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.deepbite
        ?.intrudeLowerAnteriors || false;

    this.combination =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.deepbite
        ?.combination || false;

    this.extrudePremolars =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.deepbite
        ?.extrudePremolars || false;
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      intrudeUpperAnteriors: this.intrudeUpperAnteriors,
      intrudeLowerAnteriors: this.intrudeLowerAnteriors,
      combination: this.combination,
      extrudePremolars: this.extrudePremolars,
    };

    this.props.save("deepbite", data);
  };
  render() {
    const {
      intrudeUpperAnteriors,
      intrudeLowerAnteriors,
      combination,

      extrudePremolars,
    } = this;
    const { content } = this.props.languageReducer
    return (
      <div className="">
               <CustomeHeader title={content.deepbite} optional={false} largeFont={false}/>
        <div className="buttom-paddingFORMalc">
          <div className="control-label title_active-gco title_tertment_option title-3-text">
          {content.treatment_options}{" "}
          </div>
          <CustomInput
            className={`pt-1 ${intrudeUpperAnteriors && "replacment-checkbox"} button-text-select`}
            type="checkbox"
            id="intrudeUpperAnteriors"
            label={content.intrude_upper_anteriors}
            checked={intrudeUpperAnteriors}
       
            disabled={intrudeLowerAnteriors || combination ||this.props.summary}
            onChange={() => {
              this.intrudeUpperAnteriors = !this.intrudeUpperAnteriors;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${intrudeLowerAnteriors && "replacment-checkbox"} button-text-select`}
            type="checkbox"
            id="intrudeLowerAnteriors"
            label={content.intrude_lower_anteriors}
            checked={intrudeLowerAnteriors}
            disabled={combination || intrudeUpperAnteriors ||this.props.summary}
            onChange={() => {
              this.intrudeLowerAnteriors = !this.intrudeLowerAnteriors;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${combination && "replacment-checkbox"} button-text-select`}
            type="checkbox"
            id="combination2"
            label={content.Combination_of_upper_and_lower_anteriors_intrusion}
            checked={combination}
            disabled={intrudeLowerAnteriors || intrudeUpperAnteriors ||this.props.summary}
            onChange={() => {
              this.combination = !this.combination;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${extrudePremolars && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="extrudePremolars"
            label={content.extrude_posteriors}
            checked={extrudePremolars}
            disabled={this.props.summary}
            onChange={() => {
              this.extrudePremolars = !this.extrudePremolars;
              this.UpdateCrossBiteReducer();
            }}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Deepbite);
