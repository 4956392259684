import { HYBRID_STATE } from "../constants/";

export const SetHybridState = (payload) => {
  return {
    type: HYBRID_STATE,
    payload,
  };
};


