export const fr = {
  logout: "Se déconnecter",
  notification: "Notification",
  mark_notification_as_read: "Marquer toutes les notifications comme lues",
  confirm_logout: "Confirmer la déconnexion",
  confirmation_logout: "Êtes-vous sûr de vouloir vous déconnecter ?",
  yes: "Oui",
  email_address: "Adresse e-mail",
  setting: "Paramètres",
  middle_name: "Deuxième Prénom",
  email: "E-mail",
  phone_no: "Numéro de téléphone",
  subscribe: "S'abonner",
  address: "Adresse",
  zip: "Code Postal",
  phone: "Téléphone",
  delete_address: "Supprimer l'adresse",
  confirmation_delete: "Êtes-vous sûr de vouloir supprimer cette adresse?",
  delete: "Supprimer",
  update_address: "Mettre à jour l'adresse",
  country: "Pays",
  city: "Ville",
  line: "Ligne",
  state: "Pays",
  update: "Mettre à jour",
  confirmation_cancel: "Annuler",
  new_address: "Nouvelle adresse",
  add: "Ajouter",
  country_required: "le pays est requis",
  city_required: "la ville est requise",
  create_new_address: "Créer une nouvelle adresse avec succès",
  previous: "Précédent",
  page: "Page",
  of: "de",
  rows: "Lignes",
  next: "suivant",
  review_cases: "Examiner les cas",
  submit_case: "Soumettre un cas",
  doctor_preferences: "Préférences du Médecin",
  preferences: "Préférences",
  eon_academy: "Eon academy",
  cases_requiring_your_attention: "Cas nécessitant votre attention",
  continue_case_submission: "Continuer la soumission de cas",
  review_rejected_records: "Examiner les données de dossier rejetées",
  approve_treatment_plan: "Approuver le plan de Traitement",
  mark_complete: "Marquer le cas comme complet",
  coming_soon: "Bientôt disponible",
  aligner_cases: "Cas d'aligneur",
  all: "Tous",
  active: "Actif",
  pending: "En attente",
  rejected: "Rejeté",
  complete: "Complets",
  archived: "Archivé",
  search: "Chercher",
  patient_name: "Nom du patient",
  case_num: "Cas#",
  data_submitted: "Données soumises",
  status: "Situation actuelle",
  action: "Action",
  archive: "Archiver",
  archive_case: "Archiver le cas",
  unlimited: "Unlimited",
  lite: "Lite",
  eon_basic: "Eon Basic",
  eon_plus: "Eon Plus",
  eon_pro: "Eon Pro",
  retainer: "Appareil de contention",
  lite_single_arch: "Lite Single Arch",
  continue_submission: "Continuer la soumission",
  update_records: "Mettre à jour les données du dossier",
  view_treatment_plan: "Voir le plan de Traitement",
  restore_case: "Restaurer le cas",
  generating_ts: "Générer Paramètres de Taitement",
  incomplete_submission: "Soumission incomplète",
  pending_ts_approval: "En attente d'approbation des paramètres de traitement",
  Pending_record_approval: "En attente d'approbation du dossier",
  pending_courier_notification: "Notification de messagerie en attente",
  records_rejected: "Données de dossier rejetées",
  rejected_ts: "Paramètres rejetés",
  doctor_perfences: "Préférences du Médecin",
  doctor_perfences_without_advanced_options:
    "Les préférences du médecin ne s'appliquent qu'aux cas soumis sans options avancées.",
  passive_aligners: "Aligneurs passifs",
  eon_to_recommend: "recomendation eon",
  no: "Non",
  upper_arch_leveling: "Nivellement de l'arcade supérieure",
  level_incisal_edge: "Niveler  le bord incisif ",
  level_gingival_margins: "Niveler les marges gingivales",
  other_preferences: "Autres préférences",
  ipr_timing: "Calendrier de IPR",
  restrictions: "Restrictions",
  attachments: "Taquets",
  save_preferences: "Enregistrer les préférences",
  update_doctor_preferences:
    "Mise à jour des préférences du Médecin avec succès",
  patient_Information: "Information du patient",
  first_name: "Prénom",
  last_name: "Nom de famille",
  patient_email_address: "Adresse e-mail du patient",
  gender: "Sexe",
  select: "Sélectionner",
  male: "Homme",
  female: "Femme",
  date_of_birth: "Date de naissance",
  dd_MM_yy: "jj / MM / aa",
  shipping_address: "Adresse de livraison",
  add_new_address: "Ajouter une nouvelle adresse",
  please_add_your_shipping_address:
    "Veuillez ajouter votre adresse de livraison",
  unliminted: "Unlimited",
  cancel_submission: "Annuler la soumission",
  confirmation_cancel_submission:
    "Êtes-vour sûr de vouloir annuler cette soumission?",
  photos_x_rays_plus: "Photos + radiographies",
  first_name_required: "Le prénom est requis",
  last_name_required: "Le nom de famille est requis",
  gender_required: "Le sexe est requis",
  shipping_address_required: "L'adresse de livraison est requise",
  case_type_required: "Le type de cas est requis",
  photos_x_rays: "Photos et radiographies",
  front_smiling: "Frontal souriant",
  front_pose: "Pose frontale",
  side_profile: "Profil latéral",
  panoramic_xray: "radiographie panoramique",
  panoramic: "Panoramique",
  upper_occlusal: "Occlusale supérieure",
  frontal: "Frontal",
  lower_occlusal: "Occlusale inférieure",
  cephalometric_xray: "Radiographie céphalométrique",
  cephalometric: "Céphalométrique",
  optional: "optionnel",
  right_buccal: "Buccale droite",
  left_buccal: "Buccale gauche",
  uploading: "Chargement",
  image_required: "l'image est requise",
  chief_complaint: "Plainte principale",
  treat_arch: "Traiter Arcade",
  upper: "Supérieure",
  lower: "Inférieure",
  upper_and_lower: "Inférieure & supérieure",
  wear_cycle: "Cycle d'utilisation",
  treatment_summay_and_instruction:
    "Résumé du traitement et instructions spécifiques",
  teeth_movement_restrictions: "Restrictions de mouvement des dents",
  go_to_case_summary: "Aller au résumé du cas",
  or: "ou",
  impression: "empreinte",
  select_advanced_options: "sélectionner les options avancées",
  chief_complaint_required: "La plainte principale est requise",
  treat_arches_required: "Traitement des arcades requis",
  summary_and_special_instructions_required:
    "résumé et instructions spéciales sont requis",
  upload_3d_scans: "Charger scans 3D",
  _3rd_party_integration: "Intégration tierce",
  impression_pickup: "Réception des empreintes",
  upload_impressions: "Charger les empreintes",
  upload_upper_arch: "Charger Arcade supérieure",
  upload_lower_arch: "Charger arcade inférieure",
  upper_arch_required: "L'arcade supérieure est requise",
  lower_arch_required: "L'arcade inférieure est requise",
  additional_comments: "Commentaires supplémentaires",
  use_this_option_for_third_party_integration_systems_only:
    "Utilisez cette option uniquement pour les systèmes d'intégration tiers",
  provide_links_to_3rd_party_integration_systems:
    "Fournir des liens vers des systèmes d'intégration tiers",
  _3shape_communicate_and_other_services:
    "(Ex.3shape communique et autres services)",
  please_choose_one_of_impression_methodes:
    "Veuillez choisir l'une des méthodes d'impression",
  please_choose_one_to_upload: "Veuillez en seleccionner une pour télécharger",
  extraction: "Extraction",
  ipr: "IPR",
  inter_proximal_reduction: "Réduction inter-proximale",
  pontics: "Pontiques",
  overcorrection: "Sur-correction",
  passive_aligner: "Aligneur passif",
  tooth_size_discrepancy: "Écart de taille de dent",
  torque_enhancers: "Renforts de couple",
  bite_ramps: "Rampes d'occlusion",
  arch_expansion: "Expansion de l'arcade",
  elastics: "Élastiques",
  malocclusion: "Malocclusion",
  crossbite: "Occlusion croisée",
  spacing: "Espacement",
  classII: "Classe II",
  classIlI: "Classe IlI",
  openbite: "Béance",
  deepbite: "Occlusion profonde",
  midline: "Ligne médiane",
  crowding: "Encombrement dentaire",
  use_elastics_when_correcting: "Utiliser des élastiques lors de la correction",
  placement: "Placement",
  treatment_options: "Options de traitement",
  expansion_of_the_upper_arch: "Expansion de l'arcade supérieure",
  constriction_of_the_lower_arch:
    "Rétrecissement de l'arcade inférieure 'peut nécessiter IPR' ",
  combination_of_upper_expansion_and_lower_constriction:
    "Combinaison d'expansion supérieure et de rétrécissement inférieur 'peut nécessiter IPR'",
  close_all_spaces: "Fermer tous les espaces",
  by_retraction_of_anteriors: "Par rétraction des antérieures",
  by_mesialisation: "Par Mesialisation",
  combination_of_both: "Combinaison des deux",
  partially_close_and_follow_with_restorative_or_prosthodon_tics:
    "Fermer partiellement et continuer aver restauration ou prothèses",
  ipr_opposing: "IPR opposé",
  extraction_of_two_upper_bicuspids:
    "Extraction de deux bicuspides supérieures",
  extraction_of_upper_and_lower_bicuspids:
    "Extraction des bicuspides supérieures et inférieures",
  upper_arch_distalization: "Distalisation de l'arcade supérieure",
  sequential_distalization_may_increase_number_of_steps_elastics_using_May_necessitate_extraction_of_wisdoms_if_present:
    "distalisation séquentielle - peut augmenter le nombre d'étapes - usage d' élastiques ', peut nécessiter l'extraction des dents de sagesses",
  lower_arch_mesialization: "Mésialisation de la voûte inférieure",
  sequential_mesialization_may_increase_number_of_steps_elastics_using:
    "Mésialisation séquentielle - peut augmenter le nombre d'étapes - usage d'élastiques",
  leaving_the_molars_in_classII_and_doing_posterior_reproximation:
    "Laisser les molaires en Classe II et faire une reproximation postérieure (IPR distale de la canine à la molaire) au besoin pour améliorer la relation canine.",
  maintain_classII_with_esthetic_anterior_alignment_only:
    "Maintenir la classe II avec un alignement antérieur esthétique uniquement",
  advance_upper_sby_aligners_and_classIII_elastics:
    "Avancer les supérieures par des aligneurs et élastiques de Classe III",
  retract_lowers_by_extraction_and_classIII_elastics:
    "Rétracter les inférieures par extraction et élastiques de Classe III",
  retract_lowers_after_ipr: "Rétracter les inférieures après IPR",
  esthetic_alignment_only: "Alignement esthétique uniquement",
  pre_surgical_alignment_followed_by_surgery:
    "Alignement pré-chirurgical suivi d'une intervention chirurgicale",
  extrude_anterior_teeth_only: "Extruder les dents antérieures uniquement",
  intrude_posterior_teeth_only: "Intruder les dents postérieures uniquement",
  both: "Les deux",
  intrude_upper_anteriors:
    "Intruder les parties antérieures supérieures 'peut nécessiter IPR'",
  intrude_lower_anteriors:
    "Intruder les parties antérieures inférieures 'peut nécessiter des IPR'",
  extrude_posteriors: "Extrusion des postérieures",
  upper_midline: "Ligne médiane supérieure",
  lower_midline: "Ligne médiane inférieure",
  centered: "Centrée",
  shifted_left: "Décalée vers la gauche",
  shifted_right: "Décalée vers la droite",
  if_shifted_displacement_in_mm: "Si décalée, déplacement en mm",
  treat_midline_by: "Traitez la ligne médiane par",
  accept_resulting_midline: "Accepter la ligne médiane résultante",
  maintain_intial_midline: "Maintenir la ligne médiane initiale",
  improve_with_ipr: "Améliorer avec IPR",
  improve_with_extraction: "Améliorer avec extraction",
  use_extraction_when_correcting: "Utiliser l'extraction lors de la correction",
  resolve_with_proclination: "Résoudre avec Proclination",
  resolve_with_expansion: "Résoudre avec l'expansion",
  resolve_with_ipr: "Résoudre avec IPR",
  resolve_with_distalisation: "Résoudre avec Distalisation",
  save: "Sauvegarder",
  extract: "Extraire",
  use_ipr_for_this_case: "Utiliser IPR pour ce cas",
  use_attachments_for_this_case: "Utiliser des taquets pour ce cas",
  attachments_timing: "Calendrier des taquets",
  use_pontics_for_this_case: "Utiliser des pontiques pour ce cas",
  use_overcorrection_for_thiscase: "Utiliser la sur-correction dans ce cas",
  add_details: "ajouter des détails",
  address_discrepancy_for_this_case: "Incompatibilité d'adresse pour ce cas",
  leave_spaces_around_the_lateral: "Laisser des espaces autour des latérales",
  leave_space_distal_to_lateral: "Laisser espace de distal à latéral",
  other: "Autre",
  use_passive_aligners_for_this_case:
    "Utiliser des aligneurs passifs pour ce cas",
  add_passive_aligners_at_the_beginning:
    "Ajouter des aligneurs passifs au début",
  add_passive_aligners_at_the_end: "Ajouter des aligneurs passifs à la fin",
  use_torque_enhancers_for_this_case:
    "Utiliser des renforts de couple pour ce cas",
  use_bite_ramps_for_this_case: "Utiliser des rampes d'occlusion pour ce cas",
  incisors: "Incisives",
  incisors_and_canines: "Incisives et canines",
  use_arch_expansion_for_this_case:
    "Utiliser expansion de l'arcade pour ce cas",
  increasing_the_arch_width_between_canines_remolars_and_molars:
    "Augmentation de la largeur de l'arcade entre les canines, les prémolaires et les molaires",
  increasing_the_arch_width_between_canines_premolars_only:
    "Augmentation de la largeur de l'arcade uniquement entre les canines et prémolaires.",
  increasing_the_arch_width_between_premolars_and_molars_only:
    "Augmentation de la largeur de l'arcade entre les prémolaires et les molaires uniquement",
  buttons: "Boutons",
  cuts: "Coupures",
  case_submission: "Soumission de cas",
  patient_information: "Information du patient",
  impressions: "Empreintes",
  prescription: "Prescription",
  advanced_treatment_options: "Options avancées de traitement",
  summary: "Résumé",
  details: "Détails",
  records: "Données du dossier",
  inbox: "Boîte de réception",
  history: "Histoire",
  full_name: "Nom et prénom",
  uuid: "UUID",
  case_type: "Type de cas",
  case_status: "Situation du cas",
  loading_document: "Chargement du document",
  download_treatment_pdf: "Télécharger le PDF du traitement",
  archive_a_case: "Archiver un cas",
  adjustment: "Ajustement",
  request_replacement_aligners: "Demander des aligneurs de remplacement",
  request_retainer: "Demander un appareil de contention",
  pause: "Pauser",
  resume: "Reprendre",
  mark_as_completed: "Marquer comme terminé/complet",
  restore_a_case: "Restaurer un cas",
  confirmation_archive: "êtes vour sûr de vouloir archiver ce cas?",
  cancel: "Annuler",
  new_adjustment: "Nouvel ajustement",
  adjusment_info: "Informations d'ajustement",
  current_step_numbers: "Numéro d'étape actuelle",
  last_step_that_was_tracking: "Dernière étape de suivi",
  upper_step: "Étape supérieure #",
  lower_step: "Étape inférieure #",
  reason_for_submission: "Raison de la soumission",
  additional_instructions: "Informations supplémentaires",
  save_next: "Enregistrer et suivant",
  upload_digital_impressions: "Charger empreintes numériques",
  upload_upper_and_lower_3d_models_by_clicking_the_images_on_the_right:
    "Charger les modèles 3D supérieurs et inférieurs en cliquant sur les images à droite",
  other_digital_means: "Autres méthodes numériques",
  use_this_option_for_third_party_integration_systemsonly:
    "Utiliser cette option uniquement pour un système d'intégration tiers (par exemple: 3shape communique)",
  arrange_pickup: "Organiser le retrait de l'envoi",
  use_this_option_to_send_traditional_silicon_impressions_to_eon_via_courier:
    "Utiliser cette option pour envoyer des empreintes  traditionnelles au silicium à eon par courrier",
  back: "retour",
  optional_photos: "Photos optionnelles",
  click_on_an_image_to_upload: "Cliquer sur une image pour charger",
  submit: "Soumettre",
  replacement_aligners_request: "Demande d'aligneurs de remplacement",
  new_replacements: "Nouveaux Remplacements",
  requested_replacement: "Remplacement demandé",
  step_number: "Numéro d'étape",
  submit_request: "Envoyer la demande",
  the_following_replacement_aligners_have_been_requested:
    "Les aligneurs de remplacement suivants ont été demandés",
  new_retainers: "Nouveaux appareils de contention",
  requested_retainers: "Appareils de contention demandés",
  sets_number: "Nombre de series",
  the_following_retainers_have_been_requested:
    "Les appareils de contention ont été demandés",
  pause_aligner_case: "Pauser ce cas d'aligneurs",
  confirmation_pause: "Êtes-vous sûr de vouloir suspendre ce cas d'aligneur?",
  resume_aligner_case: "Reprendre ce cas d'aligneurs",
  confirmation_resume: "Êtes-vous sûr de vouloir reprendre ce cas d'aligneurs?",
  case_rejected_for_the_following_reasons:
    "Cas rejeté pour les raisons suivantes :",
  fix: "Solucionner",
  _3D_viewer: "3D Viewer",
  copy_link_to_share: "Copier le lien pour partager",
  copy: "Copier",
  inactive: "Inactif",
  title: "Titre",
  start_together: "Commencer ensemble",
  technician_notes: "Notes du technicien :",
  adjustment_reason: "Raison de l'ajustement",
  ipr_file: "Fichier IPR",
  sps_file: "Fichier SPS",
  upper_steps: "Étapes supérieures",
  lower_steps: "Étapes inférieures",
  upper_over_correction_steps: "Étapes sur-correction supérieure",
  lower_over_correction_steps: "Étapes de sur-correction inférieure",
  case_progress: "Avancement du cas",
  stage: "Stade",
  expected_delivery_date: "Date de livraison prévue",
  airway_bill: "Lettre de transport aérien #",
  main_impressions_method_selected:
    "Méthode principale d'empreintes sélectionnée",
  no_impressions_were_uploaded_to_access:
    "Aucune empreinte n'a été téléchargée dans Access.",
  impressions_note_sent_by_dr: "Note d'empreintes envoyée par le Dr: ",
  type_Your_message: "Saisir votre message",
  send: "Envoyer",
  allowed_file_types_are_word_documents_images_and_PDFs:
    "* Les types de fichiers autorisés sont les documents Word, les images et les PDF.",
  terms_and_agreements: "Termes et Accords",
  updated_may_10_th_2018: "Updated May 8th, 2018_fr",
  agree: "Accord",
  your_case_has_been_submitted: "Votre cas a été soumis",
  submission_PDF: "Envoyer PDF",
  submit_another_case: "Soumettre un nouveau cas",
  request_new_retainer_has_been_submitted:
    "La demande d'un nouvel appareil de contention été envoyée",
  aligner_case_has_been_paused: "Le cas de l’aligneurs a été suspendu",
  aligner_case_has_been_resumed: "Le cas de l'aligneurs a été repris",
  request_new_replacment_has_been_submitted:
    "La demande de nouveau remplacement a été soumise",
  new_adjustment_has_been_submitted:
    "La demande de nouvel ajustement a été soumise",
  aligner_case_has_been_marked_as_completed:
    "Le cas d'aligneur a été marqué comme terminé",
  archived_case_successfully: "Cas archivé avec succès",
  restored_case_successfully: "Cas restauré avec succès",
  treatment_Setup_has_been_approved:
    "La configuration du traitement a été approuvée",
  treatment_Setup_has_been_disapproved:
    "La configuration du traitement a été refusée",
  updated_address_successfully: "Adresse mise à jour avec succès",
  created_new_address_successfully: "Nouvelle adresse créée avec succès",
  update_doctor_preferences_successfully:
    "Mise à jour des préférences du Médecin avec succès",
  deleted_address_successfully: "Adresse supprimée avec succès",
  updated_user_info_successfully:
    "Informations utilisateur mises à jour avec succès",
  link_is_copied: "Le lien est copié",
  nothing_is_changed: "Rien n'a changé",
  please_check_values: "veuillez vérifier les données",
  "aligner_case.created": "Le cas a été créé",
  "aligner_case.changed_package" : "Arch has been changed_fr",
  "aligner_case.case_type_changed" : "Case type has been changed_fr",
  "aligner_case.submit": "Le cas a été soumis",
  "aligner_case.courier_notified": "Le retrait a été organisé",
  "aligner_case.rejected": "Les données ont été rejetées",
  "aligner_case.updated": "Le cas a été mis à jour",
  "aligner_case.approved": "Les informations du dossier ont été approuvées",
  "aligner_case.comment_posted": "Le commentaire a été publié",
  "aligner_case.doctor_notified_of_ts":
    "notifié de la configuration du traitement",
  "aligner_case.rejected_ts": "La configuration du traitement a été rejetée",
  "aligner_case.approved_ts": "La configuration du traitement a été approuvée",
  "aligner_case.cleared_by_accounting":
    "Le cas a été approuvé par comptabilité",
  "aligner_case.paused": "Le cas a été suspendu",
  "aligner_case.resumed": "Le cas a été repris",
  "aligner_case.shipped": "Les aligneurs ont été marqués comme expédiés",
  "aligner_case.retainer_requested": "Appareil de contention demandé",
  "aligner_case.replacement_aligners_requested":
    "Les aligneurs de remplacement ont été demandés",
  "aligner_case.replacement_aligners_shipped":
    "Les aligneurs de remplacement ont été expédiés",
  "aligner_case.adjustment_requested": "Ajustement demandé",
  "aligner_case.retainer_shipped": "Appareil de contention expédié",
  "aligner_case.case_completed": "Case Completed",
  "aligner_case.expired_and_dormant": "System Marked Case Expired",
  "aligner_case.retainer_manufactured":"Retainer Manufactured",
  "aligner_case.overriden_by_admin":"Records Overridden by Admin  ",
  "aligner_case.manufactured": "Case Manufactured ",
  "aligner_case.support":"Support",
  "aligner_case.expired":"Case has been expired",
  "aligner_case.received_impressions":"Received Impressions",
  "aligner_case.pending_pre_production_process":"Pending Pre Production Process",
  "aligner_case.replacement_aligners_manufactured":"Replacement Aligners Manufactured",
  "aligner_case.converted_to_hybrid":"Case has been converted to hybrid",
  "aligner_case.cleared_by_manager":"Case Cleared by Manager",
  "aligner_case.transferred_case":"Transferred Case",
  "aligner_case.treatment_setup_generated":"Treatment Setup Generated",
  "aligner_case.exceeded_month_cap":"Case Exceeded month cap",
  "aligner_case.midcourse_adjustment_requested":"Midcourse Adjustment Requested",
  "aligner_case.refinement_requested":"Refinement Requested",
  "aligner_case.cancelled":"Case Cancelled",
  notifications: "Notifications",
  case: "Cas",
  photos: "Photos",
  x_rays: "Radiographies",
  rejection_reasons: "raisons de rejet",
  typeHere: "Type here ..._fr",
  Use_Extraction_for_this_case: "Use Extraction for this case _fr",
  Extraction_will_not_be_applied_to_this_case:
    "Extraction will not be applied to this case._fr",
  Eon_to_recommend_best_settings_for_this_treatment:
    "Eon to recommend best settings for this treatment._fr",
  IPR_will_not_be_applied_to_this_case:
    "IPR will not be applied to this case._fr",
  Overcorrection_will_not_be_applied_to_this_case:
    "Overcorrection will not be applied to this case ._fr",
  Passive_aligner_will_not_be_applied_to_this_case:
    "Passive aligner will not be applied to this case._fr",
  Bite_ramps_will_not_be_applied_to_this_case:
    "Bite ramps will not be applied to this case._fr",
  Tooth_size_discrepancy_will_not_be_applied_to_this_case:
    "Tooth size discrepancy will not be applied to this case._fr",
  Use_elastics_for_this_case: "Use elastics for this case _fr",
  Elastics_will_not_be_applied_to_this_case:
    "Elastics will not be applied to this case._fr",
  Arch_expansion_will_not_be_applied_to_this_case:
    "Arch expansion will not be applied to this case._fr",
  Attachments_will_not_be_applied_to_his_case:
    "Attachments will not be applied to this case._fr",
  Pontics_will_not_be_applied_to_this_case:
    "Pontics will not be applied to this case._fr",
  Torque_enhancers_will_not_be_applied_to_this_case:
    "Torque enhancers will not be applied to this case._fr",
  Combination_of_upper_and_lower_anteriors_intrusion:
    "Combination of upper and lower anteriors intrusion_fr",
  extraction_note:
    "This feature is used for teeth that need to be extracted during treatment._fr",
  IPR_note:
    "This feature is used to create space for tooth alignment or reshape the contact area between neighboring teeth._fr",
  attachments_note:
    "This feature allows you to choose which step they’d like to place attachments on. The tooth selector allows you to decide which teeth you don’t want attachments on._fr",
  pontics_note:
    "This feature allows you to pinpoint which teeth you would like to have pontics for._fr",
  overCorrection_note:
    "This feature allows you to plan movements beyond the ideal final position to compensate for any expected loss of tracking in a certain tooth/teeth._fr",
  passive_aligner_note:
    "This feature allows you to keep the teeth in place without movement. It is usually used when treatment of one arch is completed and the other arch still needs treatment._fr",
  tooth_size_discrepancy_note:
    "This feature is used when there is a lack of harmony between the mesiodistal widths of individual teeth or groups of teeth when related to their functional counterparts of the opposing arch. It allows you to decide how to treat tooth size discrepancy._fr",
  torque_enhancers_note:
    "This feature delivers force on teeth at a specified position. This feature is used to correct the inclination of the front teeth to their ideal position._fr",
  BiteRamps_note:
    "Use bite ramps to intrude anterior teeth, dis-occlude posterior teeth and apply intrusive forces on anterior teeth._fr",
  Archexpansion_note:
    "This feature lets you decide if you would like to expand the arches and what teeth to include in this expansion._fr",
  Elastics_note:
    "This feature allows you to decide if you would like to use elastics for this specific case and on which teeth you would like these elastics to be._fr",
  teeth_movement: "Teeth Movement_fr",
  hybrid_treatment: "Hybrid Treatment_fr",
  archExpansion: "Arch Expansion_fr",
  case_details: "Détails du cas",
  patient_management_portal: "Patient Management Portal_fr",
  see_all_notifications: "See all notifications_fr",
  submit_aligner_case: "Submit aligner case_fr",
  submit_retainer_case: "Submit Retainer Case_fr",
  case_dashboard: "Case Dashboard_fr",
  approve_treatment_setup: "Approve Treatment Setup_fr",
  general: "General_fr",
  use_passive_aligners: "Use passive aligners_fr",
  one_week: "1 Week_fr",
  two_week: "2 Weeks_fr",
  use_pontics: "Use pontics_fr",
  use_overcorrection: "Use overcorrection",
  beta: "Beta_fr",
  consider_hybrid_treatment_for_difficult_movements:
    "Consider Hybrid treatment for difficult movements_fr",
  ipr_restrictions: "IPR restrictions_fr",
  use_ipr: "Use IPR_fr",
  use_attachments: "Use attachments_fr",
  attachment_restrictions: "Attachment restrictions_fr",
  add_attachments_on_step: "Add attachments on step_fr",
  add_ipr_on_step: "Add IPR on step_fr",
  add_ipr_step: "Add IPR Step_fr",
  add_attachment_step: "Add Attachment Step_fr",
  edit: "Edit_fr",
  Set_tooth_rate_movement_for_this_case:
    "Set tooth rate movement for this case?_fr",
  Normal_Rate: "Normal Rate_fr",
  Slow_Rate: "Slow Rate_fr",
  toothRateMovement: "Tooth movement rate_fr",
  toothRateMovement_note:
    "This will make the treatment longer - recommended in patients with compromised periodontal situation or in patients with altered bone metabolism._fr",
  hybridTreatment: "Hybrid Treatment_fr",
  change_image: "Change image_fr",
  name: "Name_fr",
  email_notifications: "Email Notifications_fr",
  dental_notation: "Dental Notation_fr",
  date_format: "Date Format_fr",
  phone_number: "Phone Number_fr",
  language: "Language_fr",
  universal: "Universal Notation System_fr",
  palmer: "Palmer Notation System_fr",
  fdi: "FDI notation system_fr",
  unsubscribe: "Unsubscribe_fr",
  en: "English",
  fr: "French",
  package_type: "Package Type_fr",
  Hybrid_treatment_will_b_considered_for_difficult_movements_in_this_case:
    "Hybrid treatment will be considered for difficult movements in this case._fr",
  Hybrid_treatment_will_not_be_applied_this_case:
    "Hybrid treatment will not be applied to this case._fr",
  Use_Hybrid_treatment_for_this_case: "Use Hybrid treatment for this case_fr",
  package_type_lite_note:
    "A total of 14 steps (14 upper and 14 lower aligners) | Includes a retainer and 6 refinement steps._fr",
  package_type_lite_single_arch_note:
    " A total of 14 steps (14 upper OR 14 lower aligners single arch) | Includes a retainer and 6 refinement steps._fr",
  package_type_unlimited_note:
    "Unlimited number of steps. Includes unlimited number of stages, unlimited adjustments between stages and retainer._fr",
  file_not_supported: "sorry this file type is not supported_fr",
  send_teeth_impressions: "Send teeth impressions_fr",
  provide_links_to_third_party:
    "Provide links to third party integration systems (Ex. 3Shape Communicate)_fr",
  paste_link: "Paste link_fr",
  patient_email: "Patient email_fr",
  treatment_summary: "Treatment summary_fr",
  treatment_type: "Treatment type_fr",
  exit: "Exit_fr",
  case_submitted_successfully: "Case submitted successfully_fr",
  download_case_details: "Download case details_fr",
  homepage: "Homepage_fr",
  aligner_case_submission: "Aligner Case Submission_fr",
  submission_complete: "Submission Complete_fr",
  confirmation_restore: "Are you sure you want to restore this case?_fr",
  restore: "Restore_fr",
  cases_review: "Cases review_fr",
  Warning: "Warning_fr",
  Warning_message_here: "Warning message here!_fr",
  Success: "Success_fr",
  Archived_case_successfuly: "Archived case successfuly_fr",
  Attention_needed: "Attention needed_fr",
  Message_goes_here: "Message goes here!_fr",
  patient: "Patient_fr",
  download_pdf: "Download PDF_fr",
  adjust: "Adjust_fr",
  continue_hybrid_submission: "Continue hybrid submission_fr",
  request_replacement: "Request replacement_fr",
  upload_new_bite_record: "Upload new bite record_fr",
  confirmation_mark_case_as_completed:
    "Are You Sure you want to Mark this Aligner Case as Completed ?_fr",
  Perform_IPR_on_step: "Perform IPR on step_fr",
  Perform_attachments_on_step: "Perform Attachments on step_fr",
  add_attachments_step: "add attachments step_fr",
  mark_aligner_case_as_completed: "Mark Aligner Case as Completed_fr",
  mark: "Mark_fr",
  choose_your_device: "choose your device_fr",
  write_something_here: "Write something here_fr",
  lower_impression: "Lower Impression_fr",
  upper_impression: "Upper Impression_fr",
  view_treatment_setup: "View Treatment Setup_fr",
  share: "Share_fr",
  show_revised_data: "Show revised data_fr",
  copy_link: "Copy Link_fr",
  link_valid: "*This link is valid for 30 days only!_fr",
  case_no: "Case No._fr",
  type: "Type_fr",
  date: "Date_fr",
  actions: "Actions_fr",
  terms_title_1: "eon Dental (Conditions Tarifaires)",
  terms_title_2:
    "Conditions générales et tarifaires de eon Dental («Conditions»)",
  terms_introduction: "INTRODUCTION :",
  terms_introduction_content:
    "Ces conditions sont les seules conditions applicables aux contrats entre eon Dental ou l'une de nos filiales, selon le cas («nous» / «nous» / «eon»), et le Client («notre Client» / «Client») pour les achats de Produits («Produits» ou d'un «Produit» individuel) et / ou de Services associés («Services»), sauf si nous en avons expressément convenu autrement par écrit. Ces conditions s'appliquent à tous les aspects de la relation entre eon et le Client à partir du moment où le Client accède pour la première fois à nos systèmes et logiciels, que ce soit pour passer une Commande de Produits ou de Services («Commandes Client»), ou pour fournir des informations sur le patient dans le but de passer une Commande, ou pour accéder à des  éléments marketing ou marque eon. Aucune autre condition est applicable à la relation entre eon et notre Client pour l'achat de Produits ou de Service, implicite ou non, à l'exception des conditions expressément convenues par écrit par eon.",
  terms_title_3: "COMMANDES ET CONTRATS DES CLIENTS:",
  terms_orders_contract:
    "L’accès du Client aux Systèmes informatiques d'eon ou l’ utilisation du logiciel eon, y compris à travers le site web eon, le magasin en line de eon ou le Webstore, et / ou du logiciel de visualisation d’Accès et de Configuration de Traitement eon Médecin  («eon Systems»), doit être autorisé par eon comme condition d'une relation de bonne foi avec eon, pour la Commande de Produits ou Service offerts par eon. L'utilisation du logiciel Access est soumise aux conditions de la licence du logiciel eon. Les présentes conditions régissent l'utilisation, par le Client, des Systèmes eon,  et les processus et critères de Commande de Produits et Services.  Client s’engage à les respecter en contrepartie du droit d’accès aux Systèmes eon.",
  terms_orders_contract2:
    "Les Commandes des Clients sont passées ou soumises à l'aide d'eon Access et sont considérées comme passées ou soumises (en attente d'acceptation par eon) :  si l'Approbation de Dossier («Approbation de Dossier») est requise. Le prix applicable à une Commande Client sera le prix en vigueur au moment de passer de la Commande Client. Les Commandes Client sont acceptées par un courriel de confirmation ou selon les indications d’eon Access.",
  terms_orders_contract3:
    "La Commande de Produits d’aligneurs ou des appareils de contention sont soumis à une livraison soit dans le pays dans lequel le patient désigné («Patient») pour ces aligneurs reçoit un Traitement, soit à l'adresse du Médecin indiqué dans le profil d'accès aux Médecins.",
  terms_who_is_our_customer: "QUI EST NOTRE CLIENT?",
  terms_who_is_our_customer_content:
    "Les Clients d'eon Dental sont les Médecins dont l'adresse électronique est utilisée pour accéder au Système protégé eon («Médecin eon»). Si les factures sont au nom d’un cabinet, une société, une personne morale ou une autre entité juridique pour ou avec laquelle travaille eon et / ou que ce cabinet paie les factures d'eon, eon est en droit de considérer que c’est le Médecin eon qui utilise l’eon Access et passe des Commandes Client autorisé par, et au nom de, cette entité (le «Cabinet»). Dans ces circonstances, le Cabinet sera également un Client eon,  et le Médecin eon et le Cabinet seront conjointement et solidairement responsables des obligations énoncées dans les présentes Conditions, celles-ci étant les obligations du «Client».",
  terms_who_is_our_customer_content2:
    "Le Médecin eon et le cabinet doivent lire et comprendre ces conditions avant de télécharger le matériel ou logiciel, saisir des informations sur le patient ou de passer une Commande Client, car dans tous les cas, un contrat sera formé et le Client s’engagera à ces conditions qui régiront que Contrat.",
  terms_who_is_our_customer_content3:
    "Le contrat Align comporte également des clauses de «Limitation de responsabilité», «Date de paiement de la facture» et «Normes artistiques et publicitaires», «Loi et compétence applicables aux présentes conditions».",
  terms_products: "PRODUITS:",
  terms_product_eon_unlimited: "Produit: Eon Unlimited",
  terms_product_eon_unlimited_content:
    "Nombre d'aligneurs: Un nombre illimité d'aligneurs à utiliser durant 5 ans sur la base d’un cycle d'utilisation de 2 semaines.",
  terms_product_eon_unlimited_expiry: "Durée de validité: 60 mois",
  terms_product_eon_lite: "Produit: Eon Lite",
  terms_product_eon_lite_content:
    "Nombre d'aligneurs: 14 aligneurs supérieurs et 14 inférieurs et une finition gratuite de 6 aligneurs supérieurs et 6 inférieurs supplémentaires pour atteindre l’objectif de Traitement initial approuvé par l'équipe eon.",
  terms_product_eon_lite_expiry: "Durée de validité: 18 mois",
  terms_lite_note:
    "Remarques : Nous fournissons 6 étapes individuelles supplémentaires (1 aligneur supérieur et inférieur) gratuitement d’aligneurs de finition pour atteindre l'objectif de Traitement à condition que l'objectif de Traitement n'ait pas changé par rapport à l'objectif de Traitement initial. De plus, six étapes supplémentaires peuvent être apportées pour 300 USD pour atteindre l'objectif de Traitement s'il n'a pas été atteint après les 6 aligneurs de finition initiaux, à condition que l'objectif de Traitement initial n'ait pas changé. Tous les aligneurs seront expédiés en un seul envoi.",
  terms_product_eon_lite_single_arch: "Produit: Eon Lite Single Arch",
  terms_product_eon_lite_single_arch_content:
    "Nombre d'aligneurs: 14 aligneurs supérieurs OU 14 aligneurs inférieurs",
  terms_product_eon_lite_single_arch_expiry: "Durée de validité: 18 mois",
  terms_product_eon_lite_single_arch_price:
    "Les prix sont susceptibles d’être modifiés avec un préavis de 45 jours.",
  terms_product_eon_lite_single_arch_expiry2:
    "Durée de validité: Les boitiers Unlimited ont une durée de validité de 5 ans à compter de la date d'expédition du jeu initial d'aligneurs eon Aligner ® Unlimited, sur la base d'un cycle d'utilisation de 2 semaines.",
  terms_product_eon_lite_single_arch_expiry3:
    "Les boitiers Lite ont une durée de validité de 18 mois à compter de la date d'expédition du jeu initial d'aligneurs Aligner ® Lite et eon Aligner ® Lite Single Arch sur la base d'un cycle d'utilisation de 2 semaines.",
  terms_product_eon_lite_single_arch_expiry4:
    "Une fois que la durée de validité est expirée, toute Commande supplémentaire pour le même patient sera considérée comme un nouveau Traitement.",
  terms_refinement:
    "La finition est une option permettant de commander des aligneurs supplémentaires pour les catégories de Produits eon Aligner Lite et Lite Single Arch qui répondent aux critères cliniques de Traitement complété en 14 étapes, si un mouvement supplémentaire des dents est nécessaire pour atteindre les objectifs de Traitement initialement prévus. Pour la catégorie de Produits eon Aligner ® Lite, une étape de finition (six aligneurs supérieurs et inférieurs) est incluse dans le prix si les objectifs de Traitement n'ont pas changés. La Commande doit être reçue au moins 6 mois avant la date d'expiration du Traitement. Une seule finition par traitement peut être commandée ",
  terms_product_description: "DESCRIPTIONS DES PRODUITS",
  terms_eon_unlimited: "eon Unlimited",
  terms_eon_unlimited_content:
    "Un nombre illimité d'aligneurs à utiliser en 5 ans sur la base d'un cycle d'utilisation de 2 semaines par les patients.",
  terms_eon_lite: "eon Lite",
  terms_eon_lite_content:
    " Le Traitement comprend jusqu'à 14 aligneurs supérieurs et 14 inférieurs",
  terms_product_note_1:
    "Nous offrons gratuitement 6 étapes individuelles supplémentaires d’aligneurs de finition (1 aligneur supérieur et inférieur pour atteindre l'objectif du Traitement à condition que celui-ci n'ait pas changé par rapport à l'objectif de Traitement initial.",
  terms_product_note_2:
    "Le Client peut acheter 6 étapes supplémentaires pour atteindre l'objectif du Traitement s'il n'a pas été atteint après les 6 aligneurs de finition initiaux, à condition que l'objectif de Traitement initial n'ait pas changé.",
  terms_product_shipment: "Tous les aligneurs seront expédiés en un seul envoi",
  terms_lite_single_arch: "Eon Lite Single Arch",
  terms_lite_single_arch_content:
    "C’est un Traitement d'une seule arcade jusqu'à 14 aligneurs, puis 6 aligneurs gratuits inclus dans le paquet comme appareil de finition pour atteindre l’objectif de Traitement initial.",
  terms_discount: "SYSTÈME DE RABAIS ET PROGRAMME D'AVANTAGES CLIENT",
  terms_loyalty_program: "Programme de Fidélité ",
  terms_others: "AUTRES TERMES ET CONDITIONS",
  terms_shipment_risk: "Expédition, risques, propriété, annulations et retours",
  terms_shipment_risk_content_1:
    "Le Client est responsable de fournir toutes les informations du dossier de chaque patient («Information Requise») et d'accepter le plan de Traitement. Eon ne fabriquera les Produits qu'une fois qu'il aura reçu toutes les Informations Requises et l'acceptation de la Configuration de Traitement par le Client. Le Client doit gérer les attentes du patient du Client en ce qui concerne le calendrier de son Traitement, en particulier lorsque les retards sont causés par le Client. Les dossiers d’informations ne seront pas renvoyés au Client.",
  terms_shipment_risk_content_2: `Les Produits sont fabriqués et expédiés depuis le site de fabrication dans les 14 jours suivant l'approbation du plan de Traitement ou, pour les aligneurs de remplacement, dans les sept (7) jours après la Commande Client. Toutes les dates sont approximatives.`,
  terms_shipment_risk_content_3:
    "eon décline toute responsabilité résultant d'un retard dans la livraison des Produits.",
  terms_shipment_risk_content_4:
    "Si les Produits ne sont pas livrés, le Client informera eon qui déterminera la cause du retard et, si, conformément aux présentes Conditions, eon en a l’obligation, eon fabriquera et expédiera des Produits de remplacement comme seul recours du Client en cas de manque de livraison.",
  terms_shipment_risk_content_5:
    "Si le Client n’a pas confirmé la réception des Produits dans les cinq (5) jours suivant l’avis de la société de livraison indiquant que les Produits sont prêts à être livrés, la livraison des Produits sera considérée finalisée à 11 heures du cinquième jour après cet avis. eon facturera au Client les frais de nouvelle livraison et de stockage, y compris l'assurance, mais n'aura aucune obligation de réaliser une nouvelle livraison des Produits sept (7) jours après la notification au Client de la disponibilité des Produits pour la livraison.",
  terms_shipment_risk_content_6:
    "Les Produits seront expédiés à l'adresse de livraison choisie par le Client au moment de passer la Commande du Client à partir de la liste des adresses du Client enregistrées dans le eon Access Systems.",
  terms_shipment_risk_content_7:
    "Droits de Propriété Intellectuelle, Confidentialité et Marques commerciales",
  terms_shipment_risk_content_8:
    "Tous les droits de propriété intellectuelle , y compris tous les brevets, marques comerciales, marques de Services, dessins et modèles enregistrés,  droit de conception, droits de base de données, droits d'auteur (y compris les droits d'auteur sur les logiciels et les algorithmes informatiques), secrets commerciaux et autres informations confidentielles, savoir-faire et toute autre propriété intellectuelle et industrielle et droits de nature similaire ou correspondante, dans n'importe quelle Partie du monde) concernant ou liés aux Produits, aux Services, à toutes les données, information, logiciel d'accès, Systèmes eon, documents ou éléments préparés par eon, ou produit pour le Client ou les mises à disposition du Client, sont une propriété exclusive de eon.",
  terms_termination: "Résiliation",
  terms_termination_content_1:
    "Une fois que le Client a passé une Commande Client acceptée par eon, le Client ne peut pas annuler ou résilier sa Commande à moins qu'eon ne puisse pas fournir les Produits ou Services au Client dans un délai raisonnable.",
  terms_termination_content_2:
    "eon a le droit de donner au Client un avis écrit de résiliation de tous les contrats ou un des contrats avec effet immédiat (sans ordonnance judiciaire) si l'un des événements suivants se produit, ou eon considère, à sa discrétion exclusive, qu'ils sont susceptibles de se produire (que ce soit concernant le Client, le Cabinet, ou les deux):",
  terms_termination_content_3:
    "dans le cas de faute de paiement du Client des sommes dues à eon au moment où elles sont dues et / ou retard réitéré dans le paiement des sommes dues à eon en vertu des Accord prévus; où",
  terms_termination_content_4:
    "dans le cas où eon considère que la situation financière du Client est telle que le Client ne pourra pas payer les sommes dues à eon; où",
  terms_termination_content_5:
    "dans le cas où le cabinet cesse ses activités en tant que cabinet dentaire et / ou le Client, le Médecin, cesse de pratiquer la Médecine dentaire (que ce soit de manière permanente ou temporaire de telle façon qu'eon, à sa discrétion exclusive, considère que le Client n'est pas en mesure de traiter les patients du Client en temps opportun);",
  terms_and_conditions: "TERMES ET CONDITIONS",
  terms_and_conditions_content_1:
    "Ces termes et conditions constituent un Accord juridiquement contraignant entre «Eon Dental, BVBAa société constituée, organisée et existante selon les lois de la Belgique, ayant son siège social à Korenlei 22, 9000 Gand (Belgique), (ci-après dénommée« Eon ») ; et vous (ci-après dénommé le «Médecin»). Ils seront ci-après dénommés «Termes et Conditions» ou  «Contrat».",
  terms_and_conditions_content_2:
    "[Les Parties aux présents termes et conditions seront désignées sous le nom de «Partie» ou «Parties»]",
  terms_and_conditions_content_3:
    "Ces Termes et Conditions sont susceptibles d'être modifiés à tout moment et pour quelque raison que ce soit : eon se réserve le droit de mettre à jour ces Termes et Conditions sans préavis.",
  terms_case_acceptance: "Acceptation des cas:",
  terms_case_acceptance_content_1:
    "Tous les cas sont sujets à l'acceptation par Eon par écrit, après leur présentation à travers le «Formulaire de Soumission de cas Eon», ou selon la manière et le format prescrits par Eon ponctuellement,",
  terms_case_acceptance_content_2: "Les cas sont acceptés si:",
  terms_case_acceptance_content_3:
    "Le Formulaire de Soumission de cas est exact et complet;",
  terms_case_acceptance_content_4:
    "Les dossiers d'accompagnement du patient sont exacts et complets; et",
  terms_case_acceptance_content_5:
    "Il n'y a pas d'instructions spéciales qui nécessiteraient une clarification ou une décision du Médecin",
  terms_case_acceptance_content_6:
    "Le paiement est dû immédiatement lorsque le cas est accepté par Eon.",
  terms_case_acceptance_content_7:
    "S'il y a une carte de crédit enregistrée dans le dossier, le montant payé sera celui du prix fixé au moment de la Configuration de Traitement.",
  terms_case_acceptance_content_8:
    "Les cas qui ne sont pas automatiquement acceptés ne sont pas facturés. Dans ce cas, le Médecin sera contacté pour consultation.",
  terms_price_and_payment: "Prix et paiement:",
  terms_price_and_payment_content_1:
    "Les prix doivent être ceux que Eon spécifie sur la base d'une liste de prix publiée par Eon ou ses revendeurs, ou à la date d'acceptation de la Configuration de Traitement par le Médecin. Eon se réserve le droit de mettre à jour la liste de prix occasionnellement sans préavis. Les prix n'incluent pas les droits de douane, la taxe de vente ou toute autre taxe perçue par une autorité gouvernementale.",
  terms_price_and_payment_content_2:
    "Sauf Accord écrit contraire des deux Parties, le paiement du Cas commandé par le Médecin sera effectué au moment de l'Acceptation du Cas par Eon.",
  terms_cancellation_and_refunds: "Annulation et remboursements:",
  terms_cancellation_and_refunds_content_1:
    "Si le Médecin annule un cas avant la livraison de la Configuration de Traitement, le Médecin n'encourra aucun frais d'annulation.",
  terms_cancellation_and_refunds_content_2:
    "Pour les cas annulés après la livraison de la Configuration de Traitement, mais avant l'acceptation par le Médecin de la Configuration de Traitement, des frais d'annulation de 195 USD seront facturés au Médecin.",
  terms_cancellation_and_refunds_content_3:
    "La totalité des frais seront facturés si l’annulation intervient après la livraison de la Configuration de Traitement et après l'acceptation du Médecin.",
  terms_cancellation_and_refunds_content_4:
    "Les frais de laboratoire pour les cas annulés avant la livraison de la Configuration de Traitement seront remboursés en totalité.",
  terms_cancellation_and_refunds_content_5:
    "Les frais de laboratoire pour les cas annulés après la livraison de la Configuration de Traitement seront crédités sur le compte du Médecin moins les frais d'annulation applicables.",
  terms_shipment_and_delivery: "Expédition et livraison:",
  terms_shipment_and_delivery_content_1:
    "Les aligneurs ne seront pas fabriqués tant que le Médecin n'aura pas approuvé la Configuration de Traitement. Le Médecin recevra la Configuration de Traitement  dans les 2 à 3 semaines suivant la soumission du cas et l'acceptation par Eon. Les aligneurs de la première étape seront fabriqués et expédiés dans les 2 à 3 semaines suivant l'approbation par le Médecin de la Configuration de Traitement. Les étapes suivantes seront expédiées automatiquement. Leur livraison sera calculée pour répondre au cycle d'utilisation de 2 semaines / 14 jours par aligneur. Les étapes peuvent être expédiées dès 4 semaines avant leur date d’utilisation pour laisser au Médecin le temps de vérifier la réception de l'envoi, d'examiner les étapes à venir et de programmer le patient.",
  terms_shipment_and_delivery_content_2:
    "Les dates de livraison sont des estimations. Eon ne sera pas responsable des dommages, pertes ou coûts encourus par le Médecin si Eon ne respecte pas les dates de livraison estimées. Le Titre des Produits expédiés en vertu du présent contrat et le risque de perte ou de dommage pendant le transport passe d'Eon au Médecin lors de la livraison à l'adresse indiquée par le Médecin. L'expédition des aligneurs et des informations requises pour les soumissions de cas sont comprises dans les frais de laboratoire sans frais supplémentaires. Le retard dans la livraison des Produits ne dégage en aucun cas le Médecin de ses obligations de réaliser le paiement des Produits.",
  terms_shipment_and_delivery_content_3:
    "Si le Médecin cause ou demande un retard dans l'expédition sans aucune faute ou négligence pour la part d'Eon, le risque de perte ne pourra plus être pris en compte, à partir du moment où Eon notifie au Médecin que l'expédition est prête. Si Eon engage des frais et dépenses en raison d'un retard d'expédition pour les raisons décrites dans ce paragraphe, ces frais seront à la charge du Médecin.",
  terms_records: "Dossiers de données:",
  terms_records_content:
    "Le Médecin ne doit pas soumettre les originaux des données du dossiers à Eon. Les dossiers soumis à Eon deviennent la propriété d'Eon et ne seront pas renvoyés au Médecin. Le Médecin doit se charger d’obtenir le consentement du patient pour fournir les dossiers médicaux de ce patient à Eon, si nécessaire, pour le traitement de ce patient par le Médecin.",
  terms_doctor_representations: "Déclaration de compétences du Médecin:",
  terms_doctor_representations_content:
    "Le Médecin déclare et garantit à Eon que: (i) Le Médecin est autorisé à pratiquer la médecine dentaire et / ou l'orthodontie au moment où le traitement est fourni (ii) Le Médecin possède la formation, l'expertise et / ou l'expérience appropriées pour exécuter les procédures associées et / ou  utiliser les Produits d'alignement Eon clear; (iii) l’utilisation des Produits Eon par le Médecin sera conforme à toutes les normes médicales et dentaires applicables, et aux spécifications des Produits Eon; et (iv) le Médecin a obtenu un formulaire de consentement éclairé signé par chaque patient traité par le Médecin à l'aide des Produits d'alignement transparent Eon. Le formulaire sera fourni par le Médecin et devra être conforme aux lois locales régissant le Médecin.",
  terms_warranties_and_disclaimer: "Garanties et avis de non-responsabilité:",
  terms_warranties_and_disclaimer_content_1:
    "Eon garantit que sa Configuration de Traitement: (i) sera conforme aux spécifications fournies par le Médecin par le biais du Formulaire de Soumission de cas et contenues dans la Configuration de Traitement approuvée par le Médecin; et (ii) est exempt de défauts de matériaux et de fabrication.",
  terms_warranties_and_disclaimer_content_2:
    "Eon ne sera pas responsable (i) des défauts causés par la négligence ou une mauvaise utilisation de ses Produits par un tiers, (ii) de tout Produit qui a été altéré ou modifié de quelque manière que ce soit par un individu ou une entité autre que Eon, ou (iii) de tout Produit utilisé en combinaison avec d'autres Produits tiers. De plus, Eon ne sera pas responsable des défauts résultant des spécifications ou des instructions du Médecin pour ces Produits. Cette garantie limitée expire trois (3) mois après l'expédition du Produit. Si un Produit Eon n'est pas conforme à la garantie énoncée ci-dessus, la seule responsabilité d'Eon, à sa discrétion, sera de: (i) réparer ou remplacer ledit Produit; ou (ii) créditer le compte du Médecin pour ce Produit. Si Eon choisit de réparer ou de remplacer ledit Produit, elle disposera d'un délai raisonnable pour réparer ces Produits ou fournir des remplacements. Les Produits réparés seront garantis pour le reste de la période de garantie d'origine. Les Produits remplacés seront garantis pour une période de garantie complète de trois (3) mois.",
  terms_warranties_and_disclaimer_content_3:
    "Dans la mesure permise par la législation locale, à l'exception des obligations énoncées dans la déclaration de garantie ci-dessus, EON ne pourra en aucun cas être tenu responsable d’aucune autre garantie ou condition de quelque nature que ce soit, qu'il s'agisse de garantie explicite ou implicite, ou de conditions de valeur commerciale, de qualité, ou de quelque usage particulier que ce soit.",
  terms_limitations_of_liability: "Limitations de responsabilité:",
  terms_limitations_of_liability_content_1:
    "Dans la mesure permise par la législation locale, à l'exception des obligations spécifiquement énoncées dans cette déclaration de garantie, EON ne pourra en aucun cas être tenu responsable des dommages directs, indirects, ponctuels ou consécutifs, liés à, ou découlant du présent Contrat, ou de l'utilisation de la Configuration de Traitement , quelle qu'en soit la cause et/ou la théorie de responsabilité, que ce soit en cas de négligence, de violation de garantie, de responsabilité stricte, de contrat, de délit, d'indemnisation ou de n’importe quelle autre cause ou théorie.",
  terms_limitations_of_liability_content_2:
    "Aucune responsabilité monétaire cumulée ne pourra être réclamée à Eon pour une valeur supérieure au montant total payé à eon par le Médecin pour les Produits particuliers vendus pour des dommages de quelque nature que ce soit découlant de ou en relation avec cet Accord, ou toute utilisation d’un des Produit ou service eon. L'existence de plus d'une réclamation contre les Produits particuliers vendus au Médecin en vertu du présent Accord, ou l'existence de plus d'un Accord avec le Médecin ou la vente de Produits supplémentaires au Médecin ne devront en aucun cas dépasser cette limite fixée.",
  terms_limitations_of_liability_content_3:
    "Le Médecin sera tenu d'indemniser Eon pour les dépenses engagées par Eon à la suite du non-respect par le Médecin de cet Accord.",
  terms_indemnification: "Indemnisation:",
  terms_indemnification_content:
    "LE MÉDECIN ACCEPTE D'INDEMNISER, DE DÉFENDRE ET DE NE PAS PORTER ATTEINTE À EON ET / OU SES DIRIGEANTS, AGENTS, EMPLOYÉS, ENTREPRENEURS, SUCCESSEURS ET DE SE DISSOCIER DE TOUTE RESPONSABILITÉ, OBLIGATIONS, PERTES, RÉCLAMATIONS, ACTIONS, DOMMAGES, PÉNALITÉS, AMENDES, DEMANDES ET TOUS LES FRAIS LIÉS - FRAIS D'AVOCAT ET FRAIS DE QUELQUE NATURE QUE CE SOIT ET RÉSULTANT DE QUELQUE THÉORIE DE RESPONSABILITÉ JURIDIQUE QUE CE SOIT (désormais dénommée «RÉCLAMATION»),  OPPOSABLE À EON, DÉCOULANT DE, RÉSULTANT DE, OU RELATIF À : (I) CE CONTRAT OU L’UTILISATION DE PRODUITS VENDUS EN VERTU DE CE CONTRAT; (II) TOUTE INFRACTION OU VIOLATION DU MÉDECIN D’ UNE CONDITION DU PRÉSENT CONTRAT; (III) TOUTE VIOLATION OU VIOLATION ALLÉGUÉE DE TOUTE REPRÉSENTATION OU GARANTIE FAITE PAR LE MÉDECIN OU TOUTE INFORMATION INCORRECTE FOURNIE À EON PAR LE MÉDECIN OU LE PATIENT DU MÉDECIN; OU (IV) TOUTE ALLÉGATION SUR LA LIVRAISON OU NON LIVRAISON DES PRODUITS DE EON AU MÉDECIN, À MOINS QU’IL SAGISSE D’UNE FAUTE INTENTIONNELLE OU UNE NÉGLIGE NCE FLAGRANTE.",
  terms_relationship_of_parties: "Relation entre les Parties:",
  terms_relationship_of_parties_content:
    "Les Parties entendent par cet Accord que le Médecin est et sera dans tous les cas un entrepreneur indépendant et non l'agent ou l'employé d'Eon. Ni le présent Accord ni aucun contrat avec Eon ni aucun cours de négociation ou de pratique ne doivent être interprétés ou laisser comprendre qu’il existe une relation employeur-employé, principal-agent, de partenariat, de  coentreprise, ou de quelque autre type que ce soit, entre le Médecin et Eon.",
  terms_advertising_and_marketing: "Publicité et marketing:",
  terms_advertising_and_marketing_content:
    "Eon se réserve le droit d'utiliser le nom, l'adresse, le numéro de téléphone et les descriptions des soins et des Services spécialisés du Médecin dans son matériel publicitaire, promotionnel et marketing. Le Médecin a le droit de réviser ces documents marketing avant la publication ou la distribution finale.",
  terms_assignment: "Cession :",
  terms_assignment_content:
    "Le présent Accord ne pourra pas être cédé par l'une ou l'autre des Parties sans le consentement écrit préalable de l'autre Partie.",
  terms_successor_and_assigns: "Successeur et ayants droit:",
  terms_successor_and_assigns_content:
    "Le présent Accord est contraignant et s'appliquera uniquement au profit des Parties et de leurs successeurs respectifs, il ne pourra pas être cédé á une autre personne ou société.",
  terms_entire_agreement_and_amendment:
    "Intégralité de l'Accord et amendement:",
  terms_entire_agreement_and_amendment_content:
    "Le présent Accord et le Formulaire de Soumission de cas Eon, fourni par le Médecin et approuvé par Eon, constituera et contiendra l'intégralité de l'accord des Parties et sera supérieur et remplacera toutes les négociations, correspondances et accords antérieurs entre les Parties concernant l'objet des présentes conditions.  Le présent Accord ne peut être modifié que par un Accord écrit et dûment signé par les Parties aux présentes.",
  terms_severability: "Divisibilité:",
  terms_severability_content:
    "Dans le cas où une ou plusieurs dispositions des présentes Conditions générales sont reconnues comme invalides, illégales ou non exécutoires, cela n’affectera en aucun cas la légalité du reste des autres termes et conditions du présent Accord.",
  terms_force_majeure: "Force majeure :",
  terms_force_majeure_content:
    "Eon ne serait pas considéré responsable de rupture ou violation de cet Accord si cette violation résulte, directement ou indirectement, d'un incendie, explosion,  grève, embargo de fret, cas de force majeure,  guerre, troubles civils, action grave de gouvernement de jure ou de facto, ou d’un organisme publique ou d’un fonctionnaire de celui-ci, d’une pénurie de main-d'œuvre, imprévus de transport, intempéries, défaut du fabricant ou du fournisseur, mise en quarantaine ou restriction, épidémie ou catastrophe, manque d'instructions en temps opportun ou d'informations essentielles du Médecin ou d`autre tiers, ou d'autres conditions indépendantes de la volonté d'Eon.",
  terms_notices: "Avis:",
  terms_notices_content:
    "Toutes les notifications, demandes,  approbations et autres communications en vertu des présentes conditions doivent être transmises par écrit et seront réputée avoir été faites à la date de livraison si elles sont livrées personnellement, la date d’envoi si postée, ou envoyées par courrier recommandé (port prépayé, retour reçu demandé), ou à la date de transmission en cas de télécopie ou courriel, au Médecin à l'adresse fournie par le Médecin.",
  terms_waiver: "Renonciation :",
  terms_waiver_content:
    "Le défaut de l'une ou l'autre des parties au moment d'exiger l'exécution d’une disposition des présentes conditions n'affectera en aucun cas le droit de la faire appliquer.  Aucune renonciation par l'une ou l'autre des parties à une condition ou  la violation d’une condition, d’une disposition, d’un engagement ou d’une garantie contenue dans le présent accord, ne sera réputée comme valant renonciation à ladite clause.  Celle-ci ne sera pas non plus susceptible de créer un droit nouveau dont pourrait se prévaloir les parties sous quelque forme que ce soit.",
  terms_governing_law: "Loi applicable:",
  terms_governing_law_content:
    "Le présent Contrat est soumis et interprété conformément au droit belge.",
  TS_Viewer: "TS Viewer_fr",
  Treatment_Setup: "Treatment Setup_fr",
  Case_ID: "Case ID_fr",
  Movement_Table: "Movement Table_fr",
  Applied_Treatments: "Applied Treatments_fr",
  Notes: "Notes_fr",
  Hybrid_treatment_is_recommended_due_to_difficult_movements:
    "Hybrid treatment is recommended due to difficult movements_fr",
  Convert_to_Hybrid_Treatment: "Convert to Hybrid Treatment_fr",
  Tooth: "Tooth_fr",
  Number: "Number_fr",
  Rotation: "Rotation_fr",
  Angulation: "Angulation_fr",
  Torque: "Torque_fr",
  Mesial: "Mesial/_fr",
  Distal: "Distal_fr",
  Extrusion: "Extrusion/_fr",
  Intrusion: "Intrusion_fr",
  In_Out: "In/Out_fr",
  onboarding: "Onboarding_fr",
  get_started: "Get Started_fr",
  select_your_default_clinical_preferences:
    "Select your default clinical preferences._fr",
  you_can_override_these_preferences_on_a_case_by_case_basis_during_submission:
    "You can override these preferences on a case-by-case basis during submission._fr",
  select_your_default_options: "Select your default options._fr",
  select_your_default_account_preferences:
    "Select your default account preferences._fr",
  you_can_change_these_preferences_in_settings:
    "You can change these preferences in settings._fr",
  skip: "Skip_fr",
  movement: "Movement_fr",
  value: "Value_fr",
  rotation: "Rotation_fr",
  angulation: "Angulation_fr",
  torque: "Torque_fr",
  mesial: "Mesial_fr",
  distal: "Distal_fr",
  intrusion: "Intrusion_fr",
  extrusion: "Extrusion_fr",
  in: "In_fr",
  out: "Out_fr",
  deg: "deg_fr",
  toward: "toward_fr",
  away: "Away_fr",
  labial: "Labial_fr",
  lingual: "Lingual_fr",
  restrict_tooth: "Restrict tooth_fr",
  add_another_attachment: "Add another attachment_fr",
  use_elastics: "Use Elastics_fr",
  un_edited_tooth: "Un-edited tooth_fr",
  edited_tooth: "Edited tooth_fr",
  active_tooth: "Active tooth_fr",
  restricted_tooth: "Restricted tooth_fr",
  treatment_protocols: "Treatment Protocols_fr",
  Reject_Bite_Records: "Reject Bite Records_fr",
  ipr_amount: "IPR amount_fr",
  add_ipr_before_step: "Add IPR before step_fr",
  and: "and_fr",
  between: "between_fr",
  ipr_before_this_step: "IPR before this step_fr",
  edited: "Edited_fr",
  IPR_amount_exceeding_recommended_limit:
    "IPR amount exceeding recommended limit_fr",
  restrict_ipr: "Restrict IPR_fr",
  front: "Front_fr",
  right: "Right_fr",
  left: "Left_fr",
  Used_torque_enhancers_for_this_case: "Used torque enhancers for this case_fr",
  Used_attachments_for_this_case: "Used attachments for this case_fr",
  Used_Extraction_for_this_case: "Used Extraction for this case_fr",
  Used_Passive_aligners_for_this_case:
    " Used Passive aligners for this case_fr",
  arch: "Arch_fr",
  number_of_passive_aligners: "Number of passive aligners_fr",
  synchronize: "Synchronize_fr",
  end_Together: "End Together_fr",
  Used_bite_ramps_for_this_case: "Used bite ramps for this case_fr",
  Used_pontics_for_this_case: "Used pontics for this case_fr",
  Used_Elastics_for_this_case: "Used Elastics for this case_fr",
  High_Rate: "High Rate_fr",
  Use_IPR_for_this_case: " Use IPR for this case_fr",
  summary_treatment_required: "Treatment Summary is Required_fr",
  to: "to_fr",
  attachments_type: "Attachments Type_fr",
  use_from_stage: "Use from stage_fr",
  add_another_elastics: "Add another elastics_fr",
  case_summary: "Case Summary_fr",
  approved: "Approved_fr",
  confirmation_leave_page: "Are you sure you want to leave the page?_fr",
  disapprove_note: "Your changes have not been submitted._fr",
  revised_data_successfully: "Revision submitted successfully_fr",
  no_revesion_made: "No revisions made_fr",
  submit_without_modifications: "Submit without modifications_fr",
  invoice_based_on_case_type: "Invoice will be based on case type_fr",
  approve_message: `<div class="tp_notes">By approving this Treatment Setup, you hereby authorize Eon Aligner
         to fabricate the aligners as per the specifications provided by you
         in the Treatment Form and as contained in this Treatment Setup.</div>
         <div class="tp_notes cmt2"> You will be invoiced according to the approved case type unless the
         required treatment plan exceeds it.</div> _fr`,
  approve: "Approve_fr",
  Technician_notes: "Technician Notes_fr",
  Manufacturing_notes: "Manufacturing Notes_fr",
  optional2: " (Optional)",
  treatment_summay: "Treatment Summary ",
  Retainer_Case_Submission1: "Retainer_fr",
  Retainer_Case_Submission2: "Case Submission_fr",
  detailed_case: "Detailed case_fr",
  submission: "submission_fr",
  detailed_case_submission: "Detailed case submission_fr",
  braces_before_aligners: "Braces before aligners_fr",
  braces_and_aligners_together: "Braces and aligners together_fr",
  exit_review: "Exit Review_fr",
  braces_before_aligners_note:
    " Begin bracket treatment and submit new impression after bracket treatment is complete_fr",
  which_arch_do_you_want_to_treat_with_aligners:
    "Which arch do you want to treat with aligners?_fr",
  upper_arch: "Upper Arch_fr",
  lower_arch: "Lower Arch_fr",
  do_you_want_to_keep_the_submitted_treatment_protocols:
    "Do you want to keep the submitted treatment protocols?_fr",
  please_choose_one: "Please choose one_fr",
  provide_new_treatment_protocols: "Provide new treatment protocols_fr",
  Retainer_Case_Submission: "Retainer Case Submission_fr",
  hybrid_treatment_Summary: "Hybrid Treatment Summary_fr",
  keep_treatment_protocols: "Keep treatment protocols_fr",
  hybrid_type: "Hybrid Type_fr",
  new_treatment_protocols: "New treatment protocols_fr",
  by_submitting_this_request:
    "By submitting this request, we will create a new treatment setup for this case._fr",
  revised_data: "Revised Data_",
  aligner: "Aligner_",
  bite_record: "Bite Record_fr",
  bite_record_rejected: "Bite record rejected_fr",
  upload_new_bite_records: "Upload new bite records_fr",
  converted_to_hybrid_successfully:
    "converted to hybrid treatment successfully",
  fix_bite_records_successfully: "Fixed bite records successfully_fr",
  select_tooth_to_edit: "Select tooth to edit_fr",
  there_are_unsaved_changes: "There are unsaved changes._fr",
  Used_Tooth_Size_Discrepancy_for_this_case:
    "Used Tooth Size Discrepancy for this case_fr",
  please_add_details_in_elastic: "please add details in elastic_fr",
  to_edit_ipr: "to edit IPR_fr",
  rotational: "Rotational_fr",
  hbra: "HBRA_fr",
  vbra: "VBRA_fr",
  biplane: "Biplane_fr",
  root_control: "Root control_fr",
  Approve_Treatment: "Approve Treatment_fr",
  Revise_Treatment: "Revise Treatment_fr",
  bite_check: "Bite Check_fr",
  review_and_submit: "Review & Submit_fr",
  cancel_submission: "Cancel Submission_fr",
  your_account_is_blocked:
    "Your account is blocked from submitting new cases._fr",
  please_contact: "Please contact_fr",
  contact_email: "collections@eonaligner.com",
  blocked_note_part2:
    "or your designated relationship manager to unblock your account._fr",
  AttachmentTypeNOTE: "Attachment Type.._fr",
  Attachment_Note: "Attachment Type_fr",
  these_new_preferences_will_only_be_applied_to_new_case_submissions:
    "These new preferences will only be applied to new case submissions._fr",
  provide_links: "Provide links to third party integration systems_fr",
  provide_links_commit: "(Ex. 3Shape Communicate)_fr",
  retainer_request: "Retainer Request_fr",
  Number_of_sets: "Number of sets_fr",
  sets: "sets_fr",
  Arch_Required: "Arch Required_fr",
  impressions: "impressions_fr",
  Submit_request: "Submit request_fr",
  Date_of_request: "Date of request_fr",
  View_TS: "View TS_fr",
  retainer_case: "retainer case_fr",
  aligner_case: "aligner case_fr",
  fdi_new: "FDI notation system_fr",
  select_how_you_would_like_to_submit_your_impressions:
    "Select how you would like to submit your impressions:_fr",
  street: "Street_fr",
  building: "Building_fr",
  perform_ipr_before_step: "Perform IPR before step_fr",
  add_attachments_before_step: "Add attachments before step_fr",
  arch: "Arch_fr",
  download_case_pdf: "Download case PDF_fr",
  digital_impressions: "Digital impressions_fr",
  physical_impressions: "Physical impressions_fr",
  digital: "Digital_fr",
  physical: "Physical_fr",
  perform_ipr_before_step: "Perform IPR before step_fr",
  add_attachments_before_step: "Add attachments before step_fr",
  all_options_might_require_the_use_of_elastics:
    "All options might require the use of elastics_fr",
  select_the_teeth_you_dont_want_ipr_on:
    "Select the teeth you don't want IPR on:_fr",
  select_the_teeth_that_needto_be_extracted:
    "Select the teeth that need to be extracted:_fr",
  perform_attachments_before_step: "Perform attachments before step_fr",
  select_the_teeth_where_you_dont_want_to_place_attachments:
    "Select the teeth where you don't want to place attachments:_fr",
  select_the_teeth_requiring_pontic_placement:
    "Select the teeth requiring pontic placement:_fr",
  additional_comments: "Additional comments:_fr",
  select_the_tooth_areas_requiring_overcorrection:
    "Select the tooth areas requiring overcorrection:_fr",
  apply_ipr_in_the_opposing_arch: "Apply IPR in the opposing arch_fr",
  select_the_teeth_requiring_torque_enhancers:
    "Select the teeth requiring torque enhancers:_fr",
  select_the_teeth_on_which_you_want_to_place_bite_ramps:
    "Select the teeth on which you want to place bite ramps:_fr",
  select_the_area_where_you_want_arch_expansion:
    "Select the area where you want arch expansion:_fr",
  set_slow_tooth_movement_rate_for_this_case:
    "Set slow tooth movement rate for this case?_fr",
  select_the_teeth_requiring_buttons_placement:
    "Select the teeth requiring buttons placement (cutouts):_fr",
  select_the_teeth_requiring_precision_cuts_placement:
    "Select the teeth requiring precision cuts placement:_fr",
  your_account_is_blocked_mobile: "Your account is blocked!_fr",
  you_cant_submit_new_cases_please_contact:
    "You can’t submit new cases. Please contact_fr",
  collections_eonaligner_com: "collections@eonaligner.com_fr",
  your_designated_relationship_manager_to_unblock_your_account:
    "or your designated relationship manager to unblock your account._fr",
  revision_notes: "Revision notes_fr",
  provide_treatment_revision_notes: "Provide treatment revision notes",
  courier_note1:
    "A courier service will contact you to arrange for the date and time of the impression pick-up._fr",
  courier_note2:
    "Please keep in mind that impression trays won't be returned._fr",
  courier_requirements: "Requirements:_fr",
  courior_list_1: "Clean, clear and sterilized impressions_fr",
  courior_list_2:
    " Silicone impressions (Alginate impressions are not accepted)_fr",
  courior_list_3:
    "Case ID and doctor name to be printed on the impression box_fr",
  revision_submitted_successfully: "Revision submitted successfully_fr",
  revise: "Revise_fr",
  please_choose_one_of_arch_impression_methodes:
    "Please choose one of arches_fr",
  please_choose_one_method_new: "Please choose one of Impression methodes_fr",
  info_eonaligner_com: "info@eonaligner.com_fr",
  address_Belgium: " Mechelsesteenweg 271 Bus 1.1 2018 Antwerpen, Belgium_fr",
  www_eonaligner_com: "www.eonaligner.com_fr",
  doctor_signature: "Doctor Signature:_fr",
  doctor_information: "Doctor Information_fr",
  treatment_submission_form: "Treatment Submission Form_fr",
  aligner_treatment: "Aligner Treatment_fr",
  Braces_aligners_together: "Braces + aligners together_fr",
  aligners: "Aligners_fr",
  braces: "Braces_fr",
  special_instructions: "Special Instructions_fr",
  eonToDecide: "EON to Decide_fr",
  step: "Step_fr",
  case_type_with_capital_t: "Case Type_fr",
  ipr_indicator: "IPR indicator_fr",
  pending_action: "Pending Action_fr",
  eon_basic_hedaer: "Best for mild cases like crowding, spacing,_fr ",
  eon_basic_hedaer_2: " or relapse._fr",
  eon_basic_text_1: "10 aligner steps or less_fr",
  eon_basic_text_2: "9 months_fr",
  eon_basic_text_3: "Includes: 3 TS revisions + 1 refinement + 1 retainer_fr",
  eon_basic_footer:
    "Free Refinements are limited to the number of steps in the initial TS_fr",
  eon_plus_hedaer: "Covers a wide range of mild to moderate._fr ",
  eon_plus_text_1: "11-24 aligner steps or less_fr",
  eon_plus_text_2: "18 months_fr",
  eon_plus_text_3: "Includes: 3 TS revisions + 3 refinements + 1 retainer_fr",
  eon_pro_hedaer: "Ideal for advanced cases requiring extraction_fr ",
  eon_pro_hedaer_2: " or A-P correction._fr",
  eon_pro_text_1: "Unlimited aligner steps_fr",
  eon_pro_text_2: "36 months_fr",
  eon_pro_text_3:
    "Includes required number of TS revisions + refinements needed to achieve the desired patient outcomes + 2 retainers._fr",
  your_loyalty_status: "Your Loyalty Status_fr",
  bronze: "Bronze_fr",
  silver: "Silver_fr",
  gold: "Gold_fr",
  platinum: "Platinum_fr",
  diamond: "Diamond_fr",
  elite: "Elite_fr",
  updated_april_1_st_2022: "Updated April 1st, 2022_fr",
  t_and_c: `
  <br> </br>
  <b>1. INTRODUCTION_fr</b>
  <br></br>
  1.1. These terms and conditions ("<b>Agreement</b>") constitute the entire agreement and understanding and apply to all aspects of the relationship between Eon Dental NV or, as applicable, one of its subsidiaries or associates ("<b>Eon Dental</b>"), 
  and any customer ("<b>Customer</b>"), and no variation or amendment to the Agreement or any other terms or conditions, whether implied or otherwise, shall apply except where Eon Dental has expressly otherwise agreed in writing._fr
  <br></br>
  1.2. Eon Dental is inter alia engaged in the business of designing, manufacturing, selling, distributing and servicing custom-made clear aligners for orthodontic treatment ("<b>Clear Aligners</b>) and removable orthodontic 
  retainers made entirely of transparent plastic ("<b>Retainers</b>") (together "<b>Products</b>) and the provision of the supporting and ancillary services (including renderings of a preview of how the subject Products is expected to impact teeth, 
  including scans of the teeth, 3D virtual models of each step of the treatment, and a 3D viewer ("<b>Treatment Setup</b>")) ("<b>Services</b>")._fr
  <br></br>
  1.3. This Agreement governs the Customer's use of the Eon Dental website, its case management portal for Treatment Setup and Customer communication ("<b>Eon Access</b>") and the processes and requirements to submit an order for Products and Services
  ("<b>Case Order</b>"), and the Customer agrees to be bound by them from the time the Customer first accesses Eon Access in consideration for Eon Dental providing the Customer with the Products and Services and permission to use Eon Access._fr
  <br></br>
  <b>2. CUSTOMERS_fr</b>
  <br></br>
  2.1. Eon Dental's Customer is the doctor whose email address is used to log in to Eon Access ("<b>Eon Doctor</b>)._fr
  <br></br>
  2.2.If the Eon Doctor owns, works for, works with, or is employed by a clinic or other legal entity ("<b>Clinic</b>"), and such Clinic is named on or as a matter of practice pays for the invoice(s) issued by Eon Dental, 
  then such reference in or payment of the invoice(s) shall constitute the Clinic's authorization for the Eon Doctor to use Eon Access and make Case Orders, in which instance the Clinic is also an Eon Customer, and the Eon Doctor and the Clinic will be jointly and severally liable for obligations in this Agreement which are obligations of the Customer._fr
  <br></br>
  <b>3. CASES AND ACCEPTANCE_fr</b>
  <br></br>
  3.1. Cases Orders are placed in Eon Access and shall take effect at the date and time a Customer approves the Treatment Setup associated with that Case Order ("<b>Approved Case</b>") ("<b>Approval Date</b>")._fr 
  <br></br>
  3.2. All Approved Cases are subject to final acceptance by Eon Dental either in writing by sending the Customer a confirmation email or implicitly with the elapse of time and shipment and Eon Dental has the right in its absolute and sole discretion, and without any liability, to refuse any Case Order or Approved Case._fr
  <br></br>
  3.3. It is the Customer's responsibility to provide for each Case Order's named patient ("<b>Patient</b>") all relevant records including patient name and other personal data, the upper and lower arch scans of the teeth (the "<b>Scans</b>"),
  x-rays, photos (including full face, smiling, upper and lower occlusal, left and right buccal) that accurately represent the current condition of a Patient's teeth in addition to the prescription ("<b>Patient Data</b>")._fr
  <br></br>  
  3.4. The Customer shall only share Patient Data necessary for Eon Dental to carry out its obligations under this Agreement. Customer shall ensure that each Patient signs the Patient Information and Consent Form ("<b>Consent Form</b>") 
  available on Eon Access and retain a copy of such Consent Form for their own records._fr
  <br></br>
  3.5. The Customer shall have the full and exclusive responsibility for (i) for the accuracy of the Scans and other Patient Data, and (ii) to manage the Patient's expectations on the timing of their treatment,
  particularly where delays are caused by the Customer or Patient's own delays or failures to act._fr
  <br></br>
  3.6. Eon Dental shall produce a Treatment Plan within five (5) days of receiving the Patient Data in the form and quality as accepted by Eon Dental in its absolute and sole discretion._fr
  <br></br>
  3.7. Subject to the terms and conditions shown in Article 7, Products will only be manufactured and dispatched from the site of manufacture for Approved Cases._fr
  <br></br>
  <b>4. PRODUCTS, PRICING AND PAYMENT TERMS_fr</b>
  <br></br>
  4.1. The Customer may elect one of the Product packages below when placing a Case Order:_fr
  <br></br>
  <li class="t_and_c">4.1.1. &ensp;Eon Pro: includes an unlimited number of initial Treatment Setups and unlimited number of initial Aligner Steps to be shipped to the
  Customer at a rate of no more than twenty four (24) Aligner Steps per single shipment, 
  in addition to an unlimited number of Refinements which must be shipped to the Customer at a rate of no more than twenty four (24) Aligner Steps per single shipment, and two (2) Retainer Sets that must be shipped in one single shipment, before an Expiration Date of thirty six (36) months._fr</li> 
  <br>
  <li class="t_and_c">4.1.2. &ensp;Eon Plus: includes no more than four (4) initial Treatment Setups and no more than twenty-four (24) initial Aligner Steps to be shipped to the Customer in one single shipment, 
  in addition to three (3) Refinements (each, subject to no more than four (4) Treatment Setups) and one (1) Retainer Set, each of which must be shipped to the Customer in a single shipment before an Expiration Date of eighteen (18) months._fr</li> 
  <br>
  <li class="t_and_c">4.1.3. &ensp;Eon Basic: includes no more than four (4) initial Treatment Setups and no more than ten (10) initial Aligner Steps to be shipped to the Customer in one single shipment, 
  in addition to one (1) Refinement (subject to no more than four (4) Treatment Setups) and one (1) Retainer Set, each of which must be shipped to the Customer in a single shipment before an Expiration Date of nine (9) months._fr</li> 
  <br>
  4.2. All invoices, including cancelation invoices, are payable by the Customer in full within thirty (30) days of the invoice._fr
  <br></br>
  4.3. An Aligner Step is, in the case of Dual Arch package, a pair of one (1) upper dental arch and one (1) lower dental arch or, in the case of a Single Arch package, either one (1) upper dental arch or one (1) lower dental arch._fr 
  <br></br>
  4.4. The Customer shall ensure that the number of steps clinically required to move the teeth from the initial teeth position to the final teeth position in the initial Treatment Setup does not exceed the number of Aligner Steps in the elected Product package. Eon Dental, in its absolute and sole discretion, may propose initial Treatment Setups that better correspond with the number of Aligner Steps clinically required than those in the elected Product package._fr
  <br></br>
  4.5. Refinements are additional Aligner Steps based on a new Treatment Setup that, in Eon Dental's absolute and sole discretion, does not change the initial treatment goal of final teeth position. The cumulative number of Aligner Steps in a Product package's Refinement must not exceed the number of initial Aligner Steps from the initial Treatment Setup. Standard fees shall apply for additional Aligner Steps that exceed the number of Aligner Steps from the initial Treatment Setup._fr
  <br></br>
  In Eon Dental's absolute and sole discretion, if a Refinement's new Treatment Setup, changes the treatment goal of final teeth movement from that of the initial Treatment Setup, then Eon Dental reserves the right to automatically upgrade the initially approved Product package._fr
  <br></br>
  4.6. A Retainer Set is, in the case of Dual Arch package, a pair of one (1) upper dental arch and one (1) lower dental arch or, in the case of a Single Arch package, either one (1) upper dental arch or one (1) lower dental arch._fr
  <br></br>
  4.7. Eon Pro is subject to a 'fair-use-policy' and Eon Dental may apply standard fees for additional Aligner Steps and Treatment Setups that it may determine, in its absolute and sole discretion, are excessive with respect to the quantity of or time elapsed between such Aligner Steps and Treatment Setups._fr
  <br></br>
  4.8. Expiration Date is the number of months from the Approval Date._fr
  <br></br>
  4.9. For each package, a Case Order becomes closed (a "Closed Order") at the earliest of the (i) shipment of the applicable total number of Refinements included, (ii) shipment of the Retainer Set(s), or (iii) the applicable Expiration Date._fr
  <br></br>
  4.10. Additional Aligner Steps cannot be ordered for Closed Orders._fr
  <br></br>
  <b>5. EON OBLIGATIONS_fr</b>
  <br></br>
  5.1. Eon Dental warrants that any and all Products are not damaged, bent or broken, are conforming with the Treatment Setup and are free from defects in material and workmanship._fr
  <br></br>
  5.2. The limited warranty in Article 5.1 expires ninety (90) days after the Products have been delivered in accordance with Article 7. Delays due to a Customer's delivery to a Patient, or due to a Patient's wear schedule after the Products have been delivered to the Customer do not extend the limited warranty period._fr
  <br></br>
  5.3. Eon Dental will not be liable for:_fr
  <br></br>
  <li class="t_and_c">5.3.1. &ensp;The clinical outcome of any treatment using the Products or Services_fr;</li>
  <br>
  <li class="t_and_c">5.3.2. &ensp;Failure to follow instructions, neglect, misuse, mistreatment or improper handling of the Products and Services by the Customer, Patient or any third party_fr;</li>
  <br>
  <li class="t_and_c">5.3.3. &ensp;Products or Services that have been altered in any way by any party other than Eon Dental_fr;</li>
  <br>
  <li class="t_and_c">5.3.4. &ensp;Products or Services that have been used in combination with any other product or services provided by any other party other than Eon Dental_fr;</li>
  <br>
  <li class="t_and_c">5.3.5. Errors in submitted Scans or other Patient Data._fr</li>
  <br>
  5.4. Except for the sole and limited warranty shown in Article 5.1, Eon Dental disclaims any and all other warranties of any kind, express or implied, including warranties of merchantability and fitness for a particular purpose. For the avoidance of doubt, this sole and limited warranty is only given to the Customer and not any other third party including any Patient._fr
  <br></br>
  5.5. If any Product or Service fails to conform to the sole and limited warranty shown in 5.1, Eon Dental's sole liability, would be to either, at its absolute and sole discretion (i) replace the non-conforming Product or (ii) credit the Customer's account for the cost of the Product or Service._fr
  <br></br>
  <b>6. CUSTOMER OBLIGATIONS_fr</b>
  <br></br>
  6.1. Customer warrants and represents that:_fr
  <br></br>
  <li class="t_and_c">6.1.1. &ensp;Customer is licensed to practice dentistry or orthodontics in the location to where the Product will be shipped_fr;</li>
  <br>
  <li class="t_and_c">6.1.2. &ensp;Customer operates in accordance with applicable laws, regulations and standard of care in the location to where the Product will be shipped_fr;</li>
  <br>
  <li class="t_and_c">6.1.3. &ensp;Customer has the necessary training, expertise and experience to treat Patients using the Products and Services_fr;</li>
  <br>
  <li class="t_and_c">6.1.4. &ensp;Customer intends to only use the Products and Services in accordance with the Clinician Guide (available on Eon Access)_fr;</li>
  <br>
  <li class="t_and_c">6.1.5. &ensp;Customer is not purchasing the Products or Services with the intent for use by any party besides the Customer and the Customer's Patient, or outside the country to where the Products will be shipped by Eon Dental._fr</li>
  <br>
  6.2. Customer shall:_fr
  <br></br>
  <li class="t_and_c">6.2.1. &ensp;Not breach applicable laws, regulations, professional rules, or otherwise lose its ability (including having its professional registration withdrawn or limited) to practice dentistry or orthodontics, or where applicable, operate as a dental clinic_fr;</li>
  <br>
  <li class="t_and_c">6.2.2. &ensp;Be solely and exclusively responsible for the treatment of Patients, exercising clinical judgment in the suitability of using the Products and Services (including the Treatment Setup), the outcome of the Customer's clinical decisions, and maintaining Patient Data_fr;</li>
  <br>
  <li class="t_and_c">6.2.3. &ensp;Properly explain the Products and their clinical risks to the Patients before starting treatment; disclose to each Patient that Customer may share Patient Data with Eon Dental to facilitate their treatment with Customer's discretion; and ensure that each Patient signs the Consent Form and retain a copy of such Consent Form for their own records_fr;</li>
  <br>
  <li class="t_and_c">6.2.4. &ensp;Not ship the Products out of the country to which they were shipped by Eon Dental_fr;</li>
  <br>
  <li class="t_and_c">6.2.5. &ensp;Not allow any other party to use the Products or Services and only use them for the Patient for whom such Products or Services were shipped or provided; and_fr</li>
  <br>
  <li class="t_and_c">6.2.6. &ensp;Pay the sums that are owed to Eon Dental when they are due for payment in accordance with Article 10._fr</li>
  <br>
  6.3. Customer acknowledges and understands that:_fr
  <br></br>
  <li class="t_and_c">6.3.1. &ensp;Eon Dental is a medical device manufacturer, does not provide medical, dental or any health care services or advice and is not licensed to do so in any jurisdiction_fr;</li>
  <br>
  <li class="t_and_c">6.3.2. &ensp;Eon Access is a case management portal that renders expected teeth movement and the actual results may vary. Some Products and Services incorporate default, preset or optional features and must only be considered as a recommendation with the ultimate decision to approve and apply a Treatment Setup, change them to specific conditions of each Patient, or reject them (subject to any cancelation fee) remaining solely and exclusively with the Customer_fr;</li>
  <br>
  <li class="t_and_c">6.3.3. &ensp;The Customer must read and understand the Clinician Guide and any updates thereto including all risk factors stated therein_fr;</li>
  <br>
  <li class="t_and_c">6.3.4. &ensp;The Eon Doctor details used to log in to Eon Access may only be used by one single Eon Doctor for the treatment of Patients under the direct care and supervision of such Eon Doctor. The use of an Eon Doctor's login details by any other party is strictly prohibited other than (i) by their support staff for the treatment of Patients under the direct care and supervision of such Eon Doctor; or (ii) with the express written permission of Eon Dental_fr;</li>
  <br>
  <li class="t_and_c">6.3.5. &ensp;Any sharing of an Eon Doctor login details with any other party other than as specified in Article 6.3.4 may violate Patient privacy rights. Separate login details must be issued for doctors working in the same Clinic; and_fr</li>
  <br>
  <li class="t_and_c">6.3.6. &ensp;A Case Order constitutes an official purchase order, instructing Eon Dental to produce a Treatment Setup and, where applicable, manufacture the Products, after which time the terms and conditions with respect to cancelation in Article 8, if permitted, apply._fr</li>
  <br>
  6.4. Without prejudice to any other remedies available to Eon Dental, if the provisions of Article 6.1 and 6.2 are not complied with in any respect throughout the term of this Agreement, Eon Dental in its absolute and sole discretion may:_fr
  <br></br>
  <li class="t_and_c">6.4.1. &ensp;Suspend performance of any of its obligations under this Agreement_fr;</li>
  <br>
  <li class="t_and_c">6.4.2. &ensp;Restrict or terminate the Customer's ability to login to Eon Access_fr;</li> 
  <br>
  <li class="t_and_c">6.4.3. &ensp;Halt the delivery of the Services or Products_fr;</li>
  <br>
  <li class="t_and_c">6.4.4. &ensp;Revoke and withdraw the permission to use the Eon Marks pursuant to Article 9.4_fr;</li>
  <br>
  <li class="t_and_c">6.4.5. &ensp;Terminate the Agreement in accordance with Article 14 and in which case the provisions of Article 15 shall apply._fr</li>
  <br>
  <b>7. SHIPMENT AND DELIVERY_fr</b>
  <br></br>
  7.1. Eon Dental shall dispatch and ship the Products within fourteen (14) working days of the Approval Date and in accordance with the number of shipments for each Product shown in Article 4._fr
  <br></br>
  7.2. Any time periods indicated are not binding and are a mere indication of the ordering and delivery process. Eon Dental shall not be liable for any liability, losses or damages that the Customer may incur as a result of any delays in the shipment and delivery of the Products._fr
  <br></br>
  7.3. If the Products are not delivered, Customer will inform Eon Dental who will determine the cause of the delay and, if in accordance with this Agreement Eon Dental is under an obligation to deliver, Eon Dental will manufacture and ship replacement Products and this will be the Customer's sole remedy in respect of an undelivered Product._fr 
  <br></br>
  7.4. Any delay in the delivery of Products shall not relieve the Customer of its obligations to accept and pay for the Products._fr
  <br></br>
  7.5. If Customer fails to accept delivery of the Products within four (4) days of a notice from Eon Dental or the party delivering the Products that the Products are ready for delivery, then delivery of the Products will be deemed to have been completed on the fifth day after such notice. Eon Dental shall charge Customer for redelivery and storage costs, including insurance, but shall have no obligation to redeliver the Products seven (7) days after the notice to Customer that the Products are ready for delivery._fr
  <br></br>
  7.6. The Products will be shipped CIF to the delivery address as indicated in Eon Access, where title and risk of all Products transfer to the Customer at the first party delivering the Products. For the avoidance of doubt, Eon Dental shall deliver the Products to the party delivering the Products and Eon Dental shall contract and pay for all delivery and shipping costs to the Customer delivery address indicated in Eon Access._fr
  <br></br>
  <b>8. CANCELATIONS AND TERMINATION_fr</b>
  <br></br>
  8.1. Eon Dental may cancel an Approved Case at any time in its absolute and sole discretion without any liability._fr
  <br></br>
  8.2. Eon Dental shall charge cancelation fees in accordance with Article 10 if a Customer (i) cancels a Case Order prior to such Case Order becoming an Approved Case or (ii) a Case Order does not become an Approved Case within one hundred eighty (180) days from the date of the Case Order whether due to missing Patient Data or for any other reason._fr
  <br></br>
  8.3. All sales are final. If a Customer cancels an Approved Case for any reason whatsoever, Eon Dental shall not credit or refund any portion of the fees related to that Approved Case and any associated payments shall remain due and payable to Eon Dental._fr
  <br></br>
  <b>9. INTELLECTUAL PROPERTY RIGHTS AND TRADEMARKS_fr</b>
  <br></br>
  9.1. The Customer agrees that Eon Dental retains, and shall always retain, the sole and exclusive right, title, interest in and to, and ownership of all Intellectual Property Rights related to Eon Dental, Eon Access, the Products and Services._fr 
  <br></br>
  9.2. "<b>Intellectual Property Rights</b>" mean all intellectual property, industrial property, and proprietary rights and assets, and all rights, interests and protections that are associated with, similar to, or required for the exercise of, any of the foregoing, however arising, pursuant to the law of any jurisdiction throughout the world, whether registered or unregistered, 
  including any and all (a) patents, patent applications, patent disclosures, and inventions and all improvements thereto (whether or not patentable or reduced to practice), and all reissues, renewals, continuations, continuations-in-part, revisions, divisional, extensions, and reexaminations in connection therewith, (b) trademarks, service marks, logos, trade dress, trade styles,
  corporate names, business names (including fictitious), trade names, and other indicia of source, and all registrations, applications, recordings, and renewals in connection therewith (together with the goodwill associated therewith) ("<b>Marks</b>"), (c) copyrights and all works of authorship (whether or not copyrightable), and all registrations, applications, extensions, restorations,
  and renewals in connection therewith, regardless of the medium of fixation or means of expression ("<b>Copyrights</b>"), (d) software and firmware, including data files, source code, object code, application programming interfaces, architecture, files, records, schematics, computerized databases and other related specifications and documentation ("<b>Software & Databases</b>"),
  (e) internet domain names and URLs ("<b>Domain Names</b>"), (f) any Trade Secrets, and (g) know-how, technologies, databases, processes, concepts, data, discoveries, models, research and development information, techniques, protocols, methods, formulae, algorithms, layouts, designs, specifications, and confidential information._fr
  <br></br>
  9.3. No transfer of any Intellectual Property Rights or other proprietary rights are intended by this Agreement._fr
  <br></br>
  9.4. Eon Dental agrees to allow the Customer to use the Eon Marks on a royalty-free basis for the sole and limited purpose of promoting the Customer's business and the use of Products within the Customer's business ("<b>Customer Promotion</b>") only if the Customer:_fr
  <br></br>
  <li class="t_and_c">9.4.1. &ensp;Continues to comply with the obligations set out in Articles 6.1 and 6.2_fr;</li>
  <br>
  <li class="t_and_c">9.4.2. &ensp;Uses the Eon Marks as provided by Eon Dental without altering the Eon Marks in any way without Eon Dental's permission_fr;</li>
  <br>
  <li class="t_and_c">9.4.3. &ensp;Does not use the Eon Marks for any purpose besides Customer Promotion and that such use shall be consistent with the highest professional standards and not for any other purpose or manner that Eon Dental may, in its absolute and sole discretion, deem to be inappropriate_fr;</li>
  <br>
  <li class="t_and_c">9.4.4. &ensp;Verifies that all Customer Promotion that uses Eon Marks complies with applicable laws, regulations and professional rules_fr;</li>
  <br>
  <li class="t_and_c">9.4.5. &ensp;Will not permit the use of the Eon Marks by any third party without the prior written consent of Eon Dental, and that doing so will be deemed a violation of this permission_fr;</li>
  <br>
  <li class="t_and_c">9.4.6. &ensp;Agrees and acknowledges that Eon Dental shall have the right to revoke and terminate this permission at any time upon five (5) days written notice, for any reason or no reason whatsoever, and Customer agrees that upon receiving such notice, Customer shall cease the distribution of and destroy any materials containing the Eon Marks; and_fr</li>
  <br>
  <li class="t_and_c">9.4.7. &ensp;Agrees and acknowledges that Customer shall not be entitled to recover any costs, fees, damages, or other sums from Eon Dental for any claim relating to or arising out of this permission._fr</li>
  <br>
  <b>10. PRICING AND PAYMENTS_fr</b>
  <br></br>
  10.1. Prices for Products and Services shall be as per the price list published by Eon Dental or, as applicable, its authorized reseller at the Approval Date. Eon Dental, or as applicable its authorized reseller, reserves the right to update such prices from time to time without prior notice._fr 
  <br></br>
  10.2. Eon Dental may offer certain Customers benefits and discounts under its Eon Aligner Loyalty Program. The details of these benefits are available with the relevant Eon Dental employee or authorized reseller._fr
  <br></br>
  10.3. Prices for Products and Services shall not include applicable sales tax, value-add tax, consumption tax, withholding tax or any other similar charges levied by any governmental authority and shall include shipping and insurance._fr 
  <br></br>
  10.4. The Customer is responsible for payment of the invoices. The Eon Doctor and the Clinic, if named in the invoice or as a matter of practice pays for the invoice(s) issued by Eon Dental, are jointly and severally liable for payment of invoices. Eon Dental may therefore claim payment of any sums owed under any agreement against the Eon Doctor or against the Clinic._fr
  <br></br>
  10.5. Amounts due from Customer to Eon Dental under this Agreement must be paid in full without any amounts to be set off, deducted, withheld, or offset for any reason whatsoever. If such deductions or withholding are required by law, then the Customer shall gross up such amounts payable to ensure that Eon Dental receives the same amount that it would have received had no such amounts been deducted or withheld as a result of the payment. The Customer is responsible for all fees charged by third parties (including outbound remittance or foreign exchange fees charged by the Customer's banks or financial institutions)._fr
  <br></br>
  10.6. Customer will pay all Eon Dental invoices as directed in the invoice in full and immediately available funds and within the period specified in Article 4, or as otherwise agreed with Eon Dental under a separately negotiated payment plan._fr
  <br></br>
  10.7. Eon Dental may in its absolute and sole discretion set a maximum value of amounts due by a particular Customer to Eon Dental. In such instances, if a Customer places a new Case Order, the Customer may be required to (i) settle part of the amounts due to Eon Dental earlier than as provided in Article 10.6 of this Agreement, or (ii) make an advanced payment for such new Case Orders._fr
  <br></br>
  10.8. In case of delay past the due date of any properly invoiced amount to be made by the Customer to Eon Dental under this Agreement: (i) Eon Dental shall be allowed to exercise its rights and remedies available pursuant to Article 6.4; and (ii) the Customer may automatically and without any notice be charged interest at a per monthly rate of one percent (1%) in addition to all costs and fees (including reasonable attorney fees) incurred by Eon Dental in collecting such overdue amounts._fr
  <br></br>
  <b>11. INDEMNIFICATION_fr</b>
  <br></br>
  11.1. The Customer shall indemnify, defend and save harmless Eon Dental from and against any and all direct costs, losses, expenses, or liabilities (including attorney fees) incurred by Eon Dental in connection with or resulting from: (i) use of the Products or Services sold under this Agreement, (ii) misrepresentation or breach of warranty by the Customer, or material breach or violation by the Customer of any terms or conditions of this Agreement, (iii) material breach of any term or condition of any agreement between the Customer and any Patient or any acts or failures in respect of a Patient, (iv) Customer's provision of incorrect or incomplete information, including Scans or other Patient Data to Eon Dental or any failure to timely provide Eon Dental with any information it requests from Customer; and (v) any and all dealings with national regulators, licensing or professional bodies in relation to Customer._fr
  <br></br>
  <b>12. LIMITATION OF LIABILITY_fr</b>
  <br></br>
  12.1. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, SHALL ANY PARTY BE LIABLE TO THE OTHER PARTY FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, PUNITIVE, RELIANCE, ENHANCED DAMAGES OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST REVENUE, LOST PROFITS, LOSS OR INTERRUPTION OF BUSINESS OR LOSS OF PATIENT DATA OR OTHER DATA) INCURRED BY THE OTHER PARTY HERETO RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF THE OTHER PARTY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES._fr 
  <br></br>
  12.2. UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE), SHALL EON DENTAL BE LIABLE TO THE CUSTOMER FOR ANY DIRECT DAMAGES IN AN AMOUNT THAT EXCEEDS THE TOTAL AMOUNT PAID TO EON DENTAL FOR THE PARTICULAR PRODUCTS OR SERVICES SOLD FOR WHICH LOSSES OR DAMAGES ARE CLAIMED._fr
  <br></br>
  <b>13. DATA PRIVACY_fr</b>
  <br></br>
  13.1 Each party undertakes, represents, and warrants that it will comply with applicable laws and regulations relating to the collection, use, storage, and protection of any personal data of Customer and Patients (including Patient Data). Customer acknowledges to have read and accepted Eon Dental's privacy policy available on the Eon Dental website. Each Party will maintain any personal data confidential in accordance with applicable laws, will use any such personal data solely to the extent permitted by this Agreement and applicable laws, and will not use or disclose any such personal data in any manner that would constitute a violation of any applicable laws._fr
  <br></br>
  13.2. For any and all personal data of Patients (including Patient Data), the Customer is the data controller and Eon Dental is data processor under applicable laws and regulations._fr
  <br></br>
  13.3. Eon Dental undertakes, represents and warrants that it will use and process any personal data of Customers and Patients (including Patient Data) only for the purpose of performing its obligations under this Agreement._fr
  <br></br>
  13.4. The Customer agrees that Eon Dental may use Patient Data for promotional, educational, or research and development purposes, publications in professional journals or use in professional collateral materials, provided that such Patient Data has first been anonymized in a way that neither the Customer nor any Patient is identifiable._fr
  <br></br>
  13.5. Eon Dental shall not, for any purposes other than the performance of this Agreement, access, use, store or process personal data of Customers and Patients (including Patient Data) for any purpose beyond the scope of this Agreement. Eon Dental agrees to protect the privacy and security of any personal data of Customers and Patients (including Patient Data) and to comply with the relevant privacy laws and regulations_fr
  <br></br>
  <b>14. TERMINATION_fr</b>
  <br></br>
  14.1. Without prejudice to its right to claim full compensation for damages suffered in accordance with the provisions of this Agreement, Eon Dental may terminate this Agreement with immediate effect as follows:_fr
  <br></br>
  </li>14.1.1. Customer breaches any of the terms and conditions of this Agreement including Articles 6.1 or 6.2_fr;</li>
  <br></br>
  <li class="t_and_c">14.1.2. &ensp;Customer becomes insolvent, is declared bankrupt, is subject to a liquidation, winding-up or receivership, suspends payment of its due debts or announces its intention to do so, or is unable to meet its obligations under this or any other agreement_fr;</li>
  <br>
  <li class="t_and_c">14.1.3. &ensp;Eon Dental considers that the Customer is likely to adversely affect the ongoing, proper, and timely treatment of Patients using the Products and Services, or otherwise meet its obligations under this Agreement, or Eon Dental's best interests_fr.</li>
  <br>
  14.2. Eon Dental may terminate this Agreement, anytime, without cause, after delivering a 30 days prior written notice to Customer._fr
  <br></br>
  <b>15. CONSEQUENCES OF TERMINATION_fr</b>
  <br></br>
  15.1. Upon expiration or termination of this Agreement for any reason, Eon Dental shall immediately destroy or return to the Customer, at the Customer's discretion and expense, all Patient Data; however, Eon Dental shall not be required to return to the Customer or destroy those copies of Patient Data that (A) reside on Eon Dental’s backup, disaster recovery or business continuity systems, or (B) Eon Dental is obligated by applicable laws or regulations to retain._fr 
  <br></br>
  15.2. Immediately upon the effective date of a termination of this Agreement for any reason, Customer shall:_fr
  <br></br>
  <li class="t_and_c">15.2.1. &ensp;Pay Eon Dental all outstanding and unpaid amounts due; and_fr</li>
  <br>
  <li class="t_and_c">15.2.2. &ensp;Cease all use of the Eon Marks and any other Intellectual Property Rights related to the Products, and the licenses to use the Eon Marks and the other Intellectual Property Rights granted hereunder will immediately cease and be deemed canceled_fr.</li>
  <br>
  <b>16. MISCELLANEOUS_fr</b>
  <br></br>
  <b>16.1. Notices_fr</b>
  <br></br>
  <li class="t_and_c">16.1.1. &ensp;Any information under or in relation to this Agreement may be exchanged by email or electronic data interchange.  Any formal notice or communication shall be in writing and shall be deemed given in accordance with Article 16.1.2 if such notice is addressed, for Eon Dental at <a href="info@eonaligner.com">info@eonaligner.com</a> (with a copy of legal notices to <a href="legal@eonaligner.com">legal@eonaligner.com</a>), and for the Customer at either the email address or physical address provided by the Customer in Eon Access_fr.</li> 
  <br>
  <li class="t_and_c">16.1.2. &ensp;Any and all notices permitted or required to be given hereunder will be deemed duly effectively given upon receipt when delivered personally; upon the earlier of forty-eight (48) hours or receipt acknowledged by return email when delivered by email; or upon forty-eight (48) hours after being deposited with an industry recognized and reputable overnight international courier service (such as Federal Express or UPS), signature required_fr.</li>  
  <br>
  <b>16.2 Relationship_fr</b>
  <br></br>
  Nothing contained in this Agreement will be construed as to: (i) create a labor relationship, (ii) constitute the parties as partners, joint venturers, co-owners or otherwise participants in a joint undertaking, (iii) give a party the power to direct and control the day-to-day activities of the other party or its Affiliates; (iv) cause a party to assume any obligation or be held liable for the contractual obligations of the another party, except that the Clinic may assume the obligations of or be held liable for the Eon Doctor; or (v) allow a party to create any obligation on behalf of the other party for any purpose whatsoever, except that the Eon Doctor may create an obligation for the Clinic._fr
  <br></br>
  <b>16.3. Entire Agreement and Amendments_fr.</b>
  <br></br>
  The Agreement including the preamble and recitals constitutes the entire agreement and understanding between the parties with respect to the subject matter of this Agreement and supersedes all previous letters, representations, warranties, or agreements, express or implied, between the Parties relating to the subject matter of the Agreement._fr 
  <br></br>
  Eon Dental shall have the right to modify or amend the Agreement from time to time. When Eon Dental makes changes to the Agreement, your continued access to or use of Eon Access, Products and Services will constitute an acceptance of any modification or amendment to the Agreement._fr
  <br></br>
  <b>16.4. Assignment_fr</b>. 
  <br></br>
  This Agreement is personal to both Eon Dental and the Customer. Eon Dental may assign in whole or in part any of its rights or obligations under this Agreement to any person, without the prior written consent of the Customer. Customer may not assign in whole or in part any of its rights or obligations under this Agreement to any person, without the prior written consent of the Eon Dental._fr 
  <br></br>
  <b>16.5. No Waiver_fr</b>. 
  <br></br>
  Eon Dental shall not be considered to have waived any rights under this Agreement by not exercising or delaying the exercise of any rights under Agreement. If Eon Dental waives any right under this Agreement, such waiver will not constitute a waiver of any other or subsequent right, even if all rights under this Agreement are similar in character._fr
  <br></br>
  <b>16.6. Severability._fr</b>
  <br></br>
  If any provision or covenant in the Agreement is unenforceable, invalid, or contrary to mandatory law, such provision or covenant will be ineffective to the extent of such unenforceability, invalidity, or illegality.  The remainder of such provision or covenant, and any other provision or covenant in the Agreement, will remain valid, legal, and enforceable._fr 
  <br></br>
  <b>16.7. Force Majeure_fr</b>
  <br></br>
  This Agreement and all obligations hereunder of Eon Dental shall be suspended for any period ("<b>Period of Suspension</b>"), and Eon Dental will not be liable in any way for failure or delay in performing its obligations under this Agreement during the Period of Suspension due to acts of God, including war or warlike conditions (e.g., terrorism, invasion, riot, or other civil unrest), blockades, international sanctions or embargoes, serious accidents, fires, floods, strikes or lock-outs, lockdowns (including epidemic or pandemic lockdowns), uncontrollable and unforeseen transport interruptions, non-performance by Eon Dental's subcontractors, suppliers or providers of their respective obligations or failure of software or hardware of said parties, government intervention, civil insurrection, epidemics or pandemics in each case, or any other even beyond Eon Dental's reasonable control (“Force Majeure”); provided, however, that Eon Dental shall promptly notify the Customer of the Force Majeure. For the avoidance of doubt, any and all obligations to pay money to Eon Dental shall remain in place during any Period of Suspension despite any Force Majeure events_fr.
  <br></br>
  <b>16.8. Governing Law and Jurisdiction_fr</b>
  <br></br>
  <li class="t_and_c">16.8.1. &ensp;This Agreement and any non-contractual obligations arising out of or in connection with it shall be governed by and construed in accordance with Belgian law._fr</li>
  <br>
  <li class="t_and_c">16.8.2. &ensp;Eon Dental and the Customer agree that any dispute, controversy or claim whatsoever arising out of, in relation to or in connection with this Agreement that cannot be settled amicably shall be finally settled by the courts of Antwerp, section Antwerp._fr</li>
  <br></br>
  `,
  loyalty_program: "Loyalty program_fr",
  your_benefits: "Your benefits_fr",
  discount: "Discount_fr",
  educational_events: "Educational events_fr",
  digita_marketing_consultation: "Digital marketing consultation_fr",
  advanced_digital_marketing_consultation:
    "Advanced digital marketing consultation_fr",
  expert_orthodontist_consultation: "Expert orthodontist consultation_fr",
  commercial_business_consultation: "Commercial & business consultation_fr",
  dedicated_clinical_specialist_CAD_designer:
    "Dedicated clinical specialist & CAD designer_fr",
  inclinic_marketing_kit: "In-clinic marketing kit_fr",
  practicep_laque: "Practice plaque_fr",
  automatically_applied: "Automatically applied_fr",
  invites_will_be_sent_by_email: "Invites will be sent by email_fr",
  book_your_session_now: "Book your session now_fr",
  free_1_session_month: "1 free session/month_fr",
  free_2_session_month: "2 free sessions/month_fr",
  free_3_session_month: "3 free sessions/month_fr",
  free_4_session_month: "4 free sessions/month_fr",
  free_5_session_month: "5 free sessions/month_fr",
  free_3_sessions: "3 free sessions_fr",
  sent_directly_to_you_if_not_received:
    "Sent directly to you, if not received_fr",
  custom: "Custom_fr",
  book_time_with_your_dedicated_specialist_via_email:
    "Book time with your dedicated specialist via email_fr",
  Automatically_sent_if_not_received: "Automatically sent, if not received_fr",
  click_here: "click here_fr",
  explore_the_full_loyalty_program: "Explore the full loyalty program_fr",
  description_of_benefits: "Description of benefits_fr",
  discount_discount_on_the_price_of_all_aligner_treatments:
    "1. Discount: discount on the price of all aligner treatments._fr",
  educational_events_online_and_in_person_events_for_learning_networking_and_collaboration:
    "2. Educational events: online and in-person events for learning, networking and collaboration._fr",
  digital_marketing_support_Take_advantage_of_digital_marketing_support_services_that_help_you_promote_and_grow_your_business_online:
    "3. Digital marketing support: Take advantage of digital marketing support services that help you promote and grow your business online ._fr",
  expert_orthodontist_consultation_one_on_one_minute_sessions:
    "4. Expert orthodontist consultation: one-on-one 30 minute sessions with an orthodontist that has extensive aligner experience and can advise doctors on specific cases._fr",
  commercial_business_consultation_One_on_one_personalized:
    "5. Commercial & business consultation: One-on-one personalized assessment sessions from our success team who are armed with data and are here to help you grow youraligner business, by understanding your goals and targets to assist you with patient pricing and more._fr",
  Dedicated_clinical_specialist_CAD_designer:
    "6. Dedicated clinical specialist & CAD designer: a team is assigned to doctors of certain tiers to make all communication around cases easier and more efficient. They assess case history, preferences and understand treatment protocols for all future cases. This decreases turnaround time, gets cases approved faster with less modifications needed (up to 2 calls per case)._fr",
  In_clinic_marketing_kit_A_complete_marketing_toolkit_to:
    "7. In-clinic marketing kit: A complete marketing toolkit to promote clear aligners in your clinic, including; brochures, marketing videos, case studies, patient testimonials and ready-to-use social media templates._fr",
  Practice_plaque_plaque_awardedby_Eon_to_recognize_and_celebrate_the_top_tiers:
    "8. Practice plaque: A plaque awarded by Eon to recognize and celebrate the top tiers. Hang it up in your clinic, or show it off in your office!_fr",
  FAQs: "FAQs_fr",
  what_is_the_duration_for_this_loyalty_program:
    "  What is the duration for this loyalty program?_fr",
  the_duration_is_12_months_meaning_the_case_counter_resets_every_year_to_zero:
    "The duration is 12 months, meaning the case counter resets every year to zero. That being said, for the next 12 months you still get to enjoy your benefits, just make sure_fr",
  you_achieve_at_least_the_minimum_number_of_cases_to_maintain_your_tier:
    "you achieve at least the minimum number of cases to maintain your tier._fr",
  how_do_I_upgrade_to_higher_tiers: "How do I upgrade to higher tiers?_fr",
  our_system_counts_the_number_of_approved_cases_youve_achieved:
    "Our system counts the number of approved cases you’ve achieved, once you hit a certain number that corresponds to the minimum required for the next tier,_fr",
  you_will_be_automatically_upgraded: "you will be automatically upgraded._fr",
  Is_it_possible_to_downgrade_to_lower_tiers:
    "Is it possible to downgrade to lower tiers?_fr",
  Yes_it_is_downgrade_check_happens_after_year_2_of_tier_attainment:
    "Yes it is. A downgrade check happens after year 2 of tier attainment, meaning you can enjoy the benefits of a tier until a check is done in year 2. If the number_fr",
  of_cases_is_notin_the_range_of_current_tier_then_downgrade_occurs_that_matches_your_performance:
    "of cases is not in the range of current tier then a downgrade occurs that matches your performance._fr",
  are_all_cases_counted_the_same: "Are all cases counted the same?_fr",
  No_Eon_Basic_is_counted_as_half_a_case_and_Eon_Pro_and_Eon_Plus_are_counted_as_one_case:
    "No, Eon Basic is counted as half a case and Eon Pro and Eon Plus are counted as one case._fr",
  How_do_I_access_my_benefits: "How do I access my benefits?_fr",
  the_consultation_related_benefits_are_bookable_through_the_links:
    "The consultation related benefits are bookable through the links found above (in the section titled Your Benefits). Some benefits are automatically applied_fr",
  like_discounts_inclinic_marketing_kits_are_sent_automatically:
    "like discounts, in-clinic marketing kits are sent automatically, practice plaques are sent yearly and as for educational events you will receive invites by email._fr",
  cases_for_each_tier_refer_to_the_number_of_approved_cases_per_year:
    "Cases for each tier refer to the number of approved cases per year. Until October 1st 2022, Eon Basic, Plus and Pro case types will all be counted as 1 case, after_fr",
  which_Eon_Basic_will_be_counted_as_half_a_case:
    "which Eon Basic will be counted as half a case._fr",
  cases_required_to_upgrade: "Cases required to maintain the current tier next year_fr",
  tier_period: "Tier period_fr",
  your_tier: "Your tier_fr",
  What_are_my_benefits: "What are my benefits?_fr",
  menu: "Menu_fr",
  upper_aligners: "Upper Aligners_fr",
  lower_aligners: "Lower Aligners_fr",
  please_be_advised: "Please be advised_fr",
  approve_ts_note: `<div className="details_normal" >Once you click <span class="details_bold"> approve </span>, aligner manufacturing will start immediately, and this case is <span class="details_bold"> non-refundable </span>. </div> _fr`,
  submit_revision: "Submit Revision_fr",
  terms_and_condition: "Terms and Conditions_fr",
  weve_updated_our_terms_and_conditions:
    "We’ve Updated our Terms and Conditions_fr",
  accept_terms_note:
    "By accepting you agree to our updated terms and conditions._fr",
  ar: "عربي",
  start_new_treatment: "Start New Treatment_fr",
  addtional_aligners: "Addtional Aligners_fr",
  confirmation_start_new_treatment:
    "You’ve used up all of the additional aligners included in your case. Place an order for a new treatment.",
  related_cases: "Related Cases_fr",
  resources: "Resources _fr",
  resource_1_title: "patient information and consent form_fr",
  terms_and_condition2: "Terms & Conditions_fr",
  patient_consent_form: "Patient Consent Form_fr",
  media_consent_form:"Media Consent Form_fr",
  attention_Pending_T_S_approval:"Attention! Pending T.S approval_fr",
  Dear_valued_doctor_please_be_aware_that_if_you_dont_approve_the_case_in :" Dear valued doctor, please be aware that if you don’t approve the case in_fr" , 
  it_will_automatically_expire_cancellation_charges_apply_for_expired_cases_submitted_after_October:"it will automatically expire. Cancellation charges apply for expired cases submitted after October 1st." , 
  pickup_address:"Pickup address_fr", 
  Heads_up_treatment_duration_expiring_in:"Heads up! Treatment duration expiring in_fr", 
  days:"days_fr" , 
  this_patients_treatment_duration_expires_soon_and_the_case_will_automatically:"This patient's treatment duration expires soon, and the case will automatically be marked as complete_fr" ,
  on :"on_fr" ,
  you_wont_be_able_to_request_refinements_andor_receive_your_free_retainer :" You won't be able to request refinements and/or receive your free retainer._fr",
  change :"Change_fr", 
  change_arch_selection:"Change Arch Selection_fr" ,
  cancel:"Cancel_fr" ,
  change_case_type:"Change Case Type_fr" ,
  arch :"Arch_fr",
  case_package_has_been_changed:"Case package has been changed:_fr",
  do_you_agree_with_this_change:"Do you agree with this change?_fr" ,
  please_note_that_your_package_selection_has_been_changed_to:" Please note that your package selection has been changed to support you in achieving your desired treatment outcome._fr" ,
  decline:"Decline_fr",
  case_type_successfully_updated:"Case Type Updated Successfully_fr" , 
  arch_successfully_updated:"Arch Updated Successfully_fr"

  ,missingIPR :"missingIPR_fr", 
  need_help_choosing_your_package_type : "Need help choosing your package type?_fr", 
  evaluate_your_case:"Evaluate Your Case_fr" , 
  evaluate :" Evaluate" , 
  your_case:"Your Case" , 
  clinic_name:"Clinic Name_fr",
  ipr_generation: "IPR file is being generated._fr",
};
