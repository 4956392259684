import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectDentalNotation } from "../../redux/user/reducer";
import {
  selectAdvancedModules,
  selectEnableAdvancedModules,
} from "../../redux/CaseSubmission/reducer";

import {
  isEmptyObject,
  useAdvancedModulesState,
  WrappedDoctorCaseModules,
} from "@eon-dental/advanced-modules/dist/rollup-export";

export const AdvancedModulesNew = ({ onChange = () => {} }) => {
  const { advancedModulesRef, saveAdvancedModules } = useAdvancedModulesState();
  const caseAdvancedModules = useSelector(selectAdvancedModules);
  const dentalNotation = useSelector(selectDentalNotation);
  const enableAdvancedModules = useSelector(selectEnableAdvancedModules);

  useEffect(() => {
    advancedModulesRef.current && onChange(advancedModulesRef.current);
  }, [advancedModulesRef.current]);

  const initialAdvancedModules = !isEmptyObject(caseAdvancedModules)
    ? caseAdvancedModules
    : advancedModulesRef.current;

  // if (!enableAdvancedModules) return null;

  return (
    <WrappedDoctorCaseModules
      numberingSystem={dentalNotation}
      onSave={saveAdvancedModules}
      advancedModules={initialAdvancedModules}
    />
  );
};
