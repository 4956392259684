import { UPDATE_INBOX, PUSH_MSG, UPDATE_ATTACHMENT } from "../constants/";
const INIT_STATE = {
  messages: [],
  message: {
    attachment_name: "",
    attachment_size: 0,
    attachment_url: "",
    loading_percentage: 0,
    msg: "",
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_INBOX:
      return {
        ...state,
        messages: action.payload,
      };
    case PUSH_MSG:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case UPDATE_ATTACHMENT:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
};
