import {
  CASE_CROWDING,
  CASE_TOOTH_MOVEMENT_RESTRICTIONS,
  CASE_EXTRACTION,
  CASE_CROSSBITES,
  CASE_CLASSII,
  CASE_CLASSIII,
  CASE_ATTACHMENTS,
  CASE_IPR,
  CASE_PONTICS,
  CASE_OVERCORRECTION,
  CASE_PASSIVE_ALIGNERS,
  CASE_TORQUE_ENHANCERS,
  CASE_ELASTICS,
  CASE_ELASTICS_BUTTONS,
  CASE_ELASTICS_CUTS,
  CASE_ARCH_EXPANSION,
  DOCTOR_PREFERENCES_IPR,
  DOCTOR_PREFERENCES_ATTACHMENTS,
  CLEAR_TEETH_DATA,
} from "../constants";
import { upper_teeth, lower_teeth } from "../newCase/init_teeth";
const INIT_STATE = {
  Case_Crowding: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Tooth_Movement_Restrictions: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Extraction: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Crossbites: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_ClassII: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_ClassIII: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Attachments: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_IPR: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Pontics: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Overcorrections: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Passive_Aligners: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Torque_Enhancers: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Elastics: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Elastics_Buttons: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Elastics_Cuts: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Case_Arch_Expansion: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Doctor_Preferences_Attachments: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
  Doctor_Preferences_IPR: {
    upper_teeth: Object.assign({}, upper_teeth),
    lower_teeth: Object.assign({}, lower_teeth),
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CASE_CROWDING:
      return {
        ...state,
        Case_Crowding: action.payload
      }
    case CASE_TOOTH_MOVEMENT_RESTRICTIONS:
      return {
        ...state,
        Case_Tooth_Movement_Restrictions: action.payload,
      };
    case CASE_EXTRACTION:
      return {
        ...state,
        Case_Extraction: action.payload,
      };
    case CASE_CROSSBITES:
      return {
        ...state,
        Case_Crossbites: action.payload,
      };
    case CASE_CLASSII:
      return {
        ...state,
        Case_ClassII: action.payload,
      };
    case CASE_CLASSIII:
      return {
        ...state,
        Case_ClassIII: action.payload,
      };
    case CASE_ATTACHMENTS:
      return {
        ...state,
        Case_Attachments: action.payload,
      };
    case CASE_IPR:
      return {
        ...state,
        Case_IPR: action.payload,
      };
    case CASE_PONTICS:
      return {
        ...state,
        Case_Pontics: action.payload,
      };
    case CASE_OVERCORRECTION:
      return {
        ...state,
        Case_Overcorrections: action.payload,
      };
    case CASE_PASSIVE_ALIGNERS:
      return {
        ...state,
        Case_Passive_Aligners: action.payload,
      };
    case CASE_TORQUE_ENHANCERS:
      return {
        ...state,
        Case_Torque_Enhancers: action.payload,
      };
    case CASE_ELASTICS:
      return {
        ...state,
        Case_Elastics: action.payload,
      };
    case CASE_ELASTICS_BUTTONS:
      return {
        ...state,
        Case_Elastics_Buttons: action.payload,
      };
    case CASE_ELASTICS_CUTS:
      return {
        ...state,
        Case_Elastics_Cuts: action.payload,
      };
    case CASE_ARCH_EXPANSION:
      return {
        ...state,
        Case_Arch_Expansion: action.payload,
      };
    case DOCTOR_PREFERENCES_ATTACHMENTS:
      return {
        ...state,
        Doctor_Preferences_Attachments: action.payload,
      };
    case DOCTOR_PREFERENCES_IPR:
      return {
        ...state,
        Doctor_Preferences_IPR: action.payload,
      };
    case CLEAR_TEETH_DATA:
      return {
        ...state,
        [action.payload]: INIT_STATE[action.payload],
      }
    default:
      return state;
  }
};
