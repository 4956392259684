import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import rej_resone from "../../../../assets/images/photos/rej_resone.svg";
import { setRejectionReson } from "../../../../redux/Tooth_IPR_Options/action";

import { ReactComponent as Front } from "../../../../assets/images/icon/front_position.svg";
import { ReactComponent as Right } from "../../../../assets/images/icon/right_position.svg";
import { ReactComponent as Left } from "../../../../assets/images/icon/left_position.svg";
import { changePhotoPosition } from "../../../../redux/newCase/action";
import { MissingPhotosXraysData } from "../../../../helpers/photos-xrays";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setRejectionReson: (payload) => dispatch(setRejectionReson(payload)),
  changePhotoPosition: (payload) => dispatch(changePhotoPosition(payload)),
});

class ReasonsRejection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      details: this.props.teethIPROptions.rejection_reason,
      position: this.props.newCase.position,
    };
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  handelDetails = (e) => {
    this.setState({ details: e.target.value });
  };

  saveRejectionReason = () => {
    this.props.setRejectionReson(this.state.details);
    this.toggle();
  };

  changePosition = (position) => {
    this.setState({ position: position });

    this.props.changePhotoPosition(position);
  };
  checkIfMatch = (link) => {
    if (link?.match(/http([^&]+)/)) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    const { content } = this.props.languageReducer;
    const { photos_xrays } = this.props.caseReducer.case;
    const { position } = this.state;

    const photos_position = {
      front: this.checkIfMatch(photos_xrays.frontal) ? photos_xrays.frontal : MissingPhotosXraysData['frontal'],
      right: this.checkIfMatch(photos_xrays.right_buccal) ? photos_xrays.right_buccal : MissingPhotosXraysData['right_buccal'],
      left: this.checkIfMatch(photos_xrays.left_buccal) ? photos_xrays.left_buccal : MissingPhotosXraysData['left_buccal'],
    };
    return (
      <div className=" cmt2">
        <div className="bite-check-grid">
          <div className="positions_image">
            <img src={photos_position[position]} alt={position}></img>
          </div>
          <div className="positions">
            <div
              className={`${position === "right" ? "active-right" : "not-active-right"
                }`}
            >
              <Right
                onClick={() => {
                  this.changePosition("right");
                }}
              />

              <div className="cmt1">{content.right}</div>
            </div>
            <div
              className={`${position === "front" ? "active-front" : "not-active-front"
                }`}
            >
              <Front
                onClick={() => {
                  this.changePosition("front");
                }}
              />
              <div className="cmt1">{content.front}</div>
            </div>

            <div
              className={`${position === "left" ? "active-left" : "not-active-left"
                }`}
            >
              <Left
                onClick={() => {
                  this.changePosition("left");
                }}
              />
              <div className="cmt1">{content.left}</div>{" "}
            </div>
          </div>
          <div className="biteChech-mainButton cmt3" onClick={this.toggle}>
            {content.Reject_Bite_Records}
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className=" ReasonsRejection-container"
        >
          <ModalHeader
            className="ReasonsRejection-title    re-res-title-prop"
            toggle={this.toggle}
          >
            {content.Reject_Bite_Records}
          </ModalHeader>
          <ModalBody>
            <textarea
              className="ReasonsRejection-textarea"
              name="details"
              id="details"
              type="textarea"
              placeholder="Type here"
              rows={5}
              value={this.state.details}
              onChange={this.handelDetails}
            />

            {/* <p className="ReasonsRejection-subtitle">Submit new photo records and<br/> impressions in case details.</p> */}
            <div className="">
              {" "}
              <img
                className="ReasonsRejection-image"
                src={rej_resone}
                alt="ReasonsRejection"
              />
            </div>
          </ModalBody>

          <div className="ReasonsRejection-container-button">
            <Button
              className="ReasonsRejection-button light-green-btn btn"
              onClick={this.saveRejectionReason}
            >
              {content.submit}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReasonsRejection);
