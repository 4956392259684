import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import TeethPDF from "../TeethPDF";

const styles = StyleSheet.create({
    row: {
        paddingTop: 10,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingBottom: 10,
    },

    header: {
        fontSize: 12,
        textAlign: "left",
        color: "#00a78c",
        width: "100%",
        marginTop: 5
    },
    container: {
        flexDirection: "row",
        alignItems: "center",
        borderBottomWidth: 1,
        borderColor: "#E6E6FA",
        paddingBottom: 5,
        // paddingTop: 10,
    },
    container1: {
        flexDirection: "row",
        alignItems: "center",
        // paddingTop: 10,
    },
    container2: {
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 5,
    },
    text_area: {
        flexGrow: 1,
        borderColor: "#a0f0e7",
        borderWidth: 1,
        borderRadius: 2,
        fontSize: 8,
        padding: 2,
        margin: 3,
        minHeight: "70px",
        flexDirection: "column",
    },
    field: {
        flexDirection: "row",
        paddingTop: 10,
    },
    col: {
        flexDirection: "column",
        width: "50%",
    },
    col_4: {
        flexDirection: "column",
        width: "40%",
    },
    col_5: {
        flexDirection: "column",
        width: "50%",
    },
    col_55: {
        flexDirection: "column",
        width: "55%",
    },
    col_6: {
        flexDirection: "column",
        width: "60%",
    },
    col_1: {
        flexDirection: "column",
        width: "10%",
    },
    col_3: {
        flexDirection: "column",
        width: "30%",
    },
    col_7: {
        flexDirection: "column",
        width: "70%",
    },
    col_15: {
        flexDirection: "column",
        width: "15%",
    },
    col_25: {
        flexDirection: "column",
        width: "25%",
    },
    col_05: {
        flexDirection: "column",
        width: "5%",
    },
    title: {
        fontSize: 10,
        textAlign: "left",
        color: "#00a78c",
        padding: "10px 0 5px 0",
    },
    title2: {
        fontSize: 10,
        textAlign: "left",
        color: "#00a78c",
    },
    info: {
        fontSize: 10,
        textAlign: "left",
    },
    image: {
        // backgroundColor: "red",
        borderRadius: 4,
    },

    green_btn_not_active: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#d7f5f2",
        borderWidth: 1,
        backgroundColor: "white",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    hybrid_green_btn_not_active: {
        width: "auto",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#d7f5f2",
        borderWidth: 1,
        backgroundColor: "white",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    col_title: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        fontSize: 8,
        padding: "0 5px 0 0",
        // margin: 1,
        flexDirection: "column",
        width: "10%",
    },
    green_btn_active: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00a78c",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    hybrid_green_btn_active: {
        width: "auto",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00a78c",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    emplty_col: {
        width: "30%",
    },
    text_active: {
        fontSize: 8,
        color: "white",
        padding: 1,
    },
    text_not_active: {
        fontSize: 8,
        color: "#00a78c",
        padding: 1,
    },
    teeth_border: {
        borderWidth: 1,
        borderColor: "#00a78c",
    },
  
    photo_header: {
        flexDirection: "column",
        width: "100px",
        margin: 5,
        fontSize: 8,
        color: "#00a78c",
        textAlign: "center",
    },
    value: {
        padding: "10px",
    },
    photo: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
        zIndex: 99,
    },
    space: {
        flexDirection: "column",
        width: "10%",
    },
    active_card: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
    },
    card: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
        backgroundColor: "white",
    },
    hybrid: {
        flexDirection: "column",
        width: "90%",
        height: "auto",
        padding: "50px",
    },
    hybrid_header: {
        flexDirection: "column",
        width: "100%",
        margin: 5,
        fontSize: 8,
        color: "#00a78c",
        textAlign: "left",
        marginTop: 20
    },
    hybrid_header2: {
        flexDirection: "column",
        width: "100%",
        margin: 5,
        marginBottom:0,
        fontSize: 8,
        color: "#00a78c",
        textAlign: "left",
        marginTop: 10
    },
    buttons_container: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 5,
    },
    hybrid_subTitle: {
        fontSize: 8,
        color: "#00a78c",
        width: "30px",
        flexDirection: "column",
        margin: 5,
    },
    row_space: {
        flexDirection: "row",
        height: "10px",
    },
});

export default class Elastics extends Component {
    render() {
        const { elastics } = this.props.AdvancedModules;
        const { content } = this.props

        return (
            <Fragment>
                <View>
                    <Text style={styles.header}>{content.elastics}</Text>
                    <View style={styles.container1}>
                        <View style={styles.col_4}>
                            <Text style={styles.hybrid_header2}>{`${content.Use_elastics_for_this_case} : ${elastics.useElastics === true ? `${content.yes}`  : elastics.useElastics === "eonToDecide" ? `${content.eonToDecide}` : `${content.no}`}`}
                            </Text>
                            <Text style={styles.hybrid_header2}>{`${content.buttons} : ${elastics.use_buttons}`} </Text>
                        </View>
                        <View style={styles.col_6}>
                            <Text style={styles.hybrid_header2}>
                            {content.select_the_teeth_requiring_buttons_placement} </Text>
                            <TeethPDF teeth={elastics.buttons_teeth} />
                        </View>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.col_4}>
                            <Text style={styles.hybrid_header2}>{`${content.cuts} : ${elastics.use_cuts}`} </Text>
                            <Text style={styles.hybrid_header2}>
                                {content.additional_comments} </Text>
                            <View style={styles.text_area}>
                                <Text style={styles.value}>
                                    {elastics.details}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.col_6}>
                            <Text style={styles.hybrid_header2}>
                                {content.select_the_teeth_requiring_precision_cuts_placement} </Text>
                            <TeethPDF content={content} teeth={elastics.cuts_teeth} />
                        </View>
                    </View>
                </View>
            </Fragment>
        );
    }
}
