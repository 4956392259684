import React from "react";
import { connect } from "react-redux";
import { caseType } from "../../helpers/caseType";

export const RelatedCases = (props) => {
  const { content } = props.languageReducer;
  const { related_aligner_cases } = props;

  const wearCycle = {
    express: "1 Week",
    regular: "2 Week",
  };
  return (
    <div className="cpt2">
      <span className="related_cases_grid">
        <span className="  black-title  fs12m caption-1-bold-text">
          {content.related_cases}{" "}
        </span>
        {related_aligner_cases.map((value, index) => (
          <span key={index} className="related-cases-row-grid ">
            <span className="body-text related_case_id ctooltipTopBlack">
              {" "}
              <span
                onClick={() => {
                  props.openRelatedCase(value.id, value.status);
                }}
              >
                {value.status === "Incomplete Submission"
                  ? value.id
                  : value.uid}
              </span>
              <span
                className={
                  value.status === "Incomplete Submission"
                    ? "tooltiptextSmall top-right2"
                    : "tooltiptext top-right2"
                }
              >
                <div>
                  <span className="tooltop-title-hover">
                    {content.case_type_with_capital_t}
                  </span>
                  {`: `}
                  <span>
                    {content[caseType[value.case_type]] || value.case_type}
                  </span>
                </div>
                <div>
                  <span className="tooltop-title-hover">
                    {content.wear_cycle}
                  </span>
                  {`: `}
                  <span>{wearCycle[value.wear_cycle] || value.wear_cycle}</span>
                </div>
                <div>
                  <span className="tooltop-title-hover">
                    {content.case_status}
                  </span>
                  {`: `} <span>{value.display_status}</span>
                </div>
              </span>
            </span>
            <span>{index < related_aligner_cases.length - 1 && ` , `}</span>
          </span>
        ))}
      </span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(RelatedCases);
