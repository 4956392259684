import React, { useMemo } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

import file_icon from "../../assets/images/caseActions/file.svg";
import MyDocument from "./MyDocument";

const DownloadPdf = (props) => {
  const content = props.content;
  return useMemo(
    () => (
      <PDFDownloadLink
        document={
          <MyDocument
            content={content}
            case={props.case}
            mappedData={props.mappedData}
            doctorInfo={props.doctorInfo}
          />
        }
        fileName={`treatment form_${props.uid}.pdf`}
      >
        {({ blob, url, loading, error }) => (
          <div className="s_card_grid actions-card">
            <div className="text-center">
              {loading ? (
                <div className="submit-loader">
                  <div className="loader"></div>
                </div>
              ) : (
                <img src={file_icon} alt={content.download_pdf} />
              )}
            </div>
            <div className="subhead-text">
              {loading
                ? `${content.loading_document}...`
                : content.download_pdf}
            </div>
          </div>
        )}
      </PDFDownloadLink>
    ),
    []
  );
};

export default DownloadPdf;
