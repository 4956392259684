import React, { Component } from "react";
import { connect } from "react-redux";
import CustomeHeader from "../../../../components/header/CustomeHeader";
import IPRSummary from "./IPRSummary";
import TeethMovementSummary from "./TeethMovementSummary"
import {
  Input,
} from "reactstrap";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({

});

class Summary extends Component {
  // constructor(props) {
  //   super(props);


  // }
  render() {

    const { content } = this.props.languageReducer;



    const { rejection_reason,
      clinical_protocol_feedback,
      bite_check_changed,
      teeth_movement_changed,
      ipr_changed,
      treatment_protocol_changed

    } = this.props.revised_data ? this.props.teethIPROptions[this.props.id] : this.props.teethIPROptions;
    return (

      <div className={this.props.revised_data ? "revised-summary-grid" : " summary-grid"}>

        {bite_check_changed || teeth_movement_changed || ipr_changed || treatment_protocol_changed ? <>
          <CustomeHeader title={this.props.revised_data ? content.revised_data : content.case_summary} />
          {/*--------------------------------------------------------------------------------*/}
          {/* Bite Record Summary*/}
          {/*--------------------------------------------------------------------------------*/}
          <div className="cml2 summary-continer">
            <div className="bite-record-summary cmt2">
              {content.bite_check}:
              {rejection_reason ?
                <span className="rejected-bite-record cpl4">{content.rejected}</span> :
                <span className="approved-bite-record cpl4">{content.approved}</span>
              }
            </div>
            {
              rejection_reason &&
              <Input
                name="ReasonsRejection"
                id="ReasonsRejection"
                type="textarea"
                rows={5}
                className="custome-textarea2 cmt2 cmb2"
                readOnly
                defaultValue={rejection_reason}
              />

            }
            <div className="c-line-summary"></div>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Bite Record Summary*/}
            {/*--------------------------------------------------------------------------------*/}


            {/*--------------------------------------------------------------------------------*/}
            {/* Teeth Movement Summary*/}
            {/*--------------------------------------------------------------------------------*/}
            {teeth_movement_changed &&
              <><div className="bite-record-summary cmt2">
                {content.teeth_movement}
              </div>
                <TeethMovementSummary revised_data={this.props.revised_data} id={this.props.id} />
                <div className="c-line-summary"></div></>}

            {/*--------------------------------------------------------------------------------*/}
            {/* End Teeth Movement Summary*/}
            {/*--------------------------------------------------------------------------------*/}


            {/*--------------------------------------------------------------------------------*/}
            {/* IPR Summary*/}
            {/*--------------------------------------------------------------------------------*/}
            {ipr_changed &&
              <><div className="bite-record-summary cmt2">
                {content.ipr}
              </div>
                <IPRSummary revised_data={this.props.revised_data} id={this.props.id} />
                <div className="c-line-summary"></div>
              </>
            }

            {/*--------------------------------------------------------------------------------*/}
            {/* End IPR Summary*/}
            {/*--------------------------------------------------------------------------------*/}


            {/*--------------------------------------------------------------------------------*/}
            {/* Treatment Protocols Summary*/}
            {/*--------------------------------------------------------------------------------*/}
            {treatment_protocol_changed &&
              <div>
                <div className="bite-record-summary cmt2">
                  {content.revision_notes}
                </div>
                <Input
                  name="clinical_protocol_feedback"
                  id="clinical_protocol_feedback"
                  type="textarea"
                  rows={5}
                  className="custome-textarea2 cmt2 cmb2"
                  readOnly
                  defaultValue={clinical_protocol_feedback}
                /></div>
            }
            {/*--------------------------------------------------------------------------------*/}
            {/* End Treatment Protocols Summary*/}
            {/*--------------------------------------------------------------------------------*/}

          </div>
        </> :

          <div className="no-revision-data">
            {content.no_revesion_made}
          </div>

        }




      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary);