import React, { Component } from "react";
import {
  CardFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as yup from "yup";
import { connect } from "react-redux";
import Select from "react-select";
import { gendar } from "../../helpers/gendar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { schema, schemaRetainer } from "../../helpers/patientInfoValidation";
import { address_schema } from "../../helpers/addressValidations";
import { countries, GetCountryLabel } from "../../helpers/countries";
import { successToaster } from "../../services/toast";
import { mapCountry } from "../../helpers/countries";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  updateAdvancedModules,
  updatePatientInfo,
} from "../../redux/CaseSubmission/action";
import { mapTeethToApi } from "../../services/mapTeethToApi";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import {
  createAlignerCase,
  updateAddress,
  createAddress,
  getDoctorAddresses,
  updateAlignerCase,
} from "../../api/api";
import { mapAdvancedToApi } from "../../services/mapAdvancedToApi";
import CustomeHeader from "../../components/header/CustomeHeader";
import CustomRadioGroup from "../../components/radioGroup/CustomRadioGroup";
import check from "../../assets/images/v4/check_small.svg";
import { ToolTipEonBasic, ToolTipEonPlus, ToolTipEonPro } from "./tooltip";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import AssessmentTool from "../assessment-tool/App";
import { GetCountryValue } from "../../helpers/countries";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  updatePatientInfo: (payload) => dispatch(updatePatientInfo(payload)),
});
const years = range(1940, 2006, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
class PatientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingAddress: "",
      addresses: this.props.addresses,
      gender: "",
      line1: "",
      line2: "",
      zip: "",
      phone: "",
      state: "",
      country: this.props.userReducer.currentUser.country,
      city: "",
      id: "",
      clinic_name: "",
      createUpdateAddressModal: false,
      date: "",
      cancelSubmission: false,
      gendarClicked: false,
      hybrid: this.props.hybrid,
      pickerOpen: false,
      parent_aligner_case_id: props.parent_aligner_case_id,
    };
  }
  /*--------------------------------------------------------------------------------*/
  /* resize card height  */
  /*--------------------------------------------------------------------------------*/
  componentDidMount = () => {
    // window.addEventListener("resize", this.updateSize());
  };
  /*--------------------------------------------------------------------------------*/
  /* resize card  height if screen size update   */
  /*--------------------------------------------------------------------------------*/
  componentDidUpdate = () => {
    //window.addEventListener("resize", this.updateSize());
  };
  /*--------------------------------------------------------------------------------*/
  /* update Size   */
  /*--------------------------------------------------------------------------------*/
  updateSize = () => {
    let vh = window.innerHeight - 300;
    if (window.innerWidth < 840) {
      document
        .getElementById("case-submission")
        .style.setProperty("height", `${vh}px`);
    } else {
      document
        .getElementById("case-submission")
        .style.setProperty("height", `100`);
    }
  };

  checkIfAddressIsFull = (address) => {
    let currentAddress = this.state.addresses.filter((v) => v.id === address);

    if (currentAddress.length > 0) {
      if (
        !currentAddress[0].country ||
        !currentAddress[0].line1 ||
        !currentAddress[0].phone ||
        !currentAddress[0].line2 ||
        !currentAddress[0].clinic_name
      ) {
        return true;
      }
      return false;
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*patient info step submit  */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const doctorId = this.props.userReducer.currentUser.id;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    const { parent_aligner_case_id } = this.state;
    const parent_id =
      this.props.caseReducer?.case?.parent_aligner_case?.id || "";
    setSubmitting(true);
    // Create new Date instance
    var newdate = values?.date_of_birth ? new Date(values?.date_of_birth) : "";
    // Add a day
    newdate && newdate.setDate(newdate.getDate() + 1);
    let userInfo = {
      aligner_case: {
        step: "",
        patient_attributes: {
          date_of_birth: newdate,
          first_name: values.firstName,
          gender: values.gendar,
          last_name: values.lastName,
          email: values.email,
        },
        case_type: values.case_type,
        parent_aligner_case_id: parent_aligner_case_id || parent_id,
      },
      address_id: values.address_id,
    };

    let patientInfo = {
      date_of_birth: newdate,
      first_name: values.firstName,
      gender: values.gendar,
      last_name: values.lastName,
      email: values.email,
      case_type: values.case_type,
      address_id: values.address_id,
      parent_aligner_case_id: parent_aligner_case_id || parent_id,
    };
    if (caseId) {
      updateAlignerCase(doctorId, caseId, JSON.stringify(userInfo))
        .then(async (res) => {
          setSubmitting(false);
          this.props.updatePatientInfo(patientInfo);

          this.props.NextStep();
          // await this.props.GetAlignerCaseById();
        })
        .catch((error) => {
          setSubmitting(false);
        });
    } else {
      // this.props.NextStep();
      createAlignerCase(doctorId, JSON.stringify(userInfo))
        .then(async (res) => {
          this.props.props.history.replace(
            `${window.location.pathname}?id=${res.id}`
          );

          await this.props.updatePatientInfo(patientInfo);
        })
        .then(async (res1) => {
          await this.props.GetAlignerCaseById();
        })
        .then((res2) => {
          const caseId = (window.location.search.match(/id=([^&]+)/) || [])[1];

          if (!this.props.case.case_type === "Retainer") {
            this.UpdateTreatmentType(caseId, setSubmitting);
          } else {
            this.props.NextStep();
            setSubmitting(false);
          }
        })
        .catch((error) => {
          setSubmitting(false);
        });
    }
  };
  UpdateTreatmentType = (caseId, setSubmitting) => {
    let data = {
      aligner_case: {
        step: "Treatment Type",
        advanced_modules: {
          ...this.props.caseSubmission.advanced_modules,
          ipr: {
            ...this.props.caseSubmission.advanced_modules.ipr,
            iprRestriction: mapTeethToApi(
              this.props.customeTeethReducer.Case_IPR
            ),
          },
          archExpansion: {
            ...this.props.caseSubmission.advanced_modules.archExpansion,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Arch_Expansion
            ),
          },
          attachments: {
            ...this.props.caseSubmission.advanced_modules.attachments,
            attachmentsRestriction: mapTeethToApi(
              this.props.customeTeethReducer.Case_Attachments
            ),
          },
          elastics: {
            ...this.props.caseSubmission.advanced_modules.elastics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics),
            buttons_teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Elastics_Buttons
            ),
            cuts_teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Elastics_Cuts
            ),
          },
          extraction: {
            ...this.props.caseSubmission.advanced_modules.extraction,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Extraction
            ),
          },
          overCorrection: {
            ...this.props.caseSubmission.advanced_modules.overCorrection,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Overcorrections
            ),
          },
          pontics: {
            ...this.props.caseSubmission.advanced_modules.pontics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Pontics),
          },
          torqueEnhancers: {
            ...this.props.caseSubmission.advanced_modules.torqueEnhancers,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Torque_Enhancers
            ),
          },
          malocclusion: {
            ...this.props.caseSubmission.advanced_modules.malocclusion,
            crossbite: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crossbite,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Crossbites
              ),
            },
            crowding: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crowding,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_CrowdincheckIfAddressIsFullg
              ),
            },
            classII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classII,
              teeth: mapTeethToApi(this.props.customeTeethReducer.Case_ClassII),
            },
            classIII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classIII,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_ClassIII
              ),
            },
          },
          all_modules: mapAdvancedToApi(this.props.advancedModules.modules),
          treatment_type: {
            type: "aligner",
          },
        },
        wear_cycle: this.props.caseSubmission.prescription.wear_cycle_saved,
      },
    };

    updateAlignerCase(
      this.props.userReducer.currentUser.id,
      caseId,
      JSON.stringify(data)
    ).then((res) => {
      this.props.GetAlignerCaseById();
      this.props.NextStep();

      setSubmitting(false);
      this.props.updateAdvancedModules({
        key: "treatment_type",
        data: data.aligner_case.advanced_modules.treatment_type,
      });
    });
  };
  toggleCreateUpdateAddressModal = () => {
    this.setState({
      createUpdateAddressModal: !this.state.createUpdateAddressModal,
    });
  };
  clearAddress = () => {
    this.setState({
      country: this.props.userReducer.currentUser.country,
      city: "",
      state: "",
      line1: "",
      clinic_name: "",
      line2: "",
      zip: "",
      phone: "",
    });
  };
  GetDoctorAddresses = () => {
    getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
      let newAddress = mapCountry(res);

      this.setState({
        addresses: res,
        newAddressArr: newAddress,
        loadData: true,
      });
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  customStyles for select */
  /* --------------------------------------------------------------------------------*/
  customStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: `8px`,
      padding: "10px",
    }),
    option: (base) => ({
      ...base,
      borderRadius: `8px`,
      height: "100%",
    }),
  };

  /* --------------------------------------------------------------------------------*/
  /*  reset Date Picker */
  /* --------------------------------------------------------------------------------*/
  handleResetDatePicker = () => {
    this.setState({ pickerOpen: false });
  };
  handelOpen = () => {
    this.setState({ pickerOpen: true });
  };
  /* --------------------------------------------------------------------------------*/
  /*  Create and  Update Address */
  /* --------------------------------------------------------------------------------*/
  handleSubmit2 = (values, { setSubmitting }) => {
    const { content } = this.props.languageReducer;

    setSubmitting(true);
    const data = {
      address: {
        country: this.props.userReducer.currentUser.country,
        city: values.city,
        state: values.state,
        line1: values.line1,
        line2: values.line2,
        zip: values.zip,
        phone: values.phone,
        clinic_name: values.clinic_name,
      },
    };

    if (this.state.id) {
      //update address
      updateAddress(this.props.id, this.state.id, JSON.stringify(data)).then(
        (res) => {
          this.GetDoctorAddresses();
          this.setState({
            createUpdateAddressModal: !this.state.createUpdateAddressModal,
            country: this.props.userReducer.currentUser.country,
            city: "",
            state: "",
            line1: "",
            clinic_name: "",
            line2: "",
            zip: "",
            phone: "",
          });
          setSubmitting(false);

          successToaster(content.updated_address_successfully, content.Success);
        }
      );
    } else {
      // add new address
      createAddress(this.props.id, JSON.stringify(data)).then((res) => {
        this.GetDoctorAddresses();
        this.setState({
          createUpdateAddressModal: !this.state.createUpdateAddressModal,
          country: this.props.userReducer.currentUser.country,
          city: "",
          state: "",
          line1: "",
          clinic_name: "",
          line2: "",
          zip: "",
          phone: "",
        });
        setSubmitting(false);
        successToaster(
          content.created_new_address_successfully,
          content.Success
        );
      });
    }
  };

  toggleCancelConfirmation = () => {
    this.setState({ cancelSubmission: !this.state.cancelSubmission });
  };

  //options design in address drop down
  addressOptionLabel = (option) => {
    return (
      <div
        style={
          {
            // borderRadius: "8px",
            // backgroundColor:this.state.shippingAddress === option.value ?"#C8C8CA":""
          }
        }
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.5fr 5fr",
            columnGap: "0.5em",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            color: "#2E2E2F !important",
          }}
        >
          {this.state.shippingAddress === option.value ? (
            <img src={check} alt="" />
          ) : (
            <div></div>
          )}
          <span
            style={{
              color: "#2E2E2F",
            }}
          >
            {option.label}
          </span>
        </div>
      </div>
    );
  };
  handelChanges = () => {
    this.setState({
      gendarClicked: !this.state.gendarClicked,
    });
  };

  render() {
    const { block_case_submission } = this.props.userReducer.currentUser;

    const { content } = this.props.languageReducer;
    const { patient_info } = this.props.caseSubmission;
    const address = this.props.caseReducer.case?.address || {};
    const { date_format } = this.props.userReducer.currentUser;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1] ||
      "";
    const { hybrid } = this.state;

    const case_typs = [
      { name: "LiteII", title: content.lite },
      { name: "Lite Single Arch", title: content.lite_single_arch },
      { name: "UnlimitedII", title: content.unlimited },
    ];
    const case_typs_new = [
      {
        name: "Eon Basic",
        title: content.eon_basic,
        com: (
          <ToolTipEonBasic
            content={content}
            handleResetDatePicker={this.handleResetDatePicker}
          />
        ),
      },
      {
        name: "Eon Plus",
        title: content.eon_plus,
        com: (
          <ToolTipEonPlus
            content={content}
            handleResetDatePicker={this.handleResetDatePicker}
          />
        ),
      },
      {
        name: "Eon Pro",
        title: content.eon_pro,
        com: (
          <ToolTipEonPro
            content={content}
            handleResetDatePicker={this.handleResetDatePicker}
          />
        ),
      },
    ];

    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/* Create and Update Adress modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.createUpdateAddressModal}
          toggle={this.toggleCreateUpdateAddressModal}
          className="c-border-light-address"
        >
          <ModalHeader
            toggle={this.toggleCreateUpdateAddressModal}
          ></ModalHeader>
          <ModalBody>
            <CustomeHeader
              title={
                this.state.city ? content.update_address : content.new_address
              }
              largeFont={false}
            />

            <Formik
              initialValues={{
                id: "",
                country: GetCountryLabel(this.state.country),
                city: this.state.city,
                line1: this.state.line1,
                clinic_name: this.state.clinic_name,
                line2: this.state.line2,
                zip: this.state.zip,
                phone: this.state.phone,
                state: this.state.state,
              }}
              // validationSchema={address_schema}

              validationSchema={() =>
                yup.lazy((values) => {
                  return address_schema(values);
                })
              }
              onSubmit={this.handleSubmit2}
            >
              {({
                touched,
                errors,
                isSubmitting,
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} className="cmt2 ">
                  <div className="add-address-height">
                    <Input type="hidden" name="id" id="id" />
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="country" className="address-title">
                            {content.country}{" "}
                          </Label>
                          <Field
                            type="text"
                            name="country"
                            id="country"
                            onBlur={handleBlur}
                            className={`custome-input-text form-control }`}
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="city" className="address-title">
                            {content.city}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            onBlur={handleBlur}
                            className={`custome-input-text form-control ${
                              touched.city && errors.city ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="city"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="line1" className="address-title">
                            {`${content.street}`}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="line1"
                            id="line1"
                            className={`custome-input-text form-control ${
                              touched.line1 && errors.line1 ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.line1}
                          />
                          <ErrorMessage
                            component="div"
                            name="line1"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="line2"
                            className="address-title"
                          >{`${content.building}`}</Label>
                          <span style={{ color: "red" }}>*</span>{" "}
                          <Field
                            type="text"
                            name="line2"
                            id="line2"
                            className={`custome-input-text form-control ${
                              touched.line2 && errors.line2 ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.line2}
                          />
                          <ErrorMessage
                            component="div"
                            name="line2"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="state" className="address-title">
                            {content.state}
                          </Label>
                          <Field
                            type="text"
                            name="state"
                            id="state"
                            className={`custome-input-text form-control ${
                              touched.state && errors.state ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                            value={values.state}
                          />
                          <ErrorMessage
                            component="div"
                            name="state"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="zip" className="address-title">
                            {content.zip}
                          </Label>
                          <Field
                            type="text"
                            name="zip"
                            id="zip"
                            className={`custome-input-text form-control ${
                              touched.zip && errors.zip ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="zip"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label for="phone" className="address-title">
                            {content.phone}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            name="phone"
                            id="phone"
                            className={`custome-input-text form-control ${
                              touched.phone && errors.phone ? "is-invalid" : ""
                            }`}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="phone"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>

                      <Col xs="12" md="6" lg="6">
                        <FormGroup>
                          <Label
                            for="clinic_name"
                            className="address-title callout-text"
                          >
                            {content.clinic_name}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Label>

                          <Field
                            type="text"
                            name="clinic_name"
                            id="clinic_name"
                            value={values.clinic_name}
                            className={`custome-input-text form-control ${
                              touched.clinic_name && errors.clinic_name
                                ? "is-invalid"
                                : ""
                            }`}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="clinic_name"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <div className="add-address-module-patiant">
                        <Button
                          className={`btn light-green-btn pull-right margin-in-address cpx3 button-seme-bold-text`}
                          size="md"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {this.state.city
                            ? `${content.update}`
                            : `${content.add}`}
                        </Button>
                        <Button
                          className="btn white-btn pull-right margin-in-address cpx3 button-seme-bold-text"
                          size="md"
                          color="danger"
                          onClick={this.toggleCreateUpdateAddressModal}
                        >
                          {content.cancel}
                        </Button>{" "}
                      </div>
                    </FormGroup>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Create and Update Adress modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Cancel Submission modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.cancelSubmission}
          toggle={this.toggleCancelConfirmation}
          className="c-modal cancel-mobile confarim-modal"
        >
          {/* <ModalHeader toggle={this.toggleCancelConfirmation}></ModalHeader> */}
          <ModalBody>
            <CustomeHeader
              title={content.cancel_submission}
              largeFont={true}
              withoutLine={true}
            />

            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 ml-3 mb-1 headline-text">
                {content.confirmation_cancel_submission}
              </label>

              <div className=" confirmation-model-grid cmt2">
                <div className="displayNone_inmobile">
                  <Button
                    className="btn light-green-btn button-seme-bold-text "
                    size="md"
                    disabled={this.state.completingCase}
                    onClick={() => (window.location.href = "/home")}
                  >
                    {content.yes}
                  </Button>
                </div>

                <div>
                  <Button
                    className=" btn white-btn pull-right cml1 button-seme-bold-text"
                    size="md"
                    color="danger"
                    disabled={this.state.completingCase}
                    onClick={() => this.toggleCancelConfirmation()}
                  >
                    {content.no}
                  </Button>
                </div>
                <div className="displayNone_website">
                  <Button
                    className="btn light-green-btn cml1 button-seme-bold-text"
                    size="md"
                    disabled={this.state.completingCase}
                    onClick={() => (window.location.href = "/home")}
                  >
                    {content.yes}
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Cancel Submission modal */}
        {/*--------------------------------------------------------------------------------*/}
        {window.innerWidth > 839 ? (
          <div>
            <CustomeHeader
              title={content.patient_information}
              largeFont={false}
            />
          </div>
        ) : (
          <h1 className="head-mobile-caseSubmission title-3-text">
            {content.patient_information}
          </h1>
        )}

        <Formik
          initialValues={{
            firstName: patient_info.first_name || "",
            lastName: patient_info.last_name || "",
            email: patient_info.email || "",
            gendar: patient_info.gender,
            date_of_birth: patient_info.date_of_birth,
            address_id: patient_info.address_id,
            case_type:
              window.location.pathname !== "/retainer"
                ? patient_info.case_type
                : "Retainer",
          }}
          // validationSchema={schemaRetainer}

          validationSchema={
            window.location.pathname === "/retainer" ? schemaRetainer : schema
          }
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="">
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>

              <div id="case-submission" className="patient-info-container">
                <div className="patient_info_grid">
                  <FormGroup>
                    <Label className="form-title colorful callout-text">
                      {content.first_name}
                    </Label>
                    <Field
                      className={`custome-input-text form-control ${
                        touched.firstName && errors.firstName
                          ? "is-invalid"
                          : ""
                      }`}
                      name="firstName"
                      id="firstName"
                      type="text"
                      onChange={(e) => {
                        setFieldValue(
                          "firstName",
                          capitalizeFirstLetter(e.target.value)
                        );
                      }}
                      disabled={hybrid}
                      value={values.firstName}
                    />{" "}
                    {touched.firstName && errors.firstName && (
                      <ErrorMessage
                        component="div"
                        name="firstName"
                        className="custom-invalid-feedback mt-1 callout-text"
                      />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-title colorful callout-text">
                      {content.last_name}
                    </Label>
                    <Field
                      className={`custome-input-text form-control ${
                        touched.lastName && errors.lastName ? "is-invalid" : ""
                      }`}
                      name="lastName"
                      id="lastName"
                      type="text"
                      disabled={hybrid}
                      onChange={(e) => {
                        setFieldValue(
                          "lastName",
                          capitalizeFirstLetter(e.target.value)
                        );
                      }}
                      value={values.lastName}
                    />
                    {touched.lastName && errors.lastName && (
                      <ErrorMessage
                        component="div"
                        name="lastName"
                        className="custom-invalid-feedback mt-1 callout-text"
                      />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-title colorful callout-text">
                      {content.patient_email_address}{" "}
                      <span className="optional callout-text">
                        ({content.optional})
                      </span>
                    </Label>
                    <Input
                      className={`custome-input-text form-control ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                      name="email"
                      id="email"
                      type="text"
                      disabled={hybrid}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                      value={values.email}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email && (
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="custom-invalid-feedback mt-1 callout-text"
                      />
                    )}
                  </FormGroup>
                  <div
                    className={`${
                      this.state.addresses.length !== 0
                        ? "margin  mb-1"
                        : "mb-1"
                    }`}
                  >
                    <Label className="form-title colorful callout-text">
                      {content.gender}
                    </Label>
                    <Select
                      name="gendar"
                      id="gendar"
                      isDisabled={hybrid}
                      options={gendar}
                      onChange={(e) => {
                        setFieldValue("gendar", e.value);
                      }}
                      className={`custome-input-text ${
                        touched.gendar && errors.gendar ? "is-invalid" : ""
                      }`}
                      value={gendar.filter((v) => v.value === values.gendar)}
                    ></Select>
                    <ErrorMessage
                      component="div"
                      name="gendar"
                      className="custom-invalid-feedback mt-1 callout-text"
                    />
                  </div>
                  <FormGroup
                    style={{ position: "relative" }}
                    className={`${
                      this.state.addresses.length !== 0 ? "margin  mb-1" : ""
                    }`}
                  >
                    <Label className="form-title colorful callout-text">
                      {" "}
                      {content.date_of_birth}{" "}
                      <span className="optional callout-text">
                        ({content.optional})
                      </span>
                    </Label>
                    <div
                      className={
                        window.location.pathname === "/retainer"
                          ? "retainerPicker"
                          : ""
                      }
                    >
                      <div className="custom-date-picker custome-input-text ">
                        <DatePicker
                          className={`custome-input-text ${
                            touched.date_of_birth && errors.date_of_birth
                              ? "is-invalid"
                              : ""
                          }`}
                          onInputClick={this.handelOpen}
                          open={this.state.pickerOpen ? true : false}
                          popperPlacement="bottom"
                          popperModifiers={{
                            flip: {
                              behavior: ["bottom"], // don't allow it to flip to be above
                            },
                            preventOverflow: {
                              enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                              enabled: false, // turn off since needs preventOverflow to be enabled
                            },
                          }}
                          disabled={hybrid}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: "24px 32px 16px",
                                display: "grid",
                                justifyContent: "center",
                                alignItems: "center",
                                gridTemplateColumns: "auto 1fr 1fr auto",
                                gridColumnGap: "16px",
                                textAlign: "center",
                              }}
                            >
                              <div
                                className="arrow-button"
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              >
                                <i className="fas fa-angle-left arrows-icon pr-1"></i>
                              </div>
                              <select
                                className="border-none"
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                className="border-none"
                                value={getYear(date)}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <div
                                className="arrow-button"
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              >
                                <i className="fas fa-angle-right arrows-icon pr-1"></i>
                              </div>
                            </div>
                          )}
                          placeholderText={
                            this.props.userReducer.currentUser.date_format
                          }
                          dateFormat={
                            this.props.userReducer.currentUser.date_format ===
                            "DD/MM/YYYY"
                              ? "dd/MM/yyyy"
                              : "MM/dd/yyyy"
                          }
                          selected={values.date_of_birth}
                          onChange={(date) => {
                            this.setState({ pickerOpen: false });
                            setFieldValue("date_of_birth", date || "");
                          }}
                        />
                      </div>
                    </div>
                    {/* <Input
                        type="date"
                        name="date_of_birth"
                        id="date_of_birth"
                        onChange={(e) => {
                          setFieldValue("date_of_birth", e.target.value);
                        }}
                      // min={"1960-01-01"}
                      // max={"2000-01-01"}
                      /> */}
                    <ErrorMessage
                      component="div"
                      name="date_of_birth"
                      className="custom-invalid-feedback mt-1 callout-text"
                    />
                  </FormGroup>
                  {
                    // <div className="mb-5">
                    <div
                      className={`${
                        window.location.pathname === "/case-submission" ||
                        window.location.pathname === "/case-submission/"
                          ? "mb-2  "
                          : "mb-5"
                      }`}
                    >
                      {caseId !== "" ? (
                        <Label className="form-title w-100 colorful callout-text">
                          {" "}
                          {content.shipping_address}{" "}
                        </Label>
                      ) : (
                        <Label className="form-title w-100 colorful callout-text">
                          {" "}
                          {content.shipping_address}
                          {this.state.addresses.length === 0 ? (
                            <div>
                              <span
                                className=""
                                style={{ fontSize: "12px", color: "red" }}
                              >
                                {content.please_add_your_shipping_address}
                                <span
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    color: "#525f7f",
                                  }}
                                  className="title_active"
                                  onClick={() =>
                                    this.toggleCreateUpdateAddressModal()
                                  }
                                >
                                  {content.address}
                                </span>
                              </span>
                            </div>
                          ) : (
                            <span className=" pl-2">
                              {`  `}
                              <span
                                className="title_active add-new-address pull-right"
                                onClick={() => {
                                  this.clearAddress();
                                  this.toggleCreateUpdateAddressModal();
                                }}
                              >
                                {` + ${content.add_new_address}`}
                              </span>
                            </span>
                          )}
                        </Label>
                      )}

                      <Select
                        className="custome-input-text address-cont"
                        styles={this.customStyles}
                        options={this.state.addresses}
                        id="address_id"
                        name="address_id"
                        onChange={(e) => {
                          setFieldValue("address_id", e.value);
                          this.setState({ shippingAddress: e.value });
                        }}
                        value={
                          caseId !== ""
                            ? {
                                value: "000",
                                label: ` ${GetCountryLabel(address.country)}, ${
                                  address.city
                                }, ${address.state}, ${address.line1}, ${
                                  address.line2
                                }, ${address.zip}, ${address.phone}`,
                              }
                            : this.state.addresses.filter(
                                (v) => v.id === values.address_id
                              )
                        }
                        isDisabled={caseId !== ""}
                        theme={(theme) => {
                          return {
                            ...theme,
                            margin: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#00C7B110",
                              primary: "#C8C8CA50",
                              primary50: "#00C7B130",
                            },
                          };
                        }}
                        getOptionLabel={this.addressOptionLabel}
                      />
                      <ErrorMessage
                        component="div"
                        name="address_id"
                        className="custom-invalid-feedback mt-1 callout-text"
                      />
                      {this.checkIfAddressIsFull(values.address_id) && (
                        <div className="colorful-grig">
                          <div className="colorful-validation">
                            This Address is incomplete
                          </div>
                          <div
                            className="edit-address colorful-update body-text"
                            onClick={() => {
                              let currentAddress = this.state.addresses.filter(
                                (v) => v.id === values.address_id
                              );
                              this.setState({
                                createUpdateAddressModal:
                                  !this.state.createUpdateAddressModal,
                                obj: currentAddress,
                                id: currentAddress[0].id,
                                line1: currentAddress[0].line1,
                                line2: currentAddress[0].line2,
                                zip: currentAddress[0].zip,
                                phone: currentAddress[0].phone,
                                state: currentAddress[0].state,
                                country: GetCountryValue(
                                  currentAddress[0].country
                                ),
                                city: currentAddress[0].city,
                                clinic_name: currentAddress[0].clinic_name,
                              });
                            }}
                          >
                            <spam>{content.update}</spam>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </div>

                {window.location.pathname === "/case-submission" ||
                window.location.pathname === "/case-submission/" ? (
                  <div>
                    <FormGroup className="mt-3">
                      <Label className="form-title header_grid_note ">
                        <div className="colorful callout-text">
                          {content.package_type}
                        </div>
                      </Label>
                      <CustomRadioGroup
                        name="case_type"
                        checked={values.case_type}
                        onChange={(value) => {
                          setFieldValue("case_type", value);
                        }}
                        options={case_typs_new}
                        isToolTip={true}
                      />
                      <ErrorMessage
                        component="div"
                        name="case_type"
                        className="custom-invalid-feedback mt-1 callout-text"
                      />

                      <AssessmentTool
                        content={this.props.languageReducer.content}
                      />
                    </FormGroup>
                  </div>
                ) : (
                  <div className=""></div>
                )}
                {window.innerWidth <= 839 && !block_case_submission && (
                  <div>
                    <CardFooter className="p-3 container-footer-mobile trem-grid ">
                      <div className="order-save-button">
                        <Button
                          className={`btn light-green-btn float-right  submission-btn  button-seme-bold-text`}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {content.save}{" "}
                          <i className="fas fa-angle-right pl-1"></i>
                        </Button>{" "}
                      </div>
                      {!window.location.search && (
                        <Button
                          className={`btn white-btn float-left cancel-submission-btn button-seme-bold-text`}
                          size="sm"
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => this.toggleCancelConfirmation()}
                        >
                          <i className="fas fa-angle-left pr-1"></i>{" "}
                          {content.cancel_submission}
                        </Button>
                      )}
                    </CardFooter>
                  </div>
                )}
              </div>
              {window.innerWidth > 839 && !block_case_submission && (
                <CardFooter className="p-3 container-footer-mobile trem-grid ">
                  {!window.location.search && (
                    <Button
                      className={`btn white-btn float-left cancel-submission-btn button-seme-bold-text`}
                      size="sm"
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => this.toggleCancelConfirmation()}
                    >
                      <i className="fas fa-angle-left pr-1"></i>{" "}
                      {content.cancel_submission}
                    </Button>
                  )}

                  <div className="order-save-button ">
                    <Button
                      className={`btn light-green-btn float-right  submission-btn button-seme-bold-text`}
                      size="sm"
                      type="submit"
                      disabled={this.checkIfAddressIsFull(values.address_id)}
                    >
                      {content.save} <i className="fas fa-angle-right pl-1"></i>
                    </Button>{" "}
                  </div>
                </CardFooter>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
