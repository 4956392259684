import moment from "moment";
import React from "react";
import { getTretmentSetupById } from "../../api/api";

function calculateTimeLeft(setupDetailsId, days, date_format) {
  var date = new Date(setupDetailsId);

  date.setDate(date.getDate() + days);

  let increases = moment(new Date(date)).format("D MMM YYYY");

  const difference = new Date(increases) - new Date();


  let timeLeft = {};
  if (difference > -1) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    };
  }

  return timeLeft;
}

export default function TSApprovalPopup({
  days,
  date_format,
  content,
  setupDetailsId,
}) {
  const [timeLeft, setTimeLeft] = React.useState(
    calculateTimeLeft(setupDetailsId, days, date_format)
  );


  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(setupDetailsId, days, date_format));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });



  const timerComponents = Object.keys(timeLeft).map((interval, index) => {
    if (!timeLeft[interval] || timeLeft[interval] === 0) {
      return <></>;
    }
    return (
      <span key={index}>
      
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  if (timerComponents.length <= 0) {
    return <></>;
  }

  return (
    <>

{
 ( timeLeft.days <= 30 && timeLeft.days >0) &&
 
          <span>
         
            <div className="treatment-set-up-pop-up">
              <div className="attention-title">
                {content.attention_Pending_T_S_approval}
              </div>
              <div className="attention-text">
                {
                  content.Dear_valued_doctor_please_be_aware_that_if_you_dont_approve_the_case_in
                }{" "}
                <span>{timerComponents}</span>{" "}
                {
                  content.it_will_automatically_expire_cancellation_charges_apply_for_expired_cases_submitted_after_October
                }
              </div>
            </div>
          </span>
}
        
    </>
  );
}
