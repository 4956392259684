import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Image, Link } from "@react-pdf/renderer";
import { findClosestVal } from "./findCosestVal";
const styles = StyleSheet.create({
  row: {
    //  flexDirection: "row",
    //paddingVertical: "10px",
  },
  text: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    borderColor: "lightgray",
    borderBottomWidth: 1,
    paddingBottom: "5px",
    marginBottom: "5px",
  },
  value: {
    padding: "10px",
  },

  col: {
    flexDirection: "column",
    width: "32%",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "15px",
    marginTop: "10px",
  },
  step_amount: {
    flexDirection: "row",
    paddingVertical: "5px",
    borderBottomWidth: 1,
    borderColor: "lightgray",
  },
  table: {
    borderLeftWidth: 1,
    // borderRightWidth: 1,
    borderColor: "lightgray",
  },
  values_row: {
    paddingLeft: "5px",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: "5px",
  },
  values: {
    fontSize: 10,
  },
  disclaimer: {
    position: "absolute",
    bottom: "0",
  },
  important: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    textAlign: "left",
    color: "black",
    paddingBottom: 5,
    paddingTop: 10,
    fontWeight: "bold",
  },
  important_notes: {
    fontSize: 8,
  },
});

export default class StepAmount extends Component {
  render() {
    const { content } = this.props;
    const { upper_teeth, lower_teeth } = this.props.teeth;
    const dental_notation = "universal";
    const lower = Object.keys(lower_teeth).filter((value, index) => {
      return lower_teeth[value].ipr_steps_amounts.length > 1;
    });
    const upper = Object.keys(upper_teeth).filter((value, index) => {
      return upper_teeth[value].ipr_steps_amounts.length > 1;
    });

    return (
      <Fragment>
        <View style={styles.row}>
          {upper.length > 0 && (
            <View style={styles.container}>
              {upper.map((value, index) => (
                <View style={styles.col} key={index}>
                  <Text style={styles.text}>
                    {`${"IPR"} ${"between"} ${
                      upper_teeth[value]?.ToothSystemName?.[dental_notation]
                    }${`and`}${findClosestVal(
                      this.props.teeth.all,
                      upper_teeth[value]?.ToothSystemName?.[dental_notation]
                    )}    `}
                  </Text>
                  <View style={styles.table}>
                    {upper_teeth[value].ipr_steps_amounts.map(
                      (value, index) => (
                        <View style={styles.step_amount} key={index}>
                          <View>
                            <View style={styles.values_row}>
                              <Text style={styles.values}>
                                IPR before step :{" "}
                              </Text>
                              <Text style={styles.values}>{value.step}</Text>
                            </View>
                            <View style={styles.values_row}>
                              <Text style={styles.values}>IPR amount : </Text>
                              <Text style={styles.values}>{value.amount}</Text>
                            </View>
                          </View>
                        </View>
                      )
                    )}
                  </View>
                </View>
              ))}
            </View>
          )}

          {lower.length > 0 && (
            <View style={styles.container}>
              {lower.map((value, index) => (
                <View style={styles.col} key={index}>
                  <Text style={styles.text}>
                    {/* {`${"IPR"} ${"between"} ${lower_teeth[value].tooth_key_sys[
                      dental_notation
                    ].replace("/", ` ${"and"} `)}`} */}

                    {`${"IPR"} ${"between"} ${
                      lower_teeth[value]?.ToothSystemName?.[dental_notation]
                    }${`and`}${findClosestVal(
                      this.props.teeth.all,
                      lower_teeth[value]?.ToothSystemName?.[dental_notation]
                    )}    `}
                  </Text>
                  <View style={styles.table}>
                    {lower_teeth[value].ipr_steps_amounts.map(
                      (value, index) => (
                        <View style={styles.step_amount} key={index}>
                          <View>
                            <View style={styles.values_row}>
                              <Text style={styles.values}>
                                IPR before step :{" "}
                              </Text>
                              <Text style={styles.values}>{value.step}</Text>
                            </View>
                            <View style={styles.values_row}>
                              <Text style={styles.values}>IPR amount : </Text>
                              <Text style={styles.values}>{value.amount}</Text>
                            </View>
                          </View>
                        </View>
                      )
                    )}
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </Fragment>
    );
  }
}
