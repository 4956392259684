// import { authenticationService } from "../_services";

// import { errorToaster } from "../../services/toast";
// import { authenticationService } from "../_services";

export function authHeader() {
  // return authorization header with jwt token

  const token = localStorage.getItem("token");
  // let x = new Date();
  // let date = localStorage.getItem("expire_time");
  // let newDate = x.setMinutes(x.getMinutes() + 5);
  // if (new Date(newDate) >= new Date(date)) {
  //   errorToaster("Sorry! Your token has expired");
  //   authenticationService.logout();
  // } else {
  if (token) {
    return {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json ; charset=UTF-8",
    };
  } else {
    return {};
  }
  // }
}
