import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
export default class Settings extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {};
  }
  /* --------------------------------------------------------------------------------*/
  /* Update Doctor Info  */
  /* --------------------------------------------------------------------------------*/
  UpdateDoctorInfo = (event) => {
    event.preventDefault();
    const data = {
      doctor: {
        first_name: event.target.first_name.value,
        middle_name: event.target.middle_name.value,
        last_name: event.target.last_name.value,
        phone: event.target.phone.value,
        subscribe: this.props.subscribe,
        avatar: this.props.doctor.avatar,
      },
    };

    this.props.UpdateUser(data);
  };
  render() {
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/* Doctor Form */}
        {/*--------------------------------------------------------------------------------*/}
        <Form onSubmit={this.UpdateDoctorInfo}>
          <FormGroup>
            <Label>First Name</Label>
            <Input
              type="text"
              name="first_name"
              id="first_name"
              // placeholder={this.state.doctor.first_name}
              defaultValue={this.props.doctor.first_name}
            />
          </FormGroup>
          <FormGroup>
            <Label>Middle Name</Label>
            <Input
              type="text"
              name="middle_name"
              id="middle_name"
              // placeholder={this.state.doctor.middle_name}
              defaultValue={this.props.doctor.middle_name}
            />
          </FormGroup>
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              type="text"
              name="last_name"
              id="last_name"
              // placeholder={this.state.doctor.last_name}
              defaultValue={this.props.doctor.last_name}
            />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              readOnly
              // placeholder={this.state.doctor.email}
              defaultValue={this.props.doctor.email}
            />
          </FormGroup>
          <FormGroup>
            <Label>Phone No</Label>
            <Input
              type="text"
              name="phone"
              id="phone"
              // placeholder={this.state.doctor.phone}
              defaultValue={this.props.doctor.phone}
            />
          </FormGroup>
          <FormGroup>
            <input
              type="checkbox"
              name="subscribe"
              id="subscribe"
              checked={this.props.subscribe}
              onChange={this.props.toggleSubscribe}
            />
            <label className="pl-2" htmlFor={`subscribe`}>
              Subscribe
            </label>
          </FormGroup>
          <Button
            className={`btn green-btn-active text-center pull-right `}
            disabled={this.props.sendingData}
          >
            Update Profile
          </Button>
        </Form>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Doctor Form */}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
