import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Image, Link } from "@react-pdf/renderer";
import eon_aligner from "../../assets/images/eon-aligner.png";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "gray",
    paddingBottom: 5,
    alignItems: "center"
  },
  image: {
    width: "60px",
    height: "auto"
  },
  text: {
    fontSize: 14,
    textAlign: "center",
    width: "60%",
    color: "#00c7b1",
  },

  link: {
    fontSize: 11,
    textAlign: "right",
    width: "25%",
    color: "gray",
    textDecoration: "none",
  },
  inner_row: {
    width:"100%",
    alignContent:"center",
    paddingBottom: "5px",
    // display: "flex",
    // flexDirection: "row"
  },
  col: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "1%"
  },
  name: {
    flexDirection: "row",
    marginTop: "10px",
    alignItems: "center",
    justifyContent:"center",
    width:"100%"
  },
  nameValue: {
    flexDirection: "column",
    //position: "absolute",
    fontSize: 10,
    // right: "200px",
    // top: "-15px",
    color: "gray",
    textAlign: "left",
    paddingRight: "10px"
  },
  nameKey: {
    flexDirection: "column",

    //position: "absolute",
    fontSize: 10,
    // right: "200px",
    // top: "-15px",
    color: "#00c7b1",
    textAlign: "left",
    paddingleft: "10px"
  },
});
export default class PDFHeader extends Component {
  render() {
    const { patient, case_data, doctor } = this.props
    return (
      <Fragment>
      <View style={styles.row} fixed>
        <Image style={styles.image} src={`${eon_aligner}`}></Image>
        <Text style={styles.text}>Interproximal Reduction (IPR) Indicator</Text>
      </View>
      <View style={styles.row} fixed>
        <View style={styles.inner_row}>
          <View style={styles.col} >
            <View style={styles.name}>
              <Text style={styles.nameKey}>Doctor Name : </Text>
              <Text style={styles.nameValue}>{doctor.first_name || ""} {doctor.last_name || ""}</Text>
              <Text style={styles.nameKey}>Patient Name : </Text>
              <Text style={styles.nameValue}>{patient.first_name || ""} {patient.last_name || ""}</Text>
              <Text style={styles.nameKey}>Case Number:</Text>
              <Text style={styles.nameValue}>{case_data.uid || ""}</Text>
            </View>
          </View>
        </View>
      </View>
    </Fragment>
    );
  }
}
