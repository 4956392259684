import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { ReactComponent as PDFIcon } from "../../assets/images/icon/pdf-link.svg";
import { ReactComponent as ConsentForm } from "../../assets/images/icon/consent-patient-form.svg";
import { ReactComponent as MediaConsentForm } from "../../assets/images/icon/media_consent_form.svg";

export const Resources = (props) => {
  const resources = props.userReducer?.currentUser?.country_resource_documents;
  const { content } = props.languageReducer;

  return (
    <div className="resources_grid">
      {resources?.map((elem, index) => (
        <div key={index}>
          <div onClick={() => window.open(elem.url, "_blank")}>
            {elem.name ===
            "220610-Patient Information and Consent Form (bilingual)" ? (
              <>
                <ConsentForm className="resource" />
                <div className="title">{content.patient_consent_form}</div>
              </>
            ) : elem.name === "Media Consent Form" ? (
              <>
                <MediaConsentForm className="resource" />
                <div className="title">{content.media_consent_form}</div>
              </>
            ) : (
              <>
                <PDFIcon className="resource" />
                <div className="title">{content.terms_and_condition2}</div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
