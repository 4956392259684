import { TermsAR } from "./terms_ar";
import { TermsEN } from "./terms_en";

export const getTerms = (language, country) => {
  if (language === "ar") {
    return TermsAR(country);
  } else {
    return TermsEN(country);
  }
};
