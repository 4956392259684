import React, { useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as BackIcon } from "../../../assets/images/Ts-navBar/arrow-left.svg";
import { ReactComponent as Logo } from "../../../assets/images/Ts-navBar/Logo.svg";
import { ReactComponent as Inbox } from "../../../assets/images/Ts-navBar/inbox.svg";
import { ReactComponent as Menu } from "../../../assets/images/Ts-navBar/menu.svg";
import { ts_status } from "../../../helpers/caseStatus";
import {
  Collapse,
  Button,
  Row,
  TabContent,
  TabPane,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import info_sign from "../../../assets/images/Ts-navBar/info_sign.svg";
import arrow_right from "../../../assets/images/Ts-navBar/arrow_right.svg";

import ShareTs from "./ShareTs";

export const Header = (props) => {
  const { case_type, treatment_goals, is_archived, uid, upgrades } =
    props.caseReducer.case;

  const { block_case_submission } = props.userReducer.currentUser;

  const { content } = props.languageReducer;
  const {
    details,
    width,
    showMenu,
    showInbox,
    toggle,
    url,
    toggleApprove,
    toggleRevise,
    hideActions,
    showActions,
  } = props;

  const [shareTS, setShareTS] = useState(false);
  const [TsPopup, setTsPopup] = useState(false);
  const ArchMapper = {
    upper_only: "Upper",
    lower_only: "Lower",
    both: "Both",
  };

  const HandleTSPopup = () => {
    setTsPopup(!TsPopup);
  };

  const toggleMenu = () => {
    props.toggleMenu();
    if (shareTS) {
      setShareTS(false);
    }
  };
  const toggleShare = () => {
    setShareTS(!shareTS);
  };

  const toggleInbox = () => {
    props.toggleInbox();
    if (shareTS) {
      setShareTS(false);
    }
  };
  const approveTS = () => {
    setTsPopup(false);
    toggleApprove();
    if (shareTS) {
      setShareTS(false);
    }
  };

  const reviseTS = () => {
    toggleRevise();
    showActions();
    setTsPopup(false);
    if (shareTS) {
      setShareTS(false);
    }
  };

  return (
    <>
      <div className="tp_header">
        <div className="tp_header_grid">
          <div className="logo_grid">
            <BackIcon className="cursor-pointer" alt="back" onClick={toggle} />
            {width > 839 && <Logo className="cpl2" />}
          </div>
          {width > 839 && (
            <div className="text-center">
              <div className="tp-details">
                <div>
                  {" "}
                  {`${details.patient.first_name} ${details.patient.last_name} / ${details.front_title}- #${uid} - ${case_type} `}
                </div>
                {(upgrades?.case_type_upgraded ||
                  upgrades?.treat_arches_upgraded) &&
                  details.title !== "Adjustment" && (
                    <div className="upgraded-container">
                      {" "}
                      <span className="upgraded-text">upgraded</span>
                    </div>
                  )}
              </div>
          

              <div>
                <div className="tp_case_status">
                  <div className="status_button ts-dot-grid width-auto margin-auto">
                    <span
                      className="ts-dot"
                      style={{
                        backgroundColor:
                          ts_status[details?.state]?.color || "#898A8D",
                      }}
                    ></span>{" "}
                    <span className="text-left">
                      {" "}
                      {ts_status[details?.state]?.lable || details.state}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="tp_actions">
            {details?.can_approve_or_disapprove &&
              !is_archived &&
              !hideActions &&
              !block_case_submission && (
                <div className="tp_actions_inner">
                  <Button
                    className={`btn  white-gray-btn   button-seme-bold-text`}
                    size="sm"
                    type="button"
                    onClick={reviseTS}
                  >
                    {content.revise}
                  </Button>
                  <Button
                    className={`btn green-green-btn button-seme-bold-text `}
                    size="sm"
                    type="button"
                    onClick={
                      (upgrades?.case_type_upgraded ||
                        upgrades?.treat_arches_upgraded) &&
                      details.title !== "Adjustment"
                        ? HandleTSPopup
                        : approveTS
                    }
                  >
                    {content.approve}
                  </Button>
                </div>
              )}
            {width > 839 && (
              <div className="tp_actions_inner">
                <Inbox
                  className={`${
                    showInbox ? "active_header_tab" : "not_active_header_tab"
                  }  cursor-pointer`}
                  alt="inbox"
                  onClick={toggleInbox}
                />
                <ShareTs
                  url={url}
                  toggleShare={toggleShare}
                  shareTS={shareTS}
                />
                <Menu
                  className={`${
                    showMenu ? "active_header_tab" : "not_active_header_tab"
                  }  cursor-pointer`}
                  alt="menu"
                  onClick={toggleMenu}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={TsPopup}
        toggle={HandleTSPopup}
        className={
          window.innerWidth > 839
            ? "c-modal-approve-ts"
            : "confarim_preferences_modal"
        }
      >
        <ModalHeader toggle={HandleTSPopup}>
          <div className="case_package_changing_popup">
            <div>
              <img src={info_sign}></img>
            </div>
            <div className="case_package_changing_popup_title">
              {content.case_package_has_been_changed}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="case_package_changing_popup_body">
            {
              content.please_note_that_your_package_selection_has_been_changed_to
            }
          </div>
          <div className="case_package_changing_popup_body    padding-case-package">
            {content.do_you_agree_with_this_change}
          </div>
          {/* // arch // */}
          {upgrades?.treat_arches_upgraded && (
            <div className="upgrade-steps">
              <div className="first-upgrade-steps">
                {ArchMapper[upgrades?.last_treat_arches]}
              </div>
              <div className="image-step">
                <img src={arrow_right}></img>
              </div>
              <div className="sec-upgrade-steps">
                {
                  ArchMapper[
                    props.caseReducer.case?.treatmentPlan?.treatment_goals
                      ?.treat_arches
                  ]
                }
              </div>
            </div>
          )}
          {/* //case Type// */}
          {upgrades?.case_type_upgraded && (
            <div className="upgrade-steps">
              <div className="first-upgrade-steps">
                {upgrades?.last_case_type}
              </div>
              <div className="image-step">
                <img src={arrow_right}></img>
              </div>
              <div className="sec-upgrade-steps">{case_type}</div>
            </div>
          )}
          <div
            className={
              window.innerWidth > 839 ? "cmt3 " : " grid-approve-mobile cmy3 "
            }
          >
            <Button
              className="btn light-green-btn-ts pull-right addres-delete-margin   "
              size="md"
              onClick={approveTS}
            >
              {content.approve}
            </Button>

            <Button
              className={`btn white-gray-btn button-bold-text   case_package_changing_popup_button_floating`}
              size="sm"
              type="button"
              onClick={reviseTS}
            >
              {content.revise}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
