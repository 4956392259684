import { AVAILABLE_MODULES, ACTIVE_MODULES, ALL_MODULES } from "../constants";

export const availableModules = (payload) => {
  return {
    type: AVAILABLE_MODULES,
    payload,
  };
};
export const activateModules = (payload) => {
  return {
    type: ACTIVE_MODULES,
    payload,
  };
};

export const allModules = (payload) => {
  return {
    type: ALL_MODULES,
    payload,
  };
};
