import React, { Component } from "react";
import { connect } from "react-redux";
import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
import {
  Clear_Teeth_Data
} from "../../../../../redux/customeTeeth/action"


import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload))

});
class ClassII extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.use_elastics =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.useElasticWhenCorrecting || false;

    this.extractionTwoUpperBicuspids =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.extractionTwoUpperBicuspids || false;

    this.extractionUpperLowerBicuspids =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.extractionUpperLowerBicuspids || false;

    this.upperArchDistalization =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.upperArchDistalization || false;

    this.lowerArchMesialization =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.lowerArchMesialization || false;

    this.leavingMolars =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.leavingMolars || false;

    this.maintain =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.maintain || false;
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      useElasticWhenCorrecting: this.use_elastics,
      teeth: this.props.customeTeethReducer.Case_ClassII,
      extractionTwoUpperBicuspids: this.extractionTwoUpperBicuspids,
      extractionUpperLowerBicuspids: this.extractionUpperLowerBicuspids,
      upperArchDistalization: this.upperArchDistalization,
      lowerArchMesialization: this.lowerArchMesialization,
      leavingMolars: this.leavingMolars,
      maintain: this.maintain,
    };

    this.props.save("classII", data);
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.use_elastics = value
  }
  render() {
    const {
      extractionTwoUpperBicuspids,
      extractionUpperLowerBicuspids,
      upperArchDistalization,
      lowerArchMesialization,
      leavingMolars,
      maintain,
    } = this;
    const { content } = this.props.languageReducer
    return (
      <div className="">
         <CustomeHeader title={content.classII} optional={false} largeFont={false}/>
  
 
        <div className="buttom-paddingFORMalc  padding-custom-checkbox ">
          <div className="control-label title_active-gco title_tertment_option title-3-text">
            {content.treatment_options}{" "}
          </div>
          {/* 1 */}
          <CustomInput 
            className={`pt-1 ${extractionTwoUpperBicuspids && "replacment-checkbox "
              } button-text-select`}
            type="checkbox"
            id="extractionTwoUpperBicuspids"
            label={content.extraction_of_two_upper_bicuspids}
      
            checked={extractionTwoUpperBicuspids}
            disabled={
              maintain ||
              leavingMolars ||
              upperArchDistalization ||
              extractionUpperLowerBicuspids || this.props.summary
            }
            onChange={() => {
              this.extractionTwoUpperBicuspids = !this
                .extractionTwoUpperBicuspids;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 2 */}

          <CustomInput
            className={`pt-7 ${extractionUpperLowerBicuspids && "replacment-checkbox pt-2 "
              } button-text-select`}

            type="checkbox"
            id="extractionUpperLowerBicuspids"
            label={content.extraction_of_upper_and_lower_bicuspids}
         
            checked={extractionUpperLowerBicuspids}
            disabled={
              maintain ||
              leavingMolars ||
              upperArchDistalization ||
              extractionTwoUpperBicuspids || this.props.summary
            }
            onChange={() => {
              this.extractionUpperLowerBicuspids = !this
                .extractionUpperLowerBicuspids;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 3 */}

          <CustomInput
            className={`pt-1 ${upperArchDistalization && "replacment-checkbox "
              } button-text-select`}

            type="checkbox"
            id="upperArchDistalization"
            label={content.upper_arch_distalization}
        
            checked={upperArchDistalization}
            disabled={
              maintain ||
              leavingMolars ||
              extractionUpperLowerBicuspids ||
              extractionTwoUpperBicuspids || this.props.summary
            }
            onChange={() => {
              this.upperArchDistalization = !this.upperArchDistalization;
              this.UpdateCrossBiteReducer();
            }}
          />
          <div className="checkbox-hint pl-4 light-green text_malocclusions button-text">
            {content.sequential_distalization_may_increase_number_of_steps_elastics_using_May_necessitate_extraction_of_wisdoms_if_present}
          </div>
          {/* 4 */}

          <CustomInput
            className={`pt-1 ${lowerArchMesialization && "replacment-checkbox " 
              } button-text-select`}

            type="checkbox"
            id="lowerArchMesialization"
      
            label={content.lower_arch_mesialization}
            disabled={leavingMolars || maintain || this.props.summary}
            checked={lowerArchMesialization}
            onChange={() => {
              this.lowerArchMesialization = !this.lowerArchMesialization;
              this.UpdateCrossBiteReducer();
            }}
          />
          <div className="checkbox-hint pl-4 light-green text_malocclusions button-text">
           {content.sequential_mesialization_may_increase_number_of_steps_elastics_using}
          </div>
          {/* 5 */}

          <CustomInput

            className={`pt-1 ${leavingMolars && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="leavingMolars"
            label={content.leaving_the_molars_in_classII_and_doing_posterior_reproximation}
            checked={leavingMolars}
          
            disabled={
              maintain ||
              lowerArchMesialization ||
              upperArchDistalization ||
              extractionUpperLowerBicuspids ||
              extractionTwoUpperBicuspids || this.props.summary
            }
            onChange={() => {
              this.leavingMolars = !this.leavingMolars;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 6 */}

          <CustomInput

            className={`pt-1 ${maintain && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="maintain"
            label={content.maintain_classII_with_esthetic_anterior_alignment_only}
           
            checked={maintain}
            disabled={
              leavingMolars ||
              lowerArchMesialization ||
              upperArchDistalization ||
              extractionUpperLowerBicuspids ||
              extractionTwoUpperBicuspids || this.props.summary
            }
            onChange={() => {
              this.maintain = !this.maintain;
              this.UpdateCrossBiteReducer();
            }}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassII);
