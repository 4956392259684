import React, { Component, Fragment } from "react";
import { Page, Document, StyleSheet, View, Text } from "@react-pdf/renderer";
import IPRTeethPDF from "./IPRTeethPDF"
import PDFHeader from "./PDFHeader";
import Footer from "./Footer";
import Notes from "./Notes";
import StepAmount from "./StepAmount";
import Legends from "./Legends";
// Create styles
const borderColor = "#90e5fc";

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 70,
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 20,
    marginTop: 5,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    marginBottom: 20,
    width: "10%",
    height: "10%",
    backgroundColor: "red",
    borderRadius: 50,
  },
  header: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "35px",
    marginBottom: "35px",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    padding: 5,
  },
  value: {
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
    fontWeight: "normal",
    padding: 5,
  },

  box: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },

  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"

  },
  description: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: "25%",
  },

  row1: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description1: {
    width: "25%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty1: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  rate1: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  amount1: {
    width: "25%",
    textAlign: "center",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
    fontSize: 12,
    textAlign: "center",
  },
  testRow1: {
    height: 100,
    backgroundColor: "red",
    position: "relative"
  },
  testRow2: {
    height: 100,
    backgroundColor: "balck"
    , position: "relative"

  },
  viewConainer: {
    position: "absolute",
    bottom: 100
  },
  divider: {
    borderRightColor: "#00c7b1",
    borderRightWidth: 1,
    minHeight: "100px",
    alignSelf: "flex-start",
    padding: 1
  },

  name: {
    flexDirection: "row",
    width: "100%",
    marginLeft: "150px",
    marginTop: "15px",
    alignItems: "center",
  },
  nameValue: {
    flexDirection: "column",
    //position: "absolute",
    fontSize: 10,
    // right: "200px",
    // top: "-15px",
    color: "gray",
    textAlign: "left",
    paddingLeft: "5px"

  },
  nameKey: {
    flexDirection: "column",

    //position: "absolute",
    fontSize: 10,
    // right: "200px",
    // top: "-15px",
    color: "#00c7b1",
    textAlign: "left",
  },
});

// Create Document Component
export default class MyDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { content, data, doctor, patient, case_data, notes } = this.props;
    return (
      <Document>
        <Page style={styles.body}>
          <PDFHeader content={content} patient={patient} case_data={case_data} doctor={doctor} />
          <View style={styles.row}>
            <IPRTeethPDF content={content} teeth={data} patient={patient} case_data={case_data} />
            <Legends content={content} />
          </View>
          <Notes content={content} notes={notes} />
          <StepAmount content={content} teeth={data} />
          <Footer content={content} />
        </Page>
      </Document>
    );
  }
}
