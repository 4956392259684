import React, { useMemo } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

import MyDocument from "./MyDocument";
import { Button } from "reactstrap";

const DownloadIPRPdf = (props) => {
  const content = props.content;
  return useMemo(
    () => (
      <PDFDownloadLink
        document={
          <MyDocument
            content={content}
            data={props.data}
            doctor={props.doctor}
            patient={props.patient}
            case_data={props.case_data}
            notes={props.notes}
          />
        }
        fileName={`${content.ipr_indicator}_${props.uid}.pdf`}
      >
        {({ blob, url, loading, error }) => {
          return (
            <Button
              className="btn green-green-btn button-bold-text"
              size="sm"
              disabled={loading}
            >
              {content.ipr_file}
            </Button>
          );
        }}
      </PDFDownloadLink>
    ),
    []
  );
};

export default DownloadIPRPdf;
