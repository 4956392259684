import React, { Component } from "react";
import { connect } from "react-redux";

import { Button } from "reactstrap";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";

import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import CustomeHeader from "../../../../../components/header/CustomeHeader";
import CustomToggle from "../../../../../components/toggle/CustomeToggle";
import { content } from "../../../../../redux/language/content";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Pontics extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_pontics: this.props.caseSubmission.advanced_modules.pontics
        .usePontics,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "pontics",
      data: {
        usePontics: this.state.use_pontics,
        teeth: this.props.customeTeethReducer.Case_Pontics,
      },
    });
    this.props.save();
  };


  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_pontics } = this.state;
    const { content } = this.props.languageReducer

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">
         
      
<div className="customHeaderTS ">
            <div>  <CustomeHeader title={content.pontics} className="cp3 pt-0  "  optional={false} note={false} note_text={content.pontics_note} /></div>
            <div className="reative-close-ts">  <span className={` pull-right ts-close-button-new`} onClick={() => { this.props.toggleModalsClose(this.props.active_module) }}>&times;</span></div>
</div>

          <div className="admin-Grid-Advance">
            <div></div>
            {/* part 1 */}
            <div className=" admin-Grid-Advance-inner D-head-ts">
              <div >

                {content.Used_pontics_for_this_case}{" "}

              </div>
              {/* part 2 */}
              <div className="D-total-cont-toggle">
              <div className="pointer_disable">
                <CustomToggle

                  id="use-pontics"
                  disabled={this.props.summary}

                  checked={use_pontics}
                  onChange={() => {
                    this.setState({ use_pontics: !use_pontics });
                    if (use_pontics) {
                      this.props.Clear_Teeth_Data("Case_Pontics")
                    }
                  }}
                />
              </div>
              </div>
            </div>

            <div></div>
          </div>



   

       

    
    
            {" "}
            <div className="">
            <div className="control-label title_active-gcoTS-new  D-head-ts text-center pb-2 ">
                {content.placement}
              </div>
              <div className="teeth-selector-ts">
              <div className="teeth_selector  m-auto">
                <CustomeTeeth
                  action_key="Case_Pontics"
                  advanced_Ts="true"
                  disabled={!use_pontics || use_pontics === "eonToDecide"}
                  // summary={this.props.summary}
                  summary="true"
                />
                </div>
              </div>
            </div>


    
    
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
          </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pontics);
