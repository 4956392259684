import { updateAlignerCase } from "../api/api";
import { mapTeethToApi } from "./mapTeethToApi";

export async function expressStep(
  doctorId,
  clinical_findings_store,
  summary_and_special_instructions_store,
  caseId,
  rejectable_id
) {
  let data2 = {
    aligner_case: {
      step: "summary_and_special_instructions",
      treatment_summary:
        summary_and_special_instructions_store.treatment_summary,
      special_instructions: "",
    },
    ...(rejectable_id && { resolve_rejection_id: rejectable_id }),
  };

  let data = {
    aligner_case: {
      step: "clinical_findings",
      clinical_findings: {
        chief_complaint: clinical_findings_store.chief_complaint,
        upper_midline_displacement:
          clinical_findings_store.upper_midline_displacement,
        lower_midline_displacement:
          clinical_findings_store.lower_midline_displacement,
        skeletal: clinical_findings_store.skeletal,
        canine: {
          class1: {
            R: clinical_findings_store.canine_r_1.toString(),
            L: clinical_findings_store.canine_l_1.toString(),
          },
          class2: {
            R: clinical_findings_store.canine_r_2.toString(),
            L: clinical_findings_store.canine_l_2.toString(),
          },
          class3: {
            R: clinical_findings_store.canine_r_3.toString(),
            L: clinical_findings_store.canine_l_3.toString(),
          },
        },
        molar: {
          class1: {
            R: clinical_findings_store.molar_r_1.toString(),
            L: clinical_findings_store.molar_l_1.toString(),
          },
          class2: {
            R: clinical_findings_store.molar_r_2.toString(),
            L: clinical_findings_store.molar_l_2.toString(),
          },
          class3: {
            R: clinical_findings_store.molar_r_3.toString(),
            L: clinical_findings_store.molar_l_3.toString(),
          },
        },
        restriction: mapTeethToApi(
          clinical_findings_store.Tooth_Movement_Restrictions
        ),
        no_attachment: mapTeethToApi(
          clinical_findings_store.Do_not_place_attachments
        ),
        upper_midline: clinical_findings_store.upper_midline,
        lower_midline: clinical_findings_store.lower_midline,
      },
    },
    ...(rejectable_id && { resolve_rejection_id: rejectable_id }),
  };
  return await updateAlignerCase(doctorId, caseId, JSON.stringify(data)).then(
    (res) => {
      updateAlignerCase(doctorId, caseId, JSON.stringify(data2)).then((res) => {
        return true;
      });
    }
  );
}
