import { updateAlignerCase } from "../api/api";
import { mapTeethToApi } from "./mapTeethToApi";

export function updateTreatmentGoals(
  doctorId,
  treatment_goals_store,
  caseId,
  rejectable_id
) {
  let data = {
    aligner_case: {
      step: "treatment_goals",
      treatment_goals: {
        treat_arches: treatment_goals_store.treat_arches,
        upper_midline: treatment_goals_store.upper_midline,
        lower_midline: treatment_goals_store.lower_midline,
        overjet: treatment_goals_store.overjet,
        overbite: treatment_goals_store.overbite,
        arch_form: treatment_goals_store.arch_form,
        resolve_crowding: {
          procline: {
            U: treatment_goals_store.procline_upper_primary.toString(),
            L: treatment_goals_store.procline_lower_primary.toString(),
          },
          procline_in: {
            U: treatment_goals_store.procline_upper_if_needed.toString(),
            L: treatment_goals_store.procline_lower_if_needed.toString(),
          },
          expand: {
            U: treatment_goals_store.expand_upper_primary.toString(),
            L: treatment_goals_store.expand_lower_primary.toString(),
          },
          expand_in: {
            U: treatment_goals_store.expand_upper_if_needed.toString(),
            L: treatment_goals_store.expand_lower_if_needed.toString(),
          },
          ipr: {
            U: treatment_goals_store.ipr_upper_primary.toString(),
            L: treatment_goals_store.ipr_lower_primary.toString(),
          },
          ipr_in: {
            U: treatment_goals_store.ipr_upper_if_needed.toString(),
            L: treatment_goals_store.ipr_lower_if_needed.toString(),
          },
        },
        extraction: mapTeethToApi(treatment_goals_store.Extraction),
        crossbites: mapTeethToApi(treatment_goals_store.Crossbites),
        leave_space: mapTeethToApi(treatment_goals_store.LeaveSpace),
        canine: {
          class1: {
            R: treatment_goals_store.canine_r_1.toString(),
            L: treatment_goals_store.canine_l_1.toString(),
          },
          class2: {
            R: treatment_goals_store.canine_r_2.toString(),
            L: treatment_goals_store.canine_l_2.toString(),
          },
          class3: {
            R: treatment_goals_store.canine_r_3.toString(),
            L: treatment_goals_store.canine_l_3.toString(),
          },
        },
        molar: {
          class1: {
            R: treatment_goals_store.molar_r_1.toString(),
            L: treatment_goals_store.molar_l_1.toString(),
          },
          class2: {
            R: treatment_goals_store.molar_r_2.toString(),
            L: treatment_goals_store.molar_l_2.toString(),
          },
          class3: {
            R: treatment_goals_store.molar_r_3.toString(),
            L: treatment_goals_store.molar_l_3.toString(),
          },
        },
        ap_goal: {
          ipr36: {
            U: treatment_goals_store.goal_upper_ipr.toString(),
            L: treatment_goals_store.goal_lower_ipr.toString(),
          },
          distalization: {
            U: treatment_goals_store.goal_upper_distalization.toString(),
            L: treatment_goals_store.goal_lower_distalization.toString(),
          },
          mezialization: {
            U: treatment_goals_store.goal_upper_mezialization.toString(),
            L: treatment_goals_store.goal_lower_mezialization.toString(),
          },
        },
      },
    },
    ...(rejectable_id && { resolve_rejection_id: rejectable_id }),
  };

  return updateAlignerCase(doctorId, caseId, JSON.stringify(data)).then(
    (res) => {
      return "ok";
    }
  );
}

export function validateTreatmentGoals(treatment_goals_store) {
  return (
    treatment_goals_store.treat_arches === "" ||
    treatment_goals_store.upper_midline === "" ||
    treatment_goals_store.lower_midline === "" ||
    treatment_goals_store.overjet === "" ||
    treatment_goals_store.overbite === "" ||
    treatment_goals_store.arch_form === ""
  );
}
