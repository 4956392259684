import { upper_teeth, lower_teeth } from "../redux/newCase/init_teeth";
import { mapTeeth } from "./mapTeeth";
export function mapDataWithReducer(dataFromApi, fix) {
  let clinical_findings = dataFromApi.clinical_findings;
  let treatment_goals = dataFromApi.treatment_goals;
  let media = dataFromApi.media;
  let data = {
    userInfo: {
      caseType: dataFromApi.case_type,
      express_api_v2_case: dataFromApi.express_api_v2_case,
    },
    treatmentPlan: {
      tab1: false,
      tab2: true,
      tab3: true,
      clinical_findings: {
        skeletal_1: clinical_findings
          ? clinical_findings.skeletal === "I"
          : false,
        skeletal_2: clinical_findings
          ? clinical_findings.skeletal === "II"
          : false,
        skeletal_3: clinical_findings
          ? clinical_findings.skeletal === "III"
          : false,
        canine_r_1: clinical_findings
          ? clinical_findings.canine?.class1["R"] === "true"
            ? true
            : false
          : false || false,
        canine_r_2: clinical_findings
          ? clinical_findings.canine?.class2["R"] === "true"
            ? true
            : false
          : false|| false,
        canine_r_3: clinical_findings
          ? clinical_findings.canine?.class3["R"] === "true"
            ? true
            : false
          : false|| false,
        canine_l_1: clinical_findings
          ? clinical_findings.canine?.class1["L"] === "true"
            ? true
            : false
          : false|| false,
        canine_l_2: clinical_findings
          ? clinical_findings.canine?.class2["L"] === "true"
            ? true
            : false
          : false|| false,
        canine_l_3: clinical_findings
          ? clinical_findings.canine?.class3["L"] === "true"
            ? true
            : false
          : false|| false,
        molar_r_1: clinical_findings
          ? clinical_findings.molar?.class1["R"] === "true"
            ? true
            : false
          : false|| false,
        molar_r_2: clinical_findings
          ? clinical_findings.molar?.class2["R"] === "true"
            ? true
            : false
          : false|| false,
        molar_r_3: clinical_findings
          ? clinical_findings.molar?.class3["R"] === "true"
            ? true
            : false
          : false|| false,
        molar_l_1: clinical_findings
          ? clinical_findings.molar?.class1["L"] === "true"
            ? true
            : false
          : false|| false,
        molar_l_2: clinical_findings
          ? clinical_findings.molar?.class2["L"] === "true"
            ? true
            : false
          : false|| false,
        molar_l_3: clinical_findings
          ? clinical_findings.molar?.class3["L"] === "true"
            ? true
            : false
          : false|| false,
        upper_midline_center: clinical_findings
          ? clinical_findings.upper_midline === "centered"
          : false|| false,
        upper_midline_shifted_left: clinical_findings
          ? clinical_findings.upper_midline === "shifted_left"
          : false,
        upper_midline_shifted_right: clinical_findings
          ? clinical_findings.upper_midline === "shifted_right"
          : false,
        lower_midline_center: clinical_findings
          ? clinical_findings.upper_midline === "centered"
          : false,
        lower_midline_shifted_left: clinical_findings
          ? clinical_findings.upper_midline === "shifted_left"
          : false,
        lower_midline_shifted_right: clinical_findings
          ? clinical_findings.upper_midline === "shifted_right"
          : false,
        chief_complaint: clinical_findings
          ? clinical_findings.chief_complaint
          : "",
        upper_midline_displacement: clinical_findings
          ? clinical_findings.upper_midline_displacement
          : 0,
        lower_midline_displacement: clinical_findings
          ? clinical_findings.lower_midline_displacement
          : 0,
        skeletal: clinical_findings ? clinical_findings.skeletal : "",
        upper_midline: dataFromApi.clinical_findings
          ? dataFromApi.clinical_findings.upper_midline
          : "",
        lower_midline: dataFromApi.clinical_findings
          ? dataFromApi.clinical_findings.lower_midline
          : "",
        Tooth_Movement_Restrictions: dataFromApi.clinical_findings
          ? mapTeeth(dataFromApi.clinical_findings.restriction)
          : {
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            },
        Do_not_place_attachments: dataFromApi.clinical_findings
          ? mapTeeth(dataFromApi.clinical_findings.no_attachment)
          : {
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            },
      },
      treatment_goals: {
        treat_arches_upper: treatment_goals
          ? treatment_goals.treat_arches === "upper_only"
          : false,
        treat_arches_lower: treatment_goals
          ? treatment_goals.treat_arches === "lower_only"
          : false,
        treat_arches_both: treatment_goals
          ? treatment_goals.treat_arches === "both"
          : false,
        midline_upper_maintain: treatment_goals
          ? treatment_goals.upper_midline === "maintain"
          : false,
        midline_upper_improve: treatment_goals
          ? treatment_goals.upper_midline === "improve"
          : false,
        midline_upper_center: treatment_goals
          ? treatment_goals.upper_midline === "center"
          : false,
        midline_lower_maintain: treatment_goals
          ? treatment_goals.lower_midline === "maintain"
          : false,
        midline_lower_improve: treatment_goals
          ? treatment_goals.lower_midline === "improve"
          : false,
        midline_lower_center: treatment_goals
          ? treatment_goals.lower_midline === "center"
          : false,
        overjet_maintain: treatment_goals
          ? treatment_goals.overjet === "maintain"
          : false,
        overjet_improve: treatment_goals
          ? treatment_goals.overjet === "improve"
          : false,
        overjet_Ideal: treatment_goals
          ? treatment_goals.overjet === "ideal"
          : false,
        overbite_maintain: treatment_goals
          ? treatment_goals.overbite === "maintain"
          : false,
        overbite_improve: treatment_goals
          ? treatment_goals.overbite === "improve"
          : false,
        overbite_Ideal: treatment_goals
          ? treatment_goals.overbite === "ideal"
          : false,
        arch_form_maintain: treatment_goals
          ? treatment_goals.arch_form === "maintain"
          : false,
        arch_form_improve: treatment_goals
          ? treatment_goals.arch_form === "improve"
          : false,
        arch_form_constrict: treatment_goals
          ? treatment_goals.arch_form === "constrict"
          : false,
        procline_upper_primary: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.procline
              ? treatment_goals.resolve_crowding.procline["U"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        procline_lower_primary: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.procline
              ? treatment_goals.resolve_crowding.procline["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        procline_upper_if_needed: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.procline_in
              ? treatment_goals.resolve_crowding.procline_in["U"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        procline_lower_if_needed: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.procline_in
              ? treatment_goals.resolve_crowding.procline_in["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,

        expand_upper_primary: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.expand
              ? treatment_goals.resolve_crowding.expand["U"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        expand_lower_primary: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.expand
              ? treatment_goals.resolve_crowding.expand["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        expand_upper_if_needed: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.expand_in
              ? treatment_goals.resolve_crowding.expand_in["U"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        expand_lower_if_needed: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.expand_in
              ? treatment_goals.resolve_crowding.expand_in["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,

        ipr_upper_primary: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.ipr
              ? treatment_goals.resolve_crowding.ipr["U"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        ipr_lower_primary: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.ipr
              ? treatment_goals.resolve_crowding.ipr["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        ipr_upper_if_needed: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.ipr_in
              ? treatment_goals.resolve_crowding.ipr_in["U"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        ipr_lower_if_needed: treatment_goals
          ? treatment_goals.resolve_crowding
            ? treatment_goals.resolve_crowding.ipr_in
              ? treatment_goals.resolve_crowding.ipr_in["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,

        canine_r_1: treatment_goals
          ? treatment_goals.canine
            ? treatment_goals.canine.class1
              ? treatment_goals.canine.class1["R"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        canine_r_2: treatment_goals
          ? treatment_goals.canine
            ? treatment_goals.canine.class2
              ? treatment_goals.canine.class2["R"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        canine_r_3: treatment_goals
          ? treatment_goals.canine
            ? treatment_goals.canine.class3
              ? treatment_goals.canine.class3["R"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        canine_l_1: treatment_goals
          ? treatment_goals.canine
            ? treatment_goals.canine.class1
              ? treatment_goals.canine.class1["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        canine_l_2: treatment_goals
          ? treatment_goals.canine
            ? treatment_goals.canine.class2
              ? treatment_goals.canine.class2["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        canine_l_3: treatment_goals
          ? treatment_goals.canine
            ? treatment_goals.canine.class3
              ? treatment_goals.canine.class3["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        molar_r_1: treatment_goals
          ? treatment_goals.molar
            ? treatment_goals.molar.class1
              ? treatment_goals.molar.class1["R"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        molar_r_2: treatment_goals
          ? treatment_goals.molar
            ? treatment_goals.molar.class2
              ? treatment_goals.molar.class2["R"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        molar_r_3: treatment_goals
          ? treatment_goals.molar
            ? treatment_goals.molar.class3
              ? treatment_goals.molar.class3["R"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        molar_l_1: treatment_goals
          ? treatment_goals.molar
            ? treatment_goals.molar.class1
              ? treatment_goals.molar.class1["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        molar_l_2: treatment_goals
          ? treatment_goals.molar
            ? treatment_goals.molar.class2
              ? treatment_goals.molar.class2["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        molar_l_3: treatment_goals
          ? treatment_goals.molar
            ? treatment_goals.molar.class3
              ? treatment_goals.molar.class3["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        goal_upper_ipr: treatment_goals
          ? treatment_goals.ap_goal
            ? treatment_goals.ap_goal.ipr36
              ? treatment_goals.ap_goal.ipr36["U"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        goal_lower_ipr: treatment_goals
          ? treatment_goals.ap_goal
            ? treatment_goals.ap_goal.ipr36
              ? treatment_goals.ap_goal.ipr36["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        goal_upper_distalization: treatment_goals
          ? treatment_goals.ap_goal
            ? treatment_goals.ap_goal.distalization
              ? treatment_goals.ap_goal.distalization["U"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        goal_lower_distalization: treatment_goals
          ? treatment_goals.ap_goal
            ? treatment_goals.ap_goal.distalization
              ? treatment_goals.ap_goal.distalization["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        goal_upper_mezialization: treatment_goals
          ? treatment_goals.ap_goal
            ? treatment_goals.ap_goal.mezialization
              ? treatment_goals.ap_goal.mezialization["U"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        goal_lower_mezialization: treatment_goals
          ? treatment_goals.ap_goal
            ? treatment_goals.ap_goal.mezialization
              ? treatment_goals.ap_goal.mezialization["L"] === "true"
                ? true
                : false
              : false
            : false
          : false,
        treat_arches: treatment_goals ? treatment_goals.treat_arches : "",
        upper_midline: treatment_goals ? treatment_goals.upper_midline : "",
        lower_midline: treatment_goals ? treatment_goals.lower_midline : "",
        midline: {
          U: "",
          L: "",
        },
        overjet: treatment_goals ? treatment_goals.overjet : "",
        overbite: treatment_goals ? treatment_goals.overbite : "",
        arch_form: treatment_goals ? treatment_goals.arch_form : "",
        Extraction: treatment_goals
          ? treatment_goals.extraction
            ? mapTeeth(treatment_goals.extraction)
            : {
                upper_teeth: Object.assign({}, upper_teeth),
                lower_teeth: Object.assign({}, lower_teeth),
              }
          : {
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            },
        Crossbites: treatment_goals
          ? treatment_goals.crossbites
            ? mapTeeth(treatment_goals.crossbites)
            : {
                upper_teeth: Object.assign({}, upper_teeth),
                lower_teeth: Object.assign({}, lower_teeth),
              }
          : {
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            },
        LeaveSpace: treatment_goals
          ? treatment_goals.leave_space
            ? mapTeeth(treatment_goals.leave_space)
            : {
                upper_teeth: Object.assign({}, upper_teeth),
                lower_teeth: Object.assign({}, lower_teeth),
              }
          : {
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            },
      },
      summary_and_special_instructions: {
        treatment_summary: dataFromApi.treatment_summary,
        special_instructions: dataFromApi.special_instructions,
      },
      express: {
        chief_complaint: clinical_findings
          ? clinical_findings.chief_complaint
          : "",
        treatment_summary: dataFromApi.treatment_summary,
      },
    },
    impressions: {
      Digital_Impression: dataFromApi.impressions_method === "upload",
      Digital_Means: dataFromApi.impressions_method === "third_party",
      Arrange_Pickup: dataFromApi.impressions_method === "courier",
      upperImageValue: fix
        ? ""
        : media.impressions.upper_impression?.match(/http([^&]+)/) &&
          dataFromApi.impressions_method === "upload"
        ? media.impressions.upper_impression
        : "",
      lowerImageValue: fix
        ? ""
        : media.impressions.lower_impression?.match(/http([^&]+)/) &&
          dataFromApi.impressions_method === "upload"
        ? media.impressions.lower_impression
        : "",
      additional_comments: dataFromApi.impressions_third_party_note || "",

      Impression_Type: dataFromApi.impressions_method || "",
      upperImage: fix
        ? false
        : media.impressions.upper_impression?.match(/http([^&]+)/) &&
          dataFromApi.impressions_method === "upload"
        ? true
        : false,
      lowerImage: fix
        ? false
        : media.impressions.lower_impression?.match(/http([^&]+)/) &&
          dataFromApi.impressions_method === "upload"
        ? true
        : false,
    },
    photos: {
      front_smiling_photo: media.patient_photos.front_smiling || "",
      front_smiling: media.patient_photos.front_smiling || "",
      front_smiling_image: media.patient_photos.front_smiling ? true : false,
      front_pose_photo: media.patient_photos.front_pose || "",
      front_pose: media.patient_photos.front_pose || "",
      front_pose_image: media.patient_photos.front_pose ? true : false,
      profile_photo: media.patient_photos.profile || "",
      profile: media.patient_photos.profile || "",
      profile_image: media.patient_photos.profile ? true : false,
      upper_occlusal_photo: media.patient_photos.upper_occlusal || "",
      upper_occlusal: media.patient_photos.upper_occlusal || "",
      upper_occlusal_image: media.patient_photos.upper_occlusal ? true : false,
      lower_occlusal_photo: media.patient_photos.lower_occlusal || "",
      lower_occlusal: media.patient_photos.lower_occlusal || "",
      lower_occlusal_image: media.patient_photos.lower_occlusal ? true : false,
      left_buccal_photo: media.patient_photos.left_buccal || "",
      left_buccal: media.patient_photos.left_buccal || "",
      left_buccal_image: media.patient_photos.left_buccal ? true : false,
      frontal_photo: media.patient_photos.frontal || "",
      frontal: media.patient_photos.frontal || "",
      frontal_image: media.patient_photos.frontal ? true : false,
      right_buccal_photo: media.patient_photos.right_buccal || "",
      right_buccal: media.patient_photos.right_buccal || "",
      right_buccal_image: media.patient_photos.right_buccal ? true : false,
    },
    xrays: {
      panoramic_photo: media.xrays.panoramic || "",
      panoramic: media.xrays.panoramic || "",
      panoramic_image: media.xrays.panoramic ? true : false,
      cephalometric_photo: media.xrays.cephalometric || "",
      cephalometric: media.xrays.cephalometric || "",
      cephalometric_image: media.xrays.cephalometric ? true : false,
    },
    submission: {
      acceptTerms: false,
      savedToCloud: false,
      validateTerms: false,
    },
  };
  return data;
}
