import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/style.scss';
import './assets/scss/tailwind.css';

import {Userpilot} from 'userpilot';
// Setup fake backend
// import { configureFakeBackend } from './jwt/_helpers';
// configureFakeBackend();
const App = require('./app').default;


// Initialize Userpilot
Userpilot.initialize('NX-9e5e6bea');

ReactDOM.render( 
	<App /> , document.getElementById('root')
);