import React from "react";

/*
Custom Toggle Component
Note: id, checked ,title and onChange are required for CustomToggle component to function. The props name and disabled
 are optional.
Usage: <CustomToggle id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const CustomToggle = ({
    id,
    name,
    checked,
    onChange,
    title,
    disabled,
}) => {
    return (

        <div className="input-grid">
            <div className="custom-toggle">
                <input type="checkbox"
                    name={name}
                    id={id}
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={disabled}
                />
            </div>
            <div className="custom-options-title">{title}</div>
        </div>

    );
};



export default CustomToggle;
