import { BehaviorSubject } from "rxjs";

import { handleResponse } from "../_helpers";
import { getDoctors, Revokeaccesstoken } from "../../api/api";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  logout,
  getDoctor,
  UpdateCurrentUser,
  token: localStorage.getItem("token"),
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function UpdateCurrentUser(newValues) {
  currentUserSubject.next(newValues);
}

function getDoctor() {
  getDoctors().then((user) => {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    // currentUserSubject.next(user);
    // window.location = "/home";

    return user;
  });
}

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`/users/authenticate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);

      return user;
    });
}

function logout() {
  let token = localStorage.getItem("token");
  // remove user from local storage to log user out
  Revokeaccesstoken(token).then((res) => {
    localStorage.clear();
    window.location = process.env.REACT_APP_LOGOUT_URL;
    // window.location.reload(true);
  });

  //currentUserSubject.next(null);
}
