import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { data } from "./data";
import loyaltyTable from "../../../assets/images/loyalty/newLoyaltyTable.svg";
import { ReactComponent as Group } from "../../../assets/images/loyalty/Group.svg";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});

class LoyaltyTable extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { content } = this.props.languageReducer;
    const {loyalty} =  this.props.userReducer.currentUser


    return (
      <div className='LoyaltyTableContaiane'>
        <div >
        <img  className="LoyaltyTableImage" src={loyaltyTable} alt="loyaltyTable" />
        </div>
        {/* <div className="caption-1-text black LoyaltyTablenote">
           <div> {content.cases_for_each_tier_refer_to_the_number_of_approved_cases_per_year}</div>
           <div>{content.which_Eon_Basic_will_be_counted_as_half_a_case}</div>
        </div> */}

     </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyTable);
