import React, { Component } from "react";
import Inbox from "../inbox";

export default class Comments extends Component {
  render() {
    return (
      <div className="">
        {/*--------------------------------------------------------------------------------*/}
        {/* Inbox Component */}
        {/*--------------------------------------------------------------------------------*/}
        <Inbox
          caseId={this.props.caseId}
          formKey={"tabs"}
        />
      </div>
    );
  }
}
