import React, { Component } from "react";
import { Row, Col } from "reactstrap";

export default class AdjustmentInfo extends Component {
  /*--------------------------------------------------------------------------------*/
  /* constructor   */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state   */
    this.state = props.getStore();
    this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
  }
  /* --------------------------------------------------------------------------------*/
  /* check validation on submit   */
  /* --------------------------------------------------------------------------------*/
  isValidated = () => {
    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator

    let isDataValid = false;
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === true;
      })
    ) {
      if (
        this.props.getStore().current_upper_step !==
          userInput.currentUpperStep ||
        this.props.getStore().current_lower_step !==
          userInput.currentLowerStep ||
        this.props.getStore().tracking_upper_step !==
          userInput.trackingUpperStep ||
        this.props.getStore().tracking_lower_step !==
          userInput.trackingLowerStep ||
        this.props.getStore().reason !== userInput.reasonRef ||
        this.props.getStore().additional_instructions !==
          userInput.additional_instructions
      ) {
        let data = {
          current_upper_step: userInput.currentUpperStep,
          current_lower_step: userInput.currentLowerStep,
          tracking_upper_step: userInput.trackingUpperStep,
          tracking_lower_step: userInput.trackingLowerStep,
          additional_instructions: userInput.additional_instructions,
          reason: userInput.reasonRef,
        };
        this.props.updateStore({
          ...data,
        }); // Update store here (this is just an example, in reality you will do it via redux or flux)
        isDataValid = true;
      } else {
        isDataValid = true;
      }
    } else {
      // if anything fails then update the UI validation state but NOT the UI Data State
      this.setState(
        Object.assign(
          userInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    }

    return isDataValid;
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Check on submit  */
  /* --------------------------------------------------------------------------------*/
  validationCheck = () => {
    if (!this._validateOnDemand) return;

    const userInput = this._grabUserInput(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator

    this.setState(
      Object.assign(
        userInput,
        validateNewInput,
        this._validationErrors(validateNewInput)
      )
    );
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation on blure  */
  /* --------------------------------------------------------------------------------*/
  validationCheck2 = (key) => {
    if (!this._validateOnDemand) return;

    const userInput = this._grabUserInput2(key); // grab user entered vals
    const validateNewInput = this._validateData2(userInput, key); // run the new input against the validator

    this.setState(
      Object.assign(
        userInput,
        validateNewInput,
        this._validationErrors2(validateNewInput, key)
      )
    );
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation for each element on submit   */
  /* --------------------------------------------------------------------------------*/
  _validateData = (data) => {
    return {
      currentUpperStepVal:
        data.currentUpperStep !== "" &&
        this.props.caseType === "Lite Single Arch"
          ? data.currentUpperStep >= 0
          : data.currentUpperStep > 0, // required: anything besides N/A
      currentLowerStepVal:
        data.currentLowerStep !== "" &&
        this.props.caseType === "Lite Single Arch"
          ? data.currentLowerStep >= 0
          : data.currentLowerStep > 0, // required: anything besides N/A
      trackingUpperStepVal:
        data.trackingUpperStep !== "" &&
        this.props.caseType === "Lite Single Arch"
          ? data.trackingUpperStep >= 0
          : data.trackingUpperStep > 0, // required: anything besides N/A
      trackingLowerStepVal:
        data.trackingLowerStep !== "" &&
        this.props.caseType === "Lite Single Arch"
          ? data.trackingLowerStep >= 0
          : data.trackingLowerStep > 0, // required: anything besides N/A
      reasonRefVal: data.reasonRef !== "", // required: anything besides N/A
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation for each element on blur   */
  /* --------------------------------------------------------------------------------*/
  _validateData2 = (data, key) => {
    let dataVal = {
      currentUpperStepVal:
        data.currentUpperStep !== "" &&
        this.props.caseType === "Lite Single Arch"
          ? data.currentUpperStep >= 0
          : data.currentUpperStep > 0, // required: anything besides N/A
      currentLowerStepVal:
        data.currentLowerStep !== "" &&
        this.props.caseType === "Lite Single Arch"
          ? data.currentLowerStep >= 0
          : data.currentLowerStep > 0, // required: anything besides N/A
      trackingUpperStepVal:
        data.trackingUpperStep !== "" &&
        this.props.caseType === "Lite Single Arch"
          ? data.trackingUpperStep >= 0
          : data.trackingUpperStep > 0, // required: anything besides N/A
      trackingLowerStepVal:
        data.trackingLowerStep !== "" &&
        this.props.caseType === "Lite Single Arch"
          ? data.trackingLowerStep >= 0
          : data.trackingLowerStep > 0, // required: anything besides N/A
      reasonRefVal: data.reasonRef !== "", // required: anything besides N/A
    };
    let KeyVal = key + "Val";

    return { [KeyVal]: dataVal[KeyVal] };
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Errors on submit   */
  /* --------------------------------------------------------------------------------*/
  _validationErrors = (val) => {
    const errMsgs = {
      currentUpperStepValMsg: val.currentUpperStepVal
        ? ""
        : "valid current Upper Step is  required",
      currentLowerStepValMsg: val.currentLowerStepVal
        ? ""
        : " valid current Lower Step is  required",
      trackingUpperStepValMsg: val.trackingUpperStepVal
        ? ""
        : "valid tracking Upper Step is  required",
      trackingLowerStepValMsg: val.trackingLowerStepVal
        ? ""
        : "valid tracking Lower Step is  required",
      reasonRefValMsg: val.reasonRefVal ? "" : "reason is  required",
    };
    return errMsgs;
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Errors on blur   */
  /* --------------------------------------------------------------------------------*/
  _validationErrors2 = (val, key) => {
    const errMsgs = {
      currentUpperStepValMsg: val.currentUpperStepVal
        ? ""
        : "valid current Upper Step is  required",
      currentLowerStepValMsg: val.currentLowerStepVal
        ? ""
        : "valid current Lower Step is  required",
      trackingUpperStepValMsg: val.trackingUpperStepVal
        ? ""
        : "valid tracking Upper Step is  required",
      trackingLowerStepValMsg: val.trackingLowerStepVal
        ? ""
        : "valid tracking Lower Step is  required",
      reasonRefValMsg: val.reasonRefVal ? "" : "reason is  required",
    };
    let errMsgKey = key + "ValMsg";
    return { [errMsgKey]: errMsgs[errMsgKey] };
  };
  /* --------------------------------------------------------------------------------*/
  /* grab User Input data on submit  */
  /* --------------------------------------------------------------------------------*/
  _grabUserInput = () => {
    return {
      currentUpperStep: this.currentUpperStep.value,
      currentLowerStep: this.currentLowerStep.value,
      trackingUpperStep: this.trackingUpperStep.value,
      trackingLowerStep: this.trackingLowerStep.value,
      reasonRef: this.reasonRef.value,
      additional_instructions: this.additional_instructions.value,
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* grab User Input data on blure   */
  /* --------------------------------------------------------------------------------*/
  _grabUserInput2 = (key) => {
    let data = {
      currentUpperStep: this.currentUpperStep.value,
      currentLowerStep: this.currentLowerStep.value,
      trackingUpperStep: this.trackingUpperStep.value,
      trackingLowerStep: this.trackingLowerStep.value,
      reasonRef: this.reasonRef.value,
      additional_instructions: this.additional_instructions.value,
    };

    return { [key]: data[key] };
  };
  render() {
    // explicit class assigning based on validation
    let notValidClasses = {};
    //current upper validation
    if (
      typeof this.state.currentUpperStepVal === "undefined" ||
      this.state.currentUpperStepVal
    ) {
      notValidClasses.currentUpperStepCls = "form-control";
    } else {
      notValidClasses.currentUpperStepCls = "is-invalid form-control";
      notValidClasses.currentUpperStepValGrpCls = "text-danger";
    }
    //current lower validation
    if (
      typeof this.state.currentLowerStepVal === "undefined" ||
      this.state.currentLowerStepVal
    ) {
      notValidClasses.currentLowerStepCls = "form-control";
    } else {
      notValidClasses.currentLowerStepCls = "is-invalid form-control";
      notValidClasses.currentLowerStepValGrpCls = "text-danger";
    }
    //tracking upper validation
    if (
      typeof this.state.trackingUpperStepVal === "undefined" ||
      this.state.trackingUpperStepVal
    ) {
      notValidClasses.trackingUpperStepCls = "form-control";
    } else {
      notValidClasses.trackingUpperStepCls = "is-invalid form-control";
      notValidClasses.trackingUpperStepValGrpCls = "text-danger";
    }
    //tracking lower validation
    if (
      typeof this.state.trackingLowerStepVal === "undefined" ||
      this.state.trackingLowerStepVal
    ) {
      notValidClasses.trackingLowerStepCls = "form-control";
    } else {
      notValidClasses.trackingLowerStepCls = "is-invalid form-control";
      notValidClasses.trackingLowerStepValGrpCls = "text-danger";
    }
    //reason validation
    if (
      typeof this.state.reasonRefVal === "undefined" ||
      this.state.reasonRefVal
    ) {
      notValidClasses.reasonRefCls = "form-control";
    } else {
      notValidClasses.reasonRefCls = "is-invalid form-control";
      notValidClasses.reasonRefValGrpCls = "text-danger";
    }
    //instructions validation
    if (
      typeof this.state.additional_instructionsVal === "undefined" ||
      this.state.additional_instructionsVal
    ) {
      notValidClasses.additional_instructionsCls = "form-control";
    } else {
      notValidClasses.additional_instructionsCls = "is-invalid form-control";
      notValidClasses.additional_instructionsValGrpCls = "text-danger";
    }
    return (
      <div className="step step1 mt-5 mb-5 ">
        <div className=" justify-content-md-center">
          <div>
            <div className="">
              <form id="Form" className="form-horizontal mt-2 mb-2">
                <div className="adjustment_grid">
                  {/* Current Step Numbers  */}
                  <div className="text-center">
                    <label className="control-label title_active">
                      Current Step Numbers
                    </label>
                    <div className="text-left">
                      {/* Upper step # */}
                      <div>
                        <div className="form-group content form-block-holder">
                          <label className="control-label title_active">
                            Upper step #
                          </label>
                          <div>
                            <input
                              ref={(f) => {
                                this.currentUpperStep = f;
                              }}
                              onClick={(e) => {
                                e.target.focus();
                              }}
                              onMouseUp={(e) => {
                                e.target.blur();
                              }}
                              min="0"
                              autoComplete="off"
                              type="number"
                              placeholder="Required Field"
                              className={`${notValidClasses.currentUpperStepCls} green_input`}
                              required
                              defaultValue={this.state.current_upper_step}
                              onBlur={() =>
                                this.validationCheck2("currentUpperStep")
                              }
                            />
                            <div
                              className={
                                notValidClasses.currentUpperStepValGrpCls
                              }
                            >
                              {this.state.currentUpperStepValMsg}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-left ">
                      {/*  Lower step # */}
                      <div>
                        <div className="form-group content form-block-holder">
                          <label className="control-label title_active">
                            Lower step #
                          </label>
                          <div>
                            <input
                              ref={(f) => {
                                this.currentLowerStep = f;
                              }}
                              onClick={(e) => {
                                e.target.focus();
                              }}
                              onMouseUp={(e) => {
                                e.target.blur();
                              }}
                              min="0"
                              autoComplete="off"
                              type="number"
                              placeholder="Required Field"
                              className={`${notValidClasses.currentLowerStepCls} green_input`}
                              required
                              defaultValue={this.state.current_lower_step}
                              onBlur={() =>
                                this.validationCheck2("currentLowerStep")
                              }
                            />
                            <div
                              className={
                                notValidClasses.currentLowerStepValGrpCls
                              }
                            >
                              {this.state.currentLowerStepValMsg}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Last Step That Was Tracking  */}
                  <div className="text-center">
                    <label className="control-label title_active">
                      Last Step That Was Tracking
                    </label>
                    <Row className="text-left m-0">
                      {/* Upper step # */}
                      <Col>
                        <div className="form-group content form-block-holder">
                          <label className="control-label title_active">
                            Upper step #
                          </label>
                          <div>
                            <input
                              ref={(f) => {
                                this.trackingUpperStep = f;
                              }}
                              onClick={(e) => {
                                e.target.focus();
                              }}
                              onMouseUp={(e) => {
                                e.target.blur();
                              }}
                              min="0"
                              autoComplete="off"
                              type="number"
                              placeholder="Required Field"
                              className={`${notValidClasses.trackingUpperStepCls} green_input`}
                              required
                              defaultValue={this.state.tracking_upper_step}
                              onBlur={() =>
                                this.validationCheck2("trackingUpperStep")
                              }
                            />
                            <div
                              className={
                                notValidClasses.trackingUpperStepValGrpCls
                              }
                            >
                              {this.state.trackingUpperStepValMsg}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="text-left m-0">
                      {/* Lower step # */}
                      <Col>
                        <div className="form-group content form-block-holder">
                          <label className="control-label title_active">
                            Lower step #
                          </label>
                          <div>
                            <input
                              ref={(f) => {
                                this.trackingLowerStep = f;
                              }}
                              onClick={(e) => {
                                e.target.focus();
                              }}
                              onMouseUp={(e) => {
                                e.target.blur();
                              }}
                              min="0"
                              autoComplete="off"
                              type="number"
                              placeholder="Required Field"
                              className={`${notValidClasses.trackingLowerStepCls} green_input`}
                              required
                              defaultValue={this.state.tracking_lower_step}
                              onBlur={() =>
                                this.validationCheck2("trackingLowerStep")
                              }
                            />
                            <div
                              className={
                                notValidClasses.trackingLowerStepValGrpCls
                              }
                            >
                              {this.state.trackingLowerStepValMsg}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div>
                  {/* Reason for Submission  */}
                  <div>
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Reason for Submission
                      </label>
                      <div>
                        <textarea
                          ref={(f) => {
                            this.reasonRef = f;
                          }}
                          autoComplete="off"
                          type="textarea"
                          rows="3"
                          placeholder="Required Field"
                          className={`${notValidClasses.reasonRefCls} green_input`}
                          required
                          defaultValue={this.state.reason}
                          onBlur={() => this.validationCheck2("reasonRef")}
                        />
                        <div className={notValidClasses.reasonRefValGrpCls}>
                          {this.state.reasonRefValMsg}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {/* Additional Instructions */}
                  <div>
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active">
                        Additional Instructions{" "}
                      </label>
                      <div>
                        <textarea
                          ref={(f) => {
                            this.additional_instructions = f;
                          }}
                          autoComplete="off"
                          type="textarea"
                          rows="3"
                          className={`${notValidClasses.additional_instructionsCls} green_input`}
                          defaultValue={this.state.additional_instructions}
                          onBlur={() =>
                            this.validationCheck2("additional_instructions")
                          }
                        />
                        <div
                          className={
                            notValidClasses.additional_instructionsValGrpCls
                          }
                        >
                          {this.state.additional_instructionsValMsg}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
