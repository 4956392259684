import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { authenticationService } from "../jwt/_services";
import { getAuthrizationCode } from "../api/api";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const currentUser = authenticationService.token;
  const code = (window.location.search.match(/code=([^&]+)/) || [])[1];
  // check if there is token before render private routes
  useEffect(() => {
    if (currentUser) {
      return;
    }
    const fn = async () => {
      if (!code) {
        await getAuthrizationCode();
      } else {
        // await getAccessToken(code);
      }
    };

    fn();
  });

  const render = (props) => (currentUser ? <Component {...props} /> : null);
  return <Route render={render} {...rest} />;
};

export default PrivateRoute;
