import upper_l_1 from "../../assets/images/teeth_v4_png/not_active/upper_l_1_not_active.png";
import upper_l_2 from "../../assets/images/teeth_v4_png/not_active/upper_l_2_not_active.png";
import upper_l_3 from "../../assets/images/teeth_v4_png/not_active/upper_l_3_not_active.png";
import upper_l_4 from "../../assets/images/teeth_v4_png/not_active/upper_l_4_not_active.png";
import upper_l_5 from "../../assets/images/teeth_v4_png/not_active/upper_l_5_not_active.png";
import upper_l_6 from "../../assets/images/teeth_v4_png/not_active/upper_l_6_not_active.png";
import upper_l_7 from "../../assets/images/teeth_v4_png/not_active/upper_l_7_not_active.png";
import upper_l_8 from "../../assets/images/teeth_v4_png/not_active/upper_l_8_not_active.png";
import upper_r_1 from "../../assets/images/teeth_v4_png/not_active/upper_r_1_not_active.png";
import upper_r_2 from "../../assets/images/teeth_v4_png/not_active/upper_r_2_not_active.png";
import upper_r_3 from "../../assets/images/teeth_v4_png/not_active/upper_r_3_not_active.png";
import upper_r_4 from "../../assets/images/teeth_v4_png/not_active/upper_r_4_not_active.png";
import upper_r_5 from "../../assets/images/teeth_v4_png/not_active/upper_r_5_not_active.png";
import upper_r_6 from "../../assets/images/teeth_v4_png/not_active/upper_r_6_not_active.png";
import upper_r_7 from "../../assets/images/teeth_v4_png/not_active/upper_r_7_not_active.png";
import upper_r_8 from "../../assets/images/teeth_v4_png/not_active/upper_r_8_not_active.png";

import upper_l_1_hover from "../../assets/images/teeth_v4_png/hover/upper_l_1_hover.png";
import upper_l_2_hover from "../../assets/images/teeth_v4_png/hover/upper_l_2_hover.png";
import upper_l_3_hover from "../../assets/images/teeth_v4_png/hover/upper_l_3_hover.png";
import upper_l_4_hover from "../../assets/images/teeth_v4_png/hover/upper_l_4_hover.png";
import upper_l_5_hover from "../../assets/images/teeth_v4_png/hover/upper_l_5_hover.png";
import upper_l_6_hover from "../../assets/images/teeth_v4_png/hover/upper_l_6_hover.png";
import upper_l_7_hover from "../../assets/images/teeth_v4_png/hover/upper_l_7_hover.png";
import upper_l_8_hover from "../../assets/images/teeth_v4_png/hover/upper_l_8_hover.png";
import upper_r_1_hover from "../../assets/images/teeth_v4_png/hover/upper_r_1_hover.png";
import upper_r_2_hover from "../../assets/images/teeth_v4_png/hover/upper_r_2_hover.png";
import upper_r_3_hover from "../../assets/images/teeth_v4_png/hover/upper_r_3_hover.png";
import upper_r_4_hover from "../../assets/images/teeth_v4_png/hover/upper_r_4_hover.png";
import upper_r_5_hover from "../../assets/images/teeth_v4_png/hover/upper_r_5_hover.png";
import upper_r_6_hover from "../../assets/images/teeth_v4_png/hover/upper_r_6_hover.png";
import upper_r_7_hover from "../../assets/images/teeth_v4_png/hover/upper_r_7_hover.png";
import upper_r_8_hover from "../../assets/images/teeth_v4_png/hover/upper_r_8_hover.png";

import upper_l_1_active from "../../assets/images/teeth_v4_png/active/upper_l_1_active.png";
import upper_l_2_active from "../../assets/images/teeth_v4_png/active/upper_l_2_active.png";
import upper_l_3_active from "../../assets/images/teeth_v4_png/active/upper_l_3_active.png";
import upper_l_4_active from "../../assets/images/teeth_v4_png/active/upper_l_4_active.png";
import upper_l_5_active from "../../assets/images/teeth_v4_png/active/upper_l_5_active.png";
import upper_l_6_active from "../../assets/images/teeth_v4_png/active/upper_l_6_active.png";
import upper_l_7_active from "../../assets/images/teeth_v4_png/active/upper_l_7_active.png";
import upper_l_8_active from "../../assets/images/teeth_v4_png/active/upper_l_8_active.png";
import upper_r_1_active from "../../assets/images/teeth_v4_png/active/upper_r_1_active.png";
import upper_r_2_active from "../../assets/images/teeth_v4_png/active/upper_r_2_active.png";
import upper_r_3_active from "../../assets/images/teeth_v4_png/active/upper_r_3_active.png";
import upper_r_4_active from "../../assets/images/teeth_v4_png/active/upper_r_4_active.png";
import upper_r_5_active from "../../assets/images/teeth_v4_png/active/upper_r_5_active.png";
import upper_r_6_active from "../../assets/images/teeth_v4_png/active/upper_r_6_active.png";
import upper_r_7_active from "../../assets/images/teeth_v4_png/active/upper_r_7_active.png";
import upper_r_8_active from "../../assets/images/teeth_v4_png/active/upper_r_8_active.png";

import lower_l_1 from "../../assets/images/teeth_v4_png/not_active/lower_l_1_not_active.png";
import lower_l_2 from "../../assets/images/teeth_v4_png/not_active/lower_l_2_not_active.png";
import lower_l_3 from "../../assets/images/teeth_v4_png/not_active/lower_l_3_not_active.png";
import lower_l_4 from "../../assets/images/teeth_v4_png/not_active/lower_l_4_not_active.png";
import lower_l_5 from "../../assets/images/teeth_v4_png/not_active/lower_l_5_not_active.png";
import lower_l_6 from "../../assets/images/teeth_v4_png/not_active/lower_l_6_not_active.png";
import lower_l_7 from "../../assets/images/teeth_v4_png/not_active/lower_l_7_not_active.png";
import lower_l_8 from "../../assets/images/teeth_v4_png/not_active/lower_l_8_not_active.png";
import lower_r_1 from "../../assets/images/teeth_v4_png/not_active/lower_r_1_not_active.png";
import lower_r_2 from "../../assets/images/teeth_v4_png/not_active/lower_r_2_not_active.png";
import lower_r_3 from "../../assets/images/teeth_v4_png/not_active/lower_r_3_not_active.png";
import lower_r_4 from "../../assets/images/teeth_v4_png/not_active/lower_r_4_not_active.png";
import lower_r_5 from "../../assets/images/teeth_v4_png/not_active/lower_r_5_not_active.png";
import lower_r_6 from "../../assets/images/teeth_v4_png/not_active/lower_r_6_not_active.png";
import lower_r_7 from "../../assets/images/teeth_v4_png/not_active/lower_r_7_not_active.png";
import lower_r_8 from "../../assets/images/teeth_v4_png/not_active/lower_r_8_not_active.png";

import lower_l_1_hover from "../../assets/images/teeth_v4_png/hover/lower_l_1_hover.png";
import lower_l_2_hover from "../../assets/images/teeth_v4_png/hover/lower_l_2_hover.png";
import lower_l_3_hover from "../../assets/images/teeth_v4_png/hover/lower_l_3_hover.png";
import lower_l_4_hover from "../../assets/images/teeth_v4_png/hover/lower_l_4_hover.png";
import lower_l_5_hover from "../../assets/images/teeth_v4_png/hover/lower_l_5_hover.png";
import lower_l_6_hover from "../../assets/images/teeth_v4_png/hover/lower_l_6_hover.png";
import lower_l_7_hover from "../../assets/images/teeth_v4_png/hover/lower_l_7_hover.png";
import lower_l_8_hover from "../../assets/images/teeth_v4_png/hover/lower_l_8_hover.png";
import lower_r_1_hover from "../../assets/images/teeth_v4_png/hover/lower_r_1_hover.png";
import lower_r_2_hover from "../../assets/images/teeth_v4_png/hover/lower_r_2_hover.png";
import lower_r_3_hover from "../../assets/images/teeth_v4_png/hover/lower_r_3_hover.png";
import lower_r_4_hover from "../../assets/images/teeth_v4_png/hover/lower_r_4_hover.png";
import lower_r_5_hover from "../../assets/images/teeth_v4_png/hover/lower_r_5_hover.png";
import lower_r_6_hover from "../../assets/images/teeth_v4_png/hover/lower_r_6_hover.png";
import lower_r_7_hover from "../../assets/images/teeth_v4_png/hover/lower_r_7_hover.png";
import lower_r_8_hover from "../../assets/images/teeth_v4_png/hover/lower_r_8_hover.png";

import lower_l_1_active from "../../assets/images/teeth_v4_png/active/lower_l_1_active.png";
import lower_l_2_active from "../../assets/images/teeth_v4_png/active/lower_l_2_active.png";
import lower_l_3_active from "../../assets/images/teeth_v4_png/active/lower_l_3_active.png";
import lower_l_4_active from "../../assets/images/teeth_v4_png/active/lower_l_4_active.png";
import lower_l_5_active from "../../assets/images/teeth_v4_png/active/lower_l_5_active.png";
import lower_l_6_active from "../../assets/images/teeth_v4_png/active/lower_l_6_active.png";
import lower_l_7_active from "../../assets/images/teeth_v4_png/active/lower_l_7_active.png";
import lower_l_8_active from "../../assets/images/teeth_v4_png/active/lower_l_8_active.png";
import lower_r_1_active from "../../assets/images/teeth_v4_png/active/lower_r_1_active.png";
import lower_r_2_active from "../../assets/images/teeth_v4_png/active/lower_r_2_active.png";
import lower_r_3_active from "../../assets/images/teeth_v4_png/active/lower_r_3_active.png";
import lower_r_4_active from "../../assets/images/teeth_v4_png/active/lower_r_4_active.png";
import lower_r_5_active from "../../assets/images/teeth_v4_png/active/lower_r_5_active.png";
import lower_r_6_active from "../../assets/images/teeth_v4_png/active/lower_r_6_active.png";
import lower_r_7_active from "../../assets/images/teeth_v4_png/active/lower_r_7_active.png";
import lower_r_8_active from "../../assets/images/teeth_v4_png/active/lower_r_8_active.png";

import upper_l_1_restrect from "../../assets/images/teeth_v4_png/restrect/upper_l_1_restrect.png";
import upper_l_2_restrect from "../../assets/images/teeth_v4_png/restrect/upper_l_2_restrect.png";
import upper_l_3_restrect from "../../assets/images/teeth_v4_png/restrect/upper_l_3_restrect.png";
import upper_l_4_restrect from "../../assets/images/teeth_v4_png/restrect/upper_l_4_restrect.png";
import upper_l_5_restrect from "../../assets/images/teeth_v4_png/restrect/upper_l_5_restrect.png";
import upper_l_6_restrect from "../../assets/images/teeth_v4_png/restrect/upper_l_6_restrect.png";
import upper_l_7_restrect from "../../assets/images/teeth_v4_png/restrect/upper_l_7_restrect.png";
import upper_l_8_restrect from "../../assets/images/teeth_v4_png/restrect/upper_l_8_restrect.png";
import upper_r_1_restrect from "../../assets/images/teeth_v4_png/restrect/upper_r_1_restrect.png";
import upper_r_2_restrect from "../../assets/images/teeth_v4_png/restrect/upper_r_2_restrect.png";
import upper_r_3_restrect from "../../assets/images/teeth_v4_png/restrect/upper_r_3_restrect.png";
import upper_r_4_restrect from "../../assets/images/teeth_v4_png/restrect/upper_r_4_restrect.png";
import upper_r_5_restrect from "../../assets/images/teeth_v4_png/restrect/upper_r_5_restrect.png";
import upper_r_6_restrect from "../../assets/images/teeth_v4_png/restrect/upper_r_6_restrect.png";
import upper_r_7_restrect from "../../assets/images/teeth_v4_png/restrect/upper_r_7_restrect.png";
import upper_r_8_restrect from "../../assets/images/teeth_v4_png/restrect/upper_r_8_restrect.png";


import lower_l_1_restrect from "../../assets/images/teeth_v4_png/restrect/lower_l_1_restrect.png";
import lower_l_2_restrect from "../../assets/images/teeth_v4_png/restrect/lower_l_2_restrect.png";
import lower_l_3_restrect from "../../assets/images/teeth_v4_png/restrect/lower_l_3_restrect.png";
import lower_l_4_restrect from "../../assets/images/teeth_v4_png/restrect/lower_l_4_restrect.png";
import lower_l_5_restrect from "../../assets/images/teeth_v4_png/restrect/lower_l_5_restrect.png";
import lower_l_6_restrect from "../../assets/images/teeth_v4_png/restrect/lower_l_6_restrect.png";
import lower_l_7_restrect from "../../assets/images/teeth_v4_png/restrect/lower_l_7_restrect.png";
import lower_l_8_restrect from "../../assets/images/teeth_v4_png/restrect/lower_l_8_restrect.png";
import lower_r_1_restrect from "../../assets/images/teeth_v4_png/restrect/lower_r_1_restrect.png";
import lower_r_2_restrect from "../../assets/images/teeth_v4_png/restrect/lower_r_2_restrect.png";
import lower_r_3_restrect from "../../assets/images/teeth_v4_png/restrect/lower_r_3_restrect.png";
import lower_r_4_restrect from "../../assets/images/teeth_v4_png/restrect/lower_r_4_restrect.png";
import lower_r_5_restrect from "../../assets/images/teeth_v4_png/restrect/lower_r_5_restrect.png";
import lower_r_6_restrect from "../../assets/images/teeth_v4_png/restrect/lower_r_6_restrect.png";
import lower_r_7_restrect from "../../assets/images/teeth_v4_png/restrect/lower_r_7_restrect.png";
import lower_r_8_restrect from "../../assets/images/teeth_v4_png/restrect/lower_r_8_restrect.png";


import upper_l_1_missing from "../../assets/images/teeth_v4_png/missing/upper_l_1_missing.png";
import upper_l_2_missing from "../../assets/images/teeth_v4_png/missing/upper_l_2_missing.png";
import upper_l_3_missing from "../../assets/images/teeth_v4_png/missing/upper_l_3_missing.png";
import upper_l_4_missing from "../../assets/images/teeth_v4_png/missing/upper_l_4_missing.png";
import upper_l_5_missing from "../../assets/images/teeth_v4_png/missing/upper_l_5_missing.png";
import upper_l_6_missing from "../../assets/images/teeth_v4_png/missing/upper_l_6_missing.png";
import upper_l_7_missing from "../../assets/images/teeth_v4_png/missing/upper_l_7_missing.png";
import upper_l_8_missing from "../../assets/images/teeth_v4_png/missing/upper_l_8_missing.png";
import upper_r_1_missing from "../../assets/images/teeth_v4_png/missing/upper_r_1_missing.png";
import upper_r_2_missing from "../../assets/images/teeth_v4_png/missing/upper_r_2_missing.png";
import upper_r_3_missing from "../../assets/images/teeth_v4_png/missing/upper_r_3_missing.png";
import upper_r_4_missing from "../../assets/images/teeth_v4_png/missing/upper_r_4_missing.png";
import upper_r_5_missing from "../../assets/images/teeth_v4_png/missing/upper_r_5_missing.png";
import upper_r_6_missing from "../../assets/images/teeth_v4_png/missing/upper_r_6_missing.png";
import upper_r_7_missing from "../../assets/images/teeth_v4_png/missing/upper_r_7_missing.png";
import upper_r_8_missing from "../../assets/images/teeth_v4_png/missing/upper_r_8_missing.png";




import lower_l_1_missing from "../../assets/images/teeth_v4_png/missing/lower_l_1_missing.png";
import lower_l_2_missing from "../../assets/images/teeth_v4_png/missing/lower_l_2_missing.png";
import lower_l_3_missing from "../../assets/images/teeth_v4_png/missing/lower_l_3_missing.png";
import lower_l_4_missing from "../../assets/images/teeth_v4_png/missing/lower_l_4_missing.png";
import lower_l_5_missing from "../../assets/images/teeth_v4_png/missing/lower_l_5_missing.png";
import lower_l_6_missing from "../../assets/images/teeth_v4_png/missing/lower_l_6_missing.png";
import lower_l_7_missing from "../../assets/images/teeth_v4_png/missing/lower_l_7_missing.png";
import lower_l_8_missing from "../../assets/images/teeth_v4_png/missing/lower_l_8_missing.png";
import lower_r_1_missing from "../../assets/images/teeth_v4_png/missing/lower_r_1_missing.png";
import lower_r_2_missing from "../../assets/images/teeth_v4_png/missing/lower_r_2_missing.png";
import lower_r_3_missing from "../../assets/images/teeth_v4_png/missing/lower_r_3_missing.png";
import lower_r_4_missing from "../../assets/images/teeth_v4_png/missing/lower_r_4_missing.png";
import lower_r_5_missing from "../../assets/images/teeth_v4_png/missing/lower_r_5_missing.png";
import lower_r_6_missing from "../../assets/images/teeth_v4_png/missing/lower_r_6_missing.png";
import lower_r_7_missing from "../../assets/images/teeth_v4_png/missing/lower_r_7_missing.png";
import lower_r_8_missing from "../../assets/images/teeth_v4_png/missing/lower_r_8_missing.png";


export const teeth_pdf = {
  upper_l_1: upper_l_1,
  upper_l_2: upper_l_2,
  upper_l_3: upper_l_3,
  upper_l_4: upper_l_4,
  upper_l_5: upper_l_5,
  upper_l_6: upper_l_6,
  upper_l_7: upper_l_7,
  upper_l_8: upper_l_8,
  upper_r_1: upper_r_1,
  upper_r_2: upper_r_2,
  upper_r_3: upper_r_3,
  upper_r_4: upper_r_4,
  upper_r_5: upper_r_5,
  upper_r_6: upper_r_6,
  upper_r_7: upper_r_7,
  upper_r_8: upper_r_8,

  upper_l_1_hover: upper_l_1_hover,
  upper_l_2_hover: upper_l_2_hover,
  upper_l_3_hover: upper_l_3_hover,
  upper_l_4_hover: upper_l_4_hover,
  upper_l_5_hover: upper_l_5_hover,
  upper_l_6_hover: upper_l_6_hover,
  upper_l_7_hover: upper_l_7_hover,
  upper_l_8_hover: upper_l_8_hover,
  upper_r_1_hover: upper_r_1_hover,
  upper_r_2_hover: upper_r_2_hover,
  upper_r_3_hover: upper_r_3_hover,
  upper_r_4_hover: upper_r_4_hover,
  upper_r_5_hover: upper_r_5_hover,
  upper_r_6_hover: upper_r_6_hover,
  upper_r_7_hover: upper_r_7_hover,
  upper_r_8_hover: upper_r_8_hover,

  upper_l_1_active: upper_l_1_active,
  upper_l_2_active: upper_l_2_active,
  upper_l_3_active: upper_l_3_active,
  upper_l_4_active: upper_l_4_active,
  upper_l_5_active: upper_l_5_active,
  upper_l_6_active: upper_l_6_active,
  upper_l_7_active: upper_l_7_active,
  upper_l_8_active: upper_l_8_active,
  upper_r_1_active: upper_r_1_active,
  upper_r_2_active: upper_r_2_active,
  upper_r_3_active: upper_r_3_active,
  upper_r_4_active: upper_r_4_active,
  upper_r_5_active: upper_r_5_active,
  upper_r_6_active: upper_r_6_active,
  upper_r_7_active: upper_r_7_active,
  upper_r_8_active: upper_r_8_active,

  lower_l_1: lower_l_1,
  lower_l_2: lower_l_2,
  lower_l_3: lower_l_3,
  lower_l_4: lower_l_4,
  lower_l_5: lower_l_5,
  lower_l_6: lower_l_6,
  lower_l_7: lower_l_7,
  lower_l_8: lower_l_8,
  lower_r_1: lower_r_1,
  lower_r_2: lower_r_2,
  lower_r_3: lower_r_3,
  lower_r_4: lower_r_4,
  lower_r_5: lower_r_5,
  lower_r_6: lower_r_6,
  lower_r_7: lower_r_7,
  lower_r_8: lower_r_8,

  lower_l_1_hover: lower_l_1_hover,
  lower_l_2_hover: lower_l_2_hover,
  lower_l_3_hover: lower_l_3_hover,
  lower_l_4_hover: lower_l_4_hover,
  lower_l_5_hover: lower_l_5_hover,
  lower_l_6_hover: lower_l_6_hover,
  lower_l_7_hover: lower_l_7_hover,
  lower_l_8_hover: lower_l_8_hover,
  lower_r_1_hover: lower_r_1_hover,
  lower_r_2_hover: lower_r_2_hover,
  lower_r_3_hover: lower_r_3_hover,
  lower_r_4_hover: lower_r_4_hover,
  lower_r_5_hover: lower_r_5_hover,
  lower_r_6_hover: lower_r_6_hover,
  lower_r_7_hover: lower_r_7_hover,
  lower_r_8_hover: lower_r_8_hover,

  lower_l_1_active: lower_l_1_active,
  lower_l_2_active: lower_l_2_active,
  lower_l_3_active: lower_l_3_active,
  lower_l_4_active: lower_l_4_active,
  lower_l_5_active: lower_l_5_active,
  lower_l_6_active: lower_l_6_active,
  lower_l_7_active: lower_l_7_active,
  lower_l_8_active: lower_l_8_active,
  lower_r_1_active: lower_r_1_active,
  lower_r_2_active: lower_r_2_active,
  lower_r_3_active: lower_r_3_active,
  lower_r_4_active: lower_r_4_active,
  lower_r_5_active: lower_r_5_active,
  lower_r_6_active: lower_r_6_active,
  lower_r_7_active: lower_r_7_active,
  lower_r_8_active: lower_r_8_active,

  upper_l_1_restrect: upper_l_1_restrect,
  upper_l_2_restrect: upper_l_2_restrect,
  upper_l_3_restrect: upper_l_3_restrect,
  upper_l_4_restrect: upper_l_4_restrect,
  upper_l_5_restrect: upper_l_5_restrect,
  upper_l_6_restrect: upper_l_6_restrect,
  upper_l_7_restrect: upper_l_7_restrect,
  upper_l_8_restrect: upper_l_8_restrect,
  upper_r_1_restrect: upper_r_1_restrect,
  upper_r_2_restrect: upper_r_2_restrect,
  upper_r_3_restrect: upper_r_3_restrect,
  upper_r_4_restrect: upper_r_4_restrect,
  upper_r_5_restrect: upper_r_5_restrect,
  upper_r_6_restrect: upper_r_6_restrect,
  upper_r_7_restrect: upper_r_7_restrect,
  upper_r_8_restrect: upper_r_8_restrect,

  lower_l_1_restrect: lower_l_1_restrect,
  lower_l_2_restrect: lower_l_2_restrect,
  lower_l_3_restrect: lower_l_3_restrect,
  lower_l_4_restrect: lower_l_4_restrect,
  lower_l_5_restrect: lower_l_5_restrect,
  lower_l_6_restrect: lower_l_6_restrect,
  lower_l_7_restrect: lower_l_7_restrect,
  lower_l_8_restrect: lower_l_8_restrect,
  lower_r_1_restrect: lower_r_1_restrect,
  lower_r_2_restrect: lower_r_2_restrect,
  lower_r_3_restrect: lower_r_3_restrect,
  lower_r_4_restrect: lower_r_4_restrect,
  lower_r_5_restrect: lower_r_5_restrect,
  lower_r_6_restrect: lower_r_6_restrect,
  lower_r_7_restrect: lower_r_7_restrect,
  lower_r_8_restrect: lower_r_8_restrect,

  upper_l_1_missing: upper_l_1_missing,
  upper_l_2_missing: upper_l_2_missing,
  upper_l_3_missing: upper_l_3_missing,
  upper_l_4_missing: upper_l_4_missing,
  upper_l_5_missing: upper_l_5_missing,
  upper_l_6_missing: upper_l_6_missing,
  upper_l_7_missing: upper_l_7_missing,
  upper_l_8_missing: upper_l_8_missing,
  upper_r_1_missing: upper_r_1_missing,
  upper_r_2_missing: upper_r_2_missing,
  upper_r_3_missing: upper_r_3_missing,
  upper_r_4_missing: upper_r_4_missing,
  upper_r_5_missing: upper_r_5_missing,
  upper_r_6_missing: upper_r_6_missing,
  upper_r_7_missing: upper_r_7_missing,
  upper_r_8_missing: upper_r_8_missing,




  lower_l_1_missing: lower_l_1_missing,
  lower_l_2_missing: lower_l_2_missing,
  lower_l_3_missing: lower_l_3_missing,
  lower_l_4_missing: lower_l_4_missing,
  lower_l_5_missing: lower_l_5_missing,
  lower_l_6_missing: lower_l_6_missing,
  lower_l_7_missing: lower_l_7_missing,
  lower_l_8_missing: lower_l_8_missing,
  lower_r_1_missing: lower_r_1_missing,
  lower_r_2_missing: lower_r_2_missing,
  lower_r_3_missing: lower_r_3_missing,
  lower_r_4_missing: lower_r_4_missing,
  lower_r_5_missing: lower_r_5_missing,
  lower_r_6_missing: lower_r_6_missing,
  lower_r_7_missing: lower_r_7_missing,
  lower_r_8_missing: lower_r_8_missing,



};
