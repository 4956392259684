import React from "react";
import "./style.css";
import EONbasic from "../../assets/images/basic.svg";
import EONplus from "../../assets/images/plus.svg";
import EONpro from "../../assets/images/pro.svg";

export default function recomendation({ packagesCount }) {
  var subscription = {
    basic: {
      heading: "Quick and Simple Cases",
      subHeading: "A straight forward case that can be carried out with ease.",
      list: [
        "0-10 aligner steps",
        "Treatment planning in 3-5 calendar days",
        "1 free refinement",
        "One shipment",
        "1 free set of retainers",
        "9 month treatment period",
      ],
    },
    plus: {
      heading: "An All-Round Solution",
      subHeading:
        "A moderate case that can be treated with ease with great monitoring and compliance, might need auxiliaries.",
      list: [
        "11-24 aligner steps",
        "Treatment planning in 3-5 calendar days",
        "3 free refinement",
        "One shipment",
        "1 free set of retainers",
        "18 month treatment period",
      ],
    },
    pro: {
      heading: "Treat Complex Cases Confidently",
      subHeading:
        "A challenging case that might require the use of auxiliaries and/or extractions.",
      list: [
        "25+ aligner steps",
        "Treatment planning in 3-5 calendar days",
        "Unlimited refinements",
        "24 steps per shipment",
        "2 free set of retainers",
        "36 month treatment period",
      ],
    },
  };

  let pkg = "";
  // if (packagesCount["pro"] >= packagesCount["plus"] && packagesCount["pro"] >= packagesCount["basic"] ) {
  //   pkg = "pro";
  // } else if (packagesCount["plus"] >= packagesCount["basic"]) {
  //   pkg = "plus";
  // } else {
  //   pkg = "basic";
  // }

  if (packagesCount["pro"] >=1  ) {
    pkg = "pro";
  } else if (packagesCount["plus"] >= 1) {
    pkg = "plus";
  } else if (packagesCount["basic"] >= 1) {
    pkg = "basic";
  } 


  return (
    <div className={`recomendation-box ${pkg}`}>
      <p className="heading">{subscription[pkg].heading}</p>
      <img src={pkg === 'basic' ? EONbasic : (pkg === 'plus' ? EONplus : EONpro )} width="150" height="40" alt="" />
      <p>{subscription[pkg].subHeading}</p>
      <ul>
        {subscription[pkg].list.map((item, ind) => {
          return <li key={item + "-" + ind}>{item}</li>;
        })}
      </ul>
    </div>
  );
}
