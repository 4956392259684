import React, { Component } from "react";
import { connect } from "react-redux";

import {
  CardBody,
  CardFooter,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { schema } from "../../helpers/prescriptionValidation";
import CustomeTeeth from "../Teeth/CustomeTeeth";
import { updateAlignerCase, getDoctorPreferences } from "../../api/api";
import { mapTeethToApi } from "../../services/mapTeethToApi";
import { updatePrescription } from "../../redux/CaseSubmission/action";
import { mapAdvancedToApi } from "../../services/mapAdvancedToApi";
import CustomeHeader from "../../components/header/CustomeHeader";
import CustomRadioGroup from "../../components/radioGroup/CustomRadioGroup";
import AdvancedModulesCopy from "./AdvancedModulesCopy";
import {
  ToothSelector,
  TEETH_MODULES,
} from "@eon-dental/advanced-modules/dist/rollup-export";
import { AdvancedModulesNew } from "./AdvancedModulesNew";
import { oldAdvancedModulesMapper } from "../../services/newDataMapper";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updatePrescription: (payload) => dispatch(updatePrescription(payload)),
});

class Prescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      use_advanced: false,
      isNewAdvancedModules:
        this?.props?.caseReducer?.case?.isNewAdvancedModules,
      advanced_modules: {},
      doctorId: props.userReducer.currentUser.id,
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
  }

  onAdvancedModulesChange = (advancedModules) =>
    this.setState({ advanced_modules: advancedModules });

  /*--------------------------------------------------------------------------------*/
  /*Prescription step submit  */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.state;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    setSubmitting(true);
    let step = this.props.rejectable_id ? "clinical_findings" : "prescription";
    let data = {
      aligner_case: {
        step: step,
        advanced_modules: this.state.isNewAdvancedModules
          ? this.state.advanced_modules
          : oldAdvancedModulesMapper(
              this.props.caseSubmission.advanced_modules,
              this.props.advancedModules.modules,
              this.props.customeTeethReducer
            ),
        treatment_goals: {
          treat_arches: values.treat_arches,
        },
        wear_cycle: values.wear_cycle,
        treatment_summary: values.summary_and_special_instructions,
        clinical_findings: {
          chief_complaint: values.chief_complaint,
          restriction: mapTeethToApi(
            this.props.customeTeethReducer.Case_Tooth_Movement_Restrictions
          ),
        },
      },
      ...(this.props.rejectable_id && {
        resolve_rejection_id: this.props.rejectable_id,
      }),
    };
    let reducer_data = {
      chief_complaint: values.chief_complaint,
      summary_and_special_instructions: values.summary_and_special_instructions,
      treat_arches: values.treat_arches,
      wear_cycle: values.wear_cycle,
    };

    if (this.state.isNewAdvancedModules) {
      data.aligner_case.advanced_modules = {
        ...data.aligner_case.advanced_modules,
        version: 2,
      };
    }

    updateAlignerCase(doctorId, caseId, JSON.stringify(data))
      .then((res) => {
        //this.props.NextStep();
        this.props.GetAlignerCaseById(true);
        setSubmitting(false);
        if (this.props.rejectable_id) {
          this.props.FixAlignerCase();
        } else {
          this.props.ShowAdvancedModule(values.use_advanced);
          this.props.updatePrescription(reducer_data);
        }
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };
  render() {
    const { block_case_submission } = this.props.userReducer.currentUser;
    const { prescription } = this.props.caseSubmission;
    const { content } = this.props.languageReducer;
    const { patient_info } = this.props.caseReducer.case;
    const treat_arches = [
      { name: "upper_only", title: content.upper },
      { name: "lower_only", title: content.lower },
      { name: "both", title: content.upper_and_lower },
    ];
    const wear_cycle_options = [
      { name: "express", title: content.one_week },
      { name: "regular", title: content.two_week },
    ];
    return (
      <div className="Prescription-bite-record-inner-cont">
        {/* {!this.props.rejectable_id && <div className="patient-info-header"> <CustomeHeader title={content.prescription} />
          <span className="patient_info">
            <div>{this.props.patient?.full_name}</div>
            <div> {content.case} {this.state?.caseId}</div>
          </span>
        </div>} */}

        {window.innerWidth > 839 && !this.props.rejectable_id ? (
          <div className="patient-info-header">
            <CustomeHeader title={content.prescription} />
            <span className="patient_info callout-text">
              <div>{patient_info?.full_name}</div>
              <div>
                {" "}
                {content.case} {this.state?.caseId}
              </div>
            </span>
          </div>
        ) : (
          <h1 className="head-mobile-caseSubmission title-3-text">
            {content.prescription}
          </h1>
        )}

        <Formik
          enableReinitialize
          initialValues={{
            chief_complaint: prescription.chief_complaint,
            summary_and_special_instructions:
              prescription.summary_and_special_instructions || "",
            treat_arches: prescription.treat_arches,
            wear_cycle: prescription.wear_cycle,
            use_advanced: this.state.use_advanced,
          }}
          validationSchema={schema}
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <CardBody className=" patient-info-container">
                <div className="prescription_grid">
                  {/* <div   className="prescription-mobile-order"> */}
                  <div className="prescription-mobile-order-inner1">
                    <FormGroup>
                      <Label className="custom-title colorful title-3-text">
                        {content.chief_complaint}
                      </Label>
                      <Input
                        name="chief_complaint"
                        id="chief_complaint"
                        type="textarea"
                        className="custome-textarea2 custome-input-text"
                        rows={3}
                        defaultValue={values.chief_complaint}
                        onChange={(e) => {
                          setFieldValue("chief_complaint", e.target.value);
                        }}
                      />{" "}
                      <ErrorMessage
                        component="div"
                        name="chief_complaint"
                        className="custom-invalid-feedback mt-1 callout-text"
                      />
                    </FormGroup>
                  </div>

                  <div className="prescription-mobile-order-inner2">
                    <FormGroup>
                      <Label className="custom-title colorful title-3-text">
                        {content.treatment_summay}
                      </Label>
                      <Input
                        name="summary_and_special_instructions"
                        id="summary_and_special_instructions"
                        type="textarea"
                        className="custome-textarea2 custome-input-text"
                        rows={3}
                        defaultValue={values.summary_and_special_instructions}
                        onChange={(e) => {
                          setFieldValue(
                            "summary_and_special_instructions",
                            e.target.value
                          );
                        }}
                      />{" "}
                      <ErrorMessage
                        component="div"
                        name="summary_and_special_instructions"
                        className="custom-invalid-feedback mt-1 callout-text"
                      />
                    </FormGroup>
                  </div>
                  <div className="prescription_inner_grid cmt3">
                    <FormGroup>
                      <Label className="custom-title colorful title-3-text">
                        {content.arch}
                      </Label>

                      <CustomRadioGroup
                        name="treat_arches"
                        checked={values.treat_arches}
                        onChange={(value) => {
                          setFieldValue("treat_arches", value);
                        }}
                        options={treat_arches}
                        id="treat_arches"
                      />

                      <ErrorMessage
                        component="div"
                        name="treat_arches"
                        className="custom-invalid-feedback mt-1 callout-text"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label className="custom-title colorful title-3-text">
                        {content.wear_cycle}
                      </Label>

                      <CustomRadioGroup
                        name="wear_cycle"
                        checked={values.wear_cycle}
                        onChange={(value) => {
                          setFieldValue("wear_cycle", value);
                        }}
                        options={wear_cycle_options}
                        id="wear_cycle"
                      />
                      <ErrorMessage
                        component="div"
                        name="wear_cycle"
                        className="custom-invalid-feedback mt-1 callout-text"
                      />
                    </FormGroup>
                  </div>
                  {/* </div> */}

                  {/* <div className="prescription-mobile-order-inner2">
                    <FormGroup>
                      <Label className="custom-title colorful">
                        {content.treatment_summay}
                      </Label>
                      <Input
                        name="summary_and_special_instructions"
                        id="summary_and_special_instructions"
                        type="textarea"
                        className="custome-textarea2"
                        rows={3}
                        defaultValue={values.summary_and_special_instructions}
                        onChange={(e) => {
                          setFieldValue(
                            "summary_and_special_instructions",
                            e.target.value
                          );
                        }}
                      />{" "}
                      <ErrorMessage
                        component="div"
                        name="summary_and_special_instructions"
                        className="custom-invalid-feedback mt-1"
                      />
                    </FormGroup>
                    </div> */}
                  {!this.state.isNewAdvancedModules && (
                    <div>
                      <FormGroup className="cmt3">
                        <Label className="custom-title colorful title-3-text">
                          {content.teeth_movement_restrictions}
                          <span className="optional_color title-3-text">
                            {" "}
                            ({content.optional})
                          </span>
                        </Label>
                        <div className="teeth_selector">
                          {" "}
                          <CustomeTeeth action_key="Case_Tooth_Movement_Restrictions" />
                        </div>
                      </FormGroup>
                    </div>
                  )}
                </div>
                {this.props.rejectable_id && (
                  <Button
                    className={`btn light-green-btn float-right  submission-btn button-seme-bold-text  displayNone_inmobile`}
                    size="sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {content.fix}
                  </Button>
                )}

                {!this.props.rejectable_id &&
                  (this.state.isNewAdvancedModules ? (
                    <AdvancedModulesNew
                      onChange={this.onAdvancedModulesChange}
                    />
                  ) : (
                    <div className="cmy5 ">
                      <AdvancedModulesCopy
                        doctorId={this.props?.doctorId}
                        caseId={this.props?.caseId}
                        patient={patient_info}
                        mappedData={this.props.caseReducer.case}
                      />
                    </div>
                  ))}
              </CardBody>

              {!block_case_submission && (
                <CardFooter className="p-3 container-footer-mobile">
                  {this.props.rejectable_id ? (
                    <Button
                      className={`btn light-green-btn float-right  submission-btn button-seme-bold-text displayNone`}
                      size="sm"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {content.fix}
                    </Button>
                  ) : (
                    <>
                      <div className="order-save-button inmobile">
                        <Button
                          className={`btn light-green-btn float-right   button-seme-bold-text submission-btn `}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {content.next}{" "}
                          <i className="fas fa-angle-right pl-1"></i>
                        </Button>
                      </div>

                      <Button
                        className={`btn white-btn float-left submission-btn button-seme-bold-text custom-mb`}
                        size="sm"
                        type="button"
                        disabled={isSubmitting}
                        onClick={() => this.props.PrevStep()}
                      >
                        <i className="fas fa-angle-left pr-1"></i>{" "}
                        {content.previous}
                      </Button>
                      <div className="order-save-button inWebsit">
                        <Button
                          className={`btn light-green-btn float-right  submission-btn button-seme-bold-text `}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {content.next}{" "}
                          <i className="fas fa-angle-right pl-1"></i>
                        </Button>
                      </div>
                    </>
                  )}
                </CardFooter>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Prescription);
