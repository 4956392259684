import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import { caseType } from "../../../helpers/caseType";
const styles = StyleSheet.create({
  row: {
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center",
  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    width: "100%",
  },
  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#E6E6FA",
    paddingBottom: 15,
    paddingTop: 5,
  },
  field: {
    flexDirection: "row",
    paddingTop: 10,
  },
  col: {
    flexDirection: "column",
    width: "50%",
  },
  title: {
    fontSize: 10,
    textAlign: "left",
    color: "#00c7b1",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 15,
    width: "90px",
    height: "80px",
    alignItems: "center",
    borderRadius: 4,
  },
});

export default class PatientInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      caseInfo: props.case,
      load: true,
    };
  }
  render() {
    const { caseInfo } = this.state;
    const { content } = this.props

    return (
      <Fragment>
        <View style={styles.row}>
          <Text style={styles.header}>{content.patient_Information} :</Text>
          <View style={styles.container}>
            <View style={styles.col}>
              {this.state.load && (
                <Image
                  style={styles.image}
                  source={{
                    uri: caseInfo.patient_info.photo,
                    headers: {
                      Pragma: "no-cache",
                      "Cache-Control": "no-cache",
                    },
                  }}
                />
              )}
            </View>
            <View style={styles.col}>
              <View style={styles.field}>
                <Text style={styles.title}>{content.name} : </Text>
                <Text style={styles.info}>{caseInfo.patient_info.full_name}</Text>
              </View>
              <View style={styles.field}>
                <Text style={styles.title}>{content.case_type} : </Text>
                <Text style={styles.info}>
                  {caseType[caseInfo.case_type] || caseInfo.case_type}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Fragment>
    );
  }
}
