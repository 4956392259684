import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import hybrid from "../../../assets/images/new_pdf_images/hybrid.jpg";
import aligner from "../../../assets/images/new_pdf_images/aligner.jpg";
const styles = StyleSheet.create({
  row: {
    paddingTop: 15,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingBottom: 10,
  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00a78c",
    width: "100%",
  },
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomWidth: 1,
    borderColor: "#E6E6FA",
    paddingBottom: 15,
    paddingTop: 10,
  },
  container2: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 5,
  },
  field: {
    flexDirection: "row",
    paddingTop: 10,
  },
  col: {
    flexDirection: "column",
    width: "50%",
  },
  col_4: {
    flexDirection: "column",
    width: "40%",
  },
  col_5: {
    flexDirection: "column",
    width: "50%",
  },
  col_2:{
    flexDirection: "column",
    width: "20%",
  },
  col_1: {
    flexDirection: "column",
    width: "10%",
  },
  col_3: {
    flexDirection: "column",
    width: "30%",
  },
  col_7: {
    flexDirection: "column",
    width: "70%",
  },
  col_15: {
    flexDirection: "column",
    width: "15%",
  },
  title: {
    fontSize: 10,
    textAlign: "left",
    color: "#00a78c",
    padding: "10px 0 5px 0",
  },
  title2: {
    fontSize: 10,
    textAlign: "left",
    color: "#00a78c",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
  image: {
    // backgroundColor: "red",
    borderRadius: 4,
  },

  green_btn_not_active: {
    width: "100px",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#d7f5f2",
    borderWidth: 1,
    backgroundColor: "white",
    borderRadius: 4,
    fontSize: 8,
    padding: 5,
    margin: 3,
    flexDirection: "column",
  },
  hybrid_green_btn_not_active: {
    width: "auto",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#d7f5f2",
    borderWidth: 1,
    backgroundColor: "white",
    borderRadius: 4,
    fontSize: 8,
    padding: 5,
    margin: 3,
    flexDirection: "column",
  },
  col_title: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    fontSize: 8,
    padding: "0 5px 0 0",
    // margin: 1,
    flexDirection: "column",
    width: "10%",
  },
  green_btn_active: {
    width: "100px",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00a78c",
    borderRadius: 4,
    fontSize: 8,
    padding: 5,
    margin: 3,
    flexDirection: "column",
  },
  hybrid_green_btn_active: {
    width: "auto",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00a78c",
    borderRadius: 4,
    fontSize: 8,
    padding: 5,
    margin: 3,
    flexDirection: "column",
  },
  emplty_col: {
    width: "30%",
  },
  text_active: {
    fontSize: 8,
    color: "white",
    padding: 1,
  },
  text_not_active: {
    fontSize: 8,
    color: "#00a78c",
    padding: 1,
  },
  teeth_border: {
    borderWidth: 1,
    borderColor: "#00a78c",
  },
  text_area: {
    flexGrow: 1,
    borderColor: "#a0f0e7",
    borderWidth: 1,
    borderRadius: 2,
    fontSize: 8,
    padding: 2,
    margin: 3,
    minHeight: "50px",
    flexDirection: "column",
  },
  photo_header: {
    flexDirection: "column",
    width: "100px",
    margin: 5,
    fontSize: 8,
    color: "#00a78c",
    textAlign: "center",
  },
  value: {
    padding: "10px",
  },
  photo: {
    flexDirection: "column",
    width: "100px",
    height: "auto",
    margin: 5,
    zIndex: 99,
  },
  space: {
    flexDirection: "column",
    width: "10%",
  },
  active_card: {
    flexDirection: "column",
    width: "100px",
    height: "auto",
    margin: 5,
  },
  card: {
    flexDirection: "column",
    width: "100px",
    height: "auto",
    margin: 5,
    backgroundColor: "white",
  },
  hybrid: {
    flexDirection: "column",
    width: "90%",
    height: "auto",
    padding: "50px",
  },
  hybrid_header: {
    flexDirection: "column",
    width: "100%",
    margin: 5,
    fontSize: 8,
    color: "#00a78c",
    textAlign: "left",
    borderBottomWidth: 1,
    borderColor: "#00a78c",
    paddingBottom: 10,
  },
  buttons_container: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    paddingBottom: 5,
  },
  hybrid_subTitle: {
    fontSize: 8,
    color: "#00a78c",
    width: "30px",
    flexDirection: "column",
    margin: 5,
  },
});

export default class TreatmentType extends Component {
  render() {
    const { treatment_type } = this.props.mappedData?.advanced_modules;
    const { content } = this.props

    return (
      <Fragment>
        <View style={styles.row}>
          <Text style={styles.header}>{content.treatment_type}</Text>
          <View style={styles.container}>
            <View style={styles.col_3}>
              <View
                style={`${treatment_type.type === "aligner"
                  ? styles.active_card
                  : styles.card
                  }`}
              >
                <Image
                  style={styles.photo}
                  src={`${treatment_type.type === "aligner" ? aligner : hybrid
                    }`}
                />
                <Text style={styles.photo_header}>
                  {`${treatment_type.type === "aligner"
                    ? content.aligner_treatment
                    : content.hybrid_treatment
                    }`}
                </Text>
              </View>
            </View>
            {/* <View style={styles.space}></View> */}

            <View style={styles.col_7}>
              {treatment_type.type === "hybrid" && (
                <View>
                  <Text style={styles.hybrid_header}>{content.hybrid_treatment}</Text>
                  <View style={styles.buttons_container}>
                    <View
                      style={
                        treatment_type.hybrid_treatment.type ===
                          "Braces before aligners"
                          ? styles.green_btn_active
                          : styles.green_btn_not_active
                      }
                    >
                      <Text
                        style={
                          treatment_type.hybrid_treatment.type ===
                            "Braces before aligners"
                            ? styles.text_active
                            : styles.text_not_active
                        }
                      >
                       {content.braces_before_aligners}
                      </Text>
                    </View>

                    <View
                      style={
                        treatment_type.hybrid_treatment.type ===
                          "Braces + aligners together"
                          ? styles.green_btn_active
                          : styles.green_btn_not_active
                      }
                    >
                      <Text
                        style={
                          treatment_type.hybrid_treatment.type ===
                            "Braces + aligners together"
                            ? styles.text_active
                            : styles.text_not_active
                        }
                      >
                        {content.Braces_aligners_together}
                      </Text>
                    </View>
                  </View>

                  {
                    treatment_type.hybrid_treatment.type ===
                    "Braces + aligners together" &&
                    <View>
                       <View style={styles.container2}>
                      <View style={styles.col_15}></View>
                      <View style={styles.col_1}>
                        <Text style={styles.hybrid_subTitle}>{content.Aligners}</Text>
                      </View>
                      <View style={styles.col_3}>
                        <View
                          style={
                            treatment_type.hybrid_treatment.aligners === "upper"
                              ? styles.hybrid_green_btn_active
                              : styles.hybrid_green_btn_not_active
                          }
                        >
                          <Text
                            style={
                              treatment_type.hybrid_treatment.aligners === "upper"
                                ? styles.text_active
                                : styles.text_not_active
                            }
                          >
                            {content.upper}
                        </Text>
                        </View>
                      </View>

                      <View style={styles.col_3}>
                        <View
                          style={
                            treatment_type.hybrid_treatment.aligners === "lower"
                              ? styles.hybrid_green_btn_active
                              : styles.hybrid_green_btn_not_active
                          }
                        >
                          <Text
                            style={
                              treatment_type.hybrid_treatment.aligners === "lower"
                                ? styles.text_active
                                : styles.text_not_active
                            }
                          >
                          {content.lower}
                        </Text>
                        </View>
                      </View>

                      <View style={styles.col_15}></View>
                    </View>

                      <View style={styles.container2}>
                        <View style={styles.col_15}></View>

                        <View style={styles.col_1}>
                          <Text style={styles.hybrid_subTitle}>{content.braces}</Text>
                        </View>

                        <View style={styles.col_3}>
                          <View
                            style={
                              treatment_type.hybrid_treatment.braces === "upper"
                                ? styles.hybrid_green_btn_active
                                : styles.hybrid_green_btn_not_active
                            }
                          >
                            <Text
                              style={
                                treatment_type.hybrid_treatment.braces === "upper"
                                  ? styles.text_active
                                  : styles.text_not_active
                              }
                            >
                              {content.upper}
                        </Text>
                          </View>
                        </View>
                        <View style={styles.col_3}>
                          <View
                            style={
                              treatment_type.hybrid_treatment.braces === "lower"
                                ? styles.hybrid_green_btn_active
                                : styles.hybrid_green_btn_not_active
                            }
                          >
                            <Text
                              style={
                                treatment_type.hybrid_treatment.braces === "lower"
                                  ? styles.text_active
                                  : styles.text_not_active
                              }
                            >
                              {content.lower}
                        </Text>
                          </View>
                        </View>

                        <View style={styles.col_15}></View>
                      </View>
                    </View>
                  }


                  {
                    treatment_type.hybrid_treatment.type ===
                    "Braces before aligners" &&
                       <View style={styles.container2}>
                      <View style={styles.col_1}></View>
                      <View style={styles.col_1}>
                        <Text style={styles.hybrid_subTitle}>{content.aligners}</Text>
                      </View>
                      <View style={styles.col_2}>
                        <View
                          style={
                            treatment_type.hybrid_treatment.aligners === "upper"
                              ? styles.hybrid_green_btn_active
                              : styles.hybrid_green_btn_not_active
                          }
                        >
                          <Text
                            style={
                              treatment_type.hybrid_treatment.aligners === "upper"
                                ? styles.text_active
                                : styles.text_not_active
                            }
                          >
                            {content.upper}
                        </Text>
                        </View>
                      </View>

                      <View style={styles.col_2}>
                        <View
                          style={
                            treatment_type.hybrid_treatment.aligners === "lower"
                              ? styles.hybrid_green_btn_active
                              : styles.hybrid_green_btn_not_active
                          }
                        >
                          <Text
                            style={
                              treatment_type.hybrid_treatment.aligners === "lower"
                                ? styles.text_active
                                : styles.text_not_active
                            }
                          >
                              {content.lower}
                        </Text>
                        </View>
                      </View>

                      <View style={styles.col_2}>
                        <View
                          style={
                            treatment_type.hybrid_treatment.aligners === "both"
                              ? styles.hybrid_green_btn_active
                              : styles.hybrid_green_btn_not_active
                          }
                        >
                          <Text
                            style={
                              treatment_type.hybrid_treatment.aligners === "both"
                                ? styles.text_active
                                : styles.text_not_active
                            }
                          >
                            {content.both}
                        </Text>
                        </View>
                      </View>


                    </View>
                  }


                </View>
              )}
            </View>
          </View>
        </View>
      </Fragment>
    );
  }
}
