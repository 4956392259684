import { getAlignerCase } from "../../api/api";
import { newMapDataWithReducer } from "../../services/newDataMapper";
import {
  CASE_BY_ID,
  UPDATE_PHOTOS_XRAYS,
  UPDATE_IMPRESSIONS,
  UPDATE_PRESCRIPTION,
  UPDATE_CASE,
  CURRENT_CASE,
  UPDATE_ALL_ADVANCED_MODULES,
  UPDATE_PATIENT_INFO,
  ALL_MODULES,
  CASE_TOOTH_MOVEMENT_RESTRICTIONS,
  CASE_EXTRACTION,
  CASE_ATTACHMENTS,
  CASE_IPR,
  CASE_PONTICS,
  CASE_OVERCORRECTION,
  CASE_TORQUE_ENHANCERS,
  CASE_ARCH_EXPANSION,
  CASE_ELASTICS,
  CASE_ELASTICS_BUTTONS,
  CASE_ELASTICS_CUTS,
  CASE_CROSSBITES,
  CASE_CROWDING,
  CASE_CLASSII,
  CASE_CLASSIII,
} from "../constants/";

export function getCaseById(payload) {
  return async (dispatch) => {
    function onSuccess(response) {
      if (
        payload.hybrid &&
        response.display_status === "Converted awaiting new impressions"
      ) {
        response["case_type"] = "";
      }
      let data = newMapDataWithReducer(
        response,
        payload.hybrid,
        payload.gco_doctor
      );

      dispatch({
        type: CASE_BY_ID,
        payload: data,
        error: null,
      });

      if (payload.case_submission) {
        dispatch({
          type: UPDATE_PHOTOS_XRAYS,
          payload: data.photos_xrays,
        });
        dispatch({
          type: UPDATE_IMPRESSIONS,
          payload: data.impressions_new,
        });
        dispatch({
          type: UPDATE_PRESCRIPTION,
          payload: data.prescription,
        });
        dispatch({
          type: UPDATE_ALL_ADVANCED_MODULES,
          payload: data.advanced_modules,
        });
        dispatch({
          type: UPDATE_PATIENT_INFO,
          payload: data.patient_info,
        });
        //CASE_TOOTH_MOVEMENT_RESTRICTIONS
        dispatch({
          type: CASE_TOOTH_MOVEMENT_RESTRICTIONS,
          payload: data.prescription.restriction,
        });

        if (!data.isNewAdvancedModules) {
          dispatch({
            type: ALL_MODULES,
            payload: data.advanced_modules.all_modules,
          });
          dispatch({
            type: CASE_EXTRACTION,
            payload: data.advanced_modules.extraction.teeth,
          });
          dispatch({
            type: CASE_ATTACHMENTS,
            payload: data.advanced_modules.attachments.attachmentsRestriction,
          });

          dispatch({
            type: CASE_IPR,
            payload: data.advanced_modules.ipr.iprRestriction,
          });

          dispatch({
            type: CASE_PONTICS,
            payload: data.advanced_modules.pontics.teeth,
          });

          dispatch({
            type: CASE_OVERCORRECTION,
            payload: data.advanced_modules.overCorrection.teeth,
          });

          dispatch({
            type: CASE_TORQUE_ENHANCERS,
            payload: data.advanced_modules.torqueEnhancers.teeth,
          });

          dispatch({
            type: CASE_ARCH_EXPANSION,
            payload: data.advanced_modules.archExpansion.teeth,
          });

          dispatch({
            type: CASE_ELASTICS,
            payload: data.advanced_modules.elastics.teeth,
          });

          dispatch({
            type: CASE_ELASTICS_BUTTONS,
            payload: data.advanced_modules.elastics.buttons_teeth,
          });
          dispatch({
            type: CASE_ELASTICS_CUTS,
            payload: data.advanced_modules.elastics.cuts_teeth,
          });

          dispatch({
            type: CASE_CROSSBITES,
            payload: data.advanced_modules.malocclusion.crossbite.teeth,
          });

          dispatch({
            type: CASE_CROWDING,
            payload: data.advanced_modules.malocclusion.crowding.teeth,
          });

          dispatch({
            type: CASE_CLASSII,
            payload: data.advanced_modules.malocclusion.classII.teeth,
          });

          dispatch({
            type: CASE_CLASSIII,
            payload: data.advanced_modules.malocclusion.classIII.teeth,
          });
        }
      }
      return response;
    }

    function onError(error) {
      dispatch({
        type: CASE_BY_ID,
        payload: {},
        error: error,
      });
      return error;
    }
    try {
      const success = await getAlignerCase(payload.doctor_id, payload.case_id);
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
}
