import React from "react";
import { connect } from "react-redux";
import check from "../../../assets/images/icon/check.svg";
import { Button } from "reactstrap";
import TreatmentProtocols from "../TSViewer/TreatmentProtocols/TreatmentProtocols";
import ReasonsRejection from "../TSViewer/ReasonsRejection/ReasonsRejection";
import NewViewerDetails from "../TSViewer/NewViewerDetails";
import Summary from "../TSViewer/Summary";
import { checkIPR, checkMovement } from "../../../services/checkChanges";
import { useState } from "react";
import { setVisitedStep } from "../../../redux/Tooth_IPR_Options/action";
import { ReactComponent as Close } from "../../../assets/images/Ts-navBar/close.svg";

export const ReviseTS = (props) => {
  const { content } = props.languageReducer;
  const {
    bite_check_changed,
    teeth_movement_changed,
    ipr_changed,
    treatment_protocol_changed,
    show_Tooth_Movement,
    bite_check_step_visited,
    movement_step_visited,
    ipr_step_visited,
    submit_step_visited,
    saved_data,
    teeth_ipr_data,
    toggleApprove,
  } = props.teethIPROptions;
  const { dataChanged } = props.changesReducer;
  const {
    disapproving_data,
    disapproved_successfully,
    toggle,
    mapDataToAPI,
    setShowUnsavedModal,
    cancelChangesOnToothMovement,
    setDynamicState,
    showBiteRecord,
    showIPRData,
    current_ipr_tooth,
    current_ipr_position,
    doctorId,
    details,
    GetAlignerCase,
    GetTreatmentSetups,
    GetTretmentSetupById,
    ToggleBitsRecord,
    data,
    showToothMovement,
    showIPRToothMovement,
    finish_loading_ts,
    mapped_advanced_modules,
    ToggleOptions,
    savedStep,
    clearStateToLeave2,
    step,
    showActions,
  } = props;

  const Step = (props) => (
    <div
      className={`Stepper__step ${
        step > props.indicator && props.visited && props.pervVisited
          ? "done-stepper"
          : ""
      } `}
      onClick={() => {
        if (dataChanged) {
          setDynamicState({ step: props.indicator });
          setShowUnsavedModal(true);
        } else {
          onChangeStep(props.indicator);
          if (props.indicator > 1) {
            setVisitedStep(props.ReduxKey);
          }
        }
        if (show_Tooth_Movement && !dataChanged) {
          cancelChangesOnToothMovement();
        }
      }}
    >
      <div
        className={`${step === props.indicator ? "current-step_stepper" : ""} ${
          step > props.indicator && props.visited ? "done-step_stepper" : ""
        }`}
      >
        <div
          className={
            props.updated === true ? "Stepper__edit" : "Stepper__edit_empty"
          }
        >
          {props.updated ? "Edited" : "edited"}{" "}
        </div>
        <div className="Stepper__indicator">
          <span className="Stepper__info">
            <div className="step_indicator"></div>
          </span>
        </div>
        <div className="Stepper__label">{props.title}</div>
        <div className="Stepper__panel">{props.children}</div>
      </div>
    </div>
  );
  // Overlay
  const Stepper = (props) => (
    <div
      className={`Stepper ${props.isVertical ? "Stepper--vertical" : ""} ${
        props.isInline ? "Stepper--inline" : ""
      }`}
    >
      {props.children}
    </div>
  );
  const onChangeStep = (step) => {
    setDynamicState({ step: step });
  };
  /* --------------------------------------------------------------------------------*/
  /* set Visited Step  */
  /* --------------------------------------------------------------------------------*/
  const setVisitedStepFun = (step) => {
    if (step === 2) {
      props.setVisitedStep("bite_check_step_visited");
    }
    if (step === 3) {
      props.setVisitedStep("movement_step_visited");
    }
    if (step === 4) {
      props.setVisitedStep("ipr_step_visited");
    }

    if (step === 5) {
      props.setVisitedStep("submit_step_visited");
    }
  };
  return (
    <div className="tp_details ">
      <div className="menu-title">
        <div>{content.Revise_Treatment}</div>

        <Close
          className="cursor-pointer"
          onClick={() => {
            if (
              dataChanged ||
              bite_check_changed ||
              teeth_movement_changed ||
              ipr_changed ||
              treatment_protocol_changed
            ) {
              setDynamicState({
                showConfirmationUnsavedDataModal2: true,
                savedState: "",
                active_side_tab: "1",
                activeTab: "1",
              });
            } else {
              clearStateToLeave2();
              setDynamicState({
                showBiteRecord: !showBiteRecord,
                active_side_tab: "1",
                activeTab: "1",
              });
              showActions();
            }
            if (show_Tooth_Movement && !dataChanged) {
              cancelChangesOnToothMovement();
            }
          }}
        />
      </div>
      <div className="ts-stepper">
        {!disapproved_successfully ? (
          <>
           <TreatmentProtocols />
           <div>
           <Button
             disabled={
       
                 (!treatment_protocol_changed)
             }
             className={`btn  green-green-btn button-seme-bold-text`}
             onClick={async () => {
              mapDataToAPI();

               //setVisitedStepFun(5);
             }}
           >
             {content.submit_revision}{" "}
             <i className="fas fa-angle-right pl-1"></i>
           </Button>
         </div>
         </>
          // <div>
          //   <Stepper>
          //     <Step
          //       indicator={1}
          //       title={content.revision_notes}
          //       updated={treatment_protocol_changed}
          //       onClick={() => {
          //         onChangeStep(1);
          //       }}
          //       pervVisited={bite_check_step_visited}
          //       ReduxKey={"protocol_step_visited"}
          //       // visited={this.props.teethIPROptions.protocol_step_visited}
          //       visited={true}
          //     >
          //       <div className="Content">
          //         <button>Prev</button>
          //         <button>Next</button>
          //       </div>
          //     </Step>
          //     <Step
          //       indicator={2}
          //       title={content.bite_check}
          //       updated={bite_check_changed}
          //       onClick={() => {
          //         onChangeStep(2);
          //       }}
          //       pervVisited={movement_step_visited}
          //       ReduxKey={"bite_check_step_visited"}
          //       visited={bite_check_step_visited}
          //     >
          //       <div className="Content">
          //         <button>Prev</button>
          //         <button>Next</button>
          //       </div>
          //     </Step>
          //     <Step
          //       indicator={3}
          //       title={content.teeth_movement}
          //       updated={checkMovement(saved_data, teeth_ipr_data)}
          //       onClick={() => {
          //         onChangeStep(3);
          //       }}
          //       pervVisited={movement_step_visited && ipr_step_visited}
          //       ReduxKey={"movement_step_visited"}
          //       visited={movement_step_visited}
          //     >
          //       <div className="Content">
          //         <button>Prev</button>
          //         <button>Next</button>
          //       </div>
          //     </Step>
          //     <Step
          //       indicator={4}
          //       title={content.ipr}
          //       updated={checkIPR(saved_data, teeth_ipr_data)}
          //       onClick={() => {
          //         onChangeStep(4);
          //       }}
          //       pervVisited={ipr_step_visited && submit_step_visited}
          //       ReduxKey={"ipr_step_visited"}
          //       visited={ipr_step_visited}
          //     >
          //       <div className="Content">
          //         <button>Prev</button>
          //         <button>Next</button>
          //       </div>
          //     </Step>

          //     <Step
          //       indicator={5}
          //       title={content.review_and_submit}
          //       updated={false}
          //       onClick={() => {
          //         onChangeStep(5);
          //       }}
          //       pervVisited={ipr_step_visited && submit_step_visited}
          //       ReduxKey={"submit_step_visited"}
          //       visited={submit_step_visited}
          //     >
          //       <div className="Content">
          //         <button>Prev</button>
          //         <button>Next</button>
          //       </div>
          //     </Step>
          //   </Stepper>
          //   <div className="ts-steps">
          //     {step === 1 && (
          //       <div>
          //         {/* <TreatmentProtocols /> */}
          //       </div>
          //     )}
          //     {step === 2 && (
          //       <div>
          //         <ReasonsRejection />
          //       </div>
          //     )}

          //     {step === 3 && (
          //       <div>
          //         <NewViewerDetails
          //           doctorId={doctorId}
          //           data={props.data}
          //           details={details}
          //           props={data}
          //           GetAlignerCase={GetAlignerCase}
          //           ToggleBitsRecord={ToggleBitsRecord}
          //           mapped_advanced_modules={mapped_advanced_modules}
          //           showToothIPROptions={true}
          //           ToggleOptions={ToggleOptions}
          //           finish_loading_ts={finish_loading_ts}
          //           GetAllTs={GetTreatmentSetups}
          //           GetTretmentSetupById={GetTretmentSetupById}
          //           showTeethMovement={true}
          //           showToothMovement={showToothMovement}
          //           showIPRToothMovement={showIPRToothMovement}
          //           current_ipr_position={current_ipr_position}
          //           current_ipr_tooth={current_ipr_tooth}
          //           showIPRData={showIPRData}
          //           showBiteRecord={showBiteRecord}
          //         />
          //       </div>
          //     )}

          //     {step === 4 && (
          //       <div>
          //         <NewViewerDetails
          //           doctorId={doctorId}
          //           data={props.data}
          //           details={details}
          //           props={data}
          //           GetAlignerCase={GetAlignerCase}
          //           ToggleBitsRecord={ToggleBitsRecord}
          //           mapped_advanced_modules={mapped_advanced_modules}
          //           showToothIPROptions={true}
          //           ToggleOptions={ToggleOptions}
          //           finish_loading_ts={finish_loading_ts}
          //           GetAllTs={GetTreatmentSetups}
          //           GetTretmentSetupById={GetTretmentSetupById}
          //           showIPRMovement={true}
          //           showToothMovement={showToothMovement}
          //           showIPRToothMovement={showIPRToothMovement}
          //           current_ipr_position={current_ipr_position}
          //           current_ipr_tooth={current_ipr_tooth}
          //           showIPRData={showIPRData}
          //           showBiteRecord={showBiteRecord}
          //         />
          //       </div>
          //     )}

          //     {step === 5 && <Summary />}
          //   </div>
          //   <hr className="line"></hr>
          //   <div className="ts-stepper-actions">
          //     <div></div>
          //     <div>
          //       {step > 1 && (
          //         <Button
          //           disabled={disapproving_data}
          //           className={`btn  white-dark-green-btn float-right button-seme-bold-text`}
          //           onClick={() => {
          //             if (
          //               dataChanged &&
          //               (step === 2 || step === 3 || step === 4)
          //             ) {
          //                setDynamicState({ backClicked: true });
          //               setShowUnsavedModal(true);
          //             } else {
          //               setDynamicState({ step: step - 1 });
          //             }
          //           }}
          //         >
          //           <i className="fas fa-angle-left  pr-1"></i>
          //           {content.back}
          //         </Button>
          //       )}
          //     </div>

          //     <div>
          //       {" "}
          //       {step < 5 && (
          //         <Button
          //           disabled={disapproving_data}
          //           className={`btn  white-dark-green-btn float-left button-seme-bold-text`}
          //           onClick={() => {
          //             if (step === 5) {
          //               mapDataToAPI();
          //             } else {
          //               if (
          //                 dataChanged &&
          //                 (step === 2 || step === 3 || step === 4)
          //               ) {
          //                 //setDynamicState({ step: step + 1 });

          //                 setShowUnsavedModal(true);
          //               } else {
          //                 setDynamicState({ step: step + 1 });
          //               }
          //             }
          //             // setDynamicState({ step: step + 1 });
          //             // setVisitedStepFun(step + 1);
          //           }}
          //         >
          //           {content.next} <i className="fas fa-angle-right pl-1"></i>
          //         </Button>
          //       )}
          //     </div>
        //   <div>
        //   <Button
        //     disabled={
        //       disapproving_data ||
        //       (!bite_check_changed &&
        //         !teeth_movement_changed &&
        //         !ipr_changed &&
        //         !treatment_protocol_changed)
        //     }
        //     className={`btn  green-green-btn button-seme-bold-text`}
        //     onClick={async () => {
        //       if (step === 5) {
        //         mapDataToAPI();
        //       } else {
        //         if (dataChanged && (step === 3 || step === 4)) {
        //           // setDynamicState({ step: 5 });
        //           setShowUnsavedModal(true);
        //         } else {
        //           setDynamicState({ step: 5 });
        //         }
        //       }

        //       //setVisitedStepFun(5);
        //     }}
        //   >
        //     {content.submit_revision}{" "}
        //     <i className="fas fa-angle-right pl-1"></i>
        //   </Button>
        // </div>
            // </div>
          // </div>
          
        ) : (
          <div>
            <div className="submitted_grid">
              <div className="case-submitted">
                <img src={check} alt="case-submitted" />{" "}
                <div>{content.revised_data_successfully}</div>{" "}
              </div>
            </div>
            <hr className="line"></hr>
            <Button
              className={`btn  green-green-btn pull-right exit-button button-seme-bold-text `}
              onClick={toggle}
            >
              {content.exit}{" "}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  setVisitedStep: (payload) => dispatch(setVisitedStep(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviseTS);
