import React, { Component } from "react";
import { CardFooter, Button } from "reactstrap";
import { connect } from "react-redux";

import check from "../../assets/images/icon/check.svg";
import eonAccessLogo from "../../assets/images/eonAccessLogo.png";
import file_icon from "../../assets/images/caseActions/file.svg";
import review_cases from "../../assets/images/dashboard/review_case.svg";
import { WrappedDoctorCasePdf } from "@eon-dental/advanced-modules/dist/rollup-export";
import DownloadPdf from "../newPDF/DownloadPdf";
import { GetCountryLabel } from "../../helpers/countries";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
class Submit extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      id: props.userReducer.currentUser.id,
    };
  }

  render() {
    const { content } = this.props.languageReducer;
    const {
      id,
      uid,
      case_type,
      prescription,
      patient_info,
      isNewAdvancedModules,
    } = this.props.caseReducer.case;

    const ArchMapper = {
      upper_only: "Upper",
      lower_only: "Lower",
      both: "Upper & Lower",
    };

    const wearCycle = {
      express: "1 Week",
      regular: "2 Weeks",
    };

    const caseSubmission = this.props.caseSubmission;
    return (
      <div>
        <div className="submitted_grid">
          <div>
            {window.innerWidth < 839 && (
              <div className="case-submitted-mobile">
                <img
                  className="case-submitted-mobile-image"
                  src={check}
                  alt="case-submitted"
                />{" "}
                <div className="case-submitted-mobile title-1-text">
                  {content.detailed_case}
                  <br />
                  {content.submission}
                </div>{" "}
              </div>
            )}
            {window.innerWidth > 839 && (
              <div className="case-submitted">
                <img src={check} alt="case-submitted" />{" "}
                <div className="title-1-text">
                  {content.case_submitted_successfully}
                </div>{" "}
              </div>
            )}
            {case_type === "Retainer" ? (
              <div className="submitted_actions_grid2 cmt7">
                <div
                  className="s_card_grid cmb3"
                  onClick={() => {
                    window.location.href = "/cases";
                  }}
                >
                  <div>
                    <img src={review_cases} alt={content.review_cases} />
                  </div>
                  <div className="summary-casedetails subhead-text">
                    {content.review_cases}
                  </div>
                </div>
              </div>
            ) : (
              <div className="submitted_actions_grid cmt7">
                {isNewAdvancedModules ? (
                  <WrappedDoctorCasePdf
                    documentTitle={content.treatment_prescription_form}
                    caseId={id}
                    documentLogoUrl={eonAccessLogo}
                    doctorName={this.props.userReducer.currentUser.full_name}
                    patientName={patient_info.full_name}
                    advancedModules={caseSubmission.advanced_modules}
                    // Case PDF Section
                    address={{
                      ...this.props.caseReducer.case.address,
                      country: {
                        name: GetCountryLabel(
                          this.props.caseReducer.case.address.country
                        ),
                      },
                    }}
                    photos_xrays={caseSubmission.photos_xrays}
                    prescription={{
                      ...caseSubmission.prescription,
                      wear_cycle:
                        wearCycle[caseSubmission.prescription.wear_cycle],
                      treat_arches:
                        ArchMapper[caseSubmission.prescription.treat_arches],
                    }}
                    doctorInfo={{
                      user_data: this.props.userReducer.currentUser,
                    }}
                    patient_info={caseSubmission.patient_info}
                    enableAdvancedModules={true}
                    dentalNotation={
                      this.props.userReducer.currentUser.dental_notation
                    }
                    Button={({ onClick }) => (
                      <div
                        className={"s_card_grid actions-card"}
                        onClick={onClick}
                      >
                        {this.state.loading ? (
                          <div className="submit-loader">
                            <div className="loader"></div>
                          </div>
                        ) : (
                          <img src={file_icon} alt={content.download_pdf} />
                        )}
                        <div className={"summary-casedetails subhead-text"}>
                          {content.download_pdf}
                        </div>
                      </div>
                    )}
                  />
                ) : (
                  <DownloadPdf
                    content={content}
                    case={this.props.caseReducer.case}
                    mappedData={this.props.caseReducer.case}
                    doctorInfo={this.props.doctorInfo}
                    uid={uid}
                  />
                )}
                <div
                  className="s_card_grid actions-card"
                  onClick={() => {
                    window.location.href = "/cases";
                  }}
                >
                  <div>
                    <img src={review_cases} alt={content.review_cases} />
                  </div>
                  <div className="summary-casedetails subhead-text">
                    {content.review_cases}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <CardFooter className="p-3 ">
          <div className="btn-adv-mobile">
            {" "}
            <Button
              className={`btn light-green-btn mobile-but-adv float-right  submission-btn  button-seme-bold-text`}
              size="md"
              color="danger"
              onClick={() => {
                window.location.href = "/home";
              }}
            >
              {content.homepage}
            </Button>
          </div>
        </CardFooter>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Submit);
