import React from "react";
import Iframe from "react-iframe";

export const OldTS = (props) => {
  const {
    tsId,
    doctorId,
    caseId,
    showBiteRecord,
    divided,
    position,
    dental_notation,
    tsHeight,
    viewerId,
    new_converter,
    id,
  } = props.data;
  const handleMessages = (e) => {
    const iframe = e.target;
    iframe.contentWindow.postMessage(
      {
        dental_notation: dental_notation,
      },
      process.env.REACT_APP_PATIENT_VIEWER_URL
    );
  };
  console.log("access");
  return (
    <Iframe
      onLoad={(e) => (new_converter ? null : handleMessages(e))}
      allowFullScreen={true}
      url={
        new_converter
          ? `${process.env.REACT_APP_VIEWER_URL}/?isM=false&treatmentSetupsId=${id}&dn=${dental_notation}`
          : `${process.env.REACT_APP_PATIENT_VIEWER_URL}/&viewerId=${viewerId}&isAccess=true&isDoctor=true&dn=${dental_notation}`
      }
      height="100%"
      width="100%"
      id="myId"
      className="viewer_iframe"
      display="initial"
      position="relative"
    />
  );
};

export default OldTS;
