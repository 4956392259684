import React, { Component } from "react";
import { connect } from "react-redux";

import {
    CardTitle,
    CardFooter,
    Button,
    Card,
    CardImg,
    Input,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { schema, schema2, schema3 } from "../../../helpers/ImpressionsValidations";
import upload_not_active from "../../../assets/images/icon/upload_not_active.svg";
import upload_active from "../../../assets/images/icon/upload_active.svg";
import { upload_impression_data } from "../../../helpers/impressions";
import { CircularProgressbar } from "react-circular-progressbar";
import { uploadFile, keyToUrl } from "../../../helpers/s3";
import { errorToaster } from "../../../services/toast";
import CustomRadioGroup from "../../../components/radioGroup/CustomRadioGroup";
import { updateImpressionsBite } from "../../../redux/biteRecord/action"
const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({
    updateImpressionsBite: (payload) => dispatch(updateImpressionsBite(payload)),
});
class BiteImpressions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorId: props.userReducer.currentUser.id,
            gco_doctor: props.userReducer.currentUser.gco_doctor,
            chose_one: "",
            impressions_method: "",
            loading_percentage: 0,
            intervalID: 0,
            showProgress: false,
            loading_percentage_lower_arch_url: 0,
            loading_percentage_upper_arch_url: 0,
            impressions_third_party_note: "",
            upper_arch_url: "",
            lower_arch_url: "",
            hover_upper_arch: false,
            hover_lower_arch: false,
            caseId: props.caseReducer.case.uid,

        };
        this.lower_arch_ref = React.createRef();
        this.upper_arch_ref = React.createRef();
    }


    handleSubmit = async (values, { setSubmitting }) => {

        let data = {
            impressions_method: values.impressions_method,
            impressions_third_party_note: values.impressions_third_party_note,
            upper_arch_url: values.upper_arch_url,
            lower_arch_url: values.lower_arch_url,
        };

        await this.props.updateImpressionsBite(data);
        await this.props.SubmitBite()
    };

    /*--------------------------------------------------------------------------------*/
    /* uploading file timer                                             */
    /*--------------------------------------------------------------------------------*/
    timer = (id) => {
        this.setState({
            [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
        });
    };

    /* --------------------------------------------------------------------------------*/
    /* Upload image to s3  */
    /* --------------------------------------------------------------------------------*/
    uploadImage = async (image, id) => {
        let extention = image.name.split(".");
        return uploadFile({
            name: `case-${this.state.caseId}/patient-impression/${id}/${new Date().valueOf()}.${image.name.split(".")[extention.length - 1]
                }`,
            contentType: image.type,
            file: image,
        });
    };

    /* --------------------------------------------------------------------------------*/
    /* upload photos */
    /* --------------------------------------------------------------------------------*/

    UploadPhoto = async (e, id, setFieldValue) => {
        e.persist();

        if (e.target.files[0]) {
            const intervalID = setInterval(this.timer(id), 1000);
            this.setState({
                loading1: true,
                intervalID,
                [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
            });
            let reader = new FileReader();

            reader.readAsDataURL(e.target.files[0]);
            const file = e.target ? e.target.files[0] : e.files[0];
            const { key } = await this.uploadImage(file, id);
            clearInterval(this.state.intervalID);
            this.setState({
                [id]: keyToUrl(key),
                [`loading_percentage_${id}`]: 100,
            });

            setFieldValue(id, keyToUrl(key));
            setFieldValue("chose_one", true)
            setTimeout(() => {
                this.setState({
                    [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
                    [`loading_percentage_${id}`]: 0,
                });
            }, 500);
        }
    };
    /* --------------------------------------------------------------------------------*/
    /* upload photos if from drop zone */
    /* --------------------------------------------------------------------------------*/
    UploadPhotoDropZone = async (e, id, setFieldValue) => {
        if (e) {
            const intervalID = setInterval(this.timer(id), 1000);
            this.setState({
                loading1: true,
                intervalID,
                [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
            });
            let reader = new FileReader();

            reader.readAsDataURL(e);
            const file = e;
            const { key } = await this.uploadImage(file, id);
            clearInterval(this.state.intervalID);
            this.setState({
                [id]: keyToUrl(key),
                [`loading_percentage_${id}`]: 100,
            });

            setFieldValue(id, keyToUrl(key));
            setFieldValue("chose_one", true)
            setTimeout(() => {
                this.setState({
                    [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
                    [`loading_percentage_${id}`]: 0,
                    [`hover_${id}`]: false

                });
            }, 500);
        }
    };
    /* --------------------------------------------------------------------------------*/
    /* preventDefault */
    /* --------------------------------------------------------------------------------*/
    preventDefault = (e) => {
        e.preventDefault();
        // e.stopPropagation();
    };
    /* --------------------------------------------------------------------------------*/
    /* drag Over */
    /* --------------------------------------------------------------------------------*/
    dragOver = (e, key) => {
        this.preventDefault(e);
        this.setState(({ [`hover_${key}`]: true }))

    };
    /* --------------------------------------------------------------------------------*/
    /* drag Enter */
    /* --------------------------------------------------------------------------------*/
    dragEnter = (e, key) => {
        this.preventDefault(e);
        this.setState(({ [`hover_${key}`]: true }))
    };
    /* --------------------------------------------------------------------------------*/
    /* drag Leave */
    /* --------------------------------------------------------------------------------*/
    dragLeave = (e, key) => {
        this.preventDefault(e);
        this.setState(({ [`hover_${key}`]: false }))

    };
    /* --------------------------------------------------------------------------------*/
    /* file Drop */
    /* --------------------------------------------------------------------------------*/
    fileDrop = (e, key, setFieldValue) => {
        this.preventDefault(e);
        const files = e.dataTransfer.files;
        if (files.length === 1) {
            this.handleFiles(files, key, setFieldValue);
        } else {
            this.setState(({ [`hover_${key}`]: false }))
            // infoToaster("please add one file ");
        }
    };
    /* --------------------------------------------------------------------------------*/
    /* handle Files */
    /* --------------------------------------------------------------------------------*/
    handleFiles = (files, key, setFieldValue) => {
        const { content } = this.props.languageReducer;

        for (let i = 0; i < files.length; i++) {
            if (this.validateFile(files[i])) {
                this.UploadPhotoDropZone(files[i], key, setFieldValue)
            } else {
                errorToaster(content.file_not_supported, content.Warning);
                this.setState(({ [`hover_${key}`]: false }))

            }

        }
    };
    /* --------------------------------------------------------------------------------*/
    /* validate File */
    /* --------------------------------------------------------------------------------*/
    validateFile = (file) => {
        let extention = file.name.split(".");
        const validTypes = ["zip", "rar", "7zip", "ply", "stl"];
        if (validTypes.indexOf(file.name.split(".")[extention.length - 1]) === -1) {
            return false;
        }

        return true;
    };
    /* --------------------------------------------------------------------------------*/
    /* file Input Clicked */
    /* --------------------------------------------------------------------------------*/
    fileInputClicked = (input) => {
        // this[`${input}_ref`].current.click();
    };


    render() {

        const { content } = this.props.languageReducer;
        const { gco_doctor } = this.state;
        const { impressions } = this.props.biteReducer;
        const {case_type}= this.props.caseReducer.case
        const impressions_methods =
            [{ name: "upload", title: content.upload_3d_scans },
            { name: "third_party", title: content._3rd_party_integration },
            ...(!gco_doctor ? [{ name: "courier", title: content.impression_pickup }] : [])

            ];
        return (
            <div>

                <Formik
                    initialValues={{
                        impressions_method: impressions.impressions_method,
                        impressions_third_party_note: impressions.impressions_third_party_note,
                        upper_arch_url: impressions.upper_arch_url,
                        lower_arch_url: impressions.lower_arch_url,
                        chose_one: this.state.chose_one
                    }}
                    validationSchema={
                        (case_type === "UnlimitedII" || case_type === "LiteII")
                            && this.state.impressions_method === "upload" ? schema2 : this.state.impressions_method === "upload" ? schema : schema3}
                    onSubmit={this.handleSubmit}
                >
                    {({
                        touched,
                        errors,
                        isSubmitting,
                        values,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="bite_steps_container">
                                <div className="c_impressions_grid">

                                    <div>
                                        <div className="custom-title colorful">{content.send_teeth_impressions}</div>
                                        <CustomRadioGroup name="impressions_method" checked={values.impressions_method}
                                            onChange={(value) => {
                                                setFieldValue("impressions_method", value);
                                                this.setState({ impressions_method: value })
                                                setFieldValue("chose_one", "")

                                            }}
                                            options={impressions_methods}
                                            id="impressions_method"

                                        />
                                        <ErrorMessage
                                            component="div"
                                            name={"impressions_method"}
                                            className="custom-invalid-feedback m-1 text-center"
                                        />
                                    </div>
                                    <div className="c-divider">
                                    </div>

                                    <div>
                                        {values.impressions_method === "upload" && (
                                            <div>
                                                <div className="upload_impressions_grid">
                                                    {upload_impression_data.map((value, index) => (
                                                        <div key={index}>
                                                            <Card
                                                                onDragOver={(e) => this.dragOver(e, value.key)}
                                                                onDragEnter={(e) => this.dragEnter(e, value.key)}
                                                                onDragLeave={(e) => this.dragLeave(e, value.key)}
                                                                onDrop={(e) => this.fileDrop(e, value.key, setFieldValue)}
                                                                onClick={() => this.fileInputClicked(value.key)}

                                                                className={`cp2 cmb2 ${this.state[`hover_${value.key}`] ? "c-dragover" : ""} ${values[value.key] ? "c-card-with-img-active" : "c-card-with-img"}`}>

                                                                <CardTitle className="cmb2">
                                                                    <div className="card-title-grid">
                                                                        <div className="body-text">
                                                                            {values[value.key] ? value.title : this.state[`showProgress_${value.key}`] ? `Uploading ${value.title}` : <span className="not-active-img">{value.title} </span>}
                                                                        </div>
                                                                        <div>
                                                                            <img src={values[value.key] ? upload_active : upload_not_active} alt={value.key} />
                                                                        </div>
                                                                    </div>

                                                                </CardTitle>

                                                                <div className="image-overlay-container">
                                                                    <label
                                                                        ref={this[`${value.key}_ref`]}
                                                                        name={value.key}
                                                                        htmlFor={value.key}
                                                                        className="m-0"
                                                                    >
                                                                        <CardImg
                                                                            src={value.img}
                                                                            className="upload-impression-img"
                                                                        />
                                                                        {values[value.key] && (
                                                                            <div className="overlay-loader" htmlFor={value.key}
                                                                            >
                                                                                <div className="layer overlay-loader"></div>
                                                                            </div>
                                                                        )}

                                                                        {this.state[`showProgress_${value.key}`] && (
                                                                            <div className="overlay-loader">
                                                                                <CircularProgressbar
                                                                                    className="circule-progress-bar"
                                                                                    value={
                                                                                        this.state[
                                                                                        `loading_percentage_${value.key}`
                                                                                        ]
                                                                                    }
                                                                                    text={`${this.state[
                                                                                        `loading_percentage_${value.key}`
                                                                                    ]
                                                                                        }%`}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        id={value.key}
                                                                        onChange={(e) => {
                                                                            this.UploadPhoto(e, value.key, setFieldValue);
                                                                        }}
                                                                        multiple={false}
                                                                        className="visually-hidden"
                                                                        accept=".zip,.rar,.7zip,.ply,.stl"
                                                                    />


                                                                </div>
                                                            </Card>
                                                            <ErrorMessage
                                                                component="div"
                                                                name={value.key}
                                                                className="custom-invalid-feedback m-1"
                                                            />
                                                        </div>
                                                    ))}

                                                </div>



                                                <ErrorMessage
                                                    component="div"
                                                    name={"chose_one"}
                                                    className="custom-invalid-feedback m-1 text-center chose_one"
                                                /></div>
                                        )}

                                        {/*--------------------------------------------------------------------------------*/}
                                        {/* Digital Means Comments */}
                                        {/*--------------------------------------------------------------------------------*/}
                                        {values.impressions_method === "third_party" && (
                                            <div className="form-group content form-block-holder">
                                                <label className="custom-title colorful">
                                                    {content.provide_links_to_third_party} </label>
                                                <div>
                                                    <Input
                                                        autoComplete="off"
                                                        type="textarea"
                                                        rows={(window.innerWidth < 839) ? "4" : "8"}
                                                        placeholder={`${content.paste_link} (${content.optional})`}
                                                        className="custome-textarea"
                                                        defaultValue={values.impressions_third_party_note}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                impressions_third_party_note: e.target.value,
                                                            });
                                                            setFieldValue(
                                                                "impressions_third_party_note",
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {/*--------------------------------------------------------------------------------*/}
                                        {/*End Digital Means Comments */}
                                        {/*--------------------------------------------------------------------------------*/}

                                    </div>
                                </div>



                            </div>



                            <CardFooter className="p-3 container-footer-mobile">

                                <Button
                                    className={`btn white-btn float-left submission-btn button-seme-bold-text`}
                                    size="sm"
                                    type="button"
                                    disabled={this.props.isSubmitting}
                                    onClick={() => this.props.PrevStep()}
                                >
                                    <i className="fas fa-angle-left  pr-1"></i> {content.previous}
                                </Button>
                                <div className="order-save-button">
                                    <Button
                                        className={`btn light-green-btn float-right  submission-btn  button-seme-bold-text`}
                                        size="sm"
                                        type="submit"
                                        disabled={this.props.isSubmitting}
                                    >
                                        {content.submit} <i className="fas fa-angle-right pl-1"></i>
                                    </Button>
                                </div>
                            </CardFooter>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BiteImpressions);
