import React, { Component } from "react";
import { connect } from "react-redux";
import { getDoctorAddresses, getDoctorInfo } from "../../api/api";
import { mapCountry } from "../../helpers/countries";
import {
  changeCurrentStep,
  activateStep,
  prevStep,
  showAdvancedModule,
  Set_Submission_Side_Nav,
  activateRetainerStep,
  prevRetainerStep,
  updatePatientInfo,
} from "../../redux/CaseSubmission/action";

import { getCaseById } from "../../redux/caseById/action";
import AdvancedModulesCopy from "./AdvancedModulesCopy";
import Impressions from "./Impressions";
import PatientInfo from "./PatientInfo";
import PhotosXrays from "./PhotosXrays";
import Prescription from "./Prescription";
import Summary from "./Summary";
//import TreatmentType from "./TreatmentType";
import Terms from "./Terms";
import Submit from "./Submit";

import check from "../../assets/images/StepperMobile/check.svg";
import checkDisable from "../../assets/images/StepperMobile/checkDisable.svg";
import camera from "../../assets/images/StepperMobile/camera.svg";
import cameraDisable from "../../assets/images/StepperMobile/cameraDisable.svg";
import Frame from "../../assets/images/StepperMobile/Frame.svg";
import FrameDisable from "../../assets/images/StepperMobile/FrameDisable.svg";
import ImpressionsImage from "../../assets/images/StepperMobile/ImpressionsImage.svg";
import ImpressionsImageDisable from "../../assets/images/StepperMobile/ImpressionsImageDisable.svg";
import Information from "../../assets/images/StepperMobile/Information.svg";
import InformationDisable from "../../assets/images/StepperMobile/InformationDisable.svg";
import PrescriptionImage from "../../assets/images/StepperMobile/PrescriptionImage.svg";
import PrescriptionImageDisable from "../../assets/images/StepperMobile/PrescriptionImageDisable.svg";

import step_background from "../../assets/images/v4/step_background.svg";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  Set_Submission_Side_Nav: (payload) =>
    dispatch(Set_Submission_Side_Nav(payload)),
  changeCurrentStep: (payload) => dispatch(changeCurrentStep(payload)),
  activateStep: (payload) => dispatch(activateStep(payload)),
  prevStep: (payload) => dispatch(prevStep(payload)),
  activateRetainerStep: (payload) => dispatch(activateRetainerStep(payload)),
  prevRetainerStep: (payload) => dispatch(prevRetainerStep(payload)),
  showAdvancedModule: (payload) => dispatch(showAdvancedModule(payload)),
  getCaseById: (payload) => dispatch(getCaseById(payload)),
  updatePatientInfo: (payload) => dispatch(updatePatientInfo(payload)),
});

class CaseSubmission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      hideToggle: this.props.caseSubmission.hideSubmissionSideNav,
      addresses: [],
      newAddressArr: [],
      doctorId: props.userReducer.currentUser.id,
      caseId:
        props.caseId || (window.location.search.match(/id=([^&]+)/) || [])[1],
      case: {},
      loadData: false,
      showSubmit: false,
      mappedData: {},
      new_cases_Date: "2021-01-25",
      terms: false,
      step: 1,
      gco_doctor: props.userReducer.currentUser.gco_doctor,
      hybrid: this.props.location.state?.hybrid || false,
      patientInfo: this.props.location.state?.patient_info || {},
      parent_aligner_case_id:
        this.props.location.state?.parent_aligner_case_id || "",
    };

    this.props.history.listen((location, action) => {
      if (window.innerWidth < 840) {
        if (
          document.getElementById("mySidenav") &&
          document.getElementById("main-gco")
        )
          document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main-gco").style.marginLeft = "0";
      } else {
        if (
          document.getElementById("mySidenav") &&
          document.getElementById("main-gco")
        ) {
          document.getElementById("mySidenav").style.width = "250px";
          document.getElementById("main-gco").style.marginLeft = "250px";
        }
      }
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*Open SideNav                                         */
  /*--------------------------------------------------------------------------------*/
  openNav = () => {
    if (this.state.width > 840) {
      if (
        document.getElementById("mySidenav") &&
        document.getElementById("main-gco")
      ) {
        document.getElementById("mySidenav").style.width = "250px";
        document.getElementById("main-gco").style.marginLeft = "250px";
      }
    } else {
      if (document.getElementById("main-gco")) {
        document.getElementById("mySidenav").style.width = "206px";
      }
    }
    this.setState({ hideToggle: !this.state.hideToggle });
  };
  /*--------------------------------------------------------------------------------*/
  /*Close SideNav                                         */
  /*--------------------------------------------------------------------------------*/
  closeNav = () => {
    if (document.getElementById("mySidenav")) {
      document.getElementById("mySidenav").style.width = "0";

      if (this.state.width > 840) {
        document.getElementById("main-gco").style.marginLeft = "0";
      }
    }

    this.setState({ hideToggle: !this.state.hideToggle });
  };
  /*--------------------------------------------------------------------------------*/
  /*update Side Nav Dimintions                                        */
  /*--------------------------------------------------------------------------------*/
  updateSideNavDim = () => {
    if (this.state.width > 1300) {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("main-gco").style.marginLeft = "250px";
      this.setState({ hideToggle: true });
      this.props.Set_Submission_Side_Nav(false);
    } else {
      if (
        document.getElementById("mySidenav") &&
        document.getElementById("main-gco")
      ) {
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main-gco").style.marginLeft = "0";
        this.setState({ hideToggle: false });
        this.props.Set_Submission_Side_Nav(true);
      }
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*update screen dimintions                                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
    this.updateSideNavDim();
  };
  /* --------------------------------------------------------------------------------*/
  /* get aligner case info , doctor info and doctor addresses  */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    this.setState({
      width: window.innerWidth,
    });
    this.updateDimensions();
    if (this.state.caseId) {
      this.GetAlignerCaseById();
      getDoctorInfo(this.state.doctorId).then((res) => {
        this.setState({
          doctorInfo: res,
        });
      });
      getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
        let newAddress = mapCountry(res);

        this.setState({ addresses: res, newAddressArr: newAddress });
      });
    } else {
      getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
        let newAddress = mapCountry(res);

        this.setState({
          addresses: res,
          newAddressArr: newAddress,
          loadData: true,
        });
      });
      const { patientInfo } = this.state;
      if (patientInfo) {
        let patient_info = {
          date_of_birth: patientInfo.date_of_birth
            ? new Date(patientInfo.date_of_birth)
            : "",
          first_name: patientInfo.first_name,
          gender: patientInfo.gender,
          last_name: patientInfo.last_name,
          email: patientInfo.email,
          case_type: "",
          address_id: "",
        };
        this.props.updatePatientInfo(patient_info);
      }
    }
  }

  GetDoctorAddresses = () => {
    getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
      let newAddress = mapCountry(res);
      this.setState({
        addresses: res,
        newAddressArr: newAddress,
        loadData: true,
      });
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* get aligner case by id                                                         */
  /*--------------------------------------------------------------------------------*/
  GetAlignerCaseById = async (skipStep = false) => {
    const { hybrid, gco_doctor } = this.state;

    const doctor_id = this.props.userReducer.currentUser.id;
    const case_id = (window.location.search.match(/id=([^&]+)/) || [])[1];
    // get case by id action
    await this.props.getCaseById({
      doctor_id: doctor_id,
      case_id: case_id,
      hybrid,
      gco_doctor,
      case_submission: true,
    });

    !skipStep && this.mapData(this.props.caseReducer.case);
  };

  /*--------------------------------------------------------------------------------*/
  /* map data from api with reducer                                                 */
  /*--------------------------------------------------------------------------------*/
  mapData(data) {
    const { hybrid, gco_doctor } = this.state;

    this.setState({
      case: data,
      currentStep: data.step,
      loadData: true,
    });
    let { Steps, RetainerSteps } = this.props.caseSubmission;

    if (new Date(data.submitted_at) < new Date(this.state.new_cases_Date)) {
      // this.props.changeCurrentStep(this.renderActiveStep("Treatment Type"));
    }

    if (hybrid && data.status === "Converted awaiting new impressions") {
      this.ActivateCurreStep("");
    } else {
      if (data.uid) {
        if (data.case_type !== "Retainer") {
          //window.location.href = `case/${data.id}`
          this.setState({ showSubmit: true, terms: true });
        }
        this.props.activateStep(Steps[5]);

        if (window.location.pathname === "/retainer") {
          if (data.uid) {
            this.props.changeCurrentStep(
              this.renderActiveStepRetainer("complete")
            );
            this.setState({ showSubmit: true });
          } else {
            this.props.changeCurrentStep(
              this.renderActiveStepRetainer(data.step)
            );
          }
        } else {
          this.props.changeCurrentStep(this.renderActiveStep("complete"));
        }
      } else {
        this.ActivateCurreStep(data.step);
      }
    }
  }
  /*--------------------------------------------------------------------------------*/
  /* activate current step                                               */
  /*--------------------------------------------------------------------------------*/
  ActivateCurreStep = (step) => {
    let advancedModules = [];

    if (this.props.advancedModules?.modules) {
      advancedModules = this.props.advancedModules?.modules.filter(
        (value, index) => {
          return value.active === true;
        }
      );
    }

    let { Steps, currentStep, RetainerSteps } = this.props.caseSubmission;

    if (window.location.pathname !== "/retainer" && currentStep < 6) {
      this.props.changeCurrentStep(this.renderActiveStep(step));
      this.props.activateStep(Steps[this.renderActiveStep(step) - 1]);
    } else {
      this.props.changeCurrentStep(this.renderActiveStepRetainer(step));
      this.props.activateRetainerStep(
        RetainerSteps[this.renderActiveStepRetainer(step) - 1]
      );
    }
  };

  /*--------------------------------------------------------------------------------*/
  /* render active step                                                             */
  /*--------------------------------------------------------------------------------*/
  renderActiveStep(step) {
    switch (step) {
      case "Patient Information":
        return 1;
      case "":
        return 1;
      case "Treatment Type":
        return 2;
      case "photos":
        return 2;
      case "xrays":
        return 2;
      case "impressions":
        return 3;
      case "prescription":
        return 4;

      case "summary":
        return 6;
      case "complete":
        return 7;
      default:
        return 2;
    }
  }

  /*--------------------------------------------------------------------------------*/
  /* render active step Retainer                                                            */
  /*--------------------------------------------------------------------------------*/
  renderActiveStepRetainer(step) {
    switch (step) {
      case "Patient Information":
        return 1;
      case "":
        return 1;
      case "impressions":
        return 2;
      case "summary":
        return 3;
      case "complete":
        return 4;
      default:
        return 2;
    }
  }

  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  componentDidUpdate(prevProps, prevState) {
    // if (prevState.width !== this.state.width) {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
    // }
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  NextStep = () => {
    let { Steps, currentStep } = this.props.caseSubmission;
    if (currentStep < 6) {
      this.props.changeCurrentStep(currentStep + 1);
      this.props.activateStep(Steps[currentStep]);
      this.setState({ terms: false, showSubmit: false });
    } else {
      this.GetAlignerCaseById();
    }
  };

  NextRetainerSteps = () => {
    let { RetainerSteps, currentStep } = this.props.caseSubmission;

    if (currentStep < 4) {
      this.props.changeCurrentStep(currentStep + 1);
      this.props.activateRetainerStep(RetainerSteps[currentStep]);
      this.setState({ terms: false, showSubmit: false });
    } else {
      this.GetAlignerCaseById();
    }
  };
  TermsStep = () => {
    this.setState({ terms: !this.state.terms });
  };
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  PrevStep = () => {
    let { Steps, currentStep } = this.props.caseSubmission;
    if (currentStep === 6) {
      this.props.changeCurrentStep(4);
      this.props.prevStep(Steps[3]);
    } else {
      this.props.changeCurrentStep(currentStep - 1);
      this.props.prevStep(Steps[currentStep - 2]);
    }
  };

  PrevRetainerSteps = () => {
    let { RetainerSteps, currentStep } = this.props.caseSubmission;
    if (currentStep === 3) {
      this.props.changeCurrentStep(2);
      this.props.prevRetainerStep(RetainerSteps[1]);
    } else {
      this.props.changeCurrentStep(currentStep - 1);
      this.props.prevRetainerStep(RetainerSteps[currentStep - 2]);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  ShowAdvancedModule = (props) => {
    let { Steps, currentStep } = this.props.caseSubmission;

    this.props.showAdvancedModule(props);
    if (props === true) {
      this.props.changeCurrentStep(currentStep + 1);
      this.props.activateStep(Steps[currentStep]);
    } else {
      this.props.changeCurrentStep(currentStep + 2);
      this.props.activateStep(Steps[currentStep + 1]);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/

  ShowSubmit = () => {
    let { Steps, currentStep, RetainerSteps } = this.props.caseSubmission;

    window.location.pathname === "/retainer"
      ? console.log()
      : this.GetAlignerCaseById();

    this.setState({ showSubmit: !this.state.showSubmit });
    this.props.changeCurrentStep(currentStep + 1);
    window.location.pathname === "/retainer"
      ? this.props.activateStep(RetainerSteps[currentStep])
      : this.props.activateStep(Steps[currentStep]);
  };

  render() {
    const { step, hybrid, parent_aligner_case_id } = this.state;
    let { Steps, currentStep, RetainerSteps } = this.props.caseSubmission;
    const { patient_info, uid } = this.props.caseReducer.case;
    const { loadData, showSubmit, terms, addresses } = this.state;
    const { content } = this.props.languageReducer;
    let filtersRetainer = Steps.filter(
      (el, i) =>
        el.name === "Patient Information" ||
        el.name === "Impressions" ||
        el.name === "Summary" ||
        el.name === "Submission Complete"
    );
    let steeper = window.location.pathname === "/retainer" ? 3 : 6;

    const Step = (props) => (
      <div
        className={`Stepper__step ${
          currentStep > props.indicator ? "done-stepper" : ""
        }`}
        style={{
          pointerEvents:
            currentStep < props.indicator || currentStep > steeper
              ? "none"
              : "",
        }}
        onClick={() => {
          this.setState({ step: props.indicator });
          this.props.changeCurrentStep(props.indicator);
        }}
      >
        <div
          className={`${
            currentStep === props.indicator ? "current-step_stepper" : ""
          } ${currentStep > props.indicator ? "done-step_stepper" : ""}`}
        >
          <div
            className={
              props.updated === true
                ? "Stepper__edit  label-cont-stepper"
                : "Stepper__edit_empty label-cont-stepper"
            }
          >
            {" "}
            <img
              src={`${
                currentStep >= props.indicator
                  ? props.ColorfulImage
                  : props.DisableImage
              }`}
              className="Stepper__label"
            ></img>
          </div>
          <div className="Stepper__indicator">
            <span className="Stepper__info">
              <div className="step_indicator"></div>
            </span>
          </div>

          <div className="Stepper__panel">{props.children}</div>
        </div>
      </div>
    );

    // Overlay
    const Stepper = (props) => (
      <div
        className={`Stepper ${props.isVertical ? "Stepper--vertical" : ""} ${
          props.isInline ? "Stepper--inline" : ""
        }`}
      >
        {props.children}
      </div>
    );

    return (
      <div className="mt-0">
        {
          <aside id="mySidenav" className="sidenav-gco">
            <div className="sidenav-rl grid-Stepper">
              <div className="side-container">
                <label className=" steps-title">
                  {window.location.pathname === "/retainer" ? (
                    <div className="retainer-header-grid">
                      <div></div>
                      <div className=" retainer-header subhead-text">
                        <span className="bold-line">
                          {content.Retainer_Case_Submission1}
                        </span>
                        <br></br> {content.Retainer_Case_Submission2}
                      </div>
                    </div>
                  ) : (
                    <div className="retainer-header-grid">
                      <div></div>
                      <div className=" retainer-header subhead-text">
                        <span className="bold-line">{content.aligner}</span>
                        <br></br>
                        {content.case_submission}
                      </div>
                    </div>
                  )}
                </label>

                {window.location.pathname === "/retainer" ? (
                  <div>
                    {RetainerSteps.map((value, index) => {
                      return (
                        <div
                          key={value.id}
                          className={`step ${
                            value.id === currentStep ? "active-step" : ""
                          }`}
                          style={{
                            pointerEvents:
                              !value.active || showSubmit ? "none" : "",
                          }}
                        >
                          <span
                            onClick={() => {
                              this.props.prevRetainerStep(RetainerSteps[index]);
                              this.props.changeCurrentStep(index + 1);
                            }}
                          >
                            {value.id === currentStep && (
                              <img
                                style={{ position: "relative" }}
                                src={step_background}
                                className="step-img"
                                alt="step_background"
                              />
                            )}

                            <div
                              // style={{
                              //   bottom:
                              //     currentStep === 1 && value.active
                              //       ? "35%"
                              //       : "",
                              // }}
                              className="step-icon-grid"
                            >
                              {" "}
                              <img
                                src={value.icon}
                                alt={value.name}
                                className="m-auto"
                              />{" "}
                              <div className="subhead-text">
                                {content[value.key]}
                              </div>{" "}
                            </div>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    {Steps.map((value, index) => {
                      // !value.subTitle ? (
                      return (
                        <div
                          key={value.id}
                          className={`step ${
                            value.id === currentStep ? "active-step" : ""
                          }`}
                          style={{
                            pointerEvents:
                              !value.active || showSubmit ? "none" : "",
                          }}
                        >
                          <span
                            onClick={() => {
                              this.props.prevStep(Steps[index]);
                              this.props.changeCurrentStep(index + 1);
                            }}
                          >
                            {value.id === currentStep && (
                              <img
                                style={{ position: "relative" }}
                                src={step_background}
                                className="step-img"
                                alt="step_background"
                              />
                            )}

                            <div
                              // style={{
                              //   bottom:
                              //     currentStep === 1 && value.active
                              //       ? "35%"
                              //       : "",
                              // }}
                              className="step-icon-grid"
                            >
                              {" "}
                              <img
                                src={value.icon}
                                alt={value.name}
                                className="m-auto"
                              />{" "}
                              <div className="subhead-text">
                                {content[value.key]}
                              </div>{" "}
                            </div>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </aside>
        }

        {/* ///////Stepper case submission  ////////// */}
        {window.innerWidth < 839 &&
          window.location.pathname === "/case-submission" && (
            <div>
              <label className=" steps-title">
                {window.location.pathname === "/retainer" ? (
                  <div className="">
                    {" "}
                    <div className="label-step-title "></div>
                  </div>
                ) : (
                  <div className=" label-step-title caption-1-bold-text">
                    {" "}
                    <span className="bold-line caption-1-bold-text">
                      {content.aligner}
                    </span>
                    {content.case_submission}
                  </div>
                )}
              </label>
              <div className="stepper_grid">
                <Stepper>
                  {Steps.map((value, index) => {
                    return (
                      <Step
                        onClick={() => {
                          this.setState({ step: value.id });
                        }}
                        //  active ={value.active}
                        key={value.id}
                        indicator={value.id}
                        ColorfulImage={value.icon}
                        DisableImage={value.disableImage}
                        updated=""
                      >
                        <div className="Content">
                          <button
                            onClick={() => {
                              this.props.prevStep(Steps[value.id]);
                            }}
                          >
                            Prev
                          </button>
                          <button
                            onClick={() => {
                              this.props.changeCurrentStep(value.id + 1);
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </Step>
                    );
                  })}
                </Stepper>
                <div className="text-right step-number-grid">
                  {" "}
                  {currentStep < 6 ? currentStep : currentStep - 1} {content.of}{" "}
                  {6}{" "}
                </div>
              </div>
            </div>
          )}

        {window.innerWidth < 840 &&
          loadData &&
          window.location.pathname === "/case-submission" && (
            <div className="ts-steps case-submission-steps">
              {currentStep === 1 && (
                <div>
                  {" "}
                  <PatientInfo
                    addresses={addresses}
                    NextStep={this.NextStep}
                    props={this.props}
                    caseId={this.state.caseId}
                    GetAlignerCaseById={this.GetAlignerCaseById}
                    GetDoctorAddresses={this.GetDoctorAddresses}
                    case={this.props.caseReducer.case}
                    hybrid={hybrid}
                    parent_aligner_case_id={parent_aligner_case_id}
                  />
                </div>
              )}
              {currentStep === 2 && (
                <div>
                  <PhotosXrays
                    PrevStep={this.PrevStep}
                    NextStep={this.NextStep}
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    patient={patient_info}
                  />
                </div>
              )}

              {currentStep === 3 && (
                <div>
                  <Impressions
                    PrevStep={this.PrevStep}
                    NextStep={this.NextStep}
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    case={this.props.caseReducer.case}
                    patient={patient_info}
                    gco_doctor={this.state.gco_doctor}
                  />
                </div>
              )}

              {currentStep === 4 && (
                <div>
                  <Prescription
                    PrevStep={this.PrevStep}
                    NextStep={this.NextStep}
                    ShowAdvancedModule={this.ShowAdvancedModule}
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    patient={patient_info}
                    GetAlignerCaseById={this.GetAlignerCaseById}
                  />
                </div>
              )}

              {/* {currentStep === 6 && !showSubmit &&
              <div>

                <Summary
                  PrevStep={this.PrevStep}
                  NextStep={this.ShowSubmit}
                  props={this.props}
                  doctorId={this.state.doctorId}
                  caseData={this.state.case}
                  caseId={this.state.caseId}
                  patient={this.state.case.patient}
                />
              </div>
            } */}

              {/* {currentStep === 6 && loadData && !showSubmit && !terms && (
                <Terms
                  PrevStep={this.PrevStep}
                  NextStep={this.TermsStep}
                  props={this.props}
                  doctorId={this.state.doctorId}
                  caseId={this.state.caseId}
                  patient={patient_info}
                />
              )} */}
              {currentStep === 6 && loadData && !showSubmit && (
                <Summary
                  PrevStep={this.PrevStep}
                  NextStep={this.ShowSubmit}
                  props={this.props}
                  doctorId={this.state.doctorId}
                  caseData={this.state.case}
                  caseId={this.state.caseId}
                  patient={patient_info}
                  hybrid={hybrid}
                />
              )}

              {currentStep === 7 && loadData && uid && showSubmit && (
                <Submit
                  props={this.props}
                  doctorId={this.state.doctorId}
                  caseId={this.state.caseId}
                  case={this.props.caseReducer.case}
                  mappedData={this.props.caseReducer.case}
                  doctorInfo={this.state.doctorInfo}
                />
              )}
            </div>
          )}
        {/* /////////End stepper ///////// */}

        {/* /////////////////Stepper retainer ////////////////////// */}
        {window.innerWidth < 839 &&
          window.location.pathname === "/retainer" && (
            <div>
              <label className=" steps-title">
                {window.location.pathname === "/retainer" ? (
                  <div className=" label-step-title">
                    {" "}
                    <span className="bold-line">{content.retainer}</span>
                    {content.case_submission}
                  </div>
                ) : (
                  <div className=" label-step-title">
                    {" "}
                    <span className="bold-line">{content.aligner}</span>
                    {content.case_submission}
                  </div>
                )}
              </label>
              <div className="stepper_grid">
                <Stepper>
                  {RetainerSteps.map((value, index) => {
                    return (
                      <Step
                        onClick={() => {
                          this.setState({ step: value.id });
                        }}
                        style={{
                          pointerEvents:
                            !value.active || showSubmit ? "none" : "",
                        }}
                        key={value.id}
                        indicator={value.id}
                        ColorfulImage={value.icon}
                        DisableImage={value.disableImage}
                        updated=""
                      >
                        <div className="Content">
                          <button
                            onClick={() => {
                              this.props.prevStep(RetainerSteps[value.id]);
                            }}
                          >
                            Prev
                          </button>
                          <button
                            onClick={() => {
                              this.props.changeCurrentStep(value.id + 1);
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </Step>
                    );
                  })}
                </Stepper>

                <div className="text-right step-number-grid">
                  {" "}
                  {currentStep} {content.of} {4}{" "}
                </div>
              </div>
            </div>
          )}

        {/* 



               {/* /////////End  retainer stepper ///////// */}

        <div className="case-grid mb-0" id="main-gco">
          {window.location.pathname === "/retainer" ? (
            <div>
              {currentStep === 1 && loadData && (
                <PatientInfo
                  addresses={addresses}
                  NextStep={this.NextRetainerSteps}
                  props={this.props}
                  caseId={this.state.caseId}
                  GetAlignerCaseById={this.GetAlignerCaseById}
                  GetDoctorAddresses={this.GetDoctorAddresses}
                  case={this.props.caseReducer.case}
                  hybrid={hybrid}
                  parent_aligner_case_id={parent_aligner_case_id}
                />
              )}
              {currentStep === 2 && loadData && (
                <Impressions
                  PrevStep={this.PrevRetainerSteps}
                  NextStep={this.NextRetainerSteps}
                  props={this.props}
                  doctorId={this.state.doctorId}
                  caseId={this.state.caseId}
                  case={this.props.caseReducer.case}
                  patient={patient_info}
                  gco_doctor={this.state.gco_doctor}
                />
              )}

              {currentStep === 3 && loadData && (
                <Summary
                  PrevStep={this.PrevRetainerSteps}
                  NextStep={this.ShowSubmit}
                  props={this.props}
                  doctorId={this.state.doctorId}
                  caseData={this.props.caseReducer.case}
                  caseId={this.state.caseId}
                  patient={patient_info}
                  hybrid={hybrid}
                />
              )}

              {currentStep === 4 &&
                loadData &&
                // this.state.case.uid && terms &&
                showSubmit && (
                  <Submit
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    case={this.props.caseReducer.case}
                    mappedData={this.props.caseReducer.case}
                    doctorInfo={this.state.doctorInfo}
                  />
                )}
            </div>
          ) : (
            window.innerWidth > 839 && (
              <div>
                {currentStep === 1 && loadData && (
                  <PatientInfo
                    addresses={addresses}
                    NextStep={this.NextStep}
                    props={this.props}
                    caseId={this.state.caseId}
                    GetAlignerCaseById={this.GetAlignerCaseById}
                    GetDoctorAddresses={this.GetDoctorAddresses}
                    case={this.props.caseReducer.case}
                    hybrid={hybrid}
                    parent_aligner_case_id={parent_aligner_case_id}
                  />
                )}
                {currentStep === 2 && loadData && (
                  <PhotosXrays
                    PrevStep={this.PrevStep}
                    NextStep={this.NextStep}
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    patient={patient_info}
                  />
                )}

                {currentStep === 3 && loadData && (
                  <Impressions
                    PrevStep={this.PrevStep}
                    NextStep={this.NextStep}
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    GetAlignerCaseById={this.GetAlignerCaseById}
                    case={this.props.caseReducer.case}
                    patient={patient_info}
                    gco_doctor={this.state.gco_doctor}
                  />
                )}
                {currentStep === 4 && loadData && (
                  <Prescription
                    PrevStep={this.PrevStep}
                    NextStep={this.NextStep}
                    ShowAdvancedModule={this.ShowAdvancedModule}
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    patient={patient_info}
                    GetAlignerCaseById={this.GetAlignerCaseById}
                  />
                )}
                {currentStep === 5 && loadData && (
                  <AdvancedModulesCopy
                    PrevStep={this.PrevStep}
                    NextStep={this.NextStep}
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    patient={patient_info}
                    mappedData={this.props.caseReducer.case}
                    GetAlignerCaseById={this.GetAlignerCaseById}
                  />
                )}
                {/* 
                {currentStep === 6 && loadData && !showSubmit && !terms && (
                  <Terms
                    PrevStep={this.PrevStep}
                    NextStep={this.TermsStep}
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    patient={patient_info}
                  />
                )} */}
                {currentStep === 6 && loadData && !showSubmit && (
                  <Summary
                    PrevStep={this.PrevStep}
                    NextStep={this.ShowSubmit}
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseData={this.props.caseReducer.case}
                    caseId={this.state.caseId}
                    patient={patient_info}
                    hybrid={hybrid}
                  />
                )}
                {currentStep === 7 && loadData && uid && showSubmit && (
                  <Submit
                    props={this.props}
                    doctorId={this.state.doctorId}
                    caseId={this.state.caseId}
                    case={this.props.caseReducer.case}
                    mappedData={this.props.caseReducer.case}
                    doctorInfo={this.state.doctorInfo}
                  />
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseSubmission);
