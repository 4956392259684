import React, { Component } from "react";
import { updateAlignerCase } from "../../../api/api";
import { uploadFile, keyToUrl } from "../../../helpers/s3";
import { FillPhotos } from "../../../services/fillForm";
import { infoToaster } from "../../../services/toast";
import Resizer from "react-image-file-resizer";

export default class Photos extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      //front smillind state
      front_smiling_photo: props.getStore().front_smiling_photo,
      front_smiling: props.getStore().front_smiling,
      front_smiling_image: props.getStore().front_smiling_image,
      //front pose state
      front_pose_photo: props.getStore().front_pose_photo,
      front_pose: props.getStore().front_pose,
      front_pose_image: props.getStore().front_pose_image,
      //profile state
      profile_photo: props.getStore().profile_photo,
      profile: props.getStore().profile,
      profile_image: props.getStore().profile_image,
      //upper occlusal state
      upper_occlusal_photo: props.getStore().upper_occlusal_photo,
      upper_occlusal: props.getStore().upper_occlusal,
      upper_occlusal_image: props.getStore().upper_occlusal_image,
      //lower occlusal state
      lower_occlusal_photo: props.getStore().lower_occlusal_photo,
      lower_occlusal: props.getStore().lower_occlusal,
      lower_occlusal_image: props.getStore().lower_occlusal_image,
      //left buccal state
      left_buccal_photo: props.getStore().left_buccal_photo,
      left_buccal: props.getStore().left_buccal,
      left_buccal_image: props.getStore().left_buccal_image,
      //frontal state
      frontal_photo: props.getStore().frontal_photo,
      frontal: props.getStore().frontal,
      frontal_image: props.getStore().frontal_image,
      //right buccal state
      right_buccal_photo: props.getStore().right_buccal_photo,
      right_buccal: props.getStore().right_buccal,
      right_buccal_image: props.getStore().right_buccal_image,
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      loading6: false,
      loading7: false,
      loading8: false,
    };

    this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
    this.UID = this.props.UID || null;
    this.caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    this.fix = this.props.fix;
    this.rejectable_id = this.props.rejectable_id;
  }
  /* --------------------------------------------------------------------------------*/
  /* scroll to top after render */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  /* --------------------------------------------------------------------------------*/
  /* check validation on submit   */
  /* --------------------------------------------------------------------------------*/
  isValidated = () => {
    const { content } = this.props.languageReducer;
    const PhotosInput = this._grabPhotosInput(); // grab user entered vals
    const validateNewInput = this._validateData(PhotosInput); // run the new input against the validator
    let isDataValid = false;
    // if full validation passes then save to store and pass as valid
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === true;
      })
    ) {
      if (
        this.props.getStore().front_smiling !== PhotosInput.front_smiling ||
        this.props.getStore().front_pose !== PhotosInput.front_pose ||
        this.props.getStore().profile !== PhotosInput.profile ||
        this.props.getStore().upper_occlusal !== PhotosInput.upper_occlusal ||
        this.props.getStore().lower_occlusal !== PhotosInput.lower_occlusal ||
        this.props.getStore().left_buccal !== PhotosInput.left_buccal ||
        this.props.getStore().frontal !== PhotosInput.frontal ||
        this.props.getStore().right_buccal !== PhotosInput.right_buccal
      ) {
        if (this.props.component === "newCase") {
          let images = [
            { name: "front_smiling", url: PhotosInput.front_smiling },
            { name: "front_pose", url: PhotosInput.front_pose },
            { name: "profile", url: PhotosInput.profile },
            { name: "upper_occlusal", url: PhotosInput.upper_occlusal },
            { name: "lower_occlusal", url: PhotosInput.lower_occlusal },
            { name: "left_buccal", url: PhotosInput.left_buccal },
            { name: "frontal", url: PhotosInput.frontal },
            { name: "right_buccal", url: PhotosInput.right_buccal },
          ];
          let filtered_image = images.filter((key, index) => {
            return key.url.match(/http([^&]+)/);
          });
          let data = {
            aligner_case: {
              step: "photos",
              photos: filtered_image,
            },
            ...(this.rejectable_id && {
              resolve_rejection_id: this.rejectable_id,
            }),
          };
          this.props.sendingData();

          return updateAlignerCase(
            
            this.props.data.userReducer.currentUser.id,
            this.caseId,
            JSON.stringify(data)
          )
            .then((res) => {
              this.props.sendingData();
              // only update store of something changed
              this.props.updateStore({
                ...PhotosInput,
                savedToCloud: false,
              });
              if (this.fix) {
                this.props.data.FixAlignerCase();
              }
              isDataValid = true;
            })
            .catch((error) => {
              this.props.sendingData();
              return (isDataValid = false);
            });
        }

        if (this.props.component === "adjustment") {
          isDataValid = true;
          // only update store of something changed
          this.props.updateStore({
            ...PhotosInput,
          });
        }
      } else {
        if (this.props.data.newCase.userInfo.express_api_v2_case) {
          if (this.props.component === "newCase") {
            let data = {
              aligner_case: {
                step: "photos",
                photos: [],
              },
              ...(this.rejectable_id && {
                resolve_rejection_id: this.rejectable_id,
              }),
            };
            this.props.sendingData();

            return updateAlignerCase(
              this.props.data.userReducer.currentUser.id,
              this.caseId,
              JSON.stringify(data)
            )
              .then((res) => {
                this.props.sendingData();
                // only update store of something changed
                this.props.updateStore({
                  ...PhotosInput,
                  savedToCloud: false,
                });
                if (this.fix) {
                  this.props.data.FixAlignerCase();
                }
                isDataValid = true;
              })
              .catch((error) => {
                this.props.sendingData();
                return (isDataValid = false);
              });
          }

          if (this.props.component === "adjustment") {
            isDataValid = true;
            // only update store of something changed
            this.props.updateStore({
              ...PhotosInput,
            });
          }
        } else {
          // nothing change and every thing is valid
          infoToaster("nothing change .......",  content.Attention_needed);
         
          isDataValid = true;
        }
      }
    } else {
      // if anything fails then update the UI validation state but NOT the UI Data State
      this.setState(
        Object.assign(
          PhotosInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    }

    return isDataValid;
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Check on submit  */
  /* --------------------------------------------------------------------------------*/
  validationCheck = () => {
    if (!this._validateOnDemand) return;

    const PhotosInput = this._grabPhotosInput(); // grab user entered vals
    const validateNewInput = this._validateData(PhotosInput); // run the new input against the validator

    this.setState(
      Object.assign(
        PhotosInput,
        validateNewInput,
        this._validationErrors(validateNewInput)
      )
    );
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation for each element on submit   */
  /* --------------------------------------------------------------------------------*/
  _validateData = (data) => {
    return {
      frontSmilingVal:
        this.props.data.newCase.userInfo.express_api_v2_case ||
          this.props.component === "adjustment"
          ? true
          : data.front_smiling !== "", // required: anything besides N/A

      frontPoseVal:
        this.props.data.newCase.userInfo.express_api_v2_case ||
          this.props.component === "adjustment"
          ? true
          : data.front_pose !== "", // required: anything besides N/A

      profileVal:
        this.props.data.newCase.userInfo.express_api_v2_case ||
          this.props.component === "adjustment"
          ? true
          : data.profile !== "", // required: anything besides N/A

      upperOcclusalVal:
        this.props.data.newCase.userInfo.express_api_v2_case ||
          this.props.component === "adjustment"
          ? true
          : data.upper_occlusal !== "", // required: anything besides N/A

      lowerOcclusalVal:
        this.props.data.newCase.userInfo.express_api_v2_case ||
          this.props.component === "adjustment"
          ? true
          : data.lower_occlusal !== "", // required: anything besides N/A

      frontalVal:
        this.props.data.newCase.userInfo.express_api_v2_case ||
          this.props.component === "adjustment"
          ? true
          : data.frontal !== "", // required: anything besides N/A

      rightBuccalVal:
        this.props.data.newCase.userInfo.express_api_v2_case ||
          this.props.component === "adjustment"
          ? true
          : data.right_buccal !== "", // required: anything besides N/A

      leftBuccalVal:
        this.props.data.newCase.userInfo.express_api_v2_case ||
          this.props.component === "adjustment"
          ? true
          : data.left_buccal !== "", // required: anything besides N/A
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Errors on submit   */
  /* --------------------------------------------------------------------------------*/
  _validationErrors = (val) => {
    const errMsgs = {
      frontSmilingValMsg: val.frontSmilingVal
        ? ""
        : "front Smiling is required ",
      frontPoseValMsg: val.frontPoseVal ? "" : "front Pose is required ",
      profileValMsg: val.profileVal ? "" : "profile is required ",
      upperOcclusalValMsg: val.upperOcclusalVal
        ? ""
        : "upper Occlusal is required ",
      lowerOcclusalValMsg: val.lowerOcclusalVal
        ? ""
        : "lower Occlusal is required ",
      frontalValMsg: val.frontalVal ? "" : "frontal is required ",
      rightBuccalValMsg: val.rightBuccalVal ? "" : "right Buccal is required ",
      leftBuccalValMsg: val.leftBuccalVal ? "" : "left Buccal is required ",
    };
    return errMsgs;
  };
  /* --------------------------------------------------------------------------------*/
  /* grab Photo Input data on submit  */
  /* --------------------------------------------------------------------------------*/
  _grabPhotosInput = () => {
    return {
      front_smiling: this.state.front_smiling,
      front_smiling_image: this.state.front_smiling_image,
      front_smiling_photo: this.state.front_smiling_photo,

      front_pose: this.state.front_pose,
      front_pose_image: this.state.front_pose_image,
      front_pose_photo: this.state.front_pose_photo,

      profile: this.state.profile,
      profile_image: this.state.profile_image,
      profile_photo: this.state.profile_photo,

      upper_occlusal: this.state.upper_occlusal,
      upper_occlusal_image: this.state.upper_occlusal_image,
      upper_occlusal_photo: this.state.upper_occlusal_photo,

      lower_occlusal: this.state.lower_occlusal,
      lower_occlusal_image: this.state.lower_occlusal_image,
      lower_occlusal_photo: this.state.lower_occlusal_photo,

      frontal: this.state.frontal,
      frontal_image: this.state.frontal_image,
      frontal_photo: this.state.frontal_photo,

      right_buccal: this.state.right_buccal,
      right_buccal_image: this.state.right_buccal_image,
      right_buccal_photo: this.state.right_buccal_photo,

      left_buccal: this.state.left_buccal,
      left_buccal_image: this.state.left_buccal_image,
      left_buccal_photo: this.state.left_buccal_photo,
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id, file) => {
    return uploadFile({
      name: `case-${this.UID ? this.UID : this.caseId
        }/patient-photos/${id}/${new Date().valueOf()}.JPEG`,
      contentType: image.type,
      file: image,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* update photo for adjustment */
  /* --------------------------------------------------------------------------------*/
  UpdatePhotosForAdjustment = () => {
    const PhotosInput = this._grabPhotosInput(); // grab user entered vals
    if (this.props.component === "adjustment") {
      // only update store of something changed
      this.props.updateStore({
        ...PhotosInput,
      });
    }
  }
  /* --------------------------------------------------------------------------------*/
  /* upload front smiling photo */
  /* --------------------------------------------------------------------------------*/
  FrontSmilingOnUpload = async (e, id) => {
    e.persist();
    if (e.target.files[0]) {
      this.setState({ loading1: true });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) => {
        this.setState({
          front_smiling_photo: evt.currentTarget.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            front_smiling: keyToUrl(key),
            front_smiling_image: false,
            loading1: false,
          });
          this.UpdatePhotosForAdjustment()
        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // console.log(file, "fiiiiile ");
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   front_smiling: keyToUrl(key),
      //   front_smiling_image: false,
      //   loading1: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload front Pose photo */
  /* --------------------------------------------------------------------------------*/
  FrontPoseOnUpload = async (e, id) => {
    e.persist();

    if (e.target.files[0]) {
      this.setState({ loading2: true });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) =>
        this.setState({
          front_pose_photo: evt.currentTarget.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            front_pose: keyToUrl(key),
            front_pose_image: false,
            loading2: false,
          });
          this.UpdatePhotosForAdjustment()

        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   front_pose: keyToUrl(key),
      //   front_pose_image: false,
      //   loading2: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload Profile photo */
  /* --------------------------------------------------------------------------------*/
  ProfileOnUpload = async (e, id) => {
    e.persist();

    if (e.target.files[0]) {
      this.setState({ loading3: true });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) =>
        this.setState({
          profile_photo: evt.currentTarget.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            profile: keyToUrl(key),
            profile_image: false,
            loading3: false,
          });
          this.UpdatePhotosForAdjustment()

        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   profile: keyToUrl(key),
      //   profile_image: false,
      //   loading3: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload upper occlusal photo */
  /* --------------------------------------------------------------------------------*/
  UpperOcclusalOnUpload = async (e, id) => {
    e.persist();

    if (e.target.files[0]) {
      this.setState({ loading4: true });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) =>
        this.setState({
          upper_occlusal_photo: evt.currentTarget.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            upper_occlusal: keyToUrl(key),
            upper_occlusal_image: false,
            loading4: false,
          });
          this.UpdatePhotosForAdjustment()

        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   upper_occlusal: keyToUrl(key),
      //   upper_occlusal_image: false,
      //   loading4: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload lower occlusal photo */
  /* --------------------------------------------------------------------------------*/
  LowerOcclusalOnUpload = async (e, id) => {
    e.persist();

    if (e.target.files[0]) {
      this.setState({ loading5: true });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) =>
        this.setState({
          lower_occlusal_photo: evt.currentTarget.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            lower_occlusal: keyToUrl(key),
            lower_occlusal_image: false,
            loading5: false,
          });
          this.UpdatePhotosForAdjustment()

        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   lower_occlusal: keyToUrl(key),
      //   lower_occlusal_image: false,
      //   loading5: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload left buccal photo */
  /* --------------------------------------------------------------------------------*/
  LeftBuccalOnUpload = async (e, id) => {
    e.persist();

    if (e.target.files[0]) {
      this.setState({ loading6: true });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) =>
        this.setState({
          left_buccal_photo: evt.currentTarget.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            left_buccal: keyToUrl(key),
            left_buccal_image: false,
            loading6: false,
          });
          this.UpdatePhotosForAdjustment()

        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   left_buccal: keyToUrl(key),
      //   left_buccal_image: false,
      //   loading6: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload frontal photo */
  /* --------------------------------------------------------------------------------*/
  FrontalOnUpload = async (e, id) => {
    e.persist();

    if (e.target.files[0]) {
      this.setState({ loading7: true });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) =>
        this.setState({
          frontal_photo: evt.currentTarget.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            frontal: keyToUrl(key),
            frontal_image: false,
            loading7: false,
          });
          this.UpdatePhotosForAdjustment()

        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   frontal: keyToUrl(key),
      //   frontal_image: false,
      //   loading7: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload right buccal photo */
  /* --------------------------------------------------------------------------------*/
  RightBuccalOnUpload = async (e, id) => {
    e.persist();

    if (e.target.files[0]) {
      this.setState({ loading8: true });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) =>
        this.setState({
          right_buccal_photo: evt.currentTarget.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            right_buccal: keyToUrl(key),
            right_buccal_image: false,
            loading8: false,
          });
          this.UpdatePhotosForAdjustment()

        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   right_buccal: keyToUrl(key),
      //   right_buccal_image: false,
      //   loading8: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* Fill Form with init data   */
  /* --------------------------------------------------------------------------------*/
  FillForm = () => {
    this.setState(FillPhotos());
  };

  render() {
    // explicit class assigning based on validation
    let notValidClasses = {};
    // front smiling photo validation
    if (
      typeof this.state.front_smiling === "undefined" ||
      this.state.front_smiling
    ) {
      notValidClasses.frontSmilingCls = "";
    } else {
      notValidClasses.frontSmilingCls = "is-invalid";
      notValidClasses.frontSmilingValGrpCls = "text-danger";
    }
    // front pose photo validation
    if (typeof this.state.front_pose === "undefined" || this.state.front_pose) {
      notValidClasses.frontPoseCls = "";
    } else {
      notValidClasses.frontPoseCls = "is-invalid";
      notValidClasses.frontPoseValGrpCls = "text-danger";
    }
    // profile photo validation
    if (typeof this.state.profile === "undefined" || this.state.profile) {
      notValidClasses.profileCls = "";
    } else {
      notValidClasses.profileCls = "is-invalid";
      notValidClasses.profileValGrpCls = "text-danger";
    }
    // upper occlusal photo validation
    if (
      typeof this.state.upper_occlusal === "undefined" ||
      this.state.upper_occlusal
    ) {
      notValidClasses.upperOcclusalCls = "";
    } else {
      notValidClasses.upperOcclusalCls = "is-invalid";
      notValidClasses.upperOcclusalValGrpCls = "text-danger";
    }
    // lower occlusal photo validation
    if (
      typeof this.state.lower_occlusal === "undefined" ||
      this.state.lower_occlusal
    ) {
      notValidClasses.lowerOcclusalCls = "";
    } else {
      notValidClasses.lowerOcclusalCls = "is-invalid";
      notValidClasses.lowerOcclusalValGrpCls = "text-danger";
    }
    // frontal photo validation
    if (typeof this.state.frontal === "undefined" || this.state.frontal) {
      notValidClasses.frontalCls = "";
    } else {
      notValidClasses.frontalCls = "is-invalid";
      notValidClasses.frontalValGrpCls = "text-danger";
    }
    // right buccal photo validation
    if (
      typeof this.state.right_buccal === "undefined" ||
      this.state.right_buccal
    ) {
      notValidClasses.rightBuccalCls = "";
    } else {
      notValidClasses.rightBuccalCls = "is-invalid";
      notValidClasses.rightBuccalValGrpCls = "text-danger";
    }
    // left buccal photo validation
    if (
      typeof this.state.left_buccal === "undefined" ||
      this.state.left_buccal
    ) {
      notValidClasses.leftBuccalCls = "";
    } else {
      notValidClasses.leftBuccalCls = "is-invalid";
      notValidClasses.leftBuccalValGrpCls = "text-danger";
    }
    return (
      <div className="step step2 mt-5 mb-2">
        {/*--------------------------------------------------------------------------------*/}
        {/* Fill Form With init values button   */}
        {/*--------------------------------------------------------------------------------*/}
        {/* {!this.fix && this.props.component !== "adjustment" && (
          <div className="row p-3">
            <button
              type="button"
              id="finish-button"
              className="btn btn-next btn-primary btn-lg pull-left"
              onClick={() => {
                this.FillForm();
              }}
            >
              Fill Form
            </button>
          </div>
        )} */}
        {/*--------------------------------------------------------------------------------*/}
        {/* End Fill Form With init values button   */}
        {/*--------------------------------------------------------------------------------*/}
        <div className=" justify-content-md-center">
          <div >
            <div className="">
              <form id="photos" className="form-horizontal mt-2 mb-2">
                <div className="form-group content form-block-holder">
                  <div className="adjus_photos_grid">
                    <div xs="12" md="1" lg="1"></div>
                    <div xs="12" md="10" lg="10">
                      <label className="control-label title_active header">
                        Click on an image to upload
                      </label>
                      <div className="adjus_photos_row_grid">
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Upload Front Smiling Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        <div
                          xs="12"
                          md="6"
                          lg="3"
                          className="text-center title_active p-0 mt-3 mb-3 mt-3 mb-3"
                        >
                          <div className="buttons-container2 ">
                            <input
                              type="file"
                              id="front_smiling_photo"
                              onChange={(e) =>

                                this.FrontSmilingOnUpload(
                                  e,
                                  "front_smiling_photo"

                                )
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                            {
                              <div className="upload-container m-auto">
                                {
                                  <label
                                    htmlFor="front_smiling_photo"
                                    className="upload"
                                  >
                                    <img
                                      src={
                                        this.state.front_smiling_photo
                                          ? this.state.front_smiling_photo
                                          : `https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/case-15245/patient-photos/front_smiling_photo/1597055189808`
                                      }
                                      className={`${this.state.front_smiling_photo
                                        ? ""
                                        : "gray-scale"
                                        }  p-3`}
                                      width="100%"
                                      alt="front_smiling_photo"
                                    />
                                    {this.state.loading1 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                          </div>

                          <div className="mt-2">Front Smiling</div>
                          {this.state.front_smiling === "" && (
                            <div
                              className={notValidClasses.frontSmilingValGrpCls}
                            >
                              {this.state.frontSmilingValMsg}
                            </div>
                          )}
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/*End  Upload Front Smiling Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Upload Front Pose Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        <div
                          xs="12"
                          md="6"
                          lg="3"
                          className="text-center title_active p-0 mt-3 mb-3"
                        >
                          <div className="buttons-container2 ">
                            <input
                              type="file"
                              id="front_pose_photo"
                              onChange={(e) =>
                                this.FrontPoseOnUpload(e, "front_pose_photo")
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                            {
                              <div className="upload-container m-auto">
                                {
                                  <label
                                    htmlFor="front_pose_photo"
                                    className="upload"
                                  >
                                    <img
                                      src={
                                        this.state.front_pose_photo
                                          ? this.state.front_pose_photo
                                          : `https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/case-15245/patient-photos/front_smiling_photo/1597055269612`
                                      }
                                      className={`${this.state.front_pose_photo
                                        ? ""
                                        : "gray-scale"
                                        }  p-3`}
                                      width="100%"
                                      alt="front_pose_photo"
                                    />
                                    {this.state.loading2 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                          </div>
                          <div className="mt-2">Front Pose</div>
                          {this.state.front_pose === "" && (
                            <div className={notValidClasses.frontPoseValGrpCls}>
                              {this.state.frontPoseValMsg}
                            </div>
                          )}
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Upload Front Pose Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Upload Profile Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        <div
                          xs="12"
                          md="6"
                          lg="3"
                          className="text-center title_active p-0 mt-3 mb-3"
                        >
                          <div className="buttons-container2 ">
                            <input
                              type="file"
                              id="profile_photo"
                              onChange={(e) =>
                                this.ProfileOnUpload(e, "profile_photo")
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                            {
                              <div className="upload-container m-auto">
                                {
                                  <label
                                    htmlFor="profile_photo"
                                    className="upload"
                                  >
                                    <img
                                      src={
                                        this.state.profile_photo
                                          ? this.state.profile_photo
                                          : `https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/case-15245/patient-photos/front_smiling_photo/1597055340698`
                                      }
                                      className={`${this.state.profile_photo
                                        ? ""
                                        : "gray-scale"
                                        }  p-3`}
                                      width="100%"
                                      alt="profile_photo"
                                    />
                                    {this.state.loading3 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                          </div>
                          <div className="mt-2">Profile</div>
                          {this.state.profile === "" && (
                            <div className={notValidClasses.profileValGrpCls}>
                              {this.state.profileValMsg}
                            </div>
                          )}
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/*End Upload Profile Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Upload Upper Occlusal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        <div
                          xs="12"
                          md="6"
                          lg="3"
                          className="text-center title_active p-0 mt-3 mb-3"
                        >
                          <div className="buttons-container2 ">
                            <input
                              type="file"
                              id="upper_occlusal_photo"
                              onChange={(e) =>
                                this.UpperOcclusalOnUpload(
                                  e,
                                  "upper_occlusal_photo"
                                )
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                            {
                              <div className="upload-container m-auto">
                                {
                                  <label
                                    htmlFor="upper_occlusal_photo"
                                    className="upload"
                                  >
                                    <img
                                      src={
                                        this.state.upper_occlusal_photo
                                          ? this.state.upper_occlusal_photo
                                          : `https://sandbox.access.eonaligner.com/assets/upper_occlusal-0a36811d5ff03557d250aef5992d608e3e86e608a9139baa6f5a3d75ea423b1a.png`
                                      }
                                      width="100%"
                                      className={`${this.state.upper_occlusal_photo
                                        ? ""
                                        : "gray-scale"
                                        }  p-3`}
                                      alt="upper_occlusal_photo"
                                    />
                                    {this.state.loading4 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                          </div>
                          <div className="mt-2">Upper Occlusal</div>
                          {this.state.upper_occlusal === "" && (
                            <div
                              className={notValidClasses.upperOcclusalValGrpCls}
                            >
                              {this.state.upperOcclusalValMsg}
                            </div>
                          )}
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/*End Upload Upper Occlusal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                      </div>
                      <div className="adjus_photos_row_grid">
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Upload Lower Occlusal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        <div
                          xs="12"
                          md="6"
                          lg="3"
                          className="text-center title_active p-0 mt-3 mb-3"
                        >
                          <div className="buttons-container2 ">
                            <input
                              type="file"
                              id="lower_occlusal_photo"
                              onChange={(e) =>
                                this.LowerOcclusalOnUpload(
                                  e,
                                  "lower_occlusal_photo"
                                )
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                            {
                              <div className="upload-container m-auto">
                                {
                                  <label
                                    htmlFor="lower_occlusal_photo"
                                    className="upload"
                                  >
                                    <img
                                      src={
                                        this.state.lower_occlusal_photo
                                          ? this.state.lower_occlusal_photo
                                          : `https://sandbox.access.eonaligner.com/assets/lower_occlusal-62eab7310407931bc084dd262b0984d979c39d3bad35eaf32b473574b0e27193.png`
                                      }
                                      className="p-3"
                                      width="100%"
                                      alt="lower_occlusal_photo"
                                    />
                                    {this.state.loading5 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                          </div>
                          <div className="mt-2">Lower Occlusal</div>
                          {this.state.lower_occlusal === "" && (
                            <div
                              className={notValidClasses.lowerOcclusalValGrpCls}
                            >
                              {this.state.lowerOcclusalValMsg}
                            </div>
                          )}
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Upload Lower Occlusal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Upload Left Buccal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        <div
                          xs="12"
                          md="6"
                          lg="3"
                          className="text-center title_active p-0 mt-3 mb-3"
                        >
                          <div className="buttons-container2 ">
                            <input
                              type="file"
                              id="left_buccal_photo"
                              onChange={(e) =>
                                this.LeftBuccalOnUpload(e, "left_buccal_photo")
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                            {
                              <div className="upload-container m-auto">
                                {
                                  <label
                                    htmlFor="left_buccal_photo"
                                    className="upload"
                                  >
                                    <img
                                      src={
                                        this.state.left_buccal_photo
                                          ? this.state.left_buccal_photo
                                          : `https://sandbox.access.eonaligner.com/assets/left_buccal-3f38360956e9f6ea1e8cd8201c7be711988bc6dcb7eab22ca239b87c41dba4a7.png`
                                      }
                                      className="p-3"
                                      width="100%"
                                      alt="left_buccal_photo"
                                    />
                                    {this.state.loading6 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                          </div>
                          <div className="mt-2">Left Buccal</div>
                          {this.state.left_buccal === "" && (
                            <div
                              className={notValidClasses.leftBuccalValGrpCls}
                            >
                              {this.state.leftBuccalValMsg}
                            </div>
                          )}
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/*End  Upload Left Buccal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Upload Frontal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        <div
                          xs="12"
                          md="6"
                          lg="3"
                          className="text-center title_active p-0 mt-3 mb-3"
                        >
                          <div className="buttons-container2 ">
                            <input
                              type="file"
                              id="frontal_photo"
                              onChange={(e) =>
                                this.FrontalOnUpload(e, "frontal_photo")
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                            {
                              <div className="upload-container m-auto">
                                {
                                  <label
                                    htmlFor="frontal_photo"
                                    className="upload"
                                  >
                                    <img
                                      src={
                                        this.state.frontal_photo
                                          ? this.state.frontal_photo
                                          : `https://sandbox.access.eonaligner.com/assets/frontal-96de170d4720e7d5630d14a55839356c2279d54ad69a9ad00026bdf9df10cdb7.png`
                                      }
                                      className="p-3"
                                      width="100%"
                                      alt="frontal"
                                    />
                                    {this.state.loading7 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                          </div>
                          <div className="mt-2">Frontal</div>
                          {this.state.frontal === "" && (
                            <div className={notValidClasses.frontalValGrpCls}>
                              {this.state.frontalValMsg}
                            </div>
                          )}
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/*End  Upload Frontal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Upload Right Buccal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                        <div
                          xs="12"
                          md="6"
                          lg="3"
                          className="text-center title_active p-0 mt-3 mb-3"
                        >
                          <div className="buttons-container2 ">
                            <input
                              type="file"
                              id="right_buccal"
                              onChange={(e) =>
                                this.RightBuccalOnUpload(e, "right_buccal")
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                            {
                              <div className="upload-container m-auto">
                                {
                                  <label
                                    htmlFor="right_buccal"
                                    className="upload"
                                  >
                                    <img
                                      src={
                                        this.state.right_buccal_photo
                                          ? this.state.right_buccal_photo
                                          : `https://sandbox.access.eonaligner.com/assets/right_buccal-00932b39e06fc508f1356955bce568a3576b7481f4becd77fe67491bcb664b81.png`
                                      }
                                      className="p-3"
                                      width="100%"
                                      alt="right buccal"
                                    />
                                    {this.state.loading8 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                          </div>
                          <div className="mt-2">Right Buccal</div>
                          {this.state.right_buccal === "" && (
                            <div
                              className={notValidClasses.rightBuccalValGrpCls}
                            >
                              {this.state.rightBuccalValMsg}
                            </div>
                          )}
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/*End  Upload Right Buccal Photo */}
                        {/*--------------------------------------------------------------------------------*/}
                      </div>
                    </div>
                    <div xs="12" md="1" lg="1"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/*--------------------------------------------------------------------------------*/}
        {/*Fix Photos Step */}
        {/*--------------------------------------------------------------------------------*/}
        {this.fix && (
          <button
            type="button"
            id="finish-button"
            className="btn btn-prev btn-primary btn-lg pull-right"
            onClick={() => {
              this.isValidated();
            }}
          >
            Finish
          </button>
        )}
        {/*--------------------------------------------------------------------------------*/}
        {/*End Fix Photos Step */}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
