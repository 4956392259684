/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { selectContent } from "../../redux/language/reducer";
import { errorToaster } from "../../services/toast";

import ReactChipInput from "react-chip-input";
import enterSvg from "../../assets/images/enter.svg";

export const NumbersChipsCombobox = ({
  id,
  maxStep = 9999,
  onChange = () => {},
  className = "",
  value = [],
  disabled,
}) => {
  const content = useSelector(selectContent);
  const inputRef = useRef(null);

  const [chips, setChips] = useState([]);

  const addChip = (value) => {
    if (!value) {
      errorToaster(content.number_error, "Warning");
      return;
    }
    // check if is valid number
    if (
      isNaN(value) ||
      chips.includes(Number(value)) ||
      value > maxStep ||
      value < 1
    ) {
      errorToaster(content.valid_steps_error, "Warning");
      return;
    }

    setChips((prev) => [...prev, Number(value)]);
  };

  const removeChip = (index) => {
    if (index) {
      setChips((prev) => prev.filter((_, idx) => idx !== index));
    } else {
      setChips((prev) => prev.slice(0, prev.length - 1));
    }
  };

  const handleDeleteChip = (event) => {
    if (event.key === "Backspace") {
      if (!inputRef.current.formControlRef.current.value) {
        removeChip();
      }
    }
  };

  const handleShowSvg = (event) => {
    const key = event.key;
    const value = event.target.value;
    // adds and removes the svg icon
    if (!!value) {
      inputRef.current?.formControlRef?.current?.parentElement?.classList.add(
        "custom-form-control"
      );
      if (
        isNaN(value) ||
        chips.includes(Number(value)) ||
        value > maxStep ||
        value < 1
      ) {
        inputRef.current?.formControlRef?.current?.parentElement?.classList.add(
          "custom-form-control-invalid"
        );
      } else {
        inputRef.current?.formControlRef?.current?.parentElement?.classList.remove(
          "custom-form-control-invalid"
        );
      }
    } else {
      inputRef.current?.formControlRef?.current?.parentElement?.classList.remove(
        "custom-form-control",
        "custom-form-control-invalid"
      );
    }
  };

  useEffect(() => {
    value?.length && setChips(!disabled ? value : []);
  }, [value, disabled]);

  useEffect(() => {
    onChange(chips);
  }, [chips]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.formControlRef?.current?.parentElement?.classList.remove(
        "custom-form-control"
      );

      inputRef.current?.formControlRef?.current?.setAttribute(
        "placeholder",
        content.write_step_no
      );

      inputRef.current?.formControlRef?.current?.classList.add(
        "tw-ea-relative",
        "tw-ea-w-fit",
        "tw-ea-max-w-full"
      );

      inputRef.current?.formControlRef?.current?.addEventListener(
        "keyup",
        handleShowSvg
      );

      inputRef.current?.formControlRef?.current?.addEventListener(
        "keydown",
        handleDeleteChip
      );

      return () => {
        inputRef.current?.formControlRef?.current?.removeEventListener(
          "keyup",
          handleShowSvg
        );

        inputRef.current?.formControlRef?.current?.removeEventListener(
          "keydown",
          handleDeleteChip
        );
      };
    }
  }, [inputRef.current, chips]);

  return (
    <>
      <ReactChipInput
        tabIndex={-1}
        ref={inputRef}
        classes={clsx(
          "",
          // input styling
          "tw-ea-p-2 tw-ea-bg-[#F3F3F3]",
          // focused input styling
          // "focus-within:tw-ea-bg-white [&_.form-control:focus]:!tw-ea-bg-white",
          // Chips styling
          "[&>div>*]:!tw-ea-px-[4px] [&>div>*]:!tw-ea-py-1",
          // inner Chips styling
          "[&>div>*>div]:tw-ea-relative [&>div>*>div:hover]: [&>div>*>div]:!tw-ea-rounded-[4px] [&>div>*>div]:!tw-ea-text-center [&>div>*>div]:!tw-ea-leading-[30px] [&>div>*>div]:tw-ea-cursor-default [&>div>*>div]:!tw-ea-p-0 [&>div>*>div]:!tw-ea-w-[32px] [&>div>*>div]:!tw-ea-border-0",
          // focused inner Chips styling
          // "[&:focus-within>div>*>div]:tw-ea-bg-[#F3F3F3]",
          // x svg styling
          "[&_svg]:tw-ea-absolute [&_svg]:tw-ea-left-[11px] [&_svg]:tw-ea-top-[-9px] [&_svg]:!tw-ea-scale-[0.6]  [&_svg:hover]:!tw-ea-scale-[0.8] [&_svg]:tw-ea-fill-[#ff0000]",
          // x first path styling
          "[&_svg>path:first-child]:!tw-ea-stroke-[#ff0000] [&_svg>path:first-child]:tw-ea-stroke-[0.5px]",
          // prop stylings
          className,
          {
            "[&_input]:tw-ea-pointer-events-none [&_input]:tw-ea-cursor-not-allowed [&_*]:tw-ea-bg-[#d5d5d5] tw-ea-bg-[#d5d5d5]":
              disabled,
          }
        )}
        className="tw-ea-transform"
        chips={chips}
        onSubmit={(value) => addChip(value)}
        onRemove={(index) => removeChip(index)}
      />
      <style>
        {`
          .custom-form-control-invalid::after {
            filter: opacity(0.1);
          }
          .custom-form-control {
            position: relative;
          }
          .custom-form-control::after {
            content: url(${enterSvg});
            position: absolute;
            left: calc(100% - 60px);
            top: calc(50% - 10px);
            bottom: 0;
            width: 60px;
            height: 20px;
          }
          :root {
            --react-chip-input__box-shadow-color: #00C7B1;
            --react-chip-input__chip-bg-color: white;
          }
        `}
      </style>
    </>
  );
};
