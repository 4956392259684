import React, { Component } from "react";
import { Card, CardTitle, CardImg } from "reactstrap";
import { connect } from "react-redux";

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import back from "../../assets/scss/all/icons/navBar-record-icons/back.svg";
import backUp from "../../assets/scss/all/icons/navBar-record-icons/backUp.svg";
import backUp_active from "../../assets/scss/all/icons/navBar-record-icons/backUp_active.svg";
import reloud from "../../assets/scss/all/icons/navBar-record-icons/reloud.svg";
import zoomIn from "../../assets/scss/all/icons/navBar-record-icons/zoomIn.svg";
import zoomOut from "../../assets/scss/all/icons/navBar-record-icons/zoomOut.svg";
import x from "../../assets/scss/all/icons/navBar-record-icons/x.svg";
import x_active from "../../assets/scss/all/icons/navBar-record-icons/x_active.svg";
import zoomIn_active from "../../assets/scss/all/icons/navBar-record-icons/zoomIn_active.svg";
import zoomOut_active from "../../assets/scss/all/icons/navBar-record-icons/zoomOut_active.svg";
import back_active from "../../assets/scss/all/icons/navBar-record-icons/back_active.svg";
import reloud_active from "../../assets/scss/all/icons/navBar-record-icons/reloud_active.svg";
import file_icon from "../../assets/images/caseActions/file.svg";
import { MissingPhotosXraysData, PhotosXraysDataMobileDesktp, XraysData } from "../../helpers/photos-xrays";
const mapStateToProps = (state) => ({
  ...state,
});
class Gallery extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state  */
    this.state = {
      lightbox: false,
      lightBoxArray: [],
      selectedItem: 0,
      width: window.innerWidth
    };
    this.myRef = React.createRef();
  }
  componentDidMount() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  }
  /*--------------------------------------------------------------------------------*/
  /* show hide light box                                                            */
  /*--------------------------------------------------------------------------------*/
  toggleLightbox = () => {
    this.setState({ lightbox: !this.state.lightbox });
  };

  /*--------------------------------------------------------------------------------*/
  /* rename image                                                            */
  /*--------------------------------------------------------------------------------*/
  ImageName(image) {
    let without_thumb = image.replace(/_thumb/g, "");
    let test = without_thumb.replace(/_/g, " ");
    return this.camelize(test);
  }
  /*--------------------------------------------------------------------------------*/
  /* image name with camelcase                                                      */
  /*--------------------------------------------------------------------------------*/
  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match) {
      return match.toUpperCase();
    });
  }

  render() {
    const { content } = this.props.languageReducer;
    const { width } = this.state
    const { photos_xrays, impressions_new, case_type } = this.props.caseReducer.case
    const PhotosXraysData = [
      {
        url: photos_xrays?.front_smiling,
        title: "Front (smiling)",
        key: "front_smiling",
        uploadKey: "UploadFrontSmiligPhoto",
      },

      {
        url: photos_xrays.front_pose,
        title: "Front (non-smiling)",
        key: "front_pose",
        uploadKey: "UploadFrontPosePhoto",
      },
      {
        url: photos_xrays.profile,
        title: "Side profile",
        key: "profile",
        uploadKey: "UploadProfilePhoto",
      },
      {
        url: "",
        title: "",
        key: "",
      },
      {
        url: photos_xrays.panoramic,
        title: "Panoramic x-ray",
        key: "panoramic_xray",
        uploadKey: "UploadPanoramicPhoto",
      },
      {
        url: photos_xrays.upper_occlusal,
        title: "Upper occlusal",
        key: "upper_occlusal",
        uploadKey: "UploadUpperOcclusalPhoto",
      },
      {
        url: photos_xrays.frontal,
        title: "Frontal",
        key: "frontal",
        uploadKey: "UploadFrontalPhoto",
      },
      {
        url: photos_xrays.lower_occlusal,
        title: "Lower occlusal",
        key: "lower_occlusal",
        uploadKey: "UploadLowerOcclusalPhoto",
      },
      {
        url: "",
        title: "",
        key: "",
      },
      {
        url: photos_xrays.cephalometric,
        title: "Cephalometric x-ray",
        key: "cephalometric_xray",
        uploadKey: "UploadCephalometricPhoto",
      },
      {
        url: photos_xrays.right_buccal,
        title: "Right buccal",
        key: "right_buccal",
        uploadKey: "UploadRightBuccalPhoto",
      },
      {
        url: "",
        title: "",
        key: "",
      },
      {
        url: photos_xrays.left_buccal,
        title: "Left buccal",
        key: "left_buccal",
        uploadKey: "UploadLeftBuccalPhoto",
      },
      {
        url: "",
        title: "",
        key: "",
      },
      {
        url: "",
        title: "",
        key: "",
      },
    ];
    const PhotosData = [
      {
        url: photos_xrays?.front_smiling,
        title: "Front (smiling)",
        key: "front_smiling",
        uploadKey: "UploadFrontSmiligPhoto",
      },

      {
        url: photos_xrays?.front_pose,
        title: "Front (non-smiling)",
        key: "front_pose",
        uploadKey: "UploadFrontPosePhoto",
      },
      {
        url: photos_xrays?.profile,
        title: "Side profile",
        key: "profile",
        uploadKey: "UploadProfilePhoto",
      },
      {
        url: photos_xrays?.frontal,
        title: "Frontal",
        key: "frontal",
        uploadKey: "UploadFrontalPhoto",
      },
      {
        url: photos_xrays?.upper_occlusal,
        title: "Upper occlusal",
        key: "upper_occlusal",
        uploadKey: "UploadUpperOcclusalPhoto",
      },

      {
        url: photos_xrays?.lower_occlusal,
        title: "Lower occlusal",
        key: "lower_occlusal",
        uploadKey: "UploadLowerOcclusalPhoto",
      },

      {
        url: photos_xrays?.right_buccal,
        title: "Right buccal",
        key: "right_buccal",
        uploadKey: "UploadRightBuccalPhoto",
      },

      {
        url: photos_xrays?.left_buccal,
        title: "Left buccal",
        key: "left_buccal",
        uploadKey: "UploadLeftBuccalPhoto",
      },

    ];
    const Xrays_data = [{
      url: photos_xrays?.panoramic,
      title: "Panoramic x-ray",
      key: "panoramic_xray",
      uploadKey: "UploadPanoramicPhoto",
    },
    {
      url: photos_xrays?.cephalometric,
      title: "Cephalometric x-ray",
      key: "cephalometric_xray",
      uploadKey: "UploadCephalometricPhoto",
    },
    ]
    const Photos_xrays_data = width < 840 ? PhotosData : PhotosXraysData
    return (

      <div className=" ">

        <style>{`
        .lb-icon-arrow {
         background-image: url(${backUp});
      }
      .lb-icon-arrow:active{
        background-image: url(${backUp_active});
     }
      .lb-icon-zoomin{
        background-image: url(${zoomIn});
      }
       .lb-icon-zoomin:active{
        background-image: url(${zoomIn_active});
       }
      .lb-icon-zoomout{
          background-image: url(${zoomOut});
      }
      .lb-icon-zoomout:active{
        background-image: url(${zoomOut_active});
    }
      .lb-icon-rotate {
          background-image: url(${back});
      }
      .lb-icon-rotate:active{
        background-image: url(${back_active});
    }
      .lb-icon-close{
          background-image: url(${x});
      }
      .lb-icon-close:active{
        background-image: url(${x_active});
    }
      .lb-icon-reset {
        background-image: url(${reloud})
      }
      .lb-icon-reset:active{
        background-image: url(${reloud_active})
      }
        `}</style>

        <div className="gallery-container cpx1">
          <div
            onWheel={(event) => {
              // zoom in / out on wheel
              if (event.deltaY > 0) {
                this.myRef.current.applyZoom("out");
              }
              if (event.deltaY < 0) {
                this.myRef.current.applyZoom("in");
              }
            }}
          >
            {/*--------------------------------------------------------------------------------*/}
            {/* lightbox allow to zoom in / zoom out / rotate images  */}
            {/*--------------------------------------------------------------------------------*/}

            {this.state.lightbox &&
              (this.state.lightBoxArray.length === 1 ? (
                <Lightbox
                  ref={this.myRef}
                  onClose={this.toggleLightbox}
                  zoomStep={0.1}
                  image={this.state.lightBoxArray[0].url}
                  title={this.state.lightBoxArray[0].title}
                  doubleClickZoom={2}
                ></Lightbox>

              ) : (
                <Lightbox
                  ref={this.myRef}
                  images={this.state.lightBoxArray}
                  startIndex={this.state.selectedItem}
                  onClose={this.toggleLightbox}
                  zoomStep={0.1}
                  doubleClickZoom={2}

                ></Lightbox>
              ))}
          </div>
          {/*--------------------------------------------------------------------------------*/}
          {/*  End lightbox   */}
          {/*--------------------------------------------------------------------------------*/}

          {/*--------------------------------------------------------------------------------*/}
          {/*  Impression type    */}
          {/*--------------------------------------------------------------------------------*/}
          <div>
            <div className="title_active record-color button-text">
              {content.main_impressions_method_selected}
            </div>

            <div className="cmt2 button-text">{impressions_new.impressions_method}</div>
          </div>
          {/*--------------------------------------------------------------------------------*/}
          {/*  End Impression type    */}
          {/*--------------------------------------------------------------------------------*/}

          {/*--------------------------------------------------------------------------------*/}
          {/*  Impresssions values    */}
          {/*--------------------------------------------------------------------------------*/}
          <div>
            <div className="title_active cmt2 record-color button-text">{content.impressions} </div>

            <div>
              {" "}
              {!impressions_new
                .lower_arch_url &&
                !impressions_new
                  .upper_arch_url && (
                  <div className="cmt2 button-text">{content.no_impressions_were_uploaded_to_access}</div>
                )}
              {impressions_new.impressions_method !==
                "upload" &&
                impressions_new
                  .impressions_third_party_note && (
                  <div>
                    <div className="title_active cmt2 record-color button-text">
                      {content.impressions_note_sent_by_dr}
                    </div>
                    <div className="cmt2 button-text">
                      {
                        impressions_new
                          .impressions_third_party_note
                      }
                    </div>
                  </div>
                )}
              <div className="impressions_grid_details cmb2">


                {impressions_new.lower_arch_url && (
                  <div className="s_card_grid actions-card"
                    style={{
                      pointerEvents: `${impressions_new.lower_arch_url.match(
                        /http([^&]+)/
                      ) &&
                        impressions_new
                          .lower_arch_url !== null
                        ? ""
                        : "none"
                        }`,
                    }}
                    disabled={
                      impressions_new.lower_arch_url.match(
                        /http([^&]+)/
                      ) &&
                        impressions_new
                          .lower_arch_url !== null
                        ? false
                        : true
                    }
                    onClick={() => {
                      window.open(
                        impressions_new
                          .lower_arch_url,
                        "_blank"
                      );
                    }}
                  >
                    <div className="text-center">
                      <img src={file_icon} alt={content.lower_impression} />
                    </div>
                    <div className="label-1-text">{content.lower_impression}</div>
                  </div>
                )}
                {impressions_new
                  .upper_arch_url && (

                    <div className="s_card_grid actions-card"
                      style={{
                        pointerEvents: `${impressions_new.upper_arch_url.match(
                          /http([^&]+)/
                        ) &&
                          impressions_new
                            .upper_arch_url !== null
                          ? ""
                          : "none"
                          }`,
                      }}
                      disabled={
                        impressions_new.upper_arch_url.match(
                          /http([^&]+)/
                        ) &&
                          impressions_new
                            .upper_arch_url !== null
                          ? false
                          : true
                      }
                      onClick={() => {
                        window.open(
                          impressions_new
                            .upper_arch_url,
                          "_blank"
                        );
                      }}
                    >
                      <div className="text-center">
                        <img src={file_icon} alt={content.upper_impression} />
                      </div>
                      <div className="label-1-text">{content.upper_impression}</div>
                    </div>
                  )}


              </div>

            </div>
          </div>
          {/*--------------------------------------------------------------------------------*/}
          {/* End Impresssions values    */}
          {/*--------------------------------------------------------------------------------*/}

          {/*--------------------------------------------------------------------------------*/}
          {/* Photos  & xrays*/}
          {/*--------------------------------------------------------------------------------*/}


          {case_type !== "Retainer" && (
            <>
              <div className="photos_xrays_grid_title cmy3">
                <div className="photos_title record-photo button-text">{content.photos}</div>
                <div className="xrays_title record-photo button-text">{content.x_rays}</div>
              </div>
              <div className="photos_xrays_title2 cmy3 button-text">
                <div>{content.photos}</div>
              </div>
              <div className="main-photos-grid ">
                {Photos_xrays_data.map((value, index) => (
                  <div key={index}>
                    {value.key && (
                      <>
                        <Card
                          onClick={() => {
                            // photos with real value
                            let filteredphoto = Photos_xrays_data.filter((key, index) => {
                              return (
                                key?.url?.match(/http([^&]+)/) && key.url !== null
                              );
                            });
                            this.setState({
                              lightbox: !this.state.lightbox,
                              lightBoxArray: filteredphoto,
                              selectedItem: filteredphoto.findIndex(x => x.key === value.key),
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            pointerEvents: `${value?.url?.match(/http([^&]+)/) &&
                              value?.url !== null
                              ? ""
                              : "none"
                              }`,
                          }}
                          className={`cp2  c-card-with-img`}>
                          <CardTitle className="cmb2 body-text">
                            <div className="card-title-grid">
                              <div>
                                {<span className="not-active-img body-text">{value.title} </span>}
                              </div>
                            </div>
                          </CardTitle>

                          <div className="image-overlay-container">
                            <CardImg
                              src={
                                value?.url?.match(/http([^&]+)/) ? value.url : MissingPhotosXraysData[value.key]
                              }
                              className={`c-card-img cursor-pointer`}
                            />

                          </div>
                        </Card>

                      </>
                    )}
                  </div>
                ))}
              </div>
              {width < 840 &&
                <>
                  <div className="photos_xrays_title2 cmy3 button-text">
                    <div>{content.x_rays}</div>
                  </div>
                  <div className="main-photos-grid ">
                    {Xrays_data.map((value, index) => (
                      <div key={index}>
                        {value.key && (
                          <>
                            <Card
                              onClick={() => {
                                // photos with real value
                                let filteredphoto = Xrays_data.filter((key, index) => {
                                  return (
                                    key?.url?.match(/http([^&]+)/) && key.url !== null
                                  );
                                });
                                this.setState({
                                  lightbox: !this.state.lightbox,
                                  lightBoxArray: filteredphoto,
                                  selectedItem: filteredphoto.findIndex(x => x.key === value.key),
                                });
                              }}
                              style={{
                                cursor: "pointer",
                                pointerEvents: `${value?.url?.match(/http([^&]+)/) &&
                                  value?.url !== null
                                  ? ""
                                  : "none"
                                  }`,
                              }}
                              className={`cp2  c-card-with-img`}>
                              <CardTitle className="cmb2 body-text">
                                <div className="card-title-grid">
                                  <div>
                                    {<span className="not-active-img body-text">{value.title} </span>}
                                  </div>
                                </div>
                              </CardTitle>

                              <div className="image-overlay-container">
                                <CardImg
                                  src={
                                    value?.url?.match(/http([^&]+)/) ? value.url : MissingPhotosXraysData[value.key]
                                  }
                                  className={`c-card-img cursor-pointer`}
                                />

                              </div>
                            </Card>

                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </>}
            </>
          )}


          {/*--------------------------------------------------------------------------------*/}
          {/* End Photos & xrays  */}
          {/*--------------------------------------------------------------------------------*/}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(Gallery);
