import React, { Component } from "react";
import {
  getNotifications,
  markNotificationsAsRead,
  markOneActionItemsAsRead,
} from "../../api/api";
import moment from "moment";
import CustomeHeader from "../../components/header/CustomeHeader";
import Back from "../../components/back/Back";

export default class Notifications extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);

    /* init state */
    this.state = {
      Notifications: this.props.location.state.data,
      id: this.props.location.state.id,
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* componentDidMount */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    getNotifications(this.state.id).then((res) => {
      let FilteredNotification = res.action_items.filter((key, index) => {
        return key.actionable_type !== "RestorativeCase";
      });
      FilteredNotification.sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1));
      this.setState({
        Notifications: FilteredNotification,
      });
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*KeyDictionary                                                            */
  /*--------------------------------------------------------------------------------*/
  KeyDictionary(key) {
    return key.split(".")[1].split("_").join(" ");
  }
  /*--------------------------------------------------------------------------------*/
  /*MarkNotificationsAsRead                                                         */
  /*--------------------------------------------------------------------------------*/
  MarkNotificationsAsRead() {
    markNotificationsAsRead(this.state.id).then((res) => {
      this.setState({
        Notifications: [],
      });
    });
  }

  /*--------------------------------------------------------------------------------*/
  /*Mark  One Action Items As Read */
  /*--------------------------------------------------------------------------------*/
  MarkOneActionItemsAsRead = async (
    doctor_ID,
    notificationId,
    notificationCase
  ) => {
    await markOneActionItemsAsRead(doctor_ID, notificationId)
      .then(() => {
        getNotifications(this.props.location.state.id).then((res) => {
          let FilteredNotification = res.action_items.filter((key, index) => {
            return key.actionable_type !== "RestorativeCase";
          });
          FilteredNotification.sort((a, b) =>
            new Date(a) < new Date(b) ? 1 : -1
          );
          this.setState({
            Notifications: FilteredNotification,
          });
        });
      })
      .catch((error) => {
        console.log(error, "error");
      });

    this.props.history.push(`/case/${notificationCase}`);
  };

  /*--------------------------------------------------------------------------------*/
  /*componentWillUnmount                                                         */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const doctor_ID = this.props.location.state.id;
    const { Notifications } = this.state;
    let counter_Notifications = Notifications.filter((value, index) => {
      return value.read === false;
    });

  

    return (
      <div>
        <style>
          {`
              .fa-trash-alt{
                color:red;
                cursor:pointer;
                font-size:18px
              },
            
              `}
        </style>
        <div>
          <Back />
          {/* <span
              className="pull-right"
              title="Mark All Notifications As Read"
              onClick={() => this.MarkNotificationsAsRead()}
            >
              <i className="fas fa-trash-alt " />
            </span> */}
          <div className="cpx5">
            <div className="cmb3">
              <CustomeHeader title="Notifications" />
            </div>
            <div className="cmb1 notifications-num">{`${this.state.Notifications.length}  Notifications`}</div>

            {this.state.Notifications.map((notification, index) => {
              return (
                <div
                  className="notification-card2"
                  key={index}
                  onClick={() => {
                    this.MarkOneActionItemsAsRead(
                      doctor_ID,
                      notification.id,
                      notification.case
                    );
                  }}
                >
                  <div className="notification-card-title">
                    <div
                      className={`${
                        notification.read === true ? "circle-gray" : "circle"
                      }`}
                    >
                      <div className="inner-circle"></div>
                    </div>
                    <div
                      className={`${
                        notification.read === true
                          ? "notification-status-gray"
                          : "notification-status"
                      }`}
                    >
                      {this.KeyDictionary(notification.key)}
                    </div>
                    <div className="dot">.</div>
                    <div className="notification-date">
                      {`${moment(notification.created_at).format(
                        "DD/MM/YYYY"
                      )}`}
                    </div>
                  </div>

                  <div>
                    {`${notification.case_uid} - ${notification.patient?.first_name} ${notification.patient?.last_name}`}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
