import React from "react";
import { connect } from "react-redux";
import { Button, Input } from "reactstrap";
import { ReactComponent as Share } from "../../../assets/images/Ts-navBar/share.svg";
import copy_link from "../../../assets/images/caseActions/copy_link.svg";
import share_link_mobile from "../../../assets/images/caseActions/share_link_mobile.svg";
import { infoToaster } from "../../../services/toast";

export const ShareTs = (props) => {
  const { content } = props.languageReducer;
  const { shareTS, toggleShare, url } = props;

  return (
    <div>
      <div className="share_link_toggle">
        <Share
          className={`share_link_toggle
     ${shareTS ? "not_active_header_tab" : "not_active_header_tab"} 
       cursor-pointer`}
          alt="share"
          onClick={toggleShare}
        />
        {shareTS && url && (
          <div className="share_link_content">
            <img
              src={share_link_mobile}
              alt="share_link"
              className="share_img_link"
              loading="lazy"
            />

            <div className="share_img_content">
              <div>
                <div className="share_link_content_grid">
                  <div className="btn-group copy-link-group">
                    <Input
                      bsSize="large"
                      autoComplete="off"
                      type="text"
                      className="custome-input-text copy-link-text"
                      defaultValue={url}
                      disabled
                    />
                    <span id="copy_link">
                      <img
                        src={copy_link}
                        alt="copy-link-icon"
                        loading="lazy"
                      />
                    </span>
                  </div>
                  <Button
                    className=" btn green-green-btn button-seme-bold-text"
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                      toggleShare();
                      infoToaster(
                        content.link_is_copied,
                        content.Attention_needed
                      );
                    }}
                  >
                    {content.copy_link}
                  </Button>
                </div>

                <div className="green-title cmt1"> {content.link_valid}</div>
              </div>
              <div
                onClick={() => {
                  toggleShare();
                }}
              >
                {" "}
                <i className="fas fa-times c-pointer "></i>{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({...state});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShareTs);
