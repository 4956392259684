export const getPriceJOEN = () => {
    return `
    <div class="header_text_bold">Eon Basic </div>
    <div class="bold_text cpy1"> Eon Basic Treatment Package Price</div>
  
    <div class="cpy1">
    <div class="normal_text">Single Arch:</div>
    <div class="normal_text">JOD358</div>
    </div>
  
    <div class="cpy1">
    <div class="normal_text">Dual Arch:</div>
    <div class="normal_text">JOD511</div>
    </div>
  
    <div class="bold_text cpy1"> Eon Basic Treatment Setups</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Up to four (4) Treatment Setups for each of the initial Aligner Steps and Refinements.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Additional Treatment Setups shall be charged JOD71 each.  </div>
  
  
    <div class="bold_text cpy1"> Eon Basic Aligner Step</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Up to ten (10) initial Aligner Steps to be shipped in one (1) single shipment.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Replacement Aligner Steps shall be charged JOD11 per dental arch and JOD10 shipping fees. </div>
  
    <div class="bold_text cpy1">Eon Basic Refinements</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Refinements to be provided in one (1) single shipment.</div>
  
    <div class="bold_text cpy1"> Eon Basic Retainer Sets</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">One (1) Retainer Set shipped in one (1) single shipment.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Additional Retainers shall be charged at JOD25 per dental arch and JOD10 shipping fees.</div>
  
    <div class="bold_text cpy1">Eon Basic Expiration Date</div>
    <div class="normal_text">Nine (9) months</div>
  
  
  
  
    <div class="header_text_bold">Eon Plus  </div>
    <div class="bold_text cpy1"> Eon Plus Treatment Package Price  </div>
  
    <div class="cpy1">
    <div class="normal_text">Single Arch:</div>
    <div class="normal_text">JOD719</div>
    </div>
  
    <div class="cpy1">
    <div class="normal_text">Dual Arch:</div>
    <div class="normal_text">JOD989</div>
    </div>
  
    <div class="bold_text cpy1"> Eon Plus Treatment Setups</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Up to four (4) Treatment Setups for each of the initial Aligner Steps and Refinements.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Additional Treatment Setups shall be charged JOD71 each.</div>
  
  
    <div class="bold_text cpy1">Eon Plus Aligner Steps</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Up to twenty four (24) initial Aligner Steps to be shipped in one (1) single shipment.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Replacement Aligner Steps shall be charged JOD11 per dental arch and JOD10 shipping fees.</div>
  
    <div class="bold_text cpy1">Eon Plus Refinements</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Refinements to be provided in up to three (3) separate shipments.</div>
  
    <div class="bold_text cpy1"> Eon Plus Retainer Sets</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">One (1) Retainer Set shipped in one (1) single shipment.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Additional Retainers shall be charged at JOD25 per dental arch and JOD10 shipping fees.</div>
  
    <div class="bold_text cpy1">Eon Plus Expiration Date</div>
    <div class="normal_text">Eighteen (18) months</div>



    
  
    <div class="header_text_bold">Eon Pro  </div>
    <div class="bold_text cpy1"> Eon Pro Treatment Package Price  </div>
  
    <div class="cpy1">
    <div class="normal_text">JOD1,288</div>
    </div>
   
    <div class="bold_text cpy1"> Eon Pro Treatment Setups</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Unlimited number of Treatment Setups for each of the initial Aligner Steps and Refinements.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Treatment Setups that exceed the ‘fair-use-policy’ shall be charged
    as a Case Extension at the appropriate Treatment Package price</div>
  
  
    <div class="bold_text cpy1">Eon Pro Aligner Steps</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Unlimited number of initial Aligner Steps to be shipped at a rate of no more than 
    twenty four (24) Aligner Steps per single shipment.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Aligner Steps that exceed the ‘fair-use-policy’ shall be charged as a Case Extension 
    at the appropriate Treatment Package price.

    <span class="cpt1">Replacement Aligner Steps which shall be charged JOD11 per dental arch and JOD10 shipping fees.</span>
    </div>
  
    <div class="bold_text cpy1">Eon Pro Refinements</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Refinements to be provided in an unlimited number of shipments at a rate of no more than twenty
    four (24) Aligner Steps per single shipment.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Refinements that exceed the ‘fair-use-policy’ which shall be charged shall be charged as a
    Case Extension at the appropriate Treatment Package price.</div>
  
    <div class="bold_text cpy1"> Eon Pro Retainer Sets</div>
    <div class="normal_text_underlined" >Included: </div>
    <div class="normal_text cpb1">Two (2) Retainer Sets to be shipped together in one (1) single shipment.</div>
  
    <div class="normal_text_underlined" >Not Included: </div>
    <div class="normal_text">Additional Retainers shall be charged at JOD25 per dental arch and JOD10 shipping fees.</div>
  
    <div class="bold_text cpy1">Eon Pro Expiration Date</div>
    <div class="normal_text">Thirty six (36) months</div>
  
    <div class="header_text_bold">Cancelation Fees  </div>
    <div class="normal_text">JOD71</div>

    <div class="header_text_bold">STL Reconversion Fees  </div>
    <div class="normal_text">Subsequent physical teeth impressions replacing those that are not in the form and quality accepted by Eon Dental shall be charged JOD35</div>
    `;
  };