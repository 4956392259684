import React, { Component } from "react";
import { connect } from "react-redux";


import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";

import {
  Clear_Teeth_Data
} from "../../../../../redux/customeTeeth/action"
import CustomeHeader from "../../../../../components/header/CustomeHeader";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload))
});
class Crossbite extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.state = {
      use_elastics: ""
    }
    /* init state */
    //use_elastics
    this.use_elastics =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.useElasticWhenCorrecting || false;
    //expansion treatment Options
    this.expansion =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.treatment_Options?.expansion || false;
    //constriction treatment Options
    this.constriction =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.treatment_Options?.constriction || false;
    //combination treatment Options
    this.combination =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.treatment_Options?.combination || false;
    //elastics treatment Options
    this.elastics =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.treatment_Options?.elastics || false;


  }

  UpdateCrossBiteReducer = () => {
    let data = {
      useElasticWhenCorrecting: this.use_elastics,
      teeth: this.props.customeTeethReducer.Case_Crossbites,
      treatment_Options: {
        expansion: this.expansion,
        constriction: this.constriction,
        combination: this.combination,
        elastics: this.elastics,
      },
    };

    this.props.save("crossbite", data);
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.use_elastics = value
  }
  render() {
    const {
      expansion,
      constriction,
      combination,
    } = this;
    const { content } = this.props.languageReducer
    return (
      <div >
        <CustomeHeader title={content.crossbite} optional={false} largeFont={false}/>
        <div className="buttom-paddingFORMalc">
          <div className="control-label title_active-gco title_tertment_option title-3-text">
            {content.treatment_options}{" "}
          </div>
          <CustomInput

            className={`pt-1 ${expansion && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="expansion"
            checked={expansion}
            disabled={constriction || combination || this.props.summary}
            onChange={() => {
              this.expansion = !this.expansion;
              this.UpdateCrossBiteReducer();
            }}
            label={content.expansion_of_the_upper_arch}
          />
          <CustomInput

            className={`pt-1 ${constriction && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="constriction"
            checked={constriction}
            disabled={expansion || combination || this.props.summary}
            onChange={() => {
              this.constriction = !this.constriction;
              this.UpdateCrossBiteReducer();
            }}
            label={content.constriction_of_the_lower_arch}
          />
          <CustomInput
            className={`pt-1 ${combination && "replacment-checkbox "} button-text-select`}
            type="checkbox"
            id="combination"
            checked={combination}
            disabled={expansion || constriction || this.props.summary}
            onChange={() => {
              this.combination = !this.combination;
              this.UpdateCrossBiteReducer();
            }}
            label={content.combination_of_upper_expansion_and_lower_constriction}
          />

        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Crossbite);
