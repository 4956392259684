import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SetHybridState } from "../../../redux/hybridTreatment/action";
import Inbox from "../../inbox";
import HybridTreatment from "../HybridTreatment";
import Details from "./Details";
import Header from "./Header";
import ReviseTS from "./ReviseTS";
import Viewer from "./Viewer";
import { ReactComponent as Close } from "../../../assets/images/Ts-navBar/close.svg";

export const TreatmentPlan = (props) => {
  const { uid } = props.caseReducer.case;
  const { content } = props.languageReducer;

  const {
    details,
    showBiteRecord,
    toggle,
    url,
    mapped_advanced_modules,
    toggleViewerModal,
    GetAllData,
    ResetTabs,
    toggleApprove,
    toggleRevise,
    step,
    disapproving_data,
    disapproved_successfully,
    mapDataToAPI,
    setShowUnsavedModal,
    cancelChangesOnToothMovement,
    setDynamicState,
    showIPRData,
    current_ipr_tooth,
    current_ipr_position,
    doctorId,
    GetAlignerCase,
    GetTreatmentSetups,
    GetTretmentSetupById,
    ToggleBitsRecord,
    data,
    showToothMovement,
    showIPRToothMovement,
    finish_loading_ts,
    ToggleOptions,
    savedStep,
    clearStateToLeave2,
    hideActions,
    showActions,
    resetShowActions,
    showInbox,
    showMenu,
    toggleInbox,
    toggleMenu,
    openReviseModalMobile,
    viewerId
  } = props;

  const {
    bite_check_changed,
    teeth_movement_changed,
    ipr_changed,
    treatment_protocol_changed,
  } = props.teethIPROptions;
  const { convert_to_hybrid_treatment } = props.hybridTreatment;
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight - 60);
 // const [showInbox, setShowInbox] = useState(showInboxstate);

  useEffect(() => {
    window.addEventListener("load", updateDimensions);
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("load", updateDimensions);
      window.removeEventListener("resize", updateDimensions);
    };
  }, [width, height]);

  /* --------------------------------------------------------------------------------*/
  /* update Dimensions  */
  /* --------------------------------------------------------------------------------*/
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight - 60);
  };


  const showHeaderActions = () => {
    showActions();
    setDynamicState({showMenu:false, showinbox:false})
  };
  const closeMenu = () => {
    setDynamicState({showMenu:false})

  };

  const reviseTs = () => {
   if (width >839){
      toggleRevise();

    }else{
      openReviseModalMobile()
    }
    if (convert_to_hybrid_treatment) {
      props.SetHybridState(false);
    }
  };
  return (
    <div className="tp_viewer_container">
      <Header
        details={details}
        width={width}
        toggleMenu={toggleMenu}
        showMenu={showMenu}
        toggleInbox={toggleInbox}
        showInbox={showInbox}
        toggle={toggle}
        url={url}
        toggleApprove={toggleApprove}
        toggleRevise={reviseTs}
        hideActions={hideActions}
        showActions={showHeaderActions}
      />
      <div className="tp-grid" style={{}}>
        <Viewer
          viewerId={viewerId}
          details={details}
          showBiteRecord={showBiteRecord}
          height={height}
          width={width}
          divided={showMenu || convert_to_hybrid_treatment|| showBiteRecord|| showInbox}
        />
        {width > 839 &&
          showMenu &&
          !convert_to_hybrid_treatment &&
          !showBiteRecord && (
            <Details
              details={details}
              mapped_advanced_modules={mapped_advanced_modules}
              showBiteRecord={showBiteRecord}
              closeMenu={closeMenu}
            />
          )}
        {/* {width > 839 && convert_to_hybrid_treatment && !showBiteRecord && (
          <HybridTreatment
            toggleViewerModal={toggleViewerModal}
            GetAllData={GetAllData}
            details={details}
            ResetTabs={ResetTabs}
          />
        )} */}

        {width > 839 && showBiteRecord && (
          <ReviseTS
            step={step}
            disapproving_data={disapproving_data}
            disapproved_successfully={disapproved_successfully}
            toggle={toggle}
            toggleApprove={toggleApprove}
            mapDataToAPI={mapDataToAPI}
            setShowUnsavedModal={setShowUnsavedModal}
            cancelChangesOnToothMovement={cancelChangesOnToothMovement}
            setDynamicState={setDynamicState}
            showBiteRecord={showBiteRecord}
            showIPRData={showIPRData}
            current_ipr_tooth={current_ipr_tooth}
            current_ipr_position={current_ipr_position}
            doctorId={doctorId}
            details={details}
            GetAlignerCase={GetAlignerCase}
            GetTreatmentSetups={GetTreatmentSetups}
            GetTretmentSetupById={GetTretmentSetupById}
            ToggleBitsRecord={ToggleBitsRecord}
            data={data}
            showToothMovement={showToothMovement}
            showIPRToothMovement={showIPRToothMovement}
            finish_loading_ts={finish_loading_ts}
            mapped_advanced_modules={mapped_advanced_modules}
            ToggleOptions={ToggleOptions}
            savedStep={savedStep}
            clearStateToLeave2={clearStateToLeave2}
            showActions={showActions}
          />
        )}
        {width > 839 && showInbox && !showBiteRecord && (
          <div className="tp_details">
            <div className="menu-title">
              <div>{content.inbox}</div>
              <Close
                className="cursor-pointer"
                alt="back"
                onClick={toggleInbox}
              />
            </div>
            <Inbox className="inboxNewlayout" caseId={uid} formKey={"viewer"} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  SetHybridState: (payload) => dispatch(SetHybridState(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlan);
