import React, { Component } from "react";
import { connect } from "react-redux";
import CustomeMovementTeeth from "../../../Teeth/CustomeMovementTeeth";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});

class TeethMovementSummary extends Component {
  // constructor(props) {
  //     super(props);

  // }
  //Get the number from a string, return the value with it's per and with minuse and pluse wording
  GetNumber = (StringNum, per, moreThanText, lessThanText) => {
    let num = Number(StringNum.split(" ")[0]);
    return `${Math.abs(num)} ${per} ${num >= 0 ? moreThanText : lessThanText}`;
  };
  CheckFirstNum = (num_One, num_Two) => {
    let num1 = Number(num_One.split(" ")[0]) || false;
    let num2 = Number(num_Two.split(" ")[0]) || false;
    return num1 !== num2;
  };
  checkAttachments = (newData, oldData) => {
    if (newData.length && oldData.length) {
      if (newData.length !== oldData.length) {
        return true;
      } else {
        for (var x = 0; x < newData.length; x++) {
          if (
            newData[x].step !== oldData[x].step ||
            newData[x].type !== oldData[x].type
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  checkElastic = (newData, oldData) => {
    if (newData.length && oldData.length) {
      if (newData.length !== oldData.length) {
        return true;
      } else {
        for (var x = 0; x < newData.length; x++) {
          if (
            newData[x].from !== oldData[x].from ||
            newData[x].to !== oldData[x].to
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  render() {
    const { content } = this.props.languageReducer;
    const { teeth_ipr_data, saved_data } = this.props.revised_data
      ? this.props.teethIPROptions[this.props.id]
      : this.props.teethIPROptions;

    const { dental_notation } = this.props.userReducer.currentUser;
    return (
      <div>
        <CustomeMovementTeeth
          teeth={teeth_ipr_data}
          saved_teeth_data={saved_data}
          disableChanges={true}
          hideSide={true}
        />

        <div className="movement-summary-grid">
          {Object.keys(teeth_ipr_data.upper_teeth)
            .filter((value, index) => {
              return (
                teeth_ipr_data.upper_teeth[value].movement.updated === true &&
                !teeth_ipr_data.upper_teeth[value].movement.restrict_tooth
              );
            })
            .map((value, index) => (
              <div key={index} className="summary-movement-details">
                <div className="summary-tooth-title">
                  {
                    teeth_ipr_data.upper_teeth[value].ToothSystemName[
                      dental_notation
                    ]
                  }{" "}
                </div>
                {/* Rotation */}
                {this.CheckFirstNum(
                  saved_data.upper_teeth[value].movement.Rotation,
                  teeth_ipr_data.upper_teeth[value].movement.Rotation
                ) && (
                  //   saved_data.upper_teeth[value].movement.Rotation !==
                  //     teeth_ipr_data.upper_teeth[value].movement.Rotation

                  <div>
                    {content.rotation} :
                    <span className="old-value cpr4 cpl4">
                      {this.GetNumber(
                        saved_data.upper_teeth[value].movement.Rotation,
                        content.deg,
                        content.distal.toLowerCase(),
                        content.mesial.toLowerCase()
                      )}
                    </span>
                    {content.to}
                    <span className="updated-value cpl4">
                      {this.GetNumber(
                        teeth_ipr_data.upper_teeth[value].movement.Rotation,
                        content.deg,
                        content.distal.toLowerCase(),
                        content.mesial.toLowerCase()
                      )}
                    </span>
                  </div>
                )}

                {/* Angulation */}

                {this.CheckFirstNum(
                  saved_data.upper_teeth[value].movement.Angulation,
                  teeth_ipr_data.upper_teeth[value].movement.Angulation
                ) && (
                  //   saved_data.upper_teeth[value].movement.Angulation !==
                  //     teeth_ipr_data.upper_teeth[value].movement.Angulation
                  <div>
                    {content.angulation} :
                    <span className="old-value cpr4 cpl4">
                      {this.GetNumber(
                        saved_data.upper_teeth[value].movement.Angulation,
                        content.deg,
                        content.toward.toLowerCase(),
                        content.away.toLowerCase()
                      )}
                    </span>
                    {content.to}
                    <span className="updated-value  cpl4">
                      {this.GetNumber(
                        teeth_ipr_data.upper_teeth[value].movement.Angulation,
                        content.deg,
                        content.toward.toLowerCase(),
                        content.away.toLowerCase()
                      )}
                    </span>
                  </div>
                )}

                {/* Torque */}

                {this.CheckFirstNum(
                  saved_data.upper_teeth[value].movement.Inclination,
                  teeth_ipr_data.upper_teeth[value].movement.Inclination
                ) && (
                  //   saved_data.upper_teeth[value].movement.Inclination !==
                  //     teeth_ipr_data.upper_teeth[value].movement.Inclination
                  <div>
                    {" "}
                    {content.torque} :
                    <span className="old-value cpr4 cpl4">
                      {this.GetNumber(
                        saved_data.upper_teeth[value].movement.Inclination,
                        content.deg,
                        content.labial.toLowerCase(),
                        content.lingual.toLowerCase()
                      )}
                    </span>
                    {content.to}
                    <span className="updated-value  cpl4">
                      {this.GetNumber(
                        teeth_ipr_data.upper_teeth[value].movement.Inclination,
                        content.deg,
                        content.labial.toLowerCase(),
                        content.lingual.toLowerCase()
                      )}
                    </span>
                  </div>
                )}

                {/* Mesial / Distal */}

                {this.CheckFirstNum(
                  saved_data.upper_teeth[value].movement.LeftRight,
                  teeth_ipr_data.upper_teeth[value].movement.LeftRight
                ) && (
                  //   saved_data.upper_teeth[value].movement.LeftRight !==
                  //     teeth_ipr_data.upper_teeth[value].movement.LeftRight
                  <div>
                    {" "}
                    {`${content.mesial}(+) / ${content.distal}(-)`}:
                    <span className="old-value cpr4 cpl4">
                      {saved_data.upper_teeth[value].movement.LeftRight}
                    </span>
                    {content.to}
                    <span className="updated-value cpl4">
                      {teeth_ipr_data.upper_teeth[value].movement.LeftRight}{" "}
                    </span>
                  </div>
                )}

                {/* Intrusion / Extrusion*/}

                {this.CheckFirstNum(
                  saved_data.upper_teeth[value].movement.ExtrusionIntrusion,
                  teeth_ipr_data.upper_teeth[value].movement.ExtrusionIntrusion
                ) && (
                  //   saved_data.upper_teeth[value].movement.ExtrusionIntrusion !==
                  //     teeth_ipr_data.upper_teeth[value].movement
                  //       .ExtrusionIntrusion

                  <div>
                    {" "}
                    {`${content.intrusion}(+) / ${content.extrusion}(-)`}:
                    <span className="old-value cpr4 cpl4">
                      {
                        saved_data.upper_teeth[value].movement
                          .ExtrusionIntrusion
                      }
                    </span>
                    {content.to}
                    <span className="updated-value cpl4">
                      {
                        teeth_ipr_data.upper_teeth[value].movement
                          .ExtrusionIntrusion
                      }{" "}
                    </span>
                  </div>
                )}

                {/* Out / In*/}

                {this.CheckFirstNum(
                  saved_data.upper_teeth[value].movement.ForwardBackward,
                  teeth_ipr_data.upper_teeth[value].movement.ForwardBackward
                ) && (
                  //   saved_data.upper_teeth[value].movement.ForwardBackward !==
                  //     teeth_ipr_data.upper_teeth[value].movement
                  //       .ForwardBackward

                  <div>
                    {" "}
                    {`${content.out}(+) / ${content.in}(-)`}:
                    <span className="old-value cpr4 cpl4">
                      {saved_data.upper_teeth[value].movement.ForwardBackward}
                    </span>
                    {content.to}
                    <span className="updated-value cpl4">
                      {
                        teeth_ipr_data.upper_teeth[value].movement
                          .ForwardBackward
                      }{" "}
                    </span>
                  </div>
                )}

                {/* Attachments*/}
                {((teeth_ipr_data.upper_teeth[value].movement.useAttachments !==
                  saved_data.upper_teeth[value].movement.useAttachments)||(teeth_ipr_data.upper_teeth[value].movement.useAttachments&& (this.checkAttachments(
                    teeth_ipr_data.upper_teeth[value].movement.attachments,
                    saved_data.upper_teeth[value].movement.attachments
                  ) ))) && (
                  <div>
                    {" "}
                    {content.attachments}:{" "}
                    {teeth_ipr_data.upper_teeth[value].movement.useAttachments
                      ? content.yes
                      : content.no}
                  </div>
                )}


         
                  <div>
                    {this.checkAttachments(
                      teeth_ipr_data.upper_teeth[value].movement.attachments,
                      saved_data.upper_teeth[value].movement.attachments
                    ) && (
                      <div>
                        {teeth_ipr_data.upper_teeth[
                          value
                        ].movement.attachments.map((value, index) => {
                          return (
                            <div
                              key={index}
                              className="summary-movement-details"
                            >
                              <div>
                                {content.attachments_type} :{value.type}
                              </div>
                              <div>
                                {content.add_attachments_on_step} :
                                <span className="updated-value cpl4">
                                  {value.step}
                                </span>{" "}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
               
                {/* Pontics*/}
                {teeth_ipr_data.upper_teeth[value].movement.usePontics !==
                  saved_data.upper_teeth[value].movement.usePontics && (
                  <div>
                    {" "}
                    {content.pontics}:{" "}
                    {teeth_ipr_data.upper_teeth[value].movement.usePontics
                      ? content.yes
                      : content.no}
                  </div>
                )}

                {/* Extraction*/}
                {teeth_ipr_data.upper_teeth[value].movement.use_extraction !==
                  saved_data.upper_teeth[value].movement.use_extraction && (
                  <div>
                    {" "}
                    {content.extraction}:{" "}
                    {teeth_ipr_data.upper_teeth[value].movement.use_extraction
                      ? content.yes
                      : content.no}
                  </div>
                )}

                {/* Torque Enhancers*/}
                {teeth_ipr_data.upper_teeth[value].movement
                  .useTorqueEnhancers !==
                  saved_data.upper_teeth[value].movement.useTorqueEnhancers && (
                  <div>
                    {" "}
                    {content.torque_enhancers}:{" "}
                    {teeth_ipr_data.upper_teeth[value].movement
                      .useTorqueEnhancers
                      ? content.yes
                      : content.no}
                  </div>
                )}

                {/* Elastics*/}
                {teeth_ipr_data.upper_teeth[value].movement.useElastics !==
                  saved_data.upper_teeth[value].movement.useElastics && (
                  <div>
                    {" "}
                    {content.elastics}:{" "}
                    {teeth_ipr_data.upper_teeth[value].movement.useElastics
                      ? content.yes
                      : content.no}
                  </div>
                )}
                {this.checkElastic(
                  teeth_ipr_data.upper_teeth[value].movement.elastics,
                  saved_data.upper_teeth[value].movement.elastics
                ) && (
                  <div>
                    {teeth_ipr_data.upper_teeth[value].movement.elastics.map(
                      (value, index) => {
                        return (
                          <div key={index} className="summary-movement-details">
                            <div>
                              {content.use_from_stage}{" "}
                              <span className="updated-value">
                                {value.from}
                              </span>{" "}
                              {content.to}{" "}
                              <span className="updated-value">{value.to}</span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            ))}
          {Object.keys(teeth_ipr_data.lower_teeth)
            .filter((value, index) => {
              return (
                teeth_ipr_data.lower_teeth[value].movement.updated === true &&
                !teeth_ipr_data.lower_teeth[value].movement.restrict_tooth
              );
            })
            .map((value, index) => (
              <div key={index} className="summary-movement-details">
                <div className="summary-tooth-title">
                  {
                    teeth_ipr_data.lower_teeth[value].ToothSystemName[
                      dental_notation
                    ]
                  }{" "}
                </div>
                {/* Rotation */}
                {this.CheckFirstNum(
                  saved_data.lower_teeth[value].movement.Rotation,
                  teeth_ipr_data.lower_teeth[value].movement.Rotation
                ) && (
                  //   saved_data.lower_teeth[value].movement.Rotation !==
                  //     teeth_ipr_data.lower_teeth[value].movement.Rotation
                  <div>
                    {content.rotation} :
                    <span className="old-value cpr4 cpl4">
                      {this.GetNumber(
                        saved_data.lower_teeth[value].movement.Rotation,
                        content.deg,
                        content.distal.toLowerCase(),
                        content.mesial.toLowerCase()
                      )}
                    </span>
                    {content.to}
                    <span className="updated-value cpl4">
                      {this.GetNumber(
                        teeth_ipr_data.lower_teeth[value].movement.Rotation,
                        content.deg,
                        content.distal.toLowerCase(),
                        content.mesial.toLowerCase()
                      )}
                    </span>
                  </div>
                )}

                {/* Angulation */}
                {this.CheckFirstNum(
                  saved_data.lower_teeth[value].movement.Angulation,
                  teeth_ipr_data.lower_teeth[value].movement.Angulation
                ) && (
                  //   saved_data.lower_teeth[value].movement.Angulation !==
                  //     teeth_ipr_data.lower_teeth[value].movement.Inclination
                  <div>
                    {content.angulation} :
                    <span className="old-value cpr4 cpl4">
                      {this.GetNumber(
                        saved_data.lower_teeth[value].movement.Angulation,
                        content.deg,
                        content.toward.toLowerCase(),
                        content.away.toLowerCase()
                      )}
                    </span>
                    {content.to}
                    <span className="updated-value  cpl4">
                      {this.GetNumber(
                        teeth_ipr_data.lower_teeth[value].movement.Angulation,
                        content.deg,
                        content.toward.toLowerCase(),
                        content.away.toLowerCase()
                      )}
                    </span>
                  </div>
                )}

                {/* Torque */}

                {this.CheckFirstNum(
                  saved_data.lower_teeth[value].movement.Inclination,
                  teeth_ipr_data.lower_teeth[value].movement.Inclination
                ) && (
                  //   saved_data.lower_teeth[value].movement.Inclination !==
                  //     teeth_ipr_data.lower_teeth[value].movement.Inclination

                  <div>
                    {" "}
                    {content.torque} :
                    <span className="old-value cpr4 cpl4">
                      {this.GetNumber(
                        saved_data.lower_teeth[value].movement.Inclination,
                        content.deg,
                        content.labial.toLowerCase(),
                        content.lingual.toLowerCase()
                      )}
                    </span>
                    {content.to}
                    <span className="updated-value  cpl4">
                      {this.GetNumber(
                        teeth_ipr_data.lower_teeth[value].movement.Inclination,
                        content.deg,
                        content.labial.toLowerCase(),
                        content.lingual.toLowerCase()
                      )}
                    </span>
                  </div>
                )}

                {/* Mesial / Distal */}

                {this.CheckFirstNum(
                  saved_data.lower_teeth[value].movement.LeftRight,
                  teeth_ipr_data.lower_teeth[value].movement.LeftRight
                ) && (
                  //   saved_data.lower_teeth[value].movement.LeftRight !==
                  //     teeth_ipr_data.lower_teeth[value].movement.LeftRight
                  <div>
                    {" "}
                    {`${content.mesial}(+) / ${content.distal}(-)`}:
                    <span className="old-value cpr4 cpl4">
                      {saved_data.lower_teeth[value].movement.LeftRight}
                    </span>
                    {content.to}
                    <span className="updated-value cpl4">
                      {teeth_ipr_data.lower_teeth[value].movement.LeftRight}{" "}
                    </span>
                  </div>
                )}

                {/* Intrusion / Extrusion*/}

                {this.CheckFirstNum(
                  saved_data.lower_teeth[value].movement.ExtrusionIntrusion,
                  teeth_ipr_data.lower_teeth[value].movement.ExtrusionIntrusion
                ) && (
                  //   saved_data.lower_teeth[value].movement.ExtrusionIntrusion !==
                  //     teeth_ipr_data.lower_teeth[value].movement
                  //       .ExtrusionIntrusion
                  <div>
                    {" "}
                    {`${content.intrusion}(+) / ${content.extrusion}(-)`}:
                    <span className="old-value cpr4 cpl4">
                      {
                        saved_data.lower_teeth[value].movement
                          .ExtrusionIntrusion
                      }
                    </span>
                    {content.to}
                    <span className="updated-value cpl4">
                      {
                        teeth_ipr_data.lower_teeth[value].movement
                          .ExtrusionIntrusion
                      }{" "}
                    </span>
                  </div>
                )}

                {/* Out / In*/}

                {this.CheckFirstNum(
                  saved_data.lower_teeth[value].movement.ForwardBackward,
                  teeth_ipr_data.lower_teeth[value].movement.ForwardBackward
                ) && (
                  <div>
                    {" "}
                    {`${content.out}(+) / ${content.in}(-)`}:
                    <span className="old-value cpr4 cpl4">
                      {saved_data.lower_teeth[value].movement.ForwardBackward}
                    </span>
                    {content.to}
                    <span className="updated-value cpl4">
                      {
                        teeth_ipr_data.lower_teeth[value].movement
                          .ForwardBackward
                      }{" "}
                    </span>
                  </div>
                )}

                {/* Attachments*/}
                {(teeth_ipr_data.lower_teeth[value].movement.useAttachments !==
                  saved_data.lower_teeth[value].movement.useAttachments ||(teeth_ipr_data.lower_teeth[value].movement.useAttachments &&(this.checkAttachments(
                    teeth_ipr_data.lower_teeth[value].movement.attachments,
                    saved_data.lower_teeth[value].movement.attachments
                  ) ))) && (
                  <div>
                    {" "}
                    {content.attachments}:{" "}
                    {teeth_ipr_data.lower_teeth[value].movement.useAttachments
                      ? content.yes
                      : content.no}
                  </div>
                )}





              
           
                  <div>
                    {this.checkAttachments(
                      teeth_ipr_data.lower_teeth[value].movement.attachments,
                      saved_data.lower_teeth[value].movement.attachments
                    ) && (
                      <div>
                        {teeth_ipr_data.lower_teeth[
                          value
                        ].movement.attachments.map((value, index) => {
                          return (
                            <div
                              key={index}
                              className="summary-movement-details"
                            >
                              <div>
                                {content.attachments_type} :{value.type}
                              </div>
                              <div>
                                {content.add_attachments_on_step} :
                                <span className="updated-value cpl4">
                                  {value.step}
                                </span>{" "}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
{/*            
                {teeth_ipr_data.lower_teeth[value].movement.Attachment_Note !==
                  saved_data.lower_teeth[value].movement.Attachment_Note &&
                  saved_data.lower_teeth[value].movement.useAttachments && (
                    <div>
                      <div>
                        {content.Attachment_Note} :
                        <span className="updated-value cpl4">
                          {
                            teeth_ipr_data.lower_teeth[value].movement
                              .Attachment_Note
                          }
                        </span>{" "}
                      </div>
                    </div>
                  )} */}

                {/* Pontics*/}
                {teeth_ipr_data.lower_teeth[value].movement.usePontics !==
                  saved_data.lower_teeth[value].movement.usePontics && (
                  <div>
                    {" "}
                    {content.pontics}:{" "}
                    {teeth_ipr_data.lower_teeth[value].movement.usePontics
                      ? content.yes
                      : content.no}
                  </div>
                )}

                {/* Extraction*/}
                {teeth_ipr_data.lower_teeth[value].movement.use_extraction !==
                  saved_data.lower_teeth[value].movement.use_extraction && (
                  <div>
                    {" "}
                    {content.extraction}:{" "}
                    {teeth_ipr_data.lower_teeth[value].movement.use_extraction
                      ? content.yes
                      : content.no}
                  </div>
                )}

                {/* Torque Enhancers*/}
                {teeth_ipr_data.lower_teeth[value].movement
                  .useTorqueEnhancers !==
                  saved_data.lower_teeth[value].movement.useTorqueEnhancers && (
                  <div>
                    {" "}
                    {content.torque_enhancers}:{" "}
                    {teeth_ipr_data.lower_teeth[value].movement
                      .useTorqueEnhancers
                      ? content.yes
                      : content.no}
                  </div>
                )}

                {/* Elastics*/}
                {teeth_ipr_data.lower_teeth[value].movement.useElastics !==
                  saved_data.lower_teeth[value].movement.useElastics && (
                  <div>
                    {" "}
                    {content.elastics}:{" "}
                    {teeth_ipr_data.lower_teeth[value].movement.useElastics
                      ? content.yes
                      : content.no}
                  </div>
                )}
                {this.checkElastic(
                  teeth_ipr_data.lower_teeth[value].movement.elastics,
                  saved_data.lower_teeth[value].movement.elastics
                ) && (
                  <div>
                    {teeth_ipr_data.lower_teeth[value].movement.elastics.map(
                      (value, index) => {
                        return (
                          <div key={index} className="summary-movement-details">
                            <div>
                              {content.use_from_stage}{" "}
                              <span className="updated-value">
                                {value.from}
                              </span>{" "}
                              {content.to}{" "}
                              <span className="updated-value">{value.to}</span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeethMovementSummary);
