import React, { Component } from "react";
import { CardFooter, Button } from "reactstrap";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { updateAlignerCase } from "../../api/api";
import CustomeHeader from "../../components/header/CustomeHeader";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
class Terms extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      doctorId: props.userReducer.currentUser.id,

      terms: "",
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*Summary step submit  */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.state;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    setSubmitting(true);
    this.setState({ terms: !this.state.terms })
    // let data = {
    //   aligner_case: {
    //     step: "terms",
    //     terms_and_agreements: "1",
    //   },
    // };
    let data2 = {
      aligner_case: {
        step: "summary",
      }, ...(this.props.rejectable_id && {
        resolve_rejection_id: this.props.rejectable_id,
      })
    };

    if (this.props.rejectable_id) {
      updateAlignerCase(doctorId, caseId, JSON.stringify(data2))
        .then((res) => {
          this.props.FixAlignerCase()
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
        });
    } else {
      // updateAlignerCase(doctorId, caseId, JSON.stringify(data))
      //   .then((res) => {
      this.props.NextStep();
      setSubmitting(false);
      // this.props.ShowAdvancedModule(showAdvancedModule);
      // })
      // .catch((error) => {
      //   setSubmitting(false);
      // });
    }

  };
  render() {
    const { content } = this.props.languageReducer;

    return (
      <div>



{/* 
{ (window.innerWidth > 839) && !this.props.rejectable_id ?
        <div className="patient-info-header">
    <CustomeHeader title={content.terms_and_agreements} />
    <div>{content.updated_may_10_th_2018}</div>
        </div> :
        <h1 className="head-mobile-caseSubmission">{content.terms_and_agreements}
        </h1>} */}





        { (window.innerWidth > 839) && !this.props.rejectable_id ? <div className="patient-info-header">
          <CustomeHeader title={content.terms_and_agreements} largeFont={false}/>
          <div className="subhead-text">{content.updated_april_1_st_2022}</div>
          <span className="patient_info callout-text">
            <div>{this.props.patient?.full_name}</div>
            <div> {content.case} {this.state?.caseId}</div>
          </span>
        </div> : 
        <div className="patient-info-header">

<span className="naming-in-summary ">
<br></br>
            <div className="patient-terms">{this.props.patient?.full_name}</div>
           
            <div className="case-terms"> {content.case} {this.state?.caseId}</div>
          </span>
          <br></br>
         <div className="head-mobile-caseSubmission-container title-3-text"> <h1 className="head-mobile-caseSubmission">{content.terms_and_agreements}
        </h1></div>
          <div className="subhead-text">{content.updated_april_1_st_2022}</div>

        </div>}

        <Formik
          enableReinitialize
          initialValues={{
            terms: "",
          }}
          // validationSchema={schema}
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <div className="patient-info-container terms-info ">
                <div >
                  <div >
                    <div className="">
                      <div className="form-group content form-block-holder terms-agreement ">
                        <div className="terms subhead-text">
                        
                          
                          <div>
                            {ReactHtmlParser(content.t_and_c)}
                          </div>
                          
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Accept Terms checkbox  */}
                        {/*--------------------------------------------------------------------------------*/}

                        {/* <div className="custom-control custom-checkbox pt-4 pb-1 ">
                          <FormGroup>
                            <Input
                              className={`pt-1 ${terms && "replacment-checkbox"
                                }`}
                              label="Accept and Continue"
                              id="terms"
                              name="terms"
                              type="checkbox"
                              onChange={() => {
                                setFieldValue("terms", !terms);
                                this.setState({ terms: !terms });
                              }}
                              checked={terms}
                            />

                            <ErrorMessage
                              component="div"
                              name="terms"
                              className="custom-invalid-feedback mt-1"
                            />
                          </FormGroup>
                        </div>
                       
                        */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Accept Terms checkbox  */}
                        {/*--------------------------------------------------------------------------------*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CardFooter className="p-3  term-button    trem-grid">
                {
                  this.props.rejectable_id ?

                    <Button
                      className={`btn light-green-btn float-right  submission-btn  button-seme-bold-text`}
                      size="sm"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Fix
                    </Button>

                    : <>
                     <div className="order-save-button inmobile">
                            <Button
                        className={`btn light-green-btn float-right  submission-btn  button-seme-bold-text `}
                        size="sm"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {content.agree} <i className="fas fa-angle-right pl-1 button-bold-text "></i>
                      </Button>
                      </div>
                    <Button
              
                      className={`btn white-btn float-left submission-btn  button-seme-bold-text`}
                      size="sm"
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => this.props.PrevStep()}
                    >
                      <i className="fas fa-angle-left  pr-1"></i> {content.previous}
                    </Button>
                    <div className="inWebsit">
                      <Button
                        className={`btn light-green-btn float-right  submission-btn button-seme-bold-text `}
                        size="sm"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {content.agree} <i className="fas fa-angle-right pl-1"></i>
                      </Button> 
                      </div></>}
              </CardFooter>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Terms);