import React from "react";
import trash from "../../assets/images/icon/trash.svg";
import { Input } from "reactstrap";
import Select from "react-select";

// this is shared component for add attchements on steps for each tooth in revesion flow
/* <ToothAttachments
     StepTitle="Add attachments on step" 
     hyberText="Add another attachment" 
     attachments_array={attachments_array}
     onclick={(value) => {
     this.handelChanges(value)
     }}
     content={content}
     useAttachments={useAttachments}
/> */

const ToothAttachments = ({
  StepTitle,
  hyberText,
  onclick,
  attachments_array,
  content,
  useAttachments,
  useAttachmentAdmain
}) => {
  //attachment type options
  const attachments_typs = [
    {
      label: content.rotational,
      value: "Rotational",
    },
    {
      label: content.hbra,
      value: "HBRA",
    },
    {
      label: content.vbra,
      value: "VBRA",
    },
    {
      label: content.biplane,
      value: "Biplane",
    },
    {
      label: content.root_control,
      value: "Root control",
    },
    {
      label: content.extrusion,
      value: "Extrusion",
    },
    {
      label: content.other,
      value: "Other",
    },
  ];
  //adding new attachment step with value and type //
  const addStep = () => {
    let newStep = attachments_array.concat({
      step: 0,
      type: "",
    });
    onclick(newStep);
  };

  //delete attchemnt Step value and type //
  const removeStep = (index) => {
    let deleteStep = attachments_array.filter((el, i) => i !== index);
    onclick(deleteStep);
  };

  //change attchment Step Value//
  const changeStepValue = (value, index) => {
    let steps_changing = attachments_array.map((item, j) => {
      if (j === index) {
        return {
          ...item,
          step: value,
        };
      } else {
        return item;
      }
    });
    onclick(steps_changing);
  };
  //change attachment type//

  const changeType = (type, index) => {
    let type_changing = attachments_array.map((item, j) => {
      if (j === index) {
        return {
          ...item,
          type: type.value,
        };
      } else {
        return item;
      }
    });
    onclick(type_changing);
  };


  const changeType_note =(e, index) => {
    let type_changing = attachments_array.map((item, j) => {
      if (j === index) {
        return {
          ...item,
          type: e.target.value,
        };
      } else {
        return item;
      }
    });
    onclick(type_changing);
  };
  return (
    <div>
      <div className="cmt2">
        {attachments_array.map((value, index) => (
          <div key={index}>
            <div className="attachment-movement-grid cmb1">
              <div>
                {index > 0 && (
                  <div
                    className={`${
                      !useAttachments ? "event-none" : ""
                    } remove-step`}
                    onClick={() => removeStep(index)}
                  >
                    <img src={trash} alt="trash" />
                  </div>
                )}
              </div>
              <div>
                <div>
                  <span className="custom-dark-text">{StepTitle}</span>
                  <span className="form-material">
                    <Input
                      type="number"
                      placeholder=""
                      className="step-number"
                      onClick={(e) => {
                        e.target.focus();
                      }}
                      onMouseUp={(e) => {
                        e.target.blur();
                      }}
                      value={value.step}
                      key={index}
                      min={0}
                      disabled={!useAttachments}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          changeStepValue(0, index);
                        } else {
                          changeStepValue(
                            e.target.value.replace(/^0+/, ""),
                            index
                          );
                        }
                      }}
                    />
                  </span>
                </div>
               { useAttachmentAdmain ? 


                <div className="textarea-attachment-container"> <Input
                                        className="custome-textarea-attachment"
                                        name="Attachment_Note"
                                        id="Attachment_Note"
                                        type="textarea"
                                        placeholder={content.AttachmentTypeNOTE}
                                        rows={2}
                                        onClick={(e) => {
                                            e.target.focus();
                                          }}
                                          onMouseUp={(e) => {
                                            e.target.blur();
                                          }}
                                        
                                          value={value.type}
                                    
                                          
                                          onChange={(e) => {
                                            changeType_note(e, index);
                                          }}
                                        disabled={!useAttachments}
                                    /> </div>
              :
                <Select
                  onChange={(type) => {
                    changeType(type, index);
                  }}
                  isDisabled={!useAttachments}
                  className={` ${
                    !useAttachments ? "custome-select-disabled" : ""
                  } custome-input-text`}
                  options={attachments_typs}
                  value={attachments_typs.filter((v) => v.value === value.type)}
                />}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="add-attachment-movement-grid">
        <div></div>
        <div
          className={`${!useAttachments ? "event-none" : "green_text"} add-step`}
          onClick={addStep}
        >
          {hyberText}{" "}
        </div>
      </div> */}
    </div>
  );
};

export default ToothAttachments;
