import { UPDATE_ADJUSTMENT, RESET_ADJUSTMENT } from "../constants";

export const updateAdjustment = (payload) => {
  return {
    type: UPDATE_ADJUSTMENT,
    payload,
  };
};

export const resetAdjustment = (payload) => {
  return {
    type: RESET_ADJUSTMENT,
    payload,
  };
};
